<div class="wrapper">
  <sidebar-header [label]="'create' | translate"></sidebar-header>
  <section class="create-menu">
    <span class="create-menu__item">
      <app-q-button
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_CONTACTS_CREATE_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="contact"
        icon="user"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showCreateObject$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_CREATE_OBJECT_BASE_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="object"
        icon="home-lg-alt"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showSendMessage$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="message"
        icon="envelope"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showSalesMeeting$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_SALES_MEETING_BASE_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="sales_meeting"
        icon="briefcase"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showSendTips$ | async"
        [routerLink]="['/crm', { outlets: { sidebar: SIDEBAR_TIPS_BASE_URL } }]"
        [fullWidth]="true"
        buttonType="save"
        label="tip"
        icon="lightbulb"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showExternalTips$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_EXTERNAL_TIPS_SEARCH_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="external_tip"
        icon="lightbulb-on"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showCreateFollowUp$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: SIDEBAR_FOLLOW_UPS_BASE_URL } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="follow_up"
        icon="check"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: [ROOT_SIDEBAR_ROUTE, 'create-calling-list'] } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="calling_list"
        icon="list"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showCreateLead$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: [ROOT_SIDEBAR_ROUTE, 'create-lead'] } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="lead"
        icon="street-view"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showSendTipsEika$ | async"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: [ROOT_SIDEBAR_ROUTE, 'eika'] } }
        ]"
        [fullWidth]="true"
        buttonType="save"
        label="eika_send_tip"
        icon="bulb"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="showCreateShowing$ | async"
        (click)="createShowing()"
        [fullWidth]="true"
        buttonType="save"
        label="create_showing"
        icon="users"
      >
      </app-q-button>
    </span>
    <span class="create-menu__item">
      <app-q-button
        *ngIf="sendPotentialBuyerService.hasIntegration$ | async"
        (click)="sendToErpAsPotentialBuyer()"
        [fullWidth]="true"
        [label]="
          'send_as_potential_buyer_to_erp'
            | translate : { erp: externalProviderName$ | async | titlecase }
        "
        buttonType="save"
        icon="paper-plane"
      >
      </app-q-button>
    </span>
  </section>
</div>
