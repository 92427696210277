import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as sessionActions from "./session.actions";

export interface ToggleState {
  outgoingMail: boolean;
  latestActions: boolean;
  listsSalesMeetings: boolean;
  listsLeads: boolean;
  listsTasks: boolean;
  listsContacts: boolean;
  listsSentLeads: boolean;
  listsShowings: boolean;
  listsCallingList: boolean;
}

const initialToggleState: ToggleState = {
  outgoingMail: false,
  latestActions: false,
  listsSalesMeetings: false,
  listsLeads: false,
  listsTasks: false,
  listsContacts: false,
  listsSentLeads: false,
  listsShowings: false,
  listsCallingList: false,
};

export interface SessionState {
  toggleState: ToggleState;
}

export const initialState: SessionState = {
  toggleState: initialToggleState,
};

export function sessionReducer(
  state: SessionState,
  action: Action
): SessionState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(sessionActions.setSession, (state, { session }) => ({
    ...state,
    ...session,
  })),
  on(sessionActions.toggleToggleStateByKey, (state, { key }) => {
    const newToggleState = Object.assign({}, state.toggleState);
    newToggleState[key] = !newToggleState[key];
    return { ...state, toggleState: newToggleState };
  }),
  on(sessionActions.setToggleStateByKey, (state, { key, value }) => {
    const newToggleState = Object.assign({}, state.toggleState);
    newToggleState[key] = value;
    return { ...state, toggleState: newToggleState };
  }),
  on(sessionActions.setAllToggleStatesTo, (state, action) => {
    const newToggleState = { ...state.toggleState };
    for (const key in newToggleState) {
      if (newToggleState.hasOwnProperty(key)) {
        newToggleState[key] = action.state;
      }
    }
    return { ...state, toggleState: newToggleState };
  })
);

export const selectFeature = createFeatureSelector<SessionState>("session");

export const getToggleState = (key: string) =>
  createSelector(selectFeature, (state) => state.toggleState[key]);
