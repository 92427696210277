import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { KpiBankTipsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-bank-tips/kpi-bank-tips.component";
import { KpiCommissionsEarningsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-commissions-earnings/kpi-commissions-earnings.component";
import { KpiEstateCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-estate-commission/kpi-estate-commission.component";
import { KpiRentedObjectsCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-rented-objects-commission/kpi-rented-objects-commission.component";
import { KpiRentedObjectsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-rented-objects/kpi-rented-objects.component";
import { KpiSalesContractDateComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-sales-contract-date/kpi-sales-contract-date.component";
import { KpiShowingsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-showings/kpi-showings.component";
import { KpiSoldObjectsCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-sold-objects-commision/kpi-sold-objects-commission.component";
import { KpiSoonForSaleObjectsNewComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-soon-for-sale-objects-new/kpi-soon-for-sale-objects-new.component";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import { filter, first, map, Observable, switchMap, tap } from "rxjs";
import * as kpiActions from "../../ngrx/kpi.actions";
import * as fromKpi from "../../ngrx/kpi.reducer";
import { KpiUtilsService } from "../../services/kpi-utils.service";
import * as KPI_MODAL_TYPES from "../../utils/kpi-modal-types";
import * as KPI_TYPES from "../../utils/kpi-types";
import { KpiBookedSalesMeetingDetailComponent } from "./kpi-details/kpi-booked-sales-meeting-detail/kpi-booked-sales-meeting-detail.component";
import { KpiMadeSalesCallsComponent } from "./kpi-details/kpi-made-sales-calls/kpi-made-sales-calls.component";
import { KpiSoldObjectsComponent } from "./kpi-details/kpi-sold-objects/kpi-sold-objects.component";
import { KpiWonSalesMeetingDetailComponent } from "./kpi-details/kpi-won-sales-meeting-detail/kpi-won-sales-meeting-detail.component";
import { NpsSurveyResponsesComponent } from "./kpi-details/nps-survey-responses/nps-survey-responses.component";

@Component({
  selector: "kpi-employee",
  templateUrl: "./kpi-employee.component.html",
  styleUrls: ["../kpi-modal.component.scss", "./kpi-employee.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiEmployeeComponent implements OnInit {
  @ViewChild("container", { static: true, read: ViewContainerRef })
  container;
  componentRef: ComponentRef<any>;

  type$: Observable<string>;
  from$: Observable<string>;
  resources$: Observable<any>;
  eaOfficeId$: Observable<string>;
  roles = [ROLE_ADMIN, ROLE_MANAGER];
  type: string;

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private kpiUtils: KpiUtilsService
  ) {}

  ngOnInit(): void {
    this.init();

    this.store
      .pipe(fromKpi.getKpiDetailsByType(this.type))
      .subscribe((details) => {
        // @ts-ignore
        this.route.data.next({ kpi: details });
      });
  }

  init() {
    this.mapStateToProps();
    this.createComponent();
    this.from$.subscribe((month) => this.setTitle(month));
  }

  mapStateToProps(): void {
    this.type$ = this.route.params.pipe(map((params) => params?.type));
    this.from$ = this.route.parent.queryParams.pipe(
      map((params) => params?.from)
    );
    this.resources$ = this.route.data.pipe(map((params) => params?.kpi));
    this.eaOfficeId$ = this.route.params.pipe(
      map((params) => params?.eaOfficeId)
    );
  }

  createComponent(): void {
    this.type$.pipe(first()).subscribe((type) => {
      this.componentRef = this.container.createComponent(
        this.getComponent(type)
      );
      this.componentRef.instance.resources$ = this.resources$;
      this.type = type;
    });
  }

  getComponent(type: string): Type<any> {
    switch (type) {
      case KPI_TYPES.BOOKED_SALES_MEETINGS:
        return KpiBookedSalesMeetingDetailComponent;
      case KPI_TYPES.WON_SALES_MEETINGS:
        return KpiWonSalesMeetingDetailComponent;
      case KPI_TYPES.SOLD_OBJECTS:
        return KpiSoldObjectsComponent;
      case KPI_TYPES.MADE_SALES_CALLS:
        return KpiMadeSalesCallsComponent;
      case KPI_TYPES.NPS:
        return NpsSurveyResponsesComponent;
      case KPI_TYPES.COMMISSIONS_EARNINGS:
        return KpiCommissionsEarningsComponent;
      case KPI_TYPES.SOON_FOR_SALE_OBJECTS_NEW:
        return KpiSoonForSaleObjectsNewComponent;
      case KPI_TYPES.SALES_CONTRACT_DATE:
        return KpiSalesContractDateComponent;
      case KPI_TYPES.BANK_TIPS:
        return KpiBankTipsComponent;
      case KPI_TYPES.SHOWINGS:
        return KpiShowingsComponent;
      case KPI_TYPES.RENTED_OBJECTS:
        return KpiRentedObjectsComponent;
      case KPI_TYPES.RENTED_OBJECTS_COMMISSION:
        return KpiRentedObjectsCommissionComponent;
      case KPI_TYPES.COMMISSIONS_EARNINGS_RENTALS:
        return KpiCommissionsEarningsComponent;
      case KPI_TYPES.ESTATE_COMMISSION:
        return KpiEstateCommissionComponent;
      case KPI_TYPES.SOLD_OBJECTS_COMMISSION:
        return KpiSoldObjectsCommissionComponent;
    }
  }

  setTitle(month: string): void {
    this.route.params
      .pipe(
        first(),
        tap((params) =>
          this.store.dispatch(
            kpiActions.fetchEmployeeRequest({ id: params.eaEmployeeId })
          )
        ),
        switchMap(({ type }) =>
          this.store.pipe(
            select(fromKpi.getEmployee),
            filter((value) => !!value),
            first(),
            map((employee) => ({ type, name: employee.fullName }))
          )
        )
      )
      .subscribe(({ type, name }) =>
        this.kpiUtils.setModalTitle({
          type,
          name,
          modalType: KPI_MODAL_TYPES.EMPLOYEE,
          month,
        })
      );
  }
}
