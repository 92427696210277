import { CreateObjectComponent } from "@app/sidebar/create-object/create-object.component";
import { CreateObjectGuard } from "@app/sidebar/guards/create-object-guard.service";

export const sidebarCreateObjectRoutes = {
  path: "create-object",
  component: CreateObjectComponent,
  children: [
    {
      path: "",
      component: CreateObjectComponent,
      canActivate: [CreateObjectGuard],
    },
  ],
};
