import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getCurrency } from "@app/shared/config/config.reducer";
import * as widgetActions from "@app/widgets/widgets/statistics-sold-objects-widget/ngrx/statistics-sold-objects-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-sold-objects-widget/ngrx/statistics-sold-objects-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { Observable, skip, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-statistics-sold-objects-widget",
  templateUrl: "./statistics-sold-objects-widget.component.html",
  styleUrls: ["./statistics-sold-objects-widget.component.scss"],
})
export class StatisticsSoldObjectsWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() returnType: string;
  @Input() triggerDataFetch$: Observable<void>;
  @Input() showHeader: boolean = true;

  currentRevenue$: Observable<number>;
  revenueDifference$: Observable<number>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;
  unsubscribe$ = new Subject<void>();
  previousPeriodStart = moment().subtract(13, "months").format("L");
  previousPeriodEnd = moment().subtract(1, "months").format("L");

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId || changes.returnType) {
      this.getStatistics();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.currentRevenue$ = this.store.pipe(select(fromWidget.getStats));
    this.revenueDifference$ = this.store.pipe(select(fromWidget.getDifference));
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
    this.currency$ = this.store.pipe(select(getCurrency));
  }

  private getStatistics() {
    // Todo: Fetch returnType from settings
    const defaultParams = {
      includeLastYearsData: false,
      returnTypes: this.returnType,
    };
    this.store.dispatch(
      widgetActions.fetchStatisticsRequest({
        params: {
          ...defaultParams,
          ...this.getIds(),
          periodStart: moment().subtract(1, "year").format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        },
      })
    );
    this.store.dispatch(
      widgetActions.fetchPreviousStatisticsRequest({
        params: {
          ...defaultParams,
          ...this.getIds(),
          periodStart: moment().subtract(13, "months").format("YYYYMMDD"),
          periodEnd: moment().subtract(1, "months").format("YYYYMMDD"),
        },
      })
    );
  }

  getIds(): any {
    const params = {};
    if (!!this.eaEmployeeId) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }

  getIconClass(difference: number) {
    if (difference === 0) {
      return null;
    } else if (difference > 0) {
      return "icon-arrow-up";
    } else if (difference < 0) {
      return "icon-arrow-down";
    }
  }
}
