export interface ModalsConfig {
  external_provider_connection: {
    enabled: boolean;
  };
}

export const ModalsConfigDefaults = {
  external_provider_connection: {
    enabled: true,
  },
};
