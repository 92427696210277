import {
  catchError,
  concatMap,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  take,
} from "rxjs";
import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Contact, Office } from "@app/models";
import { getCountry } from "@app/shared/config/config.reducer";
import {
  SIDEBAR_TIPS_BASE_URL,
  SIDEBAR_TIPS_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SEND_TIPS } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import * as ccActions from "../../contacts/ngrx/create-contact.actions";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { resetDirty } from "../../ngrx/sidebar.actions";
import * as sendTipsActions from "./send-tips.actions";

@Injectable()
export class SendTipsEffects {
  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.getContactRequest),
      switchMap(({ id }) =>
        this.apiService.get(`contacts/${id}`).pipe(
          map((response: Contact) =>
            sendTipsActions.getContactSuccess({
              contact: new Contact(response),
            })
          ),
          catchError(() =>
            observableFrom([
              sendTipsActions.getContactFailed(),
              RouterActions.go({
                path: [
                  "/crm/",
                  { outlets: { sidebar: SIDEBAR_TIPS_BASE_URL } },
                ],
              }),
            ])
          )
        )
      )
    )
  );

  requestClosestOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.setContact, sendTipsActions.getContactSuccess),
      switchMap(({ contact }) =>
        this.store.pipe(
          select(getCountry),
          take(1),
          map((country) =>
            sendTipsActions.getClosestOfficeRequest({
              street: contact.street,
              zip: contact.zip,
              city: contact.city,
              country: country.toUpperCase(),
            })
          )
        )
      )
    )
  );

  getClosestOffice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.getClosestOfficeRequest),
      switchMap(({ type, ...params }) => {
        if (
          _.values(params).some((param: string) => !param || param.length === 0)
        ) {
          return observableOf(sendTipsActions.getClosestOfficeFailed());
        }

        return this.apiService.get(`offices/closest`, params).pipe(
          map((office: Office) =>
            sendTipsActions.getClosestOfficeSuccess({ office })
          ),
          catchError(() =>
            observableOf(sendTipsActions.getClosestOfficeFailed())
          )
        );
      })
    )
  );

  sendTips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.sendTipsRequest),
      switchMap(({ params }) =>
        this.apiService.post(`tasks/template`, params).pipe(
          concatMap(() =>
            observableFrom([
              sidebarActions.closeTab({ tabType: SEND_TIPS }),
              sendTipsActions.sendTipsSuccess(),
              toastActions.success({ message: "send_tips_success" }),
            ])
          ),
          catchError(() => observableOf(sendTipsActions.sendTipsFailed()))
        )
      )
    )
  );

  sendTipsFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.sendTipsFailed),
      switchMap(() => this.translateService.get("send_tips_failed")),
      map((message: string) => toastActions.danger({ message }))
    )
  );

  createContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.createContactRequest),
      switchMap(({ params, source }) =>
        this.apiService.post("contacts", params).pipe(
          mergeMap((data: { contactId }) => {
            const actions: Action[] = [
              sendTipsActions.createContactSuccess({
                contactId: data.contactId,
              }),
            ];
            if (source) {
              actions.push(
                ccActions.addContactToSource({
                  ...params,
                  eaTaskTypeId: source,
                  contactId: data.contactId,
                })
              );
            }
            return observableFrom(actions);
          })
        )
      )
    )
  );

  createContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendTipsActions.createContactSuccess),
      concatMap(({ contactId }) =>
        observableFrom([
          resetDirty({ tabType: SEND_TIPS }),
          RouterActions.go({
            path: [
              "/crm",
              { outlets: { sidebar: SIDEBAR_TIPS_CREATE_URL(contactId) } },
            ],
          }),
        ])
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}
}
