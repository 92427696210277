import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { FormElementService } from "@app/erp/components/form-elements/form-element.service";

@Component({
  selector: "app-checkbox-element",
  templateUrl: "./checkbox-element.component.html",
  styleUrls: ["./checkbox-element.component.scss"],
})
export class CheckboxElementComponent implements OnInit, OnChanges {
  @Output() valueEntered: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() preselectedValue: boolean = null;
  @Input() entity: any;
  @Input() label: string;
  @Input() infoTranslationKey: string;
  @Input() onPatchFunction: Function;
  @Input() path: string[];
  @Input() width: string;

  formElementService: FormElementService;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (!this.formElementService) {
      this.initFormElementService();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.entity && this.entity) || changes.preselectedValue) {
      this.initFormElementService();
    }
  }

  initFormElementService(): void {
    this.formElementService = new FormElementService(this.fb, {
      path: this.path,
      entity: !!this.entity ? this.entity : null,
      preselectedValue: this.preselectedValue,
      onPatchFunction: this.onPatchFunction,
    });
  }

  onBlur() {
    // VALID
    this.formElementService.handleBlur().subscribe((it) => {
      if (it === "UNKNOWN") {
        this.valueEntered.emit(this.formElementService.getFormValue());
      }
    });
  }
}
