import {
  InvoicingPeriodParams,
  InvoicingPeriodResponse,
  InvoicingStatisticsParams,
  InvoicingStatisticsResponse,
} from "@app/statistics/statistics-invoicing/models/invoicing-models";
import { createAction, props } from "@ngrx/store";

export const getInvoicingStatisticsRequest = createAction(
  "[Statistics] GET_INVOICING_STATISTICS_REQUEST",
  props<{ params: InvoicingStatisticsParams }>()
);

export const getInvoicingStatisticsSuccess = createAction(
  "[Statistics] GET_INVOICING_STATISTICS_SUCCESS",
  props<{ invoices: InvoicingStatisticsResponse }>()
);

export const getInvoicingStatisticsFailure = createAction(
  "[Statistics] GET_INVOICING_STATISTICS_FAILURE"
);

export const getInvoicingPeriodsRequest = createAction(
  "[Statistics] GET_INVOICING_PERIODS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getInvoicingPeriodsSuccess = createAction(
  "[Statistics] GET_INVOICING_PERIODS_SUCCESS",
  props<{ invoices: InvoicingPeriodResponse[] }>()
);

export const getInvoicingPeriodsFailure = createAction(
  "[Statistics] GET_INVOICING_PERIODS_FAILURE"
);

export const getInvoicingCSVRequest = createAction(
  "[Statistics] GET_INVOICING_CSV_REQUEST",
  props<{ params: InvoicingStatisticsParams }>()
);

export const getInvoicingCSVSuccess = createAction(
  "[Statistics] GET_INVOICING_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const getInvoicingCSVFailure = createAction(
  "[Statistics] GET_INVOICING_CSV_FAILURE"
);

export const resetInvoiceState = createAction("[Statistics] RESET_STATE");
