import { BudgetTarget } from "@app/models/budget-target";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import { GrowthStatisticsFilters } from "@app/statistics/statistics-growth/models/growth-statistics-filters";
import { createAction, props } from "@ngrx/store";

export const fetchGrowthStatisticsRequest = createAction(
  "[GrowthReports] FETCH_GROWTH_STATISTICS_REQUEST",
  props<{ params: GrowthStatisticsFilters }>()
);

export const fetchGrowthStatisticsSuccess = createAction(
  "[growthreports] fetch_growth_statistics_success",
  props<{ growthStatistics: GrowthStatistics }>()
);

export const fetchGrowthStatisticsFailed = createAction(
  "[GrowthReports] FETCH_GROWTH_STATISTICS_FAILED"
);

export const fetchGrowthStatisticsCsvRequest = createAction(
  "[GrowthReports] FETCH_GROWTH_STATISTICS_CSV_REQUEST",
  props<{ params: GrowthStatisticsFilters }>()
);

export const fetchGrowthStatisticsCsvSuccess = createAction(
  "[GrowthReports] FETCH_GROWTH_STATISTICS_CSV_SUCCESS"
);

export const fetchGrowthStatisticsCsvFailed = createAction(
  "[GrowthReports] FETCH_GROWTH_STATISTICS_CSV_FAILED"
);

export const fetchBudgetTargetTypesRequest = createAction(
  "[GrowthReports] FETCH_BUDGET_TARGET_TYPES_REQUEST"
);

export const fetchBudgetTargetTypesSuccess = createAction(
  "[GrowthReports] FETCH_BUDGET_TARGET_TYPES_SUCCESS",
  props<{ budgetTypes: BudgetTarget[] }>()
);

export const fetchBudgetTargetTypesFailed = createAction(
  "[GrowthReports] FETCH_BUDGET_TARGET_TYPES_FAILED"
);

export const fetchTagSuggestionsRequest = createAction(
  "[GrowthReports] FETCH_TAG_SUGGESTIONS_REQUEST",
  props<{ eaOfficeIds: string[] }>()
);

export const fetchTagSuggestionsSuccess = createAction(
  "[GrowthReports] FETCH_TAG_SUGGESTIONS_SUCCESS",
  props<{ tags: any[] }>()
);

export const fetchTagSuggestionsFailed = createAction(
  "[GrowthReports] FETCH_TAG_SUGGESTIONS_FAILED"
);
