<div
  class="q-action-dropdown"
  (clickOutside)="closeDropdown()"
  appClickStopPropagation
>
  <button
    *ngrxLet="isMobile$ as isMobile"
    class="q-action-dropdown__toggle"
    [class.q-action-dropdown__toggle--hover]="onHover"
    (click)="isMobile ? openBottomSheet(sheet) : toggleDropdown()"
    appClickStopPropagation
  >
    <app-icon name="ellipsis-h" type="regular" size="large"></app-icon>
  </button>
  <div
    role="menu"
    class="q-action-dropdown__list"
    [class.q-action-dropdown__list--placement-top]="placementTop"
    *ngIf="showDropdown"
    #element
  >
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

<ng-template #sheet>
  <div role="menu">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
