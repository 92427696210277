<h4 translate>has_loan_commitment</h4>
<div class="btn-group">
  <button
    [class.active]="isSelected(Status.HasMortgage)"
    (click)="handleClick(Status.HasMortgage)"
    type="button"
    class="btn btn-default"
    translate
  >
    yes
  </button>

  <button
    [class.active]="isSelected(Status.NoMortgage)"
    (click)="handleClick(Status.NoMortgage)"
    type="button"
    class="btn btn-default"
    translate
  >
    no
  </button>

  <button
    [class.active]="isSelected(Status.WantsMortgage)"
    (click)="handleClick(Status.WantsMortgage)"
    type="button"
    class="btn btn-default"
    translate
  >
    wants
  </button>
</div>
<div
  *ngIf="isSelected(Status.WantsMortgage) && enableSendTipToBank"
  class="send-tip-to-bank-container"
>
  <button (click)="sendTipToBankClicked.emit()" class="btn btn-primary">
    <i class="icon-bulb"></i>
    <span translate>send_tip_to_bank</span>
  </button>
</div>
