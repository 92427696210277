export interface ApartmentType {
  key: string;
  id: number;
}

export const APARTMENT_TYPES: ApartmentType[] = [
  {
    key: "Huoneisto",
    id: 1,
  },
  {
    key: "Kiinteistö",
    id: 2,
  },
];
