import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { delay, first } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ProductNewsModalComponent } from "@app/shared/modules/product-news/product-news-modal/product-news-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";

export interface ProductNews {
  id: number;
  content: any;
  visited: boolean;
}

@Injectable({
  providedIn: "root",
})
export class ProductNewsService {
  private _productNews: ProductNews[] = [];
  isShown$ = new BehaviorSubject<boolean>(false);
  unseenProductNews$ = new BehaviorSubject<{ count: number }>({
    count: 0,
  });

  get productNews() {
    return this._productNews;
  }

  constructor(
    public dialog: MatDialog,
    private modalService: QModalService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer
  ) {}

  initService() {
    this.loadUnseenProductNews();

    this.isShown$.next(
      this.localStorageService.fetchUserPreference(
        "product_news_modal_is_shown"
      ) === "true"
    );
  }

  showModal() {
    const firstUnseenItem = [...this._productNews]
      .reverse()
      .find((item) => !item.visited);
    this.modalService.show(ProductNewsModalComponent, {
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
        hasUnseenItem: !!firstUnseenItem,
        currentItem:
          (!!firstUnseenItem ? firstUnseenItem.id : this._productNews.length) -
          1,
      },
    });

    this.modalService
      .getDialogRef()
      .afterClosed()
      .pipe(first(), delay(100))
      .subscribe(() => {
        this.setVisitedProductNews();
      });
  }

  setIsShownDialog(isShown: boolean = true) {
    this.isShown$.next(isShown);
    this.localStorageService.saveUserPreference(
      "product_news_modal_is_shown",
      isShown.toString()
    );
  }

  saveVisitedItem(itemIndex: number) {
    this._productNews[itemIndex].visited = true;
    const visitedItems = this._productNews
      .filter((item) => item.visited)
      .map((item) => item.id);

    this.unseenProductNews$.next({
      count: this._productNews.length - visitedItems.length,
    });
    this.localStorageService.saveUserPreference(
      "product_news_latest_viewed",
      JSON.stringify(visitedItems)
    );
  }

  markAllAsRead() {
    this._productNews.forEach((item) => (item.visited = true));
    this.saveVisitedItem(0);
  }

  private loadUnseenProductNews() {
    this.loadProductNews();
    this.setVisitedProductNews();
  }

  private loadProductNews() {
    let index = 1;
    let thereAreProductNews = true;
    this._productNews = [];
    while (thereAreProductNews) {
      const translation = this.translateService.instant(`productnews_${index}`);

      if (translation && translation !== `productnews_${index}`) {
        this._productNews.push({
          id: index,
          content: this.sanitizer.bypassSecurityTrustHtml(translation),
          visited: false,
        });
        index++;
      } else {
        thereAreProductNews = false;
      }
    }
  }

  private setVisitedProductNews() {
    const productNewsLatestViewed =
      this.localStorageService.fetchUserPreference(
        "product_news_latest_viewed"
      );

    if (productNewsLatestViewed) {
      const visitedItems = JSON.parse(productNewsLatestViewed);

      visitedItems.forEach((visitedId) => {
        const newsItem = this._productNews.find(
          (item) => item.id === visitedId
        );
        if (newsItem) {
          newsItem.visited = true;
        }
      });

      this.unseenProductNews$.next({
        count: this._productNews.filter((item) => !item.visited).length,
      });
    } else {
      this.markAllAsRead();
    }
  }
}
