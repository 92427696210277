import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { EmployeeDTO, Employment, Office } from "@app/models";
import { getOffices } from "@app/shared/ngrx/shared.reducer";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import {
  SIDEBAR_EMPLOYEES_OFFICE_SEARCH_URL,
  SIDEBAR_EMPLOYEES_SEARCH_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import { first, map, Observable, Subject, takeUntil } from "rxjs";
import * as eoActions from "./ngrx/employees-office-create.actions";
import {
  getEmployee,
  getEmployments,
} from "./ngrx/employees-office-create.reducer";

@Component({
  selector: "employees-office-create",
  templateUrl: "./employees-office-create.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./employees-office-create.component.scss",
  ],
})
export class EmployeesOfficeCreateComponent implements OnInit, OnDestroy {
  tabType = EMPLOYEES;

  officeControl: FormControl;

  offices$: Observable<Office[]>;
  employee$: Observable<EmployeeDTO>;
  employments$: Observable<string[]>;
  eaOfficeId$: Observable<string>;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.officeControl = new FormControl("");

    this.eaOfficeId$ = this.route.params.pipe(
      map((params) => params?.eaOfficeId)
    );
    this.offices$ = this.store.pipe(select(getOffices));
    this.employee$ = this.store.pipe(select(getEmployee));
    this.employments$ = this.store.pipe(
      select(getEmployments),
      map((list) => list.map((e) => e.eaOfficeId))
    );

    this.startEmployeeStream();
    this.startEaOfficeIdStream();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  startEmployeeStream(): void {
    this.employee$
      .pipe(
        map((emp) => emp?.eaEmployeeId),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((eaEmployeeId: string) =>
        this.store.dispatch(eoActions.getEmploymentsRequest({ eaEmployeeId }))
      );
  }

  startEaOfficeIdStream(): void {
    this.eaOfficeId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOfficeId) => {
        if (eaOfficeId) {
          this.officeControl.disable();
          this.officeControl.setValue(eaOfficeId);
        } else {
          this.officeControl.enable();
        }
      });
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onClose(): void {
    const { eaOfficeId } = this.route.snapshot.params;
    const routeArray = eaOfficeId
      ? [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_EMPLOYEES_OFFICE_SEARCH_URL(eaOfficeId),
            },
          },
        ]
      : ["/crm", { outlets: { sidebar: SIDEBAR_EMPLOYEES_SEARCH_URL } }];
    this.store.dispatch(RouterActions.go({ path: routeArray }));
  }

  isDisabled$(eaOfficeId: string): Observable<boolean> {
    return this.employments$.pipe(
      first(),
      map((list) => list.some((e) => eaOfficeId === e))
    );
  }

  onSubmit(form: FormGroup): void {
    if (form.valid && this.officeControl.value) {
      this.store.dispatch(
        eoActions.createEmploymentRequest({
          employment: new Employment({
            eaEmployeeId: this.route.snapshot.params.eaEmployeeId,
            eaOfficeId: this.officeControl.value,
            ...form.value,
          }),
        })
      );
    } else {
      markAllAsTouched(form);
      markAllAsTouched(this.officeControl);
    }
  }
}
