<div class="list-header">
  <h3>
    {{ type | translate }}
    <span class="hits">
      ({{ hits | separateThousands }} {{ "hits" | translate }})
    </span>
  </h3>
  <span (click)="showAllSearchTypes()" class="text-link">
    <i class="fa fa-long-arrow-left"></i>
    {{ "back" | translate }}
  </span>
</div>
<div *ngIf="data.length > 0">
  <div class="row" *ngFor="let items of data">
    <div [ngSwitch]="type">
      <div *ngSwitchCase="'contacts'">
        <div class="col-md-3" *ngFor="let contact of items">
          <search-contact-card [contact]="contact"> </search-contact-card>
        </div>
      </div>
      <div *ngSwitchCase="'objects'">
        <div class="col-md-3" *ngFor="let object of items">
          <search-object-card
            [object]="object"
            [showExternalProviderLink]="showExternalProviderLink"
            [externalProviderName]="externalProviderName"
            [externalProviderUrl]="externalProviderUrl"
            [externalProviderLinks]="externalProviderLinks"
            [externalProviderLinksLoading]="externalProviderLinksLoading"
            (getExternalProviderLinks)="handleGetProviderLinks($event)"
          >
          </search-object-card>
        </div>
      </div>
      <div *ngSwitchCase="'leads'">
        <div class="col-md-3" *ngFor="let lead of items">
          <search-lead-card [lead]="lead"> </search-lead-card>
        </div>
      </div>
      <div *ngSwitchCase="'employees'">
        <div class="col-md-3" *ngFor="let employee of items">
          <search-employee-card [employee]="employee"> </search-employee-card>
        </div>
      </div>
      <div *ngSwitchDefault>{{ "general_error" | translate }}</div>
    </div>
  </div>
</div>
