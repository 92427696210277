import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import { createAction, props } from "@ngrx/store";

export const fetchStatisticsRequest = createAction(
  "[Widget Budget] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Widget Budget] FETCH_STATISTICS_SUCCESS",
  props<{ growth: GrowthStatistics }>()
);

export const fetchStatisticsFail = createAction(
  "[Widget Budget] FETCH_STATISTICS_FAIL"
);
