import {
  employeeSettingsReducer,
  EmployeeSettingsState,
} from "@app/settings/account/potential-buyers-to-zero-bidders/ngrx/potential-buyers-to-zero-bidders.reducer";
import {
  manageBanksReducer,
  ManageBanksState,
} from "@app/settings/banks/manage-banks/ngrx/manage-banks.reducer";
import {
  createInvoiceReducer,
  CreateInvoiceState,
} from "@app/settings/invoicing/components/create-invoice/ngrx/create-invoice.reducer";
import {
  invoiceDocumentationReducer,
  InvoiceDocumentationState,
} from "@app/settings/invoicing/components/invoice-documentation/ngrx/invoice-documentation.reducer";
import {
  officeCardReducer,
  OfficeCardState,
} from "@app/settings/invoicing/components/office-card/ngrx/office-card.reducer";
import {
  TypeOfFeeState,
  typesOfFeesReducer,
} from "@app/settings/invoicing/components/types-of-fees/ngrx/types-of-fees.reducer";
import {
  typesOfNonRecurringItemsReducer,
  TypesOfNonRecurringItemsState,
} from "@app/settings/invoicing/components/types-of-non-recurring-items/ngrx/types-of-non-recurring-items.reducer";
import {
  leadChannelResultOptionsReducer,
  LeadChannelResultOptionsState,
} from "@app/settings/lead-channel-result-options/ngrx/lead-channel-result-options.reducer";
import {
  leadChannelsReducer,
  LeadChannelsState,
} from "@app/settings/lead-channels/ngrx/lead-channels.reducer";
import {
  leadTemplatesReducer,
  LeadTemplatesState,
} from "@app/settings/lead-templates/ngrx/lead-templates.reducer";
import {
  messageTemplatesReducer,
  MessageTemplatesState,
} from "@app/settings/message-templates/ngrx/message-templates.reducer";
import {
  settingsObjectStatisticsProvidersReducer,
  SettingsObjectStatisticsProvidersState,
} from "@app/settings/object-statistics-providers/ngrx/object-statistics-providers.reducer";
import {
  manageOfficeManagementEmailReducer,
  ManageOfficeManagementEmailState,
} from "@app/settings/offices-employees/manage-office-management-email/ngrx/manage-office-management-email.reducer";
import {
  manageRegionsReducer,
  ManageRegionsState,
} from "@app/settings/offices-employees/manage-regions/ngrx/manage-regions.reducer";
import {
  tagsManagementReducer,
  TagsManagementState,
} from "@app/settings/tags/ngrx/tags-management.reducer";
import { combineReducers } from "@ngrx/store";
import {
  settingsAccountExchangeReducer,
  SettingsAccountExchangeState,
} from "./account/exchange/ngrx/settings-account-exchange.reducer";
import {
  externalTipsReducer,
  ExternalTipsState,
} from "./external-tips/ngrx/manage-external-tips.reducer";
import {
  manageEmploymentsReducer,
  ManageEmploymentsState,
} from "./offices-employees/manage-employments/ngrx/manage-employments.reducer";
import {
  salesTargetReducer,
  SalesTargetState,
} from "./sales-targets/ngrx/manage-sales-target.reducer";

export interface SettingsModuleState {
  manageEmployments: ManageEmploymentsState;
  manageOfficeManagementEmails: ManageOfficeManagementEmailState;
  manageRegions: ManageRegionsState;
  salesTargets: SalesTargetState;
  exchange: SettingsAccountExchangeState;
  externalTips: ExternalTipsState;
  manageBanks: ManageBanksState;
  tagsManagement: TagsManagementState;
  typesOfNonRecurringItems: TypesOfNonRecurringItemsState;
  typesOfFee: TypeOfFeeState;
  officeCard: OfficeCardState;
  createInvoice: CreateInvoiceState;
  invoiceDocumentation: InvoiceDocumentationState;
  objectStatisticsProviders: SettingsObjectStatisticsProvidersState;
  employeeSettings: EmployeeSettingsState;
  messageTemplates: MessageTemplatesState;
  leadChannels: LeadChannelsState;
  leadChannelResultOptions: LeadChannelResultOptionsState;
  leadTemplates: LeadTemplatesState;
}

const reducers = {
  manageEmployments: manageEmploymentsReducer,
  manageOfficeManagementEmails: manageOfficeManagementEmailReducer,
  manageRegions: manageRegionsReducer,
  salesTargets: salesTargetReducer,
  exchange: settingsAccountExchangeReducer,
  externalTips: externalTipsReducer,
  manageBanks: manageBanksReducer,
  tagsManagement: tagsManagementReducer,
  typesOfNonRecurringItems: typesOfNonRecurringItemsReducer,
  typesOfFee: typesOfFeesReducer,
  officeCard: officeCardReducer,
  createInvoice: createInvoiceReducer,
  invoiceDocumentation: invoiceDocumentationReducer,
  objectStatisticsProviders: settingsObjectStatisticsProvidersReducer,
  employeeSettings: employeeSettingsReducer,
  messageTemplates: messageTemplatesReducer,
  leadChannels: leadChannelsReducer,
  leadChannelResultOptions: leadChannelResultOptionsReducer,
  leadTemplates: leadTemplatesReducer,
};

export function settingsModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
