export interface ProfileProgressStepsState {
  firstName: boolean;
  familyName: boolean;
  email: boolean;
  mobile: boolean;
  address: boolean;
  residence: boolean;
}

export interface ProfileProgressStep {
  step: string;
  done: boolean;
}

export const ProfileProgressSteps = {
  FIRST_NAME: "firstName",
  FAMILY_NAME: "familyName",
  EMAIL: "email",
  MOBILE: "mobile",
  ADDRESS: "address",
  RESIDENCE: "residence",
};
