import {
  catchError,
  from as observableFrom,
  map,
  mergeMap,
  switchMap,
} from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as errorActions from "@app/core/error-handling/ngrx/error.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { Employee, Office } from "@app/models";
import * as userActions from "@app/shared/user/user.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import * as selectOfficeActions from "./select-office.actions";

@Injectable()
export class SelectOfficeEffects {
  authenticated: boolean;
  unauthorizedPath: string;

  searchEmployeesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOfficeActions.searchEmployeesRequest),
      switchMap(({ email }) =>
        this.apiService.get(
          "employees/legacy",
          { email, includeOffice: true },
          "api",
          false
        )
      ),
      map((response: any) =>
        response.employees.length > 0
          ? this.login(response.employees)
          : selectOfficeActions.searchEmployeesFail({
              message: "users_not_found",
            })
      )
    )
  );

  searchEmployeesFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOfficeActions.searchEmployeesFail),
      map(({ message }) => toastActions.danger({ message }))
    )
  );

  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOfficeActions.loginOfficeRequest),
      switchMap(({ eaOfficeId, eaEmployeeId }) =>
        this.apiService
          .post("user-data", { eaOfficeId, eaEmployeeId }, "auth")
          .pipe(
            mergeMap((data: { employee; office; token }) => {
              try {
                this.authService.setToken(data.token);
                const roles = [];
                if (this.authService.isViewingAsSomeoneElse()) {
                  const officeId = data.office.eaOfficeId;
                  const currentOffice = data.employee.offices.find(
                    (office) => office.eaOfficeId === officeId
                  );
                  roles.push(currentOffice.role);
                } else {
                  roles.push(...this.authService.getRoles());
                }

                return this.getLoginOfficeSuccessActions(
                  data.office,
                  new Employee(data.employee),
                  roles
                );
              } catch (e) {
                return [
                  userActions.clearState(),
                  errorActions.clearUserContext(),
                ];
              }
            }),
            catchError((error) =>
              observableFrom([
                toastActions.danger({ message: error.message }),
                userActions.logout(),
              ])
            )
          )
      )
    )
  );

  navigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOfficeActions.loginOfficeSuccess),
      map(() => {
        if (this.unauthorizedPath.length > 0) {
          return RouterActions.go({ path: ["/crm", "dashboard", "todo-list"] });
        } else {
          const url = this.localStorageService.fetchUserPreference(
            "redirect_after_login_url"
          );
          if (url && url.length > 0) {
            this.localStorageService.saveUserPreference(
              "redirect_after_login_url",
              ""
            );
            return RouterActions.goByUrl({ url });
          } else {
            return RouterActions.go({
              path: ["/crm", "dashboard", "todo-list"],
            });
          }
        }
      })
    )
  );

  navigateToUnauthorizedPath$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.navigateToUnauthorizedPath),
        map(() => this.router.navigate(["/crm"]))
      ),
    { dispatch: false }
  );

  clearUnauthorizedPath$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.navigateToUnauthorizedPath),
      map(() => userActions.clearUnauthorizedPath())
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: ApiService,
    private authService: AuthService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.store
      .select((state) => state.user)
      .subscribe((user) => {
        this.authenticated = user.authenticated;
        this.unauthorizedPath = user.unauthorizedPath;
      });
  }

  private login(employees: Employee[]) {
    if (
      this.hasOneEmployee(employees) &&
      (this.hasOneOffice(employees) ||
        this.getMainOfficeId(employees[0]).length > 0) &&
      !this.authenticated
    ) {
      return selectOfficeActions.loginOfficeRequest({
        eaEmployeeId: employees[0].eaEmployeeId,
        eaOfficeId: this.hasOneOffice(employees)
          ? employees[0].offices[0].eaOfficeId
          : this.getMainOfficeId(employees[0]),
      });
    }
    return selectOfficeActions.searchEmployeesSuccess({ employees });
  }

  private hasOneOffice(employees: Employee[]) {
    return employees.length !== 0 && employees[0].offices.length === 1;
  }

  private hasOneEmployee(employees: Employee[]) {
    return employees.length === 1;
  }

  private getMainOfficeId(employee): string {
    let mainOfficeId = "";
    employee.offices.forEach((office) => {
      if (mainOfficeId.length === 0 && office.mainOffice) {
        mainOfficeId = office.eaOfficeId;
      } else if (mainOfficeId.length > 0 && office.mainOffice) {
        mainOfficeId = "";
      }
    });
    return mainOfficeId;
  }

  private getLoginOfficeSuccessActions(
    office: Office,
    employee: Employee,
    roles: string[]
  ): Action[] {
    return [
      selectOfficeActions.loginOfficeSuccess({ employee, office, roles }),
      errorActions.setUserContext({
        user: {
          id: employee.eaEmployeeId,
          email: employee.employeeEmail,
          username: employee.employeeFullName,
        },
      }),
      errorActions.setExtraContext({
        context: {
          roles,
          eaOfficeId: office.eaOfficeId,
          officeName: office.name,
        },
      }),
    ];
  }
}
