import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { reducers } from "@app/integrations/bidding/ngrx/reducers";
import { SendPotentialBuyersEffects } from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.effects";
import { OverlayLoaderModule } from "@app/shared-features/overlay-loader/overlay-loader.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SendPotentialBuyersComponent } from "./components/send-potential-buyers/send-potential-buyers.component";
import { AddBidComponent } from "./containers/add-bid/add-bid.component";
import { BiddingListComponent } from "./containers/bidding-list/bidding-list.component";
import { BiddingEffects } from "./ngrx/bidding/bidding.effects";

@NgModule({
  imports: [
    StoreModule.forFeature("biddingIntegration", reducers),
    EffectsModule.forFeature([BiddingEffects, SendPotentialBuyersEffects]),
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    PopoverModule,
    AlertModule,
    ProgressbarModule,
    MomentModule,
    TranslateModule,
    OverlayLoaderModule,
    FormComponentsModule,
    UiComponentsModule,
    UtilModule,
    SkeletonScreenModule,
    TooltipModule,
  ],
  declarations: [
    AddBidComponent,
    BiddingListComponent,
    SendPotentialBuyersComponent,
  ],
  exports: [
    AddBidComponent,
    BiddingListComponent,
    SendPotentialBuyersComponent,
  ],
})
export class BiddingModule {}
