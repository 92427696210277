import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SubPageConfig } from "@app/shared/config/models/sub-page-config";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, Observable } from "rxjs";
import { SubPage } from "../shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { getSubPages } from "../shared/config/config.reducer";
import { SHOWINGS_CRM_MODULE } from "../shared/utils/crm-modules";
import * as icons from "../shared/utils/icons";

const SUB_PAGE_OVERVIEW = "overview";
const SUB_PAGE_POTENTIAL_BUYERS = "potential-buyers";
const SUB_PAGE_SHOWINGS = "showings";
const SUB_PAGE_MATCHINGS = "matchings";
const SUB_PAGE_FOLLOWERS = "followers";
const SUB_PAGE_STATISTICS = "statistics";

@Injectable()
export class ShowingsSubPageService {
  private _subPages$ = new BehaviorSubject([]);

  get subPages$(): Observable<SubPage[]> {
    return this._subPages$.asObservable();
  }

  constructor(private store: Store<AppState>) {}

  loadSubPages(eaOid: string): void {
    this.store
      .pipe(
        select(getSubPages(SHOWINGS_CRM_MODULE)),
        first(),
        map((subPages: SubPageConfig[]) => subPages.filter((p) => p.enabled)),
        map((subPages: SubPageConfig[]) =>
          subPages.map((p) => this.getSubPage(p.type, eaOid))
        )
      )
      .subscribe((pages) => this._subPages$.next(pages));
  }

  getSubPage(type: string, eaOid: string): SubPage {
    switch (type) {
      case SUB_PAGE_OVERVIEW: {
        return {
          label: "overview",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_OVERVIEW),
          icon: icons.ICON_OBJECT_OVERVIEW,
        };
      }
      case SUB_PAGE_POTENTIAL_BUYERS: {
        return {
          label: "potential_buyers",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_POTENTIAL_BUYERS),
          icon: icons.ICON_POTENTIAL_BUYERS,
        };
      }
      case SUB_PAGE_SHOWINGS: {
        return {
          label: "showings",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_SHOWINGS),
          icon: icons.ICON_SHOWINGS_PAGE,
        };
      }
      case SUB_PAGE_MATCHINGS: {
        return {
          label: "matchings",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_MATCHINGS),
          icon: icons.ICON_MATCHINGS,
        };
      }
      case SUB_PAGE_FOLLOWERS: {
        return {
          label: "followers",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_FOLLOWERS),
          icon: icons.ICON_FOLLOWERS,
        };
      }
      case SUB_PAGE_STATISTICS: {
        return {
          label: "statistics",
          routerLink: this.buildRoute(eaOid, SUB_PAGE_STATISTICS),
          icon: icons.ICON_STATISTICS,
        };
      }
    }
    return {
      label: "",
      routerLink: ["/crm"],
      icon: "fa-question",
    };
  }

  private buildRoute(eaOid: string, subRoute: string): any[] {
    return ["/crm", SHOWINGS_CRM_MODULE, eaOid, subRoute];
  }
}
