<div class="q-modal">
  <div mat-dialog-title class="q-modal-header">
    <h3 *ngIf="!!hasHeader" class="q-modal-title">
      <ng-content select="[slot=header]"></ng-content>
    </h3>
    <button *ngIf="!!hasHeaderCloseButton" class="q-modal-close" mat-dialog-close>
      <app-icon name="times" size="x-large"></app-icon>
    </button>
  </div>
  <div class="modal-content-container">
    <mat-dialog-content class="q-modal-content">
      <ng-content select="[slot=body]"></ng-content>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="!!hasActionBar" class="q-modal-actions">
      <ng-content select="[slot=actions]"></ng-content>
    </mat-dialog-actions>
  </div>
</div>
