export interface LanguageOption {
  key: string;
  country: string;
  title: string;
}

export const languageOptions: LanguageOption[] = [
  {
    key: "sv",
    country: "se",
    title: "swedish",
  },
  {
    key: "en",
    country: "us",
    title: "english",
  },
  {
    key: "fi",
    country: "fi",
    title: "finnish",
  },
  {
    key: "nb",
    country: "no",
    title: "norwegian",
  },
  {
    key: "da",
    country: "dk",
    title: "danish",
  },
  {
    key: "et",
    country: "ee",
    title: "estonian",
  },
];

export function findLanguage(keyword: string) {
  if (!keyword || keyword.trim().length === 0) {
    return null;
  }

  const key = keyword.trim().toLowerCase();
  return languageOptions.find(
    (language) => language.key === key || language.country === key
  );
}
