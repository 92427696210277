<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="headerLabel"></sidebar-header>
  <div class="scroll-view">
    <ng-container [ngSwitch]="(contact$ | async)?.contactType">
      <app-company-form
        *ngSwitchCase="'company'"
        [editMode]="true"
        [contact]="contact$ | async"
        (onSubmit)="handleSubmit($event)"
        #companyForm
      ></app-company-form>
      <app-estate-form
        *ngSwitchCase="'estate'"
        [editMode]="true"
        [contact]="contact$ | async"
        (onSubmit)="handleSubmit($event)"
        #estateForm
      ></app-estate-form>
      <contact-form
        #contactForm
        *ngSwitchCase="'person'"
        [enableSource]="false"
        [enableDuplicateValidation]="false"
        [editMode]="true"
        [contact]="contact$ | async"
        (onSubmit)="handleSubmit($event)"
        (onQuedroContactSelected)="onQuedroContactSelected($event)"
      ></contact-form>
      <contact-form
        #contactForm
        *ngSwitchDefault
        [enableSource]="false"
        [enableDuplicateValidation]="false"
        [editMode]="true"
        [contact]="contact$ | async"
        (onSubmit)="handleSubmit($event)"
        (onQuedroContactSelected)="onQuedroContactSelected($event)"
      ></contact-form>
    </ng-container>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button buttonType="save" label="save" (click)="submit()">
    </app-q-button>
  </div>
</div>
