<div class="widget-header">
  <h3>
    <app-icon size="large" name="align-left"></app-icon>&nbsp;<span
      >{{ "property_reporting_widget_title" | translate }}
      <app-tooltip-icon
        [translationKey]="'total_visitors_to_page_details'"
      ></app-tooltip-icon
    ></span>
  </h3>
  <a
    *ngIf="orderUrl$ | async as url"
    [href]="url"
    target="_blank"
    id="adfenix-property-reporting"
    class="adfenix-property-reporting"
  >
    <app-q-circle-btn
      icon="cart-circle-plus"
      tooltipText="order_ads"
      class="adfenix-property-reporting-btn"
    ></app-q-circle-btn>
  </a>
</div>

<div
  class="widget-container"
  [class.loading-pulse]="objectMarketingService.loading$ | async"
>
  <div *ngIf="objectMarketingService.hasError$ | async">
    <span style="color: var(--chip-5-text)">{{
      "failed_fetching_adfenix_marketing_statistics" | translate
    }}</span>
  </div>
  <span *ngIf="objectMarketingService.loading$ | async">{{
    "loading" | translate
  }}</span>

  <ng-container
    *ngIf="
      !(objectMarketingService.loading$ | async) &&
      !(objectMarketingService.hasError$ | async)
    "
  >
    <div class="active-campaigns__container">
      <span *ngIf="totalValue$ | async as total">
        {{ "total_visitors_to_page" | translate }}: {{ total | qNumber }}
      </span>
      <p
        class="active-campaigns"
        [class.no-active-campaigns]="(activeCampaigns$ | async) === 0"
      >
        <span *ngIf="activeCampaigns$ | async as total">
          {{ "active_campaigns" | translate }}: {{ total | qNumber }}
        </span>
        <a *ngIf="orderUrl$ | async as url" [href]="url" target="_blank">
          <span *ngIf="(activeCampaigns$ | async) === 0"
            >{{ "no_active_campaigns" | translate }}
            <span class="buy-icon"
              ><app-icon [name]="'cart-circle-plus'"></app-icon></span
          ></span>
        </a>
      </p>
    </div>
    <div
      *ngIf="value$ | async as marketing; else noMarketing"
      class="widget-container__chart"
    >
      <ngx-charts-pie-chart
        *ngIf="!!marketing"
        [view]="view$ | async"
        [scheme]="colorScheme"
        [results]="value$ | async"
        [legend]="false"
        [animations]="true"
        [labels]="true"
        [doughnut]="true"
        maxLabelLength="100"
      >
      </ngx-charts-pie-chart>
    </div>
    <ng-template #noMarketing>
      <span *ngIf="!(objectMarketingService.loading$ | async)">
        <a *ngIf="orderUrl$ | async as url" [href]="url" target="_blank">
          {{ "no_marketing_for_this_property" | translate }}
        </a>
      </span>
    </ng-template>
  </ng-container>
</div>
