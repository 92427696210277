import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { EmployeeDTO, Office } from "@app/models";
import { getOfficeNamePrefix } from "@app/shared/config/config.reducer";
import { OfficeEmployeeMultiSelectDropdownService } from "@app/shared/modules/form-components/office-employee-multi-select-dropdown/office-employee-multi-select-dropdown.service";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { getEaEmployeeId, hasRole } from "@app/shared/user";
import { ROLE_ADMIN, ROLE_BROKER, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";

@Component({
  selector: "app-new-office-employee-multiselect-dropdown",
  templateUrl: "./new-office-employee-multiselect-dropdown.component.html",
  styleUrls: ["./new-office-employee-multiselect-dropdown.component.scss"],
  providers: [OfficeEmployeeMultiSelectDropdownService],
})
export class NewOfficeEmployeeMultiselectDropdownComponent
  implements OnInit, OnChanges, OnDestroy
{
  // Liberal: Everyone can se everything
  // Medium: Brokers and Managers may view all info withing the offices that the user is connected to
  // Restrictive: Brokers may only view personal info, managers may view office.

  @Input() availabilityMode: "liberal" | "medium" | "restrictive" = "medium";
  @Input() parentFormGroup: FormGroup;
  @Input() eaOfficeIdFormControlName = "eaOfficeId";
  @Input() eaEmployeeIdFormControlName = "eaEmployeeId";
  @Input() includeInactiveEmployees = false;
  @Input() setDefaults = false;

  @Output() defaultsSet: EventEmitter<void> = new EventEmitter<void>();

  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  isBroker$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  officePrefix$: Observable<string>;
  eaEmployeeId$: Observable<string>;
  offices$: Observable<Office[]>;
  employees$: Observable<EmployeeDTO[]>;
  employeeDropdownState$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "DISABLED"
  );

  unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private officeEmployeeService: OfficeEmployeeMultiSelectDropdownService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.handleOfficeChanges();
    this.handleEmployeeChanges();
    this.handleEmployeeDropdownState();
    if (this.setDefaults) {
      this.setDefaultValues();
    }
    this.fetchOffices();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes) {
    if (changes.availabilityMode) {
    }
  }

  private fetchOffices() {
    combineLatest([this.isAdmin$, this.isManager$])
      .pipe(first())
      .subscribe(([isAdmin, isManager]) => {
        if (this.availabilityMode === "liberal" || isAdmin) {
          this.officeEmployeeService.fetchOffices();
        } else if (
          this.availabilityMode === "medium" ||
          (!isManager && !isAdmin)
        ) {
          this.officeEmployeeService.fetchOfficesConnectedToEmployee(false);
        } else {
          this.officeEmployeeService.fetchOfficesConnectedToEmployee(true);
        }
      });
  }

  private mapStateToProps() {
    this.offices$ = this.officeEmployeeService.offices$;
    this.employees$ = this.officeEmployeeService.employees$;
    this.isAdmin$ = this.store.pipe(select(hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(hasRole(ROLE_MANAGER)));
    this.isBroker$ = this.store.pipe(select(hasRole(ROLE_BROKER)));
    this.officePrefix$ = this.store.pipe(select(getOfficeNamePrefix));
    this.isMobile$ = this.store.pipe(select(isMobile));
    this.eaEmployeeId$ = this.store.pipe(select(getEaEmployeeId));
  }

  private handleOfficeChanges() {
    // Fetch new employees on office change
    this.parentFormGroup
      .get(this.eaOfficeIdFormControlName)
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => this.fetchEmployees());
  }

  fetchEmployees() {
    const eaOfficeIds = this.parentFormGroup.get(
      this.eaOfficeIdFormControlName
    ).value;
    combineLatest([this.isAdmin$, this.isManager$])
      .pipe(first())
      .subscribe(([isAdmin, isManager]) => {
        if (!isAdmin && !isManager && this.availabilityMode === "restrictive") {
          this.officeEmployeeService.fetchUserEmployee();
        } else {
          if (eaOfficeIds.length === 0) {
            this.offices$.pipe(first()).subscribe((offices) => {
              this.officeEmployeeService.fetchEmployeesFromOffices({
                eaOfficeId: offices.map((office) => office.eaOfficeId),
                includeInactiveEmployees: this.includeInactiveEmployees,
              });
            });
          } else {
            this.officeEmployeeService.fetchEmployeesFromOffices({
              eaOfficeId: eaOfficeIds,
              includeInactiveEmployees: this.includeInactiveEmployees,
            });
          }
        }
      });
  }

  private handleEmployeeChanges() {
    // Update form on employee changes
    this.employees$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter(
          () =>
            this.parentFormGroup.get(this.eaEmployeeIdFormControlName).value
              .length > 0
        ),
        map((employees) => {
          const employeesLeft = this.parentFormGroup
            .get(this.eaEmployeeIdFormControlName)
            .value.filter(
              (eaEmployeeId) =>
                !!employees.find(
                  (employee) => employee.eaEmployeeId === eaEmployeeId
                )
            );
          return employeesLeft;
        })
      )
      .subscribe((newValue: string[]) => {
        this.parentFormGroup
          .get(this.eaEmployeeIdFormControlName)
          .setValue(newValue);
      });
  }

  private setDefaultValues() {
    this.offices$
      .pipe(
        filter((offices) => offices.length > 0),
        first(),
        withLatestFrom(this.isAdmin$, this.isManager$, this.isBroker$)
      )
      .subscribe(([offices, isAdmin, isManager]) => {
        //  Select default office/offices based on role and availabilityMode and update form without emitting change
        this.parentFormGroup.get(this.eaOfficeIdFormControlName).setValue(
          offices.map((office) => office.eaOfficeId),
          { emitEvent: false }
        );
        //  Fetch employees
        this.fetchEmployees();
        this.employees$
          .pipe(
            filter((offices) => offices.length > 0),
            first(),
            withLatestFrom(this.eaEmployeeId$)
          )
          .subscribe(([, eaEmployeeId]) => {
            if (
              !isAdmin &&
              !isManager &&
              this.availabilityMode === "restrictive"
            ) {
              this.parentFormGroup
                .get(this.eaEmployeeIdFormControlName)
                .setValue([eaEmployeeId], { emitEvent: false });
            }
            this.defaultsSet.emit();
          });
      });
  }

  private handleEmployeeDropdownState() {
    this.employees$
      .pipe(
        withLatestFrom(this.isAdmin$, this.isManager$),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(([employees, isAdmin, isManager]) => {
        if (
          !employees ||
          employees.length === 0 ||
          (!isAdmin && !isManager && this.availabilityMode === "restrictive")
        ) {
          this.employeeDropdownState$.next("DISABLED");
        } else {
          this.employeeDropdownState$.next("READY");
        }
      });
  }
}
