import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as searchActions from "@app/core/components/search/ngrx/search.actions";
import { QObject } from "@app/models";
import { ExternalProviderLink } from "@app/models/external-provider-link";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import {
  ADVANCED_OBJECTS_LIST,
  CREATE_PRIVATE_SHOWING,
  OBJECT_OVERVIEW,
  OPEN_IN_ERP,
} from "@app/shared/config/utils/features";
import { UrlService } from "@app/core/services/url/url.service";
import { AdvancedObjectsListFeature } from "@app/shared/config/models/advanced-objects-list-feature";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { filter, first, map, switchMap, tap } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import {
  openExternalLink,
  openObjectMetaDataLink,
} from "@app/shared/utils/external-link-utils";
import {
  getExternalObjectMetadata,
  hasIntegration,
} from "@app/integrations/ngrx/integrations.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { ExternalObjectMetadata } from "@app/integrations/models/ExternalObjectMetadata";
import { fetchExternalObjectMetadataRequest } from "@app/integrations/ngrx/integrations.actions";
import { DisplayValue } from "@app/shared/config/models/display-value";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "search-object-card",
  templateUrl: "./object-card.component.html",
  styleUrls: ["./object-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchObjectCardComponent implements OnInit {
  @Input() object: QObject;
  @Input() externalProviderUrl: string;
  @Input() externalProviderName: string;
  @Input() showExternalProviderLink: boolean;
  @Input() externalProviderLinks: ExternalProviderLink[];
  @Input() externalProviderLinksLoading: boolean;
  @Input() eaEmployeeId: string;
  @Output() getExternalProviderLinks: EventEmitter<string> =
    new EventEmitter<string>();

  ssoifyLoading$: Observable<boolean>;
  clickedPresentableLink: ExternalPresentableLink;
  isMobile$: Observable<boolean>;
  objectOverViewIsEnabled$: Observable<boolean>;
  advancedObjectsListFeature$: Observable<AdvancedObjectsListFeature>;
  dateTypeIds: DisplayValue[];
  showCreatePrivateShowing$: Observable<boolean>;
  hasObjectLinks$: Observable<boolean>;
  externalObjectMetadata$: Observable<ExternalObjectMetadata>;
  canOpenInErp$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private urlService: UrlService,
    private modalService: QModalService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  navigate(eaOid) {
    this.router.navigate([
      "/crm",
      "showings",
      eaOid,
      { outlets: { sidebar: null } },
    ]);
    this.store.dispatch(searchActions.hide());
  }

  getObjectUrl(url: string, guid: string): string {
    return url.replace("{GUID}", guid);
  }

  openExternalLink(e: Event, link: ExternalPresentableLink): void {
    this.clickedPresentableLink = link;
    e.stopPropagation();
    openExternalLink(link, this.store);
  }

  openInNewTab(e: Event, eaOid): void {
    e.stopPropagation();
    const commands = ["/crm", "showings", eaOid];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  getDateFormatted(date: string): string {
    return moment(date, API_DATE_FORMAT).format("YYYY-MM-DD");
  }

  shouldShowDate(typeId): boolean {
    return !!this.dateTypeIds.find((id) => id.value === typeId);
  }

  getDateTitle(typeId) {
    return this.dateTypeIds.find((id) => id.value === typeId).display;
  }

  preventDefault(e: MouseEvent, contactId: string) {
    e.stopPropagation();
    this.store.dispatch(go({ path: ["/crm", "contacts", contactId] }));
    this.store.dispatch(searchActions.hide());
  }

  createShowing(e: MouseEvent, eaOid: string) {
    e.stopPropagation();
    const data: ShowingModalData = {
      isEditMode: false,
      eaOid: eaOid,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };

    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }

  openMetaDataLink(): void {
    combineLatest([this.externalObjectMetadata$])
      .pipe(
        filter(([metaData]) => !!metaData),
        first()
      )
      .subscribe(([metaData]) => openObjectMetaDataLink(metaData));
  }

  private mapStateToProps() {
    this.ssoifyLoading$ = this.store.pipe(select(fromShared.getSsoifyLoading));
    this.isMobile$ = this.store.pipe(select(isMobile));
    this.objectOverViewIsEnabled$ = this.store.pipe(
      select(getFeature(OBJECT_OVERVIEW)),
      map((feature) => feature.enabled)
    );
    this.advancedObjectsListFeature$ = this.store.pipe(
      select(getFeature(ADVANCED_OBJECTS_LIST))
    );
    this.advancedObjectsListFeature$.subscribe(
      (feature) => (this.dateTypeIds = feature.filterOptions.dateTypeIds)
    );
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );
    this.hasObjectLinks$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.ObjectLinks))
    );
    this.externalObjectMetadata$ = this.hasObjectLinks$.pipe(
      filter((hasObjectLinks) => hasObjectLinks),
      tap(() => {
        this.store.dispatch(
          fetchExternalObjectMetadataRequest({ eaOid: this.object.eaOid })
        );
      }),
      switchMap(() => {
        return this.store.pipe(
          select(getExternalObjectMetadata()),
          filter((metaData) => !!metaData)
        );
      })
    );
    this.canOpenInErp$ = this.store.pipe(
      select(fromConfig.getFeature(OPEN_IN_ERP)),
      map((feature) => feature.enabled)
    );
  }

  updateImageOnError(event: any) {
    event.target.onerror = null;
    event.target.src = "/assets/img/img_error_placeholder.png";
  }
}
