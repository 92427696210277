import { Pipe, PipeTransform } from "@angular/core";
import { QObject } from "@app/models";
import { ShowingObject } from "@app/showings/models";

@Pipe({
  name: "propertyTypeFromObject",
})
export class PropertyTypeFromObjectPipe implements PipeTransform {
  transform(
    value: QObject | ShowingObject
  ): "project" | "condominium" | "rental" {
    if (value.objType === "27" || value.objType === 27) {
      return "project";
    } else if (!!value.rentMonth) {
      return "rental";
    }
    return "condominium";
  }
}
