import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Employee } from "@app/models";
import * as userActions from "@app/shared/user/user.actions";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-token-bouncer",
  templateUrl: "./token-bouncer.component.html",
  styleUrls: ["./token-bouncer.component.scss"],
})
export class TokenBouncerComponent implements OnInit {
  employees$: Observable<Employee[]>;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.activatedRoute.queryParams.subscribe((token: Params) => {
      this.store.dispatch(userActions.validateOauthTokenRequest({ token }));
    });
  }

  mapStateToProps(): void {
    this.employees$ = this.store.select(
      (state) => state.login.selectOffice.employees
    );
  }
}
