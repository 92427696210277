import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { AttemptTypes, Task } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable, of as observableOf } from "rxjs";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "task-list-item",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./task-list-item.component.scss",
  ],
  template: `
    <li class="list__item" (click)="onTaskClick()">
      <div class="hidden-sm hidden-xs" [style.color]="color">
        <app-icon [name]="icon" size="medium"></app-icon>
      </div>
      <span class="task-type-name">{{ task.eaTaskTypeName }}</span>
      <span class="contact_name">
        <app-contact-name
          *ngIf="task.contactFirstName"
          [contactId]="task.contactId"
          [contactType]="task.contactType"
        >
          {{ task.contactFirstName }} {{ task.contactFamilyName }}&nbsp;
        </app-contact-name>
      </span>
      <span>{{ task.title }}</span>
      <span class="date-item date-item-created">
        <span class="task-date" translate>created</span>&nbsp;
        <br class="hidden-xs hidden-sm" />
        <span>{{ task.insertedDate | momentify }}</span>
      </span>
      <span>
        <span
          *ngIf="hasAttempt(task)"
          tooltip="{{
            'latest_attempt_X'
              | translate : { date: task.latestAttemptInsertedDate }
          }}"
          container="body"
          class="attempt"
        >
          <i class="fa fa-phone hidden-md hidden-lg"></i>
          <i class="fa fa-phone fa-2x hidden-xs hidden-sm"></i>
          <span class="date">{{
            formatAttemptDate(task.latestAttemptInsertedDate) | async
          }}</span>
        </span>
        <span
          *ngIf="isOfficeManagerExclusive(task) && !hasAttempt(task)"
          tooltip="{{ 'manager_exclusive' | translate }}"
          container="body"
          class="manager-exclusive"
        >
          <i class="icon-eye"></i>
          <span class="date">{{
            timeLeft(task.officeManagerExclusiveEndDate)
          }}</span>
        </span>
      </span>
      <spinner
        class="item-loading"
        [size]="'1.5rem'"
        [style.display]="!loading ? 'none' : 'inline-block'"
      ></spinner>
    </li>
  `,
})
export class TaskListItemComponent {
  @Input() task: Task;
  @Input() icon: string;
  @Input() color: string;
  @Input() selected = false;
  @Input() loading = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<Task>();

  constructor(private translate: TranslateService) {}

  onTaskClick(): void {
    this.onClick.emit(this.task);
  }

  hasAttempt(task: Task): boolean {
    return (
      task.latestAttemptType === AttemptTypes.CALL &&
      !!task.latestAttemptInsertedDate
    );
  }

  timeLeft(endTime: string): string {
    const now = moment();
    const end = moment(moment(endTime));
    const duration = moment.duration(end.diff(now));

    if (duration.asDays() > 30) {
      return "30d +";
    } else if (duration.asDays() >= 1 && duration.asDays() <= 30) {
      return `${Math.round(duration.asDays())}d`;
    } else if (duration.asHours() >= 1) {
      return `${Math.round(duration.asHours())}h`;
    } else {
      return `${Math.round(duration.asMinutes())}m`;
    }
  }

  isOfficeManagerExclusive(task: Task): boolean {
    const { officeManagerExclusiveEndDate: endDate } = task;
    return endDate && moment().isBefore(moment(endDate));
  }

  formatAttemptDate(date: string): Observable<string> {
    const today = moment().startOf("day");
    const yesterday = moment().startOf("day").subtract(1, "days");
    const oneWeekAgo = moment().startOf("day").subtract(1, "week");
    const attemptDate = moment(date, "YYYY-MM-DD HH:mm");

    if (attemptDate.isSameOrAfter(today)) {
      return this.translate.get("today");
    } else if (attemptDate.isSameOrAfter(yesterday)) {
      return this.translate.get("yesterday");
    } else if (attemptDate.isSameOrAfter(oneWeekAgo)) {
      return observableOf(attemptDate.format("dddd"));
    } else {
      return observableOf(attemptDate.format("DD/M"));
    }
  }
}
