import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { QObject } from "@app/models";
import { addBackLink } from "@app/contacts/contact.actions";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { OBJECT_OVERVIEW } from "@app/shared/config/utils/features";
import { getObjectStatusColor } from "@app/shared/utils/object-utils";
import { fetchLatestObjectsRequest } from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.actions";
import * as fromLatestObjectForSale from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-latest-objects-for-sale-widget",
  templateUrl: "./latest-objects-for-sale-widget.component.html",
  styleUrls: ["./latest-objects-for-sale-widget.component.scss"],
})
export class LatestObjectsForSaleWidgetComponent implements OnInit, OnChanges {
  @Input() eaOfficeId: string;

  objects$: Observable<QObject[]>;
  loading$: Observable<boolean>;
  navigationEndpoint$: Observable<"potential-buyers" | "overview">;

  getObjectStatusColor = getObjectStatusColor;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOfficeId) {
      this.fetchObjects();
    }
  }

  private fetchObjects() {
    this.store.dispatch(
      fetchLatestObjectsRequest({ eaOfficeId: this.eaOfficeId })
    );
  }

  private mapStateToProps() {
    this.objects$ = this.store.pipe(
      select(fromLatestObjectForSale.getLatestObjectsForSale)
    );
    this.loading$ = this.store.pipe(select(fromLatestObjectForSale.getLoading));
    this.navigationEndpoint$ = this.store.pipe(
      select(getFeature(OBJECT_OVERVIEW)),
      map((feature: Feature) =>
        !!feature.enabled ? "overview" : "potential-buyers"
      )
    );
  }

  handleObjectClick() {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
  }
}
