<section class="input-group-button">
  <input
    #input
    type="text"
    [placeholder]="placeholder | translate"
    (input)="onChange($event)"
    (keyup.enter)="onClick.emit(input.value)"
  />
  <button
    type="button"
    class="btn btn-{{ buttonType }}"
    (click)="onClick.emit(input.value)"
  >
    {{ buttonText | translate }}
  </button>
</section>
