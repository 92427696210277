import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";

@Component({
  selector: "app-q-tab-bar",
  templateUrl: "./q-tab-bar.component.html",
  styleUrls: ["./q-tab-bar.component.scss"],
})
export class QTabBarComponent {
  @Input() tabs: Select;
  @Input() activeTab: string;

  @Output() changeTab = new EventEmitter<string>();

  onClick(value) {
    this.changeTab.emit(value);
  }
}
