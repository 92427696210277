import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { Store } from "@ngrx/store";

@Injectable()
export class NgrxUtilService {
  constructor(private appStore: Store<AppState>) {}

  additionalCommands(toastMessage: string) {
    this.appStore.dispatch(toastActions.success({ message: toastMessage }));
  }
}
