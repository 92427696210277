import { Component, Input, ViewChild } from "@angular/core";
import { Survey } from "@app/models/survey";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "app-surveys-selector",
  templateUrl: "./surveys-modal.component.html",
  styleUrls: ["./surveys-modal.component.scss"],
})
export class SurveysModalComponent {
  @ViewChild("modal", { static: false })
  modal: ModalDirective;

  @Input() surveys: Survey[];

  handleSurveyClicked(url: string): void {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
