import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import { Action, createAction, props } from "@ngrx/store";

export const fetchShowingAttendanceRequest = createAction(
  "[PotentialBuyer] FETCH_SHOWING_ATTENDANCE_REQUEST",
  props<{ eaOid: string; contactId: string }>()
);

export const fetchShowingAttendanceSuccess = createAction(
  "[PotentialBuyer] FETCH_SHOWING_ATTENDANCE_SUCCESS",
  props<{ contactsShowingAttendance: ContactShowingAttendance[] }>()
);

export const fetchShowingAttendanceFailure = createAction(
  "[PotentialBuyer] FETCH_SHOWING_ATTENDANCE_FAILURE"
);

export const updateShowingAttendanceOptimistic = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_OPTIMISTIC",
  props<{ contactShowingAttendance: ContactShowingAttendance }>()
);

export const updateShowingAttendanceRequest = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_REQUEST",
  props<{
    contactId: string;
    body: {
      showingId: string;
      registrationStatus: string;
      eaShowingSlotId?: string;
    };
    undoAction: Action;
    origin: string;
  }>()
);

export const updateShowingAttendanceSuccess = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_SUCCESS"
);

export const updateShowingAttendanceFailure = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_FAILURE"
);

export const updateShowingAttendanceBatchRequest = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_BATCH_REQUEST",
  props<{
    contactIds: string[];
    body: {
      showingId?: string;
      registrationStatus?: string;
      eaShowingSlotId?: string;
    };
    undoAction: Action;
  }>()
);

export const updateShowingAttendanceBatchSuccess = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_BATCH_SUCCESS"
);

export const updateShowingAttendanceBatchFailure = createAction(
  "[PotentialBuyer] UPDATE_SHOWING_ATTENDANCE_BATCH_FAILURE"
);

export const deleteShowingAttendanceRequest = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_REQUEST",
  props<{
    contactId: string;
    showingId: string;
    eaShowingSlotId?: string;
  }>()
);

export const deleteShowingAttendanceSuccess = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_SUCCESS"
);

export const deleteShowingAttendanceFailure = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_FAILURE"
);

export const deleteShowingAttendanceBatchRequest = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_BATCH_REQUEST",
  props<{
    contactIds: string[];
    showingId: string;
    eaShowingSlotId?: string;
  }>()
);

export const deleteShowingAttendanceBatchSuccess = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_BATCH_SUCCESS"
);

export const deleteShowingAttendanceBatchFailure = createAction(
  "[PotentialBuyer] DELETE_SHOWING_ATTENDANCE_BATCH_FAILURE"
);

export const reset = createAction("[PotentialBuyer] RESET_ATTENDANCE");
