<table class="table table--striped">
  <thead>
    <tr>
      <th translate>broker</th>
      <th translate>this_year</th>
      <th translate>last_year</th>
      <th translate>growth</th>
      <th translate>sales_target</th>
      <th translate>sales_target_accomplished</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let employee of employees$ | async">
      <td dataLabel="broker">
        <a (click)="showEmployeeDetail(employee?.id, $event)">
          {{ employee.name }}
        </a>
      </td>
      <td dataLabel="this_year">
        {{
          employee.currentData ? (employee.currentData | separateThousands) : 0
        }}
      </td>
      <td dataLabel="last_year">
        {{
          employee.lastYearData
            ? (employee.lastYearData | separateThousands)
            : 0
        }}
      </td>
      <td
        dataLabel="growth"
        [ngClass]="{
          'text-success': employee.getGrowth() > 0,
          'text-danger': employee.getGrowth() < 0
        }"
      >
        <ng-container *ngIf="employee.getGrowth() > 0">+</ng-container>
        {{ employee.getGrowth() }}%
      </td>
      <td dataLabel="sales_target">
        {{ (employee.salesTarget | separateThousands) || "-" }}
      </td>
      <td
        dataLabel="sales_target_accomplished"
        [className]="getClassNameByProgress(employee.getSalesTargetPercent())"
      >
        {{
          employee.salesTarget ? employee.getSalesTargetPercent() + "%" : "-"
        }}
      </td>
    </tr>
    <tr
      class="hidden-sm hidden-xs"
      kpi-summary-row
      [kpi]="summary$ | async"
    ></tr>
  </tbody>
</table>
