import { CreateFollowUpGuard } from "../guards/create-follow-up-guard.service";
import { CreateFollowUpCreateContactComponent } from "./create-follow-up-create-contact/create-follow-up-create-contact.component";
import { CreateFollowUpHomeComponent } from "./create-follow-up-home/create-follow-up-home.component";
import { CreateFollowUpNewComponent } from "./create-follow-up-new/create-follow-up-new.component";
import { CreateFollowUpComponent } from "./create-follow-up.component";

export const sidebarFollowUpsRoutes = {
  path: "follow-ups",
  component: CreateFollowUpComponent,
  children: [
    {
      path: "",
      component: CreateFollowUpHomeComponent,
      canActivate: [CreateFollowUpGuard],
    },
    {
      path: "contacts/new",
      component: CreateFollowUpCreateContactComponent,
      canActivate: [CreateFollowUpGuard],
    },
    {
      path: "new/contacts/:id",
      component: CreateFollowUpNewComponent,
      canActivate: [CreateFollowUpGuard],
    },
  ],
};
