import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { getDeswinglisherUrl } from "@app/shared/config/config.reducer";
import { first } from "rxjs";

@Component({
  selector: "app-deswinglisher-modal",
  templateUrl: "./deswinglisher-modal.component.html",
  styleUrls: ["./deswinglisher-modal.component.scss"],
})
export class DeswinglisherModalComponent implements OnInit {
  iframeUrl: string = "";
  crmLanguage = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeswinglisherModalComponent>,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.crmLanguage = this.localStorageService.fetchUserPreference("language");
    this.store.pipe(select(getDeswinglisherUrl), first()).subscribe((url) => {
      const params = [
        "hideMenu=true",
        "language=sv",
        `translationKey=${this.data.translateKey}`,
      ];

      if (
        !!this.crmLanguage &&
        this.crmLanguage !== "sv" &&
        this.crmLanguage !== "en"
      ) {
        params.push(`additionalLanguage=${this.crmLanguage}`);
      }

      this.iframeUrl = `${url}?${params.join("&")}`;
    });
  }
}
