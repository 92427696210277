import { first, map, Observable, of as observableOf, switchMap } from "rxjs";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { QObject, SalesMeeting } from "@app/models";
import { ActionsSubject, select, Store } from "@ngrx/store";
import * as smActions from "../ngrx/sales-meeting.actions";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";
import { ofType } from "@ngrx/effects";

interface Data {
  salesMeeting: SalesMeeting;
  residence?: QObject;
}

@Injectable()
export class SalesMeetingResolver implements Resolve<Data> {
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Data> {
    const { smId } = route.params;

    this.store.dispatch(smActions.getSalesMeetingRequest({ id: smId }));

    const salesMeeting$ = this.dispatcher.pipe(
      ofType(smActions.getSalesMeetingSuccess, smActions.getSalesMeetingFail),
      switchMap(() => this.store.pipe(select(fromSalesMeeting.getSalesMeeting)))
    );

    const residence$ = this.dispatcher.pipe(
      ofType(smActions.getResidenceSuccess, smActions.getResidenceFail),
      switchMap(() => this.store.pipe(select(fromSalesMeeting.getResidence)))
    );

    return salesMeeting$.pipe(
      switchMap((salesMeeting) => {
        const { eaOid } = salesMeeting;
        if (!eaOid) {
          return observableOf({ salesMeeting });
        }

        this.store.dispatch(smActions.getResidenceRequest({ id: eaOid }));
        return residence$.pipe(
          map((residence) => ({ residence, salesMeeting }))
        );
      }),
      first()
    );
  }
}
