<div class="widget">
  <h3>
    <app-icon name="user-chart" size="large"></app-icon>&nbsp;<span translate
      >employees_performance</span
    >
  </h3>
  <div class="widget__filters">
    <app-segmented-controls
      [controls]="periodFilters"
      [active]="periodFilter$ | async"
      (change)="periodFilterChanged($event)"
    ></app-segmented-controls>
    <app-segmented-controls
      [controls]="compareToFilters"
      [active]="compareToFilter$ | async"
      (change)="compareToFilterChanged($event)"
    ></app-segmented-controls>
  </div>
  <div
    class="widget__table"
    [class.loading-pulse]="isLoading$ | async"
    *ngIf="(data$ | async)?.results; else noData"
  >
    <table
      matSort
      matSortStart="desc"
      [matSortActive]="(sortBy$ | async)?.active"
      [matSortDirection]="(sortBy$ | async)?.direction"
      (matSortChange)="sortData($event)"
    >
      <ng-container *ngIf="columns$ | async as columns">
        <thead>
          <tr>
            <th mat-sort-header="entityName">{{ "name" | translate }}</th>
            <th
              *ngFor="let column of columns"
              [mat-sort-header]="column.label"
              [style]="{ 'max-width': getColumnWidth(column) }"
            >
              {{ column.label | insertUnderscoreAndLowerCase | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let employee of (data$ | async)?.results">
            <td>
              {{ employee.entityName }}
            </td>
            <td *ngFor="let column of columns">
              <div
                class="bar"
                *ngIf="
                  !!employee[column.label] && employee[column.label]?.current
                "
              >
                <div
                  class="right-bar"
                  [style]="{
                    'flex-basis': getReferenceLine(
                      employee[column.label]?.current,
                      column.maxBudget
                    ),
                    'background-color': getProgressBarColor(
                      getPercentage(
                        employee[column.label],
                        compareToFilter$ | async
                      )
                    )
                  }"
                ></div>
                <div
                  class="left-bar"
                  [style]="{
                    width: getReferenceLine(
                      getBudget(
                        employee[column.label],
                        compareToFilter$ | async
                      ),
                      column.maxBudget
                    )
                  }"
                ></div>
                <div class="bar__label">
                  {{ employee[column.label]?.current.toLocaleString() }} (<span
                    *ngIf="compareToFilter$ | async as filter"
                    [class.text-bold]="
                      getPercentage(employee[column.label], filter) > 100
                    "
                    >{{
                      getPercentage(employee[column.label], filter) === 1000
                        ? ">"
                        : ""
                    }}{{
                      percentageOrHyphen(
                        getPercentage(employee[column.label], filter),
                        employee[column.label]?.current
                      )
                    }}
                    %</span
                  >)
                </div>
                <div
                  class="bar__reference-line"
                  [style]="{
                    left: getReferenceLine(
                      getBudget(
                        employee[column.label],
                        compareToFilter$ | async
                      ),
                      column.maxBudget
                    )
                  }"
                ></div>
              </div>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
  <ng-template #noData>
    <div class="no-data">
      <span translate>no_data_found</span>
    </div>
  </ng-template>
</div>
