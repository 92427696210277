<div>
  <form [formGroup]="form">
    <section class="message-type">
      <app-segmented-controls
        [controls]="[
          { label: 'sms', value: 'sms' },
          { label: 'email', value: 'email' }
        ]"
        [active]="messageType"
        (change)="chooseMessageType($event)"
      ></app-segmented-controls>
    </section>
    <section class="template">
      <h4 translate>template</h4>
      <div class="form-group">
        <select formControlName="template">
          <option value="" disabled translate>select_template</option>
          <option
            *ngFor="let template of templates"
            value="{{ template.templateId }}"
          >
            {{ template.name }}
          </option>
        </select>
        <div
          *ngIf="
            form.get('template').hasError('required') &&
            form.get('template').touched
          "
          class="validation-message"
          translate
        >
          validation_required
        </div>
      </div>
      <div
        *ngIf="errors?.dynamicContents"
        class="alert alert-warning template__warning"
        translate
      >
        template_not_configured
      </div>
      <ng-container *ngIf="!!dynamicContents && dynamicContents.length > 0">
        <app-message-templates-dynamic-contents
          #editor
          [parentForm]="form"
          [dynamicContents]="dynamicContents"
          [isEmail]="messageType === 'email'"
          [hasColor]="true"
        >
        </app-message-templates-dynamic-contents>
      </ng-container>
      <ng-content></ng-content>
    </section>

    <section
      class="object-files"
      *ngIf="messageType === 'email' && selectedObjectFiles?.length > 0"
    >
      <h4 translate>attached_files</h4>
      <card
        *ngFor="let file of selectedObjectFiles"
        [color]="'#444'"
        [removable]="true"
        (remove)="onFileRemove(file)"
        style="margin-bottom: 0.5rem"
      >
        <div title>{{ file?.fileDescription }}</div>
        <div body>
          <small>{{ roundFileSize(file?.fileSize / 1024 / 1024) }} MB</small>
          <small class="text-uppercase">{{ file?.fileType }}</small>
        </div>
      </card>
    </section>

    <section class="receivers">
      <h4 translate>add_receivers</h4>
      <search-contact
        [filter]="'freetext'"
        [placeholder]="'search_contact_free_text_placeholder' | translate"
        [icon]="'icon icon-magnifier icon-lg'"
        [group]="form"
        [controlName]="'recipient'"
        (quedroClicked)="
          addRecipient.emit($event); form.get('recipient').reset()
        "
      >
        <a (click)="showManualInput$.next(true)" class="text-link" empty>
          - {{ "add_manually" | translate }}
        </a>
      </search-contact>
      <div
        *ngIf="form.hasError('minReceivers') && submitted"
        class="validation-message"
        translate
      >
        validation_one_receiver
      </div>

      <div
        class="receivers__add-manually"
        *ngIf="showManualInput$ | async"
        [@fadeInOut]
      >
        <div class="receivers__add-manually__seperator" translate>or</div>
        <ng-container *ngIf="messageType === 'email'">
          <input-group-button
            formControlName="email"
            placeholder="email"
            (onClick)="onAddEmail($event)"
          ></input-group-button>
          <div
            *ngIf="
              form.get('email').hasError('validateEmail') &&
              form.get('email').touched
            "
            class="validation-message"
            translate
          >
            validation_email
          </div>
        </ng-container>

        <ng-container *ngIf="messageType === 'sms'">
          <mobile-input
            formControlName="mobile"
            [countryCode]="countryCode"
            [languageCode]="languageCode"
            [showButton]="true"
            (countryChange)="updateMobileNumberValidators($event)"
            (onClick)="onAddMobileNumber($event)"
          ></mobile-input>
          <div
            *ngIf="
              form.get('mobile').hasError('phone') && form.get('mobile').touched
            "
            class="validation-message"
            translate
          >
            validation_mobile
          </div>
        </ng-container>
      </div>

      <h4>
        {{ "add_extra_receivers" | translate }}
        <span *ngIf="messageType === 'email'"
          >({{ "with_email" | translate }})</span
        >
        <span *ngIf="messageType === 'sms'"
          >({{ "with_mobile" | translate }})</span
        >
      </h4>

      <ng-container *ngIf="shouldShowResponsibleEmployeeAsReceiver(object)">
        <div [formGroup]="receiverForm">
          <div class="custom-receiver">
            <input
              formControlName="responsibleBroker"
              type="checkbox"
              id="receiver-responsbile-employee"
            />
            <label for="receiver-responsbile-employee">
              <span>
                {{ object.responsibleEmployee.employeeFullName }}
                <br />
                <span class="muted" translate>responsible_broker</span>
              </span>
            </label>
          </div>

          <ng-container *ngIf="objectSellers.length > 0">
            <ng-container formArrayName="sellers">
              <ng-container
                *ngFor="let seller of sellerReceivers.controls; let i = index"
              >
                <div
                  *ngIf="shouldShowSellerAsReceiver(i)"
                  class="custom-receiver"
                  [ngClass]="i === objectSellers.length - 1 ? 'last' : null"
                >
                  <input
                    [formControl]="seller"
                    type="checkbox"
                    [id]="'receiver-sellers' + i"
                  />
                  <label [for]="'receiver-sellers' + i">
                    <span
                      >{{ objectSellers[i]?.firstName }}
                      {{ objectSellers[i]?.familyName }}
                      <br />
                      <span class="muted" translate>seller</span>
                    </span>
                  </label>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>

      <div
        *ngIf="recipients.length || emails.length || mobileNumbers.length"
        class="receivers__title"
      >
        <h4 [@fadeInOut] translate>receivers</h4>
        <app-q-button
          *ngIf="recipients.length && checkConsent"
          [fullWidth]="true"
          buttonType="remove"
          icon="trash"
          label="remove_contacts_without_consent"
          (click)="removeRecipientsWithoutConsents()"
        ></app-q-button>
      </div>
      <div
        *ngIf="recipientsWithoutMessageType(recipients).length > 0"
        class="alert alert-info"
      >
        <p>
          <span class="text-bold">{{ "obs" | translate }}! </span>
          <span *ngIf="messageType === 'email'">{{
            "send_message_warning_email"
              | translate : { orange: "orange" | translate }
          }}</span>
          <span *ngIf="messageType === 'sms'">{{
            "send_message_warning_phone"
              | translate : { orange: "orange" | translate }
          }}</span>
        </p>
        <p>
          <span *ngIf="messageType === 'email'"
            >{{ recipientsWithoutMessageType(recipients).length }}
            {{ "without_email_warning" | translate }}</span
          >
          <span *ngIf="messageType === 'sms'"
            >{{ recipientsWithoutMessageType(recipients).length }}
            {{ "without_phone_warning" | translate }}</span
          >
        </p>
      </div>

      <div
        *ngFor="let recipient of recipients; trackBy: trackByFunction"
        [@fadeInOut]
      >
        <card
          class="receivers__card"
          [class.receivers__card--not-clickable]="hasMessageType(recipient)"
          [class.danger_border]="
            checkConsent && !recipient.hasNewsletterConsent
          "
          [color]="hasMessageType(recipient) ? contactsColor : warningColor"
          [removable]="true"
          [matTooltip]="
            (checkConsent && !recipient.hasNewsletterConsent
              ? 'contact_doesnt_have_valid_consent'
              : ''
            ) | translate
          "
          matTooltipClass="q-tooltip"
          (remove)="removeRecipient.emit(recipient)"
          (click)="toggleInput(recipient.contactId)"
        >
          <div title>{{ recipient.getFullName() }}</div>
          <div body>
            <small>{{ recipient.email }}</small>
            <br *ngIf="recipient.email" />
            <small>{{ recipient.msisdn }}</small>
          </div>
        </card>
        <ng-container
          *ngIf="
            messageType === 'email' &&
            !recipient.hasEmail() &&
            showContactInputs.includes(recipient.contactId)
          "
        >
          <div class="alert alert-warning receivers__missing-info">
            <span>
              {{
                "validation_send_message_missing_email"
                  | translate : { name: recipient.getFullName() }
              }}
            </span>
            <input-group-button
              [formControlName]="recipient.contactId"
              placeholder="email"
              buttonText="save"
              buttonType="success"
              (onClick)="onUpdateRecipientEmail(recipient, $event)"
            ></input-group-button>
            <div
              *ngIf="
                form.get(recipient.contactId).hasError('validateEmail') &&
                form.get(recipient.contactId).touched
              "
              class="validation-message"
              translate
            >
              validation_email
            </div>
          </div>
        </ng-container>

        <div
          *ngIf="
            messageType === 'sms' &&
            !recipient.hasMobile() &&
            showContactInputs.includes(recipient.contactId)
          "
        >
          <div class="alert alert-warning receivers__missing-info">
            <span>
              {{
                "validation_send_message_missing_mobile"
                  | translate : { name: recipient.getFullName() }
              }}
            </span>
            <input-group-button
              [formControlName]="recipient.contactId"
              placeholder="mobile"
              buttonText="save"
              buttonType="success"
              (onClick)="onUpdateRecipientMobileNumber(recipient, $event)"
            ></input-group-button>
            <div
              *ngIf="
                form.get(recipient.contactId).hasError('phone') &&
                form.get(recipient.contactId).touched
              "
              class="validation-message"
              translate
            >
              validation_mobile
            </div>
          </div>
        </div>
      </div>

      <div *ngFor="let email of emails" [@fadeInOut]>
        <card
          class="receivers__card"
          [color]="contactsColor"
          [removable]="true"
          (remove)="onRemoveEmail(email)"
        >
          <div title>{{ email }}</div>
        </card>
        <p
          class="validation-message receivers__validation-message"
          *ngIf="messageType === 'sms'"
          translate
        >
          validation_no_sms_to_email
        </p>
      </div>

      <div *ngFor="let mobile of mobileNumbers" [@fadeInOut]>
        <card
          class="receivers__card"
          [color]="contactsColor"
          [removable]="true"
          (remove)="onRemoveMobileNumber(mobile)"
        >
          <div title>{{ mobile }}</div>
        </card>
        <p
          class="validation-message receivers__validation-message"
          *ngIf="messageType === 'email'"
          translate
        >
          validation_no_email_to_phone
        </p>
      </div>
    </section>
  </form>
</div>
