import { EmployeeDTO, Employment } from "@app/models";
import * as eoActions from "./employees-office-create.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface EmployeesCreateEmploymentState {
  employee: EmployeeDTO;
  employments: Employment[];
  loading: boolean;
  loaded: boolean;
  processing: boolean;
}

export const initialState: EmployeesCreateEmploymentState = {
  employee: null,
  employments: [],
  loading: false,
  loaded: false,
  processing: false,
};

export function employeesCreateEmploymentReducer(
  state: EmployeesCreateEmploymentState,
  action: Action
): EmployeesCreateEmploymentState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(eoActions.getEmployeeRequest, (state) => ({ ...state, loading: true })),
  on(eoActions.getEmployeeSuccess, (state, { employee }) => ({
    ...state,
    loading: false,
    loaded: true,
    employee,
  })),
  on(eoActions.getEmployeeFailed, (state) => ({
    ...state,
    loading: false,
    employee: initialState.employee,
  })),
  on(eoActions.getEmploymentsRequest, (state) => ({
    ...state,
    employments: initialState.employments,
  })),
  on(eoActions.getEmploymentsSuccess, (state, { employments }) => ({
    ...state,
    employments,
  })),
  on(eoActions.getEmploymentsFailed, (state) => ({
    ...state,
    employments: initialState.employments,
  })),
  on(eoActions.createEmploymentRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(
    eoActions.createEmploymentSuccess,
    eoActions.createEmploymentFailed,
    (state) => ({ ...state, processing: false })
  ),
  on(eoActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.employeesCreateEmployment
);

export const getEmployee = createSelector(
  getState,
  (state: EmployeesCreateEmploymentState) => state.employee
);

export const getEmployments = createSelector(
  getState,
  (state: EmployeesCreateEmploymentState) => state.employments
);

export const createEmploymentProcessing = createSelector(
  getState,
  (state: EmployeesCreateEmploymentState) => state.processing
);
