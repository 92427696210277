import { Employment } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getEmploymentsRequest = createAction(
  "[Manage Employments] GET_EMPLOYMENTS_REQUEST",
  props<{ eaOfficeId: string; params: Record<string, unknown> }>()
);

export const getEmploymentsSuccess = createAction(
  "[Manage Employments] GET_EMPLOYMENTS_SUCCESS",
  props<{ employments: Employment[] }>()
);

export const getEmploymentsFailed = createAction(
  "[Manage Employments] GET_EMPLOYMENTS_FAILED"
);

export const updateEmploymentRequest = createAction(
  "[Manage Employments] UPDATE_EMPLOYMENT_REQUEST",
  props<{
    eaOfficeId: string;
    eaEmployeeId: string;
    params: Record<string, unknown>;
  }>()
);

export const updateEmploymentSuccess = createAction(
  "[Manage Employments] UPDATE_EMPLOYMENT_SUCCESS",
  props<{ employment: Employment }>()
);

export const updateEmploymentFailed = createAction(
  "[Manage Employments] UPDATE_EMPLOYMENT_FAILED",
  props<{ eaEmployeeId: string }>()
);

export const createHasOnlineBookingRequest = createAction(
  "[Manage Employments] CREATE_HAS_ONLINE_BOOKING_REQUEST",
  props<{ eaEmployeeId: string; eaOfficeId: string }>()
);

export const createHasOnlineBookingSuccess = createAction(
  "[Manage Employments] CREATE_HAS_ONLINE_BOOKING_SUCCESS"
);

export const createHasOnlineBookingFailed = createAction(
  "[Manage Employments] CREATE_HAS_ONLINE_BOOKING_FAILED"
);

export const deleteHasOnlineBookingRequest = createAction(
  "[Manage Employments] DELETE_HAS_ONLINE_BOOKING_REQUEST",
  props<{ eaEmployeeId: string; eaOfficeId: string }>()
);

export const deleteHasOnlineBookingSuccess = createAction(
  "[Manage Employments] DELETE_HAS_ONLINE_BOOKING_SUCCESS"
);

export const deleteHasOnlineBookingFailed = createAction(
  "[Manage Employments] DELETE_HAS_ONLINE_BOOKING_FAILED"
);

export const reset = createAction("[Manage Employments] RESET");
