import { QObject } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const fetchLatestObjectsRequest = createAction(
  "[Widget Latest Objects For Sale] FETCH_LATEST_OBJECTS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const fetchLatestObjectsSuccess = createAction(
  "[Widget Latest Objects For Sale] FETCH_LATEST_OBJECTS_SUCCESS",
  props<{ objects: QObject[] }>()
);

export const fetchLatestObjectsFail = createAction(
  "[Widget Latest Objects For Sale] FETCH_LATEST_OBJECTS_FAIL"
);
