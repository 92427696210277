import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";
import * as fromResidences from "@app/contacts/contact-residences/residences.reducer";
import { QObject } from "@app/models";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-contact-residences-widget",
  templateUrl: "./contact-residences-widget.component.html",
  styleUrls: ["./contact-residences-widget.component.scss"],
})
export class ContactResidencesWidgetComponent implements OnInit, OnChanges {
  @Input() contactId: string;
  residenceList$: Observable<QObject[]>;
  loading$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchResidences();
  }

  ngOnChanges(changes) {
    if (changes.contactId) {
      this.fetchResidences();
    }
  }

  private mapStateToProps() {
    this.residenceList$ = this.store.pipe(
      select(fromResidences.getResidenceList)
    );
    this.loading$ = this.store.pipe(select(fromResidences.getLoading));
  }

  private fetchResidences() {
    this.store.dispatch(
      residencesActions.getContactResidenceListRequest({
        contactId: this.contactId,
      })
    );
  }
}
