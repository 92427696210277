<button
  class="q-btn q-btn--{{ buttonType }} {{ classes }}"
  [class.full-width]="fullWidth"
  [disabled]="disabled"
  [matMenuTriggerFor]="!!enableHoverMenu ? menu : null"
  #menuTrigger="matMenuTrigger"
  [matTooltip]="tooltipText | translate"
  matTooltipClass="q-tooltip"
  (mouseenter)="!!enableHoverMenu ? mouseEnter(menuTrigger) : null"
  (mouseleave)="!!enableHoverMenu ? mouseLeave(menuTrigger) : null"
>
  <spinner *ngIf="isLoading; else data" [size]="'1rem'"></spinner>
  <ng-template #data>
    <app-icon *ngIf="!!icon" [name]="icon"></app-icon>
    {{ label | translate }}
    <app-icon *ngIf="!!afterIcon" [name]="afterIcon"></app-icon>
  </ng-template>
</button>

<mat-menu #menu="matMenu" [hasBackdrop]="false">
  <div
    (mouseenter)="mouseEnter(menuTrigger)"
    (mouseleave)="mouseLeave(menuTrigger)"
  >
    <ng-content select="[menuContent]"></ng-content>
  </div>
</mat-menu>
