import { Component } from "@angular/core";
import { CREATE_LEAD } from "@app/shared/utils/tab-types";

@Component({
  selector: "create-lead",
  template: `
    <div>
      <div class="header-wrapper">
        <sidebar-header
          [type]="tabType"
          [label]="'create_lead' | translate"
        ></sidebar-header>
      </div>
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ["../sidebar.component.common.scss"],
})
export class CreateLeadComponent {
  tabType = CREATE_LEAD;
}
