import { createAction, props } from "@ngrx/store";
import { ObjectNoteResponse } from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.effects";
import { ObjectNote } from "@app/widgets/widgets/object-widgets/object-notes-widget/models/object-note";

const ACTION_PREFIX = "[Object Notes Widget]";

export const getNotesRequest = createAction(
  `${ACTION_PREFIX} GET_NOTES_REQUEST`,
  props<{ eaOid: string }>()
);

export const getNotesSuccess = createAction(
  `${ACTION_PREFIX} GET_NOTES_SUCCESS`,
  props<{ data: ObjectNoteResponse }>()
);

export const getNotesFail = createAction(`${ACTION_PREFIX} GET_NOTES_FAIL`);

export const createNoteRequest = createAction(
  `${ACTION_PREFIX} CREATE_NOTE_REQUEST`,
  props<{ eaOid: string; body: { title: string; content: string } }>()
);

export const createNoteSuccess = createAction(
  `${ACTION_PREFIX} CREATE_NOTE_SUCCESS`,
  props<{ data: ObjectNote }>()
);

export const createNoteFail = createAction(`${ACTION_PREFIX} CREATE_NOTE_FAIL`);

export const deleteNoteRequest = createAction(
  `${ACTION_PREFIX} DELETE_NOTE_REQUEST`,
  props<{ eaOid: string; externalId: string }>()
);

export const deleteNoteSuccess = createAction(
  `${ACTION_PREFIX} DELETE_NOTE_SUCCESS`,
  props<{ eaOid: string }>()
);

export const deleteNoteFail = createAction(`${ACTION_PREFIX} DELETE_NOTE_FAIL`);
