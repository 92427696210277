<app-q-modal>
  <ng-container slot="header">
    {{ "translate" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="iframe-container">
      <iframe [src]="iframeUrl | safe"></iframe>
    </div>
  </ng-container>
</app-q-modal>
