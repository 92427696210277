import { Contact, TaskType } from "@app/models";
import { Showing } from "@app/showings/models";
import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import moment from "moment";
import { createAction, props } from "@ngrx/store";

export interface CreateContactParams {
  eaEmployeeId: string;
  eaOfficeId: string;
  firstName: string;
  familyName: string;
  street: string;
  zip: string;
  city: string;
  email: string;
  msisdnCountry: string;
  msisdn: string;
  phoneNumberCountry: string;
  phoneNumber: string;
  originService?: string;
  eaOid?: string;
  type?: string;
  sex?: string;
  maritalStatus?: string;
  dateOfBirth?: string;
}

const ACTION_PREFIX = "[Create Contact]";

export const getContactSource = (taskTypeCategory?: string, limit?: number) => {
  return getContactSourceRequest({
    taskTypeCategory: taskTypeCategory || "contactSource",
    limit: limit || 200,
  });
};

export const getContactSourceRequest = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SOURCE_REQUEST`,
  props<{
    taskTypeCategory: string;
    limit: number;
  }>()
);

export const getContactSourceSuccess = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SOURCE_SUCCESS`,
  props<{ taskTypes: TaskType[] }>()
);

export const getContactSourceFail = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SOURCE_FAILED`
);

export const createContactRequest = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_REQUEST`,
  props<{
    params: Record<string, unknown>;
    closeSidebar: boolean;
    eaTaskTypeId: string;
    showingAttendance: ContactShowingAttendance[];
  }>()
);

export const createContactSuccess = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_SUCCESS`,
  props<{ contactId: string }>()
);

export const createContactFail = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_FAILED`
);

export const updateContactRequest = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_REQUEST`,
  props<{
    contactId: string;
    params: Record<string, unknown>;
    origin: string;
  }>()
);

export const updateContactSuccess = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_SUCCESS`,
  props<{ contact: Contact }>()
);

export const updateContactFail = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_FAILED`
);

export const setPrefillData = createAction(
  `${ACTION_PREFIX} SET_PREFILL_DATA`,
  props<{ prefillData: Partial<Contact> }>()
);

export const clearPrefillData = createAction(
  `${ACTION_PREFIX} CLEAR_PREFILL_DATA`
);

export const addContactToSource = (filters: Record<string, unknown>) => {
  const params = {
    eaTaskTypeId: filters.eaTaskTypeId,
    contactId: filters.contactId,
    title: "SET_CONSUMER_SOURCE",
    startTime: moment(new Date()).format("YYYYMMDDHHmmss"),
    deliveryDate: moment().add(1, "days").format("YYYYMMDDHHmmss"),
    originEaEmployeeId: filters.eaEmployeeId,
    originEaOfficeId: filters.eaOfficeId,
  };
  return addContactToSourceRequest({ params });
};

export const addContactToSourceRequest = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SOURCE_REQUEST`,
  props<{ params: Record<string, unknown> }>()
);

export const addContactToSourceSuccess = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SOURCE_SUCCESS`
);

export const addContactToSourceFail = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SOURCE_FAILED`
);

export const addContactToShowing = (
  contact: Contact,
  eaOid: string,
  showingAttendance: ContactShowingAttendance[],
  closeSidebar = true,
  type = "potentialbuyer",
  bidStatus = "unknown"
) => {
  return addContactToShowingRequest({
    contact,
    params: { eaOid, type, bidStatus },
    showingAttendance,
    closeSidebar,
  });
};

export const addContactToShowingRequest = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SHOWING_REQUEST`,
  props<{
    contact: Contact;
    params: { eaOid: string; type: string; bidStatus: string };
    showingAttendance: ContactShowingAttendance[];
    closeSidebar: boolean;
  }>()
);

export const addContactToShowingSuccess = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SHOWING_SUCCESS`,
  props<{
    closeSidebar: boolean;
    contact: Contact;
    showingAttendance: ContactShowingAttendance[];
  }>()
);

export const addContactToShowingFail = createAction(
  `${ACTION_PREFIX} ADD_CONTACT_TO_SHOWING_FAIL`
);

export const getShowingsRequest = createAction(
  `${ACTION_PREFIX} GET_SHOWINGS_REQUEST`,
  props<{ eaOid: string }>()
);

export const getShowingsSuccess = createAction(
  `${ACTION_PREFIX} GET_SHOWINGS_SUCCESS`,
  props<{ showings: Showing[] }>()
);

export const getShowingsFail = createAction(
  `${ACTION_PREFIX} GET_SHOWINGS_FAILED`
);

export const updateShowingAttendanceRequest = createAction(
  `${ACTION_PREFIX} UPDATE_SHOWING_ATTENDANCE_REQUEST`,
  props<{
    contactId: string;
    attendance: ContactShowingAttendance;
  }>()
);

export const updateShowingAttendanceSuccess = createAction(
  `${ACTION_PREFIX} UPDATE_SHOWING_ATTENDANCE_SUCCESS`
);

export const updateShowingAttendanceFail = createAction(
  `${ACTION_PREFIX} UPDATE_SHOWING_ATTENDANCE_FAILED`
);
