import { Constructor } from "./mixin";

export function Nameable<T extends Constructor>(Base: T) {
  return class extends Base {
    firstName;
    familyName;

    getFullName(): string {
      if (this.firstName && this.familyName) {
        return `${this.firstName} ${this.familyName}`;
      } else if (this.firstName) {
        return this.firstName;
      } else if (this.familyName) {
        return this.familyName;
      } else {
        return "";
      }
    }
  };
}
