import { Employee } from "@app/models";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import { createAction, props } from "@ngrx/store";
import { SearchProfile } from "app/contacts/contact-search-profile/models/search-profile";
import { CategoryType } from "app/models/category-type";
import { AreaSearchResults } from "app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";

export const getAreaSearchResultsRequest = createAction(
  "[Search Profile Sidebar] GET_AREA_SEARCH_RESULTS_REQUEST",
  props<{ keyword: string; filterRootNode: string; country: string }>()
);

export const getAreaSearchResultsSuccess = createAction(
  "[Search Profile Sidebar] GET_AREA_SEARCH_RESULTS_SUCCESS",
  props<{ areas: AreaSearchResults }>()
);

export const getAreaSearchResultsFail = createAction(
  "[Search Profile Sidebar] GET_AREA_SEARCH_RESULTS_FAIL"
);

export const createSearchProfileRequest = createAction(
  "[Search Profile Sidebar] CREATE_SEARCH_PROFILE_REQUEST",
  props<{
    searchProfile: SearchProfile;
    openManageAlertSidebar: boolean;
  }>()
);

export const createSearchProfileSuccess = createAction(
  "[Search Profile Sidebar] CREATE_SEARCH_PROFILE_SUCCESS",
  props<{ openManageAlertSidebar: boolean; searchProfileId: string }>()
);

export const createSearchProfileFail = createAction(
  "[Search Profile Sidebar] CREATE_SEARCH_PROFILE_FAIL"
);

export const updateSearchProfileRequest = createAction(
  "[Search Profile Sidebar] UPDATE_SEARCH_PROFILE_REQUEST",
  props<{ params: Partial<SearchProfile>; searchProfileId: string }>()
);

export const updateSearchProfileSuccess = createAction(
  "[Search Profile Sidebar] UPDATE_SEARCH_PROFILE_SUCCESS"
);

export const updateSearchProfileFail = createAction(
  "[Search Profile Sidebar] UPDATE_SEARCH_PROFILE_FAIL"
);

export const getSearchProfileRequest = createAction(
  "[Search Profile Sidebar] GET_SEARCH_PROFILE_REQUEST",
  props<{ searchProfileId: string }>()
);

export const getSearchProfileSuccess = createAction(
  "[Search Profile Sidebar] GET_SEARCH_PROFILE_SUCCESS",
  props<{ searchProfile: SearchProfile }>()
);

export const getSearchProfileFail = createAction(
  "[Search Profile Sidebar] GET_SEARCH_PROFILE_FAIL"
);

export const deleteSearchProfileRequest = createAction(
  "[Search Profile Sidebar] DELETE_SEARCH_PROFILE_REQUEST",
  props<{ searchProfileId: string }>()
);

export const deleteSearchProfileSuccess = createAction(
  "[Search Profile Sidebar] DELETE_SEARCH_PROFILE_SUCCESS",
  props<{ searchProfileId: string }>()
);

export const deleteSearchProfileFail = createAction(
  "[Search Profile Sidebar] DELETE_SEARCH_PROFILE_FAIL"
);

export const getAdditionalFiltersRequest = createAction(
  "[Search Profile Sidebar] GET_ADDITIONAL_FILTERS_REQUEST",
  props<{ categoryIds: string; country: string }>()
);

export const getAdditionalFiltersSuccess = createAction(
  "[Search Profile Sidebar] GET_ADDITIONAL_FILTERS_SUCCESS",
  props<{ categoryTypes: CategoryType[] }>()
);

export const getAdditionalFiltersFail = createAction(
  "[Search Profile Sidebar] GET_ADDITIONAL_FILTERS_FAIL"
);

export const getAlertActivitiesRequest = createAction(
  "[Search Profile Sidebar] GET_ALERT_ACTIVITIES_REQUEST",
  props<{ filterSettingsGroupId: string }>()
);

export const getAlertActivitiesSuccess = createAction(
  "[Search Profile Sidebar] GET_ALERT_ACTIVITIES_SUCCESS",
  props<{ activities: { activityId: string; name: string }[] }>()
);

export const getAlertActivitiesFail = createAction(
  "[Search Profile Sidebar] GET_ALERT_ACTIVITIES_FAIL"
);

export const getAlertRequest = createAction(
  "[Search Profile Sidebar] GET_ALERT_REQUEST",
  props<{ searchProfileId: string }>()
);

export const getAlertSuccess = createAction(
  "[Search Profile Sidebar] GET_ALERT_SUCCESS",
  props<{ alert: Alert }>()
);

export const getAlertFail = createAction(
  "[Search Profile Sidebar] GET_ALERT_FAIL"
);

export const createAlertRequest = createAction(
  "[Search Profile Sidebar] CREATE_ALERT_REQUEST",
  props<{ alert: Partial<Alert>; mail?: Record<string, unknown> }>()
);

export const createAlertSuccess = createAction(
  "[Search Profile Sidebar] CREATE_ALERT_SUCCESS",
  props<{ mail?: Record<string, unknown> }>()
);

export const createAlertFail = createAction(
  "[Search Profile Sidebar] CREATE_ALERT_FAIL"
);

export const updateAlertRequest = createAction(
  "[Search Profile Sidebar] UPDATE_ALERT_REQUEST",
  props<{
    params: Partial<Alert>;
    alertId: string;
    mail?: Record<string, unknown>;
  }>()
);

export const updateAlertSuccess = createAction(
  "[Search Profile Sidebar] UPDATE_ALERT_SUCCESS",
  props<{ mail?: Record<string, unknown> }>()
);

export const updateAlertFail = createAction(
  "[Search Profile Sidebar] UPDATE_ALERT_FAIL"
);

export const deleteAlertRequest = createAction(
  "[Search Profile Sidebar] DELETE_ALERT_REQUEST",
  props<{ alertId: string }>()
);

export const deleteAlertSuccess = createAction(
  "[Search Profile Sidebar] DELETE_ALERT_SUCCESS"
);

export const deleteAlertFail = createAction(
  "[Search Profile Sidebar] DELETE_ALERT_FAIL"
);

export const sendConfirmationMailRequest = createAction(
  "[Search Profile Sidebar] SEND_CONFIRMATION_MAIL_REQUEST",
  props<{ mail: Record<string, unknown> }>()
);

export const sendConfirmationMailSuccess = createAction(
  "[Search Profile Sidebar] SEND_CONFIRMATION_MAIL_SUCCESS"
);

export const sendConfirmationMailFail = createAction(
  "[Search Profile Sidebar] SEND_CONFIRMATION_MAIL_FAIL"
);

export const getAlertEmployeeRequest = createAction(
  "[Search Profile Sidebar] GET_ALERT_EMPLOYEE_REQUEST",
  props<{ id: string }>()
);

export const getAlertEmployeeSuccess = createAction(
  "[Search Profile Sidebar] GET_ALERT_EMPLOYEE_SUCCESS",
  props<{ employee: Employee }>()
);

export const getAlertEmployeeFail = createAction(
  "[Search Profile Sidebar] GET_ALERT_EMPLOYEE_FAIL"
);
