import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import * as apiUrls from "@app/core/services/api/utils/api-endpoints";
import { Contact, QObject, TypedPaginationListDTO } from "@app/models";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { SEND_MESSAGE } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import moment from "moment";
import {
  catchError,
  concat,
  filter,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as sendMessageActions from "./send-message.actions";
import { DynamicContent, Template } from "@app/sidebar/send-message/models";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";

@Injectable()
export class SendMessageEffects {
  fetchTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.fetchTemplatesRequest),
      switchMap(({ messageType, groupName }) =>
        this.apiService
          .get(this.resolveTemplateUrl(messageType), { groupName })
          .pipe(
            map((response: TypedPaginationListDTO<Template>) =>
              sendMessageActions.fetchTemplatesSuccess({
                templates: response.rows,
              })
            ),
            catchError(() =>
              observableOf(sendMessageActions.fetchTemplatesFail())
            )
          )
      )
    )
  );

  fetchDynamicContentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.fetchDynamicContentRequest),
      switchMap(({ templateId, params }) =>
        this.apiService
          .get(apiUrls.MESSAGES_TEMPLATES_DYNAMIC_CONTENT(templateId), params)
          .pipe(
            map((response: TypedPaginationListDTO<DynamicContent>) =>
              response.rows.length > 0
                ? sendMessageActions.fetchDynamicContentSuccess({
                    dynamicContents: response.rows,
                  })
                : sendMessageActions.dynamicContentNotFound()
            ),
            catchError(() =>
              observableOf(sendMessageActions.fetchDynamicContentFail())
            )
          )
      )
    )
  );

  updateContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.updateContactRequest),
      switchMap(({ contactId, params }) =>
        this.apiService.patch(apiUrls.CONTACTS_ID(contactId), params).pipe(
          map((response: Contact) => new Contact(response)),
          map((contact) =>
            sendMessageActions.updateContactSuccess({ contact })
          ),
          catchError(() => observableOf(sendMessageActions.updateContactFail()))
        )
      )
    )
  );

  sendMessageRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.sendMessageRequest),
      switchMap(({ messageType, params }) => {
        const requests = params.map((messageParams) => {
          const url = this.resolveMessageUrl(messageType, messageParams);
          return this.apiService.post(url, messageParams).pipe(
            catchError((err) => {
              err.customMessage = messageParams.recipients;
              return throwError(() => err);
            })
          );
        });
        const taskSet: TaskSet = {
          label: "sending_messages",
          tasks: requests,
        };
        return concat(
          [sendMessageActions.sendMessageSuccess()],
          [addTaskSetToQueue(taskSet)]
        );
      })
    )
  );

  sendMessageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.sendMessageSuccess),
      tap(() => this.sendMessageService.reset()),
      mergeMap(() => [
        sidebarActions.closeTab({ tabType: SEND_MESSAGE }),
        sendMessageActions.reset(),
      ])
    )
  );

  reset$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sidebarActions.closeTab),
      filter(({ tabType }) => tabType === SEND_MESSAGE),
      map(() => sendMessageActions.reset())
    )
  );

  resetOnCloseAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sidebarActions.closeAllNonPersistentTabs),
      map(() => sendMessageActions.reset())
    )
  );

  getUnSoldObjectsForEmployeeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.getUnsoldObjectsForEmployeeRequest),
      switchMap(({ eaEmployeeId, publishedOnNet }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaEmployeeId,
              status: "1,4,9,10,12,17,23,24,26,30,34,51",
              sortBy: "street",
              sortOrder: "asc",
              publishedOnNet,
            },
            "api"
          )
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) =>
              sendMessageActions.getUnsoldObjectsForEmployeeSuccess({
                objects: response.rows,
              })
            ),
            catchError(() =>
              observableOf(sendMessageActions.getUnsoldObjectsForEmployeeFail())
            )
          )
      )
    )
  );

  getUnSoldObjectsForOfficeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.getUnsoldObjectsForOfficeRequest),
      switchMap(({ eaOfficeId, publishedOnNet }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaOfficeId,
              status: "1,4,9,10,12,17,23,24,26,30,34,51",
              sortBy: "street",
              sortOrder: "asc",
              publishedOnNet,
            },
            "api"
          )
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) =>
              sendMessageActions.getUnsoldObjectsForOfficeSuccess({
                objects: response.rows,
              })
            ),
            catchError(() =>
              observableOf(sendMessageActions.getUnsoldObjectsForOfficeFail())
            )
          )
      )
    )
  );

  getSoldObjectsForEmployeeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.getSoldObjectsForEmployeeRequest),
      switchMap(({ eaEmployeeId }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaEmployeeId,
              status: "21,33,52",
              isSold: true,
              sortBy: "street",
              sortOrder: "asc",
              insertedFrom: moment()
                .subtract(6, "months")
                .startOf("day")
                .format(API_DATE_FORMAT),
              publishedOnNet: "both",
            },
            "api"
          )
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) =>
              sendMessageActions.getSoldObjectsForEmployeeSuccess({
                objects: response.rows,
              })
            ),
            catchError(() =>
              observableOf(sendMessageActions.getSoldObjectsForEmployeeFail())
            )
          )
      )
    )
  );

  getSoldObjectsForOfficeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sendMessageActions.getSoldObjectsForOfficeRequest),
      switchMap(({ eaOfficeId }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaOfficeId,
              status: "21,33,52",
              isSold: true,
              sortBy: "street",
              sortOrder: "asc",
              insertedFrom: moment()
                .subtract(6, "months")
                .startOf("day")
                .format(API_DATE_FORMAT),
              publishedOnNet: "both",
            },
            "api"
          )
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) =>
              sendMessageActions.getSoldObjectsForOfficeSuccess({
                objects: response.rows,
              })
            ),
            catchError(() =>
              observableOf(sendMessageActions.getSoldObjectsForOfficeFail())
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private sendMessageService: SendMessageService
  ) {}

  private resolveTemplateUrl(type: string): string {
    return type === "email"
      ? apiUrls.MESSAGES_EMAIL_TEMPLATES
      : apiUrls.MESSAGES_SMS_TEMPLATES;
  }

  private resolveMessageUrl(
    type: string,
    params: Record<string, unknown>
  ): string {
    if (type === "email") {
      if (params.hasOwnProperty("addToOutbox") && params.addToOutbox) {
        return apiUrls.MESSAGES_EMAIL + "?sendEmailThroughBrokerEmail=true";
      }
      return apiUrls.MESSAGES_EMAIL;
    }
    return apiUrls.MESSAGES_SMS;
  }
}
