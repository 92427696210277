import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { EmployeeDTO, Office } from "@app/models";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  tap,
} from "rxjs";
import { getEaOfficeId, hasRole, isManagerOrAdmin } from "../../../user";
import { ROLE_ADMIN } from "../../../utils/roles";
import { OfficeEmployeeMultiSelectDropdownService } from "./office-employee-multi-select-dropdown.service";

@Component({
  selector: "office-employee-multi-select-dropdown",
  templateUrl: "./office-employee-multi-select-dropdown.component.html",
  styleUrls: ["./office-employee-multi-select-dropdown.component.scss"],
  providers: [OfficeEmployeeMultiSelectDropdownService],
})
export class OfficeEmployeeMultiSelectDropdownComponent
  implements OnInit, OnChanges
{
  @Input() parentFormGroup: FormGroup;
  @Input() sideBySide = true;
  @Input() officePrefix: string;
  @Input() includeInactiveEmployees = false;
  @Input() fullWidth = false;
  @Input() enableMobileSelect = false;
  @Input() officeSingle = false;
  @Input() employeeSingle = false;
  @Input() officeIdFormControlName = "eaOfficeId";
  @Input() employeeIdFormControlName = "eaEmployeeId";
  @Output() ready: EventEmitter<void> = new EventEmitter<void>();

  isAdmin$: Observable<boolean>;
  isAdminOrManager$: Observable<boolean>;
  offices$: Observable<Office[]>;
  employees$: Observable<EmployeeDTO[]>;
  eaOfficeIds: string[];

  unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private localStore: OfficeEmployeeMultiSelectDropdownService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.fetchAuthorizedOffices();
    this.initFormEventHandlers();
    this.setDefaultOfficeAndEmployee();
    this.handleReload();

    this.employees$
      .pipe(
        filter((it) => it.length > 0),
        first()
      )
      .subscribe(() => {
        this.ready.emit();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      Object.keys(changes).length === 1 &&
      Object.keys(changes)[0] === "includeInactiveEmployees"
    ) {
      this.fetchEmployees(this.eaOfficeIds);
    }
  }

  handleReload() {
    if (
      Object.keys(this.route.snapshot.params).includes(
        this.officeIdFormControlName
      )
    ) {
      this.fetchEmployees(
        this.route.snapshot.params[this.officeIdFormControlName]
      );
    }
  }

  fetchEmployees(eaOfficeIds: string[]): void {
    if (eaOfficeIds.length > 0) {
      this.localStore.fetchEmployeesFromOffices({
        eaOfficeId: eaOfficeIds,
        includeInactiveEmployees: this.includeInactiveEmployees,
      });
    }
  }

  mapStateToProps(): void {
    this.offices$ = this.localStore.offices$;
    this.employees$ = this.localStore.employees$;
    this.isAdmin$ = this.store.pipe(select(hasRole(ROLE_ADMIN)));
    this.isAdminOrManager$ = this.store.pipe(select(isManagerOrAdmin));
  }

  fetchAuthorizedOffices(): void {
    this.store
      .pipe(select(hasRole(ROLE_ADMIN)), take(1))
      .subscribe((isAdmin) => {
        if (isAdmin) {
          this.localStore.fetchOffices();
        } else {
          this.localStore.fetchOfficesConnectedToEmployee();
        }
      });
  }

  initFormEventHandlers(): void {
    this.parentFormGroup
      .get(this.officeIdFormControlName)
      .valueChanges.pipe(
        filter((value) => !!value),
        distinctUntilChanged((prev: string[], curr: string[]) => {
          if (prev.length !== curr.length) {
            return false;
          }

          if (prev.length === 0 && curr.length === 0) {
            return true;
          }

          return prev.every((eaOfficeId) => curr.includes(eaOfficeId));
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((eaOfficeIds: string[]) => {
        this.eaOfficeIds = eaOfficeIds;
        this.parentFormGroup
          .get(this.employeeIdFormControlName)
          .setValue(this.employeeSingle ? "" : []);
        this.fetchEmployees(eaOfficeIds);
      });
  }

  setDefaultOfficeAndEmployee(): void {
    this.offices$.pipe(first()).subscribe((offices) => {
      if (offices.length === 1) {
        const getDefaultOffice$ = observableCombineLatest([
          this.store.pipe(select(getEaOfficeId)),
          this.offices$.pipe(filter((offices) => offices.length > 0)),
        ]).pipe(
          map(([eaOfficeId, offices]) =>
            offices.find((o) => o.eaOfficeId === eaOfficeId)
          )
        );

        getDefaultOffice$
          .pipe(
            delay(1),
            tap((office) => {
              this.parentFormGroup
                .get(this.officeIdFormControlName)
                .setValue([office.eaOfficeId]);
            })
          )
          .subscribe();
      }
    });
  }
}
