<div class="widget-header">
  <h3>
    <app-icon size="large" name="user"></app-icon>&nbsp;<span>{{
      "details" | translate
    }}</span>
  </h3>
  <app-q-circle-btn
    icon="pen"
    tooltipText="edit_contact"
    (click)="editContact()"
  ></app-q-circle-btn>
</div>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div *ngIf="contact$ | async as contact" class="overview-data">
    <app-widget-field label="address">
      <a
        *ngIf="contact.street"
        target="_blank"
        [tooltip]="'open_in_google_maps' | translate"
        [href]="getGoogleMapsUrl(contact)"
        rel="noopener noreferrer"
      >
        {{ getAddress(contact.street, contact.zip, contact.city) }}
      </a>
      <a
        *ngIf="!contact.street"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
        class="hidden-xs"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="mobile">
      <a
        *ngIf="contact.msisdn"
        href="tel:+{{ contact.msisdn }}"
        [tooltip]="getPhoneNumberTooltip(contact, true)"
      >
        <i
          *ngIf="contact.msisdnCountry"
          class="flag-icon flag-icon-{{ contact.msisdnCountry | lowercase }}"
        ></i>
        {{ contact.msisdn | phoneFormat : contact.msisdnCountry }}
      </a>
      <a
        *ngIf="!contact.msisdn"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="email">
      <a *ngIf="contact.email" href="mailto:{{ contact.email }}" target="_top">
        <span tooltip="{{ 'open_mail_client' | translate }}" placement="bottom">
          {{ contact.email }}
        </span>
      </a>
      <a
        *ngIf="!contact.email"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="phone">
      <a
        *ngIf="contact.phoneNumber"
        href="tel:+{{ contact.phoneNumber }}"
        [tooltip]="getPhoneNumberTooltip(contact, false)"
      >
        <i
          *ngIf="contact.phoneNumberCountry"
          class="flag-icon flag-icon-{{
            contact.phoneNumberCountry | lowercase
          }}"
        ></i>
        {{ contact.phoneNumber | phoneFormat : contact.phoneNumberCountry }}
        (<span translate>phone</span>)
      </a>
      <a
        *ngIf="!contact.phoneNumber"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
        class="hidden-xs"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="contacted">
      <div
        *ngIf="
          latestContactedDate$ | async as latestContactedDate;
          else notContacted
        "
      >
        {{ latestContactedDate }}
        <span [tooltip]="'contacts_card_contacted' | translate">
          <app-icon name="question-circle"></app-icon>
        </span>
      </div>
      <ng-template #notContacted>
        <div>
          {{ "not_contacted" | translate }}
          <span [tooltip]="'contacts_card_contacted' | translate">
            <app-icon name="question-circle"></app-icon>
          </span>
        </div>
      </ng-template>
    </app-widget-field>
    <app-widget-field
      label="gender"
      [editable]="false"
      [hideEdit]="!!contact.sex"
    >
      <div *ngIf="!!contact.sex">
        {{ contact.sex | translate }}
      </div>
      <a
        *ngIf="!contact.sex && contact.contactType !== 'estate'"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="birthday">
      <div
        *ngIf="contact.dateOfBirth && contact.dateOfBirth.length === 8"
        class="meta-birthday"
        [class.red]="isBirthDayToday(contact.dateOfBirth)"
        [class.green]="daysUntilNextBirthDay(contact.dateOfBirth) <= 7"
        [tooltip]="getFormattedBirthDay(contact)"
      >
        <img
          *ngIf="isBirthDayToday(contact.dateOfBirth)"
          ngSrc="assets/img/party-popper.gif"
          height="20"
          width="25"
          style="margin-top: -5px"
        />
        {{ getAgeByDateOfBirth(contact.dateOfBirth) }}
        {{ "years" | translate | lowercase }}
        ({{ "next_birth_day_in" | translate }}
        {{ daysUntilNextBirthDay(contact.dateOfBirth) }}
        {{ "days_plural" | translate }}
        )
        <span> </span>
      </div>
      <a
        *ngIf="
          (!contact.dateOfBirth || contact.dateOfBirth.length !== 8) &&
          contact.contactType !== 'estate'
        "
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field
      label="marital_status"
      [editable]="false"
      [hideEdit]="!!contact.maritalStatus"
    >
      <div *ngIf="contact.maritalStatus">
        {{ contact.maritalStatus.toLowerCase() | translate }}
      </div>
      <a
        *ngIf="!contact.maritalStatus && contact.contactType !== 'estate'"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="preferred_language">
      <div
        *ngIf="contact.preferredLanguage"
        [tooltip]="'preferred_language' | translate"
      >
        <i
          class="flag-icon flag-icon-{{
            contact.preferredLanguage | lowercase
          }}"
        ></i>
        {{ preferredLanguage$ | async | translate | titlecase }}
      </div>
      <a
        *ngIf="!contact.preferredLanguage && contact.contactType !== 'estate'"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field
      label="owns_residence"
      [editable]="true"
      [hideEdit]="!!contact?.profile?.ownsResidence"
    >
      <div display>
        <div *ngIf="contact?.profile?.ownsResidence">
          <span
            class="green"
            *ngIf="contact?.profile?.ownsResidence === 'OWNS_A_RESIDENCE'"
            >{{ "yes" | translate }}</span
          >
          <span
            class="red"
            *ngIf="
              contact?.profile?.ownsResidence === 'DOES_NOT_OWN_A_RESIDENCE'
            "
            >{{ "no" | translate }}</span
          >
          <span *ngIf="contact?.profile?.ownsResidence === 'UNKNOWN'">{{
            "unknown" | translate
          }}</span>
        </div>
        <a
          *ngIf="
            !contact?.profile?.ownsResidence && contact.contactType !== 'estate'
          "
          class="hidden-xs"
          [routerLink]="[
            '/crm',
            { outlets: { sidebar: ['root', 'contacts', contactId, 'edit'] } }
          ]"
        >
          <span class="muted" translate>add</span>
        </a>
      </div>
      <div edit>
        <app-dropdown-element
          label="owns_residence"
          [path]="['owns_residence']"
          [fieldName]="'owns_residence'"
          [required]="true"
          [preselectValue]="contact.profile?.ownsResidence"
          [items]="contactOwnsResidence"
          (valueEntered)="patchContactProfile('ownsResidence', $event)"
        ></app-dropdown-element>
      </div>
    </app-widget-field>
  </div>
</div>
