<div class="widget-header">
  <h3>
    <ng-container
      *ngIf="(contactType$ | async) === 'company'; else personRelationsTitle"
    >
      <app-icon size="large" name="address-book"></app-icon>&nbsp;<span>{{
        "company_contacts" | translate
      }}</span>
    </ng-container>
    <ng-template #personRelationsTitle>
      <app-icon size="large" name="user-friends"></app-icon>&nbsp;<span>{{
        "relations" | translate
      }}</span>
    </ng-template>
  </h3>
  <app-q-circle-btn
    icon="plus"
    tooltipText="add_relation"
    (click)="showForm = true"
    *ngIf="!showForm"
  ></app-q-circle-btn>
</div>
<div
  class="widget-container"
  [class.loading-pulse]="contactRelationService.loading$ | async"
>
  <ng-container *ngIf="!showForm; else form">
    <div *ngFor="let relation of contactRelationService.currentList$ | async">
      <app-mobile-card
        (click)="open(relation.relatedToContactId, $event)"
        [shadow]="false"
        color="WHITE"
        size="SLIM"
        [enableMetadata]="true"
        [enableActionDropdown]="true"
      >
        <div title>
          <app-contact-name
            [contactId]="relation.relatedToContactId"
            [contactType]="relation.relatedToContactType"
          >
            {{ relation.firstName }} {{ relation.familyName }}
          </app-contact-name>
        </div>
        <div metadata>
          <span *ngIf="relation.relationshipType">
            <app-icon name="user-friends"></app-icon>&nbsp;{{
              "relation_" + relation.relationshipType.toLowerCase() | translate
            }}
            ,&nbsp;
          </span>
          <span *ngIf="relation.updatedDate">
            <app-icon name="clock"></app-icon>&nbsp;{{
              relation.updatedDate | momentify : true
            }}
          </span>
        </div>
        <ng-container buttons>
          <li role="menuitem" *ngIf="relation.relationshipSource === 'crm'">
            <button
              disableNavigation
              class="btn btn-secondary"
              (click)="editContactRelation = relation; showForm = true"
            >
              <app-icon size="none" name="user-edit"></app-icon>&nbsp;{{
                "edit_relation" | translate
              }}
            </button>
          </li>
          <li role="menuitem" *ngIf="relation.relationshipSource === 'crm'">
            <button
              disableNavigation
              class="btn btn-danger"
              (click)="deleteContactRelation(relation.eaRelationshipId)"
            >
              <app-icon size="none" name="user-times"></app-icon>&nbsp;{{
                "delete_relation" | translate
              }}
            </button>
          </li>
          <li role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="open(relation.relatedToContactId, $event)"
            >
              <app-icon size="none" name="user"></app-icon>
              {{ "go_to_contact" | translate }}
            </button>
          </li>
          <li role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="openInNewTab(relation.relatedToContactId)"
            >
              <app-icon size="none" name="share-square"></app-icon>
              {{ "open_in_new_tab" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
    <p
      (click)="showForm = true"
      class="no-items"
      *ngIf="(contactRelationService.currentList$ | async)?.length === 0"
    >
      {{ "no_relations_found" | translate }}
    </p>
  </ng-container>

  <ng-template #form>
    <app-small-back-button
      mode="output"
      (click)="showForm = false; editContactRelation = null"
    ></app-small-back-button>
    <app-contact-relation-form
      [contact]="contact$ | async"
      [contactRelation]="!!editContactRelation ? editContactRelation : null"
      (submit)="showForm = false; editContactRelation = null"
    ></app-contact-relation-form>
  </ng-template>
</div>
