import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ClickStopPropagationModule } from "@app/core/directives/click-stop-propagation.module";
import {
  ActionsMenuComponent,
  ActionsMenuSheetComponent,
} from "@app/shared/modules/ui-components/actions-menu/actions-menu.component";
import { BreadcrumbComponent } from "@app/shared/modules/ui-components/breadcrumb/breadcrumb.component";
import { LocationFilterComponent } from "@app/shared/modules/ui-components/location-filter/location-filter.component";
import { NotAvilableInMobileComponent } from "@app/shared/modules/ui-components/not-avilable-in-mobile/not-avilable-in-mobile.component";
import { InsertUnderscoreAndLowerCasePipe } from "@app/shared/modules/ui-components/pipes/insert-underscore-and-lower-case.pipe";
import { MomentifyFromApiFormatPipe } from "@app/shared/modules/ui-components/pipes/momentifyFromApiFormat.pipe";
import { MomentifyFromNowPipe } from "@app/shared/modules/ui-components/pipes/momentifyFromNow.pipe";
import { ProgressCircleComponent } from "@app/shared/modules/ui-components/progress-circle/progress-circle.component";
import { QChipRoundedComponent } from "@app/shared/modules/ui-components/q-chip-rounded/q-chip-rounded.component";
import { QTabBarChipsComponent } from "@app/shared/modules/ui-components/q-tab-bar-chips/q-tab-bar-chips.component";
import { SmallBackButtonComponent } from "@app/shared/modules/ui-components/small-back-button/small-back-button.component";
import { StandaloneSidebarFooterComponent } from "@app/shared/modules/ui-components/standalone-sidebar-footer/standalone-sidebar-footer.component";
import { SafePipe } from "@app/shared/modules/util/safe/safe.pipe";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { StatisticsAdfenixReportingTableComponent } from "@app/statistics/statistics-adfenix-reporting/components/statistics-adfenix-reporting-table/statistics-adfenix-reporting-table.component";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxErrorsModule } from "@ngspot/ngx-errors";
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { ClickOutsideModule } from "ng-click-outside";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from "ngx-mask";
import { SortablejsModule } from "ngx-sortablejs";
import { SkeletonScreenModule } from "../skeleton-screen/skeleton-screen.module";
import { ButtonComponent } from "./button/button.component";
import { CardComponent } from "./card/card.component";
import { CheckboxSelectComponent } from "./checkbox-select/checkbox-select.component";
import { ContactCardComponent } from "./contact-card/contact-card.component";
import { ContainerBoxComponent } from "./container-box/container-box.component";
import { ControlsBoxComponent } from "./controls-box/controls-box.component";
import { DetailCardComponent } from "./detail-card/detail-card.component";
import { SkeletonDetailCardComponent } from "./detail-card/skeleton-detail-card.component";
import { ExpandableTextComponent } from "./expandable-text/expandable-text.component";
import { FilterContainerComponent } from "./filter-container/filter-container.component";
import { IconCurrencyComponent } from "./icon-currency/icon-currency.component";
import { IconComponent } from "./icon/icon.component";
import { ListLoadMoreSmallComponent } from "./list-load-more-small/list-load-more-small.component";
import { ListLoadMoreComponent } from "./list-load-more/list-load-more.component";
import { ListUtilityRowComponent } from "./list-utility-row/list-utility-row.component";
import { ListViewControlsComponent } from "./list-view-controls/list-view-controls.component";
import { MicroPageComponent } from "./micro-page/micro-page.component";
import { MobileCardComponent } from "./mobile-card/mobile-card.component";
import { NpsIndicatorComponent } from "./nps-indicator/nps-indicator.component";
import { ObjectCardComponent } from "./object-card/object-card.component";
import { PageNavBarFiltersComponent } from "./page-nav-bar-filters/page-nav-bar-filters.component";
import { PageNavBarItemAnchorComponent } from "./page-nav-bar-item-anchor/page-nav-bar-item-anchor.component";
import { PageNavBarItemDropdownComponent } from "./page-nav-bar-item-dropdown/page-nav-bar-item-dropdown.component";
import { PageNavBarItemIconComponent } from "./page-nav-bar-item-icon/page-nav-bar-item-icon.component";
import { PageNavBarItemLabelComponent } from "./page-nav-bar-item-label/page-nav-bar-item-label.component";
import { PageNavBarItemComponent } from "./page-nav-bar-item/page-nav-bar-item.component";
import { PageNavBarComponent } from "./page-nav-bar/page-nav-bar.component";
import { PageTabBarComponent } from "./page-tab-bar/page-tab-bar.component";
import { BackslashPipe } from "./pipes/backslash.pipe";
import { MomentifyPipe } from "./pipes/momentify.pipe";
import { MomentifyCustomFormatPipe } from "./pipes/momentifyCustomFormat.pipe";
import { MomentifyFromUtcPipe } from "./pipes/momentifyFromUtc.pipe";
import { Nl2brPipe } from "./pipes/nl2br.pipe";
import { PhoneFormatPipe } from "./pipes/phone-format.pipe";
import { FilterContactTypeFromObjectContactsPipe } from "./pipes/property-pipes/filter-contact-type-from-object-contacts.pipe";
import { FormatDateTypeNamePipe } from "./pipes/property-pipes/format-date-type-name.pipe";
import { FormatAddressPipe } from "./pipes/property-pipes/format-address.pipe";
import { SubpageTypeToIconPipe } from "./pipes/subpage-type-to-icon.pipe";
import { SubpageTypeToRouteEndpointPipe } from "./pipes/subpage-type-to-route-endpoint.pipe";
import { ThousandSeparatorPipe } from "./pipes/thousand-separator.pipe";
import { QBrokerMultiSelectComponent } from "./q-broker-multi-select/q-broker-multi-select.component";
import { QButtonComponent } from "./q-button/q-button.component";
import { QCardSeconaryTextComponent } from "./q-card/components/q-card-seconary-text/q-card-seconary-text.component";
import { QCardComponent } from "./q-card/q-card.component";
import { QChipComponent } from "./q-chip/q-chip.component";
import { QCircleBtnComponent } from "./q-circle-btn/q-circle-btn.component";
import { QContactSelectComponent } from "./q-contact-select/q-contact-select.component";
import { QControlErrorsComponent } from "./q-control-errors/q-control-errors.component";
import { QDatePickerComponent } from "./q-date-picker/q-date-picker.component";
import { QFormGroupHeadingComponent } from "./q-form-group-heading/q-form-group-heading.component";
import { QInputNumberComponent } from "./q-input-number/q-input-number.component";
import { QInputThousandSeparatorComponent } from "./q-input-thousand-separator/q-input-thousand-separator.component";
import { QInputComponent } from "./q-input/q-input.component";
import { QModalComponent } from "./q-modal/q-modal.component";
import { FindPipe } from "./q-multi-select/pipes/find.pipe";
import { QMultiSelectComponent } from "./q-multi-select/q-multi-select.component";
import { QObjectSelectComponent } from "./q-object-select/q-object-select.component";
import { QOfficeMultiSelectComponent } from "./q-office-multi-select/q-office-multi-select.component";
import { QSearchContactComponent } from "./q-search-contact/q-search-contact.component";
import { QSearchListItemComponent } from "./q-search-input/components/q-search-list-item/q-search-list-item.component";
import { QSearchInputComponent } from "./q-search-input/q-search-input.component";
import { QSearchObjectComponent } from "./q-search-object/q-search-object.component";
import { QSegmentedControlsComponent } from "./q-segmented-controls/q-segmented-controls.component";
import { QSelectComponent } from "./q-select/q-select.component";
import { QSheetWrapperComponent } from "./q-sheet-wrapper/q-sheet-wrapper.component";
import { QTabBarComponent } from "./q-tab-bar/q-tab-bar.component";
import { AddAllToPaginatorDirective } from "./q-table/add-all-to-paginator.directive";
import { CustomizeColumnsModalComponent } from "./q-table/components/customize-columns-modal/customize-columns-modal.component";
import { QActionDropdownItemComponent } from "./q-table/components/q-action-dropdown-item/q-action-dropdown-item.component";
import { QActionDropdownComponent } from "./q-table/components/q-action-dropdown/q-action-dropdown.component";
import { QTableComponent } from "./q-table/q-table.component";
import { QTextareaComponent } from "./q-textarea/q-textarea.component";
import { ResetActivatedRouteParamsComponent } from "./reset-activated-route-params/reset-activated-route-params.component";
import { ResetFilterComponent } from "./reset-filter/reset-filter.component";
import { ScrollTopComponent } from "./scroll-top/scroll-top.component";
import { SegmentedControlsComponent } from "./segmented-controls/segmented-controls.component";
import { SlideToggleComponent } from "./slide-toggle/slide-toggle.component";
import { SortByActivatedRouteParamsComponent } from "./sort-by-activated-route-params/sort-by-activated-route-params.component";
import { SortByColumnComponent } from "./sort-by-column/sort-by-column.component";
import { SpinnerComponent } from "./spinner/spinner.component";
import { StandaloneSidebarContentComponent } from "./standalone-sidebar-content/standalone-sidebar-content.component";
import { StandaloneSidebarHeaderComponent } from "./standalone-sidebar-header/standalone-sidebar-header.component";
import { StandaloneSidebarComponent } from "./standalone-sidebar/standalone-sidebar.component";
import { SurveyIndicatorComponent } from "./survey-indicator/survey-indicator.component";
import { TabViewBarComponent } from "./tab-view-bar/tab-view-bar.component";
import { TableSortHeaderComponent } from "./table-sort/table-sort-header/table-sort-header.component";
import { TableSortDirective } from "./table-sort/table-sort.directive";
import { TagComponent } from "./tag/tag.component";
import { TooltipIconComponent } from "./tooltip-icon/tooltip-icon.component";
import { DeswinglisherModalComponent } from "@app/shared/modules/ui-components/translation-text/deswinglisher-modal/deswinglisher-modal.component";
import { TranslationTextComponent } from "@app/shared/modules/ui-components/translation-text/translation-text.component";
import { ColorPickerModule } from "ngx-color-picker";
import { QLanguageDropdownComponent } from "@app/shared/modules/ui-components/q-language-dropdown/q-language-dropdown.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FilterTemplatesManagerComponent } from "@app/shared/modules/ui-components/filter-templates-manager/filter-templates-manager.component";
import { MatButtonModule } from "@angular/material/button";
import { MenuPanelDirective } from "@app/core/directives/menu-panel.directive";

const components = [
  SpinnerComponent,
  ScrollTopComponent,
  SlideToggleComponent,
  DetailCardComponent,
  SkeletonDetailCardComponent,
  CardComponent,
  PageNavBarComponent,
  PageTabBarComponent,
  SortByActivatedRouteParamsComponent,
  StandaloneSidebarComponent,
  StandaloneSidebarHeaderComponent,
  StandaloneSidebarContentComponent,
  StandaloneSidebarFooterComponent,
  PageNavBarFiltersComponent,
  PageNavBarItemComponent,
  PageNavBarItemIconComponent,
  PageNavBarItemLabelComponent,
  PageNavBarItemAnchorComponent,
  PageNavBarItemDropdownComponent,
  SortByColumnComponent,
  ResetActivatedRouteParamsComponent,
  TableSortHeaderComponent,
  TableSortDirective,
  ResetFilterComponent,
  NpsIndicatorComponent,
  NotAvilableInMobileComponent,
  SurveyIndicatorComponent,
  ListLoadMoreComponent,
  ListLoadMoreSmallComponent,
  CheckboxSelectComponent,
  SmallBackButtonComponent,
  ProgressCircleComponent,
  ActionsMenuSheetComponent,
  LocationFilterComponent,
  QModalComponent,
  QButtonComponent,
  QTextareaComponent,
  QInputNumberComponent,
  ObjectCardComponent,
  ContactCardComponent,
  ControlsBoxComponent,
  ContainerBoxComponent,
  TagComponent,
  ExpandableTextComponent,
  ListUtilityRowComponent,
  MicroPageComponent,
  ActionsMenuComponent,
  SegmentedControlsComponent,
  MobileCardComponent,
  FilterContainerComponent,
  IconComponent,
  ListViewControlsComponent,
  TabViewBarComponent,
  TooltipIconComponent,
  BreadcrumbComponent,
  ButtonComponent,
  BreadcrumbComponent,
  QTableComponent,
  QTabBarChipsComponent,
  CustomizeColumnsModalComponent,
  QActionDropdownComponent,
  QActionDropdownItemComponent,
  QChipComponent,
  QCircleBtnComponent,
  QInputComponent,
  QSelectComponent,
  QDatePickerComponent,
  QControlErrorsComponent,
  QChipRoundedComponent,
  QInputThousandSeparatorComponent,
  QSearchContactComponent,
  QCardComponent,
  QCardSeconaryTextComponent,
  QSearchObjectComponent,
  QFormGroupHeadingComponent,
  QSearchInputComponent,
  QSearchListItemComponent,
  QSegmentedControlsComponent,
  QTabBarComponent,
  QMultiSelectComponent,
  QContactSelectComponent,
  QObjectSelectComponent,
  QOfficeMultiSelectComponent,
  QBrokerMultiSelectComponent,
  QSheetWrapperComponent,
  IconCurrencyComponent,
  StatisticsAdfenixReportingTableComponent,
  TranslationTextComponent,
  DeswinglisherModalComponent,
  QLanguageDropdownComponent,
];

const pipes = [
  SafePipe,
  ThousandSeparatorPipe,
  PhoneFormatPipe,
  Nl2brPipe,
  BackslashPipe,
  MomentifyPipe,
  MomentifyCustomFormatPipe,
  MomentifyFromNowPipe,
  MomentifyFromApiFormatPipe,
  InsertUnderscoreAndLowerCasePipe,
  MomentifyFromUtcPipe,
  SubpageTypeToRouteEndpointPipe,
  SubpageTypeToIconPipe,
  FormatDateTypeNamePipe,
  FormatAddressPipe,
  FilterContactTypeFromObjectContactsPipe,
  FindPipe,
  FilterTemplatesManagerComponent,
];

const directives = [AddAllToPaginatorDirective, MenuPanelDirective];

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MomentModule,
    TranslateModule,
    SkeletonScreenModule,
    BsDropdownModule,
    ModalModule,
    UtilModule,
    PopoverModule,
    ReactiveFormsModule,
    MatCardModule,
    MatListModule,
    TooltipModule,
    MatBadgeModule,
    MatChipsModule,
    MatTableModule,
    MatDialogModule,
    MatCheckboxModule,
    MatPaginatorModule,
    SortablejsModule,
    MatSortModule,
    ClickOutsideModule,
    LetModule,
    PushModule,
    ClickStopPropagationModule,
    MatTooltipModule,
    NgxErrorsModule,
    BsDatepickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgxErrorsModule,
    MatMenuModule,
    ColorPickerModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
  ],
  declarations: [...components, ...pipes, ...directives],
  exports: [...components, ...pipes, ...directives],
  providers: [
    PhoneFormatPipe,
    ThousandSeparatorPipe,
    TranslatePipe,
    provideNgxMask(),
    { provide: Window, useValue: window },
  ],
})
export class UiComponentsModule {}
