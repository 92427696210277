<div
  id="header"
  class="navbar-fixed-top"
  [@isNavVisibleChanged]="isNavbarVisible"
  [@sidebarAnimationStates]="sidebarAnimationStates"
>
  <div class="header-controls">
    <div class="header-logo-wrapper">
      <a href="/crm">
        <img [src]="logoUrl$ | async" class="header-logo" height="40px" />
      </a>
    </div>
    <div class="items items-left hidden-lg">
      <div class="item" (click)="toggleNavbar()">
        <i
          *ngIf="!isNavbarVisible"
          class="hamburger fa fa-bars default-hamburger-icon"
        ></i>
        <i
          *ngIf="isNavbarVisible"
          class="hamburger fa fa-close close-hamburger-icon"
        ></i>
      </div>
      <div class="item hidden-md hidden-lg ml-1">
        <i (click)="showOverlay()" class="fa fa-search"> </i>
      </div>
    </div>
    <div class="back-btn-container" *ngIf="backLink$ | async as backLink">
      <a
        class="back-btn"
        matTooltip="{{ 'back' | translate }}"
        matTooltipClass="q-tooltip"
        (click)="onBackLinkClick(backLink)"
      >
        <i class="fa fa-long-arrow-left"></i>
      </a>
    </div>
  </div>

  <search
    class="search visible-md visible-lg"
    (inputClicked)="showOverlay()"
    [placeholder]="'search_placeholder' | translate"
    [readonly]="true"
  >
  </search>
  <div id="header__actions" class="items items-right">
    <div>
      <app-progress-widget></app-progress-widget>
    </div>
    <product-news *ngIf="isProductNewsEnabled"></product-news>
    <div>
      <calendar-widget-modal [date]="dateToday" [textOnly]="false">
      </calendar-widget-modal>
    </div>
    <a
      id="header__create-cta"
      class="btn btn-primary btn-circle"
      [routerLink]="[{ outlets: { sidebar: ['root', 'create'] } }]"
      (click)="showSidebar()"
    >
      <span>+</span>
    </a>
    <section id="header__profile" *ngIf="employee$ | async as employee">
      <span id="header__profile__firstName"
        >{{ "hi" | translate }}, {{ employee.getFirstName() }}!</span
      >
      <a
        [routerLink]="[{ outlets: { sidebar: ['root', 'profile'] } }]"
        (click)="showSidebar()"
      >
        <div
          id="header__profile-bg"
          *ngIf="employee.employeePhoto as src; else initials"
          [class]="
            (isViewingAsSomeoneElse$ | async)
              ? 'loading-pulse border-danger'
              : ''
          "
          [matTooltip]="
            (isViewingAsSomeoneElse$ | async)
              ? ('warning_logged_in_as_someone_else'
                | translate : { name: (employee$ | async)?.employeeFullName })
              : ''
          "
          matTooltipClass="q-tooltip"
        >
          <figure
            [ngStyle]="{
              'background-image':
                'url(' +
                employee.employeePhoto +
                '), url(' +
                '/assets/img/img_error_placeholder.png' +
                ')'
            }"
          ></figure>
        </div>
        <ng-template #initials>
          <span
            id="header__profile__login-button"
            class="btn-circle"
            [class]="
              (isViewingAsSomeoneElse$ | async)
                ? 'loading-pulse border-danger'
                : ''
            "
            [matTooltip]="
              (isViewingAsSomeoneElse$ | async)
                ? ('warning_logged_in_as_someone_else'
                  | translate : { name: (employee$ | async)?.employeeFullName })
                : ''
            "
            matTooltipClass="q-tooltip"
            >{{ employee.getInitials() }}</span
          >
        </ng-template>
      </a>
    </section>
  </div>
</div>
