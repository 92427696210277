<div class="q-modal">
  <div mat-dialog-title class="q-modal-header">
    <h3 class="q-modal-title" translate>customize_columns</h3>
    <button class="q-modal-close" (click)="close()">
      <app-icon name="times" size="x-large"></app-icon>
    </button>
  </div>
  <mat-dialog-content class="q-modal-content">
    <div>
      <ul class="sortable-list" [sortablejs]="columns">
        <li class="sortable-item" *ngFor="let checkbox of columns">
          <mat-checkbox [formControl]="checkbox.control">{{
            checkbox.label | translate
          }}</mat-checkbox>
          <app-icon name="grip-lines" size="medium" type="regular"></app-icon>
        </li>
      </ul>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="q-modal-actions">
    <div class="btn-container">
      <app-q-button
        buttonType="cancel"
        label="cancel"
        classes="margin-right"
        (click)="close()"
      ></app-q-button>
      <app-q-button
        buttonType="save"
        label="save"
        (click)="save()"
      ></app-q-button>
    </div>
  </mat-dialog-actions>
</div>
