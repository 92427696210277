import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { SalesMeeting } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const refreshed = createAction("[Contact Sales Meeting] REFRESHED");

export const getSalesMeetingsRequest = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETINGS_REQUEST",
  props<{ contactId: string }>()
);

export const getSalesMeetingsSuccess = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETINGS_SUCCESS",
  props<{ salesMeetings: SalesMeeting[] }>()
);

export const getSalesMeetingsFailed = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETINGS_FAILED"
);

export const getSalesMeetingDetailsRequest = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETING_DETAILS_REQUEST",
  props<{ eaCrmSalesMeetingId: string }>()
);

export const getSalesMeetingDetailsSuccess = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETING_DETAILS_SUCCESS",
  props<{ salesMeeting: SalesMeeting }>()
);

export const getSalesMeetingDetailsFailed = createAction(
  "[Contact Sales Meeting] GET_SALES_MEETING_DETAILS_FAILED"
);

export const deleteSalesMeetingRequest = createAction(
  "[Contact Sales Meeting] DELETE_SALES_MEETING_REQUEST",
  props<{ eaCrmSalesMeetingId: string }>()
);

export const deleteSalesMeetingSuccess = createAction(
  "[Contact Sales Meeting] DELETE_SALES_MEETING_SUCCESS"
);

export const deleteSalesMeetingFailed = createAction(
  "[Contact Sales Meeting] DELETE_SALES_MEETING_FAILED"
);

export const getExternalProviderLinkRequest = createAction(
  "[Contact Sales Meeting] GET_EXTERNAL_PROVIDER_LINK_REQUEST",
  props<{ eaOid: string }>()
);

export const getExternalProviderLinkSuccess = createAction(
  "[Contact Sales Meeting] GET_EXTERNAL_PROVIDER_LINK_SUCCESS",
  props<{ externalProviderLink: ExternalPresentableLink | null }>()
);

export const getExternalProviderLinkFailed = createAction(
  "[Contact Sales Meeting] GET_EXTERNAL_PROVIDER_LINK_FAILED"
);

export const noEditModalShow = createAction(
  "[Contact Sales Meeting] NO_EDIT_MODAL_SHOW",
  props<{ salesMeeting: SalesMeeting }>()
);

export const createPrefilledForm = createAction(
  "[Contact Sales Meeting] CREATE_PREFILLED_FORM",
  props<{ contactId: string; eaOid: string }>()
);

export const createPrefilledFormSuccess = createAction(
  "[Contact Sales Meeting] CREATE_PREFILLED_FORM_SUCCESS"
);

export const createPrefilledFormFail = createAction(
  "[Contact Sales Meeting] CREATE_PREFILLED_FORM_FAIL",
  props<{ error: string }>()
);

export const reset = createAction("[Contact Sales Meeting] RESET");
