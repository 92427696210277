import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { UrlService } from "@app/core/services/url/url.service";
import { Task } from "@app/models";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { Observable } from "rxjs";
import * as search from "../../ngrx/search.actions";

@Component({
  selector: "search-lead-card",
  templateUrl: "./lead-card.component.html",
  styleUrls: ["./lead-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchLeadCardComponent implements OnInit {
  @Input() lead: Task;
  isMobile$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private taskTypeResolver: TaskTypeResolverService,
    private urlService: UrlService
  ) {}

  ngOnInit() {
    this.isMobile$ = this.store.pipe(select(isMobile));
  }

  getLeadRoute(): any[] {
    return this.taskTypeResolver.getTaskRoute(this.lead);
  }

  onClick(): void {
    this.store.dispatch(search.hide());
  }

  formatDate(insertedDate: string) {
    return moment(insertedDate, "YYYYMMDDHHmmss").toDate();
  }

  openInNewTab(e: Event): void {
    e.stopPropagation();
    const url = this.urlService.buildUrlFromRouterCommands(
      this.taskTypeResolver.getTaskRoute(this.lead)
    );
    openLinkInNewTab(url);
  }
}
