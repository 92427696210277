import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map } from "rxjs";

import { AppState } from "@app/app.reducer";
import {
  getEmployeeEmailConfirmationTemplateId,
  getOfficeEmailConfirmationTemplateId,
  getSendModuleId,
} from "@app/shared/config/config.reducer";

@Component({
  selector: "app-lead-templates-form",
  templateUrl: "./lead-templates-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-templates-form.component.scss",
  ],
})
export class LeadTemplatesFormComponent implements OnInit, OnChanges {
  @Input() template;

  form: FormGroup;
  hours = [0, 1, 2, 3, 4, 6, 8, 12, 16, 24, 32, 48];
  officeRules: Record<string, string>[] = [
    { label: "based_on_request", value: "Default" },
    {
      label: "connect_to_closest_office",
      value: "ConnectToClosestOffice",
    },
  ];
  employeeRules: Record<string, string>[] = [
    { label: "based_on_request", value: "Default" },
    {
      label: "connect_task_to_object_employee",
      value: "ConnectTaskToObjectEmployee",
    },
  ];

  config$;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template && !!this.template) {
      this.fillForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      source: ["", Validators.required],
      title: ["", Validators.required],
      officeManagerExclusiveEndDate: [0],
      deliveryDate: [0],
      expirationDate: [0],
      useCustomerOfficeHours: [false],
      officeRule: ["Default"],
      employeeRule: ["Default"],
    });
  }

  mapStateToProps() {
    this.config$ = combineLatest([
      this.store.pipe(select(getSendModuleId)),
      this.store.pipe(select(getOfficeEmailConfirmationTemplateId)),
      this.store.pipe(select(getEmployeeEmailConfirmationTemplateId)),
    ]).pipe(
      map((config) => {
        const isBlank = (str) => !str || /^\s*$/.test(str);
        return config.some((x) => isBlank(x)) ? null : config;
      })
    );
  }

  fillForm() {
    const {
      originService,
      title,
      officeManagerExclusiveEndDate,
      deliveryDate,
      expirationDate,
      useCustomerOfficeHours,
      officeRule,
      employeeRule,
    } = this.template;
    this.form.patchValue({
      source: originService,
      title,
      ...(officeManagerExclusiveEndDate != null && {
        officeManagerExclusiveEndDate,
      }),
      ...(deliveryDate != null && { deliveryDate }),
      ...(expirationDate != null && { expirationDate }),
      useCustomerOfficeHours: useCustomerOfficeHours?.toLowerCase() === "true",
      officeRule,
      employeeRule,
    });
  }

  submit(cb: (body: any) => void) {
    if (this.form.valid) {
      this.config$.pipe(first()).subscribe((config) => {
        if (config) {
          const { source, useCustomerOfficeHours, ...rest } = this.form.value;
          cb({
            sendModuleId: config[0],
            officeEmailConfirmationTemplateId: config[1],
            employeeEmailConfirmationTemplateId: config[2],
            originService: source,
            useCustomerOfficeHours: useCustomerOfficeHours ? "true" : "false",
            ...rest,
          });
        }
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
