import { QObject } from "@app/models";
import { Recipient } from "@app/sidebar/send-message/models/recipient";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as _ from "lodash";
import { DynamicContent, Template, TemplateGroups } from "../models";
import * as sendMessageActions from "./send-message.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export type MessageType = "sms" | "email";

export interface SendMessageState {
  messageType: MessageType | null;
  templateGroupName: string | null;
  templates: Template[] | null;
  dynamicContents: DynamicContent[] | null;
  recipients: Recipient[] | null;
  emails: string[];
  mobileNumbers: string[];
  processing: boolean;
  errors: { [key: string]: any };
  extraDataSet: { [key: string]: string };
  unSoldObjectsForEmployee: QObject[];
  soldObjectsForEmployee: QObject[];
  unSoldObjectsForOffice: QObject[];
  soldObjectsForOffice: QObject[];
  loadingUnSoldObjects: boolean;
  loadingSoldObjects: boolean;
  eaOid?: string;
  checkConsent: boolean;
}

export const initialState: SendMessageState = {
  messageType: "sms",
  templateGroupName: TemplateGroups.General,
  templates: null,
  dynamicContents: null,
  recipients: [],
  emails: [],
  mobileNumbers: [],
  processing: false,
  errors: {},
  extraDataSet: {},
  unSoldObjectsForEmployee: [],
  soldObjectsForEmployee: [],
  unSoldObjectsForOffice: [],
  soldObjectsForOffice: [],
  loadingUnSoldObjects: false,
  loadingSoldObjects: false,
  eaOid: null,
  checkConsent: false,
};

export function sendMessageReducer(
  state: SendMessageState,
  action: Action
): SendMessageState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(sendMessageActions.setMessageType, (state, { messageType }) => ({
    ...state,
    messageType,
  })),
  on(sendMessageActions.setEaOid, (state, { eaOid }) => ({
    ...state,
    eaOid,
  })),
  on(sendMessageActions.setTemplateGroup, (state, { templateGroupName }) => ({
    ...state,
    templateGroupName,
  })),
  on(sendMessageActions.setExtraDataSet, (state, { extraDataSet }) => ({
    ...state,
    extraDataSet,
  })),
  on(sendMessageActions.addContacts, (state, { recipients }) => {
    const ids = state.recipients.map((r) => r.contactId);
    const newRecipients = recipients.filter(
      (recipient) => !ids.includes(recipient.contactId)
    );
    return {
      ...state,
      recipients: state.recipients.concat(newRecipients),
    };
  }),
  on(sendMessageActions.removeContacts, (state, { contactIds }) => {
    const idsToRemove = contactIds;
    return {
      ...state,
      recipients: state.recipients.filter(
        (c) => !_.includes(idsToRemove, c.contactId)
      ),
    };
  }),
  on(sendMessageActions.replaceContacts, (state, { recipients }) => ({
    ...state,
    recipients,
  })),
  on(sendMessageActions.addEmail, (state, { email }) => ({
    ...state,
    emails: state.emails.concat(email),
  })),
  on(sendMessageActions.removeEmail, (state, { email }) => ({
    ...state,
    emails: state.emails.filter((e_mail) => e_mail !== email),
  })),
  on(sendMessageActions.removeAllEmails, (state) => ({
    ...state,
    emails: [],
  })),
  on(sendMessageActions.addMobileNumber, (state, { mobileNumber }) => ({
    ...state,
    mobileNumbers: state.mobileNumbers.concat(mobileNumber),
  })),
  on(sendMessageActions.removeMobileNumber, (state, { mobileNumber }) => ({
    ...state,
    mobileNumbers: state.mobileNumbers.filter(
      (mobile) => mobile !== mobileNumber
    ),
  })),
  on(sendMessageActions.removeAllMobileNumbers, (state) => ({
    ...state,
    mobileNumbers: [],
  })),
  on(sendMessageActions.fetchTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
  })),
  on(
    sendMessageActions.fetchDynamicContentSuccess,
    (state, { dynamicContents }) => ({
      ...state,
      dynamicContents,
      errors: { ..._.omit(state.errors, ["dynamicContents"]) },
    })
  ),
  on(sendMessageActions.dynamicContentNotFound, (state) => ({
    ...state,
    dynamicContents: null,
    errors: {
      ...state.errors,
      dynamicContents: new Error("Dynamic content not configured"),
    },
  })),
  on(sendMessageActions.updateContactSuccess, (state, { contact }) => {
    const index = state.recipients.findIndex(
      (r) => r.contactId === contact.contactId
    );
    const recipients = [...state.recipients];
    recipients[index] = contact;
    return { ...state, recipients };
  }),
  on(sendMessageActions.sendMessageRequest, (state) => ({
    ...state,
    processing: false,
  })),
  on(
    sendMessageActions.sendMessageSuccess,
    sendMessageActions.sendMessageFail,
    (state) => ({ ...state, processing: false })
  ),
  on(sendMessageActions.reset, (state) => {
    const currentMessageType = state.messageType;
    return { ...initialState, messageType: currentMessageType };
  }),
  on(
    sendMessageActions.getUnsoldObjectsForEmployeeRequest,
    sendMessageActions.getUnsoldObjectsForOfficeRequest,
    (state) => ({ ...state, loadingUnSoldObjects: true })
  ),
  on(
    sendMessageActions.getUnsoldObjectsForEmployeeSuccess,
    (state, { objects }) => ({
      ...state,
      loadingUnSoldObjects: false,
      unSoldObjectsForEmployee: objects.filter((obj) => !!obj.street),
    })
  ),
  on(
    sendMessageActions.getUnsoldObjectsForOfficeSuccess,
    (state, { objects }) => ({
      ...state,
      loadingUnSoldObjects: false,
      unSoldObjectsForOffice: objects.filter((obj) => !!obj.street),
    })
  ),
  on(
    sendMessageActions.getUnsoldObjectsForEmployeeFail,
    sendMessageActions.getUnsoldObjectsForOfficeFail,
    (state) => ({ ...state, loadingUnSoldObjects: false })
  ),
  on(
    sendMessageActions.getSoldObjectsForEmployeeRequest,
    sendMessageActions.getSoldObjectsForOfficeRequest,
    (state) => ({ ...state, loadingSoldObjects: true })
  ),
  on(
    sendMessageActions.getSoldObjectsForEmployeeSuccess,
    (state, { objects }) => ({
      ...state,
      loadingSoldObjects: false,
      soldObjectsForEmployee: objects,
    })
  ),
  on(
    sendMessageActions.getSoldObjectsForOfficeSuccess,
    (state, { objects }) => ({
      ...state,
      loadingSoldObjects: false,
      soldObjectsForOffice: objects,
    })
  ),
  on(
    sendMessageActions.getSoldObjectsForEmployeeFail,
    sendMessageActions.getSoldObjectsForOfficeFail,
    (state) => ({ ...state, loadingSoldObjects: false })
  ),
  on(sendMessageActions.setCheckConsent, (state, { checkConsent }) => ({
    ...state,
    checkConsent,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.sendMessage
);

export const getMessageType = createSelector(
  getState,
  (state: SendMessageState) => state.messageType
);
export const getTemplateGroupName = createSelector(
  getState,
  (state: SendMessageState) => state.templateGroupName
);
export const getTemplates = createSelector(
  getState,
  (state: SendMessageState) => state.templates
);
export const getDynamicContents = createSelector(
  getState,
  (state: SendMessageState) => state.dynamicContents
);
export const getRecipients = createSelector(
  getState,
  (state: SendMessageState) => state.recipients
);
export const getEmails = createSelector(
  getState,
  (state: SendMessageState) => state.emails
);
export const getMobileNumbers = createSelector(
  getState,
  (state: SendMessageState) => state.mobileNumbers
);
export const getProcessing = createSelector(
  getState,
  (state: SendMessageState) => state.processing
);
export const getErrors = createSelector(
  getState,
  (state: SendMessageState) => state.errors
);
export const getExtraDataSet = createSelector(
  getState,
  (state: SendMessageState) => state.extraDataSet
);
export const getUnSoldObjectsForEmployee = createSelector(
  getState,
  (state: SendMessageState) => state.unSoldObjectsForEmployee
);
export const getSoldObjectsForEmployee = createSelector(
  getState,
  (state: SendMessageState) => state.soldObjectsForEmployee
);
export const getUnSoldObjectsForOffice = createSelector(
  getState,
  (state: SendMessageState) => state.unSoldObjectsForOffice
);
export const getSoldObjectsForOffice = createSelector(
  getState,
  (state: SendMessageState) => state.soldObjectsForOffice
);
export const getSoldObjectsLoading = createSelector(
  getState,
  (state: SendMessageState) => state.loadingSoldObjects
);
export const getUnSoldObjectsLoading = createSelector(
  getState,
  (state: SendMessageState) => state.loadingUnSoldObjects
);
export const getEaOid = createSelector(
  getState,
  (state: SendMessageState) => state.eaOid
);
export const getCheckConsent = createSelector(
  getState,
  (state: SendMessageState) => state.checkConsent
);
