import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";

import { AppState } from "@app/app.reducer";
import * as objectNotesActions from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.actions";

@Component({
  selector: "app-create-note",
  templateUrl: "./create-note.component.html",
  styleUrls: ["./create-note.component.scss"],
})
export class CreateNoteComponent implements OnInit {
  @Input() eaOid: string;

  @Output() isSubmitted = new EventEmitter<any>();

  form: FormGroup;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      title: ["", Validators.required],
      content: ["", Validators.required],
    });
  }

  onSubmit() {
    if (this.form.valid) {
      this.store.dispatch(
        objectNotesActions.createNoteRequest({
          eaOid: this.eaOid,
          body: this.form.value,
        })
      );
      this.isSubmitted.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }
}
