import { Pipe, PipeTransform } from "@angular/core";
import { PotentialBuyer } from "@app/showings/models";

@Pipe({
  name: "filterContactTypeFromObjectContacts",
})
export class FilterContactTypeFromObjectContactsPipe implements PipeTransform {
  transform(contacts: PotentialBuyer[], type: string): unknown {
    return contacts.filter((c) => c.type === type);
  }
}
