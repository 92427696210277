import { NpsEntity } from "@app/models/nps";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  select,
} from "@ngrx/store";
import * as _ from "lodash";
import { map, pipe } from "rxjs";
import { Employee } from "@app/models";
import * as kpiActions from "./kpi.actions";
import { Kpi } from "../models/kpi";

export interface KpiState {
  title: string;
  kpis: {
    [key: string]: any;
  };
  kpiLoading: boolean;
  nps: NpsEntity | null;
  npsLoading: boolean;
  details: Record<string, unknown>;
  summary: Kpi;
  employee: Employee;
}

export const initialState: KpiState = {
  title: "",
  kpis: {},
  kpiLoading: false,
  nps: null,
  npsLoading: false,
  details: {},
  summary: null,
  employee: null,
};

export function kpiReducer(state: KpiState, action: Action): KpiState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(kpiActions.fetchKpis, (state) => ({
    ...state,
    kpis: {},
    kpiLoading: true,
  })),
  on(kpiActions.fetchKpisSuccess, (state, { kpis }) => ({
    ...state,
    kpis: _.keyBy(kpis, "kpiType"),
    kpiLoading: false,
  })),
  on(kpiActions.fetchKpiSummarySuccess, (state, { kpi }) => ({
    ...state,
    summary: kpi,
  })),
  on(
    kpiActions.fetchKpiDetailsSuccess,
    (state, { parameters: { type, details } }) => ({
      ...state,
      details: { ...state.details, [type]: details },
    })
  ),
  on(kpiActions.fetchEmployeeRequest, (state) => ({
    ...state,
    employee: null,
  })),
  on(kpiActions.fetchEmployeeSuccess, (state, { employee }) => ({
    ...state,
    employee,
  })),
  on(kpiActions.fetchEmployeeNpsRequest, (state) => ({
    ...state,
    npsLoading: true,
  })),
  on(kpiActions.fetchEmployeeNpsSuccess, (state, { nps }) => ({
    ...state,
    npsLoading: false,
    nps,
  })),
  on(kpiActions.fetchEmployeeNpsFail, (state) => ({
    ...state,
    npsLoading: false,
  })),
  on(kpiActions.fetchNpsSurveyResponsesRequest, (state) => ({
    ...state,
    npsLoading: true,
  })),
  on(
    kpiActions.fetchNpsSurveyResponsesSuccess,
    (state, { response: { type, details } }) => ({
      ...state,
      details: { ...state.details, [type]: details },
      npsLoading: false,
    })
  ),
  on(kpiActions.setTitle, (state, { title }) => ({ ...state, title })),
  on(kpiActions.resetState, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<KpiState>("kpi");

export const getKpis = createSelector(
  selectFeature,
  (state: KpiState) => state.kpis
);

export const getKpiLoading = createSelector(
  selectFeature,
  (state: KpiState) => state.kpiLoading
);

export const getKpiDetails = createSelector(
  selectFeature,
  (state: KpiState) => state.details
);

export const getKpiDetailsByType = (type: string) => {
  return pipe(
    select(getKpiDetails),
    map((details) => (_.isEmpty(details) ? [] : details[type]))
  );
};

export const getTitle = createSelector(
  selectFeature,
  (state: KpiState) => state.title
);

export const getSummary = createSelector(
  selectFeature,
  (state: KpiState) => state.summary
);

export const getEmployee = createSelector(
  selectFeature,
  (state: KpiState) => state.employee
);

export const getNps = createSelector(
  selectFeature,
  (state: KpiState) => state.nps
);

export const getNpsLoading = createSelector(
  selectFeature,
  (state: KpiState) => state.npsLoading
);
