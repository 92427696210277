import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "list-load-more",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./list-load-more.component.scss",
  ],
  template: `
    <li
      class="list-load-more flex-center"
      [style.backgroundColor]="invertColors && 'transparent'"
      [class.disabled]="disabled"
      (click)="onClick()"
    >
      <div [class]="'list-load-more__button ' + size">
        <spinner *ngIf="disabled; else content" [size]="'1rem'"></spinner>
        <ng-template #content>
          <span *ngIf="!text; else customContent"
            >{{ "load_more" | translate }}
            {{ leftToLoad && "(" + (leftToLoad | qNumber) + ")" }}</span
          >
        </ng-template>
        <ng-template #customContent>
          <span>{{ text | translate }}</span>
        </ng-template>
      </div>
    </li>
  `,
})
export class ListLoadMoreComponent {
  @Input() disabled = false;
  @Input() leftToLoad: number;
  @Input() text: string;
  @Input() size: "large" | "medium" = "medium";
  @Input() invertColors = false;
  @Output() loadMore = new EventEmitter();

  onClick() {
    if (!this.disabled) {
      this.loadMore.emit({});
    }
  }
}
