import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";

import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { ResourceModule } from "@app/shared/modules/resource/resource.module";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ListComponentsModule } from "../list-components/list.module";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { UtilModule } from "../util/util.module";
import { ActionListItemComponent } from "./action-list-item/action-list-item.component";
import * as fromCommon from "./action-list-item/common";
import { ActionListComponent } from "./action-list.component";
import { ActionListItemService } from "./services/action-list-item.service";
import { ActionService } from "./services/action.service";

const components = [
  ActionListComponent,
  ActionListItemComponent,
  fromCommon.ActionCallRegisteredComponent,
  fromCommon.ActionConsumerCreatedComponent,
  fromCommon.ActionEmployeeConnectedComponent,
  fromCommon.ActionEmployeeDisconnectedComponent,
  fromCommon.ActionOfficeConnectedComponent,
  fromCommon.ActionCrmSentComponent,
  fromCommon.ActionLeadConnectedComponent,
  fromCommon.ActionLeadDeletedComponent,
  fromCommon.ActionLeadResolvedComponent,
  fromCommon.ConsumerMeetingStartedComponent,
  fromCommon.ActionMortgageDeletedComponent,
  fromCommon.ActionMortageStoredComponent,
  fromCommon.ActionNoteStoredComponent,
  fromCommon.ActionObjectConnectedComponent,
  fromCommon.ActionConsumerShowingConnectedComponent,
  fromCommon.ActionObjectEmailComponent,
  fromCommon.ActionObjectNoteStoredComponent,
  fromCommon.ActionOne2OneEmailComponent,
  fromCommon.ActionOne2OneSmsComponent,
  fromCommon.ActionRatingInformationComponent,
  fromCommon.ActionSalesMeetingCreatedComponent,
  fromCommon.ActionSalesMeetingDeletedComponent,
  fromCommon.ActionSalesMeetingUpdatedComponent,
  fromCommon.ActionTaskChangedDeliveryDateComponent,
  fromCommon.ActionTaskConnectedComponent,
  fromCommon.ActionTaskDeletedComponent,
  fromCommon.ActionTaskNotResolvedComponent,
  fromCommon.ActionTaskResolvedComponent,
  fromCommon.ActionTipsSentToBankComponent,
  fromCommon.ActionConsumerStoredAlertComponent,
  fromCommon.ActionConsumerPreferencesUpdatedComponent,
  fromCommon.ActionConsumerPreferencesDeletedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    TooltipModule,
    MomentModule,
    RouterModule,
    ListComponentsModule,
    UiComponentsModule,
    UtilModule,
    ResourceModule,
    ContactComponentsModule,
  ],
  declarations: components,
  exports: [ActionListComponent, ActionListItemComponent],
  providers: [ActionService, ActionListItemService],
})
export class ActionListModule {}
