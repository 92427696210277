import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import * as apiUrls from "@app/core/services/api/utils/api-endpoints";
import { ExternalTip } from "@app/models/external-tips";
import { Store } from "@ngrx/store";
import { catchError, Observable, of as observableOf, switchMap } from "rxjs";
import * as externalTipsActions from "../ngrx/external-tips.actions";

@Injectable()
export class EditExternalTipGuard implements CanActivate {
  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.apiService.get(apiUrls.EXTERNAL_TIPS.TIP(next.params.id)).pipe(
      switchMap((externalTip: ExternalTip) => {
        if (externalTip) {
          this.store.dispatch(
            externalTipsActions.getExternalTipSuccess({ externalTip })
          );
          return observableOf(true);
        }
        return observableOf(false);
      }),
      catchError(() => observableOf(false))
    );
  }
}
