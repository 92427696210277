import { Injectable, OnDestroy } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as previewActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { Contact, Employee, ObjectFile, Office } from "@app/models";
import * as messageUtils from "@app/shared/utils/message-utils";
import { ObjectLink } from "@app/shared/utils/object-utils";
import { nl2br } from "@app/shared/utils/string-utils";
import { DynamicContent, Template } from "@app/sidebar/send-message/models";
import * as sendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { MessageType } from "@app/sidebar/send-message/ngrx/send-message.reducer";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SendMessageService implements OnDestroy {
  objectLinks$ = new Subject<ObjectLink[]>();
  objectFiles$ = new BehaviorSubject<ObjectFile[]>([]);

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.objectLinks$.next([]);
    this.objectLinks$.complete();
    this.objectFiles$.next([]);
    this.objectFiles$.complete();
  }

  send(data: {
    template: Template;
    dynamicContents: DynamicContent[];
    addToOutbox: boolean;
    userIds: any;
    user: Employee;
    sendToModule: string;
    office: Office;
    messageType: MessageType;
    extraDataSet: any;
    recipients: any[];
    objectFiles: ObjectFile[];
  }) {
    const {
      template,
      dynamicContents,
      addToOutbox,
      userIds,
      user,
      sendToModule,
      office,
      messageType,
      extraDataSet,
      recipients,
      objectFiles,
    } = data;
    const dataSet: any = { ...userIds };

    if (messageType === "email") {
      dataSet.fromName = user.employeeFullName;
      dataSet.fromEmail = user.employeeEmail;
    } else {
      dataSet.fromName = user.employeeMobile;
    }

    const params = recipients.map((contact) => {
      const objectToBeTransformed = {
        ...dataSet,
      };

      if (contact.contactId) {
        objectToBeTransformed["consumerId"] = contact.contactId;
        objectToBeTransformed["consumerFirstName"] = contact.firstName;
        objectToBeTransformed["consumerFamilyName"] = contact.familyName;
        objectToBeTransformed["consumerEmail"] = contact.email;
        objectToBeTransformed["consumerMsisdn"] = contact.msisdn;
        objectToBeTransformed["consumerStreet"] = contact?.street;
        objectToBeTransformed["consumerZip"] = contact?.zip;
        objectToBeTransformed["consumerCity"] = contact?.city;
      }
      const combinedDataSet = messageUtils.combineMessageDataSets(
        messageUtils.transformObjectToMessageDataSet(objectToBeTransformed),
        messageUtils.transformObjectToMessageDataSet(extraDataSet),
        messageUtils.transformDynamicContentToMessageDataSet(dynamicContents)
      );

      combinedDataSet.replace("||", "|");

      let processedDataset;
      if (messageType === "email") {
        processedDataset = nl2br(combinedDataSet.replace("||", "|"));
      } else {
        processedDataset = combinedDataSet.replace("||", "|");
      }

      const temporaryParams = {
        dataSet: processedDataset,
        value2: template.templateId,
        templateId: template.externalId,
        sendToModule: office.moduleId ? office.moduleId : sendToModule,
        addToOutbox: addToOutbox,
      };

      if (contact.contactId) {
        temporaryParams["recipients"] =
          messageType === "email" ? contact.email : contact.msisdn;
        temporaryParams["sendToActionContactIds"] = contact.contactId;
      } else {
        temporaryParams["recipients"] = contact;
      }

      if (objectFiles.length > 0) {
        const fileNames = this.buildAttachmentFileNames(objectFiles);
        const filePaths = this.buildAttachmentFilePaths(objectFiles);

        if (fileNames) {
          temporaryParams["attachmentFileNames"] = fileNames;
        }

        if (filePaths) {
          temporaryParams["attachmentFilePaths"] = filePaths;
        }
      }
      return temporaryParams;
    });

    this.store.dispatch(
      sendMessageActions.sendMessageRequest({ messageType, params })
    );
    this.objectFiles$.next([]);
  }

  preview(data: {
    template: Template;
    dynamicContents?: DynamicContent[];
    userIds: any;
    extraDataSet: any;
    recipients: any[];
    eaOfficeId?: string;
    eaEmployeeId?: string;
  }) {
    const { template, dynamicContents, userIds, extraDataSet, recipients } =
      data;
    let ids: any = {
      ...userIds,
    };

    if (recipients[0]) {
      ids = {
        ...ids,
        consumerId: recipients[0].contactId,
      };
    }

    if (data?.eaOfficeId) {
      ids = {
        ...ids,
        eaOfficeId: data?.eaOfficeId,
      };
    }

    if (data?.eaEmployeeId) {
      ids = {
        ...ids,
        eaEmployeeId: data?.eaEmployeeId,
      };
    }

    const combinedDataSet = messageUtils.combineMessageDataSets(
      messageUtils.transformObjectToMessageDataSet(ids),
      messageUtils.transformObjectToMessageDataSet(extraDataSet),
      messageUtils.transformDynamicContentToMessageDataSet(dynamicContents)
    );

    const params = {
      value2: template.templateId,
      dataSet: nl2br(combinedDataSet.replace("||", "|")),
    };

    const consumerName = recipients[0]
      ? `${recipients[0]?.firstName} ${recipients[0]?.familyName}`
      : null;

    this.store.dispatch(
      previewActions.fetchPreviewRequest({
        parameters: {
          templateId: template.externalId,
          params,
          consumerName,
        },
      })
    );
  }

  previewMeeting(
    templateExternalId: string,
    dataSet: any,
    extraParams: any,
    consumerName: string | null
  ) {
    const formatedDataSet =
      messageUtils.transformObjectToMessageDataSet(dataSet);

    const params = {
      dataSet: nl2br(formatedDataSet.replace("||", "|")),
      ...extraParams,
    };

    this.store.dispatch(
      previewActions.fetchPreviewRequest({
        parameters: {
          templateId: templateExternalId,
          params,
          consumerName,
        },
      })
    );
  }

  sendMeeting(
    employee: Employee,
    contact: Contact,
    dataSet: any,
    extraParams: any,
    messageType: MessageType
  ) {
    const data = { ...dataSet };
    if (messageType === "email") {
      data.fromName = employee.employeeFullName;
      data.fromEmail = employee.employeeEmail;
    } else {
      data.fromName = employee.employeeMobile;
    }

    const params = {
      ...data,
    };

    if (contact.contactId) {
      params["consumerId"] = contact.contactId;
      params["consumerFirstName"] = contact.firstName;
      params["consumerFamilyName"] = contact.familyName;
      params["consumerEmail"] = contact.email;
      params["consumerMsisdn"] = contact.msisdn;
      params["consumerStreet"] = contact?.street;
      params["consumerZip"] = contact?.zip;
      params["consumerCity"] = contact?.city;
    }
    const combinedDataSet =
      messageUtils.transformObjectToMessageDataSet(params);

    combinedDataSet.replace("||", "|");

    let processedDataset;
    if (messageType === "email") {
      processedDataset = nl2br(combinedDataSet.replace("||", "|"));
    } else {
      processedDataset = combinedDataSet.replace("||", "|");
    }

    const temporaryParams = {
      dataSet: processedDataset,
      ...extraParams,
    };

    if (contact.contactId) {
      temporaryParams["recipients"] =
        messageType === "email" ? contact.email : contact.msisdn;
      temporaryParams["sendToActionContactIds"] = contact.contactId;
    } else {
      temporaryParams["recipients"] = contact;
    }

    this.store.dispatch(
      sendMessageActions.sendMessageRequest({
        messageType,
        params: [temporaryParams],
      })
    );
  }

  buildAttachmentFileNames(files: ObjectFile[]): string {
    return files.map((file) => file.fileDescription || file.fileId).join("|");
  }

  buildAttachmentFilePaths(files: ObjectFile[]): string {
    return files.map((file) => file.filePath).join("|");
  }

  reset() {
    this.objectFiles$.next([]);
  }
}
