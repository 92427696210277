import {
  Contact,
  Employee,
  QObject,
  SalesMeeting,
  SendListStatus,
  Task,
  TaskType,
} from "@app/models";
import { Feature } from "@app/shared/config/models";
import { CreateContactParams } from "../../contacts/ngrx/create-contact.actions";
import { createAction, props } from "@ngrx/store";
import { SalesMeetingBatchSettings } from "@app/sidebar/sales-meeting/models/batch-settings";

export const reset = createAction("[Sales Meeting] RESET");

export const getContactRequest = createAction(
  "[Sales Meeting] GET_CONTACT_REQUEST",
  props<{ contactId: string }>()
);

export const getContactSuccess = createAction(
  "[Sales Meeting] GET_CONTACT_SUCCESS",
  props<{
    contact: Contact;
  }>()
);

export const getContactFail = createAction("[Sales Meeting] GET_CONTACT_FAIL");

export const getSalesMeetingRequest = createAction(
  "[Sales Meeting] GET_SALES_MEETING_REQUEST",
  props<{
    id: string;
  }>()
);

export const getSalesMeetingSuccess = createAction(
  "[Sales Meeting] GET_SALES_MEETING_SUCCESS",
  props<{
    salesMeeting: SalesMeeting;
  }>()
);

export const getSalesMeetingFail = createAction(
  "[Sales Meeting] GET_SALES_MEETING_FAIL"
);

export const getResidenceRequest = createAction(
  "[Sales Meeting] GET_RESIDENCE_REQUEST",
  props<{
    id: string;
  }>()
);

export const getResidenceSuccess = createAction(
  "[Sales Meeting] GET_RESIDENCE_SUCCESS",
  props<{
    payload: QObject;
  }>()
);

export const getResidenceFail = createAction(
  "[Sales Meeting] GET_RESIDENCE_FAIL"
);

export const getResidencesRequest = createAction(
  "[Sales Meeting] GET_RESIDENCES_REQUEST",
  props<{
    contactId: string;
  }>()
);

export const getResidencesSuccess = createAction(
  "[Sales Meeting] GET_RESIDENCES_SUCCESS",
  props<{
    payload: QObject[];
  }>()
);

export const getResidencesFail = createAction(
  "[Sales Meeting] GET_RESIDENCES_FAIL"
);

export const getBatchSettingsRequest = createAction(
  "[Sales Meeting] GET_BATCH_SETTINGS_REQUEST",
  props<{
    filterSettingsGroupId: number;
  }>()
);

export const getBatchSettingsSuccess = createAction(
  "[Sales Meeting] GET_BATCH_SETTINGS_SUCCESS",
  props<{
    settings: SalesMeetingBatchSettings[];
  }>()
);

export const getBatchSettingsFail = createAction(
  "[Sales Meeting] GET_BATCH_SETTINGS_FAIL"
);

export const getSystemSources = (contactId: string) => {
  return getSystemSourcesRequest({
    contactId,
    taskTypeCategory: "contactSource,lead",
  });
};

export const getSystemSourcesRequest = createAction(
  "[Sales Meeting] GET_SYSTEM_SOURCES_REQUEST",
  props<{
    contactId: string;
    taskTypeCategory: string;
  }>()
);

export const getSystemSourcesSuccess = createAction(
  "[Sales Meeting] GET_SYSTEM_SOURCES_SUCCESS",
  props<{
    payload: Task[];
  }>()
);

export const getSystemSourcesFail = createAction(
  "[Sales Meeting] GET_SYSTEM_SOURCES_FAIL"
);

export const getOtherSources = (taskTypeCategory: string = "contactSource") =>
  getOtherSourcesRequest({ taskTypeCategory });

export const getOtherSourcesRequest = createAction(
  "[Sales Meeting] GET_OTHER_SOURCES_REQUEST",
  props<{
    taskTypeCategory: string;
  }>()
);

export const getOtherSourcesSuccess = createAction(
  "[Sales Meeting] GET_OTHER_SOURCES_SUCCESS",
  props<{
    payload: TaskType[];
  }>()
);

export const getOtherSourcesFail = createAction(
  "[Sales Meeting] GET_OTHER_SOURCES_FAIL"
);

export const createSourceAndResidenceSuccess = createAction(
  "[Sales Meeting] CREATE_SOURCE_AND_RESIDENCE_SUCCESS",
  props<{
    payload: Record<string, unknown>;
  }>()
);

export const saveSalesMeeting = createAction(
  "[Sales Meeting] SAVE_SALES_MEETING",
  props<{
    payload: Record<string, unknown>;
  }>()
);

export const createSalesMeetingRequest = createAction(
  "[Sales Meeting] CREATE_SALES_MEETING_REQUEST",
  props<{
    payload: Record<string, unknown>;
  }>()
);

export const createSalesMeetingSuccess = createAction(
  "[Sales Meeting] CREATE_SALES_MEETING_SUCCESS",
  props<{
    salesMeeting: SalesMeeting;
    externalProviderName?: string;
    openInExternalProvider?: boolean;
    modalConfig?: boolean;
    showConnectToObjInERPButton?: boolean;
    objectOverViewEnabled: Feature;
  }>()
);

export const createSalesMeetingFail = createAction(
  "[Sales Meeting] CREATE_SALES_MEETING_FAIL",
  props<{
    error: Error;
    errorType: string;
    extra?: unknown;
  }>()
);

export const updateSalesMeetingRequest = createAction(
  "[Sales Meeting] UPDATE_SALES_MEETING_REQUEST",
  props<{
    payload: Record<string, unknown>;
  }>()
);

export const updateSalesMeetingSuccess = createAction(
  "[Sales Meeting] UPDATE_SALES_MEETING_SUCCESS",
  props<{
    salesMeeting: SalesMeeting;
  }>()
);

export const updateSalesMeetingFail = createAction(
  "[Sales Meeting] UPDATE_SALES_MEETING_FAIL",
  props<{
    error: Error;
    errorType: string;
    extra?: unknown;
  }>()
);

export const createReportSalesMeetingRequest = createAction(
  "[Sales Meeting] CREATE_REPORT_SALES_MEETING_REQUEST",
  props<{
    payload: Record<string, unknown>;
  }>()
);

export const createReportSalesMeetingSuccess = createAction(
  "[Sales Meeting] CREATE_REPORT_SALES_MEETING_SUCCESS"
);

export const createReportSalesMeetingFail = createAction(
  "[Sales Meeting] CREATE_REPORT_SALES_MEETING_FAIL",
  props<{
    error: Error;
    errorType: string;
    extra?: unknown;
  }>()
);

export const getSendList = (contactId: string, referenceId: string) => {
  return getSendListRequest({
    contactId,
    referenceId,
    referenceType: "eaCrmSalesMeetingId",
  });
};

export const getSendListRequest = createAction(
  "[Sales Meeting] GET_SEND_LIST_STATUS_REQUEST",
  props<{
    contactId: string;
    referenceId: string;
    referenceType: string;
  }>()
);

export const getSendListSuccess = createAction(
  "[Sales Meeting] GET_SEND_LIST_STATUS_SUCCESS",
  props<{
    payload: SendListStatus[];
  }>()
);

export const getSendListFail = createAction(
  "[Sales Meeting] GET_SEND_LIST_STATUS_FAIL"
);

export const createContact = (params: CreateContactParams, source?: string) => {
  return createContactRequest({
    params: { ...params, originService: "CRM" },
    source,
  });
};

export const createContactRequest = createAction(
  "[Sales Meeting] CREATE_CONTACT_REQUEST",
  props<{
    params: CreateContactParams;
    source?: string;
  }>()
);

export const createContactSuccess = createAction(
  "[Sales Meeting] CREATE_CONTACT_SUCCESS",
  props<{
    payload: Contact;
  }>()
);

export const createContactFailed = createAction(
  "[Sales Meeting] CREATE_CONTACT_FAILED"
);

export const getSelectedEmployeeRequest = createAction(
  "[Sales Meeting] GET_SELECTED_EMPLOYEE_REQUEST",
  props<{
    id: string;
  }>()
);

export const getSelectedEmployeeSuccess = createAction(
  "[Sales Meeting] GET_SELECTED_EMPLOYEE_SUCCESS",
  props<{
    employee: Employee;
  }>()
);

export const getSelectedEmployeeFail = createAction(
  "[Sales Meeting] GET_SELECTED_EMPLOYEE_FAIL"
);

export const getMeetingHistoryRequest = createAction(
  "[Sales Meeting] GET_MEETING_HISTORY_REQUEST",
  props<{
    params: Record<string, unknown>;
  }>()
);

export const getMeetingHistorySuccess = createAction(
  "[Sales Meeting] GET_MEETING_HISTORY_SUCCESS",
  props<{ payload: SalesMeeting[] }>()
);

export const getMeetingHistoryFail = createAction(
  "[Sales Meeting] GET_MEETING_HISTORY_FAIL"
);

export const removeContactFromState = createAction(
  "[Sales Meeting] REMOVE_CONTACT_FROM_STATE"
);
