import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "kpi-won-sales-meeting-detail",
  templateUrl: "./kpi-won-sales-meeting-detail.component.html",
  styleUrls: ["./kpi-won-sales-meeting-detail.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiWonSalesMeetingDetailComponent {
  @Input() resources$: Observable<any>;
}
