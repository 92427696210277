import { Component } from "@angular/core";

@Component({
  selector: "app-not-available-in-mobile",
  template: `
    <div class="alert alert-info" translate>
      feature_not_available_in_mobile
    </div>
  `,
  styleUrls: ["./not-avilable-in-mobile.component.scss"],
})
export class NotAvilableInMobileComponent {}
