import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Competition } from "@app/competitions/models/competition.model";
import { Office } from "@app/models";
import * as competitionFormModes from "@app/shared/utils/form-modes";
import { FORM_MODE } from "@app/shared/utils/form-modes";
import * as formUtils from "@app/shared/utils/form-utils";
import moment from "moment";
import { map, PartialObserver, startWith, Subject, takeUntil } from "rxjs";

@Component({
  selector: "competition-form",
  templateUrl: "./competition-form.component.html",
  styleUrls: ["./competition-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionFormComponent implements OnInit, OnChanges {
  @Input() mode: FORM_MODE;
  @Input() competition: Competition;
  @Input() observer: PartialObserver<any>;
  @Input() offices: Office[];
  @Input() kpiTypes: string[];
  @Input() preselectedOfficeId: string;
  @Input() canEditOffice: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();
  unsubscribe$ = new Subject<void>();
  competitionForm: FormGroup;
  bsRangeValue;

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.buildForm();
  }

  ngOnInit() {
    if (this.mode === competitionFormModes.EDIT_MODE && !this.competition) {
      throw new Error("A competition is required in EDIT MODE");
    }
    this.fillForm();
    this.registerObserverToFormValueChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.competition && this.competition) {
      this.fillForm();
    }
  }

  buildForm(): void {
    this.competitionForm = this.fb.group({
      title: ["", Validators.required],
      office: "",
      kpiType: ["", Validators.required],
      dateRange: [[], Validators.required],
    });
  }

  registerObserverToFormValueChanges(): void {
    this.competitionForm.valueChanges
      .pipe(
        map(() => this.competitionForm.getRawValue()),
        startWith(this.competitionForm.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  fillForm(): void {
    if (this.mode === competitionFormModes.CREATE_MODE) {
      if (this.preselectedOfficeId) {
        this.competitionForm.get("office").setValue(this.preselectedOfficeId);
      }
      this.competitionForm
        .get("dateRange")
        .setValue([moment().toDate(), moment().add(1, "month").toDate()]);
    } else if (this.mode === competitionFormModes.EDIT_MODE) {
      this.competitionForm.setValue({
        title: this.competition.title,
        office: this.competition.eaOfficeId ? this.competition.eaOfficeId : "",
        kpiType: this.competition.kpiType,
        dateRange: [
          new Date(this.competition.startDate),
          new Date(this.competition.endDate),
        ],
      });
    }
    if (!this.canEditOffice) {
      this.competitionForm.get("office").disable();
    }
  }

  onSubmit(): void {
    if (this.competitionForm.valid) {
      this.submit.emit();
    } else {
      formUtils.markAllAsTouched(this.competitionForm);
      this.cdr.detectChanges();
    }
  }
}
