import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import {
  DataStudioConfiguration,
  HubDataStudio,
} from "@app/models/tailored-campaign";
import * as widgetActions from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsTailoredCampaignWidgetEffects {
  fetchHubDataStudioRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchHubDataStudioRequest),
      switchMap(() =>
        this.apiService
          .get(
            "marketing/reporting/widget-configuration/the-hub-data-studio",
            {},
            "api"
          )
          .pipe(
            map((data: HubDataStudio) =>
              widgetActions.fetchHubDataStudioSuccess({ data })
            ),
            catchError(() => of(widgetActions.fetchHubDataStudioFail()))
          )
      )
    )
  );

  fetchDataStudioConfigurationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchDataStudioConfigurationRequest),
      switchMap(() =>
        this.apiService
          .get(
            `marketing/reporting/widget-configuration/availability/data-studio-configuration`,
            {},
            "api"
          )
          .pipe(
            map((data: DataStudioConfiguration) =>
              widgetActions.fetchDataStudioConfigurationSuccess({ data })
            ),
            catchError(() =>
              of(widgetActions.fetchDataStudioConfigurationFail())
            )
          )
      )
    )
  );

  constructor(private apiService: ApiService, private actions$: Actions) {}
}
