<form [formGroup]="form" id="value-monitor-form" class="form">
  <app-q-contact-select
    formControlName="contactId"
    [eaOid]="form.get('eaOid').value"
    [invalid]="isInvalid('contactId')"
    *ngIf="showContactSelect(); else searchContact"
  ></app-q-contact-select>
  <ng-template #searchContact>
    <app-q-search-contact
      formControlName="contactId"
      [invalid]="isInvalid('contactId')"
      [readonly]="path === 'contacts'"
    ></app-q-search-contact>
  </ng-template>
  <app-q-control-errors
    name="contactId"
    [errors]="[
      { name: 'required', text: 'validation_required', when: 'dirty' },
      { name: 'missingPhone', text: 'missing_phone', when: 'dirty' },
      { name: 'missingEmail', text: 'missing_email', when: 'dirty' }
    ]"
  ></app-q-control-errors>
  <app-q-object-select
    formControlName="eaOid"
    [contactId]="form.get('contactId').value"
    [invalid]="isInvalid('eaOid')"
    *ngIf="showObjectSelect(); else searchObject"
  ></app-q-object-select>
  <ng-template #searchObject>
    <app-q-search-object
      formControlName="eaOid"
      [invalid]="isInvalid('eaOid')"
      [readonly]="path === 'showings'"
    >
      <ng-template let-object="object">
        <app-q-card-seconary-text>
          {{ object?.zip }}
        </app-q-card-seconary-text>
        <app-q-card-seconary-text>
          {{ object?.city }}
        </app-q-card-seconary-text>
        <app-q-card-seconary-text>
          {{ "responsible_broker" | translate }}
          :
          {{
            object?.responsibleEmployee?.employeeFullName || "undefined"
              | translate
          }}
        </app-q-card-seconary-text>
        <app-q-card-seconary-text>
          {{ "status" | translate }}:
          {{ object?.statusName || "undefined" | translate }}
        </app-q-card-seconary-text>
      </ng-template>
    </app-q-search-object>
  </ng-template>
  <app-q-control-errors
    name="eaOid"
    [errors]="[
      { name: 'required', text: 'validation_required', when: 'dirty' },
      { name: 'missingCoordinates', text: 'missing_coordinates', when: 'dirty' }
    ]"
  >
  </app-q-control-errors>
  <app-q-form-group-heading>{{
    "basic_facts" | translate
  }}</app-q-form-group-heading>
  <app-q-date-picker
    label="valuation_month"
    formControlName="baseValuationDate"
    [invalid]="isInvalid('baseValuationDate')"
    [maxDate]="baseValuationDateMaxDate"
    minMode="month"
    dateFormat="MMMM YYYY"
  ></app-q-date-picker>
  <app-q-control-errors
    name="baseValuationDate"
    [errors]="[
      { name: 'required', text: 'validation_required', when: 'dirty' }
    ]"
  ></app-q-control-errors>
  <app-q-input-thousand-separator
    label="value"
    formControlName="baseValuation"
    [invalid]="isInvalid('baseValuation')"
  ></app-q-input-thousand-separator>
  <app-q-control-errors
    name="baseValuation"
    [errors]="[
      { name: 'required', text: 'validation_required', when: 'dirty' }
    ]"
  ></app-q-control-errors>
  <app-q-select
    label="frequency"
    formControlName="frequency"
    [options]="frequencyOptions"
  ></app-q-select>
  <app-q-select
    label="type"
    formControlName="contactMethod"
    [options]="enabledContactMethods$ | async"
  ></app-q-select>
  <app-q-select
    label="employee"
    formControlName="eaEmployeeId"
    [options]="employeeList"
  ></app-q-select>
  <ng-container *ngIf="action === 'create'">
    <app-q-date-picker
      label="first_mailing"
      formControlName="nextTriggerTime"
      [invalid]="isInvalid('nextTriggerTime')"
      minMode="month"
      [minDate]="nextTriggerTimeMinDate"
      dateFormat="MMMM YYYY"
      placement="top"
    ></app-q-date-picker>
    <app-q-control-errors
      name="nextTriggerTime"
      [errors]="[
        { name: 'required', text: 'validation_required', when: 'dirty' }
      ]"
    ></app-q-control-errors>
  </ng-container>
</form>
