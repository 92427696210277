import { Component, Input, OnInit } from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-tips-sent-to-bank",
  template: `
    <div [innerHTML]="text | async" class=""></div>
    <div>
      <small *ngIf="item.param1?.length > 0" class="text-muted">
        <strong>{{ "message" | translate }}: </strong>{{ item.param1 }}
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionTipsSentToBankComponent implements OnInit {
  @Input() item: Action;

  text: Observable<string>;

  constructor(
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_tips_sent_to_bank_text", {
          you: youString,
        })
      )
    );
  }
}
