import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as search from "@app/core/components/search/ngrx/search.actions";
import { getExternalLinks } from "@app/core/components/search/ngrx/search.actions";
import {
  getExternalProviderLinks,
  getExternalProviderLinksLoading,
} from "@app/core/components/search/ngrx/search.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { IntegrationResource } from "@app/integrations/models/enums";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { Contact, Employee, QObject, Task } from "@app/models";
import { fadeInUp } from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { MAX_WIDTH_SM } from "@app/shared/utils/screen-sizes";
import { SIDEBAR_CONTACTS_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import * as createContactActions from "@app/sidebar/contacts/ngrx/create-contact.actions";
import { EmployeeOffices } from "@app/sidebar/profile/profile.component";
import { select, Store } from "@ngrx/store";
import { filter, first, Observable, Subscription, withLatestFrom } from "rxjs";
import * as searchActions from "../../ngrx/search.actions";
import * as searchCategories from "../../utils/search-category-types-constants";

@Component({
  selector: "search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.scss"],
  animations: [fadeInUp()],
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  @Output() latestSearchClicked = new EventEmitter<string>();

  searchSubscription: Subscription;
  searchResultSubscription: Subscription;
  storeSubscription: Subscription;
  latestSearches: string[] = [];
  loaded: boolean;
  keyword = "";
  contacts: Contact[];
  contactsHits: number;
  objects: QObject[];
  objectsHits: number;
  leads: Task[];
  leadsHits: number;
  employees: Employee[];
  employeesHits: number;
  groupedConsumers: Array<Contact[]>;
  groupedObjects: Array<QObject[]>;
  groupedLeads: Array<Task[]>;
  groupedEmployees: Array<Employee[]>;
  groupedResultsIsVisible = false;
  groupedResultType: string;
  isMobileView: boolean;
  categoryTypes = searchCategories;
  showExternalProviderObjectLinks$: Observable<boolean>;
  externalProvider$: Observable<ExternalProviderFeature>;
  externalProviderLinks$: Observable<any[]>;
  externalProviderLinksLoading$: Observable<boolean>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  userOffices$: Observable<EmployeeOffices[]>;
  isAdmin$: Observable<boolean>;
  latestContacts: Contact[] = [];
  latestObjects: QObject[] = [];
  superSearchTemplate$: Observable<string>;

  selectedMobileMode = this.categoryTypes.CONTACTS_CATEGORY;

  setGroupedResult(event) {
    if (!this.isMobileView) {
      this.groupedResultsIsVisible = true;
      this.groupedResultType = event;
    }
  }

  getGroupedResultsHits(): number {
    switch (this.groupedResultType) {
      case this.categoryTypes.CONTACTS_CATEGORY:
        return this.contactsHits;
      case this.categoryTypes.OBJECTS_CATEGORY:
        return this.objectsHits;
      case this.categoryTypes.LEADS_CATEGORY:
        return this.leadsHits;
      case this.categoryTypes.EMPLOYEES_CATEGORY:
        return this.employeesHits;
      default:
        return 0;
    }
  }

  getGroupedResults(): any[] {
    switch (this.groupedResultType) {
      case searchCategories.CONTACTS_CATEGORY:
        return this.groupedConsumers;
      case searchCategories.OBJECTS_CATEGORY:
        return this.groupedObjects;
      case searchCategories.LEADS_CATEGORY:
        return this.groupedLeads;
      case searchCategories.EMPLOYEES_CATEGORY:
        return this.groupedEmployees;
      default:
        return [];
    }
  }

  ngOnInit() {
    this.superSearchTemplate$ = this.store.pipe(
      select(fromConfig.getTemplateInSuperSearch)
    );
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.searchSubscription = this.store
      .select((state) => state.search)
      .subscribe((search) => {
        this.loaded = search.loaded;
        this.keyword = search.keyword;
        this.contactsHits = search.hits.contactHits;
        this.objectsHits = search.hits.objectsHits;
        this.leadsHits = search.hits.leadsHits;
        this.employeesHits = search.hits.employeesHits;
        this.latestSearches = search.latestSearches
          .split(",")
          .filter((value) => value.length > 0)
          .reverse();
      });

    this.storeSubscription = this.store
      .pipe(select(fromShared.getInnerWidth))
      .subscribe((width) => {
        if (width < MAX_WIDTH_SM) {
          this.groupedResultsIsVisible = false;
          this.isMobileView = true;
        } else {
          this.isMobileView = false;
        }
      });

    this.store
      .select((state) => state.search.keyword)
      .subscribe((keyword) => {
        if (keyword.length < 2) {
          this.groupedResultsIsVisible = false;
        }
      });

    this.searchResultSubscription = this.store
      .select((state) => state.search.results)
      .subscribe((results) => {
        this.contacts = results.contacts;
        this.objects = results.objects;
        this.leads = results.leads;
        this.employees = results.employees;
        this.createGroupedResults();
      });

    this.showExternalProviderObjectLinks$ = this.store.select(
      hasIntegration(IntegrationResource.ObjectLinks)
    );
    this.externalProvider$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER))
    );
    this.externalProviderLinks$ = this.store.pipe(
      select(getExternalProviderLinks)
    );
    this.externalProviderLinksLoading$ = this.store.pipe(
      select(getExternalProviderLinksLoading)
    );
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.userOffices$ = this.store.pipe(
      select(fromUser.getAllEmployeesAndOffices)
    );

    this.store
      .pipe(first(), select(fromConfig.getDefaultMobileSearchCategory))
      .subscribe((category) => (this.selectedMobileMode = category));
  }

  shouldShowHeaders() {
    return this.keyword.length < 2 && this.latestSearches.length > 0;
  }

  handleCreateNewContact(): void {
    const prefillData = this.getNewContactPrefillData();
    if (prefillData) {
      this.store.dispatch(createContactActions.setPrefillData({ prefillData }));
    }
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm", { outlets: { sidebar: SIDEBAR_CONTACTS_CREATE_URL } }],
      })
    );
  }

  getNewContactPrefillData(): Partial<Contact> | void {
    if (this.isStringEmail(this.keyword)) {
      return { email: this.keyword };
    }
    if (this.isStringPhoneNumber(this.keyword)) {
      return { msisdn: this.keyword };
    }
    if (this.isStringFirstName(this.keyword)) {
      return { firstName: this.keyword };
    }
    if (this.isStringFullName(this.keyword)) {
      return {
        firstName: this.keyword.split(" ")[0].capitalize(),
        familyName: this.keyword
          .replace(this.keyword.split(" ")[0] + " ", "")
          .capitalize(),
      };
    }
  }

  isStringEmail(string: string): boolean {
    return string.includes("@");
  }

  isStringPhoneNumber(string: string): boolean {
    return /^\d{2,12}$/.test(string.replace("+", ""));
  }

  isStringFirstName(string: string): boolean {
    return /^[a-\p{L}A-\p{L}]\S*[^0-9]$/.test(string.capitalize());
  }

  isStringFullName(string: string): boolean {
    return string.indexOf(" ") >= 0
      ? /^[a-\p{L}A-\p{L}][^0-9]+$/.test(string.capitalize())
      : false;
  }

  close() {
    this.store.dispatch(search.hide());
  }

  runSearch(keyword) {
    this.eaEmployeeId$
      .pipe(
        withLatestFrom(
          this.userOffices$,
          this.isAdmin$,
          this.superSearchTemplate$
        ),
        first(),
        filter((value) => !!value)
      )
      .subscribe(
        ([eaEmployeeId, userOffices, isAdmin, superSearchTemplate]) => {
          const eaOfficeIds = userOffices[0].offices
            .map((office) => office.eaOfficeId)
            .join(",");
          this.store.dispatch(
            searchActions.loadRequest({
              parameters: {
                keyword,
                eaEmployeeId,
                eaOfficeId: eaOfficeIds,
                isAdmin,
                superSearchTemplate,
              },
            })
          );
        }
      );
    this.store.dispatch(searchActions.setLatestSearch(keyword));
    this.latestSearchClicked.emit(keyword);
  }

  removeLatestSearches() {
    this.store.dispatch(searchActions.removeLatestSearches());
  }

  showAllSearchTypes() {
    this.groupedResultsIsVisible = false;
  }

  createGroupedResults() {
    this.groupedConsumers = this.createGroupedResult(this.contacts);
    this.groupedObjects = this.createGroupedResult(this.objects);
    this.groupedLeads = this.createGroupedResult(this.leads);
    this.groupedEmployees = this.createGroupedResult(this.employees);
  }

  createGroupedResult(result) {
    const arr = [];
    let currentGroup = [];
    let leftOver = [];
    for (let i = 1; i <= result.length; i++) {
      currentGroup.push(result[i - 1]);
      if (i === result.length && currentGroup.length !== 4) {
        leftOver = currentGroup;
      }
      if (i % 4 === 0) {
        arr.push(currentGroup);
        currentGroup = [];
      }
    }
    if (leftOver.length > 0) {
      arr.push(leftOver);
    }
    return arr;
  }

  getExternalProviderLinks(eaOid: string): void {
    this.store.dispatch(getExternalLinks({ eaOid }));
  }

  constructor(private store: Store<AppState>) {}

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.searchResultSubscription) {
      this.searchResultSubscription.unsubscribe();
    }
  }
}
