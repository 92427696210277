import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import { SearchProviderContact } from "@app/shared/modules/search-contact/models";
import * as formUtils from "@app/shared/utils/form-utils";
import { allFieldsRequiredOnAtLeastOneControlOrGroup } from "@app/shared/validators/form-group-validator";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, Observable } from "rxjs";

@Component({
  selector: "app-company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./company-form.component.scss",
  ],
})
export class CompanyFormComponent implements OnInit, OnChanges {
  @Input() contact: Contact;
  @Input() editMode: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;
  countryCode$: Observable<string>;
  selectedCountryCode$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  addressSuggesterFeature$: Observable<Feature>;
  addressIsRequired$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private phoneNumberService: PhoneNumberService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.initCountryCode();
    this.setValidators();
  }

  ngOnChanges(changes) {
    if (changes.contact && this.contact && !!this.editMode) {
      this.fillForm();
      if (!!this?.contact?.msisdnCountry) {
        this.updateCountryCode(this.contact.msisdnCountry);
      }
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      firstName: [null, Validators.required],
      contactOrgNumber: [null],
      street: [null],
      zip: [null],
      city: [null],
      email: [null],
      msisdn: [null],
      msisdnCountry: [null],
    });
  }

  private fillForm() {
    const processedMsisdn = this.contact.msisdnCountry
      ? this.phoneNumberService.format(
          this.contact.msisdn,
          this.contact.msisdnCountry
        )
      : this.contact.msisdn;
    const patchValue = {
      firstName: this.contact.firstName,
      contactOrgNumber: this.contact.contactOrgNumber,
      street: this.contact.street,
      zip: this.contact.zip,
      city: this.contact.city,
      email: this.contact.email,
      msisdn: processedMsisdn,
      msisdnCountry: this.contact.msisdnCountry,
    };
    this.form.patchValue(patchValue);
  }

  private mapStateToProps() {
    this.countryCode$ = this.store.pipe(
      select(fromConfig.getCountry),
      map((value) => value.toUpperCase())
    );
    this.addressSuggesterFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressIsRequired$ = this.store.pipe(
      select(fromConfig.getCreateContactTypesConfig),
      map((config) => {
        const contactType = config.find((type) => type.type === "company");
        return contactType.addressIsRequired;
      })
    );
  }

  private setValidators(): void {
    this.addressIsRequired$.pipe(first()).subscribe((addressIsRequired) => {
      this.form.setValidators([
        allFieldsRequiredOnAtLeastOneControlOrGroup([
          this.form.get("msisdn"),
          this.form.get("email"),
        ]),
      ]);

      if (addressIsRequired) {
        this.form.get("street").setValidators(Validators.required);
        this.form.get("zip").setValidators(Validators.required);
        this.form.get("city").setValidators(Validators.required);
      }
    });
  }

  handleAddressSelected(address: Address) {
    this.form.get("street").setValue(address.street);
    this.form.get("zip").setValue(address.zip);
    this.form.get("city").setValue(address.city);
  }

  handleZipSelected(address: Address) {
    this.form.get("zip").setValue(address.zip);
    this.form.get("city").setValue(address.city);
  }

  submit(): void {
    if (this.form.valid) {
      this.onSubmit.emit(true);
    } else {
      this.onSubmit.emit(false);
      formUtils.markAllAsTouched(this.form);
    }
  }

  private initCountryCode() {
    this.countryCode$.pipe(first()).subscribe((countryCode) => {
      this.selectedCountryCode$.next(countryCode);
      if (!this.form.get("msisdnCountry").value) {
        this.form.get("msisdnCountry").setValue(countryCode);
      }
    });
  }

  private updateCountryCode(phoneNumberCountry: string) {
    this.selectedCountryCode$.next(phoneNumberCountry);
  }

  handleCountryCodeChange(countryCode: string) {
    this.form.get("msisdnCountry").setValue(countryCode);
  }

  handleEniroClicked(contact: SearchProviderContact) {
    this.form.get("contactOrgNumber").setValue(contact.companyId);
    this.form.get("firstName").setValue(contact.name);

    if (contact?.addresses?.length > 0) {
      const address = contact?.addresses[0];
      this.form.get("street").setValue(address.displayAddress);
      this.form.get("zip").setValue(address.zipCode);
      this.form.get("city").setValue(address.city);
    }

    if (contact?.phoneNumbers?.length > 0) {
      const phoneNumber = contact?.phoneNumbers[0].phoneNumber;
      this.form.get("msisdn").setValue(phoneNumber);
      this.countryCode$.pipe(first()).subscribe((cc) => {
        this.selectedCountryCode$.next(cc);
        this.form.get("msisdnCountry").setValue(cc);
      });
    }
  }
}
