import { Component, Input } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { go } from "@app/core/ngrx/router/router.actions";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-kpi-sales-contract-date",
  templateUrl: "./kpi-sales-contract-date.component.html",
  styleUrls: ["./kpi-sales-contract-date.component.scss"],
})
export class KpiSalesContractDateComponent {
  @Input() resources$: Observable<any>;

  constructor(private store: Store<AppState>) {}

  navigate(eaOid: string): void {
    this.store.dispatch(
      go({
        path: [
          "/crm",
          {
            outlets: {
              primary: ["showings", eaOid, "potential-buyers"],
              modal: null,
            },
          },
        ],
      })
    );
  }
}
