export interface ContactCardActions {
  buttons: ContactCardAction[];
}

export interface ContactCardAction {
  label: string;
  sortOrder: number;
  enabled: boolean;
  type: string;
  icon?: string;
}

export const ContactCardActionsConfigDefaults = {
  buttons: [
    {
      label: "send_tips",
      sortOrder: 1,
      enabled: true,
      type: "send_tips",
      icon: "lightbulb",
    },
    {
      label: "book_sales_meeting",
      sortOrder: 2,
      enabled: true,
      type: "sales_meeting",
      icon: "briefcase",
    },
    {
      label: "send_message",
      sortOrder: 3,
      enabled: true,
      type: "send_message",
      icon: "envelope-open",
    },
    {
      label: "create_follow_up",
      sortOrder: 4,
      enabled: true,
      type: "create_follow_up",
      icon: "check",
    },
    {
      label: "create_lead",
      sortOrder: 6,
      enabled: false,
      type: "create_lead",
      icon: "street-view",
    },
    {
      label: "create_object",
      sortOrder: 7,
      enabled: false,
      type: "create_object",
      icon: "home-lg-alt",
    },
  ],
};
