<table class="table table--striped">
  <thead>
  <tr>
    <th translate>address</th>
    <th translate>zip</th>
    <th translate>district</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
    <tr (click)="navigate(resource.eaOid)" *ngFor="let resource of resources$ | async">
      <td dataLabel="address">{{resource.street}}</td>
      <td dataLabel="zip">{{resource.zip}}</td>
      <td dataLabel="district">{{resource.district}}</td>
    </tr>
  </ng-container>

  <ng-template #empty>
    <tr>
      <td class="text-center" colspan="100%" translate>no_sales_contract</td>
    </tr>
  </ng-template>
  </tbody>
</table>
