import { QObject } from "@app/models/q-object";
import { TaskResult } from "./task-result";

export const AttemptTypes = {
  CALL: "call",
};

export interface OriginService {
  total: number;
  origin: string;
  value?: string | null;
}

export class Task {
  eaOid: string;
  eaTaskId: string;
  eaOfficeId: string;
  eaOfficeName: string;
  eaTaskTypeId: string;
  eaTaskTypeCategory: string;
  eaTaskTypeDescription: string;
  title: string;
  typeName: string;
  resultName: string;
  resolved: string;
  success: string;
  deliveryDate: string;
  eaTaskTypeName: string;
  contactFirstName: string;
  contactFamilyName: string;
  contactFullName: string;
  resultComment: string;
  description: string;
  insertedDate: string;
  employeeFullName: string;
  eaEmployeeFullName?: string;
  eaEmployeeId: string;
  eaTaskResultId: string;
  eaTaskResultName: string;
  externalServiceName?: string;
  externalServiceReferenceId?: string;
  taskResults: TaskResult[];
  contactId: string;
  contactSource: boolean;
  latestAttemptType: string;
  latestAttemptInsertedDate: string | Date;
  resolvedDate: string;
  officeManagerExclusiveStartDate: string;
  officeManagerExclusiveEndDate: string;
  startTime: string;
  eaCrmSalesMeetingId: string;
  originEaEmployeeId: string;
  originEaOfficeId: string;
  originEaOfficeName: string;
  originEaEmployeeFullname: string;
  param1: string;
  leadStatistics?: any;
  object?: QObject;
  callingListId?: string;
  transfer?: boolean;
  updateContactSource?: boolean;
  transferLogs?: [
    {
      logId: string;
      date: string;
      transferredBy: {
        eaEmployeeId: string;
        employeeFullName: string;
        eaOfficeId: string;
        officeName: string;
      };
      transferredTo: {
        eaEmployeeId: string;
        employeeFullName: string;
        eaOfficeId: string;
        officeName: string;
      };
      transferredFrom: {
        eaEmployeeId: string;
        employeeFullName: string;
        eaOfficeId: string;
        officeName: string;
      };
      comment: string;
    }
  ];

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
