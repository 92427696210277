import { Contact, QObject } from "@app/models";
import { UserIds } from "@app/shared/user";
import { Recipient } from "@app/sidebar/send-message/models/recipient";
import { DynamicContent, Template } from "../models";
import { MessageType } from "./send-message.reducer";
import { createAction, props } from "@ngrx/store";

export interface dynamicContentRequestParams {
  eaEmployeeId: string;
  eaOfficeId: string;
  contactId: string;
  eaOid?: string;
  replacePlaceholders?: boolean;
}

const ACTION_PREFIX = "[Send Message]";

export const replaceContacts = createAction(
  `${ACTION_PREFIX} REPLACE_CONTACTS`,
  props<{ recipients: Recipient[] }>()
);

export const fetchTemplatesRequest = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_REQUEST`,
  props<{ messageType: MessageType; groupName: string }>()
);

export const fetchTemplatesSuccess = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_SUCCESS`,
  props<{ templates: Template[] }>()
);

export const fetchTemplatesFail = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_FAIL`
);

export const fetchDynamicContent = (
  templateId: string,
  userIds: UserIds,
  consumers: Recipient[],
  eaOid?: string,
  eaOfficeId?: string,
  eaEmployeeId?: string,
  replacePlaceholders?: boolean
) => {
  let params: dynamicContentRequestParams = {
    eaOfficeId: userIds.eaOfficeId,
    eaEmployeeId: userIds.eaEmployeeId,
    contactId: consumers.map((consumer) => consumer.contactId).join(","),
  };
  if (eaOid) {
    params = { ...params, eaOid };
  }
  if (eaOfficeId) {
    params = { ...params, eaOfficeId };
  }
  if (eaEmployeeId) {
    params = { ...params, eaEmployeeId };
  }
  if (replacePlaceholders !== undefined) {
    params = { ...params, replacePlaceholders };
  }

  return fetchDynamicContentRequest({ templateId, params });
};

export const fetchDynamicContentRequest = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_REQUEST`,
  props<{ templateId; params: dynamicContentRequestParams }>()
);

export const fetchDynamicContentSuccess = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_SUCCESS`,
  props<{ dynamicContents: DynamicContent[] }>()
);

export const fetchDynamicContentFail = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_FAIL`
);

export const dynamicContentNotFound = createAction(
  `${ACTION_PREFIX} DYNAMIC_CONTENT_NOT_FOUND`
);

export const updateContactRequest = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_REQUEST`,
  props<{ contactId: string; params: Record<string, unknown> }>()
);

export const updateContactSuccess = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_SUCCESS`,
  props<{ contact: Contact }>()
);

export const updateContactFail = createAction(
  `${ACTION_PREFIX} UPDATE_CONTACT_FAILED`
);

export const sendMessageRequest = createAction(
  `${ACTION_PREFIX} SEND_MESSAGE_REQUEST`,
  props<{ messageType: MessageType; params: Record<string, unknown>[] }>()
);

export const sendMessageSuccess = createAction(
  `${ACTION_PREFIX} SEND_MESSAGE_SUCCESS`
);

export const sendMessageFail = createAction(
  `${ACTION_PREFIX} SEND_MESSAGE_FAIL`
);

export const setEaOid = createAction(
  `${ACTION_PREFIX} SET_EAOID`,
  props<{ eaOid: string }>()
);

export const addContacts = createAction(
  `${ACTION_PREFIX} ADD_CONTACTS`,
  props<{ recipients: Recipient[] }>()
);

export const removeContacts = createAction(
  `${ACTION_PREFIX} REMOVE_CONTACTS`,
  props<{ contactIds: string[] }>()
);

export const addEmail = createAction(
  `${ACTION_PREFIX} ADD_EMAIL`,
  props<{
    email: string;
  }>()
);

export const removeEmail = createAction(
  `${ACTION_PREFIX} REMOVE_EMAIL`,
  props<{
    email: string;
  }>()
);

export const removeAllEmails = createAction(
  `${ACTION_PREFIX} REMOVE_ALL_EMAILS`
);

export const addMobileNumber = createAction(
  `${ACTION_PREFIX} ADD_MOBILE_NUMBER`,
  props<{ mobileNumber: string }>()
);

export const removeMobileNumber = createAction(
  `${ACTION_PREFIX} REMOVE_MOBILE_NUMBER`,
  props<{ mobileNumber: string }>()
);

export const removeAllMobileNumbers = createAction(
  `${ACTION_PREFIX} REMOVE_ALL_MOBILE_NUMBERS`
);

export const setMessageType = createAction(
  `${ACTION_PREFIX} SET_MESSAGE_TYPE`,
  props<{ messageType: MessageType }>()
);

export const setTemplateGroup = createAction(
  `${ACTION_PREFIX} SET_TEMPLATE_GROUP`,
  props<{ templateGroupName: string }>()
);

export const setExtraDataSet = createAction(
  `${ACTION_PREFIX} SET_EXTRA_DATA_SET`,
  props<{ extraDataSet: Record<string, string> }>()
);

export const getUnsoldObjectsForEmployeeRequest = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_EMPLOYEE_REQUEST`,
  props<{
    eaEmployeeId: string;
    publishedOnNet: string;
  }>()
);

export const getUnsoldObjectsForEmployeeSuccess = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_EMPLOYEE_SUCCESS`,
  props<{ objects: QObject[] }>()
);

export const getUnsoldObjectsForEmployeeFail = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_EMPLOYEE_FAIL`
);

export const getSoldObjectsForEmployeeRequest = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_EMPLOYEE_REQUEST`,
  props<{ eaEmployeeId: string }>()
);

export const getSoldObjectsForEmployeeSuccess = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_EMPLOYEE_SUCCESS`,
  props<{ objects: QObject[] }>()
);

export const getSoldObjectsForEmployeeFail = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_EMPLOYEE_FAIL`
);

export const getUnsoldObjectsForOfficeRequest = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_OFFICE_REQUEST`,
  props<{
    eaOfficeId: string;
    publishedOnNet: string;
  }>()
);

export const getUnsoldObjectsForOfficeSuccess = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_OFFICE_SUCCESS`,
  props<{ objects: QObject[] }>()
);

export const getUnsoldObjectsForOfficeFail = createAction(
  `${ACTION_PREFIX} GET_UNSOLD_OBJECTS_FOR_OFFICE_FAIL`
);

export const getSoldObjectsForOfficeRequest = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_OFFICE_REQUEST`,
  props<{ eaOfficeId: string }>()
);

export const getSoldObjectsForOfficeSuccess = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_OFFICE_SUCCESS`,
  props<{ objects: QObject[] }>()
);

export const getSoldObjectsForOfficeFail = createAction(
  `${ACTION_PREFIX} GET_SOLD_OBJECTS_FOR_OFFICE_FAIL`
);

export const setCheckConsent = createAction(
  `${ACTION_PREFIX} SET_CHECK_CONSENT`,
  props<{ checkConsent: boolean }>()
);

export const reset = createAction(`${ACTION_PREFIX} RESET`);
