<div class="wrapper wrapper--extra-footer-space" [formGroup]="form">
  <div class="scroll-view">
    <section>
      <h4>{{ "chosen_contact" | translate }}</h4>
      <ng-container *ngIf="selectedContact$ | async as selectedContact">
        <contact-card
          [name]="selectedContact?.getFullName()"
          [address]="selectedContact?.street"
          [number]="
            formatNumber(
              selectedContact?.msisdn,
              selectedContact?.msisdnCountry
            )
          "
          [email]="selectedContact?.email"
          [deletable]="true"
          (delete)="goBack()"
        ></contact-card>
      </ng-container>
    </section>

    <hr />

    <section>
      <h4>{{ "task_title" | translate }}</h4>
      <input
        type="text"
        formControlName="title"
        [class.validation-input]="
          form.get('title').invalid && form.get('title').touched
        "
      />
      <span
        *ngIf="
          !(processing$ | async) &&
          !form.get('title').valid &&
          form.get('title').touched
        "
        class="validation-message"
        >{{ "validation_required" | translate }}</span
      >
    </section>

    <hr />

    <section>
      <h4 translate>create_follow_up_when</h4>

      <app-quick-date-options (dateClicked)="setDateFromNow($event)">
      </app-quick-date-options>
      <form-input-datepicker-dropdown
        [minDate]="minDate"
        [group]="form"
        [controlName]="'date'"
        [class.validation-input]="
          form.get('date').invalid && form.get('date').touched
        "
      ></form-input-datepicker-dropdown>
      <div
        *ngIf="
          !(processing$ | async) &&
          !form.get('date').valid &&
          form.get('date').touched
        "
      >
        <span
          *ngIf="form.get('date').hasError('required')"
          class="validation-message"
          >{{ "validation_required" | translate }}</span
        >
      </div>
    </section>

    <hr />

    <section>
      <div *ngIf="!(isBroker() | async)">
        <h4>{{ "create_follow_up_who" | translate }}</h4>
        <div class="form-group">
          <div
            class="input-iconfield"
            [class.pseudo-spinner-large]="employeesLoading$ | async"
          >
            <app-icon class="icon" name="building"></app-icon>
            <select formControlName="employee">
              <option
                *ngFor="let employee of employees$ | async"
                [value]="employee.eaEmployeeId"
              >
                {{ getName(employee) | async }}
              </option>
            </select>
          </div>
          <span
            *ngIf="!(processing$ | async) && !form.get('employee').valid"
            class="validation-message"
            >{{ "validation_required" | translate }}</span
          >
        </div>
      </div>
      <textarea
        [placeholder]="'notes' | translate"
        formControlName="notes"
      ></textarea>
    </section>

    <section>
      <h4 class="required" translate>consent</h4>
      <div class="consent-group">
        <div class="consent-group__icon-container">
          <i class="icon-gdpr"></i>
        </div>
        <div
          class="consent-group__box"
          [ngClass]="checkedInput ? 'success-bg' : ''"
        >
          <label class="checkbox-group">
            {{ "contact_has_agreed_to_remain" | translate }}
            <input
              type="checkbox"
              [checked]="checkedInput"
              (change)="checkedInput = !checkedInput"
              formControlName="consent"
              required
            />
            <span class="checkbox-group__checkmark"></span>
          </label>
        </div>
        <div tabindex="-1" ngxErrors="consent">
          <div translate ngxError="required" showWhen="touched">
            consent_mandatory
          </div>
        </div>
      </div>
    </section>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      (click)="onSubmit(form.value)"
      [disabled]="processing$ | async"
      [isLoading]="processing$ | async"
      scrollToInvalidControl
      [formEl]="form"
    >
    </app-q-button>
  </div>
</div>
