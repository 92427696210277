import { PageView } from "@app/models/page-view";
import { createAction, props } from "@ngrx/store";

export const fetchLatestObjectsRequest = createAction(
  "[Widget Latest Objects] FETCH_LATEST_OBJECTS_REQUEST"
);

export const fetchLatestObjectsSuccess = createAction(
  "[Widget Latest Objects] FETCH_LATEST_OBJECTS_SUCCESS",
  props<{ objects: PageView[] }>()
);

export const fetchLatestObjectsFail = createAction(
  "[Widget Latest Objects] FETCH_LATEST_OBJECTS_FAIL"
);
