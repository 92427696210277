import { Employee, Office } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const searchEmployeesRequest = createAction(
  "[SelectOffice] SEARCH_EMPLOYEES_REQUEST",
  props<{ email: string }>()
);

export const searchEmployeesSuccess = createAction(
  "[SelectOffice] SEARCH_EMPLOYEES_SUCCESS",
  props<{ employees: Employee[] }>()
);

export const searchEmployeesFail = createAction(
  "[SelectOffice] SEARCH_EMPLOYEES_FAIL",
  props<{ message: string }>()
);

export const loginOfficeRequest = createAction(
  "[SelectOffice] LOGIN_OFFICE_REQUEST",
  props<{ eaEmployeeId: string; eaOfficeId: string }>()
);

export const loginOfficeSuccess = createAction(
  "[SelectOffice] LOGIN_OFFICE_SUCCESS",
  props<{ employee: Employee; office: Office; roles: string[] }>()
);

export const loginOfficeFail = createAction("[SelectOffice] LOGIN_OFFICE_FAIL");

export const alreadyAuthenticated = createAction(
  "[SelectOffice] ALREADY_AUTHENTICATED"
);
