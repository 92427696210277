import { PaginationListDTO, Task } from "@app/models";
import { TaskType } from "@app/models/task-type";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as leadActions from "./lists-leads.actions";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface TransferLeadParams {
  eaOfficeId?: string;
  eaEmployeeId?: string;
  transferComment?: string;
  sendMessage?: boolean;
  message?: string;
}

export interface ListsLeadsState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  selectingLeadsLoading: boolean;
  rows: Task[];
  types: TaskType[];
  selectedEaTaskIds: string[];
  allContactIds: string[];
  allContactIdsLoading: boolean;
}

export const initialState: ListsLeadsState = {
  loaded: false,
  loading: false,
  selectingLeadsLoading: false,
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  types: [],
  selectedEaTaskIds: [],
  allContactIds: [],
  allContactIdsLoading: false,
};

export function listsLeadsReducer(
  state: ListsLeadsState,
  action: Action
): ListsLeadsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(leadActions.getLeadsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(leadActions.getLeadsSuccess, (state, { leads }) => ({
    ...state,
    loading: false,
    loaded: true,
    ...leads,
  })),
  on(leadActions.getLeadsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    rows: initialState.rows,
  })),
  on(leadActions.getMoreLeadsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(leadActions.getMoreLeadsSuccess, (state, { leads }) => {
    const { limit, offset, total, rows } = leads;
    return {
      ...state,
      loading: false,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    };
  }),
  on(leadActions.getMoreLeadsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
  })),
  on(leadActions.getLeadsTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    types: taskTypes,
  })),
  on(leadActions.assignMultipleLeadsRequest, (state) => ({
    ...state,
  })),
  on(leadActions.setSelectedLeads, (state, { selectedEaTaskIds }) => ({
    ...state,
    selectedEaTaskIds,
  })),
  on(leadActions.selectAllLeadsSuccess, (state) => ({
    ...state,
    selectingLeadsLoading: true,
  })),
  on(leadActions.selectAllLeadsFail, (state) => ({
    ...state,
    selectingLeadsLoading: false,
  })),
  on(leadActions.selectAllLeadsSuccess, (state, { selectedEaTaskIds }) => ({
    ...state,
    selectedEaTaskIds,
    selectingLeadsLoading: false,
  })),
  on(leadActions.getAllLeadsContactIdsRequest, (state) => ({
    ...state,
    allContactIdsLoading: true,
  })),
  on(leadActions.getAllLeadsContactIdsFailed, (state) => ({
    ...state,
    allContactIdsLoading: true,
  })),
  on(leadActions.getAllLeadsContactIdsSuccess, (state, { contactIds }) => ({
    ...state,
    allContactIds: contactIds
      .filter((contactId, index) => contactIds.indexOf(contactId) === index)
      .filter((value) => !!value),
    allContactIdsLoading: false,
  })),
  on(
    leadActions.getLeadsTaskTypesRequest,
    leadActions.getLeadsTaskTypesFailed,
    (state) => ({ ...state })
  ),
  on(leadActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.leads
);

export const getListsLeadsTypes = createSelector(
  getState,
  (state: ListsLeadsState) => state.types
);
