import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import {
  MAT_CHIPS_DEFAULT_OPTIONS,
  MatChipsModule,
} from "@angular/material/chips";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSortModule } from "@angular/material/sort";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { OutgoingMailListItemComponent } from "@app/dashboard/outgoing-mails/list-item/list-item.component";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { ActionListModule } from "@app/shared/modules/action-list";
import { CalendarWidgetModule } from "@app/shared/modules/calendar-widget/calendar-widget.module";
import { ContactActionsWidgetModule } from "@app/shared/modules/contact-actions-widget/contact-actions-widget.module";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { RichTextEditorModule } from "@app/shared/modules/form-components/rich-text-editor/rich-text-editor.module";
import { ListComponentsModule } from "@app/shared/modules/list-components/list.module";
import { PropertyComponentsModule } from "@app/shared/modules/property-components/property-components.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CallingListsModule } from "@app/sidebar/calling-lists/calling-lists.module";
import { NextStepListItemComponent } from "@app/widgets/widgets/contact-widgets/contact-next-step-widget/components/next-step-item/list-item.component";
import { ContactRelationsWidgetEffects } from "@app/widgets/widgets/contact-widgets/contact-relations-widget/ngrx/contact-relations-widget.effects";
import { ContactStatusWidgetEffects } from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.effects";
import { NpsDetailsModalComponent } from "@app/widgets/widgets/contact-widgets/contact-status-widget/nps-details-modal/nps-details-modal.component";
import { SurveysModalComponent } from "@app/widgets/widgets/contact-widgets/contact-status-widget/surveys-modal/surveys-modal.component";
import { LatestContactsWidgetEffects } from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.effects";
import { LatestObjectsForSaleWidgetEffects } from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.effects";
import { LatestObjectsWidgetEffects } from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.effects";
import { CreateNoteComponent as CreateObjectNoteComponent } from "@app/widgets/widgets/object-widgets/object-notes-widget/components/create-note/create-note.component";
import { ObjectNotesWidgetEffects } from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.effects";
import { StatisticsAdfenixReportingWidgetComponent } from "@app/widgets/widgets/statistics-adfenix-reporting-widget/statistics-adfenix-reporting-widget.component";
import { StatisticsBudgetWidgetEffects } from "@app/widgets/widgets/statistics-budget-widget/ngrx/statistics-budget-widget.effects";
import { StatisticsEmployeesPerformanceWidgetEffects } from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.effects";
import { StatisticsLeadResultWidgetEffects } from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.effects";
import { StatisticsLeadResultWidgetNewComponent } from "@app/widgets/widgets/statistics-lead-result-widget-new/statistics-lead-result-widget-new.component";
import { StatisticsNpsWidgetEffects } from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.effects";
import { StatisticsObjectStatusWidgetEffects } from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.effects";
import { StatisticsRevenueWidgetEffects } from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.effects";
import { StatisticsSoldObjectsWidgetEffects } from "@app/widgets/widgets/statistics-sold-objects-widget/ngrx/statistics-sold-objects-widget.effects";
import { StatisticsSummaryWidgetEffects } from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.effects";
import { StatisticsTailoredCampaignWidgetEffects } from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.effects";
import { WidgetDetailFieldComponent } from "@app/widgets/widgets/widget-components/widget-detail-field/widget-detail-field.component";
import { WidgetComponent } from "@app/widgets/widgets/widget/widget.component";
import { EffectsModule } from "@ngrx/effects";
import { TranslateModule } from "@ngx-translate/core";
import {
  BarChartModule,
  LineChartModule,
  NgxChartsModule,
} from "@swimlane/ngx-charts";
import { CalendarCommonModule, CalendarModule } from "angular-calendar";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from "ngx-mask";
import { WidgetPageComponent } from "./containers/widget-page/widget-page.component";
import { BrokerTimelineWidgetComponent } from "./widgets/broker-timeline-widget/broker-timeline-widget.component";
import { CalendarWidgetComponent } from "./widgets/calendar-widget/calendar-widget.component";
import { CompanyDetailsWidgetComponent } from "./widgets/contact-widgets/company-details-widget/company-details-widget.component";
import { ContactDetailsWidgetComponent } from "./widgets/contact-widgets/contact-details-widget/contact-details-widget.component";
import { ContactNextStepWidgetComponent } from "./widgets/contact-widgets/contact-next-step-widget/contact-next-step-widget.component";
import { ContactNotesWidgetComponent } from "./widgets/contact-widgets/contact-notes-widget/contact-notes-widget.component";
import { ContactObjectsWidgetComponent } from "./widgets/contact-widgets/contact-objects-widget/contact-objects-widget.component";
import { ContactRelationFormComponent } from "./widgets/contact-widgets/contact-relations-widget/components/contact-relation-form/contact-relation-form.component";
import { ContactRelationsWidgetComponent } from "./widgets/contact-widgets/contact-relations-widget/contact-relations-widget.component";
import { ContactResidencesWidgetComponent } from "./widgets/contact-widgets/contact-residences-widget/contact-residences-widget.component";
import { ContactStatusItemComponent } from "./widgets/contact-widgets/contact-status-widget/contact-status-item/contact-status-item.component";
import { ContactStatusWidgetComponent } from "./widgets/contact-widgets/contact-status-widget/contact-status-widget.component";
import { CurrentTasksWidgetComponent } from "./widgets/current-tasks-widget/current-tasks-widget.component";
import { EraIntranetWidgetComponent } from "./widgets/era-intranet-widget/era-intranet-widget.component";
import { FreeLeadsWidgetComponent } from "./widgets/free-leads-widget/free-leads-widget.component";
import { LatestContactsWidgetComponent } from "./widgets/latest-contacts-widget/latest-contacts-widget.component";
import { LatestObjectsForSaleWidgetComponent } from "./widgets/latest-objects-for-sale-widget/latest-objects-for-sale-widget.component";
import { LatestObjectsWidgetComponent } from "./widgets/latest-objects-widget/latest-objects-widget.component";
import { MoreBusinessOpportunitiesWidgetComponent } from "./widgets/more-business-opportunities-widget/more-business-opportunities-widget.component";
import { AdfenixPropertyReportingWidgetComponent } from "./widgets/object-widgets/adfenix-property-reporting-widget/adfenix-property-reporting-widget.component";
import { ObjectDescriptionsWidgetComponent } from "./widgets/object-widgets/object-descriptions-widget/object-descriptions-widget.component";
import { ObjectHighestBidWidgetComponent } from "./widgets/object-widgets/object-highest-bid-widget/object-highest-bid-widget.component";
import { ObjectImageCarouselWidgetComponent } from "./widgets/object-widgets/object-image-carousel-widget/object-image-carousel-widget.component";
import { ObjectVideoModalComponent } from "./widgets/object-widgets/object-image-carousel-widget/object-video-modal/object-video-modal.component";
import { NoteListComponent } from "./widgets/object-widgets/object-notes-widget/components/note-list/note-list.component";
import { ObjectNotesWidgetComponent } from "./widgets/object-widgets/object-notes-widget/object-notes-widget.component";
import { ObjectOverviewWidgetComponent } from "./widgets/object-widgets/object-overview-widget/object-overview-widget.component";
import { ObjectPartiesWidgetComponent } from "./widgets/object-widgets/object-parties-widget/object-parties-widget.component";
import { ObjectUpcomingShowingsWidgetComponent } from "./widgets/object-widgets/object-upcoming-showings-widget/object-upcoming-showings-widget.component";
import { ProjectObjectListWidgetComponent } from "./widgets/object-widgets/project-object-list-widget/project-object-list-widget.component";
import { ProjectOverviewWidgetComponent } from "./widgets/object-widgets/project-overview-widget/project-overview-widget.component";
import { RentalOverviewWidgetComponent } from "./widgets/object-widgets/rental-overview-widget/rental-overview-widget.component";
import { OutgoingMailWidgetComponent } from "./widgets/outgoing-mail-widget/outgoing-mail-widget.component";
import { OverdueTasksWidgetComponent } from "./widgets/overdue-tasks-widget/overdue-tasks-widget.component";
import { StatisticsAdfenixReportingSummaryWidgetComponent } from "./widgets/statistics-adfenix-reporting-summary-widget/statistics-adfenix-reporting-summary-widget.component";
import { StatisticsBudgetWidgetComponent } from "./widgets/statistics-budget-widget/statistics-budget-widget.component";
import { StatisticsEmployeesPerformanceWidgetComponent } from "./widgets/statistics-employees-performance-widget/statistics-employees-performance-widget.component";
import { StatisticsNpsWidgetComponent } from "./widgets/statistics-nps-widget/statistics-nps-widget.component";
import { StatisticsObjectStatusWidgetComponent } from "./widgets/statistics-object-status-widget/statistics-object-status-widget.component";
import { StatisticsRevenueWidgetComponent } from "./widgets/statistics-revenue-widget/statistics-revenue-widget.component";
import { StatisticsSoldObjectsWidgetComponent } from "./widgets/statistics-sold-objects-widget/statistics-sold-objects-widget.component";
import { StatisticsSummaryWidgetComponent } from "./widgets/statistics-summary-widget/statistics-summary-widget.component";
import { StatisticsTailoredCampaignWidgetComponent } from "./widgets/statistics-tailored-campaign-widget/statistics-tailored-campaign-widget.component";
import { TasksWidgetComponent } from "./widgets/tasks-widget/tasks-widget.component";
import { ValueMonitorWidgetComponent } from "./widgets/value-monitor-widget/value-monitor-widget.component";
import { ClickStopPropagationModule } from "@app/core/directives/click-stop-propagation.module";
import { LeadPercentageColorCodeDirective } from "@app/widgets/widgets/statistics-lead-result-widget-new/leadPercentageColorCode.directive";
import { KpiCollectionWidgetComponent } from "@app/widgets/widgets/kpi-collection-widget/kpi-collection-widget.component";
import { KpiModule } from "@app/kpi/kpi.module";
import { ShortcutsWidgetComponent } from "@app/widgets/widgets/shortcuts-widget/shortcuts-widget.component";
import { ShortcutsSortingModalComponent } from "@app/widgets/widgets/shortcuts-widget/shortcuts-sorting-modal/shortcuts-sorting-modal.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { SortablejsModule } from "ngx-sortablejs";
import { ShortcutsWidgetEffects } from "@app/widgets/widgets/shortcuts-widget/ngrx/shortcuts-widget.effects";
import { ThirdPartyIframeWidgetComponent } from "@app/widgets/widgets/third-party-iframe-widget/third-party-iframe-widget.component";

const effects = [
  StatisticsLeadResultWidgetEffects,
  StatisticsRevenueWidgetEffects,
  StatisticsNpsWidgetEffects,
  StatisticsSummaryWidgetEffects,
  StatisticsObjectStatusWidgetEffects,
  StatisticsSummaryWidgetEffects,
  StatisticsBudgetWidgetEffects,
  LatestObjectsWidgetEffects,
  LatestObjectsForSaleWidgetEffects,
  LatestContactsWidgetEffects,
  StatisticsEmployeesPerformanceWidgetEffects,
  ContactStatusWidgetEffects,
  ContactRelationsWidgetEffects,
  ObjectNotesWidgetEffects,
  StatisticsSoldObjectsWidgetEffects,
  StatisticsTailoredCampaignWidgetEffects,
  ShortcutsWidgetEffects,
];

const components = [
  StatisticsLeadResultWidgetNewComponent,
  StatisticsRevenueWidgetComponent,
  StatisticsNpsWidgetComponent,
  StatisticsSummaryWidgetComponent,
  StatisticsObjectStatusWidgetComponent,
  StatisticsBudgetWidgetComponent,
  CalendarWidgetComponent,
  FreeLeadsWidgetComponent,
  OverdueTasksWidgetComponent,
  OutgoingMailWidgetComponent,
  OutgoingMailListItemComponent,
  WidgetPageComponent,
  WidgetComponent,
  BrokerTimelineWidgetComponent,
  MoreBusinessOpportunitiesWidgetComponent,
  LatestObjectsWidgetComponent,
  CurrentTasksWidgetComponent,
  LatestContactsWidgetComponent,
  ContactDetailsWidgetComponent,
  ContactNextStepWidgetComponent,
  NextStepListItemComponent,
  ContactNotesWidgetComponent,
  ContactObjectsWidgetComponent,
  ContactResidencesWidgetComponent,
  ProjectOverviewWidgetComponent,
  ProjectObjectListWidgetComponent,
  ObjectUpcomingShowingsWidgetComponent,
  ObjectOverviewWidgetComponent,
  ObjectPartiesWidgetComponent,
  ObjectDescriptionsWidgetComponent,
  ObjectHighestBidWidgetComponent,
  ObjectImageCarouselWidgetComponent,
  RentalOverviewWidgetComponent,
  StatisticsEmployeesPerformanceWidgetComponent,
  ContactStatusWidgetComponent,
  ContactRelationsWidgetComponent,
  ContactRelationFormComponent,
  ContactStatusItemComponent,
  CompanyDetailsWidgetComponent,
  WidgetDetailFieldComponent,
  NpsDetailsModalComponent,
  SurveysModalComponent,
  LatestObjectsForSaleWidgetComponent,
  ObjectNotesWidgetComponent,
  CreateObjectNoteComponent,
  NoteListComponent,
  StatisticsSoldObjectsWidgetComponent,
  TasksWidgetComponent,
  ValueMonitorWidgetComponent,
  ObjectVideoModalComponent,
  StatisticsTailoredCampaignWidgetComponent,
  AdfenixPropertyReportingWidgetComponent,
  StatisticsAdfenixReportingWidgetComponent,
  StatisticsAdfenixReportingSummaryWidgetComponent,
  EraIntranetWidgetComponent,
  LeadPercentageColorCodeDirective,
  KpiCollectionWidgetComponent,
  ShortcutsWidgetComponent,
  ShortcutsSortingModalComponent,
  ThirdPartyIframeWidgetComponent,
];

const modules = [
  CommonModule,
  TranslateModule,
  TooltipModule,
  UtilModule,
  LineChartModule,
  BarChartModule,
  MatCardModule,
  UiComponentsModule,
  RouterModule,
  CalendarWidgetModule,
  CalendarModule,
  CalendarCommonModule,
  ListComponentsModule,
  BsDatepickerModule,
  ActionListModule,
  CallingListsModule,
  ModalModule,
  MatChipsModule,
  SkeletonScreenModule,
  ContactActionsWidgetModule,
  FormComponentsModule,
  NgxChartsModule,
  MatSortModule,
  SearchContactModule,
  RichTextEditorModule,
  ContactComponentsModule,
  ErpFormElementsModule,
  PopoverModule,
  NgxMaskDirective,
  NgxMaskPipe,
  MatTooltipModule,
  PropertyComponentsModule,
  MatDialogModule,
  ClickStopPropagationModule,
  KpiModule,
];

@NgModule({
  imports: [
    EffectsModule.forFeature(effects),
    ...modules,
    MatCheckboxModule,
    SortablejsModule,
    NgOptimizedImage,
  ],
  exports: [
    StatisticsLeadResultWidgetNewComponent,
    StatisticsRevenueWidgetComponent,
    StatisticsNpsWidgetComponent,
    StatisticsSummaryWidgetComponent,
    StatisticsObjectStatusWidgetComponent,
    StatisticsBudgetWidgetComponent,
    WidgetPageComponent,
    WidgetComponent,
    OutgoingMailListItemComponent,
    StatisticsEmployeesPerformanceWidgetComponent,
    CreateObjectNoteComponent,
    StatisticsSoldObjectsWidgetComponent,
    WidgetDetailFieldComponent,
    StatisticsTailoredCampaignWidgetComponent,
    StatisticsAdfenixReportingWidgetComponent,
    StatisticsAdfenixReportingSummaryWidgetComponent,
  ],
  declarations: [...components],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
    provideNgxMask(),
  ],
})
export class WidgetsModule {}
