import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contact, QObject } from "@app/models";
import { Municipality } from "@app/models/municipality";
import { Feature } from "@app/shared/config/models";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import * as formUtils from "@app/shared/utils/form-utils";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { ONLY_DIGITS } from "@app/shared/utils/regex-patterns";
import { ContactFormComponent } from "@app/sidebar/shared/contact-form/contact-form.component";
import {
  map,
  Observable,
  PartialObserver,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "vitec-form",
  templateUrl: "./vitec-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./vitec-form.component.scss",
  ],
})
export class VitecFormComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;
  @Output()
  submitClicked: EventEmitter<{ residence: {}; contact?: {} }> =
    new EventEmitter<{
      residence: {};
      contact?: {};
    }>();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() municipalities$: Observable<Municipality[]>;
  @Input() observer: PartialObserver<any>;
  @Input() preselectedMuncipitalityId: number;
  @Input() preselectedObjType: string | number;
  @Input() patching: boolean;
  @Input() residence: QObject;
  @Input() contact: Contact;
  @Input() addressSuggesterFeature: Feature;
  @Input() addressValidationFeature: Feature;
  @Input() countryCode: string;
  @Input() objectTypes: ObjectType[];
  unsubscribe$ = new Subject<void>();
  form: FormGroup;
  showContactError = false;
  municipalities: Municipality[];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.setFormValidators();
    this.registerObserverToFormValueChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: any): void {
    if (changes.preselectedMuncipitalityId || changes.preselectedObjType) {
      this.municipalities$.subscribe((municipalities) => {
        this.municipalities = municipalities;
        this.fillForm();
      });
    }
    if (changes.patching) {
      this.cdr.detectChanges();
    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      municipality: ["", Validators.required],
      objType: ["", Validators.required],
      zip: ["", [Validators.pattern(ONLY_DIGITS)]],
      city: [""],
    });
  }

  fillForm(): void {
    if (
      this.municipalities &&
      this.municipalities.find(
        (municipality: Municipality) =>
          municipality.municipalityId === this.preselectedMuncipitalityId
      )
    ) {
      this.form.get("municipality").setValue(this.preselectedMuncipitalityId);
    }
    if (
      this.objectTypes.find(
        (type: ObjectType) => type.objectTypeId === this.preselectedObjType
      )
    ) {
      this.form.get("objType").setValue(this.preselectedObjType);
    }
  }

  setFormValidators(): void {
    if (!this.residence.zip) {
      this.form.get("zip").setValidators([Validators.required]);
      this.form.get("zip").updateValueAndValidity();
    }
    if (!this.residence.city) {
      this.form.get("city").setValidators([Validators.required]);
      this.form.get("city").updateValueAndValidity();
    }
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  contactDetailsRequired(): boolean {
    return !(
      this.contact.msisdn ||
      this.contact.phoneNumber ||
      this.contact.email
    );
  }

  closeTab(): void {
    this.closeClicked.emit();
  }

  getResidenceValues(): any {
    const residenceValues = {
      objType: this.form.get("objType").value,
      municipality: this.form.get("municipality").value,
    };
    if (!this.residence.zip) {
      residenceValues["zip"] = this.form.get("zip").value;
    }
    if (!this.residence.city) {
      residenceValues["city"] = this.form.get("city").value;
    }
    return residenceValues;
  }

  getContactValues(): any {
    const contactValues = {
      msisdn: this.contactForm.form.get("contactInfo.msisdn").value,
      phoneNumber: this.contactForm.form.get("contactInfo.phoneNumber").value,
      email: this.contactForm.form.get("contactInfo.email").value,
    };
    return contactValues;
  }

  isFormValid(): boolean {
    if (this.form.valid && !this.contactDetailsRequired()) {
      return true;
    } else {
      return (
        this.form.valid &&
        this.contactDetailsRequired() &&
        this.contactFormHasValue()
      );
    }
  }

  contactFormHasValue(): boolean {
    return (
      this.contactForm.form.get("contactInfo.msisdn").value.length > 0 ||
      this.contactForm.form.get("contactInfo.phoneNumber").value.length > 0 ||
      this.contactForm.form.get("contactInfo.email").value.length > 0
    );
  }

  submit(): void {
    if (this.isFormValid()) {
      const params = {
        residence: this.getResidenceValues(),
        contact: this.contactDetailsRequired() ? this.getContactValues() : null,
      };
      this.submitClicked.emit(params);
    } else {
      this.showContactError =
        this.contactForm !== undefined && !this.contactFormHasValue();
      formUtils.markAllAsTouched(this.form);
      this.cdr.detectChanges();
    }
  }

  handleZipSelected(address: Address): void {
    this.form.get("zip").setValue(address.zip);
    this.form.get("city").setValue(address.city);
    this.form.updateValueAndValidity();
  }
}
