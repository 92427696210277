<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'edit_message_template' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <app-message-templates-form action="edit"
                                [template]="template$ | async"
                                [dynamicContents]="dynamicContents$ | async"
                                [isEditable]="isEditable$ | async">
    </app-message-templates-form>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      label="cancel"
      buttonType="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      *ngIf="isAdmin$ | async"
      tabindex="-1"
      label="remove"
      buttonType="remove"
      (click)="onDelete()"
    >
    </app-q-button>
    <app-q-button
      label="save"
      buttonType="save"
      [disabled]="!(isEditable$ | async)"
      (click)="onSubmit()"
    >
    </app-q-button>
  </div>
</div>
