<h3>
  <app-icon name="list" size="large"></app-icon>&nbsp;<span
    *ngIf="(statistics$ | async)?.length === 1; else multipleObjectStatuesTitle"
    >"{{ (statistics$ | async)[0].statusName | translate }} "
    {{ "object" | translate }}</span
  >
  <span
    class="info-tooltip"
    [tooltip]="'statistics_object_status_widget_tooltip' | translate"
  >
    <app-icon name="info-circle"></app-icon>
  </span>
  <ng-template #multipleObjectStatuesTitle>
    <span translate>key_object_statuses</span>
  </ng-template>
</h3>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div
    class="widget-container__data"
    *ngIf="statistics$ | async as stats; else noData"
  >
    <ng-container *ngIf="stats.length === 1; else multipleObjectStatusesTable">
      <table>
        <tbody>
          <tr>
            <td translate>status</td>
            <td>
              <span *ngIf="!!stats[0].statusName; else noDataInCell">{{
                stats[0].statusName | translate
              }}</span>
            </td>
          </tr>
          <tr>
            <td translate>amount</td>
            <td>
              <span *ngIf="!!stats[0].numberOfObjects; else noDataInCell">{{
                stats[0].numberOfObjects | qNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td translate>turnover_time</td>
            <td>
              <span *ngIf="!!stats[0].turnoverTime; else noDataInCell">{{
                stats[0].turnoverTime | qNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td translate>amount_getting_sold</td>
            <td>
              <span *ngIf="!!stats[0].soldRate; else noDataInCell"
                >{{ stats[0].soldRate | qNumber }}%</span
              >
            </td>
          </tr>
          <tr>
            <td translate>created_yesterday</td>
            <td>
              <span *ngIf="!!stats[0].createdYesterday; else noDataInCell">{{
                stats[0].createdYesterday | qNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td translate>created_last_seven_days</td>
            <td>
              <span *ngIf="!!stats[0].createdLast7days; else noDataInCell">{{
                stats[0].createdLast7days | qNumber
              }}</span>
            </td>
          </tr>
          <tr>
            <td translate>estimated_turnover</td>
            <td>
              <span *ngIf="!!stats[0].estimatedTurnover; else noDataInCell">{{
                stats[0].estimatedTurnover | qNumber
              }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
    <ng-template #multipleObjectStatusesTable>
      <table>
        <thead>
          <tr>
            <th translate>status</th>
            <th translate>amount</th>
            <th translate>turnover_time</th>
            <th translate>amount_getting_sold</th>
            <th translate>created_yesterday</th>
            <th translate>created_last_seven_days</th>
            <th translate>estimated_turnover</th>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          <tr *ngFor="let objectStatusStat of stats">
            <td>
              <span *ngIf="!!objectStatusStat.statusName; else noDataInCell">{{
                objectStatusStat.statusName | translate
              }}</span>
            </td>
            <td>
              <span
                *ngIf="!!objectStatusStat.numberOfObjects; else noDataInCell"
                >{{ objectStatusStat.numberOfObjects | qNumber }}</span
              >
            </td>
            <td>
              <span
                *ngIf="!!objectStatusStat.turnoverTime; else noDataInCell"
                >{{ objectStatusStat.turnoverTime | qNumber }}</span
              >
            </td>
            <td>
              <span *ngIf="!!objectStatusStat.soldRate; else noDataInCell"
                >{{ objectStatusStat.soldRate | qNumber }} %</span
              >
            </td>
            <td>
              <span
                *ngIf="!!objectStatusStat.createdYesterday; else noDataInCell"
                >{{ objectStatusStat.createdYesterday | qNumber }}</span
              >
            </td>
            <td>
              <span
                *ngIf="!!objectStatusStat.createdLast7days; else noDataInCell"
                >{{ objectStatusStat.createdLast7days | qNumber }}</span
              >
            </td>
            <td>
              <span
                *ngIf="!!objectStatusStat.estimatedTurnover; else noDataInCell"
                >{{ objectStatusStat.estimatedTurnover | qNumber }}</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </div>
  <div *ngIf="statistics$ | async as stats" class="widget-container__footer">
    <span> </span>
    <span *ngIf="stats.length > 5">
      <i class="icon-arrow-down-circle"></i> {{ "scroll_for_more" | translate }}
    </span>
  </div>
</div>

<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>

<ng-template #noDataInCell>
  <span>-</span>
</ng-template>
