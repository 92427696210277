import { Injectable } from "@angular/core";
import { TypedPaginationListDTO } from "@app/models";
import { SalesFocusFilter } from "@app/settings/map-areas/focus-areas/ngrx/state";
import { SalesFocusAreaEntity } from "@app/settings/map-areas/focus-areas/models/sales-focus-area-entity";
import { GeometryCollection, Polygon } from "geojson";
import { catchError, map, Observable, of } from "rxjs";
import { ApiService, Categories } from "../api/api.service";

type SalesFocusAreaResponse = TypedPaginationListDTO<SalesFocusAreaEntity>;

@Injectable({
  providedIn: "root",
})
export class FocusAreaService {
  constructor(private readonly api: ApiService) {}

  get(params: {
    filters?: SalesFocusFilter[];
    nestedFilters?: Record<string, unknown>[];
    sort?: { field: string; order: "asc" | "desc" };
    pagination?: { offset: number; limit: number };
  }): Observable<SalesFocusAreaEntity[]> {
    const filters = {
      pagination: params?.pagination ?? {
        limit: 1000,
        offset: 0,
      },
      sort: {
        field: "areaName",
        order: "asc",
      },
    };

    if (params?.nestedFilters?.length > 0) {
      filters["nestedFilters"] = params.nestedFilters;
    }

    if (params?.filters?.length > 0) {
      filters["filters"] = params.filters;
    }

    return this.api.post("sales-focus-areas/search", filters).pipe(
      map((response: SalesFocusAreaResponse) =>
        response.rows.map((area) => ({
          ...area,
          coordinates: this.getCoordinatesForGeometry(area.geometry),
        }))
      )
    );
  }

  createFocusArea(
    agent: {
      id: string;
      name: string;
    },
    office: {
      id: string;
      name: string;
    },
    geometry: Polygon,
    areaName: string,
    jsonData: Record<string, unknown> = null
  ): Observable<SalesFocusAreaEntity> {
    const params = {
      origin: "web",
      focusAreas: [{ areaName, geometry, jsonData }],
      office: {
        id: office.id,
      },
    };

    if (agent) {
      params["agent"] = {
        id: agent.id,
      };
    }

    return this.api.post(`sales-focus-areas`, params).pipe(
      map((response: { id: string }) => ({
        id: response.id,
        areaName,
        contacts: 0,
        geometry,
        coordinates: this.getCoordinatesForGeometry(geometry),
        origin: null,
        jsonData,
        agent: agent
          ? {
              id: agent.id,
              firstName: agent.name,
              familyName: null,
              email: null,
              mobileNumber: null,
              title: null,
              profilePhoto: null,
            }
          : null,
        office: office
          ? {
              id: office.id,
              name: office.name,
              email: null,
              phone: null,
              street: null,
              zip: null,
              city: null,
              xCoordinate: null,
              yCoordinate: null,
            }
          : null,
      }))
    );
  }

  removeFocusArea(id: string) {
    return this.api.delete(`sales-focus-areas/${id}`);
  }

  reassignFocusArea(id: string, officeId: string, agentId?: string) {
    const assignee = {
      office: { id: officeId },
      agent: {
        id: agentId,
      },
    };

    return this.api.patch(`sales-focus-areas/${id}/reassign`, assignee);
  }

  updateFocusAreaGeometry(area: SalesFocusAreaEntity) {
    const geometry = this.makeGeoJsonPolygon(area.coordinates);

    return this.api.patch(`sales-focus-areas/${area.id}`, {
      geometry,
    }) as Observable<SalesFocusAreaEntity>;
  }

  renameFocusArea(id: string, name: string, jsonData: Record<string, unknown>) {
    const params: Record<string, unknown> = {};
    if (name) {
      params.areaName = name;
    }

    if (!!jsonData) {
      params.jsonData = jsonData;
    }

    return this.api
      .patch(`sales-focus-areas/${id}`, params)
      .pipe(map(() => ({ id, ...params }))) as Observable<SalesFocusAreaEntity>;
  }

  getOptimalNumber(geometry: Polygon): Observable<number> {
    return this.api
      .post(
        "optimal/contacts-in-area",
        { geometry: { ...geometry } },
        Categories.Integrations
      )
      .pipe(
        map(
          (response: { numberOfContacts: number }) => response.numberOfContacts
        ),
        catchError(() => of(0))
      );
  }

  private getCoordinatesForGeometry(
    geometry: GeometryCollection | Polygon
  ): { lat: number; lng: number }[] {
    if (geometry.type === "Polygon") {
      return geometry.coordinates[0].map(([lng, lat]) => ({ lat, lng }));
    } else if (geometry.type === "GeometryCollection") {
      return geometry.geometries.flatMap(this.getCoordinatesForGeometry);
    }
    return [];
  }

  private makeGeoJsonPolygon(coordinates: { lat: number; lng: number }[]) {
    const mapped = coordinates.map(({ lng, lat }) => [lng, lat]);
    const lastCoordinate = mapped[mapped.length - 1];

    const lastCoordinateMatchesFirst =
      mapped[0][0] === lastCoordinate[0] && mapped[0][1] === lastCoordinate[1];

    if (!lastCoordinateMatchesFirst) {
      mapped.push(mapped[0]);
    }

    return {
      type: "Polygon",
      coordinates: [mapped],
    };
  }
}
