import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { fadeInOutTrigger } from "@app/shared/animations";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import * as progressWidgetActions from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as fromProgressWidget from "@app/shared/modules/progress-widget/ngrx/progress-widget.reducer";
import {
  BRAND_DANGER_COLOR,
  BRAND_SUCCESS_COLOR,
  BRAND_WARNING_COLOR,
  getProgressBarType,
} from "@app/shared/utils/colors";
import { select, Store } from "@ngrx/store";
import { first, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-progress-widget",
  templateUrl: "./progress-widget.component.html",
  styleUrls: ["./progress-widget.component.scss"],
  animations: [fadeInOutTrigger()],
})
export class ProgressWidgetComponent implements OnInit, OnDestroy {
  showProgressWidget = false;
  getProgressBarType = getProgressBarType;

  show$: Observable<boolean>;
  taskSetsInProgress$: Observable<TaskSet[]>;
  completedTaskSets$: Observable<TaskSet[]>;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.mapStateToProps();
    this.handleWarningPopup();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleWarningPopup() {
    window.addEventListener("beforeunload", (e) => {
      this.taskSetsInProgress$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((taskSets) => {
          if (taskSets.length === 0) {
            return undefined;
          }

          const confirmationMessage =
            "WARNING: There are tasks being done in the background!" +
            " - " +
            taskSets[0].label +
            " " +
            "If you leave this page the tasks won't finish";

          e.returnValue = confirmationMessage; //Gecko + IE
          return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        });
    });
  }

  getStrokeColor(value: number, total: number): string {
    switch (getProgressBarType(value, total)) {
      case "danger":
        return "red";
      case "warning":
        return "#FF9F00";
      case "success":
        return "#28E57C";
    }
  }

  toggleProgressWidget() {
    this.show$.pipe(first()).subscribe((show) => {
      if (show) {
        this.store.dispatch(progressWidgetActions.hideProgressWidget());
      } else {
        this.store.dispatch(progressWidgetActions.showProgressWidget());
      }
    });
  }

  private mapStateToProps(): void {
    this.show$ = this.store.pipe(
      select(fromProgressWidget.getProgressWidgetShow)
    );
    this.taskSetsInProgress$ = this.store.pipe(
      select(fromProgressWidget.getProgressWidgetTaskSetsInProgress)
    );
    this.completedTaskSets$ = this.store.pipe(
      select(fromProgressWidget.getProgressWidgetCompletedTaskSets)
    );
  }

  getCompletedIconName(taskSet: TaskSet): string {
    if (taskSet?.failed > 0 && taskSet?.completed > 0) {
      return "exclamation";
    } else if (taskSet?.failed > 0 && taskSet?.completed === 0) {
      return "exclamation";
    } else {
      return "check";
    }
  }

  getCompletedIconColor(taskSet: TaskSet): string {
    if (taskSet?.failed > 0 && taskSet?.completed > 0) {
      return BRAND_WARNING_COLOR;
    } else if (taskSet?.failed > 0 && taskSet?.completed === 0) {
      return BRAND_DANGER_COLOR;
    } else {
      return BRAND_SUCCESS_COLOR;
    }
  }

  getCompletedTooltip(taskSet: TaskSet): string {
    if (taskSet?.failed > 0 && taskSet?.completed > 0) {
      return "some_tasks_failed";
    } else if (taskSet?.failed > 0 && taskSet?.completed === 0) {
      return "all_tasks_failed";
    } else {
      return "all_tasks_success";
    }
  }
}
