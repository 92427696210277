import * as supportActions from "@app/sidebar/support/ngrx/support.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface SupportSidebarState {
  loaded: boolean;
  loading: boolean;
}

export const initialState: SupportSidebarState = {
  loaded: false,
  loading: false,
};

export function supportSidebarReducer(
  state: SupportSidebarState,
  action: Action
): SupportSidebarState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(supportActions.sendMessageToSupportRequest, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(supportActions.sendMessageToSupportSuccess, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),
  on(supportActions.sendMessageToSupportFailure, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  }))
);
