import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { ExchangeAccountDTO } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  concatMap,
  from as observableFrom,
  map,
  switchMap,
} from "rxjs";
import * as exchangeActions from "./settings-account-exchange.actions";

@Injectable()
export class SettingsAccountExchangeEffects {
  getExchangeAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exchangeActions.getExchangeDataRequest),
      switchMap(({ eaEmployeeId }) =>
        this.apiService.get(`employees/${eaEmployeeId}/exchange`).pipe(
          map((dto: ExchangeAccountDTO) =>
            exchangeActions.getExchangeDataSuccess({ email: dto.email })
          ),
          catchError((error) =>
            observableFrom([
              exchangeActions.getExchangeDataFailed(),
              toastActions.danger({ message: error.message }),
            ])
          )
        )
      )
    )
  );

  createExchangeIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exchangeActions.createExchangeIntegrationRequest),
      switchMap(({ dto }) =>
        this.apiService
          .post(`employees/${dto.eaEmployeeId}/exchange`, dto)
          .pipe(
            concatMap((response: ExchangeAccountDTO) =>
              observableFrom([
                exchangeActions.createExchangeIntegrationSuccess({
                  exchangeAccount: response,
                }),
                toastActions.success({
                  message: "create_exchange_integration_success",
                }),
              ])
            ),
            catchError(() =>
              observableFrom([
                exchangeActions.createExchangeIntegrationFailed(),
                toastActions.danger({ message: "exchange_connect_error" }),
              ])
            )
          )
      )
    )
  );

  deleteExchangeIntegration$ = createEffect(() =>
    this.actions$.pipe(
      ofType(exchangeActions.deleteExchangeIntegrationRequest),
      switchMap(({ eaEmployeeId }) =>
        this.apiService.delete(`employees/${eaEmployeeId}/exchange`).pipe(
          concatMap(() =>
            observableFrom([
              exchangeActions.deleteExchangeIntegrationSuccess(),
              toastActions.success({
                message: "delete_exchange_integration_success",
              }),
            ])
          ),
          catchError((error) =>
            observableFrom([
              exchangeActions.deleteExchangeIntegrationFailed(),
              toastActions.danger({ message: error.message }),
            ])
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
