<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'create_competition' | translate"
  ></sidebar-header>
  <div class="scroll-view">
    <competition-form
      *ngIf="dataForForm$ | async as data; else loading"
      [observer]="proxy$"
      [offices]="data.offices"
      [kpiTypes]="kpiTypes"
      [canEditOffice]="data.canEditOffice"
      [preselectedOfficeId]="data.userOfficeId"
      [mode]="competitionFormModes.CREATE_MODE"
      (submit)="submitCompetition()"
    >
    </competition-form>
  </div>
  <div class="sidebar-footer">
    <app-q-button buttonType="cancel" label="cancel" (click)="closeTab()">
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="create"
      (click)="competitionFormComponent.onSubmit()"
    >
    </app-q-button>
  </div>
</div>

<ng-template #loading>
  <competition-form-skeleton></competition-form-skeleton>
</ng-template>
