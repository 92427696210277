import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { PropertyNameComponent } from "@app/shared/modules/property-components/property-name/property-name.component";
import { PropertyTypeToIconPipe } from "@app/shared/modules/property-components/property-name/property-type-to-icon.pipe";
import { ObjectPartyItemComponent } from "@app/shared/modules/property-components/property-parties-list/object-party-item/object-party-item.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { PropertyTypeFromObjectPipe } from "./property-name/property-type-from-object.pipe";
import { PropertyPartiesListComponent } from "./property-parties-list/property-parties-list.component";
import { PropertyQuickActionsComponent } from "./property-quick-actions/property-quick-actions.component";
import { PropertySummaryDialogComponent } from "./property-summary/components/property-summary-dialog/property-summary-dialog.component";
import { PropertySummaryComponent } from "./property-summary/property-summary.component";
import { PropertyTriggerService } from "@app/shared/modules/property-components/property-trigger-modal/property-trigger.service";
import { PropertyTriggerModalComponent } from "@app/shared/modules/property-components/property-trigger-modal/property-trigger-modal.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UiComponentsModule,
    TooltipModule,
    MatTooltipModule,
    MatDialogModule,
    UtilModule,
    RouterModule,
    ContactComponentsModule,
    ReactiveFormsModule,
    ErpFormElementsModule,
  ],
  declarations: [
    PropertyNameComponent,
    PropertyTypeFromObjectPipe,
    PropertyTypeToIconPipe,
    PropertySummaryComponent,
    PropertySummaryDialogComponent,
    PropertyQuickActionsComponent,
    PropertyPartiesListComponent,
    ObjectPartyItemComponent,
    PropertyTriggerModalComponent,
  ],
  providers: [PropertyTriggerService],
  exports: [
    PropertyNameComponent,
    PropertyTypeFromObjectPipe,
    PropertyTypeToIconPipe,
    PropertyQuickActionsComponent,
    ObjectPartyItemComponent,
    PropertyPartiesListComponent,
  ],
})
export class PropertyComponentsModule {}
