import { Route } from "@angular/router";
import { MessageTemplateGuard } from "@app/sidebar/guards/message-template-create-guard.service";
import { MessageTemplatesCreateComponent } from "@app/sidebar/message-templates/containers/message-templates-create/message-templates-create.component";
import { MessageTemplatesEditComponent } from "@app/sidebar/message-templates/containers/message-templates-edit/message-templates-edit.component";

export const sidebarMessageTemplatesRoutes: Route = {
  path: "message-templates",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: MessageTemplatesCreateComponent,
      canActivate: [MessageTemplateGuard],
    },
    {
      path: ":id/edit",
      component: MessageTemplatesEditComponent,
      canActivate: [MessageTemplateGuard],
    },
  ],
};
