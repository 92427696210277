<app-q-button
  buttonType="remove"
  [label]="label"
  (click)="modal.show()">
</app-q-button>

<div bsModal
     class="modal fade"
     #modal="bs-modal"
     tabindex="-1"
     [config]="{backdrop: false}">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" (click)="modal.hide()">
          <span>&times;</span>
        </button>
        <h4
          class="modal-title">
          {{ headerText | translate }}
        </h4>
      </div>
      <div class="modal-body">
        <h5 translate>
          specify_reason_message
        </h5>
        <form [formGroup]="modalForm">

          <label class="radio-button-group">
            {{ 'insufficient_information' | translate }}
            <input
              type="radio"
              name="reason"
              formControlName="reason"
              [value]="reasons.INFORMATION_PROBLEM"
            >
            <span class="radio-button-group__checkmark"></span>
          </label>

          <label class="radio-button-group">
            {{ 'duplicate' | translate }}
            <input
              type="radio"
              formControlName="reason"
              name="reason"
              [value]="reasons.DUPLICATE"
            >
            <span class="radio-button-group__checkmark"></span>
          </label>

          <label class="radio-button-group">
            {{ 'other' | translate }}
            <input
              type="radio"
              formControlName="reason"
              name="reason"
              [value]="reasons.OTHER"
            >
            <span class="radio-button-group__checkmark"></span>
          </label>
        </form>
      </div>
      <div class="modal-footer">
        <app-q-button
          buttonType="cancel"
          label="cancel"
          classes="margin-right"
          (click)="modal.hide()">
        </app-q-button>
        <app-q-button
          buttonType="remove"
          label="remove"
          (click)="onDeleteClicked(); modal.hide()">
        </app-q-button>
      </div>
    </div>
  </div>
</div>
