<app-element-wrapper
  *ngIf="!!formElementService?.form"
  [infoTranslationKey]="infoTranslationKey"
  class="form-group text-input"
  [width]="width"
>
  <div [formGroup]="formElementService?.form">
    <mat-checkbox
      (blur)="onBlur()"
      (change)="onBlur()"
      [formControl]="formElementService.getFormControl()"
      >{{ label | translate }}</mat-checkbox
    >
  </div>
</app-element-wrapper>
