import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink } from "@app/contacts/contact.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { go } from "@app/core/ngrx/router/router.actions";
import { UrlService } from "@app/core/services/url/url.service";
import { Contact } from "@app/models";
import { SIDEBAR_SEND_MESSAGE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import {
  Buyer,
  PotentialBuyer,
  Seller,
  ShowingObject,
} from "@app/showings/models";
import { TemplateGroups } from "@app/sidebar/send-message/models";
import * as SendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { show } from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-property-parties-list",
  templateUrl: "./property-parties-list.component.html",
  styleUrls: ["./property-parties-list.component.scss"],
})
export class PropertyPartiesListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaOid: string;
  @Input() disableActions = false;

  eaOid$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  property$: Observable<ShowingObject>;
  sellers$: Observable<PotentialBuyer[]>;
  buyers$: Observable<PotentialBuyer[]>;
  owners$: Observable<PotentialBuyer[]>;
  tenants$: Observable<PotentialBuyer[]>;
  rental$: Observable<boolean>;
  selectedContactIds: string[] = [];

  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
    private objectService: ObjectService,
    private router: Router,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.eaOid$.next(this.eaOid);
      this.objectService.entityMap$
        .pipe(
          map((entities) => entities[this.eaOid]),
          first()
        )
        .subscribe((property) => {
          if (!property) {
            this.objectService
              .getById(this.eaOid, {
                getContacts: true,
                getLinks: true,
                getRents: true,
              })
              .subscribe((response) => {
                this.contactService.upsertManyInCache(response.contacts);
              });
          } else {
            this.contactService.upsertManyInCache(property.contacts);
          }
        });
    }
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(SendMessageActions.reset());
  }

  sendMessageClick(contactId: string) {
    //  Todo: Single select send message
    this.store.dispatch(SendMessageActions.reset());
    this.selectedContactIds = [contactId];
    this.openSendMessageSidebar();
  }

  handleCheckboxChanged(contact: Seller | Buyer | Partial<Contact>) {
    const alreadySelectedIndex = this.selectedContactIds.indexOf(
      contact.contactId
    );
    if (alreadySelectedIndex !== -1) {
      this.selectedContactIds.splice(alreadySelectedIndex, 1);
    } else if (alreadySelectedIndex === -1) {
      this.selectedContactIds.push(contact.contactId);
      this.store.dispatch(
        SendMessageActions.addContacts({ recipients: [new Contact(contact)] })
      );
    }
  }

  openSendMessageSidebar(): void {
    combineLatest([
      this.property$,
      this.store.pipe(select(fromSidebar.sidebarIsMinimized)),
    ])
      .pipe(first())
      .subscribe(([property, isMinimized]) => {
        const users = property.contacts
          .filter((contact) =>
            this.selectedContactIds.includes(contact.contactId)
          )
          .map((user) => new Contact(user));

        const actions = [
          SendMessageActions.setTemplateGroup({
            templateGroupName: TemplateGroups.Showings,
          }),
          SendMessageActions.setExtraDataSet({
            extraDataSet: { eaOid: property.eaOid },
          }),
          SendMessageActions.addContacts({ recipients: users }),
          SendMessageActions.setEaOid({ eaOid: property.eaOid }),
          RouterActions.go({
            path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }],
          }),
        ];

        actions.forEach((action) => this.store.dispatch(action));
        if (isMinimized) {
          this.store.dispatch(show());
        }
      });
  }

  handleContactClick(seller: Partial<PotentialBuyer>, event) {
    if (!event.target.attributes["disablenavigation"]) {
      this.store.dispatch(addBackLink({ backLink: this.router.url }));
      this.store.dispatch(go({ path: ["/crm", "contacts", seller.contactId] }));
    }
  }

  openInNewTab(e: MouseEvent, contactId: any) {
    e.stopPropagation();
    const commands = ["/crm", "contacts", contactId, "overview"];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  private mapStateToProps() {
    this.property$ = this.eaOid$.pipe(
      takeUntil(this.unsubscribe$),
      switchMap((eaOid) =>
        this.objectService.entityMap$.pipe(
          map((entities) => entities[eaOid]),
          filter((entity) => !!entity)
        )
      )
    );
    this.rental$ = this.property$.pipe(
      filter((property) => !!property),
      map((property) => !!parseInt(property.rentMonth, 10))
    );

    this.buyers$ = this.property$.pipe(
      map((property) => this.filterContacts(property, "buyer"))
    );

    this.sellers$ = this.property$.pipe(
      map((property) => this.filterContacts(property, "seller"))
    );

    this.owners$ = this.property$.pipe(
      map((property) => this.filterContacts(property, "owner"))
    );

    this.tenants$ = this.property$.pipe(
      map((property) => this.filterContacts(property, "tenant"))
    );
  }

  private filterContacts(property: ShowingObject, contactType: string) {
    if (!!property?.contacts) {
      return property.contacts.filter((c) => c.type === contactType);
    }

    return [];
  }
}
