import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "resources-empty",
  styleUrls: ["./resources-empty.component.scss"],
  template: `
    <div class="flex-center">
      <ng-content></ng-content>
    </div>
  `,
})
export class ResourcesEmptyComponent {}
