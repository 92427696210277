<section>
  <div class="input-iconfield input-iconfield--flag">
    <label>{{ "mobile" | translate }}</label>
    <input
      #input
      type="tel"
      (input)="onChange($event)"
      (keyup.enter)="onClick.emit(input.value)"
    />
    <country-code-selector
      class="icon"
      [lang]="languageCode"
      [iso]="countryCode"
      (onChange)="onCountryChange($event)"
    >
    </country-code-selector>
  </div>
  <ng-container *ngIf="showButton">
    <div class="button-container">
      <button
        type="button"
        class="btn btn-success"
        (click)="onClick.emit(input.value)"
      >
        {{ buttonText | translate }}
      </button>
    </div>
  </ng-container>
</section>
