import { EmployeeDTO } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const createEmployeeRequest = createAction(
  "[Sidebar Employees Create] CREATE_EMPLOYEE_REQUEST",
  props<{
    employee: EmployeeDTO;
    eaOfficeId: string;
  }>()
);

export const createEmployeeSuccess = createAction(
  "[Sidebar Employees Create] CREATE_EMPLOYEE_SUCCESS",
  props<{ employee: EmployeeDTO }>()
);

export const createEmployeeFailed = createAction(
  "[Sidebar Employees Create] CREATE_EMPLOYEE_FAILED"
);

export const reset = createAction("[Sidebar Employees Create] RESET");
