import { SessionState } from "./session.reducer";
import { createAction, props } from "@ngrx/store";

export const setSession = createAction(
  "[Session] SET_SESSION",
  props<{ session: SessionState }>()
);

export const getSession = createAction(
  "[Session] GET_SESSION",
  props<{ employee: { eaEmployeeId: string } }>()
);

export const getSessionFail = createAction(
  "[Session] GET_SESSION_FAIL",
  props<{ message: string }>()
);

export const storeSession = createAction(
  "[Session] STORE_SESSION",
  props<{ session: SessionState }>()
);

export const toggleToggleStateByKey = createAction(
  "[Session] TOGGLE_TOGGLE_STATE_BY_KEY",
  props<{ key: string }>()
);

export const setToggleStateByKey = createAction(
  "[Session] SET_TOGGLE_STATE_BY_KEY",
  props<{
    key: string;
    value: boolean;
  }>()
);

export const setAllToggleStatesTo = createAction(
  "[Session] SET_ALL_TOGGLE_STATE_TO",
  props<{
    state: boolean;
  }>()
);
