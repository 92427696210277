import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getLatestActionCompetition } from "@app/competitions/ngrx/competitions.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import {
  COMPETITIONS,
  COMPETITIONS_ID,
} from "@app/core/services/api/utils/api-endpoints";
import * as competitionSidebarActions from "@app/sidebar/competitions/ngrx/competitions.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, map, of as observableOf, switchMap, take } from "rxjs";

@Injectable()
export class CompetitionSidebarEffects {
  createCompetition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.createCompetitionRequest),
      switchMap(({ competition }) =>
        this.apiService.post(COMPETITIONS, competition).pipe(
          map(() => competitionSidebarActions.createCompetitionSuccess()),
          catchError(() =>
            observableOf(competitionSidebarActions.createCompetitionFailed())
          )
        )
      )
    )
  );

  createCompetitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.createCompetitionSuccess),
      map(() => toastActions.success({ message: "create_competition_success" }))
    )
  );

  createCompetitionFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.createCompetitionFailed),
      map(() => toastActions.danger({ message: "create_competition_failed" }))
    )
  );

  updateCompetition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.updateCompetitionRequest),
      switchMap(({ competition }) =>
        this.apiService
          .patch(COMPETITIONS_ID(competition.competitionId), competition)
          .pipe(
            map(() => competitionSidebarActions.updateCompetitionSuccess()),
            catchError(() =>
              observableOf(competitionSidebarActions.updateCompetitionFailed())
            )
          )
      )
    )
  );

  updateCompetitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.updateCompetitionSuccess),
      map(() => toastActions.success({ message: "update_competition_success" }))
    )
  );

  updateCompetitionFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.updateCompetitionFailed),
      map(() => toastActions.danger({ message: "update_competition_failed" }))
    )
  );

  deleteCompetition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.deleteCompetitionRequest),
      switchMap(({ competitionId }) =>
        this.apiService.delete(COMPETITIONS_ID(competitionId)).pipe(
          map(() => competitionSidebarActions.deleteCompetitionSuccess()),
          catchError(() =>
            observableOf(competitionSidebarActions.deleteCompetitionFailed())
          )
        )
      )
    )
  );

  deleteCompetitionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.deleteCompetitionSuccess),
      map(() => toastActions.success({ message: "delete_competition_success" }))
    )
  );

  deleteCompetitionFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionSidebarActions.deleteCompetitionFailed),
      map(() => toastActions.danger({ message: "delete_competition_failed" }))
    )
  );

  getCompetitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        competitionSidebarActions.updateCompetitionSuccess,
        competitionSidebarActions.createCompetitionSuccess,
        competitionSidebarActions.deleteCompetitionSuccess
      ),
      switchMap(() =>
        this.store.pipe(select(getLatestActionCompetition), take(1))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}
}
