<h3>
  <app-icon size="large" name="clock"></app-icon>
  <span translate>timeline</span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <action-list-item
    [eaEmployeeId]="eaEmployeeId"
    *ngFor="let item of latestActionsList$ | async"
    [item]="item"
  ></action-list-item>
</div>
