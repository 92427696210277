<div class="modal-header">
  <h4 class="modal-title pull-left">
    {{
      "add_bid_for"
        | translate
          : {
              name:
                (pbService.selectedPotentialBuyers$ | async)[0].firstName +
                " " +
                (pbService.selectedPotentialBuyers$ | async)[0].familyName
            }
    }}
  </h4>
  <button
    type="button"
    class="close pull-right"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  [appOverlaySpinner]="pendingRequest"
>
  <div class="modal-body">
    <div class="form-group">
      <label for="amount" class="required">
        {{ "bid_amount" | translate }}
      </label>
      <span class="latest-bid" *ngIf="highestBid$ | async as highestBid">
        ({{ "highest_bid" | translate }}: {{ highestBid | qNumber }}
        {{ currency$ | async }})
      </span>
      <input
        thousandSeparator
        type="text"
        min="0"
        id="amount"
        formControlName="amount"
      />
      <div ngxErrors="amount">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="add-bid__controls form-group">
      <section>
        <label class="required" translate>date</label>
        <form-input-datepicker-dropdown
          [group]="form"
        ></form-input-datepicker-dropdown>
        <div ngxErrors="date">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
          <div translate ngxError="pattern" showWhen="touched">
            validation_date_pattern
          </div>
        </div>
      </section>

      <section class="time">
        <label class="required" translate>time</label>
        <time-picker-input
          #time
          (click)="time.select()"
          formControlName="time"
        ></time-picker-input>
        <div ngxErrors="time">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
          <div translate ngxError="pattern" showWhen="touched">
            wrong_time_format
          </div>
        </div>
      </section>
    </div>

    <div class="add-bid__controls">
      <section class="comment">
        <label translate>comment</label>
        <textarea
          type="text"
          placeholder="{{ 'comment_optional' | translate }}"
          formControlName="condition"
        ></textarea>
      </section>
    </div>

    <div class="checkbox">
      <label class="checkbox-group">
        <input type="checkbox" formControlName="isHidden" />
        <span class="checkbox-group__checkmark"></span>
      </label>
      <span class="checkbox__label">{{ "hide_bid" | translate }}</span>
      <i
        *ngIf="hideBidTranslationKey$ | async as translationKey"
        class="icon-question"
        [tooltip]="translationKey | translate"
        placement="right"
      ></i>
    </div>
    <div
      class="checkbox"
      *ngIf="enableBroadcastSetting | async as enabledSetting"
    >
      <label class="checkbox-group">
        <input
          type="checkbox"
          formControlName="broadcastBidToOtherBidders"
          [checked]="enabledSetting"
        />
        <span class="checkbox-group__checkmark"></span>
      </label>
      <span class="checkbox__label">{{
        "broadcast_bid_to_other_bidders" | translate
      }}</span>
      <i
        class="icon-question"
        [tooltip]="'broadcast_bid_to_other_bidders_tooltip' | translate"
        placement="right"
      ></i>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default"
      (click)="bsModalRef.hide()"
      translate
    >
      close
    </button>
    <button type="submit" class="btn btn-success" translate>add</button>
  </div>
</form>
