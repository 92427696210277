<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'create_search_profile' | translate"></sidebar-header>
  <div class="scroll-view" *ngIf="!(loading$ | async); else contentLoader">
    <section>
      <app-search-profile-form
        [additionalFilters]="additionalFilters$ | async"
        [editMode]="false"
        [observer]="proxy$"
        [currency]="currency$ | async"
        (submit)="createSearchProfile($event)"
      ></app-search-profile-form>
    </section>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      (click)="closeTab()"
      buttonType="cancel"
      label="cancel"
    >
    </app-q-button>
    <app-q-button
      [isLoading]="patching$ | async"
      (click)="submit()"
      buttonType="save"
      label="save"
    >
    </app-q-button>
  </div>
</div>

<ng-template #contentLoader>
  <section>
    <app-skeleton-sidebar></app-skeleton-sidebar>
  </section>
</ng-template>
