import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import * as sessionActions from "@app/core/services/session/ngrx/session.actions";
import { getToggleState } from "@app/core/services/session/ngrx/session.reducer";
import * as outgoingMailActions from "@app/dashboard/outgoing-mails/outgoing-mail.actions";
import { OutgoingMailsRequest } from "@app/dashboard/outgoing-mails/outgoing-mail.actions";
import * as fromOutgoingMail from "@app/dashboard/outgoing-mails/outgoing-mail.reducer";
import { OutgoingMail } from "@app/models";
import * as fromUser from "@app/shared/user/user.selectors";
import * as colors from "@app/shared/utils/colors";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  filter,
  map,
  Observable,
  Subscription,
} from "rxjs";
import { getOutgoingMailRequestObject } from "@app/shared/user";

@Component({
  selector: "app-outgoing-mail-widget",
  templateUrl: "./outgoing-mail-widget.component.html",
  styleUrls: ["./outgoing-mail-widget.component.scss"],
})
export class OutgoingMailWidgetComponent implements OnInit, OnDestroy {
  office$$: Subscription;
  outgoingMailList$: Observable<OutgoingMail[]>;
  outgoingMailLoading$: Observable<boolean>;
  showAllToggle$: Observable<boolean>;
  showToggleSlide$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.showAllToggle$ = this.store.pipe(
      select(getToggleState("outgoingMail"))
    );
    this.outgoingMailList$ = this.store.pipe(select(fromOutgoingMail.getList));
    this.outgoingMailLoading$ = this.store.pipe(
      select(fromOutgoingMail.getLoading)
    );
    this.office$$ = this.store
      .pipe(
        getOutgoingMailRequestObject,
        filter(
          (req: OutgoingMailsRequest) => !(req.eaEmployeeId && req.eaOfficeId)
        )
      )
      .subscribe((req) => {
        this.store.dispatch(outgoingMailActions.getOutgoingMails(req));
      });

    this.showToggleSlide$ = observableCombineLatest([
      this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER))),
      this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN))),
    ]).pipe(map(([isManager, isAdmin]) => isManager || isAdmin));
  }

  ngOnDestroy() {
    if (this.office$$) {
      this.office$$.unsubscribe();
    }
  }

  resolveIcon(period: string) {
    return period === "none" ? "fa fa-envelope fa-2x" : "fa fa-repeat fa-2x";
  }

  resolveColor() {
    return colors.CAMPAIGNS_COLOR;
  }

  onPreviewClick(mail: OutgoingMail) {
    this.store.dispatch(
      previewModalActions.showPreviewModal({
        parameters: { html: mail.content },
      })
    );
  }

  onClick(mail: OutgoingMail) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "crm",
          "lists",
          "outgoing-messages",
          { activityId: mail.eaCrmActivityId },
        ],
      })
    );
  }

  toggleShowAll(): void {
    this.store.dispatch(
      sessionActions.toggleToggleStateByKey({ key: "outgoingMail" })
    );
  }
}
