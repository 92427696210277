import { Injectable } from "@angular/core";
import { Action, select, Store } from "@ngrx/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  distinctUntilChanged,
  first,
  forkJoin,
  from,
  map,
  of,
  switchMap,
  tap,
} from "rxjs";
import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import { undo } from "ngrx-undo";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { MANAGE_POTENTIAL_BUYER_MULTIPLE } from "@app/shared/utils/tab-types";
import { ApiService } from "@app/core/services/api/api.service";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as showingAttendanceActions from "@app/sidebar/potential-buyer/ngrx/attendance/attendance.actions";

@Injectable()
export class ShowingAttendanceEffects {
  latestFetchShowingAttendance$: Action;

  fetchShowingAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingAttendanceActions.fetchShowingAttendanceRequest),
      tap((action) => (this.latestFetchShowingAttendance$ = action)),
      switchMap(({ type, ...payload }) =>
        this.api.get("contact-showing-attendances/search", payload).pipe(
          map((response: any) =>
            response?.contactShowingAttendances.map(
              (item) => new ContactShowingAttendance(item)
            )
          ),
          map((contactsShowingAttendance) =>
            showingAttendanceActions.fetchShowingAttendanceSuccess({
              contactsShowingAttendance,
            })
          ),
          catchError(() =>
            of(showingAttendanceActions.fetchShowingAttendanceFailure())
          )
        )
      )
    )
  );

  updateShowingAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingAttendanceActions.updateShowingAttendanceRequest),
      switchMap(({ contactId, body, undoAction, origin }) =>
        this.api
          .post(
            "contact-showing-attendances",
            { ...body, contactId, origin },
            "api"
          )
          .pipe(
            map(() => {
              this.contactService
                .refreshPotentialBuyers()
                .pipe(first())
                .subscribe();
              this.objectService
                .refreshShowingObject()
                .pipe(first())
                .subscribe();
              return showingAttendanceActions.updateShowingAttendanceSuccess();
            }),
            catchError(() =>
              of(
                showingAttendanceActions.updateShowingAttendanceFailure(),
                undo(undoAction)
              )
            )
          )
      )
    )
  );

  deleteShowingAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingAttendanceActions.deleteShowingAttendanceRequest),
      switchMap(({ contactId, showingId, eaShowingSlotId }) => {
        if (!!eaShowingSlotId) {
          return this.api
            .delete(
              `showings/${showingId}/consumer-attendance/${contactId}/slot/${eaShowingSlotId}`,
              {},
              "api"
            )
            .pipe(
              map(() =>
                showingAttendanceActions.deleteShowingAttendanceSuccess()
              ),
              catchError(() =>
                of(showingAttendanceActions.deleteShowingAttendanceFailure())
              )
            );
        } else {
          return this.api
            .delete(
              `showings/${showingId}/consumer-attendance/${contactId}`,
              {},
              "api"
            )
            .pipe(
              map(() =>
                showingAttendanceActions.deleteShowingAttendanceSuccess()
              ),
              catchError(() =>
                of(showingAttendanceActions.deleteShowingAttendanceFailure())
              )
            );
        }
      })
    )
  );

  deleteShowingAttendanceBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingAttendanceActions.deleteShowingAttendanceBatchRequest),
      switchMap(({ contactIds, showingId, eaShowingSlotId }) => {
        const requests = contactIds.map((contactId) => {
          if (!!eaShowingSlotId) {
            return this.api.delete(
              `showings/${showingId}/consumer-attendance/${contactId}/slot/${eaShowingSlotId}`,
              {},
              "api"
            );
          } else {
            return this.api.delete(
              `showings/${showingId}/consumer-attendance/${contactId}`,
              {},
              "api"
            );
          }
        });
        return forkJoin(requests).pipe(
          map(() =>
            showingAttendanceActions.deleteShowingAttendanceBatchSuccess()
          ),
          catchError(() =>
            of(showingAttendanceActions.deleteShowingAttendanceBatchFailure())
          )
        );
      })
    )
  );

  refreshShowingAttentance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        showingAttendanceActions.deleteShowingAttendanceSuccess,
        showingAttendanceActions.deleteShowingAttendanceBatchSuccess
      ),
      tap(() =>
        this.objectService.refreshShowingObject().pipe(first()).subscribe()
      ),
      switchMap(() => {
        return from([this.latestFetchShowingAttendance$]);
      })
    )
  );

  updateShowingAttendanceBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingAttendanceActions.updateShowingAttendanceBatchRequest),
      switchMap(({ contactIds, body, undoAction }) => {
        const requests = contactIds.map((contactId) =>
          this.api.post(
            "contact-showing-attendances",
            { ...body, contactId },
            "api"
          )
        );
        return forkJoin(requests).pipe(
          map(() => {
            this.contactService
              .refreshPotentialBuyers()
              .pipe(first())
              .subscribe();
            this.objectService.refreshShowingObject().pipe(first()).subscribe();
            return showingAttendanceActions.updateShowingAttendanceBatchSuccess();
          }),
          catchError(() =>
            of(
              showingAttendanceActions.updateShowingAttendanceBatchFailure(),
              undo(undoAction)
            )
          )
        );
      })
    )
  );

  resetAttendanceState$ = createEffect(() =>
    this.store.pipe(
      select(fromSidebar.getTabs),
      map(
        (tabs) =>
          tabs.filter((tab) => tab.type === MANAGE_POTENTIAL_BUYER_MULTIPLE)
            .length > 0
      ),
      distinctUntilChanged(),
      map(() => showingAttendanceActions.reset())
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private objectService: ObjectService,
    private contactService: ContactService
  ) {}
}
