<div
  class="search-address-advanced-container"
  [class.pseudo-spinner-large]="loading"
  [class.new-style]="newStyle"
>
  <label *ngIf="placeholder">{{ placeholder }}</label>
  <mat-form-field
    *ngIf="enabled"
    class="input-container"
    appearance="outline"
    [class.ng-invalid]="invalid"
  >
    <input
      type="text"
      matInput
      [formControl]="addressControl"
      [matAutocomplete]="auto"
      (blur)="onBlur()"
    />
    <mat-autocomplete
      [displayWith]="textInputDisplayFormatter.bind(this)"
      (optionSelected)="handleSelect($event)"
      #auto="matAutocomplete"
    >
      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
      >
        {{ formatOptionItem(option) }}
      </mat-option>
    </mat-autocomplete>
    <span
      *ngIf="!!addressControl.value"
      class="clear-button"
      [class.with-label]="placeholder"
      (click)="handleSelect(null)"
    >
      <app-icon name="times" size="small"></app-icon>
    </span>
  </mat-form-field>

  <mat-form-field
    *ngIf="!enabled"
    class="input-container"
    appearance="outline"
    [class.ng-invalid]="invalid"
  >
    <input
      type="text"
      matInput
      [formControl]="addressControl"
      (blur)="onBlur()"
    />
  </mat-form-field>
</div>
