import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";
import { getFeature } from "@app/shared/config/config.reducer";
import { REGIONS, TEAMS } from "@app/shared/config/utils/features";
import { AdvancedOfficeEmployeeDropdownService } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.service";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { hasRole, isManagerOrAdmin } from "@app/shared/user";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs";

export type AvailabilityModes = "liberal" | "medium" | "restrictive";

@Component({
  selector: "app-advanced-office-employee-dropdown",
  templateUrl: "./advanced-office-employee-dropdown.component.html",
  styleUrls: ["./advanced-office-employee-dropdown.component.scss"],
})
export class AdvancedOfficeEmployeeDropdownComponent
  implements OnInit, OnDestroy
{
  @Input() officeMultiple = false;
  @Input() employeeMultiple = false;
  @Input() setDefaults = false;
  @Input() sideBySide = true;
  @Input() availabilityMode: AvailabilityModes = "medium";
  @Input() officeFormControl: FormControl = new FormControl([[]]);
  @Input() employeeFormControl: FormControl = new FormControl([[]]);
  @Input() officeRequired = false;
  @Input() employeeRequired = false;
  @Input() employeeDisabled = false;
  @Input() teamsDisabled = false;
  @Input() newStyle = false;
  @Input() officeLabel = "office";
  @Input() employeeLabel = "employee";

  isAdminOrManager$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  regionFeatureEnabled$: Observable<boolean>;
  teamFeatureEnabled$: Observable<boolean>;
  officeItems$: Observable<DropdownItem[]>;
  employeeItems$: BehaviorSubject<DropdownItem[]> = new BehaviorSubject<
    DropdownItem[]
  >([]);
  activeEmployees$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "active"
  );
  teamEmployees$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "employees"
  );
  officeRegion$: BehaviorSubject<string> = new BehaviorSubject<string>(
    "offices"
  );

  regionControl = new FormControl();
  teamControl = new FormControl();
  regionSegmentControls: SegmentControls = ["offices", "regions"];
  activeSegmentControls: SegmentControls = ["active", "inactive"];
  teamSegmentControls: SegmentControls = ["employees", "teams"];
  disableEmployeeSegment = true;
  disableTeamSegment$ = new BehaviorSubject<boolean>(false);

  private unsubscribe$: Subject<void> = new Subject<void>();

  get isOfficeSelected() {
    return (
      this.officeFormControl.value && this.officeFormControl.value?.length > 0
    );
  }

  get isEmployeeSelected() {
    return (
      this.employeeFormControl.value &&
      this.employeeFormControl.value?.length > 0
    );
  }

  constructor(
    private store: Store<AppState>,
    public officeEmployeeDropdownService: AdvancedOfficeEmployeeDropdownService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initOfficeEmployees();
    this.handleRegionChanges();
    this.handleEmployeeChanges();
    this.handleTeamChanges();
    this.handleEmployeeSegmentStatus();

    if (!!this.setDefaults) {
      // Yes, this is stupid. Wasn't able to find a fix, and 1 ms doesnt affect UX so I went for it...
      // Please replace if a good solution is found :)
      setTimeout(() => {
        this.handleDefaults();
        this.employeeItems$
          .pipe(
            filter((employees) => employees?.length > 0),
            withLatestFrom(this.isAdminOrManager$),
            first()
          )
          .subscribe(([_employees, isAdminOrManager]) => {
            const eaOfficeIds = this.officeFormControl.value;
            this.disableEmployeeSegment =
              eaOfficeIds.length === 0 ||
              (this.availabilityMode === "restrictive" && !isAdminOrManager);

            if (this.disableEmployeeSegment) {
              this.employeeFormControl.disable({ emitEvent: false });
            } else {
              this.employeeFormControl.enable({ emitEvent: false });
            }
          });
      }, 1);
    } else {
      if (this.disableEmployeeSegment) {
        this.employeeFormControl.disable({ emitEvent: false });
      } else {
        this.employeeFormControl.enable({ emitEvent: false });
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initOfficeEmployees() {
    this.officeItems$ = this.officeRegion$.pipe(
      switchMap((officeRegion) => {
        return this.officeEmployeeDropdownService.oldGetOffices(
          this.availabilityMode,
          officeRegion
        );
      }),
      takeUntil(this.unsubscribe$)
    );

    if (!this.employeeDisabled) {
      combineLatest([
        this.officeEmployeeDropdownService.oldGetEmployees(
          this.officeFormControl,
          this.availabilityMode,
          this.activeEmployees$
        ),
        this.teamEmployees$,
      ])
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(([{ employees, teams }, teamEmployees]) => {
          this.disableTeamSegment$.next(teams?.length === 0);

          if (teamEmployees === "employees") {
            this.employeeItems$.next(employees);
            return;
          }

          if (teams?.length > 0) {
            this.employeeItems$.next(teams);
          } else {
            setTimeout(() => {
              this.teamEmployees$.next("employees");
            }, 100);
          }
        });
      this.officeFormControl.setValue(this.officeFormControl.value);
    }
  }

  private handleDefaults() {
    this.officeEmployeeDropdownService.setDefaults(
      this.officeFormControl,
      this.employeeFormControl,
      this.availabilityMode,
      this.officeMultiple,
      this.employeeMultiple,
      this.employeeRequired
    );
  }

  private mapStateToProps() {
    this.isAdminOrManager$ = this.store.pipe(select(isManagerOrAdmin));
    this.isAdmin$ = this.store.pipe(select(hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(hasRole(ROLE_MANAGER)));
    this.regionFeatureEnabled$ = this.store.pipe(
      select(getFeature(REGIONS)),
      map((feature) => feature.enabled)
    );
    this.teamFeatureEnabled$ = combineLatest([
      this.store.pipe(
        select(getFeature(TEAMS)),
        map((feature) => feature.enabled)
      ),
      this.disableTeamSegment$,
      of(this.employeeMultiple),
      of(this.teamsDisabled),
    ]).pipe(
      map(
        ([feature, disableTeamSegment, employeeMultiple, teamsDisabled]) =>
          feature && !disableTeamSegment && employeeMultiple && !teamsDisabled
      )
    );
  }

  private handleRegionChanges() {
    this.regionControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        map((value: string[]) => [
          ...new Set(_.flatten(value.map((val) => val.split(",")))),
        ])
      )
      .subscribe((value) => {
        this.officeFormControl.setValue(value);
      });
  }

  private handleEmployeeSegmentStatus() {
    combineLatest([this.officeFormControl.valueChanges, this.employeeItems$])
      .pipe(
        withLatestFrom(this.isAdminOrManager$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([[eaOfficeIds, employees], isAdminOrManager]) => {
        this.disableEmployeeSegment =
          !eaOfficeIds ||
          eaOfficeIds?.length === 0 ||
          !employees ||
          employees?.length === 0 ||
          (this.availabilityMode === "restrictive" && !isAdminOrManager);

        if (this.disableEmployeeSegment) {
          this.employeeFormControl.disable({ emitEvent: false });
        } else {
          this.employeeFormControl.enable({ emitEvent: false });
        }

        if (!eaOfficeIds || eaOfficeIds?.length === 0) {
          this.employeeFormControl.setValue([]);
          this.teamControl.setValue([]);
        }
      });
  }

  private handleTeamChanges() {
    this.teamControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((teams) => {
        if (
          (!teams || teams.length === 0) &&
          (!this.employeeFormControl.value ||
            this.employeeFormControl.value?.length === 0)
        ) {
          return;
        }

        let selectedTeam = [];
        if (!Array.isArray(teams)) {
          selectedTeam = [teams];
        } else {
          selectedTeam = [...teams];
        }

        this.officeEmployeeDropdownService.getEmployeesFromTeam(
          selectedTeam,
          this.employeeFormControl
        );
      });
  }

  private handleEmployeeChanges() {
    this.employeeFormControl.valueChanges
      .pipe(
        filter((employees) => !employees || employees.length === 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        if (!this.teamControl.value || this.teamControl.value?.length === 0) {
          return;
        }

        this.teamControl.setValue([]);
      });
  }

  handleSelectAllOffices(select: boolean) {
    if (!select) {
      combineLatest([
        this.isAdminOrManager$,
        this.officeItems$.pipe(
          map((officeItems) => officeItems.map((item) => item.value))
        ),
      ])
        .pipe(
          first(),
          map(([isManagerOrAdmin, officeItems]) => {
            if (this.officeMultiple) {
              return { isManagerOrAdmin, officeItems };
            }

            return { isManagerOrAdmin, officeItems: [officeItems[0]] };
          })
        )
        .subscribe(({ isManagerOrAdmin, officeItems }) => {
          if (!isManagerOrAdmin && !!this.setDefaults) {
            this.officeFormControl.setValue(officeItems);
          } else {
            this.officeFormControl.setValue([]);
          }
        });
      return;
    }

    this.officeItems$.pipe(first()).subscribe((items) => {
      this.officeFormControl.setValue(items.map((item) => item.value));
    });
  }

  handleSelectAllEmployees(select: boolean) {
    if (!select) {
      this.teamControl.setValue([]);
      this.employeeFormControl.setValue([]);
      return;
    }

    this.employeeItems$
      .pipe(withLatestFrom(this.teamEmployees$), first())
      .subscribe(([items, teamEmployeeSegment]) => {
        if (teamEmployeeSegment === "employees") {
          this.employeeFormControl.setValue(items.map((item) => item.value));
        } else {
          this.teamControl.setValue(items.map((item) => item.value));
        }
      });
  }
}
