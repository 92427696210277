<form [formGroup]="form">
  <section class="template">
    <ng-container *ngIf="action === 'create'">
      <div class="template__control">
        <app-segmented-controls
          [controls]="messageTypeControls"
          [active]="form.get('messageType').value"
          (change)="changeMessageType($event)"
          [truncate]="false"
        >
        </app-segmented-controls>
      </div>
      <div class="template__control">
        <label for="context" translate>context</label>
        <app-q-select
          id="context"
          formControlName="context"
          [options]="contextControls"
          [inline]="false"
          [invalid]="form.get('context').touched && form.get('context').invalid"
        ></app-q-select>
      </div>
    </ng-container>
    <div class="template__control">
      <office-employee-dropdown
        (onReady)="handleOfficeEmployeeFields()"
        [disableSelectBroker]="!(isAdmin$ | async) && !(isManager$ | async)"
        [disableSelectOffice]="!(isAdmin$ | async)"
        [parentFormGroup]="form"
        [setDefaults]="false"
        [sideBySide]="false"
        parentEmployeeFormControlName="eaEmployeeId"
        parentOfficeFormControlName="eaOfficeId"
      >
      </office-employee-dropdown>
    </div>
    <label for="name" translate>name</label>
    <div class="template__control margin-top--16">
      <app-q-input
        id="name"
        placeholder="name"
        formControlName="name"
        [invalid]="form.get('name').invalid && form.get('name').touched"
      >
      </app-q-input>
      <div ngxErrors="name">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <label for="description" translate>description</label>
    <div class="template__control margin-top--16">
      <app-q-input
        id="description"
        placeholder="description"
        formControlName="description"
      >
      </app-q-input>
    </div>
    <div class="template__control">
      <ng-container
        *ngIf="isEditable; then dcContainer; else noPermission"
      ></ng-container>
    </div>
  </section>
</form>

<ng-template #dcContainer>
  <app-message-templates-dynamic-contents
    [parentForm]="form"
    [action]="action"
    [dynamicContents]="dynamicContents"
    [isEmail]="isEmail()"
  ></app-message-templates-dynamic-contents>
</ng-template>
<ng-template #noPermission>
  <div class="alert alert-info">
    {{ "dont_have_permission_to_edit" | translate }}
  </div>
</ng-template>
