import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Contact } from "@app/models";
import { Action, ActionsSubject, select, Store } from "@ngrx/store";
import { first, Observable, switchMap } from "rxjs";
import * as smActions from "../ngrx/sales-meeting.actions";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";
import { ofType } from "@ngrx/effects";

@Injectable()
export class SalesMeetingContactResolver implements Resolve<Contact | Action> {
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Contact> {
    const { contactId } = route.params;
    this.store.dispatch(smActions.getContactRequest({ contactId }));

    return this.dispatcher.pipe(
      ofType(smActions.getContactSuccess, smActions.getContactFail),
      switchMap(() => this.store.pipe(select(fromSalesMeeting.getContact))),
      first()
    );
  }
}
