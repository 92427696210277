import { OutgoingMail } from "@app/models";
import { createAction, props } from "@ngrx/store";

export interface OutgoingMailsRequest {
  eaEmployeeId?: string;
  eaOfficeId?: string;
  status?: string;
}

export const getOutgoingMails = (params: OutgoingMailsRequest) => {
  const filters = {
    ...params,
    status: "pregenerated",
  };
  return getOutgoingMailsRequest({ filters });
};

export const getOutgoingMailsRequest = createAction(
  "[Outgoing Mails] GET_OUTGOING_MAILS_REQUEST",
  props<{ filters: OutgoingMailsRequest }>()
);

export const getOutgoingMailsSuccess = createAction(
  "[Outgoing Mails] GET_OUTGOING_MAILS_SUCCESS",
  props<{ mails: OutgoingMail[] }>()
);
