import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Employee } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/features";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { getEaOfficeId, getEmployee } from "@app/shared/user";
import * as formUtils from "@app/shared/utils/form-utils";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  first,
  map,
  Observable,
  PartialObserver,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-search-profile-alert-form",
  templateUrl: "./search-profile-alert-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./search-profile-alert-form.component.scss",
  ],
})
export class SearchProfileAlertFormComponent implements OnInit, OnChanges {
  @Input() alertActivities: { activityId: string; name: string }[];
  @Input() alert: Alert;
  @Input() canEditEmployee = true;
  @Input() canEditOffice = true;
  @Input() observer: PartialObserver<any>;

  @Output() submitForm: EventEmitter<string> = new EventEmitter<string>();
  @Output() preview: EventEmitter<void> = new EventEmitter<void>();

  showPreviewButton = false;
  editResponsibleBroker = false;

  receiverControls: SegmentControls = [
    {
      label: "contact",
      value: "contact",
    },
    {
      label: "broker",
      value: "broker",
    },
    {
      label: "responsible_broker",
      value: "responsible_broker",
    },
  ];

  selectedSendToSegment: string = "contact";
  unsubscribe$: Subject<void> = new Subject<void>();
  alertForm: FormGroup;
  searchProfileFeature$: Observable<SearchProfileFeature>;
  eaOfficeId$: Observable<string>;
  employee$: Observable<Employee>;

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.registerObserverToFormValueChanges();
    this.mapStateToProps();
    this.alertForm
      .get("sendConfirmationMail")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => (this.showPreviewButton = val));

    this.handleOfficeEmployeeDropdown();

    if (!!this.alertActivities && this.alertActivities.length === 1) {
      this.alertForm
        .get("activityId")
        .setValue(this.alertActivities[0].activityId);
    }

    if (!this.alert) {
      this.editResponsibleBroker = true;
    }
  }

  ngOnChanges(changes) {
    if (changes.alert && !!this.alert) {
      this.fillForm();
    }
  }

  private buildForm(): void {
    this.alertForm = this.fb.group({
      activityId: ["", Validators.required],
      responsibleEmployee: this.fb.group({
        office: ["", Validators.required],
        employee: ["", Validators.required],
      }),
      sendConfirmationMail: false,
      sendAlertToEmployee: [false],
      sendAlertToResponsibleEmployee: false,
    });
  }

  hasActivityIdSelected(): boolean {
    return this.alertForm.get("activityId").value;
  }

  handleSubmit(): void {
    if (this.alertForm.valid) {
      this.alert
        ? this.submitForm.emit(this.alert.alertId)
        : this.submitForm.emit();
    } else {
      formUtils.markAllAsTouched(this.alertForm);
      this.cdr.detectChanges();
    }
  }

  handlePreview(): void {
    if (this.alertForm.valid) {
      this.preview.emit();
    } else {
      formUtils.markAllAsTouched(this.alertForm);
      this.cdr.detectChanges();
    }
  }

  private registerObserverToFormValueChanges(): void {
    this.alertForm.valueChanges
      .pipe(
        map(() => this.alertForm.getRawValue()),
        startWith([this.alertForm.getRawValue()]),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  removeResponsibleEmployee() {
    this.alertForm.get("responsibleEmployee").get("employee").setValue(null);
    this.editResponsibleBroker = true;
    this.sendToSegmentChanged(this.selectedSendToSegment);
  }

  sendToSegmentChanged(selectedSendToSegment: string) {
    this.selectedSendToSegment = selectedSendToSegment;

    if (!this.editResponsibleBroker) {
      if (!!this.alert && !!this.alert.eaEmployeeId) {
        this.alertForm.patchValue({
          responsibleEmployee: {
            office: this.alert.eaOfficeId,
            employee: this.alert.eaEmployeeId,
          },
        });
      }
    }

    switch (selectedSendToSegment) {
      case "contact":
        this.alertForm.patchValue({
          sendAlertToEmployee: false,
          sendAlertToResponsibleEmployee: false,
        });
        break;

      case "broker":
        this.alertForm.patchValue({
          sendAlertToEmployee: true,
          sendAlertToResponsibleEmployee: false,
        });
        break;

      case "responsible_broker":
        combineLatest([this.eaOfficeId$, this.employee$])
          .pipe(first())
          .subscribe(([eaOfficeId, employee]) => {
            this.alertForm.patchValue({
              responsibleEmployee: {
                office: eaOfficeId,
                employee: employee.eaEmployeeId,
              },
              sendAlertToEmployee: false,
              sendAlertToResponsibleEmployee: true,
            });
          });
        break;
    }
  }

  private mapStateToProps() {
    this.searchProfileFeature$ = this.store.pipe(
      select(getFeature(SEARCH_PROFILE))
    );
    this.eaOfficeId$ = this.store.pipe(select(getEaOfficeId));
    this.employee$ = this.store.pipe(select(getEmployee));
  }

  private handleOfficeEmployeeDropdown(): void {
    this.searchProfileFeature$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((feature) => {
        if (feature.enableSendAlertToAgent && !!this.alert) {
          this.fillForm();
        }
      });
  }

  private fillForm(): void {
    this.alertForm.setValue({
      activityId: Number(this.alert.activityId),
      responsibleEmployee: {
        office: this.alert.eaOfficeId,
        employee: this.alert.eaEmployeeId,
      },
      sendConfirmationMail: false,
      sendAlertToEmployee: this.alert.sendAlertToEmployee,
      sendAlertToResponsibleEmployee: this.alert.sendAlertToResponsibleEmployee,
    });

    if (this.alert.sendAlertToResponsibleEmployee) {
      this.selectedSendToSegment = "responsible_broker";
    } else if (this.alert.sendAlertToEmployee) {
      this.selectedSendToSegment = "broker";
    } else {
      this.selectedSendToSegment = "contact";
    }

    this.cdr.detectChanges();
  }
}
