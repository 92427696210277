import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "scroll-top",
  template: `
    <div *ngIf="isMobile$ | async" (click)="scrollTop()" class="scroll-top">
      <i class="fa fa-chevron-up"></i>
    </div>
  `,
  styleUrls: ["./scroll-top.component.scss"],
})
export class ScrollTopComponent implements OnInit {
  @Input() parent: HTMLElement;
  isMobile$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.isMobile$ = this.store.pipe(select(fromShared.isMobile));
  }

  scrollTop() {
    if (this.parent) {
      this.parent.scrollTop = 0;
    } else {
      this.document.body.scrollTop = 0;
    }
  }
}
