import {
  AfterContentInit,
  Directive,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { Observable, skip, Subscription } from "rxjs";

@Directive({
  selector: "[appAutoScroll]",
})
export class AutoScrollDirective implements AfterContentInit, OnDestroy {
  private readonly nativeElement: HTMLElement;
  private sub: Subscription;

  constructor(private elementRef: ElementRef) {
    this.nativeElement = this.elementRef.nativeElement;
  }

  ngAfterContentInit(): void {
    this.sub = this.createObservable()
      .pipe(skip(1))
      .subscribe(() => this.scrollDown());
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  private createObservable(): Observable<MutationRecord[]> {
    return new Observable((observer) => {
      const mutationObserver = new MutationObserver(
        observer.next.bind(observer)
      );
      mutationObserver.observe(this.nativeElement, {
        childList: true,
        subtree: false,
      });
      return () => mutationObserver.disconnect();
    });
  }

  private scrollDown(): void {
    const children = this.nativeElement.children;
    const target = children[children.length - 1];
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }
}
