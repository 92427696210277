import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { NpsEntity, NpsRequest } from "@app/models/nps";
import { fadeInTrigger } from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature, KpiConfig, NpsConfig } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import {
  NPS,
  STATISTICS_OFFICE_FILTER,
} from "@app/shared/config/utils/features";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  skip,
  startWith,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import * as kpiActions from "../ngrx/kpi.actions";
import * as fromKpi from "../ngrx/kpi.reducer";
import { Kpi } from "../models/kpi";
import { KpiUtilsService } from "../services/kpi-utils.service";

@Component({
  selector: "kpi-collection",
  templateUrl: "./kpi-collection.component.html",
  styleUrls: ["./kpi-collection.component.scss"],
  animations: [fadeInTrigger()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiCollectionComponent implements OnInit, OnChanges, OnDestroy {
  @Input() types: string[];
  @Input() from: string;
  @Input() to: string;
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() summary = true;
  @Input() summaryPosition = 0;
  @Input() useDefaultDate: boolean;
  @Input() useFilter: boolean = false;
  @Input() ignoreOfficeConnection: boolean = false;
  @Input() triggerDataFetch$: Observable<void>;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<Kpi>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onNpsClick = new EventEmitter<{
    nps: NpsEntity;
    filters: NpsRequest;
  }>();

  kpiConfigs$: Observable<KpiConfig[]>;
  kpis$: Observable<{ [key: string]: any }>;
  kpiLoading$: Observable<boolean>;
  sortedKpis$: Observable<Kpi[]>;
  total$: Observable<number>;
  showSummary$: Observable<boolean>;
  nps$: Observable<NpsEntity>;
  npsLoading$: Observable<boolean>;
  npsFeatureEnabled$: Observable<boolean>;
  npsConfig$: Observable<NpsConfig>;
  unsubscribe$ = new Subject<void>();
  npsFilter: NpsRequest;

  constructor(
    private store: Store<AppState>,
    private kpiUtils: KpiUtilsService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fetchKpis();
        this.fetchNps();
      });
  }

  ngOnChanges(): void {
    if (this.from && this.to) {
      this.fetchKpis();
      this.fetchNps();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(kpiActions.resetState());
  }

  mapStateToProps(): void {
    this.kpis$ = this.store.pipe(
      select(fromKpi.getKpis),
      filter((kpis) => !_.isEmpty(kpis))
    );
    this.kpiLoading$ = this.store.pipe(select(fromKpi.getKpiLoading));
    this.kpiConfigs$ = this.store.pipe(
      select(fromConfig.getFeature(features.KPI)),
      map((feature) => feature.kpis),
      map((kpiConfigs) =>
        kpiConfigs.filter((conf) => _.includes(this.types, conf.type))
      )
    );

    this.sortedKpis$ = this.kpis$.pipe(
      withLatestFrom(this.kpiConfigs$),
      map(([kpis, kpiConfigs]) =>
        _.sortBy(kpiConfigs, ["displayOrder"]).map(
          (config: KpiConfig) => kpis[config.type]
        )
      )
    );

    this.showSummary$ = this.kpis$.pipe(
      map(
        (kpis) =>
          _.some(kpis, (kpi: Kpi) => kpi.salesTarget !== null) && this.summary
      ),
      distinctUntilChanged(),
      startWith(false)
    );

    this.total$ = this.kpis$.pipe(
      withLatestFrom(this.kpiConfigs$),
      map(([kpis, kpiConfigs]) =>
        this.calculateReachedTargets(kpis, kpiConfigs)
      ),
      distinctUntilChanged()
    );

    this.nps$ = this.store.pipe(select(fromKpi.getNps));
    this.npsLoading$ = this.store.pipe(select(fromKpi.getNpsLoading));
    this.npsFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(NPS)),
      map((feature: Feature) => feature.enabled)
    );
    this.npsConfig$ = this.store.pipe(select(fromConfig.getFeature(NPS)));
  }

  fetchKpis(): void {
    this.store
      .pipe(select(fromConfig.getFeature(STATISTICS_OFFICE_FILTER)), first())
      .subscribe((officeFilterFeature) => {
        const canRemoveOfficeFromKpi = officeFilterFeature.filterOptions.find(
          (option) => option.display === "kpi"
        );

        let eaOfficeId = null;
        const allowEditingOffice =
          this.ignoreOfficeConnection &&
          officeFilterFeature.enabled &&
          canRemoveOfficeFromKpi.enabled;

        if (!allowEditingOffice && this.useFilter) {
          eaOfficeId = this.eaOfficeId?.toString();
        }

        if (this.from && this.to) {
          this.types.forEach((type) => {
            this.store.dispatch(
              kpiActions.fetchKpis({
                parameters: {
                  type,
                  userIds: {
                    eaOfficeId,
                    eaEmployeeId: this.eaEmployeeId?.toString(),
                  },
                  searchParams: { from: this.from, to: this.to },
                },
              })
            );
          });

          this.store.dispatch(kpiActions.fetchKpisBufferClose());
        }
      });
  }

  fetchNps(): void {
    combineLatest([
      this.store.pipe(select(fromConfig.getFeature(NPS))),
      this.store.pipe(select(fromConfig.getFeature(STATISTICS_OFFICE_FILTER))),
    ])
      .pipe(first())
      .subscribe(([config, officeFilterFeature]) => {
        const canRemoveOfficeFromKpi = officeFilterFeature.filterOptions.find(
          (option) => option.display === "nps"
        );

        let eaOfficeId = null;
        const allowEditingOffice =
          this.ignoreOfficeConnection &&
          officeFilterFeature.enabled &&
          canRemoveOfficeFromKpi.enabled;

        if (!allowEditingOffice && this.useFilter) {
          eaOfficeId = this.eaOfficeId?.toString();
        }

        this.npsFilter = {
          eaOfficeId,
          eaEmployeeId:
            this.eaEmployeeId && this.eaEmployeeId.length > 0
              ? this.eaEmployeeId
              : null,
          searchParams: this.getSearchParams(config),
        };

        this.store.dispatch(
          kpiActions.fetchEmployeeNpsRequest({
            nps: {
              ...this.npsFilter,
            },
          })
        );
      });
  }

  getSearchParams(config: NpsConfig) {
    const searchParams = {
      resMonthMin: null,
      resDateMin: null,
      resDateMax: null,
    };

    if (this.useDefaultDate && config.months_back) {
      searchParams.resMonthMin = `-${config.months_back}`;
      delete searchParams.resDateMin;
      delete searchParams.resDateMax;
    } else {
      searchParams.resDateMin = moment(this.from, "YYYYMM")
        .startOf("month")
        .format(API_DATE_FORMAT);
      searchParams.resDateMax = moment(this.to, "YYYYMM")
        .endOf("month")
        .format(API_DATE_FORMAT);
      delete searchParams.resMonthMin;
    }

    return searchParams;
  }

  calculateReachedTargets(kpis: object, kpiConfigs: KpiConfig[]): number {
    const kpiConfigByType = _.keyBy(kpiConfigs, "type");
    const kpiStats = Object.keys(kpis)
      .filter((key) => kpis[key].salesTarget !== null)
      .map((key) => {
        const { currentData, salesTarget } = kpis[key];
        return {
          weight: kpiConfigByType[key].weight,
          reached: currentData / (salesTarget || 1),
        };
      });

    const reachedTotal = kpiStats.reduce(
      (acc, val) => acc + val.reached * val.weight,
      0
    );
    const weightTotal = kpiStats.reduce((acc, val) => acc + val.weight, 0) || 1;
    return Math.round((reachedTotal / weightTotal) * 100);
  }

  onKpiClick(kpi: Kpi): void {
    this.onClick.emit(kpi);
  }

  onNPSClick(nps: NpsEntity): void {
    this.onNpsClick.emit({ nps, filters: this.npsFilter });
  }

  getKpiTranslationKeyByType(type: string): string {
    return this.kpiUtils.getKpiTranslationKeyByType(type);
  }

  getKpiSalesTarget(kpi: Kpi) {
    if (kpi.salesTarget) {
      return kpi.salesTarget;
    } else {
      if (kpi.kpiType === "soonforsaleobjectsnew") {
        return 4;
      }
      return null;
    }
  }
}
