import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Contact } from "@app/models";
import * as externalContactModalActions from "@app/sidebar/shared/external-contact-service/ngrx/external-contact-service.actions";
import { ActionsSubject, Store } from "@ngrx/store";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Subject, takeUntil } from "rxjs";
import { ofType } from "@ngrx/effects";

@Component({
  selector: "external-contact-service-modal",
  templateUrl: "./external-contact-service.component.html",
  styleUrls: ["./external-contact-service.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalContactServiceComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild("modal", { static: false })
  modal: ModalDirective;
  @ViewChild("form", { static: false })
  form: FormGroup;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelected = new EventEmitter<any>();

  contact: Contact;
  type: any;
  vm: any;

  constructor(
    private dispatcher: ActionsSubject,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>
  ) {}

  unsubscribe$ = new Subject<void>();

  ngAfterViewInit(): void {
    this.dispatcher
      .pipe(
        ofType(externalContactModalActions.showExternalContactModal),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ params }) => {
        this.vm = this.getDefaultFormModel();
        this.contact = params.contact;
        this.type = params.type;
        this.modal.show();
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  save() {
    const selectedProps = {};
    Object.keys(this.form.value).forEach((key) => {
      if (this.vm[key]) {
        selectedProps[key] = this.contact[key];
      }
    });
    this.store.dispatch(
      externalContactModalActions.setExternalContact({
        contact: new Contact(selectedProps),
      })
    );

    this.modal.hide();
  }

  getDefaultFormModel(): any {
    return {
      firstName: true,
      familyName: true,
      msisdn: true,
      phoneNumber: true,
      street: true,
      zip: true,
      city: true,
    };
  }
}
