import { Directive, HostListener, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Directive({
  selector: "[scrollToInvalidControl]",
})
export class ScrollToInvalidControlDirective {
  @Input() formEl: FormGroup;

  constructor() {}

  @HostListener("click") onClick() {
    if (this.formEl?.invalid) {
      this.scrollToError();
    }
  }

  private scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  private scrollToError(): void {
    const firstElementWithError = document.querySelector(
      ".ng-invalid[formControlName]"
    );

    this.scrollTo(firstElementWithError);
  }
}
