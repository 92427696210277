import { createAction, props } from "@ngrx/store";

export const checkLoginMethodRequest = createAction(
  "[Login] CHECK_LOGIN_METHOD_REQUEST"
);

export const checkLoginMethodSuccess = createAction(
  "[Login] CHECK_LOGIN_METHOD_SUCCESS",
  props<{
    supportedLoginType: string;
    redirectUrl: string;
    showLoginScreen: boolean;
  }>()
);

export const checkLoginMethodFail = createAction(
  "[Login] CHECK_LOGIN_METHOD_FAIL"
);

export const triggerLoginMethodRedirect = createAction(
  "[Login] TRIGGER_LOGIN_METHOD_REDIRECT",
  props<{ redirectUrl: string }>()
);

export const triggerLoginMethodStandard = createAction(
  "[Login] TRIGGER_LOGIN_METHOD_STANDARD"
);

export const clearLoginMethod = createAction(
  "[Login] CLEAR_LOGIN_METHOD_STATE"
);
