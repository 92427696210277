import { Component, Input } from "@angular/core";
import { Contact, SalesMeeting } from "@app/models";
import { Observable } from "rxjs";

@Component({
  selector: "contact-summary-services",
  styleUrls: ["../../contact-summary.component.scss"],
  template: `
    <h5 translate>services_and_actions</h5>
    <div *ngIf="!isLoading; else loading">
      <p [ngClass]="(numberOfTasks$ | async) > 0 ? 'exists' : 'notexists'">
        <app-icon name="street-view"></app-icon>&nbsp;
        {{ "unhandled_leads_and_tasks" | translate }}
        {{ numberOfTasks$ | async }} {{ "pcs" | translate }}
      </p>

      <p [ngClass]="(numberOfObjects$ | async) > 0 ? 'exists' : 'notexists'">
        <app-icon name="home-lg-alt"></app-icon>&nbsp;
        {{ "object_connections" | translate }}: {{ numberOfObjects$ | async }}
        {{ "pcs" | translate }}
      </p>

      <p [ngClass]="(hasPriceUpdateOrder$ | async) ? 'exists' : 'notexists'">
        <i class="icon-graph"></i>&nbsp;{{ "vardekollen" | translate }}:
        <span *ngIf="hasPriceUpdateOrder$ | async" translate>active</span>
        <span *ngIf="!(hasPriceUpdateOrder$ | async)" translate>inactive</span>
      </p>

      <p [ngClass]="(hasAlert$ | async) ? 'exists' : 'notexists'">
        <i class="icon-bell"></i>&nbsp;{{ "alert" | translate }}:
        <span *ngIf="hasAlert$ | async" translate>active</span>
        <span *ngIf="!(hasAlert$ | async)" translate>inactive</span>
      </p>

      <p
        [ngClass]="(salesMeetings$ | async).length > 0 ? 'exists' : 'notexists'"
      >
        <app-icon name="briefcase"></app-icon>&nbsp;{{
          "sales_meetings" | translate
        }}:
        <span *ngIf="(salesMeetings$ | async).length > 0">
          <span translate>{{ (salesMeetings$ | async).length }}</span>
          <span> {{ "unfinished_meetings" | translate }}</span>
        </span>
        <span *ngIf="(salesMeetings$ | async).length === 0" translate
          >no_booked_meetings</span
        >
      </p>

      <p
        [ngClass]="
          (ownsResidence$ | async) === 'OWNS_A_RESIDENCE'
            ? 'exists'
            : 'notexists'
        "
      >
        <app-icon name="home-lg-alt"></app-icon>&nbsp;{{
          "owns_residence" | translate
        }}:
        <span *ngIf="(ownsResidence$ | async) === 'OWNS_A_RESIDENCE'">{{
          "yes" | translate
        }}</span>
        <span *ngIf="(ownsResidence$ | async) === 'DOES_NOT_OWN_A_RESIDENCE'">{{
          "no" | translate
        }}</span>
        <span *ngIf="(ownsResidence$ | async) === 'UNKNOWN'">{{
          "unknown" | translate
        }}</span>
      </p>
    </div>
    <ng-template #loading>
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
    </ng-template>
  `,
})
export class ContactSummaryServicesComponent {
  @Input() isLoading: boolean;
  @Input() contact$: Observable<Contact>;
  @Input() numberOfTasks$: Observable<number>;
  @Input() numberOfObjects$: Observable<number>;
  @Input() hasPriceUpdateOrder$: Observable<boolean>;
  @Input() hasAlert$: Observable<boolean>;
  @Input() salesMeetings$: Observable<SalesMeeting[]>;
  @Input() ownsResidence$: Observable<string>;
}
