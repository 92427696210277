import { Injectable } from "@angular/core";
import { CallingListService } from "@app/calling-lists/calling-list.service";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as apiEndpoints from "@app/core/services/api/utils/api-endpoints";
import { CALLING_LISTS_SEARCH } from "@app/core/services/api/utils/api-endpoints";
import * as addContactToCallingListActions from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";

@Injectable()
export class AddContactToCallingListEffects {
  getAddContactToCallingLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.addContactToCallingListRequest),
      switchMap(({ filters }) =>
        this.apiService.get(CALLING_LISTS_SEARCH, filters).pipe(
          map((response: { callingLists: CallingList[] }) =>
            addContactToCallingListActions.addContactToCallingListSuccess({
              callingLists: response.callingLists,
            })
          ),
          catchError(() =>
            observableOf(
              addContactToCallingListActions.addContactToCallingListFail()
            )
          )
        )
      )
    )
  );

  getUserCallingLists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.getUserCallingListRequest),
      switchMap(({ filters }) =>
        this.apiService.get(CALLING_LISTS_SEARCH, filters).pipe(
          map((response: any) => {
            this.callingListService.clearNote$.next({});
            return addContactToCallingListActions.getUserCallingListSuccess({
              callingLists: response.callingLists,
            });
          }),
          catchError(() =>
            observableOf(
              addContactToCallingListActions.getUserCallingListFail()
            )
          )
        )
      )
    )
  );

  storeContactToCallingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.storeContactToCallingListRequest),
      switchMap(({ callingListId, contactId }) =>
        this.apiService
          .patch(`calling-lists/${callingListId}`, {
            contactIds: contactId,
          })
          .pipe(
            map(() =>
              addContactToCallingListActions.storeContactToCallingListSuccess()
            ),
            catchError(() =>
              observableOf(
                addContactToCallingListActions.storeContactToCallingListFail()
              )
            )
          )
      )
    )
  );

  storeContactToCallingListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.storeContactToCallingListSuccess),
      map(() => toastActions.success({ message: "calling_list_updated" }))
    )
  );

  storeContactToCallingListFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.storeContactToCallingListFail),
      map(() => toastActions.danger({ message: "calling_list_updated_fail" }))
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private callingListService: CallingListService
  ) {}

  removeContactFromCallingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        addContactToCallingListActions.removeContactFromCallingListRequest
      ),
      switchMap(({ callingListId, contactId }) =>
        this.apiService
          .delete(
            apiEndpoints.CALLING_LIST_CONTACTS_ID(callingListId, contactId),
            {}
          )
          .pipe(
            map(() =>
              addContactToCallingListActions.removeContactFromCallingListSuccess()
            ),
            catchError(() =>
              observableOf(
                addContactToCallingListActions.removeContactFromCallingListFail()
              )
            )
          )
      )
    )
  );

  removeContactFromCallingListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        addContactToCallingListActions.removeContactFromCallingListSuccess
      ),
      map(() => toastActions.success({ message: "calling_list_updated" }))
    )
  );

  removeContactFromCallingListFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addContactToCallingListActions.removeContactFromCallingListFail),
      map(() => toastActions.danger({ message: "calling_list_updated_fail" }))
    )
  );
}
