import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import * as loginActions from "@app/login/ngrx/login.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";

interface GetLoginSettingsResponse {
  redirectUrl: string;
  supportedLoginType: string;
  showLoginScreen: boolean;
}

export const LOGIN_TYPES = {
  STANDARD_FORM: "STANDARD_FORM",
  SSO_OAUTH: "SSO_OAUTH",
};

@Injectable()
export class LoginEffects {
  checkLoginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.checkLoginMethodRequest),
      switchMap(() =>
        this.apiService.get("loginSettings", {}, "public", false).pipe(
          map((response: GetLoginSettingsResponse) => {
            return loginActions.checkLoginMethodSuccess({
              ...response,
            });
          }),
          catchError(() => {
            const resp = {
              showLoginScreen: true,
              supportedLoginType: "STANDARD_FORM",
              redirectUrl: "login",
            };
            return of(loginActions.checkLoginMethodSuccess({ ...resp }));
          })
          // catchError(() => observableOf(loginActions.checkLoginMethodFail()))
        )
      )
    )
  );

  triggerLoginMethodRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.triggerLoginMethodRedirect),
        tap(({ redirectUrl }) => {
          window.location.href = redirectUrl;
        })
      ),
    { dispatch: false }
  );

  checkLoginFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loginActions.checkLoginMethodFail),
        map(() => loginActions.triggerLoginMethodStandard())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
