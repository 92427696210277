import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsRevenueWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((response: GrowthStatistics) =>
            widgetActions.fetchStatisticsSuccess({
              stats: response.totals.turnover.current,
            })
          ),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );

  fetchPreviousStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchPreviousStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((response: GrowthStatistics) =>
            widgetActions.fetchPreviousStatisticsSuccess({
              previousStats: response.totals.turnover.current,
            })
          ),
          catchError(() => of(widgetActions.fetchPreviousStatisticsFail()))
        )
      )
    )
  );
}
