import { ObjectStatusStatistics } from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.reducer";
import { createAction, props } from "@ngrx/store";

export const fetchStatisticsRequest = createAction(
  "[Widget Object Status] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Widget Object Status] FETCH_STATISTICS_SUCCESS",

  props<{ stats: ObjectStatusStatistics[] }>()
);

export const fetchStatisticsFail = createAction(
  "[Widget Object Status] FETCH_STATISTICS_FAIL"
);
