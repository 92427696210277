<h3>
  <app-icon name="thumbs-up" size="large"></app-icon>&nbsp;<span translate
    >tailored_campaign</span
  >
</h3>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <ng-container *ngIf="dataStudioUrl$ | async as dataUrl; else noData">
    <div class="widget-container__data">
      <iframe
        [src]="dataUrl | safe"
        style="height: 100vh; width: 100%"
      ></iframe>
    </div>
    <div class="statistics-widget__show-more-btn">
      <a [href]="dataUrl" target="_blank" class="btn btn-default">
        {{ "open_in_new_tab" | translate }}
        <app-icon name="chevron-right"></app-icon>
      </a>
    </div>
  </ng-container>
</div>

<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
