<h3 *ngIf="showHeader">
  <app-icon size="large" name="pen"></app-icon>&nbsp;<span translate>todo</span>
</h3>

<app-segmented-controls
  [controls]="segmentedControls"
  [active]="selectedTime"
  (change)="selectedTime = $event"
></app-segmented-controls>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="tasks-container">
    <p class="no-tasks" *ngIf="(getTaskListObservable() | async).length === 0">
      {{ "no_tasks_here" | translate }}
      <app-icon [name]="'thumbs-up'"></app-icon>
    </p>
    <div
      class="card-wrapper"
      *ngFor="let task of getTaskListObservable() | async"
    >
      <app-mobile-card
        [size]="'SLIM'"
        [shadow]="false"
        [enableMetadata]="true"
        [enableActionDropdown]="true"
        (click)="onTaskClick(task, $event)"
      >
        <span title>
          <span [style.color]="resolveColor(task)">
            <app-icon
              size="medium"
              [name]="resolveIcon(task) | async"
            ></app-icon>
          </span>
          {{ task.eaTaskTypeName }} &nbsp;&nbsp;
        </span>
        <div metadata>
          <span *ngIf="task.deliveryDate">
            <app-icon name="clock"></app-icon>
            {{ "due_date" | translate }}: {{ task.deliveryDate | momentify }}
          </span>
          <br />
          <span *ngIf="task.insertedDate">
            <app-icon name="clock"></app-icon>
            {{ "created" | translate }}: {{ task.insertedDate | momentify }}
          </span>
          <span *ngIf="task.contactId && task.contactFirstName">
            <app-contact-name
              [contactId]="task.contactId"
              [contactType]="task.contactType"
            >
              {{ task.contactFirstName }} {{ task.contactFamilyName }}
            </app-contact-name>
          </span>
        </div>
        <ng-container buttons>
          <li role="menuitem">
            <button
              class="btn btn-secondary"
              (click)="onTaskClick(task, $event)"
            >
              <app-icon name="pen"></app-icon>
              {{ "manage" | translate }}
            </button>
          </li>
          <li disableNavigation role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="snoozeTask(task)"
            >
              <app-icon name="alarm-snooze"></app-icon>
              {{ "snooze_one_day" | translate }}
            </button>
          </li>
          <li disableNavigation role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="openInNewTab(task)"
            >
              <app-icon name="share-square"></app-icon>
              {{ "open_in_new_tab" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
  </div>
</div>
