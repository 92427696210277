import { Location } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-small-back-button",
  template: `
    <a
      class="small-back-button"
      [routerLink]="!!routerLink ? routerLink : []"
      (click)="!!routerLink ? null : backClicked()"
    >
      <i class="fa fa-long-arrow-left"></i>
      {{ "back" | translate }}
    </a>
  `,
  styleUrls: ["./small-back-button.component.scss"],
})
export class SmallBackButtonComponent {
  @Input() mode: "native" | "routerLink" | "output" = "native";
  @Input() routerLink: string[];

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _location: Location) {}

  backClicked() {
    if (this.mode === "output") {
      this.click.emit();
    } else {
      this._location.back();
    }
  }
}
