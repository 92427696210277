<table class="table table--striped">
  <thead>
  <tr>
    <th translate>created_date</th>
    <th translate>bank</th>
    <th translate>advisor</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
    <tr *ngFor="let resource of resources$ | async">
      <td dataLabel="created_date">{{fixDate(resource.insertedDate)}}</td>
      <td dataLabel="bank">{{resource.param1}}</td>
      <td dataLabel="advisor">{{resource.param2}}</td>
    </tr>
  </ng-container>

  <ng-template #empty>
    <tr>
      <td class="text-center" colspan="100%" translate>no_sales_meetings</td>
    </tr>
  </ng-template>
  </tbody>
</table>
