<section class="send-potential-buyer__header">
  <h4>
    {{ "send_to_erp" | translate : { erp: externalProviderName | titlecase } }}
  </h4>
</section>
<section class="send-potential-buyer">
  <button
    class="btn btn-secondary"
    [disabled]="sending || disabled"
    (click)="send.emit()"
  >
    <i class="icon-paper-plane"></i>
    <span>{{
      textLine | translate : { erp: externalProviderName | titlecase }
    }}</span>
  </button>
</section>

<progressbar *ngIf="sending" [value]="progress" [@fadeInOut] class="progress"
  >{{ progress }}%</progressbar
>

<ng-container *ngIf="disabled">
  <alert type="danger">
    <span>{{
      "erp_dont_accept_contacts_without_mobile_phone_number"
        | translate : { erp: externalProviderName | titlecase }
    }}</span>
  </alert>
</ng-container>
<ng-container *ngIf="errors.length > 0" [@fadeInOut]>
  <alert type="warning">
    <strong translate>following_potential_buyers_not_sent</strong>
    <ul>
      <li *ngFor="let error of errors">{{ error }}</li>
    </ul>
  </alert>
</ng-container>
