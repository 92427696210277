<div class="search-box-container">
  <app-q-search-input
    [label]="label"
    placeholder="street"
    [invalid]="invalid"
    [data]="data"
    (search)="search($event)"
    (itemClick)="itemClick($event)"
    *ngIf="!value || !showCard"
  >
    <ng-template let-item="item">
      <app-q-search-list-item
        [title]="item.street"
        [secondaryText]="[item.zip, item.city]"
        [chipData]="{
          text: item?.statusName,
          color: item?.status | objectStatusChipColor
        }"
      >
      </app-q-search-list-item>
    </ng-template>
  </app-q-search-input>
  <div class="spinner-container">
    <spinner *ngIf="isLoading$ | async" [size]="'1.5rem'"></spinner>
  </div>
</div>
<ng-container *ngIf="value && showCard">
  <app-q-form-group-heading>{{
    "chosen_object" | translate
  }}</app-q-form-group-heading>
  <app-q-card color="2">
    <ng-container slot="title">
      {{ object?.street }}
    </ng-container>
    <ng-container slot="body">
      <ng-template
        [ngTemplateOutlet]="cardBodyTemplate"
        [ngTemplateOutletContext]="{ object: object }"
        *ngIf="cardBodyTemplate; else defaultCardBody"
      ></ng-template>
      <ng-template #defaultCardBody>
        <div class="object-detail">
          <div style="margin-right: 0.4rem">
            <app-q-card-seconary-text>
              {{ object?.zip }}
            </app-q-card-seconary-text>
            <app-q-card-seconary-text>
              {{ object?.city }}
            </app-q-card-seconary-text>
          </div>
          <app-q-chip
            [text]="object?.status | objectStatusName | async"
            [color]="object?.status | objectStatusChipColor"
          >
          </app-q-chip>
        </div>
      </ng-template>
    </ng-container>
    <ng-container slot="actions">
      <button type="button" (click)="clear()" *ngIf="!disabled && !readonly">
        <app-icon name="times" size="large"></app-icon>
      </button>
    </ng-container>
  </app-q-card>
</ng-container>
