import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  SortableTableColumn,
  SortDirection,
  TableSortDirective,
} from "@app/shared/modules/ui-components/table-sort/table-sort.directive";

@Component({
  selector: "[table-sort-header]",
  templateUrl: "./table-sort-header.component.html",
  styleUrls: ["./table-sort-header.component.scss"],
})
export class TableSortHeaderComponent
  implements OnInit, OnDestroy, SortableTableColumn
{
  @Input() start: SortDirection;
  /* eslint-disable @angular-eslint/no-input-rename */
  @Input("table-sort-header") id: string;

  constructor(private sort: TableSortDirective) {}

  ngOnInit(): void {
    this.sort.register(this);
  }

  ngOnDestroy(): void {
    this.sort.unregister(this);
  }

  @HostListener("click")
  handleClick(): void {
    this.sort.sort(this);
  }

  isSorted(): boolean {
    return (
      this.sort.active === this.id &&
      (this.sort.direction === "asc" || this.sort.direction === "desc")
    );
  }

  getDirection(): SortDirection | undefined {
    return this.isSorted() ? this.sort.direction : this.start;
  }
}
