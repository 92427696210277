import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ContactConsent } from "@app/contacts/contact-consents/models";
import { addBackLink } from "@app/contacts/contact.actions";
import { ContactsSubPageService } from "@app/contacts/contacts-sub-page.service";
import { UrlService } from "@app/core/services/url/url.service";
import { Contact, Note, SalesMeeting } from "@app/models";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import * as fromContactSummary from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { select, Store } from "@ngrx/store";
import { filter, first, Observable, Subject, takeUntil } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-contact-summary-dialog",
  templateUrl: "./contact-summary-dialog.component.html",
  styleUrls: ["./contact-summary-dialog.component.scss"],
})
export class ContactSummaryDialogComponent implements OnInit, OnDestroy {
  contact$: Observable<Contact>;
  consents$: Observable<ContactConsent[]>;
  numberOfTasks$: Observable<number>;
  numberOfObjects$: Observable<number>;
  hasPriceUpdateOrder$: Observable<boolean>;
  hasAlert$: Observable<boolean>;
  salesMeetings$: Observable<SalesMeeting[]>;
  ownsResidence$: Observable<string>;
  notes$: Observable<Note[]>;
  isLoading$: Observable<boolean>;
  subPages$: Observable<SubPage[]>;

  constructor(
    private store: Store<AppState>,
    public dialogRef: MatDialogRef<ContactSummaryDialogComponent>,
    private router: Router,
    private urlService: UrlService,
    private subPagesService: ContactsSubPageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initSubPages();
  }

  initSubPages(): void {
    this.subPages$ = this.subPagesService.subPages$;
    this.subPagesService.loadSubPages(this.data.eaOid);
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps() {
    this.contact$ = this.store.pipe(
      select(fromContactSummary.getContact),
      takeUntil(this.unsubscribe$),
      filter((it) => !!it)
    );
    this.consents$ = this.store.pipe(
      select(fromContactSummary.getConsents),
      takeUntil(this.unsubscribe$)
    );
    this.numberOfTasks$ = this.store.pipe(
      select(fromContactSummary.getNumberOfTasks),
      takeUntil(this.unsubscribe$)
    );
    this.numberOfObjects$ = this.store.pipe(
      select(fromContactSummary.getNumberOfObjects),
      takeUntil(this.unsubscribe$)
    );
    this.hasPriceUpdateOrder$ = this.store.pipe(
      select(fromContactSummary.hasPriceUpdateOrder),
      takeUntil(this.unsubscribe$)
    );
    this.hasAlert$ = this.store.pipe(
      select(fromContactSummary.hasAlert),
      takeUntil(this.unsubscribe$)
    );
    this.salesMeetings$ = this.store.pipe(
      select(fromContactSummary.getSalesMeetings),
      takeUntil(this.unsubscribe$)
    );
    this.ownsResidence$ = this.store.pipe(
      select(fromContactSummary.ownsResidence),
      takeUntil(this.unsubscribe$)
    );
    this.notes$ = this.store.pipe(
      select(fromContactSummary.getNotes),
      takeUntil(this.unsubscribe$)
    );
    this.isLoading$ = this.store.pipe(
      select(fromContactSummary.isLoading),
      takeUntil(this.unsubscribe$)
    );
  }

  addBackLink(): void {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
  }

  closeModal() {
    this.dialogRef.close();
  }

  goToContact(additionalRoute?: string) {
    this.contact$.pipe(first()).subscribe((c) => {
      this.addBackLink();
      this.closeModal();
      const route = ["/crm", "contacts", c.contactId];
      if (!!additionalRoute) {
        route.push(additionalRoute);
      }
      this.store.dispatch(go({ path: route }));
    });
  }

  openInNewTab(additionalRoute?: string) {
    this.contact$.pipe(first()).subscribe((c) => {
      const route = ["/crm", "contacts", c.contactId];
      if (!!additionalRoute) {
        route.push(additionalRoute);
      }
      const url = this.urlService.buildUrlFromRouterCommands(route);
      openLinkInNewTab(url);
    });
  }
}
