import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";

export type Role = "broker" | "manager" | "admin" | "crm2 admins";

export const ROLE_SUPER_ADMIN: Role = "crm2 admins";
export const ROLE_MANAGER: Role = "manager";
export const ROLE_BROKER: Role = "broker";
export const ROLE_ADMIN: Role = "admin";

export const ROLES = [ROLE_MANAGER, ROLE_BROKER, ROLE_ADMIN];
export const ROLES_SELECT: Select = [
  {
    label: ROLE_ADMIN,
    value: ROLE_ADMIN,
    disabled: true,
  },
  {
    label: ROLE_MANAGER,
    value: ROLE_MANAGER,
  },
  {
    label: ROLE_BROKER,
    value: ROLE_BROKER,
  },
];
