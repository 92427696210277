import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { ShowingObject } from "@app/showings/models";
import { Project } from "@app/showings/models/project";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { select, Store } from "@ngrx/store";
import { filter, first, map, Observable } from "rxjs";
import { DOWNLOAD_OBJECT_IMAGES } from "@app/shared/config/utils/features";
import { DownloadFileService } from "@app/shared/modules/ui-components/download-file.service";

@Component({
  selector: "app-project-overview-widget",
  templateUrl: "./project-overview-widget.component.html",
  styleUrls: [
    "./project-overview-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ProjectOverviewWidgetComponent implements OnInit, OnChanges {
  @Input() eaOid: string;

  project$: Observable<Project>;
  showing$: Observable<ShowingObject & Project>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;
  canDownloadFiles$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private downloadService: DownloadFileService,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.showing$ = this.objectService.entityMap$.pipe(
        map((entities) => {
          const currentProperty = entities[this.eaOid];
          if (currentProperty?.projectDTO?.eaOid) {
            return entities[currentProperty?.projectDTO?.eaOid];
          } else {
            return currentProperty;
          }
        }),
        filter((entity) => !!entity)
      );
    }
  }

  // TODO: this part related to Aktiv customer for their migration from Webmegler to Vitec ERP ant it's a temporary code
  downloadFiles() {
    this.showing$.pipe(first()).subscribe((object) => {
      this.downloadService.downloadImages(object);
    });
  }

  private mapStateToProps() {
    this.project$ = this.store.pipe(select(fromShowings.getProject));
    this.loading$ = this.store.pipe(select(fromShowings.getProjectLoading));
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.canDownloadFiles$ = this.store.pipe(
      select(getFeature(DOWNLOAD_OBJECT_IMAGES)),
      map((feature) => feature.enabled)
    );
  }
}
