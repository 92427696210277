<section>
  <form [formGroup]="form">
    <div class="form-group">
      <label class="required">
        {{ "choose_bank" | translate }}
        <spinner *ngIf="banksLoading" [size]="'1rem'"></spinner>
      </label>
      <select formControlName="bank">
        <option translate disabled value="">select_bank</option>
        <option *ngFor="let bank of banks" [value]="bank.id" [id]="bank.id">
          {{ bank.name }}
        </option>
      </select>
      <div ngxErrors="bank">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>
        {{ "choose_advisor" | translate }}
        <spinner *ngIf="advisorsLoading" [size]="'1rem'"></spinner>
      </label>
      <app-multi-select-dropdown
        [parentFormGroup]="form"
        parentFormControlName="advisor"
        [items]="advisors"
        [displayProperty]="'firstName'"
        [secondaryDisplayProperty]="'lastName'"
        [valueProperty]="'id'"
        [enableSearch]="true"
        [singleSelect]="true"
        [minWidth]="'100%'"
        [placeholder]="'choose_advisor'"
        [state]="advisors.length > 0 ? 'READY' : 'DISABLED'"
      >
        <span placeholder>{{ getPlaceholder() | translate }}</span>
        <span mobilePlaceholder>{{ getPlaceholder() | translate }}}</span>
      </app-multi-select-dropdown>
    </div>
    <div class="form-group">
      <label translate>note</label>
      <textarea-counter
        [parentFormGroup]="form"
        [parentFormControlName]="'note'"
        [maxLength]="1000"
      >
      </textarea-counter>
    </div>
    <div class="form-group">
      <label class="checkbox-group">
        <span translate>urgent</span>
        <input type="checkbox" formControlName="urgent" />
        <span class="checkbox-group__checkmark"></span>
      </label>
    </div>
  </form>
</section>
