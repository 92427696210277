import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { Showing } from "@app/showings/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { catchError, map, Observable, takeUntil } from "rxjs";

@Injectable({ providedIn: "root" })
export class ShowingService extends CustomEntityCollectionServiceBase<Showing> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Showing", serviceElementsFactory);
  }

  fetchWithQuery = (params: any, setListDefaults = true) => {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }
    return this.apiService.get("showings/search", params).pipe(
      takeUntil(this.cancelExistingRequest$),
      map((res: PaginationListDTO) => {
        this.upsertManyInCache(res.rows);
        this.setListDefaults(res.rows, res);
        this.setLoading(false);
        return res.rows;
      }),
      catchError((err) => err)
    );
  };

  refreshFetchFromObject = (): Observable<any> | any => {};
  fetchFromObject = (eaOid: string, params?: any) => {
    this.refreshFetchFromObject = () => this.fetchFromObject(eaOid, params);
    this.setLoading(true);
    return this.apiService.get(`showings/for-object/${eaOid}`, params).pipe(
      map((res: PaginationListDTO) => {
        this.upsertManyInCache(res.rows);
        this.setListDefaults(res.rows, res);
        this.setLoading(false);
        return res.rows;
      }),
      catchError((err) => err)
    );
  };

  postPrivateShowing(entity: Partial<Showing>, showToast = true) {
    this.setLoading(true);
    return this.apiService.post("showings", entity).pipe(
      map((response) => {
        this.setLoading(false);
        // Todo: When response is fixed, update state here
        if (showToast) {
          this.appStore.dispatch(
            toastActions.success({ message: "create_showing_success" })
          );
        }
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "create_showing_failed" })
        );
        return err;
      })
    );
  }

  postPublicShowing(entity: Partial<Showing>, eaOid: string, showToast = true) {
    this.setLoading(true);
    return this.apiService
      .post(
        `showings/create-public-showing-for-object/${eaOid}`,
        entity,
        "integrations"
      )
      .pipe(
        map((response) => {
          this.setLoading(false);
          if (showToast) {
            this.appStore.dispatch(
              toastActions.success({ message: "create_showing_success" })
            );
          }
          return response;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "create_showing_failed" })
          );
          return err;
        })
      );
  }

  patchShowing(
    entity: {
      startTime?: string;
      endTime?: string;
      description?: string;
      comment?: string;
    },
    showingId: string,
    showToast = true
  ) {
    this.setLoading(true);
    return this.apiService.patch(`showings/${showingId}`, entity).pipe(
      map((response: Showing) => {
        this.setLoading(false);
        if (showToast) {
          this.appStore.dispatch(
            toastActions.success({ message: "update_showing_success" })
          );
        }
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "update_showing_failed" })
        );
        return err;
      })
    );
  }

  remove(showingId: string, eaShowingId: string) {
    return this.apiService.delete(`showings/${showingId}`).pipe(
      map((res) => {
        this.removeOneFromCache(eaShowingId);
        this.handleDelete(eaShowingId);
        return res;
      }),
      catchError((err) => {
        this.store.dispatch(
          toastActions.danger({ message: "delete_showing_failed" })
        );
        return err;
      })
    );
  }

  removePublicShowing(eaOid: string, showingId: string, eaShowingId: string) {
    return this.apiService
      .delete(
        `showings/delete-public-showing-for-object/${eaOid}/showing/${showingId}`,
        {},
        "integrations"
      )
      .pipe(
        map((res) => {
          this.removeOneFromCache(eaShowingId);
          this.handleDelete(eaShowingId);
          return res;
        }),
        catchError((err) => {
          this.store.dispatch(
            toastActions.danger({ message: "delete_showing_failed" })
          );
          return err;
        })
      );
  }
}
