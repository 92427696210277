import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Employee, Office, Task } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getOffices } from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import {
  getEaEmployeeId,
  getEmployee,
  getRoles,
  hasRole,
  isManagerOrAdmin,
} from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { ROLE_ADMIN, ROLE_BROKER, ROLE_MANAGER } from "@app/shared/utils/roles";
import { TASK } from "@app/shared/utils/tab-types";
import { SendMessageParams } from "@app/sidebar/tasks/models/send-message-params";
import { TaskActionLog } from "@app/sidebar/tasks/ngrx/tasks.reducer";
import { Action, select, Store } from "@ngrx/store";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  of as observableOf,
  skip,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { closeTab } from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import * as tasksActions from "../ngrx/tasks.actions";
import * as fromTasks from "../ngrx/tasks.reducer";
import { TasksDefaultService } from "./tasks-default.service";

@Component({
  selector: "tasks-default",
  templateUrl: "./tasks-default.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./tasks-default.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksDefaultComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() task: Task;
  processing$: Observable<boolean>;
  offices$: Observable<Office[]>;
  employees$: Observable<Employee[]>;
  eaEmployeeId$: Observable<string>;
  everyoneCanAssign$: Observable<boolean>;
  showAssign$: Observable<boolean>;
  tab$: Observable<SidebarTab>;
  user$: Observable<Employee>;
  taskActionLogs$: Observable<TaskActionLog[]>;
  templateId$: Observable<string>;
  isManagerOrAdmin$: Observable<boolean>;
  sendToModuleId$: Observable<string>;
  tabType = TASK;
  form: FormGroup;

  private unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private tasksDefaultService: TasksDefaultService
  ) {}

  ngOnInit() {
    this.initObservers();
    this.initFormAndEvents();
    this.initRoleObserver();
  }

  ngAfterViewInit() {
    this.tab$.pipe(take(1)).subscribe((tab) => {
      if (tab.dirty) {
        this.form.setValue(tab.currentValue);
        this.startContactChangesStream(1);
      } else {
        this.startContactChangesStream();
      }
      this.startValueChangesStream();
    });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initObservers(): void {
    this.user$ = this.store.pipe(
      select(getEmployee),
      filter((value) => !!value)
    );
    this.isManagerOrAdmin$ = this.store.pipe(select(isManagerOrAdmin));
    this.processing$ = this.store.pipe(select(fromTasks.getProcessing));
    this.showAssign$ = this.tasksDefaultService.getShowAssign();
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));

    this.everyoneCanAssign$ = this.store.pipe(
      select(fromConfig.getEveryoneCanAssignTasksAndLeads)
    );
    this.offices$ = this.getSelectableOffices$();
    this.employees$ = this.getSelectableEmployees$();
    this.taskActionLogs$ = this.store.pipe(select(fromTasks.getTaskActionLog));

    this.templateId$ = this.store.pipe(select(fromConfig.getMessageTemplateId));
    this.sendToModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.eaEmployeeId$ = this.store.pipe(select(getEaEmployeeId));

    observableCombineLatest([this.user$])
      .pipe(
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$),
        map(([user]) => this.task.eaEmployeeId !== user.eaEmployeeId)
      )
      .subscribe((assign) =>
        assign ? this.onShowAssign() : this.onShowManage()
      );
  }

  initFormAndEvents(): void {
    this.form = this.fb.group({
      assign: this.fb.group({
        office: "",
        employee: "",
        sendMessage: true,
        message: "",
      }),
      manage: this.fb.group({ result: "" }),
    });

    this.form
      .get("assign.office")
      .valueChanges.pipe(takeUntil(this.unSubscribe$))
      .subscribe((office: Office) =>
        this.store.dispatch(tasksActions.getOfficeEmployees(office.eaOfficeId))
      );

    this.buildFormValues()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((value) => this.form.setValue(value));
  }

  initRoleObserver(): void {
    observableCombineLatest([
      this.store.pipe(select(hasRole(ROLE_BROKER))),
      this.everyoneCanAssign$,
    ])
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(([isBroker, everyoneCanAssign]) => {
        if (!everyoneCanAssign) {
          if (isBroker) {
            this.form.get("assign.office").disable();
          } else {
            this.form.get("assign.office").enable();
          }
        }
      });
  }

  onSubmit(): void {
    if (this.form.valid) {
      const eaTaskResultId = this.form.get("manage.result").value;
      const sendMessage = this.form.get("assign.sendMessage").value;
      const message = this.form.get("assign.message").value;
      const eaEmployeeId = this.form.get("assign.employee").value;
      const office = this.form.get("assign.office").value;

      const recipientName$ =
        eaEmployeeId && eaEmployeeId !== "null"
          ? this.store.pipe(
              select(fromTasks.getEmployee(eaEmployeeId)),
              map((employee: Employee) => employee.firstName)
            )
          : observableOf(office.name);

      const recipientEmail$ =
        eaEmployeeId && eaEmployeeId !== "null"
          ? this.store.pipe(
              select(fromTasks.getEmployee(eaEmployeeId)),
              map((employee: Employee) => employee.email)
            )
          : observableOf(office.email);

      this.showAssign$
        .pipe(
          withLatestFrom(
            this.templateId$,
            this.sendToModuleId$,
            recipientName$,
            recipientEmail$,
            this.store.pipe(select(fromUser.getOffice)),
            this.store.pipe(select(fromUser.getEmployee))
          ),
          first(),
          map(
            ([
              assign,
              templateId,
              sendToModuleId,
              recipientName,
              recipientEmail,
              originOffice,
              originEmployee,
            ]) =>
              assign
                ? sendMessage
                  ? this.getUpdateTaskAction(
                      this.task,
                      this.getSendMessageParams(
                        templateId,
                        sendToModuleId,
                        recipientName,
                        recipientEmail,
                        originOffice,
                        originEmployee,
                        message
                      )
                    )
                  : this.getUpdateTaskAction(this.task)
                : this.getCreateTaskAttemptAction(
                    this.task,
                    eaTaskResultId,
                    this.isChosenTaskResultSuccess(eaTaskResultId)
                  )
          )
        )
        .subscribe((action) => this.store.dispatch(action));
    }
  }

  isChosenTaskResultSuccess(chosenResultId: string): boolean {
    return this.task.taskResults.find(
      (result) => result.eaTaskResultId === chosenResultId
    ).success;
  }

  deleteTask(event: { id: string; reason: string; taskType: string }): void {
    this.store.dispatch(
      tasksActions.deleteTaskRequest({
        params: {
          id: event.id,
          reason: event.reason,
          taskType: event.taskType,
        },
      })
    );
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  compareTaskWithCurrentUser(task: Task): Observable<boolean> {
    return this.user$.pipe(
      switchMap((e: Employee) =>
        observableOf(e.eaEmployeeId !== task.eaEmployeeId)
      )
    );
  }

  onShowManage(): void {
    this.tasksDefaultService.onShowManage();
  }

  onShowAssign(): void {
    this.tasksDefaultService.onShowAssign();
  }

  getSelectableEmployees$(): Observable<Employee[]> {
    // TODO Clean out employees when you're not a manager at that office
    return observableCombineLatest([
      this.user$,
      this.store.pipe(select(fromTasks.getEmployees)),
      this.store.pipe(select(getRoles)),
      this.everyoneCanAssign$,
    ]).pipe(
      map(([user, employees, roles, everyoneCanAssign]) => {
        this.form
          .get("assign.employee")
          .setValue(this.getSelectedEaEmployeeId(this.task, user));

        if (everyoneCanAssign) {
          return employees;
        }

        if (roles.some((r) => r === ROLE_ADMIN)) {
          return employees;
        }

        const selectedEaOfficeId =
          this.form.get("assign.office").value.eaOfficeId;
        const userOffice = user.offices.find(
          (o) => o.eaOfficeId === selectedEaOfficeId
        );

        if (userOffice && userOffice.role === ROLE_MANAGER) {
          return employees;
        }

        if (
          this.task.eaOfficeId === selectedEaOfficeId &&
          userOffice &&
          userOffice.role === ROLE_BROKER
        ) {
          return employees.filter(
            (employee) => employee.fullName === user.employeeFullName
          );
        }
        this.form.get("assign.employee").setValue("null");
        return [];
      })
    );
  }

  getSelectableOffices$(): Observable<Office[]> {
    return observableCombineLatest([
      this.store.pipe(select(getOffices)),
      this.store.pipe(select(getRoles)),
      this.everyoneCanAssign$,
    ]).pipe(
      map(([offices, roles, everyoneCanAssign]) => {
        if (
          everyoneCanAssign ||
          roles.some((r) => r === ROLE_ADMIN) ||
          roles.some((r) => r === ROLE_MANAGER)
        ) {
          return offices;
        }
        return this.getBrokerOffices(offices, this.task);
      })
    );
  }

  private startContactChangesStream(skipNumber = 0) {
    observableOf(this.task)
      .pipe(skip(skipNumber), takeUntil(this.unSubscribe$))
      .subscribe(() =>
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: this.getFormValue(),
          })
        )
      );
  }

  private startValueChangesStream() {
    this.form.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe(() =>
      this.store.dispatch(
        sidebarActions.setTabValue({
          tabType: this.tabType,
          value: this.getFormValue(),
        })
      )
    );
  }

  private buildFormValues(): Observable<any> {
    return observableOf(this.task).pipe(
      filter((value) => !!value),
      switchMap((task: Task) =>
        this.offices$.pipe(
          map((offices: Office[]) => ({
            assign: {
              employee: task.eaEmployeeId || "",
              office:
                offices.find((o) => o.eaOfficeId === task.eaOfficeId) || "",
              sendMessage: true,
              message: "",
            },
            manage: {
              result: task.eaTaskResultId !== "0" ? task.eaTaskResultId : "",
            },
          }))
        )
      )
    );
  }

  private getSelectedEaEmployeeId(task, user): string {
    if (task.eaEmployeeId && task.eaEmployeeId !== "null") {
      return task.eaEmployeeId;
    }
    const userConnectedToOffice = user.offices.some(
      (o) => o.eaOfficeId === task.eaOfficeId
    );
    const taskOfficeSelected =
      this.form.get("assign.office").value.eaOfficeId === task.eaOfficeId;

    if (taskOfficeSelected && userConnectedToOffice) {
      return user.eaEmployeeId;
    }
    return "null";
  }

  private getFormValue(): any {
    return {
      assign: {
        office: this.form.get("assign.office").value,
        employee: this.form.get("assign.employee").value,
        sendMessage: this.form.get("assign.sendMessage").value,
        message: this.form.get("assign.message").value,
      },
      manage: {
        result: this.form.get("manage.result").value,
      },
    };
  }

  private getBrokerOffices(offices: Office[], task: Task): Office[] {
    return offices.filter((o) => o.eaOfficeId === task.eaOfficeId);
  }

  private getUpdateTaskAction(
    task: Task,
    messageParams?: SendMessageParams
  ): Action {
    const params: TaskUpdateParams = {
      eaTaskId: task.eaTaskId,
      eaOfficeId: this.form.get("assign.office").value.eaOfficeId,
      eaEmployeeId: this.form.get("assign.employee").value,
    };
    if (this.isOfficeManagerExclusive(task)) {
      params.officeManagerExclusiveEndDate = moment(new Date()).format(
        API_DATE_FORMAT
      );
    }

    return messageParams
      ? tasksActions.updateTaskRequest({ task: params, messageParams })
      : tasksActions.updateTaskRequest({ task: params });
  }

  private isOfficeManagerExclusive(task: Task): boolean {
    return !!task.officeManagerExclusiveStartDate;
  }

  private getCreateTaskAttemptAction(
    task: Task,
    eaTaskResultId,
    isSuccess: boolean
  ): Action {
    return tasksActions.createTaskAttemptRequest({
      taskAttempt: {
        task: task,
        eaTaskId: task.eaTaskId,
        eaTaskResultId: eaTaskResultId !== "" ? eaTaskResultId : "0",
        isSuccess: isSuccess,
      },
    });
  }

  private getSendMessageParams(
    templateId: string,
    sendToModuleId: string,
    recipientName: string,
    recipientEmail: string,
    office: Office,
    originEmployee: Employee,
    message: string
  ): SendMessageParams {
    return {
      sendToModule: sendToModuleId,
      recipients: recipientEmail,
      templateId: templateId,
      message: message.replace(/\r?\n/g, "<br />"),
      receiverName: recipientName,
      originEmployeeFullName: originEmployee.employeeFullName,
      originOfficeName: office.officeName,
    };
  }
}

export interface TaskUpdateParams {
  eaTaskId: string;
  eaOfficeId?: string;
  eaEmployeeId?: string;
  officeManagerExclusiveEndDate?: string;
  deliveryDate?: string;
}

export enum assignOptions {
  ME = "me",
  BROKER = "broker",
  FREE = "free",
}
