import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TaskResult } from "@app/models";
import { Subject } from "rxjs";

@Component({
  selector: "sidebar-tasks-default-manage",
  templateUrl: "./tasks-default-manage.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./tasks-default-manage.component.scss",
  ],
})
export class TasksDefaultManageComponent implements OnInit, OnDestroy {
  @Input() taskResults: TaskResult[];
  @Input() formGroup: FormGroup;

  unSubscribe$ = new Subject<void>();

  constructor() {
    this.formGroup = new FormGroup({
      result: new FormControl(""),
    });
  }

  ngOnInit() {
    this.formGroup.get("result").setValidators([Validators.required]);

    this.formGroup.parent.get("assign.employee").clearValidators(); // TODO move to parent, use onReady$ state
    this.formGroup.parent.get("assign.employee").updateValueAndValidity();
    this.formGroup.parent.get("assign.office").clearValidators();
    this.formGroup.parent.get("assign.office").updateValueAndValidity();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
