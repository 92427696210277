import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-call-registered",
  template: `
    <div class="action-note-stored">
      <div>
        <div class="display-flex-start">
          <span [innerHTML]="text | async" class=""></span>&nbsp;
          <contact-summary [contactId]="item.contactId"></contact-summary>
        </div>
        <div>
          <small>
            {{ item.timestamp | amDateFormat : "YYYY-MM-DD" }}
          </small>
          <small
            *ngIf="item.street?.length > 0"
            (click)="itemService.navigateToObject(item.eaOid)"
          >
            <strong>{{ "address" | translate }}: </strong>
            <span class="text-link">{{ item.street }} </span>
          </small>
        </div>
      </div>
      <div
        *ngIf="!!eaEmployeeId && item.eaEmployeeId === eaEmployeeId"
        class="flex-center note action-note-stored__trash"
      >
        <span (click)="itemService.removeSalesCallAction(item)">
          <app-icon name="trash"></app-icon>
        </span>
      </div>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionCallRegisteredComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;
  @Input() eaEmployeeId: string = "";

  text: Observable<string>;
  listenerName: Function;

  className = "action-call-registered-name";
  unSubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_call_registered_text", {
          you: youString,
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
