import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable } from "rxjs";

@Injectable()
export class DateService {
  constructor(private translateService: TranslateService) {}

  getExpirationText(rawDate: string): Observable<string> {
    const date = moment(rawDate);
    const translationKey = date.isAfter(moment())
      ? "expires_at_X"
      : "expired_at_X";

    return this.translateService.get(translationKey, {
      fromNow: date.fromNow(),
    });
  }

  expired(rawDate: string): boolean {
    return moment(rawDate).isBefore();
  }
}
