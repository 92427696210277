<div
  bsModal
  #modal="bs-modal"
  [config]="{ show: true }"
  (onHidden)="onHidden()"
  class="modal fade"
  tabindex="-1"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="btn-close pull-right"
          (click)="hideModal()"
        >
          <i class="fa fa-close"></i>
        </button>

        <h4 class="modal-title pull-left">{{ title }}</h4>
      </div>

      <div class="modal-body">
        <div class="month-controls">
          <button
            *ngIf="canChangeDate"
            class="btn btn-default"
            (click)="loadPreviousMonth()"
          >
            <i class="icon-arrow-left"></i>
          </button>
          <div class="month">{{ monthTitle }}</div>
          <button
            *ngIf="canChangeDate"
            class="btn btn-default"
            (click)="loadNextMonth()"
          >
            <i class="icon-arrow-right"></i>
          </button>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
