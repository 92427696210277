import { Component, Input } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { Action } from "@app/models";
import { Store } from "@ngrx/store";

@Component({
  selector: "contact-action-one2one-email",
  template: `
    <ng-container>
      <h4>
        {{ action.timestamp }}
        <small>{{ "of" | translate }} {{ action.employeeFullName }}</small>
      </h4>
      <p (click)="openPreviewModal(action.param3)" class="preview-link">
        <i class="icon-eye"></i>
        {{ "view" | translate }}
      </p>
      <ng-container> </ng-container
    ></ng-container>
  `,
  styleUrls: ["./common.scss"],
})
export class ContactActionOne2OneEmailComponent {
  @Input() action: Action;

  constructor(private store: Store<AppState>) {}

  openPreviewModal(html: string): void {
    this.store.dispatch(
      previewModalActions.showPreviewModal({
        parameters: { html },
      })
    );
  }
}
