import { Component, Input } from "@angular/core";

@Component({
  selector: "app-icon",
  templateUrl: "./icon.component.html",
  styleUrls: ["./icon.component.scss"],
})
export class IconComponent {
  @Input() type: "solid" | "regular" | "light" | "duotone" | "thin" = "light";
  @Input() name: string;
  @Input() size: "x-small" | "small" | "medium" | "large" | "x-large" | "none" =
    "small";

  getType() {
    switch (this.type) {
      case "solid":
        return "fa-solid";
      case "regular":
        return "fa-regular";
      case "light":
        return "fa-light";
      case "thin":
        return "fa-thin";
      case "duotone":
        return "fa-duotone";
    }
  }

  getSize() {
    switch (this.size) {
      case "x-small":
        return "icon--x-small";
      case "small":
        return "icon--small";
      case "medium":
        return "icon--medium";
      case "large":
        return "icon--large";
      case "x-large":
        return "icon--x-large";
      case "none":
        return "";
    }
  }
}
