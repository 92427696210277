<div class="q-date-picker" [class.inline]="inline">
  <label *ngIf="label" class="q-date-picker__label">{{
    label | translate
  }}</label>
  <div class="q-date-picker__container" [class.disabled]="disabled">
    <button
      type="button"
      class="q-date-picker__icon"
      [class.q-date-picker__icon--blue]="value"
      (click)="dp.toggle()"
      [disabled]="disabled"
    >
      <app-icon
        [name]="value ? 'calendar-alt' : 'calendar'"
        size="medium"
      ></app-icon>
    </button>
    <input
      type="text"
      readonly
      [placeholder]="placeholder"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [bsConfig]="bsConfig"
      #dp="bsDatepicker"
      bsDatepicker
      [ngModel]="value"
      (ngModelChange)="onValueChange($event)"
      [isDisabled]="disabled"
      [placement]="placement"
      class="q-date-picker__control"
      [class.invalid]="invalid"
    />
    <button
      type="button"
      class="q-date-picker__clear"
      *ngIf="value && !disabled && !required"
      (click)="clear()"
    >
      <app-icon name="times" size="medium"></app-icon>
    </button>
  </div>
</div>
