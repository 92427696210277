import { SentTipsResponse } from "@app/statistics/statistics-sent-tips/models/sent-tips-response";
import { createAction, props } from "@ngrx/store";

export const fetchTaskTypesRequest = createAction(
  "[Statistics Sent Tips] FETCH_TASK_TYPES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchTaskTypesSuccess = createAction(
  "[Statistics Sent Tips] FETCH_TASK_TYPES_SUCCESS",
  props<{ taskTypes: { typeName: string; eaTaskTypeId: number }[] }>()
);

export const fetchTaskTypesFail = createAction(
  "[Statistics Sent Tips] FETCH_TASK_TYPES_FAIL"
);

export const fetchTaskResultsRequest = createAction(
  "[Statistics Sent Tips] FETCH_TASK_RESULTS_REQUEST",
  props<{ eaTaskTypeId: number[] }>()
);

export const fetchTaskResultsSuccess = createAction(
  "[Statistics Sent Tips] FETCH_TASK_RESULTS_SUCCESS",
  props<{
    taskResults: {
      typeName: string;
      eaTaskTypeId: string;
      resultName: string;
      eaTaskResultId: string;
    }[];
  }>()
);

export const fetchTaskResultsFail = createAction(
  "[Statistics Sent Tips] FETCH_TASK_RESULTS_FAIL"
);

export const fetchStatisticsRequest = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_SUCCESS",
  props<{ tips: SentTipsResponse }>()
);

export const fetchStatisticsFail = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_FAIL"
);

export const fetchStatisticsCsvRequest = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_CSV_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsCsvSuccess = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_CSV_SUCCESS"
);

export const fetchStatisticsCsvFail = createAction(
  "[Statistics Sent Tips] FETCH_STATISTICS_CSV_FAIL"
);
