import { Contact, QObject } from "@app/models";
import { TaskTemplate } from "@app/sidebar/create-lead/ngrx/create-lead.reducer";
import { CreateContactParams } from "../../contacts/ngrx/create-contact.actions";
import { createAction, props } from "@ngrx/store";
import { CreateLeadParams } from "@app/sidebar/create-lead/create-lead-new/create-lead-new.component";

const ACTION_PREFIX = "[Create Lead]";

export const createLeadRequest = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_REQUEST`,
  props<{ params: CreateLeadParams }>()
);

export const createLeadSuccess = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_SUCCESS`
);

export const createLeadFailed = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_FAILED`
);

export const getTaskTemplatesRequest = createAction(
  `${ACTION_PREFIX} GET_TASK_TEMPLATES_REQUEST`,
  props<{ params: Record<string, unknown> }>()
);

export const getTaskTemplatesSuccess = createAction(
  `${ACTION_PREFIX} GET_TASK_TEMPLATES_SUCCESS`,
  props<{ taskTemplates: TaskTemplate[] }>()
);

export const getTaskTemplatesFailed = createAction(
  `${ACTION_PREFIX} GET_TASK_TEMPLATES_FAILED`
);

export const getOfficeObjectForSaleRequest = createAction(
  `${ACTION_PREFIX} GET_OFFICE_OBJECT_FOR_SALE_REQUEST`,
  props<{ params: Record<string, unknown> }>()
);

export const getOfficeObjectForSaleSuccess = createAction(
  `${ACTION_PREFIX} GET_OFFICE_OBJECT_FOR_SALE_SUCCESS`,
  props<{ objects: QObject[] }>()
);

export const getContactRequest = createAction(
  `${ACTION_PREFIX} GET_CONTACT_REQUEST`,
  props<{ id: string }>()
);

export const getContactSuccess = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SUCCESS`,
  props<{ contact: Contact }>()
);

export const getContactFailed = createAction(
  `${ACTION_PREFIX} GET_CONTACT_FAILED`
);

export const setContact = createAction(
  `${ACTION_PREFIX} SET_CONTACT`,
  props<{ contact: Contact }>()
);

export const createContact = (params: CreateContactParams, source?: string) => {
  return createContactRequest({
    params: { ...params, originService: "CRM" },
    source,
  });
};

export const createContactRequest = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_REQUEST`,
  props<{ params: Record<string, unknown>; source?: string }>()
);

export const createContactSuccess = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_SUCCESS`,
  props<{ contactId: string }>()
);

export const createContactFailed = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_FAILED`
);

export const reset = createAction(`${ACTION_PREFIX} RESET`);
