<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'edit_external_tips' | translate"
  ></sidebar-header>
  <div>
    <external-tips-form
      *ngIf="dataForForm$ | async as data"
      [mode]="externalTipFormModes.EDIT_MODE"
      [observer]="proxy$"
      [externalTip]="data.externalTip"
      [canEditOffice]="data.canEditOffice"
      [offices]="data.offices"
      (submitForm)="onSubmit()"
    >
    </external-tips-form>
  </div>
  <div class="sidebar-footer">
    <button type="button" class="btn btn-cancel btn-lg" (click)="closeTab()">
      {{ "cancel" | translate }}
    </button>
    <button
      type="button"
      class="btn btn-success btn-lg"
      (click)="externalTipsFormComponent.submit()"
    >
      {{ "save" | translate }}
    </button>
  </div>
</div>
