<app-task-default-new *ngIf="task$ | async as task" [task]="task">
  <sidebar-tasks-detail-card card [task]="task">
    <div
      extraMetadata
      class="task__calling-list"
      *ngIf="callingList$ | async as callingList"
    >
      <div>
        <small class="task__calling-list__percent"
          >{{ callingList.progress.percent }}%</small
        >
        <small>
          {{ "calling_list_contacted_plural" | translate }} ({{
            callingList.progress.contacted
          }}
          /{{ callingList.progress.contacts }})</small
        >
      </div>
      <small>
        <a
          [routerLink]="[
            '/crm',
            {
              outlets: {
                primary: ['calling-lists', callingList.callingListId],
                sidebar: null
              }
            }
          ]"
        >
          {{ "goto_calling_list" | translate }}
          <i class="fa fa-arrow-right"></i>
        </a>
      </small>
      <br />
    </div>
  </sidebar-tasks-detail-card>
</app-task-default-new>
