import { PotentialBuyersPageConfig } from "@app/core/services/feature-config-manager/models/potential-buyers-page-config";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";

export const buildPotentialBuyersPageConfiguration = (
  externalProvideFeature: ExternalProviderFeature
): PotentialBuyersPageConfig => {
  return {
    convertUnknownToZeroBidderEnabled: isConvertUnknownToZeroBidderEnabled(
      externalProvideFeature.show_convert_unknown_to_zero_bidder_button
    ),
  };
};
const isConvertUnknownToZeroBidderEnabled = (enabled: boolean): boolean => {
  return enabled;
};
