import { Pipe, PipeTransform } from "@angular/core";
import { API_DATE_TIME_FORMAT } from "@app/shared/utils/api-defaults";
import moment from "moment";

@Pipe({
  name: "momentifyFromUtcPipe",
})
export class MomentifyFromUtcPipe implements PipeTransform {
  transform(value: string, dateFormat: string = API_DATE_TIME_FORMAT): string {
    if (!!value) {
      const utcDate = moment.utc(value, API_DATE_TIME_FORMAT);
      return utcDate.local().format(dateFormat).toUpperCase();
    } else {
      return null;
    }
  }
}
