import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ObjectMarketingService } from "@app/core/ngrx/entity-services/object-marketing.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { Employee, QObject } from "@app/models";
import * as fromUser from "@app/shared/user";
import { ShowingObject } from "@app/showings/models";
import { defaultColorScheme } from "@app/statistics/charts/utils";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-adfenix-property-reporting-widget",
  templateUrl: "./adfenix-property-reporting-widget.component.html",
  styleUrls: ["./adfenix-property-reporting-widget.component.scss"],
})
export class AdfenixPropertyReportingWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaOid: string;
  showingObject$: BehaviorSubject<QObject & ShowingObject> =
    new BehaviorSubject<QObject & ShowingObject>(null);
  value$: BehaviorSubject<{ name: string; value: number }[]> =
    new BehaviorSubject<{ name: string; value: number }[]>(null);
  orderUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  totalValue$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  activeCampaigns$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  view$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([300, 350]);
  colorScheme = defaultColorScheme;
  employee$: Observable<Employee>;

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    public objectMarketingService: ObjectMarketingService,
    private translateService: TranslateService
  ) {}

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.setViewDimensions();
    this.mapStateToProps();
    this.handlePropertyChange();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.value$.next(null);
      this.totalValue$.next(null);
      this.activeCampaigns$.next(null);
      this.orderUrl$.next(null);
      this.objectService.entityMap$
        .pipe(
          map((entities) => entities[this.eaOid]),
          debounceTime(200),
          filter((entity) => !!entity),
          first()
        )
        .subscribe((obj) => {
          this.showingObject$.next(obj);
        });
      this.objectMarketingService.entityMap$
        .pipe(
          map((entities) => entities[this.eaOid]),
          debounceTime(200),
          filter((entity) => !!entity),
          first()
        )
        .subscribe((obj) => {
          this.orderUrl$.next(obj.orderPageUrl);
          this.value$.next(
            obj.visitorCounts.map((vc) => {
              return {
                name: this.translateService.instant(
                  vc.category.toLowerCase() + "_adfenix"
                ),
                value: vc.visits,
              };
            })
          );
          this.totalValue$.next(
            obj.visitorCounts.reduce((acc, vc) => {
              return acc + vc.visits;
            }, 0)
          );
          this.activeCampaigns$.next(obj?.facebookCampaigns?.length);
        });
    }
  }

  fetchAdfenixMarketingData(eaOid: string) {
    this.objectMarketingService
      .getById(eaOid)
      .pipe(
        first(),
        catchError(() => {
          this.value$.next(null);
          this.orderUrl$.next(null);
          this.totalValue$.next(null);
          this.activeCampaigns$.next(null);
          return null;
        })
      )
      .subscribe();
  }

  private mapStateToProps() {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
  }

  private handlePropertyChange() {
    this.showingObject$
      .pipe(
        filter((it) => !!it),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((property) => {
        this.fetchAdfenixMarketingData(property.eaOid);
      });
  }

  private setViewDimensions() {
    if (window.innerWidth < 1500) {
      this.view$.next([380, 350]);
    } else if (window.innerWidth < 2400) {
      this.view$.next([350, 330]);
    } else {
      this.view$.next([450, 350]);
    }
  }
}
