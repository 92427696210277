import {
  createLeadReducer,
  CreateLeadState,
} from "@app/sidebar/create-lead/ngrx/create-lead.reducer";
import {
  createObjectReducer,
  CreateObjectState,
} from "@app/sidebar/create-object/ngrx/create-object.reducer";
import { eikaReducer, EikaState } from "@app/sidebar/eika/ngrx/eika.reducer";
import {
  externalProviderReducer,
  ExternalProviderState,
} from "@app/sidebar/external-provider/ngrx/external-provider.reducer";
import {
  leadChannelsFormReducer,
  LeadChannelsFormState,
} from "@app/sidebar/lead-channels/ngrx/lead-channels-form.reducer";
import {
  leadResultOptionsFormReducer,
  LeadResultOptionsFormState,
} from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.reducer";
import {
  leadTemplatesFormReducer,
  LeadTemplatesFormState,
} from "@app/sidebar/lead-templates/ngrx/lead-templates-form.reducer";
import {
  messageTemplatesFormReducer,
  MessageTemplatesFormState,
} from "@app/sidebar/message-templates/ngrx/message-templates-form.reducer";
import {
  potentialBuyerModuleReducer,
  PotentialBuyerState,
} from "@app/sidebar/potential-buyer/ngrx";
import {
  searchProfileSidebarReducer,
  SearchProfileSidebarState,
} from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import {
  supportSidebarReducer,
  SupportSidebarState,
} from "@app/sidebar/support/ngrx/support.reducer";
import { ActionReducerMap, combineReducers } from "@ngrx/store";
import {
  callingListsReducer,
  CallingListsState,
} from "./calling-lists/ngrx/calling-lists.reducer";
import {
  createContactReducer,
  CreateContactState,
} from "./contacts/ngrx/create-contact.reducer";
import {
  createFollowUpReducer,
  CreateFollowUpState,
} from "./create-follow-up/ngrx/create-follow-up.reducer";
import {
  employmentCreateEmployeeReducer,
  EmploymentCreateEmployeeState,
} from "./employees/employees-create/ngrx/employees-create.reducer";
import {
  employeesCreateEmploymentReducer,
  EmployeesCreateEmploymentState,
} from "./employees/employees-office-create/ngrx/employees-office-create.reducer";
import {
  employeesSearchReducer,
  EmployeesSearchState,
} from "./employees/employees-search/ngrx/employees-search.reducer";
import {
  externalTipsReducer,
  ExternalTipsState,
} from "./external-tips/ngrx/external-tips.reducer";
import {
  residenceReducer,
  ResidenceState,
} from "./residence/ngrx/residence.reducer";
import {
  salesMeetingReducer,
  SalesMeetingState,
} from "./sales-meeting/ngrx/sales-meeting.reducer";
import {
  sendMessageReducer,
  SendMessageState,
} from "./send-message/ngrx/send-message.reducer";
import { sidebarReducer, SidebarState } from "./ngrx/sidebar.reducer";
import { tasksReducer, TasksState } from "./tasks/ngrx/tasks.reducer";
import { sendTipsReducer, SendTipsState } from "./tips/ngrx/send-tips.reducer";

export interface SidebarModuleState {
  callingLists: CallingListsState;
  createContact: CreateContactState;
  createObject: CreateObjectState;
  createFollowUp: CreateFollowUpState;
  createLead: CreateLeadState;
  employmentCreateEmployee: EmploymentCreateEmployeeState;
  employeesCreateEmployment: EmployeesCreateEmploymentState;
  employeesSearch: EmployeesSearchState;
  residence: ResidenceState;
  salesMeeting: SalesMeetingState;
  sendMessage: SendMessageState;
  sendTips: SendTipsState;
  sidebar: SidebarState;
  tasks: TasksState;
  externalTips: ExternalTipsState;
  potentialBuyer: PotentialBuyerState;
  externalProvider: ExternalProviderState;
  searchProfiles: SearchProfileSidebarState;
  support: SupportSidebarState;
  eika: EikaState;
  messageTemplatesForm: MessageTemplatesFormState;
  leadChannelsForm: LeadChannelsFormState;
  leadTemplatesForm: LeadTemplatesFormState;
  leadResultOptionsForm: LeadResultOptionsFormState;
}

const reducers: ActionReducerMap<SidebarModuleState> = {
  callingLists: callingListsReducer,
  createContact: createContactReducer,
  createObject: createObjectReducer,
  createFollowUp: createFollowUpReducer,
  createLead: createLeadReducer,
  employmentCreateEmployee: employmentCreateEmployeeReducer,
  employeesCreateEmployment: employeesCreateEmploymentReducer,
  employeesSearch: employeesSearchReducer,
  residence: residenceReducer,
  salesMeeting: salesMeetingReducer,
  sendMessage: sendMessageReducer,
  sendTips: sendTipsReducer,
  sidebar: sidebarReducer,
  tasks: tasksReducer,
  externalTips: externalTipsReducer,
  potentialBuyer: potentialBuyerModuleReducer,
  externalProvider: externalProviderReducer,
  searchProfiles: searchProfileSidebarReducer,
  support: supportSidebarReducer,
  eika: eikaReducer,
  messageTemplatesForm: messageTemplatesFormReducer,
  leadChannelsForm: leadChannelsFormReducer,
  leadTemplatesForm: leadTemplatesFormReducer,
  leadResultOptionsForm: leadResultOptionsFormReducer,
};

export function sidebarModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
