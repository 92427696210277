import { StoreConsentRequestObject } from "@app/contacts/contact-consents/components/consents-sidebar/consents-sidebar.component";
import { ProcessedConsent } from "@app/contacts/contact-consents/models";
import { createAction, props } from "@ngrx/store";

export const fetchConsentsRequest = createAction(
  "[Consents] FETCH_CONSENTS_REQUEST",
  props<{ contactId: string }>()
);

export const fetchConsentsSuccess = createAction(
  "[Consents] FETCH_CONSENTS_SUCCESS",
  props<{ consents: ProcessedConsent[] }>()
);

export const fetchConsentsFail = createAction("[Consents] FETCH_CONSENTS_FAIL");

export const fetchContactActivityRequest = createAction(
  "[Consents] FETCH_CONTACT_ACTIVITY_REQUEST",
  props<{ contactId: string; showingExpirationDate: Date }>()
);

export const fetchContactActivitySuccess = createAction(
  "[Consents] FETCH_CONTACT_ACTIVITY_SUCCESS",
  props<{
    hasActiveAlert: boolean;
    hasBeenToShowing: boolean;
    hasFutureTasks: boolean;
  }>()
);

export const fetchContactActivityFail = createAction(
  "[Consents] FETCH_CONTACT_ACTIVITY_FAIL"
);

export const refreshConsents = createAction("[Consents] REFRESH_CONSENTS");

export const openSidebar = createAction(
  "[Consents] OPEN_SIDEBAR",
  props<{
    alias: string;
    validTo?: string;
    note?: string;
  }>()
);

export const closeSidebar = createAction("[Consents] CLOSE_SIDEBAR");

export const storeConsentRequest = createAction(
  "[Consents] STORE_CONSENT_REQUEST",
  props<{
    consent: StoreConsentRequestObject;
    contactId: string;
    alias: string;
    origin: string;
  }>()
);

export const storeConsentSuccess = createAction(
  "[Consents] STORE_CONSENT_SUCCESS"
);

export const storeConsentFail = createAction("[Consents] STORE_CONSENT_FAIL");

export const deleteConsentRequest = createAction(
  "[Consents] DELETE_CONSENT_REQUEST",
  props<{ contactId: string; alias: string }>()
);

export const deleteConsentSuccess = createAction(
  "[Consents] DELETE_CONSENT_SUCCESS"
);

export const deleteConsentFail = createAction("[Consents] DELETE_CONSENT_FAIL");
