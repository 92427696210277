import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { FormElementService } from "@app/erp/components/form-elements/form-element.service";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject, takeUntil } from "rxjs";

export interface DropdownItem {
  display: string;
  value: any;
}

@Component({
  selector: "app-dropdown-element",
  templateUrl: "./dropdown-element.component.html",
  styleUrls: ["./dropdown-element.component.scss"],
})
export class DropdownElementComponent<T>
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild("selectDropdown") selectDropdown: any;
  @Output() valueEntered: EventEmitter<T> = new EventEmitter<T>();

  @Input() entity: any;
  @Input() label: string;
  @Input() infoTranslationKey: string;
  @Input() fieldName: string;
  @Input() additionalFieldName: string;
  @Input() path: string[];
  @Input() width: string;
  @Input() multiple = false;
  @Input() search = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() oldStyle = false;
  @Input() newStyle = false;
  @Input() preselectValue: T;
  @Input() onPatchFunction: Function;
  @Input() items: DropdownItem[];

  form: FormGroup;
  formElementService: FormElementService;
  unSubscribe$ = new Subject<void>();
  isMobile$: Observable<boolean>;

  searchTextboxControl = new FormControl("");
  originalItems: {
    display: string;
    value: any;
  }[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    if (!this.formElementService) {
      this.initFormElementService();
    }
  }

  mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(isMobile));
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity && this.entity) {
      this.initFormElementService();
    }

    if (changes.preselectValue && !!this.formElementService) {
      this.initFormElementService();
    }

    if (changes.items && !!this.items) {
      this.originalItems = [...this.items];
    }

    if (changes.search && !!this.search) {
      this.handleSearch();
    }
  }

  initFormElementService(): void {
    this.formElementService = new FormElementService(this.fb, {
      path: this.path,
      entity: !!this.entity ? this.entity : null,
      preselectedValue: this.preselectValue ?? null,
      onPatchFunction: !!this.onPatchFunction && this.onPatchFunction,
      disabled: this.disabled,
    });
    this.formElementService.form.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(() => this.onBlur());
  }

  onBlur() {
    this.formElementService.handleBlur().subscribe((it) => {
      if (it === "UNKNOWN") {
        this.valueEntered.emit(this.formElementService.getFormValue());
      }
    });
  }

  clearSearch(event: MouseEvent) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue("");
  }

  private handleSearch() {
    this.searchTextboxControl.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((search) => {
        const searchRegexp = new RegExp(search, "i");

        this.items = this.originalItems.filter((originalItem) =>
          this.translateService
            .instant(originalItem.display)
            .match(searchRegexp)
        );
      });
  }
}
