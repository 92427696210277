import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { WeatherComponent } from "@app/shared/modules/weather/weather.component";
import { WeatherService } from "@app/shared/modules/weather/weather.service";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";

const components = [WeatherComponent];

@NgModule({
  imports: [CommonModule, TranslateModule, TooltipModule],
  declarations: components,
  exports: components,
  providers: [WeatherService],
})
export class WeatherModule {}
