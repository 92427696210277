import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  selector: "slide-toggle",
  template: `
    <label class="switch">
      <input
        [disabled]="disabled"
        [ngModel]="checked"
        type="checkbox"
        (change)="onToggle.emit($event)"
      />
      <div
        [ngClass]="checked ? 'switch__slider--left' : 'switch__slider--right'"
        [ngStyle]="getStyle()"
        class="switch__slider"
        [class.disabled]="disabled"
      >
        <span *ngIf="!checked" class="switch__text switch__text--right">
          {{ inactiveLabel | translate }}
        </span>
        <span *ngIf="checked" class="switch__text switch__text--left">
          {{ activeLabel | translate }}
        </span>
      </div>
    </label>
  `,
  styleUrls: ["./slide-toggle.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SlideToggleComponent {
  width: number | string;

  @Input() checked: boolean;
  @Input() disabled: boolean = false;
  @Input() activeLabel: string;
  @Input() inactiveLabel: string;
  @Input() modal = false;
  @Input() showDangerAndSuccessColors: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  getStyle() {
    if (this.showDangerAndSuccessColors && !this.checked) {
      return { "background-color": "#DE1B41" };
    } else if (this.showDangerAndSuccessColors && this.checked) {
      return { "background-color": "#28E57C" };
    }
  }
}
