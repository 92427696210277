import { Component } from "@angular/core";
import { SEND_TIPS } from "@app/shared/utils/tab-types";

@Component({
  selector: "send-tips",
  template: `
    <div>
      <div class="header-wrapper">
        <sidebar-header
          [type]="tabType"
          [label]="'send_tips' | translate"
        ></sidebar-header>
      </div>
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ["../sidebar.component.common.scss"],
})
export class SendTipsComponent {
  tabType = SEND_TIPS;
}
