import { Employee } from "@app/models";
import { KpiStatisticsParams } from "@app/statistics/statistics-kpi/ngrx/statistics-kpi.reducer";
import { createAction, props } from "@ngrx/store";
import { KpiResponse } from "@app/statistics/statistics-kpi/models/kpi-statistics-response";

export const fetchStatisticsRequest = createAction(
  "[Statistics] FETCH_STATISTICS_REQUEST",
  props<{ params: KpiStatisticsParams }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Statistics] FETCH_STATISTICS_SUCCESS",
  props<{ statistics: KpiResponse }>()
);

export const fetchStatisticsFailure = createAction(
  "[Statistics] FETCH_STATISTICS_FAILURE"
);

export const resetStatisticsKpi = createAction(
  "[Statistics] RESET_STATISTICS_KPI"
);

export const getEmployeesRequest = createAction(
  "[Statistics] GET_EMPLOYEES_REQUEST",
  props<{ eaOfficeId?: string }>()
);

export const getEmployeesSuccess = createAction(
  "[Statistics] GET_EMPLOYEES_SUCCESS",
  props<{ employees: Employee[] }>()
);

export const getEmployeesFailure = createAction(
  "[Statistics] GET_EMPLOYEES_FAILURE"
);

export const getStatisticsCsvRequest = createAction(
  "[Statistics] GET_STATISTICS_CSV_REQUEST",
  props<{ params: KpiStatisticsParams }>()
);

export const getStatisticsCsvSuccess = createAction(
  "[Statistics] GET_STATISTICS_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const getStatisticsCsvFailure = createAction(
  "[Statistics] GET_STATISTICS_CSV_FAILURE"
);

export const emptyEmployeesRequest = createAction(
  "[Statistics] EMPTY_EMPLOYEES"
);
