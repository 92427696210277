<h4>
  {{ 'forgot_password_new_user' | translate }}
</h4>

<div [ngSwitch]="step">
  <step1 *ngSwitchDefault
         (onSend)="send($event)">
  </step1>
  <step2 *ngSwitchCase="2"
         (onVerify)="verify($event)">
  </step2>
  <step3 *ngSwitchCase="3"
         (onSetPassword)="setPassword($event)"></step3>
</div>
