import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { ResidenceCreateComponent } from "./residence-create/residence-create.component";
import { ResidenceEditComponent } from "./residence-edit/residence-edit.component";
import { ResidenceFormComponent } from "./residence-form/residence-form.component";
import { ResidenceLinksComponent } from "./residence-links/residence-links.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    SearchAddressModule,
    TranslateModule,
    SidebarSharedModule,
    FormComponentsModule,
    UiComponentsModule,
    UtilModule,
  ],
  declarations: [
    ResidenceCreateComponent,
    ResidenceEditComponent,
    ResidenceFormComponent,
    ResidenceLinksComponent,
  ],
  exports: [ResidenceCreateComponent, ResidenceEditComponent],
})
export class ResidenceModule {}
