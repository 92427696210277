import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink, addQuickLink } from "@app/contacts/contact.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Contact, Task } from "@app/models";
import { getTaskIcons } from "@app/shared/config/config.reducer";
import { TaskIcon } from "@app/shared/config/models";
import { getQuickLinkLabel } from "@app/shared/utils/contact-quick-link";
import { CALLING_LIST } from "@app/shared/utils/task-types";
import { select, Store } from "@ngrx/store";
import { filter, first, map, Observable } from "rxjs";
import { collapsedTrigger, listAnimation } from "../../../animations";
import { CONTACTS_CRM_MODULE } from "../../../utils/crm-modules";
import * as TASK_CATEGORIES from "../../../utils/task-categories";

@Component({
  selector: "list",
  templateUrl: "./list.component.html",
  styleUrls: ["./list.component.scss"],
  animations: [collapsedTrigger(), listAnimation("35ms")],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements OnInit {
  @Input() title: string;
  @Input() items: Array<any> = [];
  @Input() itemType = "task";
  @Input() isExpanded = true;
  @Input() badge = false;
  @Input() badgeText: string;
  @Input() badgeColor: string;
  @Input() badgeSpinner = false;
  @Input() hideNoResult = false;
  @Input() lazy = false;
  @Input() disableLoadMore = false;
  @Input() itemsLeftToLoad: number;
  @Input() showHeader = true;
  @Input() helpInfo = "";
  @Input() disableSidebar = false;

  @Output() loadMore = new EventEmitter();
  @Output() expandedChanged = new EventEmitter<boolean>();

  loadingId = "";
  private taskIcons$: Observable<TaskIcon[]>;

  constructor(
    private store: Store<AppState>,
    private taskTypeResolver: TaskTypeResolverService,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  setExpanded(isExpanded) {
    this.isExpanded = isExpanded;
    this.expandedChanged.emit(isExpanded);
  }

  onLoadMore() {
    this.loadMore.emit({});
  }

  resolveIcon(task: Task): Observable<string> {
    return this.taskIcons$.pipe(
      first(),
      map((taskIcons) => {
        const taskIcon = taskIcons.find(
          (ti) => ti.task_type.toString() === task.eaTaskTypeId
        );
        if (taskIcon) {
          return taskIcon.icon_name;
        } else if (task.eaTaskTypeCategory === TASK_CATEGORIES.LEAD) {
          return this.taskTypeResolver.taskTypeCategoryToIcon(
            task.eaTaskTypeCategory
          );
        } else {
          return this.taskTypeResolver.taskTypeIdToIcon(task.eaTaskTypeId);
        }
      })
    );
  }

  resolveColor(task: Task) {
    if (task.eaTaskTypeCategory === TASK_CATEGORIES.LEAD) {
      return this.taskTypeResolver.taskTypeCategoryToColor(
        task.eaTaskTypeCategory
      );
    }
    return this.taskTypeResolver.taskTypeIdToColor(task.eaTaskTypeId);
  }

  onTaskClick(task: Task) {
    this.loadingId = task.eaTaskId;
    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        first()
      )
      .subscribe(() => {
        this.loadingId = "";
        if (
          this.checkIfDisablePrimaryRoute(task.eaTaskTypeId) ||
          this.taskTypeResolver.getPrimaryRoute(task).length === 0
        ) {
          this.cdr.detectChanges();
        }
      });

    // Todo: extract this to service?
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      if (!this.checkIfDisablePrimaryRoute(task.eaTaskTypeId)) {
        this.store.dispatch(addBackLink({ backLink: this.router.url }));
      }
      this.store.dispatch(
        RouterActions.go({
          path: this.taskTypeResolver.getTaskRoute(
            task,
            this.checkIfDisablePrimaryRoute(task.eaTaskTypeId)
          ),
        })
      );
    } else {
      if (this.disableSidebar) {
        this.store.dispatch(
          addQuickLink({
            parameters: {
              route: this.taskTypeResolver.getTaskRoute(task, true),
              label: getQuickLinkLabel(task),
            },
          })
        );
        this.store.dispatch(addBackLink({ backLink: this.router.url }));
        this.store.dispatch(
          RouterActions.go({
            path: this.taskTypeResolver.getTaskRoute(task, false, true),
          })
        );
      } else {
        this.store.dispatch(
          RouterActions.go({
            path: this.taskTypeResolver.getTaskRoute(task, false),
          })
        );
      }
    }
  }

  checkIfDisablePrimaryRoute(taskTypeId: string): boolean {
    return (
      this.taskTypeResolver.taskTypeIdToName(Number(taskTypeId)) ===
      CALLING_LIST
    );
  }

  onContactClick(contact: Contact): void {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm", CONTACTS_CRM_MODULE, contact.contactId],
      })
    );
  }

  private mapStateToProps() {
    this.taskIcons$ = this.store.pipe(select(getTaskIcons));
  }
}
