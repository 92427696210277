import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import * as threeDayTaskListActions from "@app/dashboard/todo-list/three-day-task-list/three-day-task-list.actions";
import {
  getDayAfterTomorrowTaskList,
  getThreeDayTasksLoading,
  getTodayTaskList,
  getTomorrowTaskList,
} from "@app/dashboard/todo-list/three-day-task-list/three-day-task-list.reducer";
import * as upcomingTaskActions from "@app/dashboard/todo-list/upcoming-task-list/upcoming-task-list.actions";
import {
  getTotalNumberOfUpcomingTasks,
  getUpcomingTasks,
} from "@app/dashboard/todo-list/upcoming-task-list/upcoming-task-list.reducer";
import { Task } from "@app/models";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { select, Store } from "@ngrx/store";
import { combineLatest, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-current-tasks-widget",
  templateUrl: "./current-tasks-widget.component.html",
  styleUrls: ["./current-tasks-widget.component.scss"],
})
export class CurrentTasksWidgetComponent implements OnInit, OnDestroy {
  @Input() eaEmployeeId: string;
  @Input() openLinkInNewTab = false;
  @Input() showHeader: boolean = true;
  selectedTime: "today" | "upcoming" = "today";

  todayTasks$: Observable<Task[]>;
  tomorrowTasks$: Observable<Task[]>;
  dayAfterTomorrowTasks$: Observable<Task[]>;
  upcomingTasks$: Observable<Task[]>;

  totalNumberOfUpcomingTasks$: Observable<number>;
  loading$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  segmentedControls: SegmentControls = [
    { label: "today", value: "today" },
    { label: "upcoming", value: "upcoming" },
  ];

  constructor(
    private store: Store<AppState>,
    private taskTypeResolver: TaskTypeResolverService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchTasks();
    this.setSegmentControls();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.todayTasks$ = this.store.pipe(select(getTodayTaskList));
    this.tomorrowTasks$ = this.store.pipe(select(getTomorrowTaskList));
    this.dayAfterTomorrowTasks$ = this.store.pipe(
      select(getDayAfterTomorrowTaskList)
    );
    this.upcomingTasks$ = this.store.pipe(select(getUpcomingTasks));
    this.totalNumberOfUpcomingTasks$ = this.store.pipe(
      select(getTotalNumberOfUpcomingTasks)
    );
    this.loading$ = this.store.pipe(select(getThreeDayTasksLoading));
  }

  getTaskListObservable(): Observable<Task[]> {
    switch (this.selectedTime) {
      case "today":
        return this.todayTasks$;
      case "upcoming":
        return this.upcomingTasks$;
      default:
        return this.todayTasks$;
    }
  }

  resolveIcon(task: Task): Observable<string> {
    return this.taskTypeResolver.resolveIcon(task);
  }

  resolveColor(task: Task) {
    return this.taskTypeResolver.resolveColor(task);
  }

  openInNewTab(task: Task) {
    this.taskTypeResolver.onTaskClick(task, true);
  }

  onTaskClick(task: Task, event) {
    if (!event.target.attributes["disablenavigation"]) {
      this.taskTypeResolver.onTaskClick(task, this.openLinkInNewTab);
    }
  }

  private fetchTasks() {
    this.store.dispatch(
      threeDayTaskListActions.getCurrentWeek(this.eaEmployeeId)
    );
    this.store.dispatch(
      upcomingTaskActions.getUpcoming(0, this.eaEmployeeId, null)
    );
  }

  private setSegmentControls() {
    combineLatest([this.totalNumberOfUpcomingTasks$, this.todayTasks$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([upcomingTotal, today]) => {
        this.segmentedControls = [
          { label: "today", value: "today", total: today.length },
          { label: "upcoming", value: "upcoming", total: upcomingTotal },
        ];
      });
  }

  snoozeTask(task: Task) {
    this.taskTypeResolver.snoozeTask(task);
  }
}
