import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { SortEvent, SortOrder } from "@app/models";

@Component({
  selector: "sort-by-column",
  templateUrl: "./sort-by-column.component.html",
  styleUrls: ["./sort-by-column.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SortByColumnComponent implements OnChanges {
  @Input() sortBy: string;

  @Input() startSortOrder: SortOrder = "asc";

  @Input() selectedColumn: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSort = new EventEmitter<SortEvent>();

  isSelected: boolean;

  sortOrder: SortOrder = "asc";

  ngOnChanges(changes: SimpleChanges): void {
    const { selectedColumn, startSortOrder } = changes;

    if (selectedColumn && selectedColumn.currentValue) {
      this.update(selectedColumn.currentValue);
    }

    if (startSortOrder && startSortOrder.currentValue) {
      this.sortOrder = startSortOrder.currentValue;
    }
  }

  onClick(): void {
    const sortOrder =
      this.selectedColumn === this.sortBy
        ? this.toggleAndGetSortOrder()
        : this.startSortOrder;
    const sortBy = this.sortBy;

    this.onSort.emit({ sortBy, sortOrder });
  }

  private update(selectedColumn: string): void {
    this.isSelected = selectedColumn === this.sortBy;

    if (!this.isSelected && this.startSortOrder) {
      this.sortOrder = this.startSortOrder;
    }
  }

  private toggleAndGetSortOrder(): SortOrder {
    this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
    return this.sortOrder;
  }
}
