import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import * as ContactSummaryActions from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.actions";
import { getAddress } from "@app/shared/utils/object-utils";
import { select, Store } from "@ngrx/store";
import * as i18nISOCountries from "i18n-iso-countries";
import {
  combineLatest as observableCombineLatest,
  filter,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "contact-summary-info",
  styleUrls: [
    "../../contact-summary.component.scss",
    "./contact-summary-info.component.scss",
  ],
  template: `
    <article class="contact-summary-info">
      <header class="first-header">
        <h5 translate>contact_info</h5>
        <skeleton-rectangle *ngIf="isLoading" [height]="1"></skeleton-rectangle>
        <br />
      </header>
      <section
        class="contact-summary-info__contact"
        *ngIf="!isLoading; else loading"
      >
        <ul>
          <li
            *ngIf="
              (contact$ | async)?.street ||
                (contact$ | async)?.zip ||
                (contact$ | async)?.city;
              else noAddress
            "
          >
            <i class="icon-location-pin"></i>
            <span>
              {{ address }}
            </span>
          </li>
          <ng-template #noAddress>
            <li>
              <i class="icon-location-pin"></i>
              <span class="muted"> - - - </span>
            </li>
          </ng-template>

          <li *ngIf="rawPhoneNumber?.length > 1; else noPhone">
            <i class="icon-phone"></i>
            <a href="tel:+{{ rawPhoneNumber }}">{{
              phoneNumber ? phoneNumber : rawPhoneNumber
            }}</a>
          </li>
          <ng-template #noPhone>
            <li>
              <i class="icon-phone"></i>
              <span class="muted">- - - </span>
            </li>
          </ng-template>

          <li *ngIf="(contact$ | async)?.email; else noEmail">
            <i class="icon-envelope"></i>
            <a
              tooltip="{{ 'open_mail_client' | translate }}"
              placement="bottom"
              href="mailto:{{ (contact$ | async)?.email }}"
            >
              {{ (contact$ | async)?.email }}
            </a>
          </li>
          <ng-template #noEmail>
            <li>
              <i class="icon-envelope"></i>
              <span class="muted"> - - - </span>
            </li>
          </ng-template>
          <li
            *ngIf="(contact$ | async)?.preferredLanguage"
            [tooltip]="'preferred_language' | translate"
          >
            <i
              class="flag-icon flag-icon-{{
                (contact$ | async)?.preferredLanguage | lowercase
              }}"
            ></i>
            {{ preferredLanguage }}
          </li>
        </ul>
      </section>
      <ng-template #loading>
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
      </ng-template>
    </article>
  `,
})
export class ContactSummaryInfoComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() contact$: Observable<Contact>;
  @Output() contactLinkClicked = new EventEmitter<any>();

  destroy$ = new Subject<void>();
  contactId: string;
  phoneNumber: string;
  rawPhoneNumber: string;
  address: string;
  contactLoaded = false;
  preferredLanguage: string;

  constructor(
    private phoneNumberService: PhoneNumberService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.store.dispatch(ContactSummaryActions.reset());
  }

  onContactLinkClicked(e) {
    this.contactLinkClicked.emit(e);
  }

  mapStateToProps() {
    this.contact$
      .pipe(filter((value) => !!value))
      .pipe(takeUntil(this.destroy$))
      .subscribe((contact: Contact) => {
        this.contactId = contact.contactId;
        this.rawPhoneNumber = contact.msisdn;
        this.address = getAddress(contact.street, contact.zip, contact.city);
        this.phoneNumber =
          contact.msisdn && contact.msisdnCountry
            ? this.phoneNumberService.format(
                contact.msisdn,
                contact.msisdnCountry
              )
            : this.rawPhoneNumber;
        this.contactLoaded = true;
      });

    observableCombineLatest([
      this.contact$,
      this.store.pipe(select(fromConfig.getLanguage)),
    ])
      .pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$)
      )
      .subscribe(([contact, language]) => {
        if (contact) {
          this.preferredLanguage = i18nISOCountries.getName(
            contact.preferredLanguage,
            language
          );
        }
      });
  }
}
