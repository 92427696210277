import {
  DataStudioConfiguration,
  HubDataStudio,
} from "@app/models/tailored-campaign";
import { createAction, props } from "@ngrx/store";

export const fetchDataStudioConfigurationRequest = createAction(
  "[Widget Tailored Campaign] FETCH_DATA_STUDIO_CONFIGURATION_REQUEST"
);

export const fetchDataStudioConfigurationSuccess = createAction(
  "[Widget Tailored Campaign] FETCH_DATA_STUDIO_CONFIGURATION_SUCCESS",
  props<{ data: DataStudioConfiguration }>()
);

export const fetchDataStudioConfigurationFail = createAction(
  "[Widget Tailored Campaign] FETCH_DATA_STUDIO_CONFIGURATION_FAIL"
);

export const fetchHubDataStudioRequest = createAction(
  "[Widget Tailored Campaign] FETCH_HUB_DATA_STUDIO_REQUEST"
);

export const fetchHubDataStudioSuccess = createAction(
  "[Widget Tailored Campaign] FETCH_HUB_DATA_STUDIO_SUCCESS",
  props<{ data: HubDataStudio }>()
);

export const fetchHubDataStudioFail = createAction(
  "[Widget Tailored Campaign] FETCH_HUB_DATA_STUDIO_FAIL"
);
