import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

import { ContactComponentsModule } from "../contact-components/contacts-components.module";
import { FormComponentsModule } from "../form-components/form-components.module";
import { SearchContactListEmptyComponent } from "./list-empty/list-empty.component";
import { SearchContactListHeaderComponent } from "./list-header/list-header.component";
import { SearchContactComponent } from "./search-contact.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormComponentsModule,
    ContactComponentsModule,
  ],
  exports: [
    SearchContactComponent,
    SearchContactListHeaderComponent,
    SearchContactListEmptyComponent,
  ],
  declarations: [
    SearchContactComponent,
    SearchContactListHeaderComponent,
    SearchContactListEmptyComponent,
  ],
})
export class SearchContactModule {}
