import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact } from "@app/models";
import { CONTACTS_COLOR } from "../../../utils/colors";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "contact-list-item",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./contact-list-item.component.scss",
  ],
  template: `
    <li class="list__item">
      <span class="task-type-name">
        <app-contact-name
          [contactId]="contact.contactId"
          [contactType]="contact.contactType"
        >
          {{ contact.firstName }} {{ contact.familyName }}&nbsp;
        </app-contact-name>
      </span>
      <span>{{ contact.email }}</span>
      <span>{{ getPhoneNumber() }}</span>
    </li>
  `,
})
export class ContactListItemComponent {
  @Input() contact: Contact;
  @Input() selected = false;

  color: string = CONTACTS_COLOR;
  icon = "icon-user";

  constructor(private phoneService: PhoneNumberService) {}

  getPhoneNumber() {
    const { msisdn, msisdnCountry } = this.contact;
    return this.phoneService.format(msisdn, msisdnCountry);
  }
}
