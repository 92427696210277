<div
  *ngIf="!enableMobileSelect; else mobileSelect"
  class="flex-1 multi-select"
  dropdown
  [autoClose]="false"
>
  <ng-container *ngIf="state === 'READY'; else disabledDropdown">
    <button
      [class.multi-select__dropdown__full-width]="fullWidth"
      [style.min-width]="minWidth"
      class="dropdown-toggle multi-select__dropdown"
      dropdownToggle
      translate
      type="button"
    >
      <div class="multi-select__dropdown__text-container">
        <span class="multi-select__dropdown__text-container__icon">
          <ng-content select="[placeholderIcon]"></ng-content>
        </span>
        <ng-content
          *ngIf="showPlaceholder(); else selectedItems"
          select="[placeholder]"
        ></ng-content>
        <ng-template #selectedItems>
          <ng-container *ngIf="!singleSelect; else selectedItem">
            <span
              *ngFor="
                let item of parentFormGroup.get(parentFormControlName).value;
                let index = index;
                let isLast = last
              "
            >
              <ng-container *ngIf="index < 4">
                {{
                  isOffice
                    ? (getDisplayName(item) | removeOfficePrefix | async)
                    : (getDisplayName(item) | translate)
                }}{{ index < 3 && !isLast ? "," : null }}
              </ng-container>
            </span>
          </ng-container>
        </ng-template>
        <ng-template #selectedItem>
          <span>
            {{
              isOffice
                ? (getDisplayName(
                    parentFormGroup.get(parentFormControlName).value
                  )
                  | removeOfficePrefix
                  | async)
                : getDisplayName(
                    parentFormGroup.get(parentFormControlName).value
                  )
            }}
          </span>
        </ng-template>
      </div>
      <app-icon name="angle-down"></app-icon>
    </button>
  </ng-container>

  <ng-template #disabledDropdown>
    <button
      [class.multi-select__dropdown__full-width]="fullWidth"
      [style.min-width]="minWidth"
      class="dropdown-toggle multi-select__dropdown disabled"
      disabled
      translate
      type="button"
    >
      <ng-container *ngIf="state !== 'LOADING'; else spinner">
        <div class="multi-select__dropdown__text-container">
          <span class="multi-select__dropdown__text-container__icon">
            <ng-content select="[placeholderIcon]"></ng-content>
          </span>
          <ng-container
            *ngIf="
              parentFormGroup.get(parentFormControlName).value?.length < 1;
              else labels
            "
            ><span translate>{{ placeholder }}</span></ng-container
          >
          <ng-template #labels>
            <ng-container *ngIf="!singleSelect; else singleLabel">
              <span
                *ngFor="
                  let item of parentFormGroup.get(parentFormControlName).value;
                  let index = index;
                  let isLast = last
                "
              >
                <ng-container *ngIf="index < 4">
                  {{
                    isOffice
                      ? (getDisplayName(item) | removeOfficePrefix | async)
                      : (getDisplayName(item) | translate)
                  }}{{ index < 3 && !isLast ? "," : null }}
                </ng-container>
              </span>
            </ng-container>
            <ng-template #singleLabel>
              {{
                isOffice
                  ? (getDisplayName(
                      parentFormGroup.get(parentFormControlName).value
                    )
                    | removeOfficePrefix
                    | async)
                  : getDisplayName(
                      parentFormGroup.get(parentFormControlName).value
                    )
              }}
            </ng-template>
          </ng-template>
        </div>
        <app-icon name="angle-down"></app-icon>
      </ng-container>
    </button>
  </ng-template>

  <ng-template #spinner>
    <spinner [size]="'1rem'"></spinner>
  </ng-template>

  <ul
    [style.width]="fullWidth ? '100%' : null"
    [style.max-height]="maxHeight ? maxHeight : 'auto'"
    *dropdownMenu
    class="dropdown-menu"
    role="menu"
  >
    <div
      *ngIf="!!(regionFeatureEnabled$ | async) && enableRegions && isOffice"
      class="region-switch"
    >
      <div class="btn-group">
        <button
          [class.active]="!regionsSelected"
          (click)="regionsSelected = false"
          class="btn btn-default"
          translate
        >
          offices
        </button>
        <button
          [class.active]="regionsSelected"
          (click)="regionsSelected = true"
          class="btn btn-default"
          translate
        >
          regions
        </button>
      </div>
    </div>

    <div
      *ngIf="enableInactiveBrokers && !isOffice"
      class="inactive-broker-switch"
    >
      <div class="btn-group">
        <button
          [class.active]="!inactiveBrokersSelected"
          (click)="
            inactiveBrokersSelected = false; useInactiveBrokers.emit(false)
          "
          class="btn btn-default"
          translate
        >
          active_plural
        </button>
        <button
          [tooltip]="'all_brokers_tooltip' | translate"
          placement="bottom"
          [class.active]="inactiveBrokersSelected"
          (click)="
            inactiveBrokersSelected = true; useInactiveBrokers.emit(true)
          "
          class="btn btn-default"
          translate
        >
          all
        </button>
      </div>
    </div>
    <ng-container *ngIf="!regionsSelected; else regionsList">
      <div *ngIf="enableSearch" class="search-bar">
        <input searchInput (input)="handleSearchInput($event)" type="text" />
        <span class="search-bar__icon">
          <app-icon name="search"></app-icon>
        </span>
      </div>
      <div
        class="reset-button reset-button__button"
        *ngIf="parentFormGroup.get(parentFormControlName).value?.length > 0"
        (click)="reset()"
      >
        <span translate>reset</span>
      </div>
      <li *ngFor="let item of localItems" role="menuitem">
        <button
          class="multi-select__button"
          [class.multi-select__button__active]="isActive(item[valueProperty])"
          (click)="
            isActive(item[valueProperty])
              ? removeValue(item[valueProperty])
              : addValue(item[valueProperty])
          "
          translate
        >
          {{
            isOffice
              ? (item[displayProperty] | removeOfficePrefix | async)
              : item[displayProperty]
          }}
          {{ secondaryDisplayProperty && item[secondaryDisplayProperty] }}
        </button>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #regionsList>
  <div
    class="reset-button reset-button__button"
    *ngIf="parentFormGroup.get(parentFormControlName).value?.length > 0"
    (click)="reset()"
  >
    <span translate>reset</span>
  </div>
  <li *ngFor="let region of regions$ | async" role="menuitem">
    <button
      class="multi-select__button"
      [class.multi-select__button__active]="isRegionActive(region)"
      (click)="regionClicked(region)"
      translate
    >
      {{ region.regionName }}
    </button>
  </li>
</ng-template>

<ng-template #mobileSelect>
  <button
    type="button"
    class="dropdown-toggle multi-select__dropdown"
    [style.min-width]="minWidth"
    [class.multi-select__dropdown__full-width]="fullWidth"
    (click)="modal.show()"
    translate
  >
    <div class="multi-select__dropdown__text-container">
      <span class="multi-select__dropdown__text-container__icon">
        <ng-content select="[mobilePlaceholderIcon]"></ng-content>
      </span>
      <ng-content
        *ngIf="showPlaceholder(); else selectedItems"
        select="[mobilePlaceholder]"
      ></ng-content>
      <ng-template #selectedItems>
        <ng-container *ngIf="!singleSelect; else selectedItem">
          <span
            *ngFor="
              let item of parentFormGroup.get(parentFormControlName).value;
              let index = index;
              let isLast = last
            "
          >
            <ng-container *ngIf="index < 4">
              {{
                isOffice
                  ? (getDisplayName(item) | removeOfficePrefix | async)
                  : getDisplayName(item)
              }}{{ index < 3 && !isLast ? "," : null }}
            </ng-container>
          </span>
        </ng-container>
      </ng-template>
      <ng-template #selectedItem>
        <span>
          {{
            isOffice
              ? (getDisplayName(
                  parentFormGroup.get(parentFormControlName).value
                )
                | removeOfficePrefix
                | async)
              : getDisplayName(parentFormGroup.get(parentFormControlName).value)
          }}
        </span>
      </ng-template>
    </div>
    <app-icon name="angle-down"></app-icon>
  </button>
</ng-template>

<div
  [config]="{ backdrop: false, class: 'modal-lg' }"
  class="select-modal modal fade"
  bsModal
  #modal="bs-modal"
  tabindex="-1"
>
  <div (click)="modal.hide()" class="mobile-nav__close">
    <app-icon name="times"></app-icon>
  </div>
  <div class="select-modal__header">
    <h1>{{ mobileSelectHeader | translate }}</h1>
  </div>

  <div class="reset-button reset-button__mobile">
    <div
      *ngIf="parentFormGroup.get(parentFormControlName).value?.length > 0"
      class="reset-button__button"
      (click)="reset()"
    >
      <span translate>reset</span>
    </div>
  </div>

  <ul class="list-group">
    <div
      *ngIf="!!(regionFeatureEnabled$ | async) && enableRegions && isOffice"
      class="region-switch"
    >
      <span
        [class.selected]="!regionsSelected"
        (click)="regionsSelected = false"
        translate
        >offices</span
      >&nbsp;
      <span
        [class.selected]="regionsSelected"
        (click)="regionsSelected = true"
        translate
        >regions</span
      >
    </div>

    <div *ngIf="enableInactiveBrokers && !isOffice" class="region-switch">
      <span
        [class.selected]="!inactiveBrokersSelected"
        (click)="
          inactiveBrokersSelected = false; useInactiveBrokers.emit(false)
        "
        translate
        >active_plural</span
      >&nbsp;
      <span
        [class.selected]="inactiveBrokersSelected"
        (click)="inactiveBrokersSelected = true; useInactiveBrokers.emit(true)"
        translate
        >all</span
      >
    </div>

    <ng-container *ngIf="!regionsSelected; else regionsListMobile">
      <li *ngIf="enableSearch">
        <div class="search-bar">
          <input searchInput (input)="handleSearchInput($event)" type="text" />
          <span class="search-bar__icon">
            <app-icon name="search"></app-icon>
          </span>
        </div>
      </li>
      <li *ngFor="let item of localItems" role="menuitem">
        <span
          (click)="
            isActive(item[valueProperty])
              ? removeValue(item[valueProperty])
              : addValue(item[valueProperty])
          "
          [ngClass]="isActive(item[valueProperty]) ? 'selected' : ''"
          translate
        >
          <i class="fa fa-check"></i>
          {{
            isOffice
              ? (item[displayProperty] | removeOfficePrefix | async)
              : item[displayProperty]
          }}
          {{ secondaryDisplayProperty ? item[secondaryDisplayProperty] : null }}
        </span>
      </li>
    </ng-container>
  </ul>
</div>

<ng-template #regionsListMobile>
  <li *ngFor="let region of regions$ | async" role="menuitem">
    <span
      [ngClass]="isRegionActive(region) ? 'selected' : ''"
      (click)="regionClicked(region)"
      translate
    >
      <i class="fa fa-check"></i>
      {{ region.regionName }}
    </span>
  </li>
</ng-template>
