<div class="wrapper">
  <sidebar-header
    [label]="'eika_send_tip' | translate"
    [type]="tabType"
  ></sidebar-header>
  <div class="scroll-view">
    <section>
      <card
        *ngIf="contact$ | async as contact"
        [color]="contactsColor"
        [removable]="false"
      >
        <div title>{{ contact?.firstName }} {{ contact?.familyName }}</div>
        <div body>
          <small>{{ contact?.email }}</small>
          <br />
          <small>{{
            contact?.msisdn | phoneFormat : contact?.msisdnCountry
          }}</small>
        </div>
      </card>
      <ng-container *ngIf="contact$ | async as contact">
        <div
          *ngIf="!contact?.msisdn"
          [formGroup]="form"
          class="alert alert-warning receivers__missing-info"
        >
          <span [translateParams]="{ name: contact?.firstName }" translate
            >validation_send_message_missing_mobile</span
          >
          <input-group-button
            (onClick)="onUpdateRecipientMobileNumber(contact, $event)"
            [formControlName]="'msisdn'"
            buttonText="save"
            buttonType="success"
            placeholder="mobile"
          ></input-group-button>
          <div
            *ngIf="!form.get('msisdn').valid && form.get('msisdn').touched"
            class="validation-message"
            translate
          >
            validation_mobile
          </div>
        </div>
      </ng-container>
    </section>
    <section [formGroup]="form">
      <ng-container *ngIf="!(object$ | async); else objectCard">
        <h4>
          {{ "select_residence" | translate }}
          <span class="text-lowercase">({{ "optional" | translate }})</span>
        </h4>
        <input
          #suggester
          (typeaheadOnSelect)="handleSelect($event)"
          [placeholder]="'street' | translate"
          [typeaheadItemTemplate]="customItemTemplate"
          [typeaheadMinLength]="0"
          [typeaheadOptionsLimit]="10"
          [typeaheadWaitMs]="200"
          [typeahead]="dataSource || []"
          formControlName="eaOid"
          typeaheadOptionField="street"
        />
        <small class="text-muted">{{
          "search_amongst_office_objects_for_sale" | translate
        }}</small>
      </ng-container>
      <ng-template #objectCard>
        <card
          *ngIf="object$ | async as object"
          [color]="showingsColor"
          [removable]="false"
        >
          <div title>
            {{ object.street }}
          </div>
          <div body>
            <small>{{ object.city }} {{ object.zip }}</small>
            <br />
            <small *ngIf="!object.rentMonth; else rent"
              >{{ object.price | qNumber }} {{ object.priceCode }}</small
            >
            <ng-template #rent>
              <small
                >{{ object.rentMonth }} {{ object.priceCode }}/{{
                  "month" | translate
                }}</small
              >
            </ng-template>
          </div>
        </card>
      </ng-template>
    </section>
    <app-send-tip-to-eika
      (newBankSelected)="getAdvisors($event)"
      (submitClick)="sendTip()"
      *ngIf="(banks$ | async).length > 0; else noBanks"
      [advisorsLoading]="advisorsLoading$ | async"
      [advisors]="advisors$ | async"
      [banksLoading]="banksLoading$ | async"
      [banks]="banks$ | async"
      [observer]="proxy$"
    >
    </app-send-tip-to-eika>
    <ng-template #noBanks>
      <section
        *ngIf="banksLoading$ | async; else noBanksConfigured"
        class="form-loading"
      >
        <spinner [size]="'4rem'"></spinner>
      </section>
      <ng-template #noBanksConfigured>
        <section>
          <div class="alert alert-danger" translate>no_banks_configured</div>
        </section>
      </ng-template>
    </ng-template>
  </div>
  <div class="sidebar-footer">
    <app-q-button buttonType="cancel" label="cancel" (click)="closeTab()">
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="send"
      [disabled]="
        (banks$ | async).length === 0 ||
        (sendingLoading$ | async) ||
        (contact$ | async)?.msisdn?.length === 0
      "
      [isLoading]="sendingLoading$ | async"
      (click)="submit()"
    >
    </app-q-button>
  </div>
</div>

<ng-template #customItemTemplate let-index="index" let-model="item">
  <h5 class="typeahead-responsible-broker">
    {{ model.street }}, {{ model.zip }}, {{ model.city }}
  </h5>
  <small *ngIf="!model.rentMonth; else modelRent" class="typeahead-meta">
    <span *ngIf="model.price > 0; else noResidencePrice">
      {{ model.price | qNumber }} {{ model.priceCode }}
    </span>
    <ng-template #noResidencePrice>{{
      "price_unknown" | translate
    }}</ng-template>
    |
  </small>
  <ng-template #modelRent>
    <small class="typeahead-meta">
      <span *ngIf="model.rentMonth > 0; else noRent">
        {{ model.rentMonth | qNumber }} {{ model.priceCode }}
      </span>
      <ng-template #noRent>{{ "rent_unknown" | translate }}</ng-template>
      |
    </small>
  </ng-template>
  <small *ngIf="!model.rentMonth" class="typeahead-meta">
    <span *ngIf="model.monthlyFee > 0; else noMonthFee">
      {{ model.monthlyFee | qNumber }} {{ model.priceCode }}/{{
        "month" | translate | lowercase
      }}
    </span>
    <ng-template #noMonthFee>{{ "fee_unknown" | translate }}</ng-template>
    |
  </small>
  <small>
    <span *ngIf="model.level; else noLevel">
      {{ "level" | translate }}: {{ model.level }}
    </span>
    <ng-template #noLevel>{{ "level_unknown" | translate }}</ng-template>
  </small>

  <br />
  <small *ngIf="model.responsibleEmployee; else noResponsibleEmployee">
    {{ "responsible_broker" | translate }}:
    {{ model.responsibleEmployee.employeeFullName }}
  </small>
  <ng-template #noResponsibleEmployee>
    <small>{{ "responsible_employee_missing" | translate }}</small>
  </ng-template>

  <ng-container>
    <br />
    <small
      *ngIf="model.sellers && model.sellers.length > 0; else noSeller"
      class="typeahead-meta"
    >
      {{ "seller" | translate }}:
      <span *ngFor="let seller of model.sellers; let i = index">
        {{ seller.firstName }} {{ seller.familyName }}
        <span *ngIf="model.sellers.length > i + 1">, </span>
      </span>
    </small>
    <ng-template #noSeller>
      <small>{{ "sellers_missing" | translate }}</small>
    </ng-template>
  </ng-container>
</ng-template>
