import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { FilterTemplate } from "@app/models/filter-template";
import { FormGroup } from "@angular/forms";
import { hardTrimBody } from "@app/core/services/api/api.service";
import { ShortcutsService } from "@app/widgets/widgets/shortcuts-widget/shortcuts-service";
import { Pages } from "@app/shared/config/models/shortcuts-feature";
import { map, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-filter-templates-manager",
  templateUrl: "./filter-templates-manager.component.html",
  styleUrls: ["./filter-templates-manager.component.scss"],
})
export class FilterTemplatesManagerComponent implements OnInit, OnDestroy {
  @Input() page: Pages;
  @Input() templatesKey: string;
  @Input() filterForm: FormGroup;
  @Input() preSelectedTemplate: string = null;
  @Output() selectedFilterTemplateChanged = new EventEmitter<
    Record<string, unknown>
  >();

  templateName: string;
  selectedFilter: FilterTemplate;
  filterTemplates: FilterTemplate[];
  unsubscribe$ = new Subject<void>();

  constructor(
    private localStorage: LocalStorageService,
    private shortcutsService: ShortcutsService
  ) {}

  ngOnInit() {
    if (!this.templatesKey && !this.page) return;
    this.fetchAllTemplates();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleSelectedFilterChange(key: string) {
    this.selectedFilter = this.filterTemplates.find(
      (template) => template.key === key
    );

    if (this.selectedFilter) {
      this.selectedFilterTemplateChanged.emit(this.selectedFilter.filter);
    }
  }

  saveFilterTemplate() {
    const filter = hardTrimBody(this.filterForm.getRawValue());
    this.filterTemplates.push({
      key: this.templateName,
      isPredefined: false,
      filter,
    });
    this.localStorage.saveItemToLocalStorage(
      this.templatesKey,
      this.filterTemplates.filter((template) => !template.isPredefined)
    );
    this.handleSelectedFilterChange(this.templateName);
    this.templateName = "";
  }

  deleteFilterTemplate(key: string) {
    this.filterTemplates = this.filterTemplates.filter(
      (template) => template.key !== key
    );

    if (!this.filterTemplates) {
      this.localStorage.removeItemFromLocalStorage(this.templatesKey);
    } else {
      this.localStorage.saveItemToLocalStorage(
        this.templatesKey,
        this.filterTemplates.filter((template) => !template.isPredefined)
      );
    }
  }

  private fetchAllTemplates() {
    this.shortcutsService
      .getFilters$(this.page)
      .pipe(
        map((filters) =>
          filters?.map(
            (filter) =>
              ({
                key: filter.tempId,
                isPredefined: true,
                filter: filter.params,
              } as FilterTemplate)
          )
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((predefinedFilters) => {
        const agentTemplate = this.localStorage.fetchAndDecodeFromLocalStorage(
          this.templatesKey
        );

        this.filterTemplates = [];

        if (agentTemplate) {
          this.filterTemplates.push(...agentTemplate);
        }
        if (predefinedFilters?.length > 0) {
          this.filterTemplates.push(...predefinedFilters);
        }

        if (this.preSelectedTemplate) {
          this.handleSelectedFilterChange(this.preSelectedTemplate);
        }
      });
  }
}
