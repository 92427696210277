import { Route } from "@angular/router";
import { SearchProfileAlertCreateGuard } from "@app/sidebar/guards/search-profile-alert-create-guard.service";
import { SearchProfileCreateGuard } from "@app/sidebar/guards/search-profile-create-guard.service";
import { SearchProfileEditGuard } from "@app/sidebar/guards/search-profile-edit-guard.service";
import { SearchProfileGuard } from "@app/sidebar/guards/search-profile-guard.service";
import { CreateSearchProfileAlertComponent } from "@app/sidebar/search-profile/create-search-profile-alert/create-search-profile-alert.component";
import { CreateSearchProfileComponent } from "@app/sidebar/search-profile/create-search-profile/create-search-profile.component";
import { EditSearchProfileComponent } from "@app/sidebar/search-profile/edit-search-profile/edit-search-profile.component";

export const sidebarSearchProfileRoutes: Route = {
  path: "search-profile",
  canActivate: [SearchProfileGuard],
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: CreateSearchProfileComponent,
      canActivate: [SearchProfileCreateGuard],
    },
    {
      path: ":id/edit",
      component: EditSearchProfileComponent,
      canActivate: [SearchProfileEditGuard],
    },
    {
      path: ":id/alert",
      component: CreateSearchProfileAlertComponent,
      canActivate: [SearchProfileAlertCreateGuard],
    },
  ],
};
