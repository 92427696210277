import { Directive, EventEmitter, Output } from "@angular/core";
import { SortEvent } from "@app/models";

export type SortDirection = "asc" | "desc";

export interface SortableTableColumn {
  /** The id of the column being sorted. */
  id: string;

  /** Starting sort direction. */
  start: SortDirection;
}

@Directive({
  selector: "[tableSort]",
})
export class TableSortDirective {
  active: string;
  start: "asc" | "desc" = "asc";
  direction: SortDirection;
  sortables = new Map<string, SortableTableColumn>();

  @Output() sortChange = new EventEmitter<SortEvent>();

  register(sortable: SortableTableColumn): void {
    if (!sortable.id) {
      throw new Error('property "id" is missing on type SortableTableColumn');
    }

    if (this.sortables.has(sortable.id)) {
      throw new Error(
        `SortableTableColumn with id ${sortable.id} already exists`
      );
    }

    this.sortables.set(sortable.id, sortable);
  }

  unregister(sortable: SortableTableColumn): void {
    this.sortables.delete(sortable.id);
  }

  sort(sortable: SortableTableColumn): void {
    if (this.active !== sortable.id) {
      this.active = sortable.id;
      this.direction = sortable.start ? sortable.start : this.start;
    } else {
      this.direction = this.direction === "asc" ? "desc" : "asc";
    }

    this.sortChange.emit({ sortBy: this.active, sortOrder: this.direction });
  }
}
