import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs";
import * as RouterActions from "./router.actions";

@Injectable()
export class RouterEffects {
  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.go),
        tap(({ path, query, extras }) => {
          const commands: any[] = Array.isArray(path) ? path : [path];
          const queryParams = {
            ...(query ?? {}),
            ...(extras ?? {}),
          };
          this.router.navigate(commands, {
            queryParams,
          });
        })
      ),
    { dispatch: false }
  );

  navigateByUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.goByUrl),
        tap(({ url }) => {
          const newUrl = url.replace("#", "");
          this.router.navigateByUrl("/" + newUrl);
        })
      ),
    { dispatch: false }
  );

  navigateBack$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.back),
        tap(() => this.location.back())
      ),
    { dispatch: false }
  );

  navigateForward$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RouterActions.forward),
        tap(() => this.location.forward())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private location: Location
  ) {}
}
