<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'create_external_tips' | translate"></sidebar-header>
  <external-tips-form
    *ngIf="dataForForm$ | async as data"
    [mode]="externalTipFormModes.CREATE_MODE"
    [canEditOffice]="data.canEditOffice"
    [userOfficeId]="data.preselectedOfficeId"
    [offices]="data.offices"
    [observer]="proxy$"
    (submitForm)="onSubmit()"
  ></external-tips-form>
  <div class="sidebar-footer">
    <button
      type="button"
      class="btn btn-cancel btn-lg"
      (click)="closeTab()"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      type="submit"
      class="btn btn-success btn-lg"
      (click)="externalTipsFormComponent.submit()"
    >
      {{ 'save' | translate }}
    </button>
  </div>
</div>
