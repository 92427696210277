import { Bid } from "@app/integrations/bidding/models/bid";
import { Employee } from "@app/models/employee";
import { ExternalId } from "@app/models/external-id";
import { Municipality } from "@app/models/municipality";
import { ObjectLink } from "@app/shared/utils/object-utils";
import {
  Buyer,
  PotentialBuyer,
  PotentialBuyerStatus,
  Seller,
} from "@app/showings/models";
import { Project } from "@app/showings/models/project";

export interface SellerEmployee {
  eaEmployeeId: string;
  employeeFullName: string;
  employeePhoto: string;
}

export interface ObjectFile {
  filePath: string;
  fileDescription: string;
  fileId: string;
  fileType: string;
  fileSize: number;
  category: string;
}

export class QObject {
  eaOid: string;
  status: string;
  objType: string | number;
  street: string;
  zip: string;
  city: string;
  noOfRooms: string;
  objectImage: string;
  eaOfficeId: string;
  eaEmployeeId: string;
  employeeFullName?: string;
  externalIds?: ExternalId[];
  bidStatus?: string;
  bids?: Bid[];
  api: string;
  built: string;
  balcony: string;
  elevator: string;
  sauna?: string;
  fireplace: string;
  area: string;
  rooms: string;
  level: string;
  priceCode?: string;
  currency: string;
  price: number;
  sold: boolean;
  soldPrice: number;
  monthlyFee: number;
  accessCode: string;
  propertyDescription?: string;
  contactId: string;
  municipality?: Municipality;
  officeId?: string;
  images?: { url: string }[];
  statusName?: string;
  objTypeName?: string;
  guid?: string;
  type?: string;
  updatedDate?: string;
  sellers?: Seller[];
  responsibleEmployee?: Partial<Employee>;
  isResidence?: boolean;
  employee?: Employee;
  isExistingHome?: boolean;
  project: Project;
  existingHome?: boolean;
  rentMonth?: number;
  rentYear?: number;
  links?: ObjectLink[];
  sellerEmployees: SellerEmployee[];
  salesMeetingId?: string;
  salesMeetingContactId?: string;
  salesMeetingSource?: string;
  // Response from backend is a string. It should be a boolean.
  isNewProduction?: "False" | "True";
  dateType1?: string;
  dateType2?: string;
  dateType3?: string;
  files?: ObjectFile[];
  estateNonPublicFiles?: ObjectFile[];
  dates: {
    date: string;
    typeId: string;
    typeName: string;
  }[];
  projectDTO?: {
    eaOid: string;
    projectName: string;
  };
  contacts?: PotentialBuyer[];
  employees?: Employee[];
  apartmentNumber?: string;
  buyers?: Buyer[];
  owners?: Seller[];
  tenants?: Seller[];
  potentialBuyerStatuses?: PotentialBuyerStatus;
  insertedDate?: string;
  publishOnNet?: string;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
