import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { PageView } from "@app/models/page-view";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { OBJECT_OVERVIEW } from "@app/shared/config/utils/features";
import { getObjectStatusColor } from "@app/shared/utils/object-utils";
import { fetchLatestObjectsRequest } from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.actions";
import {
  getLatestObjects,
  getLoading,
} from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-latest-objects-widget",
  templateUrl: "./latest-objects-widget.component.html",
  styleUrls: ["./latest-objects-widget.component.scss"],
})
export class LatestObjectsWidgetComponent implements OnInit {
  @Input() openLinkInNewTab = false;
  objects$: Observable<PageView[]>;
  loading$: Observable<boolean>;
  navigationEndpoint$: Observable<"potential-buyers" | "overview">;

  getObjectStatusColor = getObjectStatusColor;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchObjects();
  }

  private fetchObjects() {
    this.store.dispatch(fetchLatestObjectsRequest());
  }

  private mapStateToProps() {
    this.objects$ = this.store.pipe(select(getLatestObjects));
    this.loading$ = this.store.pipe(select(getLoading));
    this.navigationEndpoint$ = this.store.pipe(
      select(getFeature(OBJECT_OVERVIEW)),
      map((feature: Feature) =>
        !!feature.enabled ? "overview" : "potential-buyers"
      )
    );
  }
}
