<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'add_broker' | translate"></sidebar-header>
  <div class="scroll-view">
    <section>
      <search-employee-shared-card
        [deletable]="true"
        [employee]="employee$ | async"
        (delete)="onClose()"
      ></search-employee-shared-card>
    </section>
    <hr>
    <section>
      <div class="form-group">
        <select [formControl]="officeControl">
          <option
            *ngIf="!(eaOfficeId$ | async)"
            disabled
            value=""
            selected
          >{{ 'select_office' | translate }}
          </option>
          <option
            *ngFor="let office of offices$ | async"
            [ngValue]="office.eaOfficeId"
            [disabled]="isDisabled$(office.eaOfficeId) | async">
            {{ office.name | removeOfficePrefix | async}}
          </option>
        </select>
      </div>
      <employees-office-form #formComp [type]="tabType"></employees-office-form>
    </section>
  </div>
  <div class="sidebar-footer">
    <button
      tabindex="-1"
      type="button"
      class="btn btn-cancel btn-lg"
      (click)="closeTab()"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      translate
      type="button"
      class="btn btn-success btn-lg"
      (click)="onSubmit(formComp.form)"
    >save
    </button>
  </div>
</div>
