import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { SearchEmployeeModule } from "@app/shared/modules/search-employee/search-employee.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { EmployeesCreateComponent } from "./employees-create/employees-create.component";
import { EmployeesOfficeCreateComponent } from "./employees-office-create/employees-office-create.component";
import { EmployeesOfficeFormComponent } from "./employees-office-create/employees-office-form/employees-office-form.component";
import { EmployeesSearchComponent } from "./employees-search/employees-search.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    SidebarSharedModule,
    SearchEmployeeModule,
    UtilModule,
  ],
  declarations: [
    EmployeesSearchComponent,
    EmployeesCreateComponent,
    EmployeesOfficeCreateComponent,
    EmployeesOfficeFormComponent,
  ],
})
export class EmployeesModule {}
