import { Feature } from "@app/shared/config/models/feature";
import { ConfigState } from "@app/shared/config/models/config-state";
import { ModalsConfigDefaults } from "@app/shared/config/models/modals";
import { DataDisplayView } from "@app/statistics/statistics-kpi/utils/statistics-kpi-view-types";
import {
  NpsConfigDefaults,
  NpsListConfigDefaults,
} from "@app/shared/config/models/nps-config";
import { Widgets } from "@app/erp/utils/widgets";
import {
  fetchDashboardDefaultWidgetConfig,
  fetchStatisticsDefaultWidgetConfig,
} from "@app/shared/config/models/widgets-page-config";
import { GoogleAnalyticsConfigDefaults } from "@app/shared/config/models/google-analytics";
import { ContactTypesDefaultConfig } from "@app/shared/config/models/create_contact_config";
import { ContactCardActionsConfigDefaults } from "@app/shared/config/models/contact-card-actions";

export const featureInitialState: Feature = {
  enabled: false,
};

export const initialState: ConfigState = {
  proxyVerified: false,
  loaded: null,
  runtime: {
    setting_ids: {},
  },
  app: {
    general: {
      customer_name: "",
      time_zone: "",
      phone_prefix: null,
      office_name_prefix: "",
      object_location_url: "",
      object_preview_location_url: "",
      realforce_data_logo_url: "/assets/img/realforce_squircle_symbol.svg",
      object_location_external_id_provider: "",
      object_location_external_id_category: "",
      object_location_use_eaoid_as_external_id: false,
      language: "sv",
      language_modification_layers: "",
      country: "se",
      currency: "sek",
      branding: {},
      send_module_id: null,
      office_email_confirmation_template_id: null,
      employee_email_confirmation_template_id: null,
      tab_title: "CRM",
      contactform_template_name: "",
      message_template_id: "",
      integrity_policy_url: "",
      everyone_can_assign_tasks_and_leads: false,
      modals: ModalsConfigDefaults,
      display_phone_number_on_login: false,
      super_search_template: "crm3",
      object_type_ids: "1,2,3,4,5,8,12,13,99",
      use_seller_agent: false,
      use_showing_agent: false,
      send_message_object_links_show_sold_objects: true,
      send_message_object_links_show_chain_objects: false,
      default_mobile_search_category: "contacts",
      deswinglisher_url:
        "https://deswinglisher.quedro.com/admin/#/keyTranslationWidget",
      consent_valid_date: 30,
      google_map_dataset_id: null,
    },
    features: {
      employee_descriptions: {
        enabled: false,
      },
      value_monitor: {
        enabled: false,
        contactMethods: [],
      },
      sales_focus_areas: {
        enabled: false,
        only_has_office_areas: false,
        optimal_service_enabled: false,
        extra_setting: [
          { label: "reserved", type: "checkbox" },
          { label: "color", type: "color", defaultValue: "#F18700" },
        ],
      },
      contact_source: featureInitialState,
      eniro: featureInitialState,
      address_suggester: featureInitialState,
      address_validation: featureInitialState,
      send_tips: {
        ...featureInitialState,
        taskTypeId: null,
      },
      create_follow_up: featureInitialState,
      consent: {
        enabled: true,
        showingActivityExpiration: 6,
      },
      sales_meeting: {
        ...featureInitialState,
        batch_setting_id: null,
        report_result_id: null,
        follow_up_result_id: null,
        redirect_to_meeting_after_creation: false,
        force_full_address_for_sales_meeting: false,
        valuation_follow_up_time: "6_month",
        waiting_follow_up_time: "7_day",
        winning_follow_up_time: "",
      },
      kpi: {
        ...featureInitialState,
        kpis: null,
        kpis_statistics: [],
        average_filter_enabled: false,
        date_filter_type: "one_year",
        default_display_type: DataDisplayView.Graph,
      },
      outgoing_mails: featureInitialState,
      exchange_integration: {
        enabled: false,
        help_text: null,
      },
      send_message: {
        enabled: true,
        enableFileAttachments: false,
        enableObjectLinks: true,
      },
      showing_confirmation_email: {
        enabled: false,
        templateId: "17",
        externalId: "17",
      },
      competitions: featureInitialState,
      external_tips: {
        enabled: false,
        templateId: null,
        taskTypeId: null,
        taskResultId: null,
      },
      matchings: {
        enabled: false,
        templateIdPlain: null,
        templateIdWithObject: null,
      },
      external_provider: {
        enabled: false,
        name: null,
        url: null,
        show_connect_to_object_in_erp_button: true,
        show_convert_unknown_to_zero_bidder_button: false,
      },
      bidding: {
        enabled: false,
        hideBidTranslationKey: "",
      },
      potential_buyers: {
        enabled: false,
        sortPotentialBuyerList: "insertedDate",
        showContactNumber: false,
        useExperimentalPotentialBuyerApi: false,
        mobilePhoneIsRequired: false,
      },
      search_profile: {
        enabled: false,
        categoryIds: "",
        templateId: "",
        settingGroupId: "",
        forceAlertCreation: false,
        showWaitingSalesMeetings: false,
        filterRootNode: "",
        enableSendAlertToAgent: false,
        sendAlertToDefaultValue: "contact",
        form_search_options: {
          area: { enabled: true },
          rooms: { enabled: true },
          bedrooms: { enabled: true },
          price: { enabled: true },
          fee: { enabled: true },
          lot_area: { enabled: true },
          built_year: { enabled: true },
          lot_status: { enabled: false },
        },
      },
      online_booking: {
        enabled: false,
      },
      register_view: {
        enabled: false,
        show_consent_checkbox: true,
        show_finance_checkbox: false,
        show_bid_update_checkbox: false,
        make_email_required_instead_of_mobile: false,
        show_quedro_search_results: false,
        show_consent_text: false,
        consent_text: "",
        bid_status: "unknown",
        send_bankTips: {
          enabled: false,
          tip_note: "",
        },
        origin: "crm",
      },
      create_lead: {
        enabled: true,
        can_create_lead: {
          admin: true,
          manager: true,
          broker: false,
        },
      },
      nps: NpsConfigDefaults,
      nps_list: NpsListConfigDefaults,
      support: {
        enabled: true,
        supportMail: "",
        supportTel: "",
        supportTelCountry: "",
        sendMessageEnabled: false,
        templateId: "",
        helpVideoUrl: "",
        noOfHelpVideos: "",
        documentLink: "",
      },
      miles: {
        enabled: false,
      },
      draw_search_profile_area: {
        enabled: false,
        custom_filter_root: null,
        countryIso: null,
        eaGeoCustomFilterCategoryId: null,
        eaGeoCustomFilterAreaCategoryId: null,
      },
      create_private_showing: {
        enabled: true,
      },
      private_notes: {
        enabled: false,
      },
      sales_targets: {
        edit_own_sales_targets_enabled: false,
        sales_targets: [
          {
            type: "bookedSalesMeeting",
            corresponding_kpi_type: "booked_sales_meeting",
            enabled: true,
            displayOrder: 1,
          },
          {
            type: "wonSalesMeeting",
            corresponding_kpi_type: "won_salesmeeting",
            enabled: true,
            displayOrder: 2,
          },
          {
            type: "madeSalesCalls",
            corresponding_kpi_type: "made_sales_calls",
            enabled: true,
            displayOrder: 3,
          },
          {
            type: "soldObjects",
            corresponding_kpi_type: "sold_objects",
            enabled: true,
            displayOrder: 4,
          },
          {
            type: "commissionsEarnings",
            corresponding_kpi_type: "commissions_earnings",
            enabled: true,
            displayOrder: 5,
          },
          {
            type: "bankTips",
            corresponding_kpi_type: "banktips",
            enabled: false,
            displayOrder: 6,
          },
          {
            type: "earnings",
            corresponding_kpi_type: "earnings",
            enabled: false,
            displayOrder: 7,
          },
          {
            type: "estateCommission",
            corresponding_kpi_type: "estate_commission",
            enabled: false,
            displayOrder: 8,
          },
          {
            type: "followedUpPotentialBuyers",
            corresponding_kpi_type: "followed_up_potential_buyers",
            enabled: false,
            displayOrder: 9,
          },
          {
            type: "npsScore",
            corresponding_kpi_type: "nps_score",
            enabled: false,
            displayOrder: 10,
          },
          {
            type: "privateShowings",
            corresponding_kpi_type: "privateShowings",
            enabled: false,
            displayOrder: 11,
          },
          {
            type: "publicShowings",
            corresponding_kpi_type: "publicShowings",
            enabled: false,
            displayOrder: 12,
          },
          {
            type: "showings",
            corresponding_kpi_type: "showings",
            enabled: false,
            displayOrder: 13,
          },
          {
            type: "soonForSaleObjects",
            corresponding_kpi_type: "soonforsaleobjects",
            enabled: false,
            displayOrder: 14,
          },
          {
            type: "soonForSaleObjectsNew",
            corresponding_kpi_type: "soonforsaleobjectsnew",
            enabled: false,
            displayOrder: 15,
          },
        ],
      },
      bank_settings: {
        enabled: false,
      },
      send_tips_eika: {
        enabled: false,
      },
      office_management_email: {
        enabled: false,
      },
      top_lists: {
        enabled: false,
        listExpandEnabled: false,
        globalViewEnabled: true,
        topListTypes: [],
      },
      growth_reports: {
        enabled: false,
        customColumnSorting: [],
        returnType: "all",
        sortBy: "turnover",
        budgetFilterEnabled: false,
        budgetTypeFilterEnabled: false,
        contractTypeFilterEnabled: false,
        employeeTagSuggestionFilterEnabled: false,
        newProductionFilterEnabled: false,
        objectTypeFilterEnabled: false,
      },
      product_news: {
        enabled: false,
      },
      tags: {
        enabled: false,
      },
      weather: {
        enabled: false,
      },
      support_widget: {
        enabled: false,
      },
      budget: {
        enabled: false,
      },
      budget_turnover: {
        enabled: false,
      },
      invoicing: {
        enabled: false,
        onlyVisibleForAdmin: false,
        objectIdCategory: "",
      },
      object_overview: {
        enabled: false,
      },
      create_object: {
        enabled: false,
      },
      create_object_video: {
        enabled: false,
      },
      advanced_objects_list: {
        enabled: false,
        filterOptions: {
          contractTypes: [],
          dateTypeIds: [],
          objectStatuses: [],
          employeeAvailabilityMode: "medium",
          includeRentalFilter: false,
          foreignGeoData: false,
        },
        defaultFilterOptions: {
          objectStatuses: [],
        },
        defaultSorting: {
          sortBy: "price",
          sortOrder: "desc",
        },
      },
      widget_dashboard: {
        enabled: false,
      },
      advanced_leads_handing: {
        enabled: false,
      },
      regions: {
        enabled: false,
      },
      erp: {
        enabled: false,
        overview_widgets: [
          Widgets.PROPERTY_DETAILS,
          Widgets.DEAL,
          Widgets.FILES,
          Widgets.MARKETING,
          Widgets.POTENTIAL_BUYERS,
        ],
      },
      transform_unknown_to_zero_bidders: {
        enabled: false,
      },
      tailored_campaigns: {
        enabled: false,
        baseUrl: null,
      },
      lead_automation: {
        enabled: false,
        allowLeadChannelExclusion: false,
      },
      adfenix_property_marketing: {
        enabled: false,
        campaignGroup: "",
      },
      open_in_erp: {
        enabled: false,
      },
      open_buyer_or_seller_iframe: {
        enabled: false,
      },
      download_object_images: {
        enabled: false,
      },
      statistics_office_filter: {
        enabled: false,
        filterOptions: [
          {
            display: "leads",
            enabled: false,
          },
          {
            display: "sales-meeting",
            enabled: false,
          },
          {
            display: "kpi",
            enabled: false,
          },
          {
            display: "nps",
            enabled: false,
          },
          {
            display: "growth",
            enabled: false,
          },
          {
            display: "value-monitor",
            enabled: false,
          },
          {
            display: "marketing",
            enabled: false,
          },
          {
            display: "budget",
            enabled: false,
          },
        ],
      },
      showings_comment: {
        enabled: false,
      },
      statistics_marketing_reports: {
        enabled: false,
        can_filter_object_type: {
          admin: false,
          broker: false,
          manager: false,
        },
      },
      teams: {
        enabled: false,
      },
      consent_alarm_level: {
        enabled: false,
      },
      shortcuts: {
        enabled: false,
        items: [],
      },
      assistant_permissions: {
        enabled: false,
        permissions: [],
      },
      third_party_widget: {
        enabled: false,
        icon: "browsers",
        title: "third_party_widget",
        url: "https://www.realforce.com",
      },
    },
    tasks: {
      task_type_followup: null,
      followup_buyer_30_days: null,
      followup_seller_30_days: null,
      priceupdater_sold_properties: null,
      priceupdater_evaluated_properties: null,
      salesmeeting_report: null,
      salesmeeting_followup: null,
      contact_request: null,
      mitt_bolan: null,
      blixtvarderingar: null,
      vardebarometer: null,
      private_showings_follow_up: null,
      final_price: null,
      vardekollen: null,
      calling_list: null,
      potential_buyer: null,
      taskIcons: [],
    },
    pages: [],
    unsupported_browsers: [],
    profile_progress: {
      firstName: true,
      familyName: true,
      email: true,
      mobile: true,
      address: true,
      residence: true,
    },
    sidebar_processes: null,
    statistics: {
      favorites: [],
      types: {},
      general: {
        sales_contracts_in_leads: false,
      },
      widgets: {
        start_page: fetchStatisticsDefaultWidgetConfig(),
      },
    },
    dashboard_widgets: fetchDashboardDefaultWidgetConfig(),
    google_analytics: GoogleAnalyticsConfigDefaults,
    contact: {
      create_contact_config: {
        contact_types: ContactTypesDefaultConfig,
      },
      contact_card_actions: ContactCardActionsConfigDefaults,
      contact_list_config: {
        contact_types: ["buyer", "seller", "potentialbuyer", "follower"],
      },
    },
  },
};
