import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-q-input-thousand-separator",
  templateUrl: "./q-input-thousand-separator.component.html",
  styleUrls: [
    "../q-input/q-input.component.scss",
    "./q-input-thousand-separator.component.scss",
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QInputThousandSeparatorComponent),
    },
  ],
})
export class QInputThousandSeparatorComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder = "";
  @Input() invalid: boolean;

  private _value: string | number = "";
  touched = false;
  disabled = false;

  get value() {
    return this._value;
  }

  @Input() set value(text: string | number) {
    this._value = text;
  }

  private onTouched!: Function;
  private onChange!: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onTextChange(text: string | number) {
    this.markAsTouched();
    if (!this.disabled) {
      this.onChange(text);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
