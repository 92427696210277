import { Region } from "@app/models/region";
import { createAction, props } from "@ngrx/store";

export const updateRegionRequest = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_REQUEST",
  props<{ eaRegionId: string; params: Partial<Region> }>()
);

export const updateRegionSuccess = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_SUCCESS"
);

export const updateRegionFail = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_FAIL"
);

export const updateRegionOfficesRequest = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_OFFICES_REQUEST",
  props<{
    eaRegionId: string;
    eaOfficesIdsToAdd?: string[];
    eaOfficesIdsToRemove?: string[];
  }>()
);

export const updateRegionOfficesSuccess = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_OFFICES_SUCCESS"
);

export const updateRegionOfficesFail = createAction(
  "[MANAGE REGIONS] UPDATE_REGION_OFFICES_FAIL"
);

export const createRegionRequest = createAction(
  "[MANAGE REGIONS] CREATE_REGION_REQUEST",
  props<{ region: Partial<Region> }>()
);

export const createRegionSuccess = createAction(
  "[MANAGE REGIONS] CREATE_REGION_SUCCESS"
);

export const createRegionFail = createAction(
  "[MANAGE REGIONS] CREATE_REGION_FAIL"
);

export const deleteRegionRequest = createAction(
  "[MANAGE REGIONS] DELETE_REGION_REQUEST",
  props<{ eaRegionId: string }>()
);

export const deleteRegionSuccess = createAction(
  "[MANAGE REGIONS] DELETE_REGION_SUCCESS"
);

export const deleteRegionFail = createAction(
  "[MANAGE REGIONS] DELETE_REGION_FAIL"
);
