import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { AssignLeadService } from "@app/core/components/assign-lead-modal/assign-lead.service";
import { setIsLead } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import { getIsClosed } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { EmployeeService } from "@app/core/ngrx/entity-services/employee.service";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";
import { SalesMeetingService } from "@app/core/ngrx/entity-services/sales-meeting.service";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact, Employee, SalesMeeting, Task, TaskResult } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { ADVANCED_LEADS_HANDLING } from "@app/shared/config/utils/features";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import * as fromUser from "@app/shared/user";
import { isManagerOrAdmin, UserIds } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { SIDEBAR_FOLLOW_UPS_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { TASK } from "@app/shared/utils/tab-types";
import { SalesMeetingService as SidebarSalesMeetingService } from "@app/sidebar/sales-meeting/sales-meeting.service";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { TaskActionLog } from "@app/sidebar/tasks/ngrx/tasks.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  skip,
  Subject,
} from "rxjs";

@Component({
  selector: "app-task-default-new",
  templateUrl: "./task-default-new.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./task-default-new.component.scss",
  ],
})
export class TaskDefaultNewComponent implements OnInit, OnDestroy {
  @Input() task: Task;

  editAssignee: "broker" | "office" | null = null;
  form: FormGroup;
  unsubscribe$ = new Subject<void>();
  activeControl$ = new BehaviorSubject("assign");
  controls: SegmentControls = [
    { label: "assign", value: "assign" },
    { label: "manage", value: "manage" },
  ];
  tabType = TASK;
  userIds$: Observable<UserIds>;
  taskActionLog$: BehaviorSubject<TaskActionLog[]> = new BehaviorSubject<
    TaskActionLog[]
  >([]);
  taskResults$: BehaviorSubject<TaskResult[]> = new BehaviorSubject<
    TaskResult[]
  >([]);
  taskResultOptions$: BehaviorSubject<Select> = new BehaviorSubject<Select>([]);
  everyoneCanAssign$: Observable<boolean>;
  isManagerOrAdmin$: Observable<boolean>;
  contact$: Observable<Contact>;
  advancedLeadsHandlingFeature$: Observable<Feature>;
  eaEmployeeId$: Observable<string>;
  isAssignLeadModalClosed$: Observable<boolean>;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private taskService: TaskService,
    private officeService: OfficeService,
    private employeeService: EmployeeService,
    private assignLeadService: AssignLeadService,
    private salesMeetingService: SalesMeetingService,
    private sidebarSalesMeetingService: SidebarSalesMeetingService,
    private sidebarService: SidebarService,
    private contactService: ContactService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.mapStateToProps();
    this.setActiveControl();
    if (!!this.task) {
      this.taskService
        .getActionLogById(this.task.eaTaskId)
        .subscribe((log) => this.taskActionLog$.next(log));

      setTimeout(() => this.fillForm(), 1000);

      this.taskResults$.next([...this.task.taskResults]);
      this.taskResultOptions$.next(
        this.task.taskResults.map((res) => ({
          label: res.resultName,
          value: res.eaTaskResultId,
        }))
      );

      this.store.dispatch(
        setIsLead({ isLead: this.task.eaTaskTypeCategory === "lead" })
      );
    }

    this.isAssignLeadModalClosed$
      .pipe(
        skip(1),
        filter((isClosed) => !!isClosed),
        first()
      )
      .subscribe(() => {
        this.store.dispatch(closeTab({ tabType: this.tabType }));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOfficeId: [null],
      eaEmployeeId: [null],
      sendMessage: [true],
      message: [null],
      taskResult: [null],
      transferComment: [null],
    });
  }

  private fillForm() {
    const patchValue = {
      eaOfficeId: this.task.eaOfficeId,
      eaEmployeeId: this.task.eaEmployeeId,
      ...this.form.value,
    };
    this.form.patchValue(patchValue);
  }

  submit(assignToMe = false) {
    if (this.activeControl$.value === "assign") {
      if (!!assignToMe) {
        this.userIds$.pipe(first()).subscribe((ids) => {
          this.assignTask({ ...ids, sendMessage: false }, assignToMe);
        });
      } else {
        this.assignTask(this.form.value);
      }
    } else {
      this.manageTask();
    }
  }

  handleLead(assignee: "broker" | "office" | null) {
    this.editAssignee = assignee;

    if (assignee === null) {
      this.submit(true);
      return;
    }
  }

  private assignTask(
    data: {
      eaOfficeId?;
      eaEmployeeId?;
      sendMessage?;
      message?;
      transferComment?;
    },
    assignToMe = false
  ) {
    const { eaOfficeId, eaEmployeeId, sendMessage, message, transferComment } =
      data;
    const params = {
      eaOfficeId: eaOfficeId,
      eaEmployeeId: "",
      transferComment: transferComment,
      sendMessage: sendMessage,
      message: !!sendMessage ? message : "",
      transfer: true,
    };

    if (this.editAssignee === "broker" || !this.editAssignee) {
      this.assignToBroker(params, eaEmployeeId, assignToMe);
    } else {
      this.assignToOffice(eaOfficeId, params);
    }
  }

  assignedToMe() {
    this.activeControl$.next("manage");
  }

  private assignToOffice(
    eaOfficeId: string,
    params: {
      transfer: boolean;
      eaEmployeeId: string;
      eaOfficeId: any;
      sendMessage: any;
      transferComment: any;
      message: any;
    }
  ) {
    combineLatest([
      this.officeService.entityMap$.pipe(
        first(),
        map((eMap) => eMap[eaOfficeId])
      ),
    ])
      .pipe(
        first(),
        map(([office]) => {
          this.assignLeadService.handleAssign(
            [this.task],
            false,
            params,
            null,
            office
          );
        })
      )
      .subscribe(() =>
        this.store.dispatch(closeTab({ tabType: this.tabType }))
      );
  }

  private assignToBroker(
    params: {
      transfer: boolean;
      eaEmployeeId: string;
      eaOfficeId: any;
      sendMessage: any;
      transferComment: any;
      message: any;
    },
    eaEmployeeId: string,
    assignToMe: boolean
  ) {
    params.eaEmployeeId = eaEmployeeId;
    this.employeeService
      .getById(eaEmployeeId)
      .pipe(
        first(),
        map((employee: Employee) => {
          this.assignLeadService.handleAssign(
            [this.task],
            false,
            params,
            employee,
            null
          );
        })
      )
      .subscribe(() => {
        if (assignToMe) {
          this.assignedToMe();
        } else {
          this.store.dispatch(closeTab({ tabType: this.tabType }));
        }
      });
  }

  delete(event: { id: string; reason: string; taskType: string }) {
    this.taskService
      .remove(event.id, { reason: event.reason })
      .subscribe(() =>
        this.store.dispatch(closeTab({ tabType: this.tabType }))
      );
  }

  private manageTask() {
    const { taskResult } = this.form.value;
    const params = {
      eaTaskId: this.task.eaTaskId,
      eaTaskResultId: taskResult !== "" ? taskResult : "0",
    };
    this.taskService
      .postTaskAttempt(this.task.eaTaskId, params)
      .subscribe(() => {
        this.store.dispatch(closeTab({ tabType: this.tabType }));

        // If chosen taskResult has eaWorkFlowId = "2", then fetch sales meetings for task.contactId.
        let selectedTaskResult: TaskResult = null;

        if (this.task?.taskResults?.length > 0) {
          selectedTaskResult = this.task.taskResults.find(
            (tr) => tr.eaTaskResultId == taskResult
          );
        } else {
          selectedTaskResult = this.taskResults$.value.find(
            (tr) => tr.eaTaskResultId == taskResult
          );
        }

        if (selectedTaskResult.eaWorkFlowId === "2") {
          this.openSalesMeeting();
        } else if (selectedTaskResult.eaWorkFlowId === "4") {
          this.createFollowUp();
        }
      });
  }

  private mapStateToProps() {
    this.userIds$ = this.store.pipe(fromUser.getEaIds);
    this.isManagerOrAdmin$ = this.store.pipe(select(isManagerOrAdmin));
    this.everyoneCanAssign$ = this.store.pipe(
      select(fromConfig.getEveryoneCanAssignTasksAndLeads)
    );
    this.contact$ = this.contactService.getById(this.task.contactId);
    this.advancedLeadsHandlingFeature$ = this.store.pipe(
      select(getFeature(ADVANCED_LEADS_HANDLING))
    );
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.isAssignLeadModalClosed$ = this.store.pipe(select(getIsClosed));
  }

  private setActiveControl() {
    combineLatest([this.userIds$])
      .pipe(first())
      .subscribe(([userIds]) => {
        if (
          !!this.task?.eaEmployeeId &&
          this.task.eaEmployeeId === userIds.eaEmployeeId
        ) {
          this.activeControl$.next("manage");
        }
      });
  }

  private createFollowUp(): void {
    const navigationAction = RouterActions.go({
      path: [
        "/crm",
        {
          outlets: {
            sidebar: SIDEBAR_FOLLOW_UPS_CREATE_URL(this.task.contactId),
          },
        },
      ],
    });
    this.store.dispatch(navigationAction);
  }

  private openSalesMeeting() {
    this.salesMeetingService
      .fetchWithQuery(
        {
          contactId: this.task.contactId,
          insertedFrom: moment()
            .subtract(2, "days")
            .startOf("day")
            .format(API_DATE_FORMAT),
        },
        false
      )
      .pipe(
        first(),
        filter((meetings: SalesMeeting[]) => meetings.length === 0)
      )
      .subscribe(() => {
        this.sidebarSalesMeetingService.hasLead$.next(true);
        this.sidebarService.openMultipleTabs(["sales_meeting"], {
          primaryOutlet: ["contacts", this.task.contactId],
          routeParams: { contactId: this.task.contactId },
        });
      });
  }
}
