import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { AssignLeadService } from "@app/core/components/assign-lead-modal/assign-lead.service";
import { toggle } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import * as fromAssignLeadModal from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.reducer";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { Task } from "@app/models";
import { isManagerOrAdmin } from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map, Observable } from "rxjs";
import { AssignLeadToOfficeModalComponent } from "@app/core/components/assign-lead-modal/containers/assign-lead-to-office-modal/assign-lead-to-office-modal.component";
import { AssignLeadModalComponent } from "@app/core/components/assign-lead-modal/containers/assign-lead-modal/assign-lead-modal.component";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";

@Component({
  selector: "app-assign-lead-buttons",
  templateUrl: "./assign-lead-buttons.component.html",
  styleUrls: ["./assign-lead-buttons.component.scss"],
})
export class AssignLeadButtonsComponent implements OnInit {
  @Input() selectedEaTaskIds: Task[];
  @Input() eaEmployeeId: string;
  @Output() closeHandler = new EventEmitter<void>();

  isManagerOrAdmin$: Observable<boolean>;
  isLead$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private modalService: QModalService,
    private assignLeadService: AssignLeadService,
    public taskService: TaskService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  openAssignLeadModal() {
    this.store.dispatch(
      toggle({
        parameters: { showEmployee: true, leadIds: this.selectedEaTaskIds },
      })
    );
    this.modalService.show(AssignLeadModalComponent);
  }

  openAssignLeadToOfficeModal() {
    this.store.dispatch(
      toggle({
        parameters: { showOffice: true, leadIds: this.selectedEaTaskIds },
      })
    );
    this.modalService.show(AssignLeadToOfficeModalComponent);
  }

  assignToSelf(): void {
    const params = {
      eaEmployeeId: this.eaEmployeeId,
      sendMessage: false,
    };

    combineLatest([this.isLead$])
      .pipe(
        first(),
        map(([isLead]) => {
          this.assignLeadService.handleAssign(
            this.selectedEaTaskIds,
            isLead,
            params,
            null,
            null
          );
        })
      )
      .subscribe(() => {
        this.taskService.refreshFetch$.next();
        this.closeHandler.emit();
      });
  }

  private mapStateToProps() {
    this.isManagerOrAdmin$ = this.store.pipe(select(isManagerOrAdmin));
    this.isLead$ = this.store.pipe(select(fromAssignLeadModal.getIsLead));
  }
}
