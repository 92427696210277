import { OverdueTaskListState, TaskListState } from "@app/models";
import { combineReducers } from "@ngrx/store";
import { followUpSalesMeetingListReducer } from "./more-opportunities/followup-sales-meeting-list/followup-sales-meeting-list.reducer";
import {
  segmentationListReducer,
  SegmentationListState,
} from "./more-opportunities/segmentation-list/segmentation-list.reducer";
import {
  outgoingMailReducer,
  OutgoingMailState,
} from "./outgoing-mails/outgoing-mail.reducer";
import { freeLeadListReducer } from "./todo-list/free-leads-list/free-leads-list.reducer";
import { overdueTaskListReducer } from "./todo-list/overdue-task-list/overdue-task-list.reducer";
import {
  threeDayTaskListReducer,
  ThreeDayTaskListState,
} from "./todo-list/three-day-task-list/three-day-task-list.reducer";
import { upcomingTaskListReducer } from "./todo-list/upcoming-task-list/upcoming-task-list.reducer";

export interface DashboardModuleState {
  outgoingMail: OutgoingMailState;
  overdueTaskList: OverdueTaskListState;
  freeLeadList: TaskListState;
  threeDayTaskList: ThreeDayTaskListState;
  upcomingTaskList: TaskListState;
  followUpSalesMeetingList: TaskListState;
  segmentationList: SegmentationListState;
}

const reducers = {
  outgoingMail: outgoingMailReducer,
  overdueTaskList: overdueTaskListReducer,
  freeLeadList: freeLeadListReducer,
  threeDayTaskList: threeDayTaskListReducer,
  upcomingTaskList: upcomingTaskListReducer,
  followUpSalesMeetingList: followUpSalesMeetingListReducer,
  segmentationList: segmentationListReducer,
};

export function dashboardModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
