<div class="widget-header">
  <h3>
    <app-icon size="large" name="align-left"></app-icon>&nbsp;<span>{{ 'description' | translate }}</span>
  </h3>
</div>

<div class="widget-container">
  <ng-container>
    <section>
      <ng-container *ngIf="sellingTextHeader$ | async as description">
        <h4>{{ description | nl2br }}</h4>
      </ng-container>
      <ng-container *ngIf="sellingTextShort$ | async as description">
        <strong><p>{{ description | nl2br }}</p></strong>
      </ng-container>
      <ng-container *ngIf="sellingTextFull$ | async as description">
        <p>{{ description | nl2br }}</p>
      </ng-container>
      <ng-container *ngIf="objectPresentation$ | async as description">
        <p>{{ description | nl2br }}</p>
      </ng-container>
    </section>
  </ng-container>
</div>
