export const SALES_MEETINGS = "sales_meetings";
export const SEARCH_PROFILES = "search_profiles";
export const OVERVIEW = "overview";
export const RESIDENCES = "residences";
export const TIMELINE = "timeline";
export const TODO = "todo";
export const MORE_OPPORTUNITIES = "more_opportunities";
export const TASKS = "tasks";
export const LEADS = "leads";
export const LEADS_NEW = "leads-new";
export const KPI = "kpi";
export const NPS = "nps";
export const SENT_TIPS = "sent_tips";
export const TOP_LISTS = "top_lists";
export const GROWTH_REPORT = "growth_report";
export const MARKETING_REPORTS = "marketing_reports";
export const BUDGET = "budget";
export const BUDGET_MONTHLY = "overview_budget";
export const BUDGET_FIVE_YEAR = "five_year_budget";
export const INVOICING = "invoicing";
export const SENT_LEADS = "sent_leads";
export const CONTACTS = "contacts";
export const SHOWINGS = "showings";
export const START = "start";
export const CALLING_LISTS = "calling_lists";
export const SURVEYS = "surveys";
export const OUTGOING_MESSAGES = "outgoing_messages";
export const CONSENTS = "consents";
export const SEARCH_PROFILE = "search_profile";
export const OBJECTS = "objects";
export const EXTERNAL_TIPS_STATISTICS = "external_tips_statistics";
export const VALUE_MONITOR = "value_monitor";
export const NEWSLETTER = "newsletter";
export const SOCIAL_MEDIA = "social_media";
export const BRAND_KEEPER = "brandkeeper";
