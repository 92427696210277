<h4>
  {{ 'answered_by_phone' | translate }}
  <i [tooltip]="'tooltip_answered_by_phone' | translate" class="icon-question"></i>
</h4>
<div class="btn-group">
  <button
    (click)="handleClick(true)"
    [class.active]="status === true"
    type="button"
    class="btn btn-default"
    translate
  >yes
  </button>
  <button
    (click)="handleClick(false)"
    [class.active]="status === false"
    type="button"
    class="btn btn-default"
    translate
  >no
  </button>
</div>
