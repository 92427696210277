<div class="wrapper">
  <div id="sales-meeting-create-sidebar" class="scroll-view">
    <sidebar-header
      [type]="tabType"
      [label]="tabType | translate"
    ></sidebar-header>
    <sales-meeting-form
      #smForm
      (ready)="connectTabToStore()"
      (submitClicked)="onSubmit($event)"
      (setLoading)="loading = $event"
    ></sales-meeting-form>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [disabled]="(processing$ | async) || !!loading"
      [isLoading]="(processing$ | async) || !!loading"
      (click)="loading = true; smForm.submit()"
      scrollToInvalidControl
      [formEl]="smForm?.form"
    >
    </app-q-button>
  </div>
</div>
