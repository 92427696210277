import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as fromBidding from "@app/integrations/bidding/ngrx/bidding/bidding.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { BiddingFeature } from "@app/shared/config/models/bidding-feature";
import { BIDDING } from "@app/shared/config/utils/features";
import * as formUtils from "@app/shared/utils/form-utils";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { ActionsSubject, select, Store } from "@ngrx/store";
import moment from "moment";
import { BsModalRef } from "ngx-bootstrap/modal";
import { map, Observable, Subject, takeUntil, withLatestFrom } from "rxjs";
import {
  addBidFailure,
  addBidRequest,
  addBidSuccess,
} from "../../ngrx/bidding/bidding.actions";
import { ofType } from "@ngrx/effects";

@Component({
  selector: "app-add-bid",
  templateUrl: "./add-bid.component.html",
  styleUrls: ["./add-bid.component.scss"],
})
export class AddBidComponent implements OnInit, OnDestroy {
  form: FormGroup;
  addBid$ = new Subject<{
    amount: string;
    date: string;
    time: string;
    isHidden: boolean;
    bidder: boolean;
    otherBidders: boolean;
    sellers: boolean;
    brokers: boolean;
    condition: string;
  }>();
  eaOid$: Observable<string>;
  destroy$ = new Subject<void>();
  highestBid$: Observable<string>;
  currency$: Observable<string>;
  hideBidTranslationKey$: Observable<string>;
  enableBroadcastSetting: Observable<boolean>;

  pendingRequest = false;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private store: Store<AppState>,
    private dispatcher: ActionsSubject,
    public pbService: PotentialBuyerService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.addBidListener();
    this.addPendingRequestListener();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return formUtils.markAllAsTouched(this.form);
    }

    this.addBid$.next(this.form.value);
  }

  private mapStateToProps(): void {
    this.eaOid$ = this.store.pipe(select(fromShowings.getShowingObjectEaOid));
    this.highestBid$ = this.store.pipe(select(fromBidding.getHighestBid));
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.hideBidTranslationKey$ = this.store.pipe(
      select(fromConfig.getFeature(BIDDING)),
      map(
        (biddingFeature: BiddingFeature) => biddingFeature.hideBidTranslationKey
      )
    );
    this.enableBroadcastSetting = this.store.pipe(
      select(
        hasIntegration(IntegrationResource.CreateBidCommunicationConfiguration)
      )
    );
  }

  private addBidListener(): void {
    this.addBid$
      .pipe(
        withLatestFrom(this.eaOid$, this.pbService.selectedPotentialBuyers$),
        map(([formValue, eaOid, potentialBuyers]) => ({
          formValue,
          eaOid,
          contactId: potentialBuyers[0].contactId,
        })),
        takeUntil(this.destroy$)
      )
      .subscribe(({ formValue, eaOid, contactId }) => {
        const {
          amount,
          isHidden,
          bidder,
          otherBidders,
          sellers,
          brokers,
          condition,
        } = formValue;
        const createdAt = this.transformLocalDateTimeToISO8601(
          moment(formValue.date).format("L"),
          formValue.time
        );
        const action = addBidRequest({
          eaOid,
          contactId,
          body: {
            amount,
            createdAt,
            isHidden,
            communicationSettings: {
              communicateCreatedBidTo: {
                bidder,
                otherBidders,
                sellers,
                brokers,
              },
            },
            condition,
          },
        });
        this.store.dispatch(action);
        this.pendingRequest = true;
      });
  }

  private addPendingRequestListener() {
    this.dispatcher
      .pipe(ofType(addBidSuccess, addBidFailure), takeUntil(this.destroy$))
      .subscribe(() => (this.pendingRequest = false));
  }

  private transformLocalDateTimeToISO8601(date: string, time: string): string {
    const localeData = moment.localeData();
    const longDateFormat = localeData.longDateFormat("L");
    const now = moment();
    const dateTime = moment(`${date} ${time}`, `${longDateFormat} HH:mm`)
      .seconds(now.seconds())
      .milliseconds(now.milliseconds());

    return dateTime.toISOString();
  }

  private createForm(): void {
    const now = moment();

    this.form = this.formBuilder.group({
      amount: [null, Validators.required],
      date: [now.format("L"), Validators.required],
      time: [
        now.format("HH:mm"),
        [
          Validators.required,
          Validators.pattern(RegExp(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)),
        ],
      ],
      isHidden: false,
      bidder: true,
      otherBidders: true,
      sellers: true,
      brokers: true,
      condition: "",
    });
  }
}
