import { Pipe, PipeTransform } from "@angular/core";
import { getFormattedAddress } from "@app/shared/utils/object-utils";

@Pipe({
  name: "formatAddress",
})
export class FormatAddressPipe implements PipeTransform {
  transform(address: { street: string; zip: string; city: string }): string {
    return getFormattedAddress(address);
  }
}
