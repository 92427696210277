import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { CallingListService } from "@app/core/ngrx/entity-services/calling-list.service";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { Task } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { CALLING_LIST } from "@app/shared/utils/task-types";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";

@Component({
  selector: "tasks-calling-list",
  templateUrl: "./tasks-calling-lists.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./tasks-calling-lists.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TasksCallingListsComponent implements OnInit, OnDestroy {
  task$: Observable<Task>;
  callingList$: BehaviorSubject<CallingList> = new BehaviorSubject<CallingList>(
    null
  );

  private unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private callingListService: CallingListService,
    private taskService: TaskService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.initObservers();
    this.getCallingList();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initObservers(): void {
    this.task$ = this.taskService.entityMap$.pipe(
      map((em) => em[this.route.snapshot.params.eaTaskId])
    );
  }

  getCallingList(): void {
    this.task$
      .pipe(
        withLatestFrom(
          this.store.pipe(select(fromConfig.getTaskTypeId(CALLING_LIST)))
        ),
        takeUntil(this.unSubscribe$),
        filter(
          ([task, callingListTaskTypeId]) =>
            Number(task.eaTaskTypeId) === callingListTaskTypeId
        ),
        map(([task]) => task.param1)
      )
      .subscribe((callingListId) => {
        this.callingListService
          .getById(callingListId)
          .subscribe((callingList) => {
            this.callingList$.next(callingList);
          });
      });
  }
}
