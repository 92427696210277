export const convertStringToArray = (string: string): string[] => {
  return string.split(",");
};

export const convertToArray = (array: unknown) => {
  if (array && !Array.isArray(array)) {
    return array.toString().split(",");
  }

  return array || [];
};
