<div class="survey-button">
  <div class="survey-button__container">
    <ng-container *ngIf="surveys">
      <p>
        {{ surveys.length }}
      </p>
      <span translate> polls_answered </span>
      <div class="show-more-btn">
        <i class="icon-arrow-down-circle"></i>
      </div>
    </ng-container>
  </div>
</div>
