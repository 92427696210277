import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink } from "@app/contacts/contact.actions";
import { PageView } from "@app/models/page-view";
import * as contactIconFunctions from "@app/shared/utils/contact-icon-functions";
import { Store } from "@ngrx/store";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-contact-card",
  templateUrl: "./contact-card.component.html",
  styleUrls: ["./contact-card.component.scss"],
})
export class ContactCardComponent {
  @Input() contact: PageView;
  @Output() handleClick = new EventEmitter<void>();
  contactIconFunctions = contactIconFunctions;

  constructor(private store: Store<AppState>, private router: Router) {}

  onContactClick(event) {
    if (!event.target.attributes["disablenavigation"]) {
      this.addBackLink();
      this.store.dispatch(
        go({ path: ["/crm", "contacts", this.contact?.entity?.contactId] })
      );

      this.handleClick.emit();
    }
  }

  addBackLink(): void {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
  }
}
