export const DASHBOARD_CRM_MODULE = "dashboard";
export const CONTACTS_CRM_MODULE = "contacts";
export const STATISTICS_CRM_MODULE = "statistics";
export const SETTINGS_CRM_MODULE = "settings";
export const LISTS_CRM_MODULE = "lists";
export const COMPETITIONS_CRM_MODULE = "competitions";
export const CALLING_LISTS_CRM_MODULE = "calling-lists";
export const SHOWINGS_CRM_MODULE = "showings";
export const OBJECTS_CRM_MODULE = "objects";
export const ERP_CRM_MODULE = "erp";
export const MILES_CRM_MODULE = "miles";
export const EXTERNAL_LINKS_CRM_MODULE = "external-links";
export const CAMPAIGNS_CRM_MODULE = "campaigns";

export const ALL_CRM_MODULES = [
  DASHBOARD_CRM_MODULE,
  CONTACTS_CRM_MODULE,
  STATISTICS_CRM_MODULE,
  SETTINGS_CRM_MODULE,
  LISTS_CRM_MODULE,
  COMPETITIONS_CRM_MODULE,
  CALLING_LISTS_CRM_MODULE,
  SHOWINGS_CRM_MODULE,
  OBJECTS_CRM_MODULE,
  MILES_CRM_MODULE,
  EXTERNAL_LINKS_CRM_MODULE,
  CAMPAIGNS_CRM_MODULE,
  ERP_CRM_MODULE,
];
