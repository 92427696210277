import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-task-changed-delivery-date",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionTaskChangedDeliveryDateComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerTask: Function;

  classTask = "action-task-changed-delivery-date-task";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.translateService.get(
      "action_task_changed_deliverydate_text",
      {
        task: `<span class="${this.classTask} text-link">${this.item.param1}</span>`,
        fromNow: moment(this.item.param2, "YYYYMMDDHHmmss").fromNow(),
      }
    );
  }

  ngAfterViewInit() {
    this.listenerTask = this.itemService.createTaskLinkListener(
      this.elementRef,
      this.renderer,
      this.classTask,
      this.item
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerTask];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
