import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { PotentialBuyer } from "@app/showings/models";
import * as showingActions from "@app/showings/ngrx/showings/showings.actions";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, catchError, filter, first, map, Subject } from "rxjs";
import { ApiService } from "@app/core/services/api/api.service";

@Injectable({
  providedIn: "root",
})
export class PotentialBuyerService {
  selectedPotentialBuyer$ = new BehaviorSubject<PotentialBuyer>(null);
  selectedPotentialBuyers$ = new BehaviorSubject<PotentialBuyer[]>([]);
  selectedPotentialBuyerId$ = new BehaviorSubject<string>("");
  selectedPotentialBuyerOrigin$ = new BehaviorSubject<string>("");
  potentialBuyersLoading$ = new BehaviorSubject<boolean>(false);
  reset$ = new Subject<void>();
  filters$ = new BehaviorSubject<any>({});
  showModal$ = new Subject<any>();
  refreshBids$ = new Subject<void>();
  refreshPotentialBuyersForBiddersList$ = new Subject<void>();
  potentialBuyersExternalStatus$ = new BehaviorSubject<{
    [key: string]: PotentialBuyerStatus;
  }>({});
  objectCreatedActionLinks$ = new BehaviorSubject<ExternalPresentableLink>({});

  constructor(private store: Store<AppState>, private apiService: ApiService) {}

  deletePrivateShowing(showingId, eaOid) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_showing_header",
        message: "remove_showing_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        first()
      )
      .subscribe(() =>
        this.store.dispatch(
          showingActions.deletePrivateShowingRequest({
            showingId: showingId,
            eaOid: eaOid,
          })
        )
      );
  }

  getPotentialBuyersStatusByObject = (eaOid: string) => {
    return this.apiService
      .get(
        `objects/${eaOid}/external-potential-buyer-state`,
        {},
        "integrations"
      )
      .pipe(
        map((response: { potentialBuyers: PotentialBuyerStatus[] }) => {
          const statuses = response.potentialBuyers.reduce((result, item) => {
            result[item.contactId] = item;
            return result;
          }, {});
          this.potentialBuyersExternalStatus$.next(statuses);
          return response.potentialBuyers;
        }),
        catchError((err) => {
          this.potentialBuyersExternalStatus$.next({});
          throw err;
        })
      );
  };
}

export interface PotentialBuyerStatus {
  contactId: string;
  externalId: string;
  isBuyer: boolean;
  isBidder: boolean;
}
