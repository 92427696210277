<form
  *ngIf="(employees$ | async).length === 0"
  [formGroup]="loginForm"
  (ngSubmit)="onSubmit()"
>
  <app-q-input
    [label]="'username'"
    [type]="'email'"
    [formControlName]="'username'"
    [invalid]="
      loginForm.get('username').invalid && loginForm.get('username').dirty
    "
  ></app-q-input>
  <app-q-control-errors
    name="username"
    [errors]="[
      { name: 'required', text: 'validation_required', when: 'dirty' },
      { name: 'validateEmail', text: 'wrong_format', when: 'dirty' }
    ]"
  ></app-q-control-errors>
  <app-q-input
    [label]="'password'"
    [type]="'password'"
    [formControlName]="'password'"
    [invalid]="
      loginForm.get('password').invalid && loginForm.get('password').dirty
    "
  ></app-q-input>
  <app-q-control-errors
    name="password"
    [errors]="[{ name: 'required', text: 'password_mandatory', when: 'dirty' }]"
  ></app-q-control-errors>
  <section *ngIf="showRememberMe">
    <div class="login-form__remember-checkbox">
      <mat-checkbox [formControl]="loginForm.get('longLivedToken')">
        {{ "remember_me" | translate }}
      </mat-checkbox>
    </div>
  </section>
  <div style="margin-top: 12px">
    <app-q-button
      buttonType="save"
      label="login"
      afterIcon="door-open"
      tabindex="1"
      [fullWidth]="true"
    >
    </app-q-button>
  </div>
  <div *ngIf="showForgotPassword" class="login-form__options">
    <a *ngIf="!isMobileAppLogin" routerLink="forgotpassword" class="text-link">
      {{ "register" | translate }}
    </a>
    <a routerLink="forgotpassword" class="text-link">
      {{ "forgot_password" | translate }}
    </a>
  </div>
</form>

<select-office *ngIf="(employees$ | async).length > 0"></select-office>
