import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "approximateDuration",
})
export class ApproximateDurationPipe implements PipeTransform {
  transform(value: any): any {
    if (value === 0) {
      return "n/a";
    } else {
      return moment.duration(value, "milliseconds").humanize();
    }
  }
}
