import { Component, Input, OnInit } from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-lead-deleted",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
    <div>
      <small *ngIf="item.param2?.length > 0" class="text-muted">
        <strong>{{ "type" | translate }}: </strong>{{ item.param2 }}
      </small>
      <small
        *ngIf="item.street?.length > 0"
        class="text-muted"
        (click)="itemService.navigateToObject(item.eaOid)"
      >
        <strong>{{ "address" | translate }}: </strong>
        <span class="text-link">{{ item.street }} </span>
      </small>
      <small *ngIf="item.param3?.length > 0" class="text-muted">
        <strong>{{ "reason" | translate }}: </strong>{{ item.param3 }}
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionLeadDeletedComponent implements OnInit {
  @Input() item: Action;

  text: Observable<string>;

  classLead = "action-lead-deleted-lead";

  constructor(
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_lead_delete_text", {
          lead: `<span class="${this.classLead}">"${this.item.param1}"</span>`,
          you: youString,
        })
      )
    );
  }
}
