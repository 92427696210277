import { Contact, Task } from "@app/models";
import { createAction, props } from "@ngrx/store";

export interface CreateCallingListData {
  contactIds: string;
  eaEmployeeId: string;
  eaOfficeId: string;
  title: string;
  createTask?: boolean;
  taskDeliveryDate?: string;
}

export const getContactListSuccess = createAction(
  "[CallingListSidebar] GET_CONTACT_LIST_SUCCESS",
  props<{ contacts: Contact[]; listTitle: string }>()
);

export const getContactListFailed = createAction(
  "[CallingListSidebar] GET_CONTACT_LIST_FAILED"
);

export const createCallQueueTaskRequest = createAction(
  "[CallingListSidebar] CREATE_CALL_QUEUE_TASK_REQUEST",
  props<{ payload: Partial<Task> }>()
);

export const createCallQueueTaskSuccess = createAction(
  "[CallingListSidebar] CREATE_CALL_QUEUE_TASK_SUCCESS"
);

export const createCallQueueTaskFailed = createAction(
  "[CallingListSidebar] CREATE_CALL_QUEUE_TASK_FAILED"
);

export const createCallingListRequest = createAction(
  "[CallingListSidebar] CREATE_CALLING_LIST_REQUEST",
  props<{ callingList: CreateCallingListData }>()
);

export const createCallingListSuccess = createAction(
  "[CallingListSidebar] CREATE_CALLING_LIST_SUCCESS",
  props<{ callingListId: string; task?: Partial<Task> }>()
);

export const createCallingListFailed = createAction(
  "[CallingListSidebar] CREATE_CALLING_LIST_FAILED"
);
