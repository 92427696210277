import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, first, map, of as observableOf, switchMap } from "rxjs";
import * as followUpActions from "../../../../sidebar/create-follow-up/ngrx/create-follow-up.actions";
import * as tasksActions from "../../../../sidebar/tasks/ngrx/tasks.actions";
import * as tipActions from "../../../../sidebar/tips/ngrx/send-tips.actions";
import * as actionListActions from "./action-list.actions";
import { getLatestActionsFetchAction } from "./action-list.reducer";

@Injectable()
export class ActionListEffects {
  getLatestActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionListActions.getLatestActionsRequest),
      switchMap(({ params }) =>
        this.apiService.get("actions", params).pipe(
          map((response: PaginationListDTO) =>
            actionListActions.getLatestActionsSuccess({ payload: response })
          ),
          catchError(() =>
            observableOf(actionListActions.getLatestActionsFailed())
          )
        )
      )
    )
  );

  getMoreLatestActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionListActions.getMoreLatestActionsRequest),
      switchMap(({ params }) =>
        this.apiService.get("actions", params).pipe(
          map((response: PaginationListDTO) =>
            actionListActions.getMoreLatestActionsSuccess({ payload: response })
          ),
          catchError(() =>
            observableOf(actionListActions.getMoreLatestActionsFailed())
          )
        )
      )
    )
  );

  deleteAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionListActions.removeActionRequest),
      switchMap(({ actionId, eaEmployeeId }) =>
        this.apiService
          .delete(`actions/${actionId}`, {
            eaEmployeeId,
          })
          .pipe(
            map(() => actionListActions.removeActionSuccess({ actionId })),
            catchError(() =>
              observableOf(actionListActions.removeActionFailed())
            )
          )
      )
    )
  );

  deleteActionFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actionListActions.removeActionFailed),
      map(() =>
        toastActions.danger({ message: "delete_action_failed", duration: 3000 })
      )
    )
  );

  redoGetLatestActionsRequest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          tasksActions.updateTaskSuccess,
          tasksActions.createTaskAttemptSuccess,
          followUpActions.createFollowUpSuccess,
          tipActions.sendTipsSuccess
        ),
        switchMap(() =>
          this.store.pipe(select(getLatestActionsFetchAction), first())
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private apiService: ApiService
  ) {}
}
