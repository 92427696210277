import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { CompetitionFormSkeletonComponent } from "./competition-form/competition-form-skeleton/competition-form-skeleton.component";
import { CompetitionFormComponent } from "./competition-form/competition-form.component";
import { CreateCompetitionComponent } from "./create-competition/create-competition.component";
import { EditCompetitionComponent } from "./edit-competition/edit-competition.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormsModule,
    FormComponentsModule,
    UtilModule,
    BsDatepickerModule,
    SkeletonScreenModule,
    UiComponentsModule,
  ],
  declarations: [
    CreateCompetitionComponent,
    EditCompetitionComponent,
    CompetitionFormComponent,
    CompetitionFormSkeletonComponent,
  ],
})
export class CompetitionsModule {}
