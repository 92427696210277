import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { LEAD_RESULT_OPTIONS } from "@app/shared/utils/tab-types";
import { LeadResultOptionsFormComponent } from "@app/sidebar/lead-result-options/components/lead-result-options-form/lead-result-options-form.component";
import * as leadResultOptionsFormActions from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.actions";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Store } from "@ngrx/store";
import { first, map, Observable, Subject } from "rxjs";

@Component({
  selector: "app-lead-result-options-create",
  templateUrl: "./lead-result-options-create.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-result-options-create.component.scss",
  ],
})
export class LeadResultOptionsCreateComponent
  extends ConnectTab
  implements OnInit, OnDestroy, IConnectableTab
{
  @ViewChild(LeadResultOptionsFormComponent, { static: true })
  leadTemplatesForm: LeadResultOptionsFormComponent;

  tabType = LEAD_RESULT_OPTIONS;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  channelId$: Observable<number>;

  constructor(store: Store<AppState>, private route: ActivatedRoute) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.channelId$ = this.route.params.pipe(map(({ channelId }) => channelId));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    this.channelId$.pipe(first()).subscribe((id) => {
      this.leadTemplatesForm.submit((body) =>
        this.store.dispatch(
          leadResultOptionsFormActions.createLeadResultOptionRequest({
            id,
            body,
          })
        )
      );
    });
  }
}
