import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Task } from "@app/models/task";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { isManagerOrAdmin } from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-free-leads-widget",
  templateUrl: "./free-leads-widget.component.html",
  styleUrls: ["./free-leads-widget.component.scss"],
})
export class FreeLeadsWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() openLinkInNewTab = false;
  @Input() showHeader = true;

  unsubscribe$ = new Subject<void>();

  currentTasks$: Observable<Task[]>;
  yourLeads$: BehaviorSubject<Task[]> = new BehaviorSubject<Task[]>([]);
  freeLeads$: BehaviorSubject<Task[]> = new BehaviorSubject<Task[]>([]);

  isBroker$: Observable<boolean>;

  segmentedControls: SegmentControls = [
    { label: "my_leads", value: "your_leads" },
    { label: "free_leads", value: "free_leads" },
  ];

  activeControl = new FormControl("your_leads");

  constructor(
    public taskService: TaskService,
    public taskTypeResolver: TaskTypeResolverService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.setSegmentControls();
    this.handleViewChanges();
  }

  ngOnChanges(changes) {
    if (
      (changes.eaEmployeeId || changes.eaOfficeId) &&
      this.eaEmployeeId &&
      this.eaOfficeId
    ) {
      this.fetchLeads();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onTaskClick(task: Task, event) {
    event.preventDefault();
    if (!event.target.attributes["disablenavigation"]) {
      this.taskTypeResolver.onTaskClick(task, this.openLinkInNewTab);
    }
  }

  private fetchLeads() {
    const params = {
      taskTypeCategory: "lead",
      resolved: false,
      sortBy: "insertedDate",
      sortOrder: "desc",
      limit: 2000,
    };

    combineLatest([
      this.taskService.refreshFetch$,
      this.store.pipe(select(isManagerOrAdmin)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([, isManager]) => {
        this.taskService
          .getWithQuery(
            {
              ...params,
              eaOfficeId: this.eaOfficeId,
              taskNotConnectedToEmployee: isManager
                ? "true"
                : "excludeOfficeManagerExclusive",
            },
            false
          )
          .subscribe((leads: Task[]) => this.freeLeads$.next(leads));

        this.taskService
          .getWithQuery(
            {
              ...params,
              eaEmployeeId: this.eaEmployeeId,
            },
            false
          )
          .subscribe((leads: Task[]) => this.yourLeads$.next(leads));
      });
  }

  private handleViewChanges() {
    this.activeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === "free_leads") {
          this.currentTasks$ = this.freeLeads$;
        } else {
          this.currentTasks$ = this.yourLeads$;
        }
      });
  }

  private setSegmentControls() {
    combineLatest([this.freeLeads$, this.yourLeads$])
      .pipe(debounceTime(600), takeUntil(this.unsubscribe$))
      .subscribe(([free, your]) => {
        this.segmentedControls = [
          { label: "your_leads", value: "your_leads", total: your.length },
          { label: "free_leads", value: "free_leads", total: free.length },
        ];
      });
  }

  private mapStateToProps() {
    this.currentTasks$ = this.yourLeads$;
  }
}
