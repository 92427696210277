<div class="widget-header">
  <h3>
    <app-icon size="large" name="user"></app-icon>&nbsp;<span>{{
      "objects" | translate
    }}</span>
  </h3>
  <button
    *ngIf="(createObjectFeature$ | async).enabled"
    [routerLink]="['/crm', { outlets: { sidebar: ['root', 'create-object'] } }]"
    [tooltip]="'create_object'"
    class="btn btn-secondary btn-circle btn-create"
  >
    <app-icon size="x-small" name="plus"></app-icon>
  </button>
</div>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div *ngFor="let object of objectConnectionList$ | async">
    <app-mobile-card
      size="SLIM"
      [shadow]="false"
      color="WHITE"
      [enableMetadata]="true"
      [enableActionDropdown]="true"
    >
      <span title>{{ object.street }}</span>
      <div metadata>
        <span>
          <app-icon [name]="'user'"></app-icon>
          {{
            getObjectTypeTranslationKey(object.type, object.bidStatus)
              | translate
          }}
        </span>
        <span>
          <app-icon [name]="'dollar'"></app-icon>
          {{ object.statusName }}
        </span>
        <span>
          <app-icon [name]="'map-marker-alt'"></app-icon>
          {{ object.city }}, {{ object.zip }}
        </span>
        <span>
          <app-icon [name]="'clock'"></app-icon>
          {{ object.insertedDate }}
        </span>
      </div>
      <ng-container buttons>
        <li disableNavigation role="menuitem">
          <button disableNavigation class="btn btn-default">
            <app-icon name="share-square"></app-icon>
            {{ "open_in_new_tab" | translate }}
          </button>
        </li>
      </ng-container>
    </app-mobile-card>
  </div>
</div>
