import { createAction, props } from "@ngrx/store";

interface SendMessageParams {
  dataSet: string;
  origin: string;
  recipients: string;
  sendToModule: string;
  templateId: string;
}

export const sendMessageToSupportRequest = createAction(
  "[SUPPORT] SEND_MESSAGE_TO_SUPPORT_REQUEST",
  props<{ sendMessageParams: SendMessageParams }>()
);

export const sendMessageToSupportSuccess = createAction(
  "[SUPPORT] SEND_MESSAGE_TO_SUPPORT_SUCCESS"
);

export const sendMessageToSupportFailure = createAction(
  "[SUPPORT] SEND_MESSAGE_TO_SUPPORT_FAILURE"
);
