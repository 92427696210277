import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { EmployeeDTO } from "@app/models";
import {
  getCountry,
  getLanguage,
  getPhonePrefix,
} from "@app/shared/config/config.reducer";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import * as employeeActions from "./ngrx/employees-create.actions";
import { getCreateEmployeeProcessing } from "./ngrx/employees-create.reducer";

@Component({
  selector: "employees-create",
  templateUrl: "./employees-create.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./employees-create.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeesCreateComponent implements OnInit {
  tabType = EMPLOYEES;

  countryCode$: Observable<string>;
  phonePrefix$: Observable<number>;
  language$: Observable<string>;
  processing$: Observable<boolean>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.countryCode$ = this.store.pipe(select(getCountry));
    this.language$ = this.store.pipe(select(getLanguage));
    this.processing$ = this.store.pipe(select(getCreateEmployeeProcessing));
    this.phonePrefix$ = this.store.pipe(
      select(getPhonePrefix),
      map((phonePrefix) => Number(phonePrefix))
    );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(employee: EmployeeDTO): void {
    const { eaOfficeId } = this.route.snapshot.params;
    this.store.dispatch(
      employeeActions.createEmployeeRequest({ employee, eaOfficeId })
    );
  }
}
