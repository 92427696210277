import { Component, Input } from "@angular/core";
import * as taskCategories from "@app/shared/utils/task-categories";
import { TaskActionLog } from "@app/sidebar/tasks/ngrx/tasks.reducer";
import * as actionLogTypes from "@app/sidebar/tasks/utils/action-log-types";

@Component({
  selector: "app-tasks-default-action-log",
  templateUrl: "./tasks-default-action-log.component.html",
  styleUrls: ["./tasks-default-action-log.component.scss"],
})
export class TasksDefaultActionLogComponent {
  @Input() taskActionLogs: TaskActionLog[];
  @Input() taskType: string;

  getTranslationKey(actionType: string): string {
    if (this.taskType === taskCategories.TASK) {
      switch (actionType) {
        case actionLogTypes.TASK_CREATED:
          return `task_created`;
        case actionLogTypes.TASK_CONNECTED_TO_CONSUMER:
          return `task_connected_to_consumer`;
        case actionLogTypes.TASK_CONNECTED_TO_OFFICE:
          return `task_connected_to_office`;
        case actionLogTypes.TASK_DISCONNECTED_FROM_OFFICE:
          return `task_disconnected_from_office`;
        case actionLogTypes.TASK_CONNECTED_TO_EMPLOYEE:
          return `task_connected_to_employee`;
        case actionLogTypes.TASK_ASSIGNED_TO_MANAGERS:
          return `task_assigned_to_managers`;
        case actionLogTypes.TASK_TRANSFERRED_TO_EMPLOYEE:
          return `task_transferred_comment`;
        case actionLogTypes.TASK_STORED_ATTEMPT:
          return `task_stored_attempt`;
        case actionLogTypes.TASK_NOT_RESOLVED:
          return `task_not_resolved`;
        default:
          return actionType.toLowerCase();
      }
    } else {
      switch (actionType) {
        case actionLogTypes.TASK_CREATED:
          return `lead_created`;
        case actionLogTypes.TASK_CONNECTED_TO_CONSUMER:
          return `lead_connected_to_consumer`;
        case actionLogTypes.TASK_CONNECTED_TO_OFFICE:
          return `lead_connected_to_office`;
        case actionLogTypes.TASK_DISCONNECTED_FROM_OFFICE:
          return `lead_disconnected_from_office`;
        case actionLogTypes.TASK_CONNECTED_TO_EMPLOYEE:
          return `lead_connected_to_employee`;
        case actionLogTypes.TASK_ASSIGNED_TO_MANAGERS:
          return `lead_assigned_to_managers`;
        case actionLogTypes.TASK_STORED_ATTEMPT:
          return `task_stored_attempt`;
        case actionLogTypes.TASK_NOT_RESOLVED:
          return `task_not_resolved`;
        case actionLogTypes.TASK_TRANSFERRED_TO_EMPLOYEE:
          return `task_transferred_comment`;
        default:
          return actionType.toLowerCase();
      }
    }
  }

  getAdditionalInfo(actionLog: TaskActionLog): string {
    switch (actionLog.actionType) {
      case actionLogTypes.TASK_CONNECTED_TO_CONSUMER:
        return `${actionLog.firstName} ${actionLog.familyName}`;
      case actionLogTypes.TASK_CONNECTED_TO_OFFICE:
        return `${actionLog.officeName}`;
      case actionLogTypes.TASK_CONNECTED_TO_EMPLOYEE:
        return `${actionLog.employeeFullName}`;
      case actionLogTypes.TASK_TRANSFERRED_TO_EMPLOYEE:
        return ` ${
          this.isParam2Valid(actionLog.param2) ? actionLog.param2 : "---"
        }: ${actionLog.param3}`;
      default:
        return "";
    }
  }

  showAction(actionLog: TaskActionLog) {
    return !(
      actionLog.actionType === actionLogTypes.TASK_TRANSFERRED_TO_EMPLOYEE &&
      !actionLog.param3
    );
  }

  isParam2Valid(param2: string): boolean {
    return !/\d/.test(param2);
  }
}
