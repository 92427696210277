import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "addSpacesToCommaSeparatedString",
})
export class AddSpacesToCommaSeparatedStringPipe implements PipeTransform {
  transform(value: string): unknown {
    return value.replace(/,/g, ", ");
  }
}
