import { ObjectStatisticsProvider } from "@app/showings/models/object-statistics";
import { createAction, props } from "@ngrx/store";

export const getObjectStatisticsProvidersRequest = createAction(
  "[SettingsObjectStatisticsProviders] GET_OBJECT_STATISTICS_PROVIDER_REQUEST"
);

export const getObjectStatisticsProvidersSuccess = createAction(
  "[SettingObjectStatisticsProviders] GET_OBJECT_STATISTICS_SUCCESS",
  props<{ providers: ObjectStatisticsProvider[] }>()
);

export const getObjectStatisticsProvidersFailed = createAction(
  "[SettingObjectStatisticsProviders] GET_OBJECT_STATISTICS_FAILED"
);

export const storeObjectStatisticsProvidersRequest = createAction(
  "[SettingsObjectStatisticsProviders] STORE_OBJECT_STATISTICS_PROVIDER_REQUEST",
  props<{ objectStatisticsProvider: ObjectStatisticsProvider }>()
);

export const storeObjectStatisticsProvidersSuccess = createAction(
  "[SettingObjectStatisticsProviders] STORE_OBJECT_STATISTICS_SUCCESS"
);

export const storeObjectStatisticsProvidersFailed = createAction(
  "[SettingObjectStatisticsProviders] STORE_OBJECT_STATISTICS_FAILED"
);

export const updateObjectStatisticsProvidersRequest = createAction(
  "[SettingsObjectStatisticsProviders] UPDATE_OBJECT_STATISTICS_PROVIDER_REQUEST",
  props<{ objectStatisticsProvider: ObjectStatisticsProvider }>()
);

export const updateObjectStatisticsProvidersSuccess = createAction(
  "[SettingObjectStatisticsProviders] UPDATE_OBJECT_STATISTICS_SUCCESS"
);

export const updateObjectStatisticsProvidersFailed = createAction(
  "[SettingObjectStatisticsProviders] UPDATE_OBJECT_STATISTICS_FAILED"
);

export const deleteObjectStatisticsProvidersRequest = createAction(
  "[SettingsObjectStatisticsProviders] DELETE_OBJECT_STATISTICS_PROVIDER_REQUEST",
  props<{ eaVisitStatisticsProviderId: string }>()
);

export const deleteObjectStatisticsProvidersSuccess = createAction(
  "[SettingObjectStatisticsProviders] DELETE_OBJECT_STATISTICS_SUCCESS"
);

export const deleteObjectStatisticsProvidersFailed = createAction(
  "[SettingObjectStatisticsProviders] DELETE_OBJECT_STATISTICS_FAILED"
);
