import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { QObjectMarketing } from "@app/models/q-object-marketing";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { BehaviorSubject, catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ObjectMarketingService extends CustomEntityCollectionServiceBase<QObjectMarketing> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("ObjectMarketing", serviceElementsFactory);
  }

  getById = (eaOid: string) => {
    this.setLoading(true);
    this.setHasError(false);
    return this.apiService
      .get(`adfenix/property/data/object/${eaOid}`, {}, "integrations")
      .pipe(
        map((response: any) => {
          const object = {};
          Object.keys(response).forEach((key) => {
            if (response[key] !== null) {
              object[key] = response[key];
            }
          });
          this.upsertOneInCache(object);
          this.setLoading(false);
          return response;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.setHasError(true);
          return err;
        })
      );
  };

  hasError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private setHasError(value: boolean) {
    this.hasError$.next(value);
  }
}
