import { Component } from "@angular/core";

@Component({
  selector: "page-nav-bar-filters",
  template: `
    <div class="page-nav-bar">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: [
    "../page-nav-bar/page-nav-bar.component.scss",
    "./page-nav-bar-filters.component.scss",
  ],
})
export class PageNavBarFiltersComponent {}
