import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { SEND_TIPS_EIKA } from "@app/shared/config/utils/features";
import { ALL_CRM_MODULES } from "@app/shared/utils/crm-modules";
import { EIKA_SEND_TIPS } from "@app/shared/utils/tab-types";
import { CrmModule } from "@app/sidebar/models/crm-module";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map } from "rxjs";

@Injectable()
export class EikaGuard implements CanActivate {
  crmModules = ALL_CRM_MODULES;

  constructor(
    private store: Store<AppState>,
    private sidebarService: SidebarService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAvailable$ = this.store.pipe(
      select(getFeature(SEND_TIPS_EIKA)),
      first(),
      map((response) => !!response.enabled)
    );

    const crmModule = this.crmModules
      .map((module) => new CrmModule(module))
      .find((module) => module.isModuleActive(state.url));

    return combineLatest([
      this.sidebarService.canActivateTab(
        route,
        state.url,
        crmModule,
        EIKA_SEND_TIPS
      ),
      isAvailable$,
    ]).pipe(map(([canActivate, enabled]) => canActivate && enabled));
  }
}
