import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import {
  BRAND_DANGER_COLOR,
  BRAND_SUCCESS_COLOR,
} from "@app/shared/utils/colors";
import { defaultColorScheme } from "@app/statistics/charts/utils";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-budget-widget/ngrx/statistics-budget-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-budget-widget/ngrx/statistics-budget-widget.reducer";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { filter, map, Observable, skip, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-statistics-budget-widget",
  templateUrl: "./statistics-budget-widget.component.html",
  styleUrls: ["./statistics-budget-widget.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StatisticsBudgetWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() triggerDataFetch$: Observable<void>;

  statistics$: Observable<GrowthStatistics>;
  loading$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();
  colorScheme = defaultColorScheme;
  statisticsChartValue$: Observable<{ name: string; value: number }[]>;
  customColors$: Observable<{ name: string; value: string }[]>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId) {
      this.getStatistics();
    }
  }

  private mapStateToProps() {
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
    this.statistics$ = this.store.pipe(select(fromWidget.getStats));
    this.statisticsChartValue$ = this.statistics$.pipe(
      takeUntil(this.unsubscribe$),
      filter((stats) => !!stats?.totals),
      map((stats) =>
        Object.keys(stats.totals).map((key) => {
          if (
            !!stats.totals[key].budgetData.budget &&
            stats.totals[key].budgetData.budget !== 0
          ) {
            return {
              name: this.translateService.instant(`${key.toLowerCase()}`),
              value: Math.round(
                (stats.totals[key].current /
                  stats.totals[key].budgetData.budget) *
                  100
              ),
            };
          } else {
            return {
              name: this.translateService.instant(`${key.toLowerCase()}`),
              value: 0,
            };
          }
        })
      )
    );

    this.customColors$ = this.statisticsChartValue$.pipe(
      takeUntil(this.unsubscribe$),
      map((values) => this.getCustomColors(values))
    );
  }

  getStatistics() {
    const params = {
      ...this.getIds(),
      periodStart: moment().startOf("month").format("YYYYMMDD"),
      periodEnd: moment().format("YYYYMMDD"),
      returnTypes: "turnover, numberOfSoldObjects",
      includeLastYearsData: true,
      getBudgetData: true,
    };
    this.store.dispatch(widgetActions.fetchStatisticsRequest({ params }));
  }

  private getIds() {
    const params = {};
    if (!!this.eaEmployeeId) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }

  getCustomColors(chartValues: { name: string; value: number }[]) {
    return chartValues.map((chartValue) => {
      if (chartValue.value >= 100) {
        return {
          name: chartValue.name,
          value: BRAND_SUCCESS_COLOR,
        };
      } else {
        return {
          name: chartValue.name,
          value: BRAND_DANGER_COLOR,
        };
      }
    });
  }

  yAxisTickFormatting(value) {
    return `${value}%`;
  }

  getDaysLeft() {
    return moment().daysInMonth() - moment().date();
  }

  getDaysLeftPercentage() {
    return Math.round((moment().date() / moment().daysInMonth()) * 100);
  }
}
