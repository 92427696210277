import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent implements OnChanges {
  @Input() color: string;
  @Input() removable: boolean;
  @Output() remove = new EventEmitter<void>();

  @HostBinding("style.border-left-color") borderColor: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["color"]) {
      this.borderColor = changes["color"].currentValue;
    }
  }
}
