<article
  *ngIf="employee.employeeFullName"
  class="search-card search-card-details search-card-details__employee">
  <section class="search-card-details__header search-card-details__header__employee">
      <div class="search-card-details__header__circle search-card-details__header__circle--employee-image" *ngIf="employee.employeePhoto as src; else showUserIcon">
        <figure [ngStyle]="{'background-image': 'url(' + employee.employeePhoto + '150),' +
         ' url(' + '/assets/img/img_error_placeholder.png' + ')'}"></figure>
      </div>
      <ng-template #showUserIcon>
        <div class="search-card-details__header__circle search-card-details__header__circle--employee">
          <i class="icon-user"></i>
        </div>
      </ng-template>
  </section>

  <section class="search-card-details__person-contact-info ellipsify">
    <h4 class="name">
      {{employee.employeeFullName}}
    </h4>
    <p *ngIf="employee.employeeTitle">
      <i class="icon-graduation"></i> {{employee.employeeTitle}}
    </p>
    <p *ngIf="!employee.employeeTitle">
      <i class="icon-graduation"></i> <span class="muted" translate> - - -</span>
    </p>

    <a *ngIf="employee.employeeMobile" href="tel:+{{ employee.mobileCountryCode }}{{ employee.mobileNumber }}">
      <i class="icon-phone"></i>{{ employee.employeeMobile | phoneFormat:(employee.countryCode) }}
    </a>
    <p *ngIf="!employee.employeeMobile">
      <i class="icon-phone"></i> <span class="muted" translate> - - -</span>
    </p>

    <a *ngIf="employee.employeeEmail" href="mailto:{{employee.employeeEmail}}" target="_top">
      <span tooltip="{{ 'open_mail_client' | translate }}" placement="bottom"><i class="icon-envelope"></i> {{employee.employeeEmail}}</span>
    </a>
    <p *ngIf="!employee.employeeEmail">
      <i class="icon-envelope"></i> <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="employee.officeNames">
      <i class="fa fa-building-o"></i>
      <span *ngIf="employee.numberOfOffices > 5" class="subheader">
              {{ 'belongs_to' | translate }}
              {{employee.numberOfOffices}}
              {{ 'offices' | translate }}
            </span>
      <ng-container *ngIf="employee.numberOfOffices <= 5">
            <span *ngFor="let officeName of getOfficeNames(employee.officeNames); let i = index" class="subheader">
              {{ (i > 0) ? ', ' : ''}}{{ officeName | removeOfficePrefix | async }}
            </span>
      </ng-container>
    </p>
  </section>
</article>


