import { Route } from "@angular/router";
import { LeadChannelsGuard } from "@app/sidebar/guards/lead-channels-guard.service";
import { LeadChannelsCreateComponent } from "@app/sidebar/lead-channels/containers/lead-channels-create/lead-channels-create.component";
import { LeadChannelsEditComponent } from "@app/sidebar/lead-channels/containers/lead-channels-edit/lead-channels-edit.component";
import { sidebarLeadResultOptionsRoutes } from "@app/sidebar/lead-result-options/lead-result-options-routing";
import { sidebarLeadTemplatesRoutes } from "@app/sidebar/lead-templates/lead-templates-routing";

export const sidebarLeadChannelsRoutes: Route = {
  path: "lead-channels",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: LeadChannelsCreateComponent,
      canActivate: [LeadChannelsGuard],
    },
    {
      path: ":channelId",
      children: [
        {
          path: "edit",
          component: LeadChannelsEditComponent,
          canActivate: [LeadChannelsGuard],
        },
        sidebarLeadResultOptionsRoutes,
        sidebarLeadTemplatesRoutes,
      ],
    },
  ],
};
