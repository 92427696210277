import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contact } from "@app/models";
import { BankAdvisor } from "@app/settings/banks/manage-banks/models/bank-advisor";
import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import * as formUtils from "@app/shared/utils/form-utils";
import { map, PartialObserver, startWith } from "rxjs";

@Component({
  selector: "app-send-tip-to-eika",
  templateUrl: "./send-tip-to-eika-form.component.html",
  styleUrls: [
    "./send-tip-to-eika-form.component.scss",
    "../../../sidebar.component.common.scss",
  ],
})
export class SendTipToEikaFormComponent implements OnInit, OnChanges {
  @Input() banks: ExternalBank[];
  @Input() banksLoading: boolean;
  @Input() advisors: BankAdvisor[];
  @Input() advisorsLoading: boolean;
  @Input() contact: Contact;
  @Input() object: Object;
  @Input() observer: PartialObserver<any>;

  @Output() submitClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() newBankSelected: EventEmitter<string> = new EventEmitter<string>();

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.registerObserverToFormValueChanges();
    this.handleFormChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.advisors) {
      this.form.get("advisor").setValue(null);
    }
  }

  handleSubmit() {
    if (this.form.valid) {
      this.submitClick.emit(this.form.value);
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue())
      )
      .subscribe(this.observer);
  }

  getPlaceholder(): string {
    const selectedAdvisor = this.advisors.find(
      (advisor) => this.form.get("advisor").value === advisor.id
    );
    return selectedAdvisor
      ? `${selectedAdvisor.firstName} ${selectedAdvisor.lastName}`
      : "choose_advisor";
  }

  private buildForm() {
    this.form = this.fb.group({
      bank: ["", [Validators.required]],
      advisor: [],
      note: [""],
      urgent: [false],
    });
  }

  private handleFormChanges() {
    this.form.get("bank").valueChanges.subscribe((newValue: string) => {
      this.newBankSelected.emit(newValue);
    });
  }
}
