import { Component, Input } from "@angular/core";

@Component({
  selector: "page-nav-bar-item-label",
  template: '<h5 [class.dropdown]="dropdown">{{ label | translate }}</h5>',
  styleUrls: ["./page-nav-bar-item-label.component.scss"],
})
export class PageNavBarItemLabelComponent {
  @Input() label: string;
  @Input() dropdown: boolean;
}
