import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Kpi } from "../../models/kpi";
import { KpiUtilsService } from "../../services/kpi-utils.service";

@Component({
  selector: "[kpi-summary-row]",
  templateUrl: "./kpi-summary-row.component.html",
  styleUrls: [
    "../kpi-modal.component.scss",
    "./kpi-summary-row.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiSummaryRowComponent {
  @Input() kpi: Kpi;

  constructor(private kpiUtils: KpiUtilsService) {}

  getClassNameByProgress(progress: number): string {
    return this.kpiUtils.getClassNameByProgress(progress);
  }
}
