import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { QObject, SalesMeeting } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getContactResidenceListRequest = createAction(
  "[Contact Residence] GET_CONTACT_RESIDENCE_LIST_REQUEST",
  props<{ contactId: string }>()
);

export const getContactResidenceListSuccess = createAction(
  "[Contact Residence] GET_CONTACT_RESIDENCE_LIST_SUCCESS",
  props<{ list: QObject[] }>()
);

export const getContactResidenceListFailed = createAction(
  "[Contact Residence] GET_CONTACT_RESIDENCE_LIST_FAILED"
);

export const getContactObjectConnectionListRequest = createAction(
  "[Contact Residence] GET_CONTACT_OBJECT_CONNECTION_LIST_REQUEST",
  props<{ contactId: string }>()
);

export const getContactObjectConnectionListSuccess = createAction(
  "[Contact Residence] GET_CONTACT_OBJECT_CONNECTION_LIST_SUCCESS",
  props<{ objectConnectionList: QObject[] }>()
);

export const getContactObjectConnectionListFailed = createAction(
  "[Contact Residence] GET_CONTACT_OBJECT_CONNECTION_LIST_FAILED"
);

export const deleteContactResidenceAndConnectedSalesMeetingsRequest =
  createAction(
    "[Contact Residence] DELETE_CONTACT_RESIDENCE_AND_SALES_MEETINGS_REQUEST",
    props<{ eaOid: string; contactId: string }>()
  );

export const deleteContactResidenceRequest = createAction(
  "[Contact Residence] DELETE_CONTACT_RESIDENCE_REQUEST",
  props<{ eaOid: string; contactId: string }>()
);

export const deleteContactResidenceSuccess = createAction(
  "[Contact Residence] DELETE_CONTACT_RESIDENCE_SUCCESS",
  props<{ eaOid: string }>()
);

export const deleteContactResidenceFailed = createAction(
  "[Contact Residence] DELETE_CONTACT_RESIDENCE_FAILED"
);

export const deleteSalesMeetingsRequest = createAction(
  "[Contact Residence] DELETE_SALES_MEETINGS_REQUEST",
  props<{
    salesMeetings: SalesMeeting[];
    eaOid: string;
    contactId: string;
  }>()
);

export const deleteSalesMeetingsSuccess = createAction(
  "[Contact Residence] DELETE_SALES_MEETINGS_SUCCESS"
);

export const deleteSalesMeetingsFailed = createAction(
  "[Contact Residence] DELETE_SALES_MEETINGS_FAILED"
);

export const getExternalProviderLinksRequest = createAction(
  "[Contact Residence] GET_EXTERNAL_PROVIDER_LINKS_REQUEST",
  props<{ eaOid: string }>()
);

export const getExternalProviderLinksSuccess = createAction(
  "[Contact Residence] GET_EXTERNAL_PROVIDER_LINKS_SUCCESS",
  props<{ externalProviderLinks: ExternalPresentableLink[] }>()
);

export const getExternalProviderLinksFailed = createAction(
  "[Contact Residence] GET_EXTERNAL_PROVIDER_LINKS_FAILED"
);

export const setResidence = createAction(
  "[Contact Residence] SET_RESIDENCE",
  props<{ residence: QObject }>()
);

export const reset = createAction("[Contact Residence] RESET");
