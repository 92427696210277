import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { addBackLink, addQuickLink } from "@app/contacts/contact.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { UrlService } from "@app/core/services/url/url.service";
import { Task } from "@app/models";
import { getTaskIcons } from "@app/shared/config/config.reducer";
import { TaskIcon } from "@app/shared/config/models";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as COLORS from "@app/shared/utils/colors";
import { getQuickLinkLabel } from "@app/shared/utils/contact-quick-link";
import { CONTACTS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import {
  SIDEBAR_TASKS_DEFAULT_URL,
  SIDEBAR_TASKS_DYNAMIC_SEGMENT_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import * as TASK_CATEGORIES from "@app/shared/utils/task-categories";
import * as TASK_TYPES from "@app/shared/utils/task-types";
import {
  BLIXTVARDERING,
  CALLING_LIST,
  FINAL_PRICE,
  MITT_BOLAN,
  PRIVATE_SHOWING_FOLLOW_UP,
  SALES_MEETING_FOLLOW_UP,
  SALES_MEETING_REPORT,
  VARDEBAROMETERN,
  VARDEKOLLEN,
} from "@app/shared/utils/task-types-urls";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { updateTaskRequest } from "@app/sidebar/tasks/ngrx/tasks.actions";
import { select, Store } from "@ngrx/store";
import { AppState } from "app/app.reducer";
import { CrmModule } from "app/sidebar/models/crm-module";
import moment from "moment";
import { first, map, Observable } from "rxjs";
import { TaskUpdateParams } from "@app/sidebar/tasks/tasks-default/tasks-default.component";

@Injectable()
export class TaskTypeResolverService {
  map: any;
  private taskIcons$: Observable<TaskIcon[]>;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private urlService: UrlService
  ) {
    this.store
      .select((state) => state.config)
      .pipe(map((config) => config.app.tasks))
      .subscribe((tasks) => (this.map = tasks));
    this.taskIcons$ = this.store.pipe(select(getTaskIcons));
  }

  taskTypeIdToName(id: number) {
    return Object.keys(this.map).find((key) => this.map[key] === id);
  }

  taskTypeCategoryToIcon(eaTaskTypeCategory: string) {
    switch (eaTaskTypeCategory) {
      case TASK_CATEGORIES.LEAD:
        return "street-view";
      case TASK_CATEGORIES.TASK:
        return "check";
      default:
        return "";
    }
  }

  taskTypeCategoryToColor(eaTaskTypeCategory: string) {
    switch (eaTaskTypeCategory) {
      case TASK_CATEGORIES.LEAD:
        return COLORS.LEADS_COLOR;
      case TASK_CATEGORIES.TASK:
        return COLORS.CONTACTS_COLOR;
      default:
        return "";
    }
  }

  taskTypeIdToIcon(id: string | number) {
    if (typeof id === "string") {
      id = parseInt(id, 10);
    }

    switch (this.taskTypeIdToName(id)) {
      case TASK_TYPES.SALES_MEETING_REPORT:
        return "briefcase";
      case TASK_TYPES.SALES_MEETING_FOLLOW_UP:
        return "briefcase";
      default:
        return "fa fa-check";
    }
  }

  taskTypeIdToColor(id: string | number) {
    if (typeof id === "string") {
      id = parseInt(id, 10);
    }

    switch (this.taskTypeIdToName(id)) {
      case TASK_TYPES.FOLLOW_UP:
        return COLORS.CONTACTS_COLOR;
      case TASK_TYPES.FOLLOW_UP_BUYER_30_DAYS:
        return COLORS.CONTACTS_COLOR;
      case TASK_TYPES.FOLLOW_UP_SELLER_30_DAYS:
        return COLORS.CONTACTS_COLOR;
      case TASK_TYPES.PRICE_UPDATER_SOLD_PROPERTIES:
        return COLORS.CONTACTS_COLOR;
      case TASK_TYPES.PRICE_UPDATER_EVALUATED_PROPERTIES:
        return COLORS.CONTACTS_COLOR;
      case TASK_TYPES.SALES_MEETING_REPORT:
        return COLORS.SALESMEETING_COLOR;
      case TASK_TYPES.SALES_MEETING_FOLLOW_UP:
        return COLORS.SALESMEETING_COLOR;
      case TASK_TYPES.CONTACT_REQUEST:
        return COLORS.CONTACTS_COLOR;
      default:
        return COLORS.CONTACTS_COLOR;
    }
  }

  taskTypeIdToUrlSegment(id: string | number): string {
    if (typeof id === "string") {
      id = parseInt(id, 10);
    }

    switch (this.taskTypeIdToName(id)) {
      case TASK_TYPES.SALES_MEETING_REPORT:
        return SALES_MEETING_REPORT;
      case TASK_TYPES.SALES_MEETING_FOLLOW_UP:
        return SALES_MEETING_FOLLOW_UP;
      case TASK_TYPES.MITT_BOLAN:
        return MITT_BOLAN;
      case TASK_TYPES.BLIXTVARDERINGAR:
        return BLIXTVARDERING;
      case TASK_TYPES.VARDEBAROMETER:
        return VARDEBAROMETERN;
      case TASK_TYPES.PRIVATE_SHOWING_FOLLOW_UP:
        return PRIVATE_SHOWING_FOLLOW_UP;
      case TASK_TYPES.FINAL_PRICE:
        return FINAL_PRICE;
      case TASK_TYPES.VARDEKOLLEN:
        return VARDEKOLLEN;
      case TASK_TYPES.CALLING_LIST:
        return CALLING_LIST;
      default:
        return undefined;
    }
  }

  resolveSidebarTaskOrLeadRoute(
    eaTaskId: string,
    eaTaskTypeId: string | number
  ): string[] {
    const segment = this.taskTypeIdToUrlSegment(eaTaskTypeId);
    return segment
      ? SIDEBAR_TASKS_DYNAMIC_SEGMENT_URL(segment, eaTaskId)
      : SIDEBAR_TASKS_DEFAULT_URL(eaTaskId);
  }

  getTaskRoute(
    task: Task,
    disablePrimaryRoute = false,
    disableSecondaryRoute = false
  ): any[] {
    return [
      "/crm",
      {
        outlets: {
          ...(disablePrimaryRoute
            ? {}
            : { primary: this.getPrimaryRoute(task) }),
          sidebar: disableSecondaryRoute
            ? []
            : this.resolveSidebarTaskOrLeadRoute(
                task.eaTaskId,
                task.eaTaskTypeId
              ),
        },
      },
    ];
  }

  getPrimaryRoute(task: Task): string[] {
    const module = new CrmModule(CONTACTS_CRM_MODULE);
    const isContactModuleActive = module.isModuleActive(this.router.url);
    if (task.contactId) {
      return ["contacts", task.contactId];
    }
    if (isContactModuleActive) {
      return ["dashboard", "todo-list"];
    }

    return ["dashboard", "todo-list"];
  }

  resolveIcon(task: Task): Observable<string> {
    return this.taskIcons$.pipe(
      first(),
      map((taskIcons) => {
        const taskIcon = taskIcons.find(
          (ti) => ti.task_type.toString() === task.eaTaskTypeId
        );
        if (taskIcon) {
          return taskIcon.icon_name;
        } else if (task.eaTaskTypeCategory === TASK_CATEGORIES.LEAD) {
          return this.taskTypeCategoryToIcon(task.eaTaskTypeCategory);
        } else {
          return this.taskTypeIdToIcon(task.eaTaskTypeId);
        }
      })
    );
  }

  resolveColor(task: Task) {
    if (task.eaTaskTypeCategory === TASK_CATEGORIES.LEAD) {
      return this.taskTypeCategoryToColor(task.eaTaskTypeCategory);
    }
    return this.taskTypeIdToColor(task.eaTaskTypeId);
  }

  onTaskClick(task: Task, openInNewTab = false) {
    this.store.pipe(select(isMobile), first()).subscribe((isMobile) => {
      if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        )
      ) {
        if (openInNewTab) {
          const url = this.urlService.buildUrlFromRouterCommands(
            this.getTaskRoute(
              task,
              this.checkIfDisablePrimaryRoute(task.eaTaskTypeId)
            )
          );
          openLinkInNewTab(url);
        } else {
          if (!this.checkIfDisablePrimaryRoute(task.eaTaskTypeId)) {
            this.store.dispatch(addBackLink({ backLink: this.router.url }));
          }
          this.store.dispatch(
            RouterActions.go({
              path: this.getTaskRoute(
                task,
                this.checkIfDisablePrimaryRoute(task.eaTaskTypeId)
              ),
            })
          );
        }
      } else {
        if (isMobile) {
          // Check if free lead(doesnt have a connected employee, and should therefore NOT disable secondary route)
          let disableSecondaryRoute = true;
          if (!task.eaEmployeeId && task.eaTaskTypeCategory === "lead") {
            disableSecondaryRoute = false;
          }

          if (openInNewTab) {
            const url = this.urlService.buildUrlFromRouterCommands(
              this.getTaskRoute(task, false, disableSecondaryRoute)
            );
            openLinkInNewTab(url);
          } else {
            this.store.dispatch(
              addQuickLink({
                parameters: {
                  route: this.getTaskRoute(task, disableSecondaryRoute),
                  label: getQuickLinkLabel(task),
                },
              })
            );
            this.store.dispatch(addBackLink({ backLink: this.router.url }));
            this.store.dispatch(
              RouterActions.go({
                path: this.getTaskRoute(task, false, disableSecondaryRoute),
              })
            );
          }
        } else {
          if (openInNewTab) {
            const url = this.urlService.buildUrlFromRouterCommands(
              this.getTaskRoute(task, false)
            );
            openLinkInNewTab(url);
          } else {
            this.store.dispatch(
              RouterActions.go({ path: this.getTaskRoute(task, false) })
            );
          }
        }
      }
    });
  }

  checkIfDisablePrimaryRoute(taskTypeId: string): boolean {
    return (
      this.taskTypeIdToName(Number(taskTypeId)) === TASK_TYPES.CALLING_LIST
    );
  }

  snoozeTask(task: Task) {
    const params: TaskUpdateParams = {
      eaTaskId: task.eaTaskId,
      deliveryDate: moment(task.deliveryDate)
        .add(1, "day")
        .format(API_DATE_FORMAT),
    };
    this.store.dispatch(updateTaskRequest({ task: params }));
  }
}
