<div class="field">
  <span class="text-bold">{{ label | translate }}:</span>
  <div>
    <span class="content-wrapper">
      <ng-content *ngIf="!editable"></ng-content>
      <ng-content *ngIf="!!editable" select="[display]"></ng-content>
      <div
        *ngIf="!!editable"
        class="edit-icon"
        [class.edit-icon--visible]="!hideEdit"
        placement="auto"
        [popover]="editTemplate"
        [tooltip]="'edit' | translate"
        [outsideClick]="true"
      >
        <app-icon size="none" name="pen"></app-icon>
      </div>
    </span>
  </div>
</div>

<ng-template #editTemplate>
  <ng-content select="[edit]"></ng-content>
</ng-template>
