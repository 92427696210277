import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import * as progressWidgetActions from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import {
  getProgressWidgetLoading,
  getProgressWidgetTaskSetsInProgress,
} from "@app/shared/modules/progress-widget/ngrx/progress-widget.reducer";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  concat,
  first,
  from,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";

@Injectable()
export class ProgressWidgetEffects {
  executeTaskSet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressWidgetActions.executeTaskSetRequest),
      switchMap(({ taskSet }) => {
        const requests = [];
        taskSet.tasks.forEach((task: Observable<any>) => {
          requests.push(
            task.pipe(
              map(() => {
                this.store.dispatch(
                  progressWidgetActions.incrementCompletedCount({
                    id: taskSet.id,
                  })
                );
                return of({ status: true, error: null });
              }),
              catchError((err) => {
                this.store.dispatch(
                  progressWidgetActions.incrementFailedCount({ id: taskSet.id })
                );
                return of({ status: false, error: err?.customMessage });
              })
            )
          );
        });

        let index = 0;
        let errorMessages = "";
        return concat(...requests).pipe(
          map((r) => {
            if (!!r && !!r["error"]) {
              errorMessages += r["error"];
              errorMessages += ", ";
            }

            index++;
            if (index === requests.length) {
              if (!!errorMessages) {
                errorMessages = errorMessages
                  .trim()
                  .slice(0, errorMessages.length - 2);
                errorMessages =
                  this.translateService.instant("operation_failed_in") +
                  "  " +
                  this.translateService.instant(taskSet.label) +
                  ": " +
                  errorMessages;
                this.store.dispatch(
                  toastActions.danger({
                    message: errorMessages,
                    duration: 10000,
                  })
                );
              }

              return progressWidgetActions.executeTaskSetSuccess({
                id: taskSet.id,
              });
            } else {
              return progressWidgetActions.executing();
            }
          }),
          catchError(() => of(progressWidgetActions.executeTaskSetFail()))
        );
      })
    )
  );

  executeTaskSetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressWidgetActions.executeTaskSetSuccess),
      switchMap(({ id }) =>
        this.store.pipe(
          select(getProgressWidgetTaskSetsInProgress),
          first(),
          switchMap((taskSets: TaskSet[]) => {
            if (
              taskSets.length === 0 ||
              (taskSets.length === 1 && taskSets[0].id === id)
            ) {
              setTimeout(
                () =>
                  this.store.dispatch(
                    progressWidgetActions.hideProgressWidget()
                  ),
                6000
              );
              return from([progressWidgetActions.showProgressWidget()]);
            } else {
              if (taskSets[0].id === id) {
                return of(
                  progressWidgetActions.executeTaskSetRequest({
                    taskSet: taskSets[1],
                  })
                );
              } else {
                return of(
                  progressWidgetActions.executeTaskSetRequest({
                    taskSet: taskSets[0],
                  })
                );
              }
            }
          })
        )
      )
    )
  );

  addTaskSetToQueue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(progressWidgetActions.addTaskSetToQueueRequest),
      switchMap(({ taskSet }) => {
        return this.store.pipe(
          select(getProgressWidgetLoading),
          first(),
          switchMap((loading: boolean) => {
            if (loading) {
              return of(progressWidgetActions.addTaskSetToQueueSuccess());
            } else {
              return of(
                progressWidgetActions.executeTaskSetRequest({ taskSet })
              );
            }
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}
}
