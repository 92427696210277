import { Component, Input } from "@angular/core";
import { Action } from "@app/models";
import { ActionService } from "../services/action.service";

@Component({
  selector: "action-list-item",
  templateUrl: "./action-list-item.component.html",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./action-list-item.component.scss",
  ],
})
export class ActionListItemComponent {
  @Input() item: Action;
  @Input() eaEmployeeId: string;

  constructor(private cas: ActionService) {}

  getColor(item: Action) {
    return this.cas.getColor(item);
  }

  getIcon(item: Action) {
    return this.cas.getIcon(item);
  }
}
