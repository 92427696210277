import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { Competition, Competitions } from "../models/competition.model";
import * as competitionsActions from "./competitions.actions";

export interface CompetitionsState {
  loaded: boolean;
  loading: boolean;
  finishedCompetitionsLoading: boolean;
  upcomingCompetitionsLoading: boolean;
  competitions: Competitions;
  finishedCompetitions: Competitions;
  upcomingCompetitions: Competitions;
  selectedCompetition: Competition | null;
  latestFetchAction: Action | null;
}

export const initialState: CompetitionsState = {
  loaded: false,
  loading: false,
  finishedCompetitionsLoading: false,
  upcomingCompetitionsLoading: false,
  competitions: null,
  finishedCompetitions: null,
  upcomingCompetitions: null,
  selectedCompetition: null,
  latestFetchAction: null,
};

export function competitionsReducer(
  state: CompetitionsState,
  action: Action
): CompetitionsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(competitionsActions.getCompetitionRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(competitionsActions.getCompetitionsRequest, (state, action) => ({
    ...state,
    loading: true,
    latestFetchAction: action,
  })),
  on(competitionsActions.getFinishedCompetitionsRequest, (state) => ({
    ...state,
    finishedCompetitionsLoading: true,
  })),
  on(competitionsActions.getUpcomingCompetitionsRequest, (state) => ({
    ...state,
    upcomingCompetitionsLoading: true,
  })),
  on(
    competitionsActions.getCompetitionFailed,
    competitionsActions.getCompetitionsFailed,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(competitionsActions.getCompetitionsSuccess, (state, { competitions }) => ({
    ...state,
    loading: false,
    loaded: true,
    competitions,
  })),
  on(competitionsActions.getFinishedCompetitionsFailed, (state) => ({
    ...state,
    finishedCompetitionsLoading: false,
  })),
  on(competitionsActions.getUpcomingCompetitionsFailed, (state) => ({
    ...state,
    upcomingCompetitionsLoading: false,
  })),
  on(
    competitionsActions.getFinishedCompetitionsSuccess,
    (state, { competitions }) => ({
      ...state,
      finishedCompetitionsLoading: false,
      loaded: true,
      finishedCompetitions: competitions,
    })
  ),
  on(
    competitionsActions.getUpcomingCompetitionsSuccess,
    (state, { competitions }) => ({
      ...state,
      upcomingCompetitionsLoading: false,
      loaded: true,
      upcomingCompetitions: competitions,
    })
  ),
  on(competitionsActions.getCompetitionSuccess, (state, { competition }) => ({
    ...state,
    loading: false,
    loaded: true,
    selectedCompetition: competition,
  })),
  on(competitionsActions.resetSelectedCompetition, (state) => ({
    ...state,
    selectedCompetition: initialState.selectedCompetition,
  })),
  on(competitionsActions.resetCompetitions, (state) => ({
    ...state,
    competitions: initialState.competitions,
  }))
);

const selectFeature = createFeatureSelector<CompetitionsState>("competitions");

export const getLoading = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.loading
);

export const getFinishedCompetitionsLoading = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.finishedCompetitionsLoading
);

export const getUpcomingCompetitionsLoading = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.upcomingCompetitionsLoading
);

export const getCompetitions = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.competitions
);

export const getFinishedCompetitions = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.finishedCompetitions
);

export const getUpcomingCompetitions = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.upcomingCompetitions
);

export const getSelectedCompetition = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.selectedCompetition
);

export const getLatestActionCompetition = createSelector(
  selectFeature,
  (state: CompetitionsState) => state.latestFetchAction
);
