import {
  SalesMeetingSummaryStatisticsEmployeeResponse,
  SalesMeetingSummaryStatisticsOfficeResponse,
  SalesMeetingSummaryStatisticsSourceResponse,
  SalesMeetingSummaryStatisticsTotalResponse,
} from "@app/statistics/boxes/sales-meetings/utils";
import {
  SalesMeetingStatisticsEmployeeResponse,
  SalesMeetingStatisticsOfficeResponse,
  SalesMeetingStatisticsTotalResponse,
} from "../utils";
import { createAction, props } from "@ngrx/store";

// Results
export const getResultsForChainRequest = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForChainSuccess = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_CHAIN_SUCCESS",
  props<{
    total: SalesMeetingStatisticsTotalResponse;
    offices: SalesMeetingStatisticsOfficeResponse[];
  }>()
);

export const getResultsForChainFail = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_CHAIN_FAIL"
);

export const getResultsForOfficeRequest = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_OFFICE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForOfficeSuccess = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_OFFICE_SUCCESS",
  props<{
    total: SalesMeetingStatisticsTotalResponse;
    employees: SalesMeetingStatisticsEmployeeResponse[];
  }>()
);

export const getResultsForOfficeFail = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_OFFICE_FAIL"
);

export const getResultsForEmployeeRequest = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_EMPLOYEE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForEmployeeSuccess = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_EMPLOYEE_SUCCESS",
  props<{ employee: SalesMeetingStatisticsEmployeeResponse }>()
);

export const getResultsForEmployeeFail = createAction(
  "[Sales Meeting Results] GET_RESULTS_FOR_EMPLOYEE_FAIL"
);

// Booked
export const getBookedForChainRequest = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getBookedForChainSuccess = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_CHAIN_SUCCESS",
  props<{
    total: SalesMeetingSummaryStatisticsTotalResponse;
    offices: SalesMeetingSummaryStatisticsOfficeResponse[];
  }>()
);

export const getBookedForChainFail = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_CHAIN_FAIL"
);

export const getBookedForOfficeRequest = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_OFFICE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getBookedForOfficeSuccess = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_OFFICE_SUCCESS",
  props<{
    total: SalesMeetingSummaryStatisticsTotalResponse;
    employees: SalesMeetingSummaryStatisticsEmployeeResponse[];
  }>()
);

export const getBookedForOfficeFail = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_OFFICE_FAIL"
);

export const getBookedForEmployeeRequest = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_EMPLOYEE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getBookedForEmployeeSuccess = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_EMPLOYEE_SUCCESS",
  props<{ employee: SalesMeetingSummaryStatisticsEmployeeResponse }>()
);

export const getBookedForEmployeeFail = createAction(
  "[Sales Meeting Booked] GET_BOOKED_FOR_EMPLOYEE_FAIL"
);

// Sources
export const getSourcesRequest = createAction(
  "[Sales Meeting Sources] GET_SOURCES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSourcesSuccess = createAction(
  "[Sales Meeting Sources] GET_SOURCES_SUCCESS",
  props<{
    total: SalesMeetingSummaryStatisticsTotalResponse;
    sources: SalesMeetingSummaryStatisticsSourceResponse[];
  }>()
);

export const getSourcesFail = createAction(
  "[Sales Meeting Sources] GET_SOURCES_FAIL"
);

export const setFilters = createAction(
  "[Sales Meeting Sources] SET_FILTERS",
  props<{ filters: Record<string, unknown> }>()
);
