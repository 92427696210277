import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import { createAction, props } from "@ngrx/store";

export interface ObjectField {
  field: string;
}

export interface ObjectTypeSpecificRequirement {
  objectType: string;
  requirements: ObjectField[];
  optionalFields: ObjectField[];
}

export interface DelegatedObjectRequirements {
  integration: string;
  objectTypes: string[];
  objectTypeDescription: {
    type: string;
    objectTypeSpecificRequirements: ObjectTypeSpecificRequirement[];
  };
  baseRequireMents: ObjectField[];
}

export interface CreateObjectParams {
  apiParams: any;
  createMode: string;
}

export const getObjectRequirementsRequest = createAction(
  "[CreateObjectSidebar] GET_OBJECT_REQUIREMENTS_REQUEST"
);

export const getObjectRequirementsSuccess = createAction(
  "[CreateObjectSidebar] GET_OBJECT_REQUIREMENTS_SUCCESS",
  props<{ payload: DelegatedObjectRequirements }>()
);

export const getObjectRequirementsFailed = createAction(
  "[CreateObjectSidebar] GET_OBJECT_REQUIREMENTS_FAILED"
);

export const storeObjectRequest = createAction(
  "[CreateObjectSidebar] STORE_OBJECT_REQUEST",
  props<{ params: CreateObjectParams }>()
);

export const storeObjectSuccess = createAction(
  "[CreateObjectSidebar] STORE_OBJECT_SUCCESS",
  props<{ payload: ExternalObjectMetadata }>()
);

export const storeObjectFailed = createAction(
  "[CreateObjectSidebar] STORE_OBJECT_FAILED"
);
