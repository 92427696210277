export const nl2br = (str: string) => {
  if (!str) {
    return str;
  }
  return str.replace(/(?:\r\n|\r|\n)/g, "<br>");
};

export const isNotNullOrUndefined = (value: unknown) =>
  !(typeof value === "undefined" || value === null);

export const capitalizeFirstLetter = (value: string | undefined | null) => {
  return (value && value[0].toUpperCase() + value.slice(1)) || "";
};

export const reversePair = (pair: string, seperator: string): string => {
  const value = pair.split(seperator);
  const reversed = value.slice().reverse();
  return reversed.join(seperator);
};

export const hashCode = (text: string) => {
  let hash = 0;
  if (text.length == 0) {
    return hash;
  }

  for (let index = 0; index < text.length; index++) {
    const char = text.charCodeAt(index);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};
