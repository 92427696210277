import { createAction, props } from "@ngrx/store";
import { TypedPaginationListDTO } from "@app/models";
import { Campaign } from "@app/lists/lists-outgoing-messages/models/outgoing-messages";

export const getCampaignsRequest = createAction(
  "[Lists Outgoing Messages] GET_CAMPAIGNS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getCampaignsSuccess = createAction(
  "[Lists Outgoing Messages] GET_CAMPAIGNS_SUCCESS",
  props<{ campaigns: TypedPaginationListDTO<Campaign> }>()
);

export const getCampaignsFail = createAction(
  "[Lists Outgoing Messages] GET_CAMPAIGNS_FAIL"
);

export const reset = createAction("[Lists Outgoing Messages] RESET");
