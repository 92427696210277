import { Constructor } from "./mixin";

export function Deserializable<T extends Constructor>(Base: T) {
  return class extends Base {
    constructor(...args: any[]) {
      super(...args);
      const keys = Object.keys(this);

      for (const key of keys) {
        if (args[0].hasOwnProperty(key)) {
          this[key] = args[0][key];
        }
      }
    }
  };
}
