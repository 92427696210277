<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'create_broker' | translate"></sidebar-header>
  <div class="scroll-view">
    <section>
      <employee-form
        #form
        [type]="tabType"
        [countryCode]="countryCode$ | async"
        [countryCodeNumbers]="phonePrefix$ | async"
        [languageCode]="language$ | async"
        (onSubmit)="onSubmit($event)"
      ></employee-form>
    </section>
  </div>
  <div class="sidebar-footer">
    <button
      tabindex="-1"
      type="button"
      class="btn btn-cancel btn-lg"
      (click)="closeTab()"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      translate
      type="button"
      class="btn btn-success btn-lg"
      [class.icon-spinner]="processing$ | async"
      [disabled]="processing$ | async"
      (click)="form.validate()"
    >save
    </button>
  </div>
</div>
