import { Route } from "@angular/router";
import { ValueMonitorGuard } from "@app/sidebar/guards/value-monitor-guard.service";
import { ValueMonitorCreateComponent } from "@app/sidebar/value-monitor/containers/value-monitor-create/value-monitor-create.component";
import { ValueMonitorEditComponent } from "@app/sidebar/value-monitor/containers/value-monitor-edit/value-monitor-edit.component";

export const sidebarValueMonitorRoutes: Route = {
  path: "value-monitor",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: ValueMonitorCreateComponent,
      canActivate: [ValueMonitorGuard],
    },
    {
      path: ":id/edit",
      component: ValueMonitorEditComponent,
      canActivate: [ValueMonitorGuard],
    },
  ],
};
