import {
  InvoicingPeriodParams,
  InvoicingPeriodResponse,
} from "@app/statistics/statistics-invoicing/models/invoicing-models";
import { createAction, props } from "@ngrx/store";

export const getInvoicingPeriodsRequest = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getInvoicingPeriodsSuccess = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_SUCCESS",
  props<{ invoicingPeriods: InvoicingPeriodResponse[] }>()
);

export const getInvoicingPeriodsFailure = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_FAILURE"
);

export const storeInvoicingPeriodRequest = createAction(
  "[Create Invoice] STORE_INVOICING_PERIOD_REQUEST",
  props<{ params: InvoicingPeriodResponse }>()
);

export const storeInvoicingPeriodSuccess = createAction(
  "[Create Invoice] STORE_INVOICING_PERIOD_SUCCESS"
);

export const storeInvoicingPeriodFailure = createAction(
  "[Create Invoice] STORE_INVOICING_PERIOD_FAILURE"
);

export const deleteInvoicingPeriodRequest = createAction(
  "[Create Invoice] DELETE_INVOICING_PERIOD_REQUEST",
  props<{ invoicePeriodId: string }>()
);

export const deleteInvoicingPeriodSuccess = createAction(
  "[Create Invoice] DELETE_INVOICING_PERIOD_SUCCESS"
);

export const deleteInvoicingPeriodFailure = createAction(
  "[Create Invoice] DELETE_INVOICING_PERIOD_FAILURE"
);

export const getInvoicingPeriodsCSVRequest = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_CSV_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getInvoicingPeriodsCSVSuccess = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const getInvoicingPeriodsCSVFailure = createAction(
  "[Create Invoice] GET_INVOICING_PERIODS_CSV_FAILURE"
);
