import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SkeletonCircleComponent } from "./skeleton-circle/skeleton-circle.component";
import { SkeletonRectangleComponent } from "./skeleton-rectangle/skeleton-rectangle.component";
import { SkeletonSidebarComponent } from "./skeleton-sidebar/skeleton-sidebar.component";
import { SkeletonTableComponent } from "./skeleton-table/skeleton-table.component";

@NgModule({
  imports: [CommonModule],
  declarations: [
    SkeletonRectangleComponent,
    SkeletonCircleComponent,
    SkeletonSidebarComponent,
    SkeletonTableComponent,
  ],
  exports: [
    SkeletonRectangleComponent,
    SkeletonCircleComponent,
    SkeletonSidebarComponent,
    SkeletonTableComponent,
  ],
})
export class SkeletonScreenModule {}
