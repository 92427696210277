import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { Survey, SurveyResponse } from "@app/models/survey";
import { Tag } from "@app/models/tags";
import * as fromUser from "@app/shared/user/user.selectors";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
  withLatestFrom,
} from "rxjs";
import * as RouterActions from "../core/ngrx/router/router.actions";
import { ApiService } from "../core/services/api/api.service";
import { Contact, TypedPaginationListDTO } from "../models";
import * as contactActions from "./contact.actions";
import { CreateNoteParams } from "./contact.actions";
import { ContactProfileResponse } from "./contact.reducer";

interface ContactOfficeTagSuggestionResponse {
  eaOfficeId: string;
  officeName: string;
  tagSuggestions: Tag[];
}

interface ContactTagSuggestionsResponse {
  contactId: string;
  globalTagSuggestions: Tag[];
  officeTagSuggestions: ContactOfficeTagSuggestionResponse[];
}

interface ContactTags {
  contactId: string;
  tags: Tag[];
}

@Injectable()
export class ContactEffects {
  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getContactRequest),
      switchMap(({ id }) =>
        this.apiService
          .get(`contacts/${id}`, { getEmployees: true, getSSN: true })
          .pipe(
            map((contact: any) =>
              contactActions.getContactSuccess({
                contact: new Contact(contact),
              })
            ),
            catchError(() =>
              observableFrom([
                contactActions.getContactFailed(),
                RouterActions.go({ path: ["/crm", "page-not-found"] }),
              ])
            )
          )
      )
    )
  );

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getContactProfileRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/profile`).pipe(
          map((profile: ContactProfileResponse) =>
            contactActions.getContactProfileSuccess({
              ownsResidence: profile.ownsResidence,
            })
          ),
          catchError(() =>
            observableOf(contactActions.getContactProfileFailed())
          )
        )
      )
    )
  );

  updateProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.updateContactProfileRequest),
      switchMap(({ parameters: { contactId, parameters } }) =>
        this.apiService.patch(`contacts/${contactId}/profile`, parameters).pipe(
          map((response: ContactProfileResponse) =>
            contactActions.updateContactProfileSuccess({
              contactId: response.contactId,
            })
          ),
          catchError(() =>
            observableOf(contactActions.updateContactProfileFailed())
          )
        )
      )
    )
  );

  updateProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.updateContactProfileSuccess),
      map(({ contactId }) =>
        contactActions.getContactProfileRequest({ contactId })
      )
    )
  );

  createConsumerCallActionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.createConsumerCallActionRequest),
      switchMap(({ contactId }) => {
        const params = {
          code: ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
        };
        return this.apiService
          .post(`contacts/${contactId}/actions`, params)
          .pipe(
            map(() =>
              contactActions.createConsumerCallActionSuccess({ contactId })
            ),
            catchError(() =>
              observableOf(contactActions.createConsumerCallActionFailed())
            )
          );
      })
    )
  );

  createConsumerCallActionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.createConsumerCallActionSuccess),
      map(({ contactId }) =>
        contactActions.getLatestConsumerCallActionRequest({ contactId })
      )
    )
  );

  createConsumerCallActionSuccessShowToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.createConsumerCallActionSuccess),
      map(() => toastActions.success({ message: "sales_call_registered" }))
    )
  );

  getLatestConsumerCallActionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getLatestConsumerCallActionRequest),
      switchMap(({ contactId }) => {
        const params = {
          codes: [
            ContactActionCodes.CONSUMER_ONE2ONE_SMS,
            ContactActionCodes.CONSUMER_ONE2ONE_EMAIL,
            ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
            ContactActionCodes.CONSUMER_TASK_COMPLETED,
            ContactActionCodes.CONSUMER_LEAD_RESOLVED,
            ContactActionCodes.CONSUMER_CRM_PREGENERTATED,
            ContactActionCodes.CONSUMER_CRM_DELETED,
          ],
          contactId,
          sortBy: "timestamp",
          sortOrder: "desc",
          limit: 1,
        };
        return this.apiService.get(`actions`, params).pipe(
          map((response: any) => {
            const latestContactedDate =
              response.rows.length > 0 ? response.rows[0].timestamp : "";
            return contactActions.getLatestConsumerCallActionSuccess({
              latestContactedDate,
            });
          }),
          catchError(() =>
            observableOf(contactActions.getLatestConsumerCallActionFailed())
          )
        );
      })
    )
  );

  createNoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.createNoteRequest),
      withLatestFrom(
        this.store.pipe(fromUser.getEaIds),
        ({ parameters }, ids) => ({
          ...parameters,
          ...(<object>ids),
        })
      ),
      withLatestFrom(
        this.store.pipe(select(fromContact.getContact)),
        (payload, contact) => ({
          ...payload,
          contactId: contact.contactId,
        })
      ),
      switchMap((params: CreateNoteParams) =>
        this.apiService.post("notes", params).pipe(
          map(() => contactActions.createNoteSuccess()),
          catchError(() => observableOf(contactActions.createNoteFail()))
        )
      )
    )
  );

  createNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.createNoteSuccess),
      switchMap(() => this.translateService.get("note_saved")),
      map((message) => toastActions.success({ message }))
    )
  );

  getContactSurveysRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getContactSurveysRequest),
      switchMap(({ contactId }) =>
        this.apiService
          .get("survey-responses/search", { contactId })
          .pipe(
            map((response: TypedPaginationListDTO<Survey>) => response.rows)
          )
          .pipe(
            map((surveys: Survey[]) =>
              contactActions.getContactSurveysSuccess({ surveys })
            ),
            catchError(() =>
              observableOf(contactActions.getContactSurveysFailed())
            )
          )
      )
    )
  );

  getContactTagSuggestionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getContactTagSuggestionsRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/tags-suggestions`).pipe(
          map((response: ContactTagSuggestionsResponse) => {
            let tagSuggestions = response.globalTagSuggestions;
            if (response.officeTagSuggestions.length > 0) {
              response.officeTagSuggestions.forEach(
                (officeTags: ContactOfficeTagSuggestionResponse) => {
                  tagSuggestions = tagSuggestions.concat(
                    officeTags.tagSuggestions
                  );
                }
              );
            }
            return contactActions.getContactTagSuggestionsSuccess({
              tagSuggestions,
            });
          }),
          catchError(() =>
            observableOf(contactActions.getContactTagSuggestionsFailed())
          )
        )
      )
    )
  );

  storeTagToContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.storeTagToContactRequest),
      switchMap(({ parameters: { contactId, tag } }) =>
        this.apiService
          .patch(`contacts/${contactId}/tag`, {
            source: "CRM",
            tagSuggestionId: tag.tagsSuggestionId,
          })
          .pipe(
            map(() => contactActions.storeTagToContactSuccess()),
            catchError(() =>
              observableOf(contactActions.storeTagToContactFailed())
            )
          )
      )
    )
  );

  deleteTagFromContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.deleteTagFromContactRequest),
      switchMap(({ parameters: { contactId, tag } }) =>
        this.apiService.delete(`contacts/${contactId}/tag/${tag.tagId}`).pipe(
          map(() => contactActions.deleteTagFromContactSuccess()),
          catchError(() =>
            observableOf(contactActions.deleteTagFromContactFailed())
          )
        )
      )
    )
  );

  getContactTagsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getTagsForContactRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/tags`).pipe(
          map((response: ContactTags) =>
            contactActions.getTagsForContactSuccess({
              contactTags: response.tags,
            })
          ),
          catchError(() =>
            observableOf(contactActions.getTagsForContactFailed())
          )
        )
      )
    )
  );

  getContactNpsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.getContactNpsRequest),
      switchMap(({ parameters: { searchParams } }) =>
        this.apiService
          .get("survey-responses/search", { ...searchParams })
          .pipe(
            map(
              (response: TypedPaginationListDTO<SurveyResponse>) =>
                response.rows[0]
            ),
            map((response: any) =>
              contactActions.getContactNpsSuccess({ nps: response })
            ),
            catchError(() => observableOf(contactActions.getContactNpsFailed()))
          )
      )
    )
  );

  addContactTagSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.storeTagToContactSuccess),
      map(() => toastActions.success({ message: "tag_added_successfully" }))
    )
  );

  removeTagFromContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.deleteTagFromContactSuccess),
      map(() => toastActions.success({ message: "tag_deleted_successfully" }))
    )
  );

  addContactTagFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.storeTagToContactFailed),
      map(() => toastActions.danger({ message: "general_error" }))
    )
  );

  removeTagFromContactFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(contactActions.deleteTagFromContactFailed),
      map(() => toastActions.success({ message: "general_error" }))
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}
}
