import { SearchProfileList } from "@app/lists/lists-search-profiles/ngrx/lists-search-profiles.reducer";
import { createAction, props } from "@ngrx/store";
import { PaginationListDTO } from "@app/models";

const defaultFilters = {
  limit: 20,
  offset: 0,
};

export const getSearchProfiles = (filters: Record<string, unknown>) => {
  return getSearchProfilesRequest({
    filters: {
      ...defaultFilters,
      ...filters,
    },
  });
};

export const getSearchProfilesRequest = createAction(
  "[Lists Search Profiles] GET_SEARCH_PROFILES_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getSearchProfilesSuccess = createAction(
  "[Lists Search Profiles] GET_SEARCH_PROFILES_SUCCESS",
  props<{
    rows: SearchProfileList[];
    total: number;
    limit: number;
    offset: number;
  }>()
);

export const getSearchProfilesFailed = createAction(
  "[Lists Search Profiles] GET_SEARCH_PROFILES_FAILED"
);

export const getMoreSearchProfiles = (filters: Record<string, unknown>) => {
  return getMoreSearchProfilesRequest({
    filters: {
      ...defaultFilters,
      ...filters,
    },
  });
};

export const getMoreSearchProfilesRequest = createAction(
  "[Lists Search Profiles] GET_MORE_SEARCH_PROFILES_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreSearchProfilesSuccess = createAction(
  "[Lists Search Profiles] GET_MORE_SEARCH_PROFILES_SUCCESS",
  props<{
    rows: SearchProfileList[];
    total: number;
    limit: number;
    offset: number;
  }>()
);

export const getMoreSearchProfilesFailed = createAction(
  "[Lists Search Profiles] GET_MORE_SEARCH_PROFILES_FAILED"
);

export const selectAllSearchProfiles = (filters: Record<string, unknown>) => {
  return selectAllSearchProfilesRequest({
    filters: {
      ...defaultFilters,
      ...filters,
    },
  });
};

export const selectAllSearchProfilesRequest = createAction(
  "[Lists Search Profiles] SELECT_ALL_SEARCH_PROFILES_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const selectAllSearchProfilesSuccess = createAction(
  "[Lists Search Profiles] SELECT_ALL_SEARCH_PROFILES_SUCCESS",
  props<{ payload: PaginationListDTO }>()
);

export const selectAllSearchProfilesFail = createAction(
  "[Lists Search Profiles] SELECT_ALL_SEARCH_PROFILES_FAIL"
);

export const reset = createAction("[Lists Search Profiles] RESET");
