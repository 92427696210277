import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ContactRelationService } from "@app/core/ngrx/entity-services/contact-relation.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { hardTrimBody } from "@app/core/services/api/api.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, ContactProfile } from "@app/models";
import { getCreateContactTypesConfig } from "@app/shared/config/config.reducer";
import { CreateContactTypeConfig } from "@app/shared/config/models/create_contact_config";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { getEaIds, UserIds } from "@app/shared/user";
import {
  CONTACT_CREATE,
  CONTACT_CREATE_POTENTIAL_BUYER,
} from "@app/shared/utils/tab-types";
import * as ccActions from "@app/sidebar/contacts/ngrx/create-contact.actions";
import * as fromCreateContact from "@app/sidebar/contacts/ngrx/create-contact.reducer";
import { CompanyFormComponent } from "@app/sidebar/shared/company-form/company-form.component";
import { EstateFormComponent } from "@app/sidebar/shared/estate-form/estate-form.component";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  debounceTime,
  filter,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import { ContactFormComponent } from "../../shared/contact-form/contact-form.component";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as fromSidebar from "../../ngrx/sidebar.reducer";

@Component({
  selector: "create-contact",
  templateUrl: "./create-contact.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-contact.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateContactComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;

  @ViewChild("companyForm", { static: false })
  companyForm: CompanyFormComponent;

  @ViewChild("estateForm", { static: false })
  estateForm: EstateFormComponent;

  get activeForm() {
    if (this.selectedContactType === "person") {
      return this.contactForm;
    } else if (this.selectedContactType === "company") {
      return this.companyForm;
    } else if (this.selectedContactType === "estate") {
      return this.estateForm;
    } else {
      return null;
    }
  }

  tabType = CONTACT_CREATE;
  tab$: Observable<SidebarTab>;
  prefillData$: Observable<Partial<Contact>>;
  unsubscribe$ = new Subject<void>();
  eaIds$: Observable<UserIds>;
  contactTypesConfig$: Observable<CreateContactTypeConfig[]>;
  closeSidebar = true;

  contactTypeControls$: Observable<SegmentControls>;
  selectedContactType: "person" | "company" | "estate";

  isBusy$: Observable<{ status: boolean }>;

  constructor(
    private store: Store<AppState>,
    private phoneNumberService: PhoneNumberService,
    private contactService: ContactService,
    private contactRelationService: ContactRelationService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.contactTypeControls$ = this.store.pipe(
      select(getCreateContactTypesConfig),
      tap((contactTypes) => (this.selectedContactType = contactTypes[0].type)),
      map((contactTypes) => {
        return contactTypes.map((type) => ({
          label: type.type,
          value: type.type,
          icon: this.getIcon(type.type),
        }));
      })
    );
    this.prefillData$ = this.store
      .pipe(select(fromCreateContact.getPrefillData))
      .pipe(filter((value) => !!value && !_.isEmpty(value)));
    this.eaIds$ = this.store.pipe(getEaIds);
    this.isBusy$ = this.contactService.loading$.pipe(
      map((loading) => ({ status: loading }))
    );
  }

  ngAfterViewInit() {
    this.tab$.pipe(take(1)).subscribe((tab) => {
      if (tab.dirty) {
        this.contactForm.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: this.contactForm.form.value,
          })
        );
      }
      this.startValueChangesStream();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  startValueChangesStream() {
    this.contactForm.form.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setTabValue({
            tabType: this.tabType,
            value: formValues,
          })
        )
      );
  }

  submit(closeSidebar): void {
    this.closeSidebar = closeSidebar;
    this.activeForm.submit();
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  handleSubmit(valid: boolean): void {
    if (valid) {
      if (this.selectedContactType === "person") {
        this.submitPerson();
      } else if (this.selectedContactType === "company") {
        this.submitCompany();
      } else if (this.selectedContactType === "estate") {
        this.submitEstate();
      }
    }
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(sidebarActions.resetDirty({ tabType: CONTACT_CREATE }));

    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              primary: ["contacts", contact.contactId],
              sidebar: null,
            },
          },
        ],
      })
    );
  }

  private submitPerson() {
    this.contactForm
      .getCreateContactRequestParams()
      .pipe(first())
      .subscribe(({ params, source }) => {
        let contactProfileParams;
        if (this.contactForm.form.get("ownsResidence").value) {
          contactProfileParams = {
            ownsResidence: this.contactForm.form.get("ownsResidence").value,
          };
        }
        this.createContact(hardTrimBody(params), source, contactProfileParams);
      });
  }

  private submitCompany() {
    this.eaIds$.pipe(first()).subscribe((ids) => {
      const msisdn = this.phoneNumberService.toLegacyFormat(
        this.companyForm.form.get("msisdn").value,
        this.companyForm.form.get("msisdnCountry").value
      );
      const params = {
        ...ids,
        ...this.companyForm.form.value,
        contactType: "company",
        msisdn,
      };
      this.createContact(hardTrimBody(params));
    });
  }

  private submitEstate() {
    this.eaIds$.pipe(first()).subscribe((ids) => {
      const msisdn = this.phoneNumberService.toLegacyFormat(
        this.estateForm.form.get("estateContact").get("msisdn").value,
        this.estateForm.form.get("estateContact").get("msisdnCountry").value
      );
      const estateParams = {
        ...ids,
        ...this.estateForm.form.get("estate").value,
        contactType: "estate",
        email: this.estateForm.form.get("estateContact").get("email").value,
        msisdn,
      };
      const estateContactParams = {
        ...ids,
        ...this.estateForm.form.get("estateContact").value,
        contactType: "person",
        msisdn,
      };

      // Make estateContactParams optional, but check if ERP === "kivi"

      this.contactService
        .post(estateParams)
        .pipe(
          switchMap((estate: Contact) => {
            if (this.estateForm.form.get("estateContact").valid) {
              return this.contactService.post(estateContactParams, false).pipe(
                switchMap((estateContact) =>
                  this.contactRelationService.post(
                    {
                      relatedToContactId: estate.contactId,
                      relationshipType: "estatePrimaryContact",
                    },
                    estateContact.contactId
                  )
                )
              );
            } else {
              return of(true);
            }
          })
        )
        .subscribe(() => {
          if (this.closeSidebar) {
            this.store.dispatch(
              sidebarActions.closeTabs({
                tabTypes: [CONTACT_CREATE, CONTACT_CREATE_POTENTIAL_BUYER],
              })
            );
          } else {
            this.estateForm?.form?.reset();
            this.contactForm?.form?.reset();
            this.companyForm?.form?.reset();
          }
        });
    });
  }

  private createContact(
    params,
    source?: any,
    patchProfileParams?: Partial<ContactProfile>
  ) {
    this.contactService
      .post(params)
      .pipe(
        map((contact: Contact) => {
          if (!!source) {
            this.store.dispatch(
              ccActions.addContactToSourceRequest({ params })
            );
          }
          if (!!patchProfileParams) {
            this.contactService
              .patchProfile(contact.contactId, patchProfileParams, () => {})
              .subscribe();
          }
          if (this.closeSidebar) {
            this.store.dispatch(
              sidebarActions.closeTabs({
                tabTypes: [CONTACT_CREATE, CONTACT_CREATE_POTENTIAL_BUYER],
              })
            );
          } else {
            this.estateForm?.form?.reset();
            this.contactForm?.form?.reset();
            this.companyForm?.form?.reset();
          }
        })
      )
      .subscribe();
  }

  private getIcon(type) {
    if (type === "company") {
      return "building";
    } else if (type === "estate") {
      return "house-night";
    } else {
      return "user";
    }
  }
}
