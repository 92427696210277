<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'add_potential_buyer' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <div class="contact-form-container">
      <contact-form
        *ngIf="!existingContactSelected; else contactCard"
        #contactForm
        [observer]="proxy$"
        [preselectedSource]="potentialBuyerTaskTypeId$ | async"
        (onSubmit)="handleSubmit($event)"
        (onQuedroContactSelected)="onQuedroContactSelected($event)"
      ></contact-form>
    </div>
    <section class="showings-container">
      <div class="showing-details-header">
        <h4>
          {{ "showing_status" | translate }}
        </h4>
        <span
          *ngIf="attendanceStatuses.length > 0"
          (click)="onResetAttendance()"
          class="reset-attendance"
          translate
        >
          reset <i class="icon-reload"></i>
        </span>
      </div>
      <ng-container *ngIf="(showings$ | async)?.length > 0; else noShowings">
        <mat-accordion>
          <app-attendance-status
            *ngFor="let showing of showings$ | async"
            [showDeleteButton]="false"
            [config]="featureConfigManager.getShowingAttendanceConfig() | async"
            [showing]="showing"
            [attendance]="getAttendance(showing.showingId)"
            [slotAttendances]="getSlotAttendances(showing.showingId)"
            (statusChange)="
              handleAttendanceStatusChange(
                showing.showingId,
                $event,
                showing.from
              )
            "
          >
          </app-attendance-status>
        </mat-accordion>
      </ng-container>
    </section>
  </div>

  <div *ngIf="isBusy$ | async as isBusy" class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      tabindex="-1"
      buttonType="save"
      label="save_and_create"
      [isLoading]="isBusy.status"
      [disabled]="isBusy.status"
      (click)="submit(false)"
      scrollToInvalidControl
      [formEl]="contactForm?.form"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [isLoading]="isBusy.status"
      [disabled]="isBusy.status"
      (click)="submit(true)"
      scrollToInvalidControl
      [formEl]="contactForm?.form"
    >
    </app-q-button>
  </div>
</div>

<ng-template #noShowings>
  <alert type="info">{{ "no_showings" | translate }}</alert>
</ng-template>

<ng-template #contactCard>
  <section class="showings-container">
    <card
      class="receivers__card"
      [color]="contactsColor"
      [removable]="true"
      (remove)="existingContactSelected = null"
    >
      <div title>{{ existingContactSelected.getFullName() }}</div>
      <div body>
        <small>{{ existingContactSelected.email }}</small>
        <br />
        <small>{{ existingContactSelected.msisdn }}</small>
      </div>
    </card>
  </section>
</ng-template>
