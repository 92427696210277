import { TypesOfNonRecurringItem } from "@app/settings/invoicing/components/types-of-non-recurring-items/ngrx/types-of-non-recurring-items.reducer";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./office-card.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface RecurringItem {
  amount: string;
  eaOfficeId: string;
  invoiceOrderId: string;
  isActive: boolean;
  multiplyWithNumberOfSoldObjects: true;
  note: string;
  productNumber: string;
  quantity: string;
}

export interface OneTimeOrder {
  amount: string;
  eaOfficeId: string;
  invoiceOrderId: string;
  invoicePeriodId: string;
  allowedToEditOrDeleteOneTimeTypeOrder: boolean;
  note: string;
  oneTimeTypeId: string;
  typeName: string;
}

export interface OfficeCardState {
  customerNumber: string;
  customerNumberLoading: boolean;
  customerNumberStoring: boolean;
  customerNumberUpdating: boolean;
  recurringItems: RecurringItem[];
  recurringItemsLoading: boolean;
  recurringItemsStoring: boolean;
  recurringItemsUpdating: boolean;
  recurringItemsDeleting: boolean;
  oneTimeOrders: OneTimeOrder[];
  oneTimeOrdersLoading: boolean;
  oneTimeOrdersStoring: boolean;
  oneTimeOrdersUpdating: boolean;
  oneTimeOrdersDeleting: boolean;
  oneTimeTypes: TypesOfNonRecurringItem[];
  oneTimeTypesLoading: boolean;
  feeTypeOrders: FeeTypeOrderResponse[];
  feeTypesLoading: boolean;
  feeTypesStoring: boolean;
  feeTypesUpdating: boolean;
  feeTypesDeleting: boolean;
}

export const initialState: OfficeCardState = {
  customerNumber: "",
  customerNumberLoading: false,
  customerNumberStoring: false,
  customerNumberUpdating: false,
  recurringItems: [],
  recurringItemsLoading: false,
  recurringItemsStoring: false,
  recurringItemsUpdating: false,
  recurringItemsDeleting: false,
  oneTimeOrders: [],
  oneTimeOrdersLoading: false,
  oneTimeOrdersStoring: false,
  oneTimeOrdersUpdating: false,
  oneTimeOrdersDeleting: false,
  oneTimeTypes: [],
  oneTimeTypesLoading: false,
  feeTypeOrders: [],
  feeTypesLoading: false,
  feeTypesStoring: false,
  feeTypesUpdating: false,
  feeTypesDeleting: false,
};

export interface FeeTypeOrder {
  aggregatedAmountThreshold: string;
  invoiceFeeTypeId: string;
  invoiceOrderId: string;
  note: string;
  percentageValue: string;
}

export interface FeeTypeOrderResponse {
  active: boolean;
  invoiceFeeTypeId: string;
  name: string;
  orders: FeeTypeOrder[];
  productNumber: string;
}

export function officeCardReducer(
  state: OfficeCardState,
  action: Action
): OfficeCardState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getCustomerNumberRequest, (state) => ({
    ...state,
    customerNumberLoading: true,
  })),
  on(actions.getCustomerNumberSuccess, (state, { customerNumber }) => ({
    ...state,
    customerNumber,
    customerNumberLoading: false,
  })),
  on(actions.getCustomerNumberFailed, (state) => ({
    ...state,
    customerNumber: "",
    customerNumberLoading: false,
  })),
  on(actions.storeCustomerNumberRequest, (state) => ({
    ...state,
    customerNumberStoring: true,
  })),
  on(
    actions.storeCustomerNumberSuccess,
    actions.storeCustomerNumberFailed,
    (state) => ({
      ...state,
      customerNumberStoring: false,
    })
  ),
  on(actions.updateCustomerNumberRequest, (state) => ({
    ...state,
    customerNumberUpdating: true,
  })),
  on(
    actions.updateCustomerNumberSuccess,
    actions.updateCustomerNumberFailed,
    (state) => ({
      ...state,
      customerNumberUpdating: false,
    })
  ),
  on(actions.getRecurringItemsRequest, (state) => ({
    ...state,
    recurringItemsLoading: true,
  })),
  on(actions.getRecurringItemsSuccess, (state, { recurringItems }) => ({
    ...state,
    recurringItems,
    recurringItemsLoading: false,
  })),
  on(actions.getRecurringItemsFailed, (state) => ({
    ...state,
    recurringItems: [],
    recurringItemsLoading: false,
  })),
  on(actions.storeRecurringItemsRequest, (state) => ({
    ...state,
    recurringItemsStoring: true,
  })),
  on(
    actions.storeRecurringItemsSuccess,
    actions.storeRecurringItemsFailed,
    (state) => ({
      ...state,
      recurringItemsStoring: false,
    })
  ),
  on(actions.updateRecurringItemsRequest, (state) => ({
    ...state,
    recurringItemsUpdating: true,
  })),
  on(
    actions.updateRecurringItemsSuccess,
    actions.updateRecurringItemsFailed,
    (state) => ({
      ...state,
      recurringItemsUpdating: false,
    })
  ),
  on(actions.deleteRecurringItemsRequest, (state) => ({
    ...state,
    recurringItemsDeleting: true,
  })),
  on(
    actions.deleteRecurringItemsSuccess,
    actions.deleteRecurringItemsFailed,
    (state) => ({
      ...state,
      recurringItemsDeleting: false,
    })
  ),
  on(actions.getOneTimeTypesRequest, (state) => ({
    ...state,
    oneTimeTypesLoading: true,
  })),
  on(actions.getOneTimeTypesSuccess, (state, { oneTimeTypes }) => ({
    ...state,
    oneTimeTypes,
    oneTimeTypesLoading: false,
  })),
  on(actions.getOneTimeTypesFailed, (state) => ({
    ...state,
    oneTimeTypesLoading: false,
  })),
  on(actions.getOneTimeOrdersRequest, (state) => ({
    ...state,
    oneTimeOrdersLoading: true,
  })),
  on(actions.getOneTimeOrdersSuccess, (state, { oneTimeOrders }) => ({
    ...state,
    oneTimeOrders,
    oneTimeOrdersLoading: false,
  })),
  on(actions.getOneTimeOrdersFailed, (state) => ({
    ...state,
    oneTimeOrders: [],
    oneTimeOrdersLoading: false,
  })),
  on(actions.storeOneTimeOrdersRequest, (state) => ({
    ...state,
    oneTimeOrdersStoring: true,
  })),
  on(
    actions.storeOneTimeOrdersSuccess,
    actions.storeOneTimeOrdersSuccess,
    (state) => ({
      ...state,
      oneTimeOrdersStoring: false,
    })
  ),
  on(actions.updateOneTimeOrdersRequest, (state) => ({
    ...state,
    oneTimeOrdersUpdating: true,
  })),
  on(
    actions.updateOneTimeOrdersSuccess,
    actions.updateOneTimeOrdersFailed,
    (state) => ({
      ...state,
      oneTimeOrdersUpdating: false,
    })
  ),
  on(actions.deleteOneTimeOrdersRequest, (state) => ({
    ...state,
    oneTimeOrdersDeleting: true,
  })),
  on(
    actions.deleteOneTimeOrdersSuccess,
    actions.deleteOneTimeOrdersFailed,
    (state) => ({
      ...state,
      oneTimeOrdersDeleting: false,
    })
  ),
  on(actions.getFeeTypeOrdersRequest, (state) => ({
    ...state,
    feeTypesLoading: true,
  })),
  on(actions.getFeeTypeOrdersSuccess, (state, { feeTypeOrders }) => ({
    ...state,
    feeTypeOrders,
    feeTypesLoading: false,
  })),
  on(actions.getFeeTypeOrdersFailed, (state) => ({
    ...state,
    feeTypesLoading: false,
  })),
  on(actions.storeFeeTypeOrderRequest, (state) => ({
    ...state,
    feeTypesStoring: true,
  })),
  on(
    actions.storeFeeTypeOrderSuccess,
    actions.storeFeeTypeOrderFailed,
    (state) => ({
      ...state,
      feeTypesStoring: false,
    })
  ),
  on(actions.updateFeeTypeOrderRequest, (state) => ({
    ...state,
    feeTypesUpdating: true,
  })),
  on(
    actions.updateFeeTypeOrderSuccess,
    actions.updateFeeTypeOrderFailed,
    (state) => ({
      ...state,
      feeTypesUpdating: false,
    })
  ),
  on(actions.deleteFeeTypeOrderRequest, (state) => ({
    ...state,
    feeTypesDeleting: true,
  })),
  on(
    actions.deleteFeeTypeOrderSuccess,
    actions.deleteFeeTypeOrderFailed,
    (state) => ({
      ...state,
      feeTypesDeleting: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.officeCard
);

export const getCustomerNumber = createSelector(
  getState,
  (state: OfficeCardState) => state.customerNumber
);

export const getCustomerNumberLoading = createSelector(
  getState,
  (state: OfficeCardState) => state.customerNumberLoading
);

export const getCustomerNumberStoring = createSelector(
  getState,
  (state: OfficeCardState) => state.customerNumberStoring
);

export const getCustomerNumberUpdating = createSelector(
  getState,
  (state: OfficeCardState) => state.customerNumberUpdating
);

export const getRecurringItems = createSelector(
  getState,
  (state: OfficeCardState) => state.recurringItems
);

export const getRecurringItemsLoading = createSelector(
  getState,
  (state: OfficeCardState) => state.recurringItemsLoading
);

export const getRecurringItemsStoring = createSelector(
  getState,
  (state: OfficeCardState) => state.recurringItemsStoring
);

export const getRecurringItemsUpdating = createSelector(
  getState,
  (state: OfficeCardState) => state.recurringItemsUpdating
);

export const getRecurringItemsDeleting = createSelector(
  getState,
  (state: OfficeCardState) => state.recurringItemsDeleting
);

export const getOneTimeTypes = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeTypes
);

export const getOneTimeTypesLoading = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeTypesLoading
);

export const getOneTimeOrders = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeOrders
);

export const getOneTimeOrdersLoading = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeOrdersLoading
);

export const getOneTimeOrdersStoring = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeOrdersStoring
);

export const getOneTimeOrdersUpdating = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeOrdersUpdating
);

export const getOneTimeOrdersDeleting = createSelector(
  getState,
  (state: OfficeCardState) => state.oneTimeOrdersDeleting
);

export const getFeeTypeOrders = createSelector(
  getState,
  (state: OfficeCardState) => state.feeTypeOrders
);

export const getFeeTypesLoading = createSelector(
  getState,
  (state: OfficeCardState) => state.feeTypesLoading
);

export const getFeeTypesStoring = createSelector(
  getState,
  (state: OfficeCardState) => state.feeTypesStoring
);

export const getFeeTypesUpdating = createSelector(
  getState,
  (state: OfficeCardState) => state.feeTypesUpdating
);

export const getFeeTypesDeleting = createSelector(
  getState,
  (state: OfficeCardState) => state.feeTypesDeleting
);
