import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Office } from "@app/models";
import { ExternalTip } from "@app/models/external-tips";
import { ALL_OFFICES } from "@app/settings/external-tips/utils/manage-external-tips-constants";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { EXTERNAL_TIPS_UPDATE } from "@app/shared/utils/tab-types";
import { ExternalTipsFormComponent } from "@app/sidebar/external-tips/external-tips-form/external-tips-form.component";
import * as formModes from "@app/sidebar/external-tips/utils/external-tips-form-modes";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import * as fromUser from "../../../shared/user";
import { SidebarTab } from "../../models/sidebar-tab";
import { ConnectableTab } from "../../sidebar-connectable-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { closeTab } from "../../ngrx/sidebar.actions";
import * as fromSidebar from "../../ngrx/sidebar.reducer";
import * as externalTipsActions from "../ngrx/external-tips.actions";
import * as fromExternalTips from "../ngrx/external-tips.reducer";

interface DataForForm {
  offices: Office[];
  externalTip: ExternalTip;
  canEditOffice: boolean;
}

@Component({
  selector: "edit-external-tips",
  templateUrl: "./edit-external-tips.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./edit-external-tips.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditExternalTipsComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(ExternalTipsFormComponent, { static: false })
  externalTipsFormComponent: ExternalTipsFormComponent;

  proxy$ = new Subject<any>();
  externalTip$: Observable<ExternalTip>;
  tabType = EXTERNAL_TIPS_UPDATE;
  tab$: Observable<SidebarTab>;
  unsubscribe$ = new Subject<void>();
  externalTipFormModes = formModes;
  dataForForm$: Observable<DataForForm>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.connectTab();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.externalTip$ = this.store.pipe(
      select(fromExternalTips.getSelectedTip)
    );
    this.dataForForm$ = observableCombineLatest([
      this.getOffices$(),
      this.store.pipe(select(fromExternalTips.getSelectedTip)),
      this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN))),
    ]).pipe(
      map(([offices, externalTip, canEditOffice]) => ({
        offices,
        externalTip,
        canEditOffice,
      }))
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getOffices$(): Observable<Office[]> {
    return observableCombineLatest([
      this.store.pipe(select(fromShared.getOffices)),
      this.translateService.get("all_offices"),
    ]).pipe(
      map(([offices, allOfficeTranslation]) => ({
        offices,
        allOfficeTranslation,
      })),
      filter((data) => data.offices.length > 0),
      map((data) => {
        const allOfficesItem: Office = {
          name: data.allOfficeTranslation,
          eaOfficeId: ALL_OFFICES,
        };
        return [allOfficesItem].concat(data.offices);
      })
    );
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  onSubmit(): void {
    const externalTip: Partial<ExternalTip> = this.formatExternalTip();

    this.externalTip$
      .pipe(
        first(),
        map(
          (selectedExternalTip: ExternalTip) => selectedExternalTip.receiverId
        )
      )
      .subscribe((id: string) => {
        this.store.dispatch(
          externalTipsActions.updateExternalTipRequest({
            id: id,
            data: externalTip,
          })
        );
      });
  }

  formatExternalTip(): Partial<ExternalTip> {
    return {
      name: this.externalTipsFormComponent.externalTipsForm.get("title").value,
      email: this.externalTipsFormComponent.externalTipsForm.get("email").value,
      eaOfficeId:
        this.externalTipsFormComponent.externalTipsForm.get("office").value ===
        ALL_OFFICES
          ? "null"
          : this.externalTipsFormComponent.externalTipsForm.get("office").value,
    };
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }
}
