import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { CreateContactModule } from "../contacts/create-contact.module";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { CreateFollowUpCreateContactComponent } from "./create-follow-up-create-contact/create-follow-up-create-contact.component";
import { CreateFollowUpHomeComponent } from "./create-follow-up-home/create-follow-up-home.component";
import { CreateFollowUpNewComponent } from "./create-follow-up-new/create-follow-up-new.component";
import { CreateFollowUpComponent } from "./create-follow-up.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    SearchContactModule,
    CreateContactModule,
    SidebarSharedModule,
    FormComponentsModule,
    ContactComponentsModule,
    UiComponentsModule,
    UtilModule,
  ],
  exports: [
    CreateFollowUpComponent,
    CreateFollowUpHomeComponent,
    CreateFollowUpCreateContactComponent,
    CreateFollowUpNewComponent,
  ],
  declarations: [
    CreateFollowUpComponent,
    CreateFollowUpHomeComponent,
    CreateFollowUpCreateContactComponent,
    CreateFollowUpNewComponent,
  ],
  providers: [],
})
export class CreateFollowUpModule {}
