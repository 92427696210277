import { ContactRelationship } from "@app/models";
import { createAction, props } from "@ngrx/store";

const ACTION_PREFIX = "[Contact Relations Widget]";

export const fetchContactRelationsRequest = createAction(
  `${ACTION_PREFIX} FETCH_CONTACT_RELATIONS_REQUEST`,
  props<{ contactId: string }>()
);
export const fetchContactRelationsSuccess = createAction(
  `${ACTION_PREFIX} FETCH_CONTACT_RELATIONS_SUCCESS`,
  props<{ data: ContactRelationship[] }>()
);
export const fetchContactRelationsFail = createAction(
  `${ACTION_PREFIX} FETCH_CONTACT_RELATIONS_FAIL`
);

export const postContactRelationsRequest = createAction(
  `${ACTION_PREFIX} POST_CONTACT_RELATIONS_REQUEST`,
  props<{
    contactId: string;
    params: {
      relatedToContactId: string;
      relationshipType: string;
    };
  }>()
);
export const postContactRelationsSuccess = createAction(
  `${ACTION_PREFIX} POST_CONTACT_RELATIONS_SUCCESS`
);
export const postContactRelationsFail = createAction(
  `${ACTION_PREFIX} POST_CONTACT_RELATIONS_FAIL`
);

export const patchContactRelationsRequest = createAction(
  `${ACTION_PREFIX} PATCH_CONTACT_RELATIONS_REQUEST`,
  props<{
    contactId: string;
    eaRelationshipId: string;
    params: {
      relationshipType: string;
    };
  }>()
);
export const patchContactRelationsSuccess = createAction(
  `${ACTION_PREFIX} PATCH_CONTACT_RELATIONS_SUCCESS`
);
export const patchContactRelationsFail = createAction(
  `${ACTION_PREFIX} PATCH_CONTACT_RELATIONS_FAIL`
);

export const deleteContactRelationsRequest = createAction(
  `${ACTION_PREFIX} DELETE_CONTACT_RELATIONS_REQUEST`,
  props<{ contactId: string; eaRelationshipId: string }>()
);
export const deleteContactRelationsSuccess = createAction(
  `${ACTION_PREFIX} DELETE_CONTACT_RELATIONS_SUCCESS`
);
export const deleteContactRelationsFail = createAction(
  `${ACTION_PREFIX} DELETE_CONTACT_RELATIONS_FAIL`
);
