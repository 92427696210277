import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { danger, success } from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO, SalesMeeting } from "@app/models";
import { prependUTF8BOM } from "@app/shared/utils/csv";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import moment from "moment";
import { catchError, map, switchMap, takeUntil } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SalesMeetingService extends CustomEntityCollectionServiceBase<SalesMeeting> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("SalesMeeting", serviceElementsFactory);
  }

  fetchWithQuery(params: any, setListDefaults = true) {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }
    return this.apiService.get("sales-meetings", params).pipe(
      takeUntil(this.cancelExistingRequest$),
      switchMap((response: PaginationListDTO) => {
        const items: SalesMeeting[] = response.rows;
        this.upsertManyInCache(items);
        this.setLoading(false);
        if (setListDefaults) {
          this.setListDefaults(items, response);
        }
        return this.getListFromEntities(items);
      }),
      catchError((err) => {
        return err;
      })
    );
  }

  fetchById(id) {
    return this.apiService.get(`sales-meetings/${id}`, {}, "api").pipe(
      map((response: any) => {
        this.upsertOneInCache(response);
        return response;
      }),
      catchError((err) => {
        return err;
      })
    );
  }

  post(body: any) {
    return this.apiService.post("sales-meetings", body).pipe(
      map((response: SalesMeeting) => {
        this.addOneToCache(response);

        this.appStore.dispatch(
          success({ message: "sales_meeting_created_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          danger({ message: "sales_meeting_created_error" })
        );
        return err;
      })
    );
  }

  patch(id, body: any) {
    return this.apiService.patch(`sales-meetings/${id}`, body).pipe(
      map((response: SalesMeeting) => {
        this.updateOneInCache(response);
        this.appStore.dispatch(
          success({ message: "sales_meeting_updated_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          danger({ message: "sales_meeting_updated_error" })
        );
        return err;
      })
    );
  }

  remove(id) {
    return this.apiService.delete(`sales-meetings/${id}`).pipe(
      map((response: SalesMeeting) => {
        this.removeOneFromCache(id);
        this.handleDelete(id);

        this.appStore.dispatch(
          success({ message: "sales_meeting_removed_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          danger({ message: "sales_meeting_removed_error" })
        );
        return err;
      })
    );
  }

  downloadCsv(params) {
    this.setLoading(true);
    return this.apiService.getWithoutJson("sales-meetings/csv", params).pipe(
      map((response: any) => {
        this.setLoading(false);
        const blob = new Blob([prependUTF8BOM(response)], {
          type: "text/csv",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `sales-meetings-${moment().format(
          "YYYY-MM-DD HH:mm"
        )}.csv`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
      }),
      catchError((err) => {
        return err;
      })
    );
  }
}
