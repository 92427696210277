import { TypeOfFee } from "@app/settings/invoicing/components/types-of-fees/ngrx/types-of-fees.reducer";
import { createAction, props } from "@ngrx/store";

export const getTypesOfFeesRequest = createAction(
  "[TypesOfFees] GET_TYPES_OF_FEES_REQUEST"
);

export const getTypesOfFeesSuccess = createAction(
  "[TypesOfFees] GET_TYPES_OF_FEES_SUCCESS",
  props<{ typesOfFees: TypeOfFee[] }>()
);

export const getTypesOfFeesFailed = createAction(
  "[TypesOfFees] GET_TYPES_OF_FEES_FAILED"
);

export const storeTypesOfFeesRequest = createAction(
  "[TypesOfFees] STORE_TYPES_OF_FEES_REQUEST",
  props<{ params: TypeOfFee }>()
);

export const storeTypesOfFeesSuccess = createAction(
  "[TypesOfFees] STORE_TYPES_OF_FEES_SUCCESS"
);

export const storeTypesOfFeesFailed = createAction(
  "[TypesOfFees] STORE_TYPES_OF_FEES_FAILED"
);

export const updateTypesOfFeesRequest = createAction(
  "[TypesOfFees] UPDATE_TYPES_OF_FEES_REQUEST",
  props<{ params: TypeOfFee }>()
);

export const updateTypesOfFeesSuccess = createAction(
  "[TypesOfFees] UPDATE_TYPES_OF_FEES_SUCCESS"
);

export const updateTypesOfFeesFailed = createAction(
  "[TypesOfFees] UPDATE_TYPES_OF_FEES_FAILED"
);

export const deleteTypesOfFeesRequest = createAction(
  "[TypesOfFees] DELETE_TYPES_OF_FEES_REQUEST",
  props<{ feeTypeId: string }>()
);

export const deleteTypesOfFeesSuccess = createAction(
  "[TypesOfFees] DELETE_TYPES_OF_FEES_SUCCESS"
);

export const deleteTypesOfFeesFailed = createAction(
  "[TypesOfFees] DELETE_TYPES_OF_FEES_FAILED"
);
