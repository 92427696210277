import { ExternalTip } from "@app/models/external-tips";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as externalTipsActions from "./external-tips.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface ExternalTipsState {
  loading: boolean;
  externalTip: ExternalTip | null;
  externalTips: ExternalTip[] | null;
}

export const initialState: ExternalTipsState = {
  loading: false,
  externalTip: null,
  externalTips: null,
};

export function externalTipsReducer(
  state: ExternalTipsState,
  action: Action
): ExternalTipsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(externalTipsActions.createExternalTipSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    externalTipsActions.updateExternalTipSuccess,
    externalTipsActions.sendExternalTipRequest,
    externalTipsActions.createExternalTipRequest,
    externalTipsActions.getExternalTipRequest,
    externalTipsActions.getExternalTipsRequest,
    (state) => ({ ...state, loading: true })
  ),
  on(
    externalTipsActions.sendExternalTipFailed,
    externalTipsActions.sendExternalTipSuccess,
    externalTipsActions.updateExternalTipFailed,
    externalTipsActions.createExternalTipFailed,
    externalTipsActions.getExternalTipFailed,
    externalTipsActions.getExternalTipsFailed,
    externalTipsActions.updateExternalTipSuccess,
    (state) => ({ ...state, loading: false })
  ),
  on(externalTipsActions.getExternalTipSuccess, (state, { externalTip }) => ({
    ...state,
    loading: false,
    externalTip,
  })),
  on(externalTipsActions.getExternalTipsSuccess, (state, { externalTips }) => ({
    ...state,
    loading: false,
    externalTips,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.externalTips
);

export const getSelectedTip = createSelector(
  getState,
  (state: ExternalTipsState) => state.externalTip
);

export const getExternalTips = createSelector(
  getState,
  (state: ExternalTipsState) => state.externalTips
);

export const getLoadingExternalTipsStatus = createSelector(
  getState,
  (state: ExternalTipsState) => state.loading
);
