<h3>
  <app-icon size="large" name="sack-dollar"></app-icon>&nbsp;<span>{{
    "more_business_opportunities" | translate
  }}</span>
</h3>
<div class="widget-container">
  <div class="segment-container">
    <div class="card-wrapper" *ngFor="let segment of segments$ | async">
      <app-mobile-card
        [enableMetadata]="true"
        [enableActionDropdown]="true"
        [shadow]="false"
        [size]="'SLIM'"
        (click)="showSegmentListDetails(segment, segmentListDetails)"
      >
        <div title>{{ segment.segmentName }}</div>
        <div metadata>
          <div>{{ segment.total | qNumber }} {{ "total" | translate }}</div>
        </div>
        <ng-container buttons>
          <li role="menuitem">
            <button
              class="btn btn-secondary"
              (click)="getCallingListClickEvent(segment)"
            >
              <app-icon name="list"></app-icon>
              {{ "create_calling_list" | translate }}
            </button>
          </li>
          <li role="menuitem">
            <button
              class="btn btn-default"
              (click)="showSegmentListDetails(segment, segmentListDetails)"
            >
              <app-icon name="pen"></app-icon>
              {{ "details" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
  </div>
</div>

<standalone-sidebar *ngIf="showCreateCallingListSidebar">
  <div class="sidebar">
    <create-calling-list
      [contactIdList]="allContactIds$ | async"
      [listName]="listName"
      (closeSidebar)="showCreateCallingListSidebar = false"
    ></create-calling-list>
  </div>
</standalone-sidebar>

<ng-template #segmentListDetails>
  <ng-container *ngIf="selectedSegment$ | async as segment">
    <div class="modal-header">
      <h4 class="modal-title pull-left">{{ segment.segmentName }}</h4>
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" translate>
      <list
        [showHeader]="false"
        [isExpanded]="true"
        [itemType]="'contact'"
        [items]="segment.contacts"
        [hideNoResult]="segment.isLoading"
        [lazy]="!segment.allContactsloaded"
        [disableLoadMore]="segment.isLoading"
        [itemsLeftToLoad]="segment.itemsLeftToLoad"
        (loadMore)="fetchContacts(segment)"
      >
      </list>
    </div>
  </ng-container>
</ng-template>
