import { Component, Input } from "@angular/core";
import { fadeInUp } from "@app/shared/animations";

@Component({
  selector: "app-container-box",
  templateUrl: "./container-box.component.html",
  styleUrls: ["./container-box.component.scss"],
  animations: [fadeInUp()],
})
export class ContainerBoxComponent {
  @Input() margin: "default" | "none" = "default";
  @Input() padding: "default" | "none" = "default";

  @Input() background: "WHITE" | "SKYBLUE" = "WHITE";
}
