import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ScrollService {
  private subject = new Subject<any>();

  source$ = this.subject.asObservable();

  emit(value?: any): void {
    this.subject.next(value);
  }
}
