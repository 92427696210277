import { Office } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const updateOfficeRequest = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] UPDATE_OFFICE_REQUEST",
  props<{
    id: string;
    officeManagementPrimaryEmail: string;
  }>()
);

export const updateOfficeSuccess = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] UPDATE_OFFICE_SUCCESS"
);

export const updateOfficeFail = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] UPDATE_OFFICE_FAIL"
);

export const fetchOfficesRequest = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] FETCH_OFFICES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchOfficesSuccess = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] FETCH_OFFICES_SUCCESS",
  props<{ offices: Office[] }>()
);

export const fetchOfficesFail = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] FETCH_OFFICES_FAIL"
);

export const setSelectedOffice = createAction(
  "[MANAGE OFFICE MANAGEMENT EMAILS] SET_SELECTED_OFFICE",
  props<{ eaOfficeIds: string[] }>()
);
