import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { Action, PaginationListDTO } from "@app/models";
import { CONTACT_ACTIONS_WIDGET_DEFAULT_LIMIT } from "@app/shared/modules/contact-actions-widget/utils/contact-actions-widget-constants";
import {
  BehaviorSubject,
  catchError,
  first,
  map,
  Observable,
  of as observableOf,
  Subject,
  switchMap,
} from "rxjs";
import { NOTES } from "../action-list/utils/action-filter";
import { ActionMessage } from "./models/action-message";
import { ActionMessageTypes } from "./models/message-types";

const defaultParams = {
  offset: 0,
  limit: CONTACT_ACTIONS_WIDGET_DEFAULT_LIMIT,
  visibility: "employee,consumer",
  sortBy: "timestamp",
  sortOrder: "desc",
  codes: NOTES.codes.toString(),
};

@Injectable()
export class ContactActionsWidgetService {
  private _actions$ = new BehaviorSubject<Action[]>([]);
  private _totalActions$ = new BehaviorSubject<number>(0);
  private _loading$ = new BehaviorSubject(false);
  private _message$ = new Subject<ActionMessage>();

  get actions$(): Observable<Action[]> {
    return this._actions$.asObservable();
  }

  get actionsLeftToLoad$(): Observable<number> {
    return this._actions$.pipe(
      switchMap((actions) =>
        this._totalActions$.pipe(
          map((totalActions) => totalActions - actions.length)
        )
      )
    );
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get message$(): Observable<ActionMessage> {
    return this._message$.asObservable();
  }

  constructor(private apiService: ApiService) {}

  fetchActions(contactId: string): void {
    this.resetState();
    this._loading$.next(true);
    this.getActions(contactId)
      .pipe(catchError((e) => this.fetchFailed$(e)))
      .subscribe((r: PaginationListDTO) => this.handleFetchResponse(r));
  }

  fetchMoreActions(contactId: string): void {
    this._loading$.next(true);
    this.actions$
      .pipe(
        first(),
        switchMap((list) =>
          this.getActions(contactId, { offset: list.length }).pipe(
            map((response: PaginationListDTO) => ({
              ...response,
              rows: list.concat(response.rows),
            })),
            catchError((e) => this.fetchFailed$(e))
          )
        )
      )
      .subscribe((r: PaginationListDTO) => this.handleFetchResponse(r));
  }

  reloadActions(contactId: string) {
    this._loading$.next(true);
    this.actions$
      .pipe(
        first(),
        switchMap((list) => {
          let limit = list.length;
          if (defaultParams.limit > limit) {
            limit = defaultParams.limit;
          }
          return this.getActions(contactId, {
            offset: 0,
            limit: limit,
          }).pipe(catchError((e) => this.fetchFailed$(e)));
        })
      )
      .subscribe((r: PaginationListDTO) => this.handleFetchResponse(r));
  }

  deleteAction(contactId: string, actionId: string) {
    this.apiService
      .delete(`actions/${actionId}`, { contactId })
      .pipe(
        map(() => ({
          type: ActionMessageTypes.SUCCESS,
          message: "note_deleted",
        })),
        catchError(() =>
          observableOf({
            type: ActionMessageTypes.ERROR,
            message: "delete_note_failed",
          })
        ),
        first()
      )
      .subscribe((message) => {
        this._message$.next(message);
        this.reloadActions(contactId);
      });
  }

  private getActions(contactId: string, params = {}) {
    return this.apiService.get("actions", {
      ...defaultParams,
      contactId,
      ...params,
      sortBy: "pinned",
    });
  }

  private fetchFailed$(error): Observable<PaginationListDTO> {
    this._message$.next({ type: ActionMessageTypes.ERROR, ...error });
    return observableOf({ total: 0, rows: [], limit: 0, offset: 0 });
  }

  private handleFetchResponse(response: PaginationListDTO): void {
    this._actions$.next(response.rows);
    this._totalActions$.next(response.total);
    this._loading$.next(false);
  }

  private resetState(): void {
    this._actions$.next([]);
    this._totalActions$.next(0);
  }
}
