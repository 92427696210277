import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { show } from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ActionService } from "@app/core/ngrx/entity-services/action.service";
import { BidService as BidEntityService } from "@app/core/ngrx/entity-services/bid.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { NoteService } from "@app/core/ngrx/entity-services/note.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { Bid } from "@app/integrations/bidding/models/bid";
import { BiddingService } from "@app/integrations/bidding/services/bidding.service";
import { SendPotentialBuyersService } from "@app/integrations/bidding/services/send-potential-buyers.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import {
  getSupportedLinkTypes,
  hasIntegration,
} from "@app/integrations/ngrx/integrations.reducer";
import { Action, Note, QObject } from "@app/models";
import { fadeInTrigger, slideInOutTrigger } from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import {
  CONSENT_ALARM_LEVEL,
  CREATE_PRIVATE_SHOWING,
  EXTERNAL_PROVIDER,
  POTENTIAL_BUYERS,
  SEND_TIPS_EIKA,
} from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromUser from "@app/shared/user";
import { getEaEmployeeId } from "@app/shared/user";
import {
  ROOT_SIDEBAR_ROUTE,
  SIDEBAR_CONTACT_RESIDENCES_CREATE_URL,
  SIDEBAR_SEND_MESSAGE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import {
  MANAGE_POTENTIAL_BUYER_MULTIPLE,
  MANAGE_POTENTIAL_BUYER_SINGLE,
} from "@app/shared/utils/tab-types";
import { AddBidModalComponent } from "@app/showings/components/add-bid-modal/add-bid-modal.component";
import { ShowingAttendanceModalComponent } from "@app/showings/components/showing-attendance-modal/showing-attendance-modal.component";
import { SupportedDeepLinksEnum } from "@app/showings/enums/supported-deep-links.enum";
import {
  ContactablePotentialBuyer,
  PotentialBuyer,
} from "@app/showings/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { BidService } from "@app/showings/services/bid.service";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { BiddingStatus } from "@app/sidebar/potential-buyer/models/bidding-status";
import { ResidenceStatus } from "@app/sidebar/potential-buyer/models/residence-status";
import * as fromContactedStatus from "@app/sidebar/potential-buyer/ngrx/contacted-status.ngrx";
import * as fromPotentialBuyers from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import { TemplateGroups } from "@app/sidebar/send-message/models";
import * as SendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import * as sidebarActions from "../../../ngrx/sidebar.actions";
import { closeTab } from "../../../ngrx/sidebar.actions";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";
import { PotentialBuyersFeature } from "@app/shared/config/models/potential-buyers-feature";

@Component({
  selector: "app-potential-buyer-manage-single",
  templateUrl: "./potential-buyer-manage-single.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./potential-buyer-manage-single.component.scss",
  ],
  animations: [fadeInTrigger(), slideInOutTrigger()],
})
export class PotentialBuyerManageSingleComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  tabType = MANAGE_POTENTIAL_BUYER_SINGLE;
  canAddBid$: Observable<{ enabled: boolean }>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  externalProviderName$: Observable<string>;
  msisdn$: Observable<string>;
  msisdnCountry$: Observable<string>;
  phoneNumber$: Observable<string>;
  phoneNumberCountry$: Observable<string>;
  contactId$: Observable<string>;
  potentialBuyer$: Observable<PotentialBuyer>;
  highestBid$: Observable<Bid>;
  eaOid$: Observable<string>;
  object$: Observable<QObject>;
  note$: Observable<Note>;
  contactedStatus$: Observable<boolean>;
  supportedDeepLinks$: Observable<string[]>;
  showCreatePrivateShowing$: Observable<boolean>;
  enableSendTipToBank$: Observable<boolean>;
  hasBidIntegration$: Observable<boolean>;
  showBiddingInfo$: Observable<boolean>;
  isResponsibleBroker$: Observable<boolean>;
  hasAllRequiredData$: Observable<boolean>;
  mobilePhoneIsRequired$: Observable<boolean>;
  hasExternalPotentialBuyerState$: Observable<boolean>;
  checkConsentAlarmLevelEnable$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();

  supportedDeepLinksEnum = SupportedDeepLinksEnum;
  form: FormGroup;
  noteValue: string;

  constructor(
    private store: Store<AppState>,
    private pbService: PotentialBuyerService,
    public biddingService: BiddingService,
    public sendPotentialBuyerService: SendPotentialBuyersService,
    private modalService: QModalService,
    private fb: FormBuilder,
    private objectService: ObjectService,
    public contactService: ContactService,
    private bidService: BidService,
    private bidEntityService: BidEntityService,
    private noteService: NoteService,
    public actionService: ActionService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchNotes();
    this.fetchContactActions();
    this.store.dispatch(closeTab({ tabType: MANAGE_POTENTIAL_BUYER_MULTIPLE }));
    this.sendPotentialBuyerService.reset();
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromPotentialBuyers.reset());
    this.sendPotentialBuyerService.reset();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleNoteValueChanged(val) {
    this.noteValue = val;
  }

  connectTab(): void {
    throw new Error("Method not implemented.");
  }

  openSendMessageSidebar() {
    this.pbService.selectedPotentialBuyer$
      .pipe(
        withLatestFrom(this.eaOid$, this.checkConsentAlarmLevelEnable$),
        first()
      )
      .subscribe(([buyer, eaOid, checkConsent]) => {
        const users = [new ContactablePotentialBuyer(buyer)];
        const actions = [
          SendMessageActions.setTemplateGroup({
            templateGroupName: TemplateGroups.Showings,
          }),
          SendMessageActions.setExtraDataSet({ extraDataSet: { eaOid } }),
          SendMessageActions.addContacts({ recipients: users }),
          SendMessageActions.setCheckConsent({ checkConsent }),
          SendMessageActions.setEaOid({ eaOid }),
        ];
        this.store.dispatch(
          RouterActions.go({
            path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }],
          })
        );
        actions.forEach((action) => this.store.dispatch(action));
      });
  }

  openAddShowingModal() {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(first())
      .subscribe(([contactId, eaOid]) => {
        const data: ShowingModalData = {
          isEditMode: false,
          contactId: contactId,
          eaOid,
          hasHeader: true,
          hasHeaderCloseButton: true,
          hasActionBar: true,
        };

        this.modalService.show(AddShowingModalNewComponent, {
          data,
        });
      });
  }

  handleCreateNote(note: string): void {
    combineLatest([
      this.eaEmployeeId$,
      this.eaOfficeId$,
      this.eaOid$,
      this.potentialBuyer$,
    ])
      .pipe(first())
      .subscribe(([eaEmployeeId, eaOfficeId, eaOid, potentialBuyer]) => {
        this.noteService
          .post({
            note,
            type: "object",
            eaEmployeeId,
            eaOfficeId,
            eaOid,
            contactId: potentialBuyer.contactId,
          })
          .subscribe(() => {
            this.noteService.refreshWithContactId().subscribe();
            this.contactService.refreshFetch$.next();
            this.noteValue = "";
          });
      });
  }

  handleLoanCommitmentStatusChange(status: string): void {
    this.contactId$.pipe(first()).subscribe((contactId) => {
      this.contactService
        .patchProfile(contactId, { mortgageStatus: status })
        .pipe(first())
        .subscribe();
    });
  }

  handleResidenceStatusChange(status: string): void {
    this.contactId$.pipe(first()).subscribe((contactId) => {
      this.contactService
        .patchProfile(contactId, { ownsResidence: status })
        .pipe(first())
        .subscribe(() => {
          if (status === ResidenceStatus.OwnsResidence) {
            this.store.dispatch(
              show({
                header: undefined,
                message: "create_residence_question_body",
              })
            );
            this.store
              .pipe(select(confirmState))
              .pipe(
                first(),
                filter((value) => !!value)
              )
              .subscribe(() => {
                this.store.dispatch(
                  RouterActions.go({
                    path: [
                      "crm",
                      {
                        outlets: {
                          sidebar:
                            SIDEBAR_CONTACT_RESIDENCES_CREATE_URL(contactId),
                        },
                      },
                    ],
                  })
                );
              });
          }
        });
    });
  }

  handleBiddingStatusChange(bidStatus: string): void {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(first())
      .subscribe(([contactId, eaOid]) =>
        this.contactService
          .postContactObjectConnection(contactId, {
            eaOid,
            bidStatus,
          })
          .pipe(delay(1000))
          .subscribe(() => {
            this.contactService.refreshFetch$.next();
          })
      );
  }

  handleAddBid(): void {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(first())
      .subscribe(([contactId, eaOid]) => {
        this.modalService.show(AddBidModalComponent, {
          data: {
            hasHeader: true,
            hasHeaderCloseButton: true,
            hasActionBar: true,
            contactId,
            eaOid,
          },
        });
      });
  }

  handleSendPotentialBuyersToERP(): void {
    this.pbService.selectedPotentialBuyer$
      .pipe(withLatestFrom(this.eaOid$), first())
      .subscribe(([potentialBuyer, eaOid]) => {
        this.sendPotentialBuyerService.sendPotentialBuyersToERP({
          eaOid,
          potentialBuyers: [potentialBuyer],
        });
      });

    this.sendPotentialBuyerService
      .getIsDone()
      .pipe(
        filter((isDone) => isDone),
        first(),
        withLatestFrom(
          this.sendPotentialBuyerService.getErrors(),
          this.eaOid$,
          this.hasExternalPotentialBuyerState$
        )
      )
      .subscribe(([_isDone, errors, eaOid, hasExternalPotentialBuyerState]) => {
        if (hasExternalPotentialBuyerState) {
          this.pbService.getPotentialBuyersStatusByObject(eaOid).subscribe();
        }

        if (errors?.length === 0) {
          this.store.dispatch(
            sidebarActions.closeTab({ tabType: this.tabType })
          );
        }
      });
  }

  removeAsPotentialBuyer(): void {
    combineLatest([
      this.contactId$,
      this.eaOid$,
      this.biddingService.getHighestBidForContact(),
      this.isResponsibleBroker$,
    ])
      .pipe(
        filter((value) => !!value),
        filter((value) => !value[2] && !!value[3]),
        first()
      )
      .subscribe(([contactId, eaOid]) => {
        this.store.dispatch(
          confirmActions.show({
            header: "remove_potential_buyer",
            message: "remove_potential_buyer_message",
          })
        );
        this.store
          .pipe(
            select(confirmState),
            filter((value) => !!value),
            take(1)
          )
          .subscribe(() => {
            this.contactService
              .removeContactObjectConnection(contactId, eaOid)
              .subscribe(() =>
                this.store.dispatch(
                  sidebarActions.closeTab({ tabType: this.tabType })
                )
              );
          });
      });
  }

  goToSettingsForBidder(): void {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(first())
      .subscribe(([contactId, eaOid]) => {
        this.bidService.openBiddingSettingsModal(eaOid, contactId).subscribe();
      });
  }

  goToSetAsWinningBid(highestBid: string): void {
    this.bidService.openSetAsWinningBidModal(highestBid).subscribe();
  }

  openSendTipToBankSidebar() {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(
        filter((value) => !!value),
        first()
      )
      .subscribe(([contactId, eaOid]) => {
        this.store.dispatch(
          RouterActions.go({
            path: [
              "crm",
              {
                outlets: {
                  sidebar: [ROOT_SIDEBAR_ROUTE, "eika", contactId, eaOid],
                },
              },
            ],
          })
        );
      });
  }

  private fetchContactActions(): void {
    combineLatest([this.contactId$, this.eaOid$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([contactId, eaOid]) => this.getActions(contactId, eaOid));
  }

  private mapStateToProps(): void {
    this.potentialBuyer$ = this.pbService.selectedPotentialBuyer$.pipe(
      map((buyer) => buyer?.contactId),
      takeUntil(this.unsubscribe$),
      switchMap((contactId) =>
        this.contactService.entityMap$.pipe(
          map((em) => em[contactId]),
          filter((it) => !!it),
          takeUntil(this.unsubscribe$),
          distinctUntilChanged()
        )
      )
    );
    this.contactId$ = this.potentialBuyer$.pipe(
      map((buyer) => buyer.contactId)
    );
    this.highestBid$ = combineLatest([
      this.bidEntityService.currentList$,
      this.contactId$,
    ]).pipe(
      map(([bids, contactId]) => {
        const contactBids = bids.filter(
          (bid) => bid.contactId === contactId && !bid.isCanceled
        );
        if (contactBids) {
          return contactBids[0];
        } else {
          return null;
        }
      })
    );
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.externalProviderName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
    this.msisdn$ = this.potentialBuyer$.pipe(map((buyer) => buyer.msisdn));
    this.msisdnCountry$ = this.potentialBuyer$.pipe(
      map((buyer) => buyer.msisdnCountry)
    );
    this.phoneNumber$ = this.potentialBuyer$.pipe(
      map((buyer) => buyer.phoneNumber)
    );
    this.phoneNumberCountry$ = this.potentialBuyer$.pipe(
      map((buyer) => buyer.phoneNumberCountry)
    );
    this.eaOid$ = this.store.pipe(select(fromShowings.getShowingObjectEaOid));
    this.object$ = this.eaOid$.pipe(
      switchMap((eaOid) =>
        this.objectService.entityMap$.pipe(
          map((em) => em[eaOid]),
          filter((it) => !!it)
        )
      ),
      takeUntil(this.unsubscribe$)
    );
    this.isResponsibleBroker$ = combineLatest([
      this.object$,
      this.eaEmployeeId$,
    ]).pipe(
      map(
        ([object, eaEmployeeId]) =>
          object?.responsibleEmployee?.eaEmployeeId === eaEmployeeId
      )
    );
    this.hasBidIntegration$ = this.store.select(
      hasIntegration(IntegrationResource.CreateBid)
    );
    this.note$ = this.noteService.currentList$.pipe(
      map((notes) => (!!notes && notes?.length > 0 ? notes[0] : null))
    );
    this.contactedStatus$ = this.store.pipe(
      select(fromContactedStatus.getContacted)
    );
    this.supportedDeepLinks$ = this.store.select(getSupportedLinkTypes());
    this.enableSendTipToBank$ = this.store.pipe(
      select(getFeature(SEND_TIPS_EIKA)),
      map((feature: Feature) => feature.enabled)
    );
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );

    this.showBiddingInfo$ = combineLatest([
      this.hasBidIntegration$,
      this.potentialBuyer$.pipe(map((pb) => pb?.bidStatus)),
      this.object$.pipe(map((object) => object.isExistingHome)),
    ]).pipe(
      map(
        ([hasBiddingIntegration, status, isExistingHome]) =>
          hasBiddingIntegration &&
          !isExistingHome &&
          status === BiddingStatus.Bidder
      ),
      distinctUntilChanged()
    );

    this.form = this.fb.group({
      date: ["", Validators.required],
      time_from: ["", Validators.required],
      time_to: ["", Validators.required],
    });

    this.canAddBid$ = combineLatest([
      this.store.select(hasIntegration(IntegrationResource.CreateBid)),
      this.store.pipe(select(getEaEmployeeId)),
      this.object$,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([hasCreateBid, eaEmployeeId, object]) => {
        const isResponsibleEmployee = !!object?.employees?.find(
          (employee) => employee.eaEmployeeId === eaEmployeeId
        );

        return { enabled: hasCreateBid && isResponsibleEmployee };
      })
    );
    this.mobilePhoneIsRequired$ = this.store.pipe(
      select(fromConfig.getFeature(POTENTIAL_BUYERS)),
      map(
        (potentialBuyersFeature: PotentialBuyersFeature) =>
          potentialBuyersFeature.mobilePhoneIsRequired
      )
    );
    this.hasAllRequiredData$ = combineLatest([
      this.potentialBuyer$,
      this.mobilePhoneIsRequired$,
    ]).pipe(
      map(([potentialBuyer, mobilePhoneIsRequired]) => {
        if (mobilePhoneIsRequired) {
          return !!potentialBuyer?.msisdn || !!potentialBuyer?.phoneNumber;
        }

        return true;
      })
    );
    this.hasExternalPotentialBuyerState$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.GetExternalPotentialBuyerState))
    );
    this.checkConsentAlarmLevelEnable$ = this.store.pipe(
      select(getFeature(CONSENT_ALARM_LEVEL)),
      map((feature) => feature.enabled)
    );
  }

  private getActions(contactId: string, eaOid: string) {
    this.actionService
      .getWithQuery({
        contactId,
        eaOid,
        codes: [
          ContactActionCodes.CONSUMER_ONE2ONE_SMS,
          ContactActionCodes.CONSUMER_ONE2ONE_EMAIL,
          ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
          ContactActionCodes.CONSUMER_CRM_SENT,
          ContactActionCodes.CONSUMER_CALL_REGISTERED,
        ],
        sortBy: "timestamp",
        sortOrder: "desc",
        limit: 1000,
      })
      .pipe(first())
      .subscribe();
  }

  updateContactedStatus(status) {
    combineLatest([
      this.contactId$,
      this.eaOid$,
      this.eaEmployeeId$,
      this.eaOfficeId$,
    ])
      .pipe(first())
      .subscribe(([contactId, eaOid, eaEmployeeId, eaOfficeId]) => {
        const params = {
          code: ContactActionCodes.CONSUMER_CALL_REGISTERED,
          eaEmployeeId: eaEmployeeId,
          eaOid: eaOid,
          param1: !!status ? "answer" : "noanswer",
          param2: eaOfficeId,
          visibility: "employee",
        };
        this.actionService
          .postContactAction(contactId, params)
          .subscribe((res: Action) => {
            this.contactService.updateOneInCache({
              contactId,
              lastContacts: [
                {
                  actionCode: res.code,
                  actionType: res.type,
                  contactDate: res.timestamp,
                  note: null,
                  param1: res.param1,
                },
              ],
            });
          });
      });
  }

  openShowingAttendanceModal() {
    this.contactId$
      .pipe(withLatestFrom(this.eaOid$), first())
      .subscribe(([contactId, eaOid]) => {
        this.modalService.show(ShowingAttendanceModalComponent, {
          data: {
            hasHeader: true,
            hasHeaderCloseButton: true,
            hasActionBar: true,
            contactIds: [contactId],
            eaOid,
          },
        });
      });
  }

  private fetchNotes() {
    combineLatest([this.eaOid$, this.potentialBuyer$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([eaOid, pb]) => {
        this.noteService.getWithContactId(pb.contactId, { eaOid }).subscribe();
      });
  }
}
