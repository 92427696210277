import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { LocaleService } from "@app/core/services/locale/locale.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { WEATHER } from "@app/shared/config/utils/features";
import { select, Store } from "@ngrx/store";
import { CalendarEvent, CalendarView } from "angular-calendar";
import moment from "moment";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
import { BsDatepickerViewMode } from "ngx-bootstrap/datepicker/models";
import { BehaviorSubject, map, Observable, Subject, take } from "rxjs";
import { CalendarWidgetService } from "./calendar-widget.service";
import { EventDTO } from "./models";

@Component({
  selector: "calendar-widget",
  templateUrl: "./calendar-widget.component.html",
  styleUrls: [
    "../../../../styles/list-item.component.scss",
    "./calendar-widget.component.scss",
  ],
})
export class CalendarWidgetComponent implements OnInit, OnDestroy, OnChanges {
  @Input() date: Date | string;
  @Input() showHideModalButton: boolean;
  @Output() modalCloseClicked = new EventEmitter();
  @ViewChild(BsDatepickerDirective, { static: true })
  datepicker: BsDatepickerDirective;

  date$ = new BehaviorSubject(new Date());
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  historyDate: Date = new Date();

  activeDayIsOpen = true;
  locale: String;

  events$: Observable<EventDTO[]>;
  monthEvents$: Observable<EventDTO[]>;
  calMonthEvents$: Observable<CalendarEvent[]>;
  selectedEvents: EventDTO[];
  loading$: Observable<boolean>;

  weatherFeatureEnabled$: Observable<boolean>;

  unsubscribe$ = new Subject<void>();
  dpViewMode: BsDatepickerViewMode = "month";

  constructor(
    private store: Store<AppState>,
    private calendarWidgetService: CalendarWidgetService,
    private localeService: LocaleService
  ) {}

  ngOnInit() {
    this.monthEvents$ = this.calendarWidgetService.list$;
    this.locale = this.localeService.getCurrentLocale();
    this.loading$ = this.calendarWidgetService.loading$;
    this.weatherFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(WEATHER)),
      map((feature: Feature) => feature.enabled)
    );

    this.initSelectedDayEventsList();
    this.initMonthEvents();
    this.loadEvents();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { date } = changes;

    if (date && date.currentValue) {
      this.date$.next(new Date(date.currentValue));
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hideModal() {
    this.modalCloseClicked.emit({});
  }

  initSelectedDayEventsList() {
    this.calendarWidgetService.list$.subscribe((list) => {
      const foundEvents = list.filter((event) => {
        return moment(event.formatedStartTime).isSame(this.viewDate, "day");
      });

      if (foundEvents.length > 0) {
        this.selectedEvents = foundEvents;
      }
    });
  }

  initMonthEvents() {
    this.calMonthEvents$ = this.calendarWidgetService.list$.pipe(
      map((events: EventDTO[]) => this.parse(events))
    );
  }

  loadEvents(): void {
    this.calendarWidgetService.getEventsRequest(
      moment(this.viewDate).startOf("month").toDate(),
      moment(this.viewDate).endOf("month").toDate()
    );
  }

  onClick(event: EventDTO): void {
    this.hideModal();
    switch (event.type) {
      case "showing":
        return this.store.dispatch(
          RouterActions.go({ path: ["/crm/showings", event.eaOid] })
        );

      case "salesmeeting":
        return this.store.dispatch(
          RouterActions.go({
            path: [
              "/crm/contacts",
              event.contactId,
              "sales-meetings",
              event.externalId,
            ],
          })
        );
    }
  }

  getDisplayDate(date: Date): string {
    const mDate = moment(date);
    const today = moment(new Date());
    const tomorrow = today.clone().add(1, "day");
    const yesterday = today.clone().subtract(1, "day");

    if (mDate.isSame(today, "day")) {
      return "today";
    } else if (mDate.isSame(tomorrow, "day")) {
      return "tomorrow";
    } else if (mDate.isSame(yesterday, "day")) {
      return "yesterday";
    }

    return mDate.format("dddd D/M");
  }

  nextDay() {
    this.viewDate = moment(this.viewDate).add(1, "day").toDate();
    this.getEventsOfDay(this.viewDate);
  }

  previousDay() {
    this.viewDate = moment(this.viewDate).subtract(1, "day").toDate();
    this.getEventsOfDay(this.viewDate);
  }

  setDpViewMode(value: BsDatepickerViewMode): void {
    this.dpViewMode = value;
  }

  onOpenCalendar(container) {
    container.monthSelectHandler = (event: any) =>
      this.handleDatePickerSelection(event);
    container.yearSelectHandler = (event: any) =>
      this.handleDatePickerSelection(event);

    container.setViewMode(this.dpViewMode);
  }

  handleDatePickerSelection(event: any): void {
    this.viewDate = moment(event.date)
      .set("date", this.viewDate.getDate())
      .toDate();
    this.datepicker.hide();
  }

  dayClicked({ date }: { date: Date }): void {
    if (moment(date).isSame(this.viewDate, "month")) {
      this.activeDayIsOpen = true;
      this.viewDate = date;

      this.getEventsOfDay(date);
    }
  }

  getEventsOfDay(date: Date) {
    this.calendarWidgetService.list$.pipe(take(1)).subscribe((list) => {
      this.selectedEvents = list.filter((event) =>
        moment(event.formatedStartTime).isSame(date, "day")
      );
    });
  }

  beforeViewRender(): void {
    if (
      this.historyDate.getMonth() !== this.viewDate.getMonth() ||
      this.historyDate.getFullYear() !== this.viewDate.getFullYear()
    ) {
      this.viewDate = moment(this.viewDate).isSame(moment(), "day")
        ? this.viewDate
        : this.shouldSelectFirstDay()
        ? moment(this.viewDate).startOf("month").toDate()
        : this.viewDate;
      setTimeout(() => this.loadEvents());
    }
    this.historyDate = this.viewDate;
  }

  shouldSelectFirstDay() {
    if (
      moment(this.viewDate).isAfter(this.historyDate, "month") &&
      !moment(this.historyDate).isSame(
        moment(this.historyDate).endOf("month").startOf("day")
      )
    ) {
      return true;
    }
    if (
      moment(this.viewDate).isBefore(this.historyDate, "month") &&
      !moment(this.historyDate).isSame(
        moment(this.historyDate).startOf("month")
      )
    ) {
      return true;
    }
    return false;
  }

  parse(events: EventDTO[]) {
    return events.map((event) => {
      return {
        start: moment(event.formatedStartTime).startOf("day").toDate(),
        end: moment(event.formatedStartTime).endOf("day").toDate(),
        title: event.title,
      };
    });
  }

  shouldShowWeatherSource(events: EventDTO[]): boolean {
    let shouldShow = false;
    if (events && events.length > 0) {
      events.forEach(
        (event) =>
          (shouldShow =
            event.weatherDataProviderName &&
            event.weatherDataProviderName.length > 0)
      );
    }
    return shouldShow;
  }

  getWeatherSource(events: EventDTO[]): string {
    let weatherSource = "";
    if (events && events.length > 0) {
      events.forEach((event) => {
        if (
          event.weatherDataProviderName &&
          event.weatherDataProviderName.length > 0
        ) {
          weatherSource = event.weatherDataProviderName;
        }
      });
    }
    return weatherSource;
  }

  viewDateChange(date: Date) {
    this.viewDate = date;
    this.getEventsOfDay(date);
  }
}
