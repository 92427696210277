import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { ContactRelationship } from "@app/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ContactRelationService extends CustomEntityCollectionServiceBase<ContactRelationship> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("ContactRelationship", serviceElementsFactory);
  }
  // @ts-ignore
  getWithQuery = (
    contactId: string,
    params?: any,
    setListDefaults = true
  ): Observable<ContactRelationship[]> => {
    this.setLoading(true);
    // @ts-ignore
    return this.apiService
      .get(`contacts/${contactId}/contact-relationships`, { ...params })
      .pipe(
        map((response: ContactRelationship[]) => {
          this.upsertManyInCache(response);
          this.setLoading(false);
          if (setListDefaults) {
            this.setListDefaults(response);
          }
          return response;
        }),
        catchError((err) => err)
      );
  };

  patch(
    entity: Partial<ContactRelationship>,
    contactId: string,
    eaRelationshipId: string
  ) {
    return this.apiService
      .patch(
        `contacts/${contactId}/contact-relationships/${eaRelationshipId}`,
        {
          ...entity,
        }
      )
      .pipe(
        map((response: ContactRelationship) => {
          this.updateOneInCache(response);
          return response;
        }),
        catchError((err) => err)
      );
  }

  post(entity: Partial<ContactRelationship>, contactId: string) {
    return this.apiService
      .post(`contacts/${contactId}/contact-relationships`, {
        ...entity,
        relationshipSource: "crm",
      })
      .pipe(
        map((response: ContactRelationship) => {
          this.addOneToCache(response);
          return response;
        }),
        catchError((err) => err)
      );
  }

  remove(contactId: string, eaRelationshipId: string) {
    return this.apiService
      .delete(`contacts/${contactId}/contact-relationships/${eaRelationshipId}`)
      .pipe(
        map(() => {
          this.removeOneFromCache(eaRelationshipId);
          this.handleDelete(eaRelationshipId);
          return true;
        }),
        catchError((err) => err)
      );
  }
}
