import { Injectable } from "@angular/core";
import * as assignLeadModalActions from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { TaskType, TypedPaginationListDTO } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import moment from "moment";
import { catchError, map, of, switchMap } from "rxjs";
import { StatsLeadEmployee } from "@app/statistics/boxes/leads/utils";

@Injectable()
export class AssignLeadModalEffects {
  fetchEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assignLeadModalActions.fetchEmployeesRequest),
      switchMap(({ parameters }) =>
        this.apiService
          .get("stats/tasks/employees", {
            sortOrder: "desc",
            taskTypeCategory: "lead",
            to: moment().format(API_DATE_FORMAT),
            includeEmployeesWithoutStatistics: "True",
            ...parameters,
            eaTaskTypeIds: parameters.eaTaskTypeIds,
          })
          .pipe(
            map((response: TypedPaginationListDTO<StatsLeadEmployee>) =>
              assignLeadModalActions.fetchEmployeesSuccess({
                employees: response.rows,
              })
            ),
            catchError(() => of(assignLeadModalActions.fetchEmployeesFail()))
          )
      )
    )
  );

  fetchTaskTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(assignLeadModalActions.fetchTaskTypesRequest),
      switchMap(() =>
        this.apiService
          .get("task-types/search", {
            taskTypeCategory: "lead",
            direction: "incoming,twoway",
          })
          .pipe(
            map((response: TypedPaginationListDTO<TaskType>) =>
              assignLeadModalActions.fetchTaskTypesSuccess({
                taskTypes: response.rows,
              })
            ),
            catchError(() => of(assignLeadModalActions.fetchTaskTypesFail()))
          )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
