import { BudgetTargets } from "@app/models/budget-targets";
import { SalesTargets } from "./sales-targets";

interface ComparisonsOfCommissions {
  lastMonthsCommission: number;
  lastYearCurrentMonthCommission: number;
}

export class EmployeeSalesTarget {
  eaEmployeeId: string;
  employeeFullName: string;
  salesTargets: {
    salesTargets: SalesTargets;
  };
  budgetTargets: BudgetTargets;
  comparisonOfCommissions: ComparisonsOfCommissions;

  constructor(
    eaEmployeeId: string,
    employeeFullName: string,
    salesTargets: SalesTargets,
    budgetTargets: BudgetTargets,
    comparisonOfCommissions
  ) {
    this.eaEmployeeId = eaEmployeeId;
    this.employeeFullName = employeeFullName;
    this.salesTargets = {
      salesTargets: new SalesTargets(salesTargets),
    };
    this.budgetTargets = new BudgetTargets(budgetTargets);
    this.comparisonOfCommissions = comparisonOfCommissions;
  }
}
