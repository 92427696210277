<app-q-table
  [columns]="columns"
  [sortableColumns]="sortableColumns"
  [headers]="headers"
  [key]="'statistics-adfenix-reporting'"
  [data]="values$ | async"
  [length]="(values$ | async).length"
  [enablePaginator]="false"
  [enableClick]="false"
  [enableActions]="false"
  [loading]="loading$ | async"
  [enableSelection]="false"
  [enableSearch]="false"
  [enableCustomization]="enableCustomisation"
>
  <ng-container slot="buttons">
    <app-q-sheet-wrapper>
      <div class="button-container">
        <span class="icon-container"
          ><app-icon name="circle-info"></app-icon
        ></span>
        <span
          *ngIf="filters?.dataTypes !== 'numberOfPlacedBids'"
          class="filter-message"
          translate
          >marketing_report_total_of_potential_buyers_tooltip</span
        >
        <span
          *ngIf="filters?.dataTypes === 'numberOfPlacedBids'"
          class="filter-message"
          translate
          >marketing_report_total_of_bids_tooltip</span
        >
      </div>
    </app-q-sheet-wrapper>
  </ng-container>
  <ng-template let-cell="cell" let-column="column" let-row="row">
    <ng-container [ngSwitch]="column">
      <ng-container *ngSwitchCase="'columnName'">
        {{ cell === "nonGrouped" ? (cell.toLowerCase() | translate) : cell }}
      </ng-container>
      <ng-container *ngSwitchCase="'value'">
        {{ cell | qNumber }}
      </ng-container>
      <ng-container *ngSwitchCase="'numberOfObjects'">
        {{ cell | qNumber }}
      </ng-container>
      <ng-container *ngSwitchCase="'average'">
        {{ cell | qNumber }}
      </ng-container>
      <ng-container *ngSwitchCase="'impact'">
        <ng-container *ngIf="row?.columnName !== 'nonGrouped'">
          <span
            [style.font-weight]="'bold'"
            [ngClass]="cell > 0 ? 'green' : 'red'"
            >{{ cell > 0 ? "+" : "" }}{{ cell * 100 | qNumber }}%</span
          >
          <p class="metadata" *ngIf="row.numberOfObjects < 100">
            <span translate>insufficient_data</span>
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ cell }}
      </ng-container>
    </ng-container>
  </ng-template>
</app-q-table>
