<h4 translate>event_log</h4>
<section>
  <ng-container *ngFor="let log of taskActionLogs">
    <div class="action-log-item" *ngIf="showAction(log)">
      <p>
        {{ log.timestamp }},
        <span class="meta">{{ log.firstName }} {{ log.familyName }}</span>
      </p>
      <hr />
      <p>
        {{ getTranslationKey(log.actionType) | translate }}
        {{ getAdditionalInfo(log) }}
      </p>
      <p *ngIf="!!log.taskTransfer?.transferBy">
        {{ "assigned_by" | translate }}
        {{ log.taskTransfer.transferBy.employeeFullName }} ,
        {{ log.taskTransfer.transferBy.officeName }}
      </p>
      <p *ngIf="!!log.taskTransfer?.transferComment">
        {{ "comment" | translate }} : {{ log.taskTransfer?.transferComment }}
      </p>
    </div>
  </ng-container>
  <ng-template #noLogs>
    <h5 translate>no_events</h5>
  </ng-template>
</section>
