import { Component, Input } from "@angular/core";
import { NPS_VALUES } from "@app/models/nps";

@Component({
  selector: "nps-indicator",
  templateUrl: "./nps-indicator.component.html",
  styleUrls: ["./nps-indicator.component.scss"],
})
export class NpsIndicatorComponent {
  @Input() npsClass: string;
  @Input() title: string;

  getNpsIndicator() {
    switch (this.npsClass) {
      case NPS_VALUES.DETRACTORS:
        return "/assets/img/smiley-sad.svg";
      case NPS_VALUES.PASSIVES:
        return "/assets/img/smiley-default.svg";
      case NPS_VALUES.PROMOTERS:
        return "/assets/img/smiley-happy.svg";
      default:
        return "/assets/img/smiley-default.svg";
    }
  }
}
