import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { SearchEmployeeCardComponent } from "./search-employee-card/search-employee-card.component";
import { SearchEmployeeComponent } from "./search-employee.component";

const exportComponents = [SearchEmployeeComponent, SearchEmployeeCardComponent];

const components = [...exportComponents];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadModule,
    TranslateModule,
    UiComponentsModule,
  ],
  declarations: [...components],
  exports: [...exportComponents],
})
export class SearchEmployeeModule {}
