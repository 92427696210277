export const OWNS_RESIDENCE = "OWNS_A_RESIDENCE";
export const DOES_NOT_OWN_RESIDENCE = "DOES_NOT_OWN_A_RESIDENCE";
export const RESIDENCE_UNKNOWN = "UNKNOWN";

export const OWNS_RESIDENCE_COLOR = "green";
export const DOES_NOT_OWN_RESIDENCE_COLOR = "red";
export const RESIDENCE_UNKNOWN_COLOR = "gray";

export const OWNS_RESIDENCE_TRANSLATION = "contact_owns_residence_text";
export const DOES_NOT_OWN_RESIDENCE_TRANSLATION =
  "contact_does_not_own_residence_text";
export const RESIDENCE_UNKNOWN_TRANSLATION =
  "contact_owns_residence_unknown_text";
