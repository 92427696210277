<article class="card">
  <section>
    <div class="card__icon">
      <i class="icon-user"></i>
    </div>
  </section>
  <section class="card__details">
    <div>{{ employee.fullName }}</div>
    <small>{{ employee.title }}</small>
    <small>{{ employee.mobile }}</small>
    <small *ngIf="employee.mobile?.length > 0 && employee.email?.length > 0">
      |</small
    >
    <small>{{ employee.email }}</small>
  </section>
  <div *ngIf="deletable" class="link flex-center" (click)="delete.emit(true)">
    <app-icon name="times"></app-icon>
  </div>
</article>
