<section class="competition-form">
  <form [formGroup]="competitionForm" (ngSubmit)="onSubmit()">
    <div class="form-group">
      <label translate>title</label>
      <input
        type="text"
        formControlName="title"
        placeholder="{{ 'most_sold_objects' | translate }}"
        [ngClass]="
          !competitionForm.get('title').valid &&
          competitionForm.get('title').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="title">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label translate>start_end_date</label>
      <input
        formControlName="dateRange"
        type="text"
        bsDaterangepicker
        [bsConfig]="{
          rangeInputFormat: 'L',
          containerClass: 'competition-daterangepicker'
        }"
        placement="left"
        [ngClass]="
          !competitionForm.get('dateRange').valid &&
          competitionForm.get('dateRange').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="dateRange">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label translate>offices</label>
      <office-dropdown
        [offices]="offices"
        [parentFormGroup]="competitionForm"
        [disableSelectOffice]="false"
        [ngClass]="
          !competitionForm.get('office').valid &&
          competitionForm.get('office').touched
            ? 'validation-input'
            : ''
        "
      ></office-dropdown>
      <div ngxErrors="office">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label translate>competition_type</label>
      <select formControlName="kpiType">
        <option value="" disabled translate>choose_type</option>
        <option *ngFor="let type of kpiTypes" [value]="type" translate>
          {{ type }}
        </option>
      </select>
      <div ngxErrors="kpiType">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </form>
</section>
