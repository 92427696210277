import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as segmentationActions from "@app/dashboard/more-opportunities/segmentation-list/segmentation-list.actions";
import {
  getSegments,
  SegmentState,
} from "@app/dashboard/more-opportunities/segmentation-list/segmentation-list.reducer";
import { Contact } from "@app/models";
import { CREATE_CALLING_LIST_LIMIT } from "@app/sidebar/calling-lists/create-calling-list/utils/create-calling-list-const";
import { getSelectedContactList } from "@app/sidebar/calling-lists/ngrx/calling-lists.reducer";
import { select, Store } from "@ngrx/store";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { first, map, Observable } from "rxjs";

@Component({
  selector: "app-more-business-opportunities-widget",
  templateUrl: "./more-business-opportunities-widget.component.html",
  styleUrls: ["./more-business-opportunities-widget.component.scss"],
})
export class MoreBusinessOpportunitiesWidgetComponent implements OnInit {
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  segments$: Observable<SegmentState[]>;
  allContactIds$: Observable<string[]>;
  selectedSegment: SegmentState;
  selectedSegment$: Observable<SegmentState>;
  showCreateCallingListSidebar = false;
  listName: string;
  createCallingListLimit = CREATE_CALLING_LIST_LIMIT;

  modalRef: BsModalRef;

  constructor(
    private store: Store<AppState>,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  // Todo: Only get for specific list
  fetchContactsForSegments(): void {
    this.segments$.pipe(first()).subscribe((segments) => {
      segments.forEach((segment) => this.fetchContacts(segment));
    });
  }

  fetchContacts({ segmentId, contacts }): void {
    const params = { offset: contacts.length };
    this.store.dispatch(
      segmentationActions.searchSegmentList(params, segmentId)
    );
  }

  getCallingListClickEvent(segment: SegmentState): any {
    if (segment.total < this.createCallingListLimit) {
      return this.openCreateCallingListSidebar(
        segment.segmentId,
        segment.segmentName
      );
    }
    return;
  }

  openCreateCallingListSidebar(id: any, listName: string): void {
    this.listName = listName;
    const params = {
      eaEmployeeId: this.eaEmployeeId,
      eaOfficeId: this.eaOfficeId,
    };
    this.showCreateCallingListSidebar = true;
    this.store.dispatch(
      segmentationActions.searchSegmentListForCallingList(params, id)
    );
  }

  private mapStateToProps() {
    this.segments$ = this.store.pipe(select(getSegments));
    this.allContactIds$ = this.store.pipe(
      select(getSelectedContactList),
      map((contactList: Contact[]) =>
        contactList.map((contact) => contact.contactId)
      )
    );
  }

  showSegmentListDetails(segment: SegmentState, template: TemplateRef<any>) {
    // Get list for segment
    this.selectedSegment$ = this.segments$.pipe(
      map((segments) =>
        segments.find((seg) => seg.segmentId === segment.segmentId)
      )
    );
    this.modalRef = this.modalService.show(template, { animated: false });
  }
}
