import { Contact } from "@app/models";
import { getPhoneNumberType } from "@app/shared/validators/phone-validator";

interface SearchProviderAddress {
  city: string;
  coordinate: {
    lat: string;
    lon: string;
  };
  displayAddress: string;
  number: string;
  street: string;
  zipCode: string;
}

export class SearchProviderContact {
  name: string;
  companyId?: string;
  phoneNumbers: any[];
  addresses: SearchProviderAddress[];
  subscriberType: string;
  street: string;
  zip: string;
  city: string;
  email: string;
  msisdn: string;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getStreet(): string {
    let street = "";
    if (this.addresses.length > 0) {
      street = this.addresses[0].displayAddress;
    }
    return street;
  }

  getFullName(): string {
    return this.name;
  }

  getPhoneNumber(countryCode: string): string {
    let mobileNumber = "";
    if (this.phoneNumbers.length > 0) {
      this.phoneNumbers.forEach((number) => {
        if (getPhoneNumberType(number.phoneNumber, countryCode) === "MOBILE") {
          mobileNumber = number.phoneNumber;
        }
      });
    }
    return mobileNumber;
  }

  toQuedroContact(countryCode: string): Contact {
    const contact = new Contact();
    contact.firstName = this.getFirstNameByFullName();
    contact.familyName = this.getLastNameByFullName();
    contact.street = this.getStreet();
    contact.email = this.email;
    contact.msisdn = this.getPhoneNumber(countryCode);

    if (this.addresses.length > 0) {
      contact.street = this.addresses[0].displayAddress;
      contact.zip = this.addresses[0].zipCode;
      contact.city = this.addresses[0].city;
    }

    return contact;
  }

  getFirstNameByFullName(): string {
    return this.name.replace(/ .*/, "");
  }

  getLastNameByFullName(): string {
    return this.name.replace(this.name.replace(/ .*/, ""), "").replace(" ", "");
  }
}
