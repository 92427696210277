import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { combineLatest, first, map, Observable, Subject } from "rxjs";
import { QObject } from "@app/models";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { constructObjectUrl, ObjectLink } from "@app/shared/utils/object-utils";
import { OfficeEmployeeDropdownService } from "@app/shared/modules/form-components/office-employee-dropdown/office-employee-dropdown.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-add-object-links-modal",
  templateUrl: "./add-object-links-modal.component.html",
  styleUrls: ["./add-object-links-modal.component.scss"],
  providers: [OfficeEmployeeDropdownService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddObjectLinksModalComponent implements OnDestroy {
  objectLinksForm: FormGroup;
  objectLinks: ObjectLink[];
  hasLinks: boolean;
  isObjectSelected: boolean;
  object$: Observable<QObject>;

  unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddObjectLinksModalComponent>,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public sendMessageService: SendMessageService,
    private translateService: TranslateService
  ) {
    this.buildForms();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  get linksFormArray(): FormArray {
    return this.objectLinksForm.get("links") as FormArray;
  }

  openUrl(url: string): void {
    window.open(url);
  }

  handleObjectChosen(chosenObject: QObject) {
    this.linksFormArray.clear();
    this.isObjectSelected = !!chosenObject;

    if (chosenObject) {
      this.getObjectDescriptionUrl$(chosenObject).subscribe(
        (objectDescriptionLink) => {
          const allLinks = [...chosenObject.links, objectDescriptionLink];
          this.objectLinks = allLinks.filter((link: ObjectLink) => !!link.url);
          if (this.objectLinks && this.objectLinks.length > 0) {
            this.hasLinks = true;
            this.objectLinks.forEach(() =>
              this.linksFormArray.push(new FormControl())
            );
          } else {
            this.hasLinks = false;
          }
        }
      );
    }
  }

  handleAddLinks() {
    const linksToAdd = [];
    this.objectLinksForm.getRawValue().links.forEach((linkShouldBeAdded, i) => {
      if (linkShouldBeAdded) {
        linksToAdd.push(this.objectLinks[i]);
      }
    });
    this.sendMessageService.objectLinks$.next(linksToAdd);
    this.closeModal();
  }

  private getObjectDescriptionUrl$(object: QObject): Observable<ObjectLink> {
    return combineLatest([
      this.store.pipe(select(fromConfig.getObjectLocationUrl)),
      this.store.pipe(select(fromConfig.getObjectLocationExternalIdProvider)),
      this.store.pipe(select(fromConfig.getObjectLocationExternalIdCategory)),
      this.store.pipe(select(fromConfig.getObjectLocationUseEaOidAsExternalId)),
    ]).pipe(
      first(),
      map(
        ([
          objectUrl,
          objectUrlExternalIdProvider,
          objectUrlExternalIdCategory,
          objectLocationUseEaOidAsExternalId,
        ]) => ({
          name: this.translateService.instant("object_description"),
          url: constructObjectUrl({
            object: object,
            url: objectUrl,
            objectLocationUseEaOidAsExternalId:
              objectLocationUseEaOidAsExternalId,
            objectUrlExternalIdProvider: objectUrlExternalIdProvider,
            objectUrlExternalIdCategory: objectUrlExternalIdCategory,
          }),
        })
      )
    );
  }

  private buildForms() {
    this.objectLinksForm = this.fb.group({
      links: this.fb.array([]),
    });
  }
}
