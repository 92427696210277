import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { SearchProfile } from "@app/contacts/contact-search-profile/models/search-profile";
import { CategoryType } from "@app/models/category-type";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/features";
import { getEaEmployeeId } from "@app/shared/user";
import { SEARCH_PROFILE_EDIT } from "@app/shared/utils/tab-types";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import * as searchProfileActions from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.actions";
import * as fromSearchProfile from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { FormattedSearchArea } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { SearchProfileFormComponent } from "@app/sidebar/search-profile/search-profile-form/search-profile-form.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";

@Component({
  selector: "app-edit-search-profile",
  templateUrl: "./edit-search-profile.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./edit-search-profile.component.scss",
  ],
})
export class EditSearchProfileComponent implements OnInit, ConnectableTab {
  @ViewChild(SearchProfileFormComponent, { static: false })
  spForm: SearchProfileFormComponent;

  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  tabType = SEARCH_PROFILE_EDIT;
  searchProfile$: Observable<SearchProfile>;
  searchProfileId$: Observable<string>;
  searchProfileAreas$: Observable<FormattedSearchArea[]>;
  additionalFilters$: Observable<CategoryType[]>;
  country$: Observable<string>;
  searchProfileFeature$: Observable<SearchProfileFeature>;
  unsubscribe$: Subject<void> = new Subject<void>();
  patching$: Observable<boolean>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;
  deleting$: Observable<boolean>;
  eaEmployeeId$: Observable<string>;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getAdditionalFilters();
    this.getSearchProfile();
    this.connectTab();
  }

  private mapStateToProps(): void {
    this.searchProfile$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfile),
      filter((value) => !!value)
    );
    this.searchProfileId$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfileId)
    );
    this.searchProfileAreas$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfileAreas),
      filter((array) => array?.length > 0)
    );
    this.additionalFilters$ = this.store.pipe(
      select(fromSearchProfile.getAdditionalFilters)
    );
    this.country$ = this.store.pipe(select(fromConfig.getCountry));
    this.searchProfileFeature$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE))
    );
    this.patching$ = this.store.pipe(select(fromSearchProfile.getPatching));
    this.loading$ = this.store.pipe(select(fromSearchProfile.getLoading));
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.deleting$ = this.store.pipe(select(fromSearchProfile.getDeleting));
    this.eaEmployeeId$ = this.store.pipe(select(getEaEmployeeId));
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstEntity, current]) => {
        if (_.isEqual(firstEntity, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  getAdditionalFilters(): void {
    observableCombineLatest([this.country$, this.searchProfileFeature$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([country, feature]) => {
        if (feature.categoryIds) {
          this.store.dispatch(
            searchProfileActions.getAdditionalFiltersRequest({
              categoryIds: feature.categoryIds,
              country,
            })
          );
        }
      });
  }

  getSearchProfile(): void {
    this.route.params
      .pipe(map((params) => params?.id))
      .pipe(
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchProfileId) =>
        this.store.dispatch(
          searchProfileActions.getSearchProfileRequest({ searchProfileId })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  submit(): void {
    this.spForm.handleSubmit();
  }

  updateSearchProfile(searchProfile: Partial<SearchProfile>): void {
    this.searchProfileId$
      .pipe(withLatestFrom(this.eaEmployeeId$), first())
      .subscribe(([id, eaEmployeeId]) =>
        this.store.dispatch(
          searchProfileActions.updateSearchProfileRequest({
            params: {
              ...searchProfile,
              updatedByEaEmployeeId: eaEmployeeId,
            },
            searchProfileId: id,
          })
        )
      );
  }

  deleteSearchProfile(searchProfileId: string): void {
    this.store.dispatch(
      searchProfileActions.deleteSearchProfileRequest({ searchProfileId })
    );
  }
}
