import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/settings/lead-templates/ngrx/lead-templates.actions";
import { TaskTemplate, TypedPaginationListDTO } from "@app/models";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface LeadTemplatesState {
  data: TypedPaginationListDTO<TaskTemplate>;
  isLoading: boolean;
  filterId: string;
}

const initialState: LeadTemplatesState = {
  data: null,
  isLoading: false,
  filterId: "",
};

export const leadTemplatesReducer = createReducer(
  initialState,
  on(actions.getLeadTemplatesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadTemplatesSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  })),
  on(actions.getLeadTemplatesFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.changeFilter, (state, { id }) => ({
    ...state,
    filterId: id,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.leadTemplates
);

export const selectData = createSelector(
  getState,
  (state: LeadTemplatesState) => state.data
);
export const selectIsLoading = createSelector(
  getState,
  (state: LeadTemplatesState) => state.isLoading
);
export const selectFilterId = createSelector(
  getState,
  (state: LeadTemplatesState) => state.filterId
);
