import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { map } from "rxjs";

import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { Contact } from "@app/models";

export default function createContactHasRequiredContactMethodValidator(
  contactService: ContactService,
  contactMethod: string
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return contactService.getById(value).pipe(
      map((contact: Contact) => {
        if (!contact.msisdn && contactMethod === "sms") {
          return { missingPhone: true };
        } else if (!contact.email && contactMethod === "email") {
          return { missingEmail: true };
        } else {
          return null;
        }
      })
    );
  };
}
