import { Component, OnInit } from "@angular/core";
import { checkIfSystemOnlineRequest } from "@app/shared/config/config.actions";

@Component({
  selector: "app-system-temporarily-down",
  templateUrl: "./system-temporarily-down.component.html",
  styleUrls: ["./system-temporarily-down.component.scss"],
})
export class SystemTemporarilyDownComponent implements OnInit {
  // constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    // const systemCheckInterval = setInterval(
    //   () => this.checkIfSystemOnline(this.store),
    //   5000
    // );
    // this.store
    //   .pipe(
    //     select(getProxyVerified),
    //     filter((verified) => !!verified),
    //     first()
    //   )
    //   .subscribe(() => {
    //     clearInterval(systemCheckInterval);
    //     this.store.dispatch(loadRequest());
    //     this.store.dispatch(routerActions.go({ path: "crm" }));
    //   });
  }

  checkIfSystemOnline(store): void {
    store.dispatch(checkIfSystemOnlineRequest());
  }
}
