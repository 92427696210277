import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import { ContactRelationService } from "@app/core/ngrx/entity-services/contact-relation.service";
import { UrlService } from "@app/core/services/url/url.service";
import { Contact, ContactRelationship } from "@app/models";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { selectIsLoading } from "@app/widgets/widgets/contact-widgets/contact-relations-widget/ngrx/contact-relations-widget.reducer";
import { select, Store } from "@ngrx/store";
import { first, map, Observable } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-contact-relations-widget",
  templateUrl: "./contact-relations-widget.component.html",
  styleUrls: ["./contact-relations-widget.component.scss"],
})
export class ContactRelationsWidgetComponent implements OnInit, OnChanges {
  @Input() contactId: string;

  loading$: Observable<boolean>;
  contact$: Observable<Contact>;
  contactType$: Observable<string>;
  showForm = false;
  editContactRelation: ContactRelationship = null;

  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
    public contactRelationService: ContactRelationService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.contactId && this.contactId) {
      this.contactRelationService
        .getWithQuery(this.contactId)
        .pipe(first())
        .subscribe();
    }
  }

  private mapStateToProps() {
    this.contact$ = this.store.pipe(select(fromContact.getContact));
    this.contactType$ = this.contact$.pipe(
      map((contact) => contact.contactType)
    );
    this.loading$ = this.store.select(selectIsLoading);
  }

  openInNewTab(contactId: string) {
    const commands = ["/crm", "contacts", contactId, "overview"];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  open(contactId: string, event) {
    if (!event.target.attributes["disablenavigation"]) {
      this.store.dispatch(
        go({
          path: [
            "crm",
            "contacts",
            contactId,
            "overview",
            { outlets: { sidebar: null } },
          ],
        })
      );
    }
  }

  deleteContactRelation(eaRelationshipId: string) {
    this.contactRelationService
      .remove(this.contactId, eaRelationshipId)
      .pipe(first())
      .subscribe(() =>
        this.contactRelationService.getWithQuery(this.contactId).subscribe()
      );
  }
}
