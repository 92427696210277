<div class="wrapper support">
  <sidebar-header
    [type]="tabType"
    [label]="'support' | translate"
  ></sidebar-header>
  <ng-container *ngIf="supportFeature$ | async as supportFeature">
    <div class="scroll-view">
      <section>
        <div class="support__info">
          <alert type="info">{{ "support_info_text" | translate }}</alert>
        </div>
        <div *ngIf="supportFeature.supportMail" class="support__mail">
          <h4>{{ "email" | translate }}</h4>
          <a href="mailto:{{ supportFeature.supportMail }}">{{
            supportFeature.supportMail
          }}</a>
        </div>
        <div
          *ngIf="supportFeature.supportTel && supportFeature.supportTelCountry"
          class="support__tel"
        >
          <h4>{{ "support_phone" | translate }}</h4>
          <a href="tel:+{{ supportFeature.supportTel }}">
            {{
              "+" + supportFeature.supportTel
                | phoneFormat : supportFeature.supportTelCountry
            }}
          </a>
        </div>
      </section>

      <hr />
      <section>
        <div *ngIf="supportFeature.helpVideoUrl" class="support__videos">
          <h4>
            <i class="fa fa-video-camera"></i>
            {{ "educational_videos" | translate }}
          </h4>
          <p>{{ "educational_video_text" | translate }}</p>
          <a
            href="{{ supportFeature.helpVideoUrl }}"
            target="_blank"
            rel="noopener noreferrer"
            class="vimeo-button"
          >
            <div
              *ngIf="supportFeature.noOfHelpVideos"
              class="vimeo-button__number-of-videos"
            >
              <span>
                {{ supportFeature.noOfHelpVideos }}
              </span>
            </div>
            <img
              class="vimeo-button__image"
              src="../../../../../assets/img/vimeo-logo-white.png"
            />
          </a>
        </div>
      </section>
      <hr />

      <ng-container *ngIf="supportFeature.documentLink">
        <section>
          <div>
            <h4>{{ "support_document_link_header" | translate }}</h4>
            <a
              href="{{ supportFeature.documentLink }}"
              target="_blank"
              rel="noopener noreferrer"
            >
              {{ "open_support_document_link" | translate }}
            </a>
            <i class="icon-share-alt"></i>
          </div>
        </section>
        <hr />
      </ng-container>

      <section>
        <form [formGroup]="form">
          <div
            class="form-group"
            *ngIf="
              supportFeature.sendMessageEnabled && supportFeature.supportMail
            "
            class="support__message"
          >
            <h4>{{ "write_message" | translate }}</h4>
            <textarea
              formControlName="message"
              class="message-textarea"
              minlength="1"
            ></textarea>
          </div>
        </form>
      </section>
    </div>
    <div class="sidebar-footer">
      <button type="button" class="btn btn-cancel btn-lg" (click)="closeTab()">
        {{ "cancel" | translate }}
      </button>
      <button
        type="submit"
        *ngIf="supportFeature.sendMessageEnabled && supportFeature.supportMail"
        class="btn btn-success btn-lg"
        [disabled]="!form.valid"
        (click)="handleSendMessage()"
      >
        {{ "send" | translate }}
      </button>
    </div>
  </ng-container>
</div>
