import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ValidService } from "@app/shared/modules/search-address/search-address/valid.service";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import { FormControl } from "@angular/forms";
import {
  Address,
  ADDRESS_PROVIDERS,
  AddressProvider,
} from "@app/shared/modules/search-address/search-address/AddressProvider";
import { ONLY_LETTERS_DIGITS_WHITESPACE } from "@app/shared/utils/regex-patterns";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { getFormattedAddress } from "@app/shared/utils/object-utils";

@Component({
  selector: "search-address-adv",
  templateUrl: "./search-address-advanced.component.html",
  styleUrls: ["./search-address-advanced.component.scss"],
  providers: [ValidService],
})
export class SearchAddressAdvComponent implements OnInit, OnDestroy {
  @Input() containerFormControl: FormControl;
  @Input() placeholder: string;
  @Input() type: "street" | "city" | "zip";
  @Input() enabled: boolean;
  @Input() provider: string;
  @Input() showFullAddress = false;
  @Input() autoFocus = false;
  @Input() newStyle = false;
  @Input() invalid = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelected = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<any>();

  addressControl = new FormControl("");
  filteredOptions$: Observable<any>;
  loading = false;
  unsubscribe$ = new Subject<void>();

  private _countryCode: string;
  selected: boolean;

  get countryCode(): string {
    return this._countryCode;
  }

  @Input()
  set countryCode(value: string) {
    this._countryCode = value.toLowerCase();
  }

  constructor(private validService: ValidService) {}

  ngOnInit() {
    this.initProvider();

    this.containerFormControl?.valueChanges
      .pipe(
        filter((value) => !!value && value !== this.addressControl.value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((value) => this.addressControl.setValue(value));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  textInputDisplayFormatter(address: string | Address): string {
    if (typeof address === "string") {
      return address;
    }

    if (!this.showFullAddress) {
      return address[this.type];
    }

    return address.fullAddress;
  }

  formatOptionItem(option: string | Address): string {
    if (typeof option === "string") {
      return option;
    }

    switch (this.type) {
      case "street":
        return option.fullAddress;
      case "zip":
        return `${option.zip} ${option.city}`;
      case "city":
        return option.city;
    }
  }

  handleSelect(event: MatAutocompleteSelectedEvent) {
    const selectedAddress = event?.option.value;

    if (!selectedAddress) {
      this.addressControl.setValue("");
      this.containerFormControl?.setValue("");
    } else {
      this.setContainerFormControlValue(selectedAddress);
    }

    this.onSelected.emit(selectedAddress);
  }

  onBlur() {
    this.setContainerFormControlValue(this.addressControl.value);
    this.blur.emit();
  }

  private setContainerFormControlValue(
    selectedAddress: string | Address
  ): void {
    const value =
      typeof selectedAddress === "string"
        ? selectedAddress
        : selectedAddress[this.type];

    this.containerFormControl?.setValue(value);
  }

  private initProvider(): void {
    if (this.provider === ADDRESS_PROVIDERS.VALID) {
      this.validService.type = this.type;
      this.validService.countryCode = this.countryCode;
      this.initDataSource(this.validService);
    }
  }

  private initDataSource(service: AddressProvider): void {
    this.filteredOptions$ = this.addressControl.valueChanges.pipe(
      map((keyword) => {
        if (typeof keyword === "string") {
          return keyword?.trim();
        }

        return keyword;
      }), // removing white space from beginning and end of the string
      debounceTime(1000),
      distinctUntilChanged((a, b) => a === b),
      filter((keyword) => keyword !== this.containerFormControl?.value), // avoid api call when an item is selected from suggestions list
      filter((keyword) => !!keyword && keyword.length > 2),
      filter((keyword) => ONLY_LETTERS_DIGITS_WHITESPACE.test(keyword)),
      tap(() => {
        this.loading = true;
      }),
      switchMap((keyword) =>
        service.suggest(keyword).pipe(
          map((suggestions: Address[]) => {
            this.loading = false;
            if (this.type === "city") {
              const cities = new Set(
                suggestions.map((address) => address.city)
              );

              return [...cities].sort();
            }

            return suggestions
              .map((address) => ({
                ...address,
                fullAddress: getFormattedAddress(address),
              }))
              .sort((a, b) => a.fullAddress.localeCompare(b.fullAddress));
          })
        )
      )
    );
  }
}
