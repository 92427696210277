import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Competition } from "@app/competitions/models/competition.model";
import * as competitionKpiTypes from "@app/competitions/utils/competition-kpi-types";
import * as competitionTypes from "@app/competitions/utils/competition-types";
import { Office } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as formModes from "@app/shared/utils/form-modes";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { CREATE_COMPETITION } from "@app/shared/utils/tab-types";
import { CompetitionFormComponent } from "@app/sidebar/competitions/competition-form/competition-form.component";
import * as competitionsSidebarActions from "@app/sidebar/competitions/ngrx/competitions.actions";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { ConnectableTab } from "../../sidebar-connectable-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";

interface DataForForm {
  offices: Office[];
  canEditOffice: boolean;
  userOfficeId: string;
}

@Component({
  selector: "create-competition",
  templateUrl: "./create-competition.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-competition.component.scss",
  ],
})
export class CreateCompetitionComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(CompetitionFormComponent, { static: false })
  competitionFormComponent: CompetitionFormComponent;
  tab$: Observable<SidebarTab>;
  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  dataForForm$: Observable<DataForForm>;
  tabType = CREATE_COMPETITION;
  competitionFormModes = formModes;
  kpiTypes = [
    competitionKpiTypes.KPI_BOOKED_MEETING,
    competitionKpiTypes.KPI_SALES_CALLS,
    competitionKpiTypes.KPI_SOLD_OBJECTS,
    competitionKpiTypes.KPI_WON_MEETING,
    competitionKpiTypes.KPI_SENT_TIPS,
    competitionKpiTypes.KPI_EXTERNAL_TIPS,
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.connectTab();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.dataForForm$ = observableCombineLatest([
      this.store.pipe(select(fromShared.getOffices)),
      this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN))),
      this.store.pipe(select(fromUser.getEaOfficeId)),
    ]).pipe(
      map(([offices, canEditOffice, userOfficeId]) => ({
        offices,
        canEditOffice,
        userOfficeId,
      }))
    );
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  submitCompetition(): void {
    const newCompetition = this.formatCompetition();
    this.store.dispatch(
      competitionsSidebarActions.createCompetitionRequest({
        competition: newCompetition,
      })
    );
    this.closeTab();
  }

  formatCompetition(): Competition {
    const newCompetition: Competition = {
      title: this.competitionFormComponent.competitionForm.get("title").value,
      startDate: moment(
        this.competitionFormComponent.competitionForm.get("dateRange").value[0]
      )
        .startOf("day")
        .format(API_DATE_FORMAT),
      endDate: moment(
        this.competitionFormComponent.competitionForm.get("dateRange").value[1]
      )
        .endOf("day")
        .format(API_DATE_FORMAT),
      kpiType:
        this.competitionFormComponent.competitionForm.get("kpiType").value,
    };

    if (this.competitionFormComponent.competitionForm.get("office").value) {
      newCompetition.eaOfficeId =
        this.competitionFormComponent.competitionForm.get("office").value;
      newCompetition.competitionType = competitionTypes.OFFICE;
    } else {
      newCompetition.competitionType = competitionTypes.CUSTOMER;
    }

    return newCompetition;
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }
}
