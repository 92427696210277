<div
  [@fadeInUp]
  [class]="
    'statistics-widget statistics-widget--' + size + ' widget-' + widgetType
  "
>
  <app-container-box [padding]="padding" [margin]="'none'">
    <div class="statistics-widget__content">
      <ng-content></ng-content>
    </div>
    <div *ngIf="!!showMoreLink" class="statistics-widget__show-more-btn">
      <a
        [routerLink]="showMoreLink"
        [target]="openLinkInNewTab ? '_blank' : '_parent'"
        class="btn btn-default"
      >
        {{ "show_more" | translate }}
        <i class="icon-arrow-right"></i>
      </a>
    </div>
  </app-container-box>
</div>
