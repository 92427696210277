import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "list-load-more-small",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./list-load-more-small.component.scss",
  ],
  template: `
    <li
      class="list-load-more-small flex-center"
      [class.disabled]="disabled"
      (click)="onClick()"
    >
      <button class="btn btn-secondary--ghost">
        <spinner *ngIf="disabled; else content" [size]="'1rem'"></spinner>
        <ng-template #content>
          <ng-content></ng-content>
        </ng-template>
      </button>
    </li>
  `,
})
export class ListLoadMoreSmallComponent {
  @Input() disabled = false;
  @Output() loadMore = new EventEmitter();

  onClick() {
    if (!this.disabled) {
      this.loadMore.emit({});
    }
  }
}
