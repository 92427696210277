import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { dependentValidator } from "@ngspot/ngx-errors";
import moment from "moment";
import { first, map, Observable, Subject, takeUntil } from "rxjs";

import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { EmployeeService } from "@app/core/ngrx/entity-services/employee.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { Employee, Office, PostValueMonitorBody } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import createContactHasRequiredContactMethodValidator from "@app/shared/modules/ui-components/q-search-contact/validators/has-required-contact-method";
import createObjectHasCoordinatesValidator from "@app/shared/modules/ui-components/q-search-object/validators/has-coordinates";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as fromUser from "@app/shared/user";
import { markAllAsDirty } from "@app/shared/utils/form-utils";
import { VALUE_MONITOR } from "@app/statistics/utils/supported-widgets";

@Component({
  selector: "app-value-monitor-form",
  templateUrl: "./value-monitor-form.component.html",
  styleUrls: ["./value-monitor-form.component.scss"],
})
export class ValueMonitorFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() action: "create" | "edit";
  @Input() data;

  form: FormGroup;
  frequencyOptions: Select = [
    { label: "every_month", value: 1 },
    { label: "3_months", value: 3 },
    { label: "6_months", value: 6 },
  ];
  contactMethodOptions: Select = [];
  employeeList: Select = [];
  baseValuationDateMaxDate = moment().toDate();
  nextTriggerTimeMinDate = moment().add(1, "month").toDate();
  path: string;

  unsubscribe$ = new Subject<void>();
  office$: Observable<Office>;
  enabledContactMethods$: Observable<Select>;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private contactService: ContactService,
    private objectService: ObjectService,
    private employeeService: EmployeeService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.office$.pipe(first()).subscribe((office) => {
      this.employeeService
        .getWithQuery({
          eaOfficeId: office.eaOfficeId,
          status: "active",
        })
        .subscribe((employeeList: any) => {
          this.employeeList = employeeList.map((employee: Employee) => {
            const { eaEmployeeId, fullName } = employee;
            return { label: fullName, value: eaEmployeeId };
          });
        });
    });
    if (this.action === "create") {
      this.form
        .get("eaOid")
        .valueChanges.pipe(takeUntil(this.unsubscribe$))
        .subscribe((eaOid) => {
          if (eaOid) {
            this.objectService
              .getById(eaOid)
              .pipe(first())
              .subscribe((value) => {
                const { soldPrice, price, dates } = value;

                const contractMeetingDate = moment(
                  dates?.find((date) => date.typeName === "contract meeting")
                    ?.date,
                  "YYYYMMDDHHmmss"
                );

                const baseValuation = () => {
                  if (Number(soldPrice) > 0) {
                    return soldPrice;
                  } else if (Number(price) > 0) {
                    return price;
                  } else {
                    return "0";
                  }
                };

                this.form.patchValue({
                  baseValuation: baseValuation(),
                  baseValuationDate: contractMeetingDate.isValid()
                    ? contractMeetingDate
                    : moment(),
                });
              });
          } else {
            this.form.patchValue({
              baseValuation: "0",
              baseValuationDate: "",
            });
          }
        });
    }
    this.path =
      this.route.snapshot.parent.parent.parent.firstChild.routeConfig.path;
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(changes.data.currentValue);
  }

  buildForm() {
    this.form = this.fb.group({
      contactId: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: dependentValidator<string>({
            watchControl: (f) => f!.get("contactMethod")!,
            validator: (value) =>
              createContactHasRequiredContactMethodValidator(
                this.contactService,
                value
              ),
          }),
        },
      ],
      eaOid: [
        "",
        {
          validators: [Validators.required],
          asyncValidators: [
            createObjectHasCoordinatesValidator(this.objectService),
          ],
        },
      ],
      baseValuationDate: ["", [Validators.required]],
      baseValuation: ["", [Validators.required]],
      frequency: 3,
      contactMethod: "sms",
      eaEmployeeId: ["", [Validators.required]],
      nextTriggerTime: ["", [Validators.required]],
    });
  }

  mapStateToProps() {
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.enabledContactMethods$ = this.store.pipe(
      select(getFeature(VALUE_MONITOR)),
      map((feature) => feature.contactMethods),
      map((contactMethods) => {
        return contactMethods.reduce((previousValue, currentValue) => {
          return [
            ...previousValue,
            { label: currentValue, value: currentValue },
          ];
        }, []);
      })
    );
  }

  fillForm() {
    this.form.patchValue(this.data);
    if (this.contactMethodOptions.length === 1) {
      this.form.get("contactMethod").disable();
    }
    if (this.action === "edit") {
      this.form.get("contactId").disable();
      this.form.get("eaOid").disable();
      this.form.get("baseValuation").disable();
      this.form.get("baseValuationDate").disable();
      this.form.get("nextTriggerTime").disable();
    }
  }

  isInvalid(name: string) {
    return this.form.get(name).invalid && this.form.get(name).dirty;
  }

  showContactSelect() {
    return this.form.get("eaOid").value && this.path !== "contacts";
  }

  showObjectSelect() {
    return this.form.get("contactId").value && this.path !== "showings";
  }

  submit(cb: (body: PostValueMonitorBody) => void) {
    const { baseValuation, baseValuationDate, nextTriggerTime, ...rest } =
      this.form.getRawValue();

    if (this.form.valid) {
      this.office$
        .pipe(
          first(),
          map((office) => office.eaOfficeId)
        )
        .subscribe((eaOfficeId) => {
          cb(
            this.action === "create"
              ? {
                  baseValuation: {
                    baseValuationValue: baseValuation,
                    baseValuationDate:
                      moment(baseValuationDate).format("YYYYMMDDHHmmss"),
                  },
                  eaOfficeId,
                  nextTriggerTime:
                    moment(nextTriggerTime).format("YYYYMMDDHHmmss"),
                  ...rest,
                }
              : { ...rest, eaOfficeId }
          );
        });
    } else {
      markAllAsDirty(this.form);
    }
  }
}
