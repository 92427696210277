<app-q-modal>
  <ng-container slot="header">
    {{ "assign" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="form-container leads-to-office">
      <app-list-utility-row
        (searchInput)="handleSearch($event)"
        [enableSearch]="true"
      >
      </app-list-utility-row>
      <div class="assign-office-body">
        <div class="loading-spinner">
          <spinner *ngIf="loading$ | async" [size]="'2rem'"></spinner>
        </div>
        <div class="input-box-container">
          <div class="form-group">
            <span class="text-label" translate>transfer_comment</span>
            <div>
              <textarea
                [(ngModel)]="transferComment"
                [placeholder]="'transfer_comment' | translate"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <div class="checkbox-wrapper">
              <mat-checkbox
                [checked]="canSendEmail"
                (change)="handleCheckboxChange($event)"
              >
                {{ "send_message_when_lead_is_created" | translate }}
              </mat-checkbox>
            </div>
            <div>
              <textarea
                [(ngModel)]="emailContent"
                [placeholder]="'message' | translate"
                [disabled]="!canSendEmail"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card-container">
          <div
            *ngFor="let office of selectedOffices$ | async"
            class="office-card"
          >
            <app-mobile-card
              [enableMetadata]="true"
              [selected]="office.eaOfficeId === selectedOffice?.eaOfficeId"
              (click)="handleSelectedOffice(office)"
            >
              <div title>
                {{
                  (!!office.name ? office.name : office.officeName)
                    | removeOfficePrefix
                    | async
                }}
              </div>
              <div metadata>
                <span
                  >{{ office.street }}, {{ office.city }} {{ office.zip }}</span
                >
              </div>
            </app-mobile-card>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        buttonType="cancel"
        label="cancel"
        classes="margin-right"
        (click)="close()"
      >
      </app-q-button>
      <app-q-button
        buttonType="save"
        label="connect"
        [disabled]="!selectedOffice"
        (click)="handleAssign()"
      >
      </app-q-button>
    </div>
  </ng-container>
</app-q-modal>
