import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsSummaryWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((stats: GrowthStatistics) =>
            widgetActions.fetchStatisticsSuccess({ stats })
          ),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );
}
