import { PotentialBuyer } from "@app/showings/models";
import { createAction, props } from "@ngrx/store";

export const sendPotentialBuyersToERPRequest = createAction(
  "[Send PB] SEND_POTENTIAL_BUYERS_TO_ERP_REQUEST",

  props<{
    eaOid: string;
    potentialBuyers: PotentialBuyer[];
    externalProviderName: string;
  }>()
);

export const sendPotentialBuyersToERPSuccess = createAction(
  "[Send PB] SEND_POTENTIAL_BUYERS_TO_ERP_SUCCESS"
);

export const sendPotentialBuyersToERPFailure = createAction(
  "[Send PB] SEND_POTENTIAL_BUYERS_TO_ERP_FAILURE",
  props<{ errorMessage: string }>()
);

export const sendPotentialBuyersToERPDone = createAction(
  "[Send PB] SEND_POTENTIAL_BUYERS_TO_ERP_DONE"
);

export const reset = createAction("[Send PB] RESET");
