<app-q-modal>
  <ng-container slot="header">
    {{ "add_object_files" | translate }}
    <i
      class="icon-question"
      [tooltip]="'object_files_tooltips' | translate"
    ></i>
  </ng-container>
  <ng-container slot="body">
    <form [formGroup]="objectFilesForm" class="form-container">
      <div class="select-container">
        <app-select-object (chosenObjectChanged)="handleObjectChosen($event)">
        </app-select-object>
      </div>
      <div class="form-group" formArrayName="files">
        <ng-container *ngIf="hasFiles && isObjectSelected">
          <div class="file-errors">
            <div
              *ngIf="objectFilesSingleFileTooLargeError"
              class="alert alert-danger"
            >
              {{ "object_files_single_file_too_large_error" | translate }}
            </div>
            <div
              *ngIf="objectFilesAllFilesTooLargeError"
              class="alert alert-danger"
            >
              {{ "object_files_all_files_too_large_error" | translate }}
            </div>
            <div *ngIf="objectFilesWrongFormatError" class="alert alert-danger">
              {{ "object_files_wrong_format_error" | translate }}
            </div>
            <div
              *ngIf="objectFilesTooManyFilesError"
              class="alert alert-danger"
            >
              {{ "object_files_too_many_files_error" | translate }}
            </div>
          </div>

          <ng-container
            *ngFor="let file of filesFormArray.controls; let i = index"
          >
            <label class="checkbox-group">
              <span class="name">
                {{ objectFiles[i]?.fileDescription }}&nbsp; </span
              ><br />
              <span class="size">
                {{ roundFileSize(objectFiles[i]?.fileSize / 1024 / 1024) }}
                MB </span
              ><br />
              <div
                (click)="openUrl(objectFiles[i]?.filePath)"
                class="url"
                [tooltip]="'open_in_new_tab' | translate"
              >
                {{ objectFiles[i]?.filePath }}&nbsp;
              </div>
              <input type="checkbox" [formControl]="file" />
              <span class="checkbox-group__checkmark"></span>
            </label>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="objectIsLoading$ | async; else NoFiles">
          <div class="loading-spinner">
            <spinner [size]="'2rem'"></spinner>
          </div>
        </ng-container>
        <ng-template #NoFiles>
          <div *ngIf="!hasFiles && isObjectSelected" class="alert alert-info">
            {{ "object_has_no_files" | translate }}
          </div>
        </ng-template>
      </div>
    </form>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button buttonType="cancel" label="cancel" (click)="closeModal()">
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="add"
      [disabled]="!isFileSelectionValid(objectFilesForm.value)"
      (click)="handleAddFiles()"
    >
    </app-q-button>
  </ng-container>
</app-q-modal>
