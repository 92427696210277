<div
  *ngIf="!showAsModal; else datePeriodModal"
  class="flex-1 date-period"
  dropdown
  [autoClose]="false"
>
  <button
    dropdownToggle
    type="button"
    class="dropdown-toggle date-period__dropdown"
    [style.min-width]="minWidth ? minWidth : null"
    translate
  >
    <div class="date-period__dropdown__text-container">
      <span class="date-period__dropdown__text-container__icon">
        <ng-content select="[placeholderIcon]"></ng-content>
      </span>
      <ng-content select="[placeholder]"></ng-content>
    </div>
    <i class="fa fa-long-arrow-down"></i>
  </button>

  <div *dropdownMenu class="dropdown-menu" role="menu" [formGroup]="parentFormGroup">
    <div class="date-period__header">
      <hr>
      <i class="icon-calendar"></i>
      <hr>
    </div>
    <div class="date-period__container">
      <div class="date-period__container__type">
        <label class="radio-button-group" translate>
          week
          <input type="radio" value="weekView" [formControlName]="parentTypeFormControlName">
          <span class="radio-button-group__checkmark"></span>
        </label>
        <label class="radio-button-group" translate>
          month
          <input type="radio" value="monthView" [formControlName]="parentTypeFormControlName">
          <span class="radio-button-group__checkmark"></span>
        </label>
      </div>
      <span translate>from</span>
      <div class="date-period__container__dropdown">
        <div>
          <select [formControlName]="parentStartMonthFormControlName"
                  *ngIf="parentFormGroup.get(parentTypeFormControlName).value === 'monthView'; else startWeeks">
            <option *ngFor="let month of noOfMonths" [value]="month">
              {{ 'month_' + month | translate}}
            </option>
          </select>
        </div>
        <div>
          <select [formControlName]="parentStartYearFormControlName">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
          </select>
        </div>
      </div>
      <span translate>to</span>
      <div class="date-period__container__dropdown">
        <div>
          <select [formControlName]="parentEndMonthFormControlName"
                  *ngIf="parentFormGroup.get(parentTypeFormControlName).value === 'monthView'; else endWeeks">
            <option *ngFor="let month of noOfMonths" [value]="month">
              {{ 'month_' + month | translate}}
            </option>
          </select>
        </div>
        <div>
          <select [formControlName]="parentEndYearFormControlName">
            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
          </select>
        </div>
      </div>
    </div>

    <ng-template #startWeeks>
      <select [formControlName]="parentStartWeekFormControlName">
        <option *ngFor="let week of noOfWeeks" [value]="week">
          {{ 'week_prefix' | translate}} {{ week }}
        </option>
      </select>
    </ng-template>

    <ng-template #endWeeks>
      <select [formControlName]="parentEndWeekFormControlName">
        <option *ngFor="let week of noOfWeeks" [value]="week">
          {{ 'week_prefix' | translate}} {{ week }}
        </option>
      </select>
    </ng-template>

  </div>
</div>

<ng-template #datePeriodModal>
  <div class="flex-1 date-period">
    <button
      type="button"
      class="dropdown-toggle date-period__dropdown"
      [style.min-width]="minWidth ? minWidth : null"
      (click)="openModal(datePeriodModal)"
      translate
    >
      <div class="date-period__dropdown__text-container">
      <span class="date-period__dropdown__text-container__icon">
        <ng-content select="[modalPlaceholderIcon]"></ng-content>
      </span>
        <ng-content select="[modalPlaceholder]"></ng-content>
      </div>
      <i class="fa fa-long-arrow-down"></i>
    </button>

    <ng-template #datePeriodModal>
      <div class="modal-body" [formGroup]="parentFormGroup">
        <div class="date-period__close-btn">
          <i class="fa fa-close" (click)="modalRef.hide()"></i>
        </div>
        <div class="date-period__header">
          <hr>
          <i class="icon-calendar"></i>
          <hr>
        </div>
        <div class="date-period__container">
          <div class="date-period__container__type">
            <label class="radio-button-group" translate>
              week
              <input type="radio" value="weekView" [formControlName]="parentTypeFormControlName">
              <span class="radio-button-group__checkmark"></span>
            </label>
            <label class="radio-button-group" translate>
              month
              <input type="radio" value="monthView" [formControlName]="parentTypeFormControlName">
              <span class="radio-button-group__checkmark"></span>
            </label>
          </div>
          <span translate>from</span>
          <div class="date-period__container__start">
            <div class="date-period__container__field">
              <select [formControlName]="parentStartMonthFormControlName"
                      *ngIf="parentFormGroup.get(parentTypeFormControlName).value === 'monthView'; else startWeeks">
                <option *ngFor="let month of noOfMonths" [value]="month">
                  {{ 'month_' + month | translate}}
                </option>
              </select>
            </div>
            <div class="date-period__container__field">
              <select [formControlName]="parentStartYearFormControlName">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
          <span translate>to</span>
          <div class="date-period__container__end">
            <div class="date-period__container__field">
              <select [formControlName]="parentEndMonthFormControlName"
                      *ngIf="parentFormGroup.get(parentTypeFormControlName).value === 'monthView'; else endWeeks">
                <option *ngFor="let month of noOfMonths" [value]="month">
                  {{ 'month_' + month | translate}}
                </option>
              </select>
            </div>
            <div class="date-period__container__field">
              <select [formControlName]="parentEndYearFormControlName">
                <option *ngFor="let year of years" [value]="year">{{ year }}</option>
              </select>
            </div>
          </div>
        </div>

        <ng-template #startWeeks>
          <select [formControlName]="parentStartWeekFormControlName">
            <option *ngFor="let week of noOfWeeks" [value]="week">
              {{ 'week_prefix' | translate}} {{ week }}
            </option>
          </select>
        </ng-template>

        <ng-template #endWeeks>
          <select [formControlName]="parentEndWeekFormControlName">
            <option *ngFor="let week of noOfWeeks" [value]="week">
              {{ 'week_prefix' | translate}} {{ week }}
            </option>
          </select>
        </ng-template>
        <button type="button" class="btn btn-cancel--filled" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true" translate> close</span>
        </button>
      </div>
    </ng-template>
  </div>
</ng-template>
