import { ExternalSystemLinks } from "@app/integrations/models/ErpSystemLinks";
import { ExternalObjectMetadata } from "@app/integrations/models/ExternalObjectMetadata";
import { IntegrationCopyObject } from "@app/integrations/models/IntegrationCopyObject";
import { IntegrationProviders } from "@app/integrations/models/IntegrationProviders";
import { StoreIntegrationCopyObject } from "@app/integrations/models/StoreIntegrationCopyObject";
import { SupportedLinkType } from "@app/integrations/models/SupportedLinkType";
import * as integrationActions from "@app/integrations/ngrx/integrations.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { PropertyTrigger } from "@app/integrations/models/propertyTrigger";

export interface IntegrationState {
  hasIntegration: boolean | null;
  integratedResources: string[];
  supportedObjectData: string[];
  supportedLinkTypes: SupportedLinkType[];
  providers: IntegrationProviders;
  externalTipsSupported: boolean;
  officeIsConfigured: boolean;
  contactSearchCapabilities: string[];
  newsletterIsConfigured: boolean;
  integrationEngineAdminUrl: string | null;
  objectCopyOptions: IntegrationCopyObject;
  storedCopyObjects: StoreIntegrationCopyObject;
  brandkeeperIsConfigured: boolean;
  brandkeeperUrl: string | null;
  erpSystemLinks: ExternalSystemLinks | null;
  externalObjectMetadata: ExternalObjectMetadata | null;
  eraIntranetUrl: string | null;
  adfenixPropertyTriggerIsConfigured: boolean;
  adfenixPropertyTriggers: PropertyTrigger[] | null;
}

export const initialState: IntegrationState = {
  hasIntegration: null,
  integratedResources: [],
  supportedObjectData: [],
  supportedLinkTypes: [],
  providers: {},
  externalTipsSupported: false,
  officeIsConfigured: false,
  contactSearchCapabilities: [],
  newsletterIsConfigured: false,
  integrationEngineAdminUrl: null,
  objectCopyOptions: null,
  storedCopyObjects: null,
  brandkeeperIsConfigured: false,
  brandkeeperUrl: null,
  erpSystemLinks: null,
  externalObjectMetadata: null,
  eraIntranetUrl: null,
  adfenixPropertyTriggerIsConfigured: false,
  adfenixPropertyTriggers: null,
};

export function integrationReducer(
  state: IntegrationState,
  action: Action
): IntegrationState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(integrationActions.fetchIntegrationsRequest, (state) => ({
    ...state,
    hasIntegration: false,
    integratedResources: [],
  })),
  on(integrationActions.fetchIntegrationsSuccess, (state, { integration }) => ({
    ...state,
    hasIntegration: integration.hasIntegration,
    integratedResources: integration.integratedResources,
  })),
  on(
    integrationActions.fetchIntegrationProviderSuccess,
    (state, { providers }) => ({
      ...state,
      providers,
    })
  ),
  on(
    integrationActions.fetchIntegrationParentReceiverIsConfiguredForOfficeSuccess,
    (state, { officeIsConfigured }) => ({ ...state, officeIsConfigured })
  ),
  on(
    integrationActions.fetchExternalTipsIntegrationDescriptionSuccess,
    (state, { externalTipsSupported }) => ({ ...state, externalTipsSupported })
  ),
  on(
    integrationActions.fetchIntegrationSupportedObjectDataSuccess,
    (state, { supportedObjectData }) => ({ ...state, supportedObjectData })
  ),
  on(
    integrationActions.fetchNewsletterIsConfiguredSuccess,
    (state, { newsletterIsConfigured }) => ({
      ...state,
      newsletterIsConfigured,
    })
  ),
  on(
    integrationActions.fetchIntegrationContactSearchCapabilitiesSuccess,
    (state, { contactSearchCapabilities }) => ({
      ...state,
      contactSearchCapabilities,
    })
  ),
  on(
    integrationActions.fetchIntegrationSupportedLinkTypesSuccess,
    (state, { supportedLinkTypes }) => ({ ...state, supportedLinkTypes })
  ),
  on(
    integrationActions.fetchIntegrationEngineAdminUrlSuccess,
    (state, { integrationEngineAdminUrl }) => ({
      ...state,
      integrationEngineAdminUrl,
    })
  ),
  on(
    integrationActions.fetchIntegrationCopyObjectOptionsSuccess,
    (state, { objectCopyOptions }) => ({ ...state, objectCopyOptions })
  ),
  on(
    integrationActions.storeIntegrationCopyObjectSuccess,
    (state, { storedCopyObjects }) => ({ ...state, storedCopyObjects })
  ),
  on(integrationActions.resetCopyObject, (state) => ({
    ...state,
    objectCopyOptions: null,
    storedCopyObjects: null,
  })),
  on(
    integrationActions.fetchBrandkeeperIsConfiguredSuccess,
    (state, { brandkeeperIsConfigured }) => ({
      ...state,
      brandkeeperIsConfigured,
    })
  ),
  on(
    integrationActions.fetchBrandkeeperUrlSuccess,
    (state, { brandkeeperUrl }) => ({ ...state, brandkeeperUrl })
  ),
  on(
    integrationActions.fetchIntegrationErpSystemLinksUrlSuccess,
    (state, { erpSystemLinks }) => ({ ...state, erpSystemLinks })
  ),
  on(
    integrationActions.fetchExternalObjectMetadataSuccess,
    (state, { externalObjectMetadata }) => ({
      ...state,
      externalObjectMetadata,
    })
  ),
  on(integrationActions.fetchExternalObjectMetadataFailure, (state) => ({
    ...state,
    externalObjectMetadata: null,
  })),
  on(integrationActions.fetchExternalObjectMetadataReset, (state) => ({
    ...state,
    externalObjectMetadata: null,
  })),
  on(
    integrationActions.fetchIntegrationEraIntranetUrlSuccess,
    (state, { eraIntranetUrl }) => ({ ...state, eraIntranetUrl })
  ),
  on(integrationActions.fetchIntegrationEraIntranetUrlFailure, (state) => ({
    ...state,
    eraIntranetUrl: null,
  })),
  on(
    integrationActions.fetchAdfenixPropertyTriggerConfigSuccess,
    (state, { adfenixPropertyTriggerIsConfigured }) => ({
      ...state,
      adfenixPropertyTriggerIsConfigured,
    })
  ),
  on(integrationActions.fetchAdfenixPropertyTriggerConfigFailure, (state) => ({
    ...state,
    isAdfenixPropertyTriggerWorking: false,
  })),
  on(
    integrationActions.fetchAdfenixPropertyTriggersSuccess,
    (state, { triggers }) => ({
      ...state,
      adfenixPropertyTriggers: triggers,
    })
  ),
  on(integrationActions.fetchAdfenixPropertyTriggersFailure, (state) => ({
    ...state,
    adfenixPropertyTriggers: null,
  }))
);

const getIntegration = createFeatureSelector<IntegrationState>("integration");

export const integrationStateLoaded = () =>
  createSelector(
    getIntegration,
    (integration) => integration.hasIntegration !== null
  );
export const hasIntegration = (type: string) =>
  createSelector(getIntegration, (integration) =>
    integration.integratedResources.includes(type)
  );
export const getProvider = (type: string) =>
  createSelector(getIntegration, (integration) => integration.providers[type]);
export const getIsOfficeConfigured = () =>
  createSelector(
    getIntegration,
    (integration) => integration.officeIsConfigured
  );
export const getSupportedLinkTypes = () =>
  createSelector(getIntegration, (integration) =>
    integration.supportedLinkTypes.map((link) => link.linkPurpose)
  );
export const hasSupportedDataResource = (type: string) =>
  createSelector(getIntegration, (integration) =>
    integration.supportedObjectData.includes(type)
  );
export const hasContactSearchCapability = (type: string) =>
  createSelector(getIntegration, (integration) =>
    integration.contactSearchCapabilities.includes(type)
  );

export const getIntegrationEngineAdminUrl = () =>
  createSelector(
    getIntegration,
    (integration) => integration.integrationEngineAdminUrl
  );

export const getIsNewsletterConfigured = () =>
  createSelector(
    getIntegration,
    (integration) => integration.newsletterIsConfigured
  );

export const getIsExternalTipsSupportedConfigured = () =>
  createSelector(
    getIntegration,
    (integration) => integration.externalTipsSupported
  );

export const getObjectCopyOptions = () =>
  createSelector(
    getIntegration,
    (integration) => integration.objectCopyOptions
  );

export const getStoredCopyObjects = () =>
  createSelector(
    getIntegration,
    (integration) => integration.storedCopyObjects
  );
export const getIsBrandkeeperConfigured = () =>
  createSelector(
    getIntegration,
    (integration) => integration.brandkeeperIsConfigured
  );

export const getBrandkeeperUrl = () =>
  createSelector(getIntegration, (integration) => integration.brandkeeperUrl);

export const getIntegrationErpSystemLinks = () =>
  createSelector(getIntegration, (integration) => integration.erpSystemLinks);

export const getExternalObjectMetadata = () =>
  createSelector(
    getIntegration,
    (integration) => integration.externalObjectMetadata
  );

export const getEraIntranetUrl = () =>
  createSelector(getIntegration, (integration) => integration.eraIntranetUrl);

export const getIsAdfenixPropertyTriggerConfigured = () =>
  createSelector(
    getIntegration,
    (integration) => integration.adfenixPropertyTriggerIsConfigured
  );

export const getAdfenixPropertyTriggers = () =>
  createSelector(
    getIntegration,
    (integration) => integration.adfenixPropertyTriggers
  );
