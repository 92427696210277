import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import {
  getAdfenixPropertyTriggers,
  getIsAdfenixPropertyTriggerConfigured,
} from "@app/integrations/ngrx/integrations.reducer";
import { PropertyTrigger } from "@app/integrations/models/propertyTrigger";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";

@Injectable()
export class PropertyTriggerService {
  private _hasIntegration$: Observable<boolean>;
  hasIntegration$: Observable<boolean>;
  propertyTriggers$: Observable<PropertyTrigger[]>;
  isWorking$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store<AppState>, private apiService: ApiService) {
    this.mapStateToProps();
  }

  storePropertyTrigger(params: {
    eaOid: string;
    triggerId: string;
    disableDeduplication: boolean;
  }) {
    this.isWorking$.next(true);

    return this.apiService
      .post(
        "adfenix/property/trigger-property-trigger",
        params,
        Categories.Integrations
      )
      .pipe(
        map(() => {
          this.isWorking$.next(false);
          this.store.dispatch(
            toastActions.success({ message: "property_triggered_successfully" })
          );
          return true;
        }),
        catchError((err) => {
          this.isWorking$.next(false);
          let message = "operation_failed_please_try_again_later";

          if (err.status === 403) {
            message = "you_are_not_authorized_to_perform_this_action";
          }

          this.store.dispatch(
            toastActions.danger({
              message,
            })
          );
          return of(false);
        })
      );
  }

  private mapStateToProps() {
    this._hasIntegration$ = this.store.pipe(
      select(getIsAdfenixPropertyTriggerConfigured())
    );

    this.hasIntegration$ = this._hasIntegration$.pipe(
      switchMap((enabled) => (!!enabled ? this._hasIntegration$ : of(false))),
      distinctUntilChanged(),
      shareReplay(1)
    );

    this.propertyTriggers$ = this.store.pipe(
      select(getAdfenixPropertyTriggers())
    );
  }
}
