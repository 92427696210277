import { StatsLeadSource } from "@app/statistics/boxes/leads/utils";
import {
  StatsLeadEmployee,
  StatsLeadOffice,
  StatsLeadTotal,
} from "@app/statistics/statistics-leads/components/statistics-leads-content/utils";
import { createAction, props } from "@ngrx/store";

export const getResultsForChainRequest = createAction(
  "[Lead Results] GET_RESULTS_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForChainSuccess = createAction(
  "[Lead Results] GET_RESULTS_FOR_CHAIN_SUCCESS",
  props<{
    total: StatsLeadTotal;
    offices: StatsLeadOffice[];
  }>()
);

export const getResultsForChainFail = createAction(
  "[Lead Results] GET_RESULTS_FOR_CHAIN_FAIL"
);

export const getResultsForOfficeRequest = createAction(
  "[Lead Results] GET_RESULTS_FOR_OFFICE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForOfficeSuccess = createAction(
  "[Lead Results] GET_RESULTS_FOR_OFFICE_SUCCESS",
  props<{
    total: StatsLeadTotal;
    employees: StatsLeadEmployee[];
  }>()
);

export const getResultsForOfficeFail = createAction(
  "[Lead Results] GET_RESULTS_FOR_OFFICE_FAIL"
);

export const getResultsForEmployeeRequest = createAction(
  "[Lead Results] GET_RESULTS_FOR_EMPLOYEE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getResultsForEmployeeSuccess = createAction(
  "[Lead Results] GET_RESULTS_FOR_EMPLOYEE_SUCCESS",
  props<{
    results: StatsLeadEmployee;
  }>()
);

export const getResultsForEmployeeFail = createAction(
  "[Lead Results] GET_RESULTS_FOR_EMPLOYEE_FAIL"
);

export const getSourcesRequest = createAction(
  "[Lead Sources] GET_SOURCES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSourcesSuccess = createAction(
  "[Lead Sources] GET_SOURCES_SUCCESS",
  props<{
    total: StatsLeadTotal;
    sources: StatsLeadSource[];
  }>()
);

export const getSourcesFail = createAction("[Lead Sources] GET_SOURCES_FAIL");
