import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AddContactToCallingListAlternativeComponent } from "@app/shared/modules/add-contact-to-calling-list/add-contact-to-calling-list-alternative.component";
import { AddContactToCallingListComponent } from "@app/shared/modules/add-contact-to-calling-list/add-contact-to-calling-list.component";
import { AddContactToCallingListEffects } from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.effects";
import { contactSummaryReducer } from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { ClickOutsideModule } from "ng-click-outside";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UiComponentsModule } from "../ui-components/ui-components.module";

const components = [
  AddContactToCallingListComponent,
  AddContactToCallingListAlternativeComponent,
];

@NgModule({
  imports: [
    StoreModule.forFeature(
      "add-contact-to-calling-list",
      contactSummaryReducer
    ),
    EffectsModule.forFeature([AddContactToCallingListEffects]),
    CommonModule,
    TranslateModule,
    ModalModule,
    TooltipModule,
    SkeletonScreenModule,
    RouterModule,
    UiComponentsModule,
    ClickOutsideModule,
  ],
  declarations: components,
  exports: components,
})
export class AddContactToCallingListModule {}
