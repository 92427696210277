import { Task, TypedPaginationListDTO } from "@app/models";
import { API_LIMIT_MAX } from "@app/shared/utils/api-defaults";
import { createAction, props } from "@ngrx/store";

export const getNextStep = (
  contactId: string,
  limit?: number,
  offset?: number
) => {
  const params = {
    limit: limit ? limit : API_LIMIT_MAX,
    offset: offset ? offset : 0,
    contactId,
    taskTypeCategory: "lead,task",
    sortBy: "deliveryDate",
    sortOrder: "desc",
  };

  return getNextStepRequest({ params });
};

export const getNextStepRequest = createAction(
  "[Contact Overview] GET_NEXT_STEP_REQUEST",
  props<{
    params: {
      contactId: string;
      limit: number;
      offset: number;
      taskTypeCategory: string;
      sortBy: string;
      sortOrder: string;
    };
  }>()
);

export const getNextStepSuccess = createAction(
  "[Contact Overview] GET_NEXT_STEP_SUCCESS",
  props<{ nextSteps: TypedPaginationListDTO<Task> }>()
);

export const getNextStepFail = createAction(
  "[Contact Overview] GET_NEXT_STEP_FAIL"
);

export const getMoreNextSteps = (
  contactId: string,
  limit?: number,
  offset?: number
) => {
  const params = {
    limit: limit ? limit : API_LIMIT_MAX,
    offset: offset ? offset : 0,
    contactId,
    taskTypeCategory: "lead,task",
    sortBy: "deliveryDate",
    sortOrder: "desc",
  };
  return getMoreNextStepsRequest({ params });
};

export const getMoreNextStepsRequest = createAction(
  "[Contact Overview] GET_MORE_NEXT_STEPS_REQUEST",
  props<{
    params: {
      contactId: string;
      limit: number;
      offset: number;
      taskTypeCategory: string;
      sortBy: string;
      sortOrder: string;
    };
  }>()
);

export const getMoreNextStepsSuccess = createAction(
  "[Contact Overview] GET_MORE_NEXT_STEPS_SUCCESS",
  props<{ nextSteps: TypedPaginationListDTO<Task> }>()
);

export const getMoreNextStepsFail = createAction(
  "[Contact Overview] GET_MORE_NEXT_STEPS_FAIL"
);
