import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component } from "@angular/core";
import { ENTERING_DURATION, EXITING_DURATION } from "../../../animations";

@Component({
  selector: "standalone-sidebar",
  template: `
    <div class="sidebar flex-column" @sidebarAnimationStates>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./standalone-sidebar.component.scss"],
  animations: [
    trigger("sidebarAnimationStates", [
      state("void", style({ "margin-right": "-26rem" })),
      state("*", style({ "margin-right": "0" })),
      transition(":enter", animate(`${ENTERING_DURATION} ease-in`)),
      transition(":leave", animate(`${EXITING_DURATION} ease-out`)),
    ]),
  ],
})
export class StandaloneSidebarComponent {}
