import { TaskList } from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import { createAction, props } from "@ngrx/store";
import moment from "moment";

export const getFollowUpSalesMeetings = (
  eaEmployeeId: string,
  eaOfficeId: string,
  taskTypeId: number,
  all: boolean,
  offset: number,
  limit?: number,
  sortBy?: string,
  sortOrder?: string
) => {
  const params: Partial<TaskList> = {
    taskTypeId: taskTypeId,
    taskTypeCategory: "task",
    resolved: false,
    sortBy: sortBy ? sortBy : "deliveryDate",
    sortOrder: sortOrder ? sortOrder : "asc",
    limit: limit ? limit : 10,
    offset: offset,
    deliveryTo: moment().add("3", "months").format("YYYYMMDDHHmmss"),
  };

  if (all) {
    params.eaOfficeId = eaOfficeId;
  } else {
    params.eaEmployeeId = eaEmployeeId;
  }

  return getFollowUpSalesMeetingsRequest({ params });
};

export const getFollowUpSalesMeetingsRequest = createAction(
  "[FollowUpSalesMeetingsList] GET_FOLLOW_UP_SALES_MEETINGS_REQUEST",
  props<{ params: Partial<TaskList> }>()
);

export const getFollowUpSalesMeetingsSuccess = createAction(
  "[FollowUpSalesMeetingsList] GET_FOLLOW_UP_SALES_MEETINGS_SUCCESS",
  props<{
    tasks: Task[];
    listStart: number;
    totalRows: number;
  }>()
);

export const getFollowUpSalesMeetingsFailed = createAction(
  "[FollowUpSalesMeetingsList] GET_FOLLOW_UP_SALES_MEETINGS_FAIL"
);

export const getFollowUpSalesMeetingsNoTasksFound = createAction(
  "[FollowUpSalesMeetingsList] GET_FOLLOW_UP_SALES_MEETINGS_NO_TASKS_FOUND"
);

export const clearState = createAction(
  "[FollowUpSalesMeetingsList] CLEAR_STATE"
);
