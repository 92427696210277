import { Component, EventEmitter, Input, Output } from "@angular/core";
import { PotentialBuyer } from "@app/showings/models";

@Component({
  selector: "app-object-party-item",
  templateUrl: "./object-party-item.component.html",
  styleUrls: ["./object-party-item.component.scss"],
})
export class ObjectPartyItemComponent {
  @Output() handleCheckboxChanged: EventEmitter<Partial<PotentialBuyer>> =
    new EventEmitter<Partial<PotentialBuyer>>();
  @Output() handleContactClick: EventEmitter<{
    contact: Partial<PotentialBuyer>;
    event: any;
  }> = new EventEmitter<{ contact: Partial<PotentialBuyer>; event: any }>();
  @Output() openInNewTab: EventEmitter<{
    contactId: string;
    event: any;
  }> = new EventEmitter<{
    contactId: string;
    event: any;
  }>();
  @Output() sendMessageClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() selectedContactIds: string[] = [];
  @Input() contact: Partial<PotentialBuyer>;
  @Input() disableActions = false;
}
