import {
  ExternalPresentableLink,
  PresentableLinks,
} from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { ContactStatus, PotentialBuyer } from "@app/showings/models";
import { createAction, props } from "@ngrx/store";

export const fetchShowingObjectContactSummaryRequest = createAction(
  "[POTENTIAL_BUYERS] FETCH_SHOWING_OBJECT_CONTACT_SUMMARY_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchShowingObjectContactSummarySuccess = createAction(
  "[POTENTIAL_BUYERS] FETCH_SHOWING_OBJECT_CONTACT_SUMMARY_SUCCESS",
  props<{ contactStatus: ContactStatus }>()
);

export const fetchShowingObjectContactSummaryFailure = createAction(
  "[POTENTIAL_BUYERS] FETCH_SHOWING_OBJECT_CONTACT_SUMMARY_FAILURE"
);

export const refreshShowingObjectContactSummary = createAction(
  "[POTENTIAL_BUYERS] REFRESH_SHOWING_OBJECT_CONTACT_SUMMARY"
);

export const refreshPotentialBuyersSuccess = createAction(
  "[POTENTIAL_BUYERS] REFRESH_POTENTIAL_BUYERS_SUCCESS"
);

export const getObjectExternalProviderLinksRequest = createAction(
  "[POTENTIAL_BUYERS] GET_OBJECT_EXTERNAL_PROVIDER_LINKS_REQUEST",
  props<{ eaOid: string }>()
);

export const getObjectExternalProviderLinksSuccess = createAction(
  "[POTENTIAL_BUYERS] GET_OBJECT_EXTERNAL_PROVIDER_LINKS_SUCCESS",
  props<{
    externalLinks: any[];
    presentableLinks: PresentableLinks;
    presentableExternalLinkForBidding: ExternalPresentableLink;
    presentableExternalLinkForBiddingSettings: ExternalPresentableLink;
    hiddenLinks: any[];
    erpMenuLinks: any[];
    provider: string;
  }>()
);

export const getObjectExternalProviderLinksFailed = createAction(
  "[POTENTIAL_BUYERS] GET_OBJECT_EXTERNAL_PROVIDER_LINKS_FAILED"
);

export const removeBidRequest = createAction(
  "[POTENTIAL_BUYERS] REMOVE_POTENTIAL_BUYER_BID_REQUEST",
  props<{ id: string }>()
);

export const removeBidSuccess = createAction(
  "[POTENTIAL_BUYERS] REMOVE_POTENTIAL_BUYER_BID_SUCCESS"
);

export const removeBidFailure = createAction(
  "[POTENTIAL_BUYERS] REMOVE_POTENTIAL_BUYER_BID_FAILURE"
);

export const setSelectedPotentialBuyerId = createAction(
  "[POTENTIAL_BUYERS] SET_SELECTED_POTENTIAL_BUYER_ID",
  props<{ potentialBuyerId: string }>()
);

export const setSelectedPotentialBuyers = createAction(
  "[POTENTIAL_BUYERS] SET_SELECTED_POTENTIAL_BUYERS",
  props<{ potentialBuyers: PotentialBuyer[] }>()
);

export const setSelectedPotentialBuyersSuccess = createAction(
  "[POTENTIAL_BUYERS] SET_SELECTED_POTENTIAL_BUYERS_SUCCESS"
);

export const reset = createAction("[POTENTIAL_BUYERS] RESET");
