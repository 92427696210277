import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Contact, QObject } from "@app/models";
import { BankAdvisor } from "@app/settings/banks/manage-banks/models/bank-advisor";
import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import { getCountry } from "@app/shared/config/config.reducer";
import * as fromUser from "@app/shared/user";
import { CONTACTS_COLOR, SHOWINGS_COLOR } from "@app/shared/utils/colors";
import * as formUtils from "@app/shared/utils/form-utils";
import { EIKA_SEND_TIPS } from "@app/shared/utils/tab-types";
import { validatePhoneNumber } from "@app/shared/validators/phone-validator";
import { SendTipToEikaFormComponent } from "@app/sidebar/eika/components/send-tip-to-eika-form/send-tip-to-eika-form.component";
import * as eikaActions from "@app/sidebar/eika/ngrx/eika.actions";
import * as fromEika from "@app/sidebar/eika/ngrx/eika.reducer";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import * as libphonenumber from "google-libphonenumber";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-eika",
  templateUrl: "./eika.component.html",
  styleUrls: [
    "./eika.component.scss",
    "../../../sidebar.component.common.scss",
  ],
})
export class EikaComponent implements OnInit, OnDestroy, ConnectableTab {
  @ViewChild(SendTipToEikaFormComponent, { static: false })
  eikaFormComponent: SendTipToEikaFormComponent;

  unsubscribe$ = new Subject<void>();
  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;

  contactId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  eaOid$: Observable<string>;
  banks$: Observable<ExternalBank[]>;
  advisors$: Observable<BankAdvisor[]>;
  contact$: Observable<Contact>;
  object$: Observable<QObject>;
  banksLoading$: Observable<boolean>;
  advisorsLoading$: Observable<boolean>;
  contactLoading$: Observable<boolean>;
  objectLoading$: Observable<boolean>;
  sendingLoading$: Observable<boolean>;
  countryCode$: Observable<string>;

  contactsColor = CONTACTS_COLOR;
  showingsColor = SHOWINGS_COLOR;
  tabType = EIKA_SEND_TIPS;

  selectedEaOid: string;
  form: FormGroup;
  dataSource: any[];

  constructor(
    private route: ActivatedRoute,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    // TODO: Was not able to trigger onDestroy for some reason, that's why this is here
    this.store.dispatch(eikaActions.clear());

    this.mapStateToProps();
    this.buildForm();
    this.connectTab();
    this.getBanks();
    this.getContact();
    this.getObject();
    this.getOfficeObjectsForSale();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  submit(): void {
    this.eikaFormComponent.handleSubmit();
    // Add eikaForm as viewChild
  }

  sendTip(): void {
    this.contactId$.pipe(first()).subscribe((contactId) => {
      const params = {
        ids: {
          contactId,
          eaOid: this.selectedEaOid,
          bank: this.eikaFormComponent.form.get("bank").value,
          advisor: this.eikaFormComponent.form.get("advisor").value,
        },
        body: {
          note: this.eikaFormComponent.form.get("note").value,
          urgent: this.eikaFormComponent.form.get("urgent").value,
          origin: "crm",
        },
      };
      this.store.dispatch(eikaActions.sendTipRequest({ params }));
    });
  }

  handleSelect(event: any) {
    this.selectedEaOid = event.item.eaOid;
    this.store.dispatch(eikaActions.getObjectSuccess({ qObject: event.item }));
  }

  onUpdateRecipientMobileNumber(contact: Contact, msisdn: string): void {
    if (this.form.valid) {
      this.store.dispatch(
        eikaActions.updateContactRequest({
          contactId: contact.contactId,
          params: { msisdn },
        })
      );
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([localFirst, current]) => {
        if (_.isEqual(localFirst, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  getAdvisors(bankId: string) {
    this.store.dispatch(eikaActions.getAdvisorsRequest({ bankId }));
  }

  private mapStateToProps() {
    this.contactId$ = this.route.params.pipe(
      filter((params) => params["contactId"]),
      map((params) => params["contactId"])
    );
    this.eaOid$ = this.route.params.pipe(
      filter((params) => params["eaOid"]),
      map((params) => params["eaOid"])
    );
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.banks$ = this.store.pipe(select(fromEika.getBanks));
    this.advisors$ = this.store
      .pipe(select(fromEika.getAdvisors))
      .pipe(map((advs) => _.sortBy(advs, ["firstName", "lastName"])));
    this.contact$ = this.store.pipe(select(fromEika.getContact));
    this.object$ = this.store.pipe(select(fromEika.getObject));
    this.banksLoading$ = this.store.pipe(select(fromEika.getBanksLoading));
    this.advisorsLoading$ = this.store.pipe(
      select(fromEika.getAdvisorsLoading)
    );
    this.contactLoading$ = this.store.pipe(select(fromEika.getContactLoading));
    this.objectLoading$ = this.store.pipe(select(fromEika.getObjectLoading));
    this.sendingLoading$ = this.store.pipe(select(fromEika.getSendingLoading));
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.countryCode$ = this.store.pipe(select(getCountry));
    this.store
      .pipe(
        select(fromEika.getOfficeObjectsForSale),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((objects: QObject[]) => (this.dataSource = objects));
  }

  private getContact() {
    this.contactId$
      .pipe(
        first(),
        filter((value) => !!value)
      )
      .subscribe((contactId) =>
        this.store.dispatch(eikaActions.getContactRequest({ contactId }))
      );
  }

  private getObject() {
    this.eaOid$
      .pipe(
        first(),
        filter((value) => !!value)
      )
      .subscribe((eaOid) => {
        this.selectedEaOid = eaOid;
        this.store.dispatch(eikaActions.getObjectRequest({ eaOid }));
      });
  }

  private getBanks() {
    this.store.dispatch(eikaActions.getBanksRequest());
  }

  private getOfficeObjectsForSale() {
    this.eaOfficeId$.subscribe((eaOfficeId) =>
      this.store.dispatch(
        eikaActions.getOfficeObjectsForSaleRequest({ eaOfficeId })
      )
    );
  }

  private buildForm() {
    this.countryCode$.pipe(first()).subscribe((countryCode) => {
      this.form = this.fb.group({
        eaOid: "",
        msisdn: [
          "",
          [
            validatePhoneNumber(countryCode, {
              type: libphonenumber.PhoneNumberType.MOBILE,
            }),
          ],
        ],
      });
    });
  }
}
