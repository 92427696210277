import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-object-notes-widget",
  templateUrl: "./object-notes-widget.component.html",
  styleUrls: ["./object-notes-widget.component.scss"],
})
export class ObjectNotesWidgetComponent implements OnChanges {
  @Input() eaOid: string;

  isWritingNote = false;

  ngOnChanges(changes) {
    if (changes.eaOid && !!this.eaOid) {
      this.isWritingNote = false;
    }
  }
}
