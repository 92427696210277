import { Component, Input } from "@angular/core";
import { ListViewsService } from "@app/core/services/list-views/list-views.service";

@Component({
  selector: "app-list-view-controls",
  templateUrl: "./list-view-controls.component.html",
  styleUrls: ["./list-view-controls.component.scss"],
})
export class ListViewControlsComponent {
  @Input() viewService: ListViewsService;
}
