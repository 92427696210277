import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Employee } from "@app/models";
import { ContactSummaryDialogComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-dialog/contact-summary-dialog.component";
import * as ContactSummaryActions from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.actions";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromUser from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { filter, Observable } from "rxjs";

@Component({
  selector: "contact-summary",
  templateUrl: "./contact-summary.component.html",
  styleUrls: ["./contact-summary.component.scss"],
})
export class ContactSummaryComponent implements OnInit {
  @Input() contactId: string;
  @Input() tooltipText: string = "flash_info";
  employee$: Observable<Employee>;
  eaEmployeeId: string;

  ngOnInit() {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.employee$
      .pipe(filter((value) => !!value))
      .subscribe((employee: Employee) => {
        this.eaEmployeeId = employee.eaEmployeeId;
      });
  }

  openModal(event: Event) {
    event.stopPropagation();
    this.store.dispatch(
      ContactSummaryActions.fetchContactSummaryRequest({
        contactId: this.contactId,
        eaEmployeeId: this.eaEmployeeId,
      })
    );
    this.modalService.show(ContactSummaryDialogComponent, {
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }

  constructor(
    private store: Store<AppState>,
    private modalService: QModalService
  ) {}
}
