import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  UrlSegment,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  filter,
  first,
  map,
  Observable,
  of as observableOf,
  switchMap,
} from "rxjs";
import { AppState } from "../app.reducer";
import * as confirmActions from "../core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "../core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as RouterActions from "../core/ngrx/router/router.actions";
import {
  getSidebarTabByTabType,
  getSidebarTabUrlByTabType,
} from "../shared/utils/sidebar-tab-utils";
import { CrmModule } from "./models/crm-module";
import { SidebarTab } from "./models/sidebar-tab";
import * as sidebarActions from "./ngrx/sidebar.actions";
import { getTab, getTabs, isDirty } from "./ngrx/sidebar.reducer";

@Injectable()
export class SidebarService {
  constructor(private store: Store<AppState>, private router: Router) {}

  static isNextRouteAnActivatableRoute(module: string, url: string): boolean {
    const urlSegments: string[] = url.split(/[\/(]+/);
    return !["crm", module].some(
      (segment: string, i: number) => segment !== urlSegments[i + 1]
    );
  }

  canActivateTab(
    route: ActivatedRouteSnapshot,
    url: string,
    crmModule: CrmModule,
    tabType: string
  ): Observable<boolean> {
    return this.store.pipe(
      select(getTabs),
      first(),
      switchMap((tabs: SidebarTab[]) => {
        const canActivate = crmModule && crmModule.isModuleActive(url);
        return canActivate
          ? this.canActivate(route, tabs, tabType)
          : this.canNotActivate();
      })
    );
  }

  handleTabNavigation(
    tabType: string,
    canActivate: boolean,
    route: ActivatedRouteSnapshot
  ): Observable<boolean> {
    return this.store.pipe(
      select(getTabs),
      first(),
      switchMap((tabs: SidebarTab[]) => {
        return canActivate
          ? this.canActivate(route, tabs, tabType)
          : this.canNotActivate();
      })
    );
  }

  openMultipleTabs(tabTypes: string[], options: any = {}): void {
    const { primaryOutlet, routeParams } = options;
    const outlets = {};

    if (primaryOutlet) {
      outlets["primary"] = primaryOutlet;
    }
    outlets["sidebar"] = getSidebarTabUrlByTabType(tabTypes[0], routeParams);

    this.canCloseSidebar()
      .pipe(
        first(),
        filter((value) => !!value),
        switchMap(() => this.store.pipe(select(getTab(tabTypes[0])), first()))
      )
      .subscribe((firstTab) => {
        if (firstTab && firstTab.dirty) {
          this.store.dispatch(
            sidebarActions.resetTab({ tabType: firstTab.type })
          );
        }
        this.store.dispatch(RouterActions.go({ path: ["/crm", { outlets }] }));
        this.openTabsAfterNavigation(
          tabTypes.slice(1, tabTypes.length),
          routeParams
        );
      });
  }

  canCloseSidebar(): Observable<boolean> {
    return this.store.pipe(
      select(isDirty),
      first(),
      switchMap((dirty) => {
        if (dirty) {
          this.store.dispatch(sidebarActions.show());
          this.store.dispatch(
            confirmActions.show({
              header: "confirm",
              message: "unsaved_changes_text",
            })
          );
          return this.store.pipe(
            select(confirmState),
            filter(Boolean),
            first()
          );
        } else {
          return observableOf(true);
        }
      })
    );
  }

  canCloseTab(tabType: string): Observable<boolean> {
    return this.store.pipe(
      select(getTab(tabType)),
      first(),
      switchMap((tab) => {
        if (!!tab && tab.dirty) {
          this.store.dispatch(
            confirmActions.show({
              header: "confirm",
              message: "unsaved_changes_text",
            })
          );
          return this.store.pipe(
            select(confirmState),
            filter(Boolean),
            first()
          );
        }
        return observableOf(true);
      })
    );
  }

  private canActivate(
    route: ActivatedRouteSnapshot,
    tabs: SidebarTab[],
    tabType: string
  ): Observable<boolean> {
    const urlSegments: string[] = this.getSidebarUrlSegment(route).map(
      (segment) => segment.path
    );
    const existingTab: SidebarTab = tabs.find((tab) => tab.type === tabType);
    const tab = new SidebarTab(tabType, urlSegments);

    this.store.dispatch(sidebarActions.show());
    return existingTab ? this.replaceTab(existingTab, tab) : this.addTab(tab);
  }

  private canNotActivate(): Observable<boolean> {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { primary: ["page-not-found"], sidebar: null } },
        ],
      })
    );
    return observableOf(false);
  }

  private addTab(tab: SidebarTab): Observable<boolean> {
    this.store.dispatch(sidebarActions.addTab({ tab }));
    return observableOf(true);
  }

  private replaceTab(existingTab: SidebarTab, tab: SidebarTab) {
    const existingUrlEqualsRequestedUrl = _.isEqual(tab.url, existingTab.url);
    if (existingTab.dirty && !existingUrlEqualsRequestedUrl) {
      this.store.dispatch(
        confirmActions.show({
          header: "confirm",
          message: "unsaved_changes_text",
        })
      );
      return this.store.pipe(
        select(confirmState),
        filter(Boolean),
        first(),
        map((confirm) => {
          if (confirm) {
            this.store.dispatch(
              sidebarActions.replaceTab({
                tabType: existingTab.type,
                newTab: tab,
              })
            );
          }
          return confirm;
        })
      );
    } else if (!existingUrlEqualsRequestedUrl) {
      this.store.dispatch(
        sidebarActions.replaceTab({ tabType: existingTab.type, newTab: tab })
      );
    }

    return observableOf(true);
  }

  private getSidebarUrlSegment(route: ActivatedRouteSnapshot): UrlSegment[] {
    if (route.parent && route.outlet === "sidebar") {
      return route.url;
    } else {
      const segments = this.getSidebarUrlSegment(route.parent);
      return segments.concat(route.url);
    }
  }

  private openTabsAfterNavigation(
    tabTypes: string[],
    routeParams: any = {}
  ): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        switchMap(() => this.store.pipe(select(getTabs), first())),
        first()
      )
      .subscribe((tabs) => {
        tabTypes.forEach((tabType) => {
          const oldTab = tabs.some((tab) => tab.type === tabType);
          const tab = getSidebarTabByTabType(tabType, routeParams);
          if (oldTab) {
            this.store.dispatch(
              sidebarActions.replaceTab({ tabType: tab.type, newTab: tab })
            );
          } else {
            this.store.dispatch(sidebarActions.addTab({ tab }));
          }
        });
      });
  }
}
