import { Injectable } from "@angular/core";
import * as routerActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import * as toastActions from "../../core/components/toast/ngrx/toast.actions";
import * as errorActions from "../../core/error-handling/ngrx/error.actions";
import * as configActions from "./config.actions";
import { SettingGroup } from "@app/shared/config/models/setting";

@Injectable()
export class ConfigEffects {
  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.loadRequest),
      switchMap(() =>
        this.apiService.get("settings", {}, "public").pipe(
          map((response: SettingGroup) => {
            try {
              return JSON.parse(response.settings[0].settingValue);
            } catch (e) {
              console.error(e);
              return {
                settingGroupId: "0",
                settingGroupName: "crm_settings",
                settingGroupDescription: "crm_settings",
                settings: [
                  {
                    settingValueId: "0",
                    settingName: "crm_settings",
                    settingDescription: "",
                    settingValue: '{"general": {"customer_name": "realforce"}}',
                    settingId: "0",
                  },
                ],
              };
              // throw new Error(e);
            }
          }),
          map((conf) => configActions.loadSuccess({ conf })),
          catchError((error) => observableOf(configActions.loadFail({ error })))
        )
      )
    )
  );

  loadConfigSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.loadSuccess),
      map(({ conf }) => {
        const customer_name = conf.general["customer_name"] || "unknown";
        const name = customer_name.toLowerCase().trim().replace(/\s+/g, "-");
        return errorActions.setTagsContext({ tags: { customer: name } });
      })
    )
  );

  loadConfigFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.loadFail),
      switchMap(({ error }) => {
        try {
          const errorMessage = JSON.parse(error._body.toString()).message;
          if (errorMessage === "API_ERROR_INVALID_ORIGIN") {
            return observableOf(routerActions.go({ path: "unknown-origin" }));
          }
        } catch {
          return observableOf(
            routerActions.go({ path: "system-temporarily-down" })
          );
        }
      })
    )
  );

  checkIfSystemOnline$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.checkIfSystemOnlineRequest),
      switchMap(() =>
        this.apiService.get("settings", {}, "public").pipe(
          map(() => configActions.checkIfSystemOnlineSuccess()),
          catchError((error) =>
            observableOf(configActions.checkIfSystemOnlineFail({ error }))
          )
        )
      )
    )
  );

  checkIfSystemOnlineFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.checkIfSystemOnlineFail),
      switchMap(({ error }) => {
        try {
          const errorMessage = JSON.parse(error._body.toString()).message;
          if (errorMessage === "API_ERROR_INVALID_ORIGIN") {
            return observableOf(routerActions.go({ path: "unknown-origin" }));
          }
        } catch {
          return observableOf(
            routerActions.go({ path: "system-temporarily-down" })
          );
        }
      })
    )
  );

  loadConfigIdsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.loadConfigIdsRequest),
      switchMap(({ filterSettingsGroupName }) =>
        this.apiService
          .get("settings", { filterSettingsGroupName }, "api", false)
          .pipe(
            map((response: Record<string, unknown>) =>
              configActions.loadConfigIdsSuccess({
                payload: response.settings[0],
              })
            ),
            catchError(() => observableOf(configActions.loadConfigIdsFail()))
          )
      )
    )
  );

  saveRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.saveRequest),
      switchMap(({ params }) =>
        this.apiService
          .patch(
            `settings/${params.settingGroupId}/${params.settingId}`,
            {
              settingValue: params.settingValue,
            },
            "api"
          )
          .pipe(
            map(() =>
              configActions.saveSuccess({
                appConfig: JSON.parse(params.settingValue.toString()),
              })
            ),
            catchError(() => observableOf(configActions.saveFail()))
          )
      )
    )
  );

  saveSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.saveSuccess),
      switchMap(() => this.translateService.get("settings_saved")),
      map((message) => {
        location.reload();
        return toastActions.success({ message });
      })
    )
  );

  saveFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(configActions.saveFail),
      switchMap(() => this.translateService.get("settings_save_failed")),
      map((message) => toastActions.danger({ message }))
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}
}
