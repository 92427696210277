import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { Observable } from "rxjs";

import { AppState } from "@app/app.reducer";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";

@Component({
  selector: "app-q-action-dropdown",
  templateUrl: "./q-action-dropdown.component.html",
  styleUrls: ["./q-action-dropdown.component.scss"],
})
export class QActionDropdownComponent implements OnInit {
  @Input() parentRef: HTMLDivElement;
  @Input() onHover = false;

  @ViewChild("element", { static: false }) elementRef: ElementRef;

  showDropdown = false;
  placementTop = false;

  isMobile$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private bottomSheet: MatBottomSheet,
    private changeDetectorRef: ChangeDetectorRef,
    private window: Window
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(isMobile));
  }

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;

    if (this.showDropdown) {
      this.changeDetectorRef.detectChanges();
      if (!this.verticallyBound()) {
        this.placementTop = true;
      }
    }
  }

  closeDropdown() {
    this.showDropdown = false;
  }

  openBottomSheet(sheet: TemplateRef<any>) {
    this.bottomSheet.open(sheet, {
      panelClass: ["sheet-container", "full-bleed-sheet"],
    });
  }

  verticallyBound() {
    const parentRect = this.parentRef?.getBoundingClientRect();
    const childRect = this.elementRef?.nativeElement?.getBoundingClientRect();

    return (
      parentRect?.bottom >= childRect?.bottom &&
      parentRect?.top <= childRect?.top &&
      childRect?.top >= 0 &&
      childRect?.bottom <= this.window.innerHeight
    );
  }
}
