<h3>
  <app-icon size="large" name="home"></app-icon>&nbsp;<span translate
    >latest_objects_for_sale</span
  >
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div
    class="object-card"
    *ngFor="let object of objects$ | async"
    [style.background-image]="
      object?.images[0] ? 'url(' + object?.images[0]?.url + '450' + ')' : ''
    "
    (click)="handleObjectClick()"
    [routerLink]="[
      '/crm',
      'showings',
      object.eaOid,
      navigationEndpoint$ | async
    ]"
  >
    <div class="box-shadow"></div>
    <div class="card-content">
      <h4 class="title">{{ object?.street }}</h4>
      <div class="metadata">
        <p *ngIf="!!object?.sellers?.length > 0">
          {{ "sellers" | translate }}:&nbsp;<span>
            <app-contact-name
              [contactId]="object.sellers[0].contactId"
              [contactType]="object.sellers[0].contactType"
            >
              {{ object.sellers[0].firstName }}
              {{ object.sellers[0].familyName }}
            </app-contact-name>
          </span>
          <span
            *ngIf="object.sellers?.length > 1"
            [tooltipHtml]="sellersTooltip"
          >
            (+{{ object.sellers?.length - 1 }})
          </span>
        </p>
        <ng-template #sellersTooltip>
          <ul class="seller-list">
            <li *ngFor="let seller of object?.sellers">
              <ng-container *ngIf="!!seller.firstName || !!seller.familyName">
                <span class="seller-list--highlight"
                  >{{ seller.firstName }} {{ seller.familyName }}</span
                >
              </ng-container>
            </li>
          </ul>
        </ng-template>
      </div>
    </div>
    <div class="view-more-icon">
      <app-icon name="arrow-right" size="large"></app-icon>
    </div>
    <div class="status" [class]="getObjectStatusColor(object.status)">
      <mat-chip-listbox>
        <mat-chip>{{ object.statusName }}</mat-chip>
      </mat-chip-listbox>
    </div>
  </div>
</div>
