import { SalesMeetingStatisticsTotalResponse } from "@app/statistics/boxes/sales-meetings/utils";
import { createAction, props } from "@ngrx/store";
import {
  StatsLeadEmployee,
  StatsLeadTotal,
} from "@app/statistics/boxes/leads/utils";
import { SalesMeetingBookedResponse } from "@app/statistics/statistics-start/ngrx/statistics-start.reducer";

export const getLeadResultsForChainRequest = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getLeadResultsForChainSuccess = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_CHAIN_SUCCESS",
  props<{ leadTotal: StatsLeadTotal }>()
);

export const getLeadResultsForChainFail = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_CHAIN_FAIL"
);

export const getLeadResultsForEmployeeRequest = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_EMPLOYEE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getLeadResultsForEmployeeSuccess = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_EMPLOYEE_SUCCESS",
  props<{ leadEmployee: StatsLeadEmployee }>()
);

export const getLeadResultsForEmployeeFail = createAction(
  "[Statistics Start Page] GET_LEAD_RESULTS_FOR_EMPLOYEE_FAIL"
);

export const getSalesMeetingResultsForChainRequest = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSalesMeetingResultsForChainSuccess = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_CHAIN_SUCCESS",
  props<{ salesMeetingTotal: SalesMeetingStatisticsTotalResponse }>()
);

export const getSalesMeetingResultsForChainFail = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_CHAIN_FAIL"
);

export const getSalesMeetingResultsForEmployeeRequest = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_EMPLOYEE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSalesMeetingResultsForEmployeeSuccess = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_EMPLOYEE_SUCCESS",
  props<{ salesMeetingTotal: SalesMeetingStatisticsTotalResponse }>()
);

export const getSalesMeetingResultsForEmployeeFail = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_RESULTS_FOR_EMPLOYEE_FAIL"
);

export const getSalesMeetingBookedRequest = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_BOOKED_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSalesMeetingBookedSuccess = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_BOOKED_SUCCESS",
  props<{ salesMeetingBooked: SalesMeetingBookedResponse }>()
);

export const getSalesMeetingBookedFail = createAction(
  "[Statistics Start Page] GET_SALES_MEETING_BOOKED_FAIL"
);

export const setTimer = createAction(
  "[Statistics Start Page] SET_TIMER",
  props<{ enabled: boolean }>()
);
