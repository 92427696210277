import { Route } from "@angular/router";
import { CompetitionCreateGuard } from "../guards/competition-create-guard.service";
import { CompetitionEditGuard } from "../guards/competition-edit-guard.service";
import { CreateCompetitionComponent } from "./create-competition/create-competition.component";
import { EditCompetitionComponent } from "./edit-competition/edit-competition.component";

export const sidebarCompetitionsRoutes: Route = {
  path: "competitions",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: CreateCompetitionComponent,
      canActivate: [CompetitionCreateGuard],
    },
    {
      path: ":id/edit",
      component: EditCompetitionComponent,
      canActivate: [CompetitionEditGuard],
    },
  ],
};
