import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppState } from "@app/app.reducer";
import {
  SearchProfile,
  TenureTypes,
} from "@app/contacts/contact-search-profile/models/search-profile";
import { Office } from "@app/models";
import { CategoryType } from "@app/models/category-type";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/features";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import * as formUtils from "@app/shared/utils/form-utils";
import { ObjectType } from "@app/shared/utils/q-object-types";
import {
  MATCH_WHITE_SPACE_GLOBAL,
  ONLY_DIGITS,
  ONLY_DIGITS_ONE_DECIMAL,
  ONLY_DIGITS_TWO_DECIMAL,
  ONLY_DIGITS_WHITESPACE,
} from "@app/shared/utils/regex-patterns";
import { FormattedSearchArea } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import * as areaTypes from "@app/sidebar/search-profile/utils/area-types";
import { select, Store } from "@ngrx/store";
import { BsModalRef } from "ngx-bootstrap/modal";
import {
  map,
  Observable,
  PartialObserver,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-search-profile-form",
  templateUrl: "./search-profile-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./search-profile-form.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchProfileFormComponent
  implements OnChanges, OnInit, OnDestroy
{
  @ViewChild("extraFilters", { static: false })
  extraFilters: ElementRef;

  @Input() editMode = false;
  @Input() searchProfile: SearchProfile;
  @Input() selectedAreas: FormattedSearchArea[] = [];
  @Input() observer: PartialObserver<any>;
  @Input() additionalFilters: CategoryType[];
  @Input() currency: String;
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<SearchProfile> =
    new EventEmitter<SearchProfile>();

  municipalityId: any;
  countyId: any;
  formConfig$: Observable<SearchProfileFeature>;
  unsubscribe$: Subject<void> = new Subject<void>();
  searchProfileForm: FormGroup;
  bsModalRef: BsModalRef;
  objectTypes$: Observable<ObjectType[]>;
  objectTypes: ObjectType[];
  tenureTypeForSale = TenureTypes.ForSale;
  tenureTypeForRent = TenureTypes.ForRent;

  lotStatusSelect: Select = [
    {
      value: "",
      label: "all",
    },
    {
      value: "Rent",
      label: "rent",
    },
    {
      value: "Own",
      label: "ownership",
    },
    {
      value: "optionalRental",
      label: "optionalrental",
    },
  ];

  newProductionSelect: Select = [
    {
      value: "",
      label: "include_newproduction",
    },
    {
      value: "True",
      label: "show_only_newproduction",
    },
    {
      value: "False",
      label: "hide_newproduction",
    },
  ];

  constructor(
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private store: Store<AppState>
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.registerObserverToFormValueChanges();
    this.mapStateToProps();
  }

  mapStateToProps() {
    this.store.pipe(select(fromUser.getOffice)).subscribe((office: Office) => {
      this.municipalityId = parseInt(office.municipality, 10);
      this.countyId = parseInt(office.officeCounty, 10);
    });
    this.formConfig$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE))
    );
  }

  ngOnChanges(changes): void {
    if (this.additionalFilters && this.additionalFilters.length > 0) {
      const objectCategories = {};
      this.additionalFilters.forEach((stuff) => {
        objectCategories[stuff.categoryTypeId] = false;
      });
      this.searchProfileForm.addControl(
        "objectCategories",
        this.fb.group(objectCategories)
      );
    }
    if (
      changes.searchProfile &&
      changes.searchProfile.currentValue &&
      this.editMode
    ) {
      this.fillForm();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildForm(): void {
    this.objectTypes$ = this.store.pipe(select(fromShared.getObjectTypes));
    this.objectTypes$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((objectTypes) => {
        this.objectTypes = objectTypes;
        const defaultObjectTypes = {};
        this.objectTypes.forEach(
          (type) => (defaultObjectTypes[type.objectTypeId] = [""])
        );
        this.searchProfileForm = this.fb.group(
          {
            title: ["", Validators.required],
            comment: [""],
            areaSearch: ["", Validators.required],
            municipalityIds: [""],
            countyIds: [""],
            customFilterIds: [""],
            foreignGeoDataCity: [""],
            foreignGeoDataCityArea: [""],
            foreignGeoDataProvince: [""],
            hasElevator: [false],
            hasBalcony: [false],
            hasFireplace: [false],
            hasSauna: [false],
            objectType: this.fb.group(defaultObjectTypes),
            tenureTypeForSale: "",
            tenureTypeForRent: "",
            tenureType: "",
            isNewProduction: "",
            lotStatus: [""],
            maximumRooms: ["", [Validators.pattern(ONLY_DIGITS_ONE_DECIMAL)]],
            maximumPrice: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
            maximumMonthlyFee: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
            maximumBedrooms: ["", Validators.pattern(ONLY_DIGITS)],
            maximumArea: ["", Validators.pattern(ONLY_DIGITS_TWO_DECIMAL)],
            maximumBuildYear: ["", Validators.pattern(ONLY_DIGITS)],
            maximumLotArea: ["", Validators.pattern(ONLY_DIGITS_TWO_DECIMAL)],
            minimumRooms: ["", [Validators.pattern(ONLY_DIGITS_ONE_DECIMAL)]],
            minimumPrice: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
            minimumMonthlyFee: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
            minimumBedrooms: ["", Validators.pattern(ONLY_DIGITS)],
            minimumArea: ["", Validators.pattern(ONLY_DIGITS_TWO_DECIMAL)],
            minimumBuildYear: ["", Validators.pattern(ONLY_DIGITS)],
            minimumLotArea: ["", Validators.pattern(ONLY_DIGITS_TWO_DECIMAL)],
          },
          {
            validators: [
              this.numberRangeValidator(
                "minimumRooms",
                "maximumRooms",
                "roomsRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumPrice",
                "maximumPrice",
                "priceRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumMonthlyFee",
                "maximumMonthlyFee",
                "monthlyFeeRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumBedrooms",
                "maximumBedrooms",
                "bedroomsRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumArea",
                "maximumArea",
                "areaRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumBuildYear",
                "maximumBuildYear",
                "buildYearRangeMismatch"
              ),
              this.numberRangeValidator(
                "minimumLotArea",
                "maximumLotArea",
                "lotAreaRangeMismatch"
              ),
            ],
          }
        );
      });
  }

  private fillForm(): void {
    this.searchProfileForm.patchValue({
      title: this.searchProfile.title,
      comment: this.searchProfile.comment,
      cityAreas: this.searchProfile.cityAreas,
      hasElevator: this.convertStringToBoolean(this.searchProfile.hasElevator),
      hasBalcony: this.convertStringToBoolean(this.searchProfile.hasBalcony),
      hasFireplace: this.convertStringToBoolean(
        this.searchProfile.hasFireplace
      ),
      hasSauna: this.convertStringToBoolean(this.searchProfile.hasSauna),
      objectType: this.formatObjectTypesToObject(this.searchProfile.objectType),
      objectCategories: this.formatObjectCategoriesToObject(
        this.searchProfile.objectCategories
      ),
      maximumRooms:
        +this.searchProfile.maximumRooms > 0
          ? this.searchProfile.maximumRooms
          : "",
      maximumPrice:
        +this.searchProfile.maximumPrice > 0
          ? this.searchProfile.maximumPrice
          : "",
      maximumMonthlyFee:
        +this.searchProfile.maximumMonthlyFee > 0
          ? this.searchProfile.maximumMonthlyFee
          : "",
      maximumBedrooms:
        +this.searchProfile.maximumBedrooms > 0
          ? this.searchProfile.maximumBedrooms
          : "",
      maximumArea:
        +this.searchProfile.maximumArea > 0
          ? this.searchProfile.maximumArea
          : "",
      maximumBuildYear:
        +this.searchProfile.maximumBuildYear > 0
          ? this.searchProfile.maximumBuildYear
          : "",
      maximumLotArea:
        +this.searchProfile.maximumLotArea > 0
          ? this.searchProfile.maximumLotArea
          : "",
      minimumRooms:
        +this.searchProfile.minimumRooms > 0
          ? this.searchProfile.minimumRooms
          : "",
      minimumPrice:
        +this.searchProfile.minimumPrice > 0
          ? this.searchProfile.minimumPrice
          : "",
      minimumMonthlyFee:
        +this.searchProfile.minimumMonthlyFee > 0
          ? this.searchProfile.minimumMonthlyFee
          : "",
      minimumBedrooms:
        +this.searchProfile.minimumBedrooms > 0
          ? this.searchProfile.minimumBedrooms
          : "",
      minimumArea:
        +this.searchProfile.minimumArea > 0
          ? this.searchProfile.minimumArea
          : "",
      minimumBuildYear:
        +this.searchProfile.minimumBuildYear > 0
          ? this.searchProfile.minimumBuildYear
          : "",
      minimumLotArea:
        +this.searchProfile.minimumLotArea > 0
          ? this.searchProfile.minimumLotArea
          : "",
      tenureType: this.searchProfile.tenureType,
      tenureTypeForSale: this.doesTenureTypesMatchTenureType(
        this.searchProfile.tenureType,
        TenureTypes.ForSale
      ),
      tenureTypeForRent: this.doesTenureTypesMatchTenureType(
        this.searchProfile.tenureType,
        TenureTypes.ForRent
      ),
      isNewProduction: this.searchProfile.isNewProduction,
      lotStatus: this.searchProfile.lotStatus,
      areaSearch: this.selectedAreas,
    });
  }

  private doesTenureTypesMatchTenureType(
    types: string,
    targetType: string
  ): boolean {
    let isMatch = true;
    const typesArray = targetType.split(",");
    typesArray.forEach((it) => {
      if (!types.includes(it)) {
        isMatch = false;
      }
    });
    return isMatch;
  }

  private convertStringToBoolean(string: string): boolean | string {
    return string === "True" ? true : "";
  }

  handleSubmit(): void {
    if (
      this.searchProfileForm.valid &&
      this.selectedAreas &&
      this.selectedAreas.length > 0
    ) {
      this.submit.emit(this.formatFormValues(this.searchProfileForm.value));
    } else {
      formUtils.markAllAsTouched(this.searchProfileForm);
      this.cdr.detectChanges();
    }
  }

  private formatFormValues(formValues: any): SearchProfile {
    const newSearchProfile: SearchProfile = formValues;
    if (formValues.objectCategories) {
      newSearchProfile.objectCategories = this.formatObjectCategoriesToString(
        formValues.objectCategories
      );
    }
    newSearchProfile.objectType = formValues.objectType
      ? this.formatObjectTypesToString(formValues.objectType)
      : null;
    newSearchProfile.municipalityIds = this.getAreaIds(areaTypes.MUNICIPALITY);
    newSearchProfile.countyIds = this.getAreaIds(areaTypes.COUNTY);
    newSearchProfile.customFilterIds = this.getAreaIds(areaTypes.CUSTOM_FILTER);
    newSearchProfile.zipIds = this.getAreaIds(areaTypes.ZIP);
    newSearchProfile.foreignGeoDataCity =
      this.getAreaIds(areaTypes.FOREIGN_GEO_CITY) ?? "";
    newSearchProfile.foreignGeoDataCityArea =
      this.getAreaIds(areaTypes.FOREIGN_GEO_AREA) ?? "";
    newSearchProfile.foreignGeoDataProvince =
      this.getAreaIds(areaTypes.FOREIGN_GEO_PROVINCE) ?? "";

    newSearchProfile.hasBalcony = newSearchProfile.hasBalcony ? "True" : null;
    newSearchProfile.hasFireplace = newSearchProfile.hasFireplace
      ? "True"
      : null;
    newSearchProfile.hasSauna = newSearchProfile.hasSauna ? "True" : null;
    newSearchProfile.hasElevator = newSearchProfile.hasElevator ? "True" : null;

    newSearchProfile.maximumPrice = newSearchProfile.maximumPrice
      ?.toString()
      .replace(MATCH_WHITE_SPACE_GLOBAL, "");
    newSearchProfile.minimumPrice = newSearchProfile.minimumPrice
      ?.toString()
      .replace(MATCH_WHITE_SPACE_GLOBAL, "");
    newSearchProfile.maximumMonthlyFee = newSearchProfile.maximumMonthlyFee
      ?.toString()
      .replace(MATCH_WHITE_SPACE_GLOBAL, "");
    newSearchProfile.minimumMonthlyFee = newSearchProfile.minimumMonthlyFee
      ?.toString()
      .replace(MATCH_WHITE_SPACE_GLOBAL, "");

    newSearchProfile.tenureType = "";
    if (formValues.tenureTypeForSale) {
      newSearchProfile.tenureType = this.tenureTypeForSale;
    }

    newSearchProfile.lotStatus = formValues.lotStatus;

    if (formValues.tenureTypeForRent) {
      if (formValues.tenureType) {
        const tenureType = formValues.tenureType.split(",");
        tenureType.push(this.tenureTypeForRent);
        newSearchProfile.tenureType = tenureType.join(",");
      } else {
        newSearchProfile.tenureType = this.tenureTypeForRent;
      }
    }

    const areaTypesList = [
      areaTypes.FOREIGN_GEO_AREA,
      areaTypes.FOREIGN_GEO_CITY,
      areaTypes.FOREIGN_GEO_PROVINCE,
    ];

    newSearchProfile.areaSearch = newSearchProfile?.areaSearch?.filter(
      (area) => !areaTypesList.includes(area.type)
    );

    return newSearchProfile;
  }

  private formatObjectTypesToString(formValues): string {
    const resultArray = [];
    Object.keys(formValues).map((typeId) => {
      if (formValues[typeId]) {
        resultArray.push(typeId);
      }
    });
    return resultArray.join(",");
  }

  private formatObjectTypesToObject(values: string): {} {
    const objectTypes = {};
    const objectTypesIds = values.split(",");
    this.objectTypes.forEach((type) => {
      objectTypes[type.objectTypeId] = objectTypesIds.includes(
        type.objectTypeId
      );
    });
    return objectTypes;
  }

  private formatObjectCategoriesToString(values: {}): string {
    const value = this.additionalFilters
      .filter(
        (categoryType: CategoryType) => values[categoryType.categoryTypeId]
      )
      .map((categoryType: CategoryType) => categoryType.categoryTypeId)
      .toString();
    return value ? value : null;
  }

  private formatObjectCategoriesToObject(values: string): {} {
    const objectCategoriesObject = {};
    const objectCategoriesIndexes = values.split(",");

    objectCategoriesIndexes.forEach(
      (categoryIndex) => (objectCategoriesObject[categoryIndex] = true)
    );

    return objectCategoriesObject;
  }

  private getAreaIds(type: string): string {
    const value = this.selectedAreas
      ? this.selectedAreas
          .filter((area: FormattedSearchArea) => area.type === type)
          .map((area: FormattedSearchArea) => area.id)
          .toString()
      : "";

    return value ? value : null;
  }

  private registerObserverToFormValueChanges(): void {
    this.searchProfileForm.valueChanges
      .pipe(
        map(() => this.searchProfileForm.getRawValue()),
        startWith(this.searchProfileForm.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  selectedAreaChangedHandler(areas: FormattedSearchArea[]) {
    this.searchProfileForm.get("areaSearch").setValue(areas);
    this.selectedAreas = areas;
  }

  private numberRangeValidator(
    firstControl,
    secondControl,
    errorTitle = "mismatch"
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const minValue = control.get(firstControl).value;
      const maxValue = control.get(secondControl).value;

      if (!!minValue && !!maxValue && +minValue > +maxValue) {
        return { [errorTitle]: true };
      }

      return null;
    };
  }
}
