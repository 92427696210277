import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-note-stored",
  template: `
    <div class="action-note-stored">
      <div>
        <div class="display-flex-start">
          <span [innerHTML]="text | async" class=""></span>&nbsp;
          <contact-summary [contactId]="item.contactId"></contact-summary>
        </div>
        <div>
          <small *ngIf="item.param1?.length > 0" class="text-muted">
            <strong>{{ "note" | translate }}: </strong
            ><span [innerHtml]="item.param1 | nl2br | backslash"></span>
          </small>
          <small
            *ngIf="!ownsNote && item.param3 === 'private'"
            class="text-muted"
          >
            <strong>{{ "private_note_label" | translate }}</strong>
          </small>
          <small *ngIf="item.street?.length > 0" class="text-muted">
            <strong>{{ "address" | translate }}: </strong>
            <span
              class="text-link"
              (click)="itemService.navigateToObject(item.eaOid)"
              >{{ item.street }}
            </span>
          </small>
        </div>
      </div>
      <div class="flex-center note action-note-stored__trash">
        <span (click)="itemService.removeConsumerAction(item)">
          <app-icon name="trash"></app-icon>
        </span>
      </div>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionNoteStoredComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;
  @Input() ownsNote: boolean;

  text: Observable<string>;
  listenerNote: Function;

  className = "action-note-stored-name";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_note_stored_text", {
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
          you: youString,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerNote = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerNote];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
