import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({ name: "translateDsw" })
export class TranslateOnlyIfPrefixedWithDeswinglisherKeyPipe
  implements PipeTransform
{
  constructor(private ts: TranslateService) {}

  transform(value: any): string {
    if (!value) {
      return value;
    }

    if (value.toLowerCase().includes("deswinglisher_key")) {
      return this.ts.instant(value.toLowerCase());
    } else {
      return value;
    }
  }
}
