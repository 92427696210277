import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import * as widgetActions from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.actions";
import { LeadResult } from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.reducer";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsLeadResultWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/leads/search", params).pipe(
          map((response: LeadResult) =>
            widgetActions.fetchStatisticsSuccess({ leadResult: response })
          ),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );

  fetchTaskTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchTaskTypesRequest),
      switchMap(({ params }) =>
        this.apiService
          .get(
            "task-types/search",
            { ...params, direction: "outgoing,twoway" },
            "api"
          )
          .pipe(
            map(
              (response: {
                rows: { typeName: string; eaTaskTypeId: number }[];
              }) =>
                widgetActions.fetchTaskTypesSuccess({
                  taskTypes: response.rows,
                })
            ),
            catchError(() => of(widgetActions.fetchTaskTypesFail()))
          )
      )
    )
  );
}
