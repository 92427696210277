<div class="wrapper wrapper--long">
  <sidebar-header [type]="tabType" [label]="header$ | async"></sidebar-header>
  <residence-links></residence-links>
  <residence-form
    #residenceForm
    [residence]="selectedResidence$ | async"
    [processing]="processing$ | async"
    [showSauna]="showSauna$ | async"
    [objectTypes]="objectTypes$ | async"
    [config]="residenceSidebarConfig$ | async"
    (onSubmit)="onSubmit($event)"
  ></residence-form>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [disabled]="processing$ | async"
      [isLoading]="processing$ | async"
      (click)="residenceForm.submit()"
      scrollToInvalidControl [formEl]="residenceForm?.form"
    >
    </app-q-button>
  </div>
</div>
