import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { success } from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import {
  OriginService,
  PaginationListDTO,
  PaginationTaskListDTO,
  Task,
} from "@app/models";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  switchMap,
  takeUntil,
  throwError,
} from "rxjs";

@Injectable({ providedIn: "root" })
export class TaskService extends CustomEntityCollectionServiceBase<Task> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Task", serviceElementsFactory);
  }

  originServices$: BehaviorSubject<OriginService[]> = new BehaviorSubject(null);

  // @ts-ignore
  getWithQuery = (params, setListDefaults = true): Observable<any> => {
    this.setLoading(true);
    if (setListDefaults) {
      this.refreshCurrentList = () =>
        this.getWithQuery(params, setListDefaults);
      this.cancelExistingRequest$.next();
    }
    return this.apiService.get("tasks", params).pipe(
      takeUntil(this.cancelExistingRequest$),
      switchMap((response: PaginationTaskListDTO) => {
        const tasks: Task[] = response.rows;
        this.upsertManyInCache(tasks);
        this.setLoading(false);

        if (!!setListDefaults) {
          this.setListDefaults(tasks, response);
        }

        this.updateOriginServiceCount(response.originServices);
        return this.getListFromEntities(tasks);
      }),
      catchError((err) => {
        this.fetchErrorHandler(setListDefaults);
        return err;
      })
    );
  };

  fetchById = (id: string, updateInList = true) => {
    return this.apiService
      .get(`tasks/${id}`, { getObject: true, getTransferLogs: true })
      .pipe(
        map((response: any) => {
          if (updateInList) {
            this.upsertOneInCache(response);
          }
          return response;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "task_or_lead_load_failed" })
          );
          return err;
        })
      );
  };

  patch = (
    id: string,
    entity: Partial<Task>,
    triggerRefresh: boolean = true
  ): Observable<any> => {
    this.setLoading(true);
    return this.apiService
      .patch(`tasks/${id}`, {
        ...entity,
      })
      .pipe(
        map((response: any) => {
          const task = new Task(response);
          this.upsertOneInCache(task);
          this.appStore.dispatch(success({ message: "task_updated" }));
          this.setLoading(false);
          if (!!triggerRefresh) {
            this.refreshFetch$.next();
          }
          return task;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "task_or_lead_update_failed" })
          );
          return err;
        })
      );
  };

  remove = (id: string, params?: any): Observable<any> => {
    this.setLoading(true);
    return this.apiService.delete(`tasks/${id}`, { ...params }).pipe(
      map((response: any) => {
        this.removeOneFromCache(id);
        this.handleDelete(id);
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "task_or_lead_remove_failed" })
        );
        return err;
      })
    );
  };

  // Task Action Log
  getActionLogById = (taskId: string): Observable<any> => {
    this.setLoading(true);
    return this.apiService
      .get(`task-actions/${taskId}`, { sortOrder: "desc" })
      .pipe(
        map((response: PaginationListDTO) => {
          this.setLoading(false);
          return response.rows;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "task_actions_log_load_failed" })
          );
          return err;
        })
      );
  };

  // Task Attemps
  postTaskAttempt = (taskId: string, params: any): Observable<any> => {
    this.setLoading(true);
    return this.apiService.post(`tasks/${taskId}/attempts`, { ...params }).pipe(
      map((attempt: any) => {
        this.setLoading(false);
        this.appStore.dispatch(success({ message: "task_updated" }));
        return attempt;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "task_or_lead_update_failed" })
        );
        return err;
      })
    );
  };

  bulkDelete(tasks: Task[], reason: string, progressWidgetTitle: string) {
    const requests = tasks.map((task) => {
      return this.remove(task.eaTaskId, { reason }).pipe(
        catchError((err) => {
          err.customMessage = `${task.title} ${task.eaTaskTypeName}`;
          return throwError(() => err);
        })
      );
    });

    const taskSet: TaskSet = {
      label: progressWidgetTitle,
      tasks: requests,
    };

    this.store.dispatch(addTaskSetToQueue(taskSet));
  }

  getTasksOrigins = (
    taskTypeCategory: string = "task"
  ): Observable<OriginService[]> => {
    return this.apiService.get("tasks/origins", { taskTypeCategory }).pipe(
      switchMap((response: { origins: string[] }) => {
        const originServices: OriginService[] = response.origins.map(
          (origin) => ({
            origin,
            total: 0,
            value: origin === "no origin set" ? "NULL" : origin,
          })
        );

        this.originServices$.next(originServices);
        return originServices;
      }),
      catchError(() => {
        return [];
      })
    );
  };

  public updateOriginServiceCount(origins: OriginService[]) {
    const originServices = origins.map((origin) => ({
      origin: origin.origin,
      total: origin.total,
      value: origin.origin === "no origin set" ? "NULL" : origin.origin,
    }));

    this.originServices$.next(originServices);
  }

  clearData(setListDefaults: boolean = true) {
    this.setLoading(false);
    this.upsertManyInCache([]);
    if (setListDefaults) {
      this.setListDefaults([], null);
    }
  }

  private fetchErrorHandler(
    setListDefaults: boolean = true,
    message: string = "tasks_or_leads_load_failed"
  ) {
    this.clearData(setListDefaults);
    this.appStore.dispatch(toastActions.danger({ message: message }));
  }
}
