import {
  combineLatest as observableCombineLatest,
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";

import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { EmployeeDTO, Office } from "@app/models";
import { select, Store } from "@ngrx/store";
import { getEaEmployeeId, getEaOfficeId, hasRole } from "../../../user";
import { ROLE_ADMIN } from "../../../utils/roles";
import { OfficeEmployeeDropdownService } from "./office-employee-dropdown.service";

@Component({
  selector: "office-employee-dropdown",
  templateUrl: "./office-employee-dropdown.component.html",
  styleUrls: ["./office-employee-dropdown.component.scss"],
  providers: [OfficeEmployeeDropdownService],
})
export class OfficeEmployeeDropdownComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() setDefaultsOfficeOnly = false;
  @Input() setDefaults = true;
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormGroupName: string;
  @Input() parentOfficeFormControlName = "office";
  @Input() parentEmployeeFormControlName = "employee";
  @Input() disableSelectBroker = true;
  @Input() disableSelectOffice = true;
  @Input() extraOfficeProperty: string;
  @Input() extraEmployeeProperty: string;
  @Input() sideBySide = false;
  @Input() fullWidth = false;
  @Input() boldTitles = false;
  @Input() showAllOffices = false;
  @Output() employeesChange = new EventEmitter<EmployeeDTO[]>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onReady = new EventEmitter<void>();
  @Output() extraOfficePropertyReady = new EventEmitter<any>();
  @Output() extraEmployeePropertyReady = new EventEmitter<any>();

  offices$: Observable<Office[]>;
  employees$: Observable<EmployeeDTO[]>;
  unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private localStore: OfficeEmployeeDropdownService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initFormEventHandlers();
    this.fetchAuthorizedOffices();
    this.initEmployeesLoadingEvents();
  }

  ngAfterViewInit(): void {
    if (this.setDefaults) {
      this.setDefaultOfficeAndEmployee();
    } else if (this.setDefaultsOfficeOnly) {
      this.setDefaultsOffice();
    } else {
      this.offices$
        .pipe(
          filter((offices) => offices.length > 0),
          first()
        )
        .subscribe(() => this.onReady.next());
    }
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  mapStateToProps(): void {
    this.offices$ = this.localStore.offices$;
    this.employees$ = this.localStore.employees$;
  }

  initFormEventHandlers(): void {
    this.parentFormGroup
      .get(this.parentOfficeFormControlName)
      .valueChanges.pipe(
        filter((value) => !!value),
        distinctUntilChanged(),
        map((eaOfficeId) => this.localStore.getOfficeByEaOfficeId(eaOfficeId)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(({ eaOfficeId, role }) =>
        this.localStore.fetchEmployeesForOfficeByRole(eaOfficeId, role)
      );
  }

  fetchAuthorizedOffices(): void {
    this.store
      .pipe(select(hasRole(ROLE_ADMIN)), take(1))
      .subscribe((isAdmin) => {
        if (isAdmin || this.showAllOffices) {
          this.localStore.fetchOffices();
        } else {
          this.localStore.fetchOfficesConnectedToEmployee();
        }
      });
  }

  setDefaultOfficeAndEmployee(): void {
    const getDefaultOffice$ = observableCombineLatest([
      this.store.pipe(select(getEaOfficeId)),
      this.offices$.pipe(filter((offices) => offices.length > 0)),
    ]).pipe(
      map(([eaOfficeId, offices]) =>
        offices.find((o) => o.eaOfficeId === eaOfficeId)
      )
    );

    const getDefaultEmployee$ = observableCombineLatest([
      this.store.pipe(select(getEaEmployeeId)),
      this.employees$.pipe(filter((employees) => employees.length > 0)),
    ]).pipe(
      map(([eaEmployeeId, employees]) =>
        employees.find((e) => e.eaEmployeeId === eaEmployeeId)
      ),
      filter((value) => !!value)
    );

    getDefaultOffice$
      .pipe(
        delay(1), // TODO: How to make sure ngOnChanges is run in child component before?
        tap((office) =>
          this.parentFormGroup
            .get(this.parentOfficeFormControlName)
            .setValue(office.eaOfficeId)
        ),
        switchMap(() => getDefaultEmployee$),
        delay(1), // TODO: How to make sure ngOnChanges is run in child component before?
        tap((employee) =>
          this.parentFormGroup
            .get(this.parentEmployeeFormControlName)
            .setValue(employee.eaEmployeeId)
        ),
        tap(() => this.onReady.next()),
        take(1)
      )
      .subscribe();
  }

  private initEmployeesLoadingEvents() {
    this.localStore.employeesLoading$
      .pipe(withLatestFrom(this.employees$), takeUntil(this.unSubscribe$))
      .subscribe((data) => {
        const [loading, employees] = data;
        const control = this.parentFormGroup.get(
          this.parentEmployeeFormControlName
        );
        if (loading) {
          control.disable();
        } else {
          control.enable();
          this.employeesChange.next(employees);
        }
      });
  }

  getFullControlName(controlName: string): string {
    return this.parentFormGroupName
      ? `${this.parentFormGroupName}.${controlName}`
      : controlName;
  }

  private setDefaultsOffice() {
    const getDefaultOffice$ = observableCombineLatest([
      this.store.pipe(select(getEaOfficeId)),
      this.offices$.pipe(filter((offices) => offices.length > 0)),
    ]).pipe(
      map(([eaOfficeId, offices]) =>
        offices.find((o) => o.eaOfficeId === eaOfficeId)
      )
    );

    getDefaultOffice$
      .pipe(
        delay(1), // TODO: How to make sure ngOnChanges is run in child component before?
        tap((office) =>
          this.parentFormGroup
            .get(this.parentOfficeFormControlName)
            .setValue(office.eaOfficeId)
        ),
        tap(() => this.onReady.next()),
        take(1)
      )
      .subscribe();
  }
}
