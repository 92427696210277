import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import {
  PaginationListDTO,
  QObject,
  SalesMeeting,
  TypedPaginationListDTO,
} from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  catchError,
  concatMap,
  filter,
  first,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  tap,
} from "rxjs";
import * as residencesActions from "./residences.actions";

@Injectable()
export class ContactResidencesEffects {
  getResidenceList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residencesActions.getContactResidenceListRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/residences`).pipe(
          map((response: PaginationListDTO) =>
            residencesActions.getContactResidenceListSuccess({
              list: response.rows,
            })
          ),
          catchError(() =>
            observableOf(residencesActions.getContactResidenceListFailed())
          )
        )
      )
    )
  );

  getObjectConnectionList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residencesActions.getContactObjectConnectionListRequest),
      switchMap(({ contactId }) =>
        this.apiService
          .get(`object/contact/${contactId}/contact-objects/search`, {})
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) => {
              return residencesActions.getContactObjectConnectionListSuccess({
                objectConnectionList: response.rows,
              });
            }),
            catchError(() =>
              observableOf(
                residencesActions.getContactObjectConnectionListFailed()
              )
            )
          )
      )
    )
  );

  tryDeleteResidence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        residencesActions.deleteContactResidenceAndConnectedSalesMeetingsRequest
      ),
      switchMap(({ eaOid, contactId }) =>
        this.apiService.get(`residences/${eaOid}/sales-meetings`).pipe(
          map((response: PaginationListDTO) => response.rows),
          tap((salesMeetings: SalesMeeting[]) =>
            this.dispatchDeleteMessage(salesMeetings)
          ),
          switchMap((salesMeetings: SalesMeeting[]) =>
            this.store.pipe(
              select(confirmState),
              filter((value) => !!value),
              first(),
              map(() =>
                salesMeetings.length === 0
                  ? residencesActions.deleteContactResidenceRequest({
                      eaOid,
                      contactId,
                    })
                  : residencesActions.deleteSalesMeetingsRequest({
                      salesMeetings,
                      eaOid,
                      contactId,
                    })
              )
            )
          ),
          catchError(() =>
            observableFrom([
              residencesActions.deleteContactResidenceFailed(),
              toastActions.danger({ message: "delete_residence_failed" }),
            ])
          )
        )
      )
    )
  );

  deleteResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residencesActions.deleteContactResidenceRequest),
      switchMap(({ eaOid, contactId }) =>
        this.apiService
          .delete(`contacts/${contactId}/residences/${eaOid}`)
          .pipe(
            mergeMap(() =>
              observableFrom([
                residencesActions.deleteContactResidenceSuccess({ eaOid }),
                toastActions.success({ message: "delete_residence_success" }),
              ])
            ),
            catchError(() =>
              observableFrom([
                residencesActions.deleteContactResidenceFailed(),
                toastActions.danger({ message: "delete_residence_failed" }),
              ])
            )
          )
      )
    )
  );

  deleteSalesMeetingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residencesActions.deleteSalesMeetingsRequest),
      switchMap(({ salesMeetings, eaOid, contactId }) =>
        observableFrom(salesMeetings).pipe(
          concatMap((sm: SalesMeeting) =>
            this.apiService
              .delete(`sales-meetings/${sm.eaCrmSalesMeetingId}`)
              .pipe(
                map(() => [
                  residencesActions.deleteSalesMeetingsSuccess(),
                  toastActions.success({
                    message: "delete_sales_meeting_success",
                  }),
                ])
              )
          ),
          mergeMap((actions) =>
            observableFrom([
              ...actions,
              residencesActions.deleteContactResidenceRequest({
                eaOid,
                contactId,
              }),
            ])
          ),
          catchError(() =>
            observableFrom([
              residencesActions.deleteSalesMeetingsFailed(),
              toastActions.danger({ message: "delete_sales_meeting_failed" }),
            ])
          )
        )
      )
    )
  );

  getExternalLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residencesActions.getExternalProviderLinksRequest),
      switchMap(({ eaOid }) =>
        this.apiService
          .get(`external-meta-data/object/${eaOid}`, {}, "integrations")
          .pipe(
            map((response: { presentableLinks: any }) =>
              residencesActions.getExternalProviderLinksSuccess({
                externalProviderLinks: Object.values(response.presentableLinks),
              })
            ),
            catchError(() =>
              observableOf(residencesActions.getExternalProviderLinksFailed())
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}

  private dispatchDeleteMessage(salesMeetings: SalesMeeting[]): void {
    const message =
      salesMeetings.length === 0
        ? "remove_residence_message"
        : "remove_residence_sales_meeting_message";
    this.store.dispatch(
      confirmActions.show({
        header: "remove_residence_header",
        message,
      })
    );
  }
}
