<h3>
  <app-icon size="large" name="home"></app-icon>&nbsp;<span translate
    >latest_objects</span
  >
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <ng-container *ngFor="let object of objects$ | async">
    <div class="object-card-container">
      <app-object-card
        [object]="object"
        [navigationEndpoint]="navigationEndpoint$ | async"
        [openLinkInNewTab]="openLinkInNewTab"
      >
      </app-object-card>
    </div>
  </ng-container>
</div>
