import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { KiviFormComponent } from "@app/sidebar/external-provider/components/kivi-form/kivi-form.component";
import { MspecsFormComponent } from "@app/sidebar/external-provider/components/mspecs-form/mspecs-form.component";
import { VitecFormComponent } from "@app/sidebar/external-provider/components/vitec-form/vitec-form.component";
import { ConnectInExternalProviderComponent } from "@app/sidebar/external-provider/containers/connect-in-external-provider/connect-in-external-provider.component";
import { CreateInExternalProviderComponent } from "@app/sidebar/external-provider/containers/create-in-external-provider/create-in-external-provider.component";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormsModule,
    FormComponentsModule,
    UtilModule,
    SkeletonScreenModule,
    UiComponentsModule,
    SearchAddressModule,
    SearchContactModule,
  ],
  declarations: [
    CreateInExternalProviderComponent,
    VitecFormComponent,
    MspecsFormComponent,
    KiviFormComponent,
    ConnectInExternalProviderComponent,
  ],
})
export class ExternalProviderModule {}
