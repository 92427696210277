import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { fetchIntegrationEraIntranetUrlRequest } from "@app/integrations/ngrx/integrations.actions";
import { getEraIntranetUrl } from "@app/integrations/ngrx/integrations.reducer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-era-intranet-widget",
  templateUrl: "./era-intranet-widget.component.html",
  styleUrls: ["./era-intranet-widget.component.scss"],
})
export class EraIntranetWidgetComponent implements OnInit {
  eraIntranetUrl$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.dispatch(fetchIntegrationEraIntranetUrlRequest());
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.eraIntranetUrl$ = this.store.select(getEraIntranetUrl());
  }

  openLink(url: string) {
    window.open(url);
  }
}
