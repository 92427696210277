import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "qPercentCalculator",
})
export class QPercentCalculatorPipe implements PipeTransform {
  transform(numerator: number, denominator: number): any {
    if (!denominator) {
      return 0;
    }
    return numerator / denominator;
  }
}
