<div class="wrapper">
  <sidebar-header
    [label]="'sales_meeting_report' | translate"
    [type]="tabType"
  ></sidebar-header>

  <div *ngIf="task$ | async as task" class="scroll-view" [formGroup]="form">
    <sidebar-tasks-detail-card [task]="task">
      <div
        class="task-inner-container flex-container"
        *ngIf="showValuation(task.salesMeeting.evaluationValue)"
      >
        <div class="flex-column">
          <span translate>valuation</span>
          <small
            >{{ task.salesMeeting.evaluationValue | qNumber }}
            {{ task.salesMeeting.evaluationCurrency }}
          </small>
          <small
            ><em>{{ task.salesMeeting.evaluationComment }}</em></small
          >
        </div>
      </div>
    </sidebar-tasks-detail-card>
    <hr />
    <section>
      <h4 translate>result</h4>
      <div ngxErrors="result">
        <div translate ngxError="required" showWhen="touched">
          validation_required_selection
        </div>
      </div>
      <div class="form-group">
        <input formControlName="result" type="radio" id="won" value="won" />
        <label class="btn btn-default" [attr.for]="'won'">{{
          "won" | translate
        }}</label>
      </div>
      <div class="form-group">
        <input
          formControlName="result"
          type="radio"
          id="waiting"
          value="waiting"
        />
        <label class="btn btn-default" [attr.for]="'waiting'">{{
          "pending" | translate
        }}</label>
      </div>
      <div class="form-group" id="btn_salsmeeting_valuation">
        <input
          formControlName="result"
          type="radio"
          id="valuation"
          value="valuation"
        />
        <label class="btn btn-default" [attr.for]="'valuation'">{{
          "valuation" | translate
        }}</label>
      </div>
      <div class="form-group">
        <input formControlName="result" type="radio" id="lost" value="lost" />
        <label class="btn btn-default" [attr.for]="'lost'">{{
          "lost" | translate
        }}</label>
      </div>
      <div class="form-group">
        <input
          formControlName="result"
          type="radio"
          id="canceledByConsumer"
          value="canceledByConsumer"
        />
        <label class="btn btn-default" [attr.for]="'canceledByConsumer'">{{
          "canceledByConsumer" | translate
        }}</label>
      </div>
      <adaptive-placeholder>
        <textarea
          adaptivePlaceholder
          rows="5"
          formControlName="resultComment"
          [placeholder]="'note' | translate"
        ></textarea>
      </adaptive-placeholder>
    </section>
    <hr />
    <section [class]="'datepicker__' + form.get('result').value">
      <h4>
        {{ "select_follow_up_date" | translate }} ({{
          "optional" | translate | lowercase
        }})
      </h4>
      <div class="text-muted quick-options">
        <small>{{ "presets" | translate }}: </small>
        <small (click)="setDateFromNow(1)" class="text-link" translate>{{
          "tomorrow" | translate
        }}</small>
        <small> - </small>
        <small (click)="setDateFromNow(7)" class="text-link">
          {{ "in_X_days" | translate : { amount: 7 } }}
        </small>
        <small> - </small>
        <small (click)="setDateFromNow(14)" class="text-link">
          {{ "in_X_days" | translate : { amount: 14 } }}
        </small>
        <span> - </span>
        <small (click)="setDateFromNow(30)" class="text-link">
          {{ "in_X_days" | translate : { amount: 30 } }}
        </small>
      </div>
      <form-input-datepicker-dropdown
        [controlName]="'date'"
        [group]="form"
        [minDate]="minDate"
      ></form-input-datepicker-dropdown>
      <small translate>create_task_of_follow_up</small>
    </section>
    <hr />
    <ng-template #createdInCrm1>
      <section>
        <div class="alert alert-info" translate>
          report_sales_meeting_missing_field_explanation
        </div>
      </section>
    </ng-template>
    <ng-container *ngIf="task.salesMeeting.eaOid; else createdInCrm1">
      <section>
        <h4 translate>value_fees_discussion</h4>
        <adaptive-placeholder>
          <input
            adaptivePlaceholder
            thousandSeparator
            [placeholder]="'price_valuation' | translate"
            formControlName="valuation"
            value="{{ price_valuation }}"
          />
          <div ngxErrors="valuation">
            <div translate ngxError="pattern" showWhen="touched">
              validation_integers
            </div>
          </div>
        </adaptive-placeholder>
        <adaptive-placeholder>
          <div class="form-group">
            <textarea
              adaptivePlaceholder
              rows="5"
              formControlName="valuationComment"
              [placeholder]="'comment' | translate"
            ></textarea>
          </div>
        </adaptive-placeholder>
      </section>
    </ng-container>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [isLoading]="processing$ | async"
      [disabled]="processing$ | async"
      (click)="onSubmit()"
    >
    </app-q-button>
  </div>
</div>
