import { Component, Input } from "@angular/core";
import * as contactIconFunctions from "@app/shared/utils/contact-icon-functions";

@Component({
  selector: "app-contact-name",
  templateUrl: "./contact-name.component.html",
  styleUrls: ["./contact-name.component.scss"],
})
export class ContactNameComponent {
  @Input() contactId: string;
  @Input() contactType: "company" | "person" | "estate" = "person";
  @Input() summaryEnabled: boolean = true;

  contactIconFunctions = contactIconFunctions;
}
