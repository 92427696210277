import {
  createCampaignReducer,
  CreateCampaignState,
} from "@app/campaigns/create-campaign/ngrx/create-campaigns.reducer";
import { combineReducers } from "@ngrx/store";

export interface CampaignsModuleState {
  createCampaign: CreateCampaignState;
}

const reducers = {
  createCampaign: createCampaignReducer,
};

export function campaignModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
