export type ActionMessageType = "success" | "error";

interface MessageTypes {
  SUCCESS: ActionMessageType;
  ERROR: ActionMessageType;
}

export const ActionMessageTypes: MessageTypes = {
  SUCCESS: "success",
  ERROR: "error",
};
