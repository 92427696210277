import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Task } from "@app/models";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-tasks-widget",
  templateUrl: "./tasks-widget.component.html",
  styleUrls: ["./tasks-widget.component.scss"],
})
export class TasksWidgetComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() openLinkInNewTab = false;
  @Input() showHeader: boolean = true;

  unsubscribe$ = new Subject<void>();

  allTasks$: BehaviorSubject<Task[]> = new BehaviorSubject<Task[]>([]);
  currentTasks$: Observable<Task[]>;
  todayTasks$: Observable<Task[]>;
  upcomingTasks$: Observable<Task[]>;
  overdueTasks$: Observable<Task[]>;

  segmentedControls: SegmentControls = [
    { label: "today", value: "today" },
    {
      label: "upcoming",
      value: "upcoming",
      tooltip: "tasks_for_coming_30_days",
    },
    { label: "overdue", value: "overdue" },
  ];

  activeControl = new FormControl("today");

  constructor(
    public taskService: TaskService,
    public taskTypeResolver: TaskTypeResolverService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.handleViewChanges();
    this.setSegmentControls();
  }

  ngOnChanges(changes): void {
    if (changes.eaEmployeeId && this.eaEmployeeId) {
      this.fetchTasks();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.todayTasks$ = this.allTasks$.pipe(
      map((list) => {
        const startOfToday = moment().startOf("day");
        const endOfToday = moment().endOf("day");
        return list.filter((task) =>
          moment(task.deliveryDate).isBetween(startOfToday, endOfToday)
        );
      })
    );

    this.upcomingTasks$ = this.allTasks$.pipe(
      map((list) => {
        const today = moment().endOf("day");
        return list.filter((task) => moment(task.deliveryDate).isAfter(today));
      })
    );

    this.overdueTasks$ = this.allTasks$.pipe(
      map((list) => {
        const today = moment().startOf("day");
        return list.filter((task) => moment(task.deliveryDate).isBefore(today));
      })
    );
    this.currentTasks$ = this.todayTasks$;
  }

  private fetchTasks() {
    const params = {
      limit: 2000,
      eaEmployeeId: this.eaEmployeeId,
      taskTypeCategory: "task",
      resolved: false,
      sortBy: "deliveryDate",
      sortOrder: "desc",
      deliveryTo: moment().add(30, "days").endOf("day").format(API_DATE_FORMAT),
    };
    this.taskService.refreshFetch$.subscribe(() => {
      return this.taskService
        .getWithQuery(params, false)
        .subscribe((tasks: Task[]) => {
          this.allTasks$.next(tasks);
        });
    });
  }

  private handleViewChanges() {
    this.activeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (value === "today") {
          this.currentTasks$ = this.todayTasks$;
        } else if (value === "upcoming") {
          this.currentTasks$ = this.upcomingTasks$;
        } else {
          this.currentTasks$ = this.overdueTasks$;
        }
      });
  }

  private setSegmentControls() {
    combineLatest([this.todayTasks$, this.upcomingTasks$, this.overdueTasks$])
      .pipe(takeUntil(this.unsubscribe$), debounceTime(600))
      .subscribe(([today, upcoming, overdue]) => {
        this.segmentedControls = [
          { label: "today", value: "today", total: today.length },
          {
            label: "upcoming",
            value: "upcoming",
            total: upcoming.length,
            tooltip: "tasks_for_coming_30_days",
          },
          { label: "overdue", value: "overdue", total: overdue.length },
        ];
      });
  }

  onTaskClick(task: Task, event) {
    event.preventDefault();
    if (!event.target.attributes["disablenavigation"]) {
      this.taskTypeResolver.onTaskClick(task, this.openLinkInNewTab);
    }
  }
}
