import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import * as progressBarActions from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface ProgressWidgetState {
  taskSets: TaskSet[];
  completedTaskSet: TaskSet[];
  show: boolean;
  loading: boolean;
}

const initialState: ProgressWidgetState = {
  taskSets: [],
  completedTaskSet: [],
  show: false,
  loading: false,
};

export function progressWidgetReducer(
  state: ProgressWidgetState,
  action: Action
): ProgressWidgetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(progressBarActions.showProgressWidget, (state) => ({
    ...state,
    show: true,
  })),
  on(progressBarActions.hideProgressWidget, (state) => ({
    ...state,
    show: false,
  })),
  on(progressBarActions.addTaskSetToQueueRequest, (state, { taskSet }) => ({
    ...state,
    taskSets: [...state.taskSets, taskSet],
    show: true,
  })),
  on(progressBarActions.executeTaskSetRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(progressBarActions.executeTaskSetSuccess, (state, { id }) => ({
    ...state,
    completedTaskSet: [
      ...state.completedTaskSet,
      state.taskSets.find((taskSet) => taskSet.id === id),
    ],
    taskSets: state.taskSets.filter((taskSet) => taskSet.id !== id),
    loading: false,
  })),
  on(progressBarActions.executeTaskSetFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(progressBarActions.incrementCompletedCount, (state, { id }) => ({
    ...state,
    taskSets: [
      ...state.taskSets.map((taskSet) => {
        let taskSetCopy = taskSet;
        if (taskSetCopy.id === id) {
          taskSetCopy = {
            ...taskSet,
            completed: taskSet.completed + 1,
          };
        }
        return taskSetCopy;
      }),
    ],
  })),
  on(progressBarActions.incrementFailedCount, (state, { id }) => ({
    ...state,
    taskSets: [
      ...state.taskSets.map((taskSet) => {
        let taskSetCopy = taskSet;
        if (taskSetCopy.id === id) {
          taskSetCopy = {
            ...taskSet,
            failed: taskSet.failed + 1,
          };
        }
        return taskSetCopy;
      }),
    ],
  }))
);

const selectFeature =
  createFeatureSelector<ProgressWidgetState>("progressWidget");

export const getProgressWidgetShow = createSelector(
  selectFeature,
  (state: ProgressWidgetState) => state.show
);

export const getProgressWidgetTaskSetsInProgress = createSelector(
  selectFeature,
  (state: ProgressWidgetState) =>
    state.taskSets.filter(
      (taskSet) => taskSet.tasks.length !== taskSet.completed
    )
);

export const getProgressWidgetCompletedTaskSets = createSelector(
  selectFeature,
  (state: ProgressWidgetState) => state.completedTaskSet
);

export const getProgressWidgetLoading = createSelector(
  selectFeature,
  (state: ProgressWidgetState) => state.loading
);
