import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";
import * as fromResidences from "@app/contacts/contact-residences/residences.reducer";
import * as objectTypes from "@app/contacts/utils/object-types";
import { QObject } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-contact-objects-widget",
  templateUrl: "./contact-objects-widget.component.html",
  styleUrls: ["./contact-objects-widget.component.scss"],
})
export class ContactObjectsWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() contactId: string;

  createObjectFeature$: Observable<Feature>;
  objectConnectionList$: Observable<QObject[]>;
  loading$: Observable<boolean>;

  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchObjectConnections();
  }

  ngOnChanges(changes) {
    if (changes.contactId) {
      this.fetchObjectConnections();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getObjectTypeTranslationKey(type: string, bidStatus = ""): string {
    if (type === objectTypes.POTENTIAL_BUYER && bidStatus === "participating") {
      return "bidder";
    } else {
      switch (type) {
        case objectTypes.UNKNOWN:
          return "unknown";
        case objectTypes.POTENTIAL_BUYER:
          return "potential_buyer";
        case objectTypes.OWNER:
          return "owner";
        case objectTypes.BUYER:
          return "buyer";
        case objectTypes.SELLER:
          return "seller";
        case objectTypes.TENANT:
          return "tenant";
        case objectTypes.FOLLOWER:
          return "follower";
        default:
          return "unknown";
      }
    }
  }

  private mapStateToProps() {
    this.createObjectFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.CREATE_OBJECT))
    );
    this.objectConnectionList$ = this.store.pipe(
      select(fromResidences.getObjectConnectionList)
    );
    this.loading$ = this.store.pipe(
      select(fromResidences.getObjectListLoading)
    );
  }

  private fetchObjectConnections() {
    this.store.dispatch(
      residencesActions.getContactObjectConnectionListRequest({
        contactId: this.contactId,
      })
    );
  }
}
