import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Action } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "contact-action-list",
  templateUrl: "./contact-action-list.component.html",
  styleUrls: ["./contact-action-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactActionListComponent {
  actions$: Observable<Action[]>;

  showAll = false;

  @Input() actions: Action[];

  getActions() {
    return this.actions ? this.actions.splice(0, 3) : this.actions;
  }
}
