import { Injectable } from "@angular/core";
import { BidService } from "@app/core/ngrx/entity-services/bid.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { EmployeeService } from "@app/core/ngrx/entity-services/employee.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";
import { ShowingService } from "@app/core/ngrx/entity-services/showing.service";
import { ApiService } from "@app/core/services/api/api.service";
import { Bid } from "@app/integrations/bidding/models/bid";
import { Contact, Employee, Office, QObject } from "@app/models";
import { PotentialBuyer, Showing, ShowingObject } from "@app/showings/models";
import { catchError, map, Observable } from "rxjs";

export interface PotentialBuyerByObjectResponse {
  office: Partial<Office>;
  object: Partial<QObject & ShowingObject>;
  employees: Partial<Employee>[];
  potentialBuyers: Partial<PotentialBuyer & Contact>[];
}

export interface PotentialBuyerByShowingResponse {
  office: Partial<Office>;
  object: Partial<QObject & ShowingObject>;
  employees: Partial<Employee>[];
  numberOfShowings: number;
  showings: Partial<Showing>[];
}

export interface BidsByObjectResponse {
  bids: Bid[];
  externalObjectId: string;
  eaOid: string;
}

@Injectable({ providedIn: "root" })
export class PotentialBuyerService {
  constructor(
    private objectService: ObjectService,
    private contactService: ContactService,
    private officeService: OfficeService,
    private employeeService: EmployeeService,
    private showingService: ShowingService,
    private bidService: BidService,
    private apiService: ApiService
  ) {}

  getPotentialBuyersByObject = (eaOid: string, params?: any) => {
    return this.apiService
      .get(`potential-buyers/object/${eaOid}/view/object`, params)
      .pipe(
        map((response: PotentialBuyerByObjectResponse) => {
          this.contactService.upsertManyInCache(response.potentialBuyers);
          // @ts-ignore
          this.contactService.setListDefaults(response.potentialBuyers);
          this.objectService.upsertOneInCache(response.object);
          this.officeService.upsertOneInCache(response.office);
          this.employeeService.upsertManyInCache(response.employees);

          return response;
        }),
        catchError((err) => {
          throw err;
        })
      );
  };

  refreshGetPotentialBuyersByShowing = (): Observable<any> | any => {};
  getPotentialBuyersByShowing = (eaOid: string, params?: any) => {
    this.refreshGetPotentialBuyersByShowing = () =>
      this.getPotentialBuyersByShowing(eaOid, params);
    this.showingService.setLoading(true);
    this.objectService.setLoading(true);
    return this.apiService
      .get(`potential-buyers/object/${eaOid}/view/showing`, {
        sortBy: "startTime",
        sortOrder: "asc",
        ...params,
      })
      .pipe(
        map((response: PotentialBuyerByShowingResponse) => {
          this.showingService.upsertManyInCache(response.showings);
          // @ts-ignore
          this.showingService.setListDefaults(response.showings);
          this.objectService.upsertOneInCache(response.object);
          this.officeService.upsertOneInCache(response.office);
          this.employeeService.upsertManyInCache(response.employees);
          this.showingService.setLoading(false);
          this.objectService.setLoading(false);
          return response;
        }),
        catchError((err) => {
          throw err;
        })
      );
  };

  refreshBidsByObject = (): Observable<any> | any => {};
  getBidsByObject = (eaOid: string) => {
    this.refreshBidsByObject = () => this.getBidsByObject(eaOid);
    this.bidService.setLoading(true);
    return this.apiService
      .get(`object/${eaOid}/bids/search`, {}, "integrations")
      .pipe(
        map((response: BidsByObjectResponse) => {
          this.objectService.upsertOneInCache(response);
          this.bidService.upsertManyInCache(response.bids);
          this.bidService.setListDefaults(response.bids);
          this.bidService.setLoading(false);
          return response.bids;
        }),
        catchError((err) => {
          this.bidService.setLoading(false);
          throw err;
        })
      );
  };

  refreshBidListByObject = (eaOid: string) => {
    this.bidService.setLoading(true);
    return this.apiService
      .get(`object/${eaOid}/bids/search/refresh`, {}, "integrations")
      .pipe(
        map((response: BidsByObjectResponse) => {
          this.objectService.upsertOneInCache(response);
          this.bidService.upsertManyInCache(response.bids);
          this.bidService.setListDefaults(response.bids);
          this.bidService.setLoading(false);
          return response.bids;
        }),
        catchError((err) => {
          throw err;
        })
      );
  };
}
