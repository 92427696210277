import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { SalesMeeting } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as salesMeetingActions from "./contact-sales-meetings.actions";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";

export interface ContactSalesMeetingsState {
  loaded: boolean;
  loading: boolean;
  list: SalesMeeting[] | any;
  selected: SalesMeeting;
  processing: boolean;
  externalProviderLink: ExternalPresentableLink | null;
  externalProviderLinkLoading: boolean;
  latestExternalIdRequest: Action | null;
}

export const initialState: ContactSalesMeetingsState = {
  loaded: false,
  loading: false,
  list: [],
  selected: undefined,
  processing: false,
  externalProviderLink: null,
  externalProviderLinkLoading: false,
  latestExternalIdRequest: null,
};

export function contactSalesMeetingsReducer(
  state: ContactSalesMeetingsState,
  action: Action
): ContactSalesMeetingsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(salesMeetingActions.getSalesMeetingsRequest, (state) => ({
    ...state,
    loaded: initialState.loaded,
    loading: true,
  })),
  on(
    salesMeetingActions.getSalesMeetingsSuccess,
    (state, { salesMeetings }) => ({
      ...state,
      loading: false,
      loaded: true,
      list: salesMeetings,
    })
  ),
  on(salesMeetingActions.getSalesMeetingsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: initialState.loaded,
    list: initialState.list,
  })),
  on(
    salesMeetingActions.getSalesMeetingDetailsSuccess,
    (state, { salesMeeting }) => ({
      ...state,
      selected: salesMeeting,
    })
  ),
  on(salesMeetingActions.getSalesMeetingDetailsFailed, (state) => ({
    ...state,
    selected: initialState.selected,
  })),
  on(salesMeetingActions.deleteSalesMeetingRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(salesMeetingActions.deleteSalesMeetingSuccess, (state) => ({
    ...state,
    processing: initialState.processing,
    selected: initialState.selected,
  })),
  on(salesMeetingActions.deleteSalesMeetingFailed, (state) => ({
    ...state,
    processing: initialState.processing,
  })),
  on(salesMeetingActions.getExternalProviderLinkRequest, (state, action) => ({
    ...state,
    externalProviderLink: null,
    externalProviderLinkLoading: true,
    latestExternalIdRequest: action,
  })),
  on(salesMeetingActions.getExternalProviderLinkFailed, (state) => ({
    ...state,
    externalProviderLinkLoading: false,
  })),
  on(
    salesMeetingActions.getExternalProviderLinkSuccess,
    (state, { externalProviderLink }) => ({
      ...state,
      externalProviderLink,
      externalProviderLinkLoading: false,
    })
  ),
  on(salesMeetingActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature = createFeatureSelector<ContactModuleState>("contact");
const getState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.salesMeetings
);

export const getLoaded = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.loaded
);

export const getSalesMeetingList = createSelector(
  getState,
  getLoaded,
  (state: ContactSalesMeetingsState, loaded) =>
    loaded ? state.list : [new SalesMeeting(), new SalesMeeting()]
);

export const getSelectedSalesMeeting = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.selected
);

export const getSelectedSalesMeetingEaOid = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.selected.eaOid
);

export const getSelectedSalesMeetingEaCrmSalesMeetingId = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.selected.eaCrmSalesMeetingId
);

export const getSalesMeetingProcessing = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.processing
);

export const getExternalProviderLink = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.externalProviderLink
);
export const getExternalProviderLinkLoading = createSelector(
  getState,
  (state: ContactSalesMeetingsState) => state.externalProviderLinkLoading
);
