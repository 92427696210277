import { Route } from "@angular/router";
import { CreatePotentialBuyerContactComponent } from "@app/sidebar/contacts/create-potential-buyer-contact/create-potential-buyer-contact.component";
import { CreateSellerBuyerContactComponent } from "@app/sidebar/contacts/create-seller-buyer-contact/create-seller-buyer-contact.component";
import { ContactPotentialBuyerCreateGuard } from "@app/sidebar/guards/contact-potential-buyer-create-guard.service";
import { ContactSellerBuyerCreateGuard } from "@app/sidebar/guards/contact-seller-buyer-create-guard.service";
import { ContactCreateGuard } from "../guards/contact-create-guard.service";
import { ContactEditGuard } from "../guards/contact-edit-guard.service";
import { sidebarContactsResidencesRoutes } from "../residence/residences-routing";
import { sidebarContactsSalesMeetingsRoutes } from "../sales-meeting/sales-meeting-routing";
import { CreateContactComponent } from "./create-contact/create-contact.component";
import { EditContactComponent } from "./edit-contact/edit-contact.component";

export const sidebarContactsRoutes: Route = {
  path: "contacts",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: CreateContactComponent,
      canActivate: [ContactCreateGuard],
    },
    {
      path: "potential-buyer/new",
      component: CreatePotentialBuyerContactComponent,
      canActivate: [ContactPotentialBuyerCreateGuard],
    },
    {
      path: "buyer/new",
      component: CreateSellerBuyerContactComponent,
      canActivate: [ContactSellerBuyerCreateGuard],
    },
    {
      path: "seller/new",
      component: CreateSellerBuyerContactComponent,
      canActivate: [ContactSellerBuyerCreateGuard],
    },
    {
      path: ":id/edit",
      component: EditContactComponent,
      canActivate: [ContactEditGuard],
    },
    sidebarContactsSalesMeetingsRoutes,
    sidebarContactsResidencesRoutes,
  ],
};
