import { Injectable } from "@angular/core";
import { FormControl } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { EmployeeService } from "@app/core/ngrx/entity-services/employee.service";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";
import { RegionService } from "@app/core/ngrx/entity-services/region.service";
import { Employee, Office } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { AvailabilityModes } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.component";
import { getEmployee, getOffice, getRoles } from "@app/shared/user";
import { Role, ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
} from "rxjs";
import { TeamsService } from "@app/settings/offices-employees/manage-teams/teams.service";

@Injectable()
export class AdvancedOfficeEmployeeDropdownService {
  constructor(
    private officeService: OfficeService,
    private regionService: RegionService,
    private employeeService: EmployeeService,
    private teamService: TeamsService,
    private store: Store<AppState>
  ) {}

  officeEmployeesFetched: string[] = [];

  oldGetOffices = (
    availabilityMode: AvailabilityModes,
    officeRegion: string
  ) => {
    return combineLatest([
      this.officeService.entities$.pipe(
        filter((list) => !!list && list.length > 0),
        first()
      ),
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
      this.regionService.entities$,
      this.store.pipe(select(fromConfig.getOfficeNamePrefix)),
    ]).pipe(
      map(([offices, user, roles, regions, officePrefix]) => {
        if (officeRegion === "regions") {
          return regions.map((region) => ({
            display: region.regionName,
            value: region.offices.map((office) => office.eaOfficeId).toString(),
          }));
        } else {
          return offices
            .filter((office) => office.active)
            .filter((office) =>
              this.filterOffice(user.offices, office, roles, availabilityMode)
            )
            .map((office) => {
              let officeName = office.name;
              if (office.name !== officePrefix) {
                officeName = office.name.replace(officePrefix, ""); // Office prefix here?
              }

              return {
                display: officeName,
                value: office.eaOfficeId,
              };
            });
        }
      })
    );
  };

  getOffices(availabilityMode: AvailabilityModes) {
    return combineLatest([
      this.officeService.entities$.pipe(
        filter((list) => !!list && list.length > 0),
        first()
      ),
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
    ]).pipe(
      map(([offices, employee, roles]) =>
        offices
          .filter((office) => office.active)
          .filter((office) =>
            this.filterOffice(employee.offices, office, roles, availabilityMode)
          )
          .map((office) => ({
            label: office.name,
            value: office.eaOfficeId,
          }))
      )
    );
  }

  getRegions() {
    return this.regionService.entities$.pipe(
      map((regions) =>
        regions.map((region) => ({
          label: region.regionName,
          value: region.offices.map((office) => office.eaOfficeId).toString(),
        }))
      )
    );
  }

  getBrokersFromOffice(
    offices$: Observable<string>,
    availabilityMode$: Observable<AvailabilityModes>,
    active: boolean
  ) {
    return combineLatest([
      offices$,
      this.employeeService.entities$.pipe(debounceTime(500)),
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
      availabilityMode$,
    ]).pipe(
      map(([offices, brokers, user, roles, availabilityMode]) => {
        const eaOfficeIds = offices
          ? offices.split(",")
          : this.correctEaOfficeIds(availabilityMode, user, roles);
        this.fetchEmployeesForOffices(eaOfficeIds);

        return brokers
          .filter((broker) => {
            if (active) {
              return (
                broker.status === "active" &&
                broker.offices.filter(
                  (office) =>
                    eaOfficeIds.includes(office.eaOfficeId) &&
                    office.status === "active"
                ).length > 0
              );
            } else {
              return (
                broker.status !== "active" ||
                broker.offices.filter(
                  (office) =>
                    eaOfficeIds.includes(office.eaOfficeId) &&
                    office.status !== "active"
                ).length > 0
              );
            }
          })
          .filter((broker) =>
            this.filterEmployee(
              user,
              broker,
              roles,
              availabilityMode,
              eaOfficeIds
            )
          )
          .map((broker) => ({
            label: broker.fullName,
            value: broker.eaEmployeeId,
          }));
      })
    );
  }

  oldGetEmployees = (
    eaOfficeFormControl: FormControl,
    availability: AvailabilityModes = "medium",
    activeEmployees$: Observable<string>
  ) => {
    return combineLatest([
      eaOfficeFormControl.valueChanges,
      this.employeeService.entities$.pipe(debounceTime(500)),
      this.teamService.entities$.pipe(debounceTime(500)),
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
      activeEmployees$,
    ]).pipe(
      map(([eaOfficeIds, employees, teams, user, roles, active]) => {
        let newEaOfficeIds = Array.isArray(eaOfficeIds)
          ? [...eaOfficeIds]
          : [eaOfficeIds];
        if (newEaOfficeIds.length === 0) {
          newEaOfficeIds = this.correctEaOfficeIds(availability, user, roles);
        }
        // Todo: Add switchmap here?
        this.fetchEmployeesForOffices(newEaOfficeIds);
        return {
          employees,
          teams,
          user,
          roles,
          eaOfficeIds: newEaOfficeIds,
          active,
        };
      }),
      map(({ employees, teams, user, roles, eaOfficeIds, active }) => {
        const filteredTeams = teams
          .filter((t) => eaOfficeIds.includes(t?.office?.id))
          .map((team) => ({
            display: team.name,
            value: team.id,
          }));

        const filteredEmployees = employees
          .filter((employee) => {
            if (active === "inactive") {
              return (
                employee.status !== "active" ||
                employee.offices.filter(
                  (office) =>
                    eaOfficeIds.includes(office.eaOfficeId) &&
                    office.status !== "active"
                ).length > 0
              );
            } else if (active === "active") {
              return (
                employee.status === "active" &&
                employee.offices.filter(
                  (office) =>
                    eaOfficeIds.includes(office.eaOfficeId) &&
                    office.status === "active"
                ).length > 0
              );
            }
          })
          .filter((employee) =>
            this.filterEmployee(
              user,
              employee,
              roles,
              availability,
              eaOfficeIds
            )
          )
          .map((employee) => ({
            display: employee.fullName,
            value: employee.eaEmployeeId,
          }));

        return { employees: filteredEmployees, teams: filteredTeams };
      })
    );
  };

  private filterOffice(
    userOffices: Office[],
    selectedOffice: Office,
    roles: Role[],
    availability: AvailabilityModes
  ): boolean {
    if (roles.includes(ROLE_ADMIN) || availability === "liberal") {
      // Admins can see everything
      // Liberal: Everyone can see everything
      return true;
    } else if (availability === "medium") {
      // Medium: Same rights for both brokers and managers
      return (
        userOffices.filter(
          (office) => office.eaOfficeId === selectedOffice.eaOfficeId
        ).length > 0
      );
    } else if (availability === "restrictive") {
      if (roles.includes(ROLE_MANAGER)) {
        // Managers may view offices that they are managers for
        return (
          userOffices.filter(
            (office) =>
              office.eaOfficeId === selectedOffice.eaOfficeId &&
              office.role === ROLE_MANAGER
          ).length > 0
        );
      } else {
        return (
          userOffices.filter(
            (office) => office.eaOfficeId === selectedOffice.eaOfficeId
          ).length > 0
        );
      }
    }
  }

  private filterEmployee(
    user,
    employee: Employee,
    roles: Role[],
    availability: AvailabilityModes,
    eaOfficeIds: string[]
  ) {
    if (
      availability === "restrictive" &&
      !roles.includes(ROLE_ADMIN) &&
      !roles.includes(ROLE_MANAGER)
    ) {
      return employee.eaEmployeeId === user.eaEmployeeId;
    } else if (eaOfficeIds.length === 0 && !roles.includes(ROLE_ADMIN)) {
      return (
        user.offices.filter((eaOfficeId) => !!employee[eaOfficeId]).length > 0
      );
    } else {
      return true;
    }
  }

  private fetchEmployeesForOffices(eaOfficeIds: string[]) {
    if (eaOfficeIds.length > 0) {
      const officeIds = eaOfficeIds
        .filter((eaOfficeId) => eaOfficeId)
        .filter(
          (eaOfficeId) => !this.officeEmployeesFetched.includes(eaOfficeId)
        );

      if (officeIds.length > 0) {
        this.employeeService
          .getWithOfficeConnection({
            eaOfficeIds: officeIds.join(","),
            status: "active,inactive",
          })
          .subscribe(() => {
            this.officeEmployeesFetched.push(...officeIds);
          });
        this.teamService
          .getTeams(officeIds, {
            sort: {
              field: "name",
              order: "asc",
            },
            pagination: {
              offset: 0,
              limit: 100,
            },
          })
          .subscribe();
      }
    } else {
      if (!this.officeEmployeesFetched.includes("all")) {
        this.officeEmployeesFetched.push("all");
        this.employeeService
          .getWithOfficeConnection({ status: "active,inactive" })
          .subscribe();
        this.teamService
          .getTeams([], {
            sort: {
              field: "name",
              order: "asc",
            },
            pagination: {
              offset: 0,
              limit: 100,
            },
          })
          .subscribe();
      }
    }
  }

  private correctEaOfficeIds(
    availability: AvailabilityModes,
    user: Employee,
    roles: Role[]
  ) {
    if (roles.includes(ROLE_ADMIN) || availability === "liberal") {
      return [];
    } else if (availability === "medium") {
      // Medium: Same rights for both brokers and managers
      return user.offices.map((office) => office.eaOfficeId);
    } else if (availability === "restrictive") {
      if (roles.includes(ROLE_MANAGER)) {
        // Managers may view offices that they are managers for
        return user.offices
          .filter((office) => office.role === ROLE_MANAGER)
          .map((office) => office.eaOfficeId);
      } else {
        return user.offices.map((office) => office.eaOfficeId);
      }
    }
  }

  setDefaults(
    officeFormControl: FormControl,
    employeeFormControl: FormControl,
    availability: AvailabilityModes,
    officeMulti = true,
    employeeMulti = true,
    required = false
  ) {
    combineLatest([
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
      this.store.pipe(select(getOffice)),
      this.officeService.entities$.pipe(
        filter((list) => !!list && list.length > 0),
        first()
      ),
    ])
      .pipe(
        map(([user, roles, office, offices]) => {
          if (
            !officeFormControl.value ||
            officeFormControl.value.length === 0
          ) {
            if (!!officeMulti) {
              if (
                availability === "restrictive" &&
                !roles.includes(ROLE_ADMIN) &&
                roles.includes(ROLE_MANAGER)
              ) {
                officeFormControl.setValue(
                  user.offices
                    .filter((office) => office.role === ROLE_MANAGER)
                    .map((office) => office.eaOfficeId)
                );
              } else if (roles.includes(ROLE_ADMIN)) {
                if (offices.length === 1) {
                  officeFormControl.setValue([offices[0].eaOfficeId]);
                } else {
                  officeFormControl.setValue([]);
                }
              } else {
                officeFormControl.setValue(
                  user.offices.map((office) => office.eaOfficeId)
                );
              }
            } else {
              officeFormControl.setValue(office.eaOfficeId);
            }
          }

          if (
            !employeeFormControl.value ||
            employeeFormControl.value.length === 0
          ) {
            if (!required) {
              if (
                availability === "restrictive" &&
                !roles.includes(ROLE_ADMIN) &&
                !roles.includes(ROLE_MANAGER)
              ) {
                const eaEmployeeId = employeeMulti
                  ? [user.eaEmployeeId]
                  : user.eaEmployeeId;
                employeeFormControl.setValue(eaEmployeeId);
              }
            } else {
              const eaEmployeeId = employeeMulti
                ? [user.eaEmployeeId]
                : user.eaEmployeeId;
              employeeFormControl.setValue(eaEmployeeId);
            }
          }
        })
      )
      .subscribe();
  }

  handleOfficeValueEntered(
    value: any,
    officeFormControl: FormControl,
    availability: AvailabilityModes,
    multi = true,
    regionControl,
    officeRegion$: Observable<string>,
    setDefaults
  ) {
    // This is here because managers and brokers should not be able to view data from ALL offices(aka: value: []).
    combineLatest([
      this.store.pipe(select(getEmployee)),
      this.store.pipe(select(getRoles)),
      this.officeRequired(availability, multi, setDefaults),
      officeRegion$,
    ])
      .pipe(
        first(),
        map(([user, roles, required, officeRegion]) => {
          if (officeRegion === "regions") {
            regionControl.setValue(value);
            return;
          }

          if (required) {
            if (availability === "restrictive" && !roles.includes(ROLE_ADMIN)) {
              if (value.length === 0) {
                let filteredOffices;

                if (roles.includes(ROLE_MANAGER)) {
                  filteredOffices = user.offices
                    .filter((office) => roles.includes(office.role as Role))
                    .map((office) => office.eaOfficeId);
                } else {
                  filteredOffices = user.offices.map(
                    (office) => office.eaOfficeId
                  );
                }

                officeFormControl.setValue(filteredOffices);
              } else {
                officeFormControl.setValue(value);
              }
            } else if (availability === "medium") {
              if (value.length === 0) {
                officeFormControl.setValue(
                  user.offices.map((office) => office.eaOfficeId)
                );
              } else {
                officeFormControl.setValue(value);
              }
            }
          } else {
            officeFormControl.setValue(value);
          }
        })
      )
      .subscribe();
  }

  officeRequired(
    availability: AvailabilityModes,
    multi: boolean,
    setDefaults: boolean
  ) {
    return this.store.pipe(select(getRoles)).pipe(
      map((roles) => {
        if (
          !!multi &&
          availability !== "liberal" &&
          !roles.includes(ROLE_ADMIN) &&
          !!setDefaults
        ) {
          if (
            (availability === "restrictive" && !roles.includes(ROLE_ADMIN)) ||
            availability === "medium"
          ) {
            return true;
          }
        } else {
          return false;
        }
      })
    );
  }

  handleEmployeeValueEntered(
    value: unknown,
    employeeFormControl: FormControl,
    teamControl,
    teamEmployee: string
  ) {
    if (teamEmployee === "employees" || !value) {
      employeeFormControl.setValue(value);
      return;
    }

    teamControl.setValue(value);
  }

  getEmployeesFromTeam(teamIds: string[], employeeFormControl: FormControl) {
    this.teamService.entityMap$
      .pipe(
        map((entities) =>
          teamIds.map((id) => entities[id]).filter((team) => team)
        ),
        map((teams) => {
          const agentIds = teams?.flatMap((team) =>
            team.agents.map((agent) => agent.id)
          );
          return new Set([...agentIds]);
        }),
        first()
      )
      .subscribe((ids) => {
        employeeFormControl.setValue([...ids]);
      });
  }
}
