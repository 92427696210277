import { Route } from "@angular/router";
import { LeadResultOptionsGuard } from "@app/sidebar/guards/lead-result-options-guard.service";
import { LeadResultOptionsCreateComponent } from "@app/sidebar/lead-result-options/containers/lead-result-options-create/lead-result-options-create.component";
import { LeadResultOptionsEditComponent } from "@app/sidebar/lead-result-options/containers/lead-result-options-edit/lead-result-options-edit.component";

export const sidebarLeadResultOptionsRoutes: Route = {
  path: "lead-result-options",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: LeadResultOptionsCreateComponent,
      canActivate: [LeadResultOptionsGuard],
    },
    {
      path: ":id/edit",
      component: LeadResultOptionsEditComponent,
      canActivate: [LeadResultOptionsGuard],
    },
  ],
};
