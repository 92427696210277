import * as actions from "@app/lists/lists-search-profiles/ngrx/lists-search-profiles.actions";
import { API_SHOWINGS_DEFAULT_LIMIT } from "@app/lists/lists-showings/utils/lists-showings-defaults";
import { PaginationListDTO } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface SearchProfileList {
  contactFamilyName: string;
  contactFirstName: string;
  contactId: string;
  createdByEmployeeFullName: string;
  insertedDate: string;
  isNewProduction: string;
  numberOfAlerts: number;
  searchProfileId: string;
  tenureType: string;
  title: string;
  updatedByEmployeeFullName: string;
  updatedDate: string;
}

export interface ListsSearchProfilesState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  loadingMore: boolean;
  rows: SearchProfileList[];
  selectingAllSearchProfilesLoading: boolean;
}

export const initialState: ListsSearchProfilesState = {
  loaded: false,
  loading: false,
  loadingMore: false,
  limit: API_SHOWINGS_DEFAULT_LIMIT,
  offset: 0,
  total: 0,
  rows: [],
  selectingAllSearchProfilesLoading: false,
};

export function listsSearchProfilesReducer(
  state: ListsSearchProfilesState,
  action: Action
): ListsSearchProfilesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getSearchProfilesRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    actions.getSearchProfilesSuccess,
    (state, { limit, offset, total, rows }) => ({
      ...state,
      loading: false,
      loaded: true,
      rows,
      total,
      limit,
      offset,
    })
  ),
  on(actions.getSearchProfilesFailed, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    rows: initialState.rows,
  })),
  on(actions.getMoreSearchProfilesRequest, (state) => ({
    ...state,
    loadingMore: true,
  })),
  on(
    actions.getMoreSearchProfilesSuccess,
    (state, { offset, total, rows, limit }) => ({
      ...state,
      loadingMore: false,
      loaded: true,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    })
  ),
  on(actions.getMoreSearchProfilesFailed, (state) => ({
    ...state,
    loadingMore: initialState.loadingMore,
    loaded: true,
  })),
  on(actions.selectAllSearchProfilesRequest, (state) => ({
    ...state,
    selectingAllSearchProfilesLoading: true,
  })),
  on(actions.selectAllSearchProfilesSuccess, (state, { payload }) => ({
    ...state,
    ...{ ...payload, rows: payload.rows },
    selectingAllSearchProfilesLoading: false,
  })),
  on(actions.reset, () => initialState)
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.searchProfiles
);

export const getListsSearchProfilesLoaded = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.loaded
);

export const getListsSearchProfilesLoading = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.loading
);

export const getListsSearchProfilesLoadingMore = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.loadingMore
);

export const getListsSearchProfilesRows = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.rows
);

export const getListsSearchProfilesTotal = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.total
);

export const getListsSearchProfilesLimit = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.limit
);

export const getSelectingAllSearchProfilesLoading = createSelector(
  getState,
  (state: ListsSearchProfilesState) => state.selectingAllSearchProfilesLoading
);
