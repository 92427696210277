import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "@app/core/services/auth/auth.service";
import { Observable } from "rxjs";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (!request.headers.has("Authorization") && token) {
      request = request.clone({
        url: this.trimTrailingSlash(request.url),
        setHeaders: {
          Authorization: `Bearer ${this.authService.getToken()}`,
        },
      });
    }

    return next.handle(request);
  }

  private trimTrailingSlash(url: string): string {
    if (url.endsWith("/")) {
      return url.substring(0, url.length - 1);
    }
    return url;
  }
}
