import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { LogoutComponent } from "@app/login/logout.component";
import { LoginEffects } from "@app/login/ngrx/login.effects";
import { SsoFailedComponent } from "@app/login/sso-failed/sso-failed.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { EffectsModule } from "@ngrx/effects";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { CompatibilityCheckComponent } from "./compatibility-check/compatibility-check.component";
import { ForgotPasswordFormComponent } from "./forgot-password-form/forgot-password-form.component";
import { ForgotPasswordEffects } from "./forgot-password-form/forgot-password/forgot-password.effects";
import { Step1Component } from "./forgot-password-form/forgot-password/step1/step1.component";
import { Step2Component } from "./forgot-password-form/forgot-password/step2/step2.component";
import { Step3Component } from "./forgot-password-form/forgot-password/step3/step3.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { LoginRoutingModule } from "./login-routing.module";
import { LoginComponent } from "./login.component";
import { SelectOfficeItemComponent } from "./select-office-item/select-office-item.component";
import { SelectOfficeComponent } from "./select-office/select-office.component";
import { SelectOfficeEffects } from "./select-office/select-office.effects";
import { TokenBouncerComponent } from "./token-bnc/token-bouncer/token-bouncer.component";

const loginEffects = [SelectOfficeEffects, ForgotPasswordEffects, LoginEffects];

@NgModule({
  imports: [
    EffectsModule.forFeature(loginEffects),
    CommonModule,
    TranslateModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    UiComponentsModule,
    MatCheckboxModule,
  ],
  declarations: [
    LoginComponent,
    CompatibilityCheckComponent,
    SelectOfficeComponent,
    LoginFormComponent,
    Step1Component,
    Step2Component,
    Step3Component,
    ForgotPasswordFormComponent,
    SelectOfficeItemComponent,
    TokenBouncerComponent,
    LogoutComponent,
    SsoFailedComponent,
  ],
  exports: [LoginFormComponent],
})
export class LoginModule {}
