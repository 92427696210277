import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreateLeadModule } from "@app/sidebar/create-lead/create-lead.module";
import { CreateObjectModule } from "@app/sidebar/create-object/create-object.module";
import { EikaModule } from "@app/sidebar/eika/eika.module";
import { ExternalProviderModule } from "@app/sidebar/external-provider/external-provider.module";
import { LeadResultOptionsFormComponent } from "@app/sidebar/lead-result-options/components/lead-result-options-form/lead-result-options-form.component";
import { LeadResultOptionsCreateComponent } from "@app/sidebar/lead-result-options/containers/lead-result-options-create/lead-result-options-create.component";
import { LeadResultOptionsEditComponent } from "@app/sidebar/lead-result-options/containers/lead-result-options-edit/lead-result-options-edit.component";
import { PotentialBuyerModule } from "@app/sidebar/potential-buyer/potential-buyer.module";
import { SalesMeetingService } from "@app/sidebar/sales-meeting/sales-meeting.service";
import { SearchProfileModule } from "@app/sidebar/search-profile/search-profile.module";
import { SupportModule } from "@app/sidebar/support/support.module";
import { TranslateModule } from "@ngx-translate/core";
import { UiComponentsModule } from "../shared/modules/ui-components/ui-components.module";
import { CallingListsModule } from "./calling-lists/calling-lists.module";
import { CompetitionsModule } from "./competitions/competitions.module";
import { SidebarCreateMenuComponent } from "./components/create-menu/create-menu.component";
import { SidebarTabsComponent } from "./components/tabs/tabs.component";
import { CreateContactModule } from "./contacts/create-contact.module";
import { CreateFollowUpModule } from "./create-follow-up/create-follow-up.module";
import { EmployeesModule } from "./employees/employees.module";
import { ExternalTipsModule } from "./external-tips/external-tips.module";
import { LeadChannelsFormComponent } from "./lead-channels/components/lead-channels-form/lead-channels-form.component";
import { LeadChannelsCreateComponent } from "./lead-channels/containers/lead-channels-create/lead-channels-create.component";
import { LeadChannelsEditComponent } from "./lead-channels/containers/lead-channels-edit/lead-channels-edit.component";
import { LeadTemplatesFormComponent } from "./lead-templates/components/lead-templates-form/lead-templates-form.component";
import { LeadTemplatesCreateComponent } from "./lead-templates/containers/lead-templates-create/lead-templates-create.component";
import { LeadTemplatesEditComponent } from "./lead-templates/containers/lead-templates-edit/lead-templates-edit.component";
import { MessageTemplatesFormComponent } from "./message-templates/components/message-templates-form/message-templates-form.component";
import { MessageTemplatesCreateComponent } from "./message-templates/containers/message-templates-create/message-templates-create.component";
import { MessageTemplatesEditComponent } from "./message-templates/containers/message-templates-edit/message-templates-edit.component";
import { ProfileModule } from "./profile/profile.module";
import { ResidenceModule } from "./residence/residence.module";
import { SalesMeetingModule } from "./sales-meeting/sales-meeting.module";
import { SendMessageModule } from "./send-message/send-message.module";
import { SidebarSharedModule } from "./shared/sidebar-shared.module";
import { SidebarComponent } from "./sidebar.component";
import { SidebarService } from "./sidebar.service";
import { TasksDefaultService } from "./tasks/tasks-default/tasks-default.service";
import { TasksModule } from "./tasks/tasks.module";
import { SendTipsModule } from "./tips/send-tips.module";
import { ValueMonitorFormComponent } from "./value-monitor/components/value-monitor-form/value-monitor-form.component";
import { ValueMonitorCreateComponent } from "./value-monitor/containers/value-monitor-create/value-monitor-create.component";
import { ValueMonitorEditComponent } from "./value-monitor/containers/value-monitor-edit/value-monitor-edit.component";

const components = [
  SidebarComponent,
  SidebarCreateMenuComponent,
  SidebarTabsComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    CompetitionsModule,
    CreateContactModule,
    CreateFollowUpModule,
    ResidenceModule,
    ProfileModule,
    SendTipsModule,
    CreateLeadModule,
    UtilModule,
    SalesMeetingModule,
    SidebarSharedModule,
    TasksModule,
    SendMessageModule,
    UiComponentsModule,
    EmployeesModule,
    CallingListsModule,
    ExternalTipsModule,
    PotentialBuyerModule,
    ExternalProviderModule,
    SearchProfileModule,
    SupportModule,
    EikaModule,
    CreateObjectModule,
    ReactiveFormsModule,
    FormComponentsModule,
    MatSlideToggleModule,
    ErpFormElementsModule,
  ],
  exports: [SidebarComponent, SidebarTabsComponent],
  declarations: [
    ...components,
    MessageTemplatesEditComponent,
    MessageTemplatesCreateComponent,
    MessageTemplatesFormComponent,
    LeadChannelsCreateComponent,
    LeadChannelsEditComponent,
    LeadChannelsFormComponent,
    LeadTemplatesFormComponent,
    LeadTemplatesCreateComponent,
    LeadTemplatesEditComponent,
    LeadResultOptionsFormComponent,
    LeadResultOptionsCreateComponent,
    LeadResultOptionsEditComponent,
    ValueMonitorFormComponent,
    ValueMonitorCreateComponent,
    ValueMonitorEditComponent,
  ],
  providers: [SalesMeetingService],
})
export class SidebarModule {
  static forRoot(): ModuleWithProviders<SidebarModule> {
    return {
      ngModule: SidebarModule,
      providers: [SidebarService, TasksDefaultService],
    };
  }
}
