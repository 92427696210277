import { Employee } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as searchActions from "./search.actions";

export interface SearchState {
  showOverlay: boolean;
  keyword: string;
  latestSearches: string;
  loading: boolean;
  loaded: boolean;
  hits: {
    contactHits: number;
    objectsHits: number;
    leadsHits: number;
    employeesHits: number;
  };
  results: {
    contacts: any[];
    objects: any[];
    leads: any[];
    employees: Employee[];
  };
  externalProviderLinks: any[];
  externalProviderLinksLoading: boolean;
}

export const initialState: SearchState = {
  showOverlay: false,
  keyword: "",
  latestSearches: "",
  loaded: false,
  loading: false,
  hits: {
    contactHits: 0,
    objectsHits: 0,
    leadsHits: 0,
    employeesHits: 0,
  },
  results: {
    contacts: [],
    objects: [],
    leads: [],
    employees: [],
  },
  externalProviderLinks: [],
  externalProviderLinksLoading: false,
};

export function searchReducer(state: SearchState, action: Action): SearchState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(searchActions.show, (state) => ({
    ...state,
    showOverlay: true,
    results: {
      contacts: [],
      objects: [],
      leads: [],
      employees: [],
    },
    loaded: false,
    loading: false,
  })),
  on(searchActions.hide, () => ({ ...initialState })),
  on(searchActions.getLatestSearchesSuccess, (state, { latestSearches }) => ({
    ...state,
    latestSearches,
  })),
  on(searchActions.loadRequest, (state, { parameters }) => ({
    ...state,
    keyword: parameters.keyword,
    loading: true,
    loaded: false,
  })),
  on(searchActions.loadSuccess, (state, { result }) => {
    const { contacts, leads, employees, locations } = result;
    return {
      ...state,
      results: {
        contacts: contacts["entities"] || [],
        leads: leads["entities"] || [],
        employees: employees["entities"] || [],
        objects: locations["entities"] || [],
      },
      hits: {
        contactHits: contacts["found"],
        objectsHits: locations["found"],
        leadsHits: leads["found"],
        employeesHits: employees["found"],
      },
      loading: false,
      loaded: true,
    };
  }),
  on(searchActions.getExternalLinks, (state) => ({
    ...state,
    externalProviderLinksLoading: true,
  })),
  on(searchActions.getExternalLinksFailed, (state) => ({
    ...state,
    externalProviderLinksLoading: false,
  })),
  on(searchActions.getExternalLinksSuccess, (state, { externalLinks }) => ({
    ...state,
    externalProviderLinks: Object.values(externalLinks),
    externalProviderLinksLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SearchState>("search");

export const getExternalProviderLinks = createSelector(
  selectFeature,
  (state: SearchState) => state.externalProviderLinks
);

export const getExternalProviderLinksLoading = createSelector(
  selectFeature,
  (state: SearchState) => state.externalProviderLinksLoading
);
