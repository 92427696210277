import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_CONTACTS_EDIT_URL } from "@app/shared/utils/sidebar-tab-utils";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as i18nISOCountries from "i18n-iso-countries";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-company-details-widget",
  templateUrl: "./company-details-widget.component.html",
  styleUrls: ["./company-details-widget.component.scss"],
})
export class CompanyDetailsWidgetComponent implements OnInit, OnDestroy {
  @Input() contactId: string;

  loading$: Observable<boolean>;
  contact$: Observable<Contact>;
  latestContactedDate$: Observable<string>;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.loading$ = this.store.pipe(select(fromContact.getLoading));
    this.contact$ = this.store.pipe(select(fromContact.getContact));
    this.latestContactedDate$ = this.store.pipe(
      select(fromContact.getLatestContactedDate)
    );
  }

  getGoogleMapsUrl(address: string) {
    return "https://www.google.com/maps/place/" + encodeURIComponent(address);
  }

  getPhoneNumberTooltip(contact: Contact, isMsisdn: boolean): string {
    const countryCode = isMsisdn
      ? contact.msisdnCountry
      : contact.phoneNumberCountry;
    const number = isMsisdn ? contact.msisdn : contact.phoneNumber;

    if (!countryCode) {
      return null;
    }

    return this.translateService.instant("click_to_call", {
      country: i18nISOCountries.getName(
        countryCode,
        this.translateService.currentLang
      ),
      number: "+" + number,
    });
  }

  editContact() {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_CONTACTS_EDIT_URL(this.contactId) } },
        ],
      })
    );
  }
}
