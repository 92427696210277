<div class="widget-header">
  <h3>
    <app-icon size="large" name="wave-pulse"></app-icon>&nbsp;<span>{{
      "activity" | translate
    }}</span>
  </h3>
</div>

<div class="widget-container">
  <div
    *ngIf="highestBid$ | async as highestBid; else nodata"
    class="text-center"
  >
    <span class="meta">{{ "highest_bid" | translate }}:</span>
    <br *ngIf="isMobile$ | async" />
    <span class="main-text">
      {{ highestBid | qNumber }} {{ currency$ | async | uppercase }}
    </span>
  </div>
  <section class="meta">
    <ng-container *ngIf="property$ | async as property">
      <span *ngIf="!!property['soldPrice']">
        {{ "sold_price" | translate }}: <br *ngIf="isMobile$ | async" />
        <span
          >{{ property["soldPrice"] | qNumber }}
          {{ currency$ | async | uppercase }}</span
        >
      </span>
    </ng-container>
  </section>
  <div
    *ngIf="contactSummary$ | async as contactSummary"
    class="widget-container"
    style="margin-top: 2rem"
  >
    <div>
      <span class="meta">{{ "potentialbuyer" | translate }}:</span>
      <span class="main-text">{{ contactSummary?.total }}</span>
    </div>
    <div class="meta" *ngIf="contactSummary?.total > 0">
      <span style="text-transform: capitalize"
        >{{ "calling_list_contacted" | translate }}:
      </span>
      <span *ngIf="contactSummary?.contacted >= 0">
        {{ contactSummary?.contacted }} </span
      >&nbsp;
    </div>
  </div>
</div>

<ng-template #nodata>
  <span>{{ "no_bids" | translate }}</span>
</ng-template>
