import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-contact-status-item",
  templateUrl: "./contact-status-item.component.html",
  styleUrls: ["./contact-status-item.component.scss"],
})
export class ContactStatusItemComponent {
  @Input() clickable = false;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() click: EventEmitter<void> = new EventEmitter<void>();
}
