import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { UrlService } from "@app/core/services/url/url.service";
import { QObject } from "@app/models";
import { addBackLink } from "@app/contacts/contact.actions";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { ShowingObject } from "@app/showings/models";
import { ShowingsSubPageService } from "@app/showings/showings-sub-page.service";
import { Store } from "@ngrx/store";
import { filter, map, Observable, Subject, takeUntil } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";

@Component({
  selector: "app-property-summary-dialog",
  templateUrl: "./property-summary-dialog.component.html",
  styleUrls: ["./property-summary-dialog.component.scss"],
})
export class PropertySummaryDialogComponent implements OnInit, OnDestroy {
  object$: Observable<QObject & ShowingObject>;
  subPages$: Observable<SubPage[]>;

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    public dialogRef: MatDialogRef<PropertySummaryDialogComponent>,
    private urlService: UrlService,
    private router: Router,
    private subPagesService: ShowingsSubPageService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.object$ = this.objectService.entityMap$.pipe(
      map((em) => em[this.data.eaOid]),
      filter((it) => !!it),
      takeUntil(this.unsubscribe$)
    );
    this.initSubPages();
  }

  initSubPages(): void {
    this.subPages$ = this.subPagesService.subPages$;
    this.subPagesService.loadSubPages(this.data.eaOid);
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  goToProperty(property: QObject, additionalRoute?: string) {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
    this.closeModal();
    const route = this.createRoute(property, additionalRoute);
    this.store.dispatch(go({ path: route }));
  }

  openInNewTab(property: QObject, additionalRoute?: string) {
    const route = this.createRoute(property, additionalRoute);
    const url = this.urlService.buildUrlFromRouterCommands(route);
    openLinkInNewTab(url);
  }

  private createRoute(property: QObject, additionalRoute: string) {
    const route: string[] = ["/crm"];
    if (property.isExistingHome) {
      this.store.dispatch(
        residencesActions.setResidence({
          residence: property,
        })
      );
      route.push("contacts", property?.contacts[0]?.contactId, "residences");
    } else {
      route.push("showings", this.data.eaOid);
    }

    if (!!additionalRoute) {
      route.push(additionalRoute);
    }

    return route;
  }
}
