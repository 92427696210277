<div class="q-select" [class.margin-top]="!!label" [class.inline]="inline">
  <label *ngIf="!!label" [id]="'listbox-' + label" class="q-select__label">
    {{ label | translate }}
  </label>
  <div
    class="q-select__container"
    (clickOutside)="onClickOutside()"
    [attachOutsideOnClick]="true"
  >
    <button
      type="button"
      aria-haspopup="listbox"
      aria-expanded="true"
      [attr.aria-labelledby]="'listbox-' + label"
      class="q-select__toggle"
      [class.disabled]="disabled"
      [disabled]="disabled"
      (click)="toggle()"
    >
      <span
        class="q-select__text"
        [class.q-select__placeholder]="!getSelectedName()"
      >
        {{ getSelectedName() || placeholder | translate }}
      </span>
      <span class="q-select__icon">
        <app-icon name="chevron-down" type="regular"></app-icon>
      </span>
    </button>
    <div *ngIf="show" class="q-select__dropdown">
      <input
        type="text"
        [placeholder]="('search' | translate) + '...'"
        [formControl]="searchText"
        class="q-select__search"
      />
      <ul
        #listbox
        tabindex="-1"
        role="listbox"
        [attr.aria-labelledby]="'listbox-' + label"
        class="q-select__listbox"
      >
        <ng-container
          *ngIf="(searchText$ | async | find : options)?.length > 0; else empty"
        >
          <ng-container
            *ngFor="let option of searchText$ | async | find : options"
          >
            <li
              class="q-select__listbox-header"
              *ngIf="option.value === 'group-header'; else item"
            >
              {{ option.label | translate }}
            </li>
            <ng-template #item>
              <li
                role="option"
                class="q-select__listbox-item"
                [class]="{
                  'q-select__listbox-item--selected': isSelected(option.value)
                }"
                (click)="onClick(option.value)"
              >
                {{ option.label | translate }}
                <span class="q-select__icon" *ngIf="isSelected(option.value)">
                  <app-icon name="check" type="regular"></app-icon>
                </span>
              </li>
            </ng-template>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<ng-template #empty>
  <li class="q-select__listbox-empty">{{ "its_empty_here" | translate }}.</li>
</ng-template>
