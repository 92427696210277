import { Component } from "@angular/core";
import { CREATE_FOLLOW_UP } from "@app/shared/utils/tab-types";

@Component({
  selector: "create-follow-up",
  template: `
    <div>
      <div class="header-wrapper">
        <sidebar-header
          [type]="tabType"
          [label]="tabType | translate"
        ></sidebar-header>
      </div>
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ["../sidebar.component.common.scss"],
})
export class CreateFollowUpComponent {
  tabType = CREATE_FOLLOW_UP;
}
