import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Employee, Office } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SupportSidebarFeature } from "@app/shared/config/models/support-sidebar-feature";
import * as features from "@app/shared/config/utils/features";
import * as fromUser from "@app/shared/user";
import * as fromMessageUtils from "@app/shared/utils/message-utils";
import * as tabTypes from "@app/shared/utils/tab-types";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as supportActions from "@app/sidebar/support/ngrx/support.actions";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { SidebarTab } from "../../../models/sidebar-tab";
import { closeTab } from "../../../ngrx/sidebar.actions";
import * as fromSidebar from "../../../ngrx/sidebar.reducer";

@Component({
  selector: "app-support",
  templateUrl: "./support.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./support.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportComponent implements OnInit, OnDestroy, ConnectableTab {
  form: FormGroup;
  proxy$ = new Subject<any>();
  tabType = tabTypes.SUPPORT;
  tab$: Observable<SidebarTab>;
  destroy$ = new Subject<void>();
  supportFeature$: Observable<SupportSidebarFeature>;
  sendToModuleId$: Observable<string>;
  employee$: Observable<Employee>;
  office$: Observable<Office>;
  roles$: Observable<string[]>;
  customerName$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.connectTab();
    this.registerObserverToFormValueChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.supportFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.SUPPORT))
    );
    this.sendToModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.roles$ = this.store.pipe(select(fromUser.getRoles));
    this.customerName$ = this.store.pipe(select(fromConfig.getCustomerName));
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue()),
        takeUntil(this.destroy$)
      )
      .subscribe(this.proxy$);
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  buildForm(): void {
    this.form = this.fb.group({
      message: ["", Validators.required],
    });
  }

  handleSendMessage(): void {
    observableCombineLatest([
      this.supportFeature$,
      this.sendToModuleId$,
      this.employee$,
      this.office$,
      this.roles$,
      this.customerName$,
    ])
      .pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$)
      )
      .subscribe(
        ([
          supportFeature,
          sendToModuleId,
          employee,
          office,
          roles,
          customerName,
        ]) => {
          const sendMessageParams = {
            dataSet: this.formatDataSet(employee, office, roles, customerName),
            origin: "crm",
            recipients: supportFeature.supportMail,
            sendToModule: sendToModuleId,
            templateId: supportFeature.templateId,
          };

          this.store.dispatch(
            supportActions.sendMessageToSupportRequest({ sendMessageParams })
          );
        }
      );
  }

  formatDataSet(
    employee: Employee,
    office: Office,
    roles: string[],
    customerName: string
  ): string {
    const dataSet = {
      SUBJECT: this.translateService.instant("support_message_subject_line"),
      LABEL_FROM: this.translateService.instant("from"),
      TITLE: customerName,
      LABEL_USERNAME: this.translateService.instant("username"),
      EMPLOYEEEMAIL: employee.employeeEmail,
      ROLE: roles.toString(),
      LABEL_BROKER: this.translateService.instant("broker"),
      EMPLOYEEFULLNAME: employee.employeeFullName,
      EAEMPLOYEEID: employee.eaEmployeeId,
      LABEL_OFFICE: this.translateService.instant("office"),
      OFFICENAME: office.officeName,
      EAOFFICEID: office.eaOfficeId,
      ENVIRONMENT: navigator.userAgent,
      LABEL_MESSAGE: this.translateService.instant("message"),
      MESSAGE: this.form.get("message").value.replace(/\r?\n/g, "<br />"),
      SIGNATURE: this.translateService.instant("crm_mail_signature"),
      FOOTER_DATE: moment(),
      VERSION: "2.0",
      fromEmail: employee.employeeEmail,
      fromName: employee.employeeFullName,
    };

    return fromMessageUtils.transformObjectToMessageDataSet(dataSet);
  }
}
