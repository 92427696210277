import { Pipe, PipeTransform } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import * as fromConfig from "../../../config/config.reducer";

@Pipe({
  name: "removeOfficePrefix",
})
export class RemoveOfficePrefixPipe implements PipeTransform {
  constructor(private store: Store<AppState>) {}

  transform(value: any): Observable<any> {
    return this.store.pipe(
      select(fromConfig.getOfficeNamePrefix),
      map((prefix) =>
        value && value !== prefix ? value.replace(prefix, "").trim() : value
      )
    );
  }
}
