export const QuillConfiguration = (toolbarEnabled = true, hasColor = false) => {
  if (!toolbarEnabled) {
    return { toolbar: false };
  }

  return hasColor
    ? {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "super" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          ["link"],
          ["clean"],
        ],
      }
    : {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "super" }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["link"],
          ["clean"],
        ],
      };
};
