import { Contact, QObject } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as residenceActions from "./residence.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface ResidenceState {
  contact: Contact;
  residences: QObject[];
  residence: QObject;
  residenceLoading: boolean;
  processing: boolean;
}

export const initialState: ResidenceState = {
  contact: null,
  residences: [],
  residence: null,
  residenceLoading: false,
  processing: false,
};

export function residenceReducer(
  state: ResidenceState,
  action: Action
): ResidenceState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(residenceActions.getResidenceContactSuccess, (state, { contact }) => ({
    ...state,
    contact,
  })),
  on(residenceActions.getResidenceContactFailed, (state) => ({
    ...state,
    contact: initialState.contact,
  })),
  on(residenceActions.getContactResidencesSuccess, (state, { residences }) => ({
    ...state,
    residences,
  })),
  on(residenceActions.getContactResidencesFailed, (state) => ({
    ...state,
    residences: initialState.residences,
  })),
  on(residenceActions.getResidenceRequest, (state) => ({
    ...state,
    residenceLoading: true,
    processing: true,
  })),
  on(residenceActions.getResidenceSuccess, (state, { residence }) => ({
    ...state,
    residence,
    residenceLoading: false,
    processing: false,
  })),
  on(residenceActions.getResidenceFailed, (state) => ({
    ...state,
    residenceLoading: false,
    residence: initialState.residence,
  })),
  on(
    residenceActions.createResidenceRequest,
    residenceActions.updateResidenceRequest,
    (state) => ({ ...state, processing: true })
  ),
  on(
    residenceActions.createResidenceFailed,
    residenceActions.createResidenceSuccess,
    residenceActions.updateResidenceFailed,
    residenceActions.updateResidenceSuccess,
    (state) => ({ ...state, processing: false })
  ),
  on(residenceActions.setResidence, (state, { residence }) => ({
    ...state,
    residence,
  })),
  on(residenceActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.residence
);

export const getSidebarResidenceContact = createSelector(
  getState,
  (state: ResidenceState) => state.contact
);

export const getSidebarResidences = createSelector(
  getState,
  (state: ResidenceState) => state.residences
);

export const getSidebarResidence = createSelector(
  getState,
  (state: ResidenceState) => state.residence
);

export const getSidebarResidenceProcessing = createSelector(
  getState,
  (state: ResidenceState) => state.processing
);
