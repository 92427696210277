import * as employeeActions from "./employees-create.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface EmploymentCreateEmployeeState {
  processing: boolean;
}

export const initialState: EmploymentCreateEmployeeState = {
  processing: false,
};

export function employmentCreateEmployeeReducer(
  state: EmploymentCreateEmployeeState,
  action: Action
): EmploymentCreateEmployeeState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(employeeActions.createEmployeeRequest, () => ({ processing: true })),
  on(
    employeeActions.createEmployeeSuccess,
    employeeActions.createEmployeeFailed,
    () => ({ processing: false })
  ),
  on(employeeActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.employmentCreateEmployee
);

export const getCreateEmployeeProcessing = createSelector(
  getState,
  (state: EmploymentCreateEmployeeState) => state.processing
);
