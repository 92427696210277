import { LeadResult } from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.reducer";
import { createAction, props } from "@ngrx/store";

export const fetchStatisticsRequest = createAction(
  "[Widget Lead Result] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Widget Lead Result] FETCH_STATISTICS_SUCCESS",
  props<{ leadResult: LeadResult }>()
);

export const fetchStatisticsFail = createAction(
  "[Widget Lead Result] FETCH_STATISTICS_FAIL"
);

export const fetchTaskTypesRequest = createAction(
  "[Widget Lead Result] FETCH_TASK_TYPES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchTaskTypesSuccess = createAction(
  "[Widget Lead Result] FETCH_TASK_TYPES_SUCCESS",
  props<{ taskTypes: { typeName: string; eaTaskTypeId: number }[] }>()
);

export const fetchTaskTypesFail = createAction(
  "[Widget Lead Result] FETCH_TASK_TYPES_FAIL"
);
