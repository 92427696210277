import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { fromEvent as observableFromEvent, map, Observable } from "rxjs";

@Component({
  selector: "search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @ViewChild("searchInput", { static: true })
  searchInput: ElementRef;
  @Input() autofocus: boolean;
  @Input() readonly: boolean;
  @Input() placeholder: string;
  @Input() overlayActive: boolean;
  @Input() value: string;
  @Output() keywordChange = new EventEmitter();
  @Output() inputClicked = new EventEmitter();
  loading$: Observable<boolean>;

  ngOnInit() {
    const { nativeElement } = this.searchInput;

    this.keywordChange.emit(observableFromEvent(nativeElement, "keyup"));

    if (this.autofocus) {
      nativeElement.focus();
      this.value = "";
    }

    this.loading$ = this.store
      .select((state) => state.search)
      .pipe(map((search) => search.loading));
  }

  onClick() {
    this.inputClicked.emit({});
  }

  constructor(private store: Store<AppState>) {}
}
