import { ActivatedRouteSnapshot } from "@angular/router";

export const openLinkInNewTab = (url: string): void => {
  const a = document.createElement("a");
  a.href = url;
  a.target = "_blank";
  a.rel = "noopener noreferrer";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getParamsFromAllOutlets = (route: ActivatedRouteSnapshot) => ({
  ...route.params,
  ...route.children.reduce(
    (acc, child) => ({ ...getParamsFromAllOutlets(child), ...acc }),
    {}
  ),
});
