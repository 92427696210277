import {
  BRAND_DANGER_COLOR,
  BRAND_SUCCESS_COLOR,
  BRAND_WARNING_COLOR,
} from "@app/shared/utils/colors";
import * as Highcharts from "highcharts";
import { SeriesOptionsType } from "highcharts";

function formatValue(y) {
  if (y >= 10000) {
    return `${Math.round(y / 1000)}k`;
  } else {
    return y;
  }
}

export const solidGauge = (min, max, value, hideMax?): Highcharts.Options => {
  let format;

  if (hideMax) {
    format = `
      <div style="text-align:center">
        <span style="font-size:12px; font-weight: 500;font-family:'Roboto',sans-serif;">
          {y}
        </span>
      </div>
    `;
  } else {
    format = `
      <div style="text-align:center">
        <p style="font-size:12px; margin: 0; font-weight: 500;font-family:'Roboto',sans-serif;">
          {y}
        </p>
        <p style="font-size:11px; margin: 0; font-weight: 400;font-family:'Roboto',sans-serif;">
          / ${formatValue(max)}
        </p>
      </div>
    `;
  }
  const seriesData: SeriesOptionsType = {
    data: [value],
    dataLabels: {
      format: format,
    },
    type: "solidgauge",
  };
  return {
    chart: {
      type: "solidgauge",
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    pane: {
      center: ["50%", "85%"],
      size: "150%",
      startAngle: -90,
      endAngle: 90,
      background: [
        {
          backgroundColor: "#f9f9f9",
          borderWidth: 0,
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      ],
    },
    yAxis: {
      minorTickWidth: 0,
      stops: [
        [0.0, BRAND_DANGER_COLOR],
        [0.33, BRAND_DANGER_COLOR],
        [0.34, BRAND_WARNING_COLOR],
        [0.66, BRAND_WARNING_COLOR],
        [0.67, BRAND_SUCCESS_COLOR],
        [1.0, BRAND_SUCCESS_COLOR],
      ],
      tickLength: 0,
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      tickPositioner: () => {
        if (min === 0 && max === 0) {
          return;
        }
        return [min, max];
      },
      labels: {
        enabled: false,
        y: 16,
      },
      min: min,
      max: max,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },

    series: [seriesData],
  };
};

export const npsGauge = (min, max, value, total, totalStr) => {
  return {
    chart: {
      type: "solidgauge",
    },
    accessibility: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    pane: {
      center: ["50%", "85%"],
      size: "150%",
      startAngle: -90,
      endAngle: 90,
      background: {
        backgroundColor: "#f9f9f9",
        borderWidth: 0,
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    yAxis: {
      minorTickWidth: 0,
      stops: [
        [0.0, BRAND_DANGER_COLOR],
        [0.49, BRAND_DANGER_COLOR],
        [0.5, BRAND_WARNING_COLOR],
        [0.69, BRAND_WARNING_COLOR],
        [0.7, BRAND_SUCCESS_COLOR],
        [1.0, BRAND_SUCCESS_COLOR],
      ],
      tickLength: 0,
      lineWidth: 0,
      minorTickInterval: null,
      tickAmount: 0,
      tickPositioner: () => {
        if (min === 0 && max === 0) {
          return;
        }
        return [min, max];
      },
      labels: {
        enabled: false,
        y: 16,
      },
      min: min,
      max: max,
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: 5,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    series: [
      {
        data: [value],
        dataLabels: {
          format: `
              <div style="text-align:center">
              <div style="color:#666;font-size:12px; font-weight: 500;font-family:'Roboto',sans-serif;">
                  (${total} ${totalStr})
                </div>
                <div style="font-size:14px; font-weight: 500;font-family:'Roboto',sans-serif;">
                  {y}
                </div>
              </div>
            `,
        },
      },
    ],
  };
};
