import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Contact } from "@app/models";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import { debounceTime, Observable, Subscription, take } from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import { ContactFormComponent } from "../contact-form/contact-form.component";

@Component({
  selector: "create-contact-simple",
  templateUrl: "./create-contact-simple.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-contact-simple.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateContactSimpleComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() type: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuedroContactSelected = new EventEmitter<Contact>();
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;

  tab$: Observable<SidebarTab>;
  valueChangesSubscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.tab$ = this.store.pipe(select(getTab(this.type)));
  }

  ngAfterViewInit() {
    this.tab$.pipe(take(1)).subscribe((tab) => {
      if (tab.dirty) {
        this.contactForm.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.type,
            value: this.contactForm.form.value,
          })
        );
      }
      this.startValueChangesStream();
    });
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  startValueChangesStream() {
    this.valueChangesSubscription = this.contactForm.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setTabValue({ tabType: this.type, value: formValues })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.type }));
  }

  handleSubmit(valid: boolean): void {
    if (valid) {
      this.contactForm
        .getCreateContactRequestParams()
        .pipe(take(1))
        .subscribe(({ params, source }) =>
          this.onSubmit.emit({ params, source })
        );
    }
  }

  handleQuedroContactSelected(contact: Contact) {
    this.onQuedroContactSelected.emit(contact);
  }
}
