import { Component } from "@angular/core";
import { CalendarMonthViewComponent } from "angular-calendar";
import moment from "moment";

@Component({
  selector: "custom-calendar-month-view",
  template: `
    <div class="cal-month-view">
      <mwl-calendar-month-view-header
        [days]="columnHeaders"
        [locale]="locale"
        [customTemplate]="headerTemplate"
      >
      </mwl-calendar-month-view-header>
      <div class="cal-days">
        <div *ngFor="let rowIndex of view.rowOffsets">
          <div class="cal-cell-row">
            <div class="cal-cell cal-day-cell flex-center">
              <span
                class="cal-week-number flex-center"
                [class.active]="isWeekActive(view.days[rowIndex].date)"
              >
                {{ view.days[rowIndex].date | weekOf }}
              </span>
            </div>
            <mwl-calendar-month-cell
              *ngFor="
                let day of view.days
                  | slice : rowIndex : rowIndex + view.totalDaysVisibleInWeek
              "
              [class.cal-drag-over]="day.dragOver"
              [ngClass]="day?.cssClass"
              [day]="day"
              [openDay]="openDay"
              [locale]="locale"
              [tooltipPlacement]="tooltipPlacement"
              [tooltipTemplate]="tooltipTemplate"
              [customTemplate]="cellTemplate"
              (click)="dayClicked.emit({ day: day })"
              (highlightDay)="toggleDayHighlight($event.event, true)"
              (unhighlightDay)="toggleDayHighlight($event.event, false)"
              mwlDroppable
              (dragEnter)="day.dragOver = true"
              (dragLeave)="day.dragOver = false"
              (drop)="
                day.dragOver = false; eventDropped(day, $event.dropData.event)
              "
              (eventClicked)="eventClicked.emit({ event: $event.event })"
            >
            </mwl-calendar-month-cell>
          </div>
          <mwl-calendar-open-day-events
            [isOpen]="openRowIndex === rowIndex"
            [events]="openDay?.events"
            [customTemplate]="openDayEventsTemplate"
            (eventClicked)="eventClicked.emit({ event: $event.event })"
          >
          </mwl-calendar-open-day-events>
        </div>
      </div>
    </div>
  `,
})
export class CustomCalendarMonthViewComponent extends CalendarMonthViewComponent {
  isWeekActive(date: Date) {
    return moment(date).isSame(moment(), "week");
  }
}
