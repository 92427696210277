import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { SendMessageResponse } from "@app/sidebar/send-message/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import { ApiService } from "../../../services/api/api.service";
import { MESSAGES_PREVIEW } from "../../../services/api/utils/api-endpoints";
import * as previewActions from "./preview.actions";

@Injectable()
export class PreviewEffects {
  fetchPreviewRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewActions.fetchPreviewRequest),
      switchMap(
        ({
          parameters: { templateId, params, consumerName, showLinksInNewTab },
        }) =>
          this.apiService.post(MESSAGES_PREVIEW(templateId), params).pipe(
            map((response: SendMessageResponse) =>
              previewActions.fetchPreviewSuccess({
                parameters: { response, consumerName, showLinksInNewTab },
              })
            ),
            catchError(() => {
              this.appStore.dispatch(
                toastActions.danger({ message: "preview_message_failed" })
              );
              return observableOf(previewActions.fetchPreviewFail());
            })
          )
      )
    )
  );

  fetchPreviewSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(previewActions.fetchPreviewSuccess),
      map(
        ({
          parameters: {
            response: { content },
            consumerName,
            showLinksInNewTab,
          },
        }) =>
          previewActions.showPreviewModal({
            parameters: { html: content, consumerName, showLinksInNewTab },
          })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {}
}
