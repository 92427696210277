import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO, Employment, TypedPaginationListDTO } from "@app/models";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  concatMap,
  from as observableFrom,
  map,
  mergeMap,
  switchMap,
} from "rxjs";
import * as sidebarActions from "../../../ngrx/sidebar.actions";
import * as eoActions from "./employees-office-create.actions";

@Injectable()
export class EmployeesOfficeCreateEffects {
  getEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eoActions.getEmployeeRequest),
      switchMap(({ eaEmployeeId }) =>
        this.apiService.get(`employees/${eaEmployeeId}`).pipe(
          map((res: any) =>
            eoActions.getEmployeeSuccess({ employee: new EmployeeDTO(res) })
          ),
          catchError((error) =>
            observableFrom([
              eoActions.getEmployeeFailed(),
              toastActions.danger(error),
            ])
          )
        )
      )
    )
  );

  getEmployments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eoActions.getEmploymentsRequest),
      switchMap(({ eaEmployeeId }) =>
        this.apiService.get(`employees/${eaEmployeeId}/employments`).pipe(
          map((res: TypedPaginationListDTO<Employment>) =>
            eoActions.getEmploymentsSuccess({ employments: res.rows })
          ),
          catchError((error) =>
            observableFrom([
              eoActions.getEmploymentsFailed(),
              toastActions.danger(error),
            ])
          )
        )
      )
    )
  );

  createEmployment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eoActions.createEmploymentRequest),
      mergeMap(({ employment }) =>
        this.apiService
          .post(
            `employments/${employment.eaOfficeId}/${employment.eaEmployeeId}`,
            employment
          )
          .pipe(
            concatMap((response: Employment) =>
              observableFrom([
                eoActions.createEmploymentSuccess({ employment: response }),
                toastActions.success({ message: "employment_created" }),
                sidebarActions.closeTab({ tabType: EMPLOYEES }),
              ])
            ),
            catchError(() =>
              observableFrom([
                eoActions.createEmploymentFailed(),
                toastActions.danger({ message: "employment_created_failed" }),
              ])
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
