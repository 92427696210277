import { Component } from "@angular/core";

@Component({
  selector: "search-contact-list-empty",
  template: `
    <div class="list-empty">
      <span>{{ "no_hits" | translate }}</span>
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./list-empty.component.scss"],
})
export class SearchContactListEmptyComponent {}
