import { createAction, props } from "@ngrx/store";
import { TaskType, TypedPaginationListDTO } from "@app/models";

const ACTION_PREFIX = "[Lead channels]";

export const getLeadChannelsRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNELS_REQUEST`,
  props<{ params: Record<string, unknown> }>()
);

export const getLeadChannelsSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNELS_SUCCESS`,
  props<{ data: TypedPaginationListDTO<TaskType> }>()
);

export const getLeadChannelsFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNELS_FAIL`
);
