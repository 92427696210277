import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, Observable } from "rxjs";

import { AppState } from "@app/app.reducer";
import { SearchService } from "@app/core/components/search/services/search.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, Office } from "@app/models";
import { SearchContactService } from "@app/shared/modules/search-contact/search-contact.service";
import * as fromUser from "@app/shared/user";

@Component({
  selector: "app-q-search-contact",
  templateUrl: "./q-search-contact.component.html",
  styleUrls: ["./q-search-contact.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QSearchContactComponent),
    },
    SearchContactService,
  ],
})
export class QSearchContactComponent implements OnInit, ControlValueAccessor {
  @Input() params: any;
  @Input() readonly: boolean;
  @Input() invalid: boolean;
  @Input() showCard: boolean = true;
  @Output() contactSelectedChange: EventEmitter<Contact> =
    new EventEmitter<Contact>();
  @ContentChild(TemplateRef) cardBodyTemplate: TemplateRef<any>;

  data: any[];
  contact: Contact;
  private _value: string;
  touched = false;
  disabled = false;

  office$: Observable<Office>;

  get value() {
    return this._value;
  }

  @Input() set value(contactId: string) {
    this._value = contactId;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(
    private store: Store<AppState>,
    private contactService: ContactService,
    public searchContactService: SearchContactService,
    private searchService: SearchService,
    public phoneNumberService: PhoneNumberService
  ) {}

  ngOnInit(): void {
    this.office$ = this.store.pipe(select(fromUser.getOffice));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj) {
      this.getContact(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  search(text: string) {
    combineLatest([this.searchService.formatKeywordIfPhoneNumber(text)])
      .pipe(first())
      .subscribe(([searchText]) => {
        this.searchContactService.quedroSearch(searchText);
      });
  }

  getContact(contactId: string) {
    this.contactService.getById(contactId).subscribe((value: Contact) => {
      this.contact = value;
    });
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  clear() {
    this.data = [];
    this.contact = null;
    this.value = "";
    this.onChange("");
  }

  itemClick(contact) {
    this.markAsTouched();
    if (!this.disabled) {
      this.contact = contact;
      this.value = contact.contactId;
      this.onChange(contact.contactId);
      this.contactSelectedChange.emit(this.contact);
    }
  }
}
