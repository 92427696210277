<form [formGroup]="searchProfileForm">
  <h4 translate>information</h4>
  <div class="form-group">
    <label translate>title</label>*
    <div class="margin-top--16">
      <app-q-input
        formControlName="title"
        placeholder="title"
        type="text"
        [invalid]="
          !searchProfileForm.get('title').valid &&
          searchProfileForm.get('title').touched
        "
      ></app-q-input>
      <app-q-control-errors
        name="title"
        [errors]="[
          { name: 'required', text: 'validation_required', when: 'touched' }
        ]"
      >
      </app-q-control-errors>
    </div>
  </div>
  <div class="form-group margin-bottom-10">
    <label translate>comment</label>
    <div class="margin-top--16">
      <app-q-textarea
        formControlName="comment"
        placeholder="comment"
      ></app-q-textarea>
    </div>
  </div>
  <hr />
  <div class="form-group margin-bottom-10" style="margin-top: 15px">
    <label translate>location</label>*
    <div [class]="selectedAreas?.length ? '' : 'margin-top--16'">
      <app-location-filter
        [selectedAreas]="selectedAreas"
        [showTitle]="false"
        [invalid]="
          !searchProfileForm.get('areaSearch').valid &&
          searchProfileForm.get('areaSearch').touched
        "
        (selectedAreaChanged)="selectedAreaChangedHandler($event)"
      ></app-location-filter>
    </div>
    <app-q-control-errors
      name="areaSearch"
      [errors]="[
        { name: 'required', text: 'validation_required', when: 'touched' }
      ]"
    >
    </app-q-control-errors>
  </div>
  <hr />
  <div>
    <h4 translate>tenure_type</h4>
    <div class="checkbox-tag-group">
      <div class="input-checkbox-container">
        <input
          type="checkbox"
          id="for_sale"
          class="checkbox"
          formControlName="tenureTypeForSale"
        />
        <label [attr.for]="'for_sale'" translate>for_sale</label>
      </div>

      <div class="input-checkbox-container">
        <input
          type="checkbox"
          id="rental"
          class="checkbox"
          formControlName="tenureTypeForRent"
        />
        <label [attr.for]="'rental'" translate>rental</label>
      </div>
    </div>
  </div>
  <hr />
  <div>
    <h4 translate>newproduction</h4>
    <div class="form-group">
      <app-q-select
        [inline]="false"
        [options]="newProductionSelect"
        formControlName="isNewProduction"
      ></app-q-select>
    </div>
  </div>
  <hr />
  <div formGroupName="objectType">
    <h4 translate>object_type</h4>
    <div class="checkbox-tag-group">
      <div
        class="input-checkbox-container"
        *ngFor="let type of objectTypes$ | async"
      >
        <input
          type="checkbox"
          class="checkbox"
          id="{{ type.objectTypeId }}"
          formControlName="{{ type.objectTypeId }}"
        />
        <label [attr.for]="type.objectTypeId">{{ type.objectTypeName }}</label>
      </div>
    </div>
  </div>
  <hr />
  <h4 translate>object_properties</h4>
  <div class="checkbox-tag-group">
    <div class="input-checkbox-container">
      <input
        type="checkbox"
        id="elevator"
        class="checkbox"
        formControlName="hasElevator"
      />
      <label [attr.for]="'elevator'" translate>elevator</label>
    </div>

    <div class="input-checkbox-container">
      <input
        type="checkbox"
        id="balcony"
        class="checkbox"
        formControlName="hasBalcony"
      />
      <label [attr.for]="'balcony'" translate>balcony</label>
    </div>

    <div class="input-checkbox-container">
      <input
        type="checkbox"
        id="fireplace"
        class="checkbox"
        formControlName="hasFireplace"
      />
      <label [attr.for]="'fireplace'" translate>fireplace</label>
    </div>

    <div class="input-checkbox-container">
      <input
        type="checkbox"
        id="sauna"
        class="checkbox"
        formControlName="hasSauna"
      />
      <label [attr.for]="'sauna'" translate>sauna</label>
    </div>
  </div>
  <ng-container *ngIf="formConfig$ | async as config">
    <ng-container *ngIf="config.form_search_options.lot_status.enabled">
      <hr />
      <h4 translate>lot_status</h4>
      <app-q-select
        [inline]="false"
        [options]="lotStatusSelect"
        [formControlName]="'lotStatus'"
      ></app-q-select>
    </ng-container>
    <hr />
    <h4 translate>search_options</h4>
    <ng-container *ngIf="config.form_search_options.area.enabled">
      <label translate>size</label> (<label translate> area_unit</label>)
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-number
              formControlName="minimumArea"
              placeholder="minimum_area"
              mask="separator.2"
              [invalid]="
                !searchProfileForm.get('minimumArea').valid &&
                searchProfileForm.get('minimumArea').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="minimumArea"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-number
              formControlName="maximumArea"
              placeholder="maximum_area"
              mask="separator.2"
              [invalid]="
                !searchProfileForm.get('maximumArea').valid &&
                searchProfileForm.get('maximumArea').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="maximumArea"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['areaRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.rooms.enabled">
      <label translate>no_of_rooms</label>
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-number
              formControlName="minimumRooms"
              placeholder="minimum_rooms"
              mask="separator.2"
              [invalid]="
                !searchProfileForm.get('minimumRooms').valid &&
                searchProfileForm.get('minimumRooms').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="minimumRooms"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals_with_one_float',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-number
              formControlName="maximumRooms"
              placeholder="maximum_rooms"
              mask="separator.2"
              dropSpecialCharacters="false"
              [invalid]="
                !searchProfileForm.get('maximumRooms').valid &&
                searchProfileForm.get('maximumRooms').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="maximumRooms"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals_with_one_float',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['roomsRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.bedrooms.enabled">
      <label translate>no_of_bedrooms</label>
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-number
              formControlName="minimumBedrooms"
              placeholder="minimum_bedrooms"
              mask="separator.0"
              [invalid]="
                !searchProfileForm.get('minimumBedrooms').valid &&
                searchProfileForm.get('minimumBedrooms').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="minimumBedrooms"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-number
              formControlName="maximumBedrooms"
              placeholder="maximum_bedrooms"
              mask="separator.0"
              [invalid]="
                !searchProfileForm.get('maximumBedrooms').valid &&
                searchProfileForm.get('maximumBedrooms').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="maximumBedrooms"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['bedroomsRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.price.enabled">
      <label translate>price</label> (<label> {{ currency | lowercase }}</label
      >)
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-thousand-separator
              formControlName="minimumPrice"
              placeholder="minimum_price"
              [invalid]="
                !searchProfileForm.get('minimumPrice').valid &&
                searchProfileForm.get('minimumPrice').dirty
              "
            ></app-q-input-thousand-separator>
            <app-q-control-errors
              name="minimumPrice"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-thousand-separator
              formControlName="maximumPrice"
              placeholder="maximum_price"
              [invalid]="
                !searchProfileForm.get('maximumPrice').valid &&
                searchProfileForm.get('maximumPrice').dirty
              "
            ></app-q-input-thousand-separator>
            <app-q-control-errors
              name="maximumPrice"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['priceRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.fee.enabled">
      <label translate>monthly_fee</label> (<label>
        {{ currency | lowercase }}</label
      >)
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-thousand-separator
              formControlName="minimumMonthlyFee"
              placeholder="minimum_monthly_fee"
              [invalid]="
                !searchProfileForm.get('minimumMonthlyFee').valid &&
                searchProfileForm.get('minimumMonthlyFee').dirty
              "
            ></app-q-input-thousand-separator>
            <app-q-control-errors
              name="minimumMonthlyFee"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-thousand-separator
              formControlName="maximumMonthlyFee"
              placeholder="maximum_monthly_fee"
              [invalid]="
                !searchProfileForm.get('maximumMonthlyFee').valid &&
                searchProfileForm.get('maximumMonthlyFee').dirty
              "
            ></app-q-input-thousand-separator>
            <app-q-control-errors
              name="maximumMonthlyFee"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['monthlyFeeRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.lot_area.enabled">
      <label translate>lot_area</label> (<label translate> area_unit</label>)
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-number
              formControlName="minimumLotArea"
              placeholder="minimum_lot_area"
              mask="separator.2"
              [invalid]="
                !searchProfileForm.get('minimumLotArea').valid &&
                searchProfileForm.get('minimumLotArea').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="minimumLotArea"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-number
              formControlName="maximumLotArea"
              placeholder="maximum_lot_area"
              mask="separator.2"
              [invalid]="
                !searchProfileForm.get('maximumLotArea').valid &&
                searchProfileForm.get('maximumLotArea').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="maximumLotArea"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_decimals',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['lotAreaRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="config.form_search_options.built_year.enabled">
      <label translate>build_year</label>
      <div class="form-group margin-top--16">
        <div class="search-profile-form-inputs">
          <div>
            <app-q-input-number
              formControlName="minimumBuildYear"
              placeholder="minimum_build_year"
              mask="0000"
              [invalid]="
                !searchProfileForm.get('minimumBuildYear').valid &&
                searchProfileForm.get('minimumBuildYear').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="minimumBuildYear"
              [errors]="[
                {
                  name: 'pattern',
                  text: 'validation_integers',
                  when: 'touchedAndDirty'
                }
              ]"
            >
            </app-q-control-errors>
          </div>
          <span>-</span>
          <div>
            <app-q-input-number
              formControlName="maximumBuildYear"
              placeholder="maximum_build_year"
              mask="0000"
              [invalid]="
                !searchProfileForm.get('maximumBuildYear').valid &&
                searchProfileForm.get('maximumBuildYear').dirty
              "
            ></app-q-input-number>
            <app-q-control-errors
              name="maximumBuildYear"
              [errors]="[
                { name: 'pattern', text: 'validation_integers', when: 'dirty' }
              ]"
            >
            </app-q-control-errors>
          </div>
        </div>
        <div
          *ngIf="searchProfileForm.errors?.['buildYearRangeMismatch'] && (searchProfileForm.touched || searchProfileForm.dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="additionalFilters && additionalFilters.length > 0">
    <hr />
    <h5
      (click)="extraFilters.hidden = !extraFilters.hidden"
      class="extra-filters"
      [ngClass]="
        extraFilters.hidden
          ? 'extra-filters__headline'
          : 'extra-filters__headline--black'
      "
      translate
    >
      <i [ngClass]="extraFilters.hidden ? 'fa fa-plus' : 'fa fa-minus'"></i>
      filters
    </h5>
    <div class="search-profile-form__filters" #extraFilters hidden>
      <ul
        *ngIf="searchProfileForm.get('objectCategories')"
        class="form-group"
        formGroupName="objectCategories"
      >
        <li
          class="input-checkbox-container"
          *ngFor="let filter of additionalFilters"
        >
          <label class="checkbox-group">
            {{ filter.categoryName }}
            <input
              type="checkbox"
              [value]="filter.categoryTypeId"
              [formControlName]="filter.categoryTypeId"
            />
            <span class="checkbox-group__checkmark"></span>
          </label>
        </li>
      </ul>
    </div>
  </ng-container>
</form>
