import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import * as userActions from "@app/shared/user/user.actions";
import { TOO_MANY_REQUESTS } from "@app/shared/utils/http-status-codes";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  concatMap,
  from,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";
import * as forgotPassword from "./forgot-password.actions";

@Injectable()
export class ForgotPasswordEffects {
  sendEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.sendEmailRequest),
      switchMap(({ email }) =>
        this.apiService.post("send-mail", { email }, "forgot-password").pipe(
          map(() => forgotPassword.sendEmailSuccess()),
          catchError(() => observableOf(forgotPassword.sendEmailFail()))
        )
      )
    )
  );

  sendEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.sendEmailSuccess),
      switchMap(() => this.translateService.get("read_email")),
      map((message) => toastActions.success({ message }))
    )
  );

  sendEmailFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.sendEmailFail),
      switchMap(() => this.translateService.get("user_not_found")),
      map((message) => toastActions.danger({ message }))
    )
  );

  verifyEmailCodeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.verifyEmailCodeRequest),
      switchMap(({ verificationCode }) =>
        this.apiService
          .post("user-verification", { verificationCode }, "forgot-password")
          .pipe(
            map((data: { token; userId }) => {
              try {
                this.authService.setToken(data.token);
                return forgotPassword.verifyEmailCodeSuccess({
                  userId: data.userId,
                });
              } catch (e) {
                return userActions.clearState();
              }
            }),
            catchError((err) => {
              if (err.status === TOO_MANY_REQUESTS) {
                return observableOf(
                  toastActions.danger({
                    message: "to_many_requests_verification_code",
                  })
                );
              }
              return observableOf(forgotPassword.verifyEmailCodeFail());
            })
          )
      )
    )
  );

  verifyEmailCodeFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.verifyEmailCodeFail),
      switchMap(() => this.translateService.get("wrong_security_code")),
      map((message) => toastActions.danger({ message }))
    )
  );

  updateLoginInfoRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.updateLoginInfoRequest),
      switchMap(({ loginInfo: { eaEmployeeId, password } }) =>
        this.apiService
          .patch(`users/${eaEmployeeId}`, { password }, "api")
          .pipe(
            map(() => forgotPassword.updateLoginInfoSuccess()),
            catchError(() => observableOf(forgotPassword.updateLoginInfoFail()))
          )
      )
    )
  );

  updateLoginInfoSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.updateLoginInfoSuccess),
      switchMap(() => this.translateService.get("login_new_password")),
      concatMap((message) =>
        from([
          toastActions.success({ message }),
          RouterActions.go({ path: ["/login"] }),
        ])
      )
    )
  );

  updateLoginInfoFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword.updateLoginInfoFail),
      switchMap(() => this.translateService.get("update_failed")),
      map((message) => toastActions.danger({ message }))
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {}
}
