import { Injectable } from "@angular/core";

import * as libphonenumber from "google-libphonenumber";

const PhoneNumberFormat = libphonenumber.PhoneNumberFormat;
const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

@Injectable()
export class PhoneNumberService {
  format(
    number: string,
    country: string,
    format: any = PhoneNumberFormat.NATIONAL
  ) {
    let results;
    try {
      if (number.length === 9 && country === "SE") {
        results = phoneUtil.format(
          phoneUtil.parse(number.replace("46", "460"), country),
          format
        );
      } else {
        if (country) {
          const countryCode = phoneUtil.getCountryCodeForRegion(country);
          if (number.startsWith("" + countryCode)) {
            number = "+" + number;
          }
        }
        results = phoneUtil.format(phoneUtil.parse(number, country), format);
      }
      return results;
    } catch (e) {
      return number;
    }
  }

  toLegacyFormat(number: string, country: string) {
    let results;
    try {
      results = phoneUtil.format(
        phoneUtil.parse(number, country),
        PhoneNumberFormat.E164
      );
      return results.substring(1);
    } catch (e) {
      return number;
    }
  }

  getCountryCodeFromNumber(number: string, country: string) {
    let results;
    try {
      results = phoneUtil.parse(number, country).getCountryCode();
      return results;
    } catch (e) {
      return number;
    }
  }

  isValidNumber(number: string, country: string): boolean {
    return phoneUtil.isValidNumber(phoneUtil.parse(number, country));
  }

  formatToNational(number: string, countryCode: string) {
    if (number) {
      return phoneUtil.format(
        phoneUtil.parse(number, countryCode),
        PhoneNumberFormat.NATIONAL
      );
    }
    return "";
  }
}
