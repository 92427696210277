import { ProcessedConsent } from "@app/contacts/contact-consents/models";
import * as consentActions from "@app/contacts/contact-consents/ngrx/consents.actions";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface ConsentState {
  consents: ProcessedConsent[];
  contactActivity: {
    hasActiveAlert: boolean;
    hasBeenToShowing: boolean;
    hasFutureTasks: boolean;
  };
  sidebarOpen: boolean;
  loading: boolean;
  storing: boolean;
  alias: string | null;
  editValidTo: string | null;
  editNote: string | null;
}

export const initialState: ConsentState = {
  consents: [],
  contactActivity: {
    hasActiveAlert: false,
    hasBeenToShowing: false,
    hasFutureTasks: false,
  },
  sidebarOpen: false,
  loading: false,
  storing: false,
  alias: null,
  editValidTo: "",
  editNote: "",
};

export function consentReducer(
  state: ConsentState,
  action: Action
): ConsentState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(consentActions.fetchConsentsRequest, (state) => ({
    ...state,
    loading: true,
    sidebarOpen: false,
  })),
  on(consentActions.fetchConsentsSuccess, (state, { consents }) => ({
    ...state,
    consents,
    loading: false,
    sidebarOpen: false,
  })),
  on(consentActions.fetchConsentsFail, (state) => ({
    ...state,
    consents: [],
    loading: false,
    sidebarOpen: false,
  })),
  on(
    consentActions.fetchContactActivitySuccess,
    (state, { hasActiveAlert, hasBeenToShowing, hasFutureTasks }) => ({
      ...state,
      contactActivity: { hasActiveAlert, hasBeenToShowing, hasFutureTasks },
    })
  ),
  on(consentActions.fetchContactActivityFail, (state) => ({
    ...state,
    contactActivity: initialState.contactActivity,
  })),
  on(consentActions.openSidebar, (state, { alias, validTo, note }) => ({
    ...state,
    sidebarOpen: true,
    alias: alias,
    editValidTo: validTo ? validTo : null,
    editNote: note ? note : null,
  })),
  on(consentActions.closeSidebar, (state) => ({
    ...state,
    sidebarOpen: false,
    alias: null,
    editValidTo: null,
    editNote: null,
  })),
  on(consentActions.storeConsentRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(
    consentActions.storeConsentSuccess,
    consentActions.storeConsentFail,
    (state) => ({
      ...state,
      sidebarOpen: false,
      storing: false,
    })
  )
);

const selectFeature = createFeatureSelector<ContactModuleState>("contact");

export const getConsents = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.consents
);

export const getContactActivity = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.contactActivity
);

export const getConsentsLoading = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.loading
);

export const getSidebarState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.sidebarOpen
);

export const getCurrentAlias = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.alias
);

export const getStoring = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents.storing
);

export const getConsentState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.consents
);
