<form [formGroup]="form" class="employee-office-form">
  <div class="form-group btn-group">
    <input formControlName="role" type="radio" id="manager" value="manager">
    <label class="btn btn-sm btn-first" [attr.for]="'manager'" translate>manager</label>

    <input
      formControlName="role" type="radio" id="broker" value="broker">
    <label class="btn btn-sm" [attr.for]="'broker'" translate>broker</label>
  </div>

  <div class="form-group btn-group">
    <input formControlName="status" type="radio" id="active" value="active">
    <label class="btn btn-sm btn-first" [attr.for]="'active'" translate>active</label>

    <input
      formControlName="status" type="radio" id="inactive" value="deleted">
    <label class="btn btn-sm" [attr.for]="'inactive'" translate>inactive</label>
  </div>

</form>
