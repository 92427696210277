import { Segment } from "@app/campaigns/create-campaign/models/segment";
import { Template } from "@app/campaigns/create-campaign/models/template";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as createCampaign from "./create-campaigns.actions";
import { CampaignsModuleState } from "@app/campaigns/campaigns-module.reducer";

export interface CreateCampaignState {
  segments: Segment[];
  templates: Template[];
  drafts: Template[];
  segmentsLoading: boolean;
  segmentsLoaded: boolean;
  templatesLoading: boolean;
  templatesLoaded: boolean;
  draftsLoading: boolean;
  draftsLoaded: boolean;
  storing: boolean;
  draftResuming: boolean;
  iframeUrl: string;
  contactsLoading: boolean;
  numberOfContacts: number | null;
}

export const initialState: CreateCampaignState = {
  segments: [],
  templates: [],
  drafts: [],
  segmentsLoading: false,
  segmentsLoaded: false,
  templatesLoading: false,
  templatesLoaded: false,
  draftsLoading: false,
  draftsLoaded: false,
  storing: false,
  draftResuming: false,
  iframeUrl: "",
  contactsLoading: false,
  numberOfContacts: null,
};

export function createCampaignReducer(
  state: CreateCampaignState,
  action: Action
): CreateCampaignState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(createCampaign.getSegmentRequest, (state) => ({
    ...state,
    segmentsLoading: true,
    segmentsLoaded: false,
  })),
  on(createCampaign.getSegmentSuccess, (state, { segments }) => ({
    ...state,
    segments,
    segmentsLoading: false,
    segmentsLoaded: true,
  })),
  on(createCampaign.getSegmentFail, () => ({ ...initialState })),
  on(createCampaign.getDraftsRequest, (state) => ({
    ...state,
    draftsLoading: true,
    draftsLoaded: false,
  })),
  on(createCampaign.getDraftsSuccess, (state, { templates }) => ({
    ...state,
    drafts: templates,
    draftsLoading: false,
    draftsLoaded: true,
  })),
  on(createCampaign.getDraftsFail, () => ({ ...initialState })),
  on(createCampaign.getTemplatesRequest, (state) => ({
    ...state,
    templatesLoading: true,
    templatesLoaded: false,
  })),
  on(createCampaign.getTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
    templatesLoading: false,
    templatesLoaded: true,
  })),
  on(createCampaign.getTemplatesFail, () => ({ ...initialState })),
  on(createCampaign.storeCampaignRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(createCampaign.storeCampaignSuccess, (state, { url }) => ({
    ...state,
    storing: false,
    iframeUrl: url,
  })),
  on(createCampaign.resumeCampaignRequest, (state) => ({
    ...state,
    draftResuming: true,
  })),
  on(createCampaign.resumeCampaignSuccess, (state, { url }) => ({
    ...state,
    iframeUrl: url,
    draftResuming: false,
  })),
  on(createCampaign.resumeCampaignFail, (state) => ({
    ...state,
    draftResuming: false,
  })),
  on(createCampaign.getNumberOfContactsForSegmentRequest, (state) => ({
    ...state,
    contactsLoading: true,
    numberOfContacts: null,
  })),
  on(
    createCampaign.getNumberOfContactsForSegmentSuccess,
    (state, { total }) => ({
      ...state,
      contactsLoading: false,
      numberOfContacts: total,
    })
  ),
  on(createCampaign.getNumberOfContactsForSegmentFail, (state) => ({
    ...state,
    contactsLoading: false,
    numberOfContacts: null,
  })),
  on(createCampaign.reset, (state) => ({
    ...state,
    iframeUrl: "",
    numberOfContacts: null,
  }))
);

const selectFeature = createFeatureSelector<CampaignsModuleState>("campaigns");
const getState = createSelector(
  selectFeature,
  (state: CampaignsModuleState) => state.createCampaign
);

export const getSegmentLoading = createSelector(
  getState,
  (state: CreateCampaignState) => state.segmentsLoading
);

export const getSegments = createSelector(
  getState,
  (state: CreateCampaignState) => state.segments
);

export const getTemplatesLoading = createSelector(
  getState,
  (state: CreateCampaignState) => state.templatesLoading
);

export const getTemplates = createSelector(
  getState,
  (state: CreateCampaignState) => state.templates
);

export const getDraftsLoading = createSelector(
  getState,
  (state: CreateCampaignState) => state.draftsLoading
);

export const getDrafts = createSelector(
  getState,
  (state: CreateCampaignState) => state.drafts
);

export const getDraftsResuming = createSelector(
  getState,
  (state: CreateCampaignState) => state.draftResuming
);

export const getIframeUrl = createSelector(
  getState,
  (state: CreateCampaignState) => state.iframeUrl
);

export const getCampaignStoring = createSelector(
  getState,
  (state: CreateCampaignState) => state.storing
);

export const getNumberOfContactsForSegmentLoading = createSelector(
  getState,
  (state: CreateCampaignState) => state.contactsLoading
);

export const getNumberOfContactsForSegment = createSelector(
  getState,
  (state: CreateCampaignState) => state.numberOfContacts
);
