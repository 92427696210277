import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { CallingListService } from "@app/core/ngrx/entity-services/calling-list.service";
import { Contact } from "@app/models";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromUser from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import {
  ASSISTANT_PERMISSIONS,
  CALLING_LIST_CREATE,
} from "@app/shared/utils/tab-types";
import { PreviewCallingListDialogComponent } from "@app/sidebar/calling-lists/create-calling-list/preview-calling-list-dialog/preview-calling-list-dialog.component";
import { CREATE_CALLING_LIST_LIMIT } from "@app/sidebar/calling-lists/create-calling-list/utils/create-calling-list-const";
import * as fromCallingListSidebar from "@app/sidebar/calling-lists/ngrx/calling-lists.reducer";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  take,
  takeUntil,
} from "rxjs";
import {
  CreateCallingListData,
  createCallingListRequest,
} from "@app/sidebar/calling-lists/ngrx/calling-lists.actions";
import { getPermissionFeature } from "@app/shared/config/config.reducer";

@Component({
  selector: "create-calling-list",
  templateUrl: "./create-calling-list.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "../../shared/sidebar-header/sidebar-header.component.scss",
    "./create-calling-list.component.scss",
  ],
})
export class CreateCallingListComponent
  implements OnInit, OnDestroy, OnChanges, ConnectableTab
{
  @Input() contactIdList: string[] = [];
  @Input() listName = "";
  @Input() disableSubmit = false;
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();
  label = "create_calling_list";
  unsubscribe$ = new Subject<void>();
  isAdmin$: Observable<boolean>;
  isAdminOrManager$: Observable<boolean>;
  loading$: Observable<boolean>;
  tabType = CALLING_LIST_CREATE;
  createCallingListForm: FormGroup;
  proxy$ = new Subject<any>();
  minDate = new Date();
  fileName: string = "";
  showUploadTooltip = false;
  isUploading$ = new BehaviorSubject<boolean>(false);
  uploadedFile: File = null;
  uploadedContacts$ = new BehaviorSubject<Contact[]>([]);
  dialog$: Observable<Contact[]>;
  createCallingListLimit = CREATE_CALLING_LIST_LIMIT;
  hasAssistantPermission$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private modalService: QModalService,
    private callingListService: CallingListService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
    this.connectTab();

    this.uploadedContacts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((csvContacts) => {
        this.isUploading$.next(false);

        if (
          !!csvContacts &&
          csvContacts.length > 0 &&
          csvContacts.length <= this.createCallingListLimit
        ) {
          this.dialog$ = this.modalService.show(
            PreviewCallingListDialogComponent,
            {
              data: {
                hasHeader: true,
                hasHeaderCloseButton: true,
                hasActionBar: true,
                contacts: csvContacts,
              },
            }
          );
        } else {
          this.fileName = "";
          this.uploadedFile = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(): void {
    if (this.listName) {
      this.createCallingListForm.get("title").setValue(this.listName);
    }
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  submit(): void {
    if (
      this.disableSubmit ||
      this.createCallingListForm.invalid ||
      (this.contactIdList?.length === 0 && !this.fileName) ||
      this.contactIdList?.length > this.createCallingListLimit
    ) {
      markAllAsTouched(this.createCallingListForm);
      return;
    }

    if (this.createCallingListForm.valid) {
      if (!!this.fileName) {
        this.saveUploadedContacts();
        return;
      }

      const callingList = this.formatCallingListForm();
      this.store.dispatch(createCallingListRequest({ callingList }));
      this.loading$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((loading: boolean) => {
          if (!loading) {
            this.closeTab();
          }
        });
    }
  }

  submitEmptyCallingList(): void {
    if (this.createCallingListForm.valid) {
      const callingList = this.formatCallingListForm();
      this.store.dispatch(createCallingListRequest({ callingList }));
      this.loading$
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((loading: boolean) => {
          if (!loading) {
            this.closeTab();
          }
        });
    } else {
      markAllAsTouched(this.createCallingListForm);
    }
  }

  closeTab(): void {
    this.closeSidebar.emit();
    this.store.dispatch(
      sidebarActions.closeTab({ tabType: CALLING_LIST_CREATE })
    );
  }

  setDateFromNow($event: number) {
    this.createCallingListForm
      .get("deliveryDate")
      .setValue(moment().add($event, "day").toDate());
  }

  uploadContactsFile($event) {
    if ($event.target.files.length == 0) {
      return;
    }
    this.uploadedFile = $event.target.files[0];
    this.isUploading$.next(true);
    this.fileName = this.uploadedFile.name;
    this.readFileContent(this.uploadedFile);
  }

  removeUploadedFile() {
    this.fileName = "";
    this.uploadedFile = null;
    this.uploadedContacts$.next([]);
  }

  private mapStateToProps(): void {
    this.loading$ = this.store.pipe(select(fromCallingListSidebar.getLoading));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isAdminOrManager$ = this.store.pipe(select(fromUser.isManagerOrAdmin));
    this.hasAssistantPermission$ = combineLatest([
      this.isAdminOrManager$,
      this.store.pipe(
        select(
          getPermissionFeature(ASSISTANT_PERMISSIONS, "calling_list_sidebar")
        )
      ),
    ]).pipe(
      map(([isAdminOrManager, permission]) => {
        if (isAdminOrManager) {
          return false;
        }

        return permission && permission.enabled;
      })
    );
  }

  private buildForm(): void {
    const startDate = moment().add(1, "day").toDate();

    this.createCallingListForm = this.fb.group({
      title: ["", Validators.required],
      office: ["", Validators.required],
      employee: [[], Validators.required],
      createTask: [""],
      importSource: ["CRM"],
      deliveryDate: [startDate],
    });
  }

  private fillForm(): void {
    if (this.listName) {
      this.createCallingListForm.get("title").setValue(this.listName);
    }
    this.createCallingListForm.get("createTask").setValue(false);
  }

  private formatCallingListForm(): CreateCallingListData {
    let params: CreateCallingListData = {
      contactIds: this.contactIdList.toString(),
      eaEmployeeId: this.createCallingListForm.get("employee").value.toString(),
      eaOfficeId: this.createCallingListForm.get("office").value.toString(),
      title: this.createCallingListForm.get("title").value,
    };

    if (this.createCallingListForm.get("createTask").value) {
      const deliveryDate = this.createCallingListForm.get("deliveryDate").value
        ? moment(this.createCallingListForm.get("deliveryDate").value)
            .endOf("day")
            .format(API_DATE_FORMAT)
        : moment().endOf("day").format(API_DATE_FORMAT);

      params = {
        ...params,
        createTask: true,
        taskDeliveryDate: deliveryDate,
      };
    }

    return params;
  }

  private saveUploadedContacts() {
    const params = {
      ...this.formatCallingListForm(),
      importSource: this.createCallingListForm.get("importSource").value,
      communicationLevel: 5,
    };
    delete params.contactIds;

    const formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }
    formData.append("file", this.uploadedFile, this.uploadedFile.name);

    this.callingListService
      .postUpload(formData)
      .pipe(
        switchMap(() => {
          return this.callingListService.refreshCurrentList().pipe(first());
        })
      )
      .subscribe(() => {
        this.closeTab();
      });
  }

  private readFileContent(file: File) {
    this.uploadedFile = file;
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const fileContent = fileReader.result;
      const contactsObject = this.csvToContactsList(fileContent);
      this.uploadedContacts$.next(contactsObject);
    };
    fileReader.readAsText(file);
  }

  private csvToContactsList(csvText) {
    const lines = csvText.split("\n");
    const result: Contact[] = [];
    const headers = lines[0].split(";");

    for (let i = 1; i < lines.length; i++) {
      if (!lines[i]) continue;
      const obj = {};
      const currentLine = lines[i].split(";");

      for (let j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentLine[j];
      }
      result.push(new Contact(obj));
    }
    return result;
  }
}
