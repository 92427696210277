import { QObject } from "@app/models";
import * as actions from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface LatestObjectsForSaleWidgetState {
  objects: QObject[];
  loading: boolean;
}

export const initialState: LatestObjectsForSaleWidgetState = {
  loading: false,
  objects: [],
};

export function latestObjectsForSaleWidgetReducer(
  state: LatestObjectsForSaleWidgetState,
  action: Action
): LatestObjectsForSaleWidgetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.fetchLatestObjectsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.fetchLatestObjectsSuccess, (state, { objects }) => ({
    ...state,
    loading: false,
    objects,
  })),
  on(actions.fetchLatestObjectsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.latestObjectsForSale
);
export const getLatestObjectsForSale = createSelector(
  getState,
  (state: LatestObjectsForSaleWidgetState) => state.objects
);
export const getLoading = createSelector(
  getState,
  (state: LatestObjectsForSaleWidgetState) => state.loading
);
