import { Employee } from "@app/models";
import * as selectOfficeActions from "./select-office.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface SelectOfficeState {
  employees: Employee[];
}

export const initialState: SelectOfficeState = {
  employees: [],
};

export function selectOfficeReducer(
  state: SelectOfficeState,
  action: Action
): SelectOfficeState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(selectOfficeActions.searchEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
  }))
);
