import * as fromSegmentActions from "@app/dashboard/more-opportunities/segmentation-list/segmentation-list.actions";
import * as fromListsContactActions from "@app/lists/lists-contacts/ngrx/lists-contacts.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as fromCallingListActions from "./calling-lists.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface CallingListsState {
  loading: boolean;
  loaded: boolean;
  selectedContactList: any;
  listName: string;
}

export const initialState: CallingListsState = {
  loading: false,
  loaded: false,
  selectedContactList: [],
  listName: "",
};

export function callingListsReducer(state: CallingListsState, action: Action) {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    fromCallingListActions.createCallingListSuccess,
    fromCallingListActions.getContactListSuccess,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(
    fromCallingListActions.createCallingListFailed,
    fromCallingListActions.getContactListFailed,
    (state) => ({
      ...state,
      loading: false,
      loaded: false,
    })
  ),
  on(
    fromSegmentActions.searchSegmentListForCallingListSuccess,
    (state, { payload }) => ({
      ...state,
      selectedContactList: payload.rows,
    })
  ),
  on(
    fromListsContactActions.getContactsForCallingListSuccess,
    (state, { payload }) => ({
      ...state,
      selectedContactList: payload.segmentationListContacts,
    })
  ),
  on(fromCallingListActions.createCallingListRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    fromCallingListActions.createCallQueueTaskFailed,
    fromCallingListActions.createCallQueueTaskSuccess,
    fromCallingListActions.createCallQueueTaskRequest,
    (state) => state
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.callingLists
);

export const getLoading = createSelector(
  getState,
  (state: CallingListsState) => state.loading
);

export const getSelectedContactList = createSelector(
  getState,
  (state: CallingListsState) => state.selectedContactList
);

export const getListName = createSelector(
  getState,
  (state: CallingListsState) => state.listName
);
