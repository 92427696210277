import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { ContactProfile } from "@app/models";
import { undo } from "ngrx-undo";
import { ApiService } from "@app/core/services/api/api.service";
import {
  fetchContactProfileFailure,
  fetchContactProfileRequest,
  fetchContactProfileSuccess,
  updateContactProfileFailure,
  updateContactProfileRequest,
  updateContactProfileSuccess,
} from "@app/sidebar/potential-buyer/ngrx/contact-profile/contact-profile.actions";

@Injectable()
export class ContactProfileEffects {
  fetchContactProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactProfileRequest),
      switchMap(({ contactId }) =>
        this.api.get(`contacts/${contactId}/profile`).pipe(
          map((profile: ContactProfile) =>
            fetchContactProfileSuccess({ profile })
          ),
          catchError(() => of(fetchContactProfileFailure()))
        )
      )
    )
  );

  updateContactProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateContactProfileRequest),
      switchMap(({ contactId, body, undoAction }) =>
        this.api.patch(`contacts/${contactId}/profile`, body).pipe(
          map((profile: ContactProfile) =>
            updateContactProfileSuccess({ profile })
          ),
          catchError(() => of(updateContactProfileFailure(), undo(undoAction)))
        )
      )
    )
  );

  constructor(private actions$: Actions, private api: ApiService) {}
}
