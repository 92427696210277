<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="tabType | translate"
  ></sidebar-header>

  <div class="scroll-view">
    <send-message-form
      [countryCode]="countryCode$ | async"
      [languageCode]="languageCode$ | async"
      [messageType]="messageType$ | async"
      [templates]="templates$ | async"
      [dynamicContents]="dynamicContents$ | async"
      [recipients]="recipients$ | async"
      [emails]="emails$ | async"
      [checkConsent]="checkConsent$ | async"
      [mobileNumbers]="mobileNumbers$ | async"
      [processing]="processing$ | async"
      [errors]="errors$ | async"
      [addToOutbox]="form.get('addToOutbox').value"
      [observer]="proxy$"
      [object]="showingObject$ | async"
      [selectedObjectFiles]="sendMessageService.objectFiles$ | async"
      (messageTypeChange)="onMessageTypeChange($event)"
      (templateChange)="onTemplateChange($event)"
      (addRecipient)="onAddRecipient($event)"
      (removeRecipient)="onRemoveRecipient($event)"
      (addEmail)="onAddEmail($event)"
      (removeEmail)="onRemoveEmail($event)"
      (removeAllEmails)="onRemoveAllEmails()"
      (addMobileNumber)="onAddMobileNumber($event)"
      (removeMobileNumber)="onRemoveMobileNumber($event)"
      (removeAllMobileNumbers)="onRemoveAllMobileNumbers()"
      (updateContactEmail)="onUpdateContactEmail($event)"
      (updateContactMobileNumber)="onUpdateContactMobileNumber($event)"
      (previewForm)="onPreview($event)"
      (submitForm)="onSubmit($event)"
      (removeFile)="handleRemoveFile($event)"
    >
      <app-q-button
        *ngIf="showAddObjectLinks$ | async"
        buttonType="preview"
        label="add_object_links"
        classes="margin-top"
        [fullWidth]="true"
        (click)="handleShowObjectLinkModal($event)"
      >
      </app-q-button>
      <app-q-button
        *ngIf="
          (fileAttachmentsEnabled$ | async) &&
          (messageType$ | async) === 'email'
        "
        buttonType="preview"
        label="add_object_files"
        classes="margin-top"
        [fullWidth]="true"
        [disabled]="objectLoading$ | async"
        (click)="handleShowObjectFilesModal($event)"
      >
      </app-q-button>
      <app-q-button
        tabindex="-1"
        buttonType="preview"
        classes="margin-top"
        label="preview"
        [fullWidth]="true"
        [disabled]="disablePreviewAndSend$ | async"
        (click)="sendMessageForm.preview()"
      >
      </app-q-button>
    </send-message-form>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <form [formGroup]="form">
      <span
        *ngIf="(messageType$ | async) === 'email' && (exchangeEmail$ | async)"
        class="add-email-to-outbox"
      >
        <div>
          <input
            type="checkbox"
            id="add-to-outbox"
            formControlName="addToOutbox"
          />
          <label for="add-to-outbox">
            <span translate>add_mail_to_outbox</span>
          </label>
        </div>
      </span>
    </form>
    <app-q-button
      buttonType="save"
      label="send"
      [disabled]="disablePreviewAndSend$ | async"
      [isLoading]="processing$ | async"
      (click)="sendMessageForm.submit()"
      scrollToInvalidControl
      [formEl]="form"
    >
    </app-q-button>
  </div>
</div>
