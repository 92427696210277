import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import { ThirdPartyWidgetFeature } from "@app/shared/config/models/third-party-widget-feature";
import { THIRD_PARTY_WIDGET } from "@app/shared/config/utils/features";
import { getFeature } from "@app/shared/config/config.reducer";

@Component({
  selector: "app-third-party-iframe-widget",
  templateUrl: "./third-party-iframe-widget.component.html",
  styleUrls: ["./third-party-iframe-widget.component.scss"],
})
export class ThirdPartyIframeWidgetComponent implements OnInit, OnDestroy {
  @Input() eaOfficeId: string;
  @Input() showHeader: boolean = true;
  setting$: Observable<ThirdPartyWidgetFeature>;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.setting$ = this.store.pipe(select(getFeature(THIRD_PARTY_WIDGET)));
  }
}
