import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EmployeeDTO, Office } from "@app/models";
import {
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { OfficeEmployeeDropdownService } from "../office-employee-dropdown/office-employee-dropdown.service";

@Component({
  selector: "all-office-employee-dropdown",
  templateUrl: "./all-office-employee-dropdown.component.html",
  styleUrls: ["./all-office-employee-dropdown.component.scss"],
  providers: [OfficeEmployeeDropdownService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllOfficeEmployeeDropdownComponent implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormGroupName: string;
  @Input() parentOfficeFormControlName = "office";
  @Input() parentEmployeeFormControlName = "employee";
  @Input() disableSelectBrokerOption = true;
  @Output() employeesChange = new EventEmitter<void>();

  offices$: Observable<Office[]>;
  employees$: Observable<EmployeeDTO[]>;
  unSubscribe$: Subject<void> = new Subject<void>();

  constructor(private localStore: OfficeEmployeeDropdownService) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initFormEventHandlers();
    this.fetchOffices();
    this.initEmployeesLoadingEvents();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  mapStateToProps(): void {
    this.offices$ = this.localStore.offices$;
    this.employees$ = this.localStore.employees$;
  }

  initFormEventHandlers(): void {
    this.parentFormGroup
      .get(this.parentOfficeFormControlName)
      .valueChanges.pipe(
        filter((value) => !!value),
        distinctUntilChanged(),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((eaOfficeId) =>
        this.localStore.fetchEmployeesForOffice(eaOfficeId)
      );
  }

  fetchOffices(): void {
    this.localStore.fetchOffices();
  }

  private initEmployeesLoadingEvents() {
    this.localStore.employeesLoading$
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loading) => {
        const control = this.parentFormGroup.get(
          this.parentEmployeeFormControlName
        );
        if (loading) {
          control.disable();
        } else {
          control.enable();
          this.employeesChange.emit();
        }
      });
  }

  getFullControlName(controlName: string): string {
    return this.parentFormGroupName
      ? `${this.parentFormGroupName}.${controlName}`
      : controlName;
  }
}
