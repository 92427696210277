import { SegmentedControl } from "@app/shared/modules/ui-components/q-segmented-controls/q-segmented-controls.component";

export interface ObjectPartitionedResponse {
  values: {
    dataType: string;
    nonPartitioned: ObjectPartitioned;
    values: ObjectPartitioned[];
  }[];
}

export interface ObjectPartitioned {
  column: string;
  columnName: string;
  categoryGroup: string;
  campaignType: string;
  value: number;
  numberOfObjects: number;
  average: number;
  impact?: number;
}

export interface ObjectPartitionedFilters {
  eaOfficeId: string[];
  objectTypes: string;
  startDate: string;
  endDate: string;
  excludeNewProduction: string;
  groupBy: string;
  campaignNameAlias: string[];
  campaignGroup: string[];
  dataTypes: string[];
}

export const getAdfenixReportingDataTypeControls = () => {
  const dataTypeControls: SegmentedControl = [
    {
      label: "number_of_potential_buyers",
      value: "numberOfPotentialBuyers",
    },
    {
      label: "number_of_potential_buyers_connected_to_viewings",
      value: "numberOfPotentialBuyersConnectedToViewings",
    },
    {
      label: "number_of_placed_bids",
      value: "numberOfPlacedBids",
    },
  ];
  return dataTypeControls;
};
