import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, Employee, EmployeeDTO, Office } from "@app/models";
import { getTaskTypeIds } from "@app/shared/config/config.reducer";
import { getEmployee, getOffice, hasRole } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as formUtils from "@app/shared/utils/form-utils";
import { ROLE_BROKER } from "@app/shared/utils/roles";
import { SIDEBAR_FOLLOW_UPS_BASE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_FOLLOW_UP } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  skip,
  Subscription,
  switchMap,
  take,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import {
  closeTab,
  setInitialTabValue,
  setTabValue,
} from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import * as createFollowUpActions from "../ngrx/create-follow-up.actions";
import { CreateFollowUpRequest } from "../ngrx/create-follow-up.actions";
import * as fromCreateFollowUp from "../ngrx/create-follow-up.reducer";

@Component({
  selector: "create-follow-up-new",
  templateUrl: "./create-follow-up-new.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-follow-up-new.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFollowUpNewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  selectedContact$: Observable<Contact>;
  employee$: Observable<Employee>;
  employees$: Observable<EmployeeDTO[]>;
  employeesLoading$: Observable<boolean>;
  office$: Observable<Office>;
  processing$: Observable<boolean>;
  checkedInput = false;
  formInitialized$: Observable<boolean>;
  tab$: Observable<SidebarTab>;
  subscriptions: Subscription[] = [];
  form: FormGroup;
  minDate: Date = new Date();
  startDate;
  tabType = CREATE_FOLLOW_UP;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private phoneNumberService: PhoneNumberService
  ) {}

  ngOnInit() {
    this.initStoreObservers();
    this.initFormAndEvents();
    this.initParamsEvents();
    this.initSelectedContactEvents();
    this.fetchOfficeEmployees();
  }

  ngAfterViewInit() {
    this.formInitialized$
      .pipe(
        filter((value) => !!value),
        take(1)
      )
      .subscribe(() =>
        this.tab$.pipe(take(1)).subscribe((tab) => {
          if (tab.dirty) {
            this.form.setValue(tab.currentValue);
            this.startContactChangesStream(1);
          } else {
            this.startContactChangesStream();
          }
          this.startValueChangesStream();
        })
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      if (sub) {
        sub.unsubscribe();
      }
    });

    this.store.dispatch(createFollowUpActions.reset());
  }

  initStoreObservers(): void {
    this.employee$ = this.store.pipe(select(getEmployee));
    this.employees$ = this.store.pipe(select(fromCreateFollowUp.getEmployees));
    this.employeesLoading$ = this.store.pipe(
      select(fromCreateFollowUp.getEmployeesLoading)
    );
    this.selectedContact$ = this.store.pipe(
      select(fromCreateFollowUp.getSelectedContact)
    );
    this.office$ = this.store.pipe(select(getOffice));
    this.processing$ = this.store.pipe(
      select(fromCreateFollowUp.getProcessing)
    );

    this.tab$ = this.store.pipe(select(getTab(CREATE_FOLLOW_UP)));
    this.formInitialized$ = this.store.pipe(
      select(fromCreateFollowUp.isInitialized)
    );
  }

  initFormAndEvents(): void {
    const startDate = moment().add(3, "month").toDate();

    this.form = this.formBuilder.group({
      consent: [false, Validators.required],
      title: ["", [Validators.required]],
      date: [startDate, [Validators.required]],
      employee: ["", [Validators.required]],
      notes: [""],
    });

    this.subscriptions.push(
      this.office$
        .pipe(
          filter((o) => !_.isEmpty(o)),
          take(1),
          map((office: Office) =>
            this.form.get("employee").setValue(office.eaEmployeeId)
          ),
          switchMap(() => this.processing$)
        )
        .subscribe((processing: boolean) =>
          processing ? this.form.disable() : this.form.enable()
        )
    );
  }

  initParamsEvents(): void {
    this.subscriptions.push(
      this.route.params
        .pipe(
          filter((params: Params) => params.hasOwnProperty("id")),
          switchMap((params: Params) =>
            this.selectedContact$.pipe(
              take(1),
              filter(
                (contact: Contact) =>
                  !contact || contact.contactId !== params["id"]
              ),
              map(() => params["id"])
            )
          )
        )
        .subscribe((id: string) =>
          this.store.dispatch(createFollowUpActions.getContactRequest({ id }))
        )
    );
  }

  initSelectedContactEvents(): void {
    this.subscriptions.push(
      this.selectedContact$
        .pipe(
          filter((value) => !!value),
          switchMap((contact: Contact) =>
            this.translateService.get("follow_up_of", {
              name: contact.getFullName(),
            })
          )
        )
        .subscribe((title: string) => this.form.get("title").setValue(title))
    );
  }

  setDateFromNow(
    numberOfDays: number | string,
    unit: "day" | "month" = "day"
  ): void {
    this.form
      .get("date")
      .setValue(moment().add(numberOfDays, unit).format("L").toString());
  }

  fetchOfficeEmployees(): void {
    this.subscriptions.push(
      this.office$
        .pipe(filter((o) => !_.isEmpty(o)))
        .subscribe((office: Office) =>
          this.store.dispatch(
            createFollowUpActions.getOfficeEmployees(office.eaOfficeId)
          )
        )
    );
  }

  startContactChangesStream(skipNumber = 0) {
    this.subscriptions.push(
      this.selectedContact$.pipe(skip(skipNumber)).subscribe(() =>
        this.store.dispatch(
          setInitialTabValue({
            tabType: this.tabType,
            value: this.form.value,
          })
        )
      )
    );
  }

  startValueChangesStream() {
    this.subscriptions.push(
      this.form.valueChanges
        .pipe(debounceTime(500))
        .subscribe((formValues) =>
          this.store.dispatch(
            setTabValue({ tabType: this.tabType, value: formValues })
          )
        )
    );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(formData: FormData): void {
    if (this.form.valid) {
      observableCombineLatest([
        this.selectedContact$,
        this.office$,
        this.store.pipe(select(getTaskTypeIds)),
      ])
        .pipe(
          map(([contact, office, taskTypes]) => ({
            title: formData.title,
            contactId: contact.contactId,
            deliveryDate: moment(this.form.get("date").value, "L")
              .endOf("day")
              .format(API_DATE_FORMAT),
            startTime: moment().format("YYYYMMDDHHmmss").toString(),
            endTime: moment()
              .add(1, "month")
              .endOf("day")
              .format("YYYYMMDDHHmmss")
              .toString(),
            eaOfficeId: office.eaOfficeId,
            eaEmployeeId: formData.employee,
            description: formData.notes,
            eaTaskTypeId: taskTypes.task_type_followup.toString(),
            origin: contact.origin,
            maritalStatus: contact.maritalStatus,
            preferredLanguage: contact.preferredLanguage,
            dateOfBirth: contact.dateOfBirth,
            sex: contact.sex,
          })),
          take(1)
        )
        .subscribe((requestObj: CreateFollowUpRequest) =>
          this.store.dispatch(
            createFollowUpActions.createFollowUpRequest({ params: requestObj })
          )
        );
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  formatNumber(number: string, countryCode: string) {
    return this.phoneNumberService.format(number, countryCode);
  }

  goBack(): void {
    this.store.dispatch(createFollowUpActions.reset());
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm", { outlets: { sidebar: SIDEBAR_FOLLOW_UPS_BASE_URL } }],
      })
    );
  }

  getName(employee: EmployeeDTO): Observable<string> {
    return this.employee$.pipe(
      take(1),
      switchMap((e) =>
        this.translateService
          .get("you")
          .pipe(
            map((t) =>
              e.eaEmployeeId === employee.eaEmployeeId ? t : employee.fullName
            )
          )
      )
    );
  }

  isBroker(): Observable<boolean> {
    return this.store.pipe(select(hasRole(ROLE_BROKER)));
  }
}

interface FormData {
  date: string;
  employee: string;
  title: string;
  notes: string;
}
