import { Component, Input } from "@angular/core";

import { fadeInDown } from "@app/shared/animations";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
  animations: [fadeInDown()],
})
export class BreadcrumbComponent {
  @Input() routes: { label: string; to: any }[];
}
