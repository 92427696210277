<div class="widget__header">
  <h3>
    <app-icon size="large" name="book-open"></app-icon>&nbsp;<span>{{
      "notes" | translate
    }}</span>
  </h3>
  <app-q-circle-btn
    *ngIf="!isWritingNote"
    icon="plus"
    tooltipText="write_note"
    (click)="isWritingNote = true"
  ></app-q-circle-btn>
</div>

<div *ngIf="isWritingNote">
  <app-small-back-button
    [mode]="'output'"
    (click)="isWritingNote = false"
  ></app-small-back-button>
  <app-create-note
    [eaOid]="eaOid"
    (isSubmitted)="isWritingNote = false"
  ></app-create-note>
</div>

<div class="widget__body" [class.hidden]="isWritingNote">
  <app-note-list *ngIf="eaOid" [eaOid]="eaOid"></app-note-list>
</div>
