import {
  CALLING_LIST_CREATE,
  CONNECT_IN_EXTERNAL_PROVIDER,
  CONTACT_CREATE,
  CONTACT_CREATE_POTENTIAL_BUYER,
  CONTACT_CREATE_SELLER_BUYER,
  CONTACT_EDIT,
  CREATE_COMPETITION,
  CREATE_ERP_EMPLOYEE,
  CREATE_ERP_OFFICE,
  CREATE_ERP_SHOWING,
  CREATE_FOLLOW_UP,
  CREATE_IN_EXTERNAL_PROVIDER,
  CREATE_LEAD,
  CREATE_OBJECT,
  EDIT_COMPETITION,
  EIKA_SEND_TIPS,
  EMPLOYEES,
  EXTERNAL_TIPS_CREATE,
  EXTERNAL_TIPS_UPDATE,
  LEAD_CHANNELS,
  LEAD_RESULT_OPTIONS,
  LEAD_TEMPLATES,
  MANAGE_POTENTIAL_BUYER_MULTIPLE,
  MANAGE_POTENTIAL_BUYER_SINGLE,
  MESSAGE_TEMPLATE,
  RESIDENCE,
  SALES_MEETING,
  SEARCH_PROFILE_ALERT_CREATE,
  SEARCH_PROFILE_ALERT_EDIT,
  SEARCH_PROFILE_CREATE,
  SEARCH_PROFILE_EDIT,
  SEND_EXTERNAL_TIPS,
  SEND_MESSAGE,
  SEND_TIPS,
  SUPPORT,
  TASK,
  VALUE_MONITOR,
} from "@app/shared/utils/tab-types";

export enum TabPersist {
  NEVER,
  ONCE,
  ALWAYS,
}

export class SidebarTab {
  icon = "";
  dirty = false;
  initialValue: any = null;
  currentValue: any = null;
  persist = TabPersist.NEVER;

  constructor(public type: string, public url: string[]) {
    this.icon = this.getIcon(type);
  }

  getIcon(type: string): string {
    switch (type) {
      case CONTACT_CREATE:
      case CONTACT_CREATE_POTENTIAL_BUYER:
      case CONTACT_CREATE_SELLER_BUYER:
      case CONTACT_EDIT:
      case MANAGE_POTENTIAL_BUYER_SINGLE:
        return "user";
      case EMPLOYEES:
      case MANAGE_POTENTIAL_BUYER_MULTIPLE:
      case CREATE_ERP_EMPLOYEE:
        return "users";
      case RESIDENCE:
      case CREATE_OBJECT:
        return "home";
      case SALES_MEETING:
        return "briefcase";
      case CREATE_FOLLOW_UP:
      case TASK:
        return "check";
      case SEND_MESSAGE:
        return "envelope-open";
      case EXTERNAL_TIPS_CREATE:
      case SEND_EXTERNAL_TIPS:
        return "lightbulb-on";
      case SEND_TIPS:
      case EXTERNAL_TIPS_UPDATE:
      case SEARCH_PROFILE_ALERT_CREATE:
      case EIKA_SEND_TIPS:
        return "lightbulb";
      case CREATE_COMPETITION:
      case EDIT_COMPETITION:
        return "trophy-alt";
      case CALLING_LIST_CREATE:
        return "icon-list";
      case SEARCH_PROFILE_CREATE:
      case SEARCH_PROFILE_EDIT:
        return "search";
      case CREATE_IN_EXTERNAL_PROVIDER:
        return "icon-layers";
      case CONNECT_IN_EXTERNAL_PROVIDER:
        return "icon-layers";
      case SEARCH_PROFILE_ALERT_EDIT:
        return "icon-bulb";
      case SUPPORT:
        return "life-ring";
      case CREATE_LEAD:
        return "street-view";
      case CREATE_ERP_SHOWING:
        return "icon-eye";
      case CREATE_ERP_OFFICE:
        return "icon-location-pin";
      case MESSAGE_TEMPLATE:
        return "mailbox";
      case LEAD_CHANNELS:
      case LEAD_TEMPLATES:
      case LEAD_RESULT_OPTIONS:
        return "street-view";
      case VALUE_MONITOR:
        return "comment-alt-dollar";
      default:
        return "question-mark";
    }
  }
}
