import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  exhaustMap,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as actions from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.actions";
import { ObjectNote } from "@app/widgets/widgets/object-widgets/object-notes-widget/models/object-note";

export type ObjectNoteResponse = { notes: ObjectNote[] };

@Injectable()
export class ObjectNotesWidgetEffects {
  getNotesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getNotesRequest),
      switchMap(({ eaOid }) =>
        this.apiService
          .get(`integration-object-notes/${eaOid}`, {}, "integrations")
          .pipe(
            map((response: ObjectNoteResponse) =>
              actions.getNotesSuccess({ data: response })
            ),
            catchError(() => observableOf(actions.getNotesFail()))
          )
      )
    )
  );

  createNoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createNoteRequest),
      exhaustMap(({ eaOid, body }) =>
        this.apiService
          .post(
            `integration-object-notes/${eaOid}/integration-object-note`,
            body,
            "integrations"
          )
          .pipe(
            map((data: ObjectNote) => actions.createNoteSuccess({ data })),
            catchError(() => observableOf(actions.createNoteFail()))
          )
      )
    )
  );

  createNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createNoteSuccess),
      switchMap(() =>
        observableFrom([
          toastActions.success({ message: "consumer_object_note_stored" }),
        ])
      )
    )
  );

  createNoteFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createNoteFail),
      switchMap(() =>
        observableFrom([
          toastActions.danger({ message: "create_object_note_failed" }),
        ])
      )
    )
  );

  deleteNoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteNoteRequest),
      exhaustMap(({ eaOid, externalId }) =>
        this.apiService
          .delete(
            `integration-object-notes/${eaOid}/integration-object-note/${externalId}`,
            {},
            "integrations"
          )
          .pipe(
            map(() => actions.deleteNoteSuccess({ eaOid })),
            catchError(() => observableOf(actions.createNoteFail()))
          )
      )
    )
  );

  deleteNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteNoteSuccess),
      switchMap(({ eaOid }) =>
        observableFrom([
          actions.getNotesRequest({ eaOid }),
          toastActions.success({ message: "note_deleted" }),
        ])
      )
    )
  );

  deleteNoteFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteNoteFail),
      switchMap(() =>
        observableFrom([toastActions.danger({ message: "delete_note_failed" })])
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
