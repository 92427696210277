import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { map } from "rxjs";

import { ObjectService } from "@app/core/ngrx/entity-services/object.service";

export default function createObjectHasCoordinatesValidator(
  objectService: ObjectService
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    return objectService.getById(value).pipe(
      map((object) => {
        const { latitude, longitude } = object;
        return latitude && longitude ? null : { missingCoordinates: true };
      })
    );
  };
}
