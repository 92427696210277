import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SearchService } from "@app/core/components/search/services/search.service";
import { ApiService } from "@app/core/services/api/api.service";
import { ConfigService } from "@app/core/services/config/config.service";
import { IntegrationContactSearchCapabilities } from "@app/integrations/models/enums";
import { hasContactSearchCapability } from "@app/integrations/ngrx/integrations.reducer";
import { Contact } from "@app/models";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of as observableOf,
  Subject,
  take,
} from "rxjs";
import { getCountry, getFeature } from "../../config/config.reducer";
import { Feature } from "../../config/models";
import * as features from "../../config/utils/features";
import { SearchProviderContact } from "./models";

@Injectable()
export class SearchContactService {
  quedroContacts$: BehaviorSubject<Contact[]> = new BehaviorSubject<Contact[]>(
    []
  );
  quedroLoading$: BehaviorSubject<boolean>;

  eniroContacts$: BehaviorSubject<SearchProviderContact[]>;
  eniroLoading$: BehaviorSubject<boolean>;

  constructor(
    private apiService: ApiService,
    private store: Store<AppState>,
    private searchService: SearchService,
    private configService: ConfigService
  ) {
    this.quedroLoading$ = new BehaviorSubject(false);
    this.eniroContacts$ = new BehaviorSubject([]);
    this.eniroLoading$ = new BehaviorSubject(false);
  }

  search(searchStr: string, prefix: string): void {
    const phone = this.formatPhoneNumber(searchStr, prefix);
    let newSearchString = this.searchService.buildNewPhoneKeyword(
      prefix,
      phone
    );

    if (+newSearchString) {
      newSearchString =
        newSearchString === phone &&
        !(newSearchString.substring(0, prefix.length) === prefix)
          ? prefix + phone
          : newSearchString;
    } else {
      newSearchString = searchStr;
    }

    this.quedroSearch(newSearchString);
  }

  searchExtended(
    searchStr: string,
    prefix: string,
    type: string,
    isValidPhoneNumber: boolean
  ): void {
    switch (type) {
      case "number":
        const phone = this.formatPhoneNumber(searchStr, prefix);
        this.eniroSearch({
          searchtype: "nr",
          telnr: this.searchService.buildNewPhoneKeyword(prefix, phone),
          isValidPhoneNumber,
        });
        break;
      case "firstname":
        this.eniroSearch({ searchtype: "pers", firstname: searchStr });
        break;
      case "lastname":
        this.eniroSearch({ searchtype: "pers", lastname: searchStr });
        break;
      case "freetext":
      case "email":
        break;
    }
  }

  quedroSearch(keyword: String): void {
    this.quedroLoading$.next(true);

    this.apiService
      .get("search", { groups: "consumers", listNumber: 10, keyword })
      .pipe(map((response: any) => response.matches.contacts?.entities))
      .subscribe({
        next: (list: Contact[]) =>
          this.quedroContacts$.next(list.map((c) => new Contact(c))),
        error: () => {
          this.quedroContacts$.next([]);
          this.quedroLoading$.next(false);
        },
        complete: () => this.quedroLoading$.next(false),
      });
  }

  eniroSearch(params: any): void {
    combineLatest([
      this.searchAllowed(features.ENIRO),
      this.store.pipe(select(getCountry)),
      observableOf(this.configService.isSearchIntegrationsActive()),
    ])
      .pipe(
        take(1),
        map(([allowed, countryCode, active]) => ({
          allowed,
          countryCode,
          active,
        }))
      )
      .subscribe(
        (data: { allowed: boolean; countryCode: string; active: boolean }) => {
          if (data.allowed && params.isValidPhoneNumber && data.active) {
            this.eniroLoading$.next(true);
            this.apiService
              .get(
                "contact-search/contacts",
                {
                  what: params.telnr,
                },
                "integrations"
              )
              .subscribe({
                next: (list: SearchProviderContact[]) =>
                  this.eniroContacts$.next(
                    list.map((c) => new SearchProviderContact(c))
                  ),
                error: () => {
                  this.eniroContacts$.next([]);
                  this.eniroLoading$.next(false);
                },
                complete: () => this.eniroLoading$.next(false),
              });
          }
          if (!params.isValidPhoneNumber) {
            this.eniroContacts$.next([]);
          }
        }
      );
  }

  eniroCompanySearchById(id: string): void {
    combineLatest([
      this.searchAllowed(features.ENIRO),
      this.store.pipe(select(getCountry)),
      this.store.select(
        hasContactSearchCapability(
          IntegrationContactSearchCapabilities.CompanyByIdLookup
        )
      ),
    ])
      .pipe(
        take(1),
        map(([allowed, countryCode, active]) => ({
          allowed,
          countryCode,
          active,
        }))
      )
      .subscribe(
        (data: { allowed: boolean; countryCode: string; active: boolean }) => {
          if (data.allowed && data.active) {
            this.eniroLoading$.next(true);
            this.apiService
              .get(`contact-search/companies/${id}`, {}, "integrations")
              .subscribe({
                next: (list: SearchProviderContact[]) =>
                  this.eniroContacts$.next(
                    list.map((c) => new SearchProviderContact(c))
                  ),
                error: () => {
                  this.eniroContacts$.next([]);
                  this.eniroLoading$.next(false);
                },
                complete: () => this.eniroLoading$.next(false),
              });
          }
        }
      );
  }

  getQuedroContacts(): Observable<Contact[]> {
    return this.quedroContacts$.asObservable();
  }

  getEniroContacts(): Observable<SearchProviderContact[]> {
    return this.eniroContacts$.asObservable();
  }

  getLoading(): Observable<boolean> {
    return combineLatest([this.quedroLoading$, this.eniroLoading$]).pipe(
      map(([quedroLoading, eniroLoading]) => quedroLoading || eniroLoading)
    );
  }

  searchAllowed(type: string): Observable<boolean> {
    return this.store.pipe(
      select(getFeature(type)),
      map((feature: Feature) => feature.enabled || false)
    );
  }

  resetData() {
    const lists = [this.quedroContacts$, this.eniroContacts$];

    lists.forEach((list: Subject<any[]>) => list.next([]));
  }

  formatPhoneNumber(phoneNumber: string, prefix: string): string {
    const regex = /-|\s|^00|^0|\+/g;
    let result = phoneNumber;

    if (result.match(regex)) {
      result = result.replace(regex, "");
    }

    if (prefix.toString().startsWith("+")) {
      prefix = prefix.replace("+", "").trim();
    }

    if (result.startsWith(prefix)) {
      result = result.replace(prefix, "");
    }

    return result;
  }
}
