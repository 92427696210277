import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  exhaustMap,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as leadResultOptionsActions from "@app/settings/lead-channel-result-options/ngrx/lead-channel-result-options.actions";
import { LEAD_RESULT_OPTIONS } from "@app/shared/utils/tab-types";
import * as actions from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.actions";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { TaskResult } from "@app/models";

@Injectable()
export class LeadResultOptionsFormEffects {
  getLeadResultOptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getLeadResultOptionRequest),
      switchMap(({ channelId, id }) =>
        this.apiService.get(`task-types/${channelId}/results/${id}`).pipe(
          map((response: TaskResult) =>
            actions.getLeadResultOptionSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.getLeadResultOptionFail()))
        )
      )
    )
  );

  createLeadResultOptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadResultOptionRequest),
      exhaustMap(({ id, body }) =>
        this.apiService.post(`task-types/${id}/results`, body).pipe(
          map((response: TaskResult) =>
            actions.createLeadResultOptionSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.createLeadResultOptionFail()))
        )
      )
    )
  );

  createLeadResultOptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadResultOptionSuccess),
      switchMap(({ data: { eaTaskTypeId } }) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          this.getLeadResultOptions(eaTaskTypeId),
          toastActions.success({
            message: "create_lead_result_option_success",
          }),
        ])
      )
    )
  );

  createLeadResultOptionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadResultOptionFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          toastActions.danger({ message: "create_lead_result_option_fail" }),
        ])
      )
    )
  );

  editLeadResultOptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadResultOptionRequest),
      exhaustMap(({ channelId, id, body }) =>
        this.apiService
          .patch(`task-types/${channelId}/results/${id}`, body)
          .pipe(
            map((response: TaskResult) =>
              actions.editLeadResultOptionSuccess({ data: response })
            ),
            catchError(() => observableOf(actions.editLeadResultOptionFail()))
          )
      )
    )
  );

  editLeadResultOptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadResultOptionSuccess),
      switchMap(({ data: { eaTaskTypeId } }) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          this.getLeadResultOptions(eaTaskTypeId),
          toastActions.success({ message: "edit_lead_result_option_success" }),
        ])
      )
    )
  );

  editLeadResultOptionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadResultOptionFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          toastActions.danger({ message: "edit_lead_result_option_fail" }),
        ])
      )
    )
  );

  deleteLeadResultOptionRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadResultOptionRequest),
      exhaustMap(({ channelId, id }) =>
        this.apiService.delete(`task-types/${channelId}/results/${id}`).pipe(
          map(() => actions.deleteLeadResultOptionSuccess({ channelId })),
          catchError(() => observableOf(actions.deleteLeadResultOptionFail()))
        )
      )
    )
  );

  deleteLeadResultOptionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadResultOptionSuccess),
      switchMap(({ channelId }) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          this.getLeadResultOptions(channelId),
          toastActions.success({
            message: "delete_lead_result_option_success",
          }),
        ])
      )
    )
  );

  deleteLeadResultOptionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadResultOptionFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_RESULT_OPTIONS }),
          toastActions.danger({ message: "delete_lead_result_option_fail" }),
        ])
      )
    )
  );

  getLeadResultOptions(id: number | string) {
    return leadResultOptionsActions.getLeadChannelResultOptionsRequest({ id });
  }

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
