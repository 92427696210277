import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ClickService } from "@app/core/services/click/click.service";
import { filter, Subject, takeUntil } from "rxjs";

@Component({
  selector: "page-nav-bar-item-dropdown",
  templateUrl: "./page-nav-bar-item-dropdown.component.html",
  styleUrls: [
    "../page-nav-bar-item/page-nav-bar-item.component.scss",
    "./page-nav-bar-item-dropdown.component.scss",
  ],
})
export class PageNavBarItemDropdownComponent implements OnInit, OnDestroy {
  @HostBinding("class.disabled")
  @Input()
  disabled = false;

  isOpen = false;
  unsubscribe$ = new Subject<void>();

  constructor(
    private elementRef: ElementRef,
    private clickService: ClickService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.clickService.source$
      .pipe(
        filter(() => !this.disabled),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event) => {
        if (!this.elementRef.nativeElement.contains(event.target)) {
          this.isOpen = false;
          this.cdr.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  toggle(): void {
    if (!this.disabled) {
      this.isOpen = !this.isOpen;
    }
  }
}
