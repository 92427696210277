import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { ActionsSubject, Store } from "@ngrx/store";
import { first, Observable, tap } from "rxjs";
import { closeTab } from "../../ngrx/sidebar.actions";
import * as eoActions from "./ngrx/employees-office-create.actions";
import { ofType } from "@ngrx/effects";

@Injectable()
export class EmployeesOfficeCreateResolverService
  implements Resolve<Observable<any>>
{
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const { eaEmployeeId } = route.params;
    this.store.dispatch(eoActions.getEmployeeRequest({ eaEmployeeId }));

    return this.dispatcher.pipe(
      ofType(eoActions.getEmployeeSuccess, eoActions.getEmployeeFailed),
      first(),
      tap((action) => {
        if (action.type === eoActions.getEmployeeFailed) {
          this.store.dispatch(closeTab({ tabType: EMPLOYEES }));
        }
      })
    );
  }
}
