import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { CreateContactModule } from "../contacts/create-contact.module";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { SendTipsCreateContactComponent } from "./send-tips-create-contact/send-tips-create-contact.component";
import { SendTipsHomeComponent } from "./send-tips-home/send-tips-home.component";
import { SendTipsNewComponent } from "./send-tips-new/send-tips-new.component";
import { SendTipsComponent } from "./send-tips.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    SearchContactModule,
    CreateContactModule,
    SidebarSharedModule,
    ContactComponentsModule,
    FormComponentsModule,
    UtilModule,
    UiComponentsModule,
  ],
  exports: [
    SendTipsComponent,
    SendTipsHomeComponent,
    SendTipsNewComponent,
    SendTipsCreateContactComponent,
  ],
  declarations: [
    SendTipsCreateContactComponent,
    SendTipsComponent,
    SendTipsHomeComponent,
    SendTipsNewComponent,
  ],
  providers: [],
})
export class SendTipsModule {}
