import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getLatestConsumerCallActionRequest } from "@app/contacts/contact.actions";
import * as fromContact from "@app/contacts/contact.selectors";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact, getAddress } from "@app/models";
import {
  ContactMaritalStatus,
  ContactOwnsResidence,
  ContactSex,
} from "@app/models/contact-dropdown-values";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SIDEBAR_CONTACTS_EDIT_URL } from "@app/shared/utils/sidebar-tab-utils";
import { isNotNullOrUndefined } from "@app/shared/utils/string-utils";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as i18nISOCountries from "i18n-iso-countries";
import moment from "moment";
import { filter, first, map, Observable, Subject, withLatestFrom } from "rxjs";

@Component({
  selector: "app-contact-details-widget",
  templateUrl: "./contact-details-widget.component.html",
  styleUrls: ["./contact-details-widget.component.scss"],
})
export class ContactDetailsWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() contactId: string;

  editingField: string = null;
  loading$: Observable<boolean>;
  contact$: Observable<Contact>;
  latestContactedDate$: Observable<string>;
  language$: Observable<string>;
  preferredLanguage$: Observable<string>;
  unsubscribe$: Subject<void> = new Subject<void>();

  getAddress = getAddress;
  contactSex = ContactSex;
  contactMaritalStatus = ContactMaritalStatus;
  contactOwnsResidence = ContactOwnsResidence;

  moment = moment;
  readonly birthDayFormat = "YYYYMMDD";

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes): void {
    if (changes.contactId && this.contactId) {
      this.contactService.getProfile(this.contactId).subscribe();
      this.contact$ = this.contactService.entityMap$.pipe(
        map((entities) => entities[this.contactId])
      );
      this.store.dispatch(
        getLatestConsumerCallActionRequest({ contactId: this.contactId })
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getGoogleMapsUrl(contact: Contact) {
    return (
      "https://www.google.com/maps/place/" +
      encodeURIComponent(getAddress(contact.street, contact.zip, contact.city))
    );
  }

  getPhoneNumberTooltip(contact: Contact, isMsisdn: boolean): string {
    const countryCode = isMsisdn
      ? contact.msisdnCountry
      : contact.phoneNumberCountry;
    const number = isMsisdn ? contact.msisdn : contact.phoneNumber;

    if (!countryCode) {
      return null;
    }

    return this.translateService.instant("click_to_call", {
      country: i18nISOCountries.getName(
        countryCode,
        this.translateService.currentLang
      ),
      number: "+" + number,
    });
  }

  isBirthDayToday(dateOfBirth: string): boolean {
    if (!moment(dateOfBirth, this.birthDayFormat).isValid()) {
      return false;
    }

    const birthDay = moment(dateOfBirth, this.birthDayFormat)
      .set("year", moment().year())
      .format(this.birthDayFormat);
    const today = moment().format(this.birthDayFormat);
    return birthDay === today;
  }

  getAgeByDateOfBirth(dateOfBirth: string): number {
    return Math.abs(
      moment(dateOfBirth, this.birthDayFormat).diff(moment(), "years")
    );
  }

  getFormattedBirthDay(contact: Contact): string {
    let birthDayInfo: string;
    if (this.isBirthDayToday(contact.dateOfBirth)) {
      birthDayInfo = this.translateService.instant("today");
    } else if (this.daysUntilNextBirthDay(contact.dateOfBirth) === 1) {
      birthDayInfo = this.translateService.instant("tomorrow");
    } else {
      birthDayInfo = this.translateService.instant("next_birth_day_in");
      birthDayInfo +=
        " " +
        this.translateService.instant("in_X_days", {
          amount: this.daysUntilNextBirthDay(contact.dateOfBirth),
        });
    }

    return (
      this.translateService.instant("birthdate") +
      ": " +
      moment(contact.dateOfBirth, this.birthDayFormat).format("YYYY-MM-DD") +
      " (" +
      birthDayInfo +
      ")"
    );
  }

  daysUntilNextBirthDay(dateOfBirth: string): number {
    const now = moment();
    const nextBirthday = moment(dateOfBirth, this.birthDayFormat).year(
      now.year()
    );

    if (now.isAfter(nextBirthday)) {
      nextBirthday.add(1, "year");
    }

    return nextBirthday.diff(now, "days");
  }

  private mapStateToProps() {
    this.loading$ = this.store.pipe(select(fromContact.getLoading));
    this.latestContactedDate$ = this.store.pipe(
      select(fromContact.getLatestContactedDate)
    );
    this.language$ = this.store.pipe(
      select(fromConfig.getLanguage),
      filter((lang: string) => isNotNullOrUndefined(lang))
    );
    this.preferredLanguage$ = this.contact$.pipe(
      withLatestFrom(this.language$),
      map(([contact, lang]) => {
        return i18nISOCountries.getName(contact.preferredLanguage, lang);
      })
    );
  }

  patchContact(field: string, value: any) {
    this.contact$.pipe(first()).subscribe((contact) => {
      this.contactService
        .patch(contact.contactId, { origin: contact.origin, [field]: value })
        .subscribe();
    });
  }

  patchContactProfile(field: string, value: any) {
    this.contact$.pipe(first()).subscribe((contact) => {
      this.contactService
        .patchProfile(contact.contactId, { [field]: value })
        .subscribe();
    });
  }

  editContact() {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_CONTACTS_EDIT_URL(this.contactId) } },
        ],
      })
    );
  }
}
