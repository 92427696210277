import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ChipColor } from "@app/shared/modules/ui-components/q-chip/q-chip.component";

@Component({
  selector: "app-q-chip-rounded",
  templateUrl: "./q-chip-rounded.component.html",
  styleUrls: ["./q-chip-rounded.component.scss"],
})
export class QChipRoundedComponent {
  @Output() removeClicked: EventEmitter<void> = new EventEmitter<void>();

  @Input() text: string;
  @Input() removable = false;
  @Input() color: ChipColor = "1";
}
