import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { Contact, QObject, TypedPaginationListDTO } from "@app/models";
import { BankAdvisor } from "@app/settings/banks/manage-banks/models/bank-advisor";
import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import { EIKA_SEND_TIPS } from "@app/shared/utils/tab-types";
import * as eikaActions from "@app/sidebar/eika/ngrx/eika.actions";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";

@Injectable()
export class EikaEffects {
  getBanks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.getBanksRequest),
      switchMap(() =>
        this.apiService
          .get(
            "external-tips/parent-receivers/preferred",
            {},
            Categories.Integrations
          )
          .pipe(
            map((response: ExternalBank[]) =>
              eikaActions.getBanksSuccess({ externalBanks: response })
            ),
            catchError(() => of(eikaActions.getBanksFail()))
          )
      )
    )
  );

  getAdvisors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.getAdvisorsRequest),
      switchMap(({ bankId }) =>
        this.apiService
          .get(
            `external-tips/parent-receiver/${bankId}/tip-receivers`,
            {},
            Categories.Integrations
          )
          .pipe(
            map((response: BankAdvisor[]) =>
              eikaActions.getAdvisorsSuccess({ bankAdvisors: response })
            ),
            catchError(() => of(eikaActions.getAdvisorsFail()))
          )
      )
    )
  );

  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.getContactRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}`, {}).pipe(
          map((response: Contact) =>
            eikaActions.getContactSuccess({ contact: response })
          ),
          catchError(() => of(eikaActions.getContactFail()))
        )
      )
    )
  );

  getObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.getObjectRequest),
      switchMap(({ eaOid }) =>
        this.apiService.get(`objects/${eaOid}`, {}, "api").pipe(
          map((response: QObject) =>
            eikaActions.getObjectSuccess({ qObject: response })
          ),
          catchError(() => of(eikaActions.getObjectFail()))
        )
      )
    )
  );

  getOfficeObjectsForSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.getOfficeObjectsForSaleRequest),
      switchMap(({ eaOfficeId }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaOfficeId,
              status: 10,
              getResponsibleBroker: true,
              seller: true,
            },
            "api"
          )
          .pipe(
            map((response: TypedPaginationListDTO<QObject>) =>
              eikaActions.getOfficeObjectsForSaleSuccess({
                payload: response.rows,
              })
            ),
            catchError(() => of(eikaActions.getOfficeObjectsForSaleFail()))
          )
      )
    )
  );

  createContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.createContactRequest),
      switchMap(({ params }) =>
        this.apiService.post(`contact`, params).pipe(
          map(() => eikaActions.createContactSuccess()),
          catchError(() => of(eikaActions.createContactFail()))
        )
      )
    )
  );

  updateContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.updateContactRequest),
      switchMap(({ params, contactId }) =>
        this.apiService.patch(`contacts/${contactId}`, params).pipe(
          map((contact: Contact) =>
            eikaActions.updateContactSuccess({ contact })
          ),
          catchError(() => of(eikaActions.updateContactFail()))
        )
      )
    )
  );

  sendTip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.sendTipRequest),
      switchMap(({ params }) => {
        let path: string;
        const { contactId, eaOid, bank, advisor } = { ...params.ids };

        if (advisor) {
          if (eaOid) {
            path = `external-tips/send-tip/to/${advisor}/at/${bank}/for-contact/${contactId}/with-object/${eaOid}`;
          } else {
            path = `external-tips/send-tip/to/${advisor}/at/${bank}/for-contact/${contactId}`;
          }
        } else {
          if (eaOid) {
            path = `external-tips/send-tip/unassigned/at/${bank}/for-contact/${contactId}/with-object/${eaOid}`;
          } else {
            path = `external-tips/send-tip/unassigned/at/${bank}/for-contact/${contactId}`;
          }
        }

        return this.apiService
          .post(path, params.body, Categories.Integrations)
          .pipe(
            map(() => eikaActions.sendTipSuccess()),
            catchError(() => of(eikaActions.sendTipFail()))
          );
      })
    )
  );

  sendTipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.sendTipSuccess),
      mergeMap(() => [
        toastActions.success({ message: "tip_sent_to_eika" }),
        closeTab({ tabType: EIKA_SEND_TIPS }),
      ])
    )
  );

  sendTipFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(eikaActions.sendTipFail),
      map(() => toastActions.danger({ message: "tip_sent_to_eika_failed" }))
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
