<div>
  <h4>{{'contact_history' | translate }}</h4>
  <ul>
    <ng-container *ngIf="actions.length > 0; else noContent">
      <contact-action-list-item
        *ngFor="let action of (showAll ? actions : actions.slice(0,3) )"
        [action]="action"
      >
      </contact-action-list-item>
    </ng-container>
    <ng-template #noContent>
      <li class="empty">
        <div class="flex-center">
          {{ 'no_contact_history' | translate }}
        </div>
      </li>
    </ng-template>
  </ul>
  <ng-container *ngIf="actions">
    <list-load-more-small
      *ngIf="actions.length > 3"
      (loadMore)="showAll = !showAll"
    >
      <ng-container *ngIf="showAll; else showLess">
        {{ 'show_less' | translate }}
      </ng-container>

      <ng-template #showLess>
        {{ 'show_more' | translate }} ({{actions.length - 3}})
      </ng-template>
    </list-load-more-small>
  </ng-container>
</div>