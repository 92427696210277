import { Component, HostBinding, Inject } from "@angular/core";
import { StickyNotificationData } from "@app/shared-features/sticky-notification/sticky-notification.service";
import { STICKY_NOTIFICATION_DATA } from "@app/shared-features/sticky-notification/sticky-notification.tokens";
import { slideUpDownTrigger } from "@app/shared/animations";

@Component({
  selector: "sticky-notification",
  templateUrl: "./sticky-notification.component.html",
  styleUrls: ["./sticky-notification.component.scss"],
  animations: [slideUpDownTrigger()],
})
export class StickyNotificationComponent {
  @HostBinding("@slideUpDown") true;

  constructor(
    @Inject(STICKY_NOTIFICATION_DATA) public data: StickyNotificationData
  ) {}
}
