import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { UrlService } from "@app/core/services/url/url.service";
import * as fromShortcutsSetting from "./ngrx/shortcuts-widget.reducer";
import { openLinkInNewTab as handleOpenInNewTab } from "@app/shared/utils/url-utils";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import {
  CustomizedShortcuts,
  ShortcutLink,
} from "@app/shared/config/models/shortcuts-feature";
import { ShortcutsSortingModalComponent } from "@app/widgets/widgets/shortcuts-widget/shortcuts-sorting-modal/shortcuts-sorting-modal.component";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { getShortcutsRequest } from "@app/widgets/widgets/shortcuts-widget/ngrx/shortcuts-widget.actions";

@Component({
  selector: "app-shortcuts-widget",
  templateUrl: "./shortcuts-widget.component.html",
  styleUrls: ["./shortcuts-widget.component.scss"],
})
export class ShortcutsWidgetComponent implements OnInit, OnDestroy {
  @Input() showHeader = false;
  @Input() openLinkInNewTab = false;

  isLoading$: Observable<boolean>;
  predefinedShortcuts$: Observable<ShortcutLink[]>;
  shortcuts$ = new BehaviorSubject<ShortcutLink[]>([]);
  unsubscribe$ = new Subject<void>();

  private readonly localStorageKey = "agent-shortcuts-data";

  constructor(
    private modalService: QModalService,
    private store: Store<AppState>,
    private urlService: UrlService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.fetchShortcutSetting();
    this.formatShortcuts();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openInNewTab(segments: unknown[], queryParams: Record<string, unknown>) {
    const url = this.urlService.buildUrlFromRouterWithQuery(
      segments,
      queryParams
    );

    handleOpenInNewTab(url);
  }

  handleClick(shortcut: ShortcutLink, event: Event) {
    event.preventDefault();
    if (this.openLinkInNewTab) {
      this.openInNewTab(shortcut.link, shortcut.queryParams);
      return;
    }

    this.store.dispatch(
      RouterActions.go({
        path: shortcut.link,
        query: shortcut.queryParams,
      })
    );
  }

  private fetchShortcutSetting() {
    this.store.dispatch(getShortcutsRequest());
  }

  private formatShortcuts() {
    this.predefinedShortcuts$
      .pipe(
        map((shortcuts) => {
          const items: ShortcutLink[] = shortcuts || [];
          const deepCopy: ShortcutLink[] = JSON.parse(JSON.stringify(items));
          return deepCopy;
        }),
        map((shortcuts) => {
          if (shortcuts.length === 0) {
            return shortcuts;
          }

          const customData = JSON.parse(
            localStorage.getItem(this.localStorageKey)
          ) as CustomizedShortcuts;

          if (customData) {
            if (customData?.hiddenShortcuts) {
              //set the visibility based on customData.hiddenShortcuts
              shortcuts.forEach((item) => {
                item.visible = !customData.hiddenShortcuts.includes(item.id);
              });
            }

            if (customData?.displayOrders) {
              customData?.displayOrders.forEach((id, sortId) => {
                const shortcut = shortcuts.find((item) => item.id === id);
                if (shortcut) {
                  shortcut.displayOrder = sortId;
                }
              });
            }
          }

          return shortcuts
            .slice()
            .sort((a, b) => a?.displayOrder - b?.displayOrder);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((shortcuts) => this.shortcuts$.next(shortcuts));
  }

  handleEdit(event: MouseEvent) {
    event.stopPropagation();
    combineLatest([
      this.predefinedShortcuts$.pipe(
        map((predefinedShortcuts) =>
          predefinedShortcuts
            .slice()
            .sort((a, b) => a?.displayOrder - b?.displayOrder)
        )
      ),
      this.shortcuts$,
    ])
      .pipe(first())
      .subscribe(([systemDefault, shortcuts]) => {
        const columns = shortcuts.map((shortcut) => shortcut.id);
        const displayed = shortcuts
          .filter((shortcut) => shortcut.visible)
          .map((shortcut) => shortcut.id);
        const headers = systemDefault.reduce(
          (result, shortcut) => ({
            ...result,
            [shortcut.id]: shortcut.tooltip,
          }),
          {}
        );

        this.modalService.show(ShortcutsSortingModalComponent, {
          data: {
            original: columns,
            displayed: displayed,
            headers: headers,
            systemDefault: systemDefault,
            hasHeader: true,
            hasHeaderCloseButton: true,
            hasActionBar: true,
          },
        });

        this.modalService
          .getDialogRef()
          .afterClosed()
          .pipe(
            first(),
            filter((result) => !!result)
          )
          .subscribe((result) => {
            if (result?.event === "save") {
              localStorage.setItem(
                this.localStorageKey,
                JSON.stringify(result.data)
              );

              this.fetchShortcutSetting();
            }
          });
      });
  }

  private mapStateToProps() {
    this.isLoading$ = this.store.pipe(
      select(fromShortcutsSetting.getIsLoading)
    );
    this.predefinedShortcuts$ = this.store.pipe(
      select(fromShortcutsSetting.getPredefinedShortcuts)
    );
  }
}
