import { SearchProfile } from "@app/contacts/contact-search-profile/models/search-profile";
import * as contactSearchProfileActions from "@app/contacts/contact-search-profile/ngrx/contact-search-profile.actions";
import { QObject } from "@app/models";
import { CategoryType } from "@app/models/category-type";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";

export interface RelatedObjectsResponse {
  total: number;
  limit: number;
  offset: number;
  objectsMultiLocations: Partial<QObject>[];
}

export interface ContactSearchProfilesState {
  loaded: boolean;
  loading: boolean;
  loadingSelectedSearchProfile: boolean;
  selectedSearchProfile: SearchProfile | null;
  searchProfileList: SearchProfile[];
  relatedObjectsFilter: {} | null;
  relatedWaitingObjectsFilter: {} | null;
  alerts: Alert[];
  additionalFilters: CategoryType[];
  latestGetSearchProfilesAction: Action | null;
  latestGetSearchProfileAction: Action | null;
  latestGetAlertsAction: Action | null;
}

export const initialState: ContactSearchProfilesState = {
  loaded: false,
  loading: false,
  loadingSelectedSearchProfile: false,
  selectedSearchProfile: null,
  searchProfileList: [],
  relatedObjectsFilter: null,
  relatedWaitingObjectsFilter: null,
  alerts: [],
  additionalFilters: [],
  latestGetSearchProfilesAction: null,
  latestGetSearchProfileAction: null,
  latestGetAlertsAction: null,
};

export function contactSearchProfilesReducer(
  state: ContactSearchProfilesState,
  action: Action
): ContactSearchProfilesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(contactSearchProfileActions.getSearchProfilesRequest, (state, action) => ({
    ...state,
    loading: true,
    latestGetSearchProfilesAction: action,
  })),
  on(
    contactSearchProfileActions.getSearchProfilesSuccess,
    (state, { searchProfiles }) => ({
      ...state,
      loading: false,
      loaded: true,
      searchProfileList: searchProfiles,
    })
  ),
  on(contactSearchProfileActions.getSearchProfilesFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(contactSearchProfileActions.getSearchProfileRequest, (state, action) => ({
    ...state,
    loading: true,
    loadingSelectedSearchProfile: true,
    latestGetSearchProfileAction: action,
  })),
  on(
    contactSearchProfileActions.getSearchProfileSuccess,
    (state, { searchProfile }) => ({
      ...state,
      loading: false,
      loaded: true,
      loadingSelectedSearchProfile: false,
      selectedSearchProfile: searchProfile,
    })
  ),
  on(contactSearchProfileActions.getSearchProfileFailed, (state) => ({
    ...state,
    loading: false,
    loadingSelectedSearchProfile: false,
  })),
  on(
    contactSearchProfileActions.clearSelectedSearchProfile,
    (state, { searchProfileId }) => {
      let searchProfileList = state.searchProfileList;
      let selectedSearchProfile = null;
      if (state.searchProfileList.length > 0 && searchProfileId) {
        searchProfileList = searchProfileList.filter(
          (profile) => profile.searchProfileId !== searchProfileId
        );
        selectedSearchProfile =
          searchProfileList.length > 0 ? searchProfileList[0] : null;
      }

      return {
        ...state,
        loading: false,
        loadingSelectedSearchProfile: false,
        selectedSearchProfile,
        searchProfileList,
      };
    }
  ),
  on(
    contactSearchProfileActions.setRelatedObjectsFilter,
    (state, { params }) => ({
      ...state,
      relatedObjectsFilter: params,
    })
  ),
  on(
    contactSearchProfileActions.setRelatedWaitingObjectsFilter,
    (state, { params }) => ({
      ...state,
      relatedWaitingObjectsFilter: params,
    })
  ),
  on(contactSearchProfileActions.getAlertsSuccess, (state, { alerts }) => ({
    ...state,
    alerts,
  })),
  on(contactSearchProfileActions.getAlertsRequest, (state, action) => ({
    ...state,
    latestGetAlertsAction: action,
  })),
  on(
    contactSearchProfileActions.getAdditionalFiltersRequest,
    contactSearchProfileActions.getAdditionalFiltersFail,
    contactSearchProfileActions.getAlertsFailed,
    (state) => ({
      ...state,
    })
  ),
  on(
    contactSearchProfileActions.getAdditionalFiltersSuccess,
    (state, { categoryTypes }) => ({
      ...state,
      additionalFilters: categoryTypes,
    })
  ),
  on(contactSearchProfileActions.resetSelectedProfile, (state) => ({
    ...state,
    loadingSelectedSearchProfile: false,
    selectedSearchProfile: null,
    relatedObjectsFilter: null,
    relatedWaitingObjectsFilter: null,
    latestGetSearchProfileAction: null,
  })),
  on(contactSearchProfileActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature = createFeatureSelector<ContactModuleState>("contact");
const getState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.searchProfiles
);

export const getLoading = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.loading
);
export const getSelectedSearchProfileLoading = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.loadingSelectedSearchProfile
);
export const getContactSearchProfileList = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.searchProfileList
);
export const getContactSelectedSearchProfile = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.selectedSearchProfile
);
export const getSearchProfileRelatedObjectsFilter = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.relatedObjectsFilter
);
export const getSearchProfileRelatedWaitingObjectsFilter = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.relatedWaitingObjectsFilter
);
export const getContactAlerts = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.alerts
);
export const getLatestGetSearchProfilesAction = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.latestGetSearchProfilesAction
);
export const getLatestGetSearchProfileAction = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.latestGetSearchProfileAction
);
export const getLatestGetAlertsAction = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.latestGetAlertsAction
);
export const getAdditionalFilters = createSelector(
  getState,
  (state: ContactSearchProfilesState) => state.additionalFilters
);
