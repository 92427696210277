<div
  class="actions-item"
  [class.actions-item--pinned]="action.isPinned === true"
  [tooltip]="
    action.param3 === 'private' &&
    (eaEmployeeId$ | async) !== action.eaEmployeeId
      ? ('private_note_not_yours' | translate)
      : null
  "
  placement="top"
>
  <section class="actions-item__icon-container">
    <i class="icon-doc"></i>
    <div class="actions-item__line"></div>
  </section>

  <section class="actions-item__text-container">
    <section>
      <h5>
        <app-icon
          *ngIf="action.param3 === 'private'"
          name="eye-slash"
        ></app-icon>
        <span class="pinned-icon">
          <app-icon
            [tooltip]="'pinned_note_tooltip' | translate"
            *ngIf="action.isPinned === true"
            name="thumbtack"
          ></app-icon>
        </span>
        <span translate>{{ action.type | lowercase }}</span>
        <ng-container
          *ngIf="
            action.eaOid && action.eaOid.length > 0 && action.street.length > 0
          "
        >
          <span translate> for</span>
          <a
            [routerLink]="[
              '/crm',
              {
                outlets: {
                  primary: ['showings', action.eaOid],
                  modal: null,
                  sidebar: null
                }
              }
            ]"
            class="inline"
          >
            {{ action.street }}
          </a>
        </ng-container>
      </h5>
      <div class="text-muted actions-item__description">
        <small>
          <span tooltip="{{ action.timestamp }}">{{ action.timestamp }}, </span>
          <span>
            {{ "by_X" | translate : { name: action.employeeFullName } }}
          </span>
        </small>
      </div>
      <div class="flex-container flex-space-between actions-item__content">
        <span
          class="private-note"
          *ngIf="
            action.param3 === 'private' &&
              (eaEmployeeId$ | async) !== action.eaEmployeeId;
            else showNote
          "
          translate
        >
          private_note_label
        </span>
      </div>
    </section>
    <app-actions-menu *ngIf="(eaEmployeeId$ | async) === action.eaEmployeeId">
      <div buttons>
        <li *ngIf="!action.isPinned">
          <button
            (click)="patchNote({ isPinned: true })"
            class="btn btn-secondary"
          >
            <app-icon name="thumbtack"></app-icon>
            {{ "pin_note" | translate }}
          </button>
        </li>
        <li *ngIf="!!action.isPinned">
          <button
            (click)="patchNote({ isPinned: false })"
            class="btn btn-secondary"
          >
            <app-icon name="thumbtack"></app-icon>
            {{ "unpin_note" | translate }}
          </button>
        </li>

        <li>
          <button (click)="onDelete()" class="btn btn-danger">
            <app-icon name="trash"></app-icon>
            {{ "delete" | translate }}
          </button>
        </li>
      </div>
    </app-actions-menu>
  </section>
</div>

<ng-template #showNote>
  <p [innerHtml]="action.param1 | nl2br | backslash"></p>
</ng-template>
