import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as confirmActions from "./confirm-modal.actions";

export interface ConfirmState {
  header: string;
  message: string;
  newTabUrl: string;
  show: boolean;
  state: boolean;
}

export const initialState: ConfirmState = {
  header: "confirm",
  message: "unsaved_changes_text",
  newTabUrl: undefined,
  show: false,
  state: undefined,
};

export function confirmReducer(
  state: ConfirmState,
  action: Action
): ConfirmState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(confirmActions.show, (state, { header, message, newTabUrl }) => ({
    ...state,
    header,
    message,
    newTabUrl,
    show: true,
    state: undefined,
  })),
  on(confirmActions.confirm, (state) => ({
    ...state,
    show: false,
    state: true,
  })),
  on(confirmActions.cancel, (state) => ({
    ...state,
    show: false,
    state: false,
  })),
  on(confirmActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature = createFeatureSelector<ConfirmState>("confirm");

export const getHeader = createSelector(
  selectFeature,
  (state: ConfirmState) => state.header
);
export const getMessage = createSelector(
  selectFeature,
  (state: ConfirmState) => state.message
);
export const getNewTabUrl = createSelector(
  selectFeature,
  (state: ConfirmState) => state.newTabUrl
);
export const showConfirm = createSelector(
  selectFeature,
  (state: ConfirmState) => state.show
);
export const confirmState = createSelector(
  selectFeature,
  (state: ConfirmState) => state.state
);
