import { Pipe, PipeTransform } from "@angular/core";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import moment from "moment";

@Pipe({ name: "momentifyFromApiFormat" })
export class MomentifyFromApiFormatPipe implements PipeTransform {
  transform(value: string, onlyDate: boolean = false): string {
    if (!!value) {
      if (!!onlyDate) {
        return moment(value, API_DATE_FORMAT).format("L");
      } else {
        return (
          moment(value, API_DATE_FORMAT).format("L").toUpperCase() +
          " " +
          moment(value, API_DATE_FORMAT).format("LT").toUpperCase()
        );
      }
    } else {
      return null;
    }
  }
}
