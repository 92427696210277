import * as widgetActions from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface ObjectStatusStatistics {
  statusName: string;
  status: string;
  numberOfObjects: string;
  turnoverTime: string;
  soldRate: string;
  createdYesterday: string;
  createdLast7days: string;
  estimatedTurnover: string;
}

export interface ObjectStatusStatisticsState {
  stats: ObjectStatusStatistics[];
  loading: boolean;
}

export const initialState: ObjectStatusStatisticsState = {
  loading: false,
  stats: null,
};

export function objectStatusReducer(
  state,
  action: Action
): ObjectStatusStatisticsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(widgetActions.fetchStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(widgetActions.fetchStatisticsSuccess, (state, { stats }) => ({
    ...state,
    loading: false,
    stats,
  })),
  on(widgetActions.fetchStatisticsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.objectStatus
);

export const getStats = createSelector(
  getState,
  (state: ObjectStatusStatisticsState) => state.stats
);
export const getLoading = createSelector(
  getState,
  (state: ObjectStatusStatisticsState) => state.loading
);
