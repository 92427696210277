import { TransferLeadParams } from "@app/lists/lists-leads/ngrx/lists-leads.reducer";
import { PaginationListDTO } from "@app/models";
import { TaskType } from "@app/models/task-type";
import { createAction, props } from "@ngrx/store";

const defaultParams = {
  sortBy: "insertedDate",
  sortOrder: "desc",
};

export const getLeads = (filters: Record<string, unknown>) => {
  return getLeadsRequest({
    filters: {
      ...defaultParams,
      ...filters,
    },
  });
};

export const getLeadsRequest = createAction(
  "[Lists Leads] GET_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getLeadsSuccess = createAction(
  "[Lists Leads] GET_LEADS_SUCCESS",
  props<{ leads: PaginationListDTO }>()
);

export const getLeadsFailed = createAction("[Lists Leads] GET_LEADS_FAILED");

export const getMoreLeads = (filters: Record<string, unknown>) => {
  return getMoreLeadsRequest({
    filters: {
      ...defaultParams,
      ...filters,
    },
  });
};

export const getMoreLeadsRequest = createAction(
  "[Lists Leads] GET_MORE_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreLeadsSuccess = createAction(
  "[Lists Leads] GET_MORE_LEADS_SUCCESS",
  props<{ leads: PaginationListDTO }>()
);

export const getMoreLeadsFailed = createAction(
  "[Lists Leads] GET_MORE_LEADS_FAILED"
);

export const getLeadsTaskTypesRequest = createAction(
  "[Lists Leads] GET_LEADS_TASK_TYPES_REQUEST"
);

export const getLeadsTaskTypesSuccess = createAction(
  "[Lists Leads] GET_LEADS_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const getLeadsTaskTypesFailed = createAction(
  "[Lists Leads] GET_LEADS_TASK_TYPES_FAILED"
);

export const getAllLeadsContactIds = (filters: Record<string, unknown>) => {
  return getAllLeadsContactIdsRequest({
    filters: {
      ...defaultParams,
      ...filters,
    },
  });
};

export const getAllLeadsContactIdsRequest = createAction(
  "[Lists Leads] GET_ALL_LEADS_CONTACT_IDS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getAllLeadsContactIdsSuccess = createAction(
  "[Lists Leads] GET_ALL_LEADS_CONTACT_IDS_SUCCESS",
  props<{ contactIds: string[] }>()
);

export const getAllLeadsContactIdsFailed = createAction(
  "[Lists Leads] GET_ALL_LEADS_CONTACT_IDS_FAILED",
  props<{ message: string }>()
);

export const assignMultipleLeadsRequest = createAction(
  "[Lists Leads] ASSIGN_MULTIPLE_LEADS_REQUEST",
  props<{ eaTaskIds: string[]; params: TransferLeadParams }>()
);

export const assignMultipleLeadsSuccess = createAction(
  "[Lists Leads] ASSIGN_MULTIPLE_LEADS_SUCCESS"
);

export const assignMultipleLeadsFailed = createAction(
  "[Lists Leads] ASSIGN_MULTIPLE_LEADS_FAIL"
);

export const setSelectedLeads = createAction(
  "[Lists Leads] SET_SELECTED_LEADS",
  props<{ selectedEaTaskIds: string[] }>()
);

export const selectAllLeads = (filters: Record<string, unknown>) => {
  return selectAllLeadsRequest({
    filters: {
      ...defaultParams,
      ...filters,
    },
  });
};

export const selectAllLeadsRequest = createAction(
  "[Lists Leads] SELECT_ALL_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const selectAllLeadsSuccess = createAction(
  "[Lists Leads] SELECT_ALL_LEADS_SUCCESS",
  props<{ selectedEaTaskIds: string[] }>()
);

export const selectAllLeadsFail = createAction(
  "[Lists Leads] SELECT_ALL_LEADS_FAIL"
);

export const reset = createAction("[Lists Leads] RESET");
