import { Component, Input } from "@angular/core";

@Component({
  selector: "page-nav-bar-item-icon",
  template: '<i class="{{ icon }}"></i>',
  styleUrls: ["./page-nav-bar-item-icon.component.scss"],
})
export class PageNavBarItemIconComponent {
  @Input() icon: string;
}
