import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getCurrency } from "@app/shared/config/config.reducer";
import * as widgetActions from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { Observable, skip, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-statistics-revenue-widget",
  templateUrl: "./statistics-revenue-widget.component.html",
  styleUrls: ["./statistics-revenue-widget.component.scss"],
})
export class StatisticsRevenueWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() triggerDataFetch$: Observable<void>;

  currentRevenue$: Observable<number>;
  revenueDifference$: Observable<number>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;
  unsubscribe$ = new Subject<void>();
  previousPeriodStart = moment().subtract(13, "months").format("L");
  previousPeriodEnd = moment().subtract(1, "months").format("L");

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.eaEmployeeId || changes?.eaOfficeId) {
      this.getStatistics();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.currentRevenue$ = this.store.pipe(select(fromWidget.getStats));
    this.revenueDifference$ = this.store.pipe(select(fromWidget.getDifference));
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
    this.currency$ = this.store.pipe(select(getCurrency));
  }

  private getStatistics() {
    const defaultParams = {
      includeLastYearsData: false,
      returnTypes: "turnover",
    };
    this.store.dispatch(
      widgetActions.fetchStatisticsRequest({
        params: {
          ...defaultParams,
          ...this.getIds(),
          periodStart: moment().subtract(1, "year").format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        },
      })
    );
    this.store.dispatch(
      widgetActions.fetchPreviousStatisticsRequest({
        params: {
          ...defaultParams,
          ...this.getIds(),
          periodStart: moment().subtract(13, "months").format("YYYYMMDD"),
          periodEnd: moment().subtract(1, "months").format("YYYYMMDD"),
        },
      })
    );
  }

  getIds(): any {
    const params = {};
    if (!!this.eaEmployeeId) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }

  getIconClass(difference: number) {
    if (difference === 0) {
      return null;
    } else if (difference > 0) {
      return "icon-arrow-up";
    } else if (difference < 0) {
      return "icon-arrow-down";
    }
  }
}
