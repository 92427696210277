import { Component, Input } from "@angular/core";
import { getProgressBarType } from "@app/shared/utils/colors";

@Component({
  selector: "app-progress-widget-item",
  templateUrl: "./progress-widget-item.component.html",
  styleUrls: ["./progress-widget-item.component.scss"],
})
export class ProgressWidgetItemComponent {
  @Input() max: number;
  @Input() value: number;
  @Input() failed: number;
  @Input() label: string;

  getProgressBarType = getProgressBarType;
}
