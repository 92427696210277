import { Pipe, PipeTransform } from "@angular/core";

import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";

@Pipe({
  name: "find",
})
export class FindPipe implements PipeTransform {
  transform(value: string, options: Select) {
    return value
      ? options.filter((option) => {
          return option.label.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        })
      : options;
  }
}
