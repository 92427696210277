import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "app-quick-date-options",
  templateUrl: "./quick-date-options.component.html",
  styleUrls: ["./quick-date-options.component.scss"],
})
export class QuickDateOptionsComponent {
  @Output() dateClicked: EventEmitter<number> = new EventEmitter<number>();
}
