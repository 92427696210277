import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.reducer";
import { AuthService } from "@app/core/services/auth/auth.service";
import * as loginActions from "@app/login/ngrx/login.actions";
import { LOGIN_TYPES } from "@app/login/ngrx/login.effects";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import * as userActions from "./shared/user/index";

@Injectable()
export class AppGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router
  ) {}

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Observable<boolean> {
    if (!this.authService.isAuthenticated()) {
      return this.store
        .select((state) => state.login.loginMethod)
        .pipe(
          map((loginMethod) => {
            if (
              this.router.url !== "/admin/auth" &&
              loginMethod.supportedLoginType === LOGIN_TYPES.SSO_OAUTH
            ) {
              this.store.dispatch(
                loginActions.triggerLoginMethodRedirect({
                  redirectUrl: loginMethod.redirectUrl,
                })
              );
            } else {
              this.store.dispatch(
                userActions.unauthorized({ unauthorizedPath: state.url })
              );
              this.store.dispatch(userActions.logout());
            }

            return false;
          })
        );
    }
    return true;
  }
}
