import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as standardModalActions from "@app/core/components/standard-modal/ngrx/standard-modal.actions";
import { BodyLink } from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as errorActions from "@app/core/error-handling/ngrx/error.actions";
import { Tags } from "@app/core/error-handling/utils/types";
import * as routerActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import {
  Contact,
  Employee,
  QObject,
  SalesMeeting,
  SendListStatus,
  Task,
  TaskType,
  TypedPaginationListDTO,
} from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import {
  SIDEBAR_CONNECT_IN_EXTERNAL_PROVIDER_URL,
  SIDEBAR_CREATE_IN_EXTERNAL_PROVIDER_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  catchError,
  forkJoin as observableForkJoin,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  withLatestFrom,
} from "rxjs";
import * as createContactActions from "../../contacts/ngrx/create-contact.actions";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as smActions from "./sales-meeting.actions";
import { Feature } from "@app/shared/config/models";

@Injectable()
export class SalesMeetingEffects {
  saveSalesMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.saveSalesMeeting),
      switchMap(({ payload }) => {
        const { contactSourceRequest, residenceRequest } = payload;
        const requests = [
          this.apiService.post("tasks", contactSourceRequest),
          this.apiService.post("residences", residenceRequest),
        ];

        if (!contactSourceRequest && !residenceRequest) {
          return observableOf(
            smActions.createSourceAndResidenceSuccess({
              payload: { request: payload },
            })
          );
        }

        if (contactSourceRequest && contactSourceRequest["eaTaskId"]) {
          const { salesMeetingRequest } = payload;
          if (
            !!salesMeetingRequest["id"] &&
            !!salesMeetingRequest["saleEaTaskId"]
          ) {
            requests[0] = this.apiService
              .patch(`tasks/${salesMeetingRequest["saleEaTaskId"]}`, {
                eaCrmSalesMeetingId: salesMeetingRequest["id"],
                contactId: salesMeetingRequest["contactId"],
                eaEmployeeId: salesMeetingRequest["eaEmployeeId"],
                eaOfficeId: salesMeetingRequest["eaOfficeId"],
                eaOid: salesMeetingRequest["eaOid"],
                startTime: salesMeetingRequest["startTime"],
                deliveryDate: salesMeetingRequest["startTime"],
              })
              .pipe(map(() => contactSourceRequest));
          } else {
            requests[0] = observableOf(contactSourceRequest);
          }
        }

        if (!contactSourceRequest) {
          requests[0] = observableOf(null);
        }

        if (!residenceRequest) {
          requests[1] = observableOf(null);
        }

        return observableForkJoin(requests).pipe(
          map((responses: any) => ({
            request: payload,
            eaTaskId: contactSourceRequest ? responses[0].eaTaskId : null,
            eaOid: residenceRequest ? responses[1].eaOid : null,
          })),
          map((response) => _.omitBy(response, _.isNull)),
          map((response) =>
            smActions.createSourceAndResidenceSuccess({ payload: response })
          ),
          catchError((error) =>
            observableOf(
              smActions.createSalesMeetingFail({
                error,
                errorType: "contact-source-or-residence",
              })
            )
          )
        );
      })
    )
  );

  createSourceAndResidenceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createSourceAndResidenceSuccess),
      map(({ payload }) => {
        const salesMeetingRequest = payload.request["salesMeetingRequest"];
        return salesMeetingRequest["id"]
          ? smActions.updateSalesMeetingRequest({ payload })
          : smActions.createSalesMeetingRequest({ payload });
      })
    )
  );

  createSalesMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createSalesMeetingRequest),
      switchMap(({ payload }) => {
        const { request, ...params } = payload;
        const {
          salesMeetingRequest,
          reportSalesMeetingRequest,
          externalProviderName,
          openInExternalProvider,
          showConnectToObjInERPButton,
          modalConfig,
          objectOverViewEnabled,
        } = request as Record<string, unknown>;
        return this.apiService
          .post("sales-meetings", {
            ...params,
            ...(salesMeetingRequest as Record<string, unknown>),
          })
          .pipe(
            mergeMap((salesMeeting: SalesMeeting) =>
              observableFrom([
                smActions.createSalesMeetingSuccess({
                  salesMeeting,
                  externalProviderName: externalProviderName.toString() ?? "",
                  openInExternalProvider: !!openInExternalProvider,
                  modalConfig: !!modalConfig,
                  showConnectToObjInERPButton: !!showConnectToObjInERPButton,
                  objectOverViewEnabled: objectOverViewEnabled as Feature,
                }),
                smActions.createReportSalesMeetingRequest({
                  payload: {
                    ...(reportSalesMeetingRequest as Record<string, unknown>),
                    eaCrmSalesMeetingId: salesMeeting.eaCrmSalesMeetingId,
                  },
                }),
              ])
            ),
            catchError((error) =>
              observableOf(
                smActions.createSalesMeetingFail({
                  error,
                  errorType: "create",
                  extra: salesMeetingRequest,
                })
              )
            )
          );
      })
    )
  );

  updateSalesMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.updateSalesMeetingRequest),
      switchMap(({ payload }) => {
        const { request, ...params } = payload;
        const salesMeetingRequest = {
          ...(request["salesMeetingRequest"] as Record<string, unknown>),
        };
        if (!!salesMeetingRequest?.saleEaTaskId) {
          delete salesMeetingRequest["saleEaTaskId"];
        }

        return this.apiService
          .patch(`sales-meetings/${salesMeetingRequest.id}`, {
            ...params,
            ...salesMeetingRequest,
          })
          .pipe(
            map((salesMeeting: SalesMeeting) =>
              smActions.updateSalesMeetingSuccess({
                salesMeeting,
              })
            ),
            catchError((error) =>
              observableOf(
                smActions.updateSalesMeetingFail({
                  error,
                  errorType: "update",
                  extra: salesMeetingRequest,
                })
              )
            )
          );
      })
    )
  );

  createSalesMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createSalesMeetingSuccess),
      withLatestFrom(this.store.pipe(select(getFeature(SALES_MEETING)))),
      switchMap(([{ type, ...params }, salesMeetingConfig]) => {
        const redirectToMeeting =
          salesMeetingConfig.redirect_to_meeting_after_creation;
        const isOverviewEnabled =
          params.objectOverViewEnabled?.enabled ||
          !params.externalProviderName ||
          !params?.modalConfig;

        if (isOverviewEnabled) {
          const restOfActions = [
            toastActions.success({
              message: "sales_meeting_create_success",
            }),
          ];

          if (redirectToMeeting) {
            restOfActions.push(
              routerActions.go({
                path: [
                  "/crm",
                  {
                    outlets: {
                      primary: [
                        "contacts",
                        params.salesMeeting.contactId,
                        "sales-meetings",
                        params.salesMeeting.eaCrmSalesMeetingId,
                      ],
                    },
                  },
                ],
              })
            );
          }
          return restOfActions;
        } else {
          return [
            standardModalActions.show({
              header: this.translateService.instant(
                "external_provider_connection",
                {
                  name: params.externalProviderName,
                }
              ),
              bodyHtml: this.getModalBodyHtml(),
              descriptionTooltip: this.translateService.instant(
                "external_provider_modal_description",
                {
                  name: params.externalProviderName,
                }
              ),
              bodyLinks: params.openInExternalProvider
                ? []
                : params.showConnectToObjInERPButton
                ? [
                    this.getModalLink1(
                      params.externalProviderName,
                      params.salesMeeting.objectStreet
                    ),
                    this.getModalLink2(params.externalProviderName),
                  ]
                : [
                    this.getModalLink1(
                      params.externalProviderName,
                      params.salesMeeting.objectStreet
                    ),
                  ],
              bodyExternalLinks: params.openInExternalProvider
                ? [
                    this.getCreateInExternalProviderLink(
                      params.externalProviderName,
                      params.salesMeeting.eaCrmSalesMeetingId
                    ),
                  ]
                : [],
            }),
            sidebarActions.closeTab({ tabType: SALES_MEETING }),
            routerActions.go({
              path: [
                "/crm/contacts",
                params.salesMeeting.contactId,
                "sales-meetings",
                params.salesMeeting.eaCrmSalesMeetingId,
              ],
            }),
          ];
        }
      })
    )
  );

  createSalesMeetingFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createSalesMeetingFail),
      map(() => toastActions.danger({ message: "sales_meeting_create_fail" }))
    )
  );

  updateSalesMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.updateSalesMeetingSuccess),
      mergeMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: SALES_MEETING }),
          toastActions.success({ message: "sales_meeting_update_success" }),
        ])
      )
    )
  );

  updateSalesMeetingFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.updateSalesMeetingFail),
      map(() => toastActions.danger({ message: "sales_meeting_update_fail" }))
    )
  );

  createReportSalesMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createReportSalesMeetingRequest),
      switchMap(({ payload }) => {
        return this.apiService.post("tasks", payload).pipe(
          map(() => smActions.createReportSalesMeetingSuccess()),
          catchError((error) =>
            observableOf(
              smActions.createReportSalesMeetingFail({
                error,
                errorType: "create-report-sales-meeting",
                extra: payload,
              })
            )
          )
        );
      })
    )
  );

  getContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getContactRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}`).pipe(
          map((contact: Contact) =>
            smActions.getContactSuccess({ contact: new Contact(contact) })
          ),
          catchError(() => observableOf(smActions.getContactFail()))
        )
      )
    )
  );

  getSalesMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getSalesMeetingRequest),
      switchMap(({ id }) =>
        this.apiService.get(`sales-meetings/${id}`, { getTasks: true }).pipe(
          map((response: SalesMeeting) =>
            smActions.getSalesMeetingSuccess({ salesMeeting: response })
          ),
          catchError(() => observableOf(smActions.getSalesMeetingFail()))
        )
      )
    )
  );

  getResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getResidenceRequest),
      switchMap(({ id }) =>
        this.apiService.get(`objects/${id}`, {}, "api").pipe(
          map((response: any) =>
            smActions.getResidenceSuccess({ payload: response })
          ),
          catchError(() => observableOf(smActions.getResidenceFail()))
        )
      )
    )
  );

  getResidencesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getResidencesRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/residences`).pipe(
          map((response: TypedPaginationListDTO<QObject>) =>
            smActions.getResidencesSuccess({ payload: response.rows })
          ),
          catchError(() => observableOf(smActions.getResidencesFail()))
        )
      )
    )
  );

  getBatchSettingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getBatchSettingsRequest),
      switchMap(({ filterSettingsGroupId }) => {
        return this.apiService
          .get("settings/values", { filterSettingsGroupId })
          .pipe(
            map((response: any) => this.parseBatchSettings(response)),
            catchError(() => observableOf(smActions.getBatchSettingsFail()))
          );
      })
    )
  );

  getSystemSourcesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getSystemSourcesRequest),
      switchMap(({ contactId, taskTypeCategory }) =>
        this.apiService
          .get(`contacts/${contactId}/sources`, { taskTypeCategory })
          .pipe(
            map((response: TypedPaginationListDTO<Task>) =>
              smActions.getSystemSourcesSuccess({ payload: response.rows })
            ),
            catchError(() => observableOf(smActions.getSystemSourcesFail()))
          )
      )
    )
  );

  getOtherSourcesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getOtherSourcesRequest),
      switchMap(({ taskTypeCategory }) =>
        this.apiService.get("task-types/search", { taskTypeCategory }).pipe(
          map((response: TypedPaginationListDTO<TaskType>) =>
            smActions.getOtherSourcesSuccess({ payload: response.rows })
          ),
          catchError(() => observableOf(smActions.getOtherSourcesFail()))
        )
      )
    )
  );

  getSendListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getSendListRequest),
      switchMap(({ contactId, referenceType, referenceId }) =>
        this.apiService
          .get(
            `contacts/${contactId}/mailing-types/${referenceType}/${referenceId}`
          )
          .pipe(
            map((response: SendListStatus[]) =>
              smActions.getSendListSuccess({ payload: response })
            ),
            catchError(() => observableOf(smActions.getSendListFail()))
          )
      )
    )
  );

  createContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createContactRequest),
      switchMap(({ params, source }) =>
        this.apiService
          .post("contacts", params)
          .pipe(
            mergeMap((contact: Contact) =>
              this.getPostCreateContactActions(params, source, contact)
            )
          )
      )
    )
  );

  createContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createContactSuccess),
      map(() => sidebarActions.resetDirty({ tabType: SALES_MEETING }))
    )
  );

  createContactRequest2$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.createContactRequest),
      map(() => sidebarActions.resetDirty({ tabType: SALES_MEETING }))
    )
  );

  getSelectedContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getSelectedEmployeeRequest),
      switchMap(({ id }) =>
        this.apiService.get(`employees/${id}`).pipe(
          map((employee: Employee) =>
            smActions.getSelectedEmployeeSuccess({ employee })
          ),
          catchError(() => observableOf(smActions.getSelectedEmployeeFail()))
        )
      )
    )
  );

  reportErrorOnFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        smActions.createSalesMeetingFail,
        smActions.updateSalesMeetingFail,
        smActions.createReportSalesMeetingFail
      ),
      map(({ error, errorType, extra }) =>
        errorActions.captureException({
          error,
          options: {
            tags: {
              [Tags.CUSTOM_ERROR]: Tags.SALES_MEETING,
              [Tags.SALES_MEETING]: errorType,
            },
            extra: {
              payload: extra,
            },
          },
        })
      )
    )
  );

  getMeetingHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(smActions.getMeetingHistoryRequest),
      switchMap(({ params }) =>
        this.apiService.get("sales-meetings", params).pipe(
          map((response: TypedPaginationListDTO<SalesMeeting>) =>
            smActions.getMeetingHistorySuccess({ payload: response.rows })
          ),
          catchError(() => observableOf(smActions.getMeetingHistoryFail()))
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}

  private parseBatchSettings(response: any): Action {
    try {
      const settings = response.settingGroups[0].settings.map((setting) =>
        JSON.parse(setting.settingValue)
      );
      return smActions.getBatchSettingsSuccess({ settings });
    } catch (e) {
      return smActions.getBatchSettingsFail();
    }
  }

  private getPostCreateContactActions(
    params: object,
    source: string,
    contact: Contact
  ): Action[] {
    const actions: Action[] = [
      smActions.createContactSuccess({ payload: contact }),
    ];
    if (source) {
      actions.push(
        createContactActions.addContactToSource({
          ...params,
          eaTaskTypeId: source,
          contactId: contact.contactId,
        })
      );
    }
    return actions;
  }

  private getModalBodyHtml(): string {
    return `<div class="alert alert-success">
      <p>${this.translateService.instant("sales_meeting_create_success")}</p>
    </div>`;
  }

  private getModalLink1(
    externalProviderName: string,
    address: string
  ): BodyLink {
    return {
      text: this.translateService.instant("create_in_external_provider", {
        address: address,
        externalProvider: externalProviderName,
      }),
      url: [
        "/crm/",
        { outlets: { sidebar: SIDEBAR_CREATE_IN_EXTERNAL_PROVIDER_URL } },
      ],
    };
  }

  private getModalLink2(externalProviderName: string): BodyLink {
    return {
      text: `${this.translateService.instant(
        "connect_meeting_to_existing_residence"
      )} ${externalProviderName}`,
      url: [
        "/crm/",
        { outlets: { sidebar: SIDEBAR_CONNECT_IN_EXTERNAL_PROVIDER_URL } },
      ],
    };
  }

  private getCreateInExternalProviderLink(
    externalProviderName: string,
    eaOid: string
  ) {
    return {
      text: this.translateService.instant(
        "create_in_external_provider_general",
        {
          externalProvider: externalProviderName,
        }
      ),
      eaOid,
    };
  }
}
