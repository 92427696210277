import { Component, EventEmitter, Output, ViewChild } from "@angular/core";

@Component({
  selector: "step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["../../forgot-password-form.component.scss"],
})
export class Step2Component {
  @ViewChild("form", { static: false })
  form;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onVerify = new EventEmitter<string>();
  emailCode = "";

  verify(): void {
    this.onVerify.emit(this.emailCode);
  }
}
