import { Employee } from "@app/models";
import { convertStringToArray } from "@app/shared/utils/conversion-utils";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import * as searchProfileActions from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SearchProfile } from "app/contacts/contact-search-profile/models/search-profile";
import { CategoryType } from "app/models/category-type";
import * as areaTypes from "app/sidebar/search-profile/utils/area-types";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface AreaSearchResults {
  municipalities: {
    geoCountyId?: string;
    geoMunicipalityId: string;
    municipalityName: string;
  }[];
  counties: {
    geoCountyId: string;
    countyName: string;
  }[];
  customFilters: {
    customFilterId: string;
    customFilterName: string;
    countyName?: string;
    municipalityName?: string;
  }[];
  zips: {
    adm1: string;
    adm2: string;
    locality: string;
    type: string;
    zip: string;
  }[];
  foreignGeoDataCity: {
    relevance: string;
    city: string;
    province: string;
    countryCode: string;
  }[];
  foreignGeoDataCityArea: {
    relevance: string;
    cityArea: string;
    city: string;
    province: string;
    countryCode: string;
  }[];
  foreignGeoDataProvince: {
    relevance: string;
    province: string;
    countryCode: string;
  }[];
}

export interface FormattedSearchArea {
  title?: string;
  type?: string;
  county?: string;
  municipality?: string;
  id: string;
}

export interface SearchProfileSidebarState {
  loaded: boolean;
  loading: boolean;
  patching: boolean;
  deleting: boolean;
  areaSearchResults: AreaSearchResults | null;
  searchProfile: SearchProfile | null;
  additionalFilters: CategoryType[];
  activities: { activityId: string; name: string }[];
  selectedAlert: Alert | null;
  employee: Employee | null;
}

export const initialState: SearchProfileSidebarState = {
  loaded: false,
  loading: false,
  patching: false,
  deleting: false,
  areaSearchResults: null,
  searchProfile: null,
  additionalFilters: [],
  activities: [],
  selectedAlert: null,
  employee: null,
};

export function searchProfileSidebarReducer(
  state: SearchProfileSidebarState,
  action: Action
): SearchProfileSidebarState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(searchProfileActions.getAreaSearchResultsSuccess, (state, { areas }) => ({
    ...state,
    areaSearchResults: areas,
  })),
  on(
    searchProfileActions.updateSearchProfileRequest,
    searchProfileActions.createSearchProfileRequest,
    searchProfileActions.createAlertRequest,
    searchProfileActions.updateAlertRequest,
    (state) => ({
      ...state,
      patching: true,
    })
  ),
  on(
    searchProfileActions.updateSearchProfileSuccess,
    searchProfileActions.createSearchProfileSuccess,
    searchProfileActions.createAlertSuccess,
    searchProfileActions.updateAlertSuccess,
    (state) => ({
      ...state,
      patching: false,
      loaded: true,
    })
  ),
  on(
    searchProfileActions.updateSearchProfileFail,
    searchProfileActions.createSearchProfileFail,
    searchProfileActions.createAlertFail,
    searchProfileActions.updateAlertFail,
    (state) => ({
      ...state,
      patching: false,
    })
  ),
  on(searchProfileActions.getSearchProfileRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    searchProfileActions.getSearchProfileSuccess,
    (state, { searchProfile }) => ({
      ...state,
      loading: false,
      loaded: true,
      searchProfile,
    })
  ),
  on(searchProfileActions.getSearchProfileFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    searchProfileActions.getAdditionalFiltersSuccess,
    (state, { categoryTypes }) => ({
      ...state,
      additionalFilters: categoryTypes,
    })
  ),
  on(searchProfileActions.getAlertActivitiesRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    searchProfileActions.getAlertActivitiesSuccess,
    (state, { activities }) => ({
      ...state,
      loading: false,
      loaded: true,
      activities,
    })
  ),
  on(searchProfileActions.getAlertActivitiesFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(searchProfileActions.getAlertSuccess, (state, { alert }) => ({
    ...state,
    selectedAlert: alert,
  })),
  on(searchProfileActions.getAlertRequest, (state) => ({
    ...state,
    selectedAlert: null,
  })),
  on(searchProfileActions.getAlertEmployeeSuccess, (state, { employee }) => ({
    ...state,
    employee,
  })),
  on(
    searchProfileActions.deleteSearchProfileRequest,
    searchProfileActions.deleteAlertRequest,
    (state) => ({
      ...state,
      deleting: true,
    })
  ),
  on(searchProfileActions.deleteAlertSuccess, (state) => ({
    ...state,
    deleting: false,
    selectedAlert: null,
  })),
  on(
    searchProfileActions.deleteSearchProfileFail,
    searchProfileActions.deleteSearchProfileSuccess,
    searchProfileActions.deleteAlertFail,
    (state) => ({
      ...state,
      deleting: false,
    })
  ),
  on(
    searchProfileActions.getAreaSearchResultsRequest,
    searchProfileActions.getAreaSearchResultsFail,
    searchProfileActions.getAdditionalFiltersRequest,
    searchProfileActions.getAdditionalFiltersFail,
    searchProfileActions.getAlertFail,
    searchProfileActions.getAlertEmployeeRequest,
    searchProfileActions.getAlertEmployeeFail,
    (state) => state
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.searchProfiles
);

export const getPatching = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.patching
);

export const getDeleting = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.deleting
);

export const getLoading = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.loading
);

export const getAreaSearchResults = createSelector(
  getState,
  (state: SearchProfileSidebarState) => {
    const areaSearchResults = state.areaSearchResults;
    if (!areaSearchResults) {
      return null;
    }

    const municipalityArray = areaSearchResults.municipalities.map(
      (municipality) => ({
        title: municipality.municipalityName,
        type: areaTypes.MUNICIPALITY,
        id: municipality.geoMunicipalityId,
      })
    );
    const customFilterArray = areaSearchResults.customFilters.map(
      (customFilter) => ({
        title: customFilter.customFilterName,
        type: areaTypes.CUSTOM_FILTER,
        county: customFilter.countyName,
        municipality: customFilter.municipalityName,
        id: customFilter.customFilterId,
      })
    );
    const countyArray = areaSearchResults.counties.map((county) => ({
      title: county.countyName,
      type: areaTypes.COUNTY,
      id: county.geoCountyId,
    }));
    const zipArray = areaSearchResults.zips
      ? areaSearchResults.zips.map((zip) => ({
          title: zip.zip,
          type: areaTypes.ZIP,
          id: zip.zip,
          county: zip.adm2,
          municipality: zip.locality,
        }))
      : [];
    return countyArray.concat(municipalityArray, customFilterArray, zipArray);
  }
);

export const getSearchProfile = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.searchProfile
);

export const getSearchProfileActivities = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.activities
);

export const getSearchProfileId = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.searchProfile.searchProfileId
);

export const getSearchProfileTitle = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.searchProfile.title
);

export const getSearchProfileAreas = createSelector(
  getState,
  (state: SearchProfileSidebarState) => {
    const searchProfile = state.searchProfile;
    if (!searchProfile) {
      return null;
    }

    const municipalityArray = searchProfile.municipalities
      ? searchProfile.municipalities.map((municipality) => ({
          ...municipality,
          type: areaTypes.MUNICIPALITY,
        }))
      : [];
    const customFilterArray = searchProfile.cityAreas
      ? searchProfile.cityAreas.map((cityArea) => ({
          ...cityArea,
          type: areaTypes.CUSTOM_FILTER,
        }))
      : [];
    const countyArray = searchProfile.countys
      ? searchProfile.countys.map((county) => ({
          ...county,
          type: areaTypes.COUNTY,
        }))
      : [];
    const zipArray = searchProfile.zipIds
      ? convertStringToArray(searchProfile.zipIds).map((zip) => ({
          title: zip,
          id: zip,
          type: areaTypes.ZIP,
        }))
      : [];
    const foreignData = [];
    if (searchProfile?.foreignGeoDataProvince) {
      foreignData.push({
        title: searchProfile?.foreignGeoDataProvince,
        id: searchProfile?.foreignGeoDataProvince,
        type: areaTypes.FOREIGN_GEO_PROVINCE,
      });
    }
    if (searchProfile?.foreignGeoDataCity) {
      foreignData.push({
        title: searchProfile?.foreignGeoDataCity,
        id: searchProfile?.foreignGeoDataCity,
        type: areaTypes.FOREIGN_GEO_CITY,
      });
    }
    if (searchProfile?.foreignGeoDataCityArea) {
      foreignData.push({
        title: searchProfile?.foreignGeoDataCityArea,
        id: searchProfile?.foreignGeoDataCityArea,
        type: areaTypes.FOREIGN_GEO_AREA,
      });
    }
    return countyArray.concat(
      municipalityArray,
      customFilterArray,
      zipArray,
      foreignData
    );
  }
);

export const getAdditionalFilters = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.additionalFilters
);

export const getSelectedAlert = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.selectedAlert
);

export const getAlertEmployee = createSelector(
  getState,
  (state: SearchProfileSidebarState) => state.employee
);
