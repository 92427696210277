import { Employee } from "@app/models/employee";
import { Nps, NpsEntity } from "@app/models/nps";
import { SurveyResponse } from "@app/models/survey";
import { createAction, props } from "@ngrx/store";

export const downloadNPSCSV = createAction(
  "[Statistics NPS] DOWNLOAD_NPS_CSV_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const downloadNPSCSVSuccess = createAction(
  "[Statistics NPS] DOWNLOAD_NPS_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const downloadNPSCSVFail = createAction(
  "[Statistics NPS] DOWNLOAD_NPS_CSV_FAIL"
);

export const fetchOfficeNpsStatisticsRequest = createAction(
  "[Statistics NPS] FETCH_OFFICE_NPS_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchOfficeNpsStatisticsSuccess = createAction(
  "[Statistics NPS] FETCH_OFFICE_NPS_STATISTICS_SUCCESS",
  props<{ officeNps: NpsEntity }>()
);

export const fetchOfficeNpsStatisticsFail = createAction(
  "[Statistics NPS] FETCH_OFFICE_NPS_STATISTICS_FAIL"
);

export const fetchTotalNpsStatisticsRequest = createAction(
  "[Statistics NPS] FETCH_TOTAL_NPS_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchTotalNpsStatisticsSuccess = createAction(
  "[Statistics NPS] FETCH_TOTAL_NPS_STATISTICS_SUCCESS",
  props<{ totalNps: Nps }>()
);

export const fetchTotalNpsStatisticsFail = createAction(
  "[Statistics NPS] FETCH_TOTAL_NPS_STATISTICS_FAIL"
);

export const fetchNpsResultsStatisticsRequest = createAction(
  "[Statistics NPS] FETCH_NPS_RESULTS_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchNpsResultsStatisticsSuccess = createAction(
  "[Statistics NPS] FETCH_NPS_RESULTS_STATISTICS_SUCCESS",
  props<{ npsResult: { total: number; rows: (NpsEntity | Nps)[] } }>()
);

export const fetchNpsResultsStatisticsFail = createAction(
  "[Statistics NPS] FETCH_NPS_RESULTS_STATISTICS_FAIL"
);

export const fetchMoreNpsResultsStatisticsRequest = createAction(
  "[Statistics NPS] FETCH_MORE_NPS_RESULTS_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchMoreNpsResultsStatisticsSuccess = createAction(
  "[Statistics NPS] FETCH_MORE_NPS_RESULTS_STATISTICS_SUCCESS",
  props<{ npsResults: Nps[] }>()
);

export const fetchMoreNpsResultsStatisticsFail = createAction(
  "[Statistics NPS] FETCH_MORE_NPS_RESULTS_STATISTICS_FAIL"
);

export const fetchSurveysRequest = createAction(
  "[Statistics NPS] FETCH_SURVEYS_REQUEST"
);

export const fetchSurveysSuccess = createAction(
  "[Statistics NPS] FETCH_SURVEYS_SUCCESS",
  props<{ surveys: SurveyResponse[] }>()
);

export const fetchSurveysFail = createAction(
  "[Statistics NPS] FETCH_SURVEYS_FAIL"
);

export const fetchEmployeesRequest = createAction(
  "[Statistics NPS] FETCH_EMPLOYEES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchEmployeesSuccess = createAction(
  "[Statistics NPS] FETCH_EMPLOYEES_SUCCESS",
  props<{ employees: Employee[] }>()
);

export const fetchEmployeesFail = createAction(
  "[Statistics NPS] FETCH_EMPLOYEES_FAIL"
);

export const reset = createAction("[Statistics NPS] RESET");
