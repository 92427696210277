import { Observable } from "rxjs";

/**
 * The required interface to satisfy to be an address provider
 */
export interface AddressProvider {
  /**
   * Takes a search string and returns an observable sequence of Address[].
   *
   * @param query
   */
  suggest(query: string): Observable<Address[]>;
}

/**
 * The required interface to satisfy to be an address validator
 */
export interface AddressValidator {
  /**
   * Takes an address object and returns an observable sequence of AddressValidationResponse.
   *
   * @param address
   */
  validate(address: Address): Observable<AddressValidationResponse>;
}

/**
 * How an address should look like
 */
export interface Address {
  street: string;
  zip: string;
  city: string;
  fullAddress?: string;
}

/**
 * How an address validation response should look like
 */
export interface AddressValidationResponse {
  valid: boolean;
  suggestions?: Address[];
}

/**
 * The available providers
 *
 * @type {{VALID: string}}
 */
export const ADDRESS_PROVIDERS = {
  VALID: "valid",
};
