import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { OverlaySpinnerComponent } from "./components/overlay-spinner/overlay-spinner.component";

@NgModule({
  imports: [CommonModule],
  declarations: [OverlaySpinnerComponent],
  exports: [OverlaySpinnerComponent],
})
export class OverlayLoaderModule {}
