import { createAction, props } from "@ngrx/store";

import { Template } from "@app/sidebar/message-templates/ngrx/message-templates-form.reducer";
import { DynamicContent } from "@app/sidebar/send-message/models";

const ACTION_PREFIX = "[Message templates form]";

export interface TemplateBody {
  name: string;
  subject: string;
  messageType: string;
  context: string;
  content?: string;
  description?: string;
  eaEmployeeId?: string;
  eaOfficeId?: string;
}

// Get message template
export const getMessageTemplateRequest = createAction(
  `${ACTION_PREFIX} GET_MESSAGE_TEMPLATE_REQUEST`,
  props<{ templateId: string }>()
);

export const getMessageTemplateSuccess = createAction(
  `${ACTION_PREFIX} GET_MESSAGE_TEMPLATE_SUCCESS`,
  props<{ template: Template }>()
);

export const getMessageTemplateFail = createAction(
  `${ACTION_PREFIX} GET_MESSAGE_TEMPLATE_FAIL`
);

// Create message template
export const createMessageTemplateRequest = createAction(
  `${ACTION_PREFIX} CREATE_MESSAGE_TEMPLATE_REQUEST`,
  props<{ body: TemplateBody }>()
);

export const createMessageTemplateSuccess = createAction(
  `${ACTION_PREFIX} CREATE_MESSAGE_TEMPLATE_SUCCESS`,
  props<{ templateId: string }>()
);

export const createMessageTemplateFail = createAction(
  `${ACTION_PREFIX} CREATE_MESSAGE_TEMPLATE_FAIL`
);

// Edit message template
export const editMessageTemplateRequest = createAction(
  `${ACTION_PREFIX} EDIT_MESSAGE_TEMPLATE_REQUEST`,
  props<{ templateId: string; body: TemplateBody }>()
);

export const editMessageTemplateSuccess = createAction(
  `${ACTION_PREFIX} EDIT_MESSAGE_TEMPLATE_SUCCESS`,
  props<{ templateId: string }>()
);

export const editMessageTemplateFail = createAction(
  `${ACTION_PREFIX} EDIT_MESSAGE_TEMPLATE_FAIL`
);

// Delete message template
export const deleteMessageTemplateRequest = createAction(
  `${ACTION_PREFIX} DELETE_MESSAGE_TEMPLATE_REQUEST`,
  props<{ templateId: string }>()
);

export const deleteMessageTemplateSuccess = createAction(
  `${ACTION_PREFIX} DELETE_MESSAGE_TEMPLATE_SUCCESS`
);

export const deleteMessageTemplateFail = createAction(
  `${ACTION_PREFIX} DELETE_MESSAGE_TEMPLATE_FAIL`
);

// Fetch dynamic content
export const fetchDynamicContentRequest = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_REQUEST`,
  props<{
    templateId: string;
    replacePlaceholders?: boolean;
    contactId?: string;
    eaOid?: string;
    eaEmployeeId?: string;
    eaOfficeId?: string;
  }>()
);

export const fetchDynamicContentSuccess = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_SUCCESS`,
  props<{ dynamicContents: DynamicContent[] }>()
);

export const fetchDynamicContentFail = createAction(
  `${ACTION_PREFIX} FETCH_DYNAMIC_CONTENT_FAIL`
);
