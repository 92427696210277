import { Contact, QObject } from "@app/models";
import { createAction, props } from "@ngrx/store";

const ACTION_PREFIX = "[Contact Residence]";

export const getResidenceRequest = createAction(
  `${ACTION_PREFIX} GET_RESIDENCE_REQUEST`,
  props<{ eaOid: string }>()
);

export const getResidenceSuccess = createAction(
  `${ACTION_PREFIX} GET_RESIDENCE_SUCCESS`,
  props<{ residence: QObject }>()
);

export const getResidenceFailed = createAction(
  `${ACTION_PREFIX} GET_RESIDENCE_FAILED`
);

export const setResidence = createAction(
  `${ACTION_PREFIX} SET_RESIDENCE`,
  props<{ residence?: QObject }>()
);

export const getResidenceContactRequest = createAction(
  `${ACTION_PREFIX} GET_CONTACT_REQUEST`,
  props<{ contactId: string }>()
);

export const getResidenceContactSuccess = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SUCCESS`,
  props<{ contact: Contact }>()
);

export const getResidenceContactFailed = createAction(
  `${ACTION_PREFIX} GET_CONTACT_FAILED`
);

export const getContactResidencesRequest = createAction(
  `${ACTION_PREFIX} GET_RESIDENCES_REQUEST`,
  props<{ contactId: string }>()
);

export const getContactResidencesSuccess = createAction(
  `${ACTION_PREFIX} GET_RESIDENCES_SUCCESS`,
  props<{ residences: QObject[] }>()
);

export const getContactResidencesFailed = createAction(
  `${ACTION_PREFIX} GET_RESIDENCES_FAILED`
);

export const createResidenceRequest = createAction(
  `${ACTION_PREFIX} CREATE_RESIDENCE_REQUEST`,
  props<{ residence: Partial<QObject> }>()
);

export const createResidenceSuccess = createAction(
  `${ACTION_PREFIX} CREATE_RESIDENCE_SUCCESS`,
  props<{ residence: QObject }>()
);

export const createResidenceFailed = createAction(
  `${ACTION_PREFIX} CREATE_RESIDENCE_FAILED`
);

export const updateResidenceRequest = createAction(
  `${ACTION_PREFIX} UPDATE_RESIDENCE_REQUEST`,
  props<{ residence: Partial<QObject> }>()
);

export const updateResidenceSuccess = createAction(
  `${ACTION_PREFIX} UPDATE_RESIDENCE_SUCCESS`,
  props<{ residence: QObject }>()
);

export const updateResidenceFailed = createAction(
  `${ACTION_PREFIX} UPDATE_RESIDENCE_FAILED`
);

export const reset = createAction(`${ACTION_PREFIX} RESET`);
