<div class="btn-group" #elements>
  <ng-container *ngFor="let control of controls; let i = index">
    <button
      class="btn btn-default"
      [class.active]="active == getValue(control)"
      (click)="setActive(getValue(control))"
      [matTooltip]="
        !!control.tooltip
          ? (control.tooltip | translate)
          : (control.label | translate)
      "
      matTooltipClass="q-tooltip"
      [matTooltipDisabled]="!enableTooltip(i, elements)"
      [disabled]="control.disabled"
      [class.truncate]="truncate"
    >
      <span class="btn-group__icon" *ngIf="control.icon">
        <app-icon [name]="control.icon"></app-icon>
      </span>
      <span class="btn-group__label">
        {{ getLabel(control) | translate }}
      </span>
    </button>
    <div
      *ngIf="!!control?.total"
      class="btn-group__overlay"
      [matBadge]="control.total"
      [matBadgeHidden]="!control.total || control.total === 0"
      matBadgePosition="after"
      matBadgeColor="warn"
    ></div>
  </ng-container>
</div>
