import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { Contact } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import * as formUtils from "@app/shared/utils/form-utils";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, Observable } from "rxjs";
import { allFieldsRequiredOnAtLeastOneControlOrGroup } from "@app/shared/validators/form-group-validator";

@Component({
  selector: "app-estate-form",
  templateUrl: "./estate-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./estate-form.component.scss",
  ],
})
export class EstateFormComponent implements OnInit, OnChanges {
  @Input() contact: Contact;
  @Input() editMode: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: FormGroup;
  countryCode$: Observable<string>;
  addressSuggesterFeature$: Observable<Feature>;
  addressIsRequired$: Observable<boolean>;
  estateContactRequired$: Observable<boolean>;
  selectedCountryCode$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private featureConfigManager: FeatureConfigManagerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.initCountryCode();
    this.setValidators();
  }

  ngOnChanges(changes) {
    if (changes.contact && this.contact) {
      this.fillForm();
      if (!!this?.contact?.phoneNumberCountry) {
        this.updateCountryCode(this.contact.phoneNumberCountry);
      }
    }
  }

  private initCountryCode() {
    this.countryCode$.pipe(first()).subscribe((countryCode) => {
      this.selectedCountryCode$.next(countryCode);
      if (!this.form.get("phoneNumberCountry").value) {
        this.form.get("phoneNumberCountry").setValue(countryCode);
      }
    });
  }

  private buildForm() {
    this.form = this.fb.group({
      estateContact: this.fb.group({
        firstName: [null, Validators.required],
        familyName: [null],
        street: [null],
        zip: [null],
        city: [null],
        email: [null],
        msisdn: [null],
        msisdnCountry: [null],
      }),
      estate: this.fb.group({
        firstName: [null, Validators.required],
        street: [null],
        zip: [null],
        city: [null],
      }),
    });
  }

  private fillForm() {
    const patchValue = {
      firstName: `${this.contact.firstName} ${this.contact.familyName}`.trim(),
      street: this.contact.street,
      zip: this.contact.zip,
      city: this.contact.city,
    };
    this.form.get("estate").patchValue(patchValue);
    this.form.get("estateContact").patchValue({
      email: this.contact.email,
      msisdn: this.contact.msisdn,
      msisdnCountry: this.contact.msisdnCountry,
    });
  }

  private mapStateToProps() {
    this.estateContactRequired$ = this.featureConfigManager
      .getEstateCreationConfig()
      .pipe(map((c) => c.estatePrimaryContactRequired));
    this.countryCode$ = this.store.pipe(
      select(fromConfig.getCountry),
      map((value) => value.toUpperCase())
    );
    this.addressSuggesterFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressIsRequired$ = this.store.pipe(
      select(fromConfig.getCreateContactTypesConfig),
      map((config) => {
        const contactType = config.find((type) => type.type === "estate");
        return contactType.addressIsRequired;
      })
    );
  }

  private updateCountryCode(phoneNumberCountry: string) {
    this.selectedCountryCode$.next(phoneNumberCountry);
  }

  private setValidators(): void {
    this.addressIsRequired$.pipe(first()).subscribe((addressIsRequired) => {
      if (addressIsRequired) {
        this.form.get("street").setValidators(Validators.required);
        this.form.get("zip").setValidators(Validators.required);
        this.form.get("city").setValidators(Validators.required);
      }
    });

    this.form.setValidators([
      allFieldsRequiredOnAtLeastOneControlOrGroup([
        this.form.get("estateContact.msisdn"),
        this.form.get("estateContact.phoneNumber"),
        this.form.get("estateContact.email"),
      ]),
    ]);
  }

  handleAddressSelected(group: "estate" | "estateContact", address: Address) {
    this.form.get(group).get("street").setValue(address.street);
    this.form.get(group).get("zip").setValue(address.zip);
    this.form.get(group).get("city").setValue(address.city);
  }

  handleZipSelected(group: "estate" | "estateContact", address: Address) {
    this.form.get(group).get("zip").setValue(address.zip);
    this.form.get(group).get("city").setValue(address.city);
  }

  submit(): void {
    this.estateContactRequired$.pipe(first()).subscribe((ecr) => {
      if (
        !!this.editMode
          ? this.form.get("estate").valid
          : !!ecr
          ? this.form.valid
          : this.form.get("estate").valid
      ) {
        this.onSubmit.emit(true);
      } else {
        this.onSubmit.emit(false);
        formUtils.markAllAsTouched(this.form);
      }
    });
  }

  handleCountryCodeChange(countryCode: string) {
    this.form.get("estateContact").get("msisdnCountry").setValue(countryCode);
  }
}
