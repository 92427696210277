import * as showingsActions from "@app/lists/lists-showings/ngrx/lists-showings.actions";
import { API_SHOWINGS_DEFAULT_LIMIT } from "@app/lists/lists-showings/utils/lists-showings-defaults";
import { PaginationListDTO } from "@app/models";
import { ShowingObject } from "@app/showings/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsShowingsState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  rows: ShowingObject[];
}

export const initialState: ListsShowingsState = {
  loaded: false,
  loading: false,
  limit: API_SHOWINGS_DEFAULT_LIMIT,
  offset: 0,
  total: 0,
  rows: [],
};

export function listsShowingsReducer(
  state: ListsShowingsState,
  action: Action
): ListsShowingsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(showingsActions.getShowingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    showingsActions.getShowingsSuccess,
    (state, { showings: { rows, total, offset, limit } }) => ({
      ...state,
      loading: false,
      loaded: true,
      rows,
      total,
      limit,
      offset,
    })
  ),
  on(showingsActions.getShowingsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    rows: initialState.rows,
  })),
  on(showingsActions.getMoreShowingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    showingsActions.getMoreShowingsSuccess,
    (state, { showings: { rows, total, offset, limit } }) => ({
      ...state,
      loading: false,
      loaded: true,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    })
  ),
  on(showingsActions.getMoreShowingsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
  })),
  on(showingsActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.showings
);

export const getListsShowingsLoaded = createSelector(
  getState,
  (state: ListsShowingsState) => state.loaded
);

export const getListsShowingsLoading = createSelector(
  getState,
  (state: ListsShowingsState) => state.loading
);

export const getListsShowingsRows = createSelector(
  getState,
  (state: ListsShowingsState) => state.rows
);

export const getListsShowingsTotal = createSelector(
  getState,
  (state: ListsShowingsState) => state.total
);

export const getListsShowingsLimit = createSelector(
  getState,
  (state: ListsShowingsState) => state.limit
);
