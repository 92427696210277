<div class="form" [formGroup]="form">
  <div class="form__control">
    <label for="title" translate>title</label>
    <input
      id="title"
      type="text"
      formControlName="title"
      [class]="
        !form.get('title')?.valid && form.get('title')?.touched
          ? 'validation-input'
          : ''
      "
    />
  </div>
  <div class="form__control">
    <label translate>note</label>
    <app-rich-text-editor
      [control]="form.get('content')"
      [height]="80"
      [toolbarEnabled]="false"
      [autoFocus]="true"
    ></app-rich-text-editor>
  </div>
  <div class="form__footer">
    <app-q-button
      buttonType="save"
      label="save"
      [disabled]="!form.valid"
      (click)="onSubmit()"
    >
    </app-q-button>
  </div>
</div>
