<div bsModal class="modal fade z-index-10" #modal="bs-modal" tabindex="-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ "preview" | translate }}
          <span *ngIf="consumerName"
            >({{ "data_fetched_for_consumer" | translate }}
            {{ consumerName }})</span
          >
        </h4>
      </div>
      <div class="modal-body">
        <iframe #iframe src="about:blank" (load)="onLoad()"></iframe>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-cancel btn-lg"
          (click)="modal.hide()"
          translate
        >
          close
        </button>
      </div>
    </div>
  </div>
</div>
