import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_CREATE_LEAD_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_LEAD } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import * as createLeaedActions from "../ngrx/create-lead.actions";

@Component({
  selector: "create-lead-create-contact",
  template: `
    <create-contact-simple
      [type]="tabType"
      (onSubmit)="onSubmit($event)"
      (onQuedroContactSelected)="onQuedroContactSelected($event)"
    ></create-contact-simple>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateLeadCreateContactComponent {
  tabType = CREATE_LEAD;

  constructor(private store: Store<AppState>) {}

  onSubmit({ params, source }): void {
    this.store.dispatch(createLeaedActions.createContact(params, source));
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_CREATE_LEAD_CREATE_URL(contact.contactId),
            },
          },
        ],
      })
    );
  }
}
