<div class="checkbox-container">
  <div class="checkbox-list">
    <h4 *ngIf="selectedList.length">
      {{ entityName ? entityName : "" }}
      {{ "found_in" | translate | lowercase }}:
    </h4>
    <ul>
      <li
        *ngFor="let selected of selectedList"
        (click)="removeFromListClicked(selected.callingListId)"
      >
        <input type="checkbox" class="checkbox" [checked]="true" />
        <div class="checkbox-text">
          <span>
            {{ selected.title }}
          </span>
          <small
            >{{ "created_by" | translate }}:
            {{ selected.employeeFullName }}</small
          >
        </div>
      </li>

      <li
        class="checkbox-select__dropdown__list__item disabled"
        *ngIf="!selectedList.length"
      >
        <div class="checkbox-select__dropdown__list__item__text-container">
          <span>
            {{ "contact_no_calling_list" | translate }}
          </span>
        </div>
      </li>
    </ul>
  </div>
  <div class="checkbox-list">
    <h4>
      {{ "remaining_lists" | translate }}
      {{ isBroker ? ("created_by_you" | translate | lowercase) : "" }} :
    </h4>
    <ul class="checkbox-select__dropdown__list">
      <li
        class="checkbox-select__dropdown__list__item"
        *ngFor="let selected of getUnselectedList()"
        (click)="addToListClicked(selected.callingListId)"
      >
        <input type="checkbox" class="checkbox" />
        <div class="checkbox-text">
          <span>
            {{ selected.title }}
          </span>
          <small
            >{{ "created_by" | translate }}:
            {{ selected.employeeFullName }}</small
          >
        </div>
      </li>

      <li
        class="checkbox-select__dropdown__list__item disabled"
        *ngIf="!getUnselectedList().length"
      >
        <div class="checkbox-select__dropdown__list__item__text-container">
          <span>
            {{ "no_calling_lists_found" | translate }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>
