import { Injectable } from "@angular/core";
import { ListViewsService } from "@app/core/services/list-views/list-views.service";

@Injectable()
export class AssignLeadViewsService extends ListViewsService {
  views = [
    {
      name: "highest_chance_of_success",
      params: {
        sortBy: "numberOfSuccessTasksPercentual",
        sortOrder: "desc",
      },
    },
    {
      name: "needs_lead",
      params: {
        sortBy: "numberOfTasks",
        sortOrder: "asc",
      },
    },
  ];

  constructor() {
    super();
    this.setViews(this.views);
    if (this.views.length > 0) {
      this.setSelectedView(this.views[0]);
    }
  }
}
