import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { Action, PaginationListDTO } from "@app/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class ActionService extends CustomEntityCollectionServiceBase<Action> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("Action", serviceElementsFactory);
  }

  // @ts-ignore
  getWithQuery = (params: any) => {
    this.setLoading(true);
    return this.apiService.get("actions", params).pipe(
      map((response: PaginationListDTO) => {
        this.setLoading(false);
        this.upsertManyInCache(response.rows);
        this.setListDefaults(response.rows, response);
        return response.rows;
      }),
      catchError((err) => err)
    );
  };

  postContactAction = (contactId: string, params?: any) => {
    this.setLoading(true);
    return this.apiService.post(`contacts/${contactId}/actions`, params).pipe(
      map((res: Action) => {
        this.upsertOneInCache(res);
        this.setLoading(false);
        return res;
      }),
      catchError((err) => err)
    );
  };
}
