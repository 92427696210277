import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from "@angular/router";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { LocaleService } from "@app/core/services/locale/locale.service";
import * as loginActions from "@app/login/ngrx/login.actions";
import { Employee } from "@app/models";
import { SUPPORT_WIDGET } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.actions";
import * as fromSharedReducer from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { environment } from "@env/environment";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Hotkey, HotkeysService } from "angular2-hotkeys";
import { Angulartics2, Angulartics2GoogleAnalytics } from "angulartics2";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import {
  combineLatest,
  debounceTime,
  filter,
  fromEvent as observableFromEvent,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { AppState } from "./app.reducer";
import { AuthService } from "./core/services/auth/auth.service";
import { ClickService } from "./core/services/click/click.service";
import { ScrollService } from "./core/services/scroll/scroll.service";
import * as fromConfig from "./shared/config/config.reducer";
import "./shared/utils/string-extension";
import { isDirty } from "./sidebar/ngrx/sidebar.reducer";
import { showConfirm } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ConfirmModalComponent } from "@app/core/components/confirm-modal/confirm-modal.component";
import * as i18nISOCountries from "i18n-iso-countries";

declare var ga: any;
declare const require;

i18nISOCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  employee$: Observable<Employee>;
  unsubscribe$ = new Subject<void>();
  freshdeskFeature$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  // do not remove Angulartics2GoogleAnalytics from constructor
  constructor(
    @Inject(DOCUMENT) private document,
    private angulartics2: Angulartics2,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private store: Store<AppState>,
    private authService: AuthService,
    private translate: TranslateService,
    private scrollService: ScrollService,
    private renderer: Renderer2,
    private clickService: ClickService,
    private hotkeysService: HotkeysService,
    private modalService: QModalService,
    private localeService: LocaleService,
    private titleService: Title,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.angulartics2GoogleAnalytics.startTracking();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.addEventListeners();
    this.setLanguage();
    this.setTitle();
    this.authService.loadUserData();
    this.store.dispatch(
      fromShared.setInnerWidth({ innerWidth: window.innerWidth })
    );
    this.handlePageTitles();
    this.handleConfirmModal();
    // used by sso login
    if (
      location.hash.includes("#/crm") &&
      !location.hash.trim().endsWith("/miles")
    ) {
      this.localStorageService.saveUserPreference(
        "redirect_after_login_url",
        location.hash
      );
    }

    if (environment.production) {
      this.initGoogleAnalytics();
      this.toggleBeforeUnloadListener();
    } else {
      this.angulartics2.settings.developerMode = true;
      this.setupHotkeys();
    }
  }

  ngAfterViewInit(): void {
    const preloader = this.document.getElementById("app__preloader");
    const delay = environment.production ? 2000 : 0;
    if (preloader) {
      this.renderer.addClass(preloader, "app__preloader--fader");
      setTimeout(() => {
        this.renderer.removeChild(this.document, preloader);
      }, delay);
    }
  }

  handlePageTitles(): void {
    const defaultTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router)
      )
      .subscribe(() => {
        const title = this.getTitle(
          this.router.routerState,
          this.router.routerState.root
        )[0];

        if (title !== "keep") {
          this.titleService.setTitle(defaultTitle);
        }
      });
  }

  getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(...this.getTitle(state, state.firstChild(parent)));
    }

    return data;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.isMobile$ = this.store.pipe(select(fromSharedReducer.isMobile));
    this.store
      .select((state) => state.login.loginMethod.isLoaded)
      .pipe(filter((isLoaded) => !isLoaded))
      .subscribe(() => {
        this.store.dispatch(loginActions.checkLoginMethodRequest());
      });
  }

  addEventListeners(): void {
    observableFromEvent(window, "scroll")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((event) => this.scrollService.emit(event));

    observableFromEvent(window, "click")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe((event) => this.clickService.emit(event));

    observableFromEvent(window, "resize")
      .pipe(
        debounceTime(100),
        map((resizeEvent: any) => resizeEvent.target.innerWidth),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((windowWidth) =>
        this.store.dispatch(
          fromShared.setInnerWidth({ innerWidth: windowWidth })
        )
      );

    const channel = new BroadcastChannel("clientChangedChannel");
    channel.addEventListener("message", (event) => {
      if (event.data === "clientChanged" && !location.hash.includes("/admin")) {
        document.querySelector(".lock-the-page").classList.remove("hide");
      }
    });
  }

  setLanguage(): void {
    const personalLanguage =
      this.localStorageService.fetchUserPreference("language");

    combineLatest([
      this.store.pipe(select(fromConfig.getLanguage)),
      this.store.pipe(select(fromConfig.getFeature(SUPPORT_WIDGET))),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([language, freshdeskFeature]) => {
        if (personalLanguage && personalLanguage.length > 0) {
          this.localeService.setLocale(personalLanguage);
          if (freshdeskFeature.enabled) {
            this.loadFreshDesk(personalLanguage);
          }
        } else {
          this.localeService.setLocale(language);
          this.localStorageService.saveUserPreference("language", language);
          if (freshdeskFeature.enabled) {
            this.loadFreshDesk(language);
          }
        }

        i18nISOCountries.registerLocale(
          require(`i18n-iso-countries/langs/${language}.json`)
        );
      });
  }

  loadFreshDesk(languageFromSettings: string): void {
    this.employee$.subscribe((employee) => {
      // @ts-ignore
      if (employee && employee.employeeFullName && !window.FreshworksWidget) {
        const supportedLanguages = ["en", "sv-SE", "nb-NO", "fi"];
        let languageForFreshdesk;

        if (languageFromSettings === "sv") {
          languageForFreshdesk = "sv-SE";
        } else if (languageFromSettings === "nb") {
          languageForFreshdesk = "nb-NO";
        } else {
          languageForFreshdesk = languageFromSettings;
        }

        if (!supportedLanguages.includes(languageForFreshdesk)) {
          languageForFreshdesk = "en";
        }

        // @ts-ignore
        window.fwSettings = {
          widget_id: 12000000137,
          locale: languageForFreshdesk,
        };
        // @ts-ignore
        // tslint:disable-next-line:no-unused-expression
        !(function () {
          // @ts-ignore
          if ("function" !== typeof window.FreshworksWidget) {
            // @ts-ignore
            const n = function () {
              // @ts-ignore
              n.q.push(arguments);
            };
            // @ts-ignore
            (n.q = []), (window.FreshworksWidget = n);
          }
        })();
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.src = "https://widget.freshworks.com/widgets/12000000137.js";
        document.getElementsByTagName("head")[0].appendChild(script);
        // @ts-ignore
        window.FreshworksWidget("identify", "ticketForm", {
          name: employee.employeeFullName,
          email: employee.employeeEmail,
        });

        const config = {};
        supportedLanguages.forEach((lang) => {
          config[lang] = {
            banner: this.translate.instant("support_widget_header"),
            launcher: this.translate.instant("support_widget_icon_text"),
            contact_form: {
              title: this.translate.instant("contact_us"),
              submit: this.translate.instant("send"),
              confirmation: this.translate.instant(
                "support_widget_confirmation"
              ),
            },
          };
        });

        // @ts-ignore
        window.FreshworksWidget("setLabels", config);

        // @ts-ignore
        if (!!window.FreshworksWidget) {
          // @ts-ignore
          window.FreshworksWidget("hide", "launcher");
        }
      }
    });
  }

  setTitle(): void {
    this.store
      .pipe(select(fromConfig.getTabTitle), takeUntil(this.unsubscribe$))
      .subscribe((tabTitle) => {
        this.titleService.setTitle(tabTitle);
      });
  }

  toggleBeforeUnloadListener(): void {
    this.store
      .pipe(select(isDirty), takeUntil(this.unsubscribe$))
      .subscribe((dirty) =>
        dirty ? this.registerBeforeUnload() : this.unRegisterBeforeUnload()
      );
  }

  initGoogleAnalytics(): void {
    this.store
      .pipe(
        select(fromConfig.getGoogleAnalyticsTrackingId),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((id) => {
        if (typeof ga !== "undefined" && ga) {
          ga("create", id, "auto");
        }
      });
  }

  registerBeforeUnload(): void {
    window.addEventListener("beforeunload", getConfirmMessage);
  }

  unRegisterBeforeUnload(): void {
    window.removeEventListener("beforeunload", getConfirmMessage);
  }

  private handleConfirmModal() {
    this.store
      .pipe(
        select(showConfirm),
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() =>
        this.modalService.show(ConfirmModalComponent, {
          data: {
            isEditMode: false,
            hasHeader: true,
            hasHeaderCloseButton: true,
            hasActionBar: true,
          },
        })
      );
  }

  private setupHotkeys() {
    this.hotkeysService.add(
      new Hotkey(["ctrl+shift+l", "meta+shift+l"], (): boolean => {
        this.store.dispatch(fromUser.logout());
        return false;
      })
    );

    this.hotkeysService.add(
      new Hotkey(["ctrl+shift+a", "meta+shift+a"], (): boolean => {
        this.store.dispatch(
          RouterActions.go({
            path: ["admin", "auth"],
          })
        );
        return false;
      })
    );
  }

  reloadPage() {
    location.reload();
  }
}

function getConfirmMessage(e) {
  const beforeUnloadConfirmationMessage =
    "You have unsaved changes, are you sure you want to proceed?";
  e.returnValue = beforeUnloadConfirmationMessage;
  return beforeUnloadConfirmationMessage;
}
