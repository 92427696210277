export class CrmModule {
  segments: string[];

  constructor(private type: string) {
    this.segments = ["crm", this.type];
  }

  isModuleActive(url: string): boolean {
    const urlSegments: string[] = url.split(/[\/(]+/);

    return !this.segments.some(
      (segment: string, i: number) => segment !== urlSegments[i + 1]
    );
  }
}
