import { Component, Input } from "@angular/core";
import { ShowErrorWhen } from "@ngspot/ngx-errors";

interface Error {
  name: string;
  text: string;
  when: ShowErrorWhen;
}

@Component({
  selector: "app-q-control-errors",
  templateUrl: "./q-control-errors.component.html",
  styleUrls: ["./q-control-errors.component.scss"],
})
export class QControlErrorsComponent {
  @Input() name: string;
  @Input() errors: Error[];
}
