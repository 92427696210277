<ng-container>
  <ng-container *ngIf="!(isMobile$ | async); else mobileFilter">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </ng-container>
  <ng-template #mobileFilter>
    <button (click)="openSheet(mobileFilters)" class="q-btn--save">
      <app-icon [name]="icon"></app-icon>
      {{ label | translate }}
    </button>
  </ng-template>
</ng-container>

<ng-template #mobileFilters>
  <div class="sheet-content-container">
    <div class="sheet-content-container--icon">
      <span (click)="close()"><app-icon [name]="'times'"></app-icon></span>
    </div>
    <div class="sheet-content-container--content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
