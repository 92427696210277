import { ElementRef, Injectable, Renderer2 } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addQuickLink } from "@app/contacts/contact.actions";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { LocaleService } from "@app/core/services/locale/locale.service";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Action, Employee } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { getEmployee } from "@app/shared/user";
import { getQuickLinkLabelFromActionType } from "@app/shared/utils/contact-quick-link";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  combineLatest as observableCombineLatest,
  filter,
  first,
  map,
  Observable,
  switchMap,
  take,
} from "rxjs";
import * as fromActionList from "../ngrx/action-list.actions";

@Injectable()
export class ActionListItemService {
  isMobile$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private router: Router,
    private taskTypeResolver: TaskTypeResolverService,
    private localeService: LocaleService,
    private contactService: ContactService
  ) {
    this.mapStateToProps();
  }

  mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(fromShared.isMobile));
  }

  createLeadLinkListener(
    elementRef: ElementRef,
    renderer: Renderer2,
    classString: string,
    action: Action
  ): Function {
    const el = elementRef.nativeElement.querySelector(`.${classString}`);
    if (el) {
      return renderer.listen(el, "click", () =>
        this.navigateToLeadOrTask(action)
      );
    }
  }

  createContactLinkListener(
    elementRef: ElementRef,
    renderer: Renderer2,
    classString: string,
    id: string
  ): Function {
    const el = elementRef.nativeElement.querySelector(`.${classString}`);
    if (el) {
      return renderer.listen(el, "click", () => {
        this.router.navigate([
          "/crm",
          "contacts",
          id,
          { outlets: { sidebar: null } },
        ]);
      });
    }
  }

  createTaskLinkListener(
    elementRef: ElementRef,
    renderer: Renderer2,
    classString: string,
    action: Action
  ): Function {
    const el = elementRef.nativeElement.querySelector(`.${classString}`);
    if (el) {
      return renderer.listen(el, "click", () =>
        this.navigateToLeadOrTask(action)
      );
    }
  }

  createObjectLinkListener(
    elementRef: ElementRef,
    renderer: Renderer2,
    classString: string,
    id: string
  ): Function {
    const el = elementRef.nativeElement.querySelector(`.${classString}`);
    if (el) {
      return renderer.listen(el, "click", () => {
        this.router.navigate([
          "/crm",
          "showings",
          id,
          { outlets: { sidebar: null } },
        ]);
      });
    }
  }

  navigateToLeadOrTask(action: Action): void {
    const sidebarLink = this.taskTypeResolver.resolveSidebarTaskOrLeadRoute(
      action.externalId,
      action.eaTaskTypeId
    );
    this.isMobile$.pipe(first()).subscribe((isMobile: boolean) => {
      let completeRoute;
      if (isMobile) {
        completeRoute = ["/crm", "contacts", action.contactId];

        const quickLinkRoute = [
          "/crm",
          {
            outlets: {
              sidebar: sidebarLink,
            },
          },
        ];
        this.store.dispatch(
          addQuickLink({
            parameters: {
              route: quickLinkRoute,
              label: getQuickLinkLabelFromActionType(action.type),
            },
          })
        );
      } else {
        completeRoute = [
          "/crm",
          {
            outlets: {
              primary: ["contacts", action.contactId],
              sidebar: sidebarLink,
            },
          },
        ];
      }
      this.store.dispatch(RouterActions.go({ path: completeRoute }));
    });
  }

  getYouTranslation(action: Action): Observable<string> {
    return observableCombineLatest([
      this.store.pipe(select(getEmployee)),
      this.translateService.get("you"),
    ]).pipe(map(([e, t]) => this.getNameOrYou(action, e, t)));
  }

  getMeTranslation(action: Action): Observable<string> {
    return observableCombineLatest([
      this.store.pipe(select(getEmployee)),
      this.translateService.get("me"),
    ]).pipe(map(([e, t]) => this.getNameOrYou(action, e, t.toLowerCase())));
  }

  navigateToObject(eaOid: string): void {
    this.router.navigate([
      "/crm",
      "showings",
      eaOid,
      { outlets: { sidebar: null } },
    ]);
  }

  private getNameOrYou(
    action: Action,
    employee: Employee,
    youTranslation: string
  ): string {
    if (this.localeService.getCurrentLocale() === "fi") {
      return action.employeeFullName;
    }

    if (employee.eaEmployeeId === action.eaEmployeeId) {
      return youTranslation;
    } else {
      return action.employeeFullName;
    }
  }

  removeConsumerAction(action: Action) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_note_header",
        message: "remove_note_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1)
      )
      .subscribe(() =>
        this.store.dispatch(fromActionList.removeAction(action))
      );
  }

  removeSalesCallAction(action: Action) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_sales_call_header",
        message: "remove_sales_call_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1),
        switchMap(() => {
          return this.contactService.deleteSalesCall(
            action.contactId,
            action.actionId
          );
        })
      )
      .subscribe((result) => {
        if (!!result) {
          this.store.dispatch(
            fromActionList.removeActionSuccess({ actionId: action.actionId })
          );
        }
      });
  }
}
