import { Task } from "@app/models";
import { ExternalTip } from "@app/models/external-tips";
import { createAction, props } from "@ngrx/store";

export const createExternalTipRequest = createAction(
  "[External Tips] CREATE_EXTERNAL_TIP_REQUEST",
  props<{ externalTip: ExternalTip }>()
);

export const createExternalTipSuccess = createAction(
  "[External Tips] CREATE_EXTERNAL_TIP_SUCCESS"
);

export const createExternalTipFailed = createAction(
  "[External Tips] CREATE_EXTERNAL_TIP_FAILED"
);

export const updateExternalTipRequest = createAction(
  "[External Tips] UPDATE_EXTERNAL_TIP_REQUEST",
  props<{ id: string; data: Partial<ExternalTip> }>()
);

export const updateExternalTipSuccess = createAction(
  "[External Tips] UPDATE_EXTERNAL_TIP_SUCCESS"
);

export const updateExternalTipFailed = createAction(
  "[External Tips] UPDATE_EXTERNAL_TIP_FAILED"
);

export const sendExternalTipRequest = createAction(
  "[External Tips] SEND_EXTERNAL_TIP_REQUEST",
  props<{
    params: {
      dataSet: string;
      recipients: string;
      sendToModule: string;
      templateId: number;
    };
    taskBody: Partial<Task>;
    addToOutbox: boolean;
  }>()
);

export const sendExternalTipSuccess = createAction(
  "[External Tips] SEND_EXTERNAL_TIP_SUCCESS"
);

export const sendExternalTipFailed = createAction(
  "[External Tips] SEND_EXTERNAL_TIP_FAILED"
);

export const getExternalTipRequest = createAction(
  "[External Tips] GET_EXTERNAL_TIP_REQUEST",
  props<{ id: string }>()
);

export const getExternalTipSuccess = createAction(
  "[External Tips] GET_EXTERNAL_TIP_SUCCESS",
  props<{ externalTip: ExternalTip }>()
);

export const getExternalTipFailed = createAction(
  "[External Tips] GET_EXTERNAL_TIP_FAILED"
);

export const getExternalTipsRequest = createAction(
  "[External Tips] GET_EXTERNAL_TIPS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getExternalTipsSuccess = createAction(
  "[External Tips] GET_EXTERNAL_TIPS_SUCCESS",
  props<{ externalTips: ExternalTip[] }>()
);

export const getExternalTipsFailed = createAction(
  "[External Tips] GET_EXTERNAL_TIPS_FAILED"
);
