import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./potential-buyers-to-zero-bidders.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface EmployeeSettingsState {
  sendPotentialBuyersToMspecs: boolean;
  loading: boolean;
  storing: boolean;
}

export const initialState: EmployeeSettingsState = {
  sendPotentialBuyersToMspecs: null,
  loading: false,
  storing: false,
};

export function employeeSettingsReducer(
  state: EmployeeSettingsState,
  action: Action
): EmployeeSettingsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getEmployeeSettingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.getEmployeeSettingsSuccess, (state, { settings }) => ({
    ...state,
    sendPotentialBuyersToMspecs: settings.sendPotentialBuyersToMspecs,
    loading: false,
  })),
  on(actions.getEmployeeSettingsFail, () => ({ ...initialState })),
  on(actions.storeEmployeeSettingsRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(
    actions.storeEmployeeSettingsSuccess,
    actions.storeEmployeeSettingsFail,
    (state) => ({
      ...state,
      storing: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.employeeSettings
);

export const getEmployeeSettings = createSelector(
  getState,
  (state: EmployeeSettingsState) => state.sendPotentialBuyersToMspecs
);

export const getEmployeeSettingLoading = createSelector(
  getState,
  (state: EmployeeSettingsState) => state.loading
);

export const getEmployeeSettingStoring = createSelector(
  getState,
  (state: EmployeeSettingsState) => state.storing
);
