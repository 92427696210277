import {
  debounceTime,
  first,
  map,
  Observable,
  switchMap,
  timer as observableTimer,
} from "rxjs";

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Country, EmployeeDTO } from "@app/models";
import { validateEmail } from "@app/shared/modules/form-components/directives/email-validator.directive";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { validatePhoneNumber } from "@app/shared/validators/phone-validator";
import { select, Store } from "@ngrx/store";
import * as libphonenumber from "google-libphonenumber";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import { EmployeeLookupService } from "./employee-lookup.service";

@Component({
  selector: "employee-form",
  templateUrl: "./employee-form.component.html",
  styleUrls: ["./employee-form.component.scss"],
  providers: [EmployeeLookupService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeFormComponent implements OnInit, AfterViewInit {
  @Input() type: string;
  @Input() countryCode: string;
  @Input() countryCodeNumbers: number;
  @Input() languageCode: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<EmployeeDTO>();

  tab$: Observable<SidebarTab>;
  form: FormGroup;

  constructor(
    private employeeLookupService: EmployeeLookupService,
    private phoneNumberService: PhoneNumberService,
    private store: Store<AppState>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      firstName: ["", [Validators.required]],
      familyName: ["", [Validators.required]],
      email: [
        "",
        [Validators.required, validateEmail()],
        [this.validateEmailNotTaken.bind(this)],
      ],
      mobile: [
        "",
        [Validators.required, this.getMobileNumberValidators(this.countryCode)],
      ],
      title: "",
    });

    this.tab$ = this.store.pipe(select(getTab(this.type)));
  }

  ngAfterViewInit() {
    this.tab$.pipe(first()).subscribe((tab) => {
      if (tab.dirty) {
        this.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.type,
            value: this.form.value,
          })
        );
      }
      this.startValueChangesStream();
    });
  }

  updateMobileNumberValidators(country: Country): void {
    this.countryCode = country.regionCode;
    this.countryCodeNumbers = country.countryCode;
    const control = this.form.get("mobile");
    control.setValidators([
      Validators.required,
      this.getMobileNumberValidators(country.regionCode),
    ]);
    control.updateValueAndValidity();
  }

  validate(): void {
    if (this.form.valid) {
      const mobile = this.phoneNumberService.toLegacyFormat(
        this.form.get("mobile").value,
        this.countryCode
      );

      const countryCodeNumber =
        this.phoneNumberService.getCountryCodeFromNumber(
          this.form.get("mobile").value,
          this.countryCode
        );

      this.onSubmit.emit(
        new EmployeeDTO({
          ...this.form.value,
          mobile,
          countryCode: countryCodeNumber.toString(),
          mobileCountryCode: countryCodeNumber.toString(),
        })
      );
    } else {
      markAllAsTouched(this.form);
    }
  }

  private startValueChangesStream() {
    this.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setTabValue({ tabType: this.type, value: formValues })
        )
      );
  }

  private getMobileNumberValidators(countryCode): ValidatorFn {
    return validatePhoneNumber(countryCode, {
      type: libphonenumber.PhoneNumberType.MOBILE,
    });
  }

  private validateEmailNotTaken(control: AbstractControl) {
    return observableTimer(200).pipe(
      switchMap(() =>
        this.employeeLookupService
          .checkEmailNotTaken(control.value)
          .pipe(map((isTaken) => (isTaken ? { emailTaken: true } : null)))
      )
    );
  }
}
