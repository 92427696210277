<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'apply_action_on_selected' | translate"
  >
  </sidebar-header>
  <div class="potential-buyer__actions">
    <span
      class="text-link"
      (click)="showSelectedContacts = !showSelectedContacts"
    >
      {{ (pbService.selectedPotentialBuyers$ | async).length }}
      {{ "potential_buyers_selected" | translate }}
    </span>
    <div *ngIf="!!showSelectedContacts">
      <div
        [style.margin-bottom]="'6px'"
        *ngFor="let contact of pbService.selectedPotentialBuyers$ | async"
      >
        <app-q-card [color]="contact.msisdn ? '1' : '2'">
          <ng-container slot="title">
            {{ contact?.firstName }} {{ contact?.familyName }}
          </ng-container>
        </app-q-card>
      </div>
    </div>
    <button class="q-btn--save" (click)="openSendMessageSidebar()">
      {{ "send_message" | translate }}
      <app-icon name="envelope"></app-icon>
    </button>
    <button class="q-btn--save" (click)="onCreateCallingListClicked()">
      {{ "create_calling_list" | translate }}
      <app-icon name="list"></app-icon>
    </button>
    <ng-container
      *ngIf="
        !(
          featureConfigManagerService.getManagePotentialBuyerSidebarConfig()
          | async
        ).editBiddingStatusBatchEnabled
      "
    >
      <ng-container *ngIf="sendPotentialBuyerService.hasIntegration$ | async">
        <app-send-potential-buyers
          [sending]="sendPotentialBuyerService.isSending() | async"
          [progress]="sendPotentialBuyerService.getSendingProgress() | async"
          [errors]="sendPotentialBuyerService.getErrors() | async"
          [externalProviderName]="externalProviderName$ | async"
          [textLine]="'send_as_potential_buyer_to_erp'"
          (send)="handleSendPotentialBuyersToERP()"
        >
        </app-send-potential-buyers>

        <ng-container
          *ngIf="
            potentialBuyersWithoutPhone$ | async as potentialBuyersWithoutPhone
          "
        >
          <alert *ngIf="potentialBuyersWithoutPhone?.length > 0" type="danger">
            <strong translate>without_phone_warning</strong>
            <ul>
              <li *ngFor="let buyer of potentialBuyersWithoutPhone">
                {{ buyer.firstName }} {{ buyer.familyName }}
              </li>
            </ul>
          </alert>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>

  <div class="scroll-view">
    <hr />
    <section class="showings">
      <div class="showing-status" *ngIf="showCreatePrivateShowing$ | async">
        <h4 translate>showing_status</h4>
        <app-q-circle-btn
          [icon]="'plus'"
          [tooltipText]="'create_showing'"
          (click)="openAddShowingModal()"
        ></app-q-circle-btn>
      </div>
      <button
        class="q-btn--save"
        (click)="openShowingAttendanceModal()"
        translate
      >
        manage_showing_attendance
      </button>
    </section>
    <hr />

    <ng-container
      *ngIf="
        (
          featureConfigManagerService.getManagePotentialBuyerSidebarConfig()
          | async
        ).editBiddingStatusBatchEnabled
      "
    >
      <section>
        <app-bidding-status
          [status]="null"
          (statusChange)="handleBiddingStatusChange($event)"
        ></app-bidding-status>
      </section>
    </ng-container>
  </div>
</div>

<standalone-sidebar *ngIf="showCreateCallingListSidebar">
  <div class="sidebar">
    <create-calling-list
      (closeSidebar)="showCreateCallingListSidebar = false"
      [contactIdList]="callingListContactIds$.value"
    >
    </create-calling-list>
  </div>
</standalone-sidebar>
