import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { filter, first, map, Observable } from "rxjs";
import * as fromUser from "@app/shared/user/user.selectors";
import * as userAction from "@app/shared/user/user.actions";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";

@Component({
  selector: "client-switch",
  templateUrl: "./client-switch.component.html",
  styleUrls: ["./client-switch.component.scss"],
})
export class ClientSwitchComponent implements OnInit {
  chosenClient: string = "";
  selectedClient$: Observable<string>;
  clients$: Observable<DropdownItem[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.clients$ = this.store.select(fromUser.getClients).pipe(
      map((clients) =>
        clients.map((client) => ({
          display: client,
          value: client,
        }))
      )
    );
    this.selectedClient$ = this.store.select(fromUser.getSelectedClient);
    this.selectedClient$
      .pipe(
        filter((client) => !!client),
        first()
      )
      .subscribe((clientName) => {
        this.chosenClient = clientName;
      });

    this.clients$
      .pipe(
        filter((clients) => !clients || clients?.length === 0),
        first()
      )
      .subscribe(() => {
        this.store.dispatch(userAction.loadClientRequest());
        this.store.dispatch(userAction.detectClientRequest());
      });
  }

  onSelectionChange(clientName: string): void {
    this.store.dispatch(userAction.switchClientRequest({ clientName }));
    setTimeout(() => {
      const channel = new BroadcastChannel("clientChangedChannel");
      channel.postMessage("clientChanged");
      channel.close();
    }, 200);
  }
}
