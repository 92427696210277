import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { fadeInOutTrigger } from "@app/shared/animations";

@Component({
  selector: "app-send-potential-buyers",
  templateUrl: "./send-potential-buyers.component.html",
  styleUrls: ["./send-potential-buyers.component.scss"],
  animations: [fadeInOutTrigger()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendPotentialBuyersComponent {
  @Input() disabled: boolean = false;
  @Input() sending: boolean = false;
  @Input() progress: number | null = null;
  @Input() errors: string[] = [];
  @Input() textLine: string;
  @Input() externalProviderName: string;

  @Output() send = new EventEmitter<void>();
}
