import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import {
  createContactedStatusOptimistic,
  fetchContactedStatusSuccess,
} from "@app/sidebar/potential-buyer/ngrx/contacted-status/contacted-status.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";
import { PotentialBuyerState } from "@app/sidebar/potential-buyer/ngrx";

export function contactedStatusReducer(
  state: boolean | null,
  action: Action
): boolean | null {
  return reducer(state, action);
}

const reducer = createReducer(
  null,
  on(
    fetchContactedStatusSuccess,
    createContactedStatusOptimistic,
    (_state, { payload }) => payload
  ),
  on(potentialBuyerActions.reset, () => null)
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.potentialBuyer
);
export const getContacted = createSelector(
  getState,
  (state: PotentialBuyerState) => state.contacted
);
