import { createAction, props } from "@ngrx/store";
import { Polygon } from "geojson";
import { SalesFocusAreaEntity } from "@app/settings/map-areas/focus-areas/models/sales-focus-area-entity";

export const fetchSalesFocusAreas = createAction(
  "[Sales Focus Areas] Fetch Sales Focus Areas For Agents In Offices",
  props<{
    officeIds: string[] | null;
    agentIds: string[] | null;
    entityType: "agent" | "office" | null;
  }>()
);

export const fetchSalesFocusAreasSuccess = createAction(
  "[Sales Focus Areas] Fetch Sales Focus Areas For Agents In Offices Success",
  props<{ areas: SalesFocusAreaEntity[] }>()
);

export const fetchSalesFocusAreasFail = createAction(
  "[Sales Focus Areas] Fetch Sales Focus Areas For Agents In Offices Fail",
  props<{ message: string }>()
);

export const searchSalesFocusAreas = createAction(
  "[Sales Focus Areas] Search Sales Focus Areas By Address",
  props<{
    address: string;
  }>()
);

export const searchSalesFocusAreasSuccess = createAction(
  "[Sales Focus Areas] Search Sales Focus Areas Ny Address Success",
  props<{ areas: SalesFocusAreaEntity[] }>()
);

export const searchSalesFocusAreasFail = createAction(
  "[Sales Focus Areas] Search Sales Focus By Address Fail",
  props<{ message: string }>()
);

export const resetModifiedArea = createAction(
  "[Sales Focus Area] Reset Modified Area"
);

export const removeSalesFocusArea = createAction(
  "[Sales Focus Areas] Remove Sales Focus Area",
  props<{ id: string }>()
);

export const updateSalesFocusArea = createAction(
  "[Sales Focus Areas] Update Sales Focus Area",
  props<{ id: string; coordinates: { lat: number; lng: number }[] }>()
);

export const renameSalesFocusArea = createAction(
  "[Sales Focus Areas] Rename Sales Focus Area",
  props<{ id: string; name: string; jsonData?: Record<string, unknown> }>()
);

export const renameSalesFocusAreaSuccess = createAction(
  "[Sales Focus Areas] Rename Sales Focus Area Success",
  props<{ area: SalesFocusAreaEntity }>()
);

export const renameSalesFocusAreaFail = createAction(
  "[Sales Focus Areas] Rename Sales Focus Area Fail"
);

export const reassignSalesFocusArea = createAction(
  "[Sales Focus Areas] Reassign Sales Focus Area",
  props<{ id: string; officeId: string; agentId?: string }>()
);

export const reassignSalesFocusAreaSuccess = createAction(
  "[Sales Focus Areas] Reassign Sales Focus Area Success",
  props<{ area: SalesFocusAreaEntity }>()
);

export const reassignSalesFocusAreaFail = createAction(
  "[Sales Focus Areas] Reassign Sales Focus Area Fail",
  props<{ id: string }>()
);

export const saveSalesFocusArea = createAction(
  "[Sales Focus Areas] Save Sales Focus Area",
  props<{ area: SalesFocusAreaEntity }>()
);

export const saveSalesFocusAreaSuccess = createAction(
  "[Sales Focus Areas] Save Sales Focus Area Success",
  props<{ area: SalesFocusAreaEntity }>()
);

export const saveSalesFocusAreaFail = createAction(
  "[Sales Focus Areas] Save Sales Focus Area Fail"
);

export const createSalesFocusArea = createAction(
  "[Sales Focus Area] Create Focus Area",
  props<{
    geometry: Polygon;
    agent?: {
      id: string;
      name: string;
    };
    office: {
      id: string;
      name: string;
    };
    name: string;
    jsonData: Record<string, unknown>;
  }>()
);

export const createSalesFocusAreaSuccess = createAction(
  "[Sales Focus Area] Create Focus Area Success",
  props<{ area: SalesFocusAreaEntity }>()
);

export const createSalesFocusAreaFail = createAction(
  "[Sales Focus Area] Create Focus Area Fail",
  props<{ error: string }>()
);

export const optimalNumbersInFocusAreaRequest = createAction(
  "[Sales Focus Area] OPTIMAL Focus Area REQUEST",
  props<{ id: string; geometry: Polygon }>()
);

export const optimalNumbersInFocusAreaSuccess = createAction(
  "[Sales Focus Area] OPTIMAL Focus Area SUCCESS",
  props<{ id: string; numberOfContacts: number }>()
);

export const optimalNumbersInFocusAreaFail = createAction(
  "[Sales Focus Area] OPTIMAL Focus Area FAIL",
  props<{ id: string }>()
);

export const refetch = createAction("[Sales Focus Area] Refetch");
