<div>
  <span
    *ngIf="!!contactType"
    [class]="'icon ' + contactIconFunctions.getContactIconClass(contactType)"
  >
    <app-icon
      size="none"
      [name]="contactIconFunctions.getContactIcon(contactType)"
    ></app-icon>
  </span>
  <span class="name-wrapper"> <ng-content></ng-content>&nbsp; </span>&nbsp;
  <span *ngIf="summaryEnabled" class="contact-summary-container">
    <contact-summary
      [contactId]="contactId"
      [matTooltip]="'flash_info' | translate"
      matTooltipClass="q-tooltip"
    ></contact-summary>
  </span>
</div>
