import { Pipe, PipeTransform } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getObjectStatus } from "@app/shared/ngrx/shared.reducer";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Pipe({
  name: "objectStatusName",
})
export class ObjectStatusNamePipe implements PipeTransform {
  objectStatuses$: Observable<ObjectStatus[]>;

  constructor(private store: Store<AppState>) {
    this.objectStatuses$ = this.store.pipe(select(getObjectStatus));
  }

  transform(status: string | number): Observable<string> {
    return this.objectStatuses$.pipe(
      map(
        (statuses: ObjectStatus[]) =>
          statuses.find((s) => s.status.toString() === status.toString())?.name
      )
    );
  }
}
