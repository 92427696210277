import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as showingActions from "@app/sidebar/potential-buyer/ngrx/showing/showing.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface PrivateShowingState {
  storing: boolean;
  stored: boolean;
}

const initialState: PrivateShowingState = {
  storing: false,
  stored: false,
};

export function privateShowingReducer(
  state: PrivateShowingState,
  action: Action
): PrivateShowingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(showingActions.storeShowingRequest, (state) => ({
    ...state,
    storing: true,
    stored: false,
  })),
  on(showingActions.storeShowingSuccess, (state) => ({
    ...state,
    storing: false,
    stored: true,
  })),
  on(showingActions.storeShowingFail, (state) => ({
    ...state,
    storing: false,
    stored: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.potentialBuyer.private_showing
);

export const getStoring = createSelector(
  getState,
  (state: PrivateShowingState) => state.storing
);

export const getStored = createSelector(
  getState,
  (state: PrivateShowingState) => state.stored
);
