import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { Note, PaginationListDTO } from "@app/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { catchError, map, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class NoteService extends CustomEntityCollectionServiceBase<Note> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Note", serviceElementsFactory);
  }

  refreshWithContactId = (): Observable<any> | any => {};
  getWithContactId(contactId: string, params: any) {
    this.refreshWithContactId = () => this.getWithContactId(contactId, params);
    this.setLoading(true);
    return this.apiService
      .get(`notes/for-contact/${contactId}/search`, params)
      .pipe(
        map((res: PaginationListDTO) => {
          this.upsertManyInCache(res.rows);
          this.setListDefaults(res.rows, res);
          return res.rows;
        }),
        catchError((err) => err)
      );
  }

  patch(id: string, entity: Partial<Note>) {
    // Todo: Replace this when we fetch notes instead of actions.
    this.setLoading(true);
    return this.apiService
      .patch(
        `notes/${id}`,
        {
          ...entity,
        },
        "api"
      )
      .pipe(
        map((response: any) => {
          const note = new Note(response);
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.success({ message: "note_updated" })
          );
          return note;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "note_update_failed" })
          );
          return err;
        })
      );
  }

  post(entity: Partial<Note>) {
    this.setLoading(true);
    return this.apiService
      .post(
        `notes`,
        {
          ...entity,
        },
        "api"
      )
      .pipe(
        map((response: any) => {
          const note = new Note(response);
          this.addOneToCache(note);
          this.appStore.dispatch(
            toastActions.success({ message: "note_created" })
          );
          this.setLoading(false);
          return note;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "note_create_failed" })
          );
          return err;
        })
      );
  }
}
