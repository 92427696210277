import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as fromSalesMeeting from "@app/contacts/contact-sales-meetings/contact-sales-meetings.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import { QObject } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { CONNECT_IN_EXTERNAL_PROVIDER } from "@app/shared/utils/tab-types";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import * as externalProviderActions from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import * as fromExternalProvider from "@app/sidebar/external-provider/ngrx/external-provider.reducer";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-connect-in-external-provider",
  templateUrl: "./connect-in-external-provider.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./connect-in-external-provider.component.scss",
  ],
})
export class ConnectInExternalProviderComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild("searchInput", { static: false })
  searchInput: ElementRef;
  @ViewChild("statusDropdown", { static: false })
  statusDropdown: ElementRef;
  allStatuses = "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,23,26";
  tabType = CONNECT_IN_EXTERNAL_PROVIDER;
  chosenObject: QObject | null = null;
  externalProvider$: Observable<ExternalProviderFeature>;
  patching$: Observable<boolean>;
  residences$: Observable<QObject[]>;
  contactId$: Observable<string>;
  chooseResidenceForm: FormGroup;
  countryCode$: Observable<string>;
  objectStatuses$: Observable<ObjectStatus[]>;
  objectSearchResults$: Observable<QObject[]>;
  salesMeetingId$: Observable<string>;
  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  unsubscribe$ = new Subject<void>();
  showSearch = false;
  showError = false;
  loading$: Observable<boolean>;

  constructor(private store: Store<AppState>, private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.getContactResidences();
    this.getObjectStatuses();
    this.initShowSearch();
    this.registerObserverToFormValueChanges();
    this.connectTab();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.loading$ = this.store.pipe(select(fromExternalProvider.getLoading));
    this.externalProvider$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER))
    );
    this.patching$ = this.store.pipe(select(fromExternalProvider.getPatching));
    this.residences$ = this.store.pipe(
      select(fromExternalProvider.getContactResidences)
    );
    this.contactId$ = this.store.pipe(select(fromContact.getContactId));
    this.countryCode$ = this.store.pipe(
      select(fromConfig.getCountry),
      map((value: string) => value.toLowerCase())
    );
    this.objectStatuses$ = this.store.pipe(
      select(fromExternalProvider.getObjectStatuses)
    );
    this.objectSearchResults$ = this.store.pipe(
      select(fromExternalProvider.getObjectSearchResults)
    );
    this.salesMeetingId$ = this.store.pipe(
      select(fromSalesMeeting.getSelectedSalesMeetingEaCrmSalesMeetingId)
    );
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstValue, currentValue]) => {
        if (_.isEqual(firstValue, currentValue)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  registerObserverToFormValueChanges(): void {
    this.chooseResidenceForm.valueChanges
      .pipe(
        map(() => this.chooseResidenceForm.getRawValue()),
        startWith(this.chooseResidenceForm.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.proxy$);
  }

  buildForm(): void {
    this.chooseResidenceForm = this.fb.group({
      residence: [""],
      street: [""],
    });
  }

  initShowSearch(): void {
    this.chooseResidenceForm
      .get("residence")
      .valueChanges.subscribe((value) => {
        this.showSearch = value === "another";
        if (value === "another") {
          this.chosenObject = null;
          this.showSearch = true;
        } else {
          this.chosenObject = value;
          this.showSearch = false;
        }
      });
  }

  getContactResidences(): void {
    this.contactId$
      .pipe(first())
      .subscribe((contactId: string) =>
        this.store.dispatch(
          externalProviderActions.getContactResidences(contactId)
        )
      );
  }

  getObjectStatuses(): void {
    this.countryCode$
      .pipe(first())
      .subscribe((country) =>
        this.store.dispatch(
          externalProviderActions.getObjectStatusesRequest({ country })
        )
      );
  }

  getResidenceSuggestions(): void {
    this.store.dispatch(
      externalProviderActions.getObjectSearchResults({
        keyword: this.searchInput.nativeElement.value,
        filterStatus:
          this.statusDropdown.nativeElement.value === "all"
            ? this.allStatuses
            : this.statusDropdown.nativeElement.value,
      })
    );
  }

  removeChosenObject(): void {
    this.chosenObject = null;
    this.chooseResidenceForm.get("residence").setValue("");
  }

  selectObject(object: QObject): void {
    this.chosenObject = object;
    this.showError = false;
  }

  submit(): void {
    if (this.chosenObject) {
      this.salesMeetingId$
        .pipe(
          first(),
          filter((value) => !!value)
        )
        .subscribe((eaSalesMeetingId: string) =>
          this.store.dispatch(
            externalProviderActions.patchSalesMeetingResidenceRequest({
              eaOid: this.chosenObject.eaOid,
              eaSalesMeetingId,
            })
          )
        );
    } else {
      this.showError = true;
    }
  }
}
