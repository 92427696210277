import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { first, Observable, Subject } from "rxjs";

import { AppState } from "@app/app.reducer";
import { ValueMonitorService } from "@app/core/ngrx/entity-services/value-monitor.service";
import { PostValueMonitorBody } from "@app/models";
import { getEaEmployeeId } from "@app/shared/user";
import { VALUE_MONITOR } from "@app/shared/utils/tab-types";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { ValueMonitorFormComponent } from "@app/sidebar/value-monitor/components/value-monitor-form/value-monitor-form.component";

@Component({
  selector: "app-value-monitor-create",
  templateUrl: "./value-monitor-create.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./value-monitor-create.component.scss",
  ],
})
export class ValueMonitorCreateComponent
  extends ConnectTab
  implements OnInit, IConnectableTab, OnDestroy
{
  @ViewChild(ValueMonitorFormComponent, { static: true })
  valueMonitorFormComponent;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  eaEmployeeId$: Observable<string>;

  tabType = VALUE_MONITOR;
  data;
  parentRoute: ActivatedRouteSnapshot;
  path: string;

  constructor(
    store: Store<AppState>,
    private route: ActivatedRoute,
    private valueMonitorService: ValueMonitorService
  ) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.eaEmployeeId$ = this.store.pipe(select(getEaEmployeeId));
    this.parentRoute = this.route.snapshot.parent.parent.parent.firstChild;
    this.path = this.parentRoute.routeConfig.path;

    this.setData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setData() {
    this.eaEmployeeId$.pipe(first()).subscribe((eaEmployeeId) => {
      this.data = {
        contactId: this.getContact(),
        eaOid: this.getObject(),
        baseValuation: this.getBaseValuation(),
        baseValuationDate: this.getBaseValuationDate(),
        eaEmployeeId: eaEmployeeId,
      };
    });
  }

  getContact() {
    if (this.path === "contacts") {
      return this.parentRoute.firstChild.params["id"];
    } else {
      return "";
    }
  }

  getObject() {
    if (this.path === "showings") {
      return this.parentRoute.firstChild.params["eaOid"];
    } else {
      return "";
    }
  }

  getBaseValuation() {
    return "0";
  }

  getBaseValuationDate() {
    return "";
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    this.valueMonitorFormComponent.submit((body: PostValueMonitorBody) => {
      this.valueMonitorService.post(body).subscribe(() => {
        this.valueMonitorService.refreshFetch$.next();
        this.closeTab();
      });
    });
  }
}
