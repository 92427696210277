import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { BiddingStatus } from "@app/sidebar/potential-buyer/models/bidding-status";

@Component({
  selector: "app-bidding-status",
  templateUrl: "./bidding-status.component.html",
  styleUrls: ["./bidding-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiddingStatusComponent {
  @Input() status: string;
  @Output() statusChange = new EventEmitter<string>();

  Status = BiddingStatus;

  handleClick(status: string): void {
    const isSelected = this.isSelected(status);
    const newStatus = isSelected ? BiddingStatus.Unknown : status;
    this.statusChange.emit(newStatus);
  }

  isSelected(status: string): boolean {
    if (!this.status) {
      return;
    }
    return this.status === status;
  }
}
