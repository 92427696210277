import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { getCountry, getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { CREATE_OBJECT } from "@app/shared/utils/tab-types";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import * as actions from "./ngrx/create-object.actions";
import {
  DelegatedObjectRequirements,
  ObjectField,
} from "./ngrx/create-object.actions";
import * as fromReducer from "./ngrx/create-object.reducer";
import * as fromUser from "@app/shared/user";
import { OfficeService } from "@app/core/ngrx/entity-services/office.service";
import { Office } from "@app/models";
import { Actions, ofType } from "@ngrx/effects";

export interface CreateObjectApiParams {
  baseObjectType: string;
  city?: string;
  eaOid?: string;
  integration: string;
  propertyDesignation?: string;
  sellers?: [
    {
      contactId: string;
      share?: string;
    }
  ];
  streetAddress?: string;
  zip?: string;
}

@Component({
  selector: "create-object",
  templateUrl: "./create-object.component.html",
  styleUrls: [
    "../sidebar.component.common.scss",
    "../shared/sidebar-header/sidebar-header.component.scss",
    "./create-object.component.scss",
  ],
})
export class CreateObjectComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @Output() closeSidebar: EventEmitter<void> = new EventEmitter<void>();

  tab$: Observable<SidebarTab>;
  tabType = CREATE_OBJECT;
  unsubscribe$ = new Subject<void>();
  proxy$ = new Subject<any>();
  form: FormGroup;
  delegatedObjectRequirements$: Observable<DelegatedObjectRequirements>;
  loading$: Observable<boolean>;
  storingBrf$: Observable<boolean>;
  storingLantmateri$: Observable<boolean>;
  storingManual$: Observable<boolean>;
  currentUserOffice$: Observable<Office>;
  requirements: DelegatedObjectRequirements;
  requiredFormFields: ObjectField[] = [];
  optionalFormFields: ObjectField[] = [];

  // if address suggester and validation should be enabled
  addressSuggesterFeature$: Observable<Feature>;
  addressValidationFeature$: Observable<Feature>;
  selectedAddress: Address;
  defaultCountryCodeISO = "EN";

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private officeService: OfficeService,
    private translate: TranslateService,
    private readonly actions$: Actions
  ) {
    this.buildForm();
  }

  buildForm() {
    this.form = this.fb.group({
      baseObjectType: ["", Validators.required],
    });
  }

  ngOnInit() {
    this.mapStateToProps();
    this.connectTab();
    this.loadObjectRequirements();
    this.handleObjectTypeChanged();

    this.actions$.pipe(ofType(actions.storeObjectSuccess)).subscribe(() => {
      this.form.reset();
      setTimeout(() => {
        this.store.dispatch(
          sidebarActions.closeTab({ tabType: CREATE_OBJECT })
        );
      }, 500);
    });
  }

  mapStateToProps() {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.delegatedObjectRequirements$ = this.store.pipe(
      select(fromReducer.getDelegatedObjectRequirements)
    );
    this.delegatedObjectRequirements$
      .pipe(filter(Boolean))
      .subscribe(
        (requirements: DelegatedObjectRequirements) =>
          (this.requirements = requirements)
      );
    this.loading$ = this.store.pipe(select(fromReducer.getLoading));
    this.storingBrf$ = this.store.pipe(select(fromReducer.getStoringBrf));
    this.storingLantmateri$ = this.store.pipe(
      select(fromReducer.getStoringLantmateri)
    );
    this.storingManual$ = this.store.pipe(select(fromReducer.getStoringManual));
    this.addressSuggesterFeature$ = this.store.pipe(
      select(getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressValidationFeature$ = this.store.pipe(
      select(getFeature(features.ADDRESS_VALIDATION))
    );
    this.store
      .pipe(
        select(getCountry),
        takeUntil(this.unsubscribe$),
        take(2), // initial state & setting value
        map((v) => v.toUpperCase())
      )
      .subscribe(
        (countryCodeISO) => (this.defaultCountryCodeISO = countryCodeISO)
      );

    this.currentUserOffice$ = this.officeService.entityMap$.pipe(
      withLatestFrom(this.store.pipe(select(fromUser.getEaOfficeId))),
      map(([offices, eaOfficeId]) => offices[eaOfficeId]),
      filter((office) => !!office)
    );
  }

  loadObjectRequirements(): void {
    this.store.dispatch(actions.getObjectRequirementsRequest());
  }

  handleObjectTypeChanged(): void {
    this.form
      .get("baseObjectType")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((objectType) => {
        this.removeDynamicFormControls();
        this.form = this.fb.group({
          baseObjectType: [objectType, Validators.required],
        });
        this.generateFormForObjectType(objectType);
        this.handleObjectTypeChanged();
      });
  }

  removeDynamicFormControls(): void {
    this.requiredFormFields.forEach((field) =>
      this.form.removeControl(field.field)
    );
    this.optionalFormFields.forEach((field) =>
      this.form.removeControl(field.field)
    );
    this.requiredFormFields = [];
    this.optionalFormFields = [];
  }

  generateFormForObjectType(objectType: string): void {
    const requiredFields =
      this.requirements.objectTypeDescription.objectTypeSpecificRequirements.find(
        (specificRequirement) => specificRequirement.objectType === objectType
      )?.requirements;

    const optionalFields =
      this.requirements.objectTypeDescription.objectTypeSpecificRequirements.find(
        (specificRequirement) => specificRequirement.objectType === objectType
      )?.optionalFields;

    if (requiredFields && requiredFields.length > 0) {
      requiredFields.forEach((field) => {
        this.form.addControl(
          field.field,
          new FormControl("", Validators.required)
        );
      });
      this.requiredFormFields = requiredFields;
    }

    if (optionalFields && optionalFields.length > 0) {
      optionalFields.forEach((field) => {
        this.form.addControl(field.field, new FormControl(""));
      });
      this.optionalFormFields = optionalFields;
    }
  }

  submit(createMode: string): void {
    if (this.form.valid) {
      this.store.dispatch(
        actions.storeObjectRequest({
          params: {
            apiParams: this.buildAndGetAPIParams(),
            createMode,
          },
        })
      );
    } else {
      markAllAsTouched(this.form);
    }
  }

  buildAndGetAPIParams(): CreateObjectApiParams | any {
    const apiParams = { integration: this.requirements.integration };
    Object.keys(this.form.value).forEach((field) => {
      if (this.form.get(field)) {
        apiParams[field] = this.form.get(field).value;
      }
    });

    if (
      this.selectedAddress?.street &&
      this.form?.get("streetAddress")?.value
    ) {
      apiParams["streetAddress"] = this.selectedAddress.street;
    }

    const pattern = "contacts/(.*)/overview";
    const result = location.href.match(pattern);

    if (result && result[1]) {
      const contactId = result[1];
      apiParams["sellers"] = [{ contactId }];
    }

    return apiParams;
  }

  getObjectTypeName(integration: string, objectTypeName: string): string {
    const languageKey =
      `DESWINGLISHER_${integration}_${objectTypeName}`.toUpperCase();
    return this.translate.instant(languageKey);
  }

  getFieldNameTranslation(fieldName: string): string {
    return this.translate.instant(fieldName.toLowerCase());
  }

  isAddressField(fieldName: string): boolean {
    const addressFields = ["streetAddress", "zip", "city"];
    return addressFields.includes(fieldName);
  }

  closeTab(): void {
    this.closeSidebar.emit();
    this.store.dispatch(sidebarActions.closeTab({ tabType: CREATE_OBJECT }));
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  isFormValueValid(val: string): boolean {
    return (
      this.form.get(val) &&
      !this.form.get(val).valid &&
      this.form.get(val).touched
    );
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleZipSelected(address: Address): void {
    this.selectedAddress = address;
    this.form.get("zip").setValue(address?.zip ?? null);
    this.form.get("city").setValue(address?.city ?? null);
    this.form.updateValueAndValidity();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleStreetSelected(address: Address): void {
    this.selectedAddress = address;
    this.form.get("zip").setValue(address?.zip ?? null);
    this.form.get("city").setValue(address?.city ?? null);
    this.form.get("streetAddress").setValue(address?.street ?? null);
    this.form.updateValueAndValidity();
  }

  hasPermissionToCreateLantmateri() {
    return this.currentUserOffice$.pipe(
      first(),
      switchMap((currentOffice) => {
        const isNotTenantType =
          this.form.get("baseObjectType").value.length > 0 &&
          this.form.get("baseObjectType").value.toUpperCase() !==
            "OBJECT_TYPE_TENANT_OWNERSHIP";

        if (
          !currentOffice?.countryCode ||
          currentOffice?.countryCode.toLowerCase() !== "se"
        ) {
          return of(false);
        }

        return of(isNotTenantType);
      })
    );
  }
}
