import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";

@Component({
  selector: "app-q-search-input",
  templateUrl: "./q-search-input.component.html",
  styleUrls: ["./q-search-input.component.scss"],
})
export class QSearchInputComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() placeholder = "";
  @Input() invalid: boolean;
  @Input() data: unknown[];

  @Output() search = new EventEmitter<string>();
  @Output() itemClick = new EventEmitter<any>();

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  text = new FormControl("");
  show = false;
  ngOnInit(): void {
    this.text.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
      this.search.emit(value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.show = changes.data?.currentValue?.length > 0;
  }

  onClick(item: any) {
    this.show = false;
    this.itemClick.emit(item);
  }
}
