import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Contact, TaskType, TypedPaginationListDTO } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as consentTypes from "@app/shared/utils/consent-types";
import {
  CONTACT_CREATE,
  CONTACT_CREATE_POTENTIAL_BUYER,
  CONTACT_EDIT,
} from "@app/shared/utils/tab-types";
import { PotentialBuyer, ShowingObject } from "@app/showings/models";
import * as potentialBuyerActions from "@app/showings/ngrx/potential-buyer/potential-buyer.actions";
import {
  ContactShowingAttendance,
  ContactShowingAttendanceStatus,
} from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import * as residenceActions from "@app/sidebar/residence/ngrx/residence.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import {
  catchError,
  filter,
  first,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  tap,
} from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as ccActions from "./create-contact.actions";
import { Action } from "@ngrx/store";

@Injectable()
export class CreateContactEffects {
  getContactSourceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.getContactSourceRequest),
      switchMap(({ type, ...params }) =>
        this.apiService.get("task-types/search", params).pipe(
          map((response: TypedPaginationListDTO<TaskType>) => {
            const taskTypes = [...response.rows].sort((r1, r2) =>
              r1.typeName.localeCompare(r2.typeName)
            );
            return ccActions.getContactSourceSuccess({ taskTypes });
          }),
          catchError(() => observableOf(ccActions.getContactSourceFail()))
        )
      )
    )
  );

  createContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.createContactRequest),
      switchMap(({ params, closeSidebar, eaTaskTypeId, showingAttendance }) => {
        const consentParams = {
          ...params,
          consent: {
            activeConsentTypeAlias: consentTypes.EMPLOYEE_ENTRY,
            consentValidTo: moment().add(30, "days").format(API_DATE_FORMAT),
            consentNote: this.translate.instant("from_create_contact_form"),
            consentSourceType: "user",
            consentSourceId: params.eaEmployeeId,
          },
        };
        return this.apiService.post("contacts", consentParams).pipe(
          mergeMap((contact: Contact) => {
            const translations = this.translate.instant(
              ["goto_contact_card", "contact_{name}_created"],
              {
                name: `${params.firstName} ${params.familyName}`,
              }
            );

            const actions = [
              ...this.getDefaultPostCreateActions(
                translations,
                contact.contactId
              ),
              ...this.getConditionalPostCreateActions(
                { ...params, eaTaskTypeId, contactId: contact.contactId },
                closeSidebar
              ),
            ];
            return params.type === "potentialbuyer"
              ? observableOf(
                  ccActions.addContactToShowing(
                    contact,
                    params.eaOid.toString(),
                    showingAttendance,
                    closeSidebar
                  )
                )
              : observableFrom(actions);
          }),
          catchError(() => observableOf(ccActions.createContactFail()))
        );
      })
    )
  );

  addContactToSourceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.addContactToSourceRequest),
      filter(({ params }) => !!params.eaTaskTypeId && !!params.contactId),
      switchMap(({ params }) =>
        this.apiService.post("tasks", params).pipe(
          map(() => ccActions.addContactToSourceSuccess()),
          catchError(() => observableOf(ccActions.addContactToSourceFail()))
        )
      )
    )
  );

  updateContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.updateContactRequest),
      switchMap(({ contactId, params, origin }) =>
        this.apiService
          .patch(`contacts/${contactId}`, {
            ...params,
            clearNonPresentNullableFields: true,
            origin,
          })
          .pipe(
            map((contact: Contact) =>
              params.type === "potentialbuyer"
                ? ccActions.addContactToShowing(
                    contact,
                    params.eaOid.toString(),
                    []
                  )
                : ccActions.updateContactSuccess({ contact })
            ),
            catchError(() => observableOf(ccActions.updateContactFail()))
          )
      )
    )
  );

  updateContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.updateContactSuccess),
      mergeMap(({ contact }) => {
        const message = this.translate.instant("contact_updated");
        return observableFrom([
          sidebarActions.closeTab({ tabType: CONTACT_EDIT }),
          toastActions.success({ message }),
          residenceActions.getResidenceContactSuccess({ contact }),
        ]);
      })
    )
  );

  addContactToShowingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.addContactToShowingRequest),
      switchMap(({ contact, params, showingAttendance, closeSidebar }) =>
        this.apiService
          .post(
            `contacts/${contact.contactId}/contact-object-connections`,
            params
          )
          .pipe(
            map(() =>
              ccActions.addContactToShowingSuccess({
                closeSidebar,
                contact,
                showingAttendance,
              })
            ),
            catchError(() => observableOf(ccActions.addContactToShowingFail()))
          )
      )
    )
  );

  addContactToShowingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.addContactToShowingSuccess),
      tap(({ showingAttendance }) => {
        if (showingAttendance.length === 0) {
          this.contactService
            .refreshPotentialBuyers()
            .pipe(first())
            .subscribe();
        }
      }),
      mergeMap(({ closeSidebar, contact, showingAttendance }) => {
        const message = this.translate.instant("potential_buyer_added");
        const actions = [
          toastActions.success({ message }),
          ...showingAttendance
            .filter(
              (attendance: ContactShowingAttendance) =>
                attendance.registrationStatus !==
                ContactShowingAttendanceStatus.NotRegistered
            )
            .map((attendance: ContactShowingAttendance) =>
              ccActions.updateShowingAttendanceRequest({
                contactId: contact.contactId,
                attendance,
              })
            ),
        ];
        if (closeSidebar) {
          actions.push(
            sidebarActions.closeTab({
              tabType: CONTACT_CREATE_POTENTIAL_BUYER,
            }),
            potentialBuyerActions.setSelectedPotentialBuyers({
              potentialBuyers: [new PotentialBuyer(contact)],
            })
          );
        }
        return observableFrom(actions);
      })
    )
  );

  getShowings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.getShowingsRequest),
      switchMap(({ eaOid }) =>
        this.apiService.get(`showing-objects/${eaOid}`, {}).pipe(
          map((showingObject: ShowingObject) =>
            ccActions.getShowingsSuccess({ showings: showingObject.showings })
          ),
          catchError(() => observableOf(ccActions.getShowingsFail()))
        )
      )
    )
  );

  updateShowingAttendance$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ccActions.updateShowingAttendanceRequest),
      mergeMap(({ contactId, attendance }) =>
        this.apiService
          .post(
            `contact-showing-attendances`,
            {
              contactId,
              registrationStatus: attendance.registrationStatus,
              showingId: attendance.showingId,
              eaShowingSlotId: attendance.eaShowingSlotId
                ? attendance.eaShowingSlotId
                : null,
            },
            "api"
          )
          .pipe(
            tap(() =>
              this.contactService
                .refreshPotentialBuyers()
                .pipe(first())
                .subscribe()
            ),
            map(() => ccActions.updateShowingAttendanceSuccess()),
            catchError(() =>
              observableOf(ccActions.updateShowingAttendanceFail())
            )
          )
      )
    )
  );

  getDefaultPostCreateActions(
    translations: string[],
    contactId: string
  ): Action[] {
    return [
      toastActions.success(
        { message: translations["contact_{name}_created"], duration: 8000 },
        {
          label: translations["goto_contact_card"],
          icon: "fa-arrow-right",
          action: RouterActions.go({ path: ["crm", "contacts", contactId] }),
        }
      ),
      ccActions.createContactSuccess({ contactId }),
    ];
  }

  getConditionalPostCreateActions(
    params: Record<string, unknown>,
    closeSidebar: boolean
  ): Action[] {
    const actions = [];
    if (params.eaTaskTypeId) {
      actions.push(ccActions.addContactToSource(params));
    }
    if (closeSidebar) {
      actions.push(
        sidebarActions.closeTabs({
          tabTypes: [CONTACT_CREATE, CONTACT_CREATE_POTENTIAL_BUYER],
        })
      );
    }
    return actions;
  }

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translate: TranslateService,
    private contactService: ContactService
  ) {}
}
