<h3 *ngIf="showHeader">
  <app-icon size="large" name="chart-line"></app-icon>&nbsp;
  <span>
    {{ "kpi" | translate }}
  </span>
</h3>
<div class="widget-container">
  <div class="widget-container--content">
    <kpi-collection
      *ngIf="showKpi$ | async"
      [types]="enabledKpiTypes$ | async"
      [from]="kpiSearchParams.from"
      [to]="kpiSearchParams.to"
      [eaOfficeId]="(userIds$ | async)?.eaOfficeId"
      [eaEmployeeId]="(userIds$ | async)?.eaEmployeeId"
      [useDefaultDate]="true"
      (onClick)="onKpiClick($event)"
      (onNpsClick)="onNpsClick($event)"
    >
    </kpi-collection>
  </div>
</div>
