import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { debounceTime, Observable } from "rxjs";

import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";

@Component({
  selector: "app-q-multi-select",
  templateUrl: "./q-multi-select.component.html",
  styleUrls: [
    "../q-select/q-select.component.scss",
    "./q-multi-select.component.scss",
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QMultiSelectComponent),
    },
  ],
})
export class QMultiSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() label: string;
  @Input() placeholder = "select";
  @Input() options: Select;
  @Input() inline: boolean;
  @Input() valueIsArray: boolean = false;
  @Input() resetSelection: boolean = false;
  @Input() atleastOne: boolean;

  show = false;
  searchText = new FormControl("");
  private _value = [];
  touched = false;
  disabled = false;

  searchText$: Observable<string>;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  ngOnInit(): void {
    this.searchText$ = this.searchText.valueChanges.pipe(debounceTime(300));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.resetSelection && changes.resetSelection) {
      this._value = [];
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.value = !!this.valueIsArray ? obj : obj?.split(",");
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggle() {
    this.show = !this.show;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      if (this?.value?.includes(value)) {
        if (this?.atleastOne && this?.value?.length === 1) {
          return;
        }
        this.value = this?.value?.filter((x) => x !== value);
      } else {
        this?.value?.push(value);
      }
      this?.onChange(this.valueIsArray ? this?.value : this?.value?.join(","));
    }
  }

  getSelectedName() {
    return this.value
      .map((v) => this?.options?.find((x) => x?.value === v)?.label)
      .join(", ");
  }

  isSelected(value: string) {
    return this?.value?.includes(value);
  }

  onClickOutside() {
    this.show = false;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
