import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";

@Component({
  selector: "achieved-targets",
  templateUrl: "./achieved-targets.component.html",
  styleUrls: ["./achieved-targets.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AchievedTargetsComponent {
  @HostBinding("class") className: string;

  private _number: number;
  get number(): number {
    return this._number;
  }

  @Input()
  set number(value: number) {
    this._number = value;
    if (value < 34) {
      this.className = "danger";
    } else if (value < 67) {
      this.className = "warning";
    } else {
      this.className = "success";
    }
  }
}
