import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ExternalProviderLink } from "@app/models/external-provider-link";

@Component({
  selector: "grouped-search-results",
  templateUrl: "./search-results-grouped.component.html",
  styleUrls: ["./search-results-grouped.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupedSearchResultsComponent {
  @Input() data: any[];
  @Input() hits: number;
  @Input() type: string;
  @Input() groupedResultsIsVisible: boolean;
  @Input() externalProviderUrl: string;
  @Input() externalProviderName: string;
  @Input() showExternalProviderLink: boolean;
  @Input() externalProviderLinks: ExternalProviderLink[];
  @Input() externalProviderLinksLoading: boolean;

  @Output() getExternalProviderLinks: EventEmitter<string> =
    new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onShowAllSearchTypes: EventEmitter<any> = new EventEmitter();

  showAllSearchTypes() {
    this.onShowAllSearchTypes.emit({});
  }

  handleGetProviderLinks(event: string): void {
    this.getExternalProviderLinks.emit(event);
  }
}
