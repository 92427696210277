import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, Country } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent as observableFromEvent,
  map,
  Observable,
  of as observableOf,
  Subject,
  Subscription,
  take,
  takeUntil,
} from "rxjs";
import { getLanguage, getPhonePrefix } from "../../config/config.reducer";
import { SearchProviderContact } from "./models/search-provider-contact";
import { SearchContactService } from "./search-contact.service";

@Component({
  selector: "search-contact",
  templateUrl: "./search-contact.component.html",
  styleUrls: ["./search-contact.component.scss"],
  providers: [SearchContactService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchContactComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("searchInput", { static: true })
  searchInput: ElementRef;
  @Input() group: FormGroup;
  @Input() controlName = "search";
  @Input() filter = "";
  @Input() placeholder = "";
  @Input() extended = false;
  @Input() icon: string;
  @Input() hideEmptyResults = false;
  @Input() showSearchResults = true;
  @Input() showSpinner = true;
  @Input() showQuedroSearchResult = true;
  @Input() showPlaceholderInsteadOfLabelIfIpad = false;
  @Input() hideFlags = false;
  @Input() smallInputs = false;
  @Input() label = "";
  @Input() waitForRequiredChar = true;
  @Input() additionalSearchText;
  @Input() contactType: "company" | "person" = "person";

  @Input() requiredChars = [];
  @Input() minLength = 1;
  @Input() debounceTime = 350;
  @Input() prefix: any = "";

  @Input() focus = false;

  @Input() countryCode: string;

  @Output() quedroClicked: EventEmitter<Contact> = new EventEmitter();
  @Output() eniroClicked: EventEmitter<SearchProviderContact> =
    new EventEmitter();
  @Output() quedroResult: EventEmitter<Contact[]> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onCountryChange: EventEmitter<Country> = new EventEmitter();

  focus$ = new BehaviorSubject<boolean>(false);

  quedroContacts$: Observable<Contact[]>;
  eniroContacts$: Observable<SearchProviderContact[]>;
  loading$: Observable<boolean>;
  isPad$: Observable<boolean>;

  keyup$$: Subscription;
  blur$$: Subscription;
  focus$$: Subscription;
  cleanUp$$: Subscription;
  unsubscribe$ = new Subject<void>();

  predefinedValue = "";

  constructor(
    private store: Store<AppState>,
    private searchContactService: SearchContactService,
    private phoneNumberService: PhoneNumberService
  ) {
    this.group = new FormGroup({ search: new FormControl() });
  }

  ngOnInit() {
    this.isPad$ = this.store.pipe(select(fromShared.isPad));
    this.quedroContacts$ = this.searchContactService.quedroContacts$;
    this.eniroContacts$ = this.searchContactService.getEniroContacts();
    this.loading$ = this.searchContactService.getLoading();

    this.keyup$$ = observableFromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter(
          ($event: KeyboardEvent) =>
            (<HTMLInputElement>$event.target).value.length >= this.minLength
        ),
        filter(($event: KeyboardEvent) => {
          if (this.waitForRequiredChar) {
            return !this.requiredChars.some(
              (char: string) =>
                (<HTMLInputElement>$event.target).value.indexOf(char) === -1
            );
          } else {
            return true;
          }
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(($event: KeyboardEvent) => {
        let searchText = (<HTMLInputElement>$event.target).value;
        if (this.additionalSearchText) {
          searchText = `${this.additionalSearchText}${searchText}`;
        }
        this.search(searchText);
      });
    this.cleanUp$$ = observableFromEvent(
      this.searchInput.nativeElement,
      "keyup"
    )
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(this.debounceTime),
        distinctUntilChanged(),
        filter(
          ($event: KeyboardEvent) =>
            (<HTMLInputElement>$event.target).value.length < this.minLength
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.searchContactService.resetData());

    this.blur$$ = observableFromEvent(this.searchInput.nativeElement, "blur")
      .pipe(debounceTime(250), takeUntil(this.unsubscribe$))
      .subscribe(() => this.focus$.next(false));

    this.focus$$ = observableFromEvent(this.searchInput.nativeElement, "focus")
      .pipe(debounceTime(250), takeUntil(this.unsubscribe$))
      .subscribe(() => this.focus$.next(true));

    if (this.filter === "number") {
      this.store
        .pipe(select(getPhonePrefix), take(1))
        .subscribe((prefix) => (this.prefix = `+${prefix}`));
    }
  }

  ngAfterViewInit(): void {
    if (this.focus) {
      this.focus$.next(true);
      this.searchInput.nativeElement.focus();
      setTimeout(() => {
        if (
          this.searchInput.nativeElement.value &&
          this.searchInput.nativeElement.value.length > 0
        ) {
          this.search(this.searchInput.nativeElement.value);
        }
      }, 0);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  search(searchStr: any): void {
    this.store
      .pipe(select(getPhonePrefix), take(1))
      .subscribe((phonePrefix) => {
        let prefix = this.prefix;
        if (this.filter === "freetext" && !prefix) {
          prefix = phonePrefix;
        }

        this.searchContactService.search(searchStr, prefix.toString());
        if (this.extended) {
          if (this.contactType === "company") {
            this.searchContactService.eniroCompanySearchById(searchStr);
          } else {
            this.searchContactService.searchExtended(
              searchStr,
              prefix,
              this.filter,
              this.phoneNumberService.isValidNumber(searchStr, this.countryCode)
            );
          }
        }
      });
  }

  searchAllowed(type: string): Observable<boolean> {
    return this.extended
      ? this.searchContactService.searchAllowed(type)
      : observableOf(false);
  }

  onEniroClick(contact: SearchProviderContact): void {
    this.eniroClicked.emit(contact);
  }

  onClick(contact: Contact): void {
    this.quedroClicked.emit(contact);
  }

  formatNumber(number: string, countryCode: string) {
    return this.phoneNumberService.format(number, countryCode);
  }

  shouldHideEmptyResults(): Observable<boolean> {
    return combineLatest([this.quedroContacts$, this.eniroContacts$]).pipe(
      map(
        ([quedro, eniro]) =>
          this.hideEmptyResults && quedro.length === 0 && eniro.length === 0
      )
    );
  }

  showResult(searchLength: number): Observable<boolean> {
    return combineLatest([
      this.shouldHideEmptyResults(),
      this.focus$,
      this.loading$,
    ]).pipe(
      map(
        ([hide, focus, loading]) =>
          !hide && focus && !loading && searchLength >= this.minLength
      )
    );
  }

  getLanguage(): Observable<string> {
    return this.store.pipe(select(getLanguage));
  }

  handleCountryChange(country: Country) {
    this.prefix = `+${country.countryCode}`;
    this.onCountryChange.emit(country);
  }
}
