import { Injectable } from "@angular/core";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact } from "@app/models";

@Injectable()
export class ContactService {
  constructor(private phoneService: PhoneNumberService) {}

  format(contact: Contact): Contact {
    let formattedConsumer = this.formatMsisdn(contact);
    formattedConsumer = this.formatPhoneNumber(formattedConsumer);
    return formattedConsumer;
  }

  formatMsisdn(contact: Contact): Contact {
    const msisdnExists = contact.msisdn.length > 0;
    const msisdnCountryExists = contact.msisdnCountry.length > 0;
    let formattedMsisdn;

    if (msisdnExists && msisdnCountryExists) {
      formattedMsisdn = this.phoneService.format(
        contact.msisdn,
        contact.msisdnCountry
      );
      contact = Object.assign({}, contact, { msisdn: formattedMsisdn });
    }
    return contact;
  }

  formatPhoneNumber(contact: Contact): Contact {
    const phoneNumberExists = contact.phoneNumber.length > 0;
    const phoneNumberCountryExists = contact.phoneNumberCountry.length > 0;
    let formattedPhoneNumber;

    if (phoneNumberExists && phoneNumberCountryExists) {
      formattedPhoneNumber = this.phoneService.format(
        contact.phoneNumber,
        contact.phoneNumberCountry
      );
      contact = Object.assign({}, contact, {
        phoneNumber: formattedPhoneNumber,
      });
    }
    return contact;
  }
}
