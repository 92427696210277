<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'manage_search_profile_alert' | translate"
  ></sidebar-header>
  <div class="scroll-view" *ngIf="!(loading$ | async); else contentLoader">
    <section *ngIf="alert$ | async as alert; else newAlert">
      <app-search-profile-alert-form
        *ngIf="alertActivities$ | async as alertActivities"
        [alertActivities]="alertActivities"
        (preview)="previewEmail()"
        (submitForm)="createAlert($event)"
        [alert]="alert"
        [canEditEmployee]="(isManager$ | async) || (isAdmin$ | async)"
        [canEditOffice]="isAdmin$ | async"
        [observer]="proxy$"
      >
      </app-search-profile-alert-form>
    </section>
    <ng-template #newAlert>
      <section>
        <app-search-profile-alert-form
          *ngIf="alertActivities$ | async as alertActivities"
          [alertActivities]="alertActivities"
          (preview)="previewEmail()"
          (submitForm)="createAlert($event)"
          [alert]="false"
          [canEditEmployee]="(isManager$ | async) || (isAdmin$ | async)"
          [canEditOffice]="isAdmin$ | async"
          [observer]="proxy$"
        >
        </app-search-profile-alert-form>
      </section>
    </ng-template>
  </div>
  <div class="sidebar-footer">
    <app-q-button buttonType="cancel" label="cancel" (click)="closeTab()">
    </app-q-button>
    <app-q-button
      *ngIf="alert$ | async as alert"
      buttonType="remove"
      label="remove"
      [isLoading]="deleting$ | async"
      (click)="deleteAlert(alert.alertId)"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [isLoading]="patching$ | async"
      [disabled]="isSubmitDisabled()"
      (click)="submit()"
    >
    </app-q-button>
  </div>
</div>

<ng-template #contentLoader>
  <section>
    <app-skeleton-sidebar></app-skeleton-sidebar>
  </section>
</ng-template>
