import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";

import { AppState } from "@app/app.reducer";
import { LEAD_CHANNELS } from "@app/shared/utils/tab-types";
import { LeadChannelsFormComponent } from "@app/sidebar/lead-channels/components/lead-channels-form/lead-channels-form.component";
import * as leadChannelsFormActions from "@app/sidebar/lead-channels/ngrx/lead-channels-form.actions";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";

@Component({
  selector: "app-lead-channels-create",
  templateUrl: "./lead-channels-create.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-channels-create.component.scss",
  ],
})
export class LeadChannelsCreateComponent
  extends ConnectTab
  implements OnDestroy, IConnectableTab
{
  @ViewChild(LeadChannelsFormComponent, { static: true })
  leadChannelsForm: LeadChannelsFormComponent;

  tabType = LEAD_CHANNELS;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();

  constructor(store: Store<AppState>) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    this.leadChannelsForm.submit((body) => {
      this.store.dispatch(
        leadChannelsFormActions.createLeadChannelRequest({
          body: {
            ...body,
            contactWayOption: "email",
            taskTypeCategory: "lead",
            ownGenerated: true,
            direction: "incoming",
            importance: "90",
            mobilePushNotificationEnabled: false,
          },
        })
      );
    });
  }
}
