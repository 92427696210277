import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs";
import { PaginationListDTO } from "@app/models";
import { ContactedStatusResolver } from "@app/sidebar/potential-buyer/services/contacted-status-resolver.service";
import * as fromUser from "@app/shared/user";
import { undo } from "ngrx-undo";
import { refreshShowingObjectContactSummary } from "@app/showings/ngrx/potential-buyer/potential-buyer.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import {
  createContactedStatusFailure,
  createContactedStatusRequest,
  createContactedStatusSuccess,
  fetchContactedStatusFailure,
  fetchContactedStatusRequest,
  fetchContactedStatusSuccess,
} from "@app/sidebar/potential-buyer/ngrx/contacted-status/contacted-status.actions";

@Injectable()
export class ContactedStatusEffects {
  fetchContactedStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactedStatusRequest),
      switchMap(({ type, ...payload }) =>
        this.api.get("actions", payload).pipe(
          map((res: PaginationListDTO) =>
            ContactedStatusResolver.resolve(res.rows[0])
          ),
          map((contacted) =>
            fetchContactedStatusSuccess({ payload: contacted })
          ),
          catchError(() => of(fetchContactedStatusFailure()))
        )
      )
    )
  );

  createContactedStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createContactedStatusRequest),
      withLatestFrom(this.store.pipe(select(fromUser.getEaEmployeeId))),
      switchMap(([{ contactId, body, undoAction }, eaEmployeeId]) => {
        const { contacted, ...rest } = body;
        const legacyProps = ContactedStatusResolver.toLegacy(contacted);
        const newBody = { ...rest, ...legacyProps, eaEmployeeId };

        return this.api
          .post(`contacts/${contactId}/actions`, { ...newBody })
          .pipe(
            map(() => createContactedStatusSuccess()),
            catchError(() =>
              of(createContactedStatusFailure(), undo(undoAction))
            )
          );
      })
    )
  );

  createContactedStatusSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createContactedStatusSuccess),
      tap(() =>
        this.contactService.refreshPotentialBuyers().pipe(first()).subscribe()
      ),
      mergeMap(() => {
        return [refreshShowingObjectContactSummary()];
      })
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private contactService: ContactService
  ) {}
}
