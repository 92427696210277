import {
  ExternalBankTipsStatisticsParams,
  ExternalBankTipsStatisticsResponse,
} from "@app/statistics/statistics-external-bank-tips/models/models";
import { createAction, props } from "@ngrx/store";

export const getExternalBankTipsStatisticsRequest = createAction(
  "[Statistics] GET_EXTERNAL_BANK_TIPS_REQUEST",
  props<{ params: ExternalBankTipsStatisticsParams }>()
);

export const getExternalBankTipsStatisticsSuccess = createAction(
  "[Statistics] GET_EXTERNAL_BANK_TIPS_SUCCESS",
  props<{ statistics: ExternalBankTipsStatisticsResponse }>()
);

export const getExternalBankTipsStatisticsFailure = createAction(
  "[statistics] get_external_bank_tips_failure"
);

export const getExternalBankTipsCSVRequest = createAction(
  "[Statistics] GET_EXTERNAL_BANK_TIPS_CSV_REQUEST",
  props<{ params: ExternalBankTipsStatisticsParams }>()
);

export const getExternalBankTipsCSVSuccess = createAction(
  "[Statistics] GET_EXTERNAL_BANK_TIPS_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const getExternalBankTipsCSVFailure = createAction(
  "[Statistics] GET_EXTERNAL_BANK_TIPS_CSV_FAILURE"
);
