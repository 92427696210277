import { Component, Input } from "@angular/core";

@Component({
  selector: "page-nav-bar-item-anchor",
  template: `
    <a class="nav-item" [routerLink]="link" routerLinkActive="active">
      <ng-content></ng-content>
    </a>
  `,
  styleUrls: [
    "../page-nav-bar-item/page-nav-bar-item.component.scss",
    "./page-nav-bar-item-anchor.component.scss",
  ],
})
export class PageNavBarItemAnchorComponent {
  @Input() link: any[];
}
