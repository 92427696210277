import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { Sort, SortDirection } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppState } from "@app/app.reducer";
import { info } from "@app/core/components/toast/ngrx/toast.actions";
import { CustomizeColumnsModalComponent } from "@app/shared/modules/ui-components/q-table/components/customize-columns-modal/customize-columns-modal.component";
import { QActionDropdownComponent } from "@app/shared/modules/ui-components/q-table/components/q-action-dropdown/q-action-dropdown.component";
import { Store } from "@ngrx/store";
import { TranslatePipe } from "@ngx-translate/core";
import * as _ from "lodash";
import { difference, without } from "lodash";
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from "rxjs";

type Density = "default" | "comfortable" | "compact";

@Component({
  selector: "app-q-table",
  templateUrl: "./q-table.component.html",
  styleUrls: ["./q-table.component.scss"],
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class QTableComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() key: string;
  @Input() columns: string[];
  @Input() headers: Record<string, any> = {};
  @Input() length: number;
  @Input() sortableColumns: string[] = [];
  @Input() sortActive: string;
  @Input() sortDirection: SortDirection;
  @Input() enableSelection = false;
  @Input() enableActions = true;
  @Input() enableCustomization = true;
  @Input() enablePaginator = true;
  @Input() enableSearch = true;
  @Input() enableClick = true;
  @Input() enableExpandRow = false;
  @Input() data: any[];
  @Input() loading: boolean;
  @Input() preventDatasourceRefresh = false;
  @Input() defaultPageSize = 25;
  @Input() entityIdProperty?: string;
  @Input() enableShowAll = false;

  @Output() page = new EventEmitter<PageEvent>();
  @Output() selected = new EventEmitter<any[]>();
  @Output() sort = new EventEmitter<Sort>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() search = new EventEmitter<string>();
  @Output() expandRowClick = new EventEmitter<any>();

  @ContentChildren(TemplateRef) templateList: QueryList<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChildren(QActionDropdownComponent)
  dropdowns: QueryList<QActionDropdownComponent>;

  displayedColumns;
  density: Density = "default";
  selection: SelectionModel<any>;
  dataSource: MatTableDataSource<any>;
  searchControl = new FormControl("");
  _ = _;
  expandedRow: any;
  expanding = false;
  pageSizeOptions: number[] = [10, 25, 50, 100];
  unsubscribe$ = new Subject<void>();

  constructor(
    public dialog: MatDialog,
    private translate: TranslatePipe,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    const savedColumns =
      JSON.parse(localStorage.getItem(this.key + "_columns")) || [];
    const savedRemovedColumns =
      JSON.parse(localStorage.getItem(this.key + "_removed_columns")) || [];

    const x = without(this.columns, ...savedRemovedColumns); // TODO: find a better name
    const added = difference(x, savedColumns);
    const removed = difference(savedColumns, x);
    const columns = without([...savedColumns, ...added], ...removed);

    this.displayedColumns = ["expand", "select", ...columns, "actions"];

    const savedDensity = localStorage.getItem("table_density") as Density;
    if (savedDensity) {
      this.density = savedDensity;
    }

    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<any>(
      allowMultiSelect,
      initialSelection
    );

    this.dataSource = new MatTableDataSource<any>(this.data);

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        debounceTime(400),
        distinctUntilChanged()
      )
      .subscribe((value) => {
        this.search.emit(value);
        if (this.enablePaginator) {
          this.paginator.firstPage();
        }
      });
  }

  ngOnDestroy() {
    if (this.entityIdProperty && this.enableExpandRow) {
      if (this.expandedRow) {
        localStorage.setItem(
          this.key + "_expanded_row",
          JSON.stringify(this.expandedRow[this.entityIdProperty])
        );
      } else {
        localStorage.removeItem(this.key + "_expanded_row");
      }
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data?.currentValue) {
      if (this.dataSource) {
        if (!!this.entityIdProperty && !!changes?.data?.previousValue) {
          const previousIds = changes.data?.previousValue.map(
            (item) => item[this.entityIdProperty]
          );
          const newIds = changes.data?.currentValue.map(
            (item) => item[this.entityIdProperty]
          );
          if (!_.isEqual(previousIds, newIds)) {
            this.selection.clear();
            this.selected.emit([]);
          }
        } else {
          this.selection.clear();
          this.selected.emit([]);
        }
        if (this.enablePaginator) {
          const { pageIndex, pageSize } = this.paginator;
          const { startIndex, endIndex } = this.getPageSlice(
            pageIndex,
            pageSize
          );
          this.dataSource.data = changes.data.currentValue.slice(
            startIndex,
            endIndex
          );
        } else {
          if (!this.preventDatasourceRefresh) {
            this.dataSource.data = changes.data.currentValue;
          }
        }
      }

      if (!!this.expandedRow) {
        const newExpandedRow = this.data.find((d) => {
          if (this.entityIdProperty) {
            return (
              d[this.entityIdProperty] ===
              this.expandedRow[this.entityIdProperty]
            );
          } else {
            // This logic was added to prevent expanded row to contract when data input is updated.
            // Logic is not perfect, but good enough in 99%(this is made up, but you probably see my point) of cases
            const keyComparer = Object.keys(d).find(
              (dkey) => dkey.includes("id") || dkey.includes("Id")
            );
            return d[keyComparer] === this.expandedRow[keyComparer];
          }
        });
        this.expandedRow = newExpandedRow;
      } else if (
        this.enableExpandRow &&
        this.entityIdProperty &&
        !this.expandedRow
      ) {
        const expandedRowIdFromLocal =
          JSON.parse(localStorage.getItem(this.key + "_expanded_row")) || null;
        this.expandedRow = this.data.find(
          (d) => d[this.entityIdProperty] === expandedRowIdFromLocal
        );
      }
    }
  }

  ngAfterViewInit(): void {
    // translations
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel =
        this.translate.transform("items_per_page");
      this.paginator._intl.firstPageLabel =
        this.translate.transform("first_page");
      this.paginator._intl.lastPageLabel =
        this.translate.transform("last_page");
      this.paginator._intl.previousPageLabel =
        this.translate.transform("previous_page");
      this.paginator._intl.nextPageLabel =
        this.translate.transform("next_page");
      this.paginator._intl.getRangeLabel = this.getRangeLabel.bind(this);
    }
  }

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return this.translate.transform("page_range", {
        page: 1,
        amountOfPages: 1,
      });
    }
    const amountOfPages = Math.ceil(length / pageSize);
    return this.translate.transform("page_range", {
      page: page + 1,
      amountOfPages,
    });
  }

  customizeColumns() {
    const dialogRef = this.dialog.open(CustomizeColumnsModalComponent, {
      data: {
        original: this.columns,
        displayed: this.displayedColumns,
        headers: this.headers,
      },
      backdropClass: "q-modal-overlay",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.event === "save") {
        const { displayedColumns, removedColumns } = result.data;

        this.displayedColumns = [
          "expand",
          "select",
          ...displayedColumns,
          "actions",
        ];
        localStorage.setItem(
          this.key + "_columns",
          JSON.stringify(displayedColumns)
        );
        localStorage.setItem(
          this.key + "_removed_columns",
          JSON.stringify(removedColumns)
        );
      }
    });
  }

  onPageChange(e: PageEvent) {
    const { previousPageIndex, pageIndex, pageSize } = e;
    this.selection.clear();

    // page size change
    if (this.defaultPageSize !== pageSize) {
      this.defaultPageSize = pageSize;

      this.page.emit(e);
    }

    const { startIndex, endIndex } = this.getPageSlice(pageIndex, pageSize);

    if (previousPageIndex < pageIndex && endIndex > this.data.length) {
      this.page.emit(e);
    } else {
      this.dataSource.data = this.data.slice(startIndex, endIndex);
    }
  }

  isAllSelected(all = false) {
    const numSelected = this.selection?.selected?.length;
    const numRows = !!all ? this.length : this.dataSource?.data?.length;
    return numSelected >= numRows;
  }

  masterToggle(all = false) {
    const isAllSelected = this.isAllSelected(all);
    if (isAllSelected) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row));
      if (all) {
        const tempArray = Array.from(
          Array(this.length - this.dataSource?.data?.length).keys()
        );
        tempArray.forEach((it) => this.selection.select(it));
      }
    }
    this.selected.emit(this.selection.selected);
  }

  toggle(element: any) {
    this.selection.toggle(element);
    // Todo: This is a suboptimal workaround for mass selection
    if (
      this.selection.selected.length > this.defaultPageSize &&
      this.selection.selected.length < this.length
    ) {
      this.selection.clear();
      this.store.dispatch(
        info({ message: "qtable_mass_selection_info_message" })
      );
    }
    this.selected.emit(this.selection.selected);
  }

  isSortable(column: string) {
    return !this.sortableColumns.includes(column);
  }

  onSortChange(e: Sort) {
    this.sort.emit(e);
    if (this.enablePaginator) {
      this.paginator.firstPage();
    }
  }

  getRowHeight() {
    switch (this.density) {
      case "default":
        return "56px";
      case "comfortable":
        return "48px";
      case "compact":
        return "40px";
    }
  }

  changeDensity(density: Density) {
    this.density = density;
    localStorage.setItem("table_density", density);
  }

  getHeader(column: string) {
    if (this.headers[column]?.label) {
      return this.translate.transform(this.headers[column].label, {
        ...this.headers[column].args,
      });
    } else if (this.headers[column]) {
      return this.translate.transform(this.headers[column]);
    } else {
      return this.translate.transform(column);
    }
  }

  getPageSlice(pageIndex: number, pageSize: number) {
    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;
    return { startIndex, endIndex };
  }

  clearSelection() {
    this.selection.clear();
    this.selected.emit(this.selection.selected);
  }

  expandRow(row) {
    this.loading = true;
    this.expanding = true;
    this.expandedRow = this.expandedRow === row ? null : row;
    setTimeout(() => (this.loading = false), 800);
  }

  handleSearchBox(element) {
    if (!!this.searchControl.value) {
      this.searchControl.setValue("");
    }

    element.focus();
  }

  handleDiv(element) {
    element.focus();
  }
}
