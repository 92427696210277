import { milesReducer, MilesState } from "@app/miles/ngrx/miles.reducer";
import { combineReducers } from "@ngrx/store";

export interface MilesModuleState {
  overview: MilesState;
}

const reducers = {
  overview: milesReducer,
};

export function milesModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
