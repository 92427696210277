<div
  *ngIf="enabled"
  [class.pseudo-spinner-large]="loading"
  [class.new-style]="newStyle"
  [style.position]="'relative'"
>
  <label *ngIf="placeholder">{{ placeholder }}</label>
  <input
    #suggester
    [(ngModel)]="selected"
    [autofocus]="autoFocus"
    [typeahead]="dataSource || []"
    [typeaheadMinLength]="2"
    [typeaheadWaitMs]="200"
    [typeaheadOptionField]="getOptionField()"
    [typeaheadItemTemplate]="customItemTemplate"
    [typeaheadOrderBy]="sortConfig"
    [typeaheadScrollable]="true"
    (typeaheadLoading)="handleLoadingChange($event)"
    (typeaheadOnSelect)="handleSelect($event)"
    (blur)="onBlur()"
  />
  <span
    *ngIf="!!selected"
    class="clear-button"
    [class.with-label]="placeholder"
    (click)="handleSelect({ value: null })"
  >
    <app-icon name="times" size="small"></app-icon>
  </span>
</div>

<ng-template #customItemTemplate let-model="item" let-index="index">
  <h5 *ngIf="type !== 'zip' && !getCompleteObject">{{ model }}</h5>
  <h5 *ngIf="type === 'zip'">{{ model.zip }} {{ model.city }}</h5>
  <h5 *ngIf="type === 'street' && getCompleteObject">
    {{ model.fullAddress }}
  </h5>
</ng-template>

<input
  #input
  *ngIf="!enabled"
  [(ngModel)]="selected"
  placeholder="{{ placeholder }}"
  type="text"
/>
