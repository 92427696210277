import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.actions";
import { TaskResult } from "@app/models";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface LeadResultOptionsFormState {
  data: TaskResult;
  isLoading: boolean;
}

const initialState: LeadResultOptionsFormState = {
  data: null,
  isLoading: false,
};

export const leadResultOptionsFormReducer = createReducer(
  initialState,
  on(actions.getLeadResultOptionRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadResultOptionSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: true,
  })),
  on(actions.getLeadResultOptionFail, (state) => ({
    ...state,
    isLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
export const selectLeadResultOptionsForm = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.leadResultOptionsForm
);

export const selectData = createSelector(
  selectLeadResultOptionsForm,
  (state: LeadResultOptionsFormState) => state.data
);
export const selectIsLoading = createSelector(
  selectLeadResultOptionsForm,
  (state: LeadResultOptionsFormState) => state.isLoading
);
