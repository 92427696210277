import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Office } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import * as fromKpi from "../../ngrx/kpi.reducer";
import { Kpi } from "../../models/kpi";
import { KpiUtilsService } from "../../services/kpi-utils.service";
import * as KPI_MODAL_TYPES from "../../utils/kpi-modal-types";

@Component({
  selector: "kpi-office",
  templateUrl: "./kpi-office.component.html",
  styleUrls: ["../kpi-modal.component.scss", "./kpi-office.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiOfficeComponent implements OnInit, OnDestroy {
  type$: Observable<string>;
  from$: Observable<string>;
  employees$: Observable<Kpi[]>;
  summary$: Observable<Kpi>;
  eaOfficeId$: Observable<string>;
  role = ROLE_ADMIN;
  type: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private kpiUtils: KpiUtilsService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.setTitle();
    this.store
      .pipe(fromKpi.getKpiDetailsByType(this.type))
      .subscribe((details) => {
        // @ts-ignore
        this.route.data.next({ kpi: details });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.type$ = this.route.params.pipe(map((params) => params?.type));
    this.from$ = this.route.parent.queryParams.pipe(
      map((params) => params?.from)
    );
    this.employees$ = this.route.data.pipe(map((params) => params?.kpi));
    this.summary$ = this.store.pipe(select(fromKpi.getSummary));
    this.eaOfficeId$ = this.route.params.pipe(
      map((params) => params?.eaOfficeId)
    );
  }

  setTitle(): void {
    combineLatest([this.type$, this.from$, this.eaOfficeId$])
      .pipe(
        map((params: any) => ({
          type: params[0],
          month: params[1],
          eaOfficeId: params[2],
        })),
        takeUntil(this.unsubscribe$),
        switchMap((params: any) => {
          this.type = params.type;

          if (params.eaOfficeId.includes(",")) {
            return of({
              type: params.type,
              month: params.month,
              name: "",
            });
          }

          return this.store.pipe(
            select(fromShared.getOffice(params.eaOfficeId)),
            map((office: Office) => {
              return {
                type: params.type,
                month: params.month,
                name: office.name,
              };
            })
          );
        })
      )
      .subscribe((params) => {
        this.kpiUtils.setModalTitle({
          type: params.type,
          name: params.name,
          modalType: KPI_MODAL_TYPES.OFFICE,
          month: params.month,
        });
      });
  }

  getClassNameByProgress(progress: number): string {
    return this.kpiUtils.getClassNameByProgress(progress);
  }
}
