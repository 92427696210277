<span class="text-link" (click)="toggleProgressWidget()">
  <div
    class="progress-bar-border-wrapper"
    *ngIf="(taskSetsInProgress$ | async).length > 0; else withoutProgressBar"
  >
    <app-progress-circle
      *ngIf="taskSetsInProgress$ | async as taskSetsInProgress"
      [maxValue]="taskSetsInProgress[0].tasks?.length"
      [currentValue]="taskSetsInProgress[0].completed"
      [radius]="26"
      [stroke]="5"
      [strokeColor]="
        getStrokeColor(
          taskSetsInProgress[0].completed,
          taskSetsInProgress[0].tasks.length
        )
      "
    >
      <span
        class="progress-button"
        [tooltip]="'progress_button_tooltip' | translate"
      >
        <i class="fa fa-tasks processing"></i>
      </span>
    </app-progress-circle>
  </div>
  <ng-template #withoutProgressBar>
    <span
      [tooltip]="'progress_button_tooltip' | translate"
      class="progress-button progress-button__no-border"
      *ngIf="(completedTaskSets$ | async).length > 0"
    >
      <i class="fa fa-tasks"></i>
    </span>
  </ng-template>
</span>
<div *ngIf="show$ | async" [@fadeInOut] class="progress-widget">
  <p [style.color]="'orange'" class="progress-widget-label">
    <app-icon name="exclamation-circle"></app-icon>
    {{ "progress_widget_label" | translate }}
  </p>
  <i (click)="toggleProgressWidget()" class="close-btn fa fa-close"></i>
  <ng-container *ngIf="taskSetsInProgress$ | async as taskSets">
    <app-progress-widget-item
      *ngFor="let taskSet of taskSets"
      [label]="taskSet?.label"
      [max]="taskSet?.tasks?.length"
      [value]="taskSet?.completed"
      [failed]="taskSet?.failed"
    >
    </app-progress-widget-item>
  </ng-container>
  <ng-container *ngIf="completedTaskSets$ | async as taskSets">
    <div
      class="progress-widget--completed"
      [tooltip]="
        getCompletedTooltip(taskSet)
          | translate
            : { failed: taskSet?.failed, total: taskSet?.tasks?.length }
      "
      *ngFor="let taskSet of taskSets"
    >
      <span [style.color]="getCompletedIconColor(taskSet)">
        <app-icon [name]="getCompletedIconName(taskSet)"></app-icon>
      </span>
      <span>
        ({{ taskSet.completed }}/{{ taskSet.tasks.length }})
        {{ taskSet.label | translate }}</span
      >
    </div>
  </ng-container>
</div>
