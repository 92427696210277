import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { danger } from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO, Office, PaginationListDTO } from "@app/models";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  combineLatest as observableCombineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  throwError as observableThrowError,
} from "rxjs";
import { getOffices } from "../../../ngrx/shared.reducer";
import { getEaEmployeeId, getEmployeeOffices } from "../../../user";
import { ROLE_BROKER } from "../../../utils/roles";

@Injectable()
export class OfficeEmployeeDropdownService {
  private _offices$ = new BehaviorSubject<Office[]>([]);

  private _employees$ = new BehaviorSubject<EmployeeDTO[]>([]);

  private _employeesLoading$ = new Subject<boolean>();

  get offices$() {
    return this._offices$.asObservable();
  }

  get employees$() {
    return this._employees$.asObservable();
  }

  get employeesLoading$(): Observable<boolean> {
    return this._employeesLoading$.asObservable();
  }

  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  getOfficeByEaOfficeId(eaOfficeId: string): Office {
    return this._offices$
      .getValue()
      .find((office) => office.eaOfficeId === eaOfficeId);
  }

  fetchOffices(): void {
    this.store
      .pipe(
        select(getOffices),
        filter((offices: Office[]) => offices.length > 0),
        first()
      )
      .subscribe((offices) => this._offices$.next(offices));
  }

  // TODO once proxy employees/legacy is removed change so it uses name instead of officeName
  fetchOfficesConnectedToEmployee(): void {
    this.store
      .pipe(
        select(getEmployeeOffices),
        first(),
        map((offices) => offices?.map((o) => ({ ...o, name: o.officeName })))
      )
      .subscribe((offices) => this._offices$.next(offices));
  }

  fetchEmployeesForOfficeByRole(eaOfficeId: string, role: string): void {
    this._employeesLoading$.next(true);
    observableCombineLatest([
      this.store.pipe(select(getEaEmployeeId)),
      this.apiService
        .get("employees", this.employeesForOfficeParams(eaOfficeId))
        .pipe(
          map((response: PaginationListDTO) => response.rows),
          catchError((error: any) => observableThrowError(() => error.json()))
        ),
    ])
      .pipe(
        map(([eaEmployeeId, employees]) =>
          role === ROLE_BROKER
            ? [employees.find((e) => e.eaEmployeeId === eaEmployeeId)]
            : employees
        ),
        first()
      )
      .subscribe({
        next: (employees) => this._employees$.next(employees),
        error: (message) => this.store.dispatch(danger({ message })),
        complete: () => this._employeesLoading$.next(false),
      });
  }

  fetchEmployeesForOffice(officeId: string): void {
    this._employeesLoading$.next(true);
    this.apiService
      .get("employees", this.employeesForOfficeParams(officeId))
      .pipe(
        map((response: PaginationListDTO) => response.rows),
        catchError((error: any) => observableThrowError(() => error.json()))
      )
      .subscribe({
        next: (employees) => this._employees$.next(employees),
        error: (message) => this.store.dispatch(danger({ message })),
        complete: () => this._employeesLoading$.next(false),
      });
  }

  private employeesForOfficeParams(eaOfficeId: string): any {
    return {
      limit: 2000,
      eaOfficeId,
    };
  }
}
