import { CompetitionsConfig } from "@app/core/services/feature-config-manager/models/competitions-config";

export const buildCompetitionConfiguration = (
  customerName: string
): CompetitionsConfig => {
  let filterOnOffice = true;

  if (
    customerName.toLowerCase() === "aktiv eiendomsmegling" ||
    customerName.toLowerCase() === "inhouse"
  ) {
    filterOnOffice = false;
  }

  return {
    filterOnOffice,
  };
};
