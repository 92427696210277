import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import { distinctUntilChanged, filter, Subject, takeUntil, tap } from "rxjs";
import { getRoles } from "../../../user";

@Directive({
  selector: "[ifRole]",
})
export class IfRoleDirective implements OnInit, OnDestroy {
  roles: string[];
  unsubscribe$ = new Subject<void>();

  @Input()
  set ifRole(value: string | string[]) {
    this.roles = typeof value === "string" ? [value] : value;
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(getRoles),
        filter((roles) => !!roles),
        distinctUntilChanged(_.isEqual),
        tap(() => this.viewContainer.clear()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((currentRoles) => {
        const allowed = this.roles.some(
          (role) => currentRoles.indexOf(role) !== -1
        );
        if (allowed) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
