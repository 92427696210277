import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SearchProfile } from "@app/contacts/contact-search-profile/models/search-profile";
import * as contactSearchProfileActions from "@app/contacts/contact-search-profile/ngrx/contact-search-profile.actions";
import { clearSelectedSearchProfile } from "@app/contacts/contact-search-profile/ngrx/contact-search-profile.actions";
import * as fromContactSearchProfile from "@app/contacts/contact-search-profile/ngrx/contact-search-profile.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as routerActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Employee, TypedPaginationListDTO } from "@app/models";
import { CategoryType } from "@app/models/category-type";
import { SIDEBAR_SEARCH_PROFILE_ALERT_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import {
  SEARCH_PROFILE_ALERT_CREATE,
  SEARCH_PROFILE_CREATE,
  SEARCH_PROFILE_EDIT,
} from "@app/shared/utils/tab-types";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import * as searchProfileActions from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.actions";
import { AreaSearchResults } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { catchError, filter, first, map, mergeMap, of, switchMap } from "rxjs";

@Injectable()
export class SearchProfileSidebarEffects {
  getAreaSearchResultRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAreaSearchResultsRequest),
      switchMap(({ type, ...data }) =>
        this.apiService
          .get("search", {
            groups:
              "municipalitys,countys,customfilters,foreigngeodataprovince,foreigngeodatacity,foreigngeodatacityarea",
            listNumber: 30,
            ...data,
          })
          .pipe(
            map((response: any) => {
              const {
                municipalities,
                counties,
                customFilters,
                zips,
                foreignGeoDataCity,
                foreignGeoDataCityArea,
                foreignGeoDataProvince,
              } = response?.matches;

              const areas: AreaSearchResults = {
                municipalities: municipalities?.entities ?? null,
                counties: counties?.entities ?? null,
                customFilters: customFilters?.entities ?? null,
                zips: zips?.entities ?? null,
                foreignGeoDataCity: foreignGeoDataCity?.entities ?? null,
                foreignGeoDataCityArea:
                  foreignGeoDataCityArea?.entities ?? null,
                foreignGeoDataProvince:
                  foreignGeoDataProvince?.entities ?? null,
              };

              return areas;
            }),
            map((areas: AreaSearchResults) =>
              searchProfileActions.getAreaSearchResultsSuccess({ areas })
            ),
            catchError(() =>
              of(searchProfileActions.getAreaSearchResultsFail())
            )
          )
      )
    )
  );

  createSearchProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createSearchProfileRequest),
      switchMap(({ searchProfile, openManageAlertSidebar }) =>
        this.apiService.post("search-profiles", searchProfile, "api").pipe(
          map(
            (
              response: SearchProfile //response only contains searchProfileId
            ) =>
              searchProfileActions.createSearchProfileSuccess({
                openManageAlertSidebar,
                searchProfileId: response.searchProfileId,
              })
          ),
          catchError(() => of(searchProfileActions.createSearchProfileFail()))
        )
      )
    )
  );

  createSearchProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createSearchProfileSuccess),
      mergeMap(({ openManageAlertSidebar, searchProfileId }) => {
        const actions = [
          toastActions.success({ message: "search_profile_create_success" }),
          contactSearchProfileActions.getSearchProfileRequest({
            searchProfileId,
          }),
        ];
        if (openManageAlertSidebar) {
          actions.push(
            routerActions.go({
              path: [
                "/crm",
                {
                  outlets: {
                    sidebar:
                      SIDEBAR_SEARCH_PROFILE_ALERT_CREATE_URL(searchProfileId),
                  },
                },
              ],
            })
          );
        } else {
          actions.push(
            sidebarActions.closeTab({ tabType: SEARCH_PROFILE_CREATE })
          );
        }
        return actions;
      })
    )
  );

  createSearchProfileFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createSearchProfileFail),
      map(() =>
        toastActions.danger({ message: "search_profile_create_failed" })
      )
    )
  );

  updateSearchProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateSearchProfileRequest),
      switchMap(({ searchProfileId, params }) =>
        this.apiService
          .put(`search-profiles/${searchProfileId}`, params, "api")
          .pipe(
            map(() => searchProfileActions.updateSearchProfileSuccess()),
            catchError(() => of(searchProfileActions.updateSearchProfileFail()))
          )
      )
    )
  );

  updateSearchProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateSearchProfileSuccess),
      mergeMap(() => [
        sidebarActions.closeTab({ tabType: SEARCH_PROFILE_EDIT }),
        toastActions.success({ message: "search_profile_update_success" }),
      ])
    )
  );

  updateSearchProfileFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateSearchProfileFail),
      map(() =>
        toastActions.danger({ message: "search_profile_update_failed" })
      )
    )
  );

  getSearchProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getSearchProfileRequest),
      switchMap(({ searchProfileId }) =>
        this.apiService
          .get(`search-profiles/${searchProfileId}`, {}, "api")
          .pipe(
            map((searchProfile: SearchProfile) =>
              searchProfileActions.getSearchProfileSuccess({ searchProfile })
            ),
            catchError(() => of(searchProfileActions.getSearchProfileFail()))
          )
      )
    )
  );

  deleteSearchProfileRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteSearchProfileRequest),
      switchMap(({ searchProfileId }) =>
        this.apiService
          .delete(`search-profiles/${searchProfileId}`, {}, "api")
          .pipe(
            map(() =>
              searchProfileActions.deleteSearchProfileSuccess({
                searchProfileId,
              })
            ),
            catchError(() => of(searchProfileActions.deleteSearchProfileFail()))
          )
      )
    )
  );

  deleteSearchProfileSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteSearchProfileSuccess),
      mergeMap(({ searchProfileId }) => [
        clearSelectedSearchProfile({ searchProfileId }),
        sidebarActions.closeTab({ tabType: SEARCH_PROFILE_EDIT }),
        toastActions.success({ message: "search_profile_delete_success" }),
      ])
    )
  );

  deleteSearchProfileFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteSearchProfileFail),
      mergeMap(() => [
        toastActions.danger({ message: "search_profile_delete_fail" }),
      ])
    )
  );

  getAdditionalFiltersRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAdditionalFiltersRequest),
      switchMap(({ type, ...params }) =>
        this.apiService.get("object-category-types/search", params).pipe(
          map((response: TypedPaginationListDTO<CategoryType>) =>
            searchProfileActions.getAdditionalFiltersSuccess({
              categoryTypes: response.rows,
            })
          ),
          catchError(() => of(searchProfileActions.getAdditionalFiltersFail()))
        )
      )
    )
  );

  getAlertActivitiesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAlertActivitiesRequest),
      switchMap(({ filterSettingsGroupId }) =>
        this.apiService.get("settings/values", { filterSettingsGroupId }).pipe(
          map(
            (response: {
              settingGroups: { settings: { settingValue: string }[] }[];
            }) => {
              const array = response.settingGroups[0].settings.map((setting) =>
                JSON.parse(setting.settingValue)
              );
              return searchProfileActions.getAlertActivitiesSuccess({
                activities: array,
              });
            }
          ),
          catchError(() => of(searchProfileActions.getAlertActivitiesFail()))
        )
      )
    )
  );

  getAlertActivitiesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAlertActivitiesSuccess),
      mergeMap(() => [
        sidebarActions.closeTab({ tabType: SEARCH_PROFILE_CREATE }),
      ])
    )
  );

  getAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAlertRequest),
      switchMap(({ searchProfileId }) =>
        this.apiService.get("alerts/search", { searchProfileId }).pipe(
          map((response: TypedPaginationListDTO<Alert>) =>
            searchProfileActions.getAlertSuccess({ alert: response.rows[0] })
          ),
          catchError(() => of(searchProfileActions.getAlertFail()))
        )
      )
    )
  );

  createAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createAlertRequest),
      switchMap(({ alert, mail }) =>
        this.apiService.post("alerts", alert).pipe(
          map(() => searchProfileActions.createAlertSuccess({ mail })),
          catchError(() => of(searchProfileActions.createAlertFail()))
        )
      )
    )
  );

  createAlertSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createAlertSuccess),
      mergeMap(({ mail }) => {
        const actions = [
          sidebarActions.closeTab({ tabType: SEARCH_PROFILE_ALERT_CREATE }),
          toastActions.success({
            message: "search_profile_alert_create_success",
          }),
        ];
        if (mail) {
          actions.push(
            searchProfileActions.sendConfirmationMailRequest({ mail })
          );
        }
        return actions;
      })
    )
  );

  sendConfirmationMailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.sendConfirmationMailRequest),
      switchMap(({ mail }) =>
        this.apiService.post("messages/email", mail).pipe(
          map(() => searchProfileActions.sendConfirmationMailSuccess()),
          catchError(() => of(searchProfileActions.sendConfirmationMailFail()))
        )
      )
    )
  );

  createAlertFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.createAlertFail),
      mergeMap(() => [
        toastActions.danger({ message: "search_profile_alert_create_failed" }),
      ])
    )
  );

  updateAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateAlertRequest),
      switchMap(({ params, alertId, mail }) =>
        this.apiService.patch(`alerts/${alertId}`, params).pipe(
          map(() => searchProfileActions.updateAlertSuccess({ mail })),
          catchError(() => of(searchProfileActions.updateAlertFail()))
        )
      )
    )
  );

  updateAlertSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateAlertSuccess),
      mergeMap(({ mail }) => {
        const actions = [
          sidebarActions.closeTab({ tabType: SEARCH_PROFILE_ALERT_CREATE }),
          toastActions.success({
            message: "search_profile_alert_update_success",
          }),
        ];
        if (mail) {
          actions.push(
            searchProfileActions.sendConfirmationMailRequest({ mail })
          );
        }
        return actions;
      })
    )
  );

  updateAlertFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateAlertFail),
      mergeMap(() => [
        toastActions.danger({ message: "search_profile_alert_update_failed" }),
      ])
    )
  );

  deleteAlertRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteAlertRequest),
      switchMap(({ alertId }) =>
        this.apiService.delete(`alerts/${alertId}`, {}).pipe(
          map(() => searchProfileActions.deleteAlertSuccess()),
          catchError(() => of(searchProfileActions.deleteAlertFail()))
        )
      )
    )
  );

  deleteAlertSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteAlertSuccess),
      mergeMap(() => [
        sidebarActions.closeTab({ tabType: SEARCH_PROFILE_ALERT_CREATE }),
        toastActions.success({
          message: "search_profile_alert_delete_success",
        }),
      ])
    )
  );

  deleteAlertFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.deleteAlertFail),
      mergeMap(() => [
        toastActions.danger({ message: "search_profile_alert_delete_fail" }),
      ])
    )
  );

  getAlertEmployeeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.getAlertEmployeeRequest),
      switchMap(({ id }) =>
        this.apiService.get(`employees/${id}`).pipe(
          map((employee: Employee) =>
            searchProfileActions.getAlertEmployeeSuccess({ employee })
          ),
          catchError(() => of(searchProfileActions.getAlertEmployeeFail()))
        )
      )
    )
  );

  redoGetSearchProfilesRequestAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchProfileActions.updateSearchProfileSuccess,
        searchProfileActions.createSearchProfileSuccess,
        searchProfileActions.deleteSearchProfileSuccess
      ),
      switchMap(() =>
        this.store.pipe(
          select(fromContactSearchProfile.getLatestGetSearchProfilesAction),
          filter((action) => !!action),
          first()
        )
      )
    )
  );

  redoGetSearchProfileRequestAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchProfileActions.updateSearchProfileSuccess),
      switchMap(() =>
        this.store.pipe(
          select(fromContactSearchProfile.getLatestGetSearchProfileAction),
          filter((action) => !!action),
          first()
        )
      )
    )
  );

  redoGetAlertsRequestAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        searchProfileActions.createAlertSuccess,
        searchProfileActions.deleteAlertSuccess
      ),
      switchMap(() =>
        this.store.pipe(
          select(fromContactSearchProfile.getLatestGetAlertsAction),
          filter((action) => !!action),
          first()
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}
}
