<div class="q-tab-bar">
  <div class="q-tab-bar__container">
    <button
      class="q-tab-bar__item"
      *ngFor="let tab of tabs"
      [id]="tab.name"
      [class.active]="tab.name === activeTab"
      (click)="onClick(tab)"
    >
      {{ tab.name | translate }}
    </button>
  </div>
</div>
