import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, Subject, takeUntil } from "rxjs";

import { AppState } from "@app/app.reducer";
import { AssignLeadViewsService } from "@app/core/components/assign-lead-modal/containers/assign-lead-modal/assign-lead-views.service";
import { fetchTaskTypesRequest } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import { Office, TaskType } from "@app/models";
import _ from "lodash";

@Component({
  selector: "app-assign-lead-filters",
  templateUrl: "./assign-lead-filters.component.html",
  styleUrls: ["./assign-lead-filters.component.scss"],
})
export class AssignLeadFiltersComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaOfficeId: string;
  @Input() isMobile: boolean;
  @Input() taskTypes: TaskType[] = [];
  @Input() offices: Office[];
  @Output() handleSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output() formChange: EventEmitter<any> = new EventEmitter<any>();

  form: FormGroup;
  selectedCustomFilter: "custom" | "needs_leads" | "round_robin" = "custom";
  unsubscribe$ = new Subject<void>();
  updateText: "update" | "create" | null;
  showFilters = true;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    public listService: AssignLeadViewsService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    if (!this.taskTypes || this.taskTypes.length === 0) {
      this.store.dispatch(fetchTaskTypesRequest());
    }

    this.handleFormChange();
    this.listService.selectedView
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((view) => {
        if (view?.params?.sortBy) {
          this.form
            .get("sortBy")
            .setValue(view.params.sortBy, { emitEvent: false });
        }
        if (view?.params?.sortOrder) {
          this.form
            .get("sortOrder")
            .setValue(view.params.sortOrder, { emitEvent: false });
        }
        this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
      });
  }

  ngOnChanges(changes) {
    if (changes.taskTypes) {
      this.fillForm();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleFormChange(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(_.isEqual))
      .subscribe((value) => {
        this.updateText =
          this.selectedCustomFilter === "custom" ? "create" : "update";
        this.formChange.emit(value);
      });
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOfficeIds: "",
      from: "6--months",
      eaTaskTypeIds: "",
      sortBy: "numberOfResolvedTasks",
      sortOrder: "desc",
      salesResource: false,
      limitBasedOnPropertyType: false,
      budget: this.fb.group({
        reachedBudget: false,
        notReachedBudget: false,
      }),
    });
  }

  private fillForm() {
    const taskTypeIds = this.taskTypes
      .map((type) => type.eaTaskTypeId)
      .toString();
    this.form.patchValue({
      eaOfficeIds: this.eaOfficeId,
      eaTaskTypeIds: taskTypeIds,
    });
  }
}
