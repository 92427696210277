import { Pipe, PipeTransform } from "@angular/core";
import { LastContact } from "@app/showings/models";

@Pipe({
  name: "contactedStatusFromLastContacts",
})
export class ContactedStatusFromLastContactsPipe implements PipeTransform {
  transform(value: LastContact[]): unknown {
    if (!value || value?.length === 0) {
      return null;
    } else if (value[0]?.param1 === "noanswer") {
      return false;
    } else if (value[0]?.param1 === "answer") {
      return true;
    } else {
      return null;
    }
  }
}
