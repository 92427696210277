import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-contacted-status",
  templateUrl: "./contacted-status.component.html",
  styleUrls: ["./contacted-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactedStatusComponent {
  @Input() status: boolean | null = null;
  @Output() statusChange = new EventEmitter<boolean>();

  handleClick(status: boolean): void {
    this.statusChange.emit(status);
  }

  isSelected(status: boolean): boolean {
    return this.status === status;
  }
}
