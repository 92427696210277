import { Employee, Task } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as tasksAction from "./tasks.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface TaskActionLog {
  officeName: string;
  firstName: string;
  actionType: string;
  employeeFullName: string;
  eaEmployeeId: string;
  familyName: string;
  eaOfficeId: string;
  actionCode: string;
  timestamp: string;
  param2: string;
  param3: string;
  taskTransfer?: {
    transferDate: string;
    transferComment?: string;
    transferBy?: {
      eaEmployeeId: string;
      employeeFullName: string;
      eaOfficeId: string;
      officeName: string;
    };
    transferTo?: {
      eaEmployeeId: string;
      employeeFullName: string;
      eaOfficeId: string;
      officeName: string;
    };
    transferFrom?: {
      eaEmployeeId: string;
      employeeFullName: string;
      eaOfficeId: string;
      officeName: string;
    };
  };
}

export interface TasksState {
  task: Task;
  taskActionLogs: TaskActionLog[];
  taskLoading: boolean;
  employees: Employee[];
  employeesLoading: boolean;
  processing: boolean;
}

export const initialState: TasksState = {
  task: null,
  taskActionLogs: [],
  taskLoading: false,
  employees: [],
  employeesLoading: false,
  processing: false,
};

export function tasksReducer(state: TasksState, action: Action): TasksState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(tasksAction.getTaskRequest, (state) => ({ ...state, taskLoading: true })),
  on(tasksAction.getTaskSuccess, (state, { task }) => ({
    ...state,
    task,
    taskLoading: false,
  })),
  on(tasksAction.getTaskFailed, (state) => ({
    ...state,
    task: initialState.task,
    taskLoading: initialState.taskLoading,
  })),
  on(tasksAction.getTaskActionLogRequest, (state) => ({
    ...state,
    taskLoading: true,
  })),
  on(tasksAction.getTaskActionLogSuccess, (state, { taskActionLogs }) => ({
    ...state,
    taskActionLogs,
    taskLoading: false,
  })),
  on(tasksAction.getTaskActionLogFailed, (state) => ({
    ...state,
    taskActionLogs: initialState.taskActionLogs,
    taskLoading: initialState.taskLoading,
  })),
  on(tasksAction.updateTaskRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(tasksAction.updateTaskSuccess, (state, { task }) => ({
    ...state,
    task,
    processing: false,
  })),
  on(tasksAction.updateTaskFailed, (state) => ({
    ...state,
    task: initialState.task,
    processing: initialState.processing,
  })),
  on(tasksAction.deleteTaskRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(tasksAction.deleteTaskSuccess, (state) => ({
    ...state,
    task: null,
    processing: false,
  })),
  on(tasksAction.deleteTaskFailed, (state) => ({
    ...state,
    task: initialState.task,
    processing: initialState.processing,
  })),
  on(tasksAction.getOfficeEmployeesRequest, (state) => ({
    ...state,
    employees: initialState.employees,
    employeesLoading: true,
  })),
  on(tasksAction.getOfficeEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
    employeesLoading: false,
  })),
  on(tasksAction.getOfficeEmployeesFailed, (state) => ({
    ...state,
    employees: initialState.employees,
    employeesLoading: initialState.employeesLoading,
  })),
  on(tasksAction.createTaskRequest, (state) => ({
    ...state,
    taskLoading: true,
  })),
  on(tasksAction.createTaskSuccess, (state) => ({
    ...state,
    taskLoading: false,
  })),
  on(tasksAction.createTaskFailed, (state) => ({
    ...state,
    task: initialState.task,
    taskLoading: initialState.taskLoading,
  })),
  on(tasksAction.reset, () => initialState),
  on(
    tasksAction.sendMessageRequest,
    tasksAction.sendMessageSuccess,
    tasksAction.sendMessageFailed,
    (state) => state
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.tasks
);

export const getTask = createSelector(
  getState,
  (state: TasksState) => state.task
);

export const getTaskActionLog = createSelector(
  getState,
  (state: TasksState) => state.taskActionLogs
);

export const getEmployees = createSelector(
  getState,
  (state: TasksState) => state.employees
);

export const getProcessing = createSelector(
  getState,
  (state: TasksState) => state.processing
);

export const getEmployee = (eaEmployeeId: string) =>
  createSelector(getState, (state: TasksState) =>
    state.employees.find(
      (employee: Employee) => employee.eaEmployeeId === eaEmployeeId
    )
  );
