<div class="flex-space-between page-nav-bar" *ngIf="subPages?.length > 1">
  <div class="page-nav-bar__container">
    <page-nav-bar-item-anchor
      *ngFor="let subPage of subPages"
      [link]="subPage.routerLink"
    >
      <span class="page-nav-bar__icon hidden-xs hidden-sm">
        <app-icon type="light" [name]="subPage.icon" size="medium"></app-icon>
      </span>
      <page-nav-bar-item-label [label]="subPage.label"></page-nav-bar-item-label>
    </page-nav-bar-item-anchor>
  </div>
  <div class="page-nav-bar__filters">
    <ng-content select="page-nav-bar-filters"></ng-content>
  </div>
</div>
<div class="flex-space-between page-nav-bar page-nav-bar--minor" *ngIf="subSubPages$ | async as subSubPages">
  <div class="page-nav-bar__container">
    <page-nav-bar-item-anchor
      *ngFor="let subPage of subSubPages"
      [link]="subPage.routerLink"
    >
      <page-nav-bar-item-label [label]="subPage.label"></page-nav-bar-item-label>
    </page-nav-bar-item-anchor>
  </div>
</div>
