import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  first,
  map,
  Observable,
  Subject,
  switchMap,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import { AssignLeadService } from "@app/core/components/assign-lead-modal/assign-lead.service";
import { AssignLeadViewsService } from "@app/core/components/assign-lead-modal/containers/assign-lead-modal/assign-lead-views.service";
import {
  fetchEmployeesRequest,
  toggle,
} from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import * as fromAssignLeadModal from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.reducer";
import { EmployeeService } from "@app/core/ngrx/entity-services/employee.service";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { Employee, Task, TaskType } from "@app/models";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { getOffices, isMobile } from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { getEaOfficeId, getEmployeeOffices } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { StatsLeadEmployee } from "@app/statistics/boxes/leads/utils";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-assign-lead-modal",
  templateUrl: "./assign-lead-modal.component.html",
  styleUrls: ["./assign-lead-modal.component.scss"],
})
export class AssignLeadModalComponent implements OnInit, OnDestroy {
  leadIds$: Observable<Task[]>;
  isLead$: Observable<boolean>;
  loading$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  employees$: Observable<any[]>;
  selectedEmployees$: Observable<any[]>;
  offices$: Observable<any[]>;
  eaOfficeId$: Observable<string>;
  taskTypes$: Observable<TaskType[]>;
  unsubscribe$ = new Subject<void>();
  segmentControls: SegmentControls = [
    {
      label: "grid",
      value: "grid",
    },
    {
      label: "table",
      value: "table",
    },
  ];
  selectedView = "grid";
  canSendEmail = true;
  transferComment = "";
  emailContent = "";
  selectedOffices = "";
  selectedEmployee: Employee = null;

  constructor(
    public dialogRef: MatDialogRef<AssignLeadModalComponent>,
    private store: Store<AppState>,
    public taskService: TaskService,
    private employeeService: EmployeeService,
    private assignLeadService: AssignLeadService,
    public listService: AssignLeadViewsService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.dialogRef.afterClosed().subscribe(() => this.closeModal());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.closeModal();
    this.dialogRef.close();
  }

  handleFormChanges(value): void {
    const from = moment()
      .subtract(Number(value.from.split("--")[0]), value.from.split("--")[1])
      .format(API_DATE_FORMAT);

    this.selectedOffices = value.eaOfficeIds;
    this.selectedEmployee = null;
    this.store.dispatch(
      fetchEmployeesRequest({
        parameters: {
          eaOfficeIds: value.eaOfficeIds,
          eaTaskTypeIds: value.eaTaskTypeIds,
          sortBy: value.sortBy,
          sortOrder: value.sortOrder,
          from: from,
        },
      })
    );
  }

  handleSearch(input: string): void {
    this.selectedEmployees$ = this.employees$.pipe(
      map((employees: StatsLeadEmployee[]) =>
        employees.filter((employee) =>
          employee.employeeFullName.toLowerCase().includes(input.toLowerCase())
        )
      )
    );
  }

  getPercentualColor(successTasksPercentual: number) {
    if (successTasksPercentual > 0.7) {
      return "green";
    } else if (successTasksPercentual > 0.3) {
      return "orange";
    }
    return "red";
  }

  getAverageResolvedTimeColor(averageResolvedTime: number) {
    if (averageResolvedTime > 0) {
      if (averageResolvedTime < 100000000) {
        return "green";
      } else if (averageResolvedTime < 500000000) {
        return "orange";
      }
    }
    return "red";
  }

  handleCheckboxChange(showComment: any) {
    this.canSendEmail = showComment.checked;
    if (!this.canSendEmail) {
      this.emailContent = "";
    }
  }

  handleSelectedEmployee(employee: Employee) {
    this.selectedEmployee = employee;
  }

  handleAssign() {
    const params = {
      eaOfficeId: "",
      eaEmployeeId: this.selectedEmployee.eaEmployeeId,
      transferComment: this.transferComment,
      sendMessage: this.canSendEmail,
      message: !!this.canSendEmail ? this.emailContent : "",
    };

    if (!!this.selectedOffices && !this.selectedOffices.includes(",")) {
      params.eaOfficeId = this.selectedOffices;
    }

    this.employeeService
      .getById(this.selectedEmployee.eaEmployeeId)
      .pipe(
        first(),
        switchMap((employee: Employee) => {
          return this.leadIds$.pipe(
            first(),
            withLatestFrom(this.isLead$),
            map(([tasks, isLead]) => {
              this.assignLeadService.handleAssign(
                tasks,
                isLead,
                params,
                employee,
                null
              );
            })
          );
        })
      )
      .subscribe(() => this.close());
  }

  private closeModal(): void {
    this.store.dispatch(
      toggle({ parameters: { showEmployee: false, leadIds: [] } })
    );
  }

  private mapStateToProps() {
    this.isLead$ = this.store.pipe(select(fromAssignLeadModal.getIsLead));
    this.leadIds$ = this.store.pipe(select(fromAssignLeadModal.getLeadIds));
    this.loading$ = this.store.pipe(select(fromAssignLeadModal.getLoading));
    this.employees$ = this.store.pipe(select(fromAssignLeadModal.getEmployees));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.offices$ = this.isAdmin$.pipe(
      switchMap((isAdmin) =>
        isAdmin
          ? this.store.pipe(select(getOffices))
          : this.store.pipe(select(getEmployeeOffices))
      )
    );
    this.eaOfficeId$ = this.store.pipe(select(getEaOfficeId));
    this.taskTypes$ = this.store.pipe(select(fromAssignLeadModal.getTaskTypes));
    this.selectedEmployees$ = this.employees$;
    this.isMobile$ = this.store.pipe(select(isMobile));
  }
}
