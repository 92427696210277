import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  catchError,
  exhaustMap,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as leadTemplatesActions from "@app/settings/lead-templates/ngrx/lead-templates.actions";
import { selectFilterId } from "@app/settings/lead-templates/ngrx/lead-templates.reducer";
import { LEAD_TEMPLATES } from "@app/shared/utils/tab-types";
import * as actions from "@app/sidebar/lead-templates/ngrx/lead-templates-form.actions";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { TaskTemplate } from "@app/models";

@Injectable()
export class LeadTemplatesFormEffects {
  getLeadTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getLeadTemplateRequest),
      switchMap(({ id }) =>
        this.apiService.get(`task-templates/${id}`).pipe(
          map((response: TaskTemplate) =>
            actions.getLeadTemplateSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.getLeadTemplateFail()))
        )
      )
    )
  );

  createLeadTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadTemplateRequest),
      exhaustMap(({ body }) =>
        this.apiService.post("task-templates", body).pipe(
          map((response: TaskTemplate) =>
            actions.createLeadTemplateSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.createLeadTemplateFail()))
        )
      )
    )
  );

  createLeadTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadTemplateSuccess),
      withLatestFrom(this.store$.pipe(select(selectFilterId))),
      switchMap((id) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          leadTemplatesActions.getLeadTemplatesRequest({
            params: { eaTaskTypeId: id },
          }),
          toastActions.success({ message: "create_lead_template_success" }),
        ])
      )
    )
  );

  createLeadTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          toastActions.danger({ message: "create_lead_template_fail" }),
        ])
      )
    )
  );

  editLeadTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadTemplateRequest),
      exhaustMap(({ id, body }) =>
        this.apiService.patch(`task-templates/${id}`, body).pipe(
          map((response: TaskTemplate) =>
            actions.editLeadTemplateSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.editLeadTemplateFail()))
        )
      )
    )
  );

  editLeadTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadTemplateSuccess),
      withLatestFrom(this.store$.pipe(select(selectFilterId))),
      switchMap((id) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          leadTemplatesActions.getLeadTemplatesRequest({
            params: { eaTaskTypeId: id },
          }),
          toastActions.success({ message: "edit_lead_template_success" }),
        ])
      )
    )
  );

  editLeadTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          toastActions.danger({ message: "edit_lead_template_fail" }),
        ])
      )
    )
  );

  deleteLeadTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadTemplateRequest),
      exhaustMap(({ id }) =>
        this.apiService.delete(`task-templates/${id}`).pipe(
          map(() => actions.deleteLeadTemplateSuccess()),
          catchError(() => observableOf(actions.deleteLeadTemplateFail()))
        )
      )
    )
  );

  deleteLeadTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadTemplateSuccess),
      withLatestFrom(this.store$.pipe(select(selectFilterId))),
      switchMap((id) =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          leadTemplatesActions.getLeadTemplatesRequest({
            params: { eaTaskTypeId: id },
          }),
          toastActions.success({ message: "delete_lead_template_success" }),
        ])
      )
    )
  );

  deleteLeadTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_TEMPLATES }),
          toastActions.danger({ message: "delete_lead_template_fail" }),
        ])
      )
    )
  );

  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private apiService: ApiService
  ) {}
}
