import { Component, EventEmitter, Output, ViewChild } from "@angular/core";

@Component({
  selector: "step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["../../forgot-password-form.component.scss"],
})
export class Step1Component {
  @ViewChild("form", { static: false })
  form;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSend = new EventEmitter<string>();
  email = "";

  send(): void {
    this.onSend.emit(this.email);
  }
}
