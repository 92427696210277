import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import {
  COMPETITIONS_ID,
  COMPETITIONS_SEARCH,
} from "@app/core/services/api/utils/api-endpoints";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import { Competition, Competitions } from "../models/competition.model";
import * as competitionActions from "./competitions.actions";

@Injectable()
export class CompetitionsEffects {
  getCompetition$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionActions.getCompetitionRequest),
      switchMap(({ competitionId }) =>
        this.apiService.get(COMPETITIONS_ID(competitionId)).pipe(
          map((competition: Competition) =>
            competitionActions.getCompetitionSuccess({ competition })
          ),
          catchError(() =>
            observableOf(competitionActions.getCompetitionFailed())
          )
        )
      )
    )
  );

  getCompetitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionActions.getCompetitionsRequest),
      switchMap(({ filters }) =>
        this.apiService.get(COMPETITIONS_SEARCH, filters).pipe(
          map((competitions: Competitions) =>
            competitionActions.getCompetitionsSuccess({ competitions })
          ),
          catchError(() =>
            observableOf(competitionActions.getCompetitionsFailed())
          )
        )
      )
    )
  );

  getFinishedCompetitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionActions.getFinishedCompetitionsRequest),
      switchMap(({ filters }) =>
        this.apiService.get(COMPETITIONS_SEARCH, filters).pipe(
          map((competitions: Competitions) =>
            competitionActions.getFinishedCompetitionsSuccess({ competitions })
          ),
          catchError(() =>
            observableOf(competitionActions.getFinishedCompetitionsFailed())
          )
        )
      )
    )
  );

  getUpcomingCompetitions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(competitionActions.getUpcomingCompetitionsRequest),
      switchMap(({ filters }) =>
        this.apiService.get(COMPETITIONS_SEARCH, filters).pipe(
          map((competitions: Competitions) =>
            competitionActions.getUpcomingCompetitionsSuccess({ competitions })
          ),
          catchError(() =>
            observableOf(competitionActions.getUpcomingCompetitionsFailed())
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
