import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Subject } from "rxjs";

@Component({
  selector: "app-date-period-dropdown",
  templateUrl: "./date-period-dropdown.component.html",
  styleUrls: ["./date-period-dropdown.component.scss"],
})
export class DatePeriodDropdownComponent implements OnInit, OnDestroy {
  @ViewChild(BsDropdownDirective, { static: false })
  dropdown;
  @Input() parentFormGroup: FormGroup;
  @Input() parentStartYearFormControlName: string;
  @Input() parentStartMonthFormControlName: string;
  @Input() parentStartWeekFormControlName: string;
  @Input() parentEndYearFormControlName: string;
  @Input() parentEndMonthFormControlName: string;
  @Input() parentEndWeekFormControlName: string;
  @Input() parentTypeFormControlName: string;
  @Input() minWidth: string;
  @Input() showAsModal = false;

  unsubscribe$ = new Subject<void>();
  noOfWeeks = [];
  noOfMonths = [];
  years = [];
  title = "";
  modalRef: BsModalRef;

  constructor(
    private elementRef: ElementRef,
    private modalService: BsModalService
  ) {}

  ngOnInit(): void {
    this.noOfWeeks = Array(52)
      .fill(0)
      .map((_, i) => i + 1);
    this.noOfMonths = Array(12)
      .fill(0)
      .map((_, i) => i + 1);
    this.calculateYearsDropdown();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  calculateYearsDropdown(): void {
    const start = 2013;
    const end = new Date().getFullYear();
    for (let year = end; year >= start; year--) {
      this.years.push(year);
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { animated: false });
  }

  @HostListener("document:click", ["$event"])
  hideDropdown(event) {
    if (
      !this.elementRef.nativeElement.contains(event.target) &&
      !this.showAsModal
    ) {
      this.dropdown.hide();
    }
  }
}
