import { Nps } from "@app/models/nps";
import { Survey } from "@app/models/survey";
import { Tag } from "@app/models/tags";
import { createAction, props } from "@ngrx/store";
import { Contact } from "../models";
import { ContactNpsRequest } from "./../models/nps";

export interface UpdateProfileParams {
  contactId: string;
  parameters: {
    ownsResidence: string;
  };
}

export interface CreateNoteParams {
  eaEmployeeId: string;
  eaOfficeId: string;
  contactId: string;
  visibility: string;
  type: string;
  note: string;
}

export const getContactRequest = createAction(
  "[Contact] GET_CONTACT_REQUEST",
  props<{ id: string }>()
);

export const getContactSuccess = createAction(
  "[Contact] GET_CONTACT_SUCCESS",
  props<{ contact: Contact }>()
);

export const getContactFailed = createAction("[Contact] GET_CONTACT_FAILED");

export const activateCallListMode = createAction(
  "[Contact] ACTIVATE_CALL_LIST_MODE"
);

export const deactivateCallListMode = createAction(
  "[Contact] DEACTIVATE_CALL_LIST_MODE"
);

export const getContactProfileRequest = createAction(
  "[Contact] GET_CONTACT_PROFILE_REQUEST",
  props<{ contactId: string }>()
);

export const getContactProfileSuccess = createAction(
  "[Contact] GET_CONTACT_PROFILE_SUCCESS",
  props<{ ownsResidence: string }>()
);

export const getContactProfileFailed = createAction(
  "[Contact] GET_CONTACT_PROFILE_FAILED"
);

export const updateContactProfileRequest = createAction(
  "[Contact] UPDATE_CONTACT_PROFILE_REQUEST",
  props<{ parameters: UpdateProfileParams }>()
);

export const updateContactProfileSuccess = createAction(
  "[Contact] UPDATE_CONTACT_PROFILE_SUCCESS",
  props<{ contactId: string }>()
);

export const updateContactProfileFailed = createAction(
  "[Contact] UPDATE_CONTACT_PROFILE_FAILED"
);

export const createConsumerCallActionRequest = createAction(
  "[Contact] CREATE_CONSUMER_CALL_REQUEST",
  props<{ contactId: string }>()
);

export const createConsumerCallActionSuccess = createAction(
  "[Contact] CREATE_CONSUMER_CALL_SUCCESS",
  props<{ contactId: string }>()
);

export const createConsumerCallActionFailed = createAction(
  "[Contact] CREATE_CONSUMER_CALL_FAILED"
);

export const getLatestConsumerCallActionRequest = createAction(
  "[Contact] GET_LATEST_CONSUMER_CALL_REQUEST",
  props<{ contactId: string }>()
);

export const getLatestConsumerCallActionSuccess = createAction(
  "[Contact] GET_LATEST_CONSUMER_CALL_SUCCESS",
  props<{ latestContactedDate: string }>()
);

export const getLatestConsumerCallActionFailed = createAction(
  "[Contact] GET_LATEST_CONSUMER_CALL_FAILED"
);

export const createNoteRequest = createAction(
  "[Note] CREATE_NOTE_REQUEST",
  props<{ parameters: Partial<CreateNoteParams> }>()
);

export const createNoteSuccess = createAction("[Note] CREATE_NOTE_SUCCESS");

export const createNoteFail = createAction("[Note] CREATE_NOTE_FAIL");

export const createNoteModalClosed = createAction(
  "[Note] CREATE_NOTE_MODAL_CLOSED"
);

export const getContactNpsRequest = createAction(
  "[Contact] GET_CONTACT_NPS_REQUEST",
  props<{ parameters: ContactNpsRequest }>()
);

export const getContactNpsSuccess = createAction(
  "[Contact] GET_CONTACT_NPS_SUCCESS",
  props<{ nps: Nps }>()
);

export const getContactNpsFailed = createAction(
  "[Contact] GET_CONTACT_NPS_FAILED"
);

export const getContactSurveysRequest = createAction(
  "[Contact] GET_CONTACT_SURVEYS_REQUEST",
  props<{ contactId: string }>()
);

export const getContactSurveysSuccess = createAction(
  "[Contact] GET_CONTACT_SURVEYS_SUCCESS",
  props<{ surveys: Survey[] }>()
);

export const getContactSurveysFailed = createAction(
  "[Contact] GET_CONTACT_SURVEYS_FAILED"
);

export const getContactTagSuggestionsRequest = createAction(
  "[Contact] GET_CONTACT_TAG_SUGGESTIONS_REQUEST",
  props<{ contactId: string }>()
);

export const getContactTagSuggestionsSuccess = createAction(
  "[Contact] GET_CONTACT_TAG_SUGGESTIONS_SUCCESS",
  props<{ tagSuggestions: Tag[] }>()
);

export const getContactTagSuggestionsFailed = createAction(
  "[Contact] GET_CONTACT_TAG_SUGGESTIONS_FAILED"
);

export const getTagsForContactRequest = createAction(
  "[Contact] GET_TAGS_FOR_CONTACT_REQUEST",
  props<{ contactId: string }>()
);

export const getTagsForContactSuccess = createAction(
  "[Contact] GET_TAGS_FOR_CONTACT_SUCCESS",
  props<{ contactTags: Tag[] }>()
);

export const getTagsForContactFailed = createAction(
  "[Contact] GET_TAGS_FOR_CONTACT_FAILED"
);

export const deleteTagFromContactRequest = createAction(
  "[Contact] DELETE_TAG_FROM_CONTACT_REQUEST",
  props<{
    parameters: {
      contactId: string;
      tag: Tag;
    };
  }>()
);

export const deleteTagFromContactSuccess = createAction(
  "[Contact] DELETE_TAG_FROM_CONTACT_SUCCESS"
);

export const deleteTagFromContactFailed = createAction(
  "[Contact] DELETE_TAG_FROM_CONTACT_FAILED"
);

export const storeTagToContactRequest = createAction(
  "[Contact] STORE_TAG_TO_CONTACT_REQUEST",
  props<{
    parameters: {
      contactId: string;
      tag: Tag;
    };
  }>()
);

export const storeTagToContactSuccess = createAction(
  "[Contact] STORE_TAG_TO_CONTACT_SUCCESS"
);

export const storeTagToContactFailed = createAction(
  "[Contact] STORE_TAG_TO_CONTACT_FAILED"
);

export const reset = createAction("[Contact] RESET");

export const addBackLink = createAction(
  "[Contact] ADD_BACKLINK",
  props<{ backLink: string }>()
);

export const addQuickLink = createAction(
  "[Contact] ADD_QUICKLINK",
  props<{
    parameters: {
      route: any;
      label: string;
    };
  }>()
);
