import * as forgotPasswordActions from "./forgot-password.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface ForgotPasswordState {
  step: number;
  eaEmployeeId: string;
}

export const initialState: ForgotPasswordState = {
  step: 1,
  eaEmployeeId: "",
};

export function forgotPasswordReducer(
  state: ForgotPasswordState,
  action: Action
): ForgotPasswordState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(forgotPasswordActions.updateLoginInfoSuccess, (state) => ({
    ...state,
    step: 1,
  })),
  on(forgotPasswordActions.sendEmailSuccess, (state) => ({
    ...state,
    step: 2,
  })),
  on(forgotPasswordActions.verifyEmailCodeSuccess, (state, { userId }) => ({
    ...state,
    step: 3,
    eaEmployeeId: userId,
  }))
);
