<form [formGroup]="form">
  <section>
    <h4 translate>object_info</h4>
    <div class="form-group">
      <label translate>municipality</label>
      <select formControlName="municipality">
        <option translate disabled value="">select_municipality</option>
        <option
          *ngFor="let municipality of municipalities"
          [value]="municipality.municipalityId"
        >
          {{ municipality.name }}
        </option>
      </select>
      <div ngxErrors="municipality">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <label translate>object_type</label>
      <select formControlName="objType">
        <option disabled value="" selected>
          {{ "select_residence_type" | translate }}
        </option>
        <option *ngFor="let type of objectTypes" [value]="type.objectTypeId">
          {{ type.objectTypeName }}
        </option>
      </select>
      <div ngxErrors="objType">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="!residence.zip">
      <search-address-adv
        type="zip"
        [containerFormControl]="form.get('zip')"
        [enabled]="addressSuggesterFeature.enabled"
        [provider]="addressSuggesterFeature.provider"
        [countryCode]="countryCode"
        [placeholder]="'zip' | translate"
        [showFullAddress]="false"
        (onSelected)="handleZipSelected($event)"
      ></search-address-adv>
      <div ngxErrors="zip">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
        <div translate ngxError="pattern" showWhen="touched">
          validation_integers
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="!residence.city">
      <search-address-adv
        type="city"
        [containerFormControl]="form.get('city')"
        [enabled]="addressSuggesterFeature.enabled"
        [provider]="addressSuggesterFeature.provider"
        [countryCode]="countryCode"
        [placeholder]="'city' | translate"
        [showFullAddress]="false"
      ></search-address-adv>
      <div ngxErrors="city">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
</form>
<ng-container *ngIf="this.contactDetailsRequired()">
  <contact-form
    #contactForm
    [enableSource]="false"
    [enableDuplicateValidation]="false"
    [editMode]="true"
    [contact]="contact"
    [onlyContactDetails]="true"
  >
  </contact-form>
  <div class="error" *ngIf="showContactError">
    <span translate>validation_create_contact_external</span>
  </div>
</ng-container>
<div class="sidebar-footer">
  <app-q-button buttonType="cancel" label="close" (click)="closeClicked.emit()">
  </app-q-button>
  <app-q-button
    buttonType="save"
    label="create"
    [isLoading]="patching"
    [disabled]="patching"
    (click)="submit()"
  >
  </app-q-button>
</div>
