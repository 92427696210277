import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ActionsSubject, Store } from "@ngrx/store";
import { first, Observable } from "rxjs";
import * as residencesActions from "./ngrx/residence.actions";
import { ofType } from "@ngrx/effects";

@Injectable()
export class SidebarContactResidencesResolver
  implements Resolve<Observable<any>>
{
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const { contactId } = route.params;
    this.store.dispatch(
      residencesActions.getContactResidencesRequest({ contactId })
    );

    return this.dispatcher.pipe(
      ofType(
        residencesActions.getContactResidencesSuccess,
        residencesActions.getContactResidencesFailed
      ),
      first()
    );
  }
}
