<a
  class="contact-next-step-item"
  *ngIf="!isEmpty(); else loading"
  [@fadeInOut]
  (click)="!disableClick ? onTaskClick(task) : null"
  [tooltip]="disableClick ? ('task_not_yours' | translate) : null"
  placement="top"
>
  <section>
    <span [style.color]="color">
      <app-icon [name]="icon" size="large"></app-icon>
    </span>
    <div class="contact-next-step-item__line"></div>
  </section>

  <section>
    <h5>{{ task.title }}</h5>
    <small>{{ task.eaEmployeeFullName }}</small>
    <div class="text-muted contact-next-step-item__description">
      <p>{{ task.description }}</p>
      <small *ngIf="!isOverdue()">
        {{ task.eaTaskTypeName }},
        <span>
          {{ "expiration_date" | translate : { date: task.deliveryDate } }}
        </span>
      </small>

      <small *ngIf="isOverdue()" class="overdue">
        {{ task.eaTaskTypeName }},
        <span>
          {{ "expired_date" | translate : { date: task.deliveryDate } }}</span
        >
      </small>
    </div>
  </section>
</a>

<ng-template #loading>
  <li class="list-group-item skeleton-view">
    <skeleton-circle [diameter]="2.5"></skeleton-circle>
    <div class="rows">
      <skeleton-rectangle [height]="0.75"></skeleton-rectangle>
      <skeleton-rectangle [height]="0.75"></skeleton-rectangle>
      <skeleton-rectangle [height]="0.75"></skeleton-rectangle>
    </div>
  </li>
</ng-template>
