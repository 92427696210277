import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-q-modal",
  templateUrl: "./q-modal.component.html",
  styleUrls: ["./q-modal.component.scss"],
})
export class QModalComponent implements OnInit {
  hasHeader: boolean = true;
  hasHeaderCloseButton: boolean = true;
  hasActionBar: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<QModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.hasHeader = this.data?.hasHeader;
    this.hasHeaderCloseButton = this.data?.hasHeaderCloseButton;
    this.hasActionBar = this.data?.hasActionBar;
  }
}
