import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SearchProfile } from "@app/contacts/contact-search-profile/models/search-profile";
import * as fromContact from "@app/contacts/contact.selectors";
import { hardTrimBody } from "@app/core/services/api/api.service";
import { CategoryType } from "@app/models/category-type";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/features";
import { getEaEmployeeId, getEaOfficeId } from "@app/shared/user";
import { SEARCH_PROFILE_CREATE } from "@app/shared/utils/tab-types";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import * as searchProfileActions from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.actions";
import * as fromSearchProfile from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { SearchProfileFormComponent } from "@app/sidebar/search-profile/search-profile-form/search-profile-form.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-create-search-profile",
  templateUrl: "./create-search-profile.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-search-profile.component.scss",
  ],
})
export class CreateSearchProfileComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(SearchProfileFormComponent, { static: false })
  spForm: SearchProfileFormComponent;

  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  tabType = SEARCH_PROFILE_CREATE;
  contactId$: Observable<string>;
  additionalFilters$: Observable<CategoryType[]>;
  country$: Observable<string>;
  searchProfileFeature$: Observable<SearchProfileFeature>;
  unsubscribe$: Subject<void> = new Subject<void>();
  patching$: Observable<boolean>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getAdditionalFilters();
    this.connectTab();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));

    this.contactId$ = this.store.pipe(
      select(fromContact.getContactId),
      filter((value) => !!value)
    );
    this.additionalFilters$ = this.store.pipe(
      select(fromSearchProfile.getAdditionalFilters)
    );
    this.country$ = this.store.pipe(select(fromConfig.getCountry));
    this.searchProfileFeature$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE))
    );
    this.patching$ = this.store.pipe(select(fromSearchProfile.getPatching));
    this.loading$ = this.store.pipe(select(fromSearchProfile.getLoading));
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.eaEmployeeId$ = this.store.pipe(select(getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(getEaOfficeId));
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstEntity, current]) => {
        if (_.isEqual(firstEntity, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  private getAdditionalFilters(): void {
    observableCombineLatest([this.country$, this.searchProfileFeature$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([country, feature]) => {
        if (feature.categoryIds) {
          this.store.dispatch(
            searchProfileActions.getAdditionalFiltersRequest({
              categoryIds: feature.categoryIds,
              country,
            })
          );
        }
      });
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  submit(): void {
    this.spForm.handleSubmit();
  }

  createSearchProfile(newSearchProfile: SearchProfile): void {
    const searchProfileParams = hardTrimBody({
      ...newSearchProfile,
    });

    observableCombineLatest([
      this.contactId$,
      this.eaEmployeeId$,
      this.eaOfficeId$,
      this.searchProfileFeature$.pipe(
        map((feature: SearchProfileFeature) => feature.forceAlertCreation)
      ),
    ])
      .pipe(first())
      .subscribe(
        ([contactId, eaEmployeeId, eaOfficeId, openManageAlertSidebar]) =>
          this.store.dispatch(
            searchProfileActions.createSearchProfileRequest({
              searchProfile: {
                ...searchProfileParams,
                contactId,
                createdByEaEmployeeId: eaEmployeeId,
                eaOfficeId: eaOfficeId,
              },
              openManageAlertSidebar,
            })
          )
      );
  }
}
