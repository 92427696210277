import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "contact-card",
  template: `
    <div class="card">
      <div>
        <div>{{ name }}</div>
        <small>{{ address }}</small>
        <small *ngIf="address?.length > 0"> | </small>
        <small>{{ number }}</small>
        <small *ngIf="number?.length > 0 && email?.length > 0"> | </small>
        <small>{{ email }}</small>
        <div *ngIf="type === 'Business'" class="card__marker">
          <small translate>business</small>
        </div>
      </div>
      <div
        *ngIf="deletable"
        class="link flex-center"
        (click)="delete.emit(true)"
      >
        <app-icon name="times"></app-icon>
      </div>
    </div>
  `,
  styleUrls: ["./contact-card.component.scss"],
})
export class ContactCardComponent {
  @Input() name: String;
  @Input() address: String;
  @Input() number: String;
  @Input() email: String;
  @Input() type: string;
  @Input() deletable: boolean;

  @Output() delete: EventEmitter<boolean> = new EventEmitter();
}
