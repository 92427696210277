import { SalesMeeting } from "./sales-meeting";
import { Task } from "./task";

export class TaskSalesMeeting extends Task {
  salesMeeting: SalesMeeting;

  constructor(data = {}) {
    super();
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
