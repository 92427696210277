import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { filter, map, Subject, take, takeUntil } from "rxjs";

import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ValueMonitorService } from "@app/core/ngrx/entity-services/value-monitor.service";
import { PostValueMonitorBody, ValueMonitor } from "@app/models";
import { VALUE_MONITOR } from "@app/shared/utils/tab-types";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { ValueMonitorFormComponent } from "@app/sidebar/value-monitor/components/value-monitor-form/value-monitor-form.component";

@Component({
  selector: "app-value-monitor-edit",
  templateUrl: "./value-monitor-edit.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./value-monitor-edit.component.scss",
  ],
})
export class ValueMonitorEditComponent
  extends ConnectTab
  implements OnInit, IConnectableTab, OnDestroy
{
  @ViewChild(ValueMonitorFormComponent, { static: true })
  valueMonitorFormComponent: ValueMonitorFormComponent;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();

  tabType = VALUE_MONITOR;
  data;

  constructor(
    store: Store<AppState>,
    private route: ActivatedRoute,
    private valueMonitorService: ValueMonitorService
  ) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.fetchData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchData() {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe$),
        map((params) => params.id)
      )
      .subscribe((id) => {
        this.valueMonitorService
          .fetchById(id)
          .subscribe((data: ValueMonitor) => {
            const {
              contactId,
              eaOid,
              baseValuation,
              baseValuationDate,
              frequency,
              eaEmployeeId,
            } = data;
            this.data = {
              contactId,
              eaOid,
              baseValuation,
              baseValuationDate,
              frequency,
              eaEmployeeId,
            };
          });
      });
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onRemove() {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_value_monitor_header",
        message: "remove_value_monitor_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1)
      )
      .subscribe(() => {
        const id = this.route.snapshot.params["id"];
        this.valueMonitorService.remove(id).subscribe(() => {
          this.closeTab();
        });
      });
  }

  onSubmit() {
    this.valueMonitorFormComponent.submit((body: PostValueMonitorBody) => {
      const id = this.route.snapshot.params["id"];
      this.valueMonitorService.patch(id, body).subscribe(() => {
        this.closeTab();
      });
    });
  }
}
