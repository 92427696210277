import { createAction, props } from "@ngrx/store";

export const show = createAction(
  "[Confirm modal] SHOW",
  props<{
    header: string;
    message: string;
    newTabUrl?: string;
  }>()
);

export const confirm = createAction("[Confirm modal] CONFIRM");

export const cancel = createAction("[Confirm modal] CANCEL");

export const reset = createAction("[Confirm modal] RESET");
