<ng-container *ngIf="object$ | async as property">
  <app-q-modal>
    <ng-container slot="header">
      <div class="header">
        <app-property-name
          [disableSummary]="true"
          [property]="property"
        ></app-property-name>
        <app-property-quick-actions
          [property]="property"
        ></app-property-quick-actions>
      </div>
    </ng-container>
    <ng-container slot="body">
      <div class="modal-body" *ngIf="property">
        <div class="body-row">
          <img
            *ngIf="property?.images?.length > 0 && property?.images[0].url"
            [src]="property?.images[0].url + '450'"
            alt=""
            class="image"
          />
          <div class="details">
            <app-q-chip
              [text]="property.status | objectStatusName | async"
              [color]="property.status | objectStatusChipColor"
            ></app-q-chip>
            <p>
              {{ "object_type" | translate }} :
              {{
                property?.objType
                  ? (property.objType | objectTypeName | async)
                  : ("object_type_missing" | translate)
              }}
            </p>
            <p>
              {{ "size" | translate }}: {{ property?.area }}
              {{ "area_unit" | translate }}
            </p>
            <p>{{ "no_of_rooms" | translate }}: {{ property?.noOfRooms }}</p>
            <p>
              {{ "price" | translate }}: {{ property?.price | qNumber }}
              {{ property?.priceCode }}
            </p>
            <p>
              {{ "monthly_fee" | translate }}:
              {{ property?.monthlyFee | qNumber }} {{ property?.priceCode }}
            </p>
            <p>
              {{ "responsible_broker" | translate }}:
              {{
                property?.responsibleEmployee?.employeeFullName
                  ? property?.responsibleEmployee?.employeeFullName
                  : ("responsible_employee_missing" | translate)
              }}
            </p>
          </div>
          <div class="actions">
            <app-q-button
              (click)="goToProperty(property)"
              [fullWidth]="true"
              class="action"
              [label]="
                property.isExistingHome
                  ? 'go_to_contact_property'
                  : 'go_to_property'
              "
              [buttonType]="'save'"
              [icon]="'arrow-right'"
              [enableHoverMenu]="!property.isExistingHome"
            >
              <div menuContent>
                <div *ngFor="let subPage of subPagesService.subPages$ | async">
                  <app-q-action-dropdown-item
                    [label]="subPage.label"
                    [icon]="subPage.icon"
                    (press)="
                      goToProperty(property, subPage.routerLink.slice(-1).pop())
                    "
                  ></app-q-action-dropdown-item>
                </div>
              </div>
            </app-q-button>
            <app-q-button
              (click)="openInNewTab(property)"
              [fullWidth]="true"
              class="action"
              [label]="'open_in_new_tab'"
              [buttonType]="'save'"
              [icon]="'share-square'"
              [enableHoverMenu]="!property.isExistingHome"
            >
              <div menuContent>
                <div *ngFor="let subPage of subPagesService.subPages$ | async">
                  <app-q-action-dropdown-item
                    [label]="subPage.label"
                    [icon]="subPage.icon"
                    (press)="openInNewTab(subPage.routerLink.slice(-1).pop())"
                  ></app-q-action-dropdown-item>
                </div>
              </div>
            </app-q-button>
          </div>
        </div>
        <hr />
        <div class="body-row">
          <div class="details">
            <h4 translate>object_parties</h4>
            <app-property-parties-list
              [disableActions]="true"
              [eaOid]="property.eaOid"
            ></app-property-parties-list>
          </div>
          <div class="details activity">
            <h4 translate>activity</h4>
            <p *ngIf="property?.bids?.length > 0 && !!property?.bids[0].amount">
              Highest bid:
              <span class="highlight"
                >{{ property?.bids[0].amount | qNumber }}
                {{ property.priceCode }}</span
              >
            </p>
            <p>
              Potential buyers:
              <span class="highlight">{{
                (
                  property?.contacts
                  | filterContactTypeFromObjectContacts : "potentialbuyer"
                ).length | qNumber
              }}</span>
            </p>
            <ng-container *ngIf="property?.dates?.length > 0">
              <ng-container *ngFor="let date of property.dates">
                <p
                  *ngIf="
                    !!date?.date &&
                    date.typeName.toLowerCase() !== 'contractenddatesurvey' &&
                    date.typeName.toLowerCase() !== 'settled date'
                  "
                >
                  {{ date.typeName | formatDateTypeName | translate }}:
                  {{ date.date | momentifyFromApiFormat }}
                </p>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container slot="actions">
      <button class="q-btn--cancel" mat-dialog-close (click)="closeModal()">
        {{ "close" | translate | uppercase }}
      </button>
    </ng-container>
  </app-q-modal>
</ng-container>
