import { Clipboard } from "@angular/cdk/clipboard";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { Employee, Office } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { MILES } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import {
  getEaOfficeId,
  getEmployee,
  getOfficeId,
  Level,
  logout,
} from "@app/shared/user";
import { openExternalLink } from "@app/shared/utils/external-link-utils";
import { select, Store } from "@ngrx/store";
import { filter, first, map, Observable, Subject, takeUntil } from "rxjs";

export interface ExternalSystemLinks {
  baseUrl: ExternalPresentableLink;
  deepLinks: {
    links: {
      [key: string]: ExternalPresentableLink;
    };
  };
  integration: string;
}

export interface EmployeeOffices {
  eaEmployeeId: string;
  offices: Office[];
}

@Component({
  selector: "profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["../sidebar.component.common.scss", "./profile.component.scss"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  eaOfficeId$: Observable<string>;
  officeId$: Observable<string>;
  employee$: Observable<Employee>;
  milesFeatureEnabled$: Observable<boolean>;
  roles$: Observable<string[]>;
  isSuperAdmin = false;
  employeeOffices$: Observable<EmployeeOffices[]>;
  numberOfOffices = 0;
  destroy$ = new Subject<void>();
  hasSystemLinks$: Observable<boolean>;
  ssoifyLoading$: Observable<boolean>;
  baseSystemLink: ExternalPresentableLink;
  baseSystemName = "";
  showUntranslatedTooltips = false;

  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private clipboard: Clipboard,
    public authService: AuthService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.showUntranslatedTooltips =
      this.localStorageService.fetchUserPreference(
        "show_untranslated_tooltips"
      ) === "true";
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout() {
    this.store.dispatch(logout());
  }

  selectAndCopy(textInput): void {
    this.clipboard.copy(textInput.value);
  }

  getSystemLinks() {
    this.apiService
      .get("erp-system-links", {}, "integrations")
      .pipe(takeUntil(this.destroy$), first())
      .subscribe((links: ExternalSystemLinks) => {
        this.baseSystemLink = links.baseUrl;
        this.baseSystemName = links.integration;
      });
  }

  openExternalLink(e: Event, link: ExternalPresentableLink): void {
    e.stopPropagation();
    openExternalLink(link, this.store);
  }

  updateLocalStorage(seeUntranslatedTooltips: boolean) {
    let newValue = "false";
    if (seeUntranslatedTooltips) {
      newValue = "true";
    }

    this.localStorageService.saveUserPreference(
      "show_untranslated_tooltips",
      newValue
    );
  }

  private mapStateToProps() {
    this.eaOfficeId$ = this.store.pipe(select(getEaOfficeId));
    this.officeId$ = this.store.pipe(select(getOfficeId));
    this.employee$ = this.store.pipe(select(getEmployee));
    this.roles$ = this.store.pipe(select(fromUser.getRoles));
    this.roles$.subscribe(
      (roles) =>
        (this.isSuperAdmin =
          roles.includes("crm2 admins") ||
          this.authService.isSuerAdminPropsEnabled())
    );
    this.milesFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(MILES)),
      map((feature: Feature) => feature.enabled)
    );
    this.ssoifyLoading$ = this.store.pipe(select(fromShared.getSsoifyLoading));
    this.hasSystemLinks$ = this.store.pipe(
      select(hasIntegration("ERP_SYSTEM_LINKS"))
    );
    this.hasSystemLinks$
      .pipe(filter(Boolean))
      .subscribe(() => this.getSystemLinks());
    this.employeeOffices$ = this.store.pipe(
      select(fromUser.getAllEmployeesAndOffices)
    );
    this.employeeOffices$
      .pipe(takeUntil(this.destroy$))
      .subscribe((employeeOffices) => {
        employeeOffices.forEach((eo) =>
          eo.offices.forEach(() => this.numberOfOffices++)
        );
      });
  }

  switchUserLevel(level: Level) {
    this.store.dispatch(fromUser.switchSuperAdminLevelRequest({ level }));
  }
}
