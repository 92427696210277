import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { TaskSalesMeeting } from "@app/models";
import { TASK } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import {
  catchError,
  concatMap,
  from as observableFrom,
  Observable,
  of as observableOf,
  switchMap,
} from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as taskActions from "../ngrx/tasks.actions";
import * as tsmrActions from "./tasks-sales-meeting-report.actions";
import { UpdateSalesMeetingReportPayload } from "./tasks-sales-meeting-report.actions";

@Injectable()
export class TasksSalesMeetingReportEffects {
  updateSalesMeeting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tsmrActions.updateSalesMeetingRequest),
      switchMap(({ params }) =>
        this.apiService
          .patch(
            `sales-meetings/${params.salesMeeting.eaCrmSalesMeetingId}`,
            params.salesMeeting
          )
          .pipe(
            switchMap((response) =>
              this.getUpdateSalesMeetingSuccessActions$(response, params)
            ),
            catchError(() =>
              observableOf(
                tsmrActions.updateSalesMeetingFailed({
                  message: "update_sales_meeting_failed",
                })
              )
            )
          )
      )
    )
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tsmrActions.updateTaskRequest),
      switchMap(({ payload }) =>
        this.apiService
          .patch(`tasks/${payload.task.eaTaskId}`, payload.task)
          .pipe(
            concatMap((response) =>
              this.getUpdateTaskSuccessActions$(response, payload)
            ),
            catchError(() =>
              observableOf(
                tsmrActions.updateTaskFailed({ message: "update_task_failed" })
              )
            )
          )
      )
    )
  );

  createFollowUpTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tsmrActions.createFollowUpTaskRequest),
      switchMap(({ payload }) =>
        this.apiService.post(`tasks`, payload.followUpTask).pipe(
          switchMap((response) =>
            this.getCreateFollowUpTaskSuccessActions$(response)
          ),
          catchError(() =>
            observableOf(
              tsmrActions.createFollowUpTaskFailed({
                message: "create_follow_up_task_failed",
              })
            )
          )
        )
      )
    )
  );

  failed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        tsmrActions.createFollowUpTaskFailed,
        tsmrActions.updateTaskFailed,
        tsmrActions.updateSalesMeetingFailed
      ),
      switchMap(({ message }) => this.getFailActions$(message))
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}

  private getUpdateSalesMeetingSuccessActions$(
    salesMeeting,
    payload: UpdateSalesMeetingReportPayload
  ): Observable<Action> {
    return observableFrom([
      tsmrActions.updateSalesMeetingSuccess({ salesMeeting }),
      tsmrActions.updateTaskRequest({ payload }),
      toastActions.success({ message: "update_sales_meeting_success" }),
    ]);
  }

  private getUpdateTaskSuccessActions$(
    task,
    payload: UpdateSalesMeetingReportPayload
  ): Observable<Action> {
    return observableFrom([
      taskActions.updateTaskSuccess({ task: new TaskSalesMeeting(task) }),
      toastActions.success({ message: "update_task_success" }),
      ...this.getFollowUpTaskRequestActions(payload),
    ]);
  }

  private getCreateFollowUpTaskSuccessActions$(task): Observable<Action> {
    return observableFrom([
      tsmrActions.createFollowUpTaskSuccess(task),
      toastActions.success({ message: "create_follow_up_task_success" }),
      ...this.getCloseTabActions(),
    ]);
  }

  private getFailActions$(message): Observable<Action> {
    return observableFrom([
      toastActions.danger({ message }),
      ...this.getCloseTabActions(),
    ]);
  }

  private getFollowUpTaskRequestActions(
    payload: UpdateSalesMeetingReportPayload
  ): Action[] {
    return payload.followUpTask
      ? [tsmrActions.createFollowUpTaskRequest({ payload })]
      : this.getCloseTabActions();
  }

  private getCloseTabActions(): Action[] {
    return [
      tsmrActions.updateTaskSalesMeetingReportData(),
      sidebarActions.closeTab({ tabType: TASK }),
      taskActions.reset(),
    ];
  }
}
