import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { QObject } from "@app/models";
import { Project } from "@app/showings/models/project";
import * as ShowingActions from "@app/showings/ngrx/showings/showings.actions";
import { ShowingsState } from "@app/showings/showings-feature.reducer";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface ShowingState {
  loading: boolean;
  eaOid: string;
  setAsWinningBidLink: ExternalPresentableLink | null;
  setAsWinningBidLinkLoading: boolean;
  biddingSettingsLink: string | null;
  biddingSettingsIframeSource: string | null;
  biddingSettingsLinkLoading: boolean;
  pdfLoading: boolean;
  showingPdfLoading: boolean;
  project: Project | null;
  projectLoading: boolean;
  object: QObject | null;
  objectLoading: boolean;
}

export const initialState: ShowingState = {
  loading: false,
  eaOid: null,
  setAsWinningBidLink: null,
  setAsWinningBidLinkLoading: false,
  biddingSettingsLink: null,
  biddingSettingsIframeSource: null,
  biddingSettingsLinkLoading: false,
  pdfLoading: false,
  showingPdfLoading: false,
  project: null,
  projectLoading: false,
  object: null,
  objectLoading: false,
};

export function showingReducer(
  state: ShowingState,
  action: Action
): ShowingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(ShowingActions.getPDFRequest, (state) => ({
    ...state,
    pdfLoading: true,
  })),
  on(ShowingActions.getPDFFailure, ShowingActions.getPDFSuccess, (state) => ({
    ...state,
    pdfLoading: false,
  })),
  on(ShowingActions.getShowingPDFRequest, (state) => ({
    ...state,
    showingPdfLoading: true,
  })),
  on(
    ShowingActions.getShowingPDFSuccess,
    ShowingActions.getShowingPDFFailure,
    (state) => ({
      ...state,
      showingPdfLoading: false,
    })
  ),
  on(ShowingActions.getSetAsWinningBidLinkRequest, (state) => ({
    ...state,
    setAsWinningBidLink: null,
    setAsWinningBidLinkLoading: true,
  })),
  on(ShowingActions.getSetAsWinningBidLinkSuccess, (state, { payload }) => ({
    ...state,
    setAsWinningBidLink: payload,
    setAsWinningBidLinkLoading: false,
  })),
  on(ShowingActions.getSetAsWinningBidLinkFailure, (state) => ({
    ...state,
    setAsWinningBidLink: null,
    setAsWinningBidLinkLoading: false,
  })),
  on(ShowingActions.getBiddingSettingsLinkRequest, (state) => ({
    ...state,
    biddingSettingsLink: null,
    biddingSettingsIframeSource: null,
    biddingSettingsLinkLoading: true,
  })),
  on(ShowingActions.getBiddingSettingsLinkSuccess, (state, { url }) => ({
    ...state,
    biddingSettingsLink: url,
    biddingSettingsIframeSource: url,
    biddingSettingsLinkLoading: false,
  })),
  on(ShowingActions.getBiddingSettingsLinkFailure, (state) => ({
    ...state,
    biddingSettingsLink: null,
    biddingSettingsIframeSource: null,
    biddingSettingsLinkLoading: false,
  })),
  on(ShowingActions.getProjectRequest, (state) => ({
    ...state,
    projectLoading: true,
  })),
  on(ShowingActions.getProjectSuccess, (state, { project }) => ({
    ...state,
    projectLoading: false,
    project,
  })),
  on(ShowingActions.getProjectFailure, (state) => ({
    ...state,
    projectLoading: false,
    project: null,
  })),
  on(ShowingActions.getObjectRequest, (state) => ({
    ...state,
    objectLoading: true,
  })),
  on(ShowingActions.getObjectSuccess, (state, { property }) => ({
    ...state,
    objectLoading: false,
    object: property,
  })),
  on(ShowingActions.getObjectFailure, (state) => ({
    ...state,
    objectLoading: false,
    object: null,
  })),
  on(ShowingActions.setShowingObjectEaOid, (state, { eaOid }) => ({
    ...state,
    eaOid,
  }))
);

const selectFeature = createFeatureSelector<ShowingsState>("showings");
const getState = createSelector(
  selectFeature,
  (state: ShowingsState) => state.shared
);

export const getPdfLoading = createSelector(
  getState,
  (state: ShowingState) => state.pdfLoading
);

export const getBiddingSettingsLinkLoading = createSelector(
  getState,
  (state: ShowingState) => state.biddingSettingsLinkLoading
);

export const getSetAsWinningBidLinkLoading = createSelector(
  getState,
  (state: ShowingState) => state.setAsWinningBidLinkLoading
);

export const getShowingObjectEaOid = createSelector(
  getState,
  (state: ShowingState) => state.eaOid
);

export const getProject = createSelector(
  getState,
  (state: ShowingState) => state.project
);

export const getProjectLoading = createSelector(
  getState,
  (state: ShowingState) => state.projectLoading
);

export const getObject = createSelector(
  getState,
  (state: ShowingState) => state.object
);

export const getObjectLoading = createSelector(
  getState,
  (state: ShowingState) => state.objectLoading
);
