import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { DURATION_LONG } from "@app/core/components/toast/ngrx/toast.actions";
import { getIsNewsletterConfigured } from "@app/integrations/ngrx/integrations.reducer";
import { Store } from "@ngrx/store";
import { combineLatest, map, tap } from "rxjs";

@Injectable()
export class CampaignsGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    const observables = [this.store.select(getIsNewsletterConfigured())];

    return combineLatest(observables).pipe(
      map(([newsLettersIsConfigred]) => newsLettersIsConfigred),
      tap((isConfigured) => {
        if (!isConfigured) {
          this.store.dispatch(
            toastActions.warning({
              message: "warning_message_campaigns_not_configured",
              duration: DURATION_LONG,
            })
          );
        }
      })
    );
  }
}
