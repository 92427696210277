<ng-container *ngIf="(showingObject$ | async)?.images as images">
  <div class="widget-header">
    <h3>
      <app-icon size="large" name="images"></app-icon>&nbsp;<span>{{
        "images" | translate
      }}</span>
    </h3>
    <div class="d-flex">
      <app-q-circle-btn
        *ngIf="(canDownloadImages$ | async) && images.length > 0"
        icon="laptop-arrow-down"
        tooltipText="download"
        (click)="downloadImages()"
      >
      </app-q-circle-btn>
      <div *ngIf="hasCreateVideoFeature$ | async" class="margin-left-05">
        <app-q-circle-btn
          icon="video-plus"
          tooltipText="video_management"
          (click)="showVideoModal($event)"
        >
        </app-q-circle-btn>
      </div>
    </div>
  </div>

  <div class="wrapper" (click)="open($event)">
    <ng-container *ngIf="images.length > 0; else noImages">
      <div
        [ngStyle]="{ 'background-image': imageUrl$ | async }"
        class="img-container"
      >
        <div *ngIf="images.length > 1" class="arr-left arr">
          <i (click)="loadPreviousImage($event)" class="fa fa-arrow-left"></i>
        </div>
        <div *ngIf="images.length > 1" class="arr-right arr">
          <i (click)="loadNextImage($event)" class="fa fa-arrow-right"></i>
        </div>
      </div>
      <div class="img-header"></div>
    </ng-container>
  </div>
</ng-container>

<ng-template #noImages>
  <div class="no-images">
    <span translate>object_missing_images</span>
  </div>
</ng-template>
