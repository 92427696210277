import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  catchError,
  filter,
  map,
  of as observableOf,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs";
import * as followUpActions from "../../sidebar/create-follow-up/ngrx/create-follow-up.actions";
import * as tsmrActions from "../../sidebar/tasks/tasks-sales-meeting-report/tasks-sales-meeting-report.actions";
import * as tasksActions from "../../sidebar/tasks/ngrx/tasks.actions";
import * as tipActions from "../../sidebar/tips/ngrx/send-tips.actions";
import * as freeLeadListActions from "./free-leads-list/free-leads-list.actions";
import { getLatestFreeLeadListFetchAction } from "./free-leads-list/free-leads-list.reducer";
import * as overdueTaskListActions from "./overdue-task-list/overdue-task-list.actions";
import * as threeDayTaskListActions from "./three-day-task-list/three-day-task-list.actions";
import { getLatestThreeDayTaskListFetchAction } from "./three-day-task-list/three-day-task-list.reducer";
import * as upcomingTaskListActions from "./upcoming-task-list/upcoming-task-list.actions";
import { getLatestUpcomingTasksFetchAction } from "./upcoming-task-list/upcoming-task-list.reducer";

@Injectable()
export class TodoListEffects {
  getOverdueRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(overdueTaskListActions.getOverdueRequest),
      switchMap(({ filters }) =>
        this.apiService.get("tasks", filters).pipe(
          map(({ rows, offset, total }: PaginationListDTO) => {
            if (total > 0) {
              return overdueTaskListActions.getOverdueSuccess({
                tasks: rows,
                listStart: offset,
                totalRows: total,
              });
            }
            return overdueTaskListActions.getOverdueNoTasksFound();
          }),
          catchError(() =>
            observableOf(overdueTaskListActions.getOverdueFail())
          )
        )
      )
    )
  );

  getFreeLeadsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(freeLeadListActions.getFreeLeadsRequest),
      switchMap(({ filters }) =>
        this.apiService.get("tasks", filters).pipe(
          map(({ rows, offset, total }: PaginationListDTO) => {
            if (total > 0) {
              const replace: boolean = offset === 0;
              return freeLeadListActions.getFreeLeadsSuccess({
                tasks: rows,
                listStart: offset,
                totalRows: total,
                replace,
              });
            }
            return freeLeadListActions.getFreeLeadsNoTasksFound();
          }),
          catchError(() => observableOf(freeLeadListActions.getFreeLeadsFail()))
        )
      )
    )
  );

  getCurrentWeekRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(threeDayTaskListActions.getCurrentWeekRequest),
      switchMap(({ filters }) =>
        this.apiService.get("tasks", filters).pipe(
          map(({ rows }: PaginationListDTO) =>
            threeDayTaskListActions.getCurrentWeekSuccess({ tasks: rows })
          ),
          catchError(() =>
            observableOf(threeDayTaskListActions.getCurrentWeekFail())
          )
        )
      )
    )
  );

  getUpcomingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upcomingTaskListActions.getUpcomingRequest),
      switchMap(({ filters }) =>
        this.apiService.get("tasks", filters).pipe(
          map(({ rows, offset, total }: PaginationListDTO) => {
            if (total > 0) {
              const replace: boolean = offset === 0;
              return upcomingTaskListActions.getUpcomingSuccessWithDefalut(
                rows,
                offset,
                total,
                replace
              );
            }
            return upcomingTaskListActions.getUpcomingNoTasksFound();
          }),
          catchError(() =>
            observableOf(upcomingTaskListActions.getUpcomingFail())
          )
        )
      )
    )
  );

  redoGetCurrentWeekRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        followUpActions.createFollowUpSuccess,
        tasksActions.updateTaskSuccess,
        tasksActions.createTaskAttemptSuccess,
        tipActions.sendTipsSuccess,
        tsmrActions.updateTaskSalesMeetingReportData
      ),
      withLatestFrom(
        this.store.pipe(select(getLatestThreeDayTaskListFetchAction))
      ),
      filter(([, action]) => !!action),
      switchMap(() =>
        this.store.pipe(select(getLatestThreeDayTaskListFetchAction), take(1))
      )
    )
  );

  redoGetFreeLeadsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.updateTaskSuccess, tipActions.sendTipsSuccess),
      withLatestFrom(this.store.pipe(select(getLatestFreeLeadListFetchAction))),
      filter(([, action]) => !!action),
      switchMap(() =>
        this.store.pipe(select(getLatestFreeLeadListFetchAction), take(1))
      )
    )
  );

  redoGetUpcomingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        followUpActions.createFollowUpSuccess,
        tasksActions.updateTaskSuccess,
        tasksActions.createTaskAttemptSuccess,
        tipActions.sendTipsSuccess,
        tsmrActions.updateTaskSalesMeetingReportData
      ),
      withLatestFrom(
        this.store.pipe(select(getLatestUpcomingTasksFetchAction))
      ),
      filter(([, state]) => !!state),
      switchMap(() =>
        this.store.pipe(select(getLatestUpcomingTasksFetchAction), take(1))
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}
}
