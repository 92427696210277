import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { EmployeeDTO, Office } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { ADVANCED_LEADS_HANDLING } from "@app/shared/config/utils/features";
import { assignOptions } from "@app/sidebar/tasks/tasks-default/tasks-default.component";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "sidebar-tasks-default-assign",
  templateUrl: "./tasks-default-assign.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./tasks-default-assign.component.scss",
  ],
})
export class TasksDefaultAssignComponent implements OnInit, OnDestroy {
  @Input() offices: Office[];
  @Input() employees: EmployeeDTO[];
  @Input() formGroup: FormGroup;
  @Input() eaTaskId: string;
  @Input() eaEmployeeId: string;
  @Input() isManagerOrAdmin: boolean;

  @Output() assign: EventEmitter<assignOptions> =
    new EventEmitter<assignOptions>();

  assignOptions = assignOptions;
  advancedLeadsHandlingFeature$: Observable<Feature>;
  unSubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {
    this.formGroup = new FormGroup({
      office: new FormControl(""),
      employee: new FormControl(""),
      sendMessage: new FormControl(false),
      message: new FormControl(""),
    });
  }

  ngOnInit() {
    this.formGroup.get("employee").setValidators([Validators.required]);
    this.formGroup.get("office").setValidators([Validators.required]);

    this.formGroup.parent.get("manage.result").clearValidators(); // TODO move to parent, use onReady$ state
    this.formGroup.parent.get("manage.result").updateValueAndValidity();

    this.mapStateToProps();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  private mapStateToProps() {
    this.advancedLeadsHandlingFeature$ = this.store.pipe(
      select(getFeature(ADVANCED_LEADS_HANDLING))
    );
  }
}
