import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface SummaryState {
  stats: GrowthStatistics;
  loading: boolean;
}

export const initialState: SummaryState = {
  loading: false,
  stats: null,
};

export function summaryReducer(
  state: SummaryState,
  action: Action
): SummaryState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(widgetActions.fetchStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(widgetActions.fetchStatisticsSuccess, (state, { stats }) => ({
    ...state,
    loading: false,
    stats,
  })),
  on(widgetActions.fetchStatisticsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.summary
);

export const getStats = createSelector(
  getState,
  (state: SummaryState) => state.stats
);
export const getLoading = createSelector(
  getState,
  (state: SummaryState) => state.loading
);
