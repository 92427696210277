import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreateObjectComponent } from "@app/sidebar/create-object/create-object.component";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormComponentsModule,
    FormsModule,
    UtilModule,
    BsDatepickerModule,
    TooltipModule,
    UiComponentsModule,
    SearchAddressModule,
  ],
  exports: [CreateObjectComponent],
  declarations: [CreateObjectComponent],
})
export class CreateObjectModule {}
