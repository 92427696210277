import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { filter, first, map, takeUntil } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { BidService } from "@app/core/ngrx/entity-services/bid.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { PotentialBuyerService } from "@app/core/ngrx/multi-entity-services/potential-buyer.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { QObject } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { BiddingFeature } from "@app/shared/config/models/bidding-feature";
import { BIDDING } from "@app/shared/config/utils/features";
import { PotentialBuyer } from "@app/showings/models";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: "app-add-bid-modal",
  templateUrl: "./add-bid-modal.component.html",
  styleUrls: ["./add-bid-modal.component.scss"],
})
export class AddBidModalComponent implements OnInit, OnDestroy {
  potentialBuyer$: Observable<PotentialBuyer>;
  object$: Observable<QObject>;
  currency$: Observable<string>;
  hideBidTranslationKey$: Observable<string>;
  enableBroadcastSetting$: Observable<boolean>;
  loading$: Observable<boolean>;

  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddBidModalComponent>,
    private contactService: ContactService,
    private objectService: ObjectService,
    private fb: FormBuilder,
    public bidService: BidService,
    private store: Store<AppState>,
    private potentialBuyerService: PotentialBuyerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();

    this.enableBroadcastSetting$
      .pipe(
        filter((it) => !it),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.form.get("bidder").setValue(false);
        this.form.get("otherBidders").setValue(false);
        this.form.get("sellers").setValue(false);
        this.form.get("brokers").setValue(false);
      });
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  onHideBidChange(hide: MatCheckboxChange): void {
    if (!hide.checked) {
      this.form.get("otherBidders").enable();
      this.form.get("bidder").enable();
      return;
    }

    this.form.patchValue({
      otherBidders: false,
      bidder: false,
    });
    this.form.get("otherBidders").disable();
    this.form.get("bidder").disable();
  }

  private mapStateToProps() {
    this.potentialBuyer$ = this.contactService.entityMap$.pipe(
      takeUntil(this.unsubscribe$),
      map((em) => em[this.data.contactId]),
      filter((it) => !!it)
    );
    this.object$ = this.objectService.entityMap$.pipe(
      map((em) => em[this.data.eaOid]),
      filter((it) => !!it)
    );
    this.enableBroadcastSetting$ = this.store.pipe(
      select(
        hasIntegration(IntegrationResource.CreateBidCommunicationConfiguration)
      )
    );
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.hideBidTranslationKey$ = this.store.pipe(
      select(fromConfig.getFeature(BIDDING)),
      map(
        (biddingFeature: BiddingFeature) => biddingFeature.hideBidTranslationKey
      )
    );
    this.loading$ = this.bidService.loading$;
  }

  private transformLocalDateTimeToISO8601(date: string, time: string): string {
    const localeData = moment.localeData();
    const longDateFormat = localeData.longDateFormat("L");
    const now = moment();
    const dateTime = moment(`${date} ${time}`, `${longDateFormat} HH:mm`)
      .seconds(now.seconds())
      .milliseconds(now.milliseconds());

    return dateTime.toISOString();
  }

  private buildForm() {
    const now = moment();
    this.form = this.fb.group({
      amount: [null, Validators.required],
      bidder: true,
      otherBidders: true,
      sellers: true,
      brokers: true,
      date: [now.format("L"), Validators.required],
      time: [
        now.format("HH:mm"),
        [
          Validators.required,
          Validators.pattern(RegExp(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)),
        ],
      ],
      condition: [""],
      isHidden: [false],
    });
  }

  submit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.bidService.loading$
      .pipe(
        first(),
        filter((status) => !status)
      )
      .subscribe(() => {
        const {
          amount,
          isHidden,
          bidder,
          otherBidders,
          sellers,
          brokers,
          condition,
          date,
          time,
        } = this.form.getRawValue();

        const createdAt = this.transformLocalDateTimeToISO8601(
          moment(date).format("L"),
          time
        );

        this.bidService
          .post(this.data.eaOid, this.data.contactId, {
            amount,
            createdAt,
            condition,
            isHidden,
            communicationSettings: {
              communicateCreatedBidTo: {
                bidder,
                otherBidders,
                sellers,
                brokers,
              },
            },
          })
          .subscribe({
            next: () => {
              this.potentialBuyerService
                .getBidsByObject(this.data.eaOid)
                .subscribe();
              this.closeModal();
            },
            error: () => {
              this.store.dispatch(
                toastActions.danger({ message: "failed_adding_bid" })
              );
            },
          });
      });
  }
}
