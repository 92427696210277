import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { slideInOutAnimation } from "@app/shared/animations";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";

@Component({
  selector: "checkbox-select",
  animations: [slideInOutAnimation()],
  templateUrl: "./checkbox-select.component.html",
  styleUrls: ["./checkbox-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxSelectComponent {
  @ViewChild(BsDropdownDirective, { static: true })
  dropdown;
  @Input() entityName: string;
  @Input() inputShowDropdown: boolean;
  @Input() isBroker: boolean;
  @Input() selectedList: any[];
  @Input() unselectedList: any[];
  @Output() handleAddToList: EventEmitter<number> = new EventEmitter<number>();
  @Output() handleRemoveFromList: EventEmitter<number> =
    new EventEmitter<number>();

  showDropdown = false;

  addToListClicked(id: number) {
    this.handleAddToList.emit(id);
  }

  removeFromListClicked(id: number) {
    this.handleRemoveFromList.emit(id);
  }

  getUnselectedList() {
    const selectedListIds = this.selectedList.map((list) => list.callingListId);
    return this.unselectedList.filter(
      ({ callingListId }) => selectedListIds.indexOf(callingListId) < 0
    );
  }
}
