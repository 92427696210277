import { Contact, QObject } from "@app/models";
import { BankAdvisor } from "@app/settings/banks/manage-banks/models/bank-advisor";
import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import * as eikaActions from "@app/sidebar/eika/ngrx/eika.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface EikaState {
  banks: ExternalBank[];
  advisors: BankAdvisor[];
  contact: Contact;
  object: QObject;
  officeObjectsForSale: QObject[];
  banksLoading: boolean;
  advisorsLoading: boolean;
  contactLoading: boolean;
  objectLoading: boolean;
  sendingLoading: boolean;
}

const initialState: EikaState = {
  banks: [],
  advisors: [],
  contact: null,
  object: null,
  officeObjectsForSale: [],
  banksLoading: false,
  advisorsLoading: false,
  contactLoading: false,
  objectLoading: false,
  sendingLoading: false,
};

export function eikaReducer(state: EikaState, action: Action): EikaState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(eikaActions.getBanksRequest, (state) => ({
    ...state,
    banksLoading: true,
  })),
  on(eikaActions.getAdvisorsRequest, (state) => ({
    ...state,
    advisorsLoading: true,
  })),
  on(eikaActions.getContactRequest, (state) => ({
    ...state,
    contactLoading: true,
  })),
  on(eikaActions.getObjectRequest, (state) => ({
    ...state,
    objectLoading: true,
  })),
  on(eikaActions.getBanksSuccess, (state, { externalBanks }) => ({
    ...state,
    banks: externalBanks,
    banksLoading: false,
  })),
  on(eikaActions.getAdvisorsSuccess, (state, { bankAdvisors }) => ({
    ...state,
    advisors: bankAdvisors,
    advisorsLoading: false,
  })),
  on(
    eikaActions.updateContactSuccess,
    eikaActions.getContactSuccess,
    (state, { contact }) => ({
      ...state,
      contact,
      contactLoading: false,
    })
  ),
  on(eikaActions.getObjectSuccess, (state, { qObject }) => ({
    ...state,
    object: qObject,
    objectLoading: false,
  })),
  on(eikaActions.getBanksFail, (state) => ({
    ...state,
    banksLoading: false,
  })),
  on(eikaActions.getAdvisorsFail, (state) => ({
    ...state,
    advisorsLoading: false,
  })),
  on(eikaActions.getContactFail, (state) => ({
    ...state,
    contactLoading: false,
  })),
  on(eikaActions.getObjectFail, (state) => ({
    ...state,
    objectLoading: false,
  })),
  on(eikaActions.sendTipRequest, (state) => ({
    ...state,
    sendingLoading: true,
  })),
  on(eikaActions.sendTipSuccess, (state) => ({
    ...state,
    sendingLoading: false,
  })),
  on(eikaActions.sendTipFail, (state) => ({
    ...state,
    sendingLoading: false,
  })),
  on(eikaActions.getOfficeObjectsForSaleSuccess, (state, { payload }) => ({
    ...state,
    officeObjectsForSale: payload,
  })),
  on(eikaActions.clear, (state) => ({
    ...state,
    contact: null,
    object: null,
  })),
  on(
    eikaActions.createContactRequest,
    eikaActions.createContactSuccess,
    eikaActions.createContactFail,
    eikaActions.updateContactRequest,
    eikaActions.updateContactFail,
    eikaActions.getOfficeObjectsForSaleRequest,
    eikaActions.getOfficeObjectsForSaleFail,
    (state) => state
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.eika
);

export const getBanks = createSelector(
  getState,
  (state: EikaState) => state.banks
);
export const getAdvisors = createSelector(
  getState,
  (state: EikaState) => state.advisors
);
export const getContact = createSelector(
  getState,
  (state: EikaState) => state.contact
);
export const getObject = createSelector(
  getState,
  (state: EikaState) => state.object
);
export const getOfficeObjectsForSale = createSelector(
  getState,
  (state: EikaState) => state.officeObjectsForSale
);
export const getBanksLoading = createSelector(
  getState,
  (state: EikaState) => state.banksLoading
);
export const getAdvisorsLoading = createSelector(
  getState,
  (state: EikaState) => state.advisorsLoading
);
export const getContactLoading = createSelector(
  getState,
  (state: EikaState) => state.contactLoading
);
export const getObjectLoading = createSelector(
  getState,
  (state: EikaState) => state.objectLoading
);
export const getSendingLoading = createSelector(
  getState,
  (state: EikaState) => state.sendingLoading
);
