import { Note } from "@app/models";
import { Action, createReducer, on } from "@ngrx/store";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import { fetchNoteSuccess } from "@app/sidebar/potential-buyer/ngrx/note/note.actions";

export function noteReducer(state: Note | null, action: Action): Note | null {
  return reducer(state, action);
}

const reducer = createReducer(
  null,
  on(fetchNoteSuccess, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(potentialBuyerActions.reset, () => null)
);
