<h3>
  <app-icon name="wallet" size="large"></app-icon>&nbsp;<span translate
    >revenue_latest_365_days</span
  >
  <span
    class="info-tooltip"
    [tooltip]="('displayed_in' | translate) + ': ' + (currency$ | async)"
  >
    <app-icon name="info-circle"></app-icon>
  </span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <ng-container *ngIf="currentRevenue$ | async; else noData">
    <span class="revenue">{{ currentRevenue$ | async | qNumber }}</span>
    <div
      class="revenue-difference"
      *ngIf="revenueDifference$ | async as difference"
    >
      <span
        ><i [class]="getIconClass(difference)"></i> {{ difference | qNumber }}
      </span>
      <i
        class="icon-info"
        [tooltip]="
          ('compared_to_a_month_ago' | translate) +
          ': ' +
          previousPeriodStart +
          ' - ' +
          previousPeriodEnd
        "
      ></i>
    </div>
    <p></p>
  </ng-container>
  <ng-template #noData>
    <div class="no-data">
      <span translate>no_data_found</span>
    </div>
  </ng-template>
</div>
