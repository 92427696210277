import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ForgotPasswordFormComponent } from "./forgot-password-form/forgot-password-form.component";
import { LoginFormComponent } from "./login-form/login-form.component";
import { LoginComponent } from "./login.component";

import { LoginResolverService } from "@app/login/login-resolver.service";
import { LogoutComponent } from "@app/login/logout.component";
import { SsoFailedComponent } from "@app/login/sso-failed/sso-failed.component";
import { TokenBouncerComponent } from "@app/login/token-bnc/token-bouncer/token-bouncer.component";
import { LoginGuard } from "./login.guard";

const routes: Routes = [
  {
    path: "login",
    resolve: { login: LoginResolverService },
    children: [
      {
        path: "",
        component: LoginComponent,
        canActivate: [LoginGuard],
        children: [
          {
            path: "",
            component: LoginFormComponent,
          },
          {
            path: "forgotpassword",
            component: ForgotPasswordFormComponent,
          },
          { path: "bnc", component: TokenBouncerComponent },
        ],
      },
      {
        path: "sso-fail",
        component: SsoFailedComponent,
      },
    ],
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoginGuard],
})
export class LoginRoutingModule {}
