import { InvoicingPeriodResponse } from "@app/statistics/statistics-invoicing/models/invoicing-models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as createInvoiceActions from "./create-invoice.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface CreateInvoiceState {
  loading: boolean;
  storing: boolean;
  deleting: boolean;
  invoicingPeriods: InvoicingPeriodResponse[];
  csv: string;
  csvLoading: boolean;
}

export const initialState: CreateInvoiceState = {
  loading: false,
  storing: false,
  deleting: false,
  invoicingPeriods: [],
  csv: "",
  csvLoading: false,
};

export function createInvoiceReducer(
  state: CreateInvoiceState,
  action: Action
): CreateInvoiceState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(createInvoiceActions.getInvoicingPeriodsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    createInvoiceActions.getInvoicingPeriodsSuccess,
    (state, { invoicingPeriods }) => ({
      ...state,
      loading: false,
      invoicingPeriods,
    })
  ),
  on(createInvoiceActions.getInvoicingPeriodsFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(createInvoiceActions.storeInvoicingPeriodRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(
    createInvoiceActions.storeInvoicingPeriodSuccess,
    createInvoiceActions.storeInvoicingPeriodFailure,
    (state) => ({
      ...state,
      storing: false,
    })
  ),
  on(createInvoiceActions.deleteInvoicingPeriodRequest, (state) => ({
    ...state,
    deleting: true,
  })),
  on(
    createInvoiceActions.deleteInvoicingPeriodSuccess,
    createInvoiceActions.deleteInvoicingPeriodFailure,
    (state) => ({
      ...state,
      deleting: false,
    })
  ),
  on(createInvoiceActions.getInvoicingPeriodsCSVRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(createInvoiceActions.getInvoicingPeriodsCSVSuccess, (state, { csv }) => ({
    ...state,
    csvLoading: false,
    csv,
  })),
  on(createInvoiceActions.getInvoicingPeriodsCSVFailure, (state) => ({
    ...state,
    csvLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.createInvoice
);

export const getLoading = createSelector(
  getState,
  (state: CreateInvoiceState) => state.loading
);

export const getStoring = createSelector(
  getState,
  (state: CreateInvoiceState) => state.storing
);

export const getDeleting = createSelector(
  getState,
  (state: CreateInvoiceState) => state.deleting
);

export const getInvoicingPeriods = createSelector(
  getState,
  (state: CreateInvoiceState) => state.invoicingPeriods
);
