<app-task-default-new *ngIf="task$ | async as task" [task]="task">
  <ng-container card>
    <sidebar-tasks-detail-card [task]="task">
    </sidebar-tasks-detail-card>
    <div class="create-showing" *ngIf="(showCreatePrivateShowing$ | async) && task.object">
      <button translate class="btn-link" (click)="openAddShowingModal()">
        create_showing
      </button>
    </div>
  </ng-container>
</app-task-default-new>


