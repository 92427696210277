import { createAction, props } from "@ngrx/store";
import { TaskTemplate, TypedPaginationListDTO } from "@app/models";

const ACTION_PREFIX = "[Lead templates]";

export const getLeadTemplatesRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATES_REQUEST`,
  props<{ params: Record<string, unknown> }>()
);

export const getLeadTemplatesSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATES_SUCCESS`,
  props<{ data: TypedPaginationListDTO<TaskTemplate> }>()
);

export const getLeadTemplatesFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATES_FAIL`
);

export const changeFilter = createAction(
  `${ACTION_PREFIX} CHANGE_FILTER`,
  props<{ id: string }>()
);
