import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SendPotentialBuyersService } from "@app/integrations/bidding/services/send-potential-buyers.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import {
  getIsOfficeConfigured,
  hasIntegration,
} from "@app/integrations/ngrx/integrations.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import * as features from "@app/shared/config/utils/features";
import {
  CREATE_OBJECT,
  CREATE_PRIVATE_SHOWING,
  EXTERNAL_PROVIDER,
  SEND_TIPS_EIKA,
} from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { getRoles } from "@app/shared/user";
import {
  ROOT_SIDEBAR_ROUTE,
  SIDEBAR_CONTACTS_CREATE_URL,
  SIDEBAR_CREATE_OBJECT_BASE_URL,
  SIDEBAR_EXTERNAL_TIPS_SEARCH_URL,
  SIDEBAR_FOLLOW_UPS_BASE_URL,
  SIDEBAR_SALES_MEETING_BASE_URL,
  SIDEBAR_SEND_MESSAGE_URL,
  SIDEBAR_TIPS_BASE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_LEAD } from "@app/shared/utils/tab-types";
import { AddContactAsPotentialbuyerModalComponent } from "@app/sidebar/shared/add-contact-as-potentialbuyer-modal/add-contact-as-potentialbuyer-modal.component";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { select, Store } from "@ngrx/store";
import { combineLatest, map, Observable, withLatestFrom } from "rxjs";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";

@Component({
  selector: "sidebar-create-menu",
  templateUrl: "create-menu.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "create-menu.component.scss",
  ],
})
export class SidebarCreateMenuComponent implements OnInit {
  showSendTips$: Observable<boolean>;
  showCreateFollowUp$: Observable<boolean>;
  showSalesMeeting$: Observable<boolean>;
  showSendMessage$: Observable<boolean>;
  showCreateLead$: Observable<boolean>;
  showSendTipsEika$: Observable<boolean>;
  showExternalTips$: Observable<boolean>;
  showCreateObject$: Observable<boolean>;
  showCreateShowing$: Observable<boolean>;
  externalProviderName$: Observable<string>;

  SIDEBAR_CONTACTS_CREATE_URL = SIDEBAR_CONTACTS_CREATE_URL;
  SIDEBAR_CREATE_OBJECT_BASE_URL = SIDEBAR_CREATE_OBJECT_BASE_URL;
  SIDEBAR_SEND_MESSAGE_URL = SIDEBAR_SEND_MESSAGE_URL;
  SIDEBAR_TIPS_BASE_URL = SIDEBAR_TIPS_BASE_URL;
  SIDEBAR_FOLLOW_UPS_BASE_URL = SIDEBAR_FOLLOW_UPS_BASE_URL;
  SIDEBAR_SALES_MEETING_BASE_URL = SIDEBAR_SALES_MEETING_BASE_URL;
  ROOT_SIDEBAR_ROUTE = ROOT_SIDEBAR_ROUTE;
  SIDEBAR_EXTERNAL_TIPS_SEARCH_URL = SIDEBAR_EXTERNAL_TIPS_SEARCH_URL;

  constructor(
    private store: Store<AppState>,
    private modalService: QModalService,
    public sendPotentialBuyerService: SendPotentialBuyersService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  createShowing() {
    const data: ShowingModalData = {
      isEditMode: false,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };

    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }

  sendToErpAsPotentialBuyer() {
    this.modalService.show(AddContactAsPotentialbuyerModalComponent, {
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }

  private mapStateToProps() {
    this.showSendTips$ = this.store.pipe(
      select(getFeature(features.SEND_TIPS)),
      map((feature) => feature.enabled)
    );

    this.showCreateFollowUp$ = this.store.pipe(
      select(getFeature(features.CREATE_FOLLOW_UP)),
      map((feature) => feature.enabled)
    );

    this.showSalesMeeting$ = this.store.pipe(
      select(getFeature(features.SALES_MEETING)),
      map((feature) => feature.enabled)
    );

    this.showSendMessage$ = this.store.pipe(
      select(getFeature(features.SEND_MESSAGE)),
      map((feature) => feature.enabled)
    );

    this.showCreateObject$ = combineLatest([
      this.store.pipe(select(fromConfig.getFeature(CREATE_OBJECT))),
      this.store.pipe(
        select(hasIntegration(IntegrationResource.DelegatedObjectCreation))
      ),
    ]).pipe(map(([feature, createObject]) => feature.enabled && createObject));

    this.showSendTipsEika$ = this.store.pipe(
      select(getFeature(SEND_TIPS_EIKA)),
      withLatestFrom(this.store.pipe(select(getIsOfficeConfigured()))),
      map(
        ([feature, isOfficeConfigured]) => feature.enabled && isOfficeConfigured
      )
    );

    this.showExternalTips$ = this.store.pipe(
      select(getFeature(features.EXTERNAL_TIPS)),
      map((feature) => {
        return !!(
          feature.enabled &&
          feature.templateId &&
          feature.taskTypeId &&
          feature.taskResultId
        );
      })
    );

    this.showCreateLead$ = combineLatest([
      this.store.pipe(select(getFeature(CREATE_LEAD))),
      this.store.pipe(select(getRoles)),
    ]).pipe(
      map(([feature, roles]) => {
        if (feature.enabled) {
          return !!roles.find((role) => !!feature?.can_create_lead[role]);
        } else {
          return false;
        }
      })
    );

    this.showCreateShowing$ = combineLatest([
      this.store.pipe(select(fromConfig.getFeature(CREATE_PRIVATE_SHOWING))),
      this.store.select(
        hasIntegration(IntegrationResource.CreatePublicShowing)
      ),
    ]).pipe(
      map(
        ([feature, canCreatePublicShowing]) =>
          feature.enabled || canCreatePublicShowing
      )
    );

    this.externalProviderName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
  }
}
