import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTabs, sidebarIsMinimized } from "../../ngrx/sidebar.reducer";

@Component({
  selector: "sidebar-tabs",
  styleUrls: ["./tabs.component.scss"],
  template: `
    <div
      *ngIf="(tabs$ | async).length > 0"
      class="flex-column tabs-outer-container"
    >
      <div class="flex-column tabs">
        <a
          class="tab"
          *ngFor="let tab of tabs$ | async"
          [routerLink]="['/crm', { outlets: { sidebar: tab.url } }]"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="showSidebar()"
          [class.tab__orange-theme]="tab.type === 'support'"
        >
          <span [class.dirty]="tab.dirty">
            <app-icon [name]="tab.icon"></app-icon>
          </span>
        </a>
      </div>
      <div
        class="flex-column tabs minimize-tabs tabs-toggle hidden-xs hidden-sm"
      >
        <div class="tab minimize-tab" (click)="toggleSidebar()">
          <i class="toggle-icon {{ getToggleIcon$() | async }}"></i>
        </div>
      </div>
    </div>
  `,
})
export class SidebarTabsComponent implements OnInit {
  tabs$: Observable<SidebarTab[]>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.tabs$ = this.store.pipe(select(getTabs));
  }

  getToggleIcon$(): Observable<string> {
    return this.store.pipe(
      select(sidebarIsMinimized),
      map((minimized) =>
        minimized ? "fa fa-chevron-left" : "fa fa-chevron-right"
      )
    );
  }

  toggleSidebar(): void {
    this.store.dispatch(sidebarActions.toggle());
  }

  showSidebar(): void {
    this.store.dispatch(sidebarActions.show());
  }
}
