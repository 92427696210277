import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { SETTINGS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { CrmModule } from "../models/crm-module";
import { SidebarService } from "../sidebar.service";

@Injectable()
export class EmployeesGuard implements CanActivate {
  crmModules = [SETTINGS_CRM_MODULE];

  constructor(private sidebarService: SidebarService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const crmModule = this.crmModules
      .map((module) => new CrmModule(module))
      .find((module) => module.isModuleActive(state.url));

    return this.sidebarService.canActivateTab(
      route,
      state.url,
      crmModule,
      EMPLOYEES
    );
  }
}
