import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Action } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable, Subject, takeUntil } from "rxjs";
import { ContactActionsWidgetService } from "./contact-actions-widget.service";
import { ActionMessage } from "./models/action-message";

@Component({
  selector: "contact-actions-widget",
  templateUrl: "./contact-actions-widget.component.html",
  styleUrls: ["./contact-actions-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ContactActionsWidgetService],
})
export class ContactActionsWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() header: string;
  @Input() contactId: string;
  @Input() reload: string;
  @Input() deleteAction: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDelete = new EventEmitter<string>();
  @Output() noteUpdated = new EventEmitter<string>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onMessage = new EventEmitter<ActionMessage>();

  actions$: Observable<Action[]>;
  actionsLeftToLoad$: Observable<number>;
  loading$: Observable<boolean>;
  isMobile$: Observable<boolean>;

  unsubscribe$ = new Subject<void>();

  constructor(
    private contactActionsWidgetService: ContactActionsWidgetService,
    private store: Store<AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const { contactId, deleteAction, reload } = changes;

    if (contactId && contactId.currentValue) {
      this.contactActionsWidgetService.fetchActions(contactId.currentValue);
    }

    if (reload && !reload.firstChange && reload.currentValue) {
      this.contactActionsWidgetService.reloadActions(this.contactId);
    }

    if (deleteAction && deleteAction.currentValue) {
      this.contactActionsWidgetService.deleteAction(
        this.contactId,
        deleteAction.currentValue
      );
    }
  }

  ngOnInit() {
    this.actions$ = this.contactActionsWidgetService.actions$;
    this.actionsLeftToLoad$ =
      this.contactActionsWidgetService.actionsLeftToLoad$;
    this.loading$ = this.contactActionsWidgetService.loading$;
    this.isMobile$ = this.store.pipe(select(fromShared.isMobile));

    this.contactActionsWidgetService.message$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((message) => this.onMessage.emit(message));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onLoadMore(): void {
    this.contactActionsWidgetService.fetchMoreActions(this.contactId);
  }

  getPaddingTop$(): Observable<string> {
    return this.actions$.pipe(map((a) => (a.length > 0 ? "2rem" : "0")));
  }
}
