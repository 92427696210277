import { Contactable } from "@app/shared/mixins/contactable";
import { Deserializable } from "@app/shared/mixins/deserializable";
import { Nameable } from "@app/shared/mixins/nameable";

import { Bid } from "@app/integrations/bidding/models/bid";
import { contactType, Note } from "@app/models";

export interface AttendedShowing {
  attended: string;
  starttime: string;
  status: string;
  showingId: string;
  eaShowingSlotId?: string;
  registrationStatus: string;
}

interface ObjectRelationship {
  bidStatus: BidStatus | string;
  created: string;
  origin: string;
  actions: Action[];
}

interface Action {
  param3: string;
  type: string;
  timestamp: string;
}

export interface LastContact {
  contactDate: string;
  actionType: string;
  actionCode: string;
  note: string;
  param1: string;
}

export interface PotentialBuyerStatus {
  numberOfUnknown: string | number;
  numberOfAttended: string | number;
  numberOfBidders: string | number;
  numberOfNotInterested: string | number;
}

export class PotentialBuyer {
  firstName: string | null = null;
  objectRelationships: ObjectRelationship[] = [];
  msisdnCountry: string | null = null;
  phoneNumberCountry: string | null = null;
  attendedShowings: AttendedShowing[] | null = null;
  contactId: string | null = null;
  contactType?: contactType = null;
  familyName: string | null = null;
  msisdn: string | null = null;
  phoneNumber: string | null = null;
  email: string | null = null;
  highestBid?: number;
  bids?: Bid[];
  bidStatus?: string;
  bidIsCanceled?: boolean;
  origin?: string;
  notes: Note[];
  registrationStatus?: string;
  eaOids?: string[];
  type?: string;
  lastContacts?: LastContact[];
  showings?: {
    eaShowingId: string;
    eaShowingSlotId?: string;
    registrationStatus: any;
  }[];
  isTransferred?: boolean;
  hasNewsletterConsent?: boolean;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}

export const ContactablePotentialBuyer = Nameable(
  Contactable(Deserializable(PotentialBuyer))
);

export type BidStatus =
  | "interested"
  | "notinterested"
  | "participating"
  | "unknown";

export type AnsweredStatus = "noanswer" | "answer" | "";
