<div class="wrapper">
  <sidebar-header
    [label]="task.eaTaskTypeName"
    [type]="tabType"
  ></sidebar-header>

  <div class="scroll-view">
    <ng-content select="[card]"></ng-content>
    <hr />
    <section>
      <form [formGroup]="form">
        <app-segmented-controls
          [active]="activeControl$.value"
          [controls]="controls"
          (change)="activeControl$.next($event)"
        ></app-segmented-controls>

        <div [class.hidden-section]="activeControl$.value !== 'assign'">
          <div *ngIf="!editAssignee">
            <div [style.margin]="'6px 0'">
              <app-q-card color="1">
                <ng-container slot="title">
                  {{
                    task.eaEmployeeFullName
                      ? task.eaEmployeeFullName
                      : ("free_lead" | translate)
                  }}
                </ng-container>
                <ng-container slot="body">
                  <app-q-card-seconary-text>
                    {{ task?.eaOfficeName }}
                  </app-q-card-seconary-text>
                </ng-container>
              </app-q-card>
            </div>

            <ng-container
              *ngIf="
                (isManagerOrAdmin$ | async) &&
                  (advancedLeadsHandlingFeature$ | async).enabled;
                else oldLeadManagement
              "
            >
              <app-assign-lead-buttons
                [eaEmployeeId]="eaEmployeeId$ | async"
                [selectedEaTaskIds]="[task]"
                (closeHandler)="assignedToMe()"
              >
              </app-assign-lead-buttons>
            </ng-container>
          </div>

          <ng-container
            *ngIf="
              !!editAssignee &&
                !(
                  (isManagerOrAdmin$ | async) &&
                  (advancedLeadsHandlingFeature$ | async).enabled
                );
              then oldEditAssignee
            "
          >
          </ng-container>
        </div>

        <div [class.hidden-section]="activeControl$.value !== 'manage'">
          <app-q-select
            [label]="'result'"
            [options]="taskResultOptions$ | async"
            [inline]="false"
            [formControl]="form.get('taskResult')"
          ></app-q-select>
          <div [style.width]="'100%'" [style.margin-top]="'6px'">
            <app-q-button
              label="save"
              buttonType="save"
              [disabled]="!form.valid"
              (click)="submit()"
            >
            </app-q-button>
          </div>
        </div>
      </form>
    </section>
    <hr />
    <section>
      <app-tasks-default-action-log
        [taskActionLogs]="taskActionLog$ | async"
        [taskType]="task.eaTaskTypeCategory"
      ></app-tasks-default-action-log>
    </section>
  </div>
  <div class="sidebar-footer">
    <task-default-delete-modal
      (deleteClicked)="delete($event)"
      [headerText]="
        task.eaTaskTypeCategory === 'task'
          ? 'remove_task_message'
          : 'remove_lead_message'
      "
      [task]="task"
      class="float-left"
    >
    </task-default-delete-modal>
  </div>
</div>

<ng-template #oldLeadManagement>
  <div>
    <div class="full-width">
      <app-q-button
        buttonType="save"
        label="assign_to_me"
        fullWidth="true"
        (click)="handleLead(null)"
      >
      </app-q-button>
    </div>
    <div class="full-width">
      <app-q-button
        *ngIf="(isManagerOrAdmin$ | async) || (everyoneCanAssign$ | async)"
        buttonType="save"
        label="assign_to_broker"
        fullWidth="true"
        (click)="handleLead('broker')"
      >
      </app-q-button>
    </div>
    <div class="full-width">
      <app-q-button
        buttonType="save"
        label="assign_to_office"
        fullWidth="true"
        (click)="handleLead('office')"
      >
      </app-q-button>
    </div>
  </div>
</ng-template>

<ng-template #oldEditAssignee>
  <div>
    <app-small-back-button
      [mode]="'output'"
      (click)="editAssignee = null"
    ></app-small-back-button>
    <div class="form-group" style="margin-top: 0.5rem">
      <app-advanced-office-employee-dropdown
        [officeRequired]="true"
        [employeeDisabled]="editAssignee === 'office'"
        [officeMultiple]="false"
        [sideBySide]="false"
        [availabilityMode]="'liberal'"
        [setDefaults]="true"
        [employeeMultiple]="false"
        [employeeRequired]="editAssignee === 'broker'"
        [officeFormControl]="form.get('eaOfficeId')"
        [employeeFormControl]="form.get('eaEmployeeId')"
      ></app-advanced-office-employee-dropdown>
    </div>
    <div class="form-group" style="margin-top: 0.5rem">
      <span translate>transfer_comment</span>
      <div style="margin-top: -1rem">
        <app-q-textarea
          placeholder="transfer_comment"
          formControlName="transferComment"
        >
        </app-q-textarea>
      </div>
    </div>
    <mat-checkbox [formControl]="form.get('sendMessage')">
      {{ "send_message_when_lead_is_created" | translate }}
    </mat-checkbox>
    <textarea-counter
      *ngIf="!!form.get('sendMessage').value"
      [parentFormGroup]="form"
      [parentFormControlName]="'message'"
      [minHeight]="'160px'"
    ></textarea-counter>
    <div>
      <app-q-button
        label="assign"
        buttonType="save"
        [disabled]="!form.valid"
        (click)="submit()"
      >
      </app-q-button>
    </div>
  </div>
</ng-template>
