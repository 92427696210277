<ng-container *ngIf="kpi; else noKpi">
  <td translate>total</td>
  <td>{{ kpi.currentData ? (kpi.currentData | separateThousands) : 0 }}</td>
  <td>{{ kpi.lastYearData ? (kpi.lastYearData | separateThousands) : 0 }}</td>
  <td
    [ngClass]="{
      'text-success': kpi.getGrowth() > 0,
      'text-danger': kpi.getGrowth() < 0
    }"
  >
    <ng-container *ngIf="kpi.getGrowth() > 0">+</ng-container>
    {{ kpi.getGrowth() }}%
  </td>
  <td>
    {{ kpi.salesTarget ? (kpi.salesTarget | separateThousands) : 0 || "-" }}
  </td>
  <td [className]="getClassNameByProgress(kpi.getSalesTargetPercent())">
    {{ kpi.salesTarget ? kpi.getSalesTargetPercent() + "%" : "-" }}
  </td>
</ng-container>

<ng-template #noKpi>
  <td class="text-center" colspan="100%" translate>general_error</td>
</ng-template>
