import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import * as actions from "@app/sidebar/create-object/ngrx/create-object.actions";
import { DelegatedObjectRequirements } from "@app/sidebar/create-object/ngrx/create-object.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Injectable()
export class CreateObjectEffects {
  getObjectRequirementsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getObjectRequirementsRequest),
      switchMap(() =>
        this.apiService
          .get("delegated-object-requirements", {}, "integrations")
          .pipe(
            map((response: DelegatedObjectRequirements) =>
              actions.getObjectRequirementsSuccess({ payload: response })
            ),
            catchError(() =>
              observableOf(actions.getObjectRequirementsFailed())
            )
          )
      )
    )
  );

  storeObjectRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.storeObjectRequest),
      switchMap(({ params }) => {
        return this.apiService
          .post("create-object", { ...params.apiParams }, "integrations")
          .pipe(
            map((response: ExternalObjectMetadata) => {
              if (params.createMode === "CREATE_OBJECT_MODE_BRF") {
                const actionLink =
                  response.actionLinks.actions[
                    "CONNECT_TO_HOUSING_ASSOCIATION"
                  ];
                this.pbService.objectCreatedActionLinks$.next(actionLink);
              } else if (
                params.createMode === "CREATE_OBJECT_MODE_LANTMATERI"
              ) {
                const actionLink =
                  response.actionLinks.actions["ORDER_LANTMATERI_UTDRAG"];
                this.pbService.objectCreatedActionLinks$.next(actionLink);
              }
              return actions.storeObjectSuccess({ payload: response });
            }),
            catchError(() => observableOf(actions.storeObjectFailed()))
          );
      })
    )
  );

  storeObjectSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.storeObjectSuccess),
      switchMap(({ payload }) => [
        toastActions.success({
          message: "created_object_successfully",
          duration: 8000,
        }),
        go({ path: ["crm", "showings", payload.internalId, "overview"] }),
      ])
    )
  );

  storeObjectFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.storeObjectFailed),
      map(() => toastActions.danger({ message: "failed_creating_object" }))
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private pbService: PotentialBuyerService
  ) {}
}
