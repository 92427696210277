import { createAction, props } from "@ngrx/store";
import { ShowingType } from "@app/sidebar/potential-buyer/util/showing-types";

interface StoreShowingDTO {
  attendingContacts: string[];
  eaOid: string;
  endTime: string;
  startTime: string;
  showingType: ShowingType;
}

export const storeShowingRequest = createAction(
  "[PotentialBuyer] STORE_SHOWING_REQUEST",
  props<{ showings: StoreShowingDTO }>()
);

export const storeShowingSuccess = createAction(
  "[PotentialBuyer] STORE_SHOWING_SUCCESS",
  props<{ eaOid: string }>()
);

export const storeShowingFail = createAction(
  "[PotentialBuyer] STORE_SHOWING_FAIL"
);
