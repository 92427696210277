import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { ObjectFile, QObject } from "@app/models";
import { fadeInOutTrigger, slideDownStagger } from "@app/shared/animations";
import { validateEmail } from "@app/shared/modules/form-components/directives/email-validator.directive";
import { BRAND_WARNING_COLOR, CONTACTS_COLOR } from "@app/shared/utils/colors";
import * as formUtils from "@app/shared/utils/form-utils";
import { ObjectLink } from "@app/shared/utils/object-utils";
import { capitalizeFirstLetter } from "@app/shared/utils/string-utils";
import { validatePhoneNumber } from "@app/shared/validators/phone-validator";
import { Seller, ShowingObject } from "@app/showings/models";
import { Recipient } from "@app/sidebar/send-message/models/recipient";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import * as libphonenumber from "google-libphonenumber";
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  PartialObserver,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";
import {
  DynamicContent,
  EmailUpdateEvent,
  MobileNumberUpdateEvent,
  Template,
} from "../../models";
import { MessageType } from "../../ngrx/send-message.reducer";
import * as SendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";

@Component({
  selector: "send-message-form",
  templateUrl: "./send-message-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./send-message-form.component.scss",
  ],
  animations: [fadeInOutTrigger(), slideDownStagger()],
})
export class SendMessageFormComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild("editor") editor: any;

  @Input() observer: PartialObserver<any>;
  @Input() countryCode: string;
  @Input() languageCode: string;
  @Input() messageType: MessageType | null; // https://github.com/angular/angular-cli/issues/2034
  @Input() templates: Template[];
  @Input() dynamicContents: DynamicContent[];
  @Input() recipients: Recipient[];
  @Input() emails: string[];
  @Input() mobileNumbers: string[];
  @Input() processing: boolean;
  @Input() errors: any;
  @Input() addToOutbox: boolean;
  @Input() checkConsent: boolean = false;
  @Input() object: ShowingObject & QObject;
  @Input() selectedObjectFiles: ObjectFile[] = [];

  @Output() messageTypeChange = new EventEmitter<MessageType>();
  @Output() templateChange = new EventEmitter<string>();
  @Output() addRecipient = new EventEmitter<Recipient>();
  @Output() removeRecipient = new EventEmitter<Recipient>();
  @Output() addEmail = new EventEmitter<string>();
  @Output() removeEmail = new EventEmitter<string>();
  @Output() removeAllEmails = new EventEmitter<void>();
  @Output() addMobileNumber = new EventEmitter<string>();
  @Output() removeMobileNumber = new EventEmitter<string>();
  @Output() removeAllMobileNumbers = new EventEmitter<void>();
  @Output() updateContactEmail = new EventEmitter<EmailUpdateEvent>();
  @Output() updateContactMobileNumber =
    new EventEmitter<MobileNumberUpdateEvent>();
  @Output() submitForm = new EventEmitter<any>();
  @Output() previewForm = new EventEmitter<any>();
  @Output() removeFile = new EventEmitter<ObjectFile>();

  form: FormGroup;
  submitted = false;
  contactsColor = CONTACTS_COLOR;
  warningColor = BRAND_WARNING_COLOR;
  unsubscribe$ = new Subject<void>();
  showManualInput$ = new BehaviorSubject<boolean>(false);

  showContactInputs: string[] = [];

  receiverForm: FormGroup;
  objectSellers: Seller[] = [];

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private sendMessageService: SendMessageService,
    private phoneUtil: PhoneNumberService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.emitTemplateChangeEvents();
    this.registerObserverToFormValueChanges();
    this.handleObjectLinksAdded();
    this.handleCustomReceiverResponsibleBrokerForm();
    this.buildSellerReceiverControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {
      countryCode,
      recipients,
      messageType,
      processing,
      emails,
      mobileNumbers,
      object,
    } = changes;

    if (countryCode && countryCode.currentValue) {
      this.updateMobileNumberValidators(countryCode.currentValue);
    }

    if (object && this?.object?.contacts?.length > 0) {
      this.objectSellers = this.object.contacts.filter(
        (c) => c.type === "seller"
      );
    }

    if (
      (recipients && recipients.currentValue) ||
      (messageType && messageType.currentValue)
    ) {
      this.setMissingInfoControls();
    }

    if (messageType && messageType.currentValue) {
      this.form.get("template").reset("");
      this.form.setControl("dynamicContents", this.fb.array([]));
    }

    if (processing && processing.currentValue) {
      processing.currentValue ? this.form.disable() : this.form.enable();
    }

    if (
      (recipients && recipients.currentValue) ||
      (emails && emails.currentValue) ||
      (mobileNumbers && mobileNumbers.currentValue)
    ) {
      this.form.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): void {
    this.form = this.fb.group({
      template: ["", [Validators.required]],
      dynamicContents: [this.fb.array([])],
      recipient: [""],
      email: ["", [validateEmail()]],
      mobile: ["", this.getMobileNumberValidators()],
    });
    this.form.addValidators(this.minReceiversValidator());

    this.receiverForm = this.fb.group({
      responsibleBroker: false,
      sellers: this.fb.array([]),
    });
  }

  chooseMessageType(messageType: MessageType): void {
    this.messageTypeChange.emit(messageType);
    this.showManualInput$.next(false);
    this.removeAllEmails.emit();
    this.receiverForm.get("responsibleBroker").setValue(false);
    const numberOfSellers = this.objectSellers?.length;
    if (numberOfSellers) {
      const sellerEmptyArray = [];
      this.objectSellers.forEach(() => sellerEmptyArray.push(false));
      this.receiverForm.get("sellers").setValue(sellerEmptyArray);
    }
  }

  onFileRemove(file): void {
    this.removeFile.emit(file);
  }

  roundFileSize(size: number): string {
    return size.toFixed(1);
  }

  handleObjectLinksAdded() {
    this.sendMessageService.objectLinks$
      .pipe(
        filter((links: ObjectLink[]) => links.length > 0),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((links) => this.addObjectLinks(links));
  }

  addObjectLinks(links: ObjectLink[]) {
    const attribute = this.messageType === "email" ? "name" : "url";
    links.forEach((link) => {
      const format = this.messageType === "email" ? { link: link.url } : {};
      this.editor?.richTextEditor?.insert(
        link[attribute],
        format,
        links.length > 1
      );
    });
    const quill = this.editor?.richTextEditor?.quill?.quillEditor;
    const value =
      this.messageType === "email" ? quill?.root?.innerHTML : quill?.getText();
    const dynamicContents = this.form.get("dynamicContents") as FormGroup;
    dynamicContents.controls[this.messageType === "email" ? 1 : 0].setValue(
      value
    );
  }

  minReceiversValidator(minReceivers = 1): ValidatorFn {
    return () => {
      if (this.messageType === "email") {
        return this.recipients
          .filter((c) => !!c.email)
          .map((c) => c.email)
          .concat(this.emails).length < minReceivers
          ? { minReceivers: true }
          : null;
      } else if (this.messageType === "sms") {
        return this.recipients
          .filter((c) => !!c.msisdn)
          .map((c) => c.msisdn)
          .concat(this.mobileNumbers).length < minReceivers
          ? { minReceivers: true }
          : null;
      } else {
        return null;
      }
    };
  }

  emitTemplateChangeEvents(): void {
    this.form
      .get("template")
      .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.unsubscribe$))
      .subscribe(this.templateChange);
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  updateMobileNumberValidators(country: string): void {
    this.countryCode = country;
    const control = this.form.get("mobile");
    control.setValidators(this.getMobileNumberValidators());
    control.updateValueAndValidity();
  }

  setMissingInfoControls(): void {
    if (this.messageType === "email") {
      this.recipients
        .filter((recipient) => !recipient.hasEmail())
        .forEach((recipient) =>
          this.form.setControl(
            recipient.contactId,
            this.fb.control("", [validateEmail()])
          )
        );
    } else {
      this.recipients
        .filter((recipient) => !recipient.hasMobile())
        .forEach((recipient) =>
          this.form.setControl(
            recipient.contactId,
            this.fb.control("", this.getMobileNumberValidators())
          )
        );
    }
  }

  submit(): void {
    const { template: templateId, dynamicContents } = this.form.value;

    const capitalizedDynamicContent = dynamicContents.map((dc) =>
      capitalizeFirstLetter(dc.trim())
    );
    const submitForm = () =>
      this.submitForm.emit({
        templateId,
        dynamicContents: [...capitalizedDynamicContent],
        addToOutbox: this.addToOutbox,
      });

    this.submitted = true;
    this.markAsTouchedIfInvalid(this.form, false).then(() => {
      submitForm();
    });
  }

  preview(): void {
    const { template: templateId, dynamicContents } = this.form.value;
    this.markAsTouchedIfInvalid(this.form.get("template"), false).then(() =>
      this.previewForm.emit({
        templateId,
        dynamicContents: [...dynamicContents],
      })
    );
  }

  onAddEmail(email: string): void {
    if (email) {
      this.markAsTouchedIfInvalid(this.form.get("email")).then(() =>
        this.addEmail.emit(email)
      );
    }
  }

  onAddMobileNumber(mobileNumber: string): void {
    if (mobileNumber) {
      this.markAsTouchedIfInvalid(this.form.get("mobile")).then(() =>
        this.addMobileNumber.emit(
          this.phoneUtil.toLegacyFormat(mobileNumber, this.countryCode)
        )
      );
    }
  }

  onUpdateRecipientEmail(recipient: Recipient, email: string): void {
    this.markAsTouchedIfInvalid(this.form.get(recipient.contactId)).then(() =>
      this.updateContactEmail.emit({ recipient, email })
    );
  }

  onUpdateRecipientMobileNumber(
    recipient: Recipient,
    mobileNumber: string
  ): void {
    this.markAsTouchedIfInvalid(this.form.get(recipient.contactId)).then(() =>
      this.updateContactMobileNumber.emit({ recipient, mobileNumber })
    );
  }

  markAsTouchedIfInvalid(
    control: AbstractControl,
    reset = true
  ): Promise<void> {
    return new Promise<void>((resolve) => {
      if (control.valid) {
        if (reset) {
          control.reset("");
        }
        resolve();
      } else if (control instanceof FormGroup) {
        formUtils.markAllAsTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  getMobileNumberValidators(): ValidatorFn[] {
    return [
      validatePhoneNumber(this.countryCode, {
        type: libphonenumber.PhoneNumberType.MOBILE,
      }),
    ];
  }

  isInShowingsModule(): boolean {
    const pattern = "crm/\\(showings/";
    const result = location.href.match(pattern);
    return !!(result && result[0]);
  }

  shouldShowResponsibleEmployeeAsReceiver(object: ShowingObject): boolean {
    const isShowingsModule = this.isInShowingsModule();
    const responsibleEmployee = object?.employees?.find(
      (e) => e.role === "responsible"
    );
    const hasEmail = !!responsibleEmployee?.email;
    const hasMobile = !!responsibleEmployee?.mobile;

    if (this.messageType === "email") {
      return isShowingsModule && hasEmail;
    } else {
      return isShowingsModule && hasMobile;
    }
  }

  shouldShowSellerAsReceiver(index: number): boolean {
    const isShowingsModule = this.isInShowingsModule();
    const hasEmail = !!this.objectSellers[index]?.email;
    const hasMobile = !!this.objectSellers[index]?.msisdn;

    if (this.messageType === "email") {
      return isShowingsModule && hasEmail;
    } else {
      return isShowingsModule && hasMobile;
    }
  }

  trackByFunction(_index: number, item: Recipient): string {
    return item.contactId;
  }

  toggleInput(val: string) {
    const toggleElement = (array, change) =>
      array.includes(change)
        ? array.splice(array.indexOf(change[0]), change.length)
        : array.push(change);

    toggleElement(this.showContactInputs, val);
  }

  hasMessageType(recipient: Recipient): boolean {
    return (
      (this.messageType === "email" && recipient.hasEmail()) ||
      (this.messageType === "sms" && recipient.hasMobile())
    );
  }

  recipientsWithoutMessageType(recipients: Recipient[]) {
    return recipients.filter((recipient) => !this.hasMessageType(recipient));
  }

  handleCustomReceiverResponsibleBrokerForm(): void {
    this.receiverForm
      .get("responsibleBroker")
      .valueChanges.subscribe((shouldAdd) => {
        if (!!this.object) {
          const responsibleEmployee = this.object.employees.find(
            (e) => e.role === "responsible"
          );
          if (this.messageType === "email") {
            if (shouldAdd) {
              this.onAddEmail(responsibleEmployee.email);
            } else {
              this.removeEmail.emit(responsibleEmployee.email);
            }
          } else {
            if (shouldAdd) {
              this.onAddMobileNumber(responsibleEmployee.mobile);
            } else {
              this.removeMobileNumber.emit(responsibleEmployee.mobile);
            }
          }
        }
      });
  }

  handleCustomReceiverSellerForm(): void {
    this.receiverForm
      .get("sellers")
      .valueChanges.subscribe((sellersFormArray: boolean[]) => {
        if (this.messageType === "email") {
          sellersFormArray.forEach((shouldAdd, index) => {
            this.removeEmail.emit(this.objectSellers[index]?.email);
            if (shouldAdd) {
              this.onAddEmail(this.objectSellers[index].email);
            }
          });
        } else {
          sellersFormArray.forEach((shouldAdd, index) => {
            this.removeMobileNumber.emit(this.objectSellers[index]?.msisdn);
            if (shouldAdd) {
              this.onAddMobileNumber(this.objectSellers[index].msisdn);
            }
          });
        }
      });
  }

  onRemoveEmail(email: string): void {
    if (email === this.object.responsibleEmployee.employeeEmail) {
      this.receiverForm.get("responsibleBroker").setValue(false);
    } else {
      let isSellerEmail = false;
      const formArrayValues = this.receiverForm.get("sellers").value;
      this.objectSellers.forEach((seller, index) => {
        if (seller.email === email) {
          isSellerEmail = true;
          formArrayValues[index] = false;
          this.receiverForm.get("sellers").setValue(formArrayValues);
        }
      });
      if (!isSellerEmail) {
        this.removeEmail.emit(email);
      }
    }
  }

  onRemoveMobileNumber(number: string): void {
    if (number === this.object.responsibleEmployee.employeeMobile) {
      this.receiverForm.get("responsibleBroker").setValue(false);
    } else {
      let isSellerMobile = false;
      const formArrayValues = this.receiverForm.get("sellers").value;
      this.objectSellers.forEach((seller, index) => {
        if (seller.msisdn === number) {
          isSellerMobile = true;
          formArrayValues[index] = false;
          this.receiverForm.get("sellers").setValue(formArrayValues);
        }
      });
      if (!isSellerMobile) {
        this.removeEmail.emit(number);
      }
    }
  }

  get sellerReceivers(): FormArray {
    return this.receiverForm.get("sellers") as FormArray;
  }

  buildSellerReceiverControls(): void {
    if (this.objectSellers?.length > 0) {
      this.objectSellers.forEach(() => {
        this.sellerReceivers.push(new FormControl(false));
      });
      this.handleCustomReceiverSellerForm();
    }
  }

  removeRecipientsWithoutConsents() {
    this.recipients = this.recipients.filter(
      (recipient) => recipient.hasNewsletterConsent
    );

    this.store.dispatch(
      SendMessageActions.replaceContacts({ recipients: this.recipients })
    );
  }
}
