import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import * as fromKpi from "../ngrx/kpi.reducer";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  combineLatest,
  delay,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from "rxjs";
import * as KPI_MODAL_TYPES from "@app/kpi/utils/kpi-modal-types";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { ROLE_ADMIN, ROLE_BROKER, ROLE_MANAGER } from "@app/shared/utils/roles";
import * as KPI_TYPES from "@app/kpi/utils/kpi-types";
import * as kpiActions from "@app/kpi/ngrx/kpi.actions";
import { KpiUtilsService } from "@app/kpi/services/kpi-utils.service";

@Component({
  selector: "kpi-external",
  templateUrl: "./kpi-external.component.html",
  styleUrls: ["./kpi-external.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiExternalComponent implements OnInit, OnDestroy {
  dateFormat = "YYYYMM";
  fromTo = {
    from: moment().format(this.dateFormat),
    to: moment().format(this.dateFormat),
  };
  monthTitle: string;
  queryParams: Record<string, any> = {};
  canChangeDate: boolean = true;
  months: number = 0;
  currentLevel: "chain" | "office" | "employee" = "employee";

  title$: Observable<string>;
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  isBroker$: Observable<boolean>;
  userIds$: Observable<UserIds>;
  hasAccessToUpperLevel$ = new BehaviorSubject<boolean>(false);
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private kpiUtils: KpiUtilsService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.queryParams = { ...this.route.snapshot.queryParams };
    this.mapStateToProps();
    this.handleRouteChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadPreviousMonth() {
    this.fromTo = {
      from: moment(this.fromTo.from, this.dateFormat)
        .subtract(1, "month")
        .format(this.dateFormat),
      to: moment(this.fromTo.to, this.dateFormat)
        .subtract(1, "month")
        .format(this.dateFormat),
    };
    this.navigateToNewMonth();
  }

  loadNextMonth() {
    this.fromTo = {
      from: moment(this.fromTo.from, this.dateFormat)
        .add(1, "month")
        .format(this.dateFormat),
      to: moment(this.fromTo.to, this.dateFormat)
        .add(1, "month")
        .format(this.dateFormat),
    };
    this.navigateToNewMonth();
  }

  navigateToNewMonth() {
    let fromTo: Record<any, unknown> = { ...this.fromTo };

    if (this.queryParams.type === "nps") {
      fromTo = {
        minDate: moment(this.fromTo.from, "YYYYMM")
          .startOf("month")
          .format(API_DATE_FORMAT),
        maxDate: moment(this.fromTo.to, "YYYYMM")
          .endOf("month")
          .format(API_DATE_FORMAT),
      };
    }

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams,
        ...fromTo,
      },
    });

    this.setMonthTitle();
  }

  gotUpperLevel() {
    switch (this.currentLevel) {
      case "employee":
        this.showOfficeLevel();
        return;
      case "office":
        this.showChainLevel();
        return;
      default:
        return;
    }
  }

  showOfficeLevel() {
    const queryParams = { ...this.queryParams };
    delete queryParams?.employees;

    this.router.navigate([], {
      queryParams,
      replaceUrl: true,
    });
  }

  showChainLevel() {
    const queryParams = { ...this.queryParams };
    delete queryParams?.offices;
    queryParams.chain = true;

    this.router.navigate([], {
      queryParams,
      replaceUrl: true,
    });
  }

  private mapStateToProps(): void {
    this.title$ = this.store.pipe(select(fromKpi.getTitle));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.isBroker$ = this.store.pipe(select(fromUser.hasRole(ROLE_BROKER)));
    this.userIds$ = this.store.pipe(fromUser.getEaIds);
  }

  private handleRouteChanges() {
    this.route.queryParams
      .pipe(
        tap((params) => {
          this.updateVariables(params);
          this.fetchData(params);
        }),
        switchMap((params) => this.setTitle(params)),
        delay(200),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((params) => {
        this.kpiUtils.setModalTitle(params);
        this.setMonthTitle();
        this.hasAccessToUpperLevel();
      });
  }

  private setMonthTitle() {
    if (this.months > 1) {
      this.monthTitle =
        `${this.months} ` + this.translate.instant("months_back");
      this.canChangeDate = false;
    } else {
      this.monthTitle = moment(this.fromTo.from, this.dateFormat).format(
        "MMMM"
      );
    }
    this.cdr.detectChanges();
  }

  private setTitle(queryParams: Record<string, unknown>) {
    return combineLatest([
      this.store.pipe(select(fromKpi.getEmployee)),
      this.store.pipe(
        select(fromShared.getOffice(queryParams?.offices?.toString()))
      ),
    ]).pipe(
      map(([employee, office]) => {
        const params: Record<string, unknown> = {
          type: queryParams.type,
          month: queryParams.from,
          modalType: KPI_MODAL_TYPES.CHAIN,
        };

        if (queryParams?.chain) {
          return params;
        }

        if (queryParams?.employees) {
          return {
            ...params,
            modalType: KPI_MODAL_TYPES.EMPLOYEE,
            name: employee?.fullName || "",
          };
        }

        let name = "";

        if (!queryParams?.offices.toString().includes(",") && !!office) {
          name = office.name;
        }

        return {
          ...params,
          name,
          modalType: KPI_MODAL_TYPES.OFFICE,
        };
      })
    );
  }

  private fetchData(queryParams: Record<string, unknown>) {
    const { type, employees, offices, chain, ...query } = queryParams;
    const queryParamsToUse = { ...query };

    if (!!employees) {
      this.store.dispatch(
        kpiActions.fetchEmployeeRequest({
          id: employees.toString(),
        })
      );
    }

    let useEmployeeNotDetail = false;
    if (!!queryParamsToUse["use"]) {
      if (!employees) {
        useEmployeeNotDetail = true;
      }

      delete queryParamsToUse?.use;
    }

    const userIds = {
      eaOfficeId: offices ? offices.toString() : "",
      eaEmployeeId: employees ? employees.toString() : "",
    };

    if (type === KPI_TYPES.NPS) {
      this.store.dispatch(
        kpiActions.fetchNpsSurveyResponsesRequest({
          survery: {
            type,
            userIds,
            searchParams: { ...queryParamsToUse, limit: 1000 },
          },
        })
      );
    } else {
      if (!employees) {
        this.store.dispatch(
          kpiActions.fetchKpiSummaryRequest({
            parameters: {
              type: type.toString(),
              userIds,
              searchParams: queryParamsToUse,
            },
          })
        );
      }

      this.store.dispatch(
        kpiActions.fetchKpiDetailsRequest({
          parameters: {
            type: type.toString(),
            userIds,
            searchParams: queryParamsToUse,
            useEmployeeNotDetail,
          },
        })
      );
    }
  }

  private updateVariables(params: Record<string, string>) {
    this.queryParams = { ...params };

    if (params?.chain) {
      this.currentLevel = "chain";
    } else if (params?.employees) {
      this.currentLevel = "employee";
    } else {
      this.currentLevel = "office";
    }

    let from: moment.Moment;
    let to: moment.Moment;
    if (this.queryParams.type === "nps") {
      from = moment(params.minDate, API_DATE_FORMAT);
      to = moment(params.maxDate, API_DATE_FORMAT);
      this.fromTo = {
        from: from.format(this.dateFormat),
        to: to.format(this.dateFormat),
      };
    } else {
      from = moment(params.from);
      to = moment(params.to);
      this.fromTo = {
        from: from.format(this.dateFormat),
        to: to.format(this.dateFormat),
      };
    }
    this.months = to.diff(from, "months");
  }

  private hasAccessToUpperLevel() {
    combineLatest([this.isBroker$, this.isAdmin$])
      .pipe(
        map(([isBroker, isAdmin]) => {
          if (
            this.queryParams.type === "nps" ||
            this.queryParams?.chain ||
            isBroker
          ) {
            return false;
          }

          if (this.currentLevel === "office" && !isAdmin) {
            return false;
          }

          return true;
        }),
        first()
      )
      .subscribe((enabled) => this.hasAccessToUpperLevel$.next(enabled));
  }
}
