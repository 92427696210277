import { Injectable } from "@angular/core";
import { catchError, map, of, switchMap, takeUntil } from "rxjs";
import { TypedPaginationListDTO } from "@app/models";
import { ApiService } from "@app/core/services/api/api.service";
import { Team } from "@app/settings/offices-employees/manage-teams/models/Team";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";

type TeamsResponse = TypedPaginationListDTO<Team>;

@Injectable({
  providedIn: "root",
})
export class TeamsService extends CustomEntityCollectionServiceBase<Team> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private readonly apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Team", serviceElementsFactory);
  }

  getById = (id: string) => {
    return this.apiService.get(`teams/${id}`, {}, "api").pipe(
      map((response: Team) => {
        this.upsertOneInCache(response);
        return response;
      }),
      catchError(() => {
        this.appStore.dispatch(
          toastActions.danger({ message: "team_load_failed" })
        );
        return of(null);
      })
    );
  };

  getTeams(
    officeIds: string[],
    additionalParams?: {
      sort?: {
        field: string;
        order: string;
      };
      pagination?: {
        offset: number;
        limit: number;
      };
    },
    setListDefaults = true
  ) {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }

    const filter: Record<string, unknown> = {
      pagination: {
        limit: 25,
        offset: 0,
      },
      ...additionalParams,
    };

    if (officeIds && officeIds.length > 0) {
      filter.nestedFilters = [
        {
          key: "belongsToOffice",
          filters: [
            {
              field: "id",
              operator: "anyOf",
              value: officeIds,
            },
          ],
        },
      ];
    }

    return this.apiService.post("teams/search", filter, "api").pipe(
      takeUntil(this.cancelExistingRequest$),
      switchMap((response: TeamsResponse) => {
        this.setLoading(false);
        const teams = response?.rows;
        this.upsertManyInCache(teams);
        if (setListDefaults) {
          this.setListDefaults(teams, response);
        }
        return this.getListFromEntities(teams);
      }),
      catchError((err) => {
        this.fetchErrorHandler(setListDefaults);
        return err;
      })
    );
  }

  post(entity: Record<string, unknown>, showToast = true) {
    this.setLoading(true);
    return this.apiService
      .post(
        `teams`,
        {
          ...entity,
          origin: "CRM",
        },
        "api"
      )
      .pipe(
        map(() => {
          this.setLoading(false);
          this.refreshFetch$.next();
          if (showToast) {
            this.appStore.dispatch(
              toastActions.success({
                message: "team_saved",
              })
            );
          }
          return true;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "team_create_failed" })
          );
          return err;
        })
      );
  }

  patch(id: string, entity: Partial<Team>) {
    this.setLoading(true);
    return this.apiService
      .patch(
        `teams/${id}`,
        {
          ...entity,
        },
        "api"
      )
      .pipe(
        map(() => {
          this.setLoading(false);
          this.upsertOneInCache({ id, ...entity });
          this.refreshFetch$.next();
          this.appStore.dispatch(
            toastActions.success({ message: "team_updated" })
          );
          return true;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "team_update_failed" })
          );
          return err;
        })
      );
  }

  addAgentToTeam(id: string, agentId: string) {
    this.setLoading(true);
    return this.apiService
      .patch(`teams/${id}/agents/${agentId}`, {}, "api")
      .pipe(
        map(() => {
          this.setLoading(false);
          this.refreshFetch$.next();
          return true;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "team_update_failed" })
          );
          return err;
        })
      );
  }

  removeAgentFromTeam(id: string, agentId: string) {
    this.setLoading(true);
    return this.apiService.delete(`teams/${id}/agents/${agentId}`).pipe(
      map(() => {
        this.setLoading(false);
        this.refreshFetch$.next();
        return true;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({
            message: "remove_agent_form_team_failed",
          })
        );
        return err;
      })
    );
  }

  deleteTeam(id: string) {
    this.setLoading(true);
    return this.apiService.delete(`teams/${id}`).pipe(
      map(() => {
        this.setLoading(false);
        this.removeOneFromCache(id);
        this.refreshFetch$.next();
        return true;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({
            message: "remove_agent_form_team_failed",
          })
        );
        return err;
      })
    );
  }

  clearData(setListDefaults: boolean = true) {
    this.setLoading(false);
    this.upsertManyInCache([]);
    if (setListDefaults) {
      this.setListDefaults([], null);
    }
  }

  private fetchErrorHandler(
    setListDefaults: boolean = true,
    message: string = "team_load_failed"
  ) {
    this.clearData(setListDefaults);
    this.appStore.dispatch(toastActions.danger({ message: message }));
  }
}
