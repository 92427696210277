import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { CrmToken } from "@app/models";
import * as userActions from "@app/shared/user/user.actions";
import { Role } from "@app/shared/utils/roles";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Store } from "@ngrx/store";

@Injectable()
export class AuthService {
  jwtHelper = new JwtHelperService();

  constructor(private store: Store<AppState>) {}

  setToken(token: string): void {
    localStorage.setItem("token", token);
  }

  getToken(): string | null {
    return localStorage.getItem("token");
  }

  hasRole(role: Role): boolean {
    try {
      const token: CrmToken = this.jwtHelper.decodeToken(this.getToken());
      return token.roles.some((r) => r === role);
    } catch (Exception) {
      return false;
    }
  }

  getRoles(): Role[] {
    try {
      const token: CrmToken = this.jwtHelper.decodeToken(this.getToken());
      return token.roles;
    } catch (Exception) {
      return [];
    }
  }

  getIds(): { eaEmployeeId: string; eaOfficeId: string } {
    try {
      const { eaEmployeeId, eaOfficeId } = this.jwtHelper.decodeToken(
        this.getToken()
      );
      return { eaEmployeeId, eaOfficeId };
    } catch (Exception) {
      return null;
    }
  }

  isSuerAdminPropsEnabled() {
    const token: CrmToken = this.jwtHelper.decodeToken(this.getToken());
    if (token?.sap) {
      return token.sap.enabled;
    }

    return false;
  }

  isTokenExpired(): boolean {
    try {
      return this.jwtHelper.isTokenExpired(this.getToken());
    } catch (Exception) {
      return true;
    }
  }

  isAuthenticated(): boolean {
    try {
      if (!this.isTokenExpired()) {
        if (!!this.shouldRefreshToken()) {
          this.refreshToken();
        }
        const { eaEmployeeId, eaOfficeId } = this.jwtHelper.decodeToken(
          this.getToken()
        );
        return !!(eaEmployeeId && eaOfficeId);
      }
      return false;
    } catch (e) {
      return false;
    }
  }

  shouldRefreshToken(): boolean {
    const token = this.getToken();
    const decodedToken = this.jwtHelper.decodeToken(token);
    const created = new Date(0);
    created.setUTCSeconds(decodedToken.iat);
    const expirationDate = this.jwtHelper.getTokenExpirationDate(
      this.getToken()
    );
    const today = new Date();
    // @ts-ignore
    const q = Math.abs(today - created);
    // @ts-ignore
    const d = Math.abs(expirationDate - created);
    const percentage = Math.round((q / d) * 100);
    return percentage > 70;
  }

  refreshToken(): void {
    this.store.dispatch(userActions.refreshToken());
  }

  isViewingAsSomeoneElse(): boolean {
    try {
      const token: CrmToken = this.jwtHelper.decodeToken(this.getToken());
      return !!token.originatingEmployeeId && !!token.originatingOfficeId;
    } catch (Exception) {
      return false;
    }
  }

  loadUserData(): void {
    try {
      const { eaEmployeeId, eaOfficeId, roles, origin } =
        this.jwtHelper.decodeToken(this.getToken());
      if (eaEmployeeId && eaOfficeId) {
        this.store.dispatch(
          userActions.loadRequest({ eaEmployeeId, eaOfficeId })
        );
      }

      if (roles) {
        this.store.dispatch(userActions.setRoles(roles));
      }

      if (origin) {
        this.store.dispatch(
          userActions.detectClientSuccess({ clientName: origin })
        );
      }
    } catch (Exception) {}
  }
}
