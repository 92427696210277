import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as apiUrls from "@app/core/services/api/utils/api-endpoints";
import * as consentTypes from "@app/shared/utils/consent-types";
import {
  EXTERNAL_TIPS_CREATE,
  EXTERNAL_TIPS_UPDATE,
  SEND_EXTERNAL_TIPS,
} from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  forkJoin as observableForkJoin,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";
import { closeTab } from "../../ngrx/sidebar.actions";
import * as taskActions from "../../tasks/ngrx/tasks.actions";
import * as externalTipsActions from "./external-tips.actions";

@Injectable()
export class ExternalTipsEffects {
  sendExternalTipRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.sendExternalTipRequest),
      switchMap(({ taskBody, params, addToOutbox }) => {
        const requests = [
          this.apiService.patch(`contacts/${taskBody.contactId}`, {
            consent: {
              activeConsentTypeAlias: consentTypes.ACCEPT_EXTERNAL_INFO,
              consentSourceType: "user",
              consentSourceId: taskBody.originEaEmployeeId,
              consentNote:
                this.translate.instant("accepts_contact_from") +
                taskBody.param1,
            },
          }),
          this.apiService.post(
            addToOutbox
              ? `${apiUrls.MESSAGES_EMAIL}?sendEmailThroughBrokerEmail=true`
              : apiUrls.MESSAGES_EMAIL,
            params
          ),
        ];
        return observableForkJoin(requests).pipe(
          map(() => externalTipsActions.sendExternalTipSuccess()),
          catchError(() =>
            observableOf(externalTipsActions.sendExternalTipFailed())
          )
        );
      })
    )
  );

  sendExternalTipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.sendExternalTipSuccess),
      map(() => toastActions.success({ message: "send_tips_success" }))
    )
  );

  sendExternalTipFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.sendExternalTipFailed),
      map(() => toastActions.danger({ message: "send_tips_failed" }))
    )
  );

  createTaskRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.sendExternalTipRequest),
      map(({ taskBody }) => taskActions.createTaskRequest({ params: taskBody }))
    )
  );

  closeSendTipsSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.sendExternalTipSuccess),
      map(() => closeTab({ tabType: SEND_EXTERNAL_TIPS }))
    )
  );

  getExternalTip$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.getExternalTipRequest),
      switchMap(({ id }) =>
        this.apiService.get(apiUrls.EXTERNAL_TIPS.TIP(id)).pipe(
          map((response: any) =>
            externalTipsActions.getExternalTipSuccess({ externalTip: response })
          ),
          catchError(() =>
            observableOf(externalTipsActions.getExternalTipFailed())
          )
        )
      )
    )
  );

  updateExternalTipRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.updateExternalTipRequest),
      switchMap(({ id, data }) =>
        this.apiService.patch(apiUrls.EXTERNAL_TIPS.TIP(id), data).pipe(
          map(() => externalTipsActions.updateExternalTipSuccess()),
          catchError(() =>
            observableOf(externalTipsActions.updateExternalTipFailed())
          )
        )
      )
    )
  );

  updateExternalTipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.updateExternalTipSuccess),
      map(() => toastActions.success({ message: "edit_external_tips_success" }))
    )
  );

  updateExternalTipFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.updateExternalTipFailed),
      map(() => toastActions.danger({ message: "edit_external_tips_failed" }))
    )
  );

  closeEditTipsSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.updateExternalTipSuccess),
      map(() => closeTab({ tabType: EXTERNAL_TIPS_UPDATE }))
    )
  );

  createExternalTipRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.createExternalTipRequest),
      switchMap(({ externalTip }) =>
        this.apiService.post(apiUrls.EXTERNAL_TIPS.TIPS, externalTip).pipe(
          map(() => externalTipsActions.createExternalTipSuccess()),
          catchError(() =>
            observableOf(externalTipsActions.createExternalTipFailed())
          )
        )
      )
    )
  );

  createExternalTipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.createExternalTipSuccess),
      map(() =>
        toastActions.success({ message: "create_external_tips_success" })
      )
    )
  );

  createExternalTipFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.createExternalTipFailed),
      map(() => toastActions.danger({ message: "create_external_tips_failed" }))
    )
  );

  closeCreateTipsSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.createExternalTipSuccess),
      map(() => closeTab({ tabType: EXTERNAL_TIPS_CREATE }))
    )
  );

  getExternalTips$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalTipsActions.getExternalTipsRequest),
      switchMap(({ eaOfficeId }) =>
        this.apiService
          .get(apiUrls.EXTERNAL_TIPS.TIPS, { eaOfficeId, limit: 500 })
          .pipe(
            map((response: any) =>
              externalTipsActions.getExternalTipsSuccess({
                externalTips: response.rows,
              })
            ),
            catchError(() =>
              observableOf(externalTipsActions.getExternalTipsFailed())
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translate: TranslateService
  ) {}
}
