import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import {
  Pages,
  PredefinedFilters,
  ShortcutLink,
} from "@app/shared/config/models/shortcuts-feature";
import { select, Store } from "@ngrx/store";
import * as fromShortcutsSetting from "@app/widgets/widgets/shortcuts-widget/ngrx/shortcuts-widget.reducer";
import { AppState } from "@app/app.reducer";

@Injectable({ providedIn: "root" })
export class ShortcutsService {
  shortcuts$: Observable<ShortcutLink[]>;
  filters$: Observable<PredefinedFilters[]>;

  constructor(private store: Store<AppState>) {
    this.mapStateToProps();
  }

  getFilter$(id: string): Observable<PredefinedFilters> {
    return this.filters$.pipe(
      map((filters) => filters.find((filter) => filter.tempId === id))
    );
  }

  getFilters$(page: Pages): Observable<PredefinedFilters[]> {
    return this.filters$.pipe(
      map((filters) => filters.filter((filter) => filter.page === page))
    );
  }

  private mapStateToProps() {
    this.shortcuts$ = this.store.pipe(
      select(fromShortcutsSetting.getPredefinedShortcuts)
    );
    this.filters$ = this.store.pipe(
      select(fromShortcutsSetting.getPredefinedFilter)
    );
  }
}
