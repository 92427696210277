import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import moment from "moment";
import { BsDatepickerViewMode } from "ngx-bootstrap/datepicker";
import { Subject, takeUntil } from "rxjs";

@Component({
  selector: "form-input-datepicker-dropdown",
  templateUrl: "./datepicker-dropdown.component.html",
  styleUrls: ["./datepicker-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerDropdownComponent implements OnInit, OnDestroy {
  @Input() group: FormGroup;
  @Input() controlName = "date";
  @Input() placeholder = moment(new Date())
    .localeData()
    .longDateFormat("L")
    .toUpperCase();
  @Input() align: "align-left" | "align-right";
  @Input() enableReset = true;
  @Input() minDate;
  @Input() minMode: BsDatepickerViewMode = "day";
  @Input() dateFormat;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBlur: EventEmitter<void> = new EventEmitter<void>();

  unsubscribe$: Subject<void> = new Subject<void>();
  bsConfig: {};

  ngOnInit(): void {
    this.bsConfig = {
      adaptivePosition: true,
      containerClass: "theme-quedro",
      minMode: this.minMode,
      isAnimated: true,
      customTodayClass: "datepicker-custom-today-class",
    };

    this.group
      .get(this.controlName)
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onBlur.emit());

    if (this.dateFormat) {
      this.bsConfig["dateInputFormat"] = this.dateFormat;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  constructor() {
    this.group = new FormGroup({ date: new FormControl() });
  }
}
