<div class="circle-container">
  <svg
    [attr.height]="radius * 2"
    [attr.width]="radius * 2"
  >
    <circle
      [attr.stroke]="strokeColor"
      [attr.fill]="'transparent'"
      [attr.stroke-dasharray]="circumference + ' ' + circumference"
      [attr.stroke-width]="stroke"
      [attr.r]="normalizedRadius"
      [attr.stroke-dashoffset]="setProgress()"
      [attr.cx]="radius"
      [attr.cy]="radius"
    >
    </circle>
  </svg>

  <div class="circle-container__content" [style.height]="radius * 2 + 'px'" [style.width]="radius * 2 + 'px'">
    <ng-content></ng-content>
  </div>
</div>
