<form [formGroup]="form">
  <section>
    <h4 translate>company_info</h4>

    <div class="alert alert-info" translate>
      validation_create_company_external
    </div>

    <div>
      <search-contact
        [placeholder]="'org_number' | translate"
        [group]="form"
        [controlName]="'contactOrgNumber'"
        [waitForRequiredChar]="false"
        [contactType]="'company'"
        [hideEmptyResults]="true"
        [extended]="true"
        [showQuedroSearchResult]="false"
        (quedroClicked)="(null)"
        (eniroClicked)="handleEniroClicked($event)"
      ></search-contact>
      <div ngxErrors="contactOrgNumber">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div>
      <search-contact
        [placeholder]="'company_name' | translate"
        [group]="form"
        [controlName]="'firstName'"
        [waitForRequiredChar]="false"
        [contactType]="'company'"
        [hideEmptyResults]="true"
        [extended]="true"
        [showQuedroSearchResult]="false"
        (quedroClicked)="(null)"
        (eniroClicked)="handleEniroClicked($event)"
        [class.validation-input]="
          form.get('firstName').invalid && form.get('firstName').touched
        "
      ></search-contact>
      <div ngxErrors="firstName">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-contact
        [placeholder]="'email' | translate"
        [group]="form"
        [controlName]="'email'"
        [waitForRequiredChar]="false"
        [requiredChars]="['@']"
        [hideEmptyResults]="true"
        [showQuedroSearchResult]="false"
        (quedroClicked)="(null)"
      ></search-contact>
      <div ngxErrors="email">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-contact
        [countryCode]="selectedCountryCode$ | async"
        [filter]="'number'"
        [minLength]="3"
        [placeholder]="'phone' | translate"
        [group]="form"
        [controlName]="'msisdn'"
        [contactType]="'company'"
        [extended]="true"
        [hideEmptyResults]="true"
        [showQuedroSearchResult]="false"
        (onCountryChange)="handleCountryCodeChange($event.regionCode)"
        (eniroClicked)="handleEniroClicked($event)"
        (quedroClicked)="(null)"
        (quedroResult)="(null)"
      ></search-contact>
      <div ngxErrors="msisdn">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-address-adv
        type="street"
        [containerFormControl]="form.get('street')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'street' | translate"
        [invalid]="form.get('street').invalid"
        [showFullAddress]="false"
        (onSelected)="handleAddressSelected($event)"
      ></search-address-adv>
      <div ngxErrors="street">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-address-adv
        type="zip"
        [containerFormControl]="form.get('zip')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'zip' | translate"
        [invalid]="form.get('zip').invalid"
        [showFullAddress]="false"
        (onSelected)="handleZipSelected($event)"
      ></search-address-adv>
      <div ngxErrors="zip">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-address-adv
        type="city"
        [containerFormControl]="form.get('city')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'city' | translate"
        [invalid]="form.get('city').invalid"
        [showFullAddress]="false"
      ></search-address-adv>
      <div ngxErrors="city">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
</form>
