import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO, PaginationListDTO } from "@app/models";
import { Store } from "@ngrx/store";
import { catchError, first, map, Observable, of as observableOf } from "rxjs";

@Injectable()
export class SearchEmployeeService {
  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  suggest(keyword: string): Observable<EmployeeDTO[]> {
    return this.apiService.get("employees/searchFreeText", { keyword }).pipe(
      first(),
      map((response: PaginationListDTO) =>
        response.rows.map((e) => new EmployeeDTO(e))
      ),
      catchError((error: Error) => {
        this.store.dispatch(toastActions.danger({ message: error.message }));
        return observableOf([]);
      })
    );
  }
}
