import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { QObject } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import {
  CREATE_OBJECT_VIDEO,
  DOWNLOAD_OBJECT_IMAGES,
} from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { ShowingObject } from "@app/showings/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { ObjectVideoModalComponent } from "@app/widgets/widgets/object-widgets/object-image-carousel-widget/object-video-modal/object-video-modal.component";
import { select, Store } from "@ngrx/store";
import { Lightbox } from "ngx-lightbox";
import { filter, first, map, Observable, Subject, takeUntil } from "rxjs";
import { DownloadFileService } from "@app/shared/modules/ui-components/download-file.service";

@Component({
  selector: "app-object-image-carousel-widget",
  templateUrl: "./object-image-carousel-widget.component.html",
  styleUrls: [
    "./object-image-carousel-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectImageCarouselWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() eaOid: string;
  showingObject$: Observable<ShowingObject>;
  object$: Observable<QObject>;
  images$: Observable<{ url: string }[]>;
  imageUrl$: Observable<string>;
  hasCreateVideoFeature$: Observable<boolean>;
  canDownloadImages$: Observable<boolean>;
  currentImage = 0;
  _albums = [];
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private _lightbox: Lightbox,
    private downloadService: DownloadFileService,
    private objectService: ObjectService,
    private modalService: QModalService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.handleLightBoxAlbum();
    this.setImageUrl();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.showingObject$ = this.objectService.entityMap$.pipe(
        map((entities) => entities[this.eaOid]),
        filter((entity) => !!entity)
      );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.object$ = this.store.pipe(select(fromShowings.getObject));
    // @ts-ignore
    this.images$ = this.showingObject$.pipe(
      filter((obj) => !!obj),
      map((obj) => obj.images)
    );
    this.hasCreateVideoFeature$ = this.store.pipe(
      select(getFeature(CREATE_OBJECT_VIDEO)),
      map((feature) => feature.enabled)
    );
    this.canDownloadImages$ = this.store.pipe(
      select(getFeature(DOWNLOAD_OBJECT_IMAGES)),
      map((feature) => feature.enabled)
    );
  }

  private handleLightBoxAlbum() {
    this.images$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((images) => images.length > 0)
      )
      .subscribe((images) => {
        this.fillLightBoxAlbum(images);
      });
  }

  setImageUrl() {
    this.imageUrl$ = this.images$.pipe(
      map(
        (images) => `url(${images[this.currentImage].url}410&height=410&crop=1)`
      )
    );
  }

  open(e: Event): void {
    e.stopPropagation();
    this.images$.pipe(first()).subscribe((images) => {
      const album = images.map((img, i) => ({
        src: img.url,
        caption: i + 1,
        thumb: null,
      }));
      // @ts-ignore
      this._lightbox.open(album, 0);
    });
  }

  loadPreviousImage(e: Event) {
    e.stopPropagation();
    this.images$.pipe(first()).subscribe((images) => {
      const numberOfImages = images.length;
      if (numberOfImages > 0) {
        if (this.currentImage === 0) {
          this.currentImage = images.length - 1;
        } else {
          this.currentImage--;
        }
        this.setImageUrl();
      }
    });
  }

  loadNextImage(e: Event) {
    e.stopPropagation();
    this.images$.pipe(first()).subscribe((images) => {
      const numberOfImages = images.length;
      if (numberOfImages > 0) {
        if (this.currentImage === images.length - 1) {
          this.currentImage = 0;
        } else {
          this.currentImage++;
        }
        this.setImageUrl();
      }
    });
  }

  fillLightBoxAlbum(images: { url: string }[]): void {
    for (let i = 0; i < images.length; i++) {
      const src = images[i].url;
      const album = {
        src: src,
        caption: i + 1,
        thumb: null,
      };
      this._albums.push(album);
    }
  }

  showVideoModal(event: Event) {
    event.stopPropagation();

    this.showingObject$.pipe(first()).subscribe((object) => {
      this.modalService.show(ObjectVideoModalComponent, {
        data: {
          hasHeader: true,
          hasHeaderCloseButton: true,
          hasActionBar: true,
          eaOid: this.eaOid,
          object: object,
        },
      });
    });
  }

  // TODO: this part related to Aktiv customer for their migration from Webmegler to Vitec ERP ant it's a temporary code
  downloadImages() {
    this.showingObject$.pipe(first()).subscribe((object) => {
      this.downloadService.downloadImages(object);
    });
  }
}
