import { Component, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "standalone-sidebar-header",
  template: `
    <div class="sidebar-header">
      <button type="button" class="close btn-close" (click)="onClose.emit()">
        <app-icon name="times"></app-icon>
      </button>
      <h2>
        <ng-content></ng-content>
      </h2>
    </div>
  `,
  styleUrls: ["./standalone-sidebar-header.component.scss"],
})
export class StandaloneSidebarHeaderComponent {
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose = new EventEmitter<void>();
}
