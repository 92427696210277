import { CreateLeadCreateContactComponent } from "@app/sidebar/create-lead/create-lead-create-contact/create-lead-create-contact.component";
import { CreateLeadHomeComponent } from "@app/sidebar/create-lead/create-lead-home/create-lead-home.component";
import { CreateLeadNewComponent } from "@app/sidebar/create-lead/create-lead-new/create-lead-new.component";
import { CreateLeadComponent } from "@app/sidebar/create-lead/create-lead.component";
import { CreateLeadGuard } from "@app/sidebar/guards/lead-create-guard.service";

export const createLeadRoutes = {
  path: "create-lead",
  component: CreateLeadComponent,
  children: [
    {
      path: "",
      component: CreateLeadHomeComponent,
      canActivate: [CreateLeadGuard],
    },
    {
      path: "contacts/new",
      component: CreateLeadCreateContactComponent,
      canActivate: [CreateLeadGuard],
    },
    {
      path: "new/contacts/:id",
      component: CreateLeadNewComponent,
      canActivate: [CreateLeadGuard],
    },
  ],
};
