import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Task } from "@app/models";
import { TASK } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { map, Observable, Subject, takeUntil } from "rxjs";
import * as taskActions from "../ngrx/tasks.actions";
import { getTask } from "../ngrx/tasks.reducer";

@Component({
  selector: "sidebar-tasks-vardekollen",
  templateUrl: "./tasks-vardekollen.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./tasks-vardekollen.component.scss",
  ],
})
export class TasksVardekollenComponent implements OnInit, OnDestroy {
  task$: Observable<Task>;

  tabType = TASK;

  unSubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private store: Store<AppState>) {}

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.unSubscribe$),
        map((params) => params.id)
      )
      .subscribe((id) => this.store.dispatch(taskActions.getTaskRequest(id)));

    this.task$ = this.store.pipe(select(getTask));
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
