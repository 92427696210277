import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { ShowingService } from "@app/core/ngrx/entity-services/showing.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import {
  Contact,
  ContactObjectConnection,
  Employee,
  Office,
  QObject,
} from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import {
  CREATE_PRIVATE_SHOWING,
  SHOWING_CONFIRMATION_EMAIL,
  SHOWINGS_COMMENT,
} from "@app/shared/config/utils/features";
import * as fromUser from "@app/shared/user";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { ShowingType } from "@app/sidebar/potential-buyer/util/showing-types";
import { DynamicContent, Template } from "@app/sidebar/send-message/models";
import * as fromSendMessage from "@app/sidebar/send-message/ngrx/send-message.reducer";
import { MessageType } from "@app/sidebar/send-message/ngrx/send-message.reducer";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import {
  compareDates,
  transformDateStringToDate,
} from "@app/statistics/boxes/utils/date-utils";
import { select, Store } from "@ngrx/store";
import moment from "moment-timezone";
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { Feature } from "@app/shared/config/models";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";

@Component({
  selector: "app-add-showing-modal-new",
  templateUrl: "./add-showing-modal-new.component.html",
  styleUrls: ["./add-showing-modal-new.component.scss"],
})
export class AddShowingModalNewComponent implements OnInit, OnDestroy {
  form: FormGroup;
  assignToOtherAgent: boolean = false;
  isEditMode: boolean = false;
  timeZone$: Observable<string>;
  dateToday = moment().format("L");
  isWorking = false;
  segmentControls$: Observable<SegmentControls>;
  activeView = "private";
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  canWriteComment$: Observable<boolean>;
  templates$: Observable<Template[]>;
  office$: Observable<Office>;
  sendModuleId$: Observable<string>;
  user$: Observable<Employee>;
  dynamicContents$: Observable<DynamicContent[]>;
  showingConfirmationEmail$: Observable<{
    enabled: boolean;
    templateId: string;
    externalId: string;
  }>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowingModalData,
    public dialogRef: MatDialogRef<AddShowingModalNewComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private showingService: ShowingService,
    private contactService: ContactService,
    private objectService: ObjectService,
    private sendMessageService: SendMessageService,
    private phoneService: PhoneNumberService
  ) {
    this.form = this.fb.group(
      {
        date: [null, Validators.required],
        eaOid: [null, Validators.required],
        contactId: [null, Validators.required],
        startTime: [null, Validators.required],
        endTime: [null, Validators.required],
        description: [null],
        comment: [null],
        eaOfficeId: [null],
        eaEmployeeId: [null],
        sendConfirmationMessage: [false],
      },
      {
        validators: [this.selectedTimeRangeValidator()],
      }
    );
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
    this.manageStartTimeSelected();
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  changeShowingType(view: string) {
    if (view === "public") {
      this.form.get("contactId").clearValidators();
      this.form.get("eaOfficeId").addValidators(Validators.required);
      this.form.get("eaEmployeeId").addValidators(Validators.required);
    } else {
      this.form.get("eaOfficeId").clearValidators();
      this.form.get("eaEmployeeId").clearValidators();
      this.form.get("contactId").addValidators(Validators.required);
    }
    this.form.get("contactId").updateValueAndValidity();
    this.activeView = view;
  }

  generateDateFromForm = (
    formControlName: "startTime" | "endTime",
    timezone: string
  ) => {
    return moment
      .tz(this.form.get("date").value, timezone)
      .set("hour", this.form.get(formControlName).value.split(":")[0])
      .set("minute", this.form.get(formControlName).value.split(":")[1])
      .utc()
      .format("YYYY-MM-DDTHH:mm:ssZ");
  };

  generateTimeValues = (timezone: string) => {
    return {
      startTime: this.generateDateFromForm("startTime", timezone),
      endTime: this.generateDateFromForm("endTime", timezone),
    };
  };

  submit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    this.timeZone$
      .pipe(first(), withLatestFrom(this.user$, this.office$))
      .subscribe(([timezone, user, office]) => {
        this.isWorking = true;

        if (!this.isEditMode) {
          this.saveShowing(timezone, user.eaEmployeeId, office.eaOfficeId);
        } else {
          this.updateShowing(timezone);
        }
      });
  }

  private mapStateToProps() {
    this.timeZone$ = this.store.pipe(select(fromConfig.getTimeZone));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.templates$ = this.store.pipe(select(fromSendMessage.getTemplates));
    this.dynamicContents$ = this.store.pipe(
      select(fromSendMessage.getDynamicContents)
    );
    this.sendModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.user$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.showingConfirmationEmail$ = this.store.pipe(
      select(getFeature(SHOWING_CONFIRMATION_EMAIL))
    );
    this.segmentControls$ = combineLatest([
      this.store.pipe(select(fromConfig.getFeature(CREATE_PRIVATE_SHOWING))),
      this.store.select(
        hasIntegration(IntegrationResource.CreatePublicShowing)
      ),
    ]).pipe(
      map(([canCreatePrivateShowing, canCreatePublicShowing]) => {
        const result = [];

        if (canCreatePrivateShowing.enabled) {
          result.push({ label: "private", value: "private" });
          this.activeView = "private";
        } else {
          this.activeView = "public";
        }

        if (canCreatePublicShowing) {
          result.push({ label: "public", value: "public" });
        }

        return result;
      })
    );

    this.canWriteComment$ = this.store.pipe(
      select(fromConfig.getFeature(SHOWINGS_COMMENT)),
      map((feature: Feature) => feature.enabled)
    );
  }

  private fillForm() {
    this.isEditMode = this.data?.isEditMode;

    if (!this.isEditMode) {
      this.form.get("contactId").setValue(this.data.contactId);
      this.form.get("eaOid").setValue(this.data.eaOid);
    } else {
      const showing = this.data.showing;
      if (showing.isPublic) {
        this.activeView = "public";
        this.form.get("contactId").setValue(null);
        this.form
          .get("eaEmployeeId")
          .setValue(showing?.showingAgentEaEmployeeId);
      } else {
        this.activeView = "private";
        this.form
          .get("contactId")
          .setValue(showing.potentialBuyers[0].contactId);
      }
      this.form.get("contactId").disable();
      this.form.get("eaOid").disable();
      this.form.get("sendConfirmationMessage").disable();
      this.form.get("eaOid").setValue(showing.eaOid);
      const startDate = showing.startTime.split(" ");
      const endDate = showing.endTime.split(" ");
      this.form.get("date").setValue(startDate[0]);
      this.form.get("startTime").setValue(startDate[1]);
      this.form.get("endTime").setValue(endDate[1]);
      this.form.get("description").setValue(showing?.description);
      this.form.get("comment").setValue(showing?.comment);
    }
  }

  private manageStartTimeSelected() {
    this.form.get("startTime").valueChanges.subscribe((startTime) => {
      this.form
        .get("endTime")
        .setValue(moment(startTime, "HH:mm").add(30, "minute").format("HH:mm"));
    });
  }

  preview() {
    const eaOid = this.form.get("eaOid").value;
    const contactId = this.form.get("contactId").value;

    combineLatest([
      this.contactService.getById(contactId),
      this.objectService.getById(eaOid),
      this.office$,
      this.user$,
      this.showingConfirmationEmail$,
    ])
      .pipe(
        filter(
          ([contact, object, office, employee, showingConfirmationEmail]) =>
            !!office &&
            !!employee &&
            !!contact &&
            !!object &&
            !!showingConfirmationEmail
        ),
        first()
      )
      .subscribe(
        ([contact, object, office, employee, showingConfirmationEmail]) => {
          const dataSet = this.formatDataSet(office, employee, contact, object);
          const consumerName = `${contact?.firstName} ${contact?.familyName}`;

          this.sendMessageService.previewMeeting(
            showingConfirmationEmail.externalId,
            dataSet,
            { value2: showingConfirmationEmail.templateId },
            consumerName
          );
        }
      );
  }

  private saveShowing(
    timezone: string,
    eaEmployeeId: string,
    eaOfficeId: string
  ) {
    const selectedEmployeeId = this.assignToOtherAgent
      ? this.form.get("eaEmployeeId").value || eaEmployeeId
      : eaEmployeeId;
    const selectedOfficeId = this.assignToOtherAgent
      ? this.form.get("eaOfficeId").value || eaOfficeId
      : eaOfficeId;

    const paramsPrivateShowing = {
      attendingContacts: [this.form.get("contactId").value],
      eaOid: this.form.get("eaOid").value,
      showingType:
        this.activeView === "private"
          ? ShowingType.SHOWING_TYPE_PRIVATE
          : ShowingType.SHOWING_TYPE_PUBLIC,
      description: this.form.get("description").value,
      comment: this.form.get("comment").value,
      eaEmployeeId: selectedEmployeeId,
      eaOfficeId: selectedOfficeId,
      ...this.generateTimeValues(timezone),
    };

    const paramsPublicShowing = {
      description: this.form.get("description").value,
      comment: this.form.get("comment").value,
      assignedShowingAgent: {
        eaEmployeeId: this.form.get("eaEmployeeId").value,
        eaOfficeId: this.form.get("eaOfficeId").value,
      },
      ...this.generateTimeValues(timezone),
    };

    this.closeModal();

    const postPrivateShowing = () =>
      this.showingService
        .postPrivateShowing(paramsPrivateShowing, true)
        .subscribe((resp) => {
          if (!!this.form.get("sendConfirmationMessage").value) {
            this.sendConfirmationMail(resp["showingId"]);
          }
          setTimeout(
            () =>
              this.showingService
                .refreshFetchFromObject()
                .pipe(
                  switchMap(() =>
                    this.contactService.refreshPotentialBuyersNew()
                  )
                )
                .subscribe(),
            500
          );

          this.closeModal();
          this.isWorking = false;
        });

    const postPublicShowing = () => {
      this.showingService
        .postPublicShowing(
          paramsPublicShowing,
          this.form.get("eaOid").value,
          true
        )
        .subscribe(() => {
          setTimeout(
            () => this.showingService.refreshFetchFromObject().subscribe(),
            500
          );
          this.closeModal();
          this.isWorking = false;
        });
    };

    if (this.activeView === "private") {
      this.contactService
        .fetchContactObjectConnection(
          paramsPrivateShowing.attendingContacts[0],
          {
            eaOid: paramsPrivateShowing.eaOid,
          }
        )
        .subscribe((connections: ContactObjectConnection[]) => {
          if (connections.length > 0) {
            postPrivateShowing();
          } else {
            this.contactService
              .postContactObjectConnection(
                paramsPrivateShowing.attendingContacts[0],
                {
                  eaOid: paramsPrivateShowing.eaOid,
                  type: "potentialbuyer",
                  bidStatus: "unknown",
                }
              )
              .subscribe(() => {
                postPrivateShowing();
              });
          }
        });
    } else {
      // Store public showing
      postPublicShowing();
    }
  }

  private updateShowing(timezone: string) {
    const entity = {
      description: this.form.get("description").value,
      comment: this.form.get("comment").value,
      ...this.generateTimeValues(timezone),
    };

    this.closeModal();

    this.showingService
      .patchShowing(entity, this.data.showing.showingId, true)
      .subscribe(() => {
        setTimeout(
          () =>
            this.showingService
              .refreshFetchFromObject()
              .pipe(
                switchMap(() => this.contactService.refreshPotentialBuyersNew())
              )
              .subscribe(),
          500
        );

        this.closeModal();
        this.isWorking = false;
      });
  }

  private sendConfirmationMail(showingId: string) {
    const eaOid = this.form.get("eaOid").value;
    const contactId = this.form.get("contactId").value;

    combineLatest([
      this.contactService.getById(contactId),
      this.objectService.getById(eaOid),
      this.office$,
      this.user$,
      this.showingConfirmationEmail$,
      this.sendModuleId$,
    ])
      .pipe(
        filter(
          ([
            contact,
            object,
            office,
            employee,
            showingConfirmationEmail,
            sendModuleId,
          ]) =>
            !!office &&
            !!employee &&
            !!contact &&
            !!object &&
            !!showingConfirmationEmail &&
            !!sendModuleId
        ),
        first()
      )
      .subscribe(
        ([
          contact,
          object,
          office,
          employee,
          showingConfirmationEmail,
          sendModuleId,
        ]) => {
          const messageType: MessageType = "email";
          const dataSet = this.formatDataSet(office, employee, contact, object);

          const extraParams = {
            value2: showingConfirmationEmail.templateId,
            templateId: showingConfirmationEmail.externalId,
            showingId: showingId,
            sendToModule: office.moduleId ? office.moduleId : sendModuleId,
            addToOutbox: false,
          };

          this.sendMessageService.sendMeeting(
            employee,
            contact,
            dataSet,
            extraParams,
            messageType
          );
        }
      );
  }

  private formatDataSet(
    office: Office,
    employee: Employee,
    contact: Contact,
    object: QObject
  ) {
    const showingTime =
      this.getMeetingTime("startTime").format("YYYYMMDDHHmmss");
    const showingEndTime =
      this.getMeetingTime("endTime").format("YYYYMMDDHHmmss");
    return {
      eaOid: object.eaOid,
      eaOfficeId: office.eaOfficeId,
      eaEmployeeId: employee.eaEmployeeId,
      consumerId: contact.contactId,
      officeName: office.officeName,
      officeZip: office.zip,
      officeCity: office.city,
      officeStreet: office.street,
      officeMailAddress: office.street,
      employeeFullName: employee.employeeFullName,
      employeeEmail: employee.employeeEmail,
      employeeMobile: this.formatPhoneNumber(
        employee.employeeMobile,
        employee.mobileCountryCode
      ),
      employeeTitle: employee.employeeTitle,
      employeePhone: this.formatPhoneNumber(
        employee.employeePhone,
        employee.phoneCountryCode
      ),
      employeePhoto: employee.employeePhoto,
      fromName: employee.employeeFullName,
      objectStreet: object.street,

      showingTime: showingTime,
      showingEndTime: showingEndTime,
    };
  }

  private getMeetingTime(controlName: string): any {
    const [HH, mm] = this.form.get(controlName).value.split(":");
    return moment(this.form.get("date").value).hours(HH).minutes(mm);
  }

  private formatPhoneNumber(mobile: string, mobileCountry: string): string {
    const phoneNumberExists = mobile ? mobile.length > 0 : false;
    const phoneNumberCountryExists = mobileCountry
      ? mobileCountry.length > 0
      : false;
    let formattedPhoneNumber = mobile;

    if (phoneNumberExists && phoneNumberCountryExists) {
      formattedPhoneNumber = this.phoneService.format(mobile, mobileCountry);
    }
    return formattedPhoneNumber;
  }

  private selectedTimeRangeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let isValid: boolean;
      try {
        const selectedDate = control.get("date").value.replace("-", "").trim();
        const startTime = control
          .get("startTime")
          .value.replace(":", "")
          .trim();
        const endTime = control.get("endTime").value.replace(":", "").trim();
        const startDate = transformDateStringToDate(
          `${selectedDate}${startTime}00`
        );
        const endDate = transformDateStringToDate(
          `${selectedDate}${endTime}00`
        );

        isValid = compareDates(startDate, endDate);
      } catch (e) {
        isValid = false;
      }
      return isValid ? null : { invalidRange: true };
    };
  }
}
