<div id="widget-outlet">
  <ng-container *ngIf="widgets$ | async as widgets; else noData">
    <app-widget-page
      *ngIf="userIds$ | async as ids"
      [widgetConfig]="widgets"
      [eaEmployeeId]="ids.eaEmployeeId"
      [eaOfficeId]="ids.eaOfficeId"
      [useRoleEaIds]="true"
      [openLinkInNewTab]="true"
      [useDefaultSize]="false"
      [showHeader]="false"
      widgetsSize="stretch"
    >
    </app-widget-page>
  </ng-container>

  <ng-template #noData>
    <p translate>widget_not_found</p>
  </ng-template>
</div>
