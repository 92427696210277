<app-q-modal>
  <ng-container slot="header">
    {{ "assign" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="form-container leads-to-employee">
      <app-list-view-controls
        [viewService]="listService"
      ></app-list-view-controls>
      <app-assign-lead-filters
        (formChange)="handleFormChanges($event)"
        (handleSearch)="handleSearch($event)"
        [eaOfficeId]="eaOfficeId$ | async"
        [isMobile]="isMobile$ | async"
        [offices]="offices$ | async"
        [taskTypes]="taskTypes$ | async"
      >
      </app-assign-lead-filters>
      <app-list-utility-row
        (searchInput)="handleSearch($event)"
        [enableSearch]="true"
        [selectedEntities]="(selectedEmployees$ | async).length"
        [enableViewModes]="true"
        [enableActionButtons]="true"
      >
        <div viewModeButtons>
          <app-segmented-controls
            [controls]="segmentControls"
            [active]="selectedView"
            [truncate]="false"
            (change)="selectedView = $event"
          ></app-segmented-controls>
        </div>
      </app-list-utility-row>
      <div class="assign-employee-body">
        <div class="loading-spinner">
          <spinner *ngIf="loading$ | async" [size]="'2rem'"></spinner>
        </div>
        <div class="input-box-container">
          <div class="form-group">
            <span class="text-label" translate>transfer_comment</span>
            <div>
              <textarea
                [(ngModel)]="transferComment"
                [placeholder]="'transfer_comment' | translate"
              ></textarea>
            </div>
          </div>
          <div class="form-group">
            <div class="checkbox-wrapper">
              <mat-checkbox
                [checked]="canSendEmail"
                (change)="handleCheckboxChange($event)"
              >
                {{ "send_message_when_lead_is_created" | translate }}
              </mat-checkbox>
            </div>
            <div>
              <textarea
                [(ngModel)]="emailContent"
                [placeholder]="'message' | translate"
                [disabled]="!canSendEmail"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="card-container" *ngIf="selectedView === 'grid'">
          <div
            class="card-wrapper"
            *ngFor="let employee of selectedEmployees$ | async; let i = index"
          >
            <app-mobile-card
              [enableMetadata]="true"
              [selected]="
                employee.eaEmployeeId === selectedEmployee?.eaEmployeeId
              "
              (click)="handleSelectedEmployee(employee)"
            >
              <div class="employee-title" title>
                <span
                  *ngIf="i === 0"
                  [tooltip]="'recommended_lead' | translate"
                  [class.selected]="
                    employee.eaEmployeeId === selectedEmployee?.eaEmployeeId
                  "
                >
                  <app-icon name="star"></app-icon>
                </span>
                {{ employee.employeeFullName }}
              </div>
              <div metadata>
                <div>
                  <span>{{ "nbr_of_leads" | translate }}:&nbsp;</span>
                  <span>{{ employee.tasks }}</span>
                </div>
                <div>
                  <span>{{ "resolved" | translate }}:&nbsp;</span>
                  <span
                    [class]="
                      getPercentualColor(employee.resolvedTasksPercentual)
                    "
                    >{{ employee.resolvedTasks }} ({{
                      employee.resolvedTasksPercentual | qPercent : "1.0-1"
                    }})</span
                  >
                </div>
                <div>
                  <span>{{ "became_meeting" | translate }}:&nbsp;</span>
                  <span
                    [class]="
                      getPercentualColor(employee.successTasksPercentual)
                    "
                    >{{ employee.successTasks }} ({{
                      employee.successTasksPercentual | qPercent : "1.0-1"
                    }})</span
                  >
                </div>
                <div>
                  <span>{{ "assigned_time" | translate }}(≈):&nbsp;</span>
                  <span
                    [class]="
                      getAverageResolvedTimeColor(employee.averageAssignedTime)
                    "
                    >{{
                      employee.averageAssignedTime | approximateDuration
                    }}</span
                  >
                </div>
                <div>
                  <span>{{ "resolved_time" | translate }}(≈):&nbsp;</span>
                  <span
                    [class]="
                      getAverageResolvedTimeColor(employee.averageResolvedTime)
                    "
                    >{{
                      employee.averageResolvedTime | approximateDuration
                    }}</span
                  >
                </div>
              </div>
            </app-mobile-card>
          </div>
        </div>
        <div class="table" *ngIf="selectedView === 'table'">
          <table>
            <thead>
              <tr>
                <th translate>employee</th>
                <th translate>nbr_of_leads</th>
                <th translate>resolved</th>
                <th translate>became_meeting</th>
                <th translate>assigned_time</th>
                <th translate>resolved_time</th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let employee of selectedEmployees$ | async"
                (click)="handleSelectedEmployee(employee)"
              >
                <td>{{ employee.employeeFullName }}</td>
                <td>{{ employee.tasks }}</td>
                <td
                  [class]="getPercentualColor(employee.resolvedTasksPercentual)"
                >
                  {{ employee.resolvedTasks }} ({{
                    employee.resolvedTasksPercentual | qPercent : "1.0-1"
                  }})
                </td>
                <td
                  [class]="getPercentualColor(employee.successTasksPercentual)"
                >
                  {{ employee.successTasks }} ({{
                    employee.successTasksPercentual | qPercent : "1.0-1"
                  }})
                </td>
                <td>
                  {{ employee.averageAssignedTime | approximateDuration }}
                </td>
                <td>
                  {{ employee.averageResolvedTime | approximateDuration }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        buttonType="cancel"
        label="cancel"
        classes="margin-right"
        (click)="close()"
      >
      </app-q-button>
      <app-q-button
        buttonType="save"
        label="connect"
        [disabled]="!selectedEmployee"
        (click)="handleAssign()"
      >
      </app-q-button>
    </div>
  </ng-container>
</app-q-modal>
