export interface CreateContactConfig {
  contact_types: CreateContactTypesConfig;
}

export interface CreateContactTypesConfig {
  [key: string]: CreateContactTypeConfig;
}

export interface CreateContactTypeConfig {
  type: "person" | "company" | "estate";
  enabled: boolean;
  displayOrder: number;
  addressIsRequired: boolean;
}

export const ContactTypesDefaultConfig: CreateContactTypesConfig = {
  person: {
    type: "person",
    enabled: true,
    displayOrder: 1,
    addressIsRequired: false,
  },
  company: {
    type: "company",
    enabled: false,
    displayOrder: 2,
    addressIsRequired: false,
  },
  estate: {
    type: "estate",
    enabled: false,
    displayOrder: 3,
    addressIsRequired: false,
  },
};
