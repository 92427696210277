import { Injectable } from "@angular/core";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { Action as ContactAction } from "@app/models";
import { ContactedStatus } from "@app/sidebar/potential-buyer/models/contacted-status";

@Injectable()
export class ContactedStatusResolver {
  static resolve(action: ContactAction): boolean | null {
    if (!action) {
      return null;
    }

    if (
      action.code !== ContactActionCodes.CONSUMER_CALL_REGISTERED.toString()
    ) {
      throw new Error(
        `Action must have code ${ContactActionCodes.CONSUMER_CALL_REGISTERED}`
      );
    }

    return action.param1 === ContactedStatus.Answer;
  }

  static toLegacy(contacted: boolean) {
    return {
      param1: contacted ? ContactedStatus.Answer : ContactedStatus.NoAnswer,
    };
  }
}
