import {
  consentReducer,
  ConsentState,
} from "@app/contacts/contact-consents/ngrx/consents.reducer";
import {
  contactSearchProfilesReducer,
  ContactSearchProfilesState,
} from "@app/contacts/contact-search-profile/ngrx/contact-search-profile.reducer";
import { combineReducers } from "@ngrx/store";
import {
  contactNextStepReducer,
  ContactNextStepState,
} from "./contact-overview/contact-next-step/contact-next-step.reducer";
import {
  contactResidencesReducer,
  ContactResidencesState,
} from "./contact-residences/residences.reducer";
import {
  contactSalesMeetingsReducer,
  ContactSalesMeetingsState,
} from "./contact-sales-meetings/contact-sales-meetings.reducer";
import { contactReducer, ContactState } from "./contact.reducer";

export interface ContactModuleState {
  contact: ContactState;
  nextStep: ContactNextStepState;
  residences: ContactResidencesState;
  salesMeetings: ContactSalesMeetingsState;
  consents: ConsentState;
  searchProfiles: ContactSearchProfilesState;
}

const reducers = {
  contact: contactReducer,
  nextStep: contactNextStepReducer,
  residences: contactResidencesReducer,
  salesMeetings: contactSalesMeetingsReducer,
  consents: consentReducer,
  searchProfiles: contactSearchProfilesReducer,
};

export function contactModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
