import { Injectable } from "@angular/core";
import { EventDTO } from "@app/shared/modules/calendar-widget/models";
import {
  WeatherSymbol,
  WeatherSymbolGroup,
} from "@app/shared/modules/weather/weather";
import moment from "moment";
import { getSunset } from "sunrise-sunset-js";

@Injectable({ providedIn: "root" })
export class WeatherService {
  getIconStyle(event: EventDTO): any {
    const group = this.getWeatherGroupBySymbol(event);
    if (group) {
      return {
        background: `url('/assets/icons/weather_${group.toLowerCase()}.svg') no-repeat center`,
      };
    }
    return null;
  }

  getTemperatureSegment(event: EventDTO) {
    const SEGMENT_1_MAX = -10;
    const SEGMENT_2_MIN = -10;
    const SEGMENT_2_MAX = 0;
    const SEGMENT_3_MIN = 0;
    const SEGMENT_3_MAX = 10;
    const SEGMENT_4_MIN = 10;
    const SEGMENT_4_MAX = 20;
    const SEGMENT_5_MIN = 20;
    const SEGMENT_5_MAX = 25;
    const SEGMENT_6_MIN = 25;
    const SEGMENT_6_MAX = 30;
    const SEGMENT_7_MIN = 30;
    const temperature = Math.round(parseFloat(event.forecastData.temperature));

    if (temperature <= SEGMENT_1_MAX) {
      return 1;
    } else if (temperature > SEGMENT_2_MIN && temperature <= SEGMENT_2_MAX) {
      return 2;
    } else if (temperature > SEGMENT_3_MIN && temperature <= SEGMENT_3_MAX) {
      return 3;
    } else if (temperature > SEGMENT_4_MIN && temperature <= SEGMENT_4_MAX) {
      return 4;
    } else if (temperature > SEGMENT_5_MIN && temperature <= SEGMENT_5_MAX) {
      return 5;
    } else if (temperature > SEGMENT_6_MIN && temperature <= SEGMENT_6_MAX) {
      return 6;
    } else if (temperature > SEGMENT_7_MIN) {
      return 7;
    }
  }

  isNight(event: EventDTO): boolean {
    const eventTime = moment(event.formatedStartTime, "YYYY-MM-DD HH:mm");
    const sunset = moment(
      getSunset(
        Number(event.latitude),
        Number(event.longitude),
        eventTime.toDate()
      )
    );

    return eventTime.isAfter(sunset);
  }

  getWeatherGroupBySymbol(event: EventDTO): WeatherSymbolGroup | null {
    switch (event.forecastData.weatherSymbol) {
      case WeatherSymbol.CLEAR_SKY:
        return this.isNight(event)
          ? WeatherSymbolGroup.MOON
          : WeatherSymbolGroup.CLEAR_SKY;
      case WeatherSymbol.OVERCAST:
      case WeatherSymbol.CLOUDY_SKY:
        return WeatherSymbolGroup.CLOUDY;
      case WeatherSymbol.NEARLY_CLEAR_SKY:
      case WeatherSymbol.HALFCLEAR_SKY:
      case WeatherSymbol.VARIABLE_CLOUDINESS:
        return this.isNight(event)
          ? WeatherSymbolGroup.NIGHT
          : WeatherSymbolGroup.SEMI_CLOUDY;
      case WeatherSymbol.FOG:
        return WeatherSymbolGroup.FOG;
      case WeatherSymbol.LIGHT_RAIN_SHOWERS:
      case WeatherSymbol.MODERATE_RAIN_SHOWERS:
      case WeatherSymbol.HEAVY_RAIN_SHOWERS:
      case WeatherSymbol.LIGHT_RAIN:
      case WeatherSymbol.MODERATE_RAIN:
      case WeatherSymbol.HEAVY_RAIN:
        return WeatherSymbolGroup.RAIN;
      case WeatherSymbol.THUNDERSTORM:
      case WeatherSymbol.THUNDER:
        return WeatherSymbolGroup.THUNDERSTORM;
      case WeatherSymbol.LIGHT_SLEET_SHOWERS:
      case WeatherSymbol.MODERATE_SLEET_SHOWERS:
      case WeatherSymbol.HEAVY_SLEET_SHOWERS:
      case WeatherSymbol.LIGHT_SLEET:
      case WeatherSymbol.MODERATE_SLEET:
      case WeatherSymbol.HEAVY_SLEET:
        return WeatherSymbolGroup.SLEET;
      case WeatherSymbol.LIGHT_SNOW_SHOWERS:
      case WeatherSymbol.MODERATE_SNOW_SHOWERS:
      case WeatherSymbol.HEAVY_SNOW_SHOWERS:
      case WeatherSymbol.LIGHT_SNOWFALL:
      case WeatherSymbol.MODERATE_SNOWFALL:
      case WeatherSymbol.HEAVY_SNOWFALL:
        return WeatherSymbolGroup.SNOW;
      default:
        return null;
    }
  }
}
