import { Injectable } from "@angular/core";
import * as userActions from "@app/shared/user/user.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import { SessionService } from "../session.service";
import * as sessionActions from "./session.actions";
import { SessionState } from "./session.reducer";

@Injectable()
export class SessionEffects {
  getSessionOnLoadUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadSuccess),
      switchMap(({ office }) =>
        this.sessionService.getSession(office).pipe(
          map((session: SessionState) =>
            sessionActions.setSession({ session })
          ),
          catchError((error) =>
            observableOf(
              sessionActions.getSessionFail({ message: error.message })
            )
          )
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private sessionService: SessionService
  ) {}
}
