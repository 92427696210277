import { Action, createAction, props } from "@ngrx/store";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";

export const fetchBiddingStatusRequest = createAction(
  "[PotentialBuyer] FETCH_BIDDING_STATUS_REQUEST",
  props<{ contactId: string; params: { eaOid: string } }>()
);

export const fetchBiddingStatusSuccess = createAction(
  "[PotentialBuyer] FETCH_BIDDING_STATUS_SUCCESS",
  props<{ bidStatus: string }>()
);

export const fetchBiddingStatusFailure = createAction(
  "[PotentialBuyer] FETCH_BIDDING_STATUS_FAILURE"
);

export const updateBiddingStatusRequest = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_REQUEST",
  props<{
    contactId: string;
    body: { eaOid: string; bidStatus: string };
    undoAction: Action;
  }>()
);

export const updateBiddingStatusSuccess = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_SUCCESS",
  props<{ payload: any }>()
);

export const updateBiddingStatusFailure = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_FAILURE"
);

export const updateBiddingStatusMultipleRequest = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_MULTIPLE_REQUEST",
  props<{
    contactIds: string[];
    body: { eaOid: string; bidStatus: string };
    undoAction?: Action;
  }>()
);

export const updateBiddingStatusMultipleSuccess = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_MULTIPLE_SUCCESS",
  props<{ payload: any }>()
);

export const updateBiddingStatusMultipleFailure = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_MULTIPLE_FAILURE"
);

export const updateBiddingStatusOptimistic = createAction(
  "[PotentialBuyer] UPDATE_BIDDING_STATUS_OPTIMISTIC",
  props<{ bidStatus: string }>()
);

export const fetchBiddingSettingsForBidderLinkRequest = createAction(
  "[PotentialBuyer] FETCH_BIDDING_SETTINGS_FOR_BIDDER_LINK_REQUEST",
  props<{ contactId: string; eaOid: string }>()
);

export const fetchBiddingSettingsForBidderLinkSuccess = createAction(
  "[PotentialBuyer] FETCH_BIDDING_SETTINGS_FOR_BIDDER_LINK_SUCCESS",
  props<{ payload: ExternalPresentableLink }>()
);

export const fetchBiddingSettingsForBidderLinkFailure = createAction(
  "[PotentialBuyer] FETCH_BIDDING_SETTINGS_FOR_BIDDER_LINK_FAILURE"
);

export const resetBiddingSettingsForBidderLink = createAction(
  "[PotentialBuyer] RESET_BIDDING_SETTINGS_FOR_BIDDER_LINK"
);

export const fetchSetAsWinningBidLinkRequest = createAction(
  "[PotentialBuyer] FETCH_SET_AS_WINNING_BID_LINK_REQUEST",
  props<{ bidId: string }>()
);

export const fetchSetAsWinningBidLinkSuccess = createAction(
  "[PotentialBuyer] FETCH_SET_AS_WINNING_BID_LINK_SUCCESS",
  props<{ payload: ExternalPresentableLink }>()
);

export const fetchSetAsWinningBidLinkFailure = createAction(
  "[PotentialBuyer] FETCH_SET_AS_WINNING_BID_LINK_FAILURE"
);
