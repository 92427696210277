import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as search from "@app/core/components/search/ngrx/search.actions";
import { Employee } from "@app/models";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { ENTERING_DURATION, EXITING_DURATION } from "@app/shared/animations";
import * as fromUser from "@app/shared/user/index";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { map, Observable } from "rxjs";
import * as navbar from "../navbar/ngrx/navbar.actions";
import * as fromContact from "@app/contacts/contact.selectors";
import { addBackLink } from "@app/contacts/contact.actions";
import { Router } from "@angular/router";
import { isChristmasTime } from "@app/shared/utils/date-utils";

@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger("isNavVisibleChanged", [
      state("0", style({ marginLeft: "0" })),
      state("1", style({ marginLeft: "0rem" })),
      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),
    ]),
    trigger("sidebarAnimationStates", [
      state("void", style({ marginRight: "0" })),
      state("0", style({ marginRight: "0" })),
      state("1", style({ marginRight: "3rem" })),
      state("2", style({ marginRight: "29rem" })),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("void => 2", animate(`${ENTERING_DURATION} ease-in`)),

      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),
      transition("0 => 2", animate(`${ENTERING_DURATION} ease-in`)),

      transition("1 => void", animate(`${EXITING_DURATION} ease-out`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
      transition("1 => 2", animate(`${ENTERING_DURATION} ease-in`)),

      transition("2 => void", animate(`${EXITING_DURATION} ease-out`)),
      transition("2 => 0", animate(`${EXITING_DURATION} ease-out`)),
      transition("2 => 1", animate(`${EXITING_DURATION} ease-out`)),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  @Input() isNavbarVisible: boolean;
  @Input() sidebarAnimationStates: number;
  @Input() isProductNewsEnabled: boolean;
  @ViewChild("searchInput", { static: true })
  searchInput: ElementRef;
  employee$: Observable<Employee>;
  isViewingAsSomeoneElse$: Observable<boolean>;
  mobileNavigation$: Observable<SubPage[]>;
  dateToday = moment().format("L");
  logoUrl$: Observable<string>;
  backLink$: Observable<string>;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.mobileNavigation$ = this.store.select(
      (state) => state.mobileNavigation.routes
    );
    this.backLink$ = this.store.pipe(select(fromContact.getBackLink));
    this.logoUrl$ = this.store.pipe(
      map((state) => state.config.app.general.realforce_data_logo_url),
      map((url) =>
        !isChristmasTime() ? url : url.replace(".svg", "-xmass.svg")
      )
    );
    this.isViewingAsSomeoneElse$ = this.store.pipe(
      select(fromUser.isViewingAsSomeoneElse)
    );
  }

  toggleNavbar(): void {
    this.store.dispatch(navbar.toggle());
  }

  showOverlay(): void {
    this.store.dispatch(search.show());
  }

  showSidebar(): void {
    this.store.dispatch(sidebarActions.show());
  }

  onBackLinkClick(url: string): void {
    this.store.dispatch(addBackLink({ backLink: "" }));
    this.router.navigateByUrl(url);
  }
}
