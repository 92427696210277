<div [formGroup]="group">
  <div class="input-iconfield">
    <input
      type="text"
      [placeholder]="placeholder | translate"
      [formControlName]="controlName"
      [minDate]="minDate"
      [bsConfig]="bsConfig"
      [placement]="'bottom'"
      #dp="bsDatepicker"
      bsDatepicker
    />
    <span
      (click)="group.get(controlName).reset()"
      *ngIf="group.controls[controlName].value && enableReset"
      class="input-iconfield__clear"
      type="button"
    >
      <app-icon name="times"></app-icon>
    </span>
    <i class="icon icon-calendar" aria-hidden="true" (click)="dp.toggle()"></i>
  </div>
</div>
