import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { closeTab } from "../../ngrx/sidebar.actions";
import { SidebarService } from "../../sidebar.service";

@Component({
  selector: "sidebar-header",
  template: `
    <div class="sidebar-header">
      <div class="contact-summary-container">
        <h2 [ngClass]="{ 'sidebar-header--smalltext': isLongText(label) }">
          {{ label }}
        </h2>
        <ng-container *ngIf="contactId && contactId.length > 0">
          <contact-edit-button
            *ngIf="enableEdit"
            [contactId]="contactId"
            [matTooltip]="'edit' | translate"
            matTooltipClass="q-tooltip"
          ></contact-edit-button>
          <contact-summary
            [contactId]="contactId"
            [matTooltip]="'flash_info' | translate"
            matTooltipClass="q-tooltip"
          ></contact-summary>
        </ng-container>
        <button type="button" class="btn-close pull-right" (click)="close()">
          <i class="fal fa-times"></i>
        </button>
      </div>
    </div>
  `,
  styleUrls: ["./sidebar-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarHeaderComponent {
  @Input() label: string;
  @Input() type: string;
  @Input() contactId: string;
  @Input() enableEdit: boolean = false;

  constructor(
    private store: Store<AppState>,
    private sidebarService: SidebarService
  ) {}

  isLongText(label): boolean {
    return label && label.length > 17;
  }

  close(): void {
    if (this.type) {
      this.sidebarService.canCloseTab(this.type).subscribe((close) => {
        if (close) {
          this.store.dispatch(closeTab({ tabType: this.type }));
        }
      });
    } else {
      this.store.dispatch(closeTab({}));
    }
  }
}
