import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  selector: "skeleton-detail-card",
  template: `
    <div class="wrapper flex-container">
      <div class="icon-column">
        <skeleton-circle [diameter]="1"></skeleton-circle>
      </div>
      <div class="flex-column">
        <skeleton-rectangle [height]="1" [width]="10"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="0.75" [width]="10"></skeleton-rectangle>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: ["./detail-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonDetailCardComponent {}
