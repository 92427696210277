import {
  BudgetStatisticsResponse,
  BudgetVersion,
} from "@app/statistics/statistics-budget/models/budget-statistics-response";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsBudgetActions from "./statistics-budget.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsBudgetState {
  loading: boolean;
  budgetStatistics: BudgetStatisticsResponse | null;
  csv: string;
  csvLoading: boolean;
  versions: BudgetVersion[];
  versionsLoading: boolean;
  storingVersion: boolean;
  updatingVersion: boolean;
  deletingVersion: boolean;
  latestCreatedVersionId: string;
}

export const initialState: StatisticsBudgetState = {
  loading: false,
  budgetStatistics: null,
  csv: "",
  csvLoading: false,
  versions: null,
  versionsLoading: false,
  storingVersion: false,
  updatingVersion: false,
  deletingVersion: false,
  latestCreatedVersionId: "0",
};

export function statisticsBudgetReducer(
  state: StatisticsBudgetState,
  action: Action
): StatisticsBudgetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsBudgetActions.getBudgetStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    statisticsBudgetActions.getBudgetStatisticsSuccess,
    (state, { budgetStatistics }) => ({
      ...state,
      loading: false,
      budgetStatistics,
    })
  ),
  on(statisticsBudgetActions.getBudgetStatisticsFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(statisticsBudgetActions.getBudgetVersionsRequest, (state) => ({
    ...state,
    versionsLoading: true,
  })),
  on(
    statisticsBudgetActions.getBudgetVersionsSuccess,
    (state, { versions }) => ({
      ...state,
      versionsLoading: false,
      versions,
    })
  ),
  on(statisticsBudgetActions.getBudgetVersionsFailure, (state) => ({
    ...state,
    versionsLoading: false,
  })),
  on(statisticsBudgetActions.storeBudgetVersionsRequest, (state) => ({
    ...state,
    storingVersion: true,
  })),
  on(
    statisticsBudgetActions.storeBudgetVersionsSuccess,
    (state, { versionId }) => ({
      ...state,
      storingVersion: false,
      latestCreatedVersionId: versionId,
    })
  ),
  on(statisticsBudgetActions.storeBudgetVersionsFailure, (state) => ({
    ...state,
    storingVersion: false,
  })),
  on(statisticsBudgetActions.updateBudgetVersionsRequest, (state) => ({
    ...state,
    updatingVersion: true,
  })),
  on(
    statisticsBudgetActions.updateBudgetVersionsSuccess,
    statisticsBudgetActions.updateBudgetVersionsFailure,
    (state) => ({
      ...state,
      updatingVersion: false,
    })
  ),
  on(statisticsBudgetActions.deleteBudgetVersionsRequest, (state) => ({
    ...state,
    deletingVersion: true,
  })),
  on(
    statisticsBudgetActions.deleteBudgetVersionsSuccess,
    statisticsBudgetActions.deleteBudgetVersionsFailure,
    (state) => ({
      ...state,
      deletingVersion: false,
    })
  ),
  on(statisticsBudgetActions.getBudgetCSVRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(statisticsBudgetActions.getBudgetCSVSuccess, (state, { csv }) => ({
    ...state,
    csvLoading: false,
    csv,
  })),
  on(statisticsBudgetActions.getBudgetCSVFailure, (state) => ({
    ...state,
    csvLoading: false,
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.budget
);

export const getLoading = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.loading
);
export const getCsvLoading = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.csvLoading
);
export const getBudgetStatistics = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.budgetStatistics
);
export const getBudgetVersions = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.versions
);
export const getBudgetVersionsLoading = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.versionsLoading
);
export const getBudgetVersionsStoring = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.storingVersion
);
export const getBudgetVersionsUpdating = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.updatingVersion
);
export const getBudgetVersionsDeleting = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.deletingVersion
);
export const getLatestBudgetVersion = createSelector(
  getState,
  (state: StatisticsBudgetState) => state.latestCreatedVersionId
);
