import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
  selector: "app-customize-columns-modal",
  templateUrl: "./customize-columns-modal.component.html",
  styleUrls: ["./customize-columns-modal.component.scss"],
})
export class CustomizeColumnsModalComponent implements OnInit {
  columns: { label: string; value: string; control: FormControl }[];

  constructor(
    public dialogRef: MatDialogRef<CustomizeColumnsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    const { original, displayed } = this.data;
    const sorted = original.slice().sort((a, b) => {
      return displayed.indexOf(a) - displayed.indexOf(b);
    });

    this.columns = sorted.map((key) => ({
      label: this.getHeader(key),
      value: key,
      control: new FormControl(displayed.includes(key)),
    }));
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const selected = this.columns
      .filter((column) => column.control.value)
      .map((column) => column.value);
    const unselected = this.columns
      .filter((column) => !column.control.value)
      .map((column) => column.value);

    this.dialogRef.close({
      event: "save",
      data: { displayedColumns: selected, removedColumns: unselected },
    });
  }

  getHeader(column: string) {
    const { headers } = this.data;
    if (headers[column]?.label) {
      return this.translate.transform(headers[column].label, {
        ...headers[column].args,
      });
    } else if (headers[column]) {
      return this.translate.transform(headers[column]);
    } else {
      return this.translate.transform(column);
    }
  }
}
