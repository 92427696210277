import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { Contact, ContactProfile } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { CONTACTS_COLOR } from "@app/shared/utils/colors";
import {
  CONTACT_CREATE,
  CONTACT_CREATE_POTENTIAL_BUYER,
  MANAGE_POTENTIAL_BUYER_SINGLE,
} from "@app/shared/utils/tab-types";
import { POTENTIAL_BUYER } from "@app/shared/utils/task-types";
import { PotentialBuyer, Showing } from "@app/showings/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import {
  PotentialBuyerService as ManagePotentialBuyerSidebarService,
  PotentialBuyerService,
} from "@app/showings/services/potential-buyer.service";
import * as ccActions from "@app/sidebar/contacts/ngrx/create-contact.actions";
import {
  ContactShowingAttendance,
  ContactShowingAttendanceStatus,
} from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import { ContactFormComponent } from "@app/sidebar/shared/contact-form/contact-form.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as fromSidebar from "../../ngrx/sidebar.reducer";
import * as createContactActions from "../ngrx/create-contact.actions";
import * as fromCreateContact from "../ngrx/create-contact.reducer";
import * as fromIntegration from "@app/integrations/ngrx/integrations.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { SIDEBAR_MANAGE_POTENTIAL_BUYER_SINGLE_URL } from "@app/shared/utils/sidebar-tab-utils";

@Component({
  selector: "create-potential-buyer-contact",
  templateUrl: "./create-potential-buyer-contact.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-potential-buyer-contact.component.scss",
  ],
})
export class CreatePotentialBuyerContactComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;
  tabType = CONTACT_CREATE_POTENTIAL_BUYER;
  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  eaOid$: Observable<string>;
  showings$: Observable<Showing[]>;
  unsubscribe$ = new Subject<void>();
  potentialBuyerTaskTypeId$: Observable<number>;
  addToShowingLoading$: Observable<boolean>;
  attendanceStatuses: ContactShowingAttendance[] = [];
  closeSidebar = true;
  existingContactSelected: Contact;
  contactsColor = CONTACTS_COLOR;
  showingFilters = {};
  isBusy$: Observable<{ status: boolean }>;
  canCreatePotentialBuyer$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private pb: PotentialBuyerService,
    private managePbSidebarService: ManagePotentialBuyerSidebarService,
    public featureConfigManager: FeatureConfigManagerService,
    private contactService: ContactService,
    private objectService: ObjectService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.connectTab();
    this.getShowings();
    this.handleShowingFilters();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.eaOid$ = this.store.pipe(select(fromShowings.getShowingObjectEaOid));
    this.potentialBuyerTaskTypeId$ = this.store.pipe(
      select(fromConfig.getTaskTypeId(POTENTIAL_BUYER))
    );
    this.showings$ = this.store.pipe(select(fromCreateContact.getShowings));
    this.addToShowingLoading$ = this.store.pipe(
      select(fromCreateContact.getAddToShowingLoading)
    );
    this.isBusy$ = this.contactService.loading$.pipe(
      map((loading) => ({ status: loading }))
    );
    this.canCreatePotentialBuyer$ = this.store.pipe(
      select(
        fromIntegration.hasIntegration(
          IntegrationResource.CreatePartyPotentialBuyer
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  handleShowingFilters() {
    this.pb.filters$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filters) => (this.showingFilters = filters));
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  submit(closeSidebar): void {
    this.closeSidebar = closeSidebar;
    if (this.existingContactSelected) {
      this.addContactToShowing();
    } else {
      this.contactForm.submit();
    }
  }

  handleSubmit(valid: boolean): void {
    if (valid) {
      this.contactForm
        .getCreateContactRequestParams()
        .pipe(
          withLatestFrom(this.eaOid$),
          filter((value) => !!value),
          first()
        )
        .subscribe(([{ params, source }, eaOid]) => {
          const data = {
            ...params,
            originService: "CRM",
            type: "potentialbuyer",
            bidStatus: "unknown",
            eaOid,
          };

          const contactProfileParams: Partial<ContactProfile> = {
            ownsResidence: this.contactForm.form.get("ownsResidence").value,
          };
          this.createContact(data, source, contactProfileParams, [
            ...this.attendanceStatuses,
          ]);
        });
    }
  }

  onQuedroContactSelected(contact: Contact) {
    this.existingContactSelected = contact;
  }

  addContactToShowing(): void {
    this.eaOid$
      .pipe(
        filter((value) => !!value),
        first(),
        withLatestFrom(this.canCreatePotentialBuyer$)
      )
      .subscribe(([eaOid, canCreatePotentialBuyer]) => {
        this.contactService
          .postContactObjectConnection(this.existingContactSelected.contactId, {
            eaOid,
            type: "potentialbuyer",
            bidStatus: "unknown",
          })
          .subscribe(() => {
            const requests = this.attendanceStatuses
              .filter((attendance) => {
                return (
                  attendance.registrationStatus !==
                  ContactShowingAttendanceStatus.NotRegistered
                );
              })
              .map((attendance) => {
                const params = {
                  eaShowingId: attendance.eaShowingId,
                  showingId: attendance.showingId,
                  registrationStatus: attendance.registrationStatus,
                  showingStartTime: attendance.showingStartTime,
                };
                if (!!attendance.eaShowingSlotId) {
                  delete params.showingStartTime;
                  params["eaShowingSlotId"] = attendance.eaShowingSlotId;
                }
                return this.contactService.postContactShowingAttendance(
                  this.existingContactSelected.contactId,
                  params
                );
              });

            requests.push(this.contactService.refreshPotentialBuyersNew());

            const taskSet: TaskSet = {
              label: "adding_contact_to_showings",
              tasks: requests,
            };
            this.store.dispatch(addTaskSetToQueue(taskSet));

            if (canCreatePotentialBuyer) {
              this.sendDealPartyRequest(
                eaOid,
                this.existingContactSelected.contactId
              );
            }

            if (this.closeSidebar) {
              this.store.dispatch(
                sidebarActions.closeTabs({
                  tabTypes: [CONTACT_CREATE, CONTACT_CREATE_POTENTIAL_BUYER],
                })
              );
              this.replacePotentialBuyerSidebar(this.existingContactSelected);
            } else {
              this.existingContactSelected = null;
            }
          });
      });
  }

  handleAttendanceStatusChange(
    showingId: string,
    params: { status: string; eaShowingSlotId?: string },
    showingStartTime: any
  ): void {
    if (!params?.eaShowingSlotId) {
      const index = this.attendanceStatuses.findIndex(
        (attendance: ContactShowingAttendance) =>
          attendance.showingId === showingId
      );
      if (index === -1) {
        this.attendanceStatuses.push({
          registrationStatus: params.status,
          showingId,
          showingStartTime,
        });
      } else if (
        this.attendanceStatuses[index].registrationStatus === params.status
      ) {
        this.attendanceStatuses.splice(index, 1);
      } else {
        this.attendanceStatuses[index] = new ContactShowingAttendance({
          ...this.attendanceStatuses[index],
          registrationStatus: params.status,
        });
      }
    } else {
      const index = this.attendanceStatuses.findIndex(
        (attendance: ContactShowingAttendance) =>
          attendance.showingId === showingId &&
          attendance.eaShowingSlotId === params.eaShowingSlotId
      );
      if (index === -1) {
        this.attendanceStatuses.push({
          registrationStatus: params.status,
          showingId,
          showingStartTime,
          eaShowingSlotId: params.eaShowingSlotId,
        });
      } else if (
        this.attendanceStatuses[index].registrationStatus === params.status
      ) {
        this.attendanceStatuses.splice(index, 1);
      } else {
        this.attendanceStatuses[index] = new ContactShowingAttendance({
          ...this.attendanceStatuses[index],
          registrationStatus: params.status,
          eaShowingSlotId: params.eaShowingSlotId,
        });
      }
    }
  }

  getAttendance(showingId: string): ContactShowingAttendance | null {
    const foundStatus = this.attendanceStatuses.find(
      (attendanceStatus) => attendanceStatus.showingId === showingId
    );

    if (foundStatus) {
      return foundStatus;
    } else {
      if (this.showingFilters[showingId]) {
        const status = {
          registrationStatus: ContactShowingAttendanceStatus.Registered,
          showingId: showingId,
          showingStartTime: null,
        };
        this.attendanceStatuses.push(status);
        return status;
      }
    }
  }

  getSlotAttendances(showingId: string): ContactShowingAttendance[] | null {
    const foundStatuses = this.attendanceStatuses.filter(
      (attendanceStatus) => attendanceStatus.showingId === showingId
    );

    if (foundStatuses && foundStatuses.length > 0) {
      return foundStatuses;
    } else {
      return [];
    }
  }

  getShowings(): void {
    this.eaOid$
      .pipe(
        filter((value) => !!value),
        first()
      )
      .subscribe((eaOid) => {
        this.store.dispatch(createContactActions.getShowingsRequest({ eaOid }));
      });
  }

  onResetAttendance(): void {
    this.attendanceStatuses = [];
  }

  private createContact(
    params,
    source?: any,
    patchProfileParams?: Partial<ContactProfile>,
    showingAttendance?: ContactShowingAttendance[]
  ) {
    this.contactService
      .post(params)
      .pipe(
        withLatestFrom(this.canCreatePotentialBuyer$),
        map(([contact, canCreatePotentialBuyer]) => {
          if (!!source) {
            this.store.dispatch(
              ccActions.addContactToSourceRequest({ params })
            );
          }
          if (params.type === "potentialbuyer") {
            const objectConnectionParams = {
              type: params.type,
              eaOid: params.eaOid,
              bidStatus: params.bidStatus,
            };
            this.contactService
              .postContactObjectConnection(
                contact.contactId,
                objectConnectionParams
              )
              .pipe(
                map(() => {
                  showingAttendance
                    .filter(
                      (attendance: ContactShowingAttendance) =>
                        attendance.registrationStatus !==
                        ContactShowingAttendanceStatus.NotRegistered
                    )
                    .forEach((attendance) => {
                      this.store.dispatch(
                        ccActions.updateShowingAttendanceRequest({
                          contactId: contact.contactId,
                          attendance,
                        })
                      );
                    });
                })
              )
              .subscribe(() => {
                this.contactService
                  .refreshPotentialBuyersNew()
                  .pipe(first())
                  .subscribe();
              });

            if (canCreatePotentialBuyer) {
              this.sendDealPartyRequest(params.eaOid, contact.contactId);
            }
          }

          if (!!patchProfileParams) {
            this.contactService
              .patchProfile(contact.contactId, patchProfileParams)
              .subscribe();
          }
          if (this.closeSidebar) {
            this.store.dispatch(
              sidebarActions.closeTabs({
                tabTypes: [CONTACT_CREATE, CONTACT_CREATE_POTENTIAL_BUYER],
              })
            );
            this.replacePotentialBuyerSidebar(contact);
          } else {
            this.contactForm.initResetForm();
          }
        })
      )
      .subscribe();
  }

  private sendDealPartyRequest(eaOid: string, contactId: string) {
    this.objectService
      .postDealParty(eaOid, contactId, "potential-buyer", {
        interestLevel: "unknown",
      })
      .pipe(first())
      .subscribe(() => {
        setTimeout(() => {
          this.contactService
            .refreshPotentialBuyersNew()
            .pipe(first())
            .subscribe();
        }, 400);
      });
  }

  private replacePotentialBuyerSidebar(contact: Contact) {
    this.managePbSidebarService.selectedPotentialBuyer$.next(
      new PotentialBuyer(contact)
    );
    this.store
      .pipe(
        select(fromSidebar.getTab(MANAGE_POTENTIAL_BUYER_SINGLE)),
        filter((sb) => !!sb),
        first()
      )
      .subscribe(() => {
        this.store.dispatch(
          RouterActions.go({
            path: [
              "/crm/",
              {
                outlets: { sidebar: SIDEBAR_MANAGE_POTENTIAL_BUYER_SINGLE_URL },
              },
            ],
          })
        );
      });
  }
}
