import { Task, TaskType } from "@app/models";
import { createAction, props } from "@ngrx/store";
import { StatsLeadEmployee } from "@app/statistics/boxes/leads/utils";

export const toggle = createAction(
  "[Assign Lead] SHOW",
  props<{
    parameters: {
      showEmployee?: boolean;
      showOffice?: boolean;
      leadIds: Task[];
    };
  }>()
);

export const fetchEmployeesRequest = createAction(
  "[Assign Lead] FETCH_EMPLOYEES_REQUEST",
  props<{ parameters: Record<string, unknown> }>()
);

export const fetchEmployeesSuccess = createAction(
  "[Assign Lead] FETCH_EMPLOYEES_SUCCESS",
  props<{ employees: StatsLeadEmployee[] }>()
);

export const fetchEmployeesFail = createAction(
  "[Assign Lead] FETCH_EMPLOYEES_FAILURE"
);

export const fetchTaskTypesRequest = createAction(
  "[Assign Lead] FETCH_TASK_TYPES_REQUEST"
);

export const fetchTaskTypesSuccess = createAction(
  "[Assign Lead] FETCH_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const fetchTaskTypesFail = createAction(
  "[Assign Lead] FETCH_TASK_TYPES_FAILURE"
);

export const setIsLead = createAction(
  "[Assign Lead] SET_IS_LEAD",
  props<{ isLead: boolean }>()
);
