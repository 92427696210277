import { animate, style, transition, trigger } from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ENTERING_DURATION, EXITING_DURATION } from "@app/shared/animations";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ToastState } from "./ngrx/toast.reducer";

@Component({
  selector: "toast",
  styleUrls: ["./toast.component.scss"],
  templateUrl: "./toast.component.html",
  animations: [
    trigger("slideUpDown", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(100%)" }),
        animate(ENTERING_DURATION),
      ]),
      transition(":leave", [
        style({ opacity: 1, transform: "translateY(0)" }),
        animate(
          EXITING_DURATION,
          style({ opacity: 0, transform: "translateY(100%)" })
        ),
      ]),
    ]),
  ],
})
export class ToastComponent implements OnInit, OnDestroy {
  state: ToastState = null;
  display = false;
  timeoutId: any;
  subscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.subscription = this.store
      .select((state) => state.toast)
      .subscribe((state) => {
        this.state = state;
        this.display = true;
        this.setDuration(state.duration);
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  dispatch() {
    this.store.dispatch(this.state.toastAction.action);
    this.hideToast();
  }

  hideToast() {
    this.display = false;
    this.timeoutId = undefined;
  }

  setDuration(duration) {
    if (this.timeoutId) {
      this.clearCurrentTimeout();
    }
    this.timeoutId = setTimeout(() => this.hideToast(), duration);
  }

  clearCurrentTimeout() {
    clearTimeout(this.timeoutId);
    this.timeoutId = undefined;
  }
}
