import { Component, EventEmitter, Input, Output } from "@angular/core";

import { OutgoingMail } from "@app/models";
import moment from "moment";

@Component({
  selector: "dashboard-outgoing-mails-list-item",
  styleUrls: [
    "../../../../styles/list-item.component.scss",
    "./list-item.component.scss",
  ],
  template: `
    <li
      class="list-group-item"
      [ngClass]="{ 'list-border': color }"
      [style.border-left-color]="color"
      (click)="onOpenClicked()"
    >
      <div class="flex-container flex-space-between">
        <div class="flex-center">
          <div>
            <i class="{{ icon }} hidden-sm hidden-xs" [style.color]="color"></i>
          </div>
          <div>
            <div class="activity-name">{{ mail.activityName }}</div>
            <small>
              {{
                "outgoing_mail_notice"
                  | translate
                    : {
                        fromNow: getTimeLeft(mail),
                        count: mail.numberOfPreGeneratedReceivers
                      }
              }}
            </small>
          </div>
        </div>
        <div class="controls">
          <a
            (click)="preview($event)"
            class="btn btn-xs btn-default"
            [tooltip]="'preview' | translate"
          >
            <i class="fa fa-eye"></i>
          </a>
        </div>
      </div>
    </li>
  `,
})
export class OutgoingMailListItemComponent {
  @Input() mail: OutgoingMail;
  @Input() icon: string;
  @Input() color: string;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onPreviewClicked = new EventEmitter<OutgoingMail>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onBatchClicked = new EventEmitter<OutgoingMail>();

  getTimeLeft(mail: OutgoingMail) {
    return moment(mail.sendTime, "YYYY-MM-DD HH:mm").fromNow();
  }

  preview(e: Event) {
    e.stopPropagation();
    this.onPreviewClicked.emit(this.mail);
  }

  onOpenClicked() {
    this.onBatchClicked.emit(this.mail);
  }
}
