<ng-container *ngIf="unseenProductNews$ | async as unseen">
  <div
    [ngClass]="unseen.count > 0 ? 'has-news' : 'has-no-news'"
    [tooltip]="
      (unseen.count > 0 ? 'product_news_tooltip' : 'old_product_news_tooltip')
        | translate
    "
    (click)="showModal()"
    class="news-wrapper"
    [matBadge]="unseen.count"
    [matBadgeHidden]="unseen.count === 0"
    matBadgePosition="after"
    matBadgeColor="warn"
  >
    <app-icon name="bullhorn"></app-icon>
  </div>
</ng-container>
