import { Pipe, PipeTransform } from "@angular/core";
import { ChipColor } from "@app/shared/modules/ui-components/q-chip/q-chip.component";

@Pipe({
  name: "objectStatusChipColor",
})
export class ObjectStatusChipColorPipe implements PipeTransform {
  transform(value: string): ChipColor {
    switch (value) {
      case "26":
        return "2";
      case "10":
      case "32":
      case "51":
        return "3";
      case "7":
      case "29":
      case "33":
      case "21":
      case "52":
        return "4";
      case "0":
        return "5";
      default:
        return "1";
    }
  }
}
