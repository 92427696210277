import { Component, Input } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { SIDEBAR_CONTACTS_EDIT_URL } from "@app/shared/utils/sidebar-tab-utils";

@Component({
  selector: "contact-edit-button",
  templateUrl: "./contact-edit-button.component.html",
  styleUrls: ["./contact-edit-button.component.scss"],
})
export class ContactEditButtonComponent {
  @Input() contactId: string;

  openEditSidebar(event: Event) {
    event.stopPropagation();
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_CONTACTS_EDIT_URL(this.contactId) } },
        ],
      })
    );
  }

  constructor(private store: Store<AppState>) {}
}
