import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-micro-page",
  templateUrl: "./micro-page.component.html",
  styleUrls: ["./micro-page.component.scss"],
})
export class MicroPageComponent {
  @Input() breadcrumbs: string[];
  @Output() newBreadcrumbs: EventEmitter<string[]> = new EventEmitter<
    string[]
  >();

  breadcrumbClick(index): void {
    this.breadcrumbs.splice(index, 10);
    this.newBreadcrumbs.emit(this.breadcrumbs);
  }
}
