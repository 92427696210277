import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { QObject } from "@app/models";
import { ShowingObject } from "@app/showings/models";
import { Project } from "@app/showings/models/project";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { catchError, first, map, Observable, takeUntil } from "rxjs";

@Injectable({ providedIn: "root" })
export class ObjectService extends CustomEntityCollectionServiceBase<
  QObject & ShowingObject & Project
> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Object", serviceElementsFactory);
  }

  getById = (id: string, additionalParams?: any) => {
    this.setLoading(true);
    return this.apiService
      .get(`objects/${id}`, { ...additionalParams }, "api")
      .pipe(
        map((response: any) => {
          const object = {};
          Object.keys(response).forEach((key) => {
            if (response[key] !== null) {
              object[key] = response[key];
            }
          });
          this.upsertOneInCache(object);
          this.setLoading(false);
          return response;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({
              message: "object_load_failed",
            })
          );
          return err;
        })
      );
  };

  // @ts-ignore
  getWithQuery = (params, setListDefaults = true) => {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }
    return this.apiService
      .get(`objects-multi-locations/search`, { ...params }, "api")
      .pipe(
        takeUntil(this.cancelExistingRequest$),
        map((response: any) => {
          const objects = response.objectsMultiLocations;
          this.upsertManyInCache(objects);
          this.setLoading(false);
          if (setListDefaults) {
            this.setListDefaults(objects, response);
          }
          return objects;
        }),
        catchError((err) => {
          this.fetchErrorHandler(setListDefaults);
          return err;
        })
      );
  };

  // @ts-ignore
  getWithPostQuery = (params, setListDefaults = true) => {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }
    return this.apiService
      .post(`objects-multi-locations/search`, { ...params }, "api")
      .pipe(
        takeUntil(this.cancelExistingRequest$),
        map((response: any) => {
          const objects = response.objectsMultiLocations;
          this.upsertManyInCache(objects);
          this.setLoading(false);
          if (setListDefaults) {
            this.setListDefaults(objects, response);
          }
          return objects;
        }),
        catchError((err) => {
          this.fetchErrorHandler(setListDefaults);
          return err;
        })
      );
  };

  // Showing Object APIs
  refreshShowingObject = (): Observable<any> | any => {};
  getShowingObjectById = (id: string, additionalParams?: any) => {
    this.setLoading(true);
    this.refreshShowingObject = () =>
      this.getShowingObjectById(id, additionalParams);
    return this.apiService
      .get(`showing-objects/${id}`, { ...additionalParams })
      .pipe(
        map((response: any) => {
          // Todo: Do we want to update the contact state with seller/buyers? object.sellers?
          this.upsertOneInCache(response);
          this.setLoading(false);
          return response;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({
              message: "object_load_failed",
            })
          );
          return err;
        })
      );
  };

  // this api was unused when I unautomapped the projects api, there might be changes to the
  // additionalParams, though the automapped api didn't accept any params so it wouldn't have worked anyway
  // it's now possible to explicitly sort and paginate the object list, as well as apply publishOnNet filter
  // Project APIs
  getProjectById = (id: string, additionalParams?: any) => {
    return this.apiService.get(`projects/${id}`, { ...additionalParams }).pipe(
      map((response: any) => {
        // Todo: Do we want to update the contact state with seller/buyers? object.sellers?
        this.upsertOneInCache(response);
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({
            message: "object_load_failed",
          })
        );
        return err;
      })
    );
  };

  postVideo = (eaOid: string, additionalParams?: any) => {
    const force = !!additionalParams?.force || true;
    return this.apiService
      .post(`object/${eaOid}/video/order?force=${force}`, {}, "api")
      .pipe(
        map(() => {
          this.appStore.dispatch(
            toastActions.success({ message: "video_created_or_updated" })
          );

          return this.getById(eaOid, { getContacts: true, getLinks: true });
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "failed_video_created_or_updated" })
          );
          return err;
        })
      );
  };

  deleteVideo = (eaOid: string, videoIndex: number) => {
    return this.apiService.delete(`object/${eaOid}/video`, {}, "api").pipe(
      map(() => {
        this.appStore.dispatch(
          toastActions.success({ message: "video_deleted" })
        );

        this.entityMap$
          .pipe(
            map((em) => em[eaOid]),
            first()
          )
          .subscribe((object) => {
            const updatedObjectLinks = [...object.links];
            updatedObjectLinks.splice(videoIndex, 1);
            const updatedObject = {
              ...object,
              links: [...updatedObjectLinks],
            };
            this.updateOneInCache(updatedObject);
          });

        return true;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({
            message: "video_delete_failed",
          })
        );
        return err;
      })
    );
  };

  // Contact Added to MSPEC
  postDealParty = (
    eaOid: string,
    contactId: string,
    dealType: string, //"potential-buyer" | "seller" | "buyer",
    params: any
  ) => {
    this.setLoading(true);
    return this.apiService
      .post(
        `objects/${eaOid}/deal-party/${dealType}/${contactId}`,
        {
          ...params,
        },
        Categories.Integrations
      )
      .pipe(
        map((response) => {
          this.setLoading(false);
          return response;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({
              message: "operation_failed",
            })
          );
          return err;
        })
      );
  };

  private fetchErrorHandler(
    setListDefaults: boolean = true,
    message: string = "objects_load_failed"
  ) {
    this.setLoading(false);
    this.upsertManyInCache([]);
    if (setListDefaults) {
      this.setListDefaults([], null);
    }
    this.appStore.dispatch(toastActions.danger({ message: message }));
  }
}
