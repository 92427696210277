import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsActions from "../ngrx/statistics.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface FilterState {
  year?: string | number;
  month?: string;
  to: string;
  from: string;
  eaOfficeIds: string[];
  eaEmployeeId: string[];
}

export const initialState: FilterState = {
  year: "",
  month: "",
  to: "",
  from: "",
  eaOfficeIds: [],
  eaEmployeeId: [],
};

export function filterReducer(state: FilterState, action: Action): FilterState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsActions.setFilters, (state, { filters }) => ({
    ...state,
    ...filters,
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
export const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.filters
);
