import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Office, TypedPaginationListDTO } from "@app/models";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import * as sharedActions from "./shared.actions";
import { Region } from "@app/models/region";

@Injectable()
export class SharedEffects {
  loadOfficesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.loadOfficesRequest),
      switchMap(({ filters }) =>
        this.apiService.get("offices/search", filters, "api", false).pipe(
          map((response: TypedPaginationListDTO<Office>) =>
            sharedActions.loadOfficesSuccess({ offices: response.rows })
          ),
          catchError(() => observableOf(sharedActions.loadOfficesFail()))
        )
      )
    )
  );

  loadRegionsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.loadRegionsRequest),
      switchMap(({ params }) =>
        this.apiService.get("regions/search", params, "api", false).pipe(
          map((response: TypedPaginationListDTO<Region>) =>
            sharedActions.loadRegionsSuccess({ regions: response.rows })
          ),
          catchError(() => observableOf(sharedActions.loadRegionsFail()))
        )
      )
    )
  );

  loadObjectTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.loadObjectTypesRequest),
      switchMap(({ params }) =>
        this.apiService
          .get("object-types/search", {
            countryIso: params.countryIso,
            objectTypeIds: params.objectTypeIds,
          })
          .pipe(
            map((response: TypedPaginationListDTO<ObjectType>) =>
              sharedActions.loadObjectTypesSuccess({
                objectTypes: response.rows,
              })
            ),
            catchError(() => observableOf(sharedActions.loadObjectTypesFail()))
          )
      )
    )
  );

  loadObjectStatusRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.loadObjectStatusRequest),
      switchMap(({ country }) =>
        this.apiService
          .get("object-status/search", {
            country: country,
          })
          .pipe(
            map((response: { rows: ObjectStatus[] }) =>
              sharedActions.loadObjectStatusSuccess({
                objectStatus: response.rows,
              })
            ),
            catchError(() => observableOf(sharedActions.loadObjectStatusFail()))
          )
      )
    )
  );

  ssoifyLinkAndOpenItRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.ssoifyLinkAndOpenItRequest),
      switchMap(({ params }) =>
        this.apiService
          .post(
            `ssoify/link/for/${params.integration}`,
            {
              url: params.urlBearer,
            },
            "integrations"
          )
          .pipe(
            map((response: { url: string }) =>
              sharedActions.ssoifyLinkAndOpenItSuccess({ url: response.url })
            ),
            catchError(() =>
              observableOf(sharedActions.ssoifyLinkAndOpenItFail())
            )
          )
      )
    )
  );

  ssoifyLinkAndOpenItSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sharedActions.ssoifyLinkAndOpenItSuccess),
        map(({ url }) => openLinkInNewTab(url))
      ),
    { dispatch: false }
  );

  ssoifyLinkAndOpenItFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedActions.ssoifyLinkAndOpenItFail),
      map(() =>
        toastActions.danger({ message: "failed_loading_external_link" })
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
