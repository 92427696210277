import {
  DOES_NOT_OWN_RESIDENCE,
  OWNS_RESIDENCE,
  RESIDENCE_UNKNOWN,
} from "@app/contacts/utils/profile-residence-status-constants";

export const ContactSex = [
  {
    display: "male",
    label: "male",
    value: "male",
  },
  {
    display: "female",
    label: "female",
    value: "female",
  },
  {
    display: "unknown",
    label: "unknown",
    value: "",
  },
];

export const ContactMaritalStatus = [
  {
    display: "married",
    label: "married",
    value: "Married",
  },
  {
    display: "not_married",
    label: "not_married",
    value: "Notmarried",
  },
  {
    display: "unknown",
    label: "unknown",
    value: "",
  },
];

export const ContactOwnsResidence = [
  {
    label: "yes",
    display: "yes",
    value: OWNS_RESIDENCE,
  },
  {
    label: "no",
    display: "no",
    value: DOES_NOT_OWN_RESIDENCE,
  },
  {
    label: "unknown",
    display: "unknown",
    value: RESIDENCE_UNKNOWN,
  },
];
