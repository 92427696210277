import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CalendarWidgetModule } from "@app/shared/modules/calendar-widget/calendar-widget.module";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { SalesMeetingCreateContactComponent } from "./sales-meeting-create-contact/sales-meeting-create-contact.component";
import { SalesMeetingCreateComponent } from "./sales-meeting-create/sales-meeting-create.component";
import { SalesMeetingEditComponent } from "./sales-meeting-edit/sales-meeting-edit.component";
import { SalesMeetingFormComponent } from "./sales-meeting-form/sales-meeting-form.component";
import { SalesMeetingSearchContactComponent } from "./sales-meeting-search-contact/sales-meeting-search-contact.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SidebarSharedModule,
    SearchContactModule,
    ContactComponentsModule,
    UiComponentsModule,
    FormComponentsModule,
    SearchAddressModule,
    CalendarWidgetModule,
    TooltipModule,
    UtilModule,
    MatTooltipModule,
  ],
  declarations: [
    SalesMeetingSearchContactComponent,
    SalesMeetingCreateComponent,
    SalesMeetingFormComponent,
    SalesMeetingEditComponent,
    SalesMeetingCreateContactComponent,
  ],
})
export class SalesMeetingModule {}
