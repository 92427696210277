import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { Bid } from "@app/integrations/bidding/models/bid";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class BidService extends CustomEntityCollectionServiceBase<Bid> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("Bid", serviceElementsFactory);
  }

  post = (eaOid: string, contactId: string, params?: unknown) => {
    this.setLoading(true);
    return this.apiService
      .post(
        `object/${eaOid}/potential-buyer/${contactId}/bids`,
        params,
        Categories.Integrations
      )
      .pipe(
        map((res: { bid: Bid }) => {
          this.setLoading(false);
          this.upsertOneInCache(res.bid);
          this.setListDefaults([...this.currentList$.value, res.bid]);
          return res.bid;
        }),
        catchError((err) => {
          this.setLoading(false);
          return err;
        })
      );
  };

  remove = (eaOid: string, bidId: string) => {
    return this.apiService
      .delete(`object/${eaOid}/bids/${bidId}`, {}, Categories.Integrations)
      .pipe(
        map(() => {
          this.updateOneInCache({ externalBidId: bidId, isCanceled: true });
          return true;
        }),
        catchError((err) => err)
      );
  };
}
