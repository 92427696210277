import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AssignLeadButtonsComponent } from "@app/shared/modules/assign-lead-buttons/assign-lead-buttons.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, TranslateModule, UiComponentsModule],
  declarations: [AssignLeadButtonsComponent],
  exports: [AssignLeadButtonsComponent],
})
export class AssignLeadButtonsModule {}
