import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as _ from "lodash";
import { SidebarTab, TabPersist } from "../models/sidebar-tab";
import * as sidebarActions from "./sidebar.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface SidebarState {
  tabs: SidebarTab[];
  minimized: boolean;
}

export const initialState: SidebarState = {
  tabs: [],
  minimized: false,
};

export function sidebarReducer(
  state: SidebarState,
  action: Action
): SidebarState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(sidebarActions.addTab, (state, { tab }) => ({
    ...state,
    tabs: [...state.tabs, tab],
  })),
  on(sidebarActions.replaceTab, (state, { tabType, newTab }) => {
    const tabs = state.tabs.map((tab) => (tab.type === tabType ? newTab : tab));
    return {
      ...state,
      tabs,
    };
  }),
  on(sidebarActions.closeTab, (state, { tabType }) => {
    if (tabType) {
      return {
        ...state,
        tabs: state.tabs.filter((tab) => tab.type !== tabType),
      };
    }
    return state;
  }),
  on(sidebarActions.closeTabs, (state, { tabTypes }) => {
    const remainingTabs = state.tabs.filter(
      (tab) => !tabTypes.includes(tab.type)
    );

    return {
      ...state,
      tabs: remainingTabs,
      minimized: remainingTabs.length === 0,
    };
  }),
  on(sidebarActions.setInitialTabValue, (state, { tabType, value }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.initialValue = value;
      tab.dirty = false;
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.setTabValue, (state, { tabType, value }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.currentValue = value;
      tab.dirty = !_.isEqual(tab.initialValue, tab.currentValue);
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.markAsDirty, (state, { tabType }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.dirty = true;
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.resetDirty, (state, { tabType }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.dirty = false;
      tab.initialValue = tab.currentValue;
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.resetTab, (state, { tabType }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.dirty = false;
      tab.initialValue = null;
      tab.currentValue = null;
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.setTabPersistence, (state, { tabType, persistence }) => {
    const tab = _.clone(state.tabs.find((t) => t.type === tabType));
    if (tab) {
      tab.persist = persistence;
      return {
        ...state,
        tabs: state.tabs.map((t) => (t.type === tabType ? tab : t)),
      };
    }
    return state;
  }),
  on(sidebarActions.closeAllNonPersistentTabs, (state) => {
    const tabs = state.tabs
      .filter((tab) => tab.persist !== TabPersist.NEVER)
      .map((t) => {
        if (TabPersist.ONCE) {
          const tab = _.clone(t);
          tab.persist = TabPersist.NEVER;
          return tab;
        }
        return t;
      });
    return { ...state, tabs };
  }),
  on(sidebarActions.show, (state) => ({ ...state, minimized: false })),
  on(sidebarActions.hide, (state) => ({ ...state, minimized: true })),
  on(sidebarActions.toggle, (state) => ({
    ...state,
    minimized: !state.minimized,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.sidebar
);

export const getTabs = createSelector(
  getState,
  (state: SidebarState) => state.tabs
);

export const getTab = (type: string) =>
  createSelector(getState, (state: SidebarState) =>
    state.tabs.find((tab) => tab.type === type)
  );

export const isDirty = createSelector(getState, (state: SidebarState) =>
  state.tabs.some((tab) => tab.dirty)
);

export const sidebarIsMinimized = createSelector(
  getState,
  (state: SidebarState) => state.minimized
);
