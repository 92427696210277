import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";

import { Action } from "@app/models";

import { AppState } from "@app/app.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { Store } from "@ngrx/store";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-one2one-email",
  template: `
    <div class="display-flex-start">
      <span>{{ system }}</span>
      <span [innerHTML]="text | async"></span>
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
    <div>
      <small>{{ item.param1 }}</small>
    </div>
    <div>
      <small *ngIf="item.param3?.length > 0" class="text-muted">
        <strong> {{ "message" | translate }}: </strong>
        <span class="preview-link" (click)="openPreviewModal(item.param3)">
          <i class="icon-eye"></i>
          {{ "view" | translate }}
        </span>
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionOne2OneEmailComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  system = "";
  listenerName: Function;

  className = "action-one2one-email-name";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    private store: Store<AppState>,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_one2one_email_text", {
          you: youString,
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
        })
      )
    );

    if (this.item.employeeFullName.length === 0) {
      this.system = this.translateService.instant("the_system");
    }
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }

  openPreviewModal(html: string): void {
    this.store.dispatch(
      previewModalActions.showPreviewModal({
        parameters: { html },
      })
    );
  }
}
