import { API_SHOWINGS_DEFAULT_LIMIT } from "@app/lists/lists-showings/utils/lists-showings-defaults";
import { ShowingObject } from "@app/showings/models";
import { createAction, props } from "@ngrx/store";
import { TypedPaginationListDTO } from "@app/models";

const defaultFilters = {
  limit: API_SHOWINGS_DEFAULT_LIMIT,
  offset: 0,
};

export const getShowings = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultFilters,
    ...params,
  };
  return getShowingsRequest({ filters });
};

export const getShowingsRequest = createAction(
  "[Lists Showings] GET_SHOWINGS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getShowingsSuccess = createAction(
  "[Lists Showings] GET_SHOWINGS_SUCCESS",
  props<{ showings: TypedPaginationListDTO<ShowingObject> }>()
);

export const getShowingsFailed = createAction(
  "[Lists Showings] GET_SHOWINGS_FAILED"
);

export const getMoreShowings = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultFilters,
    ...params,
  };
  return getMoreShowingsRequest({ filters });
};

export const getMoreShowingsRequest = createAction(
  "[Lists Showings] GET_MORE_SHOWINGS_REQUEST",
  props<{
    filters: Record<string, unknown>;
  }>()
);

export const getMoreShowingsSuccess = createAction(
  "[Lists Showings] GET_MORE_SHOWINGS_SUCCESS",
  props<{ showings: TypedPaginationListDTO<ShowingObject> }>()
);

export const getMoreShowingsFailed = createAction(
  "[Lists Showings] GET_MORE_SHOWINGS_FAILED"
);

export const reset = createAction("[Lists Showings] RESET");
