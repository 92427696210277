export interface NpsConfig {
  enabled: boolean;
  use_average_nps_value: boolean;
  title: string;
  min: number;
  max: number;
  info_link: string;
  video_url: string;
  help_text: string;
  months_back: number;
}

export const NpsConfigDefaults = {
  enabled: false,
  use_average_nps_value: false,
  title: "nps",
  min: -100,
  max: 100,
  info_link: null,
  video_url: null,
  help_text: null,
  months_back: 3,
};

export interface NpsListConfig {
  enabled: boolean;
  max_single_score: number;
}

export const NpsListConfigDefaults = {
  enabled: false,
  max_single_score: 100,
};
