import { OverdueTaskListState } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as overdueTaskListActions from "./overdue-task-list.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export const initialState: OverdueTaskListState = {
  tasks: [],
  isLoading: false,
  loaded: false,
  totalNumberOfTasks: 0,
  numberOfTasksLeftToLoad: 0,
  allTasksLoaded: false,
  taskListConfig: {
    expanded: false,
    limit: 10,
  },
  currentScrollY: 0,
};

// TODO: Remove "| any" from action type
export function overdueTaskListReducer(
  state: OverdueTaskListState,
  action: Action
): OverdueTaskListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(overdueTaskListActions.getOverdueRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(overdueTaskListActions.getOverdueNoTasksFound, (state) => ({
    ...state,
    isLoading: false,
    allTasksLoaded: true,
    loaded: true,
  })),
  on(
    overdueTaskListActions.getOverdueSuccess,
    (state, { tasks, listStart, totalRows }) => {
      const numberOfTasksLeftToLoad = totalRows - listStart - tasks.length;
      return {
        ...state,
        isLoading: false,
        allTasksLoaded: totalRows <= 10 || numberOfTasksLeftToLoad === 0,
        totalNumberOfTasks: totalRows,
        numberOfTasksLeftToLoad,
        tasks: tasks,
        loaded: true,
      };
    }
  ),
  on(overdueTaskListActions.setListConfig, (state, { payload }) => ({
    ...state,
    taskListConfig: { ...state.taskListConfig, ...payload },
  })),
  on(overdueTaskListActions.setListConfigLimit, (state) => ({ ...state })),
  on(overdueTaskListActions.reset, (state) => ({
    ...initialState,
    taskListConfig: {
      ...state.taskListConfig,
      limit: Math.ceil(state.tasks.length / 10) * 10,
    },
    currentScrollY: document.documentElement.scrollTop,
  }))
);

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.overdueTaskList
);

export const getScrollYPosition = createSelector(
  getState,
  (state: OverdueTaskListState) => state.currentScrollY
);

export const getTasks = createSelector(
  getState,
  (state: OverdueTaskListState) => state.tasks
);

export const getTasksLength = createSelector(
  getState,
  (state: OverdueTaskListState) => state.tasks.length
);

export const getTotalNumberOfTasks = createSelector(
  getState,
  (state: OverdueTaskListState) => state.totalNumberOfTasks
);

export const getOverdueTasksLoaded = createSelector(
  getState,
  (state: OverdueTaskListState) => state.loaded
);

export const getOverdueTasksLoading = createSelector(
  getState,
  (state: OverdueTaskListState) => state.isLoading
);
