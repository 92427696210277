import { TaskType } from "@app/models";
import {
  LeadStatisticsEmployee,
  LeadStatisticsOffice,
  LeadStatisticsOrigin,
  LeadStatisticsTaskType,
  LeadStatisticsTotal,
} from "@app/statistics/statistics-leads-new/components/statistics-leads-new-content/utils/lead-statistics";
import { createAction, props } from "@ngrx/store";

export const getLeadsStatisticsForChainRequest = createAction(
  "[Lead Statistics] GET_RESULTS_FOR_CHAIN_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getLeadsStatisticsForChainSuccess = createAction(
  "[Lead Statistics] GET_RESULTS_FOR_CHAIN_SUCCESS",
  props<{
    total: LeadStatisticsTotal;
    offices: LeadStatisticsOffice[];
    employees: LeadStatisticsEmployee[];
    taskTypes: LeadStatisticsTaskType[];
    origins?: LeadStatisticsOrigin[];
  }>()
);

export const getLeadsStatisticsForChainFail = createAction(
  "[Lead Statistics] GET_RESULTS_FOR_CHAIN_FAIL"
);

export const getTaskTypesRequest = createAction(
  "[Lead Statistics] GET_TASK_TYPES_REQUEST"
);

export const getTaskTypesSuccess = createAction(
  "[Lead Statistics] GET_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const getTaskTypesFail = createAction(
  "[Lead Statistics] GET_TASK_TYPES_FAIL"
);
export const getLeadsCSVRequest = createAction(
  "[Lead Statistics] GET_LEADS_CSV_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getLeadsCSVSuccess = createAction(
  "[Lead Statistics] GET_LEADS_CSV_SUCCESS"
);

export const getLeadsCSVFail = createAction(
  "[Lead Statistics] GET_LEADS_CSV_FAIL`)"
);
