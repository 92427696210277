import moment from "moment";

export const isBiggerThanNow = (date: unknown): boolean => {
  if (!date) {
    return false;
  }
  const today = moment();
  const time = moment(date).format("YYYY-MM-DD HH:mm");
  const diff = today.diff(time);

  return diff < 0;
};

export const isChristmasTime = (): boolean => {
  const currentDate = moment();

  // Check if the current month is December
  if (currentDate.month() === 11) {
    // Check if the current day is between December 1 and December 26
    if (currentDate.date() >= 1 && currentDate.date() <= 26) {
      return true;
    }
  }

  return false;
};

export const formatSettingDate = (duration: unknown, startOfDay: boolean) => {
  if (duration && typeof duration === "number") {
    return startOfDay
      ? moment().add(duration, "days").startOf("day").toDate()
      : moment().add(duration, "days").endOf("day").toDate();
  }
  return duration || null;
};
