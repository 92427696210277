import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-tag",
  template: `
    <div
      [ngStyle]="getStyle()"
      class="tag"
      [class.tag--clickable]="clickable"
      [popover]="popOver && popOver.length > 0 ? popTemplate : null"
      triggers="mouseenter:mouseleave"
    >
      <ng-content></ng-content>
      <button (click)="remove.emit()" *ngIf="removable">
        <app-icon name="times"></app-icon>
      </button>
    </div>

    <ng-template #popTemplate>
      {{ popOver }}
    </ng-template>
  `,
  styleUrls: ["./tag.component.scss"],
})
export class TagComponent {
  @Input() popOver: string;
  @Input() popOverTitle: string;
  @Input() removable: boolean;
  @Input() tagColor = "#009ada";
  @Input() coloringMode: "FILLED" | "HOLLOW" = "FILLED";
  @Input() size: "DEFAULT" | "COMPACT" | "FAT" = "DEFAULT";
  @Input() width: "DEFAULT" | "FULL_WIDTH" = "DEFAULT";
  @Input() clickable = false;

  @Output() remove: EventEmitter<void> = new EventEmitter<void>();

  getStyle() {
    switch (this.coloringMode) {
      case "FILLED":
        return this.getFilledStyle();
      case "HOLLOW":
      default:
        return this.getHollowStyle();
    }
  }

  getFilledStyle() {
    const style = {
      background: this.tagColor,
      "border-color": "#FFFFFF",
      color: "#FFFFFF",
    };

    if (this.size === "DEFAULT") {
      style["padding"] = ".4rem 1rem";
    }

    if (this.size === "COMPACT") {
      style["padding-top"] = "0";
      style["padding-bottom"] = "0";
    }

    if (this.size === "FAT") {
      style["padding"] = ".75rem 1rem";
      style["font-size"] = "1rem";
      style["font-style"] = "normal";
      style["letter-spacing"] = "1px";
    }

    if (this.width === "FULL_WIDTH") {
      style["width"] = "100%";
    }

    if (!this.removable) {
      style["padding-right"] = "1rem";
    }

    return style;
  }

  getHollowStyle() {
    const style = {
      background: "#FFFFFF",
      "border-color": this.tagColor,
      border: "2px solid",
      color: this.tagColor,
    };

    if (this.size === "COMPACT") {
      style["padding-top"] = "0";
      style["padding-bottom"] = "0";
    }
    if (this.size === "FAT") {
      style["padding"] = ".75em 1rem";
      style["font-size"] = "1rem";
      style["font-style"] = "normal";
      style["letter-spacing"] = "1px";
    }
    if (!this.removable) {
      style["padding-right"] = "1rem";
    }

    if (this.width === "FULL_WIDTH") {
      style["width"] = "100%";
    }

    return style;
  }
}
