import * as standardModalActions from "@app/core/components/standard-modal/ngrx/standard-modal.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface BodyLink {
  text: string;
  url: any[];
}

export interface StandardModalState {
  header: string;
  bodyHtml: string;
  descriptionTooltip: string;
  bodyLinks: BodyLink[];
  bodyExternalLinks: { text: string; eaOid: string }[];
  confirmButton: BodyLink | null;
  show: boolean;
}

export const initialState: StandardModalState = {
  header: "",
  bodyHtml: "",
  descriptionTooltip: "",
  bodyLinks: [],
  bodyExternalLinks: [],
  confirmButton: null,
  show: false,
};

export function standardModalReducer(
  state: StandardModalState,
  action: Action
): StandardModalState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(standardModalActions.show, (state, parameters) => ({
    ...state,
    header: parameters.header,
    bodyHtml: parameters.bodyHtml,
    descriptionTooltip: parameters.descriptionTooltip,
    confirmButton: parameters.confirmButton ? parameters.confirmButton : null,
    bodyLinks: parameters.bodyLinks ? parameters.bodyLinks : [],
    bodyExternalLinks: parameters.bodyExternalLinks
      ? parameters.bodyExternalLinks
      : [],
    show: true,
  })),
  on(standardModalActions.close, (state) => ({
    ...state,
    show: false,
  })),
  on(standardModalActions.reset, () => ({ ...initialState }))
);

const selectFeature =
  createFeatureSelector<StandardModalState>("standardModal");

export const getStandardModalHeader = createSelector(
  selectFeature,
  (state: StandardModalState) => state.header
);

export const getStandardModalDescriptionTooltip = createSelector(
  selectFeature,
  (state: StandardModalState) => state.descriptionTooltip
);

export const getStandardModalBodyHtml = createSelector(
  selectFeature,
  (state: StandardModalState) => state.bodyHtml
);

export const getStandardModalBodyLinks = createSelector(
  selectFeature,
  (state: StandardModalState) => state.bodyLinks
);

export const getStandardModalBodyExternalLinks = createSelector(
  selectFeature,
  (state: StandardModalState) => state.bodyExternalLinks
);

export const getStandardModalConfirmButton = createSelector(
  selectFeature,
  (state: StandardModalState) => state.confirmButton
);

export const getStandardModalShowStatus = createSelector(
  selectFeature,
  (state: StandardModalState) => state.show
);
