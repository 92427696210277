import { Component, Input } from "@angular/core";
import { Action } from "@app/models";

@Component({
  selector: "contact-action-call-registered-email",
  template: `
    <ng-container>
      <h4>
        {{ action.timestamp }}
        <small>{{ "of" | translate }} {{ action.employeeFullName }}</small>
      </h4>
      <p>
        <b>{{ "status" | translate }}:</b> {{ action.param1 | translate }}
      </p>
      <p>
        <b>{{ "comment" | translate }}:</b> {{ action.param3 }}
      </p>
    </ng-container>
  `,
  styleUrls: ["./common.scss"],
})
export class ContactActionCallRegisteredComponent {
  @Input() action: Action;
}
