import * as models from "@app/models";
import { PaginationListDTO } from "@app/models/dto/pagination-list-dto";
import { createAction, props } from "@ngrx/store";
import { RequestObject } from "../models";

const defaultParams = {
  offset: 0,
  limit: 25,
  visibility: "employee,consumer,internal",
  sortBy: "timestamp",
  sortOrder: "desc",
};

export const getLatestActions = (requestProperties: RequestObject) => {
  const params = {
    ...defaultParams,
    ...requestProperties,
  };
  return getLatestActionsRequest({ params });
};

export const getLatestActionsRequest = createAction(
  "[Action] GET_LATEST_ACTIONS_REQUEST",
  props<{
    params: Record<string, unknown>;
  }>()
);

export const getLatestActionsSuccess = createAction(
  "[Action] GET_LATEST_ACTIONS_SUCCESS",
  props<{
    payload: PaginationListDTO;
  }>()
);

export const getLatestActionsFailed = createAction(
  "[Action] GET_LATEST_ACTIONS_FAILED"
);

export const getMoreLatestActions = (requestProperties: RequestObject) => {
  const params = {
    ...defaultParams,
    ...requestProperties,
  };
  return getMoreLatestActionsRequest({ params });
};

export const getMoreLatestActionsRequest = createAction(
  "[Action] GET_MORE_LATEST_ACTIONS_REQUEST",
  props<{
    params: Record<string, unknown>;
  }>()
);

export const getMoreLatestActionsSuccess = createAction(
  "[Action] GET_MORE_LATEST_ACTIONS_SUCCESS",
  props<{ payload: PaginationListDTO }>()
);

export const getMoreLatestActionsFailed = createAction(
  "[Action] GET_MORE_LATEST_ACTIONS_FAILED"
);

export const removeAction = (action: models.Action) => {
  const params = {
    actionId: action.actionId,
    eaEmployeeId: action.eaEmployeeId,
  };
  return removeActionRequest({ ...params });
};

export const removeActionRequest = createAction(
  "[Action] DELETE_ACTION_REQUEST",
  props<{ actionId: string; eaEmployeeId: string }>()
);

export const removeActionSuccess = createAction(
  "[Action] DELETE_ACTION_SUCCESS",
  props<{
    actionId: string;
  }>()
);

export const removeActionFailed = createAction("[Action] DELETE_ACTION_FAILED");
