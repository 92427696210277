<div class="list-container">
  <list-header
    (expandedChanged)="setExpanded($event)"
    *ngIf="showHeader"
    [badgeColor]="badgeColor"
    [badgeSpinner]="badgeSpinner"
    [badgeText]="badgeText"
    [badge]="badge"
    [isExpandable]="true"
    [isExpanded]="isExpanded"
    [itemType]="itemType"
  >
    <span>
      {{ title | translate }}
      <i
        *ngIf="helpInfo.length > 0"
        [tooltip]="helpInfo | translate"
        class="icon-question"
      ></i>
    </span>
  </list-header>

  <div
    [@collapsedState]="isExpanded ? 'expanded' : 'collapsed'"
    class="list-group"
  >
    <div
      *ngIf="itemType === 'task'"
      [@listAnimation]="items.length"
      class="list"
    >
      <task-list-item
        (onClick)="onTaskClick($event)"
        *ngFor="let task of items"
        [color]="resolveColor(task)"
        [icon]="resolveIcon(task) | async"
        [loading]="loadingId === task.eaTaskId"
        [task]="task"
      >
      </task-list-item>
    </div>

    <div *ngIf="itemType === 'contact'" [@listAnimation]="items.length">
      <contact-list-item
        (click)="onContactClick(contact)"
        *ngFor="let contact of items"
        [contact]="contact"
      >
      </contact-list-item>
    </div>

    <list-item-empty *ngIf="items?.length === 0 && !hideNoResult">
      {{ "no_hits" | translate }}
    </list-item-empty>

    <list-load-more
      (loadMore)="onLoadMore()"
      *ngIf="lazy"
      [disabled]="disableLoadMore"
      [leftToLoad]="itemsLeftToLoad"
    >
    </list-load-more>
  </div>
</div>
