import { Tag } from "@app/models/tags";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as tagsActions from "./tags-management.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface TagsManagementState {
  globalTags: Tag[];
  localTags: Tag[];
  loadingGlobalTags: boolean;
  loadingLocalTags: boolean;
  storingTag: boolean;
  updatingTag: boolean;
  deletingTag: boolean;
}

export const initialState: TagsManagementState = {
  globalTags: [],
  localTags: [],
  loadingGlobalTags: false,
  loadingLocalTags: false,
  storingTag: false,
  updatingTag: false,
  deletingTag: false,
};

export function tagsManagementReducer(
  state: TagsManagementState,
  action: Action
): TagsManagementState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(tagsActions.getGlobalTagsRequest, (state) => ({
    ...state,
    globalTags: [],
    loadingGlobalTags: true,
  })),
  on(tagsActions.getGlobalTagsSuccess, (state, { tags }) => ({
    ...state,
    globalTags: tags,
    loadingGlobalTags: false,
  })),
  on(tagsActions.getGlobalTagsFail, () => ({ ...initialState })),
  on(tagsActions.getLocalTagsRequest, (state) => ({
    ...state,
    localTags: [],
    loadingLocalTags: true,
  })),
  on(tagsActions.getLocalTagsSuccess, (state, { tags }) => ({
    ...state,
    localTags: tags,
    loadingLocalTags: false,
  })),
  on(tagsActions.getLocalTagsFail, () => ({ ...initialState })),
  on(tagsActions.storeTagRequest, (state) => ({
    ...state,
    storingTag: true,
  })),
  on(tagsActions.storeTagSuccess, tagsActions.storeTagFail, (state) => ({
    ...state,
    storingTag: false,
  })),
  on(tagsActions.updateTagRequest, (state) => ({
    ...state,
    updatingTag: true,
  })),
  on(tagsActions.updateTagSuccess, tagsActions.updateTagFail, (state) => ({
    ...state,
    updatingTag: false,
  })),
  on(tagsActions.deleteTagRequest, (state) => ({
    ...state,
    deletingTag: true,
  })),
  on(tagsActions.deleteTagSuccess, tagsActions.deleteTagFail, (state) => ({
    ...state,
    deletingTag: false,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.tagsManagement
);

export const getGlobalTags = createSelector(
  getState,
  (state: TagsManagementState) => state.globalTags
);

export const getGlobalTagsLoading = createSelector(
  getState,
  (state: TagsManagementState) => state.loadingGlobalTags
);

export const getLocalTags = createSelector(
  getState,
  (state: TagsManagementState) => state.localTags
);

export const getLocalTagsLoading = createSelector(
  getState,
  (state: TagsManagementState) => state.loadingLocalTags
);

export const getTagsStoring = createSelector(
  getState,
  (state: TagsManagementState) => state.storingTag
);

export const getTagUpdating = createSelector(
  getState,
  (state: TagsManagementState) => state.updatingTag
);

export const getTagDeleting = createSelector(
  getState,
  (state: TagsManagementState) => state.deletingTag
);
