import { Office } from "@app/models";
import * as momeActions from "@app/settings/offices-employees/manage-office-management-email/ngrx/manage-office-management-email.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface ManageOfficeManagementEmailState {
  offices: Office[];
  selectedOffices: Office[];
  fetchLoading: boolean;
  updateLoading: boolean;
}

export const initialState: ManageOfficeManagementEmailState = {
  offices: [],
  selectedOffices: [],
  fetchLoading: false,
  updateLoading: false,
};

export function manageOfficeManagementEmailReducer(
  state: ManageOfficeManagementEmailState,
  action: Action
): ManageOfficeManagementEmailState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(momeActions.fetchOfficesRequest, (state) => ({
    ...state,
    fetchLoading: true,
  })),
  on(momeActions.fetchOfficesSuccess, (state, { offices }) => ({
    ...state,
    fetchLoading: false,
    offices,
  })),
  on(momeActions.fetchOfficesFail, (state) => ({
    ...state,
    fetchLoading: false,
  })),
  on(
    momeActions.updateOfficeFail,
    momeActions.updateOfficeSuccess,
    (state) => ({
      ...state,
      updateLoading: false,
    })
  ),
  on(momeActions.updateOfficeRequest, (state) => ({
    ...state,
    updateLoading: true,
  })),
  on(momeActions.setSelectedOffice, (state, { eaOfficeIds }) => ({
    ...state,
    selectedOffices:
      eaOfficeIds.length > 0
        ? eaOfficeIds.map((eaOfficeId) =>
            state.offices.find((office) => office.eaOfficeId === eaOfficeId)
          )
        : [...state.offices],
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.manageOfficeManagementEmails
);

export const getOffices = createSelector(
  getState,
  (state: ManageOfficeManagementEmailState) => state.offices
);
export const getSelectedOffices = createSelector(
  getState,
  (state: ManageOfficeManagementEmailState) => state.selectedOffices
);
export const getFetchLoading = createSelector(
  getState,
  (state: ManageOfficeManagementEmailState) => state.fetchLoading
);
export const getUpdateLoading = createSelector(
  getState,
  (state: ManageOfficeManagementEmailState) => state.updateLoading
);
