<table class="table table--striped">
  <thead>
    <tr>
      <th>
        {{ "contact" | translate }}
      </th>
      <th>
        {{ "answered" | translate }}
      </th>
      <th>
        {{ "survey_name" | translate }}
      </th>
      <th>
        {{ "nps_class" | translate }}
      </th>
      <th *ngIf="isManagerOrAdmin$ | async">
        {{ "employee" | translate }}
      </th>
      <th *ngIf="isAdmin$ | async">
        {{ "office" | translate }}
      </th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
      <tr
        *ngFor="let surveyResponse of resources$ | async"
        (click)="handleSurveyClicked(surveyResponse.responseUrl)"
      >
        <td class="td--bold" dataLabel="name">
          <app-contact-name
            [contactType]="surveyResponse?.contactType"
            [contactId]="surveyResponse.contactId"
          >
            <a>
              {{ surveyResponse.contactFirstName }}
              {{ surveyResponse.contactFamilyName }}
            </a>
          </app-contact-name>
        </td>
        <td dataLabel="response_date">{{ surveyResponse.responseDate }}</td>
        <td dataLabel="survey_name">{{ surveyResponse.surveyName }}</td>
        <td dataLabel="nps_class">{{ surveyResponse.NPSClass | translate }}</td>
        <td *ngIf="isManagerOrAdmin$ | async" dataLabel="employee">
          {{ surveyResponse.employeeFullName }}
        </td>
        <td *ngIf="isAdmin$ | async" dataLabel="office">
          {{ surveyResponse.officeName | removeOfficePrefix | async }}
        </td>
      </tr>
    </ng-container>
    <ng-template #empty>
      <tr>
        <td class="text-center" colspan="100%" translate>no_hits</td>
      </tr>
    </ng-template>
  </tbody>
</table>
