import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { PageView } from "@app/models/page-view";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { OBJECT_OVERVIEW } from "@app/shared/config/utils/features";
import { getObjectStatusColor } from "@app/shared/utils/object-utils";
import { getLatestContacts } from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.reducer";
import { fetchLatestObjectsRequest } from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.actions";
import {
  getLatestObjects,
  getLoading,
} from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.reducer";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, map, Observable, Subject, takeUntil } from "rxjs";
import { fetchLatestContactsRequest } from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.actions";

@Component({
  selector: "app-history-sidebar",
  templateUrl: "./history-sidebar.component.html",
  styleUrls: ["./history-sidebar.component.scss"],
})
export class HistorySidebarComponent implements OnInit, OnDestroy {
  @Output() closeSidebar = new EventEmitter<void>();

  objects$: Observable<PageView[]>;
  contacts$: Observable<PageView[]>;
  loading$: Observable<boolean>;
  navigationEndpoint$: Observable<"potential-buyers" | "overview">;

  objectsList$: BehaviorSubject<PageView[]> = new BehaviorSubject<PageView[]>(
    []
  );
  contactsList$: BehaviorSubject<PageView[]> = new BehaviorSubject<PageView[]>(
    []
  );
  unsubscribe$ = new Subject<void>();

  getObjectStatusColor = getObjectStatusColor;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchObjects();
    this.fetchContacts();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private fetchObjects() {
    this.store.dispatch(fetchLatestObjectsRequest());
    this.objects$.pipe(takeUntil(this.unsubscribe$)).subscribe((objects) => {
      if (objects.length > 5) {
        this.objectsList$.next(objects.slice(0, 5));
      } else {
        this.objectsList$.next([...objects]);
      }
    });
  }

  private fetchContacts() {
    this.store.dispatch(fetchLatestContactsRequest());
    this.contacts$.pipe(takeUntil(this.unsubscribe$)).subscribe((contacts) => {
      if (contacts.length > 5) {
        this.contactsList$.next(contacts.slice(0, 5));
      } else {
        this.contactsList$.next([...contacts]);
      }
    });
  }

  private mapStateToProps() {
    this.objects$ = this.store.pipe(select(getLatestObjects));
    this.contacts$ = this.store.pipe(select(getLatestContacts));
    this.loading$ = this.store.pipe(select(getLoading));
    this.navigationEndpoint$ = this.store.pipe(
      select(getFeature(OBJECT_OVERVIEW)),
      map((feature: Feature) =>
        !!feature.enabled ? "overview" : "potential-buyers"
      )
    );
  }
}
