import { Injectable } from "@angular/core";
import { danger } from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import * as outgoingMailActions from "./outgoing-mail.actions";

@Injectable()
export class OutgoingMailEffects {
  getOutgoingMails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(outgoingMailActions.getOutgoingMailsRequest),
      switchMap(({ filters }) =>
        this.apiService.get("bulk-messages", filters).pipe(
          map((response: any) =>
            outgoingMailActions.getOutgoingMailsSuccess({
              mails: response.rows,
            })
          ),
          catchError(({ message }) => observableOf(danger({ message })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
