import { Employee } from "@app/models";
import { FilterState } from "../reducers/filters.reducer";
import { createAction, props } from "@ngrx/store";

export const setFilters = createAction(
  "[Statistics] SET_FILTERS",
  props<{ filters: Partial<FilterState> }>()
);

export const fetchEmployeesRequest = createAction(
  "[Statistics] FETCH_EMPLOYEES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchEmployeesSuccess = createAction(
  "[Statistics] FETCH_EMPLOYEES_SUCCESS",
  props<{ employees: Employee[] }>()
);

export const fetchEmployeesFail = createAction(
  "[Statistics] FETCH_EMPLOYEES_FAIL"
);

export const clearEmployees = createAction("[Statistics] CLEAR_EMPLOYEES");
