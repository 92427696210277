import { SurveyFilters } from "@app/lists/lists-surveys/ngrx/lists-surveys.reducer";
import { API_SURVEYS_DEFAULT_LIMIT } from "@app/lists/lists-surveys/utils/lists-surveys-defaults";
import { TypedPaginationListDTO } from "@app/models";
import { SurveyResponse } from "@app/models/survey";
import { createAction, props } from "@ngrx/store";

const defaultFilters = {
  limit: API_SURVEYS_DEFAULT_LIMIT,
  offset: 0,
};

export const getSurveys = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultFilters,
    ...params,
  };
  return getSurveysRequest({ filters });
};

export const getSurveysRequest = createAction(
  "[Lists Surveys] GET_SURVEYS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getSurveysSuccess = createAction(
  "[Lists Surveys] GET_SURVEYS_SUCCESS",
  props<{ surveys: TypedPaginationListDTO<SurveyResponse> }>()
);

export const getSurveysFailed = createAction(
  "[Lists Surveys] GET_SURVEYS_FAILED"
);

export const getMoreSurveys = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultFilters,
    ...params,
  };
  return getMoreSurveysRequest({ filters });
};

export const getMoreSurveysRequest = createAction(
  "[Lists Surveys] GET_MORE_SURVEYS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreSurveysSuccess = createAction(
  "[Lists Surveys] GET_MORE_SURVEYS_SUCCESS",
  props<{ surveys: TypedPaginationListDTO<SurveyResponse> }>()
);

export const getMoreSurveysFailed = createAction(
  "[Lists Surveys] GET_MORE_SURVEYS_FAILED"
);

export const getSurveyFiltersRequest = createAction(
  "[Lists Surveys] GET_SURVEY_FILTERS_REQUEST"
);

export const getSurveyFiltersSuccess = createAction(
  "[Lists Surveys] GET_SURVEY_FILTERS_SUCCESS",
  props<{ surveyFilters: SurveyFilters[] }>()
);

export const getSurveyFiltersFailed = createAction(
  "[Lists Surveys] GET_SURVEY_FILTERS_FAILED"
);

export const reset = createAction("[Lists Surveys] RESET");
