import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { SupportComponent } from "@app/sidebar/support/containers/support/support.component";
import { TranslateModule } from "@ngx-translate/core";
import { AlertModule } from "ngx-bootstrap/alert";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    UtilModule,
    AlertModule,
    UiComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    FormComponentsModule,
  ],
  exports: [SupportComponent],
  declarations: [SupportComponent],
  providers: [],
})
export class SupportModule {}
