import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { Employee, ObjectFile, Office } from "@app/models";
import { getSettingsAccountExchangeEmail } from "@app/settings/account/exchange/ngrx/settings-account-exchange.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { SendMessageFeature } from "@app/shared/config/models/send-message-feature";
import * as features from "@app/shared/config/utils/features";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { SEND_MESSAGE } from "@app/shared/utils/tab-types";
import { ShowingObject } from "@app/showings/models";
import * as showingActions from "@app/showings/ngrx/showings/showings.actions";
import { Recipient } from "@app/sidebar/send-message/models/recipient";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  from as observableFrom,
  map,
  Observable,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { ConnectableTab } from "../../sidebar-connectable-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import {
  DynamicContent,
  EmailUpdateEvent,
  MobileNumberUpdateEvent,
  Template,
} from "../models";
import * as sendMessageActions from "../ngrx/send-message.actions";
import * as fromSendMessage from "../ngrx/send-message.reducer";
import { getEaOid, MessageType } from "../ngrx/send-message.reducer";
import { SendMessageFormComponent } from "@app/sidebar/send-message/components/send-message-form/send-message-form.component";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { AddObjectFilesModalComponent } from "@app/sidebar/send-message/components/add-object-files-modal/add-object-files-modal.component";
import { AddObjectLinksModalComponent } from "@app/sidebar/send-message/components/add-object-links-modal/add-object-links-modal.component";

@Component({
  selector: "send-message-container",
  templateUrl: "./send-message-container.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./send-message-container.component.scss",
  ],
})
export class SendMessageContainerComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(SendMessageFormComponent, { static: false })
  sendMessageForm: SendMessageFormComponent;
  tabType = SEND_MESSAGE;
  proxy$ = new Subject<any>();

  showAddObjectLinks$: Observable<SendMessageFeature>;
  messageType$: Observable<MessageType>;
  templateGroupName$: Observable<string>;
  templates$: Observable<Template[]>;
  dynamicContents$: Observable<DynamicContent[]>;
  recipients$: Observable<Recipient[]>;
  emails$: Observable<any[]>;
  mobileNumbers$: Observable<any[]>;
  processing$: Observable<boolean>;
  errors$: Observable<object>;
  extraDataSet$: Observable<any>;

  countryCode$: Observable<string>;
  languageCode$: Observable<string>;
  sendModuleId$: Observable<string>;
  userIds$: Observable<UserIds>;
  user$: Observable<Employee>;
  eaEmployeeId$: Observable<string>;
  eaEmployeeId: string;
  eaOfficeId$: Observable<string>;
  eaOfficeId: string;
  unsubscribe$ = new Subject<void>();
  exchangeEmail$: Observable<string>;
  office$: Observable<Office>;
  templateExternalId: (id: string) => Observable<string>;
  dynamicContentsWithFormValue: (
    values: string[]
  ) => Observable<DynamicContent[]>;
  emailsCsv$: Observable<any[]>;
  mobileNumbersCsv$: Observable<any[]>;
  recipientsCsv$: Observable<any[]>;
  disablePreviewAndSend$: Observable<boolean>;
  form: FormGroup;
  objectLoading$: Observable<boolean>;
  showingObject$: Observable<ShowingObject>;
  fileAttachmentsEnabled$: Observable<boolean>;
  checkConsent$: Observable<boolean>;
  messageTypeSubscription: Subscription;

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private modalService: QModalService,
    public sendMessageService: SendMessageService,
    private objectService: ObjectService
  ) {
    this.buildForms();
  }

  ngOnInit(): void {
    this.sendMessageService.reset();
    this.mapStateToProps();
    this.createStreams();
    this.fetchTemplatesOnMessageTypeChange();
    this.connectTab();
    this.loadObjectIfInShowingsModule();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(sendMessageActions.reset());
  }

  mapStateToProps(): void {
    this.showingObject$ = this.store.pipe(
      select(getEaOid),
      filter((eaOid) => !!eaOid),
      switchMap((eaOid) =>
        this.objectService.entityMap$.pipe(
          map((entities) => entities[eaOid]),
          filter((entity) => !!entity)
        )
      )
    );
    this.messageType$ = this.store.pipe(select(fromSendMessage.getMessageType));
    this.templateGroupName$ = this.store.pipe(
      select(fromSendMessage.getTemplateGroupName)
    );
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.dynamicContents$ = this.store.pipe(
      select(fromSendMessage.getDynamicContents)
    );
    this.recipients$ = this.store.pipe(select(fromSendMessage.getRecipients));
    this.checkConsent$ = this.store.pipe(
      select(fromSendMessage.getCheckConsent)
    );
    this.emails$ = this.store.pipe(select(fromSendMessage.getEmails));
    this.mobileNumbers$ = this.store.pipe(
      select(fromSendMessage.getMobileNumbers)
    );
    this.templates$ = this.store.pipe(select(fromSendMessage.getTemplates));
    this.processing$ = this.store.pipe(select(fromSendMessage.getProcessing));
    this.errors$ = this.store.pipe(select(fromSendMessage.getErrors));
    this.extraDataSet$ = this.store.pipe(
      select(fromSendMessage.getExtraDataSet)
    );
    this.countryCode$ = this.store.pipe(select(fromConfig.getCountry));
    this.languageCode$ = this.store.pipe(select(fromConfig.getLanguage));
    this.sendModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.userIds$ = this.store.pipe(fromUser.getEaIds);
    this.user$ = this.store.pipe(select(fromUser.getEmployee));
    this.exchangeEmail$ = this.store.pipe(
      select(getSettingsAccountExchangeEmail)
    );
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.eaEmployeeId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaEmployeeId) => {
        this.eaEmployeeId = eaEmployeeId;
      });
    this.eaOfficeId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOfficeId) => {
        this.eaOfficeId = eaOfficeId;
      });
    this.fileAttachmentsEnabled$ = this.store.pipe(
      select(getFeature(features.SEND_MESSAGE)),
      map((feature) => feature?.enableFileAttachments)
    );
    this.showAddObjectLinks$ = this.store.pipe(
      select(getFeature(features.SEND_MESSAGE)),
      map((feature) => feature?.enableObjectLinks)
    );
  }

  loadObjectIfInShowingsModule(): void {
    // Todo: Dont use regex...
    const pattern = /showings\/([\s\S]*?)\/potential-buyers/;
    const result = location.href.match(pattern);
    if (result && result[1]) {
      const eaOid = result[1];
      this.store.dispatch(
        showingActions.getObjectRequest({
          eaOid,
          params: {
            getFiles: true,
          },
        })
      );
    }
  }

  createStreams(): void {
    this.templateExternalId = (id: string) =>
      this.templates$.pipe(
        switchMap((templates) => observableFrom(templates)),
        filter((t) => t.templateId === id),
        map((template) => template?.externalId)
      );

    this.dynamicContentsWithFormValue = (values: string[]) =>
      this.dynamicContents$.pipe(
        map((dynamicContents) =>
          dynamicContents.map((dc) => ({ ...dc, value: values.shift() }))
        )
      );

    this.emailsCsv$ = this.messageType$.pipe(
      filter((type) => type === "email"),
      switchMap(() =>
        this.emails$.pipe(
          withLatestFrom(this.recipients$),
          map(([emails, contacts]) =>
            contacts
              .filter((c) => !!c.email)
              .map((c) => c)
              .concat(emails)
          )
        )
      )
    );

    this.mobileNumbersCsv$ = this.messageType$.pipe(
      filter((type) => type === "sms"),
      switchMap(() =>
        this.mobileNumbers$.pipe(
          withLatestFrom(this.recipients$),
          map(([mobileNumbers, contacts]) =>
            contacts
              .filter((c) => !!c.msisdn)
              .map((c) => c)
              .concat(mobileNumbers)
          )
        )
      )
    );

    this.recipientsCsv$ = this.messageType$.pipe(
      switchMap((type) =>
        type === "email" ? this.emailsCsv$ : this.mobileNumbersCsv$
      )
    );

    this.disablePreviewAndSend$ = combineLatest([
      this.processing$,
      this.errors$.pipe(
        map((errors) => errors.hasOwnProperty("dynamicContents"))
      ),
    ]).pipe(map(([processing, errors]) => processing || errors));
  }

  fetchTemplatesOnMessageTypeChange(): void {
    this.messageTypeSubscription = this.messageType$
      .pipe(
        withLatestFrom(this.templateGroupName$),
        debounceTime(100),
        distinctUntilChanged(_.isEqual),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([messageType, groupName]) => {
        this.store.dispatch(
          sendMessageActions.fetchTemplatesRequest({ messageType, groupName })
        );
      });
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  buildForms() {
    this.form = this.fb.group({
      addToOutbox: true,
    });
  }

  handleShowObjectLinkModal(event: MouseEvent) {
    event.preventDefault();
    if (this.sendMessageForm.form.get("template").invalid) {
      markAllAsTouched(this.sendMessageForm.form);
      return;
    }

    this.modalService.show(AddObjectLinksModalComponent);
  }

  handleShowObjectFilesModal(event: MouseEvent) {
    event.preventDefault();
    if (this.sendMessageForm.form.get("template").invalid) {
      markAllAsTouched(this.sendMessageForm.form);
      return;
    }

    this.modalService.show(AddObjectFilesModalComponent);
  }

  handleRemoveFile(file: ObjectFile): void {
    this.sendMessageService.objectFiles$.pipe(first()).subscribe((files) => {
      const newFilesArray = files.filter((f) => f.filePath !== file.filePath);
      this.sendMessageService.objectFiles$.next(newFilesArray);
    });
  }

  closeTab(): void {
    this.messageTypeSubscription.unsubscribe();
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(event: any) {
    const {
      templateId,
      dynamicContents: dynamicContentValues,
      addToOutbox,
    } = event;
    combineLatest([
      this.userIds$,
      this.user$,
      this.sendModuleId$,
      this.office$,
      this.dynamicContentsWithFormValue(dynamicContentValues),
      this.messageType$,
      this.extraDataSet$,
      this.recipientsCsv$,
      this.sendMessageService.objectFiles$,
      this.templates$,
    ])
      .pipe(first())
      .subscribe((data) => {
        const [
          userIds,
          user,
          sendToModule,
          office,
          dynamicContents,
          messageType,
          extraDataSet,
          recipients,
          objectFiles,
          templates,
        ] = data;

        const template = templates.find(
          (temp) => temp.templateId === templateId
        );
        const newParams = {
          template,
          dynamicContents,
          addToOutbox,
          userIds,
          user,
          sendToModule,
          office,
          messageType,
          extraDataSet,
          recipients,
          objectFiles,
        };

        this.sendMessageService.send(newParams);
      });
  }

  onPreview(event: any) {
    const { templateId, dynamicContents: values } = event;

    combineLatest([
      this.userIds$,
      this.dynamicContentsWithFormValue(values),
      this.extraDataSet$,
      this.recipients$,
      this.templates$,
    ])
      .pipe(first())
      .subscribe((data) => {
        const [userIds, dynamicContents, extraDataSet, recipients, templates] =
          data;

        const template = templates.find(
          (temp) => temp.templateId === templateId
        );
        const newParams = {
          template,
          dynamicContents,
          userIds,
          extraDataSet,
          recipients,
          eaOfficeId: this.eaOfficeId,
          eaEmployeeId: this.eaEmployeeId,
        };
        this.sendMessageService.preview(newParams);
      });
  }

  onMessageTypeChange(messageType: MessageType): void {
    this.store.dispatch(sendMessageActions.setMessageType({ messageType }));
  }

  onTemplateChange(templateId: string): void {
    if (templateId === "") {
      return;
    }

    combineLatest([this.userIds$, this.extraDataSet$, this.recipients$])
      .pipe(first())
      .subscribe(([userIds, { eaOid }, consumers]) => {
        this.store.dispatch(
          sendMessageActions.fetchDynamicContent(
            templateId,
            userIds,
            consumers.slice(0, 1),
            eaOid,
            this.eaOfficeId,
            this.eaEmployeeId,
            true
          )
        );
      });
  }

  onAddRecipient(recipient: Recipient): void {
    this.store.dispatch(
      sendMessageActions.addContacts({ recipients: [recipient] })
    );
  }

  onRemoveRecipient(recipient: Recipient): void {
    this.store.dispatch(
      sendMessageActions.removeContacts({ contactIds: [recipient.contactId] })
    );
  }

  onAddEmail(email: string): void {
    this.store.dispatch(sendMessageActions.addEmail({ email }));
  }

  onRemoveEmail(email: string): void {
    this.store.dispatch(sendMessageActions.removeEmail({ email }));
  }

  onRemoveAllEmails(): void {
    this.store.dispatch(sendMessageActions.removeAllEmails());
  }

  onAddMobileNumber(mobileNumber: string): void {
    this.store.dispatch(sendMessageActions.addMobileNumber({ mobileNumber }));
  }

  onRemoveMobileNumber(mobileNumber: string): void {
    this.store.dispatch(
      sendMessageActions.removeMobileNumber({ mobileNumber })
    );
  }

  onRemoveAllMobileNumbers(): void {
    this.store.dispatch(sendMessageActions.removeAllMobileNumbers());
  }

  onUpdateContactEmail(event: EmailUpdateEvent): void {
    const {
      recipient: { contactId },
      email,
    } = event;
    this.store.dispatch(
      sendMessageActions.updateContactRequest({ contactId, params: { email } })
    );
  }

  onUpdateContactMobileNumber(event: MobileNumberUpdateEvent): void {
    const {
      recipient: { contactId },
      mobileNumber,
    } = event;
    this.store.dispatch(
      sendMessageActions.updateContactRequest({
        contactId,
        params: {
          msisdn: mobileNumber,
        },
      })
    );
  }
}
