import { Employment } from "@app/models";
import * as employeesActions from "./employees-search.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface EmployeesSearchState {
  loading: boolean;
  loaded: boolean;
  employments: Employment[];
}

export const initialState: EmployeesSearchState = {
  loading: false,
  loaded: false,
  employments: [],
};

export function employeesSearchReducer(
  state: EmployeesSearchState,
  action: Action
): EmployeesSearchState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(employeesActions.getEmploymentsRequest, (state) => ({
    ...state,
    employments: initialState.employments,
    loading: true,
  })),
  on(employeesActions.getEmploymentsSuccess, (state, { employments }) => ({
    ...state,
    employments,
    loading: false,
    loaded: true,
  })),
  on(employeesActions.getEmploymentsFailed, (state) => ({
    ...state,
    employments: initialState.employments,
    loading: false,
    loaded: true,
  })),
  on(employeesActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.employeesSearch
);

export const getIgnoreList = createSelector(
  getState,
  (state: EmployeesSearchState) => state.employments.map((e) => e.eaEmployeeId)
);

export const getEmploymentsLoading = createSelector(
  getState,
  (state: EmployeesSearchState) => state.loading
);

export const getEmploymentsLoaded = createSelector(
  getState,
  (state: EmployeesSearchState) => state.loaded
);
