import { Pipe, PipeTransform } from "@angular/core";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";

@Pipe({ name: "phoneFormat" })
export class PhoneFormatPipe implements PipeTransform {
  constructor(private phoneNumberService: PhoneNumberService) {}

  transform(value: any, countryCode = ""): string {
    return this.phoneNumberService.format(value, countryCode);
  }
}
