<div
  class="q-segmented-controls"
  [class.margin-top]="!!label"
  [class.inline]="inline"
>
  <label *ngIf="!!label" class="q-segmented-controls__label">
    {{ label | translate }}
  </label>
  <div class="controls-list">
    <button
      type="button"
      *ngFor="let control of controls"
      class="q-segmented-controls__button"
      [class.active]="control.value === value"
      (click)="onClick(control.value)"
      [class.disabled]="disabled"
      [disabled]="disabled"
    >
      {{ control.label | translate }}
    </button>
  </div>
</div>
