export interface NpsData {
  npsScore: number;
  totalCount: number;
  detractorPercentage: number;
  passivePercentage: number;
  promoterPercentage: number;
  detractorCount: number;
  passiveCount: number;
  promoterCount: number;
}

export interface Nps {
  title: string;
  created: string;
  nps: NpsData;
}

export interface NpsEntity {
  NPSValue: number;
  detractors: number;
  passives: number;
  promoters: number;
  responses: number;
  responsesWithNPSValues: number;
  unknown: number;
  averageNPSValue: number;
  answerFrequency: number;
}

export interface NpsRequest {
  eaOfficeId: string;
  eaEmployeeId: string;
  searchParams: {
    resMonthMin?: string;
    resDateMin?: string;
    resDateMax?: string;
  };
}

export interface ContactNpsRequest {
  searchParams: {
    contactId: string;
    limit: number;
    sort: string;
    sortOrder: string;
    NPSClass: string;
  };
}

export interface NpsSurveyResponsesRequest {
  type: string;
  userIds: Record<string, unknown>;
  searchParams: Record<string, unknown>;
}

export interface NpsSurveyResponsesSuccess {
  type: string;
  details: any;
}

export const NPS_VALUES = {
  DETRACTORS: "detractors",
  PASSIVES: "passives",
  PROMOTERS: "promoters",
};
