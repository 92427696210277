import { BudgetTarget } from "@app/models/budget-target";
import { NewRecruitTargetTotal } from "@app/models/budget-targets";

export class NewRecuitSalesTarget {
  recruitId: string;
  recruitName: string;
  targetTotal: NewRecruitTargetTotal;
  targets: BudgetTarget[];

  constructor(
    recruitId: string,
    recruitName: string,
    targetTotal: NewRecruitTargetTotal,
    targets: BudgetTarget[]
  ) {
    this.recruitId = recruitId;
    this.recruitName = recruitName;
    this.targetTotal = targetTotal;
    this.targets = targets;
  }
}
