import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { DatePeriodDropdownComponent } from "@app/shared/modules/form-components/date-period-dropdown/date-period-dropdown.component";
import { MultiSelectDropdownComponent } from "@app/shared/modules/form-components/multi-select-dropdown/multi-select-dropdown.component";
import { TextareaAutoExpandingComponent } from "@app/shared/modules/form-components/textarea-auto-expanding/textarea-auto-expanding.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { NgxErrorsModule } from "@ngspot/ngx-errors";
import { TranslateModule } from "@ngx-translate/core";
import { defineLocale } from "ngx-bootstrap/chronos";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { svLocale } from "ngx-bootstrap/locale";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UtilModule } from "../util/util.module";
import { AdaptivePlaceholderComponent } from "./adaptive-placeholder/adaptive-placeholder.component";
import { AllOfficeEmployeeDropdownComponent } from "./all-office-employee-dropdown/all-office-employee-dropdown.component";
import { CountryCodeSelectorComponent } from "./country-code-selector/country-code-selector.component";
import { CountrySelectorComponent } from "./country-selector/country-selector.component";
import { DatepickerDropdownComponent } from "./datepicker-dropdown/datepicker-dropdown.component";
import { AdaptivePlaceholderDirective } from "./directives/adaptive-placeholder.directive";
import { EmailValidatorDirective } from "./directives/email-validator.directive";
import { EqualValidatorDirective } from "./directives/equal-validator.directive";
import { FocusDirective } from "./directives/focus.directive";
import { ThousandSeparatorDirective } from "./directives/thousand-separator.directive";
import { EmployeeDropdownComponent } from "./employee-dropdown/employee-dropdown.component";
import { InputErrorComponent } from "./input-error/input-error.component";
import { InputGroupButtonComponent } from "./input-group-button/input-group-button.component";
import { MobileInputComponent } from "./mobile-input/mobile-input.component";
import { NewOfficeEmployeeMultiselectDropdownComponent } from "./new-office-employee-multiselect-dropdown/new-office-employee-multiselect-dropdown.component";
import { OfficeDropdownComponent } from "./office-dropdown/office-dropdown.component";
import { OfficeEmployeeDropdownComponent } from "./office-employee-dropdown/office-employee-dropdown.component";
import { OfficeEmployeeMultiSelectDropdownComponent } from "./office-employee-multi-select-dropdown/office-employee-multi-select-dropdown.component";
import { QListFiltersComponent } from "./q-list-filters/q-list-filters.component";
import { QuickDateOptionsComponent } from "./quick-date-options/quick-date-options.component";
import { TextareaCounterComponent } from "./textarea-counter/textarea-counter.component";
import { TimePickerInputComponent } from "./time-picker-input/time-picker-input.component";

// TODO: this should probably not be hardcoded here...
defineLocale("sv", svLocale);

const components = [
  AdaptivePlaceholderDirective,
  AdaptivePlaceholderComponent,
  DatepickerDropdownComponent,
  InputErrorComponent,
  OfficeDropdownComponent,
  EmployeeDropdownComponent,
  CountryCodeSelectorComponent,
  OfficeEmployeeDropdownComponent,
  AllOfficeEmployeeDropdownComponent,
  InputGroupButtonComponent,
  MobileInputComponent,
  TimePickerInputComponent,
  TextareaCounterComponent,
  TextareaAutoExpandingComponent,
  MultiSelectDropdownComponent,
  DatePeriodDropdownComponent,
  OfficeEmployeeMultiSelectDropdownComponent,
];

const directives = [
  EmailValidatorDirective,
  EqualValidatorDirective,
  FocusDirective,
  ThousandSeparatorDirective,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    BsDatepickerModule,
    BsDropdownModule,
    TranslateModule,
    UtilModule,
    ModalModule,
    UiComponentsModule,
    TooltipModule,
    MatDialogModule,
  ],
  declarations: [
    ...components,
    ...directives,
    QuickDateOptionsComponent,
    CountrySelectorComponent,
    NewOfficeEmployeeMultiselectDropdownComponent,
    QListFiltersComponent,
  ],
  exports: [
    ...components,
    ...directives,
    FormsModule,
    ReactiveFormsModule,
    NgxErrorsModule,
    QuickDateOptionsComponent,
    NewOfficeEmployeeMultiselectDropdownComponent,
    QListFiltersComponent,
  ],
})
export class FormComponentsModule {}
