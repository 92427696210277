<div class="language-dropdown-container">
  <label *ngIf="label">{{ label | translate }}</label>
  <mat-form-field appearance="outline" class="dropdown-field">
    <mat-select
      [placeholder]="label | translate"
      [(ngModel)]="selectedLanguage"
      (selectionChange)="onselectionchange($event)"
    >
      <mat-select-trigger>
        <i
          matSuffix
          class="flag-icon flag-icon-{{ selectedLanguage.country }}"
        ></i>
        <span>{{ selectedLanguage.title | translate }}</span>
      </mat-select-trigger>
      <mat-option
        *ngFor="let language of languages"
        [value]="language"
        class="option-container"
      >
        <i matSuffix class="flag-icon flag-icon-{{ language.country }}"></i>
        <span>{{ language.title | translate }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
