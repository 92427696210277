<section>
  <div class="login-fail">
    <div *ngIf="unmatchedSSOEmail$ | async as email; else ssoError">
      <h1 translate>login_failed</h1>
      <h3>
        {{ "unmatched_sso_email_message" | translate : { email: email } }}
      </h3>
    </div>
    <ng-template #ssoError>
      <h1 translate>sso_fail_message</h1>
    </ng-template>
    <ng-container *ngIf="supportEnabled$ | async">
      <div *ngIf="supportMail$ | async" class="support__mail">
        <h2>{{ "email" | translate }}</h2>
        <a href="mailto:{{ supportMail$ | async }}">{{
          supportMail$ | async
        }}</a>
      </div>
      <div
        *ngIf="(supportTel$ | async) && (supportTelCountry$ | async)"
        class="support__tel"
      >
        <h2>{{ "support_phone" | translate }}</h2>
        <a href="tel:+{{ supportTel$ | async }}">
          {{
            "+" + (supportTel$ | async)
              | phoneFormat : (supportTelCountry$ | async)
          }}
        </a>
      </div>
    </ng-container>
  </div>
</section>
