import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import {
  Contact,
  QObject,
  SalesMeeting,
  SendListStatus,
  Task,
} from "@app/models";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Action, select, Store } from "@ngrx/store";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  delay,
  filter,
  first,
  map,
  merge as observableMerge,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
  zip as observableZip,
} from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as fromSidebar from "../../ngrx/sidebar.reducer";
import { SalesMeetingBatchSettings } from "../models/batch-settings";
import { SalesMeetingFormComponent } from "../sales-meeting-form/sales-meeting-form.component";
import * as smActions from "../ngrx/sales-meeting.actions";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";
import {
  getSalesMeetingHasError,
  getSalesMeetingProcessing,
} from "../ngrx/sales-meeting.reducer";

@Component({
  selector: "sales-meeting-edit",
  templateUrl: "./sales-meeting-edit.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./sales-meeting-edit.component.scss",
  ],
})
export class SalesMeetingEditComponent implements OnInit, OnDestroy {
  @ViewChild(SalesMeetingFormComponent, { static: false })
  smForm: SalesMeetingFormComponent;

  tabType = SALES_MEETING;
  tab$: Observable<SidebarTab>;
  processing$: Observable<boolean>;

  contact$: Observable<Contact>;
  salesMeeting$: Observable<SalesMeeting>;
  residence$: Observable<QObject>;
  sendListStatus$: Observable<SendListStatus[]>;
  batchSettings$: Observable<SalesMeetingBatchSettings[]>;
  systemSources$: Observable<Task[]>;

  unsubscribe$ = new Subject<void>();
  disconnectTab$ = new Subject<void>();
  connectedToStore = false;
  loading = false;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.disconnectTabFromStore();
    });

    observableCombineLatest([this.contact$, this.salesMeeting$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([{ contactId }, { eaCrmSalesMeetingId }]) => {
        this.store.dispatch(smActions.getSystemSources(contactId));
        this.store.dispatch(
          smActions.getSendList(contactId, eaCrmSalesMeetingId)
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));

    this.contact$ = this.route.data.pipe(map((params) => params?.contact));
    this.salesMeeting$ = this.route.data.pipe(
      map((params) => params?.data),
      map((data: any) => data.salesMeeting)
    );
    this.residence$ = this.route.data.pipe(
      map((params) => params?.data),
      map((data: any) => data.residence)
    );
    this.sendListStatus$ = this.store.pipe(
      select(fromSalesMeeting.getSendListStatus),
      filter((value) => !!value)
    );
    this.batchSettings$ = this.store.pipe(
      select(fromSalesMeeting.getBatchSettings),
      filter((value) => !!value)
    );
    this.systemSources$ = this.store.pipe(
      select(fromSalesMeeting.getSystemSources),
      filter((value) => !!value)
    );
    this.processing$ = this.store.pipe(select(getSalesMeetingProcessing));
  }

  connectTabToStore(): void {
    this.tab$.pipe(first()).subscribe((tab) => {
      if (tab.dirty) {
        this.smForm.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: this.smForm.value,
          })
        );
      }
      this.smForm.form.valueChanges
        .pipe(
          debounceTime(500),
          takeUntil(observableMerge(this.unsubscribe$, this.disconnectTab$)),
          first()
        )
        .subscribe(() =>
          this.store.dispatch(
            sidebarActions.setTabValue({
              tabType: this.tabType,
              value: this.smForm.form.getRawValue(),
            })
          )
        );

      this.connectedToStore = true;
    });
  }

  disconnectTabFromStore() {
    this.connectedToStore = false;
    this.disconnectTab$.next();
  }

  onSubmit(action: Action): void {
    this.store.dispatch(action);
    this.store
      .pipe(
        delay(500),
        select(getSalesMeetingProcessing),
        filter((processing) => !processing),
        withLatestFrom(this.store.pipe(select(getSalesMeetingHasError))),
        first()
      )
      .subscribe(([_processing, hasError]) => {
        this.loading = false;
        if (!hasError) {
          this.store.dispatch(
            sidebarActions.closeTab({ tabType: SALES_MEETING })
          );
        }
      });
  }

  // prettier-ignore
  onEmployeeChange(): void {
    if (!this.connectedToStore) {
      this.salesMeeting$.pipe(
        first())
        .subscribe(({eaEmployeeId}) => {
          this.cdr.detectChanges();
          this.smForm.form.patchValue({employee: eaEmployeeId,});
          this.connectTabToStore();
        });
    }
  }

  onFormReady(): void {
    const combined$ = observableZip(
      this.salesMeeting$,
      this.sendListStatus$,
      this.systemSources$
    ).pipe(
      map(([salesMeeting, sendListStatus, systemSources]) => ({
        salesMeeting,
        sendListStatus,
        systemSources,
      }))
    );

    observableCombineLatest([combined$, this.batchSettings$])
      .pipe(
        map(([data, batches]) => ({ ...data, batches })),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => this.patchForm(data));
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  patchForm(data: any): void {
    const { salesMeeting, sendListStatus, systemSources, batches } = data;
    this.smForm.form.patchValue({
      ...this.parseDateTime(salesMeeting.startTime),
      office: salesMeeting.eaOfficeId,
      comment: salesMeeting.note || "",
      batches: this.parseBatches(batches, sendListStatus),
      source: this.parseSource(salesMeeting.tasks, systemSources),
    });

    if (!!salesMeeting?.updateContactSource) {
      this.smForm.form.get("source").enable({ emitEvent: true });
    } else {
      this.smForm.form.get("source").disable({ emitEvent: true });
    }
    this.cdr.detectChanges();
  }

  parseDateTime(dateTime: string): any {
    const date = moment(dateTime, "YYYY-MM-DD HH:mm");
    return {
      date: date.toDate(),
      time: date.format("HH:mm"),
    };
  }

  parseBatches(batches: SalesMeetingBatchSettings[], status: SendListStatus[]) {
    return batches.map((batch) =>
      status.some(
        (s) => parseInt(s.eaCrmActivityId, 10) === batch.eaCrmActivityId
      )
    );
  }

  parseSource(salesMeetingTasks: Task[], sources: Task[]): Task | string {
    try {
      const sourceTask = salesMeetingTasks.find((task) => task.contactSource);
      return sources.find((source) => source.eaTaskId === sourceTask.eaTaskId);
    } catch (e) {
      return "";
    }
  }
}
