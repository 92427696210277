import { Injectable } from "@angular/core";
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  map,
  Observable,
} from "rxjs";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { TypedPaginationListDTO } from "@app/models";
import {
  ProgramInstance,
  ProgramInstanceFilter,
} from "@app/showings/models/ProgramInstance";
import { SellersChoiceStatus } from "@app/showings/models/SellersChoiceStatus";
import { select, Store } from "@ngrx/store";
import { getFeature } from "@app/shared/config/config.reducer";
import { ADFENIX_PROPERTY_MARKETING } from "@app/shared/config/utils/features";
import { getIsAdfenixPropertyTriggerConfigured } from "@app/integrations/ngrx/integrations.reducer";
import { AppState } from "@app/app.reducer";

@Injectable({
  providedIn: "root",
})
export class ObjectIntegrationService {
  selectedOid$ = new BehaviorSubject<string>("");
  sellersChoiceStatus$ = new BehaviorSubject<SellersChoiceStatus>(null);
  programInstance$ = new BehaviorSubject<ProgramInstance[]>(null);
  propertyTriggerEnabled$: Observable<boolean>;

  constructor(private store: Store<AppState>, private apiService: ApiService) {}

  checkHasAccess() {
    this.propertyTriggerEnabled$ = combineLatest([
      this.store.pipe(select(getFeature(ADFENIX_PROPERTY_MARKETING))),
      this.store.pipe(select(getIsAdfenixPropertyTriggerConfigured())),
      this.sellersChoiceStatus$,
      this.programInstance$,
    ]).pipe(
      map(([feature, isConfigured, sellersChoiceStatus, programInstance]) => {
        return (
          feature.enabled &&
          isConfigured &&
          sellersChoiceStatus?.currentUserCanTrigger &&
          (!programInstance || programInstance?.length === 0)
        );
      })
    );
  }

  getObjectSellersChoiceStatus = (eaOid: string) => {
    this.selectedOid$.next(eaOid);
    return this.apiService
      .get(
        `adfenix/property/${eaOid}/sellers-choice-state`,
        {},
        Categories.Integrations
      )
      .pipe(
        map((response: SellersChoiceStatus) => {
          this.sellersChoiceStatus$.next(response);
          return response;
        }),
        catchError((err) => {
          this.sellersChoiceStatus$.next(null);
          throw err;
        })
      );
  };

  getObjectProgramInstance = (
    eaOid: string,
    filters: ProgramInstanceFilter = {
      limit: 25,
    }
  ) => {
    this.selectedOid$.next(eaOid);
    return this.apiService
      .get(`program-instances/for-property/${eaOid}`, filters)
      .pipe(
        map((response: TypedPaginationListDTO<ProgramInstance>) => {
          this.programInstance$.next(response.rows);
          return response;
        }),
        catchError((err) => {
          this.programInstance$.next(null);
          throw err;
        })
      );
  };

  reset() {
    this.selectedOid$.next(null);
    this.sellersChoiceStatus$.next(null);
    this.programInstance$.next(null);
  }
}
