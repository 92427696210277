import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { PartialObserver, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-create-note",
  templateUrl: "./create-note.component.html",
  styleUrls: ["./create-note.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateNoteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value: string;
  @Input() observer: PartialObserver<string>;
  @Output() create = new EventEmitter<string>();
  @Output() noteValueChanged = new EventEmitter<string>();

  form: FormGroup;
  destroy$ = new Subject<void>();

  get noteControl(): FormControl {
    return this.form.get("note") as FormControl;
  }

  constructor(private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    if (this.observer) {
      this.noteControl.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(this.observer);
    }
    this.noteControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((val) => this.noteValueChanged.emit(val));
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { value } = changes;

    if (value && (this.value || this.value === "")) {
      this.noteControl.setValue(value.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleCreate(): void {
    if (this.form.valid) {
      this.create.emit(this.noteControl.value);
    }
  }

  private createForm(): void {
    this.form = this.fb.group({ note: ["", Validators.required] });
  }
}
