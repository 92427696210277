<app-element-wrapper
  *ngIf="!!formElementService?.form"
  [infoTranslationKey]="infoTranslationKey"
  [width]="width"
  class="form-group text-input"
>
  <div
    [class.old-style]="oldStyle"
    [class.new-style]="newStyle"
    [formGroup]="formElementService?.form"
  >
    <mat-form-field
      [appearance]="!!oldStyle || !!newStyle ? 'outline' : 'fill'"
    >
      <mat-label *ngIf="!oldStyle && !newStyle">
        {{ label | translate }}
      </mat-label>
      <mat-select
        #selectDropdown
        [placeholder]="label | translate"
        [disabled]="disabled"
        [formControlName]="formElementService.getFieldName()"
        [multiple]="multiple"
      >
        <div class="select-container">
          <div class="exit-icon" *ngIf="isMobile$ | async">
            <app-icon name="times" (click)="selectDropdown.close()"></app-icon>
          </div>
          <ng-content></ng-content>
          <mat-optgroup *ngIf="!!search" class="search-field-container">
            <mat-form-field>
              <input
                #search
                autocomplete="off"
                [placeholder]="'search' | translate"
                matInput
                [formControl]="searchTextboxControl"
              />
              <button
                [disableRipple]="true"
                *ngIf="search.value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSearch($event)"
              >
                <app-icon name="close"></app-icon>
              </button>
            </mat-form-field>
          </mat-optgroup>
          <mat-optgroup *ngIf="items.length == 0">
            <div translate>no_results_found</div>
          </mat-optgroup>
        </div>
        <mat-option *ngIf="!multiple && !required" [value]="''">{{
          "all" | translate
        }}</mat-option>
        <mat-option *ngFor="let item of items" [value]="item.value">{{
          item.display | translate
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</app-element-wrapper>
