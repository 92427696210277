<div class="q-search-input__container">
  <app-q-input
    [label]="label"
    [placeholder]="placeholder"
    [invalid]="invalid"
    [formControl]="text"
  ></app-q-input>
  <ul class="q-search-input__listbox" *ngIf="show">
    <li *ngFor="let item of data" (click)="onClick(item)">
      <ng-template
        [ngTemplateOutlet]="itemTemplate"
        [ngTemplateOutletContext]="{ item: item }"
      ></ng-template>
    </li>
  </ul>
</div>
