import { FormattedSearchArea } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";

export interface SearchProfile {
  searchProfileId: string;
  maximumRooms: string;
  maximumPrice: string;
  maximumMonthlyFee: string;
  maximumBedrooms: string;
  maximumArea: string;
  maximumBuildYear: string;
  maximumLotArea: string;
  minimumRooms: string;
  minimumPrice: string;
  minimumMonthlyFee: string;
  minimumBedrooms: string;
  minimumArea: string;
  minimumBuildYear: string;
  minimumLotArea: string;
  hasBalcony: string;
  hasSauna: string;
  hasFireplace: string;
  hasElevator: string;
  hasGarage: string;
  contactId: string;
  comment?: string;
  objectCategories: string;
  objectType: string;
  title: string;
  municipalityIds: string;
  countyIds: string;
  customFilterIds: string;
  zipIds: string;
  eaOfficeId?: string;
  createdByEaEmployeeId?: string;
  updatedByEaEmployeeId?: string;
  updatedByEmployeeFullName?: string;
  createdByEmployeeFullName?: string;
  updatedDate?: string;
  insertedDate?: string;
  lotStatus: string;
  cityAreas?: {
    id: string;
    title: string;
  }[];
  municipalities?: {
    id: string;
    title: string;
  }[];
  countys?: {
    id: string;
    title: string;
  }[];
  tenureType: string;
  isNewProduction: string;
  foreignGeoDataCity?: string;
  foreignGeoDataCityArea?: string;
  foreignGeoDataProvince?: string;
  areaSearch?: FormattedSearchArea[];
}

export enum TenureTypes {
  ForSale = "1,2,3,4,5,7,8,9,10,11,12,13,14",
  ForRent = "6",
}
