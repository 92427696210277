import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { fadeInOutTrigger, fadeInUp } from "@app/shared/animations";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { debounceTime, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-list-utility-row",
  templateUrl: "./list-utility-row.component.html",
  styleUrls: ["./list-utility-row.component.scss"],
  animations: [fadeInUp(), fadeInOutTrigger("150ms")],
})
export class ListUtilityRowComponent implements OnInit, OnDestroy {
  @Input() enableSearch: boolean;
  @Input() enableAddButton: boolean;
  @Input() enableActionButtons: boolean;
  @Input() enableViewModes: boolean;
  @Input() enableMultipleAddButtons = false;
  @Input() selectedEntities: number;
  @Input() selectedEntitiesLabel = "hits";
  @Input() tooltipLabel = "";

  @Output() searchInput: EventEmitter<any> = new EventEmitter<any>();
  @Output() addButtonPressed: EventEmitter<void> = new EventEmitter<void>();

  modalRef: BsModalRef;

  searchFormControl: FormControl = new FormControl();
  unsubscribe$ = new Subject<void>();

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    if (this.enableSearch) {
      this.searchFormControl.valueChanges
        .pipe(takeUntil(this.unsubscribe$), debounceTime(400))
        .subscribe((value) => this.searchInput.emit(value));
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openModal(template: TemplateRef<any>): void {
    this.modalRef = this.modalService.show(template, {
      class: "modal-sm",
      animated: false,
    });
  }

  closeModal(): void {
    this.modalRef.hide();
  }
}
