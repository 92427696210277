<div class="wrapper wrapper--extra-footer-space">
  <div class="scroll-view">
    <contact-form
      #contactForm
      (onSubmit)="handleSubmit($event)"
      (onQuedroContactSelected)="handleQuedroContactSelected($event)"
    ></contact-form>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      (click)="contactForm.submit()"
      scrollToInvalidControl [formEl]="contactForm?.form">
    </app-q-button>
  </div>
</div>
