import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  AfterContentChecked,
  AfterContentInit,
  Component,
  ContentChild,
  ElementRef,
} from "@angular/core";
import { AdaptivePlaceholderDirective } from "../directives/adaptive-placeholder.directive";

@Component({
  selector: "adaptive-placeholder",
  templateUrl: "./adaptive-placeholder.component.html",
  styleUrls: ["./adaptive-placeholder.component.scss"],
  animations: [
    trigger("transitionMessages", [
      state("enter", style({ opacity: 1, transform: "translateY(0%)" })),
      transition("void => enter", [
        style({ opacity: 0, transform: "translateY(-100%)" }),
        animate("300ms cubic-bezier(0.55, 0, 0.55, 0.2)"),
      ]),
    ]),
  ],
})
export class AdaptivePlaceholderComponent
  implements AfterContentInit, AfterContentChecked
{
  @ContentChild(AdaptivePlaceholderDirective, { static: true })
  inputChild: AdaptivePlaceholderDirective;

  constructor(public _elementRef: ElementRef) {}

  ngAfterContentInit() {
    this._validateInputChild();
  }

  ngAfterContentChecked() {
    this._validateInputChild();
  }

  /** Determines whether a class from the NgControl should be forwarded to the host element. */
  _shouldForward(prop: string): boolean {
    const control = this.inputChild ? this.inputChild._ngControl : null;
    return control && (control as any)[prop];
  }

  /** Focuses the underlying input. */
  _focusInput() {
    this.inputChild.focus();
  }

  /**
   * Throws an error if the container's input child was removed.
   */
  private _validateInputChild() {
    if (!this.inputChild) {
      throw new Error(
        "adaptive-placeholder must contain an adaptivePlaceholder directive. " +
          "Did you forget to add input to the native input or textarea element?"
      );
    }
  }
}
