import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-sold-objects-widget/ngrx/statistics-sold-objects-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsSoldObjectsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((response: GrowthStatistics) => {
            if (params.returnTypes === "numberOfSoldObjects") {
              return widgetActions.fetchStatisticsSuccess({
                stats: response.totals.numberOfSoldObjects.current,
              });
            } else if (
              params.returnTypes === "numberOfSoldObjectsWithCommission"
            ) {
              return widgetActions.fetchStatisticsSuccess({
                stats:
                  response.totals.numberOfSoldObjectsWithCommission.current,
              });
            }
          }),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );

  fetchPreviousStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchPreviousStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((response: GrowthStatistics) => {
            if (params.returnTypes === "numberOfSoldObjects") {
              return widgetActions.fetchPreviousStatisticsSuccess({
                previousStats: response.totals.numberOfSoldObjects.current,
              });
            } else if (
              params.returnTypes === "numberOfSoldObjectsWithCommission"
            ) {
              return widgetActions.fetchPreviousStatisticsSuccess({
                previousStats:
                  response.totals.numberOfSoldObjectsWithCommission.current,
              });
            }
          }),
          catchError(() => of(widgetActions.fetchPreviousStatisticsFail()))
        )
      )
    )
  );
}
