import { Employee } from "@app/models";
import { KpiResponse } from "@app/statistics/statistics-kpi/models/kpi-statistics-response";
import {
  GroupTypes,
  KpiTypes,
  TimeFormatView,
} from "@app/statistics/statistics-kpi/utils/statistics-kpi-view-types";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsKpiActions from "./statistics-kpi.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface KpiStatisticsParams {
  kpiType: KpiTypes;
  viewType: TimeFormatView;
  groupType: GroupTypes;
  params: {
    eaEmployeeId?: string;
    eaOfficeId?: string;
    periodMonthStart?: string;
    periodMonthEnd?: string;
    periodWeekStart?: string;
    periodWeekEnd?: string;
    sortByPeriod?: string;
    sortOrder?: string;
    sortBy?: string;
  };
}

export interface StatisticsKpiState {
  loading: boolean;
  employees: Employee[];
  kpiStatistics: KpiResponse | null;
  csv: string;
  csvLoading: boolean;
}

export const initialState: StatisticsKpiState = {
  loading: false,
  employees: [],
  kpiStatistics: null,
  csv: "",
  csvLoading: false,
};

export function statisticsKpiReducer(
  state: StatisticsKpiState,
  action: Action
): StatisticsKpiState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsKpiActions.getEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
  })),
  on(
    statisticsKpiActions.getEmployeesFailure,
    statisticsKpiActions.getEmployeesRequest,
    (state) => ({ ...state })
  ),
  on(statisticsKpiActions.getStatisticsCsvRequest, (state, {}) => ({
    ...state,
    csvLoading: true,
  })),
  on(statisticsKpiActions.getStatisticsCsvSuccess, (state, { csv }) => ({
    ...state,
    csvLoading: false,
    csv,
  })),
  on(statisticsKpiActions.getStatisticsCsvFailure, (state) => ({
    ...state,
    csvLoading: false,
  })),
  on(statisticsKpiActions.fetchStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(statisticsKpiActions.fetchStatisticsSuccess, (state, { statistics }) => ({
    ...state,
    loading: false,
    kpiStatistics: statistics,
  })),
  on(statisticsKpiActions.fetchStatisticsFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(statisticsKpiActions.resetStatisticsKpi, (state) => ({
    ...state,
    loading: false,
    kpiStatistics: null,
  })),
  on(statisticsKpiActions.emptyEmployeesRequest, (state) => ({
    ...state,
    employees: [],
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.kpi
);

export const getLoading = createSelector(
  getState,
  (state: StatisticsKpiState) => state.loading
);
export const getCsvLoading = createSelector(
  getState,
  (state: StatisticsKpiState) => state.csvLoading
);
export const getEmployees = createSelector(
  getState,
  (state: StatisticsKpiState) => state.employees
);
export const getKpiStatistics = createSelector(
  getState,
  (state: StatisticsKpiState) => state.kpiStatistics
);
export const getCsv = createSelector(
  getState,
  (state: StatisticsKpiState) => state.csv
);
