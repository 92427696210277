import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { generateUUID } from "@app/shared/utils/uuid";
import { createAction, props } from "@ngrx/store";

export const showProgressWidget = createAction(
  "[PROGRESS WIDGET] SHOW_PROGRESS_WIDGET"
);

export const hideProgressWidget = createAction(
  "[PROGRESS WIDGET] HIDE_PROGRESS_WIDGET"
);

export const addTaskSetToQueue = (taskSet: TaskSet) => {
  return addTaskSetToQueueRequest({
    taskSet: {
      ...taskSet,
      id: generateUUID(),
      completed: 0,
      failed: 0,
    },
  });
};

export const addTaskSetToQueueRequest = createAction(
  "[PROGRESS WIDGET] ADD_TASK_SET_TO_QUEUE",
  props<{
    taskSet: TaskSet;
  }>()
);

export const addTaskSetToQueueSuccess = createAction(
  "[PROGRESS WIDGET] ADD_TASK_SET_TO_QUEUE_SUCCESS"
);

export const executeTaskSetRequest = createAction(
  "[PROGRESS WIDGET] EXECUTE_TASK_SET_REQUEST",
  props<{ taskSet: TaskSet }>()
);

export const executeTaskSetSuccess = createAction(
  "[PROGRESS WIDGET] EXECUTE_TASK_SET_SUCCESS",
  props<{ id: string }>()
);

export const executeTaskSetFail = createAction(
  "[PROGRESS WIDGET] EXECUTE_TASK_SET_FAIL"
);

export const executing = createAction("[PROGRESS WIDGET] EXECUTING");

export const incrementCompletedCount = createAction(
  "[PROGRESS WIDGET] INCREMENT_COMPLETED_COUNT",
  props<{ id: string }>()
);

export const incrementFailedCount = createAction(
  "[PROGRESS WIDGET] INCREMENT_FAILED_COUNT",
  props<{ id: string }>()
);
