import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { NoteService } from "@app/core/ngrx/entity-services/note.service";
import { Action } from "@app/models";
import * as fromUser from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { first, map, Observable, switchMap } from "rxjs";

@Component({
  selector: "contact-actions-widget-list-item",
  templateUrl: "./contact-actions-widget-list-item.component.html",
  styleUrls: ["./contact-actions-widget-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactActionsWidgetListItemComponent implements OnInit {
  @Input() action: Action;
  @Output() delete = new EventEmitter<string>();
  @Output() noteUpdated = new EventEmitter<string>();

  eaEmployeeId$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private noteService: NoteService
  ) {}

  ngOnInit() {
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
  }

  onDelete(): void {
    this.delete.emit(this.action.actionId);
  }

  patchNote(params) {
    this.eaEmployeeId$
      .pipe(
        first(),
        switchMap((eaEmployeeId) =>
          this.noteService
            .patch(this.action.externalId, { ...params, eaEmployeeId })
            .pipe(map(() => this.noteUpdated.emit(Math.random().toString())))
        )
      )
      .subscribe();
  }
}
