<div class="q-table-top">
  <div class="q-table-top--left">
    <ng-content select="[slot=buttons]"></ng-content>
    <spinner
      [size]="'28px'"
      [style.margin-left]="'8px'"
      *ngIf="loading"
    ></spinner>
  </div>
  <div
    class="q-table-top--right"
    [style.width]="enableSearch ? '226px' : 'inherit'"
    [style.justify-content]="!enableCustomization ? 'flex-end' : 'inherit'"
  >
    <div
      *ngIf="enableSearch"
      class="search-box"
      (click)="handleDiv(searchBox)"
      [class.has-value]="!!searchControl.value.toString().trim()"
    >
      <input
        type="text"
        class="search-box__input"
        [formControl]="searchControl"
        #searchBox
      />
      <button class="search-box__submit" (click)="handleSearchBox(searchBox)">
        <app-icon
          *ngIf="!searchControl.value.toString()"
          name="search"
        ></app-icon>
        <app-icon
          *ngIf="!!searchControl.value.toString()"
          name="times"
        ></app-icon>
      </button>
    </div>
    <ng-container *ngIf="enableCustomization">
      <div class="density-group">
        <button
          class="density-group__btn"
          [class.density-group__btn--active]="density === 'default'"
          (click)="changeDensity('default')"
        >
          <ng-container *ngTemplateOutlet="densityDefaultIcon"></ng-container>
        </button>
        <button
          class="density-group__btn"
          [class.density-group__btn--active]="density === 'comfortable'"
          (click)="changeDensity('comfortable')"
        >
          <ng-container
            *ngTemplateOutlet="densityComfortableIcon"
          ></ng-container>
        </button>
        <button
          class="density-group__btn"
          [class.density-group__btn--active]="density === 'compact'"
          (click)="changeDensity('compact')"
        >
          <ng-container *ngTemplateOutlet="densityCompactIcon"></ng-container>
        </button>
      </div>
      <button class="customize-columns-btn" (click)="customizeColumns()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path
            fill="#C2C9D1"
            d="M10 19h5V6h-5v13zm-6 0h5V6H4v13zM16 6v13h5V6h-5z"
          />
          <path
            fill="currentColor"
            d="M10 19h5V6h-5v13zm-6 0h5V6H4v13zM16 6v13h5V6h-5z"
          />
        </svg>
      </button>
    </ng-container>
  </div>
</div>
<div class="q-table">
  <div class="q-table-wrapper" #table>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="onSortChange($event)"
      matSortStart="desc"
      [matSortActive]="sortActive"
      [matSortDirection]="sortDirection"
      multiTemplateDataRows
    >
      <ng-container matColumnDef="expand">
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="!enableExpandRow"
          class="q-table-header-cell"
          translate
        ></th>
        <td
          mat-cell
          *matCellDef="let row"
          [hidden]="!enableExpandRow"
          class="q-table-row-cell"
        >
          <button
            class="expanded-icon"
            [class.expanded-icon--rotated]="expandedRow === row"
            (click)="expandRow(row)"
          >
            <app-icon size="large" [name]="'angle-right'"></app-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="select" sticky>
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="!enableSelection"
          class="q-table-header-cell q-table-header-selection-cell"
        >
          <div class="chk-overlay-container">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [disabled]="loading"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              class="chk-overlay-check"
            >
            </mat-checkbox>
            <div class="density-group chk-overlay">
              <button
                class="density-group__btn"
                [class.density-group__btn--active]="
                  selection.selected.length === 0
                "
                (click)="clearSelection()"
                [matTooltip]="'clear_selection' | translate"
                matTooltipClass="q-tooltip"
              >
                <app-icon name="square" type="regular"></app-icon>
              </button>
              <button
                *ngIf="!!enablePaginator"
                class="density-group__btn"
                [class.density-group__btn--active]="
                  selection.hasValue() && selection.selected.length !== length
                "
                (click)="
                  selection.selected.length === length
                    ? clearSelection()
                    : null;
                  masterToggle()
                "
                [matTooltip]="'select_current_page' | translate"
                matTooltipClass="q-tooltip"
              >
                <app-icon name="check" type="regular"></app-icon>
              </button>
              <button
                class="density-group__btn"
                [class.density-group__btn--active]="
                  selection.selected.length === length
                "
                (click)="masterToggle(true)"
                [matTooltip]="'select_all' | translate"
                matTooltipClass="q-tooltip"
              >
                <app-icon name="check-double" type="regular"></app-icon>
              </button>
            </div>
          </div>
          <span
            class="select-all"
            [matTooltip]="'select_all' | translate"
            (click)="loading ? null : masterToggle(true)"
            matTooltipClass="q-tooltip"
          >
            {{ selection.selected.length }}/{{ length }}
          </span>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          [hidden]="!enableSelection"
          class="q-table-row-cell"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            [disabled]="loading"
            (change)="$event ? toggle(row) : null"
            [checked]="selection.isSelected(row) || isAllSelected()"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container
        *ngFor="let column of displayedColumns | slice : 2 : -1"
        [matColumnDef]="column"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          class="q-table-header-cell"
          mat-sort-header
          [disabled]="isSortable(column)"
        >
          {{ getHeader(column) }}&nbsp;&nbsp;
          <app-tooltip-icon
            *ngIf="!!key && !!column"
            [translationKey]="
              'qtable_' +
              key.toLowerCase() +
              '_' +
              column.toLowerCase() +
              '_tooltip'
            "
          ></app-tooltip-icon>
        </th>
        <td mat-cell *matCellDef="let row" class="q-table-row-cell">
          <ng-template
            [ngTemplateOutlet]="templateList.first"
            [ngTemplateOutletContext]="{
              column: column,
              row: row,
              cell: row[column]
            }"
          ></ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions" stickyEnd>
        <th
          mat-header-cell
          *matHeaderCellDef
          [hidden]="!enableActions"
          class="q-table-header-cell"
        ></th>
        <td
          mat-cell
          *matCellDef="let row; let i = dataIndex"
          [hidden]="!enableActions"
          class="q-table-row-cell q-table-row-cell--action"
          [class.open-dropdown]="
            dropdowns?.length > 0 &&
            !!dropdowns?.toArray()[i] &&
            dropdowns.toArray()[i].showDropdown
          "
        >
          <app-q-action-dropdown [onHover]="true" [parentRef]="table">
            <ng-template
              [ngTemplateOutlet]="templateList.last"
              [ngTemplateOutletContext]="{ row: row }"
            ></ng-template>
          </app-q-action-dropdown>
        </td>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let row"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            class="expanded-details"
            [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'"
          >
            <ng-container *ngIf="row == expandedRow">
              <ng-template
                [ngTemplateOutlet]="templateList.first"
                [ngTemplateOutletContext]="{
                  column: 'expandedDetail',
                  row: row,
                  cell: null
                }"
              ></ng-template>
            </ng-container>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="q-table-row"
        [class.q-table-row-clickable]="enableClick"
        [style]="{ height: getRowHeight() }"
        (click)="rowClick.emit(row)"
      ></tr>
      <ng-container *ngIf="!!enableExpandRow">
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          class="q-table-expanded"
        ></tr>
      </ng-container>
    </table>
  </div>

  <ng-container *ngIf="enablePaginator">
    <ng-container *ngIf="enableShowAll">
      <mat-paginator
        [disabled]="loading"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="defaultPageSize"
        [length]="length"
        (page)="onPageChange($event)"
        addAllToPaginator
        [allValue]="length"
      >
      </mat-paginator>
    </ng-container>
    <ng-container *ngIf="!enableShowAll">
      <mat-paginator
        [disabled]="loading"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="defaultPageSize"
        [length]="length"
        (page)="onPageChange($event)"
      >
      </mat-paginator>
    </ng-container>
  </ng-container>
</div>

<ng-template #densityDefaultIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="#C2C9D1" d="M4 4h16v4.3H4z" />
    <path fill="currentColor" d="M4 4h16v4.3H4z" />
    <path fill="#C2C9D1" d="M4 9.3h16v4.3H4z" />
    <path fill="currentColor" d="M4 9.3h16v4.3H4z" />
    <path fill="#C2C9D1" d="M4 14.6h16v4.3H4z" />
    <path fill="currentColor" d="M4 14.6h16v4.3H4z" />
  </svg>
</ng-template>
<ng-template #densityComfortableIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="#C2C9D1" d="M4 8h16v3H4z" />
    <path fill="currentColor" d="M4 8h16v3H4z" />
    <path fill="#C2C9D1" d="M4 4h16v3H4z" />
    <path fill="currentColor" d="M4 4h16v3H4z" />
    <path fill="#C2C9D1" d="M4 12h16v3H4z" />
    <path fill="currentColor" d="M4 12h16v3H4z" />
    <path fill="#C2C9D1" d="M4 16h16v3H4z" />
    <path fill="currentColor" d="M4 16h16v3H4z" />
  </svg>
</ng-template>
<ng-template #densityCompactIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    width="24"
    height="24"
  >
    <path fill="#C2C9D1" d="M4 7.2h16v2.2H4z" />
    <path fill="currentColor" d="M4 7.2h16v2.2H4z" />
    <path fill="#C2C9D1" d="M4 4h16v2.2H4z" />
    <path fill="currentColor" d="M4 4h16v2.2H4z" />
    <path fill="#C2C9D1" d="M4 13.6h16v2.2H4z" />
    <path fill="currentColor" d="M4 13.6h16v2.2H4z" />
    <path fill="#C2C9D1" d="M4 10.4h16v2.2H4z" />
    <path fill="currentColor" d="M4 10.4h16v2.2H4z" />
    <path fill="#C2C9D1" d="M4 16.8h16V19H4z" />
    <path fill="currentColor" d="M4 16.8h16V19H4z" />
  </svg>
</ng-template>
