import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";

@Component({
  selector: "app-textarea-auto-expanding",
  templateUrl: "./textarea-auto-expanding.component.html",
  styleUrls: ["./textarea-auto-expanding.component.scss"],
})
export class TextareaAutoExpandingComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild("textArea", { static: false })
  textArea: ElementRef;
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName: string;
  @Input() lineHeightPx = 23;
  @Input() baseHeightPx = 32;
  @Input() maxHeightPx = 500;
  @Input() adaptivePlaceholder = "";

  textAreaHeight$: Subject<number> = new Subject<number>();

  ngAfterViewInit() {
    const scrollHeight =
      this.textArea.nativeElement.scrollHeight === 0
        ? 56
        : this.textArea.nativeElement.scrollHeight;
    this.textAreaHeight$.next(scrollHeight);
  }

  ngOnDestroy() {
    this.textAreaHeight$.next(null);
    this.textAreaHeight$.complete();
  }

  handleTextLength(input): void {
    const scrollHeight = input.target.scrollHeight;
    this.textAreaHeight$.next(scrollHeight);
  }
}
