import { Component, EventEmitter, HostListener, Output } from "@angular/core";

@Component({
  selector: "reset-filter",
  templateUrl: "./reset-filter.component.html",
  styleUrls: ["./reset-filter.component.scss"],
})
export class ResetFilterComponent {
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset = new EventEmitter<void>();

  @HostListener("click")
  handleClick() {
    this.reset.emit();
  }
}
