import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { Store } from "@ngrx/store";
import * as mobileNavigationActions from "../../../../shared-features/mobile-navigation/ngrx/mobile-navigation.actions";

@Component({
  selector: "page-tab-bar",
  templateUrl: "./page-tab-bar.component.html",
  styleUrls: ["./page-tab-bar.component.scss"],
})
export class PageTabBarComponent implements OnChanges, OnDestroy {
  @Input() subPages: SubPage[];

  constructor(private store: Store<AppState>) {}

  ngOnChanges(changes: SimpleChanges) {
    const { subPages } = changes;
    if (subPages) {
      this.store.dispatch(
        mobileNavigationActions.setRoutes({
          subPages: (<SimpleChange>subPages).currentValue,
        })
      );
    }
  }

  ngOnDestroy() {
    this.store.dispatch(mobileNavigationActions.reset());
  }
}
