import { Contact } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as segmentationListActions from "./segmentation-list.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export interface SegmentState {
  isLoading: boolean;
  loaded: boolean;
  total: number;
  itemsLeftToLoad: number;
  allContactsloaded: boolean;
  contacts: Contact[];
  segmentId: number;
  segmentName: string;
}

export const initialSegmentState: SegmentState = {
  isLoading: false,
  loaded: false,
  total: 0,
  itemsLeftToLoad: 0,
  allContactsloaded: false,
  contacts: [],
  segmentId: 0,
  segmentName: "",
};

export interface SegmentationListState {
  segments: SegmentState[];
  isLoading: boolean;
}

export const initialState: SegmentationListState = {
  segments: [],
  isLoading: false,
};

// TODO: Remove "| any" from action type
export function segmentationListReducer(
  state: SegmentationListState,
  action: Action
): SegmentationListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(segmentationListActions.searchSegmentsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(segmentationListActions.searchSegmentsSuccess, (state, { segments }) => {
    const updatedSegments = segments.map(
      (segment) =>
        ({
          ...initialSegmentState,
          segmentId: +segment.segmentId,
          segmentName: segment.segmentName,
        } as SegmentState)
    );
    return { ...state, isLoading: false, segments: updatedSegments };
  }),
  on(
    segmentationListActions.searchSegmentListRequest,
    (state, { segmentId }) => {
      const segments = updateSegmentWithProps(state.segments, segmentId, {
        isLoading: true,
      });
      return { ...state, segments };
    }
  ),
  on(
    segmentationListActions.searchSegmentListSuccess,
    (state, { data, segmentId }) => {
      const { rows, offset, total } = data;
      const oldSegment = state.segments.find((s) => s.segmentId === segmentId);
      let segments = state.segments;
      if (!!oldSegment) {
        const { contacts: oldConsumers } = oldSegment;
        const itemsLeftToLoad = total - offset - rows.length;
        const allContactsloaded = total <= 10 || itemsLeftToLoad === 0;
        const newContactsList = [...oldConsumers, ...rows];
        const updateContactsList = [
          ...new Map(
            newContactsList.map((item) => [item["contactId"], item])
          ).values(),
        ];
        const props = {
          isLoading: false,
          contacts: updateContactsList,
          total,
          itemsLeftToLoad,
          allContactsloaded,
        };
        segments = updateSegmentWithProps(state.segments, segmentId, props);
      }
      return { ...state, segments };
    }
  ),
  on(
    segmentationListActions.searchSegmentListForCallingListRequest,
    segmentationListActions.searchSegmentListForCallingListSuccess,
    (state) => ({
      ...state,
    })
  ),
  on(segmentationListActions.clearSegment, (state, { segmentId }) => {
    const oldSegment = state.segments.find((s) => s.segmentId === segmentId);
    let segments = state.segments;
    if (!!oldSegment) {
      const props = {
        ...oldSegment,
        isLoading: false,
        loaded: false,
        itemsLeftToLoad: 0,
        allContactsloaded: false,
        contacts: [],
      };
      segments = updateSegmentWithProps(state.segments, segmentId, props);
    }
    return { ...state, segments };
  }),
  on(segmentationListActions.reset, () => ({ ...initialState }))
);

function updateSegmentWithProps(
  segments: SegmentState[],
  segmentId: number,
  props: any
): SegmentState[] {
  const _segments = [...segments];
  const segment = _segments.find((s) => s.segmentId === segmentId);
  const segmentIndex = _segments.indexOf(segment);
  _segments[segmentIndex] = { ...segment, ...props };
  return _segments;
}

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.segmentationList
);

export const getSegments = createSelector(
  getState,
  (state: SegmentationListState) => state.segments
);
