import {
  AdjustedCommissions,
  InvoiceSummary,
  OneTimeFeesData,
  OtherRevenuesDetails,
  PeriodCommissionDetails,
  SubscriptionFeesData,
} from "@app/settings/invoicing/components/invoice-documentation/models/models";
import {
  InvoicingPeriodParams,
  InvoicingPeriodResponse,
} from "@app/statistics/statistics-invoicing/models/invoicing-models";
import { createAction, props } from "@ngrx/store";

export const getInvoiceSummaryRequest = createAction(
  "[Invoice Documentation] GET_INVOICE_SUMMARY_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getInvoiceSummarySuccess = createAction(
  "[Invoice Documentation] GET_INVOICE_SUMMARY_SUCCESS",
  props<{ invoiceSummary: InvoiceSummary }>()
);

export const getInvoiceSummaryFailure = createAction(
  "[Invoice Documentation] GET_INVOICE_SUMMARY_FAILURE"
);

export const getInvoicePeriodsRequest = createAction(
  "[Invoice Documentation] GET_INVOICE_PERIODS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getInvoicePeriodsSuccess = createAction(
  "[Invoice Documentation] GET_INVOICE_PERIODS_SUCCESS",
  props<{ invoicingPeriods: InvoicingPeriodResponse[] }>()
);

export const getInvoicePeriodsFailure = createAction(
  "[Invoice Documentation] GET_INVOICE_PERIODS_FAILURE"
);

export const getPeriodCommissionDetailsRequest = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getPeriodCommissionDetailsSuccess = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_SUCCESS",
  props<{ periodCommissionDetails: PeriodCommissionDetails }>()
);

export const getPeriodCommissionDetailsFailure = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_FAILURE"
);

export const getPeriodCommissionDetailsCSVRequest = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_CSV_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getPeriodCommissionDetailsCSVSuccess = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_CSV_SUCCESS"
);

export const getPeriodCommissionDetailsCSVFailure = createAction(
  "[Invoice Documentation] GET_PERIOD_COMMISSION_DETAILS_CSV_FAILURE"
);

export const getOtherRevenuesDetailsRequest = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getOtherRevenuesDetailsSuccess = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_SUCCESS",
  props<{ otherRevenuesDetails: OtherRevenuesDetails }>()
);

export const getOtherRevenuesDetailsFailure = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_FAILURE"
);

export const getOtherRevenuesDetailsCSVRequest = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_CSV_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getOtherRevenuesDetailsCSVSuccess = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_CSV_SUCCESS"
);

export const getOtherRevenuesDetailsCSVFailure = createAction(
  "[Invoice Documentation] GET_OTHER_REVENUES_DETAILS_CSV_FAILURE"
);

export const getAdjustedCommissionsRequest = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getAdjustedCommissionsSuccess = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_SUCCESS",
  props<{ adjustedCommissions: AdjustedCommissions }>()
);

export const getAdjustedCommissionsFailure = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_FAILURE"
);

export const getAdjustedCommissionsCSVRequest = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_CSV_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getAdjustedCommissionsCSVSuccess = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_CSV_SUCCESS"
);

export const getAdjustedCommissionsCSVFailure = createAction(
  "[Invoice Documentation] GET_ADJUSTED_COMMISSIONS_CSV_FAILURE"
);

export const getOneTimeFeesDataRequest = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_DATA_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getOneTimeFeesDataSuccess = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_DATA_SUCCESS",
  props<{ oneTimeFeesData: OneTimeFeesData }>()
);

export const getOneTimeFeesDataFailure = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_DATA_FAILURE"
);

export const getOneTimeFeesCSVRequest = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_CSV_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getOneTimeFeesCSVSuccess = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_CSV_SUCCESS"
);

export const getOneTimeFeesCSVFailure = createAction(
  "[Invoice Documentation] GET_ONE_TIME_FEES_CSV_FAILURE"
);

export const getSubscriptionFeesDataRequest = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_DATA_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getSubscriptionFeesDataSuccess = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_DATA_SUCCESS",
  props<{ subscriptionFeesData: SubscriptionFeesData }>()
);

export const getSubscriptionFeesDataFailure = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_DATA_FAILURE"
);

export const getSubscriptionFeesCSVRequest = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_CSV_REQUEST",
  props<{ params: InvoicingPeriodParams }>()
);

export const getSubscriptionFeesCSVSuccess = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_CSV_SUCCESS"
);

export const getSubscriptionFeesCSVFailure = createAction(
  "[Invoice Documentation] GET_SUBSCRIPTION_FEES_CSV_FAILURE"
);

export const resetState = createAction("[Invoice Documentation] RESET_STATE");
