<li (dblclick)="selectOfficeAndLogin(employee, office)">
  <span>
    {{ office.officeName
    }}<em *ngIf="office.street && office.officeName">,</em> {{ office.street }}
    <br />
    {{ office.city
    }}<span class="select-office-item__role" *ngIf="office.role !== 'broker'">
      {{ office.role | translate }}</span
    >
  </span>
  <button
    (click)="selectOfficeAndLogin(employee, office)"
    class="btn btn-primary pull-right submit"
  >
    <i class="fa fa-long-arrow-right icon--lg" aria-hidden="true"></i>
  </button>
</li>
