import { Employee } from "@app/models/employee";
import { Nps, NpsEntity } from "@app/models/nps";
import { SurveyResponse } from "@app/models/survey";
import * as statisticsNpsActions from "@app/statistics/statistics-nps/ngrx/statistics-nps.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsNpsState {
  officeNpsLoading: boolean;
  officeNps: NpsEntity | null;
  totalNpsLoading: boolean;
  totalNps: Nps | null;
  npsResultsLoading: boolean;
  moreNpsResultsLoading: boolean;
  npsResultsTotal: number | null;
  npsResults: (NpsEntity | Nps)[] | null;
  surveys: SurveyResponse[] | null;
  employees: Employee[] | null;
}

export const initialState: StatisticsNpsState = {
  officeNpsLoading: false,
  officeNps: null,
  totalNpsLoading: false,
  totalNps: null,
  npsResultsLoading: false,
  moreNpsResultsLoading: false,
  npsResults: null,
  npsResultsTotal: null,
  surveys: null,
  employees: null,
};

export function statisticsNpsReducer(
  state: StatisticsNpsState,
  action: Action
): StatisticsNpsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsNpsActions.fetchTotalNpsStatisticsRequest, (state) => ({
    ...state,
    totalNpsLoading: true,
  })),
  on(
    statisticsNpsActions.fetchTotalNpsStatisticsSuccess,
    (state, { totalNps }) => ({
      ...state,
      totalNpsLoading: false,
      totalNps,
    })
  ),
  on(statisticsNpsActions.fetchTotalNpsStatisticsFail, (state) => ({
    ...state,
    totalNpsLoading: false,
  })),
  on(statisticsNpsActions.fetchOfficeNpsStatisticsRequest, (state) => ({
    ...state,
    officeNpsLoading: true,
  })),
  on(
    statisticsNpsActions.fetchOfficeNpsStatisticsSuccess,
    (state, { officeNps }) => ({
      ...state,
      officeNpsLoading: false,
      officeNps,
    })
  ),
  on(statisticsNpsActions.fetchOfficeNpsStatisticsFail, (state) => ({
    ...state,
    officeNpsLoading: false,
  })),
  on(statisticsNpsActions.fetchNpsResultsStatisticsRequest, (state) => ({
    ...state,
    npsResultsLoading: true,
  })),
  on(
    statisticsNpsActions.fetchNpsResultsStatisticsSuccess,
    (state, { npsResult }) => ({
      ...state,
      npsResultsLoading: false,
      npsResults: npsResult.rows,
      npsResultsTotal: npsResult.total,
    })
  ),
  on(statisticsNpsActions.fetchNpsResultsStatisticsFail, (state) => ({
    ...state,
    npsResultsLoading: false,
  })),
  on(statisticsNpsActions.fetchMoreNpsResultsStatisticsRequest, (state) => ({
    ...state,
    moreNpsResultsLoading: true,
  })),
  on(
    statisticsNpsActions.fetchMoreNpsResultsStatisticsSuccess,
    (state, { npsResults }) => ({
      ...state,
      moreNpsResultsLoading: false,
      npsResults: [...state.npsResults, ...npsResults],
    })
  ),
  on(statisticsNpsActions.fetchMoreNpsResultsStatisticsFail, (state) => ({
    ...state,
    moreNpsResultsLoading: false,
  })),
  on(statisticsNpsActions.fetchSurveysRequest, (state) => ({
    ...state,
  })),
  on(statisticsNpsActions.fetchSurveysSuccess, (state, { surveys }) => ({
    ...state,
    surveys,
  })),
  on(statisticsNpsActions.fetchSurveysFail, (state) => ({
    ...state,
  })),
  on(statisticsNpsActions.fetchEmployeesRequest, (state) => ({
    ...state,
  })),
  on(statisticsNpsActions.fetchEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
  })),
  on(statisticsNpsActions.fetchEmployeesFail, (state) => ({
    ...state,
  })),
  on(statisticsNpsActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");

const getNpsState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.nps
);

export const getOfficeNpsLoading = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.officeNpsLoading
);

export const getOfficeNps = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.officeNps
);

export const getTotalNpsLoading = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.totalNpsLoading
);

export const getTotalNps = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.totalNps
);

export const getNpsResultsLoading = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.npsResultsLoading
);

export const getMoreNpsResultsLoading = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.moreNpsResultsLoading
);

export const getNpsResultsTotal = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.npsResultsTotal
);

export const getNpsResults = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.npsResults
);

export const getSurveys = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.surveys
);

export const getEmployees = createSelector(
  getNpsState,
  (state: StatisticsNpsState) => state.employees
);
