export interface ExtraRealtyType {
  key: string;
  id: number;
}

export const EXTRA_REALTY_TYPES: ExtraRealtyType[] = [
  {
    key: "tila",
    id: 1,
  },
  {
    key: "tontti",
    id: 2,
  },
  {
    key: "määräala",
    id: 3,
  },
  {
    key: "määräosa",
    id: 4,
  },
  {
    key: "omakotitalo",
    id: 5,
  },
  {
    key: "vapaa-ajan asunto",
    id: 6,
  },
  {
    key: "rakentamaton",
    id: 7,
  },
  {
    key: "metsätila",
    id: 8,
  },
  {
    key: "maatila",
    id: 9,
  },
];
