import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { SUPPORT } from "@app/shared/utils/tab-types";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as supportActions from "@app/sidebar/support/ngrx/support.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";

@Injectable()
export class SupportEffects {
  sendSupportMail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportActions.sendMessageToSupportRequest),
      switchMap(({ sendMessageParams }) =>
        this.apiService.post("messages/email", sendMessageParams).pipe(
          map(() => supportActions.sendMessageToSupportSuccess()),
          catchError(() => of(supportActions.sendMessageToSupportFailure()))
        )
      )
    )
  );

  sendSupportMailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportActions.sendMessageToSupportSuccess),
      mergeMap(() => [
        sidebarActions.closeTab({ tabType: SUPPORT }),
        toastActions.success({ message: "support_mail_sent" }),
      ])
    )
  );

  sendSupportMailFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(supportActions.sendMessageToSupportFailure),
      mergeMap(() => [
        toastActions.danger({ message: "support_mail_not_sent" }),
      ])
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
