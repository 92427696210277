import { DelegatedObjectRequirements } from "@app/sidebar/create-object/ngrx/create-object.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./create-object.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface CreateObjectState {
  loading: boolean;
  storingBrf: boolean;
  storingLantmateri: boolean;
  storingManual: boolean;
  delegatedObjectRequirements: DelegatedObjectRequirements;
}

export const initialState: CreateObjectState = {
  loading: false,
  storingBrf: false,
  storingLantmateri: false,
  storingManual: false,
  delegatedObjectRequirements: null,
};

export function createObjectReducer(state: CreateObjectState, action: Action) {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getObjectRequirementsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.getObjectRequirementsSuccess, (state, { payload }) => ({
    ...state,
    delegatedObjectRequirements: payload,
    loading: false,
  })),
  on(actions.getObjectRequirementsFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(actions.storeObjectRequest, (state, { params }) => {
    switch (params.createMode) {
      case "CREATE_OBJECT_MODE_BRF":
        return { ...state, storingBrf: true };
      case "CREATE_OBJECT_MODE_LANTMATERI":
        return { ...state, storingLantmateri: true };
      case "CREATE_OBJECT_MODE_MANUAL":
        return { ...state, storingManual: true };
      default:
        return { ...state };
    }
  }),
  on(actions.storeObjectSuccess, actions.storeObjectFailed, (state) => ({
    ...state,
    storingBrf: false,
    storingLantmateri: false,
    storingManual: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.createObject
);

export const getLoading = createSelector(
  getState,
  (state: CreateObjectState) => state.loading
);

export const getStoringBrf = createSelector(
  getState,
  (state: CreateObjectState) => state.storingBrf
);

export const getStoringLantmateri = createSelector(
  getState,
  (state: CreateObjectState) => state.storingLantmateri
);

export const getStoringManual = createSelector(
  getState,
  (state: CreateObjectState) => state.storingManual
);

export const getDelegatedObjectRequirements = createSelector(
  getState,
  (state: CreateObjectState) => state.delegatedObjectRequirements
);
