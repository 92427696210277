import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/sidebar/lead-templates/ngrx/lead-templates-form.actions";
import { TaskTemplate } from "@app/models";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface LeadTemplatesFormState {
  data: TaskTemplate;
  isLoading: boolean;
  isWorking: boolean;
}

const initialState: LeadTemplatesFormState = {
  data: null,
  isLoading: false,
  isWorking: false,
};

export const leadTemplatesFormReducer = createReducer(
  initialState,
  on(actions.getLeadTemplateRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadTemplateSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: true,
  })),
  on(actions.getLeadTemplateFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(
    actions.createLeadTemplateRequest,
    actions.editLeadTemplateRequest,
    actions.deleteLeadTemplateRequest,
    (state) => ({
      ...state,
      isWorking: true,
    })
  ),
  on(
    actions.createLeadTemplateSuccess,
    actions.createLeadTemplateFail,
    actions.editLeadTemplateSuccess,
    actions.editLeadTemplateFail,
    actions.deleteLeadTemplateSuccess,
    actions.deleteLeadTemplateFail,
    (state) => ({
      ...state,
      isWorking: false,
    })
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
export const selectLeadTemplatesForm = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.leadTemplatesForm
);

export const selectData = createSelector(
  selectLeadTemplatesForm,
  (state: LeadTemplatesFormState) => state.data
);
export const selectIsLoading = createSelector(
  selectLeadTemplatesForm,
  (state: LeadTemplatesFormState) => state.isLoading
);
export const selectIsWorking = createSelector(
  selectLeadTemplatesForm,
  (state: LeadTemplatesFormState) => state.isWorking
);
