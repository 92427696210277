import { createAction, props } from "@ngrx/store";
import { TaskType } from "@app/models";

const ACTION_PREFIX = "[Lead channels form]";

export const getLeadChannelRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNEL_REQUEST`,
  props<{ id: number }>()
);

export const getLeadChannelSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNEL_SUCCESS`,
  props<{ data: TaskType }>()
);

export const getLeadChannelFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNEL_FAIL`
);

export const createLeadChannelRequest = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_CHANNEL_REQUEST`,
  props<{ body: Partial<TaskType> }>()
);

export const createLeadChannelSuccess = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_CHANNEL_SUCCESS`,
  props<{ data: TaskType }>()
);

export const createLeadChannelFail = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_CHANNEL_FAIL`
);

export const editLeadChannelRequest = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_CHANNEL_REQUEST`,
  props<{ id: number; body: Partial<TaskType> }>()
);

export const editLeadChannelSuccess = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_CHANNEL_SUCCESS`,
  props<{ data: TaskType }>()
);

export const editLeadChannelFail = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_CHANNEL_FAIL`
);

export const deleteLeadChannelRequest = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_CHANNEL_REQUEST`,
  props<{ id: number }>()
);

export const deleteLeadChannelSuccess = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_CHANNEL_SUCCESS`
);

export const deleteLeadChannelFail = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_CHANNEL_FAIL`
);
