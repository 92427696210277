import { NavigationExtras } from "@angular/router";
import { createAction, props } from "@ngrx/store";

export const go = createAction(
  "[Router] Go",
  props<{
    path: string | unknown[];
    query?: Record<string, unknown>;
    extras?: NavigationExtras;
  }>()
);

export const goByUrl = createAction(
  "[Router] GoByUrl",
  props<{ url: string }>()
);

export const back = createAction("[Router] Back");

export const forward = createAction("[Router] Forward");
