import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "form-input-error",
  template: `
    <span
      translate
      *ngIf="!processing && !control?.valid && control?.touched"
      class="validation-message"
    >
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ["./input-error.component.scss"],
})
export class InputErrorComponent {
  @Input() processing = false;
  @Input() control: FormControl;
}
