import { BiddingIntegrationState } from "@app/integrations/bidding/ngrx/reducers";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as pbActions from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.action";

export interface SendPotentialBuyersState {
  sending: boolean;
  numberOfPotentialBuyersToSend: number;
  numberOfPotentialBuyersSent: number;
  errors: string[];
}

export const initialState: SendPotentialBuyersState = {
  sending: false,
  numberOfPotentialBuyersToSend: 0,
  numberOfPotentialBuyersSent: 0,
  errors: [],
};

export function sendPotentialBuyerReducer(
  state: SendPotentialBuyersState,
  action: Action
): SendPotentialBuyersState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    pbActions.sendPotentialBuyersToERPRequest,
    (state, { potentialBuyers }) => ({
      ...state,
      sending: true,
      numberOfPotentialBuyersToSend: potentialBuyers.length,
    })
  ),
  on(pbActions.sendPotentialBuyersToERPSuccess, (state) => ({
    ...state,
    numberOfPotentialBuyersSent: state.numberOfPotentialBuyersSent + 1,
  })),
  on(pbActions.sendPotentialBuyersToERPFailure, (state, { errorMessage }) => ({
    ...state,
    numberOfPotentialBuyersSent: state.numberOfPotentialBuyersSent + 1,
    errors: [...state.errors, errorMessage],
  })),
  on(pbActions.sendPotentialBuyersToERPDone, (state) => ({
    ...state,
    sending: false,
  })),
  on(pbActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature =
  createFeatureSelector<BiddingIntegrationState>("biddingIntegration");
const getState = createSelector(
  selectFeature,
  (state: BiddingIntegrationState) => state.sendPotentialBuyers
);

export const getSending = createSelector(
  getState,
  (state: SendPotentialBuyersState) => state.sending
);

export const getProgress = createSelector(
  getState,
  (state: SendPotentialBuyersState) => {
    const progress = Math.floor(
      (state.numberOfPotentialBuyersSent /
        state.numberOfPotentialBuyersToSend) *
        100
    );

    return !isNaN(progress) ? progress : null;
  }
);

export const getIsDone = createSelector(
  getState,
  (state: SendPotentialBuyersState) =>
    state.numberOfPotentialBuyersSent === state.numberOfPotentialBuyersToSend
);

export const getErrors = createSelector(
  getState,
  (state: SendPotentialBuyersState) => state.errors
);
