import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

import { AppState } from "@app/app.reducer";
import { Employee } from "@app/models";
import { loginOfficeRequest } from "./select-office.actions";

@Component({
  selector: "select-office",
  styleUrls: ["./select-office.component.scss"],
  templateUrl: "./select-office.component.html",
})
export class SelectOfficeComponent implements OnInit {
  employees$: Observable<Employee[]>;
  eaEmployeeId: string;
  eaOfficeId: string;
  disabled = true;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.employees$ = this.store
      .select((state) => state.login.selectOffice)
      .pipe(map((state) => state.employees));
  }

  selectOfficeAndLogin($event) {
    this.eaEmployeeId = $event.employee.eaEmployeeId;
    this.eaOfficeId = $event.office.eaOfficeId;
    this.disabled = false;
    this.store.dispatch(
      loginOfficeRequest({
        eaEmployeeId: this.eaEmployeeId,
        eaOfficeId: this.eaOfficeId,
      })
    );
  }
}
