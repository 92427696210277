import { createAction, props } from "@ngrx/store";

export const fetchStatisticsRequest = createAction(
  "[Widget Revenue] FETCH_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchStatisticsSuccess = createAction(
  "[Widget Revenue] FETCH_STATISTICS_SUCCESS",
  props<{ stats: number }>()
);

export const fetchStatisticsFail = createAction(
  "[Widget Revenue] FETCH_STATISTICS_FAIL"
);

export const fetchPreviousStatisticsRequest = createAction(
  "[Widget Revenue] FETCH_PREVIOUS_STATISTICS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const fetchPreviousStatisticsSuccess = createAction(
  "[Widget Revenue] FETCH_PREVIOUS_STATISTICS_SUCCESS",
  props<{ previousStats: number }>()
);

export const fetchPreviousStatisticsFail = createAction(
  "[Widget Revenue] FETCH_PREVIOUS_STATISTICS_FAIL"
);
