import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { ShowingObject } from "@app/showings/models";
import { filter, map, Observable } from "rxjs";

@Component({
  selector: "app-rental-overview-widget",
  templateUrl: "./rental-overview-widget.component.html",
  styleUrls: [
    "./rental-overview-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class RentalOverviewWidgetComponent implements OnChanges {
  @Input() eaOid: string;
  showing$: Observable<ShowingObject>;

  constructor(private objectService: ObjectService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaOid && this.eaOid) {
      this.showing$ = this.objectService.entityMap$.pipe(
        map((entities) => entities[this.eaOid]),
        filter((entity) => !!entity)
      );
    }
  }

  isRentZero(rent: string): boolean {
    return !!parseInt(rent, 10);
  }
}
