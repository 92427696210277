<app-controls-box>
  <div
    [style.display]="showFilters ? 'flex' : 'none'"
    class="assign-employee-filters"
  >
    <form [formGroup]="form" [style.display]="isMobile ? 'block' : 'flex'">
      <div class="form-group">
        <span translate>office</span>
        <office-dropdown
          *ngIf="offices"
          [offices]="offices"
          [parentFormControlName]="'eaOfficeIds'"
          [parentFormGroup]="form"
        ></office-dropdown>
      </div>
      <div class="form-group">
        <span translate>statistics_from</span>
        <select [formControlName]="'from'">
          <option translate value="1--weeks">1_week</option>
          <option translate value="2--weeks">2_weeks</option>
          <option translate value="1--months">1_month</option>
          <option translate value="3--months">3_months</option>
          <option translate value="6--months">6_months</option>
          <option translate value="12--months">12_months</option>
        </select>
      </div>
    </form>
  </div>
</app-controls-box>
