import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ProgressWidgetItemComponent } from "@app/shared/modules/progress-widget/components/progress-widget-item/progress-widget-item.component";
import { ProgressWidgetEffects } from "@app/shared/modules/progress-widget/ngrx/progress-widget.effects";
import { ProgressWidgetComponent } from "@app/shared/modules/progress-widget/progress-widget.component";
import { ResourceModule } from "@app/shared/modules/resource/resource.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { EffectsModule } from "@ngrx/effects";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";

@NgModule({
  imports: [
    EffectsModule.forFeature([ProgressWidgetEffects]),
    CommonModule,
    TranslateModule,
    ResourceModule,
    UiComponentsModule,
    MomentModule,
    ProgressbarModule,
    TooltipModule,
  ],
  declarations: [ProgressWidgetComponent, ProgressWidgetItemComponent],
  exports: [ProgressWidgetComponent, ProgressWidgetItemComponent],
})
export class ProgressWidgetModule {}
