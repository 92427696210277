import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";
import * as fromResidences from "@app/contacts/contact-residences/residences.reducer";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";
import { QObject } from "@app/models";
import { getCurrency, getFeature } from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import * as externalProviders from "@app/shared/utils/external-providers";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { RESIDENCE, SALES_MEETING } from "@app/shared/utils/tab-types";
import { SalesMeetingService } from "@app/sidebar/sales-meeting/sales-meeting.service";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTab, getTabs } from "../../ngrx/sidebar.reducer";
import { ResidenceFormComponent } from "../residence-form/residence-form.component";
import * as residenceActions from "../ngrx/residence.actions";
import {
  getSidebarResidenceContact,
  getSidebarResidenceProcessing,
} from "../ngrx/residence.reducer";
import { FormBuilder, FormGroup } from "@angular/forms";
import { QSearchObjectParams } from "@app/shared/modules/ui-components/q-search-object/q-search-object.service";

@Component({
  selector: "residence-create",
  templateUrl: "./residence-create.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./residence-create.component.scss",
    "../residence-links/residence-links.component.scss",
  ],
})
export class ResidenceCreateComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("residenceForm", { static: false })
  residenceForm: ResidenceFormComponent;
  tab$: Observable<SidebarTab>;
  processing$: Observable<boolean>;
  showSauna$: Observable<boolean>;
  valueChangesSubscription: Subscription;
  tabType = RESIDENCE;
  objectTypes$: Observable<ObjectType[]>;
  residenceSidebarConfig$: Observable<ResidenceSidebarConfig>;
  tabs$: Observable<SidebarTab[]>;
  salesMeetingTabIsPresent = false;
  objectSelectionHasBeenMade = false;
  objectConnectionList$: Observable<QObject[]>;
  salesObjectsList: QObject[] = [];
  form: FormGroup;

  get objectSearchParam(): QSearchObjectParams {
    return {
      filterStatus: "0,1,2,3,4,5,6,7,8,9",
      publishOnNet: "false",
    };
  }

  constructor(
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private featureConfigManager: FeatureConfigManagerService,
    private salesMeetingService: SalesMeetingService
  ) {}

  ngOnInit() {
    this.createForm();
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));
    this.processing$ = this.store.pipe(select(getSidebarResidenceProcessing));
    this.showSauna$ = this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name),
      map((name) => name.toLowerCase() === externalProviders.KIVI)
    );
    this.objectTypes$ = this.store.pipe(select(fromShared.getObjectTypes));
    this.residenceSidebarConfig$ =
      this.featureConfigManager.getResidenceSidebarConfig();
    this.tabs$ = this.store.pipe(select(getTabs));
    this.tabs$.subscribe((tabs) => {
      this.salesMeetingTabIsPresent = !!tabs.find(
        (tab) => tab.type === SALES_MEETING
      );
    });
    this.objectConnectionList$ = this.store.pipe(
      select(fromResidences.getObjectConnectionList)
    );
    this.objectConnectionList$.subscribe((objects) => {
      this.salesObjectsList = objects.filter(
        (obj) => obj.status === "4" && obj.type === "seller"
      );
    });
  }

  ngAfterViewInit() {
    this.store
      .pipe(
        select(getSidebarResidenceContact),
        filter((value) => !!value),
        first(),
        tap(({ street, zip, city, contactId }) => {
          this.residenceForm.form
            .get("address")
            .setValue({ street, zip, city });
          this.store.dispatch(
            residencesActions.getContactObjectConnectionListRequest({
              contactId,
            })
          );
          this.cdr.detectChanges();
        }),
        switchMap(() =>
          this.tab$.pipe(
            filter((value) => !!value),
            first()
          )
        )
      )
      .subscribe((tab) => {
        if (tab.dirty) {
          this.residenceForm.form.setValue(tab.currentValue);
          this.cdr.detectChanges();
        } else {
          this.store.dispatch(
            sidebarActions.setInitialTabValue({
              tabType: this.tabType,
              value: this.residenceForm.form.value,
            })
          );
        }
        this.startValueChangesStream();
      });
  }

  onCreateNewResidence(): void {
    this.objectSelectionHasBeenMade = true;
  }

  shouldShowResidenceForm(showSalesObjectSelection: boolean): boolean {
    if (!showSalesObjectSelection) {
      return true;
    }

    if (!this.salesMeetingTabIsPresent) {
      return true;
    }

    return this.objectSelectionHasBeenMade;
  }

  intakeObjectSelected(e: Event, obj: QObject): void {
    e?.preventDefault();
    this.salesMeetingService.selectedIntakeObject$.next(obj);
    this.store.dispatch(sidebarActions.closeTab({ tabType: RESIDENCE }));
  }

  ngOnDestroy() {
    if (this.valueChangesSubscription) {
      this.valueChangesSubscription.unsubscribe();
    }
  }

  startValueChangesStream() {
    this.valueChangesSubscription = this.residenceForm.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setTabValue({
            tabType: this.tabType,
            value: formValues,
          })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(residence: QObject): void {
    residence.rooms = residence.rooms.replace(",", ".");
    const { eaOid, ...rest } = residence;
    observableCombineLatest([
      this.store.pipe(
        select(fromUser.getOffice),
        map((office) => office?.officeId)
      ),
      this.store.pipe(
        select(getSidebarResidenceContact),
        map((contact) => contact?.contactId)
      ),
      this.store.pipe(select(getCurrency)),
      this.store.pipe(select(fromUser.getEaEmployeeId)),
    ])
      .pipe(
        first(),
        map(([officeId, contactId, currency, eaEmployeeId]) =>
          residenceActions.createResidenceRequest({
            residence: {
              ...rest,
              officeId,
              contactId,
              currency,
              eaEmployeeId,
            },
          })
        )
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  private createForm(): void {
    this.form = this.fb.group({
      eaOid: "",
    });
  }
}
