import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsTopListsActions from "./statistics-top-lists.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsTopListsState {
  loading: boolean;
  csvLoading: boolean;
  wonsalesmeeting: TopList;
  bookedsalesmeeting: TopList;
  reportedsalesmeeting: TopList;
  soldobjects: TopList;
  estatecommision: TopList;
  commisionaverage: TopList;
  commisionpercentage: TopList;
  transactionamount: TopList;
  npsvalue: TopList;
  npsaveragevalue: TopList;
  senttips: TopList;
  madesalescalls: TopList;
  rentedObjects: TopList;
  rentedObjectsCommission: TopList;
}

export const initialState: StatisticsTopListsState = {
  loading: false,
  csvLoading: false,
  wonsalesmeeting: null,
  bookedsalesmeeting: null,
  reportedsalesmeeting: null,
  soldobjects: null,
  estatecommision: null,
  commisionaverage: null,
  commisionpercentage: null,
  transactionamount: null,
  npsvalue: null,
  npsaveragevalue: null,
  senttips: null,
  madesalescalls: null,
  rentedObjects: null,
  rentedObjectsCommission: null,
};

export function statisticsTopListsReducer(
  state: StatisticsTopListsState,
  action: Action
): StatisticsTopListsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    statisticsTopListsActions.fetchTopListRequest,
    statisticsTopListsActions.fetchTopListCsvRequest,
    (state, { listType }) => ({
      ...state,
      loading: true,
      [listType]: state[listType]
        ? { ...state[listType], loading: true }
        : null,
    })
  ),
  on(
    statisticsTopListsActions.fetchTopListSuccess,
    (state, { listType, summary, topList }) => ({
      ...state,
      [listType]: {
        loading: false,
        summary,
        ...topList,
        type: listType,
      },
      loading: false,
    })
  ),
  on(
    statisticsTopListsActions.fetchTopListCsvSuccess,
    statisticsTopListsActions.fetchTopListCsvFailed,
    statisticsTopListsActions.fetchTopListFailed,
    (state, { listType }) => ({
      ...state,
      loading: false,
      [listType]: {
        ...state[listType],
        loading: false,
        summary: true,
      },
    })
  )
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.topLists
);

export const getStatisticsTopListsLoading = createSelector(
  getState,
  (state: StatisticsTopListsState) => state.loading
);

export const getStatisticsTopList = (type: string) =>
  createSelector(getState, (state: StatisticsTopListsState) => state[type]);

export const getAllTopLists = createSelector(
  getState,
  (state: StatisticsTopListsState) => {
    const selectedTopLists = [];
    Object.keys(state)
      .filter((key) => !key.includes("loading"))
      .forEach((key: string) => {
        if (state[key]) {
          selectedTopLists.push({ ...state[key], topListType: key });
        }
      });
    return selectedTopLists;
  }
);
