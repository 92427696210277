<app-q-modal>
  <ng-container slot="header">
    <span translate>add_note_optional</span>
  </ng-container>
  <ng-container slot="body">
    <div class="modal-body">
      <create-note-widget
        (submitPressed)="closeModal()"
        [showSalesCallButton]="showSalesCallButton"
      ></create-note-widget>
    </div>
  </ng-container>
</app-q-modal>
