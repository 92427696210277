import * as toastActions from "./toast.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface ToastAction {
  label: string;
  icon?: string;
  action: Action;
}

export interface ToastLink {
  label: string;
  icon?: string;
  link: string;
}

export interface ToastState {
  message: string;
  type: string;
  duration: number;
  toastAction?: ToastAction;
  toastLink?: ToastLink;
}

export const initialState: ToastState = {
  message: "",
  type: "",
  duration: 0,
};

export function toastReducer(state: ToastState, action: Action): ToastState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(toastActions.show, (state, { data, toastAction, toastLink }) => {
    return { ...state, ...data, toastAction, toastLink };
  })
);
