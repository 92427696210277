import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { EmployeeDTO } from "@app/models";
import {
  SIDEBAR_EMPLOYEE_OFFICE_EMPLOYMENT_URL,
  SIDEBAR_EMPLOYEES_OFFICE_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable, Subject, takeUntil } from "rxjs";
import * as employeeActions from "./ngrx/employees-search.actions";
import { getIgnoreList } from "./ngrx/employees-search.reducer";

@Component({
  selector: "employees-search",
  templateUrl: "./employees-search.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./employees-search.component.scss",
  ],
})
export class EmployeesSearchComponent implements OnInit, OnDestroy {
  tabType = EMPLOYEES;

  ignoreList$: Observable<string[]>;

  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.params
      .pipe(
        map((params) => params?.eaOfficeId),
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((eaOfficeId: string) =>
        this.store.dispatch(
          employeeActions.getEmploymentsRequest({ eaOfficeId })
        )
      );

    this.ignoreList$ = this.store.pipe(select(getIgnoreList));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSelected(employee: EmployeeDTO): void {
    const { eaOfficeId } = this.route.snapshot.params;

    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_EMPLOYEE_OFFICE_EMPLOYMENT_URL(
                employee.eaEmployeeId,
                eaOfficeId
              ),
            },
          },
        ],
      })
    );
  }

  getCreateEmployeeRoute(): any[] {
    const { eaOfficeId } = this.route.snapshot.params;
    return [
      "/crm",
      { outlets: { sidebar: SIDEBAR_EMPLOYEES_OFFICE_CREATE_URL(eaOfficeId) } },
    ];
  }
}
