import * as actions from "@app/core/components/multi-tasker/ngrx/multi-tasker.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { Observable } from "rxjs";

export interface MultiTaskerEntity {
  id: string;
  entityType: "contact" | "object";
  data?: Observable<unknown>;
}

export interface MultiTaskerState {
  entities: MultiTaskerEntity[];
}

export const initialState: MultiTaskerState = {
  entities: [],
};

export function multiTaskerReducer(
  state: MultiTaskerState,
  action: Action
): MultiTaskerState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.addEntityId, (state, { id, entityType }) => {
    const newEntities = !state.entities.find((e) => e.id === id)
      ? [...state.entities, { id, entityType }]
      : state.entities;
    if (newEntities.length > 10) {
      newEntities.shift();
    }
    return {
      ...state,
      entities: newEntities,
    };
  }),
  on(actions.removeEntityId, (state, { id }) => {
    const newEntities = state.entities.filter((e) => e.id !== id);
    return {
      ...state,
      entities: newEntities,
    };
  }),
  on(actions.setEntityIds, (state, { payload }) => ({
    ...state,
    entities: payload,
  }))
);

const selectFeature = createFeatureSelector<MultiTaskerState>("multiTasker");

export const getMultiTaskerEntities = createSelector(
  selectFeature,
  (state: MultiTaskerState) => state.entities
);
