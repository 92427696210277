import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ProductNewsService } from "@app/shared/modules/product-news/product-news.service";

@Component({
  selector: "app-product-news-modal",
  templateUrl: "./product-news-modal.component.html",
  styleUrls: ["./product-news-modal.component.scss"],
})
export class ProductNewsModalComponent implements OnInit, OnDestroy {
  currentProductNewsItem = 0;
  hasUnseenItem = true;

  constructor(
    public productNewsService: ProductNewsService,
    public dialogRef: MatDialogRef<ProductNewsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    const { currentItem, hasUnseenItem } = this.data;
    this.currentProductNewsItem = currentItem;
    this.hasUnseenItem = hasUnseenItem;
  }

  ngOnDestroy() {
    this.productNewsService.saveVisitedItem(this.currentProductNewsItem);
  }

  previousProductNewsItem() {
    this.productNewsService.saveVisitedItem(this.currentProductNewsItem);
    this.currentProductNewsItem--;
  }

  nextProductNewsItem() {
    this.productNewsService.saveVisitedItem(this.currentProductNewsItem);
    this.currentProductNewsItem++;
  }

  markAllAsRead() {
    this.productNewsService.markAllAsRead();
    this.hasUnseenItem = false;
  }
}
