import { WidgetConfig } from "@app/shared/config/models/widgets-page-config";
import { Role, ROLE_BROKER } from "@app/shared/utils/roles";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
  select,
} from "@ngrx/store";
import merge from "deepmerge";
import { pipe } from "rxjs";
import * as configActions from "./config.actions";
import { ConfigState, initialState, KpiStatisticFilterConfig } from "./models";
import { checkDuplicates } from "@app/shared/config/utils/duplicate-util";
import _ from "lodash";
import { CustomPermission } from "@app/shared/config/models/assistant-permissions-feature";

export function configReducer(state: ConfigState, action: Action): ConfigState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(configActions.loadSuccess, (state, { conf }) => {
    const app = merge(initialState.app, conf);
    const uniqueApp = checkDuplicates(_.cloneDeep(app));
    return { ...state, app: uniqueApp, loaded: true };
  }),
  on(configActions.loadFail, (state) => ({
    ...state,
    app: { ...initialState.app },
    loaded: false,
  })),
  on(configActions.saveSuccess, (state, { appConfig }) => ({
    ...state,
    app: appConfig,
  })),
  on(configActions.checkIfSystemOnlineSuccess, (state) => ({
    ...state,
    proxyVerified: true,
  })),
  on(configActions.loadConfigIdsSuccess, (state, { payload }) => {
    const { settingName, settingId, settingGroupId } = payload;
    const setting_ids = {
      ...state.runtime.setting_ids,
      ...{ [settingName.toString()]: { settingId, settingGroupId } },
    };
    return { ...state, ...{ runtime: { setting_ids } } };
  })
);

const selectFeature = createFeatureSelector<ConfigState>("config");

export const getProxyVerified = createSelector(
  selectFeature,
  (state: ConfigState) => state.proxyVerified
);

export const getLoaded = createSelector(
  selectFeature,
  (state: ConfigState) => state.loaded
);

export const getApp = createSelector(
  selectFeature,
  (state: ConfigState) => state.app
);

export const getRuntimeSetting = (name: string) =>
  createSelector(
    selectFeature,
    (state: ConfigState) => state.runtime.setting_ids[name]
  );

export const getSendModuleId = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.send_module_id
);

export const getOfficeEmailConfirmationTemplateId = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.general.office_email_confirmation_template_id
);

export const getEmployeeEmailConfirmationTemplateId = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.general.employee_email_confirmation_template_id
);

export const getTimeZone = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.time_zone
);

export const getCustomerName = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.customer_name
);

export const getBranding = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.branding
);

export const getOfficeNamePrefix = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.office_name_prefix
);

export const getObjectLocationUrl = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.object_location_url
);

export const getObjectPreviewLocationUrl = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.object_preview_location_url
);

export const getObjectLocationExternalIdProvider = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.object_location_external_id_provider
);

export const getObjectLocationExternalIdCategory = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.object_location_external_id_category
);

export const getObjectLocationUseEaOidAsExternalId = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.general.object_location_use_eaoid_as_external_id
);

export const getPhonePrefix = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.phone_prefix
);

export const getMessageTemplateId = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.message_template_id
);

export const getIntegrityPolicyUrl = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.integrity_policy_url
);

export const getConsentIsMandatory = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.register_view.show_consent_checkbox
);

export const getConsentText = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.register_view.consent_text
);

export const getShowConsentText = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.register_view.show_consent_text
);

export const getBidStatus = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.register_view.bid_status
);

export const getMakeEmailRequiredInsteadOfMobile = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.features.register_view.make_email_required_instead_of_mobile
);

export const getShowQuedroSearchResult = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.features.register_view.show_quedro_search_results
);

export const getGrowthStatisticsReturnType = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.growth_reports.returnType
);

export const getGrowthStatisticsSortBy = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features.growth_reports.sortBy
);

export const getEveryoneCanAssignTasksAndLeads = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.everyone_can_assign_tasks_and_leads
);

export const getTaskTypeIds = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.tasks
);

export const getTaskTypeId = (taskTypeName: string) =>
  createSelector(
    selectFeature,
    (state: ConfigState) => state.app.tasks[taskTypeName]
  );

export const getLanguage = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.language
);

export const getLanguageModificationLayers = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.language_modification_layers
);

export const getCountry = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.country
);

export const getCurrency = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.currency
);

export const getDeswinglisherUrl = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.deswinglisher_url
);

export const getConsentValidDate = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.consent_valid_date
);

export const getGoogleMapDatasetId = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.google_map_dataset_id
);

export const getFeature = (feature: string) =>
  createSelector(
    selectFeature,
    (state: ConfigState) => state.app.features[feature] || null
  );

export const getFeatures = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.features
);

export const getProfileProgress = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.profile_progress
);

export const getSubPages = (pageType: string) =>
  createSelector(selectFeature, (state: ConfigState) => {
    const pages = state.app.pages;
    const page = pages.find((p) => p.type === pageType);
    return page ? page["sub_pages"] : [];
  });

export const getStatisticsGeneral = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.statistics.general
);

const getStatisticsWidgetsPartial = (
  page: string,
  userRole: Role,
  visible: boolean
) =>
  createSelector(selectFeature, (state: ConfigState) => {
    const widgets = state.app.statistics.widgets[page];
    return Object.keys(widgets)
      .map((key) => widgets[key])
      .filter(
        (widget: WidgetConfig) =>
          !!widget.enabled &&
          (visible
            ? !!widget.visibility[userRole].visible
            : !widget.visibility[userRole].visible)
      )
      .sort(
        (a, b) =>
          a.visibility[userRole].displayOrder -
          b.visibility[userRole].displayOrder
      );
  });

const getWidgetsPartial = (
  page: "dashboard_widgets",
  userRole: Role,
  visible
) =>
  createSelector(selectFeature, (state: ConfigState) => {
    const widgets = state.app[page];
    return Object.keys(widgets)
      .map((key) => widgets[key])
      .filter(
        (widget: WidgetConfig) =>
          !!widget.enabled &&
          (visible
            ? !!widget.visibility[userRole].visible
            : !widget.visibility[userRole].visible)
      )
      .sort(
        (a, b) =>
          a.visibility[userRole].displayOrder -
          b.visibility[userRole].displayOrder
      );
  });

export const getStatisticsWidgets = (
  page = "start_page",
  role = ROLE_BROKER,
  visible = true
) => pipe(select(getStatisticsWidgetsPartial(page, role, visible)));

export const getWidgets = (
  page: "dashboard_widgets" | "statistics_start_page" = "dashboard_widgets",
  role = ROLE_BROKER,
  visible = true
) => {
  if (page === "statistics_start_page") {
    return pipe(
      select(getStatisticsWidgetsPartial("start_page", role, visible))
    );
  } else {
    return pipe(select(getWidgetsPartial(page, role, visible)));
  }
};

export const getGoogleAnalyticsTrackingId = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.google_analytics.tracking_id
);

export const getAppPages = (pageType?: string) =>
  createSelector(selectFeature, (state: ConfigState) => {
    const pages = state.app.pages;
    if (!pageType) {
      return pages;
    }

    return pages.find((page) => page.type === pageType);
  });

export const getContactCardActions = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.contact.contact_card_actions
);

export const getContactListContactTypes = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.contact.contact_list_config.contact_types
);

export const getCreateContactTypesConfig = createSelector(
  selectFeature,
  (state: ConfigState) => {
    const contactTypes = state.app.contact.create_contact_config.contact_types;
    return Object.keys(contactTypes)
      .map((key) => contactTypes[key])
      .filter((type) => type.enabled)
      .sort((a, b) => a.displayOrder - b.displayOrder);
  }
);

export const getTabTitle = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.tab_title
);

export const getModals = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.modals
);

export const getDisplayPhoneNumberOnLogin = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.display_phone_number_on_login
);

export const getTemplateInSuperSearch = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.super_search_template
);

export const getTaskIcons = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.tasks.taskIcons
);

export const getKpiStatisticFilters = createSelector(
  selectFeature,
  (state: ConfigState) => {
    const kpis: KpiStatisticFilterConfig[] =
      state.app.features.kpi.kpis_statistics;
    return kpis.filter((kpi) => kpi.enabled);
  }
);

export const getObjectTypeIds = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.object_type_ids
);

export const getUseShowingAgent = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.use_showing_agent
);

export const getSendMessageObjectLinkSoldObjectsEnabled = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.general.send_message_object_links_show_sold_objects
);

export const getSendMessageObjectLinkChainObjectsEnabled = createSelector(
  selectFeature,
  (state: ConfigState) =>
    state.app.general.send_message_object_links_show_chain_objects
);

export const getDefaultMobileSearchCategory = createSelector(
  selectFeature,
  (state: ConfigState) => state.app.general.default_mobile_search_category
);

export const getPermissionFeature = (feature: string, componentName: string) =>
  createSelector(selectFeature, (state: ConfigState) => {
    const assistantPermissions = state.app.features[feature];
    const permission = assistantPermissions.permissions.find(
      (item: CustomPermission) => item.component === componentName
    );
    return permission || null;
  });
