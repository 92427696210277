import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { CALLING_LISTS } from "@app/core/services/api/utils/api-endpoints";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import { catchError, filter, map, of as observableOf, switchMap } from "rxjs";
import * as fromSidebarCallingList from "./calling-lists.actions";

@Injectable()
export class SidebarCallingListsEffects {
  createCallQueueTaskRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSidebarCallingList.createCallQueueTaskRequest),
      switchMap(({ payload }) =>
        this.apiService.post("tasks", payload).pipe(
          map(() => fromSidebarCallingList.createCallQueueTaskSuccess()),
          catchError(() =>
            observableOf(fromSidebarCallingList.createCallQueueTaskFailed())
          )
        )
      )
    )
  );

  createCallingListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSidebarCallingList.createCallingListRequest),
      switchMap(({ callingList }) =>
        this.apiService.post(CALLING_LISTS, callingList).pipe(
          map((response: CallingList) =>
            fromSidebarCallingList.createCallingListSuccess({
              callingListId: response.callingListId,
            })
          ),
          catchError(() =>
            observableOf(fromSidebarCallingList.createCallingListFailed())
          )
        )
      )
    )
  );

  createCallingListSuccessToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSidebarCallingList.createCallingListSuccess),
      map(({ callingListId }) =>
        toastActions.success(
          { message: "create_calling_list_success" },
          {
            label: this.translate.instant("goto_calling_list"),
            icon: "fa-arrow-right",
            action: RouterActions.go({
              path: ["crm", "calling-lists", callingListId],
            }),
          }
        )
      )
    )
  );

  createCallingListFailedToast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSidebarCallingList.createCallingListFailed),
      map(() => toastActions.danger({ message: "create_calling_list_failed" }))
    )
  );

  createCallingListSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromSidebarCallingList.createCallingListSuccess),
      filter(({ task }) => !!task),
      map(({ callingListId, task }) =>
        fromSidebarCallingList.createCallQueueTaskRequest({
          payload: {
            ...task,
            callingListId: callingListId,
            param1: callingListId,
          },
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translate: TranslateService
  ) {}
}
