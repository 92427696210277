import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "resource-header",
  styleUrls: ["./resource-header.component.scss"],
  template: `
    <div class="resource-header">
      <ng-content></ng-content>
      <div>
        <a
          *ngIf="showCreateNew"
          class="pull-right btn btn-secondary btn-circle"
          [routerLink]="createRouterLink"
          routerLinkActive="active"
        >
          <span>+</span>
        </a>
      </div>
    </div>
  `,
})
export class ResourceHeaderComponent {
  @Input() showCreateNew = false;

  @Input() createRouterLink;
}
