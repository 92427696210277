export const BOOKED_SALES_MEETINGS = "booked_salesmeeting";
export const WON_SALES_MEETINGS = "won_salesmeeting";
export const MADE_SALES_CALLS = "made_sales_calls";
export const SOLD_OBJECTS = "sold_objects";
export const FOLLOWED_UP_POTENTIAL_BUYERS = "followed_up_potential_buyers";
export const NPS = "nps";
export const COMMISSIONS_EARNINGS = "commissions_earnings";
export const COMMISSIONS_EARNINGS_RENTALS = "commissions_earnings_rentals";
export const SOON_FOR_SALE_OBJECTS_NEW = "soonforsaleobjectsnew";
export const SALES_CONTRACT_DATE = "sales_contract_date";
export const BANK_TIPS = "banktips";
export const SHOWINGS = "showings";
export const RENTED_OBJECTS = "rentedobjects";
export const RENTED_OBJECTS_COMMISSION = "rentedobjectscommission";
export const ESTATE_COMMISSION = "estate_commission";
export const SOLD_OBJECTS_COMMISSION = "soldobjectswithcommission";
