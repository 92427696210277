import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Observable } from "rxjs";

@Component({
  selector: "kpi-made-sales-calls",
  templateUrl: "./kpi-made-sales-calls.component.html",
  styleUrls: ["./kpi-made-sales-calls.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiMadeSalesCallsComponent {
  @Input() resources$: Observable<any>;

  getAddress(resource: any): string {
    const { objectStreet, objectCity, objectZip } = resource;

    if (!objectStreet) {
      return "-";
    }

    if (!objectCity && !objectZip) {
      return objectStreet;
    }

    return `${objectStreet}, ${objectZip + " " || ""}${objectCity || ""}`;
  }
}
