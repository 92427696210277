import { Component, OnDestroy, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { isEqual } from "lodash";
import { combineLatest, debounceTime, Subject, take, takeUntil } from "rxjs";

import { AppState } from "@app/app.reducer";
import { hardTrimBody } from "@app/core/services/api/api.service";
import { MESSAGE_TEMPLATE } from "@app/shared/utils/tab-types";
import { MessageTemplatesFormComponent } from "@app/sidebar/message-templates/components/message-templates-form/message-templates-form.component";
import * as messageTemplatesFormActions from "@app/sidebar/message-templates/ngrx/message-templates-form.actions";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";

@Component({
  selector: "app-message-templates-create",
  templateUrl: "./message-templates-create.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./message-templates-create.component.scss",
  ],
})
export class MessageTemplatesCreateComponent
  implements ConnectableTab, OnDestroy
{
  @ViewChild(MessageTemplatesFormComponent, { static: true })
  messageTemplatesForm: MessageTemplatesFormComponent;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();

  tabType = MESSAGE_TEMPLATE;

  constructor(private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    if (this.messageTemplatesForm.form.valid) {
      const body = this.messageTemplatesForm.getFormValues();
      this.store.dispatch(
        messageTemplatesFormActions.createMessageTemplateRequest({
          body: hardTrimBody(body),
        })
      );
    } else {
      this.messageTemplatesForm.form.markAllAsTouched();
    }
  }
}
