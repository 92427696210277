import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";
import { distinctUntilChanged, map, Observable } from "rxjs";

@Component({
  selector: "reset-activated-route-params",
  template: `
    <span *ngIf="showReset$ | async" (click)="onReset()" translate>
      reset <i class="icon-reload"></i>
    </span>
  `,
  styleUrls: ["./reset-activated-route-params.component.scss"],
})
export class ResetActivatedRouteParamsComponent implements OnInit {
  @Input()
  set ignoreParams(value: string[] | string) {
    this.ignoreList = typeof value === "string" ? [value] : value;
  }

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() reset = new EventEmitter<void>();

  showReset$: Observable<boolean>;

  private ignoreList: string[] = ["sortBy", "sortOrder"];

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.showReset$ = this.route.params.pipe(
      map((params) => _.omit(params, this.ignoreList)),
      map((params) => !_.isEmpty(params)),
      distinctUntilChanged()
    );
  }

  onReset(): void {
    const currentParams = this.route.snapshot.params;
    const newParams = {};
    this.ignoreList.forEach((key) =>
      currentParams[key] ? (newParams[key] = currentParams[key]) : void 0
    );
    this.router.navigate([".", newParams], { relativeTo: this.route });
    this.reset.emit();
  }
}
