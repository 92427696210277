import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MobileModalListHeaderComponent } from "@app/shared-features/mobile-navigation/mobile-modal-list-header/mobile-modal-list-header.component";
import { MobileModalListItemComponent } from "@app/shared-features/mobile-navigation/mobile-modal-list-item/mobile-modal-list-item.component";
import { MobileNavigationComponent } from "@app/shared-features/mobile-navigation/mobile-navigation.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    ModalModule,
    UiComponentsModule,
  ],
  declarations: [
    MobileNavigationComponent,
    MobileModalListHeaderComponent,
    MobileModalListItemComponent,
  ],
  exports: [MobileNavigationComponent],
})
export class MobileNavigationModule {}
