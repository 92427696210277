<h3>
  <app-icon size="large" name="user"></app-icon>&nbsp;<span>{{
    "latest_contacts" | translate
  }}</span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="widget-container--content">
    <p class="no-items" *ngIf="(contacts$ | async).length === 0">
      {{ "no_recent_contacts_found" | translate }}
    </p>
    <div class="card-wrapper" *ngFor="let contact of contacts$ | async">
      <app-mobile-card
        color="WHITE"
        [enableMetadata]="true"
        [shadow]="false"
        [size]="'SLIM'"
        [enableActionDropdown]="true"
        (click)="onContactClick(contact, $event)"
      >
        <span title>
          <app-contact-name
            [contactId]="contact?.entity?.contactId"
            [contactType]="contact?.entity?.contactType"
          >
            {{ contact?.entity?.firstName }} {{ contact?.entity?.familyName }}
          </app-contact-name>
        </span>
        <div metadata>
          <span *ngIf="contact?.entity?.street">
            {{ contact?.entity?.street }} {{ contact?.entity?.zip }},
            {{ contact?.entity?.city }}
          </span>
        </div>
        <ng-container buttons>
          <li role="menuitem">
            <button
              class="btn btn-secondary"
              (click)="onContactClick(contact, $event)"
            >
              <app-icon name="user"></app-icon>
              {{ "go_to_contact" | translate }}
            </button>
          </li>
          <li disableNavigation role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="openInNewTab(contact)"
            >
              <app-icon name="share-square"></app-icon>
              {{ "open_in_new_tab" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
  </div>
  <ng-template #noData>
    <span translate>no_data_found</span>
  </ng-template>
</div>
