import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getRoles } from "@app/shared/user";
import { API_ONLY_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { Role } from "@app/shared/utils/roles";
import {
  fetchStatisticsRequest,
  fetchTaskTypesRequest,
} from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.actions";
import * as fromWidgetReducer from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.reducer";
import { LeadResult } from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  debounceTime,
  fromEvent as observableFromEvent,
  map,
  Observable,
  skip,
  Subject,
  takeUntil,
} from "rxjs";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";

@Component({
  selector: "app-statistics-lead-result-widget-new",
  templateUrl: "./statistics-lead-result-widget-new.component.html",
  styleUrls: ["./statistics-lead-result-widget-new.component.scss"],
})
export class StatisticsLeadResultWidgetNewComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() eaEmployeeIds: string[] = [];
  @Input() eaOfficeIds: string[] = [];
  @Input() taskTypeIds: string[] = [];
  @Input() quickFilter = "today";
  @Input() triggerDataFetch$: Observable<void>;
  @Input() showHeader: boolean = true;

  @ViewChild("filterContainer", { static: false })
  filterContainer: ElementRef;

  showSegment: boolean = true;
  statistics$: Observable<LeadResult>;
  loading$: Observable<boolean>;
  roles$: Observable<Role[]>;
  tooltip$: Observable<string>;
  unsubscribe$ = new Subject<void>();

  filters = [
    { label: "yesterday", value: "yesterday" },
    { label: "today", value: "today" },
    { label: "this_month", value: "currentMonth" },
    { label: "this_year", value: "currentYear" },
  ];
  dropDownFilters: DropdownItem[] = [
    { display: "yesterday", value: "yesterday" },
    { display: "today", value: "today" },
    { display: "this_month", value: "currentMonth" },
    { display: "this_year", value: "currentYear" },
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getTooltip();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });

    observableFromEvent(window, "resize")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showSegment = !(
          this.filterContainer?.nativeElement?.offsetWidth < 450 &&
          this.dropDownFilters.length > 3
        );
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.filterContainer?.nativeElement?.offsetWidth < 450 &&
        this.dropDownFilters.length > 3
      ) {
        this.showSegment = false;
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeIds || changes.eaOfficeIds || changes.taskTypeIds) {
      this.getStatistics();
    }
  }

  formatPercentage(val): number {
    return !!Math.round(val * 100) ? Math.round(val * 100) : 0;
  }

  private mapStateToProps() {
    this.statistics$ = this.store.pipe(select(fromWidgetReducer.getStats));
    this.loading$ = this.store.pipe(select(fromWidgetReducer.getLoading));
    this.tooltip$ = this.store.pipe(
      select(fromWidgetReducer.getTaskTypes),
      map((taskTypes) => {
        let tooltip = "";
        taskTypes.forEach((tt) => {
          if (!!tooltip) {
            tooltip = tooltip + ", " + tt.typeName;
          } else {
            tooltip = tt.typeName;
          }
        });
        return tooltip;
      })
    );
    this.roles$ = this.store.pipe(select(getRoles));
  }

  getStatistics() {
    const params = {
      ...this.getDates(),
      ...this.getIds(),
      ...this.getTaskTypeIds(),
    };
    this.store.dispatch(fetchStatisticsRequest({ params }));
  }

  private getDates() {
    switch (this.quickFilter) {
      case "yesterday":
        return {
          startDate: moment().subtract(1, "day").format(API_ONLY_DATE_FORMAT),
          endDate: moment().subtract(1, "day").format(API_ONLY_DATE_FORMAT),
        };
      case "today":
        return {
          startDate: moment().format(API_ONLY_DATE_FORMAT),
          endDate: moment().format(API_ONLY_DATE_FORMAT),
        };
      case "currentMonth":
        return {
          startDate: moment().startOf("month").format(API_ONLY_DATE_FORMAT),
          endDate: moment().format(API_ONLY_DATE_FORMAT),
        };
      case "currentYear":
        return {
          startDate: moment().startOf("year").format(API_ONLY_DATE_FORMAT),
          endDate: moment().format(API_ONLY_DATE_FORMAT),
        };
      default:
        return {
          startDate: moment().format(API_ONLY_DATE_FORMAT),
          endDate: moment().format(API_ONLY_DATE_FORMAT),
        };
    }
  }

  private getIds() {
    const params = {};
    if (this.eaEmployeeIds.length > 0 && !!this.eaEmployeeIds[0]) {
      params["eaEmployeeIds"] = this.eaEmployeeIds.toString();
    }
    if (this.eaOfficeIds.length > 0 && !!this.eaOfficeIds[0]) {
      params["eaOfficeIds"] = this.eaOfficeIds.toString();
    }
    return params;
  }

  private getTaskTypeIds() {
    const params = {};
    if (this.taskTypeIds.length > 0) {
      params["taskTypeIds"] = this.taskTypeIds;
    }
    return params;
  }

  private getTooltip() {
    this.store.dispatch(
      fetchTaskTypesRequest({
        params: {
          direction: "incoming",
          eaTaskTypeId: this.taskTypeIds,
          taskTypeCategory: "lead",
        },
      })
    );
  }

  changeFilter(filter: string) {
    this.quickFilter = filter;
    this.getStatistics();
  }
}
