import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  Renderer2,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ONLY_LETTERS_DIGITS_WHITESPACE } from "@app/shared/utils/regex-patterns";
import _ from "lodash";
import { TypeaheadMatch, TypeaheadOrder } from "ngx-bootstrap/typeahead";
import {
  filter,
  fromEvent as observableFromEvent,
  map,
  mergeMap,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import { Address, ADDRESS_PROVIDERS, AddressProvider } from "./AddressProvider";
import { ValidService } from "./valid.service";
import { getFormattedAddress } from "@app/shared/utils/object-utils";

interface InputChanges extends SimpleChanges {
  enabled?: SimpleChange;
  provider?: SimpleChange;
  countryCode?: SimpleChange;
}

@Component({
  selector: "search-address",
  templateUrl: "./search-address.component.html",
  styleUrls: ["./search-address.component.scss"],
  providers: [
    ValidService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchAddressComponent),
    },
  ],
})
export class SearchAddressComponent
  implements OnChanges, AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild("input", { static: true })
  input: ElementRef;
  @ViewChild("suggester", { static: false })
  suggester: ElementRef;

  @Input() placeholder: string;
  @Input() type: "street" | "city" | "zip";
  @Input() enabled: boolean;
  @Input() provider: string;
  @Input() getCompleteObject = false;
  @Input() autoFocus = false;
  @Input() newStyle = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelected = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<any>();

  loading: false;
  dataSource: Observable<any>;
  propagateChange: (value: any) => void;
  propagateTouch: (value: any) => void;
  unsubscribe$ = new Subject<void>();

  private _countryCode: string;

  get countryCode(): string {
    return this._countryCode;
  }

  @Input()
  set countryCode(value: string) {
    this._countryCode = value.toLowerCase();
  }

  private _selected: string;

  get selected(): string {
    return this._selected;
  }

  set selected(value: string) {
    this._selected = value;

    if (this.propagateChange) {
      this.propagateChange(value);
    }
  }

  generalSortConfig: TypeaheadOrder = {
    direction: "asc",
  };

  fullAddressSortConfig: TypeaheadOrder = {
    direction: "asc",
    field: "fullAddress",
  };

  get sortConfig() {
    if (this.type === "street") {
      return this.fullAddressSortConfig;
    }

    return this.generalSortConfig;
  }

  constructor(private renderer: Renderer2, private valid: ValidService) {}

  ngOnChanges(changes: InputChanges): void {
    if (changes.enabled && changes.enabled.currentValue) {
      this.initProvider();
    }
  }

  ngAfterViewInit(): void {
    const input = this.enabled ? this.suggester : this.input;
    observableFromEvent(input.nativeElement, "blur")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        if (this.propagateTouch) {
          this.propagateTouch(event);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  initProvider(): void {
    if (this.provider === ADDRESS_PROVIDERS.VALID) {
      this.initValid();
    }
  }

  initValid(): void {
    this.valid.type = this.type;
    this.valid.countryCode = this.countryCode;
    this.initDataSource(this.valid);
  }

  initDataSource(service: AddressProvider): void {
    this.dataSource = new Observable((observer: any) =>
      observer.next(this.selected)
    ).pipe(
      filter((keyword: string) => !!keyword),
      filter((keyword: string) => ONLY_LETTERS_DIGITS_WHITESPACE.test(keyword)),
      mergeMap((keyword: string) =>
        service.suggest(keyword).pipe(
          map((suggestions: Address[]) => {
            if (this.type !== "zip" && !this.getCompleteObject) {
              return _.uniq(suggestions.map((address) => address[this.type]));
            }

            return suggestions.map((address) => ({
              ...address,
              fullAddress: this.formatter(address),
            }));
          })
        )
      )
    );
  }

  handleLoadingChange(event): void {
    this.loading = event;
  }

  handleSelect(event: TypeaheadMatch): void {
    let value;

    if (typeof event.item === "object") {
      const { street, zip, city } = event.item;
      value = { street, zip, city };
    } else {
      value = event.value;
    }

    this.onSelected.emit(value);
    this.writeValue(value);
  }

  getOptionField(): string {
    return this.type === "zip" ? "zip" : "fullAddress";
  }

  formatter(item): string {
    if (this.type === "zip") {
      return item.zip;
    }

    return getFormattedAddress(item);
  }

  writeValue(value: any): void {
    if (!!value && typeof value === "object") {
      this.selected = getFormattedAddress(value);
    } else {
      this.selected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.suggester) {
      this.renderer.setProperty(
        this.suggester.nativeElement,
        "disabled",
        isDisabled
      );
    }
    if (this.input) {
      this.renderer.setProperty(
        this.input.nativeElement,
        "disabled",
        isDisabled
      );
    }
  }

  onBlur() {
    this.blur.emit();
  }
}
