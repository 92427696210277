import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "@env/environment";
import { AppModule } from "./app/app.module";

// Temporary, used to clear certain cookies that were moved to localstorage
deleteAllCookies();

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: unknown) => console.error(err));

function deleteAllCookies() {
  const names = ["latestSearches", "redirectAfterLoginUrl", "crm-language"];
  names.forEach(
    (name) =>
      (document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT")
  );
}
