<div class="q-input">
  <label *ngIf="label" class="q-input__label">{{ label | translate }}</label>
  <textarea
    [rows]="rows"
    [placeholder]="placeholder | translate"
    class="q-input__control"
    [class.invalid]="invalid"
    [class.disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="onTextChange($event)"
    (focus)="markAsTouched()"
    [disabled]="disabled"
  >
  </textarea>
</div>
