import { createAction, props } from "@ngrx/store";
import { TaskResult, TypedPaginationListDTO } from "@app/models";

const ACTION_PREFIX = "[Lead channel result options]";

export const getLeadChannelResultOptionsRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNEL_RESULT_OPTIONS_REQUEST`,
  props<{ id: number | string }>()
);

export const getLeadChannelResultOptionsSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNELS_RESULT_OPTIONS_SUCCESS`,
  props<{ data: TypedPaginationListDTO<TaskResult> }>()
);

export const getLeadChannelResultOptionsFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_CHANNELS_RESULT_OPTIONS_FAIL`
);
