import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
  CalendarDateFormatter,
  CalendarModule,
  CalendarMomentDateFormatter,
  DateAdapter,
  MOMENT,
} from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/moment";
import { MomentModule } from "angular2-moment";
import moment from "moment";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ResourceModule } from "../resource/resource.module";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { CalendarWidgetModalComponent } from "./calendar-widget-modal/calendar-widget-modal.component";
import { CalendarWidgetComponent } from "./calendar-widget.component";
import { CustomCalendarMonthViewComponent } from "./custom-calendar-month-view/custom-calendar-month-view.component";
import { WeekOfPipe } from "./custom-calendar-month-view/week-of.pipe";
import { DayAbbreviationPipe } from "./day-abbreviation/day-abbreviation.pipe";
import { CalendarWidgetListItemComponent } from "./list-item/list-item.component";
import { LocalizedDatePipe } from "./localized-date/localized-date.pipe";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";

export function momentAdapterFactory() {
  return adapterFactory(moment);
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ResourceModule,
    BsDatepickerModule,
    UiComponentsModule,
    MomentModule,
    ModalModule,
    TooltipModule,
    PopoverModule,
    CalendarModule.forRoot(
      {
        provide: DateAdapter,
        useFactory: () => adapterFactory(moment),
      },
      {
        dateFormatter: {
          provide: CalendarDateFormatter,
          useClass: CalendarMomentDateFormatter,
        },
      }
    ),
    MatTooltipModule,
    RouterLink,
  ],
  declarations: [
    CalendarWidgetComponent,
    CalendarWidgetListItemComponent,
    CalendarWidgetModalComponent,
    CustomCalendarMonthViewComponent,
    WeekOfPipe,
    LocalizedDatePipe,
    DayAbbreviationPipe,
  ],
  exports: [
    CalendarWidgetComponent,
    CalendarWidgetModalComponent,
    LocalizedDatePipe,
    CustomCalendarMonthViewComponent,
    DayAbbreviationPipe,
    CalendarWidgetListItemComponent,
  ],
  providers: [
    {
      provide: MOMENT,
      useValue: moment,
    },
  ],
})
export class CalendarWidgetModule {}
