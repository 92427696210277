import * as colors from "../../../utils/colors";
import {
  ICON_CLIENT_CONNECTIONS,
  ICON_LEADS,
  ICON_LEAD_SCORING,
  ICON_MESSAGES,
  ICON_NOTES,
  ICON_OBJECT_CONNECTIONS,
  ICON_OVERVIEW,
  ICON_SALES_MEETING,
  ICON_TASKS,
} from "../../../utils/icons";
import { ActionFilter } from "../models";

export const LEADS: ActionFilter = {
  translationKey: "leads",
  codes: ["4501", "4502", "4503", "4601"],
  color: colors.LEADS_COLOR,
  icon: ICON_LEADS,
};

export const LEAD_SCORING: ActionFilter = {
  translationKey: "lead_scoring",
  codes: ["3203"],
  color: colors.LEAD_SCORING_COLOR,
  icon: ICON_LEAD_SCORING,
};

export const TASKS: ActionFilter = {
  translationKey: "tasks",
  codes: ["4102", "4301", "4302", "4303", "4307"],
  color: colors.CONTACTS_COLOR,
  icon: ICON_TASKS,
};

export const NOTES: ActionFilter = {
  translationKey: "notes",
  codes: ["3204", "3205"],
  color: colors.CONTACTS_COLOR,
  icon: ICON_NOTES,
};

export const MESSAGES: ActionFilter = {
  translationKey: "messages",
  codes: ["4201", "2101", "2102", "2203"],
  color: colors.CAMPAIGNS_COLOR,
  icon: ICON_MESSAGES,
};

export const SALE_MEETINGS: ActionFilter = {
  translationKey: "sales_meetings",
  codes: ["4401", "4402", "4403"],
  color: colors.SALESMEETING_COLOR,
  icon: ICON_SALES_MEETING,
};

export const OBJECT_CONNECTIONS: ActionFilter = {
  translationKey: "object_connections",
  codes: ["2103", "3220", "3221", "81", "3205", "3107"],
  color: colors.SHOWINGS_COLOR,
  icon: ICON_OBJECT_CONNECTIONS,
};

export const CLIENT_CONNECTIONS: ActionFilter = {
  translationKey: "client_connections",
  codes: ["3105"],
  color: colors.CONTACTS_COLOR,
  icon: ICON_CLIENT_CONNECTIONS,
};

export const CONTACTS: ActionFilter = {
  translationKey: "contacts",
  codes: ["80", "3601", "3301", "3303"],
  color: colors.CONTACTS_COLOR,
  icon: ICON_OVERVIEW,
};

export const FILTERS: ActionFilter[] = [
  LEADS,
  TASKS,
  NOTES,
  MESSAGES,
  SALE_MEETINGS,
  OBJECT_CONNECTIONS,
  CLIENT_CONNECTIONS,
  LEAD_SCORING,
  CONTACTS,
];
