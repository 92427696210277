<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="
      ('connect_residence_in' | translate) +
      ' ' +
      (externalProvider$ | async).name
    "
  ></sidebar-header>
  <div class="scroll-view">
    <section>
      <div class="alert alert-info" translate>
        connect_in_external_provider_info_message
      </div>
    </section>
    <section>
      <form [formGroup]="chooseResidenceForm">
        <div class="form-group">
          <h4 translate>select_residence</h4>
          <div *ngFor="let residence of residences$ | async">
            <label class="radio-button-group">
              <span>{{ residence.street }}</span>
              <input
                class="radio-button"
                type="radio"
                name="residence"
                [value]="residence"
                formControlName="residence"
              />
              <span class="radio-button-group__checkmark"></span>
            </label>
          </div>
          <div>
            <label class="radio-button-group">
              <span>{{ "choose_another_residence" | translate }}</span>
              <input
                class="radio-button"
                type="radio"
                name="residence"
                value="another"
                formControlName="residence"
              />
              <span class="radio-button-group__checkmark"></span>
            </label>
          </div>
        </div>
        <div *ngIf="!chosenObject && showSearch">
          <div class="form-group">
            <label translate> search_object </label>
            <div class="input-iconfield">
              <input
                (input)="getResidenceSuggestions()"
                #searchInput
                [ngClass]="showError ? 'validation-input' : ''"
                placeholder="{{ 'search_object' | translate }}"
                type="text"
              />
              <i class="icon icon-magnifier" aria-hidden="true"></i>
              <spinner
                *ngIf="loading$ | async"
                class="search__spinner pull-right"
                [size]="'1.5rem'"
              >
              </spinner>
            </div>

            <div class="results-dropdown">
              <div class="results-content">
                <div class="result-set">
                  <card
                    (click)="selectObject(object)"
                    *ngFor="let object of objectSearchResults$ | async"
                  >
                    <div title>{{ object.street }}</div>
                  </card>
                </div>
              </div>
            </div>
            <div class="error" *ngIf="showError" translate>
              you_must_choose_a_residence
            </div>
          </div>

          <div class="form-group">
            <label translate> status</label>
            <select #statusDropdown (change)="getResidenceSuggestions()">
              <option value="all" translate>all_statuses</option>
              <option
                *ngFor="let status of objectStatuses$ | async"
                [value]="status.status"
              >
                {{ status.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group">
          <card
            *ngIf="chosenObject"
            [removable]="true"
            (remove)="removeChosenObject()"
          >
            <div title>
              {{ chosenObject.street }}
            </div>
          </card>
        </div>
      </form>
    </section>
  </div>
  <div class="sidebar-footer">
    <button
      type="button"
      class="btn btn-lg btn-success"
      (click)="submit()"
      [disabled]="patching$ | async"
    >
      <spinner
        *ngIf="patching$ | async; else connect"
        [size]="'1rem'"
      ></spinner>
      <ng-template #connect>
        <span translate>connect</span>
      </ng-template>
    </button>
  </div>
</div>
