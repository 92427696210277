<div class="q-tab-bar">
  <div class="q-tab-bar__container">
    <button class="q-tab-bar__item"
            *ngFor="let tab of tabs"
            [class.active]="tab.value == activeTab"
            (click)="onClick(tab.value)">
      {{tab.label | translate}}
    </button>
  </div>
</div>
