import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CallingListService } from "@app/calling-lists/calling-list.service";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { NguiMapModule } from "@ngui/map";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ActionListModule } from "../shared/modules/action-list";
import { ListComponentsModule } from "../shared/modules/list-components/list.module";
import { ResourceModule } from "../shared/modules/resource/resource.module";
import { SkeletonScreenModule } from "../shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "../shared/modules/ui-components/ui-components.module";
import { UtilModule } from "../shared/modules/util/util.module";
import { CallingListOverviewComponent } from "./calling-list-overview.component";
import { CallingListsRoutingModule } from "./calling-lists-routing.module";
import { CallingListCardDetailsComponent } from "./components/calling-list-card-details/calling-list-card-details.component";
import { CallingListContactListComponent } from "./components/calling-list-contact-list/calling-list-contact-list.component";
import { CallingListQuickinfoComponent } from "./components/calling-list-quickinfo/calling-list-quickinfo.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TooltipModule,
    CallingListsRoutingModule,
    ProgressbarModule,
    ModalModule,
    UtilModule,
    MomentModule,
    SkeletonScreenModule,
    NguiMapModule,
    ActionListModule,
    ListComponentsModule,
    UiComponentsModule,
    ResourceModule,
    ContactComponentsModule,
    PopoverModule,
  ],
  declarations: [
    CallingListOverviewComponent,
    CallingListCardDetailsComponent,
    CallingListContactListComponent,
    CallingListQuickinfoComponent,
  ],
  providers: [CallingListService],
})
export class CallingListsModule {}
