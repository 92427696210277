<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'send_external_tips' | translate"
  ></sidebar-header>
  <form [formGroup]="externalTipsForm">
    <div class="scroll-view">
      <section>
        <div>
          <div class="form-group">
            <h4 translate>select_receiver</h4>
            <select
              formControlName="receiver"
              [ngClass]="
                !externalTipsForm.get('receiver').valid &&
                externalTipsForm.get('receiver').touched
                  ? 'validation-input'
                  : ''
              "
            >
              <option value="" disabled translate>select_receiver</option>
              <option *ngFor="let tip of externalTips$ | async" [ngValue]="tip">
                {{ tip.name }}
              </option>
            </select>
            <div ngxErrors="receiver">
              <div translate ngxError="required" showWhen="touched">
                validation_required
              </div>
            </div>
          </div>
          <div class="form-group">
            <h4>{{ "subject" | translate }}</h4>
            <input
              type="text"
              placeholder="{{ 'subject' | translate }}"
              formControlName="subject"
              [ngClass]="
                !externalTipsForm.get('subject').valid &&
                externalTipsForm.get('subject').touched
                  ? 'validation-input'
                  : ''
              "
            />
            <div ngxErrors="subject">
              <div translate ngxError="required" showWhen="touched">
                validation_required
              </div>
            </div>
          </div>
          <div class="form-group">
            <h4 translate>message</h4>
            <textarea
              type="text"
              placeholder="{{ 'message' | translate }}"
              formControlName="message"
            >
              {{ "name" | translate }}: stuff
            </textarea>
            <div ngxErrors="message">
              <div translate ngxError="required" showWhen="touched">
                validation_required
              </div>
            </div>
          </div>
          <div class="form-group">
            <span class="add-email-to-outbox">
              <div>
                <input
                  formControlName="addToOutbox"
                  id="add-to-outbox"
                  type="checkbox"
                />
                <label for="add-to-outbox">
                  <span translate>add_mail_to_outbox</span>
                </label>
              </div>
            </span>
          </div>
          <hr />
          <h4 class="required" translate>consent</h4>
          <div class="consent-group">
            <div class="consent-group__icon-container">
              <i class="icon-gdpr"></i>
            </div>
            <div
              class="consent-group__box"
              [ngClass]="checkedInput ? 'success-bg' : ''"
            >
              <label class="checkbox-group">
                {{ "contact_has_agreed_to_3rd_party" | translate }}
                <input
                  type="checkbox"
                  [checked]="checkedInput"
                  (change)="checkedInput = !checkedInput"
                  formControlName="consent"
                  required
                />
                <span class="checkbox-group__checkmark"></span>
              </label>
            </div>
            <div tabindex="-1" ngxErrors="consent">
              <div translate ngxError="required" showWhen="touched">
                consent_mandatory
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="sidebar-footer">
      <app-q-button buttonType="cancel" label="cancel" (click)="closeTab()">
      </app-q-button>
      <app-q-button
        buttonType="save"
        label="save"
        scrollToInvalidControl
        [formEl]="externalTipsForm"
        [disabled]="isWorking$ | async"
        [isLoading]="isWorking$ | async"
        (click)="onSubmit()"
      >
      </app-q-button>
    </div>
  </form>
</div>
