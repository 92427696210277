import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { DrawMapModule } from "@app/shared/modules/draw-map/draw-map.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreateSearchProfileAlertComponent } from "@app/sidebar/search-profile/create-search-profile-alert/create-search-profile-alert.component";
import { CreateSearchProfileComponent } from "@app/sidebar/search-profile/create-search-profile/create-search-profile.component";
import { EditSearchProfileComponent } from "@app/sidebar/search-profile/edit-search-profile/edit-search-profile.component";
import { SearchProfileAlertFormComponent } from "@app/sidebar/search-profile/search-profile-alert-form/search-profile-alert-form.component";
import { DrawMapModalService } from "@app/sidebar/search-profile/search-profile-form/draw-map-modal.service";
import { SearchProfileFormComponent } from "@app/sidebar/search-profile/search-profile-form/search-profile-form.component";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { NguiMapModule } from "@ngui/map";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsModalRef, ModalModule } from "ngx-bootstrap/modal";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormsModule,
    FormComponentsModule,
    UtilModule,
    BsDatepickerModule,
    SkeletonScreenModule,
    UiComponentsModule,
    NguiMapModule,
    ModalModule,
    DrawMapModule,
    ErpFormElementsModule,
  ],
  declarations: [
    CreateSearchProfileComponent,
    EditSearchProfileComponent,
    SearchProfileFormComponent,
    CreateSearchProfileAlertComponent,
    SearchProfileAlertFormComponent,
  ],
  providers: [BsModalRef, DrawMapModalService],
})
export class SearchProfileModule {}
