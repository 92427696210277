<div class="kpi-box-container">
  <h4 class="kpi-box__title">
    <span
      matTooltip="{{ (title.length > 18 ? title : '') | translate }}"
      matTooltipClass="q-tooltip"
    >
      {{ title }}
    </span>
    <span
      *ngIf="getTooltip(type)"
      matTooltip="{{ getTooltip(type) | translate }}"
      matTooltipClass="q-tooltip"
    >
      <app-icon name="info-circle"></app-icon>
    </span>
  </h4>

  <div class="kpi-box">
    <ng-container *ngIf="max; else noSalesTarget">
      <div class="progress-container">
        <div class="progress" style="width: 100%">
          <div class="progress__bar">
            <progressbar
              [max]="max"
              [value]="value"
              [type]="getProgressBarType(value, max)"
            >
            </progressbar>
          </div>
          <span class="value">{{ (value | separateThousands) || 0 }}</span>
        </div>
        <h5>/ {{ (max | separateThousands) || 0 }}</h5>
      </div>
    </ng-container>

    <ng-template #noSalesTarget>
      <div class="no-target">
        <div>
          <div class="value">{{ (value | separateThousands) || 0 }}</div>
          <small translate class="text text-muted">sales_target_not_set</small>
        </div>
      </div>
    </ng-template>

    <div class="kpi-box__show-more-btn">
      <app-icon name="circle-ellipsis-vertical"></app-icon>
    </div>
  </div>
</div>
