import * as actionListModels from "@app/models/action";
import * as _ from "lodash";
import * as ActionListActions from "./action-list.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface ActionListState {
  loading: boolean;
  loaded: boolean;
  totalActions: number;
  actions: actionListModels.Action[];
  latestFetchAction: Action;
}

export const initialState: ActionListState = {
  loading: false,
  loaded: false,
  totalActions: 0,
  actions: [],
  latestFetchAction: null,
};

// TODO: Remove "| any" from action type
export function actionListReducer(
  state: ActionListState,
  action: Action
): ActionListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(ActionListActions.getLatestActionsRequest, (state, action) => {
    let actions = [];
    let totalActions = 0;

    if (_.isEqual(state.latestFetchAction, action)) {
      actions = state.actions;
      totalActions = state.totalActions;
    }
    return {
      ...state,
      loading: true,
      totalActions,
      actions,
      latestFetchAction: action,
    };
  }),
  on(ActionListActions.getLatestActionsSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    totalActions: payload.total,
    actions: payload.rows,
  })),
  on(ActionListActions.getMoreLatestActionsRequest, (state, action) => {
    const limit = parseInt(action.params.limit.toString());
    const payload = {
      ...action.params,
      offset: 0,
      limit: limit + limit,
    };
    const latestFetchAction = { ...action, payload };
    return { ...state, loading: true, latestFetchAction };
  }),
  on(ActionListActions.getMoreLatestActionsSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    totalActions: payload.total,
    actions: [...state.actions, ...payload.rows],
  })),
  on(ActionListActions.removeActionSuccess, (state, { actionId }) => {
    const newList = state.actions.filter(
      (ca: actionListModels.Action) => ca.actionId !== actionId
    );
    return {
      ...state,
      totalActions: state.totalActions - 1,
      actions: newList,
    };
  })
);

const selectFeature = createFeatureSelector<ActionListState>("actionList");

export const getLoading = createSelector(
  selectFeature,
  (state: ActionListState) => state.loading
);

export const getLoaded = createSelector(
  selectFeature,
  (state: ActionListState) => state.loaded
);

export const getTotalCount = createSelector(
  selectFeature,
  (state: ActionListState) => state.totalActions
);

export const getList = createSelector(
  selectFeature,
  (state: ActionListState) => state.actions
);

export const getLatestActionsFetchAction = createSelector(
  selectFeature,
  (state: ActionListState) => state.latestFetchAction
);
