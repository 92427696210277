<hr class="hidden-sm hidden-md hidden-lg hidden-xl" />
<li class="action-list-item">
  <section class="xs hidden-sm hidden-md hidden-lg hidden-xl">
    <div class="action-list-item__date">
      <small
        >{{ item.timestamp | amDateFormat : "D" }}
        {{ item.timestamp | amDateFormat : "MMM" }}
      </small>
    </div>

    <div>
      <app-icon
        [name]="getIcon(item)"
        [style.color]="getColor(item)"
      ></app-icon>
      <div class="action-list-item__line hidden-xs"></div>
    </div>
  </section>

  <section class="action-list-item__date hidden-xs">
    <small
      >{{ item.timestamp | amDateFormat : "D" }}
      {{ item.timestamp | amDateFormat : "MMM" }}
    </small>
  </section>

  <section class="hidden-xs">
    <app-icon [name]="getIcon(item)" [style.color]="getColor(item)"></app-icon>
    <div class="action-list-item__line"></div>
  </section>

  <section class="action-list-item__headline">
    <h4>
      {{ item.type | lowercase | translate }}
      <i *ngIf="item.param3 === 'private'" class="fa fa-eye-slash"></i>
    </h4>
    <small class="text-muted">{{ item.timestamp }}</small>
  </section>

  <section class="action-list-item__content">
    <action-lead-connected
      [item]="item"
      *ngIf="item.code === '4501'"
    ></action-lead-connected>
    <action-lead-resolved
      [item]="item"
      *ngIf="item.code === '4502'"
    ></action-lead-resolved>
    <action-lead-deleted
      [item]="item"
      *ngIf="item.code === '4503'"
    ></action-lead-deleted>
    <action-tips-sent-to-bank
      [item]="item"
      *ngIf="item.code === '4601'"
    ></action-tips-sent-to-bank>
    <consumer-meeting-started
      [item]="item"
      *ngIf="item.code === '4102'"
    ></consumer-meeting-started>

    <action-task-connected
      [item]="item"
      *ngIf="item.code === '4301'"
    ></action-task-connected>
    <action-task-resolved
      [item]="item"
      *ngIf="item.code === '4302'"
    ></action-task-resolved>
    <action-task-deleted
      [item]="item"
      *ngIf="item.code === '4303'"
    ></action-task-deleted>
    <action-task-not-resolved
      [item]="item"
      *ngIf="item.code === '4307'"
    ></action-task-not-resolved>
    <action-task-changed-delivery-date
      [item]="item"
      *ngIf="item.code === '8218'"
    ></action-task-changed-delivery-date>

    <action-note-stored
      [ownsNote]="eaEmployeeId === item.eaEmployeeId"
      [item]="item"
      *ngIf="item.code === '3204'"
    ></action-note-stored>
    <action-object-note-stored
      [item]="item"
      *ngIf="item.code === '3205'"
    ></action-object-note-stored>

    <action-call-registered
      [item]="item"
      [eaEmployeeId]="eaEmployeeId"
      *ngIf="item.code === '4201'"
    ></action-call-registered>
    <action-one2one-sms
      [item]="item"
      *ngIf="item.code === '2101'"
    ></action-one2one-sms>
    <action-one2one-email
      [item]="item"
      *ngIf="item.code === '2102'"
    ></action-one2one-email>
    <action-crm-sent
      [item]="item"
      *ngIf="item.code === '2203'"
    ></action-crm-sent>

    <action-sales-meeting-created
      [item]="item"
      *ngIf="item.code === '4401'"
    ></action-sales-meeting-created>
    <action-sales-meeting-updated
      [item]="item"
      *ngIf="item.code === '4402'"
    ></action-sales-meeting-updated>
    <action-sales-meeting-deleted
      [item]="item"
      *ngIf="item.code === '4403'"
    ></action-sales-meeting-deleted>

    <action-object-email
      [item]="item"
      *ngIf="item.code === '2103'"
    ></action-object-email>
    <action-mortgage-stored
      [item]="item"
      *ngIf="item.code === '3220'"
    ></action-mortgage-stored>
    <action-mortgage-deleted
      [item]="item"
      *ngIf="item.code === '3221'"
    ></action-mortgage-deleted>
    <action-object-connected
      [item]="item"
      *ngIf="item.code === '81'"
    ></action-object-connected>
    <action-consumer-showing-connected
      [item]="item"
      *ngIf="item.code === '3107'"
    ></action-consumer-showing-connected>

    <action-rating-information
      [item]="item"
      *ngIf="item.code === '3203'"
    ></action-rating-information>

    <action-consumer-created
      [item]="item"
      *ngIf="item.code === '80'"
    ></action-consumer-created>
    <action-employee-connected
      [item]="item"
      *ngIf="item.code === '3103'"
    ></action-employee-connected>
    <action-employee-disconnected
      [item]="item"
      *ngIf="item.code === '3104'"
    ></action-employee-disconnected>
    <action-office-connected
      [item]="item"
      *ngIf="item.code === '3105'"
    ></action-office-connected>
    <action-alert-stored
      [item]="item"
      *ngIf="item.code === '3601'"
    ></action-alert-stored>
    <action-preferences-updated
      [item]="item"
      *ngIf="item.code === '3301'"
    ></action-preferences-updated>
    <action-preferences-deleted
      [item]="item"
      *ngIf="item.code === '3303'"
    ></action-preferences-deleted>
  </section>
</li>
