import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-object-note-stored",
  template: `
    <div class="flex-container flex-space-between">
      <div>
        <div [innerHTML]="text | async" class=""></div>
        <div>
          <small *ngIf="item.param1?.length > 0" class="text-muted">
            <strong>{{ "note" | translate }}: </strong>
            <div [innerHtml]="item.param1 | nl2br | backslash"></div>
          </small>
        </div>
      </div>
      <div class="flex-center">
        <i
          class="fa fa-trash-o link"
          (click)="itemService.removeConsumerAction(item)"
        ></i>
      </div>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionObjectNoteStoredComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerNote: Function;

  classObject = "action-object-note-stored-object";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_object_note_stored_text", {
          object: `<span class="${this.classObject} text-link">${this.item.street}</span>`,
          you: youString,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerNote = this.itemService.createObjectLinkListener(
      this.elementRef,
      this.renderer,
      this.classObject,
      this.item.eaOid
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerNote];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
