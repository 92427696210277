import { Contact, Office } from "@app/models";
import { CreateContactParams } from "../../contacts/ngrx/create-contact.actions";
import { createAction, props } from "@ngrx/store";
import { SendTipsParams } from "@app/sidebar/tips/send-tips-new/send-tips-new.component";

export const ActionTypes = {
  CREATE_CONTACT_FAILED: "[Send Tips] CREATE_CONTACT_FAILED",
};

export const getClosestOfficeRequest = createAction(
  "[Send Tips] GET_CLOSEST_OFFICE_REQUEST",
  props<{
    street: String;
    zip: String;
    city: String;
    country: String;
  }>()
);

export const getClosestOfficeSuccess = createAction(
  "[Send Tips] GET_CLOSEST_OFFICE_SUCCESS",
  props<{ office: Office }>()
);

export const getClosestOfficeFailed = createAction(
  "[Send Tips] GET_CLOSEST_OFFICE_FAILED"
);

export const sendTipsRequest = createAction(
  "[Send Tips] SEND_TIPS_REQUEST",
  props<{ params: SendTipsParams }>()
);

export const sendTipsSuccess = createAction("[Send Tips] SEND_TIPS_SUCCESS");

export const sendTipsFailed = createAction("[Send Tips] SEND_TIPS_FAILED");

export const getContactRequest = createAction(
  "[Send Tips] GET_CONTACT_REQUEST",
  props<{ id: string }>()
);

export const getContactSuccess = createAction(
  "[Send Tips] GET_CONTACT_SUCCESS",
  props<{ contact: Contact }>()
);

export const getContactFailed = createAction("[Send Tips] GET_CONTACT_FAILED");

export const setContact = createAction(
  "[Send Tips] SET_CONTACT",
  props<{ contact: Contact }>()
);

export const createContact = (params: CreateContactParams, source?: string) => {
  return createContactRequest({
    params: { ...params, originService: "CRM" },
    source,
  });
};

export const createContactRequest = createAction(
  "[Send Tips] CREATE_CONTACT_REQUEST",
  props<{
    params: CreateContactParams;
    source?: string;
  }>()
);

export const createContactSuccess = createAction(
  "[Send Tips] CREATE_CONTACT_SUCCESS",
  props<{ contactId: string }>()
);

export const reset = createAction("[Send Tips] RESET");
