import { Component, Input } from "@angular/core";

@Component({
  selector: "app-q-search-list-item",
  templateUrl: "./q-search-list-item.component.html",
  styleUrls: ["./q-search-list-item.component.scss"],
})
export class QSearchListItemComponent {
  @Input() title: string;
  @Input() secondaryText: string[];
  @Input() chipData: { text: string; color: string } = null;

  joinArray(arr) {
    return arr.filter((x) => x).join(" | ");
  }
}
