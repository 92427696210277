import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  exhaustMap,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as apiUrls from "@app/core/services/api/utils/api-endpoints";
import { fetchTemplatesRequest } from "@app/settings/message-templates/ngrx/message-templates.actions";
import { MESSAGE_TEMPLATE } from "@app/shared/utils/tab-types";
import * as messageTemplatesFormActions from "@app/sidebar/message-templates/ngrx/message-templates-form.actions";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";

@Injectable()
export class MessageTemplatesFormEffects {
  getMessageTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.getMessageTemplateRequest),
      switchMap(({ templateId }) =>
        this.apiService.get(apiUrls.MESSAGE_TEMPLATE(templateId)).pipe(
          map((response: any) =>
            messageTemplatesFormActions.getMessageTemplateSuccess({
              template: response,
            })
          ),
          catchError(() =>
            observableOf(messageTemplatesFormActions.getMessageTemplateFail())
          )
        )
      )
    )
  );

  createMessageTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.createMessageTemplateRequest),
      exhaustMap(({ body }) =>
        this.apiService.post(apiUrls.CREATE_MESSAGE_TEMPLATE, body).pipe(
          map((response: any) =>
            messageTemplatesFormActions.createMessageTemplateSuccess({
              templateId: response,
            })
          ),
          catchError(() =>
            observableOf(
              messageTemplatesFormActions.createMessageTemplateFail()
            )
          )
        )
      )
    )
  );

  createMessageTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.createMessageTemplateSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.success({ message: "create_message_template_success" }),
          fetchTemplatesRequest(),
        ])
      )
    )
  );

  createMessageTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.createMessageTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.danger({ message: "create_message_template_fail" }),
        ])
      )
    )
  );

  editMessageTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.editMessageTemplateRequest),
      exhaustMap(({ templateId, body }) =>
        this.apiService
          .patch(apiUrls.EDIT_MESSAGE_TEMPLATE(templateId), body)
          .pipe(
            map((response: any) =>
              messageTemplatesFormActions.editMessageTemplateSuccess({
                templateId: response,
              })
            ),
            catchError(() =>
              observableOf(
                messageTemplatesFormActions.editMessageTemplateFail()
              )
            )
          )
      )
    )
  );

  editMessageTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.editMessageTemplateSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.success({ message: "edit_message_template_success" }),
          fetchTemplatesRequest(),
        ])
      )
    )
  );

  editMessageTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.editMessageTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.danger({ message: "edit_message_template_fail" }),
        ])
      )
    )
  );

  deleteMessageTemplateRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.deleteMessageTemplateRequest),
      exhaustMap(({ templateId }) =>
        this.apiService
          .delete(apiUrls.DELETE_MESSAGE_TEMPLATE(templateId))
          .pipe(
            map(() =>
              messageTemplatesFormActions.deleteMessageTemplateSuccess()
            ),
            catchError(() =>
              observableOf(
                messageTemplatesFormActions.deleteMessageTemplateFail()
              )
            )
          )
      )
    )
  );

  deleteMessageTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.deleteMessageTemplateSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.success({ message: "delete_message_template_success" }),
          fetchTemplatesRequest(),
        ])
      )
    )
  );

  deleteMessageTemplateFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.deleteMessageTemplateFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: MESSAGE_TEMPLATE }),
          toastActions.danger({ message: "delete_message_template_fail" }),
        ])
      )
    )
  );

  fetchDynamicContentRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(messageTemplatesFormActions.fetchDynamicContentRequest),
      switchMap(({ type, templateId, ...params }) =>
        this.apiService
          .get(apiUrls.MESSAGES_TEMPLATES_DYNAMIC_CONTENT(templateId), {
            ...params,
          })
          .pipe(
            map((response: any) =>
              messageTemplatesFormActions.fetchDynamicContentSuccess({
                dynamicContents: response.rows,
              })
            ),
            catchError(() =>
              observableOf(
                messageTemplatesFormActions.fetchDynamicContentFail()
              )
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
