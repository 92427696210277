import * as manageRegionsActions from "@app/settings/offices-employees/manage-regions/ngrx/manage-regions.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface ManageRegionsState {
  updateLoading: boolean;
  deleteLoading: boolean;
}

export const initialState: ManageRegionsState = {
  updateLoading: false,
  deleteLoading: false,
};

export function manageRegionsReducer(state = initialState, action: Action) {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    manageRegionsActions.updateRegionRequest,
    manageRegionsActions.updateRegionOfficesRequest,
    manageRegionsActions.createRegionRequest,
    (state) => ({
      ...state,
      updateLoading: true,
    })
  ),
  on(manageRegionsActions.deleteRegionRequest, (state) => ({
    ...state,
    deleteLoading: true,
  })),
  on(
    manageRegionsActions.updateRegionSuccess,
    manageRegionsActions.updateRegionFail,
    manageRegionsActions.updateRegionOfficesSuccess,
    manageRegionsActions.updateRegionOfficesFail,
    manageRegionsActions.createRegionSuccess,
    manageRegionsActions.createRegionFail,
    (state) => ({
      ...state,
      updateLoading: false,
    })
  ),
  on(
    manageRegionsActions.deleteRegionSuccess,
    manageRegionsActions.deleteRegionFail,
    (state) => ({
      ...state,
      deleteLoading: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.manageRegions
);

export const getUpdateLoading = createSelector(
  getState,
  (state: ManageRegionsState) => state.updateLoading
);
