import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { RemoveOfficePrefixPipe } from "../../../util/remove-office-prefix/remove-office-prefix.pipe";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-office-connected",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
  `,
  styleUrls: ["./common.scss"],
  providers: [RemoveOfficePrefixPipe],
})
export class ActionOfficeConnectedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerName: Function;

  className = "action-office-connected-office";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService,
    private removeOfficePrefixPipe: RemoveOfficePrefixPipe
  ) {}

  ngOnInit() {
    this.text = this.removeOfficePrefixPipe.transform(this.item.param2).pipe(
      switchMap((officeName) =>
        this.translateService.get("action_office_connected_text", {
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
          office: officeName,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
