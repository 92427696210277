import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.actions";
import { ObjectNoteResponse } from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.effects";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface ObjectNotesState {
  data: ObjectNoteResponse;
  isLoading: boolean;
}

const initialState: ObjectNotesState = {
  data: null,
  isLoading: false,
};

export const objectNotesReducer = createReducer(
  initialState,
  on(actions.getNotesRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getNotesSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: true,
  })),
  on(actions.getNotesFail, (state) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.createNoteSuccess, (state, { data }) => ({
    ...state,
    data: {
      ...state.data,
      notes: [data, ...state.data.notes],
    },
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const selectObjectNotes = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.objectNotes
);
export const selectData = createSelector(
  selectObjectNotes,
  (state: ObjectNotesState) => state.data
);
export const selectIsLoading = createSelector(
  selectObjectNotes,
  (state: ObjectNotesState) => state.isLoading
);
