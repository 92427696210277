import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TranslateModule } from "@ngx-translate/core";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { AssignCallingListComponent } from "./assign-calling-list/assign-calling-list.component";
import { CreateCallingListComponent } from "./create-calling-list/create-calling-list.component";
import { PreviewCallingListDialogComponent } from "./create-calling-list/preview-calling-list-dialog/preview-calling-list-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormComponentsModule,
    FormsModule,
    UtilModule,
    BsDatepickerModule,
    TooltipModule,
    UiComponentsModule,
    ErpFormElementsModule,
    MatDialogModule,
    SkeletonScreenModule,
    MatTooltipModule,
  ],
  exports: [CreateCallingListComponent, AssignCallingListComponent],
  declarations: [
    CreateCallingListComponent,
    AssignCallingListComponent,
    PreviewCallingListDialogComponent,
  ],
})
export class CallingListsModule {}
