import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "backslash",
})
export class BackslashPipe implements PipeTransform {
  transform(value: any): any {
    return value.replace(/(?:\\)/g, "");
  }
}
