<div class="list-item">
  <div class="list-item__icon">
    <app-icon [name]="getIcon()"></app-icon>
  </div>
  <div class="list-item__content">
    <contact-action-one2one-sms
      *ngIf="action.code === '2101'"
      [action]="action"
    ></contact-action-one2one-sms>
    <contact-action-one2one-email
      *ngIf="action.code === '2102'"
      [action]="action"
    ></contact-action-one2one-email>
    <contact-action-crm-sent-email
      *ngIf="action.code === '2203'"
      [action]="action"
    ></contact-action-crm-sent-email>
    <contact-action-call-registered-email
      *ngIf="action.code === '4201'"
      [action]="action"
    ></contact-action-call-registered-email>
    <contact-action-call-registered-phone
      *ngIf="action.code === '4202'"
      [action]="action"
    ></contact-action-call-registered-phone>
  </div>
</div>
