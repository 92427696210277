import { Route } from "@angular/router";
import { ExistingHomeGuard } from "../guards/existing-home-guard.service";
import { ResidenceCreateComponent } from "./residence-create/residence-create.component";
import { ResidenceEditComponent } from "./residence-edit/residence-edit.component";
import { SidebarContactResidencesResolver } from "./sidebar-contact-residences-resolver.service";
import { SidebarResidenceContactResolver } from "./sidebar-residence-contact-resolver.service";

export const sidebarContactsResidencesRoutes: Route = {
  path: ":contactId/residences",
  resolve: {
    contact: SidebarResidenceContactResolver,
    residences: SidebarContactResidencesResolver,
  },
  children: [
    { path: "", redirectTo: "new", pathMatch: "full" },
    {
      path: "new",
      component: ResidenceCreateComponent,
      canActivate: [ExistingHomeGuard],
    },
    {
      path: ":id/edit",
      component: ResidenceEditComponent,
      canActivate: [ExistingHomeGuard],
    },
  ],
};
