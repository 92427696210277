import { PaginationListDTO, TaskType } from "@app/models";
import { createAction, props } from "@ngrx/store";

const defaultGetSentLeadsParams = {
  limit: 10,
  resolved: "both",
  taskTypeCategory: "lead",
  offset: 0,
  sortBy: "insertedDate",
  sortOrder: "desc",
};

export const getSentLeads = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultGetSentLeadsParams,
    ...params,
  };

  return getSentLeadsRequest({
    filters,
  });
};

export const getSentLeadsRequest = createAction(
  "[Lists Sent Leads] GET_SENT_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getSentLeadsSuccess = createAction(
  "[Lists Sent Leads] GET_SENT_LEADS_SUCCESS",
  props<{ sentLeads: PaginationListDTO }>()
);

export const getSentLeadsFail = createAction(
  "[Lists Sent Leads] GET_SENT_LEADS_FAIL"
);

export const getMoreSentLeads = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultGetSentLeadsParams,
    ...params,
  };
  return getMoreSentLeadsRequest({ filters });
};

export const getMoreSentLeadsRequest = createAction(
  "[Lists Sent Leads] GET_MORE_SENT_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreSentLeadsSuccess = createAction(
  "[Lists Sent Leads] GET_MORE_SENT_LEADS_SUCCESS",
  props<{ sentLeads: PaginationListDTO }>()
);

export const getMoreSentLeadsFail = createAction(
  "[Lists Sent Leads] GET_MORE_SENT_LEADS_FAIL"
);

export const getTaskTypesRequest = createAction(
  "[Lists Sent Leads] GET_TASK_TYPES_REQUEST"
);

export const getTaskTypesSuccess = createAction(
  "[Lists Sent Leads] GET_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const getTaskTypesFailed = createAction(
  "[Lists Sent Leads] GET_TASK_TYPES_FAILED"
);

export const reset = createAction("[Lists Sent Leads] RESET");
