import { ContactRelationship } from "@app/models";
import {
  deleteContactRelationsFail,
  deleteContactRelationsRequest,
  deleteContactRelationsSuccess,
  fetchContactRelationsFail,
  fetchContactRelationsRequest,
  fetchContactRelationsSuccess,
  patchContactRelationsFail,
  patchContactRelationsRequest,
  patchContactRelationsSuccess,
  postContactRelationsFail,
  postContactRelationsRequest,
  postContactRelationsSuccess,
} from "@app/widgets/widgets/contact-widgets/contact-relations-widget/ngrx/contact-relations-widget.actions";
import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface ContactRelationsWidgetState {
  isLoading: boolean;
  data: ContactRelationship[];
}

export const initialState: ContactRelationsWidgetState = {
  isLoading: false,
  data: [],
};
export const contactRelationsWidgetReducer = createReducer(
  initialState,
  on(
    fetchContactRelationsRequest,
    postContactRelationsRequest,
    patchContactRelationsRequest,
    deleteContactRelationsRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(fetchContactRelationsSuccess, (state, { data }) => ({
    ...state,
    isLoading: false,
    data,
  })),
  on(
    fetchContactRelationsFail,
    postContactRelationsFail,
    postContactRelationsSuccess,
    patchContactRelationsFail,
    patchContactRelationsSuccess,
    deleteContactRelationsSuccess,
    deleteContactRelationsFail,
    (state) => ({
      ...state,
      isLoading: false,
    })
  )
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.contactRelations
);

export const selectIsLoading = createSelector(
  getState,
  (state: ContactRelationsWidgetState) => state.isLoading
);
