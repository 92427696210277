import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { ERP_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { CREATE_ERP_SHOWING } from "@app/shared/utils/tab-types";
import { CrmModule } from "@app/sidebar/models/crm-module";
import { SidebarService } from "@app/sidebar/sidebar.service";

@Injectable()
export class ErpShowingsGuardService implements CanActivate {
  crmModules = [ERP_CRM_MODULE];

  constructor(private sidebarService: SidebarService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const crmModule = this.crmModules
      .map((module) => new CrmModule(module))
      .find((module) => module.isModuleActive(state.url));

    return this.sidebarService.canActivateTab(
      route,
      state.url,
      crmModule,
      CREATE_ERP_SHOWING
    );
  }
}
