import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { SalesMeeting, Task, TaskSalesMeeting } from "@app/models";
import { TASK } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import { map, Observable, of as observableOf, switchMap, tap } from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as taskActions from "../ngrx/tasks.actions";

@Injectable()
export class TasksSalesMeetingReportResolverService
  implements Resolve<Observable<Task>>
{
  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  resolve(next: ActivatedRouteSnapshot): Observable<Task> {
    const { eaTaskId } = next.params;
    return this.apiService.get(`tasks/${eaTaskId}`).pipe(
      switchMap((task: Task) => this.getTaskSalesMeeting(task)),
      tap((taskSalesMeeting: TaskSalesMeeting) => {
        if (
          !taskSalesMeeting.hasOwnProperty("salesMeeting") ||
          !taskSalesMeeting.salesMeeting
        ) {
          this.store.dispatch(
            toastActions.warning({ message: "no_connected_sales_meeting" })
          );
          this.executeFailActions();
        } else {
          this.store.dispatch(
            taskActions.getTaskSuccess({ task: taskSalesMeeting })
          );
        }
      })
    );
  }

  private executeFailActions(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: TASK }));
    this.store.dispatch(taskActions.reset());
  }

  private getTaskSalesMeeting(task: Task): Observable<TaskSalesMeeting> {
    if (task.eaCrmSalesMeetingId) {
      return this.apiService
        .get(`sales-meetings/${task.eaCrmSalesMeetingId}`, { getTasks: "True" })
        .pipe(
          map(
            (salesMeeting: SalesMeeting) =>
              new TaskSalesMeeting({
                ...task,
                salesMeeting: new SalesMeeting({ ...salesMeeting }),
              })
          )
        );
    } else {
      return observableOf(new TaskSalesMeeting({ ...task }));
    }
  }
}
