import {
  StatsLeadEmployee,
  StatsLeadTotal,
} from "@app/statistics/boxes/leads/utils";
import { SalesMeetingStatisticsTotalResponse } from "@app/statistics/boxes/sales-meetings/utils";
import * as statisticsStartActions from "@app/statistics/statistics-start/ngrx/statistics-start.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface SalesMeetingBookedResponse {
  salesMeetings: number;
  bookedSalesMeetings: number;
}

export interface StatisticsStartPageState {
  leadTotal: StatsLeadTotal;
  salesMeetingTotal: SalesMeetingStatisticsTotalResponse;
  leadEmployee: StatsLeadEmployee;
  salesMeetingBooked: SalesMeetingBookedResponse;
  loadingLeadsResults: boolean;
  loadingSalesMeetingsResults: boolean;
  loadingSalesMeetingsBooked: boolean;
  timerEnabled: boolean;
}

export const initialState: StatisticsStartPageState = {
  leadTotal: null,
  salesMeetingTotal: null,
  leadEmployee: null,
  salesMeetingBooked: null,
  loadingLeadsResults: false,
  loadingSalesMeetingsResults: false,
  loadingSalesMeetingsBooked: false,
  timerEnabled: false,
};

export function statisticsStartPageReducer(
  state: StatisticsStartPageState,
  action: Action
) {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    statisticsStartActions.getLeadResultsForChainRequest,
    statisticsStartActions.getLeadResultsForEmployeeRequest,
    (state) => ({
      ...state,
      loadingLeadsResults: true,
    })
  ),
  on(
    statisticsStartActions.getSalesMeetingResultsForChainRequest,
    statisticsStartActions.getSalesMeetingResultsForEmployeeRequest,
    (state) => ({
      ...state,
      loadingSalesMeetingsResults: true,
    })
  ),
  on(statisticsStartActions.getSalesMeetingBookedRequest, (state, {}) => ({
    ...state,
    loadingSalesMeetingsBooked: true,
  })),
  on(
    statisticsStartActions.getLeadResultsForChainFail,
    statisticsStartActions.getLeadResultsForEmployeeFail,
    (state, {}) => ({
      ...state,
      loadingLeadsResults: false,
    })
  ),
  on(
    statisticsStartActions.getSalesMeetingResultsForChainFail,
    statisticsStartActions.getSalesMeetingResultsForEmployeeFail,
    (state, {}) => ({
      ...state,
      loadingSalesMeetingsResults: false,
    })
  ),
  on(statisticsStartActions.getSalesMeetingBookedFail, (state, {}) => ({
    ...state,
    loadingSalesMeetingsBooked: false,
  })),
  on(
    statisticsStartActions.getLeadResultsForChainSuccess,
    (state, { leadTotal }) => ({
      ...state,
      loadingLeadsResults: false,
      leadTotal,
    })
  ),
  on(
    statisticsStartActions.getLeadResultsForEmployeeSuccess,
    (state, { leadEmployee }) => ({
      ...state,
      loadingLeadsResults: false,
      leadEmployee,
    })
  ),
  on(
    statisticsStartActions.getSalesMeetingBookedSuccess,
    (state, { salesMeetingBooked }) => ({
      ...state,
      loadingSalesMeetingsBooked: false,
      salesMeetingBooked,
    })
  ),
  on(
    statisticsStartActions.getSalesMeetingResultsForChainSuccess,
    statisticsStartActions.getSalesMeetingResultsForEmployeeSuccess,
    (state, { salesMeetingTotal }) => ({
      ...state,
      loadingSalesMeetingsResults: false,
      salesMeetingTotal,
    })
  ),
  on(statisticsStartActions.setTimer, (state, { enabled }) => ({
    ...state,
    timerEnabled: enabled,
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.startPage
);

export const getLoadingLeadResults = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.loadingLeadsResults
);

export const getLoadingSalesMeetingsResults = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.loadingSalesMeetingsResults
);

export const getLoadingSalesMeetingsBooked = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.loadingSalesMeetingsBooked
);

export const getLeadTotal = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.leadTotal
);

export const getSalesMeetingTotal = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.salesMeetingTotal
);

export const getLeadEmployee = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.leadEmployee
);

export const getSalesMeetingBooked = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.salesMeetingBooked
);

export const getTimerStatus = createSelector(
  getState,
  (state: StatisticsStartPageState) => state.timerEnabled
);
