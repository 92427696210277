<app-q-modal>
  <ng-container slot="header">
    {{ "change_sorting" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="list-container">
      <ul class="sortable-list" [sortablejs]="columns">
        <li class="sortable-item" *ngFor="let checkbox of columns">
          <mat-checkbox [formControl]="checkbox.control"
            >{{ checkbox.label | translate }}
          </mat-checkbox>
          <app-icon name="angles-up-down" size="medium"></app-icon>
        </li>
      </ul>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button
      buttonType="cancel"
      label="close"
      (click)="close()"
    ></app-q-button>
    <app-q-button
      buttonType="ignore"
      label="reset"
      (click)="reset()"
    ></app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      (click)="save()"
    ></app-q-button>
  </ng-container>
</app-q-modal>
