<div
  class="sub-heading"
  [@fadeInUp]
  *ngIf="enableAddButton || enableActionButtons || enableSearch"
>
  <div class="sub-heading--left">
    <div class="sub-heading__add-btn" *ngIf="enableAddButton">
      <button
        (click)="
          !!enableMultipleAddButtons
            ? openModal(addButtonsModal)
            : addButtonPressed.emit()
        "
        class="btn btn-secondary btn-circle btn-create"
      >
        +
      </button>
    </div>
    <div *ngIf="enableActionButtons" class="sub-heading__action-buttons">
      <ng-content select="[buttons]"></ng-content>
      <span
        class="sub-heading__selected-entities"
        [matTooltip]="tooltipLabel | translate"
        matTooltipClass="q-tooltip"
        *ngIf="selectedEntities > 0"
      >
        {{ selectedEntities | separateThousands }}
        {{ selectedEntitiesLabel | translate }}

        <span *ngIf="!!tooltipLabel" class="info-tooltip">
          <app-icon name="info-circle" size="small"></app-icon>
        </span>
      </span>
    </div>
  </div>
  <div class="sub-heading--right">
    <div class="sub-heading__search-input" *ngIf="enableSearch">
      <input type="text" [formControl]="searchFormControl" />
      <span class="sub-heading__search-icon">
        <app-icon name="search" size="none"></app-icon>
      </span>
    </div>
  </div>
</div>

<div *ngIf="enableViewModes" class="sub-heading" [@fadeInUp]>
  <div class="sub-heading--left">
    <div class="sub-heading__action-buttons">
      <ng-content select="[viewModeButtons]"></ng-content>
    </div>
  </div>
</div>

<ng-template #addButtonsModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>create</h4>
    <button
      (click)="modalRef.hide()"
      aria-label="Close"
      class="close pull-right"
      type="button"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-content select="[addButtons]"></ng-content>
  </div>
</ng-template>
