import { StatsLeadSource } from "@app/statistics/boxes/leads/utils";
import {
  StatsLeadEmployee,
  StatsLeadOffice,
  StatsLeadTotal,
} from "@app/statistics/statistics-leads/components/statistics-leads-content/utils";
import * as sourceActions from "@app/statistics/statistics-leads/ngrx/statistics-leads.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as resultsActions from "./statistics-leads.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface LeadResultsState {
  loading: boolean;
  total: StatsLeadTotal;
  offices: StatsLeadOffice[];
  employees: StatsLeadEmployee[];
  employee: StatsLeadEmployee;
}

export const initialStateLeadResults: LeadResultsState = {
  loading: false,
  total: null,
  offices: null,
  employees: null,
  employee: null,
};

export interface LeadSourcesState {
  loading: boolean;
  total: StatsLeadTotal;
  sources: StatsLeadSource[];
}

export const initialStateLeaedSources: LeadSourcesState = {
  loading: false,
  total: null,
  sources: null,
};

export function leadResultsReducer(
  state: LeadResultsState,
  action: Action
): LeadResultsState {
  return resultReducer(state, action);
}

const resultReducer = createReducer(
  initialStateLeadResults,
  on(
    resultsActions.getResultsForChainRequest,
    resultsActions.getResultsForOfficeRequest,
    resultsActions.getResultsForEmployeeRequest,
    () => ({ ...initialStateLeadResults, loading: true })
  ),
  on(resultsActions.getResultsForChainSuccess, (state, { total, offices }) => ({
    ...state,
    loading: false,
    total,
    offices,
  })),
  on(
    resultsActions.getResultsForOfficeSuccess,
    (state, { total, employees }) => ({
      ...state,
      loading: false,
      total,
      employees,
    })
  ),
  on(resultsActions.getResultsForEmployeeSuccess, (state, { results }) => ({
    ...state,
    loading: false,
    employee: results,
  })),
  on(
    resultsActions.getResultsForChainFail,
    resultsActions.getResultsForOfficeFail,
    resultsActions.getResultsForEmployeeFail,
    (state) => ({ ...state, loading: false })
  )
);

export function leadSourcesReducer(
  state: LeadSourcesState,
  action: Action
): LeadSourcesState {
  return sourceReducer(state, action);
}

const sourceReducer = createReducer(
  initialStateLeaedSources,
  on(sourceActions.getSourcesRequest, () => ({
    ...initialStateLeaedSources,
    loading: true,
  })),
  on(sourceActions.getSourcesSuccess, (state, { sources, total }) => ({
    ...state,
    loading: false,
    total,
    sources,
  })),
  on(sourceActions.getSourcesFail, (state) => ({ ...state, loading: false }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.leadResults
);

export const getLeadResultLoading = createSelector(
  getState,
  (state: LeadResultsState) => state.loading
);

export const getLeadResultTotal = createSelector(
  getState,
  (state: LeadResultsState) => state.total
);

export const getOffices = createSelector(
  getState,
  (state: LeadResultsState) => state.offices
);

export const getEmployees = createSelector(
  getState,
  (state: LeadResultsState) => state.employees
);

export const getEmployee = createSelector(
  getState,
  (state: LeadResultsState) => state.employee
);

// Sources
const getLeadSourcesState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.leadSources
);

export const getLeadSourcesLoading = createSelector(
  getLeadSourcesState,
  (state: LeadSourcesState) => state.loading
);

export const getLeadSourcesTotal = createSelector(
  getLeadSourcesState,
  (state: LeadSourcesState) => state.total
);

export const getSources = createSelector(
  getLeadSourcesState,
  (state: LeadSourcesState) => state.sources
);
