import { Component, Input } from "@angular/core";
import {
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  fadeInUp,
} from "@app/shared/animations";
import { WidgetSize } from "@app/widgets/widgets/widget/widget-size";

@Component({
  selector: "app-widget",
  templateUrl: "./widget.component.html",
  styleUrls: ["./widget.component.scss"],
  animations: [fadeInUp(), fadeInDown(), fadeInRight(), fadeInLeft()],
})
export class WidgetComponent {
  @Input() widgetType: string;
  @Input() showMoreLink: string[];
  @Input() size: WidgetSize = "medium";
  @Input() padding: "default" | "none" = "default";
  @Input() openLinkInNewTab: boolean = false;
}
