<div *ngIf="clients$ | async as clients" class="clientSwitch">
  <h3 translate>change_client</h3>
  <div class="client-dropdown-container">
    <app-dropdown-element
      [multiple]="false"
      [newStyle]="true"
      [items]="clients"
      [label]="'change_client'"
      [path]="['clients']"
      [width]="'100%'"
      [required]="true"
      [search]="clients?.length > 5"
      [preselectValue]="chosenClient"
      (valueEntered)="onSelectionChange($event)"
    >
    </app-dropdown-element>
  </div>
</div>
