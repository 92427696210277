import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  map,
  Observable,
} from "rxjs";
import { hasRole } from "../user";
import { ROLE_ADMIN, ROLE_MANAGER } from "../utils/roles";

@Injectable()
export class AdminManagerGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return observableCombineLatest([
      this.store.pipe(select(hasRole(ROLE_ADMIN))),
      this.store.pipe(select(hasRole(ROLE_MANAGER))),
    ]).pipe(map(([isAdmin, isManager]) => isAdmin || isManager));
  }
}
