<div [formGroup]="form">
  <section>
    <hr />
    <h4 translate>residence</h4>
    <div class="form-group">
      <label translate>type</label>
      <select formControlName="objType">
        <option disabled value="" selected>
          {{ "select_residence_type" | translate }}
        </option>
        <option *ngFor="let type of objectTypes" [value]="type.objectTypeId">
          {{ type.objectTypeName }}
        </option>
      </select>
      <div *ngIf="config.objectTypeIsMandatory" ngxErrors="objType">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div formGroupName="address">
      <div class="form-group">
        <search-address-adv
          type="street"
          [containerFormControl]="form.get('address').get('street')"
          [enabled]="(addressSuggesterFeature$ | async)?.enabled"
          [provider]="(addressSuggesterFeature$ | async)?.provider"
          [countryCode]="defaultCountryCodeISO.toLowerCase()"
          [placeholder]="'street' | translate"
          [invalid]="form.get('address').get('street').invalid"
          [showFullAddress]="false"
          (onSelected)="handleStreetSelected($event)"
        ></search-address-adv>
        <div ngxErrors="street">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>

      <div class="form-group">
        <search-address-adv
          type="zip"
          [containerFormControl]="form.get('address').get('zip')"
          [enabled]="(addressSuggesterFeature$ | async)?.enabled"
          [provider]="(addressSuggesterFeature$ | async)?.provider"
          [countryCode]="defaultCountryCodeISO.toLowerCase()"
          [placeholder]="'zip' | translate"
          [invalid]="form.get('address').get('zip').invalid"
          [showFullAddress]="false"
          (onSelected)="handleZipSelected($event)"
        ></search-address-adv>
        <div ngxErrors="zip">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>

      <div class="form-group">
        <search-address-adv
          type="city"
          [containerFormControl]="form.get('address').get('city')"
          [enabled]="(addressSuggesterFeature$ | async)?.enabled"
          [provider]="(addressSuggesterFeature$ | async)?.provider"
          [countryCode]="defaultCountryCodeISO.toLowerCase()"
          [placeholder]="'city' | translate"
          [invalid]="form.get('address').get('city').invalid"
          [showFullAddress]="false"
        ></search-address-adv>
        <div ngxErrors="city">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>

      <address-validation
        *ngIf="(addressValidationFeature$ | async)?.enabled"
        [address]="addressValues$"
        [countryCode]="defaultCountryCodeISO.toLowerCase()"
        [max]="5"
        (onSelect)="handleAddressSuggestionSelected($event)"
      ></address-validation>
    </div>
  </section>
  <hr />
  <section>
    <h4>{{ "residence_information" | translate }}</h4>
    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        [placeholder]="getAreaPlaceholder() | async | translate"
        formControlName="area"
      />
      <div ngxErrors="area">
        <div translate ngxError="pattern" showWhen="touched">
          validation_decimals
        </div>
      </div>
    </adaptive-placeholder>

    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        [placeholder]="'nbr_of_rooms' | translate"
        formControlName="rooms"
      />
      <div ngxErrors="rooms">
        <div translate ngxError="pattern" showWhen="touched">
          validation_decimals
        </div>
      </div>
    </adaptive-placeholder>

    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        [placeholder]="'floor' | translate"
        formControlName="floor"
      />
    </adaptive-placeholder>

    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        thousandSeparator
        [placeholder]="'fee' | translate"
        formControlName="fee"
      />
      <div ngxErrors="fee">
        <div translate ngxError="pattern" showWhen="touched">
          validation_integers
        </div>
      </div>
    </adaptive-placeholder>

    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        [placeholder]="'year_built' | translate"
        formControlName="year"
      />
      <div ngxErrors="year">
        <div translate ngxError="pattern" showWhen="touched">
          validation_integers
        </div>
      </div>
    </adaptive-placeholder>

    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        [placeholder]="'entrance_code' | translate"
        formControlName="code"
      />
    </adaptive-placeholder>

    <app-textarea-auto-expanding
      [parentFormGroup]="form"
      parentFormControlName="propertyDescription"
      adaptivePlaceholder="property_description"
    >
    </app-textarea-auto-expanding>

    <div class="form-group btn-group residence-form">
      <input
        formControlName="fireplace"
        type="radio"
        id="have_fireplace"
        value="True"
      />
      <label class="btn btn-sm btn-first" [attr.for]="'have_fireplace'">{{
        "have_fireplace" | translate
      }}</label>

      <input
        formControlName="fireplace"
        type="radio"
        id="no_fireplace"
        value="False"
      />
      <label class="btn btn-sm" [attr.for]="'no_fireplace'">{{
        "no_fireplace" | translate
      }}</label>

      <input
        formControlName="fireplace"
        type="radio"
        id="unknown_fireplace"
        value="Unknown"
      />
      <label class="btn btn-sm" [attr.for]="'unknown_fireplace'">{{
        "unknown" | translate
      }}</label>
    </div>

    <div class="form-group btn-group residence-form">
      <input
        formControlName="balcony"
        type="radio"
        id="have_balcony"
        value="True"
      />
      <label class="btn btn-sm btn-first" [attr.for]="'have_balcony'">{{
        "have_balcony" | translate
      }}</label>

      <input
        formControlName="balcony"
        type="radio"
        id="no_balcony"
        value="False"
      />
      <label class="btn btn-sm" [attr.for]="'no_balcony'">{{
        "no_balcony" | translate
      }}</label>

      <input
        formControlName="balcony"
        type="radio"
        id="unknown_balcony"
        value="Unknown"
      />
      <label class="btn btn-sm" [attr.for]="'unknown_balcony'">{{
        "unknown" | translate
      }}</label>
    </div>

    <div class="form-group btn-group residence-form">
      <input
        formControlName="elevator"
        type="radio"
        id="have_elevator"
        value="True"
      />
      <label class="btn btn-sm btn-first" [attr.for]="'have_elevator'">{{
        "have_elevator" | translate
      }}</label>

      <input
        formControlName="elevator"
        type="radio"
        id="no_elevator"
        value="False"
      />
      <label class="btn btn-sm" [attr.for]="'no_elevator'">{{
        "no_elevator" | translate
      }}</label>

      <input
        formControlName="elevator"
        type="radio"
        id="unknown_elevator"
        value="Unknown"
      />
      <label class="btn btn-sm" [attr.for]="'unknown_elevator'">{{
        "unknown" | translate
      }}</label>
    </div>

    <div *ngIf="showSauna" class="form-group btn-group residence-form">
      <input
        formControlName="sauna"
        type="radio"
        id="have_sauna"
        value="True"
      />
      <label class="btn btn-sm btn-first" [attr.for]="'have_sauna'">{{
        "have_sauna" | translate
      }}</label>

      <input formControlName="sauna" type="radio" id="no_sauna" value="False" />
      <label class="btn btn-sm" [attr.for]="'no_sauna'">{{
        "no_sauna" | translate
      }}</label>

      <input
        formControlName="sauna"
        type="radio"
        id="unknown_sauna"
        value="Unknown"
      />
      <label class="btn btn-sm" [attr.for]="'unknown_sauna'">{{
        "unknown" | translate
      }}</label>
    </div>
  </section>
  <hr />
  <section>
    <h4>{{ "valuation" | translate }}</h4>
    <adaptive-placeholder>
      <input
        adaptivePlaceholder
        thousandSeparator
        [placeholder]="'price_valuation' | translate"
        formControlName="valuation"
      />
      <div ngxErrors="valuation">
        <div translate ngxError="pattern" showWhen="touched">
          validation_integers
        </div>
      </div>
    </adaptive-placeholder>
  </section>
</div>
