import { Component } from "@angular/core";

@Component({
  selector: "page-not-found",
  template: `
    <div class="flex-center full-height">
      <h2>{{ "page_not_found" | translate }}</h2>
    </div>
  `,
  styles: [
    `
      .full-height {
        height: 100%;
      }
    `,
  ],
})
export class PageNotFoundComponent {}
