import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import { getFeature } from "@app/shared/config/config.reducer";
import { AdfenixMarketingReportFeature } from "@app/shared/config/models/adfenix-marketing-report-feature";
import { ADFENIX_PROPERTY_MARKETING } from "@app/shared/config/utils/features";
import { SegmentedControl } from "@app/shared/modules/ui-components/q-segmented-controls/q-segmented-controls.component";
import { API_ONLY_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import {
  getAdfenixReportingDataTypeControls,
  ObjectPartitioned,
  ObjectPartitionedFilters,
  ObjectPartitionedResponse,
} from "@app/statistics/statistics-adfenix-reporting/models/object-partitioned";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  first,
  fromEvent as observableFromEvent,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { getEaOfficesIds, hasRole } from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";

@Component({
  selector: "app-statistics-adfenix-reporting-summary-widget",
  templateUrl: "./statistics-adfenix-reporting-summary-widget.component.html",
  styleUrls: ["./statistics-adfenix-reporting-summary-widget.component.scss"],
})
export class StatisticsAdfenixReportingSummaryWidgetComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() eaOfficeId: string;
  @Input() triggerDataFetch$: Observable<void>;
  @Input() showHeader: boolean = true;

  @ViewChild("filterContainer", { static: false })
  filterContainer: ElementRef;

  showSegment: boolean = true;
  form: FormGroup;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  values$: BehaviorSubject<ObjectPartitioned[]> = new BehaviorSubject<
    ObjectPartitioned[]
  >([]);
  adfenixMarketingFeature$: Observable<AdfenixMarketingReportFeature>;
  unsubscribe$ = new Subject<void>();
  dataTypeControls: SegmentedControl = [];
  typesControl: DropdownItem[];

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private store: Store<AppState>
  ) {
    this.adfenixMarketingFeature$ = this.store.pipe(
      select(getFeature(ADFENIX_PROPERTY_MARKETING))
    );
    this.dataTypeControls = getAdfenixReportingDataTypeControls();
    this.typesControl = this.dataTypeControls.map((item) => ({
      display: item.label,
      value: item.value,
    }));
    this.buildForm();
  }

  ngOnInit(): void {
    combineLatest([
      this.store.pipe(select(hasRole(ROLE_ADMIN))),
      this.store.pipe(select(getEaOfficesIds)),
    ])
      .pipe(first())
      .subscribe(([isAdmin, eaOfficeIds]) => {
        if (!isAdmin) {
          this.eaOfficeId = eaOfficeIds.join(",");
          this.form
            .get("eaOfficeId")
            .setValue(eaOfficeIds, { emitEvent: false });
        }

        this.fetchStatistics(this.form.value);
      });

    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
      .subscribe((filters) => this.fetchStatistics(filters));

    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fetchStatistics(this.form.value);
      });

    observableFromEvent(window, "resize")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showSegment = !(
          this.filterContainer?.nativeElement?.offsetWidth < 450 &&
          this.dataTypeControls.length > 2
        );
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.filterContainer?.nativeElement?.offsetWidth < 450 &&
        this.dataTypeControls.length > 2
      ) {
        this.showSegment = false;
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOfficeId: [],
      objectTypes: [],
      startDate: [moment().subtract(12, "months").toDate()],
      endDate: [moment().endOf("month").toDate()],
      excludeNewProduction: [],
      groupBy: ["campaignGroup"],
      campaignNameAlias: [],
      campaignGroup: [],
      dataTypes: ["numberOfPotentialBuyers"],
    });
  }

  private fetchStatistics(params?: Partial<ObjectPartitionedFilters>) {
    this.loading$.next(true);
    this.adfenixMarketingFeature$
      .pipe(
        first(),
        switchMap((feature) => {
          // WARNING: the campaignGroup filter is very unstable and should be temporary. We need the fetch the supported campaignGroups, or have typed.
          return this.apiService.post(
            "stats/object-partitioned",
            this.formatFilters({
              ...params,
              campaignGroup: !!feature.campaignGroup
                ? feature.campaignGroup.split(",")
                : null,
            })
          );
        })
      )
      .subscribe({
        next: (res: ObjectPartitionedResponse) => {
          const values = [];
          this.values$.next(values.concat(res.values[0].values));
          this.loading$.next(false);
        },
        error: () => {
          this.loading$.next(false);
        },
      });
  }

  private formatFilters(params): ObjectPartitionedFilters {
    return {
      ...params,
      startDate: !!params.startDate
        ? moment(params.startDate).format(API_ONLY_DATE_FORMAT)
        : null,
      endDate: !!params.endDate
        ? moment(params.endDate).format(API_ONLY_DATE_FORMAT)
        : null,
      dataTypes: [params.dataTypes],
      eaOfficeId:
        !!params.eaOfficeId && params.eaOfficeId.length > 0
          ? params.eaOfficeId
          : null,
    };
  }
}
