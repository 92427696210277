import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as fromCallingList from "@app/calling-lists/ngrx/calling-lists.reducer";
import * as contactActions from "@app/contacts/contact.actions";
import { getCallListMode } from "@app/contacts/contact.selectors";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { CallingListContact } from "@app/lists/lists-calling-lists/models/calling-list";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
} from "rxjs";

@Injectable()
export class ContactGuard implements CanDeactivate<any> {
  constructor(private store: Store<AppState>, private location: Location) {}

  canDeactivate(
    _component: any,
    _currentRoute: ActivatedRouteSnapshot,
    _currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> {
    return combineLatest([
      this.store.pipe(select(getCallListMode)),
      this.store.pipe(select(fromCallingList.getNotContactedContacts)),
    ]).pipe(
      map(([callListMode, callingListContacts]) => ({
        callListMode,
        callingListContacts,
      })),
      switchMap(
        (data: {
          callListMode: boolean;
          callingListContacts: CallingListContact[];
        }) => {
          if (
            this.allowNavigation(
              data.callingListContacts,
              nextState.url,
              data.callListMode
            )
          ) {
            return of(true);
          } else {
            this.store.dispatch(
              confirmActions.show({
                header: "leaving_call_queue_header",
                message: "leaving_call_queue_message",
                newTabUrl: this.location.prepareExternalUrl(nextState.url),
              })
            );
            return this.store.pipe(select(confirmState)).pipe(
              filter((state) => !!state),
              first(),
              map((state) => {
                this.store.dispatch(contactActions.deactivateCallListMode());
                return state;
              })
            );
          }
        }
      )
    );
  }

  allowNavigation(
    contacts: any,
    newUrl: string,
    callListMode: boolean
  ): boolean {
    return (
      !callListMode ||
      contacts.find((contact) => newUrl.includes(contact.contactId)) ||
      newUrl.includes("calling-lists")
    );
  }
}
