<div class="country-code-selector">
  <div class="btn-group" dropdown (onHidden)="form.reset()" [autoClose]="false">
    <button dropdownToggle type="button" class="country-code-selector__button">
      <span class="flag-icon flag-icon-{{ iso }}"></span>
      <i class="caret"></i>
    </button>

    <ul *dropdownMenu class="dropdown-menu">
      <form [formGroup]="form">
        <input
          formControlName="query"
          placeholder="{{ 'select_country_code' | translate }}"
        />
      </form>

      <div *ngIf="filteredList$ | async as filteredList" class="scroll-view">
        <li
          class="menu-item"
          *ngFor="let country of filteredList"
          (click)="onItemSelected(country)"
        >
          <span *ngIf="showCode" class="country-code text-muted"
            >+{{ country.countryCode }}</span
          >
          <span class="country-name">{{ country.countryName }}</span>
        </li>
      </div>
    </ul>
  </div>
</div>
