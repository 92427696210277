import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "list-item-empty",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./list-item-empty.component.scss",
  ],
  template: `
    <li class="list__item--empty">
      <div class="flex-center">
        <ng-content></ng-content>
      </div>
    </li>
  `,
})
export class ListItemEmptyComponent {}
