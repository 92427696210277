<h3 *ngIf="showHeader">
  <app-icon size="large" name="chart-line"></app-icon>&nbsp;
  <span>
    {{ "kpi" | translate }}
  </span>
</h3>
<div class="widget-container">
  <div class="widget-container--content">
    <ng-container *ngIf="isLoading$ | async; else ShortcutsContainer">
      <div class="shortcuts-container">
        <spinner *ngIf="isLoading$ | async" [size]="'1.5rem'"></spinner>
      </div>
    </ng-container>

    <ng-template #ShortcutsContainer>
      <ng-container *ngIf="shortcuts$ | async as shortcuts">
        <div class="shortcuts-container">
          <ng-container *ngFor="let shortcut of shortcuts">
            <a
              *ngIf="shortcut?.visible"
              matTooltip="{{ shortcut?.tooltip | translate }}"
              matTooltipClass="q-tooltip"
              class="shortcut-link"
              style="--shortcut-icon-color: {{ shortcut?.color || 'black' }}"
              (click)="handleClick(shortcut, $event)"
            >
              <span class="icon-holder">
                <app-icon
                  *ngIf="shortcut.icon"
                  [name]="shortcut.icon"
                  type="light"
                  size="medium"
                ></app-icon>
                <span *ngIf="shortcut?.subIcon" class="sub-icon-holder">
                  <app-icon
                    [name]="shortcut?.subIcon"
                    type="light"
                    size="x-small"
                  ></app-icon>
                </span>
              </span>
            </a>
          </ng-container>
        </div>
        <div *ngIf="shortcuts.length > 0" class="edit-button-container">
          <a
            matTooltip="{{ 'edit' | translate }}"
            matTooltipClass="q-tooltip"
            class="shortcut-link"
            (click)="handleEdit($event)"
          >
            <app-icon name="pencil" size="medium"></app-icon>
          </a>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
