import * as listsSurveyActions from "@app/lists/lists-surveys/ngrx/lists-surveys.actions";
import { PaginationListDTO } from "@app/models";
import { SurveyResponse } from "@app/models/survey";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface SurveyFilters {
  totalResponses: string;
  eaCrmSurveyId: string;
  insertedDate: string;
  name: string;
}

export interface ListsSurveysState extends PaginationListDTO {
  loading: boolean;
  loaded: boolean;
  rows: SurveyResponse[];
  surveyFilters: SurveyFilters[];
}

export const initialState: ListsSurveysState = {
  loaded: false,
  loading: false,
  rows: [],
  offset: 0,
  limit: 10,
  total: 0,
  surveyFilters: [],
};

export function listsSurveysReducer(
  state: ListsSurveysState,
  action: Action
): ListsSurveysState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(listsSurveyActions.getSurveysRequest, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(
    listsSurveyActions.getSurveysSuccess,
    (state, { surveys: { limit, offset, total, rows } }) => ({
      ...state,
      loading: false,
      loaded: true,
      rows,
      total,
      offset,
      limit,
    })
  ),
  on(listsSurveyActions.getSurveysFailed, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(listsSurveyActions.getMoreSurveysRequest, (state) => ({
    ...state,
    loading: true,
    loaded: false,
  })),
  on(
    listsSurveyActions.getMoreSurveysSuccess,
    (state, { surveys: { limit, offset, total, rows } }) => ({
      ...state,
      loading: false,
      loaded: true,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    })
  ),
  on(listsSurveyActions.getMoreSurveysFailed, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(listsSurveyActions.getSurveyFiltersRequest, (state) => ({
    ...state,
  })),
  on(
    listsSurveyActions.getSurveyFiltersSuccess,
    (state, { surveyFilters }) => ({
      ...state,
      surveyFilters,
    })
  ),
  on(listsSurveyActions.getSurveyFiltersFailed, (state) => ({
    ...state,
  })),
  on(listsSurveyActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.surveys
);

export const getListsLoaded = createSelector(
  getState,
  (state: ListsSurveysState) => state.loaded
);

export const getListsLoading = createSelector(
  getState,
  (state: ListsSurveysState) => state.loading
);

export const getListsRows = createSelector(
  getState,
  (state: ListsSurveysState) => state.rows
);

export const getListsTotal = createSelector(
  getState,
  (state: ListsSurveysState) => state.total
);

export const getListsLimit = createSelector(
  getState,
  (state: ListsSurveysState) => state.limit
);

export const getSurveyFilters = createSelector(
  getState,
  (state: ListsSurveysState) => state.surveyFilters
);
