<section>
  <h4 translate>chosen_contact</h4>
  <app-mobile-card
    [enableActionDropdown]="true"
    [showCross]="true"
    *ngIf="contact$ | async as contact"
    (click)="editContact()"
    (remove)="goBack()"
  >
    <ng-container title>
      {{ contact.firstName }} {{ contact.familyName }}
    </ng-container>
    <div metadata class="contact-card__metadata">
      <div *ngIf="contact.street" class="contact-card__detail">
        <app-icon name="map-marker-alt" size="x-small"></app-icon>
        {{ contact.street }}
      </div>
      <div
        *ngIf="contact.msisdn && contact.msisdnCountry"
        class="contact-card__detail"
      >
        <app-icon name="phone-alt" size="x-small"></app-icon>
        {{ contact.msisdn | phoneFormat : contact.msisdnCountry }}
      </div>
      <div *ngIf="contact.email" class="contact-card__detail">
        <app-icon name="envelope" size="x-small"></app-icon>
        {{ contact.email }}
      </div>
    </div>
    <ng-container buttons>
      <li role="menuitem">
        <button type="button" class="btn btn-secondary" (click)="editContact()">
          <app-icon name="pencil"></app-icon>
          {{ "edit" | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="goBack()">
          <app-icon name="trash"></app-icon>
          {{ "remove" | translate }}
        </button>
      </li>
    </ng-container>
  </app-mobile-card>
</section>
<hr />
<form [formGroup]="form" (ngSubmit)="submit()">
  <section>
    <div class="residence__header">
      <h4>
        <span class="residence__icon">
          <app-icon name="home-lg-alt"></app-icon>
        </span>
        <span class="residence__title">
          {{
            ((selectedResidence$ | async) ? "chosen_residence" : "residence")
              | translate
          }}
        </span>
      </h4>
      <button
        type="button"
        class="residence__button"
        (click)="editResidences()"
        *ngIf="!(selectedResidence$ | async)"
      >
        {{ "create_or_search" | translate }}
      </button>
    </div>
    <div
      class="alert alert-info"
      *ngIf="
        (meetingHistory$ | async) &&
        countUniqueBrokers(meetingHistory$ | async) as numberOfBrokers
      "
    >
      <span class="text-bold">{{ "obs" | translate }}!</span
      ><span class="text-orange"> {{ numberOfBrokers }}</span>
      {{
        "meeting_history_warning"
          | translate
            : {
                colleagues:
                  (numberOfBrokers > 1 ? "colleagues" : "colleague")
                  | translate
                  | lowercase,
                have: (numberOfBrokers > 1 ? "have" : "has") | translate
              }
      }}
      <button
        class="expand-btn"
        type="button"
        (click)="toggleMeetingHistoryList()"
      >
        {{ (showMeetingHistoryList ? "hide" : "show") | translate }}
      </button>
      <ol class="meeting-history-list" *ngIf="showMeetingHistoryList">
        <li *ngFor="let meeting of meetingHistory$ | async">
          {{ meeting.insertedDate }}:
          <span class="text-orange">{{ meeting.employeeFullName }}</span>
        </li>
      </ol>
    </div>
    <div *ngIf="!(selectedResidence$ | async)" class="form-group">
      <ng-container
        *ngIf="
          (salesMeetingFeature$ | async)?.force_full_address_for_sales_meeting
        "
      >
        <span class="first-letter-capital" translate>please</span>&nbsp;
        <button
          type="button"
          class="residence__button"
          (click)="editResidences()"
        >
          2 {{ "create_residence" | translate }}
        </button>
        <input
          formControlName="street"
          style="visibility: collapse; width: 5px"
        />
      </ng-container>
      <ng-container
        *ngIf="
          !(salesMeetingFeature$ | async)?.force_full_address_for_sales_meeting
        "
      >
        <search-address-adv
          type="street"
          [containerFormControl]="form.get('fullAddress')"
          [enabled]="(addressSuggesterFeature$ | async)?.enabled"
          [provider]="(addressSuggesterFeature$ | async)?.provider"
          [countryCode]="countryCode$ | async"
          [showFullAddress]="true"
          [placeholder]="'or_state_street' | translate"
          [invalid]="form.get('fullAddress').invalid"
          (onSelected)="handleStreetSelected($event)"
          (blur)="getMeetingHistory()"
        ></search-address-adv>
      </ng-container>
      <div ngxErrors="street">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
      <div *ngIf="(residences$ | async)?.length > 0" class="residences">
        <span translate>suggestions</span>:
        <ng-container
          *ngIf="
            !(residenceSidebarConfig$ | async)?.showSalesObjectSelection;
            else mergedLists
          "
        >
          <span *ngFor="let r of residences$ | async; let last = last">
            <span (click)="patchAddress(r)" class="street">
              {{ r | formatAddress }} </span
            ><span *ngIf="!last">, </span>
          </span>
        </ng-container>
        <ng-template #mergedLists>
          <span *ngFor="let r of suggestionsList; let last = last">
            <span (click)="patchAddress(r)" class="street">
              {{ r | formatAddress }} </span
            ><span *ngIf="!last">, </span>
          </span>
        </ng-template>
      </div>
    </div>

    <app-mobile-card
      [enableActionDropdown]="true"
      [showCross]="true"
      *ngIf="selectedResidence$ | async as residence"
      (click)="editResidences()"
      (remove)="onResidenceRemove()"
    >
      <ng-container title>{{ residence.street }}</ng-container>
      <div metadata *ngIf="getLocationDetails([residence.zip, residence.city])">
        <app-icon name="map-marker-alt" size="x-small"></app-icon>
        {{ getLocationDetails([residence.zip, residence.city]) }}
      </div>
      <ng-container buttons>
        <li role="menuitem">
          <button
            type="button"
            class="btn btn-secondary"
            (click)="editResidences()"
          >
            <app-icon name="pencil"></app-icon>
            {{ "edit" | translate }}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            (click)="onResidenceRemove()"
          >
            <app-icon name="trash"></app-icon>
            {{ "remove" | translate }}
          </button>
        </li>
      </ng-container>
    </app-mobile-card>
  </section>
  <hr />

  <section>
    <div class="flex-container flex-space-between">
      <h4 translate>time</h4>
      <div class="section-label">
        <calendar-widget-modal [date]="dateToday"></calendar-widget-modal>
      </div>
    </div>

    <div class="form-group">
      <form-input-datepicker-dropdown
        [group]="form"
        [ngClass]="
          !form.get('date').valid && form.get('date').touched
            ? 'validation-input'
            : ''
        "
      >
      </form-input-datepicker-dropdown>
      <div ngxErrors="date">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
      <div ngxErrors="date">
        <div translate ngxError="pattern" showWhen="touched">
          validation_date_pattern
        </div>
      </div>
    </div>

    <div class="form-group">
      <label translate>start_time</label>
      <time-picker-input
        #time
        (click)="time.select()"
        formControlName="time"
        start="07:00"
        end="22:00"
        [ngClass]="
          (form.get('time').invalid || form.errors?.['timeRange']) && form.get('time').touched
            ? 'validation-input'
            : ''
        "
      >
      </time-picker-input>
      <div ngxErrors="time">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
      <div ngxErrors="time">
        <div translate ngxError="pattern" showWhen="touched">
          wrong_time_format
        </div>
      </div>
    </div>

    <div class="form-group">
      <label translate>end_time</label>
      <time-picker-input
        #endTime
        (click)="endTime.select()"
        formControlName="endTime"
        start="07:00"
        end="22:00"
        [ngClass]="
          (form.get('endTime').invalid || form.errors?.['timeRange']) && form.get('endTime').touched
            ? 'validation-input'
            : ''
        "
      >
      </time-picker-input>
      <div ngxErrors="endTime">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
      <div ngxErrors="endTime">
        <div translate ngxError="pattern" showWhen="touched">
          wrong_time_format
        </div>
      </div>
      <div
        *ngIf="form.errors?.['timeRange'] && (form.touched || form.dirty)"
        ngxerror
      >
        {{ "range_mismatch" | translate }}
      </div>
    </div>
  </section>
  <hr />

  <section>
    <h4 translate>broker_connection</h4>
    <office-employee-dropdown
      [parentFormGroup]="form"
      [setDefaults]="!editMode"
      (employeesChange)="onEmployeeChange($event)"
      (onReady)="onBrokerConnectionReady()"
    ></office-employee-dropdown>
  </section>
  <hr />

  <ng-container *ngIf="(contactSourceFeature$ | async)?.enabled">
    <section>
      <h4 translate>sales_meeting_source</h4>
      <div class="form-group">
        <select
          formControlName="source"
          [ngClass]="
            !form.get('source').valid && form.get('source').touched
              ? 'validation-input'
              : ''
          "
        >
          <option translate disabled value="">select_source</option>
          <optgroup label="{{ 'source_suggestions_system' | translate }}">
            <option
              *ngFor="let source of systemSources$ | async"
              [ngValue]="source"
            >
              {{ source.eaTaskTypeName }} - {{ "created" | translate }}
              {{ getSourceDateFormatted(source.insertedDate) }}
            </option>
          </optgroup>
          <optgroup label="{{ 'source_suggestions_other' | translate }}">
            <option
              *ngFor="let source of otherSources$ | async"
              [ngValue]="source"
            >
              {{ source.typeName }}
            </option>
          </optgroup>
        </select>
        <div ngxErrors="source">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>
    </section>
    <hr />
  </ng-container>

  <ng-container *ngIf="(salesMeetingFeature$ | async)?.batch_setting_id">
    <section>
      <header class="outgoing-message__wrapper">
        <h4 translate>outgoing_messages</h4>
        <div
          class="alert alert-info alert--sm"
          *ngIf="getBatchDisabled()"
          translate
        >
          batches_disabled_message
        </div>
      </header>
      <div formArrayName="batches">
        <div
          *ngFor="let batch of batches.controls; let i = index"
          class="batch"
          [tooltip]="
            (batch.disabled ? getBatchSettingMessages(i) : null) | translate
          "
        >
          <label class="checkbox-group">
            <span [class.text-muted]="batch.disabled">
              {{ batchSettings[i].name }}
            </span>
            <input type="checkbox" [formControl]="batch" />
            <span class="checkbox-group__checkmark"></span>
          </label>
          <i
            (click)="!batch.disabled ? previewBatch(i) : null"
            [style.cursor]="!batch.disabled ? 'pointer' : 'auto'"
            class="icon-eye preview"
          ></i>
        </div>
      </div>
      <div
        class="alert alert-info alert--sm"
        *ngIf="meetingIsEarly()"
        translate
      >
        early_mailing_message
      </div>
    </section>
    <hr />
  </ng-container>

  <section>
    <div class="form-group">
      <textarea
        formControlName="comment"
        rows="8"
        placeholder="{{ 'take_note' | translate }}"
      >
      </textarea>
    </div>
    <small translate class="text-muted">sales_meeting_comment_helper</small>
  </section>
</form>
