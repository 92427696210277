import { Directive, forwardRef } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";

export function validateEmail() {
  return (c: FormControl) => {
    const EMAIL_REGEXP =
      /^($|[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]*)*?\.[a-zA-Z]+$)/i;

    return EMAIL_REGEXP.test(c.value)
      ? null
      : {
          validateEmail: {
            valid: false,
          },
        };
  };
}

@Directive({
  selector: "[validateEmail][ngModel],[validateEmail][formControl]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EmailValidatorDirective),
      multi: true,
    },
  ],
})
export class EmailValidatorDirective implements Validator {
  validator: Function;

  constructor() {
    this.validator = validateEmail();
  }

  validate(c: FormControl) {
    return this.validator(c);
  }
}
