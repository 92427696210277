import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Competition } from "@app/competitions/models/competition.model";
import * as competitionsActions from "@app/competitions/ngrx/competitions.actions";
import * as fromCompetitions from "@app/competitions/ngrx/competitions.reducer";
import * as competitionKpiTypes from "@app/competitions/utils/competition-kpi-types";
import * as competitionTypes from "@app/competitions/utils/competition-types";
import { Office } from "@app/models";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as formModes from "@app/shared/utils/form-modes";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { EDIT_COMPETITION } from "@app/shared/utils/tab-types";
import { CompetitionFormComponent } from "@app/sidebar/competitions/competition-form/competition-form.component";
import * as competitionsSidebarActions from "@app/sidebar/competitions/ngrx/competitions.actions";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";
import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { ConnectableTab } from "../../sidebar-connectable-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";

interface DataForForm {
  offices: Office[];
  canEditOffice: boolean;
  competition: Competition;
}

@Component({
  selector: "edit-competition",
  templateUrl: "./edit-competition.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./edit-competition.component.scss",
  ],
})
export class EditCompetitionComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(CompetitionFormComponent, { static: false })
  competitionForm: CompetitionFormComponent;
  tab$: Observable<SidebarTab>;
  proxy$ = new Subject<any>();
  tabType = EDIT_COMPETITION;
  unsubscribe$ = new Subject<void>();
  dataForForm$: Observable<DataForForm>;
  competitionFormModes = formModes;
  kpiTypes = [
    competitionKpiTypes.KPI_BOOKED_MEETING,
    competitionKpiTypes.KPI_SALES_CALLS,
    competitionKpiTypes.KPI_SOLD_OBJECTS,
    competitionKpiTypes.KPI_WON_MEETING,
    competitionKpiTypes.KPI_SENT_TIPS,
    competitionKpiTypes.KPI_EXTERNAL_TIPS,
  ];

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getCompetition();
    this.connectTab();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.dataForForm$ = observableCombineLatest([
      this.store.pipe(select(fromShared.getOffices)),
      this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN))),
      this.store.pipe(
        select(fromCompetitions.getSelectedCompetition),
        filter((value) => !!value)
      ),
    ]).pipe(
      map(([offices, canEditOffice, competition]) => ({
        offices,
        canEditOffice,
        competition,
      }))
    );
  }

  connectTab(): void {
    observableCombineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  getCompetition(): void {
    this.route.params
      .pipe(
        map((params) => params?.id),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((competitionId: string) => {
        this.store.dispatch(
          competitionsActions.getCompetitionRequest({ competitionId })
        );
      });
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  onSubmit(): void {
    const updatedCompetition: Competition = this.formatCompetition();
    this.store.dispatch(
      competitionsSidebarActions.updateCompetitionRequest({
        competition: updatedCompetition,
      })
    );
    this.closeTab();
  }

  formatCompetition(): Competition {
    const updatedCompetition: Competition = {
      competitionId: this.competitionForm.competition.competitionId,
      title: this.competitionForm.competitionForm.get("title").value,
      startDate: moment(
        this.competitionForm.competitionForm.get("dateRange").value[0]
      )
        .startOf("day")
        .format(API_DATE_FORMAT),
      endDate: moment(
        this.competitionForm.competitionForm.get("dateRange").value[1]
      )
        .endOf("day")
        .format(API_DATE_FORMAT),
      kpiType: this.competitionForm.competitionForm.get("kpiType").value,
    };

    if (this.competitionForm.competitionForm.get("office").value) {
      updatedCompetition.eaOfficeId =
        this.competitionForm.competitionForm.get("office").value;
      updatedCompetition.competitionType = competitionTypes.OFFICE;
    } else {
      updatedCompetition.competitionType = competitionTypes.CUSTOMER;
    }

    return updatedCompetition;
  }
}
