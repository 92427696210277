import * as listSentLeadsActions from "@app/lists/lists-sent-leads/ngrx/lists-sent-leads.actions";
import { API_SENT_LEADS_DEFAULT_LIMIT } from "@app/lists/lists-sent-leads/utils/lists-sent-leads-defaults";
import { PaginationListDTO, Task, TaskType } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface GetSentLeadsParams {
  taskTypeId: string;
  originEaEmployeeId?: string;
  originEaOfficeId: string;
  resolved?: string;
  taskTypeCategory?: string;
}

export interface ListsSentLeadsState extends PaginationListDTO {
  loading: boolean;
  loaded: boolean;
  rows: Task[];
  types: TaskType[];
}

export const initialState: ListsSentLeadsState = {
  loaded: false,
  loading: false,
  limit: API_SENT_LEADS_DEFAULT_LIMIT,
  offset: 0,
  total: 0,
  rows: [],
  types: [],
};

export function listsSentLeadsReducer(
  state: ListsSentLeadsState,
  action: Action
): ListsSentLeadsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(listSentLeadsActions.getSentLeadsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    listSentLeadsActions.getSentLeadsSuccess,
    (state, { sentLeads: { rows, limit, offset, total } }) => ({
      ...state,
      loading: false,
      loaded: true,
      limit,
      offset,
      total,
      rows,
    })
  ),
  on(listSentLeadsActions.getSentLeadsFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(listSentLeadsActions.getMoreSentLeadsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    listSentLeadsActions.getMoreSentLeadsSuccess,
    (state, { sentLeads: { rows, limit, offset, total } }) => ({
      ...state,
      loading: false,
      loaded: true,
      limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    })
  ),
  on(listSentLeadsActions.getMoreSentLeadsFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(listSentLeadsActions.getTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    types: taskTypes,
  })),
  on(
    listSentLeadsActions.getTaskTypesRequest,
    listSentLeadsActions.getTaskTypesFailed,
    (state) => ({ ...state })
  ),
  on(listSentLeadsActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.sentLeads
);

export const getSentLeadsLoading = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.loading
);

export const getSentLeads = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.rows
);

export const getSentLeadsLimit = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.limit
);

export const getSentLeadsTotal = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.total
);

export const showSentLeadsLoadMore = createSelector(
  getState,
  (state: ListsSentLeadsState) =>
    state.loaded && state.rows.length !== state.total
);

export const getSentLeadsLeftToLoad = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.total - state.rows.length
);

export const getSentLeadsTypes = createSelector(
  getState,
  (state: ListsSentLeadsState) => state.types
);
