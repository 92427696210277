import { Observable, of as observableOf } from "rxjs";
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Contact } from "../models";

@Injectable()
export class LoginResolverService implements Resolve<Observable<Contact>> {
  resolve(): Observable<any> {
    return observableOf(true);
  }
}
