import {
  TaskList,
  TaskListConfig,
} from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { createAction, props } from "@ngrx/store";
import moment from "moment";

export const getUpcoming = (
  offset: number,
  eaEmployeeId: string,
  limit?: number
) => {
  const filters: Partial<TaskList> = {
    limit: limit ? limit : 10,
    offset: offset,
    eaEmployeeId: eaEmployeeId,
    taskTypeCategory: "lead,task",
    resolved: false,
    sortBy: "deliveryDate",
    sortOrder: "asc",
    deliveryFrom: moment()
      .add(1, "days")
      .startOf("day")
      .format(API_DATE_FORMAT),
    deliveryTo: moment().add(13, "days").endOf("day").format(API_DATE_FORMAT),
  };

  return getUpcomingRequest({ filters });
};

export const getUpcomingRequest = createAction(
  "[UpcomingTasksList] GET_UPCOMING_REQUEST",
  props<{ filters: Partial<TaskList> }>()
);

export const getUpcomingSuccessWithDefalut = (
  tasks: Task[],
  listStart: number,
  totalRows: number,
  replace: boolean = false
) => {
  return getUpcomingSuccess({ tasks, listStart, totalRows, replace });
};

export const getUpcomingSuccess = createAction(
  "[UpcomingTasksList] GET_UPCOMING_SUCCESS",
  props<{
    tasks: Task[];
    listStart: number;
    totalRows: number;
    replace?: boolean;
  }>()
);

export const getUpcomingFail = createAction(
  "[UpcomingTasksList] GET_UPCOMING_FAIL"
);

export const getUpcomingNoTasksFound = createAction(
  "[UpcomingTasksList] GET_UPCOMING_NO_TASKS_FOUND"
);

export const setListConfig = createAction(
  "[UpcomingTasksList] SET_LIST_CONFIG",
  props<{ taskListConfig: TaskListConfig }>()
);

export const reset = createAction("[UpcomingTasksList] RESET");
