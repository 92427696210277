import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import * as widgetActions from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.actions";
import { ObjectStatusStatistics } from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.reducer";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsObjectStatusWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("stats/object-status/search", params).pipe(
          map(
            (response: { objectStatusStatistics: ObjectStatusStatistics[] }) =>
              widgetActions.fetchStatisticsSuccess({
                stats: response.objectStatusStatistics,
              })
          ),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );
}
