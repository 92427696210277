import {
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  of as observableOf,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, QObject } from "@app/models";
import { SHOWINGS_COLOR } from "@app/shared/utils/colors";
import * as formUtils from "@app/shared/utils/form-utils";
import { SIDEBAR_CREATE_LEAD_BASE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_LEAD } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { SidebarTab } from "../../models/sidebar-tab";
import {
  closeTab,
  setInitialTabValue,
  setTabValue,
} from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import * as createLeadActions from "../ngrx/create-lead.actions";
import {
  getOfficeObjects,
  getOfficeObjectsLoading,
  getProcessing,
  getSelectedContact,
  getTaskTemplates,
  TaskTemplate,
} from "../ngrx/create-lead.reducer";

@Component({
  selector: "create-lead-edit",
  templateUrl: "./create-lead-new.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-lead-new.component.scss",
  ],
})
export class CreateLeadNewComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  selectedContact$: Observable<Contact>;
  taskTemplates$: Observable<TaskTemplate[]>;
  processing$: Observable<boolean>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  objectsLoading$: Observable<boolean>;

  recipientEmployeesChanged$ = new Subject<void>();
  senderOnReady$ = new Subject<void>();
  dataSource: any[];
  propagateChange: (value: any) => void;

  tab$: Observable<SidebarTab>;

  form: FormGroup;

  residenceColor = SHOWINGS_COLOR;

  tabType = CREATE_LEAD;

  unSubscribe$ = new Subject<void>();

  private _selectedObject: QObject;

  get selectedObject(): QObject {
    return this._selectedObject;
  }

  set selectedObject(value: QObject) {
    if (value !== undefined) {
      this._selectedObject = value;
      if (this.propagateChange) {
        this.propagateChange(value);
      }
    }
  }

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private phoneNumberService: PhoneNumberService
  ) {}

  ngOnInit(): void {
    this.initStoreObservers();

    this.initFormAndEvents();
    this.initParamsEvents();
    this.initSelectedContactEvents();
    this.store.dispatch(
      createLeadActions.getTaskTemplatesRequest({
        params: {},
      })
    );
  }

  ngAfterViewInit() {
    this.senderOnReady$
      .pipe(
        switchMap(() => this.tab$.pipe(first())),
        first()
      )
      .subscribe((tab) => {
        if (tab.dirty) {
          this.form.setValue(tab.currentValue);
          this.startContactChangesStream(1);
        } else {
          this.startContactChangesStream();
        }
        this.startValueChangesStream();
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();

    this.store.dispatch(createLeadActions.reset());
  }

  initStoreObservers(): void {
    this.selectedContact$ = this.store.pipe(select(getSelectedContact));
    this.processing$ = this.store.pipe(select(getProcessing));
    this.taskTemplates$ = this.store.pipe(select(getTaskTemplates));
    this.objectsLoading$ = this.store.pipe(select(getOfficeObjectsLoading));
    this.store
      .pipe(select(getOfficeObjects))
      .subscribe((objects: QObject[]) => (this.dataSource = objects));
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));
    this.eaEmployeeId$ = this.store.select(
      (state) => state.user.employee.eaEmployeeId
    );
    this.eaOfficeId$ = this.store.select(
      (state) => state.user.office.eaOfficeId
    );
    this.eaOfficeId$.subscribe((eaOfficeId) =>
      this.store.dispatch(
        createLeadActions.getOfficeObjectForSaleRequest({
          params: { eaOfficeId: eaOfficeId },
        })
      )
    );
  }

  initFormAndEvents(): void {
    this.form = this.formBuilder.group({
      comment: ["", [Validators.required]],
      title: ["", [Validators.required]],
      taskTemplate: ["", [Validators.required]],
      eaOid: [""],
      recipient: this.formBuilder.group({
        employee: "",
        office: ["", [Validators.required]],
      }),
    });

    this.processing$
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((processing: boolean) => {
        if (processing) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }

  initParamsEvents(): void {
    this.route.params
      .pipe(
        filter((params: Params) => params.hasOwnProperty("id")),
        takeUntil(this.unSubscribe$),
        switchMap((params: Params) =>
          this.selectedContact$.pipe(
            first(),
            filter(
              (contact: Contact) =>
                !contact || contact.contactId !== params["id"]
            ),
            map(() => params["id"])
          )
        )
      )
      .subscribe((id: string) =>
        this.store.dispatch(createLeadActions.getContactRequest({ id }))
      );
  }

  handleSelect(event: any) {
    this.selectedObject = event.item;
    this.form.get("eaOid").setValue(this.selectedObject.eaOid);
  }

  onObjectRemove() {
    this.selectedObject = null;
    this.form.get("eaOid").setValue("");
  }

  initSelectedContactEvents(): void {
    this.selectedContact$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((value) => !!value),
        switchMap((contact: Contact) => observableOf(contact.getFullName()))
      )
      .subscribe((title: string) => this.form.get("title").setValue(title));
  }

  startContactChangesStream(skipNumber = 0) {
    this.selectedContact$
      .pipe(
        filter((value) => !!value),
        takeUntil(this.unSubscribe$),
        skip(skipNumber)
      )
      .subscribe(() =>
        this.store.dispatch(
          setInitialTabValue({ tabType: this.tabType, value: this.form.value })
        )
      );
  }

  startValueChangesStream() {
    this.form.valueChanges
      .pipe(takeUntil(this.unSubscribe$), debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          setTabValue({ tabType: this.tabType, value: formValues })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  send(formData: FormData): void {
    if (this.form.valid) {
      observableCombineLatest([
        this.selectedContact$,
        this.eaEmployeeId$,
        this.eaOfficeId$,
      ])
        .pipe(
          map(([contact, eaEmployeeId, eaOfficeId]) => {
            const data: CreateLeadParams = {
              eaTaskTemplateId: formData.taskTemplate,
              eaOfficeId: formData.recipient.office,
              contactId: contact.contactId,
              title: formData.title,
              description: formData.comment,
              originEaEmployeeId: eaEmployeeId,
              originEaOfficeId: eaOfficeId,
              eaOid: formData.eaOid,
            };

            if (formData.recipient.employee) {
              data.eaEmployeeId = formData.recipient.employee;
            }

            return data;
          }),
          first()
        )
        .subscribe((params) => {
          this.store.dispatch(createLeadActions.createLeadRequest({ params }));
        });
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  formatNumber(number: string, countryCode: string) {
    return this.phoneNumberService.format(number, countryCode);
  }

  goBack(): void {
    this.store.dispatch(createLeadActions.reset());
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm", { outlets: { sidebar: SIDEBAR_CREATE_LEAD_BASE_URL } }],
      })
    );
  }

  senderOnReady(): void {
    this.senderOnReady$.next();
  }

  recipientEmployeesChanged(): void {
    this.recipientEmployeesChanged$.next();
  }
}

interface FormData {
  taskTemplate: number;
  title: string;
  sender: EmployeeGroup;
  recipient: EmployeeGroup;
  comment: string;
  eaOid: string;
}

interface EmployeeGroup {
  employee: string;
  office: string;
}

export interface CreateLeadParams {
  eaTaskTemplateId: number;
  eaOfficeId: string;
  eaEmployeeId?: string;
  contactId: string;
  title: string;
  description: string;
  originEaEmployeeId: string;
  originEaOfficeId: string;
  eaOid: string;
}
