import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as externalProviders from "@app/shared/utils/external-providers";
import { select, Store } from "@ngrx/store";
import { combineLatest, filter, map, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

export type RelationShipTypes =
  | "companySignatory"
  | "companyContact"
  | "married"
  | "parent"
  | "child"
  | "estateContact"
  | "others"
  | "estatePrimaryContact";

@Injectable({ providedIn: "root" })
export class RelationshipTypeService {
  isKiviCustomer$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {
    this.isKiviCustomer$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name),
      filter((value) => !!value),
      map(
        (pproviderName) =>
          pproviderName.toLowerCase() === externalProviders.KIVI
      )
    );
  }

  getRelationsShipTypeOptions(): Observable<string[]> {
    return combineLatest([this.isKiviCustomer$]).pipe(
      map(([isKiviCustomer]) => {
        const relationsShipTypeOptions = [
          "companyContact",
          "married",
          "parent",
          "child",
          "estateContact",
          "others",
          "estatePrimaryContact",
        ];
        if (!isKiviCustomer) {
          relationsShipTypeOptions.unshift("companySignatory");
        }
        return relationsShipTypeOptions;
      })
    );
  }

  getRelationsShipTypeOptionsByContactType(
    contactType: string
  ): Observable<string[]> {
    return combineLatest([this.isKiviCustomer$]).pipe(
      map(([isKiviCustomer]) => {
        let relationsShipTypeOptions = [];
        if (contactType === "company") {
          relationsShipTypeOptions = ["companyContact"];
        } else {
          relationsShipTypeOptions = [
            "companyContact",
            "married",
            "parent",
            "child",
            "estateContact",
            "others",
            "estatePrimaryContact",
          ];
        }

        if (!isKiviCustomer) {
          relationsShipTypeOptions.unshift("companySignatory");
        }
        return relationsShipTypeOptions;
      })
    );
  }

  getRelationsShipTypeSelect(): Observable<Select> {
    return combineLatest([this.isKiviCustomer$]).pipe(
      map(([isKiviCustomer]) => {
        let relationshipTypes: Select = [
          {
            value: "companyContact",
            label: this.translateService.instant("relation_companycontact"),
          },
          {
            value: "married",
            label: this.translateService.instant("relation_married"),
          },
          {
            value: "parent",
            label: this.translateService.instant("relation_parent"),
          },
          {
            value: "child",
            label: this.translateService.instant("relation_child"),
          },
          {
            value: "estateContact",
            label: this.translateService.instant("relation_estatecontact"),
          },
          {
            value: "others",
            label: this.translateService.instant("relation_others"),
          },
          {
            value: "estatePrimaryContact",
            label: this.translateService.instant(
              "relation_estateprimarycontact"
            ),
          },
        ];

        if (!isKiviCustomer) {
          relationshipTypes.unshift({
            value: "companySignatory",
            label: this.translateService.instant("relation_companysignatory"),
          });
        }

        relationshipTypes = relationshipTypes
          .slice()
          .sort((a, b) => a.label.localeCompare(b.label));
        return relationshipTypes;
      })
    );
  }
}
