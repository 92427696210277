import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { OutgoingMail } from "@app/models";
import * as outgoingMailActions from "./outgoing-mail.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export interface OutgoingMailState {
  loading: boolean;
  loaded: boolean;
  list: OutgoingMail[];
}

export const initialState: OutgoingMailState = {
  loading: false,
  loaded: false,
  list: [],
};

// Todo: Remove "| any" from action
export function outgoingMailReducer(state: OutgoingMailState, action: Action) {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(outgoingMailActions.getOutgoingMailsRequest, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    list: [],
  })),
  on(outgoingMailActions.getOutgoingMailsSuccess, (state, { mails }) => ({
    ...state,
    loading: false,
    loaded: true,
    list: mails ? mails : [],
  }))
);

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.outgoingMail
);

export const getLoading = createSelector(
  getState,
  (state: OutgoingMailState) => state.loading
);

export const getLoaded = createSelector(
  getState,
  (state: OutgoingMailState) => state.loaded
);

export const getList = createSelector(
  getState,
  (state: OutgoingMailState) => state.list
);
