<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'edit_lead_template' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <app-lead-templates-form
      [template]="data$ | async"
    ></app-lead-templates-form>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      tabindex="-1"
      buttonType="remove"
      label="remove"
      [disabled]="isWorking$ | async"
      [isLoading]="isWorking$ | async"
      (click)="onRemove()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="send"
      [disabled]="isWorking$ | async"
      [isLoading]="isWorking$ | async"
      (click)="onSubmit()"
    >
    </app-q-button>
  </div>
</div>
