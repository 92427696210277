import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-consumer-created",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionConsumerCreatedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerName: Function;

  className = "action-created-name";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) => {
        if (youString.length === 0) {
          return this.translateService.get("resource_created", {
            resource: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
          });
        } else {
          return this.translateService.get("action_created_text", {
            you: youString,
            name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
          });
        }
      })
    );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
