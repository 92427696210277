import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  findLanguage,
  LanguageOption,
  languageOptions,
} from "@app/shared/utils/language-utils";

@Component({
  selector: "app-q-language-dropdown",
  templateUrl: "./q-language-dropdown.component.html",
  styleUrls: ["./q-language-dropdown.component.scss"],
})
export class QLanguageDropdownComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() defaultValue: string = "sv";
  @Output() selectedLanguageChanged = new EventEmitter<LanguageOption>();

  selectedLanguage: LanguageOption;
  languages: LanguageOption[] = languageOptions;

  ngOnInit() {
    this.selectedLanguage = findLanguage(this.defaultValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultValue) {
      this.selectedLanguage = findLanguage(this.defaultValue);
    }
  }

  onselectionchange({ value }) {
    this.selectedLanguageChanged.emit(value);
  }
}
