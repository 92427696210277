import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { PageView } from "@app/models/page-view";
import { addBackLink } from "@app/contacts/contact.actions";
import { getObjectStatusColor } from "@app/shared/utils/object-utils";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-object-card",
  templateUrl: "./object-card.component.html",
  styleUrls: ["./object-card.component.scss"],
})
export class ObjectCardComponent {
  @Input() object: PageView;
  @Input() navigationEndpoint: "potential-buyers" | "overview" = "overview";
  @Input() openLinkInNewTab = false;
  @Output() handleClick = new EventEmitter<void>();

  getObjectStatusColor = getObjectStatusColor;

  get firstSeller() {
    return this.object?.entity?.sellers[0];
  }

  get otherSellers() {
    return this.object?.entity?.sellers?.slice(1);
  }

  get sellersCount() {
    return this.object?.entity?.sellers?.length;
  }

  constructor(private store: Store<AppState>, private router: Router) {}

  handleObjectClick() {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
    this.handleClick.emit();
  }
}
