import { QObject } from "@app/models";
import { Municipality } from "@app/models/municipality";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import * as externalProviderActions from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface ExternalProviderState {
  loading: boolean;
  loaded: boolean;
  patching: boolean;
  contactResidences: QObject[];
  selectedResidence: QObject | null;
  municipalities: Municipality[];
  preselectedMunicipality: Municipality;
  objectStatuses: ObjectStatus[];
  objectSearchResults: QObject[];
  openInExternalProvider: boolean;
  cityChangeNeeded: boolean;
}

export const initialState: ExternalProviderState = {
  loading: false,
  loaded: false,
  patching: false,
  contactResidences: [],
  selectedResidence: null,
  municipalities: [],
  preselectedMunicipality: null,
  objectStatuses: [],
  objectSearchResults: [],
  openInExternalProvider: false,
  cityChangeNeeded: false,
};

export function externalProviderReducer(
  state: ExternalProviderState,
  action: Action
): ExternalProviderState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(externalProviderActions.getResidenceRequest, (state) => ({
    ...state,
    loading: true,
    loaded: false,
    cityChangeNeeded: false,
  })),
  on(externalProviderActions.getResidenceSuccess, (state, { qObject }) => ({
    ...state,
    loading: false,
    loaded: true,
    selectedResidence: qObject,
  })),
  on(externalProviderActions.getResidenceFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
  })),
  on(
    externalProviderActions.getMunicipalitiesSuccess,
    (state, { municipalities }) => ({
      ...state,
      municipalities,
    })
  ),
  on(
    externalProviderActions.getPreselectedMunicipalitySuccess,
    (state, { municipality }) => ({
      ...state,
      preselectedMunicipality: municipality,
    })
  ),
  on(
    externalProviderActions.patchProviderResidenceRequest,
    externalProviderActions.updateResidenceRequest,
    externalProviderActions.updateContactRequest,
    externalProviderActions.getContactResidencesRequest,
    externalProviderActions.patchSalesMeetingResidenceRequest,
    externalProviderActions.getExternalProviderLinkRequest,
    (state) => ({
      ...state,
      patching: true,
    })
  ),
  on(
    externalProviderActions.patchProviderResidenceFail,
    (state, { message }) => ({
      ...state,
      cityChangeNeeded: message,
      patching: false,
    })
  ),
  on(externalProviderActions.patchProviderResidenceSuccess, (state) => ({
    ...state,
    cityChangeNeeded: false,
    patching: false,
    selectedResidence: null,
  })),
  on(
    externalProviderActions.updateResidenceSuccess,
    externalProviderActions.updateResidenceFail,
    externalProviderActions.updateContactSuccess,
    externalProviderActions.updateContactFail,
    externalProviderActions.getContactResidencesFail,
    externalProviderActions.patchSalesMeetingResidenceFail,
    externalProviderActions.patchSalesMeetingResidenceSuccess,
    externalProviderActions.getExternalProviderLinkSuccess,
    externalProviderActions.getExternalProviderLinkFail,
    (state) => ({
      ...state,
      patching: false,
    })
  ),
  on(
    externalProviderActions.getContactResidencesSuccess,
    (state, { qObjects }) => ({
      ...state,
      patching: false,
      contactResidences: qObjects,
    })
  ),
  on(
    externalProviderActions.getObjectStatusesSuccess,
    (state, { objectStatuses }) => ({
      ...state,
      objectStatuses,
    })
  ),
  on(
    externalProviderActions.getObjectSearchResultsSuccess,
    (state, { locations }) => ({
      ...state,
      objectSearchResults: locations,
      loading: false,
    })
  ),
  on(
    externalProviderActions.getObjectSearchResultsRequest,
    externalProviderActions.openCreationFormInExternalProviderRequest,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(
    externalProviderActions.getOpenInExternalProviderSuccess,
    (state, { hasOpenIn }) => ({
      ...state,
      openInExternalProvider: hasOpenIn,
    })
  ),
  on(
    externalProviderActions.openCreationFormInExternalProviderFail,
    externalProviderActions.openCreationFormInExternalProviderSuccess,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(
    externalProviderActions.getMunicipalitiesRequest,
    externalProviderActions.getMunicipalitiesFail,
    externalProviderActions.getObjectStatusesRequest,
    externalProviderActions.getObjectStatusesFail,
    externalProviderActions.getObjectSearchResultsFail,
    externalProviderActions.getPreselectedMunicipalityRequest,
    externalProviderActions.getPreselectedMunicipalityFail,
    externalProviderActions.getOpenInExternalProviderRequest,
    externalProviderActions.getOpenInExternalProviderFail,
    (state) => state
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.externalProvider
);

export const getPatching = createSelector(
  getState,
  (state: ExternalProviderState) => state.patching
);

export const getLoading = createSelector(
  getState,
  (state: ExternalProviderState) => state.loading
);

export const getContactResidences = createSelector(
  getState,
  (state: ExternalProviderState) => state.contactResidences
);

export const getSelectedResidence = createSelector(
  getState,
  (state: ExternalProviderState) => state.selectedResidence
);

export const getSelectedResidenceObjType = createSelector(
  getState,
  (state: ExternalProviderState) => state.selectedResidence?.objType
);

export const getMunicipalities = createSelector(
  getState,
  (state: ExternalProviderState) => state.municipalities
);

export const getPreselectedMunicipalityCode = createSelector(
  getState,
  (state: ExternalProviderState) =>
    state.preselectedMunicipality?.municipalityCode
);

export const getPreselectedMunicipalityId = createSelector(
  getState,
  (state: ExternalProviderState) =>
    state.preselectedMunicipality?.municipalityId
);

export const getObjectStatuses = createSelector(
  getState,
  (state: ExternalProviderState) => state.objectStatuses
);

export const getObjectSearchResults = createSelector(
  getState,
  (state: ExternalProviderState) => state.objectSearchResults?.slice(0, 10)
);

export const getOpenInExternalProvider = createSelector(
  getState,
  (state: ExternalProviderState) => state.openInExternalProvider
);

export const getCityChangeNeeded = createSelector(
  getState,
  (state: ExternalProviderState) => state.cityChangeNeeded
);
