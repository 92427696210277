import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SKELETON_SCREEN_COLOR } from "../../../utils/colors";

@Component({
  selector: "skeleton-circle",
  styleUrls: ["./skeleton-circle.component.scss"],
  template: `
    <div
      [style.background-color]="color"
      [style.width]="diameter + unit"
      [style.height]="diameter + unit"
    ></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonCircleComponent {
  @Input() diameter: number;
  @Input() unit = "rem";
  @Input() color = SKELETON_SCREEN_COLOR;
}
