import { Action as ActionModel } from "@app/models";
import { Action, createReducer, on } from "@ngrx/store";
import {
  deleteContactObjectConnectionFail,
  deleteContactObjectConnectionRequest,
  deleteContactObjectConnectionSuccess,
  getContactActionsFail,
  getContactActionsRequest,
  getContactActionsSuccess,
  getPotentialBuyerExternalIdFail,
  getPotentialBuyerExternalIdRequest,
  getPotentialBuyerExternalIdSuccess,
} from "@app/sidebar/potential-buyer/ngrx/potential-buyer/potential-buyer.actions";

export interface PotentialBuyerState {
  deleting: boolean;
  externalId: string;
  contactActions: ActionModel[];
}

const initialState: PotentialBuyerState = {
  deleting: false,
  externalId: "",
  contactActions: [],
};

export function potentialBuyerReducer(
  state: PotentialBuyerState,
  action: Action
): PotentialBuyerState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    deleteContactObjectConnectionSuccess,
    deleteContactObjectConnectionFail,
    (state) => ({ ...state, deleting: false })
  ),
  on(deleteContactObjectConnectionRequest, (state) => ({
    ...state,
    deleting: true,
  })),
  on(getPotentialBuyerExternalIdRequest, (state) => ({ ...state })),
  on(getPotentialBuyerExternalIdFail, (state) => ({
    ...state,
    externalId: "",
  })),
  on(getPotentialBuyerExternalIdSuccess, (state, { externalId }) => ({
    ...state,
    externalId,
  })),
  on(getContactActionsRequest, getContactActionsFail, (state) => ({
    ...state,
  })),
  on(getContactActionsSuccess, (state, { payload }) => ({
    ...state,
    contactActions: payload,
  }))
);
