import { Injectable } from "@angular/core";
import { FocusAreaService } from "@app/core/services/focus-areas/focus-area.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  catchError,
  debounceTime,
  map,
  of,
  switchMap,
  withLatestFrom,
} from "rxjs";
import * as actions from "./actions";
import * as toast from "@app/core/components/toast/ngrx/toast.actions";
import { selectEntityIds } from "./selectors";
import { focusAreaFormatParameters } from "@app/settings/map-areas/focus-areas/focus-area-util";
import { SalesFocusFilter } from "@app/settings/map-areas/focus-areas";

@Injectable()
export class SalesFocusAreasEffects {
  fetchSalesFocusAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchSalesFocusAreas),
      debounceTime(500),
      switchMap(({ officeIds, agentIds, entityType }) => {
        if (
          !entityType &&
          (!officeIds || officeIds?.length === 0) &&
          (!agentIds || agentIds?.length === 0)
        ) {
          return of(
            actions.fetchSalesFocusAreasSuccess({
              areas: [],
            })
          );
        }

        const params = focusAreaFormatParameters(
          officeIds,
          agentIds,
          entityType
        );

        return this.service.get(params).pipe(
          map((areas) =>
            actions.fetchSalesFocusAreasSuccess({
              areas,
            })
          )
        );
      }),
      catchError(() =>
        of(
          actions.fetchSalesFocusAreasFail({
            message: "could not fetch areas for offices",
          })
        )
      )
    )
  );

  searchSalesFocusAreas$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.searchSalesFocusAreas),
      switchMap(({ address }) => {
        if (!address || address.trim().length <= 1) {
          return of(
            actions.searchSalesFocusAreasSuccess({
              areas: [],
            })
          );
        }

        const params = {
          filters: [
            {
              field: "address",
              operator: "eq",
              value: address,
            } as SalesFocusFilter,
          ],
        };

        return this.service.get(params).pipe(
          map((areas) =>
            actions.searchSalesFocusAreasSuccess({
              areas,
            })
          )
        );
      }),
      catchError(() =>
        of(
          actions.searchSalesFocusAreasFail({
            message: "could not fetch areas for this address",
          })
        )
      )
    )
  );

  refetch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.refetch),
      withLatestFrom(this.store.pipe(select(selectEntityIds))),
      map(([, { agentIds, officeIds, entityType }]) =>
        actions.fetchSalesFocusAreas({ agentIds, officeIds, entityType })
      )
    )
  );

  renameArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.renameSalesFocusArea),
      switchMap(({ id, name, jsonData }) =>
        this.service.renameFocusArea(id, name, jsonData)
      ),
      switchMap((area) => [
        actions.renameSalesFocusAreaSuccess({ area }),
        toast.show({
          data: { message: "area_renamed", duration: 5000, type: "success" },
        }),
      ]),
      catchError(() => of(actions.renameSalesFocusAreaFail()))
    )
  );

  reassignArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.reassignSalesFocusArea),
      switchMap(({ id, officeId, agentId }) =>
        this.service.reassignFocusArea(id, officeId, agentId)
      ),
      map(() => actions.refetch())
    )
  );

  saveSalesFocusArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveSalesFocusArea),
      switchMap(({ area }) => {
        return this.service.updateFocusAreaGeometry(area).pipe(map(() => area));
      }),
      map((area) => actions.saveSalesFocusAreaSuccess({ area })),
      catchError(() => of(actions.saveSalesFocusAreaFail()))
    )
  );

  saveSalesFocusAreaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveSalesFocusAreaSuccess),
      switchMap(() => [
        actions.refetch(),
        toast.show({
          data: {
            message: "focus_area_saved",
            duration: 5000,
            type: "success",
          },
        }),
      ])
    )
  );

  saveSalesFocusAreaFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.saveSalesFocusAreaFail),
      switchMap(() => [
        toast.show({
          data: {
            message: "update_focus_area_failed",
            duration: 5000,
            type: "danger",
          },
        }),
      ])
    )
  );

  removeSalesFocusArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.removeSalesFocusArea),
      switchMap(({ id }) => this.service.removeFocusArea(id)),
      switchMap(() => [
        actions.refetch(),
        toast.show({
          data: {
            message: "focus_area_removed",
            duration: 5000,
            type: "success",
          },
        }),
      ])
    )
  );

  createSalesFocusArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createSalesFocusArea),
      switchMap(({ agent, office, name, geometry, jsonData }) =>
        this.service.createFocusArea(agent, office, geometry, name, jsonData)
      ),
      switchMap((result) => [
        actions.createSalesFocusAreaSuccess({ area: result }),
        actions.refetch(),
        toast.show({
          data: {
            message: "focus_area_created",
            duration: 5000,
            type: "success",
          },
        }),
      ]),
      catchError((error) => {
        return of(actions.createSalesFocusAreaFail({ error }));
      })
    )
  );

  optimalNumbersInFocus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.optimalNumbersInFocusAreaRequest),
      switchMap(({ id, geometry }) =>
        this.service
          .getOptimalNumber(geometry)
          .pipe(map((numberOfContacts) => ({ id, numberOfContacts })))
      ),
      map(({ id, numberOfContacts }) =>
        actions.optimalNumbersInFocusAreaSuccess({ id, numberOfContacts })
      ),
      catchError(() => {
        return of(actions.optimalNumbersInFocusAreaFail({ id: null }));
      })
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly service: FocusAreaService,
    private readonly store: Store
  ) {}
}
