<search-overlay></search-overlay>
<header
  iosScrollFix
  [isProductNewsEnabled]="isProductNewsEnabled$ | async"
  [isNavbarVisible]="isNavbarVisible"
  [sidebarAnimationStates]="sidebarAnimationStates$ | async">
</header>
<navbar
  [isNavbarVisible]="isNavbarVisible"
  [isLargeScreen]="isLargeScreen"
  [isMobile]="isMobile$ | async"
  [isSupportWidgetEnabled]="isSupportWidgetEnabled$ | async">
</navbar>
<div
  [@routeTransition]="getRouteState(outlet)"
  [@isNavVisibleChanged]="isNavbarVisible"
  [@sidebarAnimationStates]="sidebarAnimationStates$ | async"
  id="main-outlet"
  iosScrollFix
>
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<router-outlet name="sidebar"></router-outlet>
<router-outlet name="modal"></router-outlet>
<scroll-top *ngIf="hasScrolled$ | async"></scroll-top>
