<div class="kpi-box-container">
  <h4
    class="kpi-box__title"
    matTooltip="{{ (title.length > 18 ? title : '') | translate }}"
    matTooltipClass="q-tooltip"
  >
    <span>
      {{ title }}
    </span>
    <span (click)="showInfo()">
      <app-icon name="info-circle"></app-icon>
    </span>
  </h4>

  <div class="kpi-box" (click)="onClick()">
    <ng-container *ngIf="nps && nps.responsesWithNPSValues; else noNps">
      <div class="progress-container">
        <div class="progress" style="width: 100%">
          <div class="progress__bar">
            <progressbar
              [max]="max"
              [value]="value"
              [type]="getProgressBarType(value, max)"
            >
            </progressbar>
          </div>
          <span class="value">{{
            (nps.responsesWithNPSValues | separateThousands) || 0
          }}</span>
        </div>
        <h5>
          {{ "answer" | translate }} / {{ (value | separateThousands) || 0 }}
        </h5>
      </div>
    </ng-container>

    <ng-template #noNps>
      <div class="nps">
        <div>
          <div class="value">-</div>
          <small translate class="text">no_nps_found</small>
        </div>
      </div>
    </ng-template>

    <div class="kpi-box__show-more-btn">
      <app-icon name="circle-ellipsis-vertical"></app-icon>
    </div>
  </div>
</div>

<div bsModal class="modal fade" #modal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 translate class="modal-title">{{ npsConfig.title | translate }}</h4>
        <button type="button" class="btn-close" (click)="hideModal()">
          <i class="fa fa-close"></i>
        </button>
      </div>

      <div class="modal-body">
        <ng-container *ngIf="npsConfig.video_url; else npsHelpText">
          <iframe
            [src]="npsConfig.video_url | safe"
            width="640"
            height="360"
            frameborder="0"
            allow="autoplay; fullscreen"
            allowfullscreen
          ></iframe>
        </ng-container>

        <ng-template #npsHelpText>
          <ng-container *ngIf="npsConfig.help_text; else npsTextInfo">
            <p [innerHTML]="replaceNewLine(npsConfig.help_text)"></p>
          </ng-container>
        </ng-template>
        <ng-template #npsTextInfo>
          <p>
            {{ "nps_info_one" | translate }}
          </p>
          <p translate>nps_info_two</p>
          <p translate>nps_info_three</p>
          <p *ngIf="npsConfig.info_link">
            {{ "nps_info_four" | translate }}:
            <a (click)="handleLinkClicked(npsConfig.info_link)">{{
              npsConfig.info_link
            }}</a>
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>
