import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ContactActionCallRegisteredPhoneComponent } from "@app/shared/modules/contact-action-list/contact-action-list-item/common/call-registered-phone.component";
import { ResourceModule } from "@app/shared/modules/resource/resource.module";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ListComponentsModule } from "../list-components/list.module";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { UtilModule } from "../util/util.module";
import { ContactActionCallRegisteredComponent } from "./contact-action-list-item/common/call-registered.component";
import { ContactActionCrmSentComponent } from "./contact-action-list-item/common/crm-sent.component";
import { ContactActionOne2OneEmailComponent } from "./contact-action-list-item/common/one2one-email.component";
import { ContactActionOne2OneSmsComponent } from "./contact-action-list-item/common/one2one-sms.component";
import { ContactActionListItemComponent } from "./contact-action-list-item/contact-action-list-item.component";
import { ContactActionListComponent } from "./contact-action-list.component";

const components = [
  ContactActionListComponent,
  ContactActionListItemComponent,
  ContactActionOne2OneEmailComponent,
  ContactActionOne2OneSmsComponent,
  ContactActionCrmSentComponent,
  ContactActionCallRegisteredComponent,
  ContactActionCallRegisteredPhoneComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    TooltipModule,
    MomentModule,
    RouterModule,
    ListComponentsModule,
    UiComponentsModule,
    UtilModule,
    ResourceModule,
  ],
  declarations: components,
  exports: [ContactActionListComponent],
  providers: [],
})
export class ContactActionListModule {}
