import { Component, Input } from "@angular/core";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { ProgressbarType } from "ngx-bootstrap/progressbar";

@Component({
  selector: "calling-list-card-details",
  templateUrl: "./calling-list-card-details.component.html",
  styleUrls: ["./calling-list-card-details.component.scss"],
})
export class CallingListCardDetailsComponent {
  @Input() callingList: CallingList;

  getProgressType(maxValue: number, value: number): ProgressbarType {
    if (maxValue / value > 3) {
      return "danger";
    } else if (maxValue / value >= 1.5) {
      return "warning";
    } else {
      return "success";
    }
  }
}
