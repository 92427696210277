import { Injectable } from "@angular/core";
import { Segment } from "@app/campaigns/create-campaign/models/segment";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO, TypedPaginationListDTO } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
} from "rxjs";
import * as followUpSalesMeetingsActions from "./followup-sales-meeting-list/followup-sales-meeting-list.actions";
import * as segmentationListActions from "./segmentation-list/segmentation-list.actions";

@Injectable()
export class MoreOpportunitiesEffects {
  getFollowUpSalesMeetingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(followUpSalesMeetingsActions.getFollowUpSalesMeetingsRequest),
      switchMap(({ params }) =>
        this.apiService.get("tasks", params).pipe(
          map(({ rows, total, offset }: PaginationListDTO) => {
            if (total > 0) {
              return followUpSalesMeetingsActions.getFollowUpSalesMeetingsSuccess(
                {
                  tasks: rows,
                  listStart: offset,
                  totalRows: total,
                }
              );
            }
            return followUpSalesMeetingsActions.getFollowUpSalesMeetingsNoTasksFound();
          }),
          catchError(() =>
            observableOf(
              followUpSalesMeetingsActions.getFollowUpSalesMeetingsFailed()
            )
          )
        )
      )
    )
  );

  searchSegmentsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(segmentationListActions.searchSegmentsRequest),
      debounceTime(1000),
      switchMap(({ segmentTypeFilter, limit }) =>
        this.apiService
          .get("contact-segmentation/search", { segmentTypeFilter, limit })
          .pipe(
            map((response: TypedPaginationListDTO<Segment>) => {
              if (response?.rows?.length > 0) {
                return segmentationListActions.searchSegmentsSuccess({
                  segments: response.rows,
                });
              } else {
                return segmentationListActions.noSegmentsFound();
              }
            }),
            catchError(() =>
              observableOf(segmentationListActions.searchSegmentsFail())
            )
          )
      )
    )
  );

  searchSegmentListForCallingListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(segmentationListActions.searchSegmentListForCallingListRequest),
      mergeMap(({ params, segmentId }) =>
        this.apiService
          .get(`contact-segmentation/${segmentId}/contacts`, params)
          .pipe(
            map((response: TypedPaginationListDTO<Segment>) =>
              segmentationListActions.searchSegmentListForCallingListSuccess({
                payload: response,
              })
            ),
            catchError(() =>
              observableOf(
                segmentationListActions.searchSegmentListForCallingListFail()
              )
            )
          )
      )
    )
  );

  searchSegmentListRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(segmentationListActions.searchSegmentListRequest),
      mergeMap(({ params, segmentId }) =>
        this.apiService
          .get(`contact-segmentation/${segmentId}/contacts`, params)
          .pipe(
            map((response: TypedPaginationListDTO<Segment>) =>
              segmentationListActions.searchSegmentListSuccess({
                data: response,
                segmentId: segmentId,
              })
            ),
            catchError(() =>
              observableOf(segmentationListActions.searchSegmentListFail())
            )
          )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
