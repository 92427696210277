<div class="manager-container">
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    class="menu-button"
    matTooltip="{{ 'select_and_manage_filter_templates' | translate }}"
    matTooltipClass="q-tooltip"
  >
    <app-icon name="ellipsis-vertical" type="solid" size="medium"></app-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div appMenuPanel class="input-box-container">
      <mat-form-field class="input-form-field" subscriptSizing="dynamic">
        <input
          matInput
          type="text"
          placeholder="{{ 'save_as_new_template' | translate }}"
          [(ngModel)]="templateName"
        />
      </mat-form-field>
      <div class="button-container">
        <button
          *ngIf="templateName"
          class="save-button"
          matTooltip="{{ 'save' | translate }}"
          matTooltipClass="q-tooltip"
          aria-label="Save"
          (click)="saveFilterTemplate()"
        >
          <app-icon name="check" size="small"></app-icon>
        </button>
        <button
          *ngIf="templateName"
          class="delete-button"
          matTooltip="{{ 'delete' | translate }}"
          matTooltipClass="q-tooltip"
          aria-label="Clear"
          (click)="templateName = ''"
        >
          <app-icon name="times" size="small"></app-icon>
        </button>
      </div>
    </div>
    <ng-container *ngFor="let template of filterTemplates">
      <div
        class="menu-item-container"
        [class.selected]="selectedFilter?.key === template.key"
        [class.predefined]="template.isPredefined"
      >
        <button
          mat-menu-item
          (click)="handleSelectedFilterChange(template.key)"
        >
          <span>{{ template.key | translate }}</span>
        </button>
        <button
          *ngIf="!template.isPredefined"
          class="delete-button"
          matTooltip="{{ 'delete' | translate }}"
          matTooltipClass="q-tooltip"
          aria-label="Delete"
          (click)="deleteFilterTemplate(template.key)"
        >
          <app-icon name="times" size="small"></app-icon>
        </button>
      </div>
    </ng-container>
  </mat-menu>
</div>
