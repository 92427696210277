import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getCurrency } from "@app/shared/config/config.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-icon-currency",
  templateUrl: "./icon-currency.component.html",
  styleUrls: ["./icon-currency.component.scss"],
})
export class IconCurrencyComponent implements OnInit {
  @Input() size: "x-small" | "small" | "medium" | "large" | "x-large" | "none" =
    "small";

  currencyIcon$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.currencyIcon$ = this.store.pipe(
      select(getCurrency),
      map((currency) => this.getCurrencySign(currency))
    );
  }

  getCurrencySign(currency: string) {
    switch (currency) {
      case "eur":
        return "euro-sign";
      case "gbp":
        return "pound-sign";
      default:
        return "dollar-sign";
    }
  }
}
