import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatDateTypeName",
})
export class FormatDateTypeNamePipe implements PipeTransform {
  transform(value: string): string {
    return "date_" + value.replace(/ /g, "").toLowerCase();
  }
}
