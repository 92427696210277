import { Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as contactActions from "@app/contacts/contact.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_SEND_EXTERNAL_TIPS_URL } from "@app/shared/utils/sidebar-tab-utils";
import { Store } from "@ngrx/store";

@Component({
  selector: "send-external-tips-home",
  templateUrl: "./send-external-tips-home.component.html",
  styleUrls: [
    "./send-external-tips-home.component.scss",
    "../../sidebar.component.common.scss",
  ],
})
export class SendExternalTipsHomeComponent {
  constructor(private store: Store<AppState>) {}

  onClick(contact: Contact) {
    this.store.dispatch(contactActions.getContactSuccess({ contact }));
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_SEND_EXTERNAL_TIPS_URL } },
        ],
      })
    );
  }
}
