import {
  ExternalPresentableLink,
  PresentableLinks,
} from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { ContactStatus } from "@app/showings/models";
import * as PBActions from "@app/showings/ngrx/potential-buyer/potential-buyer.actions";
import { ShowingsState } from "@app/showings/showings-feature.reducer";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface PotentialBuyerState {
  selectedPotentialBuyerId: string;
  contactSummary: ContactStatus | null;
  objectExternalProviderLinks: any[];
  objectHiddenExternalProviderLinks: any[];
  erpMenuLinks: any[];
  urlForBidding: ExternalPresentableLink;
  urlForBiddingSettings: ExternalPresentableLink;
  presentableLinks: PresentableLinks;
  objectExternalProviderLinksLoading: boolean;
  removeBidLoading: boolean;
  provider: string;
}

export const initialState: PotentialBuyerState = {
  selectedPotentialBuyerId: "",
  contactSummary: null,
  objectExternalProviderLinks: [],
  objectHiddenExternalProviderLinks: [],
  erpMenuLinks: [],
  urlForBidding: null,
  urlForBiddingSettings: null,
  presentableLinks: null,
  objectExternalProviderLinksLoading: false,
  removeBidLoading: false,
  provider: "",
};

export function potentialBuyerReducer(
  state: PotentialBuyerState,
  action: Action
): PotentialBuyerState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    PBActions.fetchShowingObjectContactSummarySuccess,
    (state, { contactStatus }) => ({ ...state, contactSummary: contactStatus })
  ),
  on(PBActions.getObjectExternalProviderLinksRequest, (state) => ({
    ...state,
    objectExternalProviderLinksLoading: true,
  })),
  on(
    PBActions.getObjectExternalProviderLinksSuccess,
    (state, { ...payload }) => ({
      ...state,
      objectExternalProviderLinks: payload.externalLinks,
      objectHiddenExternalProviderLinks: payload.hiddenLinks,
      erpMenuLinks: payload.erpMenuLinks,
      objectExternalProviderLinksLoading: false,
      presentableLinks: payload.presentableLinks,
      urlForBidding: payload.presentableExternalLinkForBidding,
      urlForBiddingSettings: payload.presentableExternalLinkForBiddingSettings,
      provider: payload.provider,
    })
  ),
  on(PBActions.getObjectExternalProviderLinksFailed, (state) => ({
    ...state,
    objectExternalProviderLinksLoading: false,
    erpMenuLinks: [],
    presentableLinks: null,
    objectExternalProviderLinks: [],
    objectHiddenExternalProviderLinks: [],
    urlForBidding: null,
    urlForBiddingSettings: null,
    provider: "",
  })),
  on(PBActions.removeBidRequest, (state) => ({
    ...state,
    removeBidLoading: true,
  })),
  on(PBActions.removeBidSuccess, (state) => ({
    ...state,
    removeBidLoading: false,
  })),
  on(PBActions.removeBidFailure, (state) => ({
    ...state,
    removeBidLoading: false,
  })),
  on(PBActions.setSelectedPotentialBuyerId, (state, { potentialBuyerId }) => ({
    ...state,
    selectedPotentialBuyerId: potentialBuyerId,
  })),
  on(PBActions.reset, () => initialState)
);

const selectFeature = createFeatureSelector<ShowingsState>("showings");
const getState = createSelector(
  selectFeature,
  (state: ShowingsState) => state.potentialBuyer
);

export const getShowingObjectContactSummary = createSelector(
  getState,
  (state: PotentialBuyerState) => state.contactSummary
);
export const getObjectExternalProviderLinks = createSelector(
  getState,
  (state: PotentialBuyerState) => state.objectExternalProviderLinks
);
export const getHiddenObjectExternalProviderLinks = createSelector(
  getState,
  (state: PotentialBuyerState) => state.objectHiddenExternalProviderLinks
);
export const getErpMenuLinks = createSelector(
  getState,
  (state: PotentialBuyerState) => state.erpMenuLinks
);
export const getObjectExternalProviderLinksLoading = createSelector(
  getState,
  (state: PotentialBuyerState) => state.objectExternalProviderLinksLoading
);
export const getUrlForBidding = createSelector(
  getState,
  (state: PotentialBuyerState) => state.urlForBidding
);
export const getUrlForBiddingSettings = createSelector(
  getState,
  (state: PotentialBuyerState) => state.urlForBiddingSettings
);
export const getPresentableLinks = createSelector(
  getState,
  (state: PotentialBuyerState) => state.presentableLinks
);
export const getRemovePotentialBuyerBidLoading = createSelector(
  getState,
  (state: PotentialBuyerState) => state.removeBidLoading
);
export const getProvider = createSelector(
  getState,
  (state: PotentialBuyerState) => state.provider
);
