import { Component, Input } from "@angular/core";

export type CardColor = "1" | "2";

@Component({
  selector: "app-q-card",
  templateUrl: "./q-card.component.html",
  styleUrls: ["./q-card.component.scss"],
})
export class QCardComponent {
  @Input() color: CardColor;
  @Input() disabled: boolean;
}
