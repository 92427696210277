import { TypesOfNonRecurringItem } from "@app/settings/invoicing/components/types-of-non-recurring-items/ngrx/types-of-non-recurring-items.reducer";
import { createAction, props } from "@ngrx/store";

export const getTypesOfNonRecurringItemsRequest = createAction(
  "[TypesOfNonRecurringItems] GET_TYPES_OF_NON_RECURRING_ITEMS_REQUEST"
);

export const getTypesOfNonRecurringItemsSuccess = createAction(
  "[TypesOfNonRecurringItems] GET_TYPES_OF_NON_RECURRING_ITEMS_SUCCESS",
  props<{ rows: TypesOfNonRecurringItem[] }>()
);

export const getTypesOfNonRecurringItemsFailed = createAction(
  "[TypesOfNonRecurringItems] GET_TYPES_OF_NON_RECURRING_ITEMS_FAILED"
);

export const storeTypeOfNonRecurringItemsRequest = createAction(
  "[TypesOfNonRecurringItems] STORE_TYPE_OF_NON_RECURRING_ITEMS_REQUEST",
  props<{ params: TypesOfNonRecurringItem }>()
);

export const storeTypeOfNonRecurringItemsSuccess = createAction(
  "[TypesOfNonRecurringItems] STORE_TYPE_OF_NON_RECURRING_ITEMS_SUCCESS"
);

export const storeTypeOfNonRecurringItemsFailed = createAction(
  "[TypesOfNonRecurringItems] STORE_TYPE_OF_NON_RECURRING_ITEMS_FAILED"
);

export const updateTypeOfNonRecurringItemsRequest = createAction(
  "[TypesOfNonRecurringItems] UPDATE_TYPE_OF_NON_RECURRING_ITEMS_REQUEST",
  props<{ params: TypesOfNonRecurringItem }>()
);

export const updateTypeOfNonRecurringItemsSuccess = createAction(
  "[TypesOfNonRecurringItems] UPDATE_TYPE_OF_NON_RECURRING_ITEMS_SUCCESS"
);

export const updateTypeOfNonRecurringItemsFailed = createAction(
  "[TypesOfNonRecurringItems] UPDATE_TYPE_OF_NON_RECURRING_ITEMS_FAILED"
);

export const deleteTypeOfNonRecurringItemsRequest = createAction(
  "[TypesOfNonRecurringItems] DELETE_TYPE_OF_NON_RECURRING_ITEMS_REQUEST",
  props<{ oneTypeId: string }>()
);

export const deleteTypeOfNonRecurringItemsSuccess = createAction(
  "[TypesOfNonRecurringItems] DELETE_TYPE_OF_NON_RECURRING_ITEMS_SUCCESS"
);

export const deleteTypeOfNonRecurringItemsFailed = createAction(
  "[TypesOfNonRecurringItems] DELETE_TYPE_OF_NON_RECURRING_ITEMS_FAILED"
);
