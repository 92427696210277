import * as messageTemplatesActions from "@app/settings/message-templates/ngrx/message-templates.actions";
import { Template } from "@app/sidebar/send-message/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface MessageTemplatesState {
  filters: { groupType: string; groupName: string } | null;
  templates: Template[] | null;
}

export const initialState: MessageTemplatesState = {
  filters: null,
  templates: null,
};

export function messageTemplatesReducer(
  state: MessageTemplatesState,
  action: Action
): MessageTemplatesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    messageTemplatesActions.changeFilter,
    (state, { groupType, groupName }) => ({
      ...state,
      filters: { groupType, groupName },
    })
  ),
  on(messageTemplatesActions.fetchTemplatesSuccess, (state, { templates }) => ({
    ...state,
    templates,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.messageTemplates
);

export const getTemplates = createSelector(
  getState,
  (state: MessageTemplatesState) => state.templates
);
export const getFilters = createSelector(
  getState,
  (state: MessageTemplatesState) => state.filters
);
