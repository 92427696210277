import { Pipe, PipeTransform } from "@angular/core";
import * as subPages from "@app/shared/config/utils/sub-pages";
import * as icons from "@app/shared/utils/icons";

@Pipe({
  name: "subpageTypeToIcon",
})
export class SubpageTypeToIconPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case subPages.OVERVIEW: {
        return icons.ICON_OVERVIEW;
      }
      case subPages.TIMELINE: {
        return icons.ICON_TIMELINE;
      }
      case subPages.SALES_MEETINGS: {
        return icons.ICON_SALES_MEETING;
      }
      case subPages.RESIDENCES: {
        return icons.ICON_RESIDENCE;
      }
      case subPages.SEARCH_PROFILE: {
        return icons.ICON_SEARCH_PROFILE;
      }
      case subPages.CONSENTS: {
        return icons.ICON_CONSENTS;
      }
      default:
        return null;
    }
  }
}
