import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs";

import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ValueMonitorService } from "@app/core/ngrx/entity-services/value-monitor.service";
import { GetValueMonitorParams } from "@app/models";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-value-monitor-widget",
  templateUrl: "./value-monitor-widget.component.html",
  styleUrls: ["./value-monitor-widget.component.scss"],
})
export class ValueMonitorWidgetComponent implements OnInit, OnChanges {
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() contactId?: string;
  @Input() eaOid?: string;

  columns: string[] = [];
  headers = {
    street: "object",
    baseValuation: "price",
    employeeFullName: "responsible_broker",
    contactMethod: "type",
  };

  constructor(
    public valueMonitorService: ValueMonitorService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    if (this.eaOid) {
      this.columns = ["contact", "baseValuation", "employeeFullName"];
    } else if (this.contactId) {
      this.columns = [
        "street",
        "baseValuation",
        "employeeFullName",
        "contactMethod",
      ];
    }
  }

  ngOnChanges(changes) {
    if (
      (changes.contactId && !!this.contactId) ||
      (changes.eaOid && !!this.eaOid)
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const contactId = this.contactId;
    const eaOid = this.eaOid;
    const params: GetValueMonitorParams = {
      orderType: "intake",
      ...(contactId && { contactId }),
      ...(eaOid && { eaOid }),
    };

    this.valueMonitorService.refreshFetch$.subscribe(() => {
      this.valueMonitorService.fetchWithQuery(params).subscribe();
    });
  }

  createValueMonitor() {
    this.store.dispatch(
      go({
        path: [
          "/crm",
          { outlets: { sidebar: ["root", "value-monitor", "new"] } },
        ],
      })
    );
  }

  editValueMonitor(id) {
    this.store.dispatch(
      go({
        path: [
          "/crm",
          { outlets: { sidebar: ["root", "value-monitor", id, "edit"] } },
        ],
      })
    );
  }

  removeValueMonitor(id) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_value_monitor_header",
        message: "remove_value_monitor_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1)
      )
      .subscribe(() => {
        this.valueMonitorService.remove(id).subscribe();
      });
  }
}
