import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";

import { RichTextEditorComponent } from "./rich-text-editor.component";

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, QuillModule],
  declarations: [RichTextEditorComponent],
  exports: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
