import * as widgetActions from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface NpsState {
  stats: any;
  loading: boolean;
}

export const initialState: NpsState = {
  stats: null,
  loading: false,
};

export function npsReducer(state, action: Action): NpsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(widgetActions.fetchStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(widgetActions.fetchStatisticsSuccess, (state, { nps }) => ({
    ...state,
    loading: false,
    stats: nps,
  })),
  on(widgetActions.fetchStatisticsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.nps
);
export const getStats = createSelector(
  getState,
  (state: NpsState) => state.stats
);
export const getLoading = createSelector(
  getState,
  (state: NpsState) => state.loading
);
