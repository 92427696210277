import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { Action } from "@app/models";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";

@Component({
  selector: "contact-action-crm-sent-email",
  template: `
    <ng-container>
      <h4>{{ action.timestamp }}</h4>
      <p>
        {{ text$ | async }} <br />
        <small>{{ action.param1 }}</small>
      </p>
    </ng-container>
  `,
  styleUrls: ["./common.scss"],
})
export class ContactActionCrmSentComponent implements OnInit {
  @Input() action: Action;

  text$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.text$ = this.translateService.get("action_crm_sent_text", {
      name: `${this.action.firstName} ${this.action.familyName}`,
    });
  }

  openPreviewModal(html: string): void {
    this.store.dispatch(
      previewModalActions.showPreviewModal({
        parameters: { html },
      })
    );
  }
}
