import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { KpiModalResolver } from "@app/kpi/resolvers/kpi-modal-resolver.service";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import * as fromKpi from "../ngrx/kpi.reducer";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "kpi-modal",
  templateUrl: "./kpi-modal.component.html",
  styleUrls: ["./kpi-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiModalComponent implements OnInit {
  @ViewChild(ModalDirective, { static: true })
  modal: ModalDirective;

  title: string;
  dateFormat = "YYYYMM";
  fromTo = {
    from: moment().format(this.dateFormat),
    to: moment().format(this.dateFormat),
  };
  monthTitle: string;
  soon_for_sale_objects_new_office;
  hasUseParam: boolean = false;
  canChangeDate: boolean = true;
  months: number = 0;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private resolver: KpiModalResolver,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.handleRouteChanges();

    const queryParamsToUse = this.route.snapshot.queryParams;
    if (!!queryParamsToUse["use"]) {
      this.hasUseParam = queryParamsToUse["use"];
    }
  }

  private handleRouteChanges() {
    this.route.queryParams.subscribe((params) => {
      let from: moment.Moment;
      let to: moment.Moment;
      if (this.isKpiTypeNps()) {
        from = moment(params.minDate, API_DATE_FORMAT);
        to = moment(params.maxDate, API_DATE_FORMAT);
        this.fromTo = {
          from: from.format(this.dateFormat),
          to: to.format(this.dateFormat),
        };
      } else {
        from = moment(params.from);
        to = moment(params.to);
        this.fromTo = {
          from: from.format(this.dateFormat),
          to: to.format(this.dateFormat),
        };
      }
      this.months = to.diff(from, "months");
      this.setMonthTitle();
      //  SetTitle for kpi
    });
  }

  hideModal(): void {
    this.modal.hide();
  }

  onHidden(): void {
    this.store.dispatch(
      RouterActions.go({ path: ["/crm", { outlets: { modal: null } }] })
    );
  }

  mapStateToProps(): void {
    this.store.pipe(select(fromKpi.getTitle)).subscribe((title) => {
      this.title = title;
      this.cdr.detectChanges();
    });
  }

  setMonthTitle() {
    if (this.months > 1) {
      this.monthTitle =
        `${this.months} ` + this.translate.instant("months_back");
      this.canChangeDate = false;
    } else {
      this.monthTitle = moment(this.fromTo.from, this.dateFormat).format(
        "MMMM"
      );
    }
    this.cdr.detectChanges();
  }

  loadPreviousMonth() {
    this.fromTo = {
      from: moment(this.fromTo.from, this.dateFormat)
        .subtract(1, "month")
        .format(this.dateFormat),
      to: moment(this.fromTo.to, this.dateFormat)
        .subtract(1, "month")
        .format(this.dateFormat),
    };
    this.navigateToNewMonth();
  }

  loadNextMonth() {
    this.fromTo = {
      from: moment(this.fromTo.from, this.dateFormat)
        .add(1, "month")
        .format(this.dateFormat),
      to: moment(this.fromTo.to, this.dateFormat)
        .add(1, "month")
        .format(this.dateFormat),
    };
    this.navigateToNewMonth();
  }

  navigateToNewMonth() {
    let fromTo;
    if (this.isKpiTypeNps()) {
      fromTo = {
        minDate: moment(this.fromTo.from, "YYYYMM")
          .startOf("month")
          .format(API_DATE_FORMAT),
        maxDate: moment(this.fromTo.to, "YYYYMM")
          .endOf("month")
          .format(API_DATE_FORMAT),
      };
    } else {
      fromTo = this.fromTo;
      if (this.hasUseParam) {
        fromTo = { ...this.fromTo, use: "true" };
      } else {
        fromTo = this.fromTo;
      }
    }

    this.router.navigate([], { relativeTo: this.route, queryParams: fromTo });
    this.resolver.resolve(this.route.snapshot.children[0], {}, fromTo);
    this.setMonthTitle();
  }

  isKpiTypeNps(): boolean {
    return location.href.includes("kpi/nps");
  }
}
