<ng-container *ngIf="authService.isAuthenticated() && isEnable">
  <div id="multi-tasker-container" *ngIf="!(isMobile$ | async)">
    <div class="multi-tasker-wrapper">
      <div class="history-button">
        <button class="q-btn--save" (click)="showFullHistory = true">
          {{ "history" | translate }}
          <app-icon [name]="'rectangle-history'"></app-icon>
        </button>
      </div>
      <div
        class="multi-tasker-card"
        *ngFor="let entity of entities$ | async; let i = index"
      >
        <ng-container *ngIf="i < 3">
          <ng-container *ngIf="entity.data | async as data">
            <app-mobile-card
              *ngIf="entity.entityType === 'contact'"
              color="WHITE"
              [clickable]="true"
              [enableMetadata]="false"
              [showCross]="true"
              [size]="'SLIM'"
              (click)="navigateToEntity(entity)"
              (remove)="removeEntity(entity.id)"
            >
              <div title>
                <app-contact-name
                  [contactId]="data.contactId"
                  [contactType]="data.contactType"
                >
                  {{ data.firstName }} {{ data.familyName }}
                </app-contact-name>
              </div>
            </app-mobile-card>
            <app-mobile-card
              *ngIf="entity.entityType === 'object'"
              color="WHITE"
              [clickable]="true"
              [enableMetadata]="false"
              [showCross]="true"
              [size]="'SLIM'"
              (click)="navigateToEntity(entity)"
              (remove)="removeEntity(entity.id)"
            >
              <div title>
                {{ data.street }}
              </div>
            </app-mobile-card>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

<app-history-sidebar
  *ngIf="!!showFullHistory"
  (closeSidebar)="showFullHistory = false"
>
</app-history-sidebar>
