import {
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map, Observable, switchMap } from "rxjs";

import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { QObject } from "@app/models";
import * as fromUser from "@app/shared/user";
import {
  QSearchObjectParams,
  QSearchObjectService,
} from "@app/shared/modules/ui-components/q-search-object/q-search-object.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ROLE_ADMIN } from "@app/shared/utils/roles";

@Component({
  selector: "app-q-search-object",
  templateUrl: "./q-search-object.component.html",
  styleUrls: ["./q-search-object.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QSearchObjectComponent),
    },
  ],
})
export class QSearchObjectComponent implements OnInit, ControlValueAccessor {
  @Input() params: QSearchObjectParams;
  @Input() readonly: boolean;
  @Input() invalid: boolean;
  @Input() showCard: boolean = true;
  @Input() label: string = "object";
  @Output() objectSelectedChange: EventEmitter<QObject> =
    new EventEmitter<QObject>();
  @ContentChild(TemplateRef) cardBodyTemplate: TemplateRef<any>;

  data: any[];
  object: any;
  private _value: string;
  touched = false;
  disabled = false;

  isAdmin$: Observable<boolean>;
  eaEmployeeId$: Observable<string>;
  userOffices$: Observable<string[]>;
  superSearchTemplate$: Observable<string>;
  isLoading$: Observable<boolean>;

  get value() {
    return this._value;
  }

  @Input() set value(eaOId: string) {
    this._value = eaOId;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    private searchObjectService: QSearchObjectService
  ) {}

  ngOnInit(): void {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.userOffices$ = this.store.pipe(select(fromUser.getEaOfficesIds));
    this.superSearchTemplate$ = this.store.pipe(
      select(fromConfig.getTemplateInSuperSearch)
    );

    this.isLoading$ = this.searchObjectService.loading$;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (obj) {
      this.getObject(obj);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  search(keyword: string) {
    combineLatest([
      this.eaEmployeeId$,
      this.userOffices$,
      this.isAdmin$,
      this.superSearchTemplate$,
    ])
      .pipe(
        first(),
        map(([eaEmployeeId, userOffices, isAdmin, superSearchTemplate]) => {
          const eaOfficeIds = userOffices.join(",");
          return {
            ...this.params,
            keyword,
            eaEmployeeId,
            eaOfficeId: eaOfficeIds,
            isAdmin,
            superSearchTemplate,
          };
        }),
        switchMap((parameters) => this.searchObjectService.doSearch(parameters))
      )
      .subscribe((value) => {
        this.objectService.upsertManyInCache(value);
        this.data = value;
      });
  }

  getObject(eaOid: string) {
    this.objectService.getById(eaOid).subscribe((value) => {
      this.object = value;
    });
  }

  itemClick(object) {
    this.markAsTouched();
    if (!this.disabled) {
      this.object = object;
      this.value = object.eaOid;
      this.onChange(object.eaOid);
      this.objectSelectedChange.emit(this.object);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  clear() {
    this.data = [];
    this.object = null;
    this.value = "";
    this.onChange("");
  }
}
