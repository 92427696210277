<h4
  class="title"
  matTooltip="{{ 'reached_sales_targets' | translate }}"
  matTooltipClass="q-tooltip"
>
  {{ "reached_sales_targets" | translate }}
</h4>
<div class="achieved-targets">
  <span class="percent">{{ number }}%</span>
</div>
