import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getContact } from "@app/contacts/contact.selectors";
import { AuthService } from "@app/core/services/auth/auth.service";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { Contact, Employee, Office } from "@app/models";
import { slideInOutAnimation } from "@app/shared/animations";
import * as addContactToCallingListActions from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.actions";
import * as fromAddContactToCallingList from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.reducer";
import * as fromContactSummary from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_BROKER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  combineLatest as observableCombineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "add-contact-to-calling-list",
  animations: [slideInOutAnimation()],
  template: `
    <div (clickOutside)="hideDropDown()">
      <app-q-button
        [label]="'add_to_calling_list'"
        [icon]="'list'"
        [buttonType]="'save'"
        [fullWidth]="true"
        (click)="openModal(addToCallingList)"
      ></app-q-button>
      <ng-template #addToCallingList>
        <div class="modal-header">
          <h4 class="modal-title pull-left" translate>add_to_calling_list</h4>
          <button
            type="button"
            class="close pull-right"
            (click)="closeModal($event)"
          >
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body scrollable">
          <checkbox-select
            [selectedList]="userCallingLists$ | async"
            [unselectedList]="callingLists$ | async"
            [entityName]="contactName"
            [inputShowDropdown]="true"
            [isBroker]="isBroker()"
            (handleAddToList)="handleAddToListClick($event)"
            (handleRemoveFromList)="handleRemoveFromList($event)"
          >
          </checkbox-select>
        </div>
      </ng-template>
    </div>
  `,
  styleUrls: ["./add-contact-to-calling-list.component.scss"],
})
export class AddContactToCallingListComponent implements OnInit, OnDestroy {
  @Input() contactId: string;
  @Input() contactName: string;
  callingLists$: Observable<CallingList[]>;
  userCallingLists$: Observable<CallingList[]>;
  loading$: Observable<boolean>;
  changing$: Observable<boolean>;
  employee$: Observable<Employee>;
  office$: Observable<Office>;
  contactFromContactSummary$: Observable<Contact>;
  contactFromContactCard$: Observable<Contact>;
  unsubscribe$: Subject<void> = new Subject<void>();
  eaEmployeeId: string;
  animationState = "out";
  modalRef: BsModalRef;

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.contactFromContactSummary$ = this.store.pipe(
      select(fromContactSummary.getContact)
    );
    this.contactFromContactCard$ = this.store.pipe(select(getContact));
    this.callingLists$ = this.store.pipe(
      select(fromAddContactToCallingList.getCallingLists)
    );
    this.userCallingLists$ = this.store.pipe(
      select(fromAddContactToCallingList.getUserCallingList)
    );
    this.loading$ = this.store.pipe(
      select(fromAddContactToCallingList.getCallingListsLoading)
    );
    this.changing$ = this.store.pipe(
      select(fromAddContactToCallingList.getChanging)
    );
    this.employee$.subscribe(
      (employee: Employee) => (this.eaEmployeeId = employee.eaEmployeeId)
    );
    this.handleChange();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit(e) {
    if (e.value) {
      this.store.dispatch(
        addContactToCallingListActions.storeContactToCallingListRequest({
          contactId: this.contactId,
          callingListId: e.value,
        })
      );
    }
  }

  openModal(template: TemplateRef<unknown>) {
    this.modalRef = this.modalService.show(template, { animated: false });
  }

  closeModal(event: Event) {
    event.stopPropagation();
    this.modalRef.hide();
  }

  toggleShowDiv() {
    this.animationState = this.animationState === "out" ? "in" : "out";
  }

  hideDropDown() {
    this.animationState = "out";
  }

  getCallingLists() {
    observableCombineLatest([this.employee$, this.office$])
      .pipe(
        map(([employee, office]) => ({
          employee,
          office,
        })),
        takeUntil(this.unsubscribe$),
        first()
      )
      .subscribe(({ employee, office }) => {
        let filters: {} = { eaOfficeId: office.eaOfficeId };

        if (this.authService.hasRole(ROLE_BROKER)) {
          filters = { eaEmployeeId: employee.eaEmployeeId };
        }

        this.store.dispatch(
          addContactToCallingListActions.addContactToCallingListRequest({
            filters,
          })
        );
      });
  }

  handleChange() {
    this.changing$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isChanging) => {
        if (!isChanging) {
          this.getUserCallingLists();
          this.getCallingLists();
        }
      });
  }

  getUserCallingLists() {
    observableCombineLatest([
      this.employee$,
      this.office$,
      this.contactFromContactSummary$,
      this.contactFromContactCard$,
    ])
      .pipe(
        map(
          ([
            employee,
            office,
            contactFromContactSummary,
            contactFromContactCard,
          ]) => ({
            employee,
            office,
            contactFromContactSummary,
            contactFromContactCard,
          })
        ),
        takeUntil(this.unsubscribe$),
        filter(({ contactFromContactSummary, contactFromContactCard }) => {
          return !!contactFromContactSummary || !!contactFromContactCard;
        }),
        first()
      )
      .subscribe(({ employee, office }) => {
        if (!!this.contactId) {
          let payload: {} = { eaOfficeId: office.eaOfficeId };

          if (this.authService.hasRole(ROLE_BROKER)) {
            payload = { eaEmployeeId: employee.eaEmployeeId };
          }

          this.store.dispatch(
            addContactToCallingListActions.getUserCallingListRequest({
              filters: {
                ...payload,
                contactId: this.contactId,
              },
            })
          );
        }
      });
  }

  handleAddToListClick(callingListId) {
    this.store.dispatch(
      addContactToCallingListActions.storeContactToCallingListRequest({
        contactId: this.contactId,
        callingListId,
      })
    );
  }

  handleRemoveFromList(callingListId) {
    this.store.dispatch(
      addContactToCallingListActions.removeContactFromCallingListRequest({
        contactId: this.contactId,
        callingListId,
      })
    );
  }

  isBroker() {
    return this.authService.hasRole(ROLE_BROKER);
  }
}
