<div disableNavigation class="task-actions-container">
  <div disableNavigation>
    <app-q-button disableNavigation
                  buttonType="save"
                  label="assign_to_me"
                  icon="user"
                  [fullWidth]="true"
                  (click)="assignToSelf()">
    </app-q-button>
  </div>
  <div disableNavigation>
    <app-q-button disableNavigation
                  *ngIf="isManagerOrAdmin$ | async"
                  buttonType="save"
                  label="assign_to_broker"
                  icon="user"
                  [fullWidth]="true"
                  (click)="openAssignLeadModal()">
    </app-q-button>
  </div>
  <div disableNavigation>
    <app-q-button disableNavigation
                  buttonType="save"
                  label="assign_to_office"
                  icon="building"
                  [fullWidth]="true"
                  (click)="openAssignLeadToOfficeModal()">
    </app-q-button>
  </div>
</div>
