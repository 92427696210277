import { Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "textarea-counter",
  templateUrl: "./textarea-counter.component.html",
  styleUrls: ["./textarea-counter.component.scss"],
})
export class TextareaCounterComponent {
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName: string;
  @Input() maxLength: number;
  @Input() minHeight: string;
  currentLength = 0;

  countWords(event): void {
    this.currentLength = event.target.textLength;
  }
}
