<div class="wrapper">
  <standalone-sidebar-header (onClose)="closeTab()"
    >{{ label | translate }}
  </standalone-sidebar-header>
  <div class="scroll-view">
    <form [formGroup]="createCallingListForm">
      <section>
        <h4 *ngIf="contactIdList && contactIdList.length > 0">
          {{
            "selected_no_of_items" | translate : { items: contactIdList.length }
          }}
        </h4>
        <p
          *ngIf="
            (contactIdList && contactIdList.length > createCallingListLimit) ||
            uploadedContacts$.value.length > createCallingListLimit
          "
          class="error"
          translate
        >
          create_calling_list_to_many_contacts_error
        </p>
        <div class="section-label">{{ "title" | translate }}*</div>
        <div class="form-group">
          <input
            type="text"
            formControlName="title"
            [class.invalid]="
              createCallingListForm.get('title').invalid &&
              createCallingListForm.get('title').touched
            "
          />
        </div>
        <div ngxErrors="title">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
        <ng-container *ngIf="contactIdList.length === 0">
          <div class="form-group margin-top-1">
            <input
              #fileUpload
              type="file"
              accept=".csv"
              (change)="uploadContactsFile($event)"
              onClick="this.value=null"
            />
            <div class="file-upload">
              <div class="create-task">
                <app-q-button
                  [buttonType]="'save'"
                  [icon]="'file-upload'"
                  [label]="'upload_file'"
                  [isLoading]="isUploading$ | async"
                  tooltipText="calling_list_file_upload_tooltip"
                  (click)="fileUpload.click()"
                >
                </app-q-button>

                <div
                  style="
                    margin-left: 1rem;
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                  "
                  (click)="showUploadTooltip = !showUploadTooltip"
                >
                  <app-icon
                    name="question-circle"
                    size="medium"
                    [matTooltip]="'info' | translate"
                  >
                  </app-icon>
                </div>
              </div>
              <div class="margin-top-1">
                <app-q-card *ngIf="!!fileName">
                  <ng-container slot="title">
                    {{ fileName }}
                  </ng-container>
                  <ng-container slot="actions">
                    <button type="button" (click)="removeUploadedFile()">
                      <app-icon name="times" size="large"></app-icon>
                    </button>
                  </ng-container>
                </app-q-card>
              </div>
            </div>
          </div>
          <div class="form-group" *ngIf="!!showUploadTooltip">
            <div class="alert alert-info">
              {{ "calling_list_columns_import_info" | translate }}:<br />
              firstName,<br />
              familyName,<br />
              msisdn,<br />
              email,<br />
              street,<br />
              zip,<br />
              city <br /><br />
              <a href="/assets/calling_list_template.csv" download translate
                >template_csv_file</a
              >
              <br /><br />
              {{ "calling_list_file_upload_tooltip" | translate }}
            </div>
          </div>
        </ng-container>
        <app-advanced-office-employee-dropdown
          [officeFormControl]="createCallingListForm.get('office')"
          [employeeFormControl]="createCallingListForm.get('employee')"
          [officeRequired]="true"
          [employeeRequired]="true"
          [officeMultiple]="false"
          [employeeMultiple]="true"
          [setDefaults]="!(isAdmin$ | async)"
          [sideBySide]="false"
          [availabilityMode]="
            (hasAssistantPermission$ | async) ? 'medium' : 'restrictive'
          "
        ></app-advanced-office-employee-dropdown>
        <div ngxErrors="office">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
        <div ngxErrors="employee">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
        <ng-container *ngIf="contactIdList.length === 0">
          <div class="section-label margin-top-1">
            {{ "source" | translate }}
          </div>
          <div class="form-group">
            <input type="text" formControlName="importSource" />
          </div>
        </ng-container>
        <div class="form-group" *ngIf="isAdminOrManager$ | async">
          <div class="alert alert-info margin-top-1" translate>
            calling_list_multiple_brokers
          </div>
        </div>
        <div
          class="form-group batch input-checkbox-container ng-star-inserted create-task"
        >
          <input
            type="checkbox"
            formControlName="createTask"
            class="checkbox"
          />
          <span translate>create_task</span>
        </div>
        <div
          class="form-group"
          [style.display]="
            createCallingListForm.get('createTask').value ? 'block' : 'none'
          "
        >
          <app-quick-date-options (dateClicked)="setDateFromNow($event)">
          </app-quick-date-options>
          <form-input-datepicker-dropdown
            [group]="createCallingListForm"
            controlName="deliveryDate"
          >
          </form-input-datepicker-dropdown>
        </div>
      </section>
    </form>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      [buttonType]="'cancel'"
      [label]="'cancel'"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="create_empty_calling_list"
      [isLoading]="loading$ | async"
      [disabled]="loading$ | async"
      (click)="submitEmptyCallingList()"
    >
    </app-q-button>
    <app-q-button
      [buttonType]="'save'"
      [label]="'save'"
      [isLoading]="loading$ | async"
      [disabled]="loading$ | async"
      (click)="submit()"
      scrollToInvalidControl
      [formEl]="createCallingListForm"
    >
    </app-q-button>
  </div>
</div>
