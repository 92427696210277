<div class="q-input">
  <label class="q-input__label">{{label | translate}}</label>
  <input [type]="type"
         [placeholder]="placeholder | translate"
         class="q-input__control"
         [class.invalid]="invalid"
         [class.disabled]="disabled"
         [ngModel]="value"
         (ngModelChange)="onTextChange($event)"
         (focus)="markAsTouched()"
         [disabled]="disabled"
         [mask]="mask"
         [thousandSeparator]="thousandSeparator"
         [attr.inputmode]="inputMode"
  >
</div>
