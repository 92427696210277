import { ContactConsent } from "@app/contacts/contact-consents/models";
import { RESIDENCE_UNKNOWN } from "@app/contacts/utils/profile-residence-status-constants";
import { Contact, Note, SalesMeeting } from "@app/models";
import * as ContactSummaryActions from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface ContactSummaryState {
  contact: Contact | null;
  consents: ContactConsent[];
  numberOfTasks: number;
  numberOfObjects: number;
  hasPriceUpdateOrder: boolean;
  hasAlert: boolean;
  salesMeetings: SalesMeeting[];
  ownsResidence: string;
  notes: Note[];
  loading: boolean;
}

export const initialState: ContactSummaryState = {
  contact: null,
  consents: [],
  numberOfTasks: 0,
  numberOfObjects: 0,
  hasPriceUpdateOrder: false,
  hasAlert: false,
  salesMeetings: [],
  ownsResidence: RESIDENCE_UNKNOWN,
  notes: [],
  loading: false,
};

export function contactSummaryReducer(
  state: ContactSummaryState,
  action: Action
): ContactSummaryState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(ContactSummaryActions.fetchContactSummaryRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    ContactSummaryActions.fetchContactSummarySuccess,
    (state, { payload }) => ({
      ...state,
      contact: new Contact(payload[0]),
      consents: payload[1]["consents"],
      numberOfTasks: payload[2]["total"],
      numberOfObjects: payload[3]["rows"].length,
      hasPriceUpdateOrder: payload[4]["total"] > 0,
      hasAlert: payload[5]["rows"].length > 0,
      salesMeetings: payload[6]["rows"],
      ownsResidence: payload[7]["ownsResidence"],
      notes: payload[8]["rows"].slice(0, 3),
      loading: false,
    })
  ),
  on(ContactSummaryActions.fetchContactSummaryFail, (state) => ({
    ...state,
    contact: null,
    loading: false,
  })),
  on(ContactSummaryActions.reset, () => initialState)
);

const selectFeature =
  createFeatureSelector<ContactSummaryState>("contactSummary");

export const getContact = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.contact
);

export const getConsents = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.consents
);

export const getNumberOfTasks = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.numberOfTasks
);

export const getNumberOfObjects = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.numberOfObjects
);

export const hasPriceUpdateOrder = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.hasPriceUpdateOrder
);

export const hasAlert = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.hasAlert
);

export const getSalesMeetings = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.salesMeetings
);

export const ownsResidence = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.ownsResidence
);

export const getNotes = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.notes
);

export const isLoading = createSelector(
  selectFeature,
  (state: ContactSummaryState) => state.loading
);
