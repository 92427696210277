import { createAction, props } from "@ngrx/store";
import { Tag } from "@app/models/tags";

export const getGlobalTagsRequest = createAction(
  "[Tags] GET_GLOBAL_TAGS_REQUEST"
);

export const getGlobalTagsSuccess = createAction(
  "[Tags] GET_GLOBAL_TAGS_SUCCESS",
  props<{ tags: Tag[] }>()
);

export const getGlobalTagsFail = createAction("[Tags] GET_GLOBAL_TAGS_FAIL");

export const getLocalTagsRequest = createAction(
  "[Tags] GET_LOCAL_TAGS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getLocalTagsSuccess = createAction(
  "[Tags] GET_LOCAL_TAGS_SUCCESS",
  props<{ tags: Tag[] }>()
);

export const getLocalTagsFail = createAction("[Tags] GET_LOCAL_TAGS_FAIL");

export const storeTagRequest = createAction(
  "[Tags] STORE_TAG_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeTagSuccess = createAction("[Tags] STORE_TAG_SUCCESS");

export const storeTagFail = createAction("[Tags] STORE_TAG_FAIL");

export const updateTagRequest = createAction(
  "[Tags] UPDATE_TAG_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateTagSuccess = createAction("[Tags] UPDATE_TAG_SUCCESS");

export const updateTagFail = createAction("[Tags] UPDATE_TAG_FAIL");

export const deleteTagRequest = createAction(
  "[Tags] DELETE_TAG_REQUEST",
  props<{ tagSuggestionId: string; deleteContactTags: boolean }>()
);

export const deleteTagSuccess = createAction("[Tags] DELETE_TAG_SUCCESS");

export const deleteTagFail = createAction("[Tags] DELETE_TAG_FAIL");
