import * as actions from "@app/settings/object-statistics-providers/ngrx/object-statistics-providers.actions";
import { ObjectStatisticsProvider } from "@app/showings/models/object-statistics";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface SettingsObjectStatisticsProvidersState {
  providers: ObjectStatisticsProvider[];
  loading: boolean;
  storing: boolean;
  deleting: boolean;
}

export const initialState: SettingsObjectStatisticsProvidersState = {
  providers: null,
  loading: false,
  storing: false,
  deleting: false,
};

export function settingsObjectStatisticsProvidersReducer(
  state: SettingsObjectStatisticsProvidersState,
  action: Action
): SettingsObjectStatisticsProvidersState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getObjectStatisticsProvidersRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.getObjectStatisticsProvidersSuccess, (state, { providers }) => ({
    ...state,
    loading: false,
    providers,
  })),
  on(actions.getObjectStatisticsProvidersFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(
    actions.storeObjectStatisticsProvidersRequest,
    actions.updateObjectStatisticsProvidersRequest,
    (state) => ({
      ...state,
      storing: true,
    })
  ),
  on(
    actions.storeObjectStatisticsProvidersSuccess,
    actions.storeObjectStatisticsProvidersFailed,
    actions.updateObjectStatisticsProvidersSuccess,
    actions.updateObjectStatisticsProvidersFailed,
    (state) => ({
      ...state,
      storing: false,
    })
  ),
  on(actions.deleteObjectStatisticsProvidersRequest, (state) => ({
    ...state,
    deleting: true,
  })),
  on(
    actions.deleteObjectStatisticsProvidersSuccess,
    actions.deleteObjectStatisticsProvidersFailed,
    (state) => ({
      ...state,
      deleting: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.objectStatisticsProviders
);

export const getObjectStatisticsProviders = createSelector(
  getState,
  (state: SettingsObjectStatisticsProvidersState) => state.providers
);

export const getObjectStatisticsProvidersLoading = createSelector(
  getState,
  (state: SettingsObjectStatisticsProvidersState) => state.loading
);

export const getObjectStatisticsProvidersStoring = createSelector(
  getState,
  (state: SettingsObjectStatisticsProvidersState) => state.storing
);

export const getObjectStatisticsProvidersDeleting = createSelector(
  getState,
  (state: SettingsObjectStatisticsProvidersState) => state.deleting
);
