<h4 translate>interest_level</h4>
<div class="btn-group">
  <button
    [class.active]="isSelected(Status.Bidder)"
    (click)="handleClick(Status.Bidder)"
    type="button"
    class="btn btn-default"
    translate
  >bidder
  </button>

  <button
    [class.active]="isSelected(Status.Interested)"
    (click)="handleClick(Status.Interested)"
    type="button"
    class="btn btn-default"
    translate
  >yes
  </button>

  <button
    [class.active]="isSelected(Status.NotInterested)"
    (click)="handleClick(Status.NotInterested)"
    type="button"
    class="btn btn-default"
    translate
  >no
  </button>
</div>

<ng-content select="[bidding]"></ng-content>
