<div class="q-card" [class]="'q-card--' + color" [class.disabled]="disabled">
  <div class="q-card__content">
    <h4 class="q-card__title">
      <ng-content select="[slot=title]"></ng-content>
    </h4>
    <ng-content select="[slot=body]"></ng-content>
  </div>
  <div class="q-card__actions">
    <ng-content select="[slot=actions]"></ng-content>
  </div>
</div>
