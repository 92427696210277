import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as objectNotesActions from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.actions";
import {
  selectData,
  selectIsLoading,
} from "@app/widgets/widgets/object-widgets/object-notes-widget/ngrx/object-notes-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { filter, first, Observable } from "rxjs";

@Component({
  selector: "app-note-list",
  templateUrl: "./note-list.component.html",
  styleUrls: ["./note-list.component.scss"],
})
export class NoteListComponent implements OnInit, OnChanges {
  @Input() eaOid: string;

  data$: Observable<any>;
  isLoading$: Observable<any>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.store.dispatch(
        objectNotesActions.getNotesRequest({ eaOid: this.eaOid })
      );
    }
  }

  mapStateToProps() {
    this.data$ = this.store.select(selectData);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  formatDate(date: string): string {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  deleteNote(externalId: string) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_note_header",
        message: "remove_note_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        first()
      )
      .subscribe(() =>
        this.store.dispatch(
          objectNotesActions.deleteNoteRequest({
            eaOid: this.eaOid,
            externalId,
          })
        )
      );
  }
}
