import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "kpi-sold-objects-commission",
  templateUrl: "./kpi-sold-objects-commission.component.html",
  styleUrls: ["./kpi-sold-objects-commission.component.scss"],
})
export class KpiSoldObjectsCommissionComponent {
  @Input() resources$: Observable<any>;

  constructor(private router: Router) {}

  navigateToObject(eaOid: string, event: Event): void {
    event.preventDefault();
    this.router.navigate([
      "/crm",
      {
        outlets: {
          primary: ["showings", eaOid],
          sidebar: null,
          modal: [],
        },
      },
    ]);
  }
}
