import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getExternalLinks } from "@app/core/components/search/ngrx/search.actions";
import {
  getExternalProviderLinks,
  getExternalProviderLinksLoading,
} from "@app/core/components/search/ngrx/search.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { collapsedTrigger, fadeInUp, fadeInUp2 } from "@app/shared/animations";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { MAX_WIDTH_SM } from "@app/shared/utils/screen-sizes";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable, Subscription } from "rxjs";
import { SearchService } from "../../services/search.service";

type SearchResultType = "contacts" | "objects" | "leads" | "employees";

@Component({
  selector: "search-result-column",
  templateUrl: "./column.component.html",
  styleUrls: ["./column.component.scss"],
  animations: [collapsedTrigger(), fadeInUp2(), fadeInUp()],
})
export class SearchResultsColumnComponent implements OnInit, OnDestroy {
  @ViewChild("header", { static: true })
  header: ElementRef;
  @Input() data: any[];
  @Input() loaded: boolean;
  @Input() type: SearchResultType;
  @Input() hits: number;
  @Output() showGroupedResults: EventEmitter<string> = new EventEmitter();

  storeSubscription: Subscription;
  scrolledSubscription: Subscription;
  scrolled$ = this.searchService.getScrollSubject();
  userId$: Observable<string>;
  showExternalProviderObjectLinks$: Observable<boolean>;
  externalProvider$: Observable<ExternalProviderFeature>;
  externalProviderLinks$: Observable<any[]>;
  externalProviderLinksLoading$: Observable<boolean>;
  keyword = "";
  isMobileView: boolean;
  headerShouldSnap: boolean;
  contactsIsVisible = true;
  objectsIsVisible = true;
  leadsIsVisible = true;
  employeesIsVisible = true;

  ngOnInit() {
    this.storeSubscription = this.store
      .pipe(select(fromShared.getInnerWidth))
      .subscribe((width) => {
        if (width < MAX_WIDTH_SM) {
          this.isMobileView = true;
        } else {
          this.resetSnappingHeader();
          this.isMobileView = false;
        }
      });

    this.store
      .select((state) => state.search)
      .pipe(map((search) => search.keyword))
      .subscribe((keyword) => (this.keyword = keyword));

    this.mapStateToProps();
  }

  mapStateToProps(): void {
    this.scrolledSubscription = this.scrolled$
      .pipe(filter(() => this.isMobileView))
      .subscribe(
        () =>
          (this.headerShouldSnap = this.searchService.headerShouldSnap(
            this.header
          ))
      );
    this.userId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.showExternalProviderObjectLinks$ = this.store.select(
      hasIntegration(IntegrationResource.ObjectLinks)
    );
    this.externalProvider$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER))
    );
    this.externalProviderLinks$ = this.store.pipe(
      select(getExternalProviderLinks)
    );
    this.externalProviderLinksLoading$ = this.store.pipe(
      select(getExternalProviderLinksLoading)
    );
  }

  onTitleClicked(type) {
    this.showGroupedResults.emit(type);
  }

  resetSnappingHeader() {
    this.headerShouldSnap = false;
  }

  toggleVisibility() {
    if (this.isMobileView) {
      switch (this.type) {
        case "contacts":
          this.contactsIsVisible = !this.contactsIsVisible;
          break;
        case "objects":
          this.objectsIsVisible = !this.objectsIsVisible;
          break;
        case "leads":
          this.leadsIsVisible = !this.leadsIsVisible;
          break;
        case "employees":
          this.employeesIsVisible = !this.employeesIsVisible;
          break;
        default:
          break;
      }
    }
  }

  animationDone() {
    this.headerShouldSnap = this.searchService.headerShouldSnap(this.header);
  }

  getColumnVisibility() {
    switch (this.type) {
      case "contacts":
        return this.contactsIsVisible;
      case "objects":
        return this.objectsIsVisible;
      case "leads":
        return this.leadsIsVisible;
      case "employees":
        return this.employeesIsVisible;
    }
  }

  ngOnDestroy() {
    if (this.storeSubscription) {
      this.storeSubscription.unsubscribe();
    }
    if (this.scrolledSubscription) {
      this.scrolledSubscription.unsubscribe();
    }
  }

  getExternalProviderLinks(eaOid: string): void {
    this.store.dispatch(getExternalLinks({ eaOid }));
  }

  constructor(
    private store: Store<AppState>,
    private searchService: SearchService
  ) {}
}
