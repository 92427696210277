import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable()
export class TasksDefaultService {
  showAssign = new BehaviorSubject(false);

  getShowAssign(): Observable<boolean> {
    return this.showAssign.asObservable();
  }

  onShowAssign(): void {
    this.showAssign.next(true);
  }

  onShowManage(): void {
    this.showAssign.next(false);
  }
}
