import { SUPPORT } from "@app/shared/utils/tab-types";
import { SupportGuard } from "@app/sidebar/guards/support-guard.service";
import { SupportComponent } from "@app/sidebar/support/containers/support/support.component";

export const sidebarSupportRoutes = [
  {
    path: "support",
    component: SupportComponent,
    canActivate: [SupportGuard],
    data: { tabType: SUPPORT },
  },
];
