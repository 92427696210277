<form [formGroup]="form">
  <section class="form__control">
    <label for="name" translate>name</label>
    <div>
      <input
        id="name"
        type="text"
        formControlName="name"
        [class]="
          !form.get('name').valid && form.get('name').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="name">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
  <section class="form__control">
    <label for="description" translate>description</label>
    <div>
      <input id="description" type="text" formControlName="description" />
    </div>
  </section>
  <section class="form__control">
    <label for="taskExpirationDateInHours" translate
      >task_expiration_date_in_hours</label
    >
    <div>
      <input
        id="taskExpirationDateInHours"
        type="text"
        placeholder="0"
        formControlName="taskExpirationDateInHours"
        [class]="
          !form.get('taskExpirationDateInHours').valid &&
          form.get('taskExpirationDateInHours').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="taskExpirationDateInHours">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
</form>
