import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { Store } from "@ngrx/store";
import { BehaviorSubject, map, Observable } from "rxjs";

@Injectable()
export class SearchService {
  scrolled$ = new BehaviorSubject({});

  constructor(
    private localStorageService: LocalStorageService,
    private store: Store<AppState>
  ) {}

  setLatestSearch(keyword) {
    if (keyword.trim().length >= 2) {
      const existingLatestSearches = this.getLatestSearches().replace(
        keyword,
        ""
      );
      const newLatestSearches = existingLatestSearches
        .split(",")
        .filter((value) => value.length > 0)
        .concat(keyword)
        .slice(-20)
        .join(",");
      this.localStorageService.saveUserPreference(
        "latest_searches",
        newLatestSearches
      );
    }
  }

  getLatestSearches(): string {
    let latestSearches =
      this.localStorageService.fetchUserPreference("latest_searches");
    if (latestSearches === null) {
      latestSearches = "";
    }
    return latestSearches;
  }

  getDistanceFromTop(element) {
    let distanceFromTop = 0;

    while (element) {
      distanceFromTop +=
        element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return distanceFromTop;
  }

  emitScroll() {
    this.scrolled$.next({});
  }

  getScrollSubject() {
    return this.scrolled$;
  }

  headerShouldSnap(header) {
    const { nativeElement } = header;

    if (nativeElement.children.length > 1) {
      const columnElement = nativeElement.children[1];
      const columnHeight = columnElement.offsetHeight;
      const distance = this.getDistanceFromTop(columnElement);
      const isWithinMinDistance = distance > 48 - columnHeight;
      const isWithinMaxDistance = distance <= 100;
      return columnHeight > 0 && isWithinMinDistance && isWithinMaxDistance;
    }
  }

  formatKeywordIfPhoneNumber(keyword): Observable<string> {
    return this.store
      .select((state) => state.config)
      .pipe(
        map((config) => config.app.general.phone_prefix),
        map((prefix) => this.buildNewPhoneKeyword(prefix, keyword))
      );
  }

  buildNewPhoneKeyword(prefix, keyword): string {
    const originalKeyword = keyword;
    const regex = /-|\s|^00|^0|\+/g;

    if (keyword.match(regex)) {
      // Remove +, -, whitespace and 00 and 0 (must be first character)
      keyword = keyword.replace(regex, "");
    }

    if (keyword.startsWith(prefix)) {
      keyword = keyword.replace(prefix, "");
    }

    return !isNaN(keyword) ? prefix + keyword : originalKeyword;
  }
}
