import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Employee, Office } from "@app/models";

@Component({
  selector: "select-office-item",
  templateUrl: "./select-office-item.component.html",
  styleUrls: ["./select-office-item.component.scss"],
})
export class SelectOfficeItemComponent {
  @Input() employee: Employee;
  @Input() office: Office;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<any>();

  selectOfficeAndLogin(employee, office): void {
    this.onClick.emit({ employee, office });
  }
}
