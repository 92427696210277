import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { QObject } from "@app/models";
import { ShowingObject } from "@app/showings/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { ModalDirective } from "ngx-bootstrap/modal";
import { filter, map, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-object-overview-widget",
  templateUrl: "./object-overview-widget.component.html",
  styleUrls: [
    "./object-overview-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectOverviewWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild("videoModal", { static: false }) videoModal: ModalDirective;

  @Input() eaOid: string;

  property$: Observable<ShowingObject>;
  object$: Observable<QObject>;
  videoSrc = "";

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.property$ = this.objectService.entityMap$.pipe(
        map((entities) => entities[this.eaOid]),
        takeUntil(this.unsubscribe$),
        filter((entity) => !!entity)
      );
    }
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.object$ = this.store.pipe(select(fromShowings.getObject));
  }

  isVideo(video) {
    return (
      video?.objectLinkUrl.substring(video.objectLinkUrl.length - 3) === "mp4"
    );
  }

  playVideo(url) {
    this.videoSrc = url;
    this.videoModal.show();
  }

  closeVideoModal() {
    this.videoModal.hide();
  }

  formatDate(date: string): string {
    return moment(date, "YYYYMMDDHHmmss").format("YYYY-MM-DD");
  }
}
