import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { hasRole } from "@app/shared/user";
import { ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-tooltip-icon",
  templateUrl: "./tooltip-icon.component.html",
  styleUrls: ["./tooltip-icon.component.scss"],
})
export class TooltipIconComponent implements OnInit {
  @Input() translationKey: string;

  displayUntranslatedTooltip$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.displayUntranslatedTooltip$ = this.store.pipe(
      select(hasRole(ROLE_SUPER_ADMIN)),
      map((isSuperAdmin) => {
        return (
          isSuperAdmin &&
          this.localStorageService.fetchUserPreference(
            "show_untranslated_tooltips"
          ) === "true"
        );
      })
    );
  }

  selectAndCopy(e, value): void {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
  }
}
