import { Injectable } from "@angular/core";

// https://quedro.atlassian.net/wiki/spaces/QCRM/pages/35815465/Action+Codes
export enum ContactActionCodes {
  // CALLS
  CONSUMER_SALES_CALL_REGISTERED = 4201,
  CONSUMER_CALL_REGISTERED = 4202,

  // MEETINGS
  CONSUMER_MEETING_STARTED = 4102,

  // LEAD
  CONSUMER_LEAD_RESOLVED = 4502,

  // TASK
  CONSUMER_TASK_COMPLETED = 4302,

  // CRM
  CONSUMER_CRM_PREGENERTATED = 2201,
  CONSUMER_CRM_DELETED = 2202,
  CONSUMER_CRM_SENT = 2203,

  // ONE2ONE
  CONSUMER_ONE2ONE_SMS = 2101,
  CONSUMER_ONE2ONE_EMAIL = 2102,
  CONSUMER_OBJECT_EMAIL = 2103,

  // BASIC -> NOTE
  CONSUMER_NOTE_STORED = 3204,
  CONSUMER_OBJECT_NOTE_STORED = 3205,
}

@Injectable()
export class ContactActionService {
  getIcon(type: string | number): string {
    const number = Number(type);
    type = isNaN(number) ? type : number;

    switch (type) {
      case 4201:
      case 4202:
      case ContactActionCodes[4202]:
      case ContactActionCodes[4201]:
        return "phone";

      case 2101:
      case ContactActionCodes[2101]:
        return "comment-dots";

      case 2102:
      case ContactActionCodes[2102]:
        return "envelope-open";

      case 2103:
      case ContactActionCodes[2103]:
        return "envelope";

      case 2203:
      case ContactActionCodes[2203]:
        return "envelope";

      default:
        return "";
    }
  }
}
