import { SearchProfile } from "@app/contacts/contact-search-profile/models/search-profile";
import { CategoryType } from "@app/models/category-type";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import { createAction, props } from "@ngrx/store";

export const getSearchProfilesRequest = createAction(
  "[Search Profiles] GET_SEARCH_PROFILES_REQUEST",
  props<{ contactId: string }>()
);

export const getSearchProfilesSuccess = createAction(
  "[Search Profiles] GET_SEARCH_PROFILES_SUCCESS",
  props<{ searchProfiles: SearchProfile[] }>()
);

export const getSearchProfilesFailed = createAction(
  "[Search Profiles] GET_SEARCH_PROFILES_FAILED"
);

export const getSearchProfileRequest = createAction(
  "[Search Profiles] GET_SEARCH_PROFILE_REQUEST",
  props<{ searchProfileId: string }>()
);

export const getSearchProfileSuccess = createAction(
  "[Search Profiles] GET_SEARCH_PROFILE_SUCCESS",
  props<{ searchProfile: SearchProfile }>()
);

export const getSearchProfileFailed = createAction(
  "[Search Profiles] GET_SEARCH_PROFILE_FAILED"
);

export const setFiltersOfRelatedObjects = ({
  params,
}: {
  params: Record<string, unknown>;
}) => {
  if (!!params.objectCategories) {
    params["objectAndCategories"] = params.objectCategories;
    delete params.objectCategories;
  }
  return setRelatedObjectsFilter({ params });
};

export const setRelatedObjectsFilter = createAction(
  "[Search Profiles] SET_RELATED_OBJECTS_FILTER",
  props<{ params: Record<string, unknown> }>()
);

export const setFiltersOfRelatedWaitingObjects = ({
  params,
}: {
  params: Record<string, unknown>;
}) => {
  if (!!params.objectCategories) {
    params["objectAndCategories"] = params.objectCategories;
    delete params.objectCategories;
  }
  return setRelatedWaitingObjectsFilter({ params });
};

export const setRelatedWaitingObjectsFilter = createAction(
  "[Search Profiles] SET_RELATED_WAITING_OBJECTS_FILTER",
  props<{ params: Record<string, unknown> }>()
);

export const getAlertsRequest = createAction(
  "[Search Profiles] GET_ALERTS_REQUEST",
  props<{ contactId: string }>()
);

export const getAlertsSuccess = createAction(
  "[Search Profiles] GET_ALERTS_SUCCESS",
  props<{ alerts: Alert[] }>()
);

export const getAlertsFailed = createAction(
  "[Search Profiles] GET_ALERTS_FAILED"
);

export const getAdditionalFiltersRequest = createAction(
  "[Search Profiles] GET_ADDITIONAL_FILTERS_REQUEST",
  props<{ categoryIds: string; country: string }>()
);

export const getAdditionalFiltersSuccess = createAction(
  "[Search Profiles] GET_ADDITIONAL_FILTERS_SUCCESS",
  props<{ categoryTypes: CategoryType[] }>()
);

export const getAdditionalFiltersFail = createAction(
  "[Search Profiles] GET_ADDITIONAL_FILTERS_FAIL"
);

export const resetSelectedProfile = createAction(
  "[Search Profiles] RESET SELECTED PROFILE"
);

export const reset = createAction("[Search Profiles] RESET");

export const clearSelectedSearchProfile = createAction(
  "[Search Profiles] CLEAR_SELECTED_SEARCH_PROFILE",
  props<{ searchProfileId?: string }>()
);
