import * as showingAttendanceActions from "@app/sidebar/potential-buyer/ngrx/attendance.ngrx";
import * as biddingStatusActions from "@app/sidebar/potential-buyer/ngrx/bidding.ngrx";
import * as contactProfileActions from "@app/sidebar/potential-buyer/ngrx/contact-profile.ngrx";
import * as contactedStatusActions from "@app/sidebar/potential-buyer/ngrx/contacted-status.ngrx";
import * as noteActions from "@app/sidebar/potential-buyer/ngrx/note.ngrx";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import { Action, createReducer, on } from "@ngrx/store";

export interface LoadingState {
  attendance: boolean;
  note: boolean;
  contactedStatus: boolean;
  profile: boolean;
  bidding: boolean;
}

export const initialState: LoadingState = {
  attendance: false,
  note: false,
  contactedStatus: false,
  profile: false,
  bidding: false,
};

export function loadingReducer(
  state: LoadingState,
  action: Action
): LoadingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(showingAttendanceActions.fetchShowingAttendanceRequest, (state) => ({
    ...state,
    attendance: true,
  })),
  on(
    showingAttendanceActions.fetchShowingAttendanceSuccess,
    showingAttendanceActions.fetchShowingAttendanceFailure,
    (state) => ({ ...state, attendance: false })
  ),
  on(noteActions.fetchNoteRequest, (state) => ({ ...state, note: true })),
  on(noteActions.fetchNoteSuccess, noteActions.fetchNoteFailure, (state) => ({
    ...state,
    note: false,
  })),
  on(contactedStatusActions.fetchContactedStatusRequest, (state) => ({
    ...state,
    contactedStatus: true,
  })),
  on(
    contactedStatusActions.fetchContactedStatusSuccess,
    contactedStatusActions.fetchContactedStatusFailure,
    (state) => ({ ...state, contactedStatus: false })
  ),
  on(contactProfileActions.fetchContactProfileRequest, (state) => ({
    ...state,
    profile: true,
  })),
  on(
    contactProfileActions.fetchContactProfileSuccess,
    contactProfileActions.fetchContactProfileFailure,
    (state) => ({ ...state, profile: false })
  ),
  on(biddingStatusActions.fetchBiddingStatusRequest, (state) => ({
    ...state,
    bidding: true,
  })),
  on(
    biddingStatusActions.fetchBiddingStatusSuccess,
    biddingStatusActions.fetchBiddingStatusFailure,
    (state) => ({ ...state, bidding: false })
  ),
  on(potentialBuyerActions.reset, () => initialState)
);
