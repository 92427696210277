import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { EmployeeDTO } from "@app/models";
import { filter, Subject, takeUntil } from "rxjs";

@Component({
  selector: "employee-dropdown",
  template: `
    <div [formGroup]="parentFormGroup" class="input-iconfield">
      <i class="icon icon-user"></i>
      <select [formControlName]="parentFormControlName">
        <option translate [disabled]="disableSelectBroker" value="">
          select_broker
        </option>
        <option
          *ngFor="let employee of employees"
          [value]="employee.eaEmployeeId"
        >
          {{ employee.fullName }}
        </option>
      </select>
    </div>
  `,
  styleUrls: ["./employee-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmployeeDropdownComponent implements OnDestroy, OnInit {
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName = "employee";
  @Input() employees: EmployeeDTO[] = [];
  @Input() disableSelectBroker = true;
  @Input() extraEmployeeProperty: string;
  @Output() extraEmployeePropertyReady = new EventEmitter<any>();

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.extraEmployeeProperty) {
      this.handleDropdownExtraProperty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleDropdownExtraProperty(): void {
    this.parentFormGroup
      .get(this.parentFormControlName)
      .valueChanges.pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) =>
        this.extraEmployeePropertyReady.emit(
          this.employees.find(
            (employee: EmployeeDTO) => employee.eaEmployeeId === value
          )[this.extraEmployeeProperty]
        )
      );
  }
}
