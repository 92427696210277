import { filter, map } from "rxjs/operators";
import {
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { AppState } from "../../app.reducer";
import { Browser } from "../../shared/config/models";

const SUPPORTED_BROWSERS = {
  Safari: "5.1.7",
  MSIE: "10.0",
  Firefox: "3.6",
  Chrome: "14.0",
  Netscape: "1",
  Edge: "12",
};

export const EVENTS = {
  browser: "BROWSER",
  cookie: "COOKIE",
};

@Component({
  selector: "compatibility-check",
  styleUrls: ["./compatibility-check.component.scss"],
  templateUrl: "./compatibility-check.component.html",
})
export class CompatibilityCheckComponent implements OnInit, OnDestroy {
  @HostBinding("hidden") hidden = true;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onError = new EventEmitter();
  browserName = this.getBrowser("name");
  browserVersion = this.getBrowser("version");
  browserIsSupported = false;
  cookieEnabled = navigator.cookieEnabled;
  subscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.checkIfAppSupportsBrowser();
    this.subscribeToCustomerRejectedBrowsers();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  checkIfAppSupportsBrowser() {
    if (!this.appSupportsBrowser()) {
      this.hidden = false;
      if (!this.cookieEnabled) {
        this.onError.emit({ type: EVENTS.cookie });
      } else {
        this.onError.emit({ type: EVENTS.browser });
      }
    }
  }

  subscribeToCustomerRejectedBrowsers() {
    this.subscription = this.store
      .select((state) => state.config)
      .pipe(
        map((state) => state.app.unsupported_browsers),
        filter((unsupportedBrowsers) => unsupportedBrowsers.length > 0)
      )
      .subscribe((browsers) => {
        if (!this.customerSupportsBrowser(browsers)) {
          this.hidden = false;
          const browser = browsers
            .filter((b) => b.name === this.browserName)
            .pop();
          if (browser) {
            this.onError.emit({ type: EVENTS.browser, browser });
          } else {
            this.onError.emit({ type: EVENTS.cookie });
          }
        }
      });
  }

  customerSupportsBrowser(browsers: Browser[]) {
    const unsupportedBrowser = browsers.filter(
      (browser) => browser.name === this.browserName
    );
    return unsupportedBrowser.length === 0;
  }

  appSupportsBrowser() {
    const keys = Object.keys(SUPPORTED_BROWSERS);

    keys.forEach((browserName) => {
      if (this.browserName === browserName) {
        if (this.browserName.toLowerCase() === "netscape") {
          this.browserIsSupported = true;
          this.browserVersion = "11.0"; // IE11
        } else if (this.isBrowserVersionValid()) {
          this.browserIsSupported = true;
        }
      }
    });

    return this.browserIsSupported && this.cookieEnabled;
  }

  getBrowser(property: string) {
    const { userAgent } = navigator;
    const edge = userAgent.match(/(edge)\/?\s*(\.?\d+(\.\d+)*)/i);
    const pattern =
      /(opera|chrome|safari|firefox|msie|netscape)\/?\s*(\.?\d+(\.\d+)*)/i;
    let match = userAgent.match(pattern);
    let tem;

    if (edge) {
      match = [edge[1], edge[2]];
    } else {
      if (match && (tem = userAgent.match(/version\/([\.\d]+)/i)) != null) {
        match[2] = tem[1];
      }
      match = match ? [match[1], match[2]] : [userAgent, "-?"];
    }

    return property === "version" ? match[1] : match[0];
  }

  isBrowserVersionValid() {
    const minimumVersion = SUPPORTED_BROWSERS[this.browserName];
    const diff = this.compareBrowserVersion(
      this.browserVersion,
      minimumVersion
    );
    return diff >= 0;
  }

  compareBrowserVersion(a, b) {
    let i, cmp;

    const re = /(\.0)+[^\.]*$/;
    a = (a + "").replace(re, "").split(".");
    b = (b + "").replace(re, "").split(".");
    const len = Math.min(a.length, b.length);
    for (i = 0; i < len; i++) {
      cmp = parseInt(a[i], 10) - parseInt(b[i], 10);
      if (cmp !== 0) {
        return cmp;
      }
    }
    return a.length - b.length;
  }
}
