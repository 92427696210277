import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { first, map, Observable, Subject } from "rxjs";

import { AppState } from "@app/app.reducer";
import { LEAD_TEMPLATES } from "@app/shared/utils/tab-types";
import { LeadTemplatesFormComponent } from "@app/sidebar/lead-templates/components/lead-templates-form/lead-templates-form.component";
import * as leadTemplatesFormActions from "@app/sidebar/lead-templates/ngrx/lead-templates-form.actions";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { selectIsWorking } from "@app/sidebar/lead-templates/ngrx/lead-templates-form.reducer";

@Component({
  selector: "app-lead-templates-create",
  templateUrl: "./lead-templates-create.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-templates-create.component.scss",
  ],
})
export class LeadTemplatesCreateComponent
  extends ConnectTab
  implements OnInit, OnDestroy, IConnectableTab
{
  @ViewChild(LeadTemplatesFormComponent, { static: true })
  leadTemplatesForm: LeadTemplatesFormComponent;

  tabType = LEAD_TEMPLATES;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  channelId$: Observable<number>;
  isWorking$: Observable<boolean>;

  constructor(store: Store<AppState>, private route: ActivatedRoute) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.channelId$ = this.route.params.pipe(
      map((params) => params?.channelId)
    );
    this.isWorking$ = this.store.pipe(select(selectIsWorking));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    this.channelId$.pipe(first()).subscribe((id) => {
      this.leadTemplatesForm.submit((body) =>
        this.store.dispatch(
          leadTemplatesFormActions.createLeadTemplateRequest({
            body: { ...body, startTime: 0, eaTaskTypeId: id },
          })
        )
      );
    });
  }
}
