import { SendMessageResponse } from "@app/sidebar/send-message/models";
import { createAction, props } from "@ngrx/store";

export const fetchPreviewRequest = createAction(
  "[Preview] FETCH_PREVIEW_REQUEST",
  props<{
    parameters: {
      templateId: string;
      params: any;
      consumerName?: string;
      showLinksInNewTab?: boolean;
    };
  }>()
);

export const fetchPreviewSuccess = createAction(
  "[Preview] FETCH_PREVIEW_SUCCESS",
  props<{
    parameters: {
      response: SendMessageResponse;
      consumerName?: string;
      showLinksInNewTab?: boolean;
    };
  }>()
);

export const fetchPreviewFail = createAction("[Preview] FETCH_PREVIEW_FAIL");

export const showPreviewModal = createAction(
  "[Preview] SHOW_PREVIEW_MODAL",
  props<{
    parameters: {
      html: string;
      consumerName?: string;
      showLinksInNewTab?: boolean;
    };
  }>()
);

export const hidePreviewModal = createAction("[Preview] HIDE_PREVIEW_MODAL");
