export enum IntegrationResource {
  Contact = "CONTACT",
  Object = "OBJECT",
  ObjectLinks = "OBJECT_LINKS",
  GetBidsList = "GET_BIDS_LIST",
  CreateBid = "CREATE_BID",
  DeleteBid = "DELETE_BID",
  RefreshBidsList = "REFRESH_BIDS_LIST",
  Consent = "CONSENT",
  SetSpeculatorStatus = "SET_SPECULATOR_STATUS",
  GetSpeculatorStatus = "GET_SPECULATOR_STATUS",
  SalesMeetingExternalCreationUrlGeneration = "SALES_MEETING_EXTERNAL_CREATION_URL_GENERATION",
  CreateBidCommunicationConfiguration = "CREATE_BID_COMMUNICATION_CONFIGURATION",
  MergeBidSpeculatorList = "MERGE_BID_SPECULATOR_LIST",
  ObjectNotes = "OBJECT_NOTES",
  IntegrationEngineAdmin = "INTEGRATION_ENGINE_ADMIN",
  ErpSystemLinks = "ERP_SYSTEM_LINKS",
  ObjectCopy = "OBJECT_COPY",
  DeletePublicShowing = "DELETE_PUBLIC_SHOWING",
  DelegatedObjectCreation = "DELEGATED_OBJECT_CREATION",
  CreatePartySeller = "CREATE_PARTY_SELLER",
  CreatePartyBuyer = "CREATE_PARTY_BUYER",
  CreatePartyPotentialBuyer = "CREATE_PARTY_POTENTIAL_BUYER",
  PrefillEstateForm = "PREFILL_ESTATE_FORM",
  LinkGeneration = "LINK_GENERATION",
  DealDebugging = "INTEGRATION_DEAL_DEBUGGING",
  CreatePublicShowing = "CREATE_PUBLIC_SHOWING",
  GetExternalPotentialBuyerState = "GET_EXTERNAL_POTENTIAL_BUYER_STATE",
}

export enum IntegrationSupportedDataTypes {
  BiddingStatus = "BIDDING_STATUS",
}

export enum IntegrationContactSearchCapabilities {
  ContactSearch = "CONTACT_SEARCH",
  CompanyByIdLookup = "COMPANY_BY_ID_LOOKUP",
}
