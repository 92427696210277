import { TransferLeadParams } from "@app/lists/lists-leads/ngrx/lists-leads.reducer";
import { PaginationListDTO } from "@app/models";
import { TaskType } from "@app/models/task-type";
import { createAction, props } from "@ngrx/store";

const defaultParams = {
  sortBy: "insertedDate",
  sortOrder: "desc",
};

export const getTasks = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultParams,
    ...params,
  };
  return getTasksRequest({ filters });
};

export const getTasksRequest = createAction(
  "[Lists Tasks] GET_TASKS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getTasksSuccess = createAction(
  "[Lists Tasks] GET_TASKS_SUCCESS",
  props<{ tasks: PaginationListDTO }>()
);

export const getTasksFailed = createAction("[Lists Tasks] GET_TASKS_FAILED");

export const getMoreTasks = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultParams,
    ...params,
  };
  return getMoreTasksRequest({ filters });
};

export const getMoreTasksRequest = createAction(
  "[Lists Tasks] GET_MORE_TASKS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreTasksSuccess = createAction(
  "[Lists Tasks] GET_MORE_TASKS_SUCCESS",
  props<{ tasks: PaginationListDTO }>()
);

export const getMoreTasksFailed = createAction(
  "[Lists Tasks] GET_MORE_TASKS_FAILED"
);

export const getTaskTypesRequest = createAction(
  "[Lists Tasks] GET_TASK_TYPES_REQUEST"
);

export const getTaskTypesSuccess = createAction(
  "[Lists Tasks] GET_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const getTaskTypesFailed = createAction(
  "[Lists Tasks] GET_TASK_TYPES_FAILED"
);

export const getAllTaskContactIds = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultParams,
    ...params,
  };
  return getAllTaskContactIdsRequest({ filters });
};

export const getAllTaskContactIdsRequest = createAction(
  "[Lists Tasks] GET_ALL_TASK_CONTACT_IDS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getAllTaskContactIdsSuccess = createAction(
  "[Lists Tasks] GET_ALL_TASK_CONTACT_IDS_SUCCESS",
  props<{ contactIds: string[] }>()
);

export const getAllTaskContactIdsFailed = createAction(
  "[Lists Tasks] GET_ALL_TASK_CONTACT_IDS_FAILED",
  props<{ payload: string }>()
);

export const assignMultipleTasksRequest = createAction(
  "[Lists Tasks] ASSIGN_MULTIPLE_TASKS_REQUEST",
  props<{
    eaTaskIds: string[];
    params: TransferLeadParams;
  }>()
);

export const assignMultipleTasksSuccess = createAction(
  "[Lists Tasks] ASSIGN_MULTIPLE_TASKS_SUCCESS"
);

export const assignMultipleTasksFailed = createAction(
  "[Lists Tasks] ASSIGN_MULTIPLE_TASKS_FAIL"
);

export const setSelectedTasks = createAction(
  "[Lists Tasks] SET_SELECTED_TASKS",
  props<{
    eaTaskIds: string[];
  }>()
);

export const selectAllTasks = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultParams,
    ...params,
  };
  return selectAllTasksRequest({ filters });
};

export const selectAllTasksRequest = createAction(
  "[Lists Tasks] SELECT_ALL_TASKS_REQUEST",
  props<{
    filters: Record<string, unknown>;
  }>()
);

export const selectAllTasksSuccess = createAction(
  "[Lists Tasks] SELECT_ALL_TASKS_SUCCESS",
  props<{
    eaTaskIds: string[];
  }>()
);

export const selectAllTasksFail = createAction(
  "[Lists Tasks] SELECT_ALL_TASKS_FAIL"
);

export const reset = createAction("[Lists Tasks] RESET");
