import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { combineLatest, map, Observable, withLatestFrom } from "rxjs";

import { AppState } from "@app/app.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { REGIONS } from "@app/shared/config/utils/features";
import { AvailabilityModes } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.component";
import { AdvancedOfficeEmployeeDropdownService } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.service";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as fromUser from "@app/shared/user";
import { hasRole } from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";

@Component({
  selector: "app-q-office-multi-select",
  templateUrl: "./q-office-multi-select.component.html",
  styleUrls: ["./q-office-multi-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QOfficeMultiSelectComponent),
    },
  ],
})
export class QOfficeMultiSelectComponent
  implements OnInit, ControlValueAccessor
{
  @Input() inline: boolean;
  @Input() availabilityMode: AvailabilityModes = "restrictive";

  private _value = "";
  touched = false;
  disabled = false;

  isAdmin$: Observable<boolean>;
  isAdminOrManager$: Observable<boolean>;
  regionFeatureEnabled$: Observable<boolean>;
  offices$: Observable<any[]>;
  regions$: Observable<any[]>;
  options$: Observable<Select>;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(
    private store: Store<AppState>,
    private officeEmployeeDropdownService: AdvancedOfficeEmployeeDropdownService
  ) {}

  ngOnInit(): void {
    this.isAdmin$ = this.store.pipe(select(hasRole(ROLE_ADMIN)));
    this.isAdminOrManager$ = this.store.pipe(select(fromUser.isManagerOrAdmin));
    this.regionFeatureEnabled$ = this.store.pipe(
      select(getFeature(REGIONS)),
      map((feature) => feature.enabled)
    );
    this.offices$ = this.officeEmployeeDropdownService.getOffices(
      this.availabilityMode
    );
    this.regions$ = this.officeEmployeeDropdownService.getRegions();
    this.options$ = combineLatest([this.offices$, this.regions$]).pipe(
      withLatestFrom(this.isAdmin$, this.regionFeatureEnabled$),
      map(([[offices, regions], isAdmin, regionFeatureEnabled]) => {
        if (!isAdmin) {
          this.value = offices.map((office) => office.value).toString();
          this.onChange(this.value);
        }
        return isAdmin && regionFeatureEnabled && regions.length
          ? [
              { label: "offices", value: "group-header" },
              ...offices,
              { label: "regions", value: "group-header" },
              ...regions,
            ]
          : offices;
      })
    );

    // Set default value
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
