import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { HubDataStudio } from "@app/models/tailored-campaign";
import * as widgetActions from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.reducer";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-statistics-tailored-campaign-widget",
  templateUrl: "./statistics-tailored-campaign-widget.component.html",
  styleUrls: ["./statistics-tailored-campaign-widget.component.scss"],
})
export class StatisticsTailoredCampaignWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() eaOfficeId: string;
  @Input() triggerDataFetch$: Observable<void>;

  dataStudio$: Observable<HubDataStudio>;
  loading$: Observable<boolean>;
  dataStudioUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getHubDataStudio();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getHubDataStudio();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaOfficeId) {
      this.getHubDataStudio();
    }
  }

  private mapStateToProps() {
    this.loading$ = this.store.pipe(select(fromWidget.getHubDataStudioLoading));
    this.dataStudio$ = this.store.pipe(select(fromWidget.getHubDataStudio));

    this.store
      .pipe(select(fromWidget.getDataStudioConfigurationIsLoaded))
      .pipe(
        first(),
        filter((isLoaded) => !isLoaded)
      )
      .subscribe((isLoaded) => {
        if (!isLoaded) {
          this.store.dispatch(
            widgetActions.fetchDataStudioConfigurationRequest()
          );
        }
      });

    this.store
      .pipe(select(fromWidget.getDataStudioConfiguration))
      .pipe(
        filter((config) => !!config?.theHubDataStudio),
        switchMap(() =>
          this.store.pipe(select(fromWidget.getHubDataStudioIsLoaded))
        ),
        filter((isLoaded) => !isLoaded)
      )
      .subscribe(() => {
        this.store.dispatch(widgetActions.fetchHubDataStudioRequest());
      });
  }

  private getHubDataStudio() {
    combineLatest([this.dataStudio$.pipe(filter((data) => !!data))])
      .pipe(first())
      .subscribe(([data]) => {
        if (!!this.eaOfficeId) {
          const selectedOffice = data.offices.find(
            (office) => office.eaOfficeId === this.eaOfficeId
          );
          this.dataStudioUrl$.next(selectedOffice?.mainUrl);
        } else {
          this.dataStudioUrl$.next(data.total.mainUrl);
        }
      });
  }
}
