import {
  combineLatest,
  debounceTime,
  filter,
  map,
  Observable,
  skip,
  Subject,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { getSalesMeetingDetailsRequest } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.actions";
import * as standardModalActions from "@app/core/components/standard-modal/ngrx/standard-modal.actions";
import { BodyLink } from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { SalesMeeting, Task, TaskSalesMeeting } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import {
  getCurrency,
  getFeature,
  getTaskTypeId,
} from "@app/shared/config/config.reducer";
import {
  FOLLOW_UP_RESULT_ID,
  REPORT_RESULT_ID,
} from "@app/shared/config/utils/feature-settings-sales-meeting";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { ThousandSeparatorPipe } from "@app/shared/modules/ui-components/pipes/thousand-separator.pipe";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { MATCH_WHITE_SPACE_GLOBAL } from "@app/shared/utils/regex-patterns";
import {
  SIDEBAR_CONNECT_IN_EXTERNAL_PROVIDER_URL,
  SIDEBAR_CREATE_IN_EXTERNAL_PROVIDER_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SALES_MEETING, TASK } from "@app/shared/utils/tab-types";
import {
  SALES_MEETING_FOLLOW_UP,
  SALES_MEETING_REPORT,
} from "@app/shared/utils/task-types";
import { getOpenInExternalProvider } from "@app/sidebar/external-provider/ngrx/external-provider.reducer";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { SalesMeetingStatus } from "@app/sidebar/tasks/utils/sales-meeting-statuses";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import { getProcessing, getTask } from "../ngrx/tasks.reducer";
import {
  UpdateSalesMeetingReportPayload,
  updateSalesMeetingRequest,
} from "./tasks-sales-meeting-report.actions";
import { TasksSalesMeetingReportEffects } from "./tasks-sales-meeting-report.effects";

export type FollowUpDateUnit = "day" | "month";
export type SalesMeetingOutcome = "won" | "waiting" | "valuation";
export type SalesMeetingFeatureKey =
  | "winning_follow_up_time"
  | "waiting_follow_up_time"
  | "valuation_follow_up_time";

@Component({
  selector: "sidebar-tasks-sales-meeting-report",
  templateUrl: "./tasks-sales-meeting-report.component.html",
  styleUrls: [
    "../shared/tasks-detail-card/tasks-detail-card.component.scss",
    "../../sidebar.component.common.scss",
    "./tasks-sales-meeting-report.component.scss",
  ],
  providers: [TasksSalesMeetingReportEffects],
})
export class TasksSalesMeetingReportComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  task$: Observable<Task | TaskSalesMeeting>;
  processing$: Observable<boolean>;
  tab$: Observable<SidebarTab>;
  price_valuation: string;
  tabType = TASK;
  form: FormGroup;
  minDate: Date = new Date();
  unSubscribe$ = new Subject<void>();
  salesMeeting: SalesMeeting;
  externalProvider: any;
  openInexternalProvider: boolean;
  erpModalsConfig: any;

  mapOutcomeToFeatureKey: Record<SalesMeetingOutcome, SalesMeetingFeatureKey> =
    {
      won: "winning_follow_up_time",
      waiting: "waiting_follow_up_time",
      valuation: "valuation_follow_up_time",
    };

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private taskTypeResolverService: TaskTypeResolverService,
    private thousandSeparator: ThousandSeparatorPipe
  ) {
    this.initForm();
  }

  ngOnInit() {
    this.initFormEvents();
    this.initStoreObservers();
  }

  ngAfterViewInit() {
    this.tab$
      .pipe(
        filter((value) => !!value),
        take(1)
      )
      .subscribe((tab) => {
        if (tab.dirty) {
          this.form.setValue(tab.currentValue);
          this.cdr.detectChanges();
          this.startTaskChangesStream(1);
        } else {
          this.startTaskChangesStream();
        }
        this.startValueChangesStream();
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initStoreObservers(): void {
    this.task$ = this.store.pipe(
      select(getTask),
      filter((t) => t && t.hasOwnProperty("salesMeeting") && t["salesMeeting"])
    );
    this.processing$ = this.store.pipe(select(getProcessing));
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));

    this.store
      .pipe(select(fromConfig.getFeature(EXTERNAL_PROVIDER)))
      .subscribe((provider) => (this.externalProvider = provider));

    this.store
      .pipe(select(getOpenInExternalProvider))
      .subscribe(
        (openInExternalProvider) =>
          (this.openInexternalProvider = openInExternalProvider)
      );

    this.store
      .pipe(select(fromConfig.getModals))
      .subscribe((modalsConfig) => (this.erpModalsConfig = modalsConfig));

    this.task$
      .pipe(
        map((task: TaskSalesMeeting) => task?.salesMeeting),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((salesMeeting: SalesMeeting) => {
        this.salesMeeting = salesMeeting;
        this.price_valuation = this.thousandSeparator.transform(
          salesMeeting.evaluationValue
        );
        this.form
          .get("valuationComment")
          .setValue(salesMeeting.evaluationComment);
        this.form.get("resultComment").setValue(salesMeeting.note);

        if (
          salesMeeting.status !== SalesMeetingStatus.Canceled &&
          salesMeeting.status !== SalesMeetingStatus.Prepared
        ) {
          this.form.get("result").setValue(salesMeeting.status);
        }

        if (
          this.shouldUpdateFormResult(salesMeeting.status) &&
          salesMeeting.tasks?.length > 0
        ) {
          this.form
            .get("date")
            .setValue(
              moment(salesMeeting.tasks[0].deliveryDate).format("L").toString()
            );
        }
      });
  }

  maybeShowErpModal() {
    if (
      this.erpModalsConfig &&
      this.erpModalsConfig.external_provider_connection &&
      !this.erpModalsConfig.external_provider_connection.enabled &&
      !!this.salesMeeting.isExistingHome
    ) {
      const params = {
        externalProviderName: this.externalProvider.name,
        openInExternalProvider: this.openInexternalProvider,
        showConnectToObjInERPButton:
          this.externalProvider.show_connect_to_object_in_erp_button,
        salesMeeting: this.salesMeeting,
      };

      // Quickfix: Set selected salesmeeting here
      this.store.dispatch(
        getSalesMeetingDetailsRequest({
          eaCrmSalesMeetingId: this.salesMeeting.eaCrmSalesMeetingId,
        })
      );

      this.store.dispatch(
        standardModalActions.show({
          header: this.translateService.instant(
            "external_provider_connection",
            {
              name: params.externalProviderName,
            }
          ),
          descriptionTooltip: this.translateService.instant(
            "external_provider_modal_description",
            {
              name: params.externalProviderName,
            }
          ),
          bodyLinks: params.openInExternalProvider
            ? []
            : params.showConnectToObjInERPButton
            ? [
                this.getModalLink1(
                  params.externalProviderName,
                  params.salesMeeting.objectStreet
                ),
                this.getModalLink2(params.externalProviderName),
              ]
            : [
                this.getModalLink1(
                  params.externalProviderName,
                  params.salesMeeting.objectStreet
                ),
              ],
          bodyExternalLinks: params.openInExternalProvider
            ? [
                this.getCreateInExternalProviderLink(
                  params.externalProviderName
                ),
              ]
            : [],
        })
      );
    }
  }

  private getModalLink1(
    externalProviderName: string,
    address: string
  ): BodyLink {
    return {
      text: this.translateService.instant("create_in_external_provider", {
        address: address,
        externalProvider: externalProviderName,
      }),
      url: [
        "/crm/",
        { outlets: { sidebar: SIDEBAR_CREATE_IN_EXTERNAL_PROVIDER_URL } },
      ],
    };
  }

  private getModalLink2(externalProviderName: string): BodyLink {
    return {
      text: `${this.translateService.instant(
        "connect_meeting_to_existing_residence"
      )} ${externalProviderName}`,
      url: [
        "/crm/",
        { outlets: { sidebar: SIDEBAR_CONNECT_IN_EXTERNAL_PROVIDER_URL } },
      ],
    };
  }

  private getCreateInExternalProviderLink(externalProviderName: string): any {
    return {
      text: this.translateService.instant(
        "create_in_external_provider_general",
        {
          externalProvider: externalProviderName,
        }
      ),
    };
  }

  setDateFromNow(
    numberOfDays: number | string,
    unit: FollowUpDateUnit = "day"
  ): void {
    this.form
      .get("date")
      .setValue(moment().add(numberOfDays, unit).format("L").toString());
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(): void {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    this.form.disable({ emitEvent: false });
    this.getPayload(this.getFormValue())
      .pipe(take(1))
      .subscribe((params) => {
        this.store.dispatch(updateSalesMeetingRequest({ params }));
        if (params.salesMeeting.status === "won") {
          this.maybeShowErpModal();
        }
      });
  }

  initForm(): void {
    this.form = this.fb.group({
      result: ["", [Validators.required]],
      resultComment: "",
      date: "",
      valuation: "",
      valuationComment: "",
      message: false,
    });
  }

  initFormEvents(): void {
    this.form
      .get("result")
      .valueChanges.pipe(
        filter(() => this.form.enabled),
        withLatestFrom(this.store.pipe(select(getFeature(SALES_MEETING)))),
        takeUntil(this.unSubscribe$)
      )
      .subscribe(([result, salesMeetingFeature]) => {
        // If the sales meeting result has a default value in the setting.json
        // we set it in the date field otherwise date field should be set to null
        const key = this.mapOutcomeToFeatureKey[result];

        if (!!key && !!salesMeetingFeature[key]) {
          const date = salesMeetingFeature[key].split("_");
          this.setDateFromNow(Number(date[0]), date[1] as FollowUpDateUnit);
        } else {
          this.form.get("date").setValue("");
        }
      });
  }

  showValuation(value: string): boolean {
    return value && value !== "0";
  }

  private shouldUpdateFormResult(status: string): boolean {
    switch (status) {
      case SalesMeetingStatus.Valuation:
      case SalesMeetingStatus.Waiting:
      case SalesMeetingStatus.Won:
        return true;
      default:
        return false;
    }
  }

  private getPayload(formValues): Observable<UpdateSalesMeetingReportPayload> {
    return this.task$.pipe(
      filter((value) => !!value),
      switchMap((task: TaskSalesMeeting) =>
        combineLatest([
          this.store.pipe(select(getCurrency)),
          this.store.pipe(select(getTaskTypeId(SALES_MEETING_FOLLOW_UP))),
          this.getFollowUpResultId$(task),
          this.translateService.get("follow_up_task_title", {
            street: task.salesMeeting.objectStreet,
          }),
        ]).pipe(
          map(([currency, eaTaskTypeId, eaTaskResultId, title]) => ({
            salesMeeting: this.getUpdateSalesMeetingParams(
              task.salesMeeting,
              formValues,
              currency
            ),
            task: new Task({ eaTaskId: task.eaTaskId, eaTaskResultId }),
            followUpTask: formValues.date
              ? this.getFollowUpTaskParams(
                  task.salesMeeting,
                  eaTaskTypeId,
                  formValues.date,
                  title
                )
              : null,
            sendMessage: formValues.message,
          }))
        )
      )
    );
  }

  private getFollowUpResultId$(task: TaskSalesMeeting): Observable<number> {
    const taskTypeKey = this.taskTypeResolverService.taskTypeIdToName(
      +task.eaTaskTypeId
    );
    const taskResultKey =
      taskTypeKey === SALES_MEETING_REPORT
        ? REPORT_RESULT_ID
        : FOLLOW_UP_RESULT_ID;
    return this.store.pipe(
      select(getFeature(SALES_MEETING)),
      filter((value) => !!value),
      map((f) => f[taskResultKey])
    );
  }

  private getUpdateSalesMeetingParams(
    salesMeeting: SalesMeeting,
    formValues: any,
    currency: string
  ): SalesMeeting {
    const requestBody = new SalesMeeting({
      eaCrmSalesMeetingId: salesMeeting.eaCrmSalesMeetingId,
      status: formValues.result,
      commissionVAT: true,
    });

    if (formValues.resultComment) {
      requestBody.note = formValues.resultComment;
    }

    const evaluationValue = formValues.valuation.replace(
      MATCH_WHITE_SPACE_GLOBAL,
      ""
    );
    if (salesMeeting.eaOid && evaluationValue) {
      requestBody.eaOid = salesMeeting.eaOid;
      requestBody.evaluationValue = evaluationValue;
      requestBody.evaluationComment = formValues.valuationComment;
      requestBody.evaluationCurrency = currency;
    }
    return requestBody;
  }

  private getFollowUpTaskParams(
    salesMeeting: SalesMeeting,
    eaTaskTypeId: number,
    date: string,
    title: string
  ): Task {
    return new Task({
      eaCrmSalesMeetingId: salesMeeting.eaCrmSalesMeetingId,
      eaEmployeeId: salesMeeting.eaEmployeeId,
      contactId: salesMeeting.contactId,
      eaOfficeId: salesMeeting.eaOfficeId,
      startTime: moment().format(API_DATE_FORMAT),
      deliveryDate: date,
      title,
      eaTaskTypeId,
    });
  }

  private startTaskChangesStream(skipNumber = 0) {
    this.task$
      .pipe(takeUntil(this.unSubscribe$), skip(skipNumber))
      .subscribe(() =>
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: this.getFormValue(),
          })
        )
      );
  }

  private startValueChangesStream() {
    this.form.valueChanges
      .pipe(takeUntil(this.unSubscribe$), debounceTime(1)) // Avoid setTabValue to be called twice when choosing result
      .subscribe(() =>
        this.store.dispatch(
          sidebarActions.setTabValue({
            tabType: this.tabType,
            value: this.getFormValue(),
          })
        )
      );
  }

  private getFormValue(): any {
    return {
      result: this.form.get("result").value,
      resultComment: this.form.get("resultComment").value,
      date: this.form.get("date").value
        ? moment(this.form.get("date").value, "L")
            .endOf("day")
            .format(API_DATE_FORMAT)
        : "",
      valuation: this.form.get("valuation").value,
      valuationComment: this.form.get("valuationComment").value,
      message: this.form.get("message").value,
    };
  }
}
