import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { BidService } from "@app/core/ngrx/entity-services/bid.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { ContactStatus, ShowingObject } from "@app/showings/models";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable } from "rxjs";
import * as fromPotentialBuyers from "@app/showings/ngrx/potential-buyer/potential-buyer.reducer";

@Component({
  selector: "app-object-highest-bid-widget",
  templateUrl: "./object-highest-bid-widget.component.html",
  styleUrls: [
    "./object-highest-bid-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectHighestBidWidgetComponent implements OnInit, OnChanges {
  @Input() eaOid: string;
  property$: Observable<ShowingObject>;
  highestBid$: Observable<string>;
  currency$: Observable<string>;
  isMobile$: Observable<boolean>;
  contactSummary$: Observable<ContactStatus>;

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    private bidService: BidService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.property$ = this.objectService.entityMap$.pipe(
        map((entities) => entities[this.eaOid]),
        filter((entity) => !!entity)
      );
    }
  }

  private mapStateToProps() {
    this.highestBid$ = this.bidService.currentList$.pipe(
      map((bids) => {
        const sortedBids = bids
          .filter((bid) => !bid.isCanceled)
          .sort((a, b) => Number(b.amount) - Number(a.amount));
        return sortedBids.length > 0 ? sortedBids[0]?.amount : null;
      })
    );
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
    this.isMobile$ = this.store.pipe(select(isMobile));
    this.contactSummary$ = this.store.pipe(
      select(fromPotentialBuyers.getShowingObjectContactSummary)
    );
  }
}
