<h3 *ngIf="showHeader">
  <app-icon size="large" name="street-view"></app-icon>&nbsp;<span>{{
    "leads" | translate
  }}</span>
</h3>
<app-segmented-controls
  [controls]="segmentedControls"
  [active]="activeControl.value"
  (change)="activeControl.setValue($event)"
></app-segmented-controls>
<div
  class="widget-container"
  [class.loading-pulse]="taskService.loading$ | async"
>
  <div class="free-leads-container">
    <p
      class="no-tasks"
      *ngIf="(currentTasks$ | async) && (currentTasks$ | async).length === 0"
    >
      {{ "no_tasks_here" | translate }}
      <app-icon [name]="'thumbs-up'"></app-icon>
    </p>
    <div class="card-wrapper" *ngFor="let task of currentTasks$ | async">
      <app-mobile-card
        color="WHITE"
        [enableMetadata]="true"
        [size]="'SLIM'"
        [shadow]="false"
        [enableActionDropdown]="true"
        (click)="onTaskClick(task, $event)"
      >
        <span title>
          <span [style.color]="taskTypeResolver.resolveColor(task)">
            <app-icon
              size="medium"
              [name]="taskTypeResolver.resolveIcon(task) | async"
            ></app-icon>
          </span>
          {{ task.eaTaskTypeName }} &nbsp;&nbsp;
        </span>
        <div metadata>
          <span *ngIf="task.eaTaskTypeName">
            <app-icon name="cube"></app-icon>
            {{ task.title }}
          </span>
          <br />
          <span *ngIf="task.insertedDate">
            <app-icon name="clock"></app-icon>
            {{ "created" | translate }}: {{ task.insertedDate | momentify }}
          </span>
          <br />
          <div *ngIf="task.contactId && task.contactFirstName">
            <app-contact-name
              [contactId]="task.contactId"
              [contactType]="task.contactType"
            >
              {{ task.contactFirstName }} {{ task.contactFamilyName }}
            </app-contact-name>
          </div>
        </div>
        <ng-container buttons>
          <!--          Todo: Add shortcut buttons here(assign to me, assign to office, assign to broker) -->
          <li role="menuitem">
            <button
              class="btn btn-secondary"
              (click)="onTaskClick(task, $event)"
            >
              <app-icon name="user-plus"></app-icon>
              {{ "assign" | translate }}
            </button>
          </li>
          <li disableNavigation role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="taskTypeResolver.onTaskClick(task, true, true)"
            >
              <app-icon name="share-square"></app-icon>
              {{ "open_in_new_tab" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
  </div>
  <ng-template #noData>
    <span translate>no_data_found</span>
  </ng-template>
</div>
