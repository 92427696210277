import {
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Type,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { KpiBankTipsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-bank-tips/kpi-bank-tips.component";
import { KpiCommissionsEarningsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-commissions-earnings/kpi-commissions-earnings.component";
import { KpiEstateCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-estate-commission/kpi-estate-commission.component";
import { KpiRentedObjectsCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-rented-objects-commission/kpi-rented-objects-commission.component";
import { KpiRentedObjectsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-rented-objects/kpi-rented-objects.component";
import { KpiSalesContractDateComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-sales-contract-date/kpi-sales-contract-date.component";
import { KpiShowingsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-showings/kpi-showings.component";
import { KpiSoldObjectsCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-sold-objects-commision/kpi-sold-objects-commission.component";
import { KpiSoonForSaleObjectsNewComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-soon-for-sale-objects-new/kpi-soon-for-sale-objects-new.component";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import * as fromKpi from "../../../ngrx/kpi.reducer";
import * as KPI_TYPES from "../../../utils/kpi-types";
import { KpiBookedSalesMeetingDetailComponent } from "../../../kpi-modal/kpi-employee/kpi-details/kpi-booked-sales-meeting-detail/kpi-booked-sales-meeting-detail.component";
import { KpiMadeSalesCallsComponent } from "../../../kpi-modal/kpi-employee/kpi-details/kpi-made-sales-calls/kpi-made-sales-calls.component";
import { KpiSoldObjectsComponent } from "../../../kpi-modal/kpi-employee/kpi-details/kpi-sold-objects/kpi-sold-objects.component";
import { KpiWonSalesMeetingDetailComponent } from "../../../kpi-modal/kpi-employee/kpi-details/kpi-won-sales-meeting-detail/kpi-won-sales-meeting-detail.component";
import { NpsSurveyResponsesComponent } from "../../../kpi-modal/kpi-employee/kpi-details/nps-survey-responses/nps-survey-responses.component";

@Component({
  selector: "kpi-employee-advanced",
  templateUrl: "./kpi-employee-advanced.component.html",
  styleUrls: [
    "../../kpi-external.component.scss",
    "./kpi-employee-advanced.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiEmployeeAdvancedComponent implements OnInit {
  @Input() params: Record<string, string>;
  @Output() showOfficeLevel = new EventEmitter<void>();

  @ViewChild("container", { static: true, read: ViewContainerRef })
  container;
  componentRef: ComponentRef<any>;

  resources$: Observable<any>;
  roles = [ROLE_ADMIN, ROLE_MANAGER];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.createComponent();
  }

  mapStateToProps(): void {
    this.resources$ = this.store.pipe(
      fromKpi.getKpiDetailsByType(this.params.type)
    );
  }

  private createComponent(): void {
    this.componentRef = this.container.createComponent(this.getComponent());
    this.componentRef.instance.resources$ = this.resources$;
  }

  private getComponent(): Type<unknown> {
    switch (this.params.type) {
      case KPI_TYPES.BOOKED_SALES_MEETINGS:
        return KpiBookedSalesMeetingDetailComponent;
      case KPI_TYPES.WON_SALES_MEETINGS:
        return KpiWonSalesMeetingDetailComponent;
      case KPI_TYPES.SOLD_OBJECTS:
        return KpiSoldObjectsComponent;
      case KPI_TYPES.MADE_SALES_CALLS:
        return KpiMadeSalesCallsComponent;
      case KPI_TYPES.NPS:
        return NpsSurveyResponsesComponent;
      case KPI_TYPES.COMMISSIONS_EARNINGS:
        return KpiCommissionsEarningsComponent;
      case KPI_TYPES.SOON_FOR_SALE_OBJECTS_NEW:
        return KpiSoonForSaleObjectsNewComponent;
      case KPI_TYPES.SALES_CONTRACT_DATE:
        return KpiSalesContractDateComponent;
      case KPI_TYPES.BANK_TIPS:
        return KpiBankTipsComponent;
      case KPI_TYPES.SHOWINGS:
        return KpiShowingsComponent;
      case KPI_TYPES.RENTED_OBJECTS:
        return KpiRentedObjectsComponent;
      case KPI_TYPES.RENTED_OBJECTS_COMMISSION:
        return KpiRentedObjectsCommissionComponent;
      case KPI_TYPES.COMMISSIONS_EARNINGS_RENTALS:
        return KpiCommissionsEarningsComponent;
      case KPI_TYPES.ESTATE_COMMISSION:
        return KpiEstateCommissionComponent;
      case KPI_TYPES.SOLD_OBJECTS_COMMISSION:
        return KpiSoldObjectsCommissionComponent;
    }
  }
}
