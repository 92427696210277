import {
  animate,
  animateChild,
  animation,
  group,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from "@angular/animations";

export const ENTERING_DURATION = "425ms";
export const EXITING_DURATION = "195ms";
export const FADE_IN_SLIDE_DURATION = "400ms 250ms";

// prettier-ignore
const fadeAnimation = animation([
  style({opacity: '{{ from }}', transform: 'translateY(.5%)'}),
  animate('{{ time }}', style({opacity: '{{ to }}', transform: 'translateY(0)'}))
]);

// prettier-ignore
export function fadeInTrigger(duration = ENTERING_DURATION) {
  return trigger('fadeIn', [
    transition(':enter', [
      useAnimation(fadeAnimation, {
        params: {from: 0, to: 1, time: duration}
      })
    ])
  ]);
}

// prettier-ignore
export function fadeInDown(duration = FADE_IN_SLIDE_DURATION) {
  return trigger('fadeInDown', [
    transition('* => *', [
      style({transform: 'translateY(-2%)', opacity: 0}),
      animate(duration, style({transform: 'translateY(0)', opacity: 1})),
    ]),
    transition('* => void', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('200ms', style({transform: 'translateY(-2%)', opacity: 0})),
    ])
  ]);
}

// prettier-ignore
export function fadeInUp(duration = FADE_IN_SLIDE_DURATION) {
  return trigger('fadeInUp', [
    transition('* <=> *', [
      animateChild(),
      style({transform: 'translateY(2%)', opacity: 0}),
      animate(duration, style({transform: 'translateY(0)', opacity: 1})),
    ]),
    transition('* => void', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('200ms', style({transform: 'translateY(2%)', opacity: 0})),
    ])
  ]);
}

// prettier-ignore
export function fadeInUp2(duration = '400ms 350ms') {
  return trigger('fadeInUp2', [
    transition(':enter', [
      style({transform: 'translateY(2%)', opacity: 0}),
      animate(duration, style({transform: 'translateY(0)', opacity: 1})),
    ]),
    transition('* => void', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('200ms', style({transform: 'translateY(2%)', opacity: 0})),
    ])
  ]);
}

// prettier-ignore
export function fadeInRight(duration = FADE_IN_SLIDE_DURATION) {
  return trigger('fadeInRight', [
    transition('void => *', [
      style({transform: 'translateX(2.5%)', opacity: 0}),
      animate(duration, style({transform: 'translateX(0)', opacity: 1})),
    ]),
    transition('* => void', [
      style({transform: 'translateX(0)', opacity: 1}),
      animate(duration, style({transform: 'translateY(2%)', opacity: 0})),
    ])
  ]);
}

// prettier-ignore
export function fadeInLeft(duration = FADE_IN_SLIDE_DURATION) {
  return trigger('fadeInLeft', [
    transition('void => *', [
      style({transform: 'translateX(-2%)', opacity: 0}),
      animate(duration, style({transform: 'translateX(0)', opacity: 1})),
    ]),
    transition('* => void', [
      style({transform: 'translateX(0)', opacity: 1}),
      animate(duration, style({transform: 'translateY(-2%)', opacity: 0})),
    ])
  ]);
}

// prettier-ignore
export function fadeInOutTrigger(duration = ENTERING_DURATION) {
  return trigger('fadeInOut', [
    transition(':enter', [useAnimation(fadeAnimation, {
      params: {from: 0, to: 1, time: duration}
    })]),
    transition(':leave', [useAnimation(fadeAnimation, {
      params: {from: 1, to: 0, time: duration}
    })]),
  ]);
}

// prettier-ignore
export function slideUpDownTrigger(duration = ENTERING_DURATION) {
  return trigger('slideUpDown', [
    transition(':enter', [
      style({transform: 'translateY(100%)'}),
      animate(duration, style({transform: 'translateY(0)'}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0)'}),
      animate(duration, style({transform: 'translateY(100%)'}))
    ]),
  ]);
}

// prettier-ignore
export function slideInOutTrigger(duration = ENTERING_DURATION) {
  return trigger('slideInOut', [
    state('0', style({ height: '0', opacity: 0, display: 'none', overflow: 'hidden' })),
    state('1', style({ height: '*', opacity: 1, display: 'block' })),
    transition('0 => 1', [
      style({ display: 'block', overflow: 'hidden' }),
      animate(duration + ' ease-in'),
    ]),
    transition('1 => 0', [
      style({ overflow: 'hidden' }),
      animate(duration + ' ease-out'),
    ]),
  ]);
}

// prettier-ignore
export function collapsedTrigger(duration = ENTERING_DURATION) {
  return trigger('collapsedState', [
    state('collapsed', style({height: '0'})),
    state('expanded', style({height: '*'})),
    transition('collapsed => expanded', animate(duration + ' ease-in')),
    transition('expanded => collapsed', animate(duration + ' ease-out')),
  ]);
}

// prettier-ignore
export function routeTransition() {
  return trigger('routeTransition', [
    transition('* <=> *', [
      query(':enter, :leave', style({
        position: 'fixed', width: 'calc(100% - 6rem)'
      }), {optional: true}),
      group([
        query(':enter', [
          useAnimation(fadeAnimation, {
            params: {from: 0, to: 1, time: ENTERING_DURATION}
          }),
          animateChild()
        ], {optional: true}),
        query(':leave', [
          useAnimation(fadeAnimation, {
            params: {from: 1, to: 0, time: EXITING_DURATION}
          }),
          animateChild()
        ], {optional: true}),
      ])
    ])
  ]);
}

// prettier-ignore
export function listAnimation(duration = ENTERING_DURATION) {
  return trigger('listAnimation', [
    transition('* => *', [
      query(':enter', style({opacity: 0}), {optional: true}),
      query(':enter', stagger(duration, [
        animate(duration, style({opacity: 1}))
      ]), {optional: true})
    ])
  ]);
}

// prettier-ignore
export function slideDownStagger(duration = ENTERING_DURATION) {
  return trigger('slideDownStagger', [
    transition('* => *', [
      query(':enter', style({opacity: 0}), {optional: true}),
      query(':enter', stagger('300ms', [
        animate(`${duration} ease-in`, keyframes([
          style({opacity: 0, transform: 'translateY(-75%)', offset: 0}),
          style({opacity: 1, transform: 'translateY(0)', offset: 1.0}),
        ]))]), {optional: true})
    ])
  ]);
}

export function slideInOutAnimation() {
  return trigger("slideInOutAnimation", [
    state(
      "in",
      style({
        "max-height": "500px",
        opacity: "1",
        visibility: "visible",
      })
    ),
    state(
      "out",
      style({
        "max-height": "0px",
        opacity: "0",
        visibility: "hidden",
      })
    ),
    transition("in => out", [
      group([
        animate(
          "400ms ease-in-out",
          style({
            opacity: "0",
          })
        ),
        animate(
          "600ms ease-in-out",
          style({
            "max-height": "0px",
          })
        ),
        animate(
          "700ms ease-in-out",
          style({
            visibility: "hidden",
          })
        ),
      ]),
    ]),
    transition("out => in", [
      group([
        animate(
          "1ms ease-in-out",
          style({
            visibility: "visible",
          })
        ),
        animate(
          "600ms ease-in-out",
          style({
            "max-height": "500px",
          })
        ),
        animate(
          "800ms ease-in-out",
          style({
            opacity: "1",
          })
        ),
      ]),
    ]),
  ]);
}
