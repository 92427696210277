import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import {
  fetchContactStatusFail,
  fetchContactStatusRequest,
  fetchContactStatusSuccess,
} from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  forkJoin as observableForkJoin,
  map,
  of,
  switchMap,
} from "rxjs";

@Injectable()
export class ContactStatusWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  getContactStatusRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactStatusRequest),
      switchMap(({ contactId, eaEmployeeId }) => {
        return observableForkJoin(
          this.fetchContactStatusesRequests(contactId, eaEmployeeId)
        ).pipe(
          map((results: unknown[]) => fetchContactStatusSuccess({ results })),
          catchError(() => of(fetchContactStatusFail()))
        );
      })
    )
  );

  fetchContactStatusesRequests = (contactId, eaEmployeeId) => {
    const requests = [];

    const getConsents = { url: `contacts/${contactId}/consents/search` };
    const getTasks = {
      url: `tasks`,
      params: {
        contactId: contactId,
        eaEmployeeId: eaEmployeeId,
        taskTypeCategory: "task,lead",
        resolved: "false",
      },
    };
    const getObjects = {
      url: `object/contact/${contactId}/contact-objects/search`,
      params: {},
    };
    const getPriceUpdateOrders = {
      url: `contacts/${contactId}/price-update-orders/search`,
      params: {},
    };
    const getAlert = {
      url: `alerts/search`,
      params: {
        contactId: contactId,
      },
    };
    const getSalesMeetings = {
      url: `contacts/${contactId}/sales-meetings`,
      params: {
        status: "prepared,valuation,waiting",
      },
    };

    requests.push(
      this.apiService.get(getConsents.url, {}),
      this.apiService.get(getTasks.url, getTasks.params),
      this.apiService.get(getObjects.url, getObjects.params),
      this.apiService.get(
        getPriceUpdateOrders.url,
        getPriceUpdateOrders.params
      ),
      this.apiService.get(getAlert.url, getAlert.params),
      this.apiService.get(getSalesMeetings.url, getSalesMeetings.params)
    );
    return requests;
  };
}
