import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { ROLE_BROKER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import { debounceTime, first, Observable } from "rxjs";
import { SidebarTab } from "../../../models/sidebar-tab";
import * as sidebarActions from "../../../ngrx/sidebar.actions";
import { getTab } from "../../../ngrx/sidebar.reducer";

@Component({
  selector: "employees-office-form",
  templateUrl: "./employees-office-form.component.html",
  styleUrls: ["./employees-office-form.component.scss"],
})
export class EmployeesOfficeFormComponent implements OnInit, AfterViewInit {
  @Input() type: string;

  tab$: Observable<SidebarTab>;
  form: FormGroup;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.form = new FormGroup({
      role: new FormControl(ROLE_BROKER),
      status: new FormControl("active"),
    });

    this.tab$ = this.store.pipe(select(getTab(this.type)));
  }

  ngAfterViewInit() {
    this.tab$.pipe(first()).subscribe((tab) => {
      if (tab.dirty) {
        this.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.type,
            value: this.form.value,
          })
        );
      }
      this.startValueChangesStream();
    });
  }

  private startValueChangesStream() {
    this.form.valueChanges
      .pipe(debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setTabValue({ tabType: this.type, value: formValues })
        )
      );
  }
}
