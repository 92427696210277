import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslatePipe } from "@ngx-translate/core";
import { ShortcutsSortingModalData } from "@app/shared/config/models/shortcuts-feature";

@Component({
  selector: "app-shortcuts-sorting-modal",
  templateUrl: "./shortcuts-sorting-modal.component.html",
  styleUrls: ["./shortcuts-sorting-modal.component.scss"],
})
export class ShortcutsSortingModalComponent implements OnInit {
  columns: { label: string; value: string; control: FormControl }[];

  constructor(
    public dialogRef: MatDialogRef<ShortcutsSortingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShortcutsSortingModalData,
    private translate: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.initializeColumns();
  }

  close() {
    this.dialogRef.close(null);
  }

  save() {
    const selected = this.columns
      .filter((column) => column.control.value)
      .map((column) => column.value);
    const unselected = this.columns
      .filter((column) => !column.control.value)
      .map((column) => column.value);

    this.dialogRef.close({
      event: "save",
      data: { displayOrders: selected, hiddenShortcuts: unselected },
    });
  }

  getHeader(column: string) {
    const { headers } = this.data;
    if (headers[column]) {
      return this.translate.transform(headers[column]);
    } else {
      return this.translate.transform(column);
    }
  }

  reset() {
    const { systemDefault } = this.data;
    const visibleList = systemDefault
      .filter((item) => item.visible)
      .map((item) => item.id);

    this.columns = systemDefault.map((item) => ({
      label: this.getHeader(item.id),
      value: item.id,
      control: new FormControl(visibleList.includes(item.id)),
    }));
  }

  private initializeColumns() {
    const { original, displayed } = this.data;
    this.columns = original.map((key) => ({
      label: this.getHeader(key),
      value: key,
      control: new FormControl(displayed.includes(key)),
    }));
  }
}
