import { ExternalId } from "@app/models/external-id";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";

export function getAddress(street: string, zip: string, city: string): string {
  if (street && zip && city) {
    return `${street}, ${zip} ${city}`;
  } else if (street && zip) {
    return `${street}, ${zip}`;
  } else if (street && city) {
    return `${street}, ${city}`;
  } else if (zip && city) {
    return `${zip}, ${city}`;
  } else if (street) {
    return street;
  } else if (zip) {
    return zip;
  } else if (city) {
    return city;
  } else {
    return "";
  }
}

export function getExternalId(config: ObjectUrlConfig): string {
  if (config.objectLocationUseEaOidAsExternalId) {
    return config.object.eaOid;
  }

  if (!config.object.externalIds) {
    return "";
  }

  const filteredExternalIdsArray = config.object.externalIds
    .filter(
      (externalId: ExternalId) =>
        externalId.provider === config.objectUrlExternalIdProvider
    )
    .filter(
      (externalId: ExternalId) =>
        externalId.category === config.objectUrlExternalIdCategory
    );

  if (filteredExternalIdsArray.length > 0) {
    return filteredExternalIdsArray[0].externalId;
  }
  return "";
}

export function constructObjectUrl(config: ObjectUrlConfig) {
  return config.url.replace(`{EXTERNAL_ID}`, getExternalId(config));
}

interface ObjectUrlConfig {
  object: any;
  url: string;
  objectLocationUseEaOidAsExternalId: boolean;
  objectUrlExternalIdProvider: string;
  objectUrlExternalIdCategory: string;
}

export interface ObjectLink {
  name: string;
  url: string;
}

export const getObjectStatusColor = (statusId: string): string => {
  if (statusId === "26") {
    return "orange-status-mat-chip";
  } else if (statusId === "10" || statusId === "32" || statusId === "51") {
    return "green-status-mat-chip";
  } else if (
    statusId === "7" ||
    statusId === "29" ||
    statusId === "33" ||
    statusId === "21" ||
    statusId === "52"
  ) {
    return "gray-status-mat-chip";
  } else {
    return "blue-status-mat-chip";
  }
};

export const getFormattedAddress = ({ street, zip, city }: Address): string => {
  const result = `${street ? street + "," : ""} ${zip ?? ""} ${city ?? ""}`;
  if (result.trim().length > 1) {
    return result.trim();
  }

  return "";
};

export const mergeObjects = (
  object1: Record<string, any>,
  object2: Record<string, any>
): Record<string, any> => {
  // Create a copy of object1 to avoid modifying it directly
  const mergedObject = { ...object1 };

  // Iterate through the properties of object2 and overwrite corresponding properties in mergedObject
  for (const key in object2) {
    if (object2.hasOwnProperty(key)) {
      if (typeof object2[key] === "object" && !Array.isArray(object2[key])) {
        // Recursively merge nested objects
        mergedObject[key] = mergeObjects(mergedObject[key] || {}, object2[key]);
      } else {
        // Overwrite the property with the value from object2
        mergedObject[key] = object2[key];
      }
    }
  }

  return mergedObject;
};

export const isEqual = (objectA: unknown, objectB: unknown): boolean => {
  return JSON.stringify(objectA) === JSON.stringify(objectB);
};
