<mat-card
  [class.off-white]="color === 'OFF-WHITE'"
  [class.slim]="size === 'SLIM'"
  [class.transparent]="color === 'TRANSPARENT'"
  [class.shadow]="!!shadow"
  [class.clickable]="clickable"
  [class.selected]="selected"
>
  <div class="mobile-card-content">
    <div *ngIf="enableCheckbox">
      <ng-content select="[checkbox]"></ng-content>
    </div>
    <div>
      <span [class.title]="!!enableMetadata"
        ><ng-content select="[title]"></ng-content
      ></span>
      <div class="metadata" *ngIf="enableMetadata">
        <ng-content select="[metadata]"></ng-content>
      </div>
    </div>
  </div>
  <div class="mobile-card__actions">
    <div *ngIf="enableActionDropdown" class="mobile-card-option">
      <app-actions-menu>
        <ng-content select="[buttons]" buttons></ng-content>
      </app-actions-menu>
    </div>
    <button
      type="button"
      class="btn btn-default mobile-card__cross"
      *ngIf="showCross"
      (click)="onRemove()"
    >
      <app-icon name="times" size="medium"></app-icon>
    </button>
  </div>
</mat-card>
