import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";

import { TranslateService } from "@ngx-translate/core";

import { Action } from "@app/models";
import { Observable } from "rxjs";

import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-consumer-showing-connected",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionConsumerShowingConnectedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerName: Function;
  listenerObject: Function;

  className = "action-consumer-showing-connected-name";
  classObject = "action-consumer-showing-connected-object";
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.translateService.get(
      "action_consumer_showing_connected_text",
      {
        name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
        object: `<span class="${this.classObject} text-link">${this.item.street}</span>`,
      }
    );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
    this.listenerObject = this.itemService.createObjectLinkListener(
      this.elementRef,
      this.renderer,
      this.classObject,
      this.item.eaOid
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerObject, this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
