import { Component, Input } from "@angular/core";
import { Action } from "@app/models";

@Component({
  selector: "contact-action-one2one-sms",
  template: `
    <ng-container>
      <h4>
        {{ action.timestamp }}
        <small>{{ "of" | translate }} {{ action.employeeFullName }}</small>
      </h4>
      <p>{{ action.param3 }}</p>
      <ng-container> </ng-container
    ></ng-container>
  `,
  styleUrls: ["./common.scss"],
})
export class ContactActionOne2OneSmsComponent {
  @Input() action: Action;
}
