import { Route } from "@angular/router";
import { LeadTemplatesGuard } from "@app/sidebar/guards/lead-templates-guard.service";
import { LeadTemplatesCreateComponent } from "@app/sidebar/lead-templates/containers/lead-templates-create/lead-templates-create.component";
import { LeadTemplatesEditComponent } from "@app/sidebar/lead-templates/containers/lead-templates-edit/lead-templates-edit.component";

export const sidebarLeadTemplatesRoutes: Route = {
  path: "lead-templates",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: LeadTemplatesCreateComponent,
      canActivate: [LeadTemplatesGuard],
    },
    {
      path: ":id/edit",
      component: LeadTemplatesEditComponent,
      canActivate: [LeadTemplatesGuard],
    },
  ],
};
