import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Contact, QObject } from "@app/models";
import {
  SIDEBAR_CONTACT_RESIDENCES_CREATE_URL,
  SIDEBAR_CONTACT_RESIDENCES_EDIT_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SalesMeetingService } from "@app/sidebar/sales-meeting/sales-meeting.service";
import { select, Store } from "@ngrx/store";
import { filter, Observable } from "rxjs";
import {
  getSidebarResidenceContact,
  getSidebarResidences,
} from "../ngrx/residence.reducer";

@Component({
  selector: "residence-links",
  templateUrl: "./residence-links.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./residence-links.component.scss",
  ],
})
export class ResidenceLinksComponent implements OnInit {
  residences$: Observable<QObject[]>;
  contact$: Observable<Contact>;

  @Input() shouldShowCreateButton = true;
  @Output() createNewResidence = new EventEmitter<{}>();

  constructor(
    private store: Store<AppState>,
    private salesMeetingService: SalesMeetingService
  ) {}

  ngOnInit() {
    this.residences$ = this.store.pipe(
      select(getSidebarResidences),
      filter((value) => !!value)
    );
    this.contact$ = this.store.pipe(
      select(getSidebarResidenceContact),
      filter((value) => !!value)
    );
  }

  getLink(contact: Contact, residence: QObject): any[] {
    return [
      "/crm",
      {
        outlets: {
          sidebar: SIDEBAR_CONTACT_RESIDENCES_EDIT_URL(
            contact.contactId,
            residence.eaOid
          ),
        },
      },
    ];
  }

  getCreateLink(contact: Contact): any[] {
    return [
      "/crm",
      {
        outlets: {
          sidebar: SIDEBAR_CONTACT_RESIDENCES_CREATE_URL(contact.contactId),
        },
      },
    ];
  }

  residenceClicked(): void {
    this.salesMeetingService.selectedIntakeObject$.next(null);
  }

  createLinkClicked(): void {
    this.salesMeetingService.selectedIntakeObject$.next(null);
    this.createNewResidence.emit({});
  }
}
