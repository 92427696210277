import { Injectable } from "@angular/core";
import { Routes } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { AutoResolveRouteComponent } from "@app/shared/modules/auto-resolve-route/auto-resolve-route.component";
import { Store } from "@ngrx/store";
import { go } from "@app/core/ngrx/router/router.actions";

export const AUTO_RESOLVER_ROUTES: Routes = [
  {
    path: "",
    redirectTo: "quedro-auto-resolver",
    pathMatch: "full",
  },
  {
    path: "quedro-auto-resolver",
    component: AutoResolveRouteComponent,
    pathMatch: "full",
  },
];

@Injectable({ providedIn: "root" })
export class RouteAutoResolver {
  constructor(private store: Store<AppState>) {}

  autoResolve(pages: SubPage[]) {
    if (
      location.href.includes("quedro-auto-resolver") &&
      !!pages &&
      pages.length > 0 &&
      !!pages[0]?.routerLink
    ) {
      this.store.dispatch(go({ path: pages[0].routerLink }));
    }
  }
}
