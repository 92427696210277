<div
  #header
  [class.fixed-header]="headerShouldSnap"
  class="col-md-3 result-column"
  [@fadeInUp]
>
  <div
    *ngIf="keyword.length >= 2"
    class="result-column__list-header">
    <section (click)="toggleVisibility();">
      <div (click)="onTitleClicked(type);">
        <h4>
          {{ type | translate }} <span class="hits"> ({{ hits | qNumber }} {{ 'hits' | translate }})
        </span>
        </h4>
        <span class="result-column__list-header__arrow"> {{ 'filter'| translate }} <i class="fa fa-long-arrow-down"></i></span>
      </div>
      <span *ngIf="isMobileView" class="result-column__list-header__chevron">
        <i class="fa fa-angle-double-{{getColumnVisibility() ? 'up' : 'down'}}"></i>
      </span>
    </section>
    <ng-content *ngIf="data.length === 0 && loaded" select="[noResult]"></ng-content>
  </div>

  <div
    [ngSwitch]="type"
    *ngIf="data.length > 0"
    class="columns"
  [@fadeInUp]>

    <div *ngSwitchCase="'contacts'">
      <div
        [@collapsedState]="contactsIsVisible ? 'expanded' : 'collapsed'"
        (@collapsedState.done)="animationDone()"
        class="column-content"
      >
        <search-contact-card
          *ngFor="let contact of data"
          [contact]="contact"
          [eaEmployeeId]="userId$ | async">
        </search-contact-card>

      </div>
    </div>
    <div *ngSwitchCase="'objects'">
      <div
        [@collapsedState]="objectsIsVisible ? 'expanded' : 'collapsed'"
        (@collapsedState.done)="animationDone()"
        class="column-content">
        <search-object-card
          *ngFor="let object of data"
          [object]="object"
          [showExternalProviderLink]="((externalProvider$ | async).enabled && (showExternalProviderObjectLinks$ | async))"
          [externalProviderName]="(externalProvider$ | async).name"
          [externalProviderUrl]="(externalProvider$ | async).url"
          [externalProviderLinks]="externalProviderLinks$ | async"
          [externalProviderLinksLoading]="externalProviderLinksLoading$ | async"
          [eaEmployeeId]="userId$ | async"
          (getExternalProviderLinks)="getExternalProviderLinks($event)"
        >
        </search-object-card>
      </div>
    </div>

    <div *ngSwitchCase="'leads'">
      <div
        [@collapsedState]="leadsIsVisible ? 'expanded' : 'collapsed'"
        (@collapsedState.done)="animationDone()"
        class="column-content">
        <search-lead-card *ngFor="let lead of data"
                          [lead]="lead">
        </search-lead-card>
      </div>
    </div>

    <div *ngSwitchCase="'employees'">
      <div
        [@collapsedState]="employeesIsVisible ? 'expanded' : 'collapsed'"
        (@collapsedState.done)="animationDone()"
        class="column-content">
        <search-employee-card *ngFor="let employee of data"
                              [employee]="employee">
        </search-employee-card>
      </div>
    </div>

    <div *ngSwitchDefault>{{ 'general_error' | translate }}</div>
  </div>
</div>
