import * as listOutgoingMessagesActions from "@app/lists/lists-outgoing-messages/ngrx/lists-outgoing-messages.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsOutgoingMessagesState {
  campaignsLoading: boolean;
  campaigns: any[];
}

export const initialState: ListsOutgoingMessagesState = {
  campaignsLoading: false,
  campaigns: [],
};

export function listsOutgoingMessagesReducer(
  state: ListsOutgoingMessagesState,
  action: Action
): ListsOutgoingMessagesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(listOutgoingMessagesActions.getCampaignsRequest, (state) => ({
    ...state,
    campaignsLoading: true,
  })),
  on(
    listOutgoingMessagesActions.getCampaignsSuccess,
    (state, { campaigns }) => ({
      ...state,
      campaignsLoading: false,
      campaigns: campaigns.rows,
    })
  ),
  on(listOutgoingMessagesActions.getCampaignsFail, (state) => ({
    ...state,
    campaignsLoading: false,
  }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.outgoingMessages
);

export const getCampaigns = createSelector(
  getState,
  (state: ListsOutgoingMessagesState) => state.campaigns
);

export const getCampaignsLoading = createSelector(
  getState,
  (state: ListsOutgoingMessagesState) => state.campaignsLoading
);
