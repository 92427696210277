import { createAction, props } from "@ngrx/store";
import { TaskTemplate } from "@app/models";

const ACTION_PREFIX = "[Lead templates form]";

export const getLeadTemplateRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATE_REQUEST`,
  props<{ id: number }>()
);

export const getLeadTemplateSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATE_SUCCESS`,
  props<{ data: TaskTemplate }>()
);

export const getLeadTemplateFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_TEMPLATE_FAIL`
);

export const createLeadTemplateRequest = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_TEMPLATE_REQUEST`,
  props<{ body: Partial<TaskTemplate> }>()
);

export const createLeadTemplateSuccess = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_TEMPLATE_SUCCESS`,
  props<{ data: TaskTemplate }>()
);

export const createLeadTemplateFail = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_TEMPLATE_FAIL`
);

export const editLeadTemplateRequest = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_TEMPLATE_REQUEST`,
  props<{ id: number; body: Partial<TaskTemplate> }>()
);

export const editLeadTemplateSuccess = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_TEMPLATE_SUCCESS`,
  props<{ data: TaskTemplate }>()
);

export const editLeadTemplateFail = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_TEMPLATE_FAIL`
);

export const deleteLeadTemplateRequest = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_TEMPLATE_REQUEST`,
  props<{ id: number }>()
);

export const deleteLeadTemplateSuccess = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_TEMPLATE_SUCCESS`
);

export const deleteLeadTemplateFail = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_TEMPLATE_FAIL`
);
