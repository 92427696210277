import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { SupportSidebarFeature } from "@app/shared/config/models/support-sidebar-feature";
import { SUPPORT } from "@app/shared/config/utils/features";
import { getUnmatchedSSOEmail } from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-sso-failed",
  templateUrl: "./sso-failed.component.html",
  styleUrls: ["./sso-failed.component.scss"],
})
export class SsoFailedComponent implements OnInit {
  supportMail$: Observable<string>;
  supportTel$: Observable<string>;
  supportTelCountry$: Observable<string>;
  supportEnabled$: Observable<boolean>;
  unmatchedSSOEmail$: Observable<string>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.supportMail$ = this.store.pipe(
      select(getFeature(SUPPORT)),
      map((supportFeature: SupportSidebarFeature) => supportFeature.supportMail)
    );
    this.supportTel$ = this.store.pipe(
      select(getFeature(SUPPORT)),
      map((supportFeature: SupportSidebarFeature) => supportFeature.supportTel)
    );
    this.supportTelCountry$ = this.store.pipe(
      select(getFeature(SUPPORT)),
      map(
        (supportFeature: SupportSidebarFeature) =>
          supportFeature.supportTelCountry
      )
    );
    this.supportEnabled$ = this.store.pipe(
      select(getFeature(SUPPORT)),
      map((supportFeature: SupportSidebarFeature) => supportFeature.enabled)
    );
    this.unmatchedSSOEmail$ = this.store.pipe(select(getUnmatchedSSOEmail));
  }
}
