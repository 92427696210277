import { Pipe, PipeTransform } from "@angular/core";
import * as subPages from "@app/shared/config/utils/sub-pages";

@Pipe({
  name: "subpageTypeToRouteEndpoint",
})
export class SubpageTypeToRouteEndpointPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case subPages.OVERVIEW: {
        return "overview";
      }
      case subPages.TIMELINE: {
        return "timeline";
      }
      case subPages.SALES_MEETINGS: {
        return "sales-meetings";
      }
      case subPages.RESIDENCES: {
        return "residences";
      }
      case subPages.SEARCH_PROFILE: {
        return "search-profile";
      }
      case subPages.CONSENTS: {
        return "consents";
      }
      default:
        return null;
    }
  }
}
