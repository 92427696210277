import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { CampaignsSubpageService } from "@app/campaigns/campaigns-subpage-service";
import { ListsSubPageService } from "@app/lists/lists-sub-page.service";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { Store } from "@ngrx/store";
import { Observable, Subject, takeUntil, withLatestFrom } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-auto-resolve-route",
  templateUrl: "./auto-resolve-route.component.html",
  styleUrls: ["./auto-resolve-route.component.scss"],
})
export class AutoResolveRouteComponent implements OnInit, OnDestroy {
  subPages$: Observable<SubPage[]>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private listsSubPageService: ListsSubPageService,
    private campaignSubPageService: CampaignsSubpageService,
    private store: Store<AppState>,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (location.href.endsWith("/lists/quedro-auto-resolver")) {
      this.subPages$ = this.listsSubPageService.subPages$;
    } else if (location.href.endsWith("/campaigns/quedro-auto-resolver")) {
      this.subPages$ = this.campaignSubPageService.subPages$;
    }

    // Manual redirect if no sub-page is selected. This is not handled in the routing module because of dynamic page sorting reasons.
    if (location.href.endsWith("/quedro-auto-resolver")) {
      this.route.parent.url
        .pipe(takeUntil(this.unsubscribe$), withLatestFrom(this.subPages$))
        .subscribe(([, subpages]) =>
          this.store.dispatch(go({ path: subpages[0].routerLink }))
        );
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
