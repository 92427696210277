import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { RouterModule } from "@angular/router";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { AssignLeadButtonsModule } from "@app/shared/modules/assign-lead-buttons/assign-lead-buttons.module";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { RichTextEditorModule } from "@app/shared/modules/form-components/rich-text-editor/rich-text-editor.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { TasksDefaultActionLogComponent } from "@app/sidebar/tasks/tasks-default/tasks-default-action-log/tasks-default-action-log.component";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { TasksDetailCardComponent } from "./shared/tasks-detail-card/tasks-detail-card.component";
import { TaskDefaultNewComponent } from "./task-default-new/task-default-new.component";
import { TasksBlixtvarderingarComponent } from "./tasks-blixtvarderingar/tasks-blixtvarderingar.component";
import { TasksCallingListsComponent } from "./tasks-calling-lists/tasks-calling-lists.component";
import { TaskDefaultDeleteModalComponent } from "./tasks-default/task-default-delete-modal/task-default-delete-modal.component";
import { TasksDefaultAssignComponent } from "./tasks-default/tasks-default-assign/tasks-default-assign.component";
import { TasksDefaultManageComponent } from "./tasks-default/tasks-default-manage/tasks-default-manage.component";
import { TasksDefaultComponent } from "./tasks-default/tasks-default.component";
import { TasksFinalPriceComponent } from "./tasks-final-price/tasks-final-price.component";
import { TasksMittBolanComponent } from "./tasks-mitt-bolan/tasks-mitt-bolan.component";
import { TasksPrivateShowingFollowUpComponent } from "./tasks-private-showing-follow-up/tasks-private-showing-follow-up.component";
import { TasksSalesMeetingReportComponent } from "./tasks-sales-meeting-report/tasks-sales-meeting-report.component";
import { TasksStandardComponent } from "./tasks-standard/tasks-standard.component";
import { TasksVardebarometerComponent } from "./tasks-vardebarometer/tasks-vardebarometer.component";
import { TasksVardekollenComponent } from "./tasks-vardekollen/tasks-vardekollen.component";
import { TasksComponent } from "./tasks.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MomentModule,
    SidebarSharedModule,
    RouterModule,
    ReactiveFormsModule,
    PopoverModule,
    TooltipModule,
    FormComponentsModule,
    UiComponentsModule,
    UtilModule,
    ModalModule,
    ErpFormElementsModule,
    MatCheckboxModule,
    RichTextEditorModule,
    ContactComponentsModule,
    AssignLeadButtonsModule,
  ],
  declarations: [
    TasksComponent,
    TasksDefaultComponent,
    TasksDefaultManageComponent,
    TasksDefaultAssignComponent,
    TasksMittBolanComponent,
    TasksBlixtvarderingarComponent,
    TasksVardebarometerComponent,
    TasksFinalPriceComponent,
    TasksSalesMeetingReportComponent,
    TasksPrivateShowingFollowUpComponent,
    TasksVardekollenComponent,
    TasksDetailCardComponent,
    TaskDefaultDeleteModalComponent,
    TasksCallingListsComponent,
    TasksStandardComponent,
    TasksDefaultActionLogComponent,
    TaskDefaultNewComponent,
  ],
  exports: [TaskDefaultDeleteModalComponent],
})
export class TasksModule {}
