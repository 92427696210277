import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { hardTrimBody } from "@app/core/services/api/api.service";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { MESSAGE_TEMPLATE } from "@app/shared/utils/tab-types";
import { MessageTemplatesFormComponent } from "@app/sidebar/message-templates/components/message-templates-form/message-templates-form.component";
import * as messageTemplatesFormActions from "@app/sidebar/message-templates/ngrx/message-templates-form.actions";
import * as fromMessageTemplatesForm from "@app/sidebar/message-templates/ngrx/message-templates-form.reducer";
import { Template } from "@app/sidebar/message-templates/ngrx/message-templates-form.reducer";
import { DynamicContent } from "@app/sidebar/send-message/models";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import { isEqual } from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  tap,
} from "rxjs";

@Component({
  selector: "app-message-templates-edit",
  templateUrl: "./message-templates-edit.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./message-templates-edit.component.scss",
  ],
})
export class MessageTemplatesEditComponent
  implements OnInit, ConnectableTab, OnDestroy
{
  @ViewChild(MessageTemplatesFormComponent, { static: true })
  messageTemplatesForm: MessageTemplatesFormComponent;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  template$: Observable<Template>;
  dynamicContents$: Observable<DynamicContent[]>;
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  isEditable$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  tabType = MESSAGE_TEMPLATE;
  templateId: string;

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params
      .pipe(map((params) => params.id))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => {
        this.templateId = id;
        this.fetchTemplate();
        this.fetchDynamicContent(
          this.messageTemplatesForm.form.get("eaOfficeId").value,
          this.messageTemplatesForm.form.get("eaEmployeeId").value
        );
      });

    this.mapStateToProps();

    combineLatest([
      this.messageTemplatesForm.form.get("eaOfficeId").valueChanges,
      this.messageTemplatesForm.form.get("eaEmployeeId").valueChanges,
    ])
      .pipe(
        distinctUntilChanged(_.isEqual),
        debounceTime(200),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([eaOfficeId, eaEmployeeId]) => {
        if (!eaOfficeId) {
          eaEmployeeId = null;
          this.messageTemplatesForm.form.patchValue(
            {
              eaEmployeeId: eaEmployeeId,
            },
            { emitEvent: false }
          );
        }
        this.fetchDynamicContent(eaOfficeId, eaEmployeeId);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit() {
    if (this.messageTemplatesForm.form.valid) {
      const body = this.messageTemplatesForm.getFormValues();
      this.store.dispatch(
        messageTemplatesFormActions.editMessageTemplateRequest({
          templateId: this.templateId,
          body: hardTrimBody(body),
        })
      );
    } else {
      this.messageTemplatesForm.form.markAllAsTouched();
    }
  }

  onDelete() {
    this.store.dispatch(
      confirmActions.show({
        header: "delete_template_header",
        message: "delete_template_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1)
      )
      .subscribe(() => {
        this.store.dispatch(
          messageTemplatesFormActions.deleteMessageTemplateRequest({
            templateId: this.templateId,
          })
        );
      });
  }

  private mapStateToProps() {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.template$ = this.store.pipe(
      select(fromMessageTemplatesForm.getTemplate)
    );
    this.dynamicContents$ = this.store
      .pipe(select(fromMessageTemplatesForm.getDynamicContents))
      .pipe(
        tap((dc) => {
          let isEditable = false;
          if (!!dc && dc?.length > 0) {
            isEditable = dc.some((content) => content.isEditableByCurrentUser);
          }
          this.isEditable$.next(isEditable);
        })
      );
  }

  private fetchTemplate() {
    this.store.dispatch(
      messageTemplatesFormActions.getMessageTemplateRequest({
        templateId: this.templateId,
      })
    );
  }

  private fetchDynamicContent(eaOfficeId: string, eaEmployeeId: string) {
    combineLatest([this.isAdmin$, this.isManager$])
      .pipe(first())
      .subscribe(([isAdmin, isManager]) => {
        const params = {
          templateId: this.templateId,
          replacePlaceholders: false,
          eaOfficeId: eaOfficeId,
          eaEmployeeId: eaEmployeeId,
          officeEditPermission: isAdmin ? null : true,
          employeeEditPermission: isAdmin || isManager ? null : true,
        };

        this.store.dispatch(
          messageTemplatesFormActions.fetchDynamicContentRequest({
            ...hardTrimBody(params),
          })
        );
      });
  }
}
