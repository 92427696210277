<div class="message message--alert">

  <h2 *ngIf="!browserIsSupported">
    <span>{{ 'browser_unsupported' | translate }}</span>
  </h2>
  <h2 *ngIf="!cookieEnabled">
    <span>{{ 'cookies_inactive' | translate }}</span>
  </h2>
  <p>{{browserName}} {{browserVersion}}</p>

</div>



