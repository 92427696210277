import { PageView } from "@app/models/page-view";
import {
  fetchLatestObjectsFail,
  fetchLatestObjectsRequest,
  fetchLatestObjectsSuccess,
} from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface LatestObjectsWidgetState {
  objects: PageView[];
  loading: boolean;
}

export const initialState: LatestObjectsWidgetState = {
  loading: false,
  objects: [],
};

export function latestObjectsWidgetReducer(
  state: LatestObjectsWidgetState,
  action: Action
): LatestObjectsWidgetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(fetchLatestObjectsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(fetchLatestObjectsSuccess, (state, { objects }) => ({
    ...state,
    loading: false,
    objects,
  })),
  on(fetchLatestObjectsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.latestObjects
);
export const getLatestObjects = createSelector(
  getState,
  (state: LatestObjectsWidgetState) => state.objects
);
export const getLoading = createSelector(
  getState,
  (state: LatestObjectsWidgetState) => state.loading
);
