import { CommonModule, DecimalPipe, PercentPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { ObjectStatusChipColorPipe } from "@app/shared/modules/util/object-status-chip-color.pipe";
import { ObjectTypeNamePipe } from "@app/shared/modules/util/object-type-name/object-type-name.pipe";
import { ScrollToInvalidControlDirective } from "@app/shared/modules/util/scroll-to-invalid-control/scroll-to-invalid-control.directive";
import { TranslateOnlyIfPrefixedWithDeswinglisherKeyPipe } from "@app/shared/modules/util/translate/translate-with-dsw-prefix.pipe";
import { TranslateModule } from "@ngx-translate/core";
import { AddSpacesToCommaSeparatedStringPipe } from "./add-spaces-to-comma-separated-string/add-spaces-to-comma-separated-string.pipe";
import { ApproximateDurationPipe } from "./approximate-duration/approximate-duration.pipe";
import { AutoScrollDirective } from "./auto-scroll/auto-scroll.directive";
import { DataLabelDirective } from "./data-label/data-label.directive";
import { IfRoleDirective } from "./if-role/if-role.directive";
import { LoadingMaskDirective } from "./loading-mask/loading-mask.directive";
import { ObjectStatusNamePipe } from "./object-status-name/object-status-name.pipe";
import { QNumberPipe } from "./q-number/q-number.pipe";
import { QPercentCalculatorPipe } from "./q-percent/q-percent-calculator.pipe";
import { QPercentPipe } from "./q-percent/q-percent.pipe";
import { RemoveOfficePrefixPipe } from "./remove-office-prefix/remove-office-prefix.pipe";
import { RouterLinkParamsActiveDirective } from "./router-link-params-active/router-link-params-active.directive";

const components = [
  IfRoleDirective,
  RemoveOfficePrefixPipe,
  DataLabelDirective,
  RouterLinkParamsActiveDirective,
  LoadingMaskDirective,
  ApproximateDurationPipe,
  QNumberPipe,
  TranslateOnlyIfPrefixedWithDeswinglisherKeyPipe,
  QPercentPipe,
  AutoScrollDirective,
  QPercentCalculatorPipe,
];

const pipeAndDirectives = [
  ObjectStatusNamePipe,
  ObjectTypeNamePipe,
  ObjectStatusChipColorPipe,
  AddSpacesToCommaSeparatedStringPipe,
  ScrollToInvalidControlDirective,
];

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [...components, ...pipeAndDirectives],
  exports: [...components, ...pipeAndDirectives],
  providers: [DecimalPipe, PercentPipe],
})
export class UtilModule {}
