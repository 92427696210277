import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Region } from "@app/models/region";
import { getFeature } from "@app/shared/config/config.reducer";
import { REGIONS } from "@app/shared/config/utils/features";
import { getRegions } from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import {
  BsModalRef,
  BsModalService,
  ModalDirective,
} from "ngx-bootstrap/modal";
import { map, Observable } from "rxjs";

@Component({
  selector: "app-multi-select-dropdown",
  templateUrl: "./multi-select-dropdown.component.html",
  styleUrls: ["./multi-select-dropdown.component.scss"],
})
export class MultiSelectDropdownComponent implements OnInit, OnChanges {
  @ViewChild(BsDropdownDirective, { static: false })
  dropdown;
  @ViewChild("modal", { static: false })
  modal: ModalDirective;

  @Input() state: "READY" | "LOADING" | "DISABLED" = "READY";
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName: string;
  @Input() items: any[];
  @Input() displayProperty = "name";
  @Input() secondaryDisplayProperty: string;
  @Input() valueProperty = "value";
  @Input() isOffice = false;
  // Adds a switch in the dropdown to enable regions. Displays all regions.
  @Input() enableRegions = false;
  // Adds a switch in the dropdown to enable inactiveBrokers. Displays all inactive brokers.
  @Input() enableInactiveBrokers = false;
  // Todo: Fetch officePrefix directly from store instead of as an input.
  @Input() officePrefix: string;
  @Input() enableSearch = false;
  @Input() maxHeight: string;
  @Input() minWidth: string;
  @Input() fullWidth = false;
  // Todo: This should probably be true as default.
  @Input() enableMobileSelect = false;
  @Input() mobileSelectHeader: string;
  @Input() singleSelect = false;
  // Todo: Is this even used? We currently use ng-content as placeholders
  @Input() placeholder: string;

  @Output() useInactiveBrokers: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  localItems: any[];
  modalRef: BsModalRef;
  regions$: Observable<Region[]>;
  regionsSelected = false;
  @Input() inactiveBrokersSelected = false;
  regionFeatureEnabled$: Observable<boolean>;

  constructor(
    private elementRef: ElementRef,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  ngOnChanges(changes: any): void {
    if (changes.items) {
      this.localItems = this.items;
    }
  }

  private mapStateToProps(): void {
    this.regions$ = this.store.pipe(select(getRegions));
    this.regionFeatureEnabled$ = this.store.pipe(
      select(getFeature(REGIONS)),
      map((feature) => feature.enabled)
    );
  }

  handleSearchInput(input): void {
    const value = input.target.value;

    if (this.isOffice && this.officePrefix) {
      this.localItems = this.items.filter((item) =>
        item[this.displayProperty]
          .replace(this.officePrefix, "")
          .trim()
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    } else {
      this.localItems = this.items.filter((item) =>
        item[this.displayProperty].toLowerCase().includes(value.toLowerCase())
      );
    }
  }

  getDisplayName(itemValue: any): string {
    // eslint-disable-next-line eqeqeq
    const selectedItem = this.items.find(
      (item) => item[this.valueProperty] == itemValue
    );
    return itemValue && selectedItem
      ? `${this.translateService.instant(selectedItem[this.displayProperty])} ${
          this.secondaryDisplayProperty
            ? selectedItem[this.secondaryDisplayProperty]
            : ""
        }`
      : "";
  }

  isActive(value: any): boolean {
    const control = this.parentFormGroup.get(this.parentFormControlName);

    if (!control.value) {
      return false;
    } else if (!this.singleSelect) {
      return (
        control.value.includes(value) ||
        control.value.includes(value.toString())
      );
    } else {
      return control.value == value;
    }
  }

  removeValue(value: any): void {
    const control = this.parentFormGroup.get(this.parentFormControlName);
    if (!this.singleSelect) {
      const newValue = [...control.value];
      newValue.splice(
        newValue.findIndex((item) => item == value || item == value.toString()),
        1
      );
      control.setValue(newValue);
    } else {
      control.setValue(null);
    }
  }

  addValue(value: any): void {
    const control = this.parentFormGroup.get(this.parentFormControlName);
    if (!this.singleSelect) {
      const newValue = [...control.value, value];
      control.setValue(newValue);
    } else {
      control.setValue(value);
      this.dropdown.hide();
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { animated: false });
  }

  reset(): void {
    const control = this.parentFormGroup.get(this.parentFormControlName);
    if (!this.singleSelect) {
      control.setValue([]);
    } else {
      control.setValue("");
    }
  }

  showPlaceholder(): boolean {
    if (!this.singleSelect) {
      return (
        this.parentFormGroup.get(this.parentFormControlName)?.value?.length ===
        0
      );
    } else {
      return !this.parentFormGroup.get(this.parentFormControlName).value;
    }
  }

  @HostListener("document:click", ["$event"])
  hideDropdown(event) {
    if (
      !this.enableMobileSelect &&
      !this.elementRef.nativeElement.contains(event.target)
    ) {
      this.dropdown.hide();
    }
  }

  isRegionActive(region: Region) {
    const control = this.parentFormGroup.get(this.parentFormControlName);

    if (!control.value) {
      return false;
    } else if (!this.singleSelect) {
      let isActive = true;

      region.offices.forEach((office) => {
        if (
          !control.value.includes(office.eaOfficeId) &&
          !control.value.includes(office.eaOfficeId.toString())
        ) {
          isActive = false;
        }
      });

      return isActive;
    }
  }

  regionClicked(region: Region) {
    const isActive = this.isRegionActive(region);
    let currentlySelectedOffices = [
      ...this.parentFormGroup.get(this.parentFormControlName).value,
    ];

    if (!isActive) {
      const officesToAdd = region.offices
        .map((office) => office.eaOfficeId)
        .filter((eaOfficeId) => !currentlySelectedOffices.includes(eaOfficeId));
      this.parentFormGroup
        .get(this.parentFormControlName)
        .setValue([...currentlySelectedOffices, ...officesToAdd]);
    } else {
      const officesToRemove = region.offices
        .map((office) => office.eaOfficeId)
        .filter((eaOfficeId) => currentlySelectedOffices.includes(eaOfficeId));
      currentlySelectedOffices = currentlySelectedOffices.filter(
        (eaOfficeId) => !officesToRemove.includes(eaOfficeId)
      );
      this.parentFormGroup
        .get(this.parentFormControlName)
        .setValue(currentlySelectedOffices);
    }
  }
}
