<ng-container *ngFor="let key of [1,2]">
  <div>
    <skeleton-rectangle height="1" width="80" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="65" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="80" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="55" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="95" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="20" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="50" widthUnit="%"></skeleton-rectangle>
  </div>

  <div>
    <skeleton-rectangle height="1" width="40" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="80" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="55" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="60" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="20" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="90" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="35" widthUnit="%"></skeleton-rectangle>
  </div>

  <div>
    <skeleton-rectangle height="1" width="80" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="65" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="20" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="90" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="45" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="30" widthUnit="%"></skeleton-rectangle>
    <skeleton-rectangle height="1" width="60" widthUnit="%"></skeleton-rectangle>
  </div>
</ng-container>
