import {
  Contact,
  Employee,
  QObject,
  SalesMeeting,
  SendListStatus,
  Task,
  TaskType,
} from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SalesMeetingBatchSettings } from "../models/batch-settings";
import * as smActions from "./sales-meeting.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface SalesMeetingState {
  loading: boolean;
  contact: Contact;
  latestCreatedContact: Contact;
  residences: QObject[];
  batchSettings: SalesMeetingBatchSettings[];
  systemSources: Task[];
  otherSources: TaskType[];
  salesMeeting: SalesMeeting;
  residence: QObject;
  sendListStatus: SendListStatus[];
  processing: boolean;
  hasError: boolean;
  selectedEmployee: Employee;
  meetingHistory: SalesMeeting[];
}

export const initialState: SalesMeetingState = {
  loading: false,
  contact: null,
  latestCreatedContact: null,
  residences: null,
  batchSettings: null,
  systemSources: null,
  otherSources: null,
  salesMeeting: null,
  residence: null,
  sendListStatus: null,
  processing: false,
  hasError: false,
  selectedEmployee: null,
  meetingHistory: null,
};

export function salesMeetingReducer(
  state: SalesMeetingState,
  action: Action
): SalesMeetingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(smActions.getContactSuccess, (state, { contact }) => ({
    ...state,
    contact,
  })),
  on(smActions.createContactSuccess, (state, { payload }) => ({
    ...state,
    latestCreatedContact: payload,
  })),
  on(smActions.getResidencesSuccess, (state, { payload }) => ({
    ...state,
    residences: payload,
  })),
  on(smActions.getResidencesFail, smActions.getResidencesRequest, (state) => ({
    ...state,
    residences: [],
  })),
  on(smActions.getBatchSettingsSuccess, (state, { settings }) => ({
    ...state,
    batchSettings: settings,
  })),
  on(smActions.getSystemSourcesSuccess, (state, { payload }) => ({
    ...state,
    systemSources: payload,
  })),
  on(smActions.getOtherSourcesSuccess, (state, { payload }) => ({
    ...state,
    otherSources: payload,
  })),
  on(smActions.getSalesMeetingSuccess, (state, { salesMeeting }) => ({
    ...state,
    salesMeeting,
    sendListStatus: null,
  })),
  on(smActions.getResidenceSuccess, (state, { payload }) => ({
    ...state,
    residence: payload,
  })),
  on(smActions.getSendListSuccess, (state, { payload }) => ({
    ...state,
    sendListStatus: payload,
  })),
  on(
    smActions.updateSalesMeetingRequest,
    smActions.createSalesMeetingRequest,
    (state) => ({ ...state, processing: true, hasError: false })
  ),
  on(
    smActions.updateSalesMeetingSuccess,
    smActions.createSalesMeetingSuccess,
    (state) => ({ ...state, processing: false, hasError: false })
  ),
  on(
    smActions.updateSalesMeetingFail,
    smActions.createSalesMeetingFail,
    (state) => ({ ...state, processing: false, hasError: true })
  ),
  on(
    smActions.getSelectedEmployeeRequest,
    smActions.getSelectedEmployeeFail,
    (state) => ({ ...state, selectedEmployee: null })
  ),
  on(smActions.getSelectedEmployeeSuccess, (state, { employee }) => ({
    ...state,
    selectedEmployee: employee,
  })),
  on(smActions.getMeetingHistorySuccess, (state, { payload }) => ({
    ...state,
    meetingHistory: payload,
  })),
  on(smActions.removeContactFromState, (state) => ({
    ...state,
    contact: null,
  })),
  on(smActions.reset, smActions.getContactFail, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.salesMeeting
);

export const getLoading = createSelector(
  getState,
  (state: SalesMeetingState) => state.loading
);

export const getContact = createSelector(
  getState,
  (state: SalesMeetingState) => state.contact
);

export const getResidences = createSelector(
  getState,
  (state: SalesMeetingState) => state.residences
);

export const getBatchSettings = createSelector(
  getState,
  (state: SalesMeetingState) => state.batchSettings
);

export const getSystemSources = createSelector(
  getState,
  (state: SalesMeetingState) => state.systemSources
);

export const getOtherSources = createSelector(
  getState,
  (state: SalesMeetingState) => state.otherSources
);

export const getSalesMeeting = createSelector(
  getState,
  (state: SalesMeetingState) => state.salesMeeting
);

export const getResidence = createSelector(
  getState,
  (state: SalesMeetingState) => state.residence
);

export const getSendListStatus = createSelector(
  getState,
  (state: SalesMeetingState) => state.sendListStatus
);

export const getLatestCreatedContact = createSelector(
  getState,
  (state: SalesMeetingState) => state.latestCreatedContact
);

export const getSalesMeetingProcessing = createSelector(
  getState,
  (state: SalesMeetingState) => state.processing
);

export const getSalesMeetingHasError = createSelector(
  getState,
  (state: SalesMeetingState) => state.hasError
);

export const getSelectedEmployee = createSelector(
  getState,
  (state: SalesMeetingState) => state.selectedEmployee
);

export const getMeetingHistory = createSelector(
  getState,
  (state: SalesMeetingState) => state.meetingHistory
);
