import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Country } from "@app/models";
import * as libphonenumber from "google-libphonenumber";
import * as i18nISOCountries from "i18n-iso-countries";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";
import { debounceTime, Observable, of as observableOf, startWith } from "rxjs";
import { FilterService } from "./filter/filter.service";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface InputChanges extends SimpleChanges {
  lang?: SimpleChange;
  iso?: SimpleChange;
}

declare const require;

@Component({
  selector: "country-code-selector",
  templateUrl: "./country-code-selector.component.html",
  styleUrls: ["./country-code-selector.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryCodeSelectorComponent implements OnInit, OnChanges {
  @Input() lang: string;
  @Input() showCode: boolean = true;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onChange = new EventEmitter<Country>();
  @ViewChild(BsDropdownDirective, { static: false })
  dropdown;
  form: FormGroup;
  countries$: Observable<Country[]>;
  filteredList$: Observable<Country[]>;

  constructor(private elementRef: ElementRef) {}

  private _iso: string;

  get iso(): string {
    return this._iso;
  }

  @Input()
  set iso(value: string) {
    this._iso = value.toLowerCase();
  }

  ngOnInit(): void {
    i18nISOCountries.registerLocale(
      require(`i18n-iso-countries/langs/${this.lang}.json`)
    );

    this.form = new FormGroup({ query: new FormControl("") });

    const filterService = new FilterService(this.countries$, [
      "countryName",
      "countryCode",
    ]);
    this.filteredList$ = filterService.values$;

    this.form
      .get("query")
      .valueChanges.pipe(startWith(""), debounceTime(100))
      .subscribe((query) => filterService.filter(query));
  }

  ngOnChanges(changes: InputChanges): void {
    const { lang } = changes;
    if (lang && lang.currentValue !== lang.previousValue) {
      i18nISOCountries.registerLocale(
        require(`i18n-iso-countries/langs/${this.lang}.json`)
      );
      this.countries$ = observableOf(this.buildList());
    }
  }

  buildList(): Country[] {
    const countryNames = i18nISOCountries.getNames(this.lang);
    const regions = phoneUtil.getSupportedRegions();
    return regions
      .map(
        (regionCode) =>
          new Country({
            regionCode,
            countryCode: phoneUtil.getCountryCodeForRegion(regionCode),
            countryName: countryNames[regionCode],
          })
      )
      .filter((c) => c.countryName);
  }

  onItemSelected(country: Country): void {
    this.iso = country.regionCode;
    this.onChange.emit(country);
    this.dropdown.hide();
  }

  @HostListener("document:click", ["$event"])
  hideDropdown(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.dropdown.hide();
    }
  }
}
