import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { Task } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import { CREATE_PRIVATE_SHOWING } from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { select, Store } from "@ngrx/store";
import { BsModalRef } from "ngx-bootstrap/modal";
import { first, map, Observable } from "rxjs";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";

@Component({
  selector: "tasks-standard",
  templateUrl: "./tasks-standard.component.html",
  styleUrls: ["./tasks-standard.component.scss"],
})
export class TasksStandardComponent implements OnInit {
  bsModalRef: BsModalRef;

  task$: Observable<Task>;
  showCreatePrivateShowing$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private modalService: QModalService,
    private route: ActivatedRoute,
    private taskService: TaskService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
  }

  mapStateToProps(): void {
    this.task$ = this.taskService.entityMap$.pipe(
      map((em) => em[this.route.snapshot.params.eaTaskId])
    );
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );
  }

  openAddShowingModal() {
    this.task$.pipe(first()).subscribe((task) => {
      const { contactId, eaOid } = task;
      const data: ShowingModalData = {
        isEditMode: false,
        contactId: contactId,
        eaOid: eaOid,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      };

      this.modalService.show(AddShowingModalNewComponent, {
        data,
      });
    });
  }
}
