import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Country } from "@app/models";
import { fromEvent as observableFromEvent, Subject, takeUntil } from "rxjs";

@Component({
  selector: "mobile-input",
  templateUrl: "./mobile-input.component.html",
  styleUrls: ["./mobile-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MobileInputComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileInputComponent
  implements AfterViewInit, OnDestroy, ControlValueAccessor
{
  @ViewChild("input", { static: true })
  input: ElementRef;

  @Input() buttonText = "add";
  @Input() buttonType = "primary";
  @Input() showButton = false;
  @Input() countryCode: string;
  @Input() languageCode: string;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClick = new EventEmitter<string>();
  @Output() countryChange = new EventEmitter<Country>();

  propagateChange: (value: any) => void;
  propagateTouch: (value: any) => void;
  unsubscribe$ = new Subject<void>();

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    observableFromEvent(this.input.nativeElement, "blur")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => this.propagateTouch(event));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onCountryChange(country: Country): void {
    this.countryCode = country.regionCode;
    this.countryChange.emit(country);
  }

  onChange(event: Event): void {
    this.propagateChange((<HTMLInputElement>event.target).value);
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.renderer.setProperty(this.input.nativeElement, "value", value);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.input.nativeElement, "disabled", isDisabled);
  }
}
