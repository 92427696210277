<form
  *ngIf="externalTipsForm"
  [formGroup]="externalTipsForm"
  (ngSubmit)="submit()"
>
  <section>
    <div class="scroll-view">
      <div class="form-group">
        <label>{{ "office" | translate }}</label>
        <office-dropdown
          [offices]="offices"
          [parentFormGroup]="externalTipsForm"
          [ngClass]="
            !externalTipsForm.get('office').valid &&
            externalTipsForm.get('office').touched
              ? 'validation-input'
              : ''
          "
        ></office-dropdown>
        <div ngxErrors="office">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "name" | translate }}</label>
        <input
          type="text"
          placeholder="{{ 'name' | translate }}"
          formControlName="title"
          [ngClass]="
            !externalTipsForm.get('title').valid &&
            externalTipsForm.get('title').touched
              ? 'validation-input'
              : ''
          "
        />
        <div ngxErrors="title">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>{{ "email" | translate }}</label>
        <input
          type="text"
          placeholder="{{ 'email' | translate }}"
          formControlName="email"
          [ngClass]="
            !externalTipsForm.get('email').valid &&
            externalTipsForm.get('email').touched
              ? 'validation-input'
              : ''
          "
        />
        <div ngxErrors="email">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
          <div translate ngxError="validateEmail" showWhen="touched">
            validation_email
          </div>
        </div>
      </div>
    </div>
  </section>
</form>
