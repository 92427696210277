import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { TypedPaginationListDTO } from "@app/models";
import {
  fetchLatestContactsFail,
  fetchLatestContactsRequest,
  fetchLatestContactsSuccess,
} from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { PageView } from "@app/models/page-view";

@Injectable()
export class LatestContactsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchLatestContactsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLatestContactsRequest),
      switchMap(() =>
        this.apiService.get(`page-views/unique/current-user/contacts`, {}).pipe(
          map((response: TypedPaginationListDTO<PageView>) =>
            fetchLatestContactsSuccess({ contacts: response.rows })
          ),
          catchError(() => of(fetchLatestContactsFail()))
        )
      )
    )
  );
}
