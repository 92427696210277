import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { EmployeeOffices } from "@app/sidebar/profile/profile.component";
import { select, Store } from "@ngrx/store";
import { debounceTime, map, Observable, withLatestFrom } from "rxjs";
import * as search from "../../ngrx/search.actions";
import { SearchService } from "../../services/search.service";

@Component({
  selector: "search-overlay",
  templateUrl: "./search-overlay.component.html",
  styleUrls: ["./search-overlay.component.scss", "../../search.component.scss"],
})
export class SearchOverlayComponent implements OnInit {
  @Input() overlayActive: boolean;
  @ViewChild("overlay", { static: true })
  overlay: ElementRef;
  showOverlay$: Observable<boolean>;
  latestSearchKeyword: string;
  hasScrolled: boolean;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  userOffices$: Observable<EmployeeOffices[]>;
  isAdmin$: Observable<boolean>;
  superSearchTemplate$: Observable<string>;

  constructor(
    private store: Store<AppState>,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.showOverlay$ = this.store.select((state) => state.search.showOverlay);
    this.eaEmployeeId$ = this.store.select(
      (state) => state.user.employee.eaEmployeeId
    );
    this.eaOfficeId$ = this.store.select(
      (state) => state.user.office.eaOfficeId
    );
    this.userOffices$ = this.store.pipe(
      select(fromUser.getAllEmployeesAndOffices)
    );
    this.superSearchTemplate$ = this.store.pipe(
      select(fromConfig.getTemplateInSuperSearch)
    );
  }

  onKeywordChange(keyword$) {
    const keywordValue$ = keyword$.pipe(
      map((event: { target: any }) => event.target.value)
    );

    this.searchFreeText(keywordValue$);
    this.setLatestSearch(keywordValue$);
  }

  searchFreeText(keywordValue$) {
    keywordValue$
      .pipe(
        debounceTime(400),
        withLatestFrom(
          this.eaEmployeeId$,
          this.userOffices$,
          this.isAdmin$,
          this.superSearchTemplate$
        )
      )
      .subscribe(
        ([
          keyword,
          eaEmployeeId,
          userOffices,
          isAdmin,
          superSearchTemplate,
        ]) => {
          const eaOfficeIds = userOffices[0].offices
            .map((office) => office.eaOfficeId)
            .join(",");

          this.store.dispatch(
            search.loadRequest({
              parameters: {
                keyword,
                eaEmployeeId,
                eaOfficeId: eaOfficeIds,
                isAdmin,
                superSearchTemplate,
              },
            })
          );
        }
      );
  }

  setLatestSearch(keywordValue$) {
    keywordValue$.pipe(debounceTime(3000)).subscribe((keyword: string) => {
      this.store.dispatch(search.setLatestSearch({ keyword }));
    });
  }

  close() {
    this.store.dispatch(search.hide());
    this.latestSearchKeyword = "";
  }

  onScroll() {
    this.hasScrolled = this.overlay.nativeElement.scrollTop > 0;
    this.searchService.emitScroll();
  }

  onLatestSearchClicked(keyword) {
    this.latestSearchKeyword = keyword;
  }
}
