import { Contact, Office } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as sendTipsActions from "./send-tips.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export const closestOfficeRequestStatus = {
  failed: "failed",
  succeeded: "succeeded",
};

export interface SendTipsState {
  selectedContact: Contact;
  selectedContactLoading: boolean;
  closestOffice: Office;
  closestOfficeLoading: boolean;
  closestOfficeRequestStatus: string;
  processing: boolean;
}

export const initialState: SendTipsState = {
  selectedContact: null,
  selectedContactLoading: false,
  closestOffice: null,
  closestOfficeLoading: false,
  closestOfficeRequestStatus: null,
  processing: false,
};

export function sendTipsReducer(
  state: SendTipsState,
  action: Action
): SendTipsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(sendTipsActions.getClosestOfficeRequest, (state, {}) => ({
    ...state,
    closestOffice: initialState.closestOffice,
    closestOfficeLoading: true,
    closestOfficeRequestStatus: null,
  })),
  on(sendTipsActions.getClosestOfficeSuccess, (state, { office }) => ({
    ...state,
    closestOffice: office,
    closestOfficeLoading: false,
    closestOfficeRequestStatus: closestOfficeRequestStatus.succeeded,
  })),
  on(sendTipsActions.getClosestOfficeFailed, (state) => ({
    ...state,
    closestOffice: initialState.closestOffice,
    closestOfficeLoading: initialState.closestOfficeLoading,
    closestOfficeRequestStatus: closestOfficeRequestStatus.failed,
  })),
  on(sendTipsActions.sendTipsRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(sendTipsActions.sendTipsFailed, (state) => ({
    ...state,
    processing: false,
  })),
  on(
    sendTipsActions.getContactSuccess,
    sendTipsActions.setContact,
    (state, { contact }) => ({
      ...state,
      selectedContact: contact,
      selectedContactLoading: false,
      closestOfficeLoading: true,
    })
  ),
  on(
    sendTipsActions.reset,
    sendTipsActions.sendTipsSuccess,
    sendTipsActions.getContactFailed,
    () => ({ ...initialState })
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.sendTips
);

export const getSelectedContact = createSelector(
  getState,
  (state: SendTipsState) => state.selectedContact
);

export const getSelectedContactLoading = createSelector(
  getState,
  (state: SendTipsState) => state.selectedContactLoading
);

export const getClosestOffice = createSelector(
  getState,
  (state: SendTipsState) => state.closestOffice
);

export const getClosestOfficeLoading = createSelector(
  getState,
  (state: SendTipsState) => state.closestOfficeLoading
);

export const getProcessing = createSelector(
  getState,
  (state: SendTipsState) => state.processing
);

export const getClosestOfficeRequestStatus = createSelector(
  getState,
  (state: SendTipsState) => state.closestOfficeRequestStatus
);
