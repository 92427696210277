import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./types-of-non-recurring-items.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface TypesOfNonRecurringItem {
  includeCommissionBase: boolean;
  instructions: string;
  name: string;
  oneTimeTypeId?: string;
  productNumber: string;
}

export interface TypesOfNonRecurringItemsState {
  items: TypesOfNonRecurringItem[];
  loading: boolean;
  storing: boolean;
  updating: boolean;
  deleting: boolean;
}

export const initialState: TypesOfNonRecurringItemsState = {
  items: [],
  loading: false,
  storing: false,
  updating: false,
  deleting: false,
};

export function typesOfNonRecurringItemsReducer(
  state: TypesOfNonRecurringItemsState,
  action: Action
): TypesOfNonRecurringItemsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getTypesOfNonRecurringItemsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.getTypesOfNonRecurringItemsSuccess, (state, { rows }) => ({
    ...state,
    items: rows,
    loading: false,
  })),
  on(actions.getTypesOfNonRecurringItemsFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(actions.storeTypeOfNonRecurringItemsRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(
    actions.storeTypeOfNonRecurringItemsSuccess,
    actions.storeTypeOfNonRecurringItemsFailed,
    (state) => ({
      ...state,
      storing: false,
    })
  ),
  on(actions.updateTypeOfNonRecurringItemsRequest, (state) => ({
    ...state,
    updating: true,
  })),
  on(
    actions.updateTypeOfNonRecurringItemsSuccess,
    actions.updateTypeOfNonRecurringItemsFailed,
    (state) => ({
      ...state,
      updating: false,
    })
  ),
  on(actions.deleteTypeOfNonRecurringItemsRequest, (state) => ({
    ...state,
    deleting: true,
  })),
  on(
    actions.deleteTypeOfNonRecurringItemsSuccess,
    actions.deleteTypeOfNonRecurringItemsFailed,
    (state) => ({
      ...state,
      deleting: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.typesOfNonRecurringItems
);

export const getNonRecurringItems = createSelector(
  getState,
  (state: TypesOfNonRecurringItemsState) => state.items
);

export const getNonRecurringItemsLoading = createSelector(
  getState,
  (state: TypesOfNonRecurringItemsState) => state.loading
);

export const getNonRecurringItemsStoring = createSelector(
  getState,
  (state: TypesOfNonRecurringItemsState) => state.storing
);

export const getNonRecurringItemsUpdating = createSelector(
  getState,
  (state: TypesOfNonRecurringItemsState) => state.updating
);

export const getNonRecurringItemsDeleting = createSelector(
  getState,
  (state: TypesOfNonRecurringItemsState) => state.deleting
);
