<app-q-modal>
  <ng-container slot="header">
    {{ header$ | async | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="body-container">
      {{ message$ | async | translate }}
    </div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        (click)="cancel()"
        label="cancel"
        buttonType="cancel"
      ></app-q-button>
      <app-q-button
        *ngIf="newTabUrl$ | async as url"
        (click)="openInNewTab(url)"
        label="open_in_new_tab"
        buttonType="preview"
      ></app-q-button>
      <app-q-button
        (click)="confirm()"
        label="yes"
        buttonType="save"
      ></app-q-button>
    </div>
  </ng-container>
</app-q-modal>
