<div bsModal class="modal fade" #modal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close pull-right" (click)="modal.hide()">
          <span>&times;</span>
        </button>
        <h4 class="modal-title pull-left">
          {{ "surveys" | translate }}
        </h4>
      </div>

      <div class="modal-body">
        <table class="table table--striped">
          <thead>
            <tr>
              <th>
                {{ "response_date" | translate }}
              </th>
              <th>
                {{ "survey_name" | translate }}
              </th>
              <th>
                {{ "nps_value" | translate }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="surveys.length > 0; else empty">
              <tr
                *ngFor="let surveyResponse of surveys"
                (click)="handleSurveyClicked(surveyResponse.responseUrl)"
              >
                <td dataLabel="response_date">
                  {{ surveyResponse.responseDate }}
                </td>
                <td dataLabel="survey_name">{{ surveyResponse.surveyName }}</td>
                <td dataLabel="nps_value">{{ surveyResponse.nps }}</td>
              </tr>
            </ng-container>
            <ng-template #empty>
              <tr>
                <td class="text-center" colspan="100%" translate>no_hits</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
