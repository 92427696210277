import {
  AfterViewInit,
  Directive,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";
import * as fromRouter from "../ngrx/router/router.selectors";

@Directive({
  selector: "[iosScrollFix]",
})
export class IosScrollFixDirective implements OnInit, AfterViewInit, OnDestroy {
  sidebarOpen$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private host: ElementRef,
    private renderer: Renderer2,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngAfterViewInit(): void {
    this.sidebarOpen$
      .pipe(
        filter(
          () =>
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              window.navigator.userAgent
            ) && screen.width < 769
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((open) => {
        if (open) {
          setTimeout(() => {
            this.renderer.setAttribute(
              this.host.nativeElement,
              "style",
              "position:fixed;"
            );
          }, 750);
        } else {
          setTimeout(() => {
            this.renderer.removeAttribute(this.host.nativeElement, "style");
          }, 800);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps(): void {
    this.sidebarOpen$ = combineLatest([
      this.store.pipe(
        select(fromRouter.getUrl),
        map((url) => url.indexOf("//sidebar") !== -1)
      ),
      this.store
        .pipe(select(fromSidebar.getTabs))
        .pipe(map((tabs) => tabs.length > 0)),
    ]).pipe(map(([sidebarUrl, hasTabs]) => sidebarUrl && hasTabs));
  }
}
