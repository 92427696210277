import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { EmployeeDTO } from "@app/models";
import { ONLY_LETTERS_DIGITS_WHITESPACE } from "@app/shared/utils/regex-patterns";
import { filter, map, mergeMap, Observable } from "rxjs";
import { SearchEmployeeService } from "./search-employee.service";

@Component({
  selector: "search-employee",
  templateUrl: "./search-employee.component.html",
  styleUrls: ["./search-employee.component.scss"],
  providers: [
    SearchEmployeeService,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchEmployeeComponent),
    },
  ],
})
export class SearchEmployeeComponent implements ControlValueAccessor {
  @Input() placeholder = "";
  @Input() ignoreList: string[] = [];
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelected = new EventEmitter<EmployeeDTO>();

  @ViewChild("suggester", { static: false })
  suggester: HTMLInputElement;
  @ViewChild("input", { static: false })
  input: HTMLInputElement;

  loading: boolean;
  dataSource: Observable<EmployeeDTO[]>;
  propagateChange: (value: any) => void;

  private onTouched!: Function;

  private _selected: string;

  get selected(): string {
    return this._selected;
  }

  set selected(value: string) {
    if (value !== undefined) {
      this._selected = value;
      if (this.propagateChange) {
        this.propagateChange(value);
      }
    }
  }

  constructor(private searchEmployeeService: SearchEmployeeService) {
    this.dataSource = new Observable((observer: any) =>
      observer.next(this.selected)
    ).pipe(
      filter((keyword) => !!keyword),
      filter((keyword: string) => ONLY_LETTERS_DIGITS_WHITESPACE.test(keyword)),
      mergeMap((keyword: string) =>
        this.searchEmployeeService
          .suggest(keyword)
          .pipe(
            map((employees: EmployeeDTO[]) =>
              employees.filter(
                (e) => !this.ignoreList.some((i) => i === e.eaEmployeeId)
              )
            )
          )
      )
    );
  }

  onSelect(employee: EmployeeDTO): void {
    this.selected = employee.fullName;
    this.onSelected.emit(employee);
    this.markAsTouched();
  }

  onLoadingChange(loading: boolean): void {
    this.loading = loading;
  }

  writeValue(value: any): void {
    if (value !== undefined) {
      this.selected = value;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  markAsTouched() {
    this.onTouched();
  }
}
