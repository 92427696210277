import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { CountyService } from "@app/core/ngrx/entity-services/county.service";
import { CustomFilterService } from "@app/core/ngrx/entity-services/custom-filter.service";
import { MunicipalityService } from "@app/core/ngrx/entity-services/municipality.service";
import { ApiService } from "@app/core/services/api/api.service";
import { County } from "@app/models/county";
import { CustomFilter } from "@app/models/custom-filter";
import { Municipality } from "@app/models/municipality";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getCountry } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import {
  DRAW_SEARCH_PROFILE_AREA,
  SEARCH_PROFILE,
} from "@app/shared/config/utils/features";
import * as fromSearchProfile from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import {
  AreaSearchResults,
  FormattedSearchArea,
} from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { DrawMapModalService } from "@app/sidebar/search-profile/search-profile-form/draw-map-modal.service";
import { DrawMapModalComponent } from "@app/sidebar/search-profile/search-profile-form/draw-map-modal/draw-map-modal.component";
import * as areaTypes from "@app/sidebar/search-profile/utils/area-types";
import {
  COUNTY,
  CUSTOM_FILTER,
  MUNICIPALITY,
} from "@app/sidebar/search-profile/utils/area-types";
import { select, Store } from "@ngrx/store";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  BehaviorSubject,
  catchError,
  combineLatest as observableCombineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-location-filter",
  templateUrl: "./location-filter.component.html",
  styleUrls: [
    "../../../../sidebar/sidebar.component.common.scss",
    "./location-filter.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationFilterComponent implements OnInit, OnDestroy {
  @ViewChild("mapModal", { static: false })
  drawModalComponent: DrawMapModalComponent;

  @Input() groups: string[] = [
    "municipalitys",
    "countys",
    "customfilters",
    "foreigngeodataprovince",
    "foreigngeodatacity",
    "foreigngeodatacityarea",
  ];
  @Input() showTitle: boolean = true;
  @Input() disableDrawAreaOnMap: boolean = false;
  @Input() invalid: boolean = false;
  @Input() selectedAreas: FormattedSearchArea[] = [];
  @Input() selectedPolygon: string = null;
  @Input() showMode: "Show" | "Create" | "Filter" = "Create";
  @Output() selectedAreaChanged: EventEmitter<FormattedSearchArea[]> =
    new EventEmitter<FormattedSearchArea[]>();
  @Output() polygonDrawnHandler: EventEmitter<string> =
    new EventEmitter<string>();

  searchProfileFeature$: Observable<SearchProfileFeature>;
  areaSearchResults$: BehaviorSubject<fromSearchProfile.FormattedSearchArea[]> =
    new BehaviorSubject<FormattedSearchArea[]>([]);
  drawSearchProfileAreaFeatureEnabled$: Observable<boolean>;
  unSubscribe$: Subject<void> = new Subject<void>();
  drawnAreas = [];
  bsModalRef: BsModalRef;
  customZip: string;
  areaTypes = areaTypes;
  areaSearchText: string = "";

  constructor(
    private mapModalService: DrawMapModalService,
    private modalService: BsModalService,
    private store: Store<AppState>,
    private cdr: ChangeDetectorRef,
    private apiService: ApiService,
    private municipalityService: MunicipalityService,
    private countyService: CountyService,
    private customFilterService: CustomFilterService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.mapModalService.areaSaved$.subscribe((area: FormattedSearchArea) =>
      this.handlePolygonSaved(area)
    );

    this.mapModalService.drawnArea$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((polygon) => !!polygon)
      )
      .subscribe((polygon: string) => this.polygonDrawnHandler.emit(polygon));
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  mapStateToProps() {
    this.searchProfileFeature$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE))
    );
    this.drawSearchProfileAreaFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(DRAW_SEARCH_PROFILE_AREA)),
      map((feature: Feature) => feature.enabled)
    );
  }

  showModal(area = null) {
    const config = {
      backdrop: true,
      class: "mapmodal",
      animated: false,
    };

    if (area) {
      this.mapModalService.showMode$.next("Show");
      this.mapModalService.showArea$.next(area);
    } else {
      this.mapModalService.showMode$.next(this.showMode);
      this.mapModalService.showArea$.next(null);
    }

    this.bsModalRef = this.modalService.show(DrawMapModalComponent, config);
  }

  handlePolygonSaved(customFilter: FormattedSearchArea) {
    if (!this.selectedAreas) {
      this.selectedAreas = [];
    }
    this.selectedAreas.push(customFilter);
    this.cdr.detectChanges();
    this.selectedAreaChanged.emit(this.selectedAreas);
  }

  getAreaIds(type: string): string {
    const value = this.selectedAreas
      ? this.selectedAreas
          .filter((area: FormattedSearchArea) => area.type === type)
          .map((area: FormattedSearchArea) => area.id)
          .toString()
      : "";

    return value ? value : null;
  }

  shouldShow(area: FormattedSearchArea): boolean {
    if (!this.selectedAreas) {
      this.selectedAreas = [];
    }
    return !!(
      this.selectedAreas &&
      this.selectedAreas.find((result) => area.id === result.id)
    );
  }

  addArea(area: FormattedSearchArea): void {
    if (!this.selectedAreas.find((result: any) => area.id === result.id)) {
      this.selectedAreas.push(area);
      this.areaSearchText = "";
      this.selectedAreaChanged.emit(this.selectedAreas);
    }
  }

  removeArea(area: FormattedSearchArea): void {
    const index = this.selectedAreas.indexOf(area);
    this.selectedAreas.splice(index, 1);
    this.selectedAreaChanged.emit(this.selectedAreas);
  }

  removePolygon(): void {
    this.selectedPolygon = null;
    this.polygonDrawnHandler.emit(null);
  }

  removeDrawnArea(area: FormattedSearchArea): void {
    const index = this.drawnAreas.indexOf(area);
    this.drawnAreas.splice(index, 1);
  }

  getSearchResults(keyword: string): void {
    this.areaSearchText = keyword;
    if (keyword && keyword.length > 2) {
      this.handleAreaSearchInput(keyword);
    }
    if (keyword.length >= 4 && Number(keyword)) {
      this.customZip = keyword;
    } else {
      this.customZip = null;
    }
  }

  handleAreaSearchInput(keyword: string): void {
    observableCombineLatest([
      this.searchProfileFeature$,
      this.store.pipe(select(getCountry)),
    ])
      .pipe(
        first(),
        map(([feature, country]) => ({
          filterRootNode: feature.filterRootNode,
          country,
        }))
      )
      .subscribe(({ filterRootNode, country }) => {
        this.apiService
          .get("search", {
            groups: this.groups,
            listNumber: 30,
            keyword,
            filterRootNode,
            country,
          })
          .pipe(
            map((response: any) => {
              const {
                municipalities,
                counties,
                customFilters,
                zips,
                foreignGeoDataCity,
                foreignGeoDataCityArea,
                foreignGeoDataProvince,
              } = response?.matches;

              const areas: AreaSearchResults = {
                municipalities: municipalities?.entities ?? null,
                counties: counties?.entities ?? null,
                customFilters: customFilters?.entities ?? null,
                zips: zips?.entities ?? null,
                foreignGeoDataCity: foreignGeoDataCity?.entities ?? null,
                foreignGeoDataCityArea:
                  foreignGeoDataCityArea?.entities ?? null,
                foreignGeoDataProvince:
                  foreignGeoDataProvince?.entities ?? null,
              };

              return areas;
            }),
            map((response: AreaSearchResults) => {
              const areas = this.formatSearchArea(response);
              this.updateAreasState(areas);
              return areas;
            }),
            catchError(() => [])
          )
          .subscribe((response: FormattedSearchArea[]) => {
            this.areaSearchResults$.next(response);
          });
      });
  }

  hideScrollIndicator(elementRef) {
    return elementRef.offsetHeight === elementRef.scrollHeight;
  }

  private formatSearchArea(
    areaSearchResults: AreaSearchResults
  ): FormattedSearchArea[] {
    let municipalityArray = [];
    let customFilterArray = [];
    let countyArray = [];
    let zipArray = [];
    let foreignGeoCityArray = [];
    let foreignGeoProvinceArray = [];
    let foreignGeoAreaArray = [];

    if (this.groups.includes("municipalitys")) {
      municipalityArray = areaSearchResults.municipalities.map(
        (municipality) => ({
          title: municipality.municipalityName,
          type: areaTypes.MUNICIPALITY,
          id: municipality.geoMunicipalityId,
        })
      );
    }
    if (this.groups.includes("customfilters")) {
      customFilterArray = areaSearchResults.customFilters.map(
        (customFilter) => ({
          title: customFilter.customFilterName,
          type: areaTypes.CUSTOM_FILTER,
          county: customFilter.countyName,
          municipality: customFilter.municipalityName,
          id: customFilter.customFilterId,
        })
      );
    }
    if (this.groups.includes("countys")) {
      countyArray = areaSearchResults.counties.map((county) => ({
        title: county.countyName,
        type: areaTypes.COUNTY,
        id: county.geoCountyId,
      }));
      zipArray = areaSearchResults.zips
        ? areaSearchResults.zips.map((zip) => ({
            title: zip.zip,
            type: areaTypes.ZIP,
            id: zip.zip,
            county: zip.adm2,
            municipality: zip.locality,
          }))
        : [];
    }
    if (this.groups.includes("foreigngeodatacity")) {
      foreignGeoCityArray = areaSearchResults.foreignGeoDataCity
        ? areaSearchResults.foreignGeoDataCity.map((geoData) => ({
            title: `${geoData.city}, ${geoData.province}`,
            type: areaTypes.FOREIGN_GEO_CITY,
            id: geoData.city,
          }))
        : [];
    }
    if (this.groups.includes("foreigngeodataprovince")) {
      foreignGeoProvinceArray = areaSearchResults.foreignGeoDataProvince
        ? areaSearchResults.foreignGeoDataProvince.map((geoData) => ({
            title: geoData.province,
            type: areaTypes.FOREIGN_GEO_PROVINCE,
            id: geoData.province,
          }))
        : [];
    }
    if (this.groups.includes("foreigngeodatacityarea")) {
      foreignGeoAreaArray = areaSearchResults.foreignGeoDataCityArea
        ? areaSearchResults.foreignGeoDataCityArea.map((geoData) => ({
            title: geoData.cityArea,
            type: areaTypes.FOREIGN_GEO_AREA,
            id: geoData.cityArea,
          }))
        : [];
    }

    return countyArray.concat(
      municipalityArray,
      customFilterArray,
      zipArray,
      foreignGeoCityArray,
      foreignGeoProvinceArray,
      foreignGeoAreaArray
    );
  }

  private updateAreasState(areas: FormattedSearchArea[]) {
    const municipalities: Municipality[] = areas
      .filter((a) => a.type === MUNICIPALITY)
      .map((item) => ({ municipalityId: Number(item.id), name: item.title }));
    this.municipalityService.upsertManyInCache(municipalities);
    const counties: County[] = areas
      .filter((a) => a.type === COUNTY)
      .map((item) => ({ countyId: Number(item.id), countyName: item.title }));
    this.countyService.upsertManyInCache(counties);
    const customFilters: CustomFilter[] = areas
      .filter((a) => a.type === CUSTOM_FILTER)
      .map((item) => ({
        customFilterId: Number(item.id),
        customFilterName: item.title,
      }));
    this.customFilterService.upsertManyInCache(customFilters);
  }
}
