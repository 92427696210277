<section class="create-residence flex-column">
  <div class="create-residence__links-wrapper">
    <ng-container *ngIf="shouldShowCreateButton">
      <a
        class="create-residence__edit-residence create-residence__edit-residence--add-new"
        (click)="createLinkClicked()"
        [routerLink]="getCreateLink(contact$ | async)"
        routerLinkActive="active"
        ><i class="icon-plus"></i>
        <div>{{ "create_new" | translate }}</div>
      </a>
    </ng-container>
    <ng-container *ngIf="(residences$ | async)?.length > 0">
      <a
        *ngFor="let residence of residences$ | async"
        (click)="residenceClicked()"
        [routerLink]="getLink(contact$ | async, residence)"
        routerLinkActive="active"
        class="create-residence__edit-residence"
      >
        <app-icon name="home-lg-alt"></app-icon>
        <div class="create-residence__edit-residence--street">
          {{ residence.street }}
        </div>
      </a>
    </ng-container>
  </div>
</section>
