<table class="table table--striped">
  <thead>
  <tr>
    <th translate>type</th>
    <th translate>street</th>
    <th translate>city</th>
    <th translate>date</th>
    <th translate>commissions_earnings</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
    <tr *ngFor="let resource of resources$ | async">
      <td dataLabel="commissions_earnings_type">{{resource.type | translate}}</td>
      <td dataLabel="street">
        <a (click)="navigateToObject(resource.eaOid, $event)" href="#">{{resource.objectStreet}}</a>
      </td>
      <td dataLabel="city">{{resource.objectCity}}, {{resource.objectZip}}</td>
      <td dataLabel="date">{{resource.date | momentify}}</td>
      <td dataLabel="commissions_earnings">{{(resource.total) | qNumber:'1.0-0' }}</td>
    </tr>
  </ng-container>

  <ng-template #empty>
    <tr>
      <td class="text-center" colspan="100%" translate>no_commissions_earnings</td>
    </tr>
  </ng-template>
  </tbody>
</table>
