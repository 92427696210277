import { KpiExternalComponent } from "@app/kpi/kpi-external/kpi-external.component";
import { Routes } from "@angular/router";
import { KpiModalComponent } from "@app/kpi/kpi-modal/kpi-modal.component";
import { KpiChainComponent } from "@app/kpi/kpi-modal/kpi-chain/kpi-chain.component";
import { AdminGuard } from "@app/shared/guards/admin.guard";
import { KpiModalResolver } from "@app/kpi/resolvers/kpi-modal-resolver.service";
import { KpiOfficeComponent } from "@app/kpi/kpi-modal/kpi-office/kpi-office.component";
import { AdminManagerGuard } from "@app/shared/guards/admin-manager.guard";
import { KpiEmployeeComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-employee.component";

export const routes: Routes = [
  {
    path: "xkpi",
    component: KpiExternalComponent,
  },
  {
    path: "kpi",
    outlet: "modal",
    component: KpiModalComponent,
    children: [
      {
        path: ":type/chain",
        component: KpiChainComponent,
        canActivate: [AdminGuard],
        resolve: { kpi: KpiModalResolver },
      },
      {
        path: ":type/offices/:eaOfficeId",
        component: KpiOfficeComponent,
        canActivate: [AdminManagerGuard],
        resolve: { kpi: KpiModalResolver },
      },
      {
        path: ":type/offices/:eaOfficeId/employees/:eaEmployeeId",
        component: KpiEmployeeComponent,
        resolve: { kpi: KpiModalResolver },
      },
    ],
  },
];
