<div class="wrapper wrapper--long">
  <sidebar-header
    [type]="tabType"
    [label]="'create_residence' | translate"
  ></sidebar-header>

  <ng-container *ngIf="residenceSidebarConfig$ | async as sidebarConfig">
    <span
      [ngClass]="
        !shouldShowResidenceForm(sidebarConfig.showSalesObjectSelection)
          ? null
          : 'hidden'
      "
    >
      <section>
        <div class="alert alert-info">
          {{ "info_connect_salesmeeting_to_object" | translate }}
        </div>
        <h4>{{ "residences" | translate }}:</h4>
        <form [formGroup]="form">
          <app-q-search-object
            formControlName="eaOid"
            label=""
            [showCard]="false"
            [readonly]="false"
            [params]="objectSearchParam"
            (objectSelectedChange)="intakeObjectSelected(null, $event)"
          ></app-q-search-object>
        </form>
        <residence-links
          [shouldShowCreateButton]="true"
          (createNewResidence)="onCreateNewResidence()"
        ></residence-links>
      </section>

      <section *ngIf="salesObjectsList.length > 0" style="margin-top: 7rem">
        <h4>{{ "intake_objects" | translate }}:</h4>

        <section
          *ngIf="salesObjectsList.length > 0"
          class="create-residence flex-column"
        >
          <div class="create-residence__links-wrapper">
            <a
              *ngFor="let residence of salesObjectsList"
              (click)="intakeObjectSelected($event, residence)"
              routerLinkActive="active"
              class="create-residence__edit-residence"
              ><app-icon name="home-lg-alt"></app-icon>
              <div class="create-residence__edit-residence--street">
                {{ residence.street }}
              </div>
            </a>
          </div>
        </section>
      </section>
    </span>

    <span
      [ngClass]="
        shouldShowResidenceForm(sidebarConfig.showSalesObjectSelection)
          ? null
          : 'hidden'
      "
    >
      <residence-links
        [shouldShowCreateButton]="false"
        (createNewResidence)="onCreateNewResidence()"
      ></residence-links>
      <residence-form
        #residenceForm
        [processing]="processing$ | async"
        [showSauna]="showSauna$ | async"
        [objectTypes]="objectTypes$ | async"
        [config]="residenceSidebarConfig$ | async"
        class="scroll-view"
        (onSubmit)="onSubmit($event)"
      ></residence-form>
    </span>
  </ng-container>

  <div class="sidebar-footer">
    <ng-container *ngIf="residenceSidebarConfig$ | async as sidebarConfig">
      <ng-container
        *ngIf="shouldShowResidenceForm(sidebarConfig.showSalesObjectSelection)"
      >
        <app-q-button
          tabindex="-1"
          buttonType="cancel"
          label="cancel"
          (click)="closeTab()"
        >
        </app-q-button>
        <app-q-button
          buttonType="save"
          label="save"
          [disabled]="processing$ | async"
          [isLoading]="processing$ | async"
          (click)="residenceForm.submit()"
          scrollToInvalidControl
          [formEl]="residenceForm?.form"
        >
        </app-q-button>
      </ng-container>
    </ng-container>
  </div>
</div>
