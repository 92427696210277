import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import * as manageBanksActions from "@app/settings/banks/manage-banks/ngrx/manage-banks.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface ManageBanksState {
  banks: ExternalBank[];
  getLoading: boolean;
  updateLoading: boolean;
}

const initialState: ManageBanksState = {
  banks: [],
  getLoading: false,
  updateLoading: false,
};

export function manageBanksReducer(
  state: ManageBanksState,
  action: Action
): ManageBanksState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(manageBanksActions.getBanksRequest, (state) => ({
    ...state,
    getLoading: true,
  })),
  on(manageBanksActions.getBanksSuccess, (state, { chosenProviders }) => ({
    ...state,
    banks: chosenProviders,
    getLoading: false,
  })),
  on(manageBanksActions.getBanksFail, (state) => ({
    ...state,
    getLoading: false,
  })),
  on(manageBanksActions.updateSelectedBanksRequest, (state) => ({
    ...state,
    updateLoading: true,
  })),
  on(
    manageBanksActions.updateSelectedBanksFail,
    manageBanksActions.updateSelectedBanksSuccess,
    (state) => ({
      ...state,
      updateLoading: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.manageBanks
);

export const getBanks = createSelector(
  getState,
  (state: ManageBanksState) => state.banks
);

export const getGetLoading = createSelector(
  getState,
  (state: ManageBanksState) => state.getLoading
);

export const getUpdateLoading = createSelector(
  getState,
  (state: ManageBanksState) => state.updateLoading
);
