export enum ViewTypes {
  DataDisplay = "DATA-DISPLAY",
  TimeDisplay = "TIME-DISPLAY",
}

export enum DataDisplayView {
  Graph = "graph",
  Table = "table",
}

export enum TimeFormatView {
  Month = "monthView",
  Week = "weekView",
}

export enum GroupTypes {
  Employee = "perEmployee",
  Office = "perOffice",
}

export enum KpiTypes {
  KpiBookedMeeting = "booked_salesmeeting",
  KpiReportedSalesMeeting = "reported_salesmeeting",
  KpiWonMeeting = "won_salesmeeting",
  KpiSalesMeeting = "salesmeeting",
  KpiSalesCalls = "made_sales_calls",
  KpiSoldObjects = "sold_objects",
  KpiFollowedUpPotentialBuyers = "followed_up_potential_buyers",
  KpiResolvedLeads = "resolved_leads",
  KpiSentTips = "sent_tips",
  KpiEstateCommission = "estate_commission",
  KpiNps = "nps",
  KpiAlerts = "alerts",
  KpiSalesContractDate = "sales_contract_date",
  KpiEarnings = "earnings",
}
