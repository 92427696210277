<div class="q-select" [class.margin-top]="!!label" [class.inline]="inline">
  <label *ngIf="label" [id]="'listbox-' + label" class="q-select__label">
    <app-translation-text [text]="label"></app-translation-text>
  </label>
  <div
    class="q-select__container"
    (clickOutside)="onClickOutside()"
    [attachOutsideOnClick]="true"
  >
    <button
      type="button"
      aria-haspopup="listbox"
      aria-expanded="true"
      [attr.aria-labelledby]="'listbox-' + label"
      class="q-select__toggle"
      [class.disabled]="disabled"
      [disabled]="disabled"
      [class.invalid]="invalid"
      (click)="toggle()"
    >
      <span
        class="q-select__text"
        [class.q-select__placeholder]="!getSelectedName()"
      >
        {{ getSelectedName() || placeholder | translate }}
      </span>
      <span class="q-select__icon">
        <app-icon name="chevron-down" type="regular"></app-icon>
      </span>
    </button>
    <div
      *ngIf="show"
      class="q-select__dropdown"
      [class.q-select__dropdown--placement-top]="placementTop"
      #element
    >
      <ul
        tabindex="-1"
        role="listbox"
        [attr.aria-labelledby]="'listbox-' + label"
        class="q-select__listbox"
      >
        <ng-container *ngIf="options?.length > 0; else empty">
          <li
            role="option"
            class="q-select__listbox-item"
            *ngFor="let option of options"
            [class]="{
              'q-select__listbox-item--selected': isSelected(option.value)
            }"
            [class.q-select__listbox-item--disabled]="!!option.disabled"
            (click)="!!option.disabled ? null : onClick(option.value)"
          >
            {{ option.label | translate }}
            <span class="q-select__icon" *ngIf="isSelected(option.value)">
              <app-icon name="check" type="regular"></app-icon>
            </span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>

<ng-template #empty>
  <li class="q-select__listbox-empty">{{ "its_empty_here" | translate }}.</li>
</ng-template>
