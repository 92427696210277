<div [formGroup]="parentFormGroup">
  <adaptive-placeholder *ngIf="adaptivePlaceholder; else noAdaptivePlaceholder">
     <textarea
       #textArea
       adaptivePlaceholder
       (input)="handleTextLength($event)"
       [style.height.px]="textAreaHeight$ | async"
       [style.max-height.px]="maxHeightPx"
       [placeholder]="adaptivePlaceholder | translate"
       [formControlName]="parentFormControlName"
     ></textarea>
  </adaptive-placeholder>

  <ng-template #noAdaptivePlaceholder>
    <textarea
      #textArea
      (input)="handleTextLength($event)"
      [style.height.px]="textAreaHeight$ | async"
      [style.max-height.px]="maxHeightPx"
      [formControlName]="parentFormControlName"
    ></textarea>
  </ng-template>
</div>
