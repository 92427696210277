import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { AppState } from "@app/app.reducer";
import { getContact } from "@app/contacts/contact.selectors";
import { AuthService } from "@app/core/services/auth/auth.service";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { Contact, Employee, Office } from "@app/models";
import * as addContactToCallingListActions from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.actions";
import * as fromAddContactToCallingList from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.reducer";
import * as fromContactSummary from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_BROKER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "add-contact-to-calling-list-alternative",
  templateUrl: "./add-contact-to-calling-list-alternative.component.html",
  styleUrls: ["./add-contact-to-calling-list-alternative.component.scss"],
})
export class AddContactToCallingListAlternativeComponent
  implements OnInit, OnDestroy
{
  @Input() contactId: string;
  @Input() contactName: string;
  callingLists$: Observable<CallingList[]>;
  userCallingLists$: Observable<CallingList[]>;
  loading$: Observable<boolean>;
  changing$: Observable<boolean>;
  employee$: Observable<Employee>;
  office$: Observable<Office>;
  contactFromContactSummary$: Observable<Contact>;
  contactFromContactCard$: Observable<Contact>;
  unsubscribe$: Subject<void> = new Subject<void>();
  eaEmployeeId: string;
  animationState = "out";

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit() {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.contactFromContactSummary$ = this.store.pipe(
      select(fromContactSummary.getContact)
    );
    this.contactFromContactCard$ = this.store.pipe(select(getContact));
    this.callingLists$ = this.store.pipe(
      select(fromAddContactToCallingList.getCallingLists)
    );
    this.userCallingLists$ = this.store.pipe(
      select(fromAddContactToCallingList.getUserCallingList)
    );
    this.loading$ = this.store.pipe(
      select(fromAddContactToCallingList.getCallingListsLoading)
    );
    this.changing$ = this.store.pipe(
      select(fromAddContactToCallingList.getChanging)
    );
    this.employee$.subscribe(
      (employee: Employee) => (this.eaEmployeeId = employee.eaEmployeeId)
    );
    this.getCallingLists();
    this.getUserCallingLists();
    this.handleChange();
  }

  getUnselectedList(
    allCallingLists: CallingList[],
    userCallingLists: CallingList[]
  ) {
    const selectedListIds = userCallingLists.map((list) => list.callingListId);
    return allCallingLists.filter(
      ({ callingListId }) => selectedListIds.indexOf(callingListId) < 0
    );
  }

  openSheet(e: Event, sheet: TemplateRef<any>) {
    e.stopPropagation();
    this._bottomSheet.open(sheet, { panelClass: "sheet-container" });
  }

  closeSheet() {
    this._bottomSheet.dismiss();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit(e) {
    if (e.value) {
      this.store.dispatch(
        addContactToCallingListActions.storeContactToCallingListRequest({
          contactId: this.contactId,
          callingListId: e.value,
        })
      );
    }
  }

  toggleShowDiv() {
    this.animationState = this.animationState === "out" ? "in" : "out";
  }

  hideDropDown() {
    this.animationState = "out";
  }

  getCallingLists() {
    observableCombineLatest([this.employee$, this.office$])
      .pipe(
        map(([employee, office]) => ({
          employee,
          office,
        })),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ employee, office }) => {
        let filters: {} = { eaOfficeId: office.eaOfficeId };

        if (this.authService.hasRole(ROLE_BROKER)) {
          filters = { eaEmployeeId: employee.eaEmployeeId };
        }

        this.store.dispatch(
          addContactToCallingListActions.addContactToCallingListRequest({
            filters,
          })
        );
      });
  }

  handleChange() {
    this.changing$.subscribe((isChanging) => {
      if (!isChanging) {
        this.getUserCallingLists();
        this.getCallingLists();
      }
    });
  }

  getUserCallingLists() {
    observableCombineLatest([
      this.employee$,
      this.office$,
      this.contactFromContactSummary$,
      this.contactFromContactCard$,
    ])
      .pipe(
        map(
          ([
            employee,
            office,
            contactFromContactSummary,
            contactFromContactCard,
          ]) => ({
            employee,
            office,
            contactFromContactSummary,
            contactFromContactCard,
          })
        ),
        takeUntil(this.unsubscribe$),
        filter(({ contactFromContactSummary, contactFromContactCard }) => {
          return !!contactFromContactSummary || !!contactFromContactCard;
        })
      )
      .subscribe(
        ({
          employee,
          office,
          contactFromContactSummary,
          contactFromContactCard,
        }) => {
          let contactId;
          if (
            location.href.includes("contacts") &&
            location.href.includes("overview") &&
            contactFromContactCard
          ) {
            contactId = contactFromContactCard?.contactId;
          } else {
            contactId = contactFromContactSummary?.contactId;
          }

          if (!!contactId) {
            let payload: {} = { eaOfficeId: office.eaOfficeId };
            if (this.authService.hasRole(ROLE_BROKER)) {
              payload = { eaEmployeeId: employee.eaEmployeeId };
            }

            this.store.dispatch(
              addContactToCallingListActions.getUserCallingListRequest({
                filters: {
                  ...payload,
                  contactId: contactId,
                },
              })
            );
          }
        }
      );
  }

  handleAddToListClick(callingListId) {
    this.store.dispatch(
      addContactToCallingListActions.storeContactToCallingListRequest({
        contactId: this.contactId,
        callingListId,
      })
    );
  }

  handleRemoveFromList(callingListId) {
    this.store.dispatch(
      addContactToCallingListActions.removeContactFromCallingListRequest({
        contactId: this.contactId,
        callingListId,
      })
    );
  }

  isBroker() {
    return this.authService.hasRole(ROLE_BROKER);
  }
}
