import { Component, EventEmitter, Input, Output } from "@angular/core";

export type Tab = {
  label: string;
  value: string | number;
  tooltip?: string;
  disabled?: boolean;
}[];

@Component({
  selector: "app-q-tab-bar-chips",
  templateUrl: "./q-tab-bar-chips.component.html",
  styleUrls: ["./q-tab-bar-chips.component.scss"],
})
export class QTabBarChipsComponent {
  @Input() tabs: Tab;
  @Input() activeTab: any;

  @Output() changeTab = new EventEmitter<string>();

  onClick(value) {
    this.changeTab.emit(value);
  }
}
