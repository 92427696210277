import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { combineLatest, map, Observable, Subject, takeUntil } from "rxjs";
import * as fromKpi from "../../ngrx/kpi.reducer";
import { Kpi } from "../../models/kpi";
import { KpiUtilsService } from "../../services/kpi-utils.service";
import * as KPI_MODAL_TYPES from "../../utils/kpi-modal-types";

@Component({
  selector: "kpi-chain",
  templateUrl: "./kpi-chain.component.html",
  styleUrls: ["../kpi-modal.component.scss", "./kpi-chain.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiChainComponent implements OnInit, OnDestroy {
  type$: Observable<string>;
  from$: Observable<string>;
  offices$: Observable<any>;
  summary$: Observable<Kpi>;
  type: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private kpiUtils: KpiUtilsService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.setTitle();
    this.store
      .pipe(fromKpi.getKpiDetailsByType(this.type))
      .subscribe((details) => {
        // @ts-ignore
        this.route.data.next({ kpi: details });
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.type$ = this.route.params.pipe(map((params) => params?.type));
    this.from$ = this.route.parent.queryParams.pipe(
      map((params) => params?.from)
    );
    this.offices$ = this.route.data.pipe(map((params) => params?.kpi));
    this.summary$ = this.store.pipe(select(fromKpi.getSummary));
  }

  setTitle(): void {
    combineLatest([this.type$, this.from$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([type, month]) => {
        this.type = type;
        this.kpiUtils.setModalTitle({
          type,
          modalType: KPI_MODAL_TYPES.CHAIN,
          month,
        });
      });
  }

  getClassNameByProgress(progress: number): string {
    return this.kpiUtils.getClassNameByProgress(progress);
  }
}
