import { TopList } from "@app/statistics/statistics-top-lists/models/top-list";
import { TopListFilters } from "@app/statistics/statistics-top-lists/models/top-list-filters";
import { createAction, props } from "@ngrx/store";

export const fetchTopListRequest = createAction(
  "[TopLists] FETCH_TOP_LIST_REQUEST",
  props<{ listType: string; filters: TopListFilters }>()
);

export const fetchTopListSuccess = createAction(
  "[TopLists] FETCH_TOP_LIST_SUCCESS",
  props<{ listType: string; summary: boolean; topList: TopList }>()
);

export const fetchTopListFailed = createAction(
  "[TopLists] FETCH_TOP_LIST_FAILED",
  props<{ listType: string }>()
);

export const fetchTopListCsvRequest = createAction(
  "[TopLists] FETCH_TOP_LIST_CSV_REQUEST",
  props<{ listType: string; filters: TopListFilters }>()
);

export const fetchTopListCsvSuccess = createAction(
  "[TopLists] FETCH_TOP_LIST_CSV_SUCCESS",

  props<{ listType: string }>()
);

export const fetchTopListCsvFailed = createAction(
  "[TopLists] FETCH_TOP_LIST_CSV_FAILED",
  props<{ listType: string }>()
);
