<div class="widget-header">
  <h3>
    <app-icon size="large" name="book-open"></app-icon>&nbsp;<span>{{
      "notes" | translate
    }}</span>
  </h3>
  <app-q-circle-btn
    icon="plus"
    tooltipText="write_note"
    (click)="writingNote = true"
    *ngIf="!writingNote"
  ></app-q-circle-btn>
</div>
<div class="widget-container">
  <contact-actions-widget
    *ngIf="!writingNote; else writeNoteForm"
    [contactId]="contactId"
    [deleteAction]="delete$ | async"
    [reload]="reload$ | async"
    (onDelete)="onDelete($event)"
    (onMessage)="onMessage($event)"
    (noteUpdated)="reload$.next($event)"
  ></contact-actions-widget>
</div>

<ng-template #writeNoteForm>
  <app-small-back-button
    [mode]="'output'"
    (click)="writingNote = false"
  ></app-small-back-button>
  <create-note-widget
    (submitPressed)="writingNote = false"
  ></create-note-widget>
</ng-template>
