import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { ExternalTipsFormComponent } from "@app/sidebar/external-tips/external-tips-form/external-tips-form.component";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { CreateExternalTipsComponent } from "./create-external-tips/create-external-tips.component";
import { EditExternalTipsComponent } from "./edit-external-tips/edit-external-tips.component";
import { SendExternalTipsHomeComponent } from "./send-external-tips-home/send-external-tips-home.component";
import { SendExternalTipsComponent } from "./send-external-tips/send-external-tips.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    SearchContactModule,
    FormsModule,
    ReactiveFormsModule,
    FormComponentsModule,
    UtilModule,
    UiComponentsModule,
  ],
  declarations: [
    CreateExternalTipsComponent,
    EditExternalTipsComponent,
    SendExternalTipsComponent,
    ExternalTipsFormComponent,
    SendExternalTipsHomeComponent,
  ],
})
export class ExternalTipsModule {}
