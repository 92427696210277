import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal, PortalInjector } from "@angular/cdk/portal";
import { ComponentRef, Injectable, Injector, TemplateRef } from "@angular/core";
/* eslint-disable max-len */
import { StickyNotificationComponent } from "@app/shared-features/sticky-notification/components/sticky-notification/sticky-notification.component";
import { StickyNotificationRef } from "@app/shared-features/sticky-notification/sticky-notification-ref";
import { STICKY_NOTIFICATION_DATA } from "@app/shared-features/sticky-notification/sticky-notification.tokens";

export interface StickyNotificationData {
  template?: TemplateRef<any>;
}

interface StickyNotificationConfig {
  width?: string;
  panelClass?: string;
  data?: StickyNotificationData;
}

const DEFAULT_CONFIG: StickyNotificationConfig = {
  width: "100%",
  panelClass: "",
  data: null,
};

@Injectable()
export class StickyNotificationService {
  constructor(private injector: Injector, private overlay: Overlay) {}

  open(config: StickyNotificationConfig = {}): StickyNotificationRef {
    const notificationConfig = { ...DEFAULT_CONFIG, ...config };
    const overlayRef = this.createOverlay(notificationConfig);
    const notificationRef = new StickyNotificationRef(overlayRef);

    this.attachNotificationContainer(
      overlayRef,
      notificationConfig,
      notificationRef
    );
    return notificationRef;
  }

  private createOverlay(config: StickyNotificationConfig): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachNotificationContainer(
    overlayRef: OverlayRef,
    config: StickyNotificationConfig,
    notificationRef: StickyNotificationRef
  ): StickyNotificationComponent {
    const injector = this.createInjector(config, notificationRef);

    const containerPortal = new ComponentPortal(
      StickyNotificationComponent,
      null,
      injector
    );
    const containerRef: ComponentRef<StickyNotificationComponent> =
      overlayRef.attach(containerPortal);

    return containerRef.instance;
  }

  private createInjector(
    config: StickyNotificationConfig,
    notificationRef: StickyNotificationRef
  ): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(StickyNotificationRef, notificationRef);
    injectionTokens.set(STICKY_NOTIFICATION_DATA, config.data);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: StickyNotificationConfig): OverlayConfig {
    const positionStrategy = this.overlay
      .position()
      .global()
      .bottom()
      .centerHorizontally();

    return new OverlayConfig({
      width: config.width,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy,
    });
  }
}
