import { createAction, props } from "@ngrx/store";

export const show = createAction("[Search] SHOW");

export const hide = createAction("[Search] HIDE");

export const loadRequest = createAction(
  "[Search] LOAD_REQUEST",
  props<{
    parameters: {
      keyword: string;
      eaEmployeeId: string;
      eaOfficeId: string;
      isAdmin: boolean;
      superSearchTemplate: string;
    };
  }>()
);

export const loadSuccess = createAction(
  "[Search] LOAD_SUCCESS",
  props<{ result: Record<string, unknown> }>()
);

export const getLatestSearches = createAction("[Search] GET_LATEST_SEARCHES");

export const getLatestSearchesSuccess = createAction(
  "[Search] GET_LATEST_SEARCHES_SUCCESS",
  props<{ latestSearches: string }>()
);

export const setLatestSearch = createAction(
  "[Search] SET_LATEST_SEARCH",
  props<{ keyword: string }>()
);

export const removeLatestSearches = createAction(
  "[Search] REMOVE_LATEST_SEARCHES"
);

export const getExternalLinks = createAction(
  "[Search] GET_EXTERNAL_LINKS",
  props<{ eaOid: string }>()
);

export const getExternalLinksSuccess = createAction(
  "[Search] GET_EXTERNAL_LINKS_SUCCESS",
  props<{ externalLinks: any }>()
);

export const getExternalLinksFailed = createAction(
  "[Search] GET_EXTERNAL_LINKS_FAILED"
);
