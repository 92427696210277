import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_SALES_MEETING_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, Subject, switchMap, takeUntil } from "rxjs";
import * as smActions from "../ngrx/sales-meeting.actions";
import { SalesMeetingEffects } from "../ngrx/sales-meeting.effects";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";

@Component({
  selector: "sales-meeting-create-contact",
  template: `
    <div>
      <div class="header-wrapper">
        <sidebar-header
          [type]="tabType"
          [label]="tabType | translate"
        ></sidebar-header>
      </div>
      <create-contact-simple
        [type]="tabType"
        (onSubmit)="onSubmit($event)"
        (onQuedroContactSelected)="onQuedroContactSelected($event)"
      ></create-contact-simple>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["../../sidebar.component.common.scss"],
})
export class SalesMeetingCreateContactComponent implements OnInit, OnDestroy {
  tabType = SALES_MEETING;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private effects: SalesMeetingEffects
  ) {}

  ngOnInit(): void {
    const combined$ = combineLatest([
      this.store.pipe(select(fromSalesMeeting.getLatestCreatedContact)),
    ]);

    this.effects.createContactSuccess$
      .pipe(
        switchMap(() => combined$.pipe(first())),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([contact]) => {
        this.onQuedroContactSelected(contact);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit({ params, source }): void {
    this.store.dispatch(smActions.createContact(params, source));
  }

  onQuedroContactSelected(contact: Contact): void {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_SALES_MEETING_CREATE_URL(contact.contactId),
              primary: ["contacts", contact.contactId],
            },
          },
        ],
      })
    );
  }
}
