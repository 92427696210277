import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { AppState } from "@app/app.reducer";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";
import * as fromResidences from "@app/contacts/contact-residences/residences.reducer";
import { fetchPreviewRequest } from "@app/core/components/preview-modal/ngrx/preview.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import {
  Contact,
  Employee,
  QObject,
  SalesMeeting,
  Task,
  TaskType,
} from "@app/models";
import { Office } from "@app/models/office";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature, SalesMeetingFeature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import {
  EXTERNAL_PROVIDER,
  OBJECT_OVERVIEW,
} from "@app/shared/config/utils/features";
import { getOffice } from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as formUtils from "@app/shared/utils/form-utils";
import * as fromMessageUtils from "@app/shared/utils/message-utils";
import {
  SIDEBAR_CONTACT_RESIDENCES_BASE_URL,
  SIDEBAR_CONTACTS_EDIT_URL,
  SIDEBAR_SALES_MEETING_BASE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { SALES_MEETING_REPORT } from "@app/shared/utils/task-types";
import { getOpenInExternalProviderRequest } from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import { getOpenInExternalProvider } from "@app/sidebar/external-provider/ngrx/external-provider.reducer";
import * as fromResidence from "@app/sidebar/residence/ngrx/residence.actions";
import * as fromSidebarResidence from "@app/sidebar/residence/ngrx/residence.reducer";
import { SalesMeetingService } from "@app/sidebar/sales-meeting/sales-meeting.service";
import { Action, select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { uniqBy } from "lodash";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { SalesMeetingBatchSettings } from "../models/batch-settings";
import * as smActions from "../ngrx/sales-meeting.actions";
import {
  getResidencesFail,
  removeContactFromState,
} from "../ngrx/sales-meeting.actions";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";
import { getFormattedAddress } from "@app/shared/utils/object-utils";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";

@Component({
  selector: "sales-meeting-form",
  templateUrl: "./sales-meeting-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./sales-meeting-form.component.scss",
  ],
})
export class SalesMeetingFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() editMode = false;
  @Input() residence: QObject;

  @Output() ready = new EventEmitter<void>();
  @Output() employeeChange = new EventEmitter<Employee[]>();
  @Output() submitClicked = new EventEmitter<Action>();
  @Output() setLoading = new EventEmitter<boolean>();

  form: FormGroup;
  tabType = SALES_MEETING;
  dateToday = moment().format("L");
  contact$: Observable<Contact>;
  selectedEmployee$: Observable<Employee>;
  residences$: Observable<QObject[]>;
  meetingHistory$: Observable<SalesMeeting[]>;
  sidebarResidence$: Observable<QObject>;
  selectedResidence$ = new BehaviorSubject<QObject>(null);
  batchSettings$: Observable<SalesMeetingBatchSettings[]>;
  batchSettings: SalesMeetingBatchSettings[];
  batchSettingsMessages: string[][] = [];
  systemSources$: Observable<Task[]>;
  otherSources$: Observable<TaskType[]>;
  salesMeetingFeature$: Observable<SalesMeetingFeature>;
  contactSourceFeature$: Observable<Feature>;
  addressSuggesterFeature$: Observable<Feature>;
  countryCode$: Observable<string>;
  brokerConnectionReady$ = new Subject<void>();
  unsubscribe$: Subject<void> = new Subject<void>();
  objectConnectionList$: Observable<QObject[]>;
  suggestionsList: QObject[] = [];
  residenceSidebarConfig$: Observable<ResidenceSidebarConfig>;
  showMeetingHistoryList = false;
  contactId$: Observable<string>;

  get batches(): FormArray {
    return this.form.get("batches") as FormArray;
  }

  get value(): any {
    const formValue = { ...this.form.getRawValue() };
    const batches = [];
    this.batches.controls.forEach((control, i) => (batches[i] = control.value));
    formValue.batches = batches;
    return formValue;
  }

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private phoneService: PhoneNumberService,
    private salesMeetingService: SalesMeetingService,
    private featureConfigManager: FeatureConfigManagerService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
    this.createBatchControls();
    this.fetchData();
    this.initFormEventHandlers();
    this.onFormReady();
    this.getOpenCreateInExternalProvider();
    this.initDateField();
    if (!this.editMode) {
      let isResidenceSet = false;
      combineLatest([
        this.residences$,
        this.salesMeetingService.selectedIntakeObject$,
      ])
        .pipe(
          filter(
            ([residences, selectedIntakeObject]) =>
              residences.length > 0 || !!selectedIntakeObject
          ),
          first()
        )
        .subscribe(([residences, selectedIntakeObject]) => {
          isResidenceSet = true;

          if (selectedIntakeObject) {
            this.patchAddress(selectedIntakeObject);
          } else {
            this.patchAddress(residences[0]);
          }
        });

      if (!isResidenceSet) {
        combineLatest([this.contact$])
          .pipe(first())
          .subscribe(([contact]) => {
            this.form.get("street").setValue(contact.street);
            this.form.get("zip").setValue(contact.zip);
            this.form.get("city").setValue(contact.city);
            this.getMeetingHistory();
            this.form.get("fullAddress").setValue(
              getFormattedAddress({
                street: contact?.street,
                zip: contact?.zip,
                city: contact?.city,
              })
            );
          });
      }
    }

    this.form
      .get("fullAddress")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        if (!this.form.get("street").value) {
          this.form.get("street").setValue(value);
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["residence"]) {
      this.patchAddress(changes["residence"].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(fromResidence.setResidence({}));
    this.store.dispatch(getResidencesFail());
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getSourceDateFormatted(date: string): string {
    return moment(date, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD");
  }

  getOpenCreateInExternalProvider(): void {
    this.store.dispatch(getOpenInExternalProviderRequest());
  }

  mapStateToProps(): void {
    this.contact$ = this.store.pipe(
      select(fromSalesMeeting.getContact),
      filter((value) => !!value)
    );
    this.contactId$ = this.contact$.pipe(map(({ contactId }) => contactId));
    this.sidebarResidence$ = this.store.pipe(
      select(fromSidebarResidence.getSidebarResidence)
    );
    this.residenceSidebarConfig$ =
      this.featureConfigManager.getResidenceSidebarConfig();
    this.residences$ = this.store.pipe(
      select(fromSalesMeeting.getResidences),
      filter((value) => !!value)
    );
    this.batchSettings$ = this.store.pipe(
      select(fromSalesMeeting.getBatchSettings),
      filter((value) => !!value)
    );
    this.selectedEmployee$ = this.store.pipe(
      select(fromSalesMeeting.getSelectedEmployee)
    );

    this.systemSources$ = this.store.pipe(
      select(fromSalesMeeting.getSystemSources),
      filter((value) => !!value)
    );
    this.otherSources$ = this.store.pipe(
      select(fromSalesMeeting.getOtherSources),
      filter((value) => !!value)
    );

    this.salesMeetingFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.SALES_MEETING))
    );
    this.contactSourceFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.CONTACT_SOURCE))
    );
    this.addressSuggesterFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_SUGGESTER))
    );
    this.countryCode$ = this.store.pipe(
      select(fromConfig.getCountry),
      map((value) => value.toLowerCase())
    );

    this.objectConnectionList$ = this.store.pipe(
      select(fromResidences.getObjectConnectionList)
    );

    combineLatest([this.residences$, this.objectConnectionList$]).subscribe(
      ([residences, objects]) => {
        const sellerObjects = objects.filter(
          (obj) => obj.status === "4" && obj.type === "seller"
        );

        if (residences.length > 0) {
          this.suggestionsList.push(...residences);
        }

        if (sellerObjects.length > 0) {
          this.suggestionsList.push(...sellerObjects);
        }
      }
    );

    this.meetingHistory$ = this.store.pipe(
      select(fromSalesMeeting.getMeetingHistory)
    );
  }

  createForm(): void {
    this.form = this.formBuilder.group(
      {
        office: ["", Validators.required],
        employee: ["", Validators.required],
        date: [new Date(), [Validators.required]],
        time: [
          "",
          [
            Validators.required,
            Validators.pattern(RegExp(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)),
          ],
        ],
        endTime: [
          "",
          [
            Validators.required,
            Validators.pattern(RegExp(/^([01][0-9]|2[0-3]):[0-5][0-9]$/)),
          ],
        ],
        fullAddress: ["", Validators.required],
        street: ["", Validators.required],
        zip: [""],
        city: [""],
        batches: this.formBuilder.array([]),
        comment: [""],
        source: [""],
      },
      {
        validators: [this.timeRangeValidator()],
      }
    );
  }

  createBatchControls(): void {
    this.batchSettings$.pipe(first()).subscribe((settings) => {
      this.batchSettings = settings;
      settings.forEach(() =>
        this.batches.push(new FormControl({ value: false, disabled: true }))
      );
    });
  }

  validateBatchControls(options: any): void {
    const { date, time, contact, settings } = options;
    const batchSendTime = this.getMessageSendTime(time, date);

    this.batches.controls.forEach((control) => control.enable());
    this.batches.controls.forEach(
      (_, i) => (this.batchSettingsMessages[i] = [])
    );

    settings.forEach((setting: SalesMeetingBatchSettings, i) => {
      if (setting.type === "email" && !contact.hasEmail()) {
        this.batches.controls[i].disable();
        this.batchSettingsMessages[i].push("missing_email");
      }

      if (setting.type === "sms" && !contact.hasMobile()) {
        this.batches.controls[i].disable();
        this.batchSettingsMessages[i].push("missing_phone");
      }

      if (!date || batchSendTime.isBefore(moment().add(4, "hours"))) {
        this.batches.controls[i].disable();
        this.batchSettingsMessages[i].push(
          "disabled_sales_meeting_message_tooltip"
        );
      }
    });
  }

  getBatchDisabled(): boolean {
    return !!this.batches.controls.find((batch) => batch.disabled === true);
  }

  getBatchSettingMessages(index: number): string {
    return this.batchSettingsMessages[index]
      ? this.batchSettingsMessages[index][0]
      : "meeting_time_not_selected";
  }

  onFormReady(): void {
    const observables: any = [this.brokerConnectionReady$, this.batchSettings$];

    this.contactSourceFeature$
      .pipe(
        first(),
        map((f) => f.enabled)
      )
      .subscribe((enabled) => {
        if (enabled) {
          observables.push(this.systemSources$);
          observables.push(this.otherSources$);
        }

        combineLatest(observables)
          .pipe(first())
          .subscribe(() => this.ready.emit());
      });
  }

  initFormEventHandlers(): void {
    this.addDateTimeChangeHandler();
  }

  initDateField(): void {
    this.form.patchValue({ date: new Date() });
    this.form.updateValueAndValidity();
  }

  addDateTimeChangeHandler(): void {
    combineLatest([
      this.form.get("date").valueChanges,
      this.form.get("time").valueChanges,
    ])
      .pipe(
        map(([date, time]) => ({ date, time })),
        withLatestFrom(
          this.contact$,
          this.batchSettings$,
          (dateTime, contact, settings) => {
            return { ...dateTime, contact, settings };
          }
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((data) => {
        if (!this.form.get("endTime").value && data.time) {
          const [hour, minute] = data.time.split(":");
          const endHour = (+hour + 1).toString().padStart(2, "0");
          this.form.get("endTime").setValue(`${endHour}:${minute}`);
        }
        this.validateBatchControls(data);
      });
  }

  fetchData(): void {
    this.fetchBatchSettings();
    this.fetchSources();
    this.fetchResidences();
  }

  fetchBatchSettings(): void {
    this.store
      .pipe(
        select(fromSalesMeeting.getBatchSettings),
        filter((settings) => settings === null),
        switchMap(() =>
          this.salesMeetingFeature$.pipe(
            map((feature) => feature.batch_setting_id),
            filter((value) => !!value)
          )
        ),
        first()
      )
      .subscribe((id) =>
        this.store.dispatch(
          smActions.getBatchSettingsRequest({ filterSettingsGroupId: id })
        )
      );
  }

  fetchSources(): void {
    this.contactSourceFeature$
      .pipe(
        filter((f) => f.enabled),
        switchMap(() => this.contact$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ contactId }) => {
        const control = this.form.get("source");
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();

        this.store.dispatch(
          residencesActions.getContactObjectConnectionListRequest({ contactId })
        );
        this.store.dispatch(smActions.getSystemSources(contactId));
        this.store.dispatch(smActions.getOtherSources());
      });
  }

  fetchResidences(): void {
    this.contact$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ contactId }) =>
        this.store.dispatch(smActions.getResidencesRequest({ contactId }))
      );
  }

  submit(): void {
    if (this.form.invalid) {
      formUtils.markAllAsTouched(this.form);
      this.setLoading.emit(false);
      return;
    }

    this.getSubmitAction(this.form.getRawValue(), this.editMode)
      .pipe(first())
      .subscribe((action) => this.submitClicked.emit(action));
  }

  // prettier-ignore
  getSubmitAction(formValue: any, editMode = false): Observable<Action> {
    const {source, street, zip, city} = formValue;

    return combineLatest(
      [this.contact$.pipe(map(contact => contact.contactId)),
        this.store.pipe(fromUser.getEaIds),
        this.store.pipe(select(fromConfig.getTaskTypeId(SALES_MEETING_REPORT))),
        this.store.pipe(select(fromSalesMeeting.getSalesMeeting)),
        this.contactSourceFeature$.pipe(map(f => f.enabled)),
        this.selectedResidence$,
        this.store.pipe(select(fromUser.getOffice), map(o => o.officeId)),
        this.store.pipe(select(fromConfig.getFeature(EXTERNAL_PROVIDER))),
        this.store.pipe(select(getOpenInExternalProvider)),
        this.store.pipe(select(fromConfig.getModals)),
        this.store.pipe(select(fromConfig.getFeature(OBJECT_OVERVIEW))),]
    ).pipe(
      first(),
      map(data => {
        const [
          contactId,
          userIds,
          eaTaskTypeId,
          salesMeeting,
          isSourceEnabled,
          residence,
          officeId,
          externalProvider,
          openInExternalProvider,
          modals,
          objectOverViewEnabled,
        ] = data;
        const {eaEmployeeId, eaOfficeId} = <UserIds>userIds;
        let contactSourceRequest;

        if (isSourceEnabled) {
          contactSourceRequest = {eaTaskId: source.eaTaskId};

          if (!source.eaTaskId && !!source.eaTaskTypeId) {
            contactSourceRequest = this.getContactSourceData({
              contactId, eaEmployeeId, eaOfficeId, eaTaskTypeId: source.eaTaskTypeId,
            });
          }
        }

        const salesMeetingRequest = this.getSalesMeetingData({
          contactId, eaEmployeeId, residence,
        }, formValue);

        const reportSalesMeetingRequest = this.getReportSalesMeetingData({contactId, eaTaskTypeId}, formValue);

        const request = {contactSourceRequest, salesMeetingRequest};

        if (!residence) {
          request['residenceRequest'] = {contactId, officeId, eaEmployeeId, street, zip, city};
        }

        if (editMode) {
          request.salesMeetingRequest['id'] = salesMeeting.eaCrmSalesMeetingId;
          const salesTask = salesMeeting?.tasks.find((task) => task.eaTaskTypeId === eaTaskTypeId.toString());

          if (!!salesTask) {
            request.salesMeetingRequest['saleEaTaskId'] = salesTask.eaTaskId;
          }
        } else {
          request['reportSalesMeetingRequest'] = reportSalesMeetingRequest;
        }

        if (externalProvider.enabled && externalProvider.name) {
          request['externalProviderName'] = externalProvider.name;
        }

        if (externalProvider.show_connect_to_object_in_erp_button) {
          request['showConnectToObjInERPButton'] = externalProvider.show_connect_to_object_in_erp_button;
        }

        if (openInExternalProvider) {
          request['openInExternalProvider'] = openInExternalProvider;
        }

        if (modals) {
          request['modalConfig'] = modals.external_provider_connection.enabled;
        }

        if (objectOverViewEnabled) {
          request['objectOverViewEnabled'] = objectOverViewEnabled;
        }

        return smActions.saveSalesMeeting({payload: request});
      }));
  }

  getContactSourceData(params: any): any {
    return {
      ...params,
      title: "SET_CONSUMER_SOURCE",
      startTime: moment().format("YYYYMMDDHHmmss"),
      deliveryDate: moment().add(1, "days").format("YYYYMMDDHHmmss"),
    };
  }

  getSalesMeetingData(params: any, formValue: any): any {
    const { contactId, eaEmployeeId, residence } = params;
    const { date, time, endTime, office, employee, comment } = formValue;

    const [startHour, startMinute] = time.split(":");
    const [endHour, endMinute] = endTime.split(":");

    const requestData = {
      contactId,
      status: "prepared",
      startTime: moment(date)
        .hours(startHour)
        .minutes(startMinute)
        .seconds(0)
        .format(API_DATE_FORMAT),
      endTime: moment(date)
        .hours(endHour)
        .minutes(endMinute)
        .seconds(0)
        .format(API_DATE_FORMAT),
      eaOfficeId: office,
      eaEmployeeId: employee,
      actionEaEmployeeId: eaEmployeeId,
      isContactSource: "True",
      activityHours: this.parseActivityHours(false),
      activityHoursBooked: this.parseActivityHours(true),
    };

    if (comment) {
      requestData["note"] = comment;
    }

    if (residence) {
      requestData["eaOid"] = residence.eaOid;
    }

    return requestData;
  }

  getReportSalesMeetingData(params: any, formValue: any): any {
    const { street, zip, city, date, time, office, employee } = formValue;
    const { contactId, eaTaskTypeId } = params;
    const [HH, mm] = time.split(":");
    const meetingTime = moment(date).hours(HH).minutes(mm).seconds(0);

    return {
      contactId,
      eaTaskTypeId,
      eaEmployeeId: employee,
      eaOfficeId: office,
      title: `${this.translate.instant(
        "report_sales_meeting"
      )} ${getFormattedAddress({ street, zip, city })}`,
      startTime: meetingTime.format(API_DATE_FORMAT),
      deliveryDate: meetingTime.format(API_DATE_FORMAT),
    };
  }

  parseActivityHours(sendAfterBookingDate: boolean): string {
    return this.batches.controls
      .map((control) => control.value)
      .map((send, i) => (send ? this.batchSettings[i] : null))
      .filter((batch) => batch !== null)
      .filter((batch) => batch.sendAfterBookingDate === sendAfterBookingDate)
      .map((batch) => `${batch.eaCrmActivityId}=${batch.timeDiff}`)
      .join(",");
  }

  getMessageSendTime(time: string, date: Date): moment.Moment {
    const timeMoment = moment(time, "HH:mm");
    return moment(date).set({
      hour: timeMoment.get("hour"),
      minute: timeMoment.get("minute"),
    });
  }

  patchAddress(residence: QObject): void {
    this.form.patchValue({
      street: residence.street,
      zip: residence.zip,
      city: residence.city,
      fullAddress: getFormattedAddress({
        street: residence.street,
        zip: residence.zip,
        city: residence.city,
      }),
    });
    this.selectedResidence$.next(residence);
    this.getMeetingHistory();
  }

  onResidenceRemove(): void {
    this.selectedResidence$.next(null);
    this.form.patchValue(
      { street: "", zip: "", city: "", fullAddress: "" },
      { emitEvent: false }
    );
  }

  goBack(): void {
    this.store.dispatch(removeContactFromState());
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_SALES_MEETING_BASE_URL } },
        ],
      })
    );
  }

  onBrokerConnectionReady(): void {
    this.brokerConnectionReady$.next();
  }

  onEmployeeChange(employees: Employee[]): void {
    this.employeeChange.next(employees);
  }

  previewBatch(batchIndex): void {
    this.store.dispatch(
      smActions.getSelectedEmployeeRequest({
        id: this.form.get("employee").value,
      })
    );
    combineLatest([
      this.store.pipe(select(getOffice(this.form.get("office").value))),
      this.selectedEmployee$,
      this.contact$,
    ])
      .pipe(
        map((data) => ({
          office: data[0],
          employee: data[1],
          contact: data[2],
        })),
        filter(
          ({ office, employee, contact }) => !!office && !!employee && !!contact
        ),
        first()
      )
      .subscribe(({ office, employee, contact }) => {
        const { street, zip, city } = this.form.value;

        const dataSet = this.formatDataSet(
          office,
          employee,
          this.batchSettings[batchIndex],
          street,
          zip,
          city
        );
        this.store.dispatch(
          fetchPreviewRequest({
            parameters: {
              templateId:
                this.batchSettings[batchIndex].msgTemplateId.toString(),
              params: {
                dataSet,
                value2: contact.contactId,
              },
            },
          })
        );
      });
  }

  formatPhoneNumber(mobile: string, mobileCountry: string): string {
    const phoneNumberExists = mobile ? mobile.length > 0 : false;
    const phoneNumberCountryExists = mobileCountry
      ? mobileCountry.length > 0
      : false;
    let formattedPhoneNumber = mobile;

    if (phoneNumberExists && phoneNumberCountryExists) {
      formattedPhoneNumber = this.phoneService.format(mobile, mobileCountry);
    }
    return formattedPhoneNumber;
  }

  meetingIsEarly(): boolean {
    const meetingTime = this.getMeetingTime();
    if (typeof meetingTime === "object") {
      let from = moment(meetingTime);
      let to = moment(meetingTime);
      from = from.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      to = to.set({ hour: 9, minute: 1, second: 0, millisecond: 0 });

      return meetingTime.isBetween(from, to);
    }
    return false;
  }

  getMeetingTime(): any {
    const [HH, mm] = this.form.get("time").value.split(":");
    return moment(this.form.get("date").value).hours(HH).minutes(mm);
  }

  private formatDataSet(
    office: Office,
    employee: Employee,
    batch: SalesMeetingBatchSettings,
    street: string,
    zip: string,
    city: string
  ): string {
    const meetingTime = this.getMeetingTime().format("YYYYMMDDHHmmss");
    const dataSet = {
      meetingTime: meetingTime,
      templateId: batch.templateId,
      eaCrmActivityId: batch.eaCrmActivityId,
      officeName: office.name,
      employeeFullName: employee.fullName,
      officeZip: office.zip,
      officeStreet: office.street,
      employeeEmail: employee.email,
      employeeMobile: this.formatPhoneNumber(
        employee.mobile,
        employee.mobileCountryCode
      ), // FORMATERA MOBILNUMMER
      officeCity: office.city,
      officeMailAddress: office.street,
      senderName: employee.fullName,
      employeeTitle: employee.title,
      employeePhone: this.formatPhoneNumber(
        employee.phone,
        employee.phoneCountryCode
      ),
      objectStreet: street,
      objectZip: zip,
      objectCity: city,
      employeePhoto: employee.photo,
      eaOfficeId: office.eaOfficeId,
      eaEmployeeId: employee.eaEmployeeId,
    };
    return fromMessageUtils.transformObjectToMessageDataSet(dataSet);
  }

  private fillForm() {
    this.systemSources$
      .pipe(
        filter((sources) => sources.length > 0),
        first()
      )
      .subscribe((sources) => {
        this.form.get("source").setValue(sources[0]);

        if (!this.editMode && sources[0]?.updateContactSource === false) {
          if (this.salesMeetingService.hasLead$.value) {
            this.form.get("source").disable({ emitEvent: false });
          } else {
            this.form.get("source").enable({ emitEvent: false });
          }
        }
      });
  }

  getMeetingHistory() {
    const { street, zip, city } = this.form.getRawValue();
    let address = "";

    if (street) {
      address = `${street}`;
    }

    if (zip) {
      address += `, ${zip}`;
    }

    if (city) {
      address += ` ${city}`;
    }

    const params = {
      address: address.trim(),
    };

    if (address.trim().length > 0) {
      this.store.dispatch(smActions.getMeetingHistoryRequest({ params }));
    }

    this.form.get("street").markAllAsTouched();
  }

  toggleMeetingHistoryList() {
    this.showMeetingHistoryList = !this.showMeetingHistoryList;
  }

  countUniqueBrokers(arr) {
    return uniqBy(arr, "eaEmployeeId").length;
  }

  editContact() {
    this.contactId$.pipe(first()).subscribe((contactId) => {
      this.store.dispatch(
        RouterActions.go({
          path: [
            "/crm",
            { outlets: { sidebar: SIDEBAR_CONTACTS_EDIT_URL(contactId) } },
          ],
        })
      );
    });
  }

  editResidences() {
    this.contactId$.pipe(first()).subscribe((contactId) => {
      this.store.dispatch(
        RouterActions.go({
          path: [
            "/crm",
            {
              outlets: {
                sidebar: SIDEBAR_CONTACT_RESIDENCES_BASE_URL(contactId),
              },
            },
          ],
        })
      );
    });
  }

  getLocationDetails(str: string[]) {
    return str.filter((x) => x).join(", ");
  }

  handleStreetSelected(address: Address): void {
    this.form.get("zip").setValue(address?.zip || "");
    this.form.get("city").setValue(address?.city || "");
    this.form.get("street").setValue(address?.street || "");
    this.form.get("fullAddress").setValue(address?.fullAddress || "");
  }

  private timeRangeValidator(): ValidatorFn {
    return (controls: AbstractControl): ValidationErrors | null => {
      let invalid: boolean;
      const date = controls.get("date").value;
      const startTime = controls.get("time").value;
      const endTime = controls.get("endTime").value;

      if (!date || !startTime || !endTime) {
        invalid = true;
      } else {
        const startDateTime = this.getMessageSendTime(startTime, date);
        const endDateTime = this.getMessageSendTime(endTime, date);
        invalid = startDateTime.diff(endDateTime) > 0;
      }

      return invalid ? { timeRange: { valid: false } } : null;
    };
  }
}
