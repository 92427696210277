import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreateLeadCreateContactComponent } from "@app/sidebar/create-lead/create-lead-create-contact/create-lead-create-contact.component";
import { CreateLeadHomeComponent } from "@app/sidebar/create-lead/create-lead-home/create-lead-home.component";
import { CreateLeadNewComponent } from "@app/sidebar/create-lead/create-lead-new/create-lead-new.component";
import { CreateLeadComponent } from "@app/sidebar/create-lead/create-lead.component";
import { TranslateModule } from "@ngx-translate/core";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { CreateContactModule } from "../contacts/create-contact.module";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    TranslateModule,
    SearchContactModule,
    CreateContactModule,
    SidebarSharedModule,
    ContactComponentsModule,
    FormComponentsModule,
    SearchAddressModule,
    TypeaheadModule,
    UiComponentsModule,
    UtilModule,
  ],
  exports: [
    CreateLeadComponent,
    CreateLeadCreateContactComponent,
    CreateLeadHomeComponent,
    CreateLeadNewComponent,
  ],
  declarations: [
    CreateLeadComponent,
    CreateLeadCreateContactComponent,
    CreateLeadHomeComponent,
    CreateLeadNewComponent,
  ],
  providers: [],
})
export class CreateLeadModule {}
