import { TaskListState } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as freeLeadsListActions from "./free-leads-list.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export const initialState: TaskListState = {
  tasks: [],
  isLoading: false,
  loaded: false,
  totalNumberOfTasks: 0,
  numberOfTasksLeftToLoad: 0,
  allTasksLoaded: false,
  latestFetchAction: null,
  taskListConfig: {
    expanded: true,
    limit: 10,
  },
};

// Todo: Remove "| any" from action
export function freeLeadListReducer(
  state: TaskListState,
  action: Action
): TaskListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(freeLeadsListActions.getFreeLeadsRequest, (state, action) => {
    return { ...state, isLoading: true, latestFetchAction: action };
  }),
  on(freeLeadsListActions.getFreeLeadsNoTasksFound, (state) => ({
    ...state,
    isLoading: false,
    allTasksLoaded: true,
    loaded: true,
    tasks: [],
    numberOfTasksLeftToLoad: 0,
    totalNumberOfTasks: 0,
  })),
  on(
    freeLeadsListActions.getFreeLeadsSuccess,
    (state, { tasks, listStart, totalRows, replace }) => {
      const numberOfTasksLeftToLoad = totalRows - listStart - tasks.length;
      return {
        ...state,
        isLoading: false,
        allTasksLoaded: totalRows <= 10 || numberOfTasksLeftToLoad === 0,
        totalNumberOfTasks: totalRows,
        numberOfTasksLeftToLoad,
        tasks: replace ? tasks : state.tasks.concat(tasks),
        loaded: true,
      };
    }
  ),
  on(freeLeadsListActions.setListConfig, (state, { payload }) => ({
    ...state,
    taskListConfig: { ...state.taskListConfig, ...payload },
  })),
  on(freeLeadsListActions.reset, (state) => ({
    ...initialState,
    taskListConfig: {
      ...state.taskListConfig,
      limit: Math.ceil(state.tasks.length / 10) * 10,
    },
  }))
);

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.freeLeadList
);

export const getLatestFreeLeadListFetchAction = createSelector(
  getState,
  (state: TaskListState) => state.latestFetchAction
);

export const getFreeLeadsLoaded = createSelector(
  getState,
  (state: TaskListState) => state.loaded
);
