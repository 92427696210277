import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject, combineLatest, map, Observable, of } from "rxjs";

import { AvailabilityModes } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.component";
import { AdvancedOfficeEmployeeDropdownService } from "@app/shared/modules/form-components/advanced-office-employee-dropdown/advanced-office-employee-dropdown.service";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";

@Component({
  selector: "app-q-broker-multi-select",
  templateUrl: "./q-broker-multi-select.component.html",
  styleUrls: ["./q-broker-multi-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QBrokerMultiSelectComponent),
    },
  ],
})
export class QBrokerMultiSelectComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() inline: boolean;
  @Input() eaOfficeId: string;
  @Input() availabilityMode: AvailabilityModes = "restrictive";

  private _value = "";
  touched = false;
  disabled = false;

  eaOfficeId$ = new BehaviorSubject<string>("");
  activeBrokers$: Observable<any>;
  inactiveBrokers$: Observable<any>;
  options$: Observable<Select>;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(
    private officeEmployeeDropdownService: AdvancedOfficeEmployeeDropdownService
  ) {}

  ngOnInit(): void {
    this.activeBrokers$ =
      this.officeEmployeeDropdownService.getBrokersFromOffice(
        this.eaOfficeId$,
        of(this.availabilityMode),
        true
      );
    this.inactiveBrokers$ =
      this.officeEmployeeDropdownService.getBrokersFromOffice(
        this.eaOfficeId$,
        of(this.availabilityMode),
        false
      );
    this.options$ = combineLatest([
      this.activeBrokers$,
      this.inactiveBrokers$,
    ]).pipe(
      map(([activeBrokers, inactiveBrokers]) => {
        const active =
          activeBrokers.length > 0
            ? [{ label: "active", value: "group-header" }, ...activeBrokers]
            : [];
        const inactive =
          inactiveBrokers.length > 0
            ? [{ label: "inactive", value: "group-header" }, ...inactiveBrokers]
            : [];
        if (activeBrokers.length === 1) {
          this.value = active[1].value;
          this.onChange(this.value);
        }
        return [...active, ...inactive];
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.eaOfficeId) {
      this.eaOfficeId$.next(changes.eaOfficeId.currentValue);
      this.disabled = !/\S/.test(changes.eaOfficeId.currentValue);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
