import { setContactsFilters } from "@app/lists/lists-contacts-advanced/ngrx/lists-contact-advanced.actions";
import { SegmentationFilters } from "@app/models/segmentation-filters";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsContactsAdvancedState {
  filters: Partial<SegmentationFilters>;
}

export const initialState: ListsContactsAdvancedState = {
  filters: null,
};

export function listsContactsAdvancedReducer(
  state: ListsContactsAdvancedState,
  action: Action
): ListsContactsAdvancedState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(setContactsFilters, (_state, { filters }) => ({
    filters,
  }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.contactsAdvanced
);

export const getListsContactsFilters = createSelector(
  getState,
  (state: ListsContactsAdvancedState) => state.filters
);
