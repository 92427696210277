import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Subject, takeUntil } from "rxjs";
import * as previewActions from "./ngrx/preview.actions";
import { ofType } from "@ngrx/effects";

@Component({
  selector: "preview-modal",
  templateUrl: "./preview-modal.component.html",
  styleUrls: ["./preview-modal.component.scss"],
})
export class PreviewModalComponent implements AfterViewInit, OnDestroy {
  @ViewChild(ModalDirective, { static: false })
  modal: ModalDirective;
  @ViewChild("iframe", { static: false })
  iframe: ElementRef;

  consumerName: string;
  showLinksInNewTab: boolean;
  unsubscribe$ = new Subject<void>();

  constructor(
    private dispatcher: ActionsSubject,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit(): void {
    this.dispatcher
      .pipe(
        ofType(previewActions.showPreviewModal),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        ({ parameters: { html, consumerName, showLinksInNewTab } }) => {
          this.writeHtmlToIframe(html);
          this.consumerName = consumerName;
          this.showLinksInNewTab = showLinksInNewTab;
        }
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  writeHtmlToIframe(html: string): void {
    const doc = this.iframe.nativeElement.contentDocument;
    doc.open();
    doc.write(html);
    doc.close();
    this.modal.show();
  }

  onLoad() {
    try {
      if (
        !!this.showLinksInNewTab &&
        this.iframe.nativeElement.contentDocument
      ) {
        const a = this.iframe.nativeElement.contentDocument.querySelector("a");
        if (a) {
          this.renderer.setAttribute(a, "target", "_blank");
        }
      }
    } catch {}
  }
}
