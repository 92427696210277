/* eslint-disable max-len */
import {
  MANAGE_POTENTIAL_BUYER_MULTIPLE,
  MANAGE_POTENTIAL_BUYER_SINGLE,
} from "@app/shared/utils/tab-types";
import { PotentialBuyerManageMultipleComponent } from "./containers/potential-buyer-manage-multiple/potential-buyer-manage-multiple.component";
import { PotentialBuyerManageSingleComponent } from "./containers/potential-buyer-manage-single/potential-buyer-manage-single.component";
import { PotentialBuyerGuard } from "./potential-buyer.guard";

export const sidebarPotentialBuyerRoutes = [
  {
    path: "potential-buyer",
    component: PotentialBuyerManageSingleComponent,
    canActivate: [PotentialBuyerGuard],
    data: { tabType: MANAGE_POTENTIAL_BUYER_SINGLE },
  },
  {
    path: "potential-buyers",
    component: PotentialBuyerManageMultipleComponent,
    canActivate: [PotentialBuyerGuard],
    data: { tabType: MANAGE_POTENTIAL_BUYER_MULTIPLE },
  },
];
