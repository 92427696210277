import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from "rxjs";

export type RequestType = "remigrate" | "conversion";

@Injectable()
export class DebuggerObjectService {
  private _hasIntegration$: Observable<boolean>;
  hasIntegration$: Observable<boolean>;
  isWorking$ = new BehaviorSubject<boolean>(false);

  constructor(private store: Store<AppState>, private apiService: ApiService) {
    this.mapStateToProps();
  }

  storeDebuggerObject(
    requestType: RequestType,
    params: {
      eaOid: string;
      adjacentResourcesToRemigrate: string[];
    }
  ) {
    this.isWorking$.next(true);
    let url = "";
    if (requestType === "remigrate") {
      url = "remigrate";
    } else {
      url = "get-conversion-result";
    }

    return this.apiService
      .post(
        `debugger/object/${url}/${params.eaOid}`,
        { adjacentResourcesToRemigrate: params.adjacentResourcesToRemigrate },
        Categories.Integrations
      )
      .pipe(
        map((res: { [key: string]: any }) => {
          this.isWorking$.next(false);
          return res;
        }),
        catchError(() => {
          this.isWorking$.next(false);
          return of(null);
        })
      );
  }

  private mapStateToProps() {
    this._hasIntegration$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.ObjectCopy))
    );

    this.hasIntegration$ = this._hasIntegration$.pipe(
      switchMap((enabled) => (!!enabled ? this._hasIntegration$ : of(false))),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }
}
