import { Observable, of as observableOf } from "rxjs";

import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO, PaginationListDTO } from "@app/models";
import { Store } from "@ngrx/store";
import { catchError, first, map } from "rxjs";

@Injectable()
export class EmployeeLookupService {
  constructor(private store: Store<AppState>, private apiService: ApiService) {}

  checkEmailNotTaken(keyword: string): Observable<boolean> {
    return this.apiService.get("employees/searchFreeText", { keyword }).pipe(
      first(),
      map((response: PaginationListDTO) =>
        response.rows.some((e: EmployeeDTO) => e.email === keyword)
      ),
      catchError((error) => {
        this.store.dispatch(toastActions.danger(error));
        return observableOf(false);
      })
    );
  }
}
