import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { map, Observable } from "rxjs";

import { ObjectService } from "@app/core/ngrx/entity-services/object.service";

@Component({
  selector: "app-q-contact-select",
  templateUrl: "./q-contact-select.component.html",
  styleUrls: ["./q-contact-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QContactSelectComponent),
    },
  ],
})
export class QContactSelectComponent implements OnInit, ControlValueAccessor {
  @Input() eaOid: string;
  @Input() invalid: boolean;
  @Input() inline: boolean;

  private _value = null;
  touched = false;
  disabled = false;

  contacts$: Observable<any>;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(private objectService: ObjectService) {}

  ngOnInit(): void {
    this.contacts$ = this.objectService.getShowingObjectById(this.eaOid).pipe(
      map((showing) =>
        [...showing?.buyers, ...showing?.sellers].map((contact) => {
          return {
            value: contact.contactId,
            label: `${contact.firstName} ${contact.familyName}`,
          };
        })
      )
    );
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
