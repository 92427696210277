import {
  NpsEntity,
  NpsRequest,
  NpsSurveyResponsesRequest,
  NpsSurveyResponsesSuccess,
} from "@app/models/nps";
import { createAction, props } from "@ngrx/store";
import { Employee } from "@app/models";
import { UserIds } from "@app/shared/user";
import { Kpi } from "../models/kpi";

export const fetchKpis = createAction(
  "[KPI] FETCH_KPIS_REQUEST",
  props<{
    parameters: {
      type: string;
      userIds: UserIds;
      searchParams: Record<string, unknown>;
    };
  }>()
);
export const fetchKpisFail = createAction("[KPI] FETCH_KPIS_FAIL");
export const fetchKpisSuccess = createAction(
  "[KPI] FETCH_KPIS_SUCCESS",
  props<{ kpis: Kpi[] }>()
);

export const fetchKpisBufferClose = createAction(
  "[KPI] FETCH_KPIS_BUFFER_CLOSE"
);
export const fetchKpiSummaryRequest = createAction(
  "[KPI] FETCH_KPI_SUMMARY_REQUEST",
  props<{
    parameters: {
      type: string;
      userIds: UserIds;
      searchParams: Record<string, unknown>;
    };
  }>()
);

export const fetchKpiSummarySuccess = createAction(
  "[KPI] FETCH_KPI_SUMMARY_SUCCESS",
  props<{ kpi: Kpi }>()
);

export const fetchKpiSummaryFail = createAction("[KPI] FETCH_KPI_SUMMARY_FAIL");

export const fetchKpiDetailsRequest = createAction(
  "[KPI] FETCH_KPI_DETAILS_REQUEST",
  props<{
    parameters: {
      type: string;
      userIds: UserIds;
      searchParams: Record<string, unknown>;
      useEmployeeNotDetail?: boolean;
    };
  }>()
);

export const fetchKpiDetailsSuccess = createAction(
  "[KPI] FETCH_KPI_DETAILS_SUCCESS",
  props<{
    parameters: {
      type: string;
      details: any;
    };
  }>()
);

export const fetchKpiDetailsFail = createAction("[KPI] FETCH_KPI_DETAILS_FAIL");

export const fetchEmployeeRequest = createAction(
  "[KPI] FETCH_EMPLOYEE_REQUEST",
  props<{ id: string }>()
);

export const fetchEmployeeSuccess = createAction(
  "[KPI] FETCH_EMPLOYEE_SUCCESS",
  props<{ employee: Employee }>()
);

export const fetchEmployeeFail = createAction("[KPI] FETCH_EMPLOYEE_FAIL");

export const fetchEmployeeNpsRequest = createAction(
  "[KPI] FETCH_EMPLOYEE_NPS_REQUEST",
  props<{ nps: NpsRequest }>()
);

export const fetchEmployeeNpsSuccess = createAction(
  "[KPI] FETCH_EMPLOYEE_NPS_SUCCESS",
  props<{ nps: NpsEntity }>()
);

export const fetchEmployeeNpsFail = createAction(
  "[KPI] FETCH_EMPLOYEE_NPS_FAIL"
);

export const fetchNpsSurveyResponsesRequest = createAction(
  "[KPI] FETCH_NPS_SURVEY_RESPONSES_REQUEST",
  props<{ survery: NpsSurveyResponsesRequest }>()
);

export const fetchNpsSurveyResponsesSuccess = createAction(
  "[KPI] FETCH_NPS_SURVEY_RESPONSES_SUCCESS",
  props<{ response: NpsSurveyResponsesSuccess }>()
);

export const fetchNpsSurveyResponsesFail = createAction(
  "[KPI] FETCH_NPS_SURVEY_RESPONSES_FAIL"
);

export const setTitle = createAction(
  "[KPI] SET_TITLE",
  props<{ title: string }>()
);

export const resetState = createAction("[KPI] RESET_STATE");
