import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
  name: "dayAbbreviation",
  pure: false,
})
export class DayAbbreviationPipe implements PipeTransform {
  transform(value: Date): any {
    return moment(value).format("ddd");
  }
}
