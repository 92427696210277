import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_TIPS_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { SEND_TIPS } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import * as sendTipsActions from "../ngrx/send-tips.actions";

@Component({
  selector: "send-tips-create-contact",
  template: `
    <create-contact-simple
      [type]="tabType"
      (onSubmit)="onSubmit($event)"
      (onQuedroContactSelected)="onQuedroContactSelected($event)"
    ></create-contact-simple>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendTipsCreateContactComponent {
  tabType = SEND_TIPS;

  constructor(private store: Store<AppState>) {}

  onSubmit({ params, source }): void {
    this.store.dispatch(sendTipsActions.createContact(params, source));
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: SIDEBAR_TIPS_CREATE_URL(contact.contactId) } },
        ],
      })
    );
  }
}
