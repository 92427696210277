import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { Component, OnDestroy, OnInit } from "@angular/core";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable, Subject, takeUntil } from "rxjs";
import { AppState } from "../app.reducer";
import { ENTERING_DURATION, EXITING_DURATION } from "../shared/animations";
import * as sidebarActions from "./ngrx/sidebar.actions";
import { sidebarIsMinimized } from "./ngrx/sidebar.reducer";

@Component({
  selector: "sidebar-component",
  template: `
    <div
      class="sidebar"
      [@sidebarAnimationStates]="sidebarAnimationStates$ | async"
    >
      <sidebar-tabs></sidebar-tabs>
      <router-outlet></router-outlet>
    </div>
  `,
  styleUrls: ["./sidebar.component.scss", "../../styles/icon-colors.scss"],
  animations: [
    trigger("sidebarAnimationStates", [
      state("void", style({ "margin-right": "-26rem" })),
      state("0", style({ "margin-right": "-26rem" })),
      state("1", style({ "margin-right": "0" })),
      transition("void => 0", animate(`${ENTERING_DURATION} ease-in`)),
      transition("void => 1", animate(`${ENTERING_DURATION} ease-in`)),

      transition("0 => void", animate(`${EXITING_DURATION} ease-out`)),
      transition("0 => 1", animate(`${ENTERING_DURATION} ease-in`)),

      transition("1 => void", animate(`${EXITING_DURATION} ease-out`)),
      transition("1 => 0", animate(`${EXITING_DURATION} ease-out`)),
    ]),
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  sidebarAnimationStates$: Observable<boolean>;

  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.sidebarAnimationStates$ = this.store.pipe(
      select(sidebarIsMinimized),
      map((m) => !m)
    );

    this.store
      .pipe(
        select(fromShared.isMobile),
        takeUntil(this.unsubscribe$),
        filter((value) => !!value)
      )
      .subscribe(() => this.store.dispatch(sidebarActions.show()));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
