<h3 *ngIf="showHeader">
  <app-icon size="large" name="check"></app-icon>&nbsp;<span>{{
    "overdue_tasks" | translate
  }}</span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="free-leads-container">
    <p class="total" *ngIf="(overdueTasks$ | async).length > 0">
      {{ "total" | translate }}: {{ total$ | async }}
    </p>
    <p class="no-tasks" *ngIf="(overdueTasks$ | async).length === 0">
      {{ "no_tasks_here" | translate }}
      <app-icon [name]="'thumbs-up'"></app-icon>
    </p>
    <div class="card-wrapper" *ngFor="let task of overdueTasks$ | async">
      <app-mobile-card
        color="WHITE"
        [enableMetadata]="true"
        [shadow]="false"
        [size]="'SLIM'"
        [enableActionDropdown]="true"
        (click)="onTaskClick(task, $event)"
      >
        <span title>
          <span [style.color]="resolveColor(task)">
            <app-icon
              size="medium"
              [name]="resolveIcon(task) | async"
            ></app-icon>
          </span>
          {{ task.eaTaskTypeName }} &nbsp;&nbsp;
        </span>
        <div metadata>
          <span *ngIf="task.deliveryDate">
            <app-icon name="clock"></app-icon>
            {{ "due_date" | translate }}: {{ task.deliveryDate | momentify }}
          </span>
          <br />
          <span *ngIf="task.insertedDate">
            <app-icon name="clock"></app-icon>
            {{ "created" | translate }}: {{ task.insertedDate | momentify }}
          </span>
          <span *ngIf="task.contactId && task.contactFirstName">
            <app-contact-name
              [contactId]="task.contactId"
              [contactType]="task.contactType"
            >
              {{ task.contactFirstName }} {{ task.contactFamilyName }}
            </app-contact-name>
          </span>
        </div>
        <ng-container buttons>
          <li role="menuitem">
            <button
              class="btn btn-secondary"
              (click)="onTaskClick(task, $event)"
            >
              <app-icon name="pen"></app-icon>
              {{ "manage" | translate }}
            </button>
          </li>
          <li disableNavigation role="menuitem">
            <button
              disableNavigation
              class="btn btn-default"
              (click)="openInNewTab(task)"
            >
              <app-icon name="share-square"></app-icon>
              {{ "open_in_new_tab" | translate }}
            </button>
          </li>
        </ng-container>
      </app-mobile-card>
    </div>
  </div>
  <ng-template #noData>
    <span translate>no_data_found</span>
  </ng-template>
</div>
