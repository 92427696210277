import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { DrawMapModalComponent } from "@app/sidebar/search-profile/search-profile-form/draw-map-modal/draw-map-modal.component";
import { NguiMapModule } from "@ngui/map";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";

const components = [DrawMapModalComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    ReactiveFormsModule,
    FormsModule,
    TooltipModule,
    RouterModule,
    NguiMapModule,
    UiComponentsModule,
  ],
  declarations: components,
  exports: components,
  providers: [],
})
export class DrawMapModule {}
