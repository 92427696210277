import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { LanguageSwitchComponent } from "@app/sidebar/profile/language-switch/language-switch.component";
import { TranslateModule } from "@ngx-translate/core";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { ChangeOfficeComponent } from "./change-office/change-office.component";
import { ProfileCardComponent } from "./profile-card/profile-card.component";
import { ProfileComponent } from "./profile.component";
import { ClientSwitchComponent } from "@app/sidebar/profile/client-switch/client-switch.component";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule } from "@angular/forms";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    UtilModule,
    RouterModule,
    UiComponentsModule,
    ErpFormElementsModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
  ],
  exports: [
    ProfileComponent,
    ProfileCardComponent,
    ChangeOfficeComponent,
    LanguageSwitchComponent,
    ClientSwitchComponent,
  ],
  declarations: [
    ProfileComponent,
    ProfileCardComponent,
    ChangeOfficeComponent,
    LanguageSwitchComponent,
    ClientSwitchComponent,
  ],
  providers: [],
})
export class ProfileModule {}
