export class Employment {
  eaOfficeId: string;
  officeName: string;
  status: string;
  role: string;
  hasOnlineBooking: boolean;

  previousState: Employment;
  processing: boolean;

  eaEmployeeId: string;
  fullName: string;
  title: string;
  position: string;
  countryCode: string;
  mobileCountryCode: string;
  mobile: string;
  phone: string;
  email: string;
  admin: boolean;
  signature: string;
  type: string;
  photo: string;
  isSellingResourceAtThisOffice?: boolean;

  exclusions?: {
    description: string;
    exclusionAliasName: string;
    exclusionId: string;
    exclusionType: string;
    insertedDate: string;
    name: string;
    updatedDate: string;
  }[];

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
