import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";

import { ApiService } from "@app/core/services/api/api.service";
import {
  Address,
  AddressProvider,
  AddressValidationResponse,
  AddressValidator,
} from "./AddressProvider";

interface ValidSuggestion {
  street?: string;
  streetNumber?: string;
  letter?: string;
  postalCode?: string;
  locality?: string;
  adm2?: string;
}

@Injectable()
export class ValidService implements AddressProvider, AddressValidator {
  type: string;
  countryCode: string;

  constructor(private api: ApiService) {}

  suggest(query: string): Observable<Address[]> {
    if (!!query) {
      return this.api
        .get(
          `suggest/${this.resolveType(this.type)}/${this.countryCode}`,
          { query },
          "valid"
        )
        .pipe(
          map((data: { suggestions }) =>
            this.mapToAddressSuggestions(data.suggestions)
          )
        );
    } else {
    }
  }

  validate(address: Address): Observable<AddressValidationResponse> {
    const { street, zip: postalCode, city: locality } = address;
    return this.api
      .get(
        `validate/${this.countryCode}`,
        { street, postalCode, locality },
        "valid"
      )
      .pipe(
        map((data: { response }) => data.response),
        map(({ valid, suggestions }) => {
          return {
            valid,
            suggestions: suggestions
              ? this.mapToAddressSuggestions(suggestions)
              : [],
          };
        })
      );
  }

  private mapToAddressSuggestions(suggestions: ValidSuggestion[]): Address[] {
    return suggestions.map((s) => {
      return {
        street: this.formatStreet(s),
        zip: s.postalCode,
        city: s.locality,
        municipality: s.adm2,
      };
    });
  }

  private formatStreet(s: ValidSuggestion): string {
    if (s.street && s.streetNumber && s.letter) {
      return `${s.street} ${s.streetNumber}${s.letter}`;
    } else if (s.street && s.streetNumber) {
      return `${s.street} ${s.streetNumber}`;
    } else {
      return s.street;
    }
  }

  private resolveType(type: string): string {
    if (type === "street") {
      return "street";
    }
    if (type === "zip") {
      return "postalcode";
    }
    if (type === "city") {
      return "locality";
    }
  }
}
