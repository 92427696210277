import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { QObject } from "@app/models";
import {
  fetchLatestObjectsFail,
  fetchLatestObjectsRequest,
  fetchLatestObjectsSuccess,
} from "@app/widgets/widgets/latest-objects-for-sale-widget/ngrx/latest-objects-for-sale-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class LatestObjectsForSaleWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchLatestObjectsForSaleRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLatestObjectsRequest),
      switchMap(({ eaOfficeId }) =>
        this.apiService
          .get(
            "objects-multi-locations/search",
            {
              status: "10",
              publishedOnNet: "both",
              limit: 20,
              sortBy: "statusChange",
              sortOrder: "desc",
              seller: true,
              eaOfficeId,
            },
            "api"
          )
          .pipe(
            map((response: { objectsMultiLocations: QObject[] }) =>
              fetchLatestObjectsSuccess({
                objects: response?.objectsMultiLocations,
              })
            ),
            catchError(() => of(fetchLatestObjectsFail()))
          )
      )
    )
  );
}
