import * as widgets from "@app/statistics/utils/supported-widgets";

export const supportedExternalWidgets = {
  leads: widgets.FREE_LEADS,
  kpi: widgets.KPI_COLLECTION,
  marketing: widgets.ADFENIX_MARKETING_REPORTS,
  marketing_summary: widgets.ADFENIX_MARKETING_REPORTS_SUMMARY,
  statistics_summary: widgets.SUMMARY,
  [widgets.LEAD_RESULT]: widgets.LEAD_RESULT,
  [widgets.TASKS]: widgets.TASKS,
  [widgets.CURRENT_TASKS]: widgets.CURRENT_TASKS,
  [widgets.OVERDUE_TASKS]: widgets.OVERDUE_TASKS,
  [widgets.SOLD_OBJECTS]: widgets.SOLD_OBJECTS,
};

export function isSupportedWidget(widgetName: string): boolean {
  return !!widgetName && !!supportedExternalWidgets[widgetName];
}
