import { SendTipsGuard } from "../guards/send-tips-guard.service";
import { SendTipsCreateContactComponent } from "./send-tips-create-contact/send-tips-create-contact.component";
import { SendTipsHomeComponent } from "./send-tips-home/send-tips-home.component";
import { SendTipsNewComponent } from "./send-tips-new/send-tips-new.component";
import { SendTipsComponent } from "./send-tips.component";

export const sidebarTipsRoutes = {
  path: "tips",
  component: SendTipsComponent,
  children: [
    {
      path: "",
      component: SendTipsHomeComponent,
      canActivate: [SendTipsGuard],
    },
    {
      path: "contacts/new",
      component: SendTipsCreateContactComponent,
      canActivate: [SendTipsGuard],
    },
    {
      path: "new/contacts/:id",
      component: SendTipsNewComponent,
      canActivate: [SendTipsGuard],
    },
  ],
};
