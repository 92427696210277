import { Contact, QObject } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as createLeadActions from "./create-lead.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface TaskTemplate {
  eaTaskTemplateId: number;
  eaTaskTypeId: number;
  typeName: string;
  title: string;
  originService?: string;
}

export interface CreateLeadState {
  selectedContact: Contact;
  selectedContactLoading: boolean;
  taskTemplates: TaskTemplate[];
  officeObjectsForSale: QObject[];
  loadingObjects: boolean;
  processing: boolean;
}

export const initialState: CreateLeadState = {
  selectedContact: null,
  selectedContactLoading: false,
  taskTemplates: [],
  officeObjectsForSale: [],
  loadingObjects: false,
  processing: false,
};

export function createLeadReducer(
  state: CreateLeadState,
  action: Action
): CreateLeadState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(createLeadActions.createContactRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(createLeadActions.createContactFailed, (state) => ({
    ...state,
    processing: false,
  })),
  on(
    createLeadActions.getContactSuccess,
    createLeadActions.setContact,
    (state, { contact }) => ({
      ...state,
      selectedContact: contact,
      selectedContactLoading: false,
      processing: false,
    })
  ),
  on(
    createLeadActions.reset,
    createLeadActions.createLeadSuccess,
    createLeadActions.getContactFailed,
    () => ({ ...initialState })
  ),
  on(createLeadActions.getTaskTemplatesSuccess, (state, { taskTemplates }) => ({
    ...state,
    taskTemplates,
  })),
  on(createLeadActions.getOfficeObjectForSaleRequest, (state) => ({
    ...state,
    loadingObjects: true,
  })),
  on(createLeadActions.getOfficeObjectForSaleSuccess, (state, { objects }) => ({
    ...state,
    officeObjectsForSale: objects,
    loadingObjects: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.createLead
);

export const getSelectedContact = createSelector(
  getState,
  (state: CreateLeadState) => state.selectedContact
);

export const getSelectedContactLoading = createSelector(
  getState,
  (state: CreateLeadState) => state.selectedContactLoading
);

export const getProcessing = createSelector(
  getState,
  (state: CreateLeadState) => state.processing
);

export const getTaskTemplates = createSelector(
  getState,
  (state: CreateLeadState) => state.taskTemplates
);

export const getOfficeObjects = createSelector(
  getState,
  (state: CreateLeadState) => state.officeObjectsForSale
);

export const getOfficeObjectsLoading = createSelector(
  getState,
  (state: CreateLeadState) => state.loadingObjects
);
