import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ResidenceStatus } from "@app/sidebar/potential-buyer/models/residence-status";

@Component({
  selector: "app-owns-residence",
  templateUrl: "./owns-residence.component.html",
  styleUrls: ["./owns-residence.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnsResidenceComponent {
  @Input() status: string;
  @Output() statusChange = new EventEmitter<string>();

  Status = ResidenceStatus;

  handleClick(status: string): void {
    const isSelected = this.isSelected(status);
    const newStatus = isSelected ? ResidenceStatus.Unknown : status;
    this.statusChange.emit(newStatus);
  }

  isSelected(status: string): boolean {
    if (!this.status) {
      return;
    }
    return this.status === status;
  }
}
