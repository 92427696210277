import { TaskListState } from "@app/models";
import * as followUpSalesMeetingsListActions from "./followup-sales-meeting-list.actions";
import { Action, createReducer, on } from "@ngrx/store";

export const initialState: TaskListState = {
  tasks: [],
  isLoading: false,
  loaded: false,
  totalNumberOfTasks: 0,
  numberOfTasksLeftToLoad: 0,
  allTasksLoaded: false,
  taskListConfig: {
    expanded: true,
    limit: 10,
  },
};

// TODO: Remove "| any" from action type
export function followUpSalesMeetingListReducer(
  state: TaskListState,
  action: Action
): TaskListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    followUpSalesMeetingsListActions.getFollowUpSalesMeetingsRequest,
    (state) => ({ ...state, isLoading: true, loaded: false })
  ),
  on(
    followUpSalesMeetingsListActions.getFollowUpSalesMeetingsNoTasksFound,
    (state) => ({
      ...state,
      isLoading: false,
      allTasksLoaded: true,
      loaded: true,
    })
  ),
  on(
    followUpSalesMeetingsListActions.getFollowUpSalesMeetingsSuccess,
    (state, { tasks, listStart, totalRows }) => {
      const numberOfTasksLeftToLoad = totalRows - listStart - tasks.length;
      const newTasksList = [...state.tasks, ...tasks];
      const updateTasksList = [
        ...new Map(
          newTasksList.map((item) => [item["eaTaskId"], item])
        ).values(),
      ];

      return {
        ...state,
        isLoading: false,
        loaded: true,
        allTasksLoaded: totalRows <= 10 || numberOfTasksLeftToLoad === 0,
        totalNumberOfTasks: totalRows,
        numberOfTasksLeftToLoad,
        tasks: updateTasksList,
      };
    }
  ),
  on(followUpSalesMeetingsListActions.clearState, (state) => ({
    ...initialState,
    taskListConfig: {
      ...state.taskListConfig,
      limit: Math.ceil(state.tasks.length / 10) * 10,
    },
  }))
);
