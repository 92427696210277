import { CommonModule, NgOptimizedImage } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CampaignsSubpageService } from "@app/campaigns/campaigns-subpage-service";
import { ContactsSubPageService } from "@app/contacts/contacts-sub-page.service";
import { AssignLeadViewsService } from "@app/core/components/assign-lead-modal/containers/assign-lead-modal/assign-lead-views.service";
import { StandardModalComponent } from "@app/core/components/standard-modal/standard-modal.component";
import { NgrxUtilService } from "@app/core/ngrx/services/ngrx-util.service";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import { ClickService } from "@app/core/services/click/click.service";
import { ContactActionService } from "@app/core/services/contact/contact-action.service";
import { ContactService } from "@app/core/services/contact/contact.service";
import { DateService } from "@app/core/services/date/date.service";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { JwtInterceptor } from "@app/core/services/jwt/jwt.interceptor";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { LocaleService } from "@app/core/services/locale/locale.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { ScrollService } from "@app/core/services/scroll/scroll.service";
import { SessionService } from "@app/core/services/session/session.service";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { UrlService } from "@app/core/services/url/url.service";
import { ListsSubPageService } from "@app/lists/lists-sub-page.service";
import { MobileNavigationModule } from "@app/shared-features/mobile-navigation/mobile-navigation.module";
import { StickyNotificationModule } from "@app/shared-features/sticky-notification/sticky-notification.module";
import { AddContactToCallingListModule } from "@app/shared/modules/add-contact-to-calling-list/add-contact-to-calling-list.module";
import { CalendarWidgetModule } from "@app/shared/modules/calendar-widget/calendar-widget.module";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { ProductNewsModule } from "@app/shared/modules/product-news/product-news.module";
import { ProgressWidgetModule } from "@app/shared/modules/progress-widget/progress-widget.module";
import { PropertyComponentsModule } from "@app/shared/modules/property-components/property-components.module";
import { WeatherModule } from "@app/shared/modules/weather/weather.module";
import { ShowingsSubPageService } from "@app/showings/showings-sub-page.service";
import { ExternalContactServiceComponent } from "@app/sidebar/shared/external-contact-service/external-contact-service.component";
import { StatisticsSubPageService } from "@app/statistics/services/statistics-sub-page.service";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UiComponentsModule } from "../shared/modules/ui-components/ui-components.module";
import { UtilModule } from "../shared/modules/util/util.module";
import { SidebarModule } from "../sidebar/sidebar.module";
import { AssignLeadFiltersComponent } from "./components/assign-lead-modal/components/assign-lead-filters/assign-lead-filters.component";
import { AssignLeadModalComponent } from "./components/assign-lead-modal/containers/assign-lead-modal/assign-lead-modal.component";
import { AssignLeadToOfficeModalComponent } from "./components/assign-lead-modal/containers/assign-lead-to-office-modal/assign-lead-to-office-modal.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { HeaderComponent } from "./components/layout/header/header.component";
import { MainPageComponent } from "./components/layout/main-page/main-page.component";
import { NavbarComponent } from "./components/layout/navbar/navbar.component";
import { HistorySidebarComponent } from "./components/multi-tasker/history-sidebar/history-sidebar.component";
import { MultiTaskerComponent } from "./components/multi-tasker/multi-tasker.component";
import { PreviewModalComponent } from "./components/preview-modal/preview-modal.component";
import { SearchResultsColumnComponent } from "./components/search/components/columns/column.component";
import { SearchContactCardComponent } from "./components/search/components/contact-card/contact-card.component";
import { SearchEmployeeCardComponent } from "./components/search/components/employee-card/employee-card.component";
import { SearchLeadCardComponent } from "./components/search/components/lead-card/lead-card.component";
import { SearchObjectCardComponent } from "./components/search/components/object-card/object-card.component";
import { SearchOverlayComponent } from "./components/search/components/overlay/search-overlay.component";
import { GroupedSearchResultsComponent } from "./components/search/components/search-results-grouped/search-results-grouped.component";
import { SearchResultsComponent } from "./components/search/components/search-results/search-results.component";
import { SearchComponent } from "./components/search/search.component";
import { SearchService } from "./components/search/services/search.service";
import { ToastComponent } from "./components/toast/toast.component";
import { IosScrollFixDirective } from "./directives/ios-scroll-fix.directive";

const layout = [MainPageComponent, HeaderComponent, NavbarComponent];
const search = [
  SearchComponent,
  SearchOverlayComponent,
  SearchResultsComponent,
  SearchResultsColumnComponent,
  SearchContactCardComponent,
  SearchObjectCardComponent,
  SearchLeadCardComponent,
  SearchEmployeeCardComponent,
  GroupedSearchResultsComponent,
];
const components = [
  ...layout,
  ...search,
  ToastComponent,
  ConfirmModalComponent,
  PreviewModalComponent,
  StandardModalComponent,
  ExternalContactServiceComponent,
  HistorySidebarComponent,
  MultiTaskerComponent,
  AssignLeadFiltersComponent,
  AssignLeadModalComponent,
  AssignLeadToOfficeModalComponent,
];
const directives = [IosScrollFixDirective];

@NgModule({
  imports: [
    CommonModule,
    CalendarWidgetModule,
    ProgressWidgetModule,
    RouterModule,
    FormsModule,
    MomentModule,
    TranslateModule,
    ModalModule,
    SidebarModule,
    UiComponentsModule,
    UtilModule,
    MobileNavigationModule,
    StickyNotificationModule,
    TooltipModule,
    AddContactToCallingListModule,
    BsDropdownModule,
    ProgressbarModule,
    WeatherModule,
    ProductNewsModule,
    ContactComponentsModule,
    PropertyComponentsModule,
    MatTooltipModule,
    PopoverModule,
    MatExpansionModule,
    FormComponentsModule,
    MatCheckboxModule,
    NgOptimizedImage,
  ],
  declarations: [...components, ...directives],
  exports: [
    ToastComponent,
    ConfirmModalComponent,
    PreviewModalComponent,
    StandardModalComponent,
    ExternalContactServiceComponent,
    MultiTaskerComponent,
    AssignLeadModalComponent,
    AssignLeadToOfficeModalComponent,
  ],
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        ApiService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        AuthService,
        SearchService,
        ContactService,
        PhoneNumberService,
        TaskTypeResolverService,
        ScrollService,
        ClickService,
        DateService,
        SessionService,
        ContactActionService,
        LocaleService,
        FeatureConfigManagerService,
        UrlService,
        LocalStorageService,
        NgrxUtilService,
        ListsSubPageService,
        StatisticsSubPageService,
        CampaignsSubpageService,
        ShowingsSubPageService,
        ContactsSubPageService,
        AssignLeadViewsService,
      ],
    };
  }
}
