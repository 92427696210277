import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { Bid } from "@app/integrations/bidding/models/bid";
import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import * as httpCodes from "@app/shared/utils/http-status-codes";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { catchError, first, map, mergeMap, of, switchMap, tap } from "rxjs";
import { AddBidComponent } from "../../containers/add-bid/add-bid.component";
import * as biddingActions from "./bidding.actions";
import { Action } from "@ngrx/store";

@Injectable()
export class BiddingEffects {
  fetchBidsRequest: Action;
  fetchHighestBidRequest: Action;
  fetchHighestBidForContactRequest: Action;
  bsModalRef: BsModalRef;

  fetchExternalObjectMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.fetchExternalObjectMetadataRequest),
      switchMap(({ eaOid }) =>
        this.api
          .get(
            `external-meta-data/object/${eaOid}`,
            {},
            Categories.Integrations
          )
          .pipe(
            map((externalObjectMetaData: ExternalObjectMetadata) =>
              biddingActions.fetchExternalObjectMetadataSuccess({
                externalObjectMetaData,
              })
            ),
            catchError(() =>
              of(biddingActions.fetchExternalObjectMetadataFailure())
            )
          )
      )
    )
  );

  fetchHighestBids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.fetchHighestBidRequest),
      tap((action) => (this.fetchHighestBidRequest = action)),
      switchMap(({ eaOid }) =>
        this.api
          .get(`object/${eaOid}/bids/highest`, {}, Categories.Integrations)
          .pipe(
            map((res: BidsResponse) => {
              if (res.bids.length > 0) {
                return biddingActions.fetchHighestBidSuccess({
                  highestAmount: res.bids[0].amount,
                });
              }
              return biddingActions.fetchHighestBidSuccess({
                highestAmount: null,
              });
            }),
            catchError((res) =>
              of(biddingActions.fetchHighestBidFailure({ payload: res }))
            )
          )
      )
    )
  );

  fetchHighestBidsFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.fetchHighestBidFailure),
      map(() => {
        return toastActions.info({
          message: "object_not_found_in_external_system",
        });
      })
    )
  );

  fetchHighestBidForContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.fetchHighestBidForContactRequest),
      tap((action) => (this.fetchHighestBidForContactRequest = action)),
      switchMap(({ eaOid, contactId }) =>
        this.api
          .get(
            `object/${eaOid}/potential-buyer/${contactId}/bids/highest`,
            {},
            Categories.Integrations
          )
          .pipe(
            map((res: BidResponse) => {
              if (!res.bid) {
                return biddingActions.fetchHighestBidForContactSuccess({
                  bidAmount: null,
                  bidId: null,
                });
              } else {
                return biddingActions.fetchHighestBidForContactSuccess({
                  bidAmount: res.bid.amount,
                  bidId: res.bid.externalBidId,
                });
              }
            }),
            catchError(() =>
              of(biddingActions.fetchHighestBidForContactFailure())
            )
          )
      )
    )
  );

  fetchBids$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.fetchBidsRequest),
      tap((action) => (this.fetchBidsRequest = action)),
      switchMap(({ eaOid }) =>
        this.api
          .get(`object/${eaOid}/bids/search`, {}, Categories.Integrations)
          .pipe(
            map((res: BidsResponse) =>
              biddingActions.fetchBidsSuccess({ bids: res.bids })
            ),
            catchError(() => of(biddingActions.fetchBidsFailure()))
          )
      )
    )
  );

  removeBid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.removeBidRequest),
      switchMap(({ id }) =>
        this.api.delete(`object/bid/${id}`, {}, Categories.Integrations).pipe(
          map(() => biddingActions.removeBidSuccess()),
          catchError((res) =>
            of(biddingActions.removeBidFailure({ payload: res }))
          )
        )
      )
    )
  );

  removeBidSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.removeBidSuccess),
      tap(() =>
        this.contactService.refreshPotentialBuyers().pipe(first()).subscribe()
      ),
      mergeMap(() => {
        return [this.fetchBidsRequest, this.fetchHighestBidRequest];
      })
    )
  );

  removeBidFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.removeBidFailure),
      map(({ payload }) => {
        if (
          payload.status === httpCodes.NOT_FOUND ||
          payload.status === httpCodes.BAD_REQUEST
        ) {
          return toastActions.danger({ message: "bid_removed_failure" });
        }
        if (payload.status === httpCodes.FORBIDDEN) {
          return toastActions.danger({
            message: "only_responsible_broker_can_remove_bid",
          });
        }
        return toastActions.generalError();
      })
    )
  );

  addBid$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.addBidRequest),
      switchMap(({ eaOid, contactId, body }) => {
        return this.api
          .post(
            `object/${eaOid}/potential-buyer/${contactId}/bids`,
            body,
            Categories.Integrations
          )
          .pipe(
            map(() => biddingActions.addBidSuccess()),
            catchError((res) =>
              of(biddingActions.addBidFailure({ payload: res }))
            )
          );
      })
    )
  );

  addBidSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.addBidSuccess),
      tap(() => this.pbService.refreshBids$.next()),
      tap(() =>
        this.contactService.refreshPotentialBuyers().pipe(first()).subscribe()
      ),
      mergeMap(() => {
        return [
          this.fetchHighestBidRequest,
          this.fetchHighestBidForContactRequest,
          toastActions.success({ message: "bid_added" }),
          biddingActions.hideAddBidModal(),
        ];
      })
    )
  );

  addBidFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingActions.addBidFailure),
      map(({ payload }) => {
        if (
          payload.status === httpCodes.NOT_FOUND ||
          payload.status === httpCodes.BAD_REQUEST
        ) {
          return toastActions.danger({ message: "bid_added_failure" });
        }
        if (payload.status === httpCodes.FORBIDDEN) {
          return toastActions.danger({
            message: "only_responsible_broker_can_add_bid",
          });
        }
        return toastActions.generalError();
      })
    )
  );

  showAddBidModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(biddingActions.showAddBidModal),
        tap(
          () =>
            (this.bsModalRef = this.modalService.show(AddBidComponent, {
              animated: false,
            }))
        )
      ),
    { dispatch: false }
  );

  hideAddBidModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(biddingActions.hideAddBidModal),
        tap(() => {
          if (this.bsModalRef) {
            this.bsModalRef.hide();
          }
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private modalService: BsModalService,
    private pbService: PotentialBuyerService,
    private contactService: ContactService
  ) {}
}

interface BidsResponse {
  externalObjectId: string;
  eaOid: string;
  bids: Bid[];
}

interface BidResponse {
  externalObjectId: string;
  eaOid: string;
  bid: Bid;
}
