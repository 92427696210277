<div
  [class.default-background]="
    branding.header_background_image === '' && branding.background_color === ''
  "
  [style.background-image]="
    branding.header_background_image
      ? 'url(' + branding.header_background_image + ')'
      : ''
  "
  [style.background-color]="branding.background_color"
  class="login container-fluid flex-center"
>
  <div
    [style.background-color]="branding.header_background_color"
    class="login__box-container"
  >
    <div class="clearfix login__form">
      <compatibility-check (onError)="onBrowserNotSupported($event)">
      </compatibility-check>

      <div [hidden]="!(isBrowserSupported$ | async)">
        <div class="login__form__customer-logo-wrapper">
          <img
            [style.width.px]="branding.logo_width"
            [style.height.px]="branding.logo_height"
            [attr.src]="branding.logo_url"
            class="login__form__customer-logo"
            alt="logo"
          />
        </div>
        <router-outlet></router-outlet>
      </div>
      <div [hidden]="isBrowserSupported$ | async">
        {{ label$ | async }}
      </div>
    </div>
    <div class="login__form__logo">
      <a [routerLink]="['/admin/auth']">
        <img
          [src]="
            '/assets/img/realforce-r-logo' +
            (isChristmasTime ? '-xmass' : '') +
            '.svg'
          "
          alt="logo"
          height="24px"
        />
      </a>
      <a
        class="phone-number"
        *ngIf="displayPhoneNumberOnLogin$ | async"
        href="tel:+{{ supportTel }}"
      >
        {{ "+" + supportTel | phoneFormat : supportTelCountry }}
      </a>
    </div>
  </div>
</div>
