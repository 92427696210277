import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { EIKA_SEND_TIPS } from "@app/shared/utils/tab-types";
import * as eikaActions from "@app/sidebar/eika/ngrx/eika.actions";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-eika-create-contact",
  template: `
    <div>
      <div class="header-wrapper">
        <sidebar-header
          [type]="tabType"
          [label]="'eika_send_tip' | translate"
        ></sidebar-header>
      </div>
      <div>
        <create-contact-simple
          [type]="tabType"
          (onSubmit)="onSubmit($event)"
          (onQuedroContactSelected)="onQuedroContactSelected($event)"
        ></create-contact-simple>
      </div>
    </div>
  `,
  styleUrls: ["../../../sidebar.component.common.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EikaCreateContactComponent {
  tabType = EIKA_SEND_TIPS;

  constructor(private store: Store<AppState>) {}

  onSubmit({ params }): void {
    this.store.dispatch(eikaActions.createContactRequest({ params }));
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: ["root", "eika", contact.contactId] } },
        ],
      })
    );
  }
}
