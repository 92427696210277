import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { EIKA_SEND_TIPS } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-select-contact",
  template: `
    <div class="wrapper">
      <sidebar-header
        [type]="tabType"
        [label]="'eika_send_tip' | translate"
      ></sidebar-header>
      <section>
        <h4 class="name">{{ "search_contact" | translate }}</h4>
        <search-contact
          #search
          [filter]="'freetext'"
          [icon]="'icon icon-magnifier'"
          [placeholder]="'search_contact_free_text_placeholder' | translate"
          (quedroClicked)="onClick($event)"
          [focus]="true"
        >
          <a
            class="text-link"
            [routerLink]="['/crm', { outlets: { sidebar: createEikaContact } }]"
            [queryParams]="{ number: search.searchInput.nativeElement.value }"
            empty
          >
            - {{ "create_new_contact" | translate }}</a
          >
        </search-contact>
        <div class="create-contact flex-center">
          <a
            [routerLink]="['/crm', { outlets: { sidebar: createEikaContact } }]"
            class="text-link"
            >{{ "create_new_contact" | translate }}</a
          >
        </div>
      </section>
    </div>
  `,
  styles: [".create-contact { padding: 0.5rem; }"],
  styleUrls: ["../../../sidebar.component.common.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectContactComponent {
  createEikaContact = ["root", "eika", "new"];

  tabType = EIKA_SEND_TIPS;

  constructor(private store: Store<AppState>) {}

  onClick(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          { outlets: { sidebar: ["root", "eika", contact.contactId] } },
        ],
      })
    );
  }
}
