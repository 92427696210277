import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { UrlService } from "@app/core/services/url/url.service";
import { addBackLink } from "@app/contacts/contact.actions";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getObjectStatusColor } from "@app/shared/utils/object-utils";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { Project, ProjectObject } from "@app/showings/models/project";
import { select, Store } from "@ngrx/store";
import { filter, map, Observable } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-project-object-list-widget",
  templateUrl: "./project-object-list-widget.component.html",
  styleUrls: [
    "./project-object-list-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ProjectObjectListWidgetComponent implements OnInit, OnChanges {
  @Input() eaOid: string;

  project$: Observable<Project>;
  loading$: Observable<boolean>;
  currency$: Observable<string>;

  getObjectStatusColor = getObjectStatusColor;

  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
    private router: Router,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    // Todo: Find a way to only trigger fetchProjects once per widget page(project-overview-widget currently triggers it)
  }

  ngOnChanges(changes) {
    if (changes.eaOid && this.eaOid) {
      this.project$ = this.objectService.entityMap$.pipe(
        map((entities) => entities[this.eaOid]),
        filter((entity) => !!entity)
      );
    }
  }

  private mapStateToProps() {
    this.currency$ = this.store.pipe(select(fromConfig.getCurrency));
  }

  openInNewTab(object: ProjectObject) {
    const commands = ["/crm", "showings", object.eaOid];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  objectClick(eaOid: any, event) {
    if (!event.target.attributes["disablenavigation"]) {
      this.store.dispatch(addBackLink({ backLink: this.router.url }));
      this.store.dispatch(
        go({
          path: ["/crm", "showings", eaOid, { outlets: { sidebar: null } }],
        })
      );
    }
  }
}
