<h3>
  <app-icon size="large" name="envelope"></app-icon>&nbsp;<span>{{
    "outgoing_mails" | translate
  }}</span>
</h3>
<div
  class="widget-container"
  [class.loading-pulse]="outgoingMailLoading$ | async"
>
  <div>
    {{ "mailing" | translate }}
    <slide-toggle
      (onToggle)="toggleShowAll()"
      *ngIf="showToggleSlide$ | async"
      [activeLabel]="'all' | translate"
      [checked]="showAllToggle$ | async"
      [inactiveLabel]="'mine_plural' | translate"
    ></slide-toggle>
  </div>
  <div class="outgoing-mails-container">
    <dashboard-outgoing-mails-list-item
      *ngFor="let mail of outgoingMailList$ | async"
      [mail]="mail"
      [icon]="resolveIcon(mail.period)"
      [color]="resolveColor()"
      (onPreviewClicked)="onPreviewClick(mail)"
      (onBatchClicked)="onClick(mail)"
    >
    </dashboard-outgoing-mails-list-item>

    <list-item-empty *ngIf="(outgoingMailList$ | async)?.length === 0">
      <span *ngIf="!(outgoingMailLoading$ | async); else spinner">{{
        "no_outgoing_mails" | translate
      }}</span>
      <ng-template #spinner>
        <spinner [size]="'1.5rem'"></spinner>
      </ng-template>
    </list-item-empty>
  </div>
  <ng-template #noData>
    <span translate>no_data_found</span>
  </ng-template>
</div>
