import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import * as integrationActions from "@app/integrations/ngrx/integrations.actions";
import {
  getObjectCopyOptions,
  getStoredCopyObjects,
  hasIntegration,
} from "@app/integrations/ngrx/integrations.reducer";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  first,
  forkJoin,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CopyObjectService {
  private _completedRequests = [];
  private _hasIntegration$: Observable<boolean>;
  hasIntegration$: Observable<boolean>;
  isCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private store: Store<AppState>, private apiService: ApiService) {
    this.mapStateToProps();
  }

  resetCopyObject() {
    this.isCompleted$.next(false);
    return this.store.dispatch(integrationActions.resetCopyObject());
  }

  getCopyObjectOptions(eaOid: string) {
    this.resetCopyObject();
    this.fetchCopyObjectOptions(eaOid);
    return this.store.pipe(select(getObjectCopyOptions()));
  }

  getStoredCopyObject() {
    return this.store.pipe(select(getStoredCopyObjects()));
  }

  fetchCopyObjectOptions(eaOid: string): void {
    this.store.dispatch(
      integrationActions.fetchIntegrationCopyObjectOptionsRequest({ eaOid })
    );
  }

  storeCopyObject(params: {
    eaOid: string;
    params: { options: { [key: string]: boolean }; copies: number };
  }): void {
    this.store.dispatch(
      integrationActions.storeIntegrationCopyObjectRequest({
        parameters: { ...params },
      })
    );
  }

  getCopiedObjectEaOid() {
    return this._completedRequests;
  }

  checkCopyObjectProgress() {
    this.isCompleted$.next(false);
    this.getStoredCopyObject()
      .pipe(
        filter((copyObjects) => !!copyObjects),
        first(),
        map((copyObjects) => {
          const externalIds = copyObjects.copies.map((item) => item.externalId);
          return externalIds;
        })
      )
      .subscribe((externalIds) => {
        this._completedRequests = [];
        this.runTimer(externalIds);
      });
  }

  getByExternalId = (
    externalId: string,
    externalIdProvider: string = "push"
  ) => {
    if (!!externalId) {
      return this.apiService
        .get(
          `external-id/object/provider/${externalIdProvider}/${externalId}`,
          {}
        )
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(() => {
            return of(null);
          })
        );
    } else {
      return of(null);
    }
  };

  private mapStateToProps() {
    this._hasIntegration$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.ObjectCopy))
    );

    this.hasIntegration$ = this._hasIntegration$.pipe(
      switchMap((enabled) => (!!enabled ? this._hasIntegration$ : of(false))),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  private runTimer(externalIds: string[]) {
    let currentForkIsCompleted = true;

    let interval = setInterval(() => {
      if (!currentForkIsCompleted) {
        return;
      }

      currentForkIsCompleted = false;
      const requests = externalIds
        .filter((externalId) => !this._completedRequests.includes(externalId))
        .map((externalId) => this.getByExternalId(externalId));

      forkJoin(requests)
        .pipe(
          map((responses) => {
            return responses
              .filter((resp) => !!resp)
              .filter((resp) => resp["entities"].length > 0)
              .map((resp) => resp["entities"][0].quedroId);
          })
        )
        .subscribe({
          next: (results) => {
            results.forEach((result) => {
              this._completedRequests.push(result);
            });
          },
          error: () => {},
          complete: () => {
            currentForkIsCompleted = true;
            if (this._completedRequests.length === externalIds.length) {
              this.isCompleted$.next(true);
              clearInterval(interval);
              interval = null;
            }
          },
        });
    }, 5000);
  }
}
