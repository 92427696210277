import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import {
  SIDEBAR_SALES_MEETING_CONTACTS_CREATE_URL,
  SIDEBAR_SALES_MEETING_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import * as smActions from "../ngrx/sales-meeting.actions";

@Component({
  selector: "app-sales-meeting-search-contact",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./sales-meeting-search-contact.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="wrapper">
      <sidebar-header
        [type]="tabType"
        [label]="tabType | translate"
      ></sidebar-header>
      <section>
        <h4 class="search-label">{{ "search_a_contact" | translate }}</h4>
        <search-contact
          #search
          [filter]="'freetext'"
          [icon]="'icon icon-magnifier icon-lg'"
          [placeholder]="'search_contact_free_text_placeholder' | translate"
          (quedroClicked)="onClick($event)"
          [focus]="true"
        >
          <a
            class="text-link"
            [routerLink]="['/crm', { outlets: { sidebar: createContactUrl } }]"
            [queryParams]="{ number: search.searchInput.nativeElement.value }"
            empty
          >
            - {{ "create_new_contact" | translate }}</a
          >
        </search-contact>
        <div class="flex-center">
          <a
            [routerLink]="['/crm', { outlets: { sidebar: createContactUrl } }]"
          >
            {{ "create_new_contact" | translate }}
          </a>
        </div>
      </section>
    </div>
  `,
})
export class SalesMeetingSearchContactComponent {
  tabType = SALES_MEETING;
  createContactUrl = SIDEBAR_SALES_MEETING_CONTACTS_CREATE_URL;

  constructor(private store: Store<AppState>) {}

  onClick(contact: Contact) {
    const { contactId } = contact;
    this.store.dispatch(smActions.getContactSuccess({ contact }));

    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_SALES_MEETING_CREATE_URL(contactId),
              primary: ["contacts", contactId, "sales-meetings"],
            },
          },
        ],
      })
    );
  }
}
