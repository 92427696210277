import { createAction, props } from "@ngrx/store";

import {
  StatisticsEmployeesPerformanceData,
  StatisticsEmployeesPerformanceParams,
} from "@app/widgets/widgets/statistics-employees-performance-widget/models/statistics-employees-performance";

export const fetchEmployeesPerformanceStatisticsRequest = createAction(
  "[Statistics] FETCH_EMPLOYEES_PERFORMANCE_STATISTICS_REQUEST",
  props<{ params: StatisticsEmployeesPerformanceParams }>()
);

export const fetchEmployeesPerformanceStatisticsSuccess = createAction(
  "[Statistics] FETCH_EMPLOYEES_PERFORMANCE_STATISTICS_SUCCESS",
  props<{ data: StatisticsEmployeesPerformanceData }>()
);

export const fetchEmployeesPerformanceStatisticsFail = createAction(
  "[Statistics] FETCH_EMPLOYEES_PERFORMANCE_STATISTICS_FAIL"
);
