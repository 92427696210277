import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { Action, createAction, props } from "@ngrx/store";

export const fetchContactedStatus = (params: {
  contactId: string;
  eaOid: string;
  codes?: string;
  visibility?: string;
  sortBy?: string;
  sortOrder?: string;
  limit?: number;
}) => {
  const defaultParams = {
    codes: ContactActionCodes.CONSUMER_CALL_REGISTERED.toString(),
    visibility: "employee",
    sortBy: "timestamp",
    sortOrder: "desc",
    limit: 1,
  };

  return fetchContactedStatusRequest({ ...defaultParams, ...params });
};

export const fetchContactedStatusRequest = createAction(
  "[PotentialBuyer] FETCH_CONTACTED_STATUS_REQUEST",
  props<{
    contactId: string;
    eaOid: string;
    codes?: string;
    visibility?: string;
    sortBy?: string;
    sortOrder?: string;
    limit?: number;
  }>()
);

export const fetchContactedStatusSuccess = createAction(
  "[PotentialBuyer] FETCH_CONTACTED_STATUS_SUCCESS",
  props<{ payload: boolean | null }>()
);

export const fetchContactedStatusFailure = createAction(
  "[PotentialBuyer] FETCH_CONTACTED_STATUS_FAILURE"
);

export const createContactedStatus = (
  contactId: string,
  body: {
    contacted: boolean;
    eaOid: string;
    code?: string | number;
    visibility?: string;
    param2: string;
  },
  undoAction: Action
) => {
  const defaultParams = {
    code: ContactActionCodes.CONSUMER_CALL_REGISTERED,
    visibility: "employee",
  };

  const params = { ...defaultParams, ...body };

  return createContactedStatusRequest({ contactId, body: params, undoAction });
};

export const createContactedStatusRequest = createAction(
  "[PotentialBuyer] CREATE_CONTACTED_STATUS_REQUEST",
  props<{
    contactId: string;
    body: {
      contacted: boolean;
      eaOid: string;
      code?: string | number;
      visibility?: string;
      param2: string;
    };
    undoAction: Action;
  }>()
);

export const createContactedStatusSuccess = createAction(
  "[PotentialBuyer] CREATE_CONTACTED_STATUS_SUCCESS"
);

export const createContactedStatusFailure = createAction(
  "[PotentialBuyer] CREATE_CONTACTED_STATUS_FAILURE"
);

export const createContactedStatusOptimistic = createAction(
  "[PotentialBuyer] CREATE_CONTACTED_STATUS_OPTIMISTIC",
  props<{ payload: boolean }>()
);
