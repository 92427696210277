import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { NpsEntity } from "@app/models/nps";
import * as widgetActions from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";

@Injectable()
export class StatisticsNpsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(widgetActions.fetchStatisticsRequest),
      switchMap(({ params }) =>
        this.apiService.get("nps-values/statistics/total", params).pipe(
          map((nps: NpsEntity) =>
            widgetActions.fetchStatisticsSuccess({ nps })
          ),
          catchError(() => of(widgetActions.fetchStatisticsFail()))
        )
      )
    )
  );
}
