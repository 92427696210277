import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { ContactListItemComponent } from "./contact-list-item/contact-list-item.component";
import { ListHeaderComponent } from "./list-header/list-header.component";
import { ListItemEmptyComponent } from "./list-item-empty/list-item-empty.component";
import { ListComponent } from "./list/list.component";
import { TaskListItemComponent } from "./task-list-item/task-list-item.component";

const components = [
  ListHeaderComponent,
  ListItemEmptyComponent,
  ListComponent,
  TaskListItemComponent,
  ContactListItemComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    MomentModule,
    TooltipModule,
    UiComponentsModule,
    ContactComponentsModule,
  ],
  declarations: components,
  exports: components,
})
export class ListComponentsModule {}
