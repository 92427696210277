import { Directive, ElementRef, Input } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[addAllToPaginator]",
})
export class AddAllToPaginatorDirective {
  @Input("addAllToPaginator") enable: boolean = true;
  @Input() allValue: number = 2000;
  @Input() allValueLabel: string = "all";

  public constructor(
    private readonly host: MatPaginator,
    private readonly elRef: ElementRef,
    private translate: TranslateService
  ) {
    if (!this.enable) {
      return;
    }

    const allLabel = this.translate.instant(this.allValueLabel);
    const proxiedUpdateDisplayedPageSizeOptions =
      // @ts-ignore
      host._updateDisplayedPageSizeOptions.bind(host);

    // @ts-ignore
    host._updateDisplayedPageSizeOptions = () => {
      proxiedUpdateDisplayedPageSizeOptions();
      const displayedPageSizeOptions = host._displayedPageSizeOptions;

      if (!displayedPageSizeOptions) {
        return;
      }

      const newDisplayedPageSizeOptions = [
        ...displayedPageSizeOptions.filter((x) => x !== this.allValue),
        allLabel,
      ];

      this.host._displayedPageSizeOptions = newDisplayedPageSizeOptions;
      // @ts-ignore
      host._changeDetectorRef.markForCheck();
    };

    const proxiedChangePageSize = host._changePageSize.bind(host);
    host._changePageSize = (v) => {
      if (v == allLabel) {
        v = this.allValue;
      }
      proxiedChangePageSize(v);

      this.elRef.nativeElement.querySelector(".mat-select-value").innerText =
        v === this.allValue ? allLabel : v;
    };
  }
}
