import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import * as objectTypes from "@app/contacts/utils/object-types";
import { QObject } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as residencesActions from "./residences.actions";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";

export interface ContactResidencesState {
  loaded: boolean;
  loading: boolean;
  objectListLoading: boolean;
  list: QObject[];
  objectConnectionList: QObject[];
  selected: QObject;
  processing: boolean;
  externalProviderLinks: ExternalPresentableLink[];
  externalProviderLinksLoading: boolean;
}

export const initialState: ContactResidencesState = {
  loaded: false,
  loading: false,
  objectListLoading: false,
  list: [],
  objectConnectionList: [],
  selected: null,
  processing: false,
  externalProviderLinks: [],
  externalProviderLinksLoading: false,
};

export function contactResidencesReducer(
  state: ContactResidencesState,
  action: Action
): ContactResidencesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(residencesActions.getContactResidenceListRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(residencesActions.getContactResidenceListSuccess, (state, { list }) => ({
    ...state,
    loading: false,
    loaded: true,
    list,
  })),
  on(residencesActions.getContactResidenceListFailed, (state) => ({
    ...state,
  })),
  on(residencesActions.getContactObjectConnectionListRequest, (state) => ({
    ...state,
    objectListLoading: true,
  })),
  on(
    residencesActions.getContactObjectConnectionListSuccess,
    (state, { objectConnectionList }) => ({
      ...state,
      objectListLoading: false,
      loaded: true,
      objectConnectionList,
    })
  ),
  on(residencesActions.getContactObjectConnectionListFailed, (state) => ({
    ...state,
  })),
  on(residencesActions.setResidence, (state, { residence }) => ({
    ...state,
    selected: residence,
  })),
  on(residencesActions.deleteContactResidenceRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(residencesActions.deleteContactResidenceSuccess, (state, { eaOid }) => {
    const list = state.list.filter((r) => r.eaOid !== eaOid);
    return { ...state, processing: false, list, selected: list[0] };
  }),
  on(residencesActions.deleteContactResidenceFailed, (state) => ({
    ...state,
    processing: false,
  })),
  on(residencesActions.getExternalProviderLinksRequest, (state) => ({
    ...state,
    externalProviderLinksLoading: true,
  })),
  on(
    residencesActions.getExternalProviderLinksSuccess,
    (state, { externalProviderLinks }) => ({
      ...state,
      externalProviderLinksLoading: false,
      externalProviderLinks,
    })
  ),
  on(residencesActions.getExternalProviderLinksFailed, (state) => ({
    ...state,
    externalProviderLinksLoading: false,
    externalProviderLinks: [],
  })),
  on(residencesActions.reset, () => ({
    ...initialState,
  }))
);

const selectFeature = createFeatureSelector<ContactModuleState>("contact");
const getState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.residences
);

export const getLoaded = createSelector(
  getState,
  (state: ContactResidencesState) => state.loaded
);

export const getLoading = createSelector(
  getState,
  (state: ContactResidencesState) => state.loading
);

export const getObjectListLoading = createSelector(
  getState,
  (state: ContactResidencesState) => state.objectListLoading
);

export const getResidenceList = createSelector(
  getState,
  (state: ContactResidencesState) => state.list
);

export const getObjectConnectionList = createSelector(
  getState,
  (state: ContactResidencesState) => state.objectConnectionList
);

export const getContactStatus = createSelector(
  getState,
  (state: ContactResidencesState) => {
    if (state.objectConnectionList.length > 0) {
      const object: QObject = state.objectConnectionList[0];
      return getObjectTypeTranslationKey(object.type, object.bidStatus);
    } else {
      return null;
    }
  }
);

export const getSelectedResidence = createSelector(
  getState,
  (state: ContactResidencesState) => state.selected
);

export const getProcessingState = createSelector(
  getState,
  (state: ContactResidencesState) => state.processing
);

export const getExternalProviderLinks = createSelector(
  getState,
  (state: ContactResidencesState) => state.externalProviderLinks
);

export const getExternalProviderLinksLoading = createSelector(
  getState,
  (state: ContactResidencesState) => state.externalProviderLinksLoading
);

export const getObjectTypeTranslationKey = (
  type: string,
  bidStatus = ""
): string => {
  if (type === objectTypes.POTENTIAL_BUYER && bidStatus === "participating") {
    return "bidder";
  } else {
    switch (type) {
      case objectTypes.UNKNOWN:
        return "unknown";
      case objectTypes.POTENTIAL_BUYER:
        return "potential_buyer";
      case objectTypes.OWNER:
        return "owner";
      case objectTypes.BUYER:
        return "buyer";
      case objectTypes.SELLER:
        return "seller";
      case objectTypes.TENANT:
        return "tenant";
      case objectTypes.FOLLOWER:
        return "follower";
      default:
        return "unknown";
    }
  }
};
