import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { SendPotentialBuyersService } from "@app/integrations/bidding/services/send-potential-buyers.service";
import { Contact } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as fromProgress from "@app/shared/modules/progress-widget/ngrx/progress-widget.reducer";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { PotentialBuyer } from "@app/showings/models";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  filter,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
} from "rxjs";

@Component({
  selector: "app-add-contact-as-potentialbuyer-modal",
  templateUrl: "./add-contact-as-potentialbuyer-modal.component.html",
  styleUrls: ["./add-contact-as-potentialbuyer-modal.component.scss"],
})
export class AddContactAsPotentialbuyerModalComponent
  implements OnInit, OnDestroy
{
  form: FormGroup;
  externalProviderName$: Observable<string>;
  unsubscribe$ = new Subject<void>();
  selectedContacts$: BehaviorSubject<Contact[]> = new BehaviorSubject([]);
  isWorking$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  canSelectMultiple: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddContactAsPotentialbuyerModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private sendPotentialBuyerService: SendPotentialBuyersService,
    private contactService: ContactService,
    public phoneNumberService: PhoneNumberService
  ) {
    this.form = this.fb.group({
      eaOid: [null, Validators.required],
      contactId: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  submit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    this.isWorking$.next(true);
    const eaOid = this.form.get("eaOid").value;

    this.selectedContacts$
      .pipe(
        first(),
        tap((selectedContacts: Contact[]) => {
          if (selectedContacts.length === 1) {
            this.saveOneContactAsPotentialBuyer(eaOid, selectedContacts[0]);
          } else {
            this.saveMultipleContactsAsPotentialBuyers(eaOid, selectedContacts);
          }
        }),
        switchMap((selectedContacts) => {
          if (selectedContacts.length === 1) {
            return of(true);
          } else {
            return this.checkProgressIsDone$();
          }
        })
      )
      .subscribe(() => {
        this.sendToERP(eaOid);
        this.isWorking$.next(false);
        this.closeModal();
      });
  }

  handleSelectedContact(contact: Contact) {
    const newSelections = [...this.selectedContacts$.value, contact];
    this.selectedContacts$.next(newSelections);
  }

  removeSelectedContact(contactId: string) {
    const updatedSelectedContactList = this.selectedContacts$.value.filter(
      (contact) => contact.contactId !== contactId
    );
    this.selectedContacts$.next(updatedSelectedContactList);

    if (updatedSelectedContactList?.length === 0) {
      this.form.get("contactId").setValue(null);
    }
  }

  private mapStateToProps() {
    this.externalProviderName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
  }

  private fillForm() {
    this.form.get("contactId").setValue(this.data?.contact?.contactId);
    this.form.get("eaOid").setValue(this.data?.eaOid);

    this.canSelectMultiple = !this.data?.contact;
    if (!!this.data?.contact) {
      this.selectedContacts$.next([this.data.contact]);
    }
  }

  private saveMultipleContactsAsPotentialBuyers(
    eaOid: string,
    selectedContacts: Contact[]
  ) {
    const requests = [];
    selectedContacts.forEach((contact) => {
      requests.push(
        this.contactService.postContactObjectConnection(contact.contactId, {
          eaOid,
          type: "potentialbuyer",
          bidStatus: "unknown",
        })
      );
    });

    const taskSet: TaskSet = {
      label: "save_potential_buyers",
      tasks: requests,
    };
    this.store.dispatch(addTaskSetToQueue(taskSet));
  }

  private saveOneContactAsPotentialBuyer(
    eaOid: string,
    selectedContact: Contact
  ) {
    this.contactService
      .postContactObjectConnection(selectedContact.contactId, {
        eaOid,
        type: "potentialbuyer",
        bidStatus: "unknown",
      })
      .subscribe(() =>
        this.contactService.fetchContactObjectConnection(
          selectedContact.contactId,
          { eaOid }
        )
      );
  }

  private sendToERP(eaOid: string) {
    const potentialBuyers = this.selectedContacts$.value.map(
      (contact) =>
        new PotentialBuyer({
          ...contact,
          type: "potentialbuyer",
          bidStatus: "unknown",
        })
    );

    this.sendPotentialBuyerService.sendPotentialBuyersToERP({
      eaOid,
      potentialBuyers: potentialBuyers,
    });
  }

  private checkProgressIsDone$(): Observable<any> {
    return this.store.pipe(select(fromProgress.getProgressWidgetShow)).pipe(
      filter((isInProgress) => !isInProgress),
      first()
    );
  }
}
