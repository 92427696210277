import { SalesTargetsFeature } from "@app/shared/config/models/sales-targets-feature";
import {
  AppConfigState,
  ContactConfigState,
  KpiFeature,
  SidebarProcesses,
} from "@app/shared/config/models";
import { ErpFeature } from "@app/shared/config/models/erp-feature";
import { GrowthReportsFeature } from "@app/shared/config/models/growth-reports";
import { StatisticsOfficeFilterConfig } from "@app/shared/config/models/statistics-office-filter-config";
import { TopListsFeature } from "@app/shared/config/models/top-lists-feature";
import { ValueMonitorFeature } from "@app/shared/config/models/value-monitor-feature";
import { AdvancedObjectsListFeature } from "@app/shared/config/models/advanced-objects-list-feature";
import { SalesFocusAreaFeature } from "@app/shared/config/models/sales_focus_area_feature";

export const checkDuplicates = (app: AppConfigState) => {
  app.contact = checkContactBranch(app.contact);
  app.features.advanced_objects_list = checkAdvancedObjectListFeature(
    app.features.advanced_objects_list
  );
  app.features.erp = checkFeatureErp(app.features.erp);
  app.features.growth_reports = checkGrowthReport(app.features.growth_reports);
  app.features.kpi = checkKpis(app.features.kpi);
  app.features.sales_targets = checkSalesTargets(app.features.sales_targets);
  app.features.statistics_office_filter = checkStatisticsOfficeFilters(
    app.features.statistics_office_filter
  );
  app.features.top_lists = checkTopList(app.features.top_lists);
  app.features.value_monitor = checkValueMonitor(app.features.value_monitor);
  app.features.sales_focus_areas = checkSalesFocusAreas(
    app.features.sales_focus_areas
  );
  app.sidebar_processes = checkSidebarProcess(app.sidebar_processes);
  app.statistics = checkStatistics(app.statistics);

  return app;
};

export function checkFeatureErp(erp: ErpFeature) {
  if (erp.overview_widgets?.length > 0) {
    erp.overview_widgets = [...new Set(erp.overview_widgets)];
  }

  return erp;
}

export function checkGrowthReport(growth_reports: GrowthReportsFeature) {
  if (growth_reports.customColumnSorting?.length > 0) {
    growth_reports.customColumnSorting = [
      ...new Set(growth_reports.customColumnSorting),
    ];
  }

  return growth_reports;
}

export function checkStatisticsOfficeFilters(
  officeFilters: StatisticsOfficeFilterConfig
) {
  if (officeFilters.filterOptions?.length > 0) {
    officeFilters.filterOptions = clearDuplicateObjectsInArray(
      officeFilters.filterOptions,
      "display"
    );
  }

  return officeFilters;
}

export function checkTopList(top_lists: TopListsFeature) {
  if (top_lists.topListTypes?.length > 0) {
    top_lists.topListTypes = [...new Set(top_lists.topListTypes)];
  }

  return top_lists;
}

export function checkValueMonitor(value_monitor: ValueMonitorFeature) {
  if (value_monitor.contactMethods?.length > 0) {
    value_monitor.contactMethods = [...new Set(value_monitor.contactMethods)];
  }

  return value_monitor;
}

export function checkSidebarProcess(sidebar_processes: SidebarProcesses) {
  if (sidebar_processes.book_sales_meeting?.length > 0) {
    sidebar_processes.book_sales_meeting = [
      ...new Set(sidebar_processes.book_sales_meeting),
    ];
  }

  return sidebar_processes;
}

export function checkContactBranch(contact: ContactConfigState) {
  if (contact.contact_list_config.contact_types?.length > 0) {
    contact.contact_list_config.contact_types = [
      ...new Set(contact.contact_list_config.contact_types),
    ];
  }

  if (contact.contact_card_actions.buttons?.length > 0) {
    const actionButtons = contact.contact_card_actions.buttons;
    contact.contact_card_actions.buttons = clearDuplicateObjectsInArray(
      actionButtons,
      "label"
    );
  }

  return contact;
}

export function checkAdvancedObjectListFeature(
  advanced_objects_list: AdvancedObjectsListFeature
) {
  if (advanced_objects_list.filterOptions.contractTypes?.length > 0) {
    advanced_objects_list.filterOptions.contractTypes =
      clearDuplicateObjectsInArray(
        advanced_objects_list.filterOptions.contractTypes,
        "display"
      );
  }

  if (advanced_objects_list.filterOptions.dateTypeIds?.length > 0) {
    advanced_objects_list.filterOptions.dateTypeIds =
      clearDuplicateObjectsInArray(
        advanced_objects_list.filterOptions.dateTypeIds,
        "display"
      );
  }

  if (advanced_objects_list.filterOptions.objectStatuses?.length > 0) {
    advanced_objects_list.filterOptions.objectStatuses =
      clearDuplicateObjectsInArray(
        advanced_objects_list.filterOptions.objectStatuses,
        "display"
      );
  }

  if (advanced_objects_list.defaultFilterOptions.objectStatuses?.length > 0) {
    advanced_objects_list.defaultFilterOptions.objectStatuses =
      clearDuplicateObjectsInArray(
        advanced_objects_list.defaultFilterOptions.objectStatuses,
        "display"
      );
  }

  return advanced_objects_list;
}

export function checkSalesTargets(sales_targets: SalesTargetsFeature) {
  if (sales_targets.sales_targets?.length >= 0) {
    sales_targets.sales_targets = clearDuplicateObjectsInArray(
      sales_targets.sales_targets,
      "type"
    );
  }

  return sales_targets;
}

export function checkKpis(kpi: KpiFeature) {
  if (kpi.kpis?.length > 0) {
    kpi.kpis = clearDuplicateObjectsInArray(kpi.kpis, "type");
  }

  if (kpi.kpis_statistics?.length > 0) {
    kpi.kpis_statistics = clearDuplicateObjectsInArray(
      kpi.kpis_statistics,
      "type"
    );
  }

  return kpi;
}

export function checkStatistics(statistics) {
  if (statistics.favorites?.length > 0) {
    statistics.favorites = clearDuplicateObjectsInArray(
      statistics.favorites,
      "type"
    );
  }

  return statistics;
}

export function checkSalesFocusAreas(salesFocusAreas: SalesFocusAreaFeature) {
  if (salesFocusAreas?.extra_setting?.length > 0) {
    salesFocusAreas.extra_setting = clearDuplicateObjectsInArray(
      salesFocusAreas.extra_setting,
      "label"
    );
  }
  return salesFocusAreas;
}

export function clearDuplicateObjectsInArray<T extends object>(
  array: T[],
  itemKey: string
): T[] {
  if (!array || array.length === 0) {
    return array;
  }

  const uniqueArray = array.reduce((result, item) => {
    result[item[itemKey]] = { ...result[item[itemKey]], ...item };
    return result;
  }, {});

  return Object.values(uniqueArray);
}
