import { Component, Input, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import * as fromUser from "@app/shared/user/user.selectors";
import { Observable } from "rxjs";
import { ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { DeswinglisherModalComponent } from "@app/shared/modules/ui-components/translation-text/deswinglisher-modal/deswinglisher-modal.component";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";

@Component({
  selector: "app-translation-text",
  templateUrl: "./translation-text.component.html",
  styleUrls: ["./translation-text.component.scss"],
})
export class TranslationTextComponent implements OnInit {
  @Input() text: string = "";
  @Input() classes: string = "";

  isSuperAdmin$: Observable<boolean>;
  isEnabled: boolean;

  constructor(
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private modalService: QModalService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.isEnabled =
      this.localStorageService.fetchUserPreference(
        "show_untranslated_tooltips"
      ) === "true";
  }

  mapStateToProps(): void {
    this.isSuperAdmin$ = this.store.pipe(
      select(fromUser.hasRole(ROLE_SUPER_ADMIN))
    );
  }

  openEditDialog() {
    this.modalService.show(DeswinglisherModalComponent, {
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: false,
        translateKey: this.text,
      },
    });
  }
}
