import { Injectable } from "@angular/core";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { catchError, map, switchMap } from "rxjs";

import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import {
  GetValueMonitorParams,
  PaginationListDTO,
  PostValueMonitorBody,
  ValueMonitor,
} from "@app/models";

@Injectable({
  providedIn: "root",
})
export class ValueMonitorService extends CustomEntityCollectionServiceBase<ValueMonitor> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("ValueMonitor", serviceElementsFactory);
  }

  fetchWithQuery(params: GetValueMonitorParams) {
    this.setLoading(true);
    return this.apiService.get("price-update/order/search", params).pipe(
      switchMap((response: PaginationListDTO) => {
        const vms: ValueMonitor[] = response.rows;
        this.upsertManyInCache(vms);
        this.setLoading(false);
        this.setListDefaults(vms, response);
        return this.getListFromEntities(vms);
      }),
      catchError((err) => {
        return err;
      })
    );
  }

  fetchById(id) {
    return this.apiService.get(`price-update/order/${id}`, {}, "api").pipe(
      map((response: any) => {
        this.upsertOneInCache(response);
        return response;
      }),
      catchError((err) => {
        return err;
      })
    );
  }

  post(body: PostValueMonitorBody) {
    return this.apiService.post("price-update/order", body).pipe(
      map((response: ValueMonitor) => {
        this.addOneToCache(response);

        this.appStore.dispatch(
          toastActions.success({ message: "value_monitor_created_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "value_monitor_created_error" })
        );
        return err;
      })
    );
  }

  patch(id, body: PostValueMonitorBody) {
    return this.apiService.patch(`price-update/order/${id}`, body).pipe(
      map((response: ValueMonitor) => {
        this.updateOneInCache(response);
        this.appStore.dispatch(
          toastActions.success({ message: "value_monitor_updated_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "value_monitor_updated_error" })
        );
        return err;
      })
    );
  }

  remove(id) {
    return this.apiService.delete(`price-update/order/${id}`).pipe(
      map((response: ValueMonitor) => {
        this.removeOneFromCache(id);
        this.handleDelete(id);

        this.appStore.dispatch(
          toastActions.success({ message: "value_monitor_removed_success" })
        );
        return response;
      }),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({ message: "value_monitor_removed_error" })
        );
        return err;
      })
    );
  }
}
