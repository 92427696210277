import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { SKELETON_SCREEN_COLOR } from "../../../utils/colors";

@Component({
  selector: "skeleton-rectangle",
  styleUrls: ["./skeleton-rectangle.component.scss"],
  template: `
    <div
      [style.background-color]="color"
      [style.width]="width + widthUnit"
      [style.height]="height + heightUnit"
    ></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonRectangleComponent {
  @Input() width: number;
  @Input() widthUnit = "rem";
  @Input() height: number;
  @Input() heightUnit = "rem";
  @Input() color = SKELETON_SCREEN_COLOR;
}
