import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { SHOWINGS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { Store } from "@ngrx/store";
import { map, of, switchMap, tap } from "rxjs";
import { SidebarService } from "../sidebar.service";

@Injectable()
export class PotentialBuyerGuard implements CanActivate {
  canActivateInModules = [SHOWINGS_CRM_MODULE];

  constructor(
    private sidebarService: SidebarService,
    private pbService: PotentialBuyerService,
    private store: Store<AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const { tabType } = route.data;
    const canActivate = this.canActivateInModules.some((module) =>
      SidebarService.isNextRouteAnActivatableRoute(module, state.url)
    );

    const handleTabNavigation$ = this.sidebarService.handleTabNavigation(
      tabType,
      canActivate,
      route
    );
    const redirect$ = of(false).pipe(
      tap(() => {
        // TODO: need to find a better solution for redirect rather than parsing the state url
        const matchParens = /[\(\)]/g;
        let url = state.url.replace(matchParens, "");
        url = url.split("//sidebar")[0];
        this.store.dispatch(RouterActions.go({ path: url }));
      })
    );
    return this.pbService.selectedPotentialBuyers$.pipe(
      map((buyers) => buyers.length > 0),
      switchMap((hasPotentialBuyers) =>
        hasPotentialBuyers ? handleTabNavigation$ : redirect$
      )
    );
  }
}
