<div class="quick-actions">
  <ng-container *ngIf="isMobile$ | async; then mobileView; else desktopView">
  </ng-container>
</div>

<ng-template #mobileView>
  <div class="action-item">
    <app-actions-menu
      [highlight]="true"
      size="x-small"
      dropdownAlignment="RIGHT"
    >
      <ng-container buttons>
        <li *ngIf="contact.msisdn" class="action-item">
          <a
            href="tel:+{{ contact.msisdn }}"
            class="q-btn q-btn--save full-width"
          >
            <app-icon size="x-small" name="phone"></app-icon>
            {{ "call_contact" | translate }}
          </a>
        </li>
        <li *ngFor="let action of buttons$ | async" class="action-item">
          <app-q-button
            (click)="navigate(action.type)"
            [label]="getAction(action.type).label"
            [icon]="getAction(action.type).icon"
            [fullWidth]="true"
          >
          </app-q-button>
        </li>
        <li>
          <app-q-button
            [icon]="'phone-plus'"
            label="sales_call"
            [fullWidth]="true"
            (click)="dialogRef?.close(); registerSalesCall(); openNoteModal()"
          ></app-q-button>
        </li>
        <li *ngIf="showCreateShowing$ | async">
          <app-q-button
            [label]="'create_showing'"
            [icon]="'users'"
            [fullWidth]="true"
            (click)="createShowing($event, contact.contactId)"
          ></app-q-button>
        </li>
        <li>
          <add-contact-to-calling-list
            [contactId]="contact.contactId"
            [contactName]="contact.firstName"
          >
          </add-contact-to-calling-list>
        </li>
        <ng-container *ngIf="sendPotentialBuyerService.hasIntegration$ | async">
          <li>
            <app-q-button
              icon="paper-plane"
              [label]="
                'send_as_potential_buyer_to_erp'
                  | translate
                    : { erp: externalProviderName$ | async | titlecase }
              "
              [fullWidth]="true"
              (click)="sendToErpAsPotentialBuyer($event, contact)"
            ></app-q-button>
          </li>
        </ng-container>
      </ng-container>
    </app-actions-menu>
  </div>
</ng-template>

<ng-template #desktopView>
  <ng-container *ngIf="buttons$ | async as buttons">
    <div *ngIf="contact.msisdn" class="action-item">
      <a
        href="tel:+{{ contact.msisdn }}"
        [tooltip]="'call_contact' | translate"
        class="btn btn-secondary btn-secondary--ghost btn-circle contact-button"
      >
        <app-icon size="x-small" name="phone"></app-icon>
      </a>
    </div>
    <ng-container *ngFor="let action of buttons; index as indx">
      <div *ngIf="indx < 4" class="action-item">
        <a
          [tooltip]="getAction(action.type).label | translate"
          (click)="navigate(action.type)"
          class="btn btn-secondary btn-secondary--ghost btn-circle contact-button"
        >
          <app-icon
            size="x-small"
            [name]="getAction(action.type).icon"
          ></app-icon>
        </a>
      </div>
    </ng-container>
    <div class="action-item">
      <app-actions-menu
        [highlight]="true"
        size="x-small"
        dropdownAlignment="RIGHT"
      >
        <ng-container buttons>
          <li>
            <app-q-button
              [icon]="'phone-plus'"
              label="sales_call"
              [fullWidth]="true"
              (click)="dialogRef?.close(); registerSalesCall(); openNoteModal()"
            ></app-q-button>
          </li>
          <ng-container *ngFor="let action of buttons; index as indx">
            <li *ngIf="indx >= 4" class="action-item">
              <app-q-button
                (click)="navigate(action.type)"
                [label]="getAction(action.type).label"
                [icon]="getAction(action.type).icon"
                [fullWidth]="true"
              >
              </app-q-button>
            </li>
          </ng-container>
          <li *ngIf="showCreateShowing$ | async">
            <app-q-button
              [label]="'create_showing'"
              [icon]="'users'"
              [fullWidth]="true"
              (click)="createShowing($event, contact.contactId)"
            ></app-q-button>
          </li>
          <li>
            <add-contact-to-calling-list
              [contactId]="contact.contactId"
              [contactName]="contact.firstName"
            >
            </add-contact-to-calling-list>
          </li>
          <ng-container
            *ngIf="sendPotentialBuyerService.hasIntegration$ | async"
          >
            <li>
              <app-q-button
                icon="paper-plane"
                [label]="
                  'send_as_potential_buyer_to_erp'
                    | translate
                      : { erp: externalProviderName$ | async | titlecase }
                "
                [fullWidth]="true"
                (click)="sendToErpAsPotentialBuyer($event, contact)"
              ></app-q-button>
            </li>
          </ng-container>
        </ng-container>
      </app-actions-menu>
    </div>
  </ng-container>
</ng-template>
