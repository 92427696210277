import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { ALL_CRM_MODULES } from "@app/shared/utils/crm-modules";
import { RESIDENCE } from "@app/shared/utils/tab-types";
import { CrmModule } from "../models/crm-module";
import { SidebarService } from "../sidebar.service";

@Injectable()
export class ExistingHomeGuard implements CanActivate {
  crmModules = ALL_CRM_MODULES;

  constructor(private sidebarService: SidebarService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const crmModule = this.crmModules
      .map((module) => new CrmModule(module))
      .find((module) => module.isModuleActive(state.url));

    return this.sidebarService.canActivateTab(
      route,
      state.url,
      crmModule,
      RESIDENCE
    );
  }
}
