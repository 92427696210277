import { CommonModule, registerLocaleData } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { AppConfigService } from "@app/app-config.service";
import { CallingListsModule } from "@app/calling-lists/calling-lists.module";
import { SearchEffects } from "@app/core/components/search/ngrx/search.effects";
import { ConfigService } from "@app/core/services/config/config.service";
import { LocaleService } from "@app/core/services/locale/locale.service";
import { PreviousRouteService } from "@app/core/services/routes/previous-route.service";
import { IntegrationEffects } from "@app/integrations/ngrx/integrations.effects";
import { SettingsAccountExchangeEffects } from "@app/settings/account/exchange/ngrx/settings-account-exchange.effects";
import { CompetitionSidebarEffects } from "@app/sidebar/competitions/ngrx/competitions.effects";
import { CreateLeadEffects } from "@app/sidebar/create-lead/ngrx/create-lead.effects";
import { EikaEffects } from "@app/sidebar/eika/ngrx/eika.effects";
import { ExternalProviderEffects } from "@app/sidebar/external-provider/ngrx/external-provider.effects";
import { SearchProfileSidebarEffects } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.effects";
import { SupportEffects } from "@app/sidebar/support/ngrx/support.effects";
import { EffectsModule } from "@ngrx/effects";
import {
  RouterStateSerializer,
  StoreRouterConnectingModule,
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { NguiMapModule } from "@ngui/map";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { ChartModule } from "angular2-highcharts";
import { HotkeyModule } from "angular2-hotkeys";
import { MomentModule } from "angular2-moment";
import { Angulartics2Module } from "angulartics2";
import { HighchartsChartModule } from "highcharts-angular";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { AlertModule } from "ngx-bootstrap/alert";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { PopoverModule } from "ngx-bootstrap/popover";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { CustomReuseStrategy } from "./app-route-reuse-strategy";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { metaReducers, reducers } from "./app.reducer";
import { CompetitionsEffects } from "./competitions/ngrx/competitions.effects";
import { ContactResidencesEffects } from "./contacts/contact-residences/residences.effects";
import { PreviewEffects } from "./core/components/preview-modal/ngrx/preview.effects";
import { CoreModule } from "./core/core.module";
import { CustomSerializer } from "./core/ngrx/router/custom-serializer";
import { RouterEffects } from "./core/ngrx/router/router.effects";
import { AuthModule } from "./core/services/auth/auth.module";
import { SessionEffects } from "./core/services/session/ngrx/session.effects";
import { OutgoingMailEffects } from "./dashboard/outgoing-mails/outgoing-mail.effects";
import { DevModule } from "./dev/dev.module";
import { KpiEffects } from "./kpi/ngrx/kpi.effects";
import { KpiModule } from "./kpi/kpi.module";
import { LoginModule } from "./login/login.module";
import { ConfigEffects } from "./shared/config/config.effects";
import { SharedEffects } from "./shared/ngrx/shared.effects";
import { UserEffects } from "./shared/user";
import { SidebarCallingListsEffects } from "./sidebar/calling-lists/ngrx/calling-lists.effects";
import { CreateContactEffects } from "./sidebar/contacts/ngrx/create-contact.effects";
import { CreateFollowUpEffects } from "./sidebar/create-follow-up/ngrx/create-follow-up.effects";
import { EmployeesCreateEffects } from "./sidebar/employees/employees-create/ngrx/employees-create.effects";
import { EmployeesOfficeCreateEffects } from "./sidebar/employees/employees-office-create/ngrx/employees-office-create.effects";
import { EmployeesSearchEffects } from "./sidebar/employees/employees-search/ngrx/employees-search.effects";
import { ExternalTipsEffects } from "./sidebar/external-tips/ngrx/external-tips.effects";
import { ResidenceEffects } from "./sidebar/residence/ngrx/residence.effects";
import { SalesMeetingEffects } from "./sidebar/sales-meeting/ngrx/sales-meeting.effects";
import { SendMessageEffects } from "./sidebar/send-message/ngrx/send-message.effects";
import { SidebarEffects } from "./sidebar/ngrx/sidebar.effects";
import { SidebarModule } from "./sidebar/sidebar.module";
import { TasksDefaultEffects } from "./sidebar/tasks/tasks-default/tasks-default.effects";
import { TasksSalesMeetingReportEffects } from "./sidebar/tasks/tasks-sales-meeting-report/tasks-sales-meeting-report.effects";
import { TasksEffects } from "./sidebar/tasks/ngrx/tasks.effects";
import { SendTipsEffects } from "./sidebar/tips/ngrx/send-tips.effects";

import { A11yModule } from "@angular/cdk/a11y";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { PortalModule } from "@angular/cdk/portal";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatSliderModule } from "@angular/material/slider";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTreeModule } from "@angular/material/tree";
import { AssignLeadModalEffects } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.effects";
import { entityConfig } from "@app/core/ngrx/entity-metadata";
import { GlobalErrorHandler } from "@app/core/services/global-error-handler/global-error-handler.module";
import { MoreOpportunitiesEffects } from "@app/dashboard/more-opportunities/more-opportunities.effects";
import { TodoListEffects } from "@app/dashboard/todo-list/todo-list.effects";
import { ActionListEffects } from "@app/shared/modules/action-list";
import { CalendarWidgetService } from "@app/shared/modules/calendar-widget/calendar-widget.service";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { CreateObjectEffects } from "@app/sidebar/create-object/ngrx/create-object.effects";
import { LeadChannelsFormEffects } from "@app/sidebar/lead-channels/ngrx/lead-channels-form.effects";
import { LeadResultOptionsFormEffects } from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.effects";
import { LeadTemplatesFormEffects } from "@app/sidebar/lead-templates/ngrx/lead-templates-form.effects";
import { MessageTemplatesFormEffects } from "@app/sidebar/message-templates/ngrx/message-templates-form.effects";
import { TranslationsModule } from "@app/translations.module";
import { EntityDataModule } from "@ngrx/data";
import * as Highcharts from "highcharts/highcharts";
import HC_more from "highcharts/highcharts-more";
import HC_funnel from "highcharts/modules/funnel";
import HC_noDataToDisplay from "highcharts/modules/no-data-to-display";
import HC_solidGauge from "highcharts/modules/solid-gauge";
import HC_variablePie from "highcharts/modules/variable-pie";
import { Ng9OdometerModule } from "ng9-odometer";
import { forkJoin, map, switchMap } from "rxjs";
import { SalesFocusAreasEffects } from "./settings/map-areas/focus-areas/ngrx";

const GOOGLE_MAPS_KEY = "AIzaSyDjSYy_Lff0xbJI-0-IVdBlPuq-Z9dFdek";

declare var require: any;

export function loadLocale(localeService: LocaleService) {
  const currentLocale = localeService.getCurrentLocale();
  import(`/node_modules/@angular/common/locales/${currentLocale}.js`).then(
    (locale) => {
      registerLocaleData(locale.default);
    }
  );
  return currentLocale;
}

export function translateLoader(
  http: HttpClient,
  translationsModule: TranslationsModule
) {
  translationsModule.getLanguageModificationLayers().subscribe(() => {});
  return new MultiTranslateHttpLoader(http, translationsModule, [
    { prefix: "./assets/i18n/", suffix: ".json" },
  ]);
}

export class MultiTranslateHttpLoader implements TranslateLoader {
  constructor(
    private http: HttpClient,
    private translationsModule: TranslationsModule,
    public resources: { prefix: string; suffix: string }[] = [
      {
        prefix: "./assets/i18n/",
        suffix: ".json",
      },
    ]
  ) {}

  public getTranslation(lang: string): any {
    return this.translationsModule.getLanguageModificationLayers().pipe(
      map((layers) => {
        layers
          .split(",")
          .filter((layer) => layer !== "")
          .forEach((layer) =>
            this.resources.push({
              prefix: "./assets/i18n/",
              suffix: `-${layer}.json`,
            })
          );
      }),
      switchMap(() =>
        forkJoin(
          this.resources.map((config) => {
            return this.http.get(`${config.prefix}${lang}${config.suffix}`);
          })
        ).pipe(
          map((response: any[]) => {
            return response.reduce((a, b) => {
              return Object.assign(a, b);
            });
          })
        )
      )
    );
  }
}

export function loadAppConfig(appConfig: AppConfigService) {
  return () => appConfig.load();
}

const angularModules = [
  CommonModule,
  BrowserModule,
  BrowserAnimationsModule,
  A11yModule,
  ClipboardModule,
  CdkStepperModule,
  CdkTableModule,
  CdkTreeModule,
  DragDropModule,
  MatAutocompleteModule,
  MatBadgeModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatStepperModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  PortalModule,
  ScrollingModule,
  MatFormFieldModule,
  MatBottomSheetModule,
  MatDialogModule,
];

const ngrxModules = [
  StoreModule.forRoot(reducers, { metaReducers }),
  StoreRouterConnectingModule.forRoot(),
  StoreDevtoolsModule.instrument({ name: "Quedro CRM", maxAge: 40 }),
];

const bootstrapModules = [
  BsDropdownModule.forRoot(),
  ModalModule.forRoot(),
  PaginationModule.forRoot(),
  PopoverModule.forRoot(),
  TypeaheadModule.forRoot(),
  TooltipModule.forRoot(),
  BsDatepickerModule.forRoot(),
  ProgressbarModule.forRoot(),
  AccordionModule.forRoot(),
  AlertModule.forRoot(),
];

const thirdPartyModules = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: translateLoader,
      deps: [HttpClient, TranslationsModule],
    },
  }),
  MomentModule,
  NguiMapModule.forRoot({
    apiUrl: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_KEY}&v=3.exp&libraries=drawing`,
  }),
  ChartModule,
  Angulartics2Module.forRoot(),
  HotkeyModule.forRoot(),
  Ng9OdometerModule.forRoot(),
  HighchartsChartModule,
];

const coreEffects = [RouterEffects];

const appEffects = [
  UserEffects,
  ConfigEffects,
  IntegrationEffects,
  PreviewEffects,
  SharedEffects,
  SearchEffects,
  SessionEffects,
  KpiEffects,
  OutgoingMailEffects,
  SalesFocusAreasEffects,
  CompetitionsEffects,
  SettingsAccountExchangeEffects,
  TodoListEffects,
  ActionListEffects,
  MoreOpportunitiesEffects,
  AssignLeadModalEffects,
];

const sidebarEffects = [
  SidebarEffects,
  CreateContactEffects,
  SidebarCallingListsEffects,
  ContactResidencesEffects,
  CreateFollowUpEffects,
  SendTipsEffects,
  CreateLeadEffects,
  SalesMeetingEffects,
  SendMessageEffects,
  TasksEffects,
  TasksSalesMeetingReportEffects,
  TasksDefaultEffects,
  ResidenceEffects,
  EmployeesCreateEffects,
  EmployeesOfficeCreateEffects,
  EmployeesSearchEffects,
  CompetitionSidebarEffects,
  ExternalTipsEffects,
  ExternalProviderEffects,
  SearchProfileSidebarEffects,
  EikaEffects,
  SupportEffects,
  CreateObjectEffects,
  MessageTemplatesFormEffects,
  LeadChannelsFormEffects,
  LeadResultOptionsFormEffects,
  LeadTemplatesFormEffects,
];

const effects: any = [...coreEffects, ...appEffects, ...sidebarEffects];

const crmModules = [
  CoreModule.forRoot(),
  SidebarModule.forRoot(),
  DevModule,
  LoginModule,
  AuthModule,
  AppRoutingModule,
  KpiModule,
  CallingListsModule,
];

const providers: any[] = [
  AppConfigService,
  {
    provide: APP_INITIALIZER,
    useFactory: loadAppConfig,
    deps: [AppConfigService],
    multi: true,
  },
  {
    provide: RouteReuseStrategy,
    useClass: CustomReuseStrategy,
  },
  {
    provide: RouterStateSerializer,
    useClass: CustomSerializer,
  },
  {
    provide: LOCALE_ID,
    deps: [LocaleService],
    useFactory: (localeService: LocaleService) => loadLocale(localeService),
  },
  { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ConfigService,
  PreviousRouteService,
  Title,
  CalendarWidgetService,
];

@NgModule({
  imports: [
    ...angularModules,
    ...ngrxModules,
    ...bootstrapModules,
    ...thirdPartyModules,
    ...crmModules,
    EffectsModule.forRoot(effects),
    EntityDataModule.forRoot(entityConfig),
    FormComponentsModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: providers,
  exports: [],
})
export class AppModule {
  constructor() {
    HC_more(Highcharts);
    HC_solidGauge(Highcharts);
    HC_variablePie(Highcharts);
    HC_funnel(Highcharts);
    HC_noDataToDisplay(Highcharts);
  }
}
