import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./types-of-fees.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface TypeOfFee {
  orderNumber: string;
  name: string;
  invoiceFeeTypeId?: string;
  productNumber: string;
}

export interface TypeOfFeeState {
  items: TypeOfFee[];
  loading: boolean;
  storing: boolean;
  updating: boolean;
  deleting: boolean;
}

export const initialState: TypeOfFeeState = {
  items: [],
  loading: false,
  storing: false,
  updating: false,
  deleting: false,
};

export function typesOfFeesReducer(
  state: TypeOfFeeState,
  action: Action
): TypeOfFeeState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.getTypesOfFeesRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(actions.getTypesOfFeesSuccess, (state, { typesOfFees }) => ({
    ...state,
    items: typesOfFees,
    loading: false,
  })),
  on(actions.getTypesOfFeesFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(actions.storeTypesOfFeesRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(
    actions.storeTypesOfFeesSuccess,
    actions.storeTypesOfFeesFailed,
    (state) => ({
      ...state,
      storing: false,
    })
  ),
  on(actions.updateTypesOfFeesRequest, (state) => ({
    ...state,
    updating: true,
  })),
  on(
    actions.updateTypesOfFeesSuccess,
    actions.updateTypesOfFeesFailed,
    (state) => ({
      ...state,
      updating: false,
    })
  ),
  on(actions.deleteTypesOfFeesRequest, (state) => ({
    ...state,
    deleting: true,
  })),
  on(
    actions.deleteTypesOfFeesSuccess,
    actions.deleteTypesOfFeesFailed,
    (state) => ({
      ...state,
      deleting: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.typesOfFee
);

export const getTypeOfFee = createSelector(
  getState,
  (state: TypeOfFeeState) => state.items
);

export const getTypeOfFeeLoading = createSelector(
  getState,
  (state: TypeOfFeeState) => state.loading
);

export const getTypeOfFeeStoring = createSelector(
  getState,
  (state: TypeOfFeeState) => state.storing
);

export const getTypeOfFeeUpdating = createSelector(
  getState,
  (state: TypeOfFeeState) => state.updating
);

export const getTypeOfFeeDeleting = createSelector(
  getState,
  (state: TypeOfFeeState) => state.deleting
);
