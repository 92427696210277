import { PlatformLocation } from "@angular/common";
import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import * as sidebarActions from "../ngrx/sidebar.actions";
import { SidebarService } from "../sidebar.service";

@Injectable()
export class SidebarGuard implements CanDeactivate<any> {
  navigatingBack: boolean;

  constructor(
    private store: Store<AppState>,
    private sidebarService: SidebarService,
    private location: PlatformLocation
  ) {
    this.location.onPopState(() => (this.navigatingBack = true)); // TODO Find a better way to prevent the guard from closing tabs that should be open when navigating back from a feature module change.
  }

  canDeactivate(): Observable<boolean> {
    return this.sidebarService.canCloseSidebar().pipe(
      map((close) => {
        if (close && !this.navigatingBack) {
          this.store.dispatch(sidebarActions.closeAllNonPersistentTabs());
        }

        this.navigatingBack = false;
        return close;
      })
    );
  }
}
