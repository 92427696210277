import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { getSubPages } from "@app/shared/config/config.reducer";
import { SubPageConfig } from "@app/shared/config/models";
import * as subPages from "@app/shared/config/utils/sub-pages";
import { CONTACTS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import * as icons from "@app/shared/utils/icons";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, Observable } from "rxjs";

@Injectable()
export class ContactsSubPageService {
  _subPages$ = new BehaviorSubject([]);

  get subPages$(): Observable<SubPage[]> {
    return this._subPages$.asObservable();
  }

  constructor(private store: Store<AppState>) {}

  loadSubPages(contactId: string): void {
    this.store
      .pipe(
        select(getSubPages(CONTACTS_CRM_MODULE)),
        first(),
        map((subPages: SubPageConfig[]) => subPages.filter((p) => p.enabled)),
        map((subPages: SubPageConfig[]) =>
          subPages.map((p) => this.getSubPage(p.type, contactId))
        )
      )
      .subscribe((pages) => this._subPages$.next(pages));
  }

  getSubPage(type: string, contactId: string): SubPage {
    switch (type) {
      case subPages.OVERVIEW: {
        return {
          label: subPages.OVERVIEW,
          routerLink: this.getRoute(contactId, "overview"),
          icon: icons.ICON_OVERVIEW,
        };
      }
      case subPages.TIMELINE: {
        return {
          label: subPages.TIMELINE,
          routerLink: this.getRoute(contactId, "timeline"),
          icon: icons.ICON_TIMELINE,
        };
      }
      case subPages.SALES_MEETINGS: {
        return {
          label: subPages.SALES_MEETINGS,
          routerLink: this.getRoute(contactId, "sales-meetings"),
          icon: icons.ICON_SALES_MEETING,
        };
      }
      case subPages.RESIDENCES: {
        return {
          label: subPages.OBJECTS,
          routerLink: this.getRoute(contactId, "residences"),
          icon: icons.ICON_RESIDENCE,
        };
      }
      case subPages.SEARCH_PROFILE: {
        return {
          label: subPages.SEARCH_PROFILE,
          routerLink: this.getRoute(contactId, "search-profile"),
          icon: icons.ICON_SEARCH_PROFILE,
        };
      }
      case subPages.CONSENTS: {
        return {
          label: subPages.CONSENTS,
          routerLink: this.getRoute(contactId, "consents"),
          icon: icons.ICON_CONSENTS,
        };
      }
    }
    return {
      label: "",
      routerLink: ["/crm"],
      icon: "fa-question",
    };
  }

  private getRoute(contactId: string, subRoute: string): any[] {
    return ["/crm", CONTACTS_CRM_MODULE, contactId, subRoute];
  }
}
