import { createAction, props } from "@ngrx/store";
import { Contact } from "@app/models";

export const showExternalContactModal = createAction(
  "[ExternalContact] SHOW_EXTERNAL_CONTACT_MODAL",
  props<{ params: { contact: Contact; type: string } }>()
);

export const setExternalContact = createAction(
  "[ExternalContact] SET_EXTERNAL_CONTACT",
  props<{ contact: Contact }>()
);
