<app-q-modal>
  <ng-container slot="header">
    {{ "manage_showing_attendance" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="showing-table-container">
      <ng-container
        *ngIf="(contactIds$ | async).length > 0; else selectPotentialBuyers"
      >
        <p>
          {{ "selected_contacts" | translate }}:
          {{ (contacts$ | async).length }}
        </p>
        <ng-container *ngIf="taskSetsInProgress$ | async as taskSets">
          <app-progress-widget-item
            *ngFor="let taskSet of taskSets"
            [label]="taskSet?.label"
            [max]="taskSet?.tasks?.length"
            [value]="taskSet?.completed"
            [failed]="taskSet?.failed"
          >
          </app-progress-widget-item>
        </ng-container>
        <app-q-table
          *ngIf="!selectedShowing$.value"
          [columns]="columns"
          [sortableColumns]="sortableColumns"
          [headers]="headers"
          [defaultPageSize]="paginator$.value.pageSize"
          [key]="'showing-modal-list'"
          [data]="showings$ | async"
          [length]="showingService.total$ | async"
          [enablePaginator]="false"
          [enableClick]="false"
          [enableActions]="false"
          [enableCustomization]="false"
          [sortActive]="sorting$.value.active"
          [sortDirection]="sorting$.value.direction"
          [loading]="showingService.loading$ | async"
          [enableSelection]="false"
          [enableSearch]="false"
        >
          <ng-container slot="buttons">
            <app-q-circle-btn
              [style.display]="
                (showCreatePrivateShowing$ | async) ? 'inline-block' : 'none'
              "
              [tooltipText]="'create_showing'"
              [icon]="'plus'"
              (click)="createNewShowingModal()"
            ></app-q-circle-btn>
            <app-q-circle-btn
              [style.margin-left]="'6px'"
              [icon]="'arrow-rotate-right'"
              [tooltipText]="'refresh_showings_tooltip'"
              (click)="refreshShowings()"
            ></app-q-circle-btn>
          </ng-container>
          <ng-template let-cell="cell" let-column="column" let-row="row">
            <ng-container [ngSwitch]="column">
              <ng-container *ngSwitchCase="'attendance'">
                <div style="width: 95%">
                  <app-segmented-controls
                    *ngIf="!row?.showingSlots; else showingSlotLink"
                    [controls]="
                      contacts$ | async | attendanceControlsFromContacts : row
                    "
                    [active]="
                      contacts$ | async | registrationStatusFromContacts : row
                    "
                    (change)="handleAttendedChange($event, row)"
                  ></app-segmented-controls>
                </div>
                <ng-template #showingSlotLink>
                  <span
                    class="text-link"
                    (click)="selectedShowing$.next(row)"
                    translate
                    >view_showing_slots</span
                  >
                </ng-template>
              </ng-container>
              <ng-container *ngSwitchCase="'isPublic'">
                {{ !!cell ? ("yes" | translate) : ("no" | translate) }}
              </ng-container>
              <ng-container *ngSwitchCase="'delete'">
                <ng-container
                  *ngIf="
                    row
                      | showingIsDeletable
                        : (contacts$ | async | attendanceContacts : row)
                        : object
                      | async
                  "
                >
                  <span
                    class="delete-icon deletable"
                    (click)="deleteShowing(row)"
                  >
                    <app-icon name="trash"></app-icon>
                  </span>
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                {{ cell }}
              </ng-container>
            </ng-container>
          </ng-template>
        </app-q-table>

        <ng-container *ngIf="!!selectedShowing$.value">
          <app-small-back-button
            [mode]="'output'"
            (click)="selectedShowing$.next(null)"
          ></app-small-back-button>
          <h4>
            {{ "selected_showing" | translate }}:
            {{ selectedShowing$.value.startTime }}
          </h4>
          <app-q-table
            [columns]="slotColumns"
            [sortableColumns]="[]"
            [headers]="{}"
            [defaultPageSize]="paginator$.value.pageSize"
            [key]="'showing-slots-modal-list'"
            [data]="selectedShowing$.value.showingSlots"
            [length]="selectedShowing$.value.showingSlots.length"
            [enablePaginator]="false"
            [enableClick]="false"
            [enableActions]="false"
            [enableCustomization]="false"
            [sortActive]="'startTime'"
            [sortDirection]="'desc'"
            [loading]="showingService.loading$ | async"
            [enableSelection]="false"
            [enableSearch]="false"
          >
            <ng-template let-cell="cell" let-column="column" let-row="row">
              <ng-container [ngSwitch]="column">
                <ng-container *ngSwitchCase="'slotCapacity'">
                  <div class="capacity-attention-container">
                    <span>{{ cell }}</span>
                    <span
                      *ngIf="
                        (
                          contactService.currentList$
                          | async
                          | contactsAssignedToShowing : row : true
                        ).length > +row?.slotCapacity
                      "
                      [matTooltip]="
                        'this_viewing_has_reach_the_capacity' | translate
                      "
                      matTooltipClass="q-tooltip"
                      class="capacity-attention"
                    >
                      <app-icon name="circle-exclamation"></app-icon>
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'attendance'">
                  <div style="width: 95%">
                    <app-segmented-controls
                      [controls]="
                        contacts$
                          | async
                          | attendanceControlsFromContacts
                            : row
                            : true
                            : (contactService.currentList$
                                | async
                                | contactsAssignedToShowing : row : true)
                      "
                      [active]="
                        contacts$
                          | async
                          | registrationStatusFromContacts : row : true
                      "
                      (change)="
                        handleAttendedChange(
                          $event,
                          selectedShowing$.value,
                          row
                        )
                      "
                    ></app-segmented-controls>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ cell }}
                </ng-container>
              </ng-container>
            </ng-template>
          </app-q-table>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #selectPotentialBuyers>
      <h3>
        {{ "step" | translate }} 1: {{ "select_potential_buyers" | translate }}
      </h3>
      <app-q-table
        [columns]="pbColumns"
        [sortableColumns]="[]"
        [headers]="headers"
        [defaultPageSize]="paginator$.value.pageSize"
        [key]="'showing-attendance-pb-list'"
        [data]="contactService.currentList$ | async"
        [length]="contactService.total$ | async"
        [enablePaginator]="false"
        [enableClick]="false"
        [enableActions]="false"
        [sortActive]="'firstName'"
        [sortDirection]="sorting$.value.direction"
        [loading]="contactService.loading$ | async"
        [enableSelection]="true"
        [enableSearch]="false"
        (selected)="pbSelection$.next($event)"
        (sort)="sorting$.next($event)"
        (page)="paginator$.next($event)"
      >
        <ng-template let-cell="cell" let-column="column" let-row="row">
          <ng-container [ngSwitch]="column">
            <ng-container *ngSwitchCase="'firstName'">
              <app-contact-name
                [contactId]="row.contactId"
                [contactType]="row?.contactType"
              >
                {{ row.firstName }} {{ row.familyName }}
              </app-contact-name>
            </ng-container>
            <ng-container *ngSwitchCase="'address'">
              <p>
                {{ row.street }}
                {{ row.city }} {{ row.zip }}
              </p>
            </ng-container>
            <ng-container *ngSwitchCase="'bidStatus'">
              <app-q-chip
                *ngIf="provider$ | async as provider"
                [text]="
                  row?.bidStatus
                    | bidstatusToText
                      : !!(row?.bids | highestActiveBid)
                      : provider
                    | translate
                "
                [color]="
                  row?.bidStatus
                    | bidstatusToColor
                      : !!(row?.bids | highestActiveBid)
                      : provider
                "
              ></app-q-chip>
            </ng-container>
            <ng-container *ngSwitchCase="'notes'">
              <span
                [popover]="row.notes ? row.notes[0]?.note : ''"
                triggers="mouseenter:mouseleave"
                placement="left"
                class="popover-container popover--arrow-left"
              >
                {{ row.notes ? row.notes[0]?.note : "" }}
              </span>
            </ng-container>

            <ng-container *ngSwitchCase="'contacted'">
              <ng-container *ngIf="row?.lastContacts.length > 0">
                <app-icon
                  [name]="row.lastContacts[0]?.actionType | contactActionCode"
                ></app-icon>
                {{ row.lastContacts[0]?.contactDate | customDateFormat }}
                <span
                  *ngIf="
                    row.lastContacts[0]?.note &&
                    row.lastContacts[0]?.actionType !== 'CONSUMER_ONE2ONE_EMAIL'
                  "
                  class="text-link"
                  [popover]="row.lastContacts[0]?.note"
                  triggers="mouseenter:mouseleave"
                  placement="left"
                >
                  {{ "details" | translate }}
                </span>
                <span
                  *ngIf="
                    row.lastContacts[0]?.note &&
                    row.lastContacts[0]?.actionType === 'CONSUMER_ONE2ONE_EMAIL'
                  "
                  class="text-link"
                  (click)="
                    $event.stopPropagation();
                    openPreviewModal(row.lastContacts[0]?.note)
                  "
                >
                  {{ "view" | translate }}
                </span>
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'insertedDate'">
              <div>{{ row.contactInsertedDate | momentify }}</div>
              <div>
                <span translate>source</span>:
                <span> {{ row.contactOrigin | originByKey | translate }}</span>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ cell }}
            </ng-container>
          </ng-container>
        </ng-template>
      </app-q-table>
      <div class="pull-right">
        <button
          class="q-btn--save"
          [disabled]="pbSelection$.value.length === 0"
          (click)="setPbSelectionToContactIds()"
        >
          {{ "next" | translate | uppercase }}
        </button>
      </div>
    </ng-template>
  </ng-container>

  <ng-container slot="actions">
    <button class="q-btn--cancel" mat-dialog-close (click)="closeModal()">
      {{ "close" | translate | uppercase }}
    </button>
  </ng-container>
</app-q-modal>
