import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import { createAction, props } from "@ngrx/store";

export const getBanksRequest = createAction(
  "[MANAGE BANKS] GET_BANKS_REQUESTS"
);

export const getBanksSuccess = createAction(
  "[MANAGE BANKS] GET_BANKS_SUCCESS",
  props<{ chosenProviders: ExternalBank[] }>()
);

export const getBanksFail = createAction("[MANAGE BANKS] GET_BANKS_FAIL");

export const updateSelectedBanksRequest = createAction(
  "[MANAGE BANKS] UPDATE_SELECTED_BANKS_REQUESTS",
  props<{ ids: string[] }>()
);

export const updateSelectedBanksSuccess = createAction(
  "[MANAGE BANKS] UPDATE_SELECTED_BANKS_SUCCESS"
);

export const updateSelectedBanksFail = createAction(
  "[MANAGE BANKS] UPDATE_SELECTED_BANKS_FAIL"
);
