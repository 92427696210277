import * as assignLeadModalActions from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import { Task, TaskType } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatsLeadEmployee } from "@app/statistics/boxes/leads/utils";

export interface AssignLeadModalState {
  loading: boolean;
  showEmployee: boolean;
  showOffice: boolean;
  leadIds: Task[];
  employees: StatsLeadEmployee[];
  taskTypes: TaskType[];
  isLead: boolean;
  isClosed: boolean;
}

export const initialState: AssignLeadModalState = {
  employees: [],
  loading: false,
  showEmployee: false,
  showOffice: false,
  leadIds: [],
  taskTypes: [],
  isLead: true,
  isClosed: true,
};

export function assignLeadModalReducer(
  state: AssignLeadModalState,
  action: Action
): AssignLeadModalState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(assignLeadModalActions.toggle, (state, { parameters }) => ({
    ...state,
    showEmployee: parameters?.showEmployee ? parameters.showEmployee : false,
    showOffice: parameters?.showOffice ? parameters.showOffice : false,
    leadIds: parameters.leadIds,
    isClosed: !state.isClosed,
  })),
  on(assignLeadModalActions.fetchEmployeesRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(assignLeadModalActions.fetchEmployeesSuccess, (state, { employees }) => ({
    ...state,
    loading: false,
    employees,
  })),
  on(assignLeadModalActions.fetchEmployeesFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(assignLeadModalActions.fetchTaskTypesRequest, (state) => ({
    ...state,
    taskTypesLoading: true,
  })),
  on(assignLeadModalActions.fetchTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    taskTypesLoading: false,
    taskTypes,
  })),
  on(assignLeadModalActions.fetchTaskTypesFail, (state) => ({
    ...state,
    taskTypesLoading: false,
  })),
  on(assignLeadModalActions.setIsLead, (state, { isLead }) => ({
    ...state,
    isLead,
  }))
);

const selectFeature =
  createFeatureSelector<AssignLeadModalState>("assignLeadModal");

export const getLoading = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.loading
);

export const getShowEmployee = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.showEmployee
);

export const getShowOffice = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.showOffice
);

export const getLeadIds = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.leadIds
);

export const getEmployees = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.employees
);

export const getTaskTypes = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.taskTypes
);

export const getIsLead = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.isLead
);

export const getIsClosed = createSelector(
  selectFeature,
  (state: AssignLeadModalState) => state.isClosed
);
