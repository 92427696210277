import { Task } from "@app/models";

export const getQuickLinkLabel = (task: Task): string => {
  return `handle_${task.eaTaskTypeCategory}`;
};

export const getQuickLinkLabelFromActionType = (actionType: string): string => {
  if (actionType === "4501" || actionType === "4502" || actionType === "4503") {
    return `handle_lead`;
  } else {
    return `handle_task`;
  }
};
