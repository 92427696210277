import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Contact, QObject } from "@app/models";
import { Municipality } from "@app/models/municipality";
import { Feature } from "@app/shared/config/models";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import * as formUtils from "@app/shared/utils/form-utils";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { ONLY_DIGITS } from "@app/shared/utils/regex-patterns";
import { APARTMENT_TYPES } from "@app/sidebar/external-provider/components/kivi-form/utils/apartment-types";
import { EXTRA_REALTY_TYPES } from "@app/sidebar/external-provider/components/kivi-form/utils/extra-realty-types";
import { ContactFormComponent } from "@app/sidebar/shared/contact-form/contact-form.component";
import {
  first,
  map,
  Observable,
  PartialObserver,
  startWith,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-kivi-form",
  templateUrl: "./kivi-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./kivi-form.component.scss",
  ],
})
export class KiviFormComponent implements OnDestroy, OnChanges, OnInit {
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;
  @Output()
  submitClicked: EventEmitter<{ residence: {}; contact?: {}; extraData: {} }> =
    new EventEmitter<{
      residence: {};
      contact?: {};
      extraData: {};
    }>();
  @Output() closeClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() preselectedObjType: string | number;
  @Input() patching: boolean;
  @Input() observer: PartialObserver<any>;
  @Input() residence: QObject;
  @Input() municipalities$: Observable<Municipality[]>;
  @Input() contact: Contact;
  @Input() preselectedMuncipitalityId: number;
  @Input() addressSuggesterFeature: Feature;
  @Input() addressValidationFeature: Feature;
  @Input() countryCode: string;
  @Input() objectTypes: ObjectType[];
  @Input() showCity = false;
  unsubscribe$ = new Subject<void>();
  apartmentTypes = APARTMENT_TYPES;
  extraRealtyTypes = EXTRA_REALTY_TYPES;
  form: FormGroup;
  showContactError = false;
  municipalities: Municipality[];

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) {
    this.buildForm();
  }

  ngOnInit() {
    this.setFormValidators();
    this.registerObserverToFormValueChanges();
    this.handleExtraRealtyTypeValidator();
    this.municipalities$.pipe(first()).subscribe((municipalities) => {
      this.municipalities = municipalities;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: any): void {
    if (changes.preselectedObjType || (changes.showCity && this.showCity)) {
      this.fillForm();
    }

    if (
      changes.preselectedMuncipitalityId &&
      !!this.preselectedMuncipitalityId
    ) {
      this.municipalities$.pipe(first()).subscribe((municipalities) => {
        this.municipalities = municipalities;
        if (
          municipalities &&
          municipalities.find(
            (municipality: Municipality) =>
              municipality.municipalityId ===
              Number(this.preselectedMuncipitalityId)
          )
        ) {
          this.form
            .get("municipality")
            .setValue(this.preselectedMuncipitalityId);
        }
      });
    }
    if (changes.patching) {
      this.cdr.detectChanges();
    }
  }

  buildForm(): void {
    this.form = this.fb.group({
      municipality: ["", Validators.required],
      objType: ["", Validators.required],
      zip: ["", [Validators.pattern(ONLY_DIGITS)]],
      city: [""],
      apartmentType: ["", Validators.required],
      extraRealtyType: [""],
    });
  }

  fillForm(): void {
    if (
      this.objectTypes.find(
        (type: ObjectType) => type.objectTypeId === this.preselectedObjType
      )
    ) {
      this.form.get("objType").setValue(this.preselectedObjType);
    }

    if (this.residence.city) {
      this.form.get("city").setValue(this.residence.city);
    }

    if (this.residence.zip) {
      this.form.get("zip").setValue(this.residence.zip);
    }
  }

  setFormValidators(): void {
    if (!this.residence.zip || this.showCity) {
      this.form.get("zip").setValidators([Validators.required]);
      this.form.get("zip").updateValueAndValidity();
    }
    if (!this.residence.city || this.showCity) {
      this.form.get("city").setValidators([Validators.required]);
      this.form.get("city").updateValueAndValidity();
    }
  }

  handleExtraRealtyTypeValidator(): void {
    this.form
      .get("apartmentType")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((newValue) => {
        if (newValue === "2") {
          this.form.get("extraRealtyType").setValidators([Validators.required]);
          this.form.get("extraRealtyType").updateValueAndValidity();
        } else {
          this.form.get("extraRealtyType").setValidators([]);
          this.form.get("extraRealtyType").updateValueAndValidity();
        }
      });
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  contactDetailsRequired(): boolean {
    return !(
      this.contact.msisdn ||
      this.contact.phoneNumber ||
      this.contact.email
    );
  }

  getResidenceValues(): any {
    const residenceValues = {
      objType: this.form.get("objType").value,
      municipality: this.form.get("municipality").value,
    };
    if (!this.residence.zip || this.showCity) {
      residenceValues["zip"] = this.form.get("zip").value;
    }
    if (!this.residence.city || this.showCity) {
      residenceValues["city"] = this.form.get("city").value;
    }
    return residenceValues;
  }

  getContactValues(): any {
    const contactValues = {
      msisdn: this.contactForm.form.get("contactInfo.msisdn").value,
      phoneNumber: this.contactForm.form.get("contactInfo.phoneNumber").value,
      email: this.contactForm.form.get("contactInfo.email").value,
    };
    return contactValues;
  }

  getExtraValues(): any {
    if (this.form.get("apartmentType").value === "2") {
      return {
        apartmentTypeId: Number(this.form.get("apartmentType").value),
        extraRealtyTypeId: Number(this.form.get("extraRealtyType").value),
      };
    } else {
      return {
        apartmentTypeId: Number(this.form.get("apartmentType").value),
      };
    }
  }

  isFormValid(): boolean {
    if (this.form.valid && !this.contactDetailsRequired()) {
      return true;
    } else if (
      this.form.valid &&
      this.contactDetailsRequired() &&
      this.contactFormHasValue()
    ) {
      return true;
    } else {
      return false;
    }
  }

  closeTab(): void {
    this.closeClicked.emit();
  }

  contactFormHasValue(): boolean {
    return (
      this.contactForm.form.get("contactInfo.msisdn").value.length > 0 ||
      this.contactForm.form.get("contactInfo.phoneNumber").value.length > 0 ||
      this.contactForm.form.get("contactInfo.email").value.length > 0
    );
  }

  submit(): void {
    if (this.isFormValid()) {
      const params = {
        residence: this.getResidenceValues(),
        contact: this.contactDetailsRequired() ? this.getContactValues() : null,
        extraData: this.getExtraValues(),
      };
      this.submitClicked.emit(params);
    } else {
      if (
        this.contactDetailsRequired() &&
        this.contactForm !== undefined &&
        !this.contactFormHasValue()
      ) {
        this.showContactError = true;
      } else {
        this.showContactError = false;
      }
      formUtils.markAllAsTouched(this.form);
      this.cdr.detectChanges();
    }
  }

  handleZipSelected(address: Address): void {
    this.form.get("zip").setValue(address.zip);
    this.form.get("city").setValue(address.city);
    this.form.updateValueAndValidity();
  }
}
