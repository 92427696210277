import { Component, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { MatDialogRef } from "@angular/material/dialog";
import {
  first,
  map,
  Observable,
  Subject,
  switchMap,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import { AssignLeadService } from "@app/core/components/assign-lead-modal/assign-lead.service";
import { toggle } from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.actions";
import * as fromAssignLeadModal from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.reducer";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { TransferLeadParams } from "@app/lists/lists-leads/ngrx/lists-leads.reducer";
import { Office, Task } from "@app/models";
import { getOffices } from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { getEmployeeOffices } from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";

@Component({
  selector: "app-assign-lead-to-office-modal",
  templateUrl: "./assign-lead-to-office-modal.component.html",
  styleUrls: ["./assign-lead-to-office-modal.component.scss"],
})
export class AssignLeadToOfficeModalComponent implements OnInit, OnDestroy {
  leadIds$: Observable<Task[]>;
  isLead$: Observable<boolean>;
  loading$: Observable<boolean>;
  isAdmin$: Observable<boolean>;
  isAdminOsManager$: Observable<boolean>;
  offices$: Observable<Office[]>;
  selectedOffices$: Observable<Office[]>;
  unsubscribe$ = new Subject<void>();
  canSendEmail = true;
  transferComment = "";
  emailContent = "";
  selectedOffice: Office = null;

  constructor(
    public dialogRef: MatDialogRef<AssignLeadToOfficeModalComponent>,
    private store: Store<AppState>,
    public taskService: TaskService,
    private assignLeadService: AssignLeadService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.dialogRef.afterClosed().subscribe(() => this.closeModal());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
    this.closeModal();
    this.dialogRef.close();
  }

  handleSearch(input: string): void {
    this.selectedOffices$ = this.offices$.pipe(
      map((offices: Office[]) =>
        offices.filter((office) => {
          return office.officeName.toLowerCase().includes(input.toLowerCase());
        })
      )
    );
  }

  handleCheckboxChange(showComment: any) {
    this.canSendEmail = showComment.checked;
    if (!this.canSendEmail) {
      this.emailContent = "";
    }
  }

  handleSelectedOffice(office: Office) {
    this.selectedOffice = office;
  }

  handleAssign() {
    const params: TransferLeadParams = {
      eaOfficeId: this.selectedOffice.eaOfficeId,
      eaEmployeeId: "",
      transferComment: this.transferComment,
      sendMessage: this.canSendEmail,
      message: !!this.canSendEmail ? this.emailContent : "",
    };

    this.leadIds$
      .pipe(
        first(),
        withLatestFrom(this.isLead$),
        map(([tasks, isLead]) => {
          this.assignLeadService.handleAssign(
            tasks,
            isLead,
            params,
            null,
            this.selectedOffice
          );
        })
      )
      .subscribe(() => this.close());
  }

  private mapStateToProps() {
    this.isLead$ = this.store.pipe(select(fromAssignLeadModal.getIsLead));
    this.leadIds$ = this.store.pipe(select(fromAssignLeadModal.getLeadIds));
    this.loading$ = this.store.pipe(select(fromAssignLeadModal.getLoading));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isAdminOsManager$ = this.store.pipe(select(fromUser.isManagerOrAdmin));
    this.offices$ = this.isAdminOsManager$.pipe(
      switchMap((isAdmin) =>
        isAdmin
          ? this.store.pipe(select(getOffices))
          : this.store.pipe(select(getEmployeeOffices))
      )
    );
    this.selectedOffices$ = this.offices$;
  }

  private closeModal() {
    this.store.dispatch(
      toggle({
        parameters: { showEmployee: false, showOffice: false, leadIds: [] },
      })
    );
  }
}
