import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Optional,
  Output,
  Renderer2,
  Self,
} from "@angular/core";
import { NgControl } from "@angular/forms";

let supportedInputTypes: Set<string>;
let nextUniqueId = 0;

const INPUT_INVALID_TYPES = [
  "button",
  "checkbox",
  "color",
  "file",
  "hidden",
  "image",
  "radio",
  "range",
  "reset",
  "submit",
];

@Directive({
  selector: "[adaptivePlaceholder]",
})
export class AdaptivePlaceholderDirective {
  /** Variables used as cache for getters and setters. */
  private _type = "text";
  private _placeholder = "";
  private _disabled = false;
  private _required = false;
  private _id: string;
  private _cachedUid: string;

  /** Whether the element is focused or not. */
  focused = false;

  /** Sets the aria-describedby attribute on the input for improved a11y. */
  ariaDescribedby: string;

  /** Whether the element is disabled. */
  @Input()
  get disabled() {
    return this._ngControl ? this._ngControl.disabled : this._disabled;
  }

  set disabled(value: any) {
    this._disabled = this.coerceBooleanProperty(value);
  }

  /** Unique id of the element. */
  @Input()
  get id() {
    return this._id;
  }

  set id(value: string) {
    this._id = value || this._uid;
  }

  /** Placeholder attribute of the element. */
  @Input()
  get placeholder() {
    return this._placeholder;
  }

  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this._placeholderChange.emit(this._placeholder);
    }
  }

  /** Whether the element is required. */
  @Input()
  get required() {
    return this._required;
  }

  set required(value: any) {
    this._required = this.coerceBooleanProperty(value);
  }

  /** Input type of the element. */
  @Input()
  get type() {
    return this._type;
  }

  set type(value: string) {
    this._type = value || "text";
    this._validateType();

    // When using Angular inputs, developers are no longer able to set the properties on the native
    // input element. To ensure that bindings for `type` work, we need to sync the setter
    // with the native property. Textarea elements don't support the type property or attribute.
    if (!this._isTextarea() && this.getSupportedInputTypes().has(this._type)) {
      this._renderer.setProperty(
        this._elementRef.nativeElement,
        "type",
        this._type
      );
    }
  }

  /** The input element's value. */
  get value() {
    return this._elementRef.nativeElement.value;
  }

  set value(value: string) {
    this._elementRef.nativeElement.value = value;
  }

  /**
   * Emits an event when the placeholder changes so that the `md-input-container` can re-validate.
   */
  @Output() _placeholderChange = new EventEmitter<string>();

  get empty() {
    return (
      !this._isNeverEmpty() &&
      (this.value == null || this.value === "") &&
      // Check if the input contains bad input. If so, we know that it only appears empty because
      // the value failed to parse. From the user's perspective it is not empty.
      // TODO(mmalerba): Add e2e test for bad input case.
      !this._isBadInput()
    );
  }

  private get _uid() {
    return (this._cachedUid = this._cachedUid || `md-input-${nextUniqueId++}`);
  }

  private _neverEmptyInputTypes = [
    "date",
    "datetime",
    "datetime-local",
    "month",
    "time",
    "week",
  ].filter((t) => this.getSupportedInputTypes().has(t));

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    @Optional()
    @Self()
    public _ngControl: NgControl
  ) {
    // Force setter to be called in case id was not specified.
    this.id = this.id;
  }

  /** Focuses the input element. */
  focus() {
    this._elementRef.nativeElement.focus();
  }

  _onFocus() {
    this.focused = true;
  }

  _onBlur() {
    this.focused = false;
  }

  _onInput() {
    // This is a noop function and is used to let Angular know whenever the value changes.
    // Angular will run a new change detection each time the `input` event has been dispatched.
    // It's necessary that Angular recognizes the value change, because when floatingLabel
    // is set to false and Angular forms aren't used, the placeholder won't recognize the
    // value changes and will not disappear.
    // Listening to the input event wouldn't be necessary when the input is using the
    // FormsModule or ReactiveFormsModule, because Angular forms also listens to input events.
  }

  /** Make sure the input is a supported type. */
  private _validateType() {
    if (INPUT_INVALID_TYPES.indexOf(this._type) !== -1) {
      throw new Error(
        `Input type "${this.type}" isn't supported by md-input-container.`
      );
    }
  }

  private _isNeverEmpty() {
    return this._neverEmptyInputTypes.indexOf(this._type) !== -1;
  }

  private _isBadInput() {
    return (this._elementRef.nativeElement as HTMLInputElement).validity
      .badInput;
  }

  /** Determines if the component host is a textarea. If not recognizable it returns false. */
  private _isTextarea() {
    const nativeElement = this._elementRef.nativeElement;
    return nativeElement
      ? nativeElement.nodeName.toLowerCase() === "textarea"
      : false;
  }

  private coerceBooleanProperty(value: any): boolean {
    return value != null && `${value}` !== "false";
  }

  private getSupportedInputTypes(): Set<string> {
    if (!supportedInputTypes) {
      const featureTestInput = document.createElement("input");
      supportedInputTypes = new Set(
        [
          // `color` must come first. Chrome 56 shows a warning if we change the type to `color` after
          // first changing it to something else:
          // The specified value "" does not conform to the required format.
          // The format is "#rrggbb" where rr, gg, bb are two-digit hexadecimal numbers.
          "color",
          "button",
          "checkbox",
          "date",
          "datetime-local",
          "email",
          "file",
          "hidden",
          "image",
          "month",
          "number",
          "password",
          "radio",
          "range",
          "reset",
          "search",
          "submit",
          "tel",
          "text",
          "time",
          "url",
          "week",
        ].filter((value) => {
          featureTestInput.setAttribute("type", value);
          return featureTestInput.type === value;
        })
      );
    }
    return supportedInputTypes;
  }
}
