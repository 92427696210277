import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-task-not-resolved",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
    <div>
      <small *ngIf="item.param2?.length > 0" class="text-muted">
        <strong>{{ "type" | translate }}: </strong>{{ item.param2 }}
      </small>
      <small *ngIf="item.street?.length > 0" class="text-muted">
        <strong>{{ "address" | translate }}: </strong>
        <span
          (click)="itemService.navigateToObject(item.eaOid)"
          class="text-link"
        >
          {{ item.street }}
        </span>
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionTaskNotResolvedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;

  listenerTask: Function;

  classTask = "action-task-not-resolved-task";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap(() =>
        this.translateService.get("action_task_not_resolved_text", {
          task: `<span class="${
            this.classTask
          } text-link">${this.getTaskName()}</span>`,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerTask = this.itemService.createTaskLinkListener(
      this.elementRef,
      this.renderer,
      this.classTask,
      this.item
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerTask];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }

  getTaskName(): string {
    if (this.item.firstName || this.item.familyName) {
      return `${this.item.firstName} ${this.item.familyName}`;
    } else {
      return this.item.param2;
    }
  }
}
