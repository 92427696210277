<div
  [class.full-width]="fullWidth"
  [ngClass]="{ column: !sideBySide }"
  class="filters"
>
  <div
    [style.margin]="sideBySide ? '0 1rem 0 0' : '0 0 1rem 0'"
    class="filters__office filters__group border"
  >
    <span
      class="filters__category"
      [ngClass]="{
        disabled: !(offices$ | async) || (offices$ | async).length < 2
      }"
      >{{ "office" | translate }}:</span
    >
    <app-multi-select-dropdown
      [parentFormGroup]="parentFormGroup"
      [parentFormControlName]="officeIdFormControlName"
      [items]="(offices$ | async) ? (offices$ | async) : []"
      [displayProperty]="'name'"
      [valueProperty]="'eaOfficeId'"
      [isOffice]="true"
      [enableSearch]="
        (offices$ | async) ? (offices$ | async).length > 7 : false
      "
      [officePrefix]="officePrefix"
      [maxHeight]="'18.55rem'"
      [fullWidth]="fullWidth"
      [enableMobileSelect]="enableMobileSelect"
      [minWidth]="'13rem'"
      [singleSelect]="officeSingle"
      [enableRegions]="(isAdmin$ | async) && !officeSingle"
      [state]="
        !(offices$ | async) || (offices$ | async).length < 2
          ? 'DISABLED'
          : 'READY'
      "
    >
      <i class="icon-user" placeholderIcon></i>
      <span placeholder translate>offices</span>
      <i class="icon-user" mobilePlaceholderIcon></i>
      <span mobilePlaceholder translate>offices</span>
    </app-multi-select-dropdown>
  </div>
  <div
    class="filters__employee filters__group border"
    *ngIf="employees$ | async as employees"
  >
    <span
      class="filters__category"
      [ngClass]="{ disabled: employees.length < 1 }"
      >{{ "broker" | translate }}:</span
    >
    <app-multi-select-dropdown
      [parentFormGroup]="parentFormGroup"
      [parentFormControlName]="employeeIdFormControlName"
      [items]="employees"
      [displayProperty]="'fullName'"
      [valueProperty]="'eaEmployeeId'"
      [enableSearch]="employees.length > 7"
      [maxHeight]="'18.55rem'"
      [fullWidth]="fullWidth"
      [enableMobileSelect]="enableMobileSelect"
      [minWidth]="'13rem'"
      [state]="employees.length < 1 ? 'DISABLED' : 'READY'"
      [placeholder]="'broker'"
      [singleSelect]="employeeSingle"
      [enableInactiveBrokers]="isAdminOrManager$ | async"
      [inactiveBrokersSelected]="includeInactiveEmployees"
      (useInactiveBrokers)="
        includeInactiveEmployees = $event; fetchEmployees(eaOfficeIds)
      "
    >
      <i class="icon-user" placeholderIcon></i>
      <span placeholder translate>brokers</span>
      <i class="icon-user" mobilePlaceholderIcon></i>
      <span mobilePlaceholder translate>brokers</span>
    </app-multi-select-dropdown>
  </div>
</div>
