import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Office } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getOffice } from "@app/shared/user";
import * as userActions from "@app/shared/user/user.actions";
import { EmployeeOffices } from "@app/sidebar/profile/profile.component";
import { select, Store } from "@ngrx/store";
import {
  combineLatest as observableCombineLatest,
  Observable,
  take,
} from "rxjs";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "profile-change-office",
  template: `
    <div class="profile-change-office">
      <h3 translate>change_office</h3>
      <section>
        <ng-container *ngIf="offices?.length < 4">
          <button
            *ngFor="let office of offices"
            class="btn btn-default"
            [class.btn-secondary]="
              (currentOffice$ | async)?.eaOfficeId === office.eaOfficeId
            "
            (click)="changeOffice(office.eaEmployeeId, office.eaOfficeId)"
          >
            <span>{{ office.officeName | removeOfficePrefix | async }}</span>
            <span *ngIf="office.role !== 'broker'">
              ({{ office.role | translate }})
            </span>
          </button>
        </ng-container>
        <ng-container *ngIf="offices?.length >= 4">
          <app-dropdown-element
            [multiple]="false"
            [newStyle]="true"
            [items]="officeList"
            [label]="'select_office'"
            [path]="['offices']"
            [width]="'100%'"
            [required]="true"
            [search]="officeList?.length > 5"
            [preselectValue]="(currentOffice$ | async)?.eaOfficeId"
            (valueEntered)="handleSelectedOffice($event)"
          >
          </app-dropdown-element>
        </ng-container>
      </section>
    </div>
  `,
  styleUrls: ["./change-office.component.scss"],
})
export class ChangeOfficeComponent implements OnInit {
  private _offices: Office[];
  officeList: DropdownItem[] = [];
  currentOffice$: Observable<Office>;
  prefix = "";

  get offices() {
    return this._offices;
  }

  @Input()
  set employeeOffices(employeeOffices: EmployeeOffices[]) {
    const copy = JSON.parse(JSON.stringify(employeeOffices));
    const sortedOffices = [];

    copy.forEach((eo) =>
      eo.offices.forEach((office) => {
        office["eaEmployeeId"] = eo.eaEmployeeId;
        sortedOffices.push(office);
      })
    );

    this._offices = sortedOffices;

    if (this.prefix.length > 0) {
      this.removePrefix();
      this.sortOffices();
    }

    this.generateOfficeList();
    this.putMainOfficeFirst();
  }

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.currentOffice$ = this.store.pipe(select(getOffice));
    this.store
      .pipe(select(fromConfig.getOfficeNamePrefix))
      .subscribe((prefix) => {
        this.prefix = prefix;
        this.removePrefix();
        this.sortOffices();
        this.generateOfficeList();
        this.putMainOfficeFirst();
      });
  }

  removePrefix() {
    this._offices.forEach((office) => {
      if (office.officeName === this.prefix) {
        return;
      }

      office["officeName"] = office["officeName"]
        .replace(this.prefix, "")
        .trim();
    });
  }

  sortOffices() {
    this._offices.sort((a, b) => {
      return a.officeName.localeCompare(b.officeName);
    });
  }

  putMainOfficeFirst() {
    this._offices.forEach((office, index) => {
      if (office.isMainOffice.toLowerCase() === "true" && index !== 0) {
        const mainOffice = this._offices.splice(index, 1)[0];
        this._offices.unshift(mainOffice);
      }
    });
  }

  generateOfficeList() {
    this.officeList = [];
    this.officeList = this._offices.map((office) => {
      let officeName = office.officeName;

      if (office.role !== "broker") {
        const role = this.translateService.instant(office.role);
        officeName = `${officeName} (${role})`;
      }

      return {
        display: officeName,
        value: office.eaOfficeId,
      };
    });
  }

  changeOffice(eaEmployeeId: string, eaOfficeId: string): void {
    observableCombineLatest([this.currentOffice$])
      .pipe(take(1))
      .subscribe(([currentOffice]) => {
        if (eaOfficeId !== currentOffice.eaOfficeId) {
          this.store.dispatch(
            userActions.loadRequest({ eaEmployeeId, eaOfficeId })
          );
        }
      });
  }

  handleSelectedOffice(eaOfficeId: string) {
    const selectedOffice = this._offices.find(
      (office) => office.eaOfficeId === eaOfficeId
    );

    this.changeOffice(selectedOffice.eaEmployeeId, eaOfficeId);
  }
}
