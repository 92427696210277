import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { KpiCommissionsEarningsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-commissions-earnings/kpi-commissions-earnings.component";
import { KpiShowingsComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-showings/kpi-showings.component";
import { KpiSoldObjectsCommissionComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-sold-objects-commision/kpi-sold-objects-commission.component";
import { KpiSoonForSaleObjectsNewComponent } from "@app/kpi/kpi-modal/kpi-employee/kpi-details/kpi-soon-for-sale-objects-new/kpi-soon-for-sale-objects-new.component";
import { NpsBoxComponent } from "@app/kpi/nps-box/nps-box.component";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { ChartModule } from "angular2-highcharts";
import { MomentModule } from "angular2-moment";
import { HighchartsChartModule } from "highcharts-angular";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SkeletonScreenModule } from "../shared/modules/skeleton-screen/skeleton-screen.module";
import { UtilModule } from "../shared/modules/util/util.module";
import { AchievedTargetsComponent } from "./achieved-targets/achieved-targets.component";
import { KpiBoxComponent } from "./kpi-box/kpi-box.component";
import { KpiCollectionComponent } from "./kpi-collection/kpi-collection.component";
import { KpiChainComponent } from "./kpi-modal/kpi-chain/kpi-chain.component";
import { KpiBankTipsComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-bank-tips/kpi-bank-tips.component";
import { KpiBookedSalesMeetingDetailComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-booked-sales-meeting-detail/kpi-booked-sales-meeting-detail.component";
import { KpiCommissionEarningsRentalsComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-commission-earnings-rentals/kpi-commission-earnings-rentals.component";
import { KpiEstateCommissionComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-estate-commission/kpi-estate-commission.component";
import { KpiMadeSalesCallsComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-made-sales-calls/kpi-made-sales-calls.component";
import { KpiRentedObjectsCommissionComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-rented-objects-commission/kpi-rented-objects-commission.component";
import { KpiRentedObjectsComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-rented-objects/kpi-rented-objects.component";
import { KpiSalesContractDateComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-sales-contract-date/kpi-sales-contract-date.component";
import { KpiSoldObjectsComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-sold-objects/kpi-sold-objects.component";
import { KpiWonSalesMeetingDetailComponent } from "./kpi-modal/kpi-employee/kpi-details/kpi-won-sales-meeting-detail/kpi-won-sales-meeting-detail.component";
import { NpsSurveyResponsesComponent } from "./kpi-modal/kpi-employee/kpi-details/nps-survey-responses/nps-survey-responses.component";
import { KpiEmployeeComponent } from "./kpi-modal/kpi-employee/kpi-employee.component";
import { KpiModalComponent } from "./kpi-modal/kpi-modal.component";
import { KpiOfficeComponent } from "./kpi-modal/kpi-office/kpi-office.component";
import { KpiSummaryRowComponent } from "./kpi-modal/kpi-summary-row/kpi-summary-row.component";
import { KpiUtilsService } from "./services/kpi-utils.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { KpiExternalComponent } from "@app/kpi/kpi-external/kpi-external.component";
import { KpiEmployeeAdvancedComponent } from "@app/kpi/kpi-external/components/kpi-employee-advanced/kpi-employee-advanced.component";
import { KpiOfficeAdvancedComponent } from "@app/kpi/kpi-external/components/kpi-office-advanced/kpi-office-advanced.component";
import { KpiChainAdvancedComponent } from "@app/kpi/kpi-external/components/kpi-chain-advanced/kpi-chain-advanced.component";

const components = [
  KpiBoxComponent,
  KpiCollectionComponent,
  KpiModalComponent,
  KpiChainComponent,
  KpiOfficeComponent,
  KpiEmployeeComponent,
  KpiBookedSalesMeetingDetailComponent,
  KpiWonSalesMeetingDetailComponent,
  KpiSoldObjectsComponent,
  KpiShowingsComponent,
  KpiMadeSalesCallsComponent,
  KpiCommissionsEarningsComponent,
  KpiSummaryRowComponent,
  AchievedTargetsComponent,
  NpsBoxComponent,
  NpsSurveyResponsesComponent,
  KpiSoonForSaleObjectsNewComponent,
  KpiSoldObjectsCommissionComponent,
  KpiSalesContractDateComponent,
  KpiBankTipsComponent,
  KpiRentedObjectsComponent,
  KpiRentedObjectsCommissionComponent,
  KpiCommissionEarningsRentalsComponent,
  KpiEstateCommissionComponent,
  KpiExternalComponent,
  KpiEmployeeAdvancedComponent,
  KpiOfficeAdvancedComponent,
  KpiChainAdvancedComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ChartModule,
    TranslateModule,
    MomentModule,
    UtilModule,
    SkeletonScreenModule,
    ModalModule,
    TooltipModule,
    UiComponentsModule,
    HighchartsChartModule,
    ContactComponentsModule,
    MatTooltipModule,
    ProgressbarModule,
  ],
  declarations: [...components],
  exports: [KpiBoxComponent, KpiCollectionComponent, AchievedTargetsComponent],
  providers: [KpiUtilsService],
})
export class KpiModule {}
