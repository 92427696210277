<ol class="breadcrumb" [@fadeInDown]>
  <li *ngFor="let route of routes">
    <a [routerLink]="[route.to]">
      {{ route.label | translate }}
    </a>
  </li>
  <li class="active" translate>
    <ng-content></ng-content>
  </li>
</ol>
