<div class="widget-header">
  <h3>
    <app-icon size="large" name="home"></app-icon>&nbsp;<span>{{
      "project_details" | translate
    }}</span>
  </h3>
  <div class="d-flex">
    <app-q-circle-btn
      *ngIf="canDownloadFiles$ | async"
      icon="laptop-arrow-down"
      tooltipText="download"
      (click)="downloadFiles()"
    >
    </app-q-circle-btn>
  </div>
</div>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div *ngIf="showing$ | async as project" class="overview-data">
    <div class="overview-data--field">
      <span class="text-bold">{{ "size" | translate }}:</span>
      <div>
        <span>{{ project.projectMinArea }}</span>
        <span> - </span>
        <span>{{ project.projectMaxArea }} </span>
        <span translate>area_unit</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "no_of_rooms" | translate }}:</span>
      <div>
        <span>{{ project.projectMinRooms }}</span>
        <span> - </span>
        <span>{{ project.projectMaxRooms }} </span>
        <span translate>no_rooms</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "price" | translate }}:</span>
      <div>
        <span>{{ project.projectMinPrice | separateThousands }}</span>
        <span> - </span>
        <span>{{ project.projectMaxPrice | separateThousands }} </span>
        <span>{{ currency$ | async }}</span>
      </div>
    </div>

    <div class="overview-data--field">
      <span class="text-bold">{{ "monthly_fee" | translate }}:</span>
      <div>
        <span>{{ project.minFee | separateThousands }}</span>
        <span> - </span>
        <span>{{ project.maxFee | separateThousands }} </span>
        <span
          ><span>{{ currency$ | async }}</span> /
          {{ "month" | translate | lowercase }}</span
        >
      </div>
    </div>

    <div
      class="overview-data--field"
      *ngIf="!!project?.responsibleEmployee?.employeeFullName"
    >
      <span class="text-bold">{{ "responsible_broker" | translate }}:</span>
      <div>
        {{
          project?.responsibleEmployee?.employeeFullName
            ? project?.responsibleEmployee?.employeeFullName
            : ("responsible_employee_missing" | translate)
        }}
      </div>
    </div>

    <div class="widget-container--footer" *ngIf="project.eaOid !== eaOid">
      <a [routerLink]="['/crm', 'showings', project.eaOid, 'overview']">
        <app-icon name="arrow-left" size="none"></app-icon>
        {{ "go_to_project" | translate }}</a
      >
    </div>
  </div>
</div>
