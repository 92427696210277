<div class="layout-container">
  <app-controls-box>
    <div class="title-container">
      <h4>{{ title$ | async }}</h4>

      <div class="right-container">
        <app-q-button
          *ngIf="hasAccessToUpperLevel$ | async"
          buttonType="save"
          label="show_upper_level"
          (click)="gotUpperLevel()"
        ></app-q-button>
        <div class="month-controls">
          <app-q-button
            *ngIf="canChangeDate"
            buttonType="cancel"
            icon="chevron-left"
            (click)="loadPreviousMonth()"
          >
          </app-q-button>
          <div class="month">{{ monthTitle }}</div>
          <app-q-button
            *ngIf="canChangeDate"
            buttonType="cancel"
            icon="chevron-right"
            (click)="loadNextMonth()"
          >
          </app-q-button>
        </div>
      </div>
    </div>
  </app-controls-box>

  <div>
    <ng-container [ngSwitch]="currentLevel">
      <ng-container *ngSwitchCase="'chain'">
        <kpi-chain-advanced [params]="queryParams"></kpi-chain-advanced>
      </ng-container>
      <ng-container *ngSwitchCase="'office'">
        <kpi-office-advanced [params]="queryParams"></kpi-office-advanced>
      </ng-container>
      <ng-container *ngSwitchCase="'employee'">
        <kpi-employee-advanced [params]="queryParams"></kpi-employee-advanced>
      </ng-container>
    </ng-container>
  </div>
</div>
