import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { filter, map, mergeMap, take } from "rxjs";
import { AppState } from "../../app.reducer";
import * as RouterActions from "../../core/ngrx/router/router.actions";
import { SALES_MEETING } from "../../shared/utils/tab-types";
import * as smActions from "../sales-meeting/ngrx/sales-meeting.actions";
import * as sidebarActions from "./sidebar.actions";
import { getTabs } from "./sidebar.reducer";

@Injectable()
export class SidebarEffects {
  closeTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sidebarActions.closeTab),
      mergeMap(({ tabType }) =>
        this.store.pipe(
          select(getTabs),
          take(1),
          map((tabs) => {
            // TODO possible obsolete after ngRx v4 https://github.com/ngrx/effects/issues/58
            const newTabs = tabs.filter((tab) => tab.type !== tabType);
            return this.redicetToOtherTab(
              newTabs.length > 0 ? newTabs[0].url : null
            );
          })
        )
      )
    )
  );

  closeTabs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sidebarActions.closeTabs),
      mergeMap(({ tabTypes }) =>
        this.store.pipe(
          select(getTabs),
          take(1),
          map((tabs) => {
            // TODO possible obsolete after ngRx v4 https://github.com/ngrx/effects/issues/58
            const newTabs = tabs.filter((tab) => !tabTypes.includes(tab.type));
            return this.redicetToOtherTab(
              newTabs.length > 0 ? newTabs[0].url : null
            );
          })
        )
      )
    )
  );

  resetSalesMeetingSidebar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sidebarActions.closeTab),
      filter(({ tabType }) => tabType === SALES_MEETING),
      map(() => smActions.reset())
    )
  );

  private redicetToOtherTab(newTab) {
    return RouterActions.go({
      path: [
        "/crm",
        {
          outlets: {
            sidebar: newTab,
          },
        },
      ],
      extras: {
        queryParamsHandling: "preserve",
      },
    });
  }

  constructor(private actions$: Actions, private store: Store<AppState>) {}
}
