import * as fromCallingLists from "@app/calling-lists/ngrx/calling-lists.reducer";
import * as fromCampaigns from "@app/campaigns/campaigns-module.reducer";
import * as fromAssignLeadModal from "@app/core/components/assign-lead-modal/ngrx/assign-lead-modal.reducer";
import * as fromMultiTasker from "@app/core/components/multi-tasker/ngrx/multi-tasker.reducer";
import * as fromSearch from "@app/core/components/search/ngrx/search.reducer";
import * as fromStandardModal from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import * as fromIntegration from "@app/integrations/ngrx/integrations.reducer";
import * as fromMiles from "@app/miles/miles-module.reducer";
import * as fromAddContactToCallingList from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.reducer";
import * as fromContactSummary from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import * as fromProgressWidget from "@app/shared/modules/progress-widget/ngrx/progress-widget.reducer";
import * as fromWidgets from "@app/widgets/widgets-module.reducer";
import { environment } from "@env/environment";
import * as fromRouter from "@ngrx/router-store";
import { ActionReducerMap, MetaReducer } from "@ngrx/store";
import { storeFreeze } from "ngrx-store-freeze";
import { handleUndo } from "ngrx-undo";
import * as fromCompetitions from "./competitions/ngrx/competitions.reducer";
import * as fromContact from "./contacts/contact-module.reducer";
import * as fromConfirm from "./core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as fromNavbar from "./core/components/layout/navbar/ngrx/navbar.reducer";
import * as fromToast from "./core/components/toast/ngrx/toast.reducer";
import { RouterStateUrl } from "./core/ngrx/router/custom-serializer";
import * as fromSession from "./core/services/session/ngrx/session.reducer";
import * as fromDashboard from "./dashboard/dashboard-module.reducer";
import * as fromKpi from "./kpi/ngrx/kpi.reducer";
import * as fromList from "./lists/lists-module.reducer";
import * as fromLogin from "./login/login-module.reducer";
import * as fromSalesFocusAreas from "./settings/map-areas/focus-areas/ngrx";
import * as fromSettings from "./settings/settings-module.reducer";
import * as fromMobileNavigation from "./shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import * as fromConfig from "./shared/config/config.reducer";
import { ConfigState } from "./shared/config/models";
import * as fromActionList from "./shared/modules/action-list";
import * as fromShared from "./shared/ngrx/shared.reducer";
import * as fromUser from "./shared/user";
import * as fromSidebar from "./sidebar/sidebar-module.reducer";
import * as fromStatistics from "./statistics/statistics-module.reducer";

export interface AppState {
  config: ConfigState;
  callingLists: fromCallingLists.CallingListsState;
  session: fromSession.SessionState;
  user: fromUser.UserState;
  confirm: fromConfirm.ConfirmState;
  competitions: fromCompetitions.CompetitionsState;
  toast: fromToast.ToastState;
  search: fromSearch.SearchState;
  actionList: fromActionList.ActionListState;
  kpi: fromKpi.KpiState;
  navbar: fromNavbar.NavbarState;
  mobileNavigation: fromMobileNavigation.MobileNavigationState;
  salesFocusAreas: fromSalesFocusAreas.SalesFocusAreasState;
  shared: fromShared.SharedState;
  lists: fromList.ListsModuleState;
  login: fromLogin.LoginModuleState;
  dashboard: fromDashboard.DashboardModuleState;
  contact: fromContact.ContactModuleState;
  contactSummary: fromContactSummary.ContactSummaryState;
  miles: fromMiles.MilesModuleState;
  addToContactCallingList: fromAddContactToCallingList.AddContactToCallingListState;
  statistics: fromStatistics.StatisticsModuleState;
  settings: fromSettings.SettingsModuleState;
  sidebar: fromSidebar.SidebarModuleState;
  standardModal: fromStandardModal.StandardModalState;
  assignLeadModal: fromAssignLeadModal.AssignLeadModalState;
  integration: fromIntegration.IntegrationState;
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  progressWidget: fromProgressWidget.ProgressWidgetState;
  campaigns: fromCampaigns.CampaignsModuleState;
  widgets: fromWidgets.WidgetsModuleState;
  multiTasker: fromMultiTasker.MultiTaskerState;
}

export const reducers: ActionReducerMap<AppState> = {
  config: fromConfig.configReducer,
  callingLists: fromCallingLists.callingListsReducer,
  session: fromSession.sessionReducer,
  user: fromUser.userReducer,
  confirm: fromConfirm.confirmReducer,
  competitions: fromCompetitions.competitionsReducer,
  toast: fromToast.toastReducer,
  salesFocusAreas: fromSalesFocusAreas.salesFocusAreasReducer,
  search: fromSearch.searchReducer,
  actionList: fromActionList.actionListReducer,
  kpi: fromKpi.kpiReducer,
  navbar: fromNavbar.navbarReducer,
  mobileNavigation: fromMobileNavigation.mobileNavigationReducer,
  shared: fromShared.sharedReducer,
  lists: fromList.listsModuleReducer,
  login: fromLogin.loginModuleReducer,
  dashboard: fromDashboard.dashboardModuleReducer,
  contact: fromContact.contactModuleReducer,
  contactSummary: fromContactSummary.contactSummaryReducer,
  miles: fromMiles.milesModuleReducer,
  addToContactCallingList:
    fromAddContactToCallingList.addContactToCallingListReducer,
  statistics: fromStatistics.statisticsModuleReducer,
  settings: fromSettings.settingsModuleReducer,
  sidebar: fromSidebar.sidebarModuleReducer,
  standardModal: fromStandardModal.standardModalReducer,
  assignLeadModal: fromAssignLeadModal.assignLeadModalReducer,
  integration: fromIntegration.integrationReducer,
  router: fromRouter.routerReducer,
  progressWidget: fromProgressWidget.progressWidgetReducer,
  campaigns: fromCampaigns.campaignModuleReducer,
  widgets: fromWidgets.widgetsModuleReducer,
  multiTasker: fromMultiTasker.multiTaskerReducer,
};

const _metaReducers = [handleUndo];
export const metaReducers: MetaReducer<AppState>[] = !environment.production
  ? [storeFreeze, ..._metaReducers]
  : _metaReducers;
