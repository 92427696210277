import { Task } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as contactNextStepActions from "./contact-next-step.actions";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";

export interface ContactNextStepState {
  loading: boolean;
  loaded: boolean;
  tasks: Task[];
  total: number;
  latestFetchAction: Action;
}

export const initialState: ContactNextStepState = {
  loading: false,
  loaded: false,
  tasks: [],
  total: 0,
  latestFetchAction: null,
};

export function contactNextStepReducer(
  state: ContactNextStepState,
  action: Action
): ContactNextStepState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(contactNextStepActions.getNextStepRequest, (state, action) => ({
    ...state,
    loading: true,
    latestFetchAction: action,
  })),
  on(contactNextStepActions.getNextStepSuccess, (state, { nextSteps }) => ({
    ...state,
    loading: false,
    loaded: true,
    tasks: [...nextSteps.rows],
    total: nextSteps.total,
  })),
  on(contactNextStepActions.getNextStepFail, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  })),
  on(contactNextStepActions.getMoreNextStepsRequest, (state, action) => ({
    ...state,
    loading: true,
    latestFetchAction: action,
  })),
  on(
    contactNextStepActions.getMoreNextStepsSuccess,
    (state, { nextSteps }) => ({
      ...state,
      loading: false,
      loaded: true,
      tasks: [...state.tasks, ...nextSteps.rows],
    })
  ),
  on(contactNextStepActions.getMoreNextStepsFail, (state) => ({
    ...state,
    loading: false,
    loaded: true,
  }))
);

const selectFeature = createFeatureSelector<ContactModuleState>("contact");
const getState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.nextStep
);

export const getLoading = createSelector(
  getState,
  (state: ContactNextStepState) => state.loading
);

export const getLoaded = createSelector(
  getState,
  (state: ContactNextStepState) => state.loaded
);

export const getTasks = createSelector(
  getState,
  (state: ContactNextStepState) => state.tasks
);

export const getTotal = createSelector(
  getState,
  (state: ContactNextStepState) => state.total
);

export const getRemainingTasks = createSelector(
  getState,
  (state: ContactNextStepState) => state.total - state.tasks.length
);

export const getLatestNextStepFetchAction = createSelector(
  getState,
  (state: ContactNextStepState) => state.latestFetchAction
);
