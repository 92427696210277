import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { PotentialBuyerService } from "@app/core/ngrx/multi-entity-services/potential-buyer.service";
import { ShowingAttendanceModalComponent } from "@app/showings/components/showing-attendance-modal/showing-attendance-modal.component";

@Component({
  selector: "app-bid-setting-modal",
  templateUrl: "./bid-setting-modal.component.html",
  styleUrls: ["./bid-setting-modal.component.scss"],
})
export class BidSettingModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowingAttendanceModalComponent>,
    private potentialBuyerService: PotentialBuyerService
  ) {}

  ngOnInit(): void {
    this.dialogRef.afterClosed().subscribe(() => {
      this.potentialBuyerService.refreshBidsByObject().subscribe();
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
