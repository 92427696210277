import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[leadPercentageColorCode]",
})
export class LeadPercentageColorCodeDirective implements OnChanges {
  @Input("leadPercentageColorCode") percentage: number;
  private _previousClassName: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  ngOnChanges() {
    const newClassName = this.getClassName();

    if (newClassName !== this._previousClassName) {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        this._previousClassName
      );
      this.renderer.addClass(this.elementRef.nativeElement, newClassName);
      this._previousClassName = newClassName;
    }
  }

  private getClassName(): string {
    let className = "dark-red-text";

    if (this.percentage >= 90) {
      className = "green-text";
    } else if (this.percentage >= 80) {
      className = "yellow-text";
    } else if (this.percentage >= 50) {
      className = "red-text";
    }

    return className;
  }
}
