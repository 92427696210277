import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import * as kpiActions from "../ngrx/kpi.actions";
import {
  BANK_TIPS,
  BOOKED_SALES_MEETINGS,
  COMMISSIONS_EARNINGS,
  COMMISSIONS_EARNINGS_RENTALS,
  ESTATE_COMMISSION,
  FOLLOWED_UP_POTENTIAL_BUYERS,
  MADE_SALES_CALLS,
  NPS,
  RENTED_OBJECTS,
  RENTED_OBJECTS_COMMISSION,
  SALES_CONTRACT_DATE,
  SHOWINGS,
  SOLD_OBJECTS,
  SOLD_OBJECTS_COMMISSION,
  SOON_FOR_SALE_OBJECTS_NEW,
  WON_SALES_MEETINGS,
} from "../utils/kpi-types";

@Injectable()
export class KpiUtilsService {
  resources = {
    [BOOKED_SALES_MEETINGS]: "booked-sales-meetings",
    [WON_SALES_MEETINGS]: "won-sales-meetings",
    [MADE_SALES_CALLS]: "made-sales-calls",
    [SOLD_OBJECTS]: "sold-objects",
    [FOLLOWED_UP_POTENTIAL_BUYERS]: "followed-up-potential-buyers",
    [COMMISSIONS_EARNINGS]: "commissions_earnings",
    [COMMISSIONS_EARNINGS_RENTALS]: "commissions_earnings_rentals",
    [RENTED_OBJECTS_COMMISSION]: "rentedobjectscommission",
    [RENTED_OBJECTS]: "rentedobjects",
    [SOON_FOR_SALE_OBJECTS_NEW]: "soonforsaleobjectsnew",
    [SALES_CONTRACT_DATE]: "sales-contract-date",
    [BANK_TIPS]: "banktips",
    [SHOWINGS]: "showings",
    [ESTATE_COMMISSION]: "estate_commissions",
    [SOLD_OBJECTS_COMMISSION]: "sold_objects_with_commission",
  };

  translationKeys = {
    [BOOKED_SALES_MEETINGS]: "sales_meeting_booked",
    [WON_SALES_MEETINGS]: "sales_meeting_won",
    [MADE_SALES_CALLS]: "sales_calls_made",
    [SOLD_OBJECTS]: "objects_sold",
    [NPS]: "nps_survey_responses",
    [COMMISSIONS_EARNINGS]: "commissions_earnings",
    [COMMISSIONS_EARNINGS_RENTALS]: "commissions_earnings_rentals",
    [RENTED_OBJECTS_COMMISSION]: "rented_objects_commission",
    [RENTED_OBJECTS]: "rented_objects",
    [SOON_FOR_SALE_OBJECTS_NEW]: "soon_for_sale_objects_new",
    [SALES_CONTRACT_DATE]: "sales_contract",
    [BANK_TIPS]: "bank_tips",
    [SHOWINGS]: "showings",
    [ESTATE_COMMISSION]: "estate_commissions",
    [SOLD_OBJECTS_COMMISSION]: "sold_objects_with_commission",
  };

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  getKpiUrlResourceByType(type: string): string {
    return this.resources[type];
  }

  getKpiTranslationKeyByType(type: string): string {
    return this.translationKeys[type];
  }

  setModalTitle(options: any) {
    const { type, modalType, name } = options;
    const month = moment.months()[moment(options.month, "YYYYMM").month()];
    const year = moment(options.month, "YYYYMM").year();
    const params = { month, year, name };
    const title = this.translate.instant(
      `${this.translationKeys[type]}_${modalType}`,
      {
        ...params,
      }
    );

    this.store.dispatch(kpiActions.setTitle({ title }));
  }

  getClassNameByProgress(progress: number): string {
    if (progress === null) {
      return "";
    }

    if (progress < 33) {
      return "text-danger";
    } else if (progress < 66) {
      return "text-warning";
    } else {
      return "text-success";
    }
  }
}
