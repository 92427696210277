import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import * as ContactSummaryActions from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  forkJoin as observableForkJoin,
  map,
  of,
  switchMap,
} from "rxjs";

@Injectable()
export class ContactSummaryEffects {
  getContactSummaryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContactSummaryActions.fetchContactSummaryRequest),
      switchMap(({ contactId, eaEmployeeId }) => {
        return observableForkJoin(
          this.fetchContactSummaryRequests(contactId, eaEmployeeId)
        ).pipe(
          map((results: any) =>
            ContactSummaryActions.fetchContactSummarySuccess({
              payload: results,
            })
          ),
          catchError(() => of(ContactSummaryActions.fetchContactSummaryFail()))
        );
      })
    )
  );

  fetchContactSummaryRequests = (contactId, eaEmployeeId) => {
    const requests = [];

    const getContact = { url: `contacts/${contactId}` };
    const getConsents = { url: `contacts/${contactId}/consents/search` };
    const getTasks = {
      url: `tasks`,
      params: {
        contactId: contactId,
        eaEmployeeId: eaEmployeeId,
        taskTypeCategory: "task,lead",
        resolved: "false",
      },
    };
    const getObjects = {
      url: `object/contact/${contactId}/contact-objects/search`,
      params: {},
    };
    const getPriceUpdateOrders = {
      url: `contacts/${contactId}/price-update-orders/search`,
      params: {},
    };
    const getAlert = {
      url: `alerts/search`,
      params: {
        contactId: contactId,
      },
    };
    const getSalesMeetings = {
      url: `contacts/${contactId}/sales-meetings`,
      params: {
        status: "prepared,valuation,waiting",
      },
    };
    const getOwnsResidence = { url: `contacts/${contactId}/profile` };
    const getNotes = {
      url: `notes/for-contact/${contactId}/search`,
      params: {
        sortBy: "pinned",
        sortOrder: "desc",
      },
    };

    requests.push(
      this.apiService.get(getContact.url),
      this.apiService.get(getConsents.url, {}),
      this.apiService.get(getTasks.url, getTasks.params),
      this.apiService.get(getObjects.url, getObjects.params, "api"),
      this.apiService.get(
        getPriceUpdateOrders.url,
        getPriceUpdateOrders.params
      ),
      this.apiService.get(getAlert.url, getAlert.params),
      this.apiService.get(getSalesMeetings.url, getSalesMeetings.params),
      this.apiService.get(getOwnsResidence.url),
      this.apiService.get(getNotes.url, getNotes.params)
    );
    return requests;
  };

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
