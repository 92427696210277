import { Component, Input } from "@angular/core";

export type ChipColor = "0" | "1" | "2" | "3" | "4" | "5";

@Component({
  selector: "app-q-chip",
  templateUrl: "./q-chip.component.html",
  styleUrls: ["./q-chip.component.scss"],
})
export class QChipComponent {
  @Input() text: string;
  @Input() color: ChipColor;
}
