import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "insertUnderscoreAndLowerCase" })
export class InsertUnderscoreAndLowerCasePipe implements PipeTransform {
  transform(value: string): string {
    return value
      ? value.replace(/([a-z])([A-Z])/g, "$1_$2").toLowerCase()
      : value;
  }
}
