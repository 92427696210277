import { Route } from "@angular/router";
import { SendExternalTipsHomeComponent } from "@app/sidebar/external-tips/send-external-tips-home/send-external-tips-home.component";
import { ExternalTipsCreateGuard } from "../guards/external-tips-create-guard.service";
import { ExternalTipsEditGuard } from "../guards/external-tips-edit-guard.service";
import { SendExternalTipsGuard } from "../guards/send-external-tips-guard.service.";
import { CreateExternalTipsComponent } from "./create-external-tips/create-external-tips.component";
import { EditExternalTipsComponent } from "./edit-external-tips/edit-external-tips.component";
import { EditExternalTipGuard } from "./guards/edit-external-tip-guard.service";
import { SendExternalTipsComponent } from "./send-external-tips/send-external-tips.component";

export const sidebarExternalTipsRoutes: Route = {
  path: "external-tips",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: CreateExternalTipsComponent,
      canActivate: [ExternalTipsCreateGuard],
    },
    {
      path: ":id/edit",
      component: EditExternalTipsComponent,
      canActivate: [ExternalTipsEditGuard, EditExternalTipGuard],
    },
    {
      path: "send",
      component: SendExternalTipsComponent,
      canActivate: [SendExternalTipsGuard],
    },
    {
      path: "search",
      component: SendExternalTipsHomeComponent,
      canActivate: [SendExternalTipsGuard],
    },
  ],
};
