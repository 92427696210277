import { ContactObjectConnection } from "@app/models/contact-object-connection";
import { ContactProfile } from "@app/models/contact-profile";
import { Employee } from "@app/models/employee";
import { RelationShipTypes } from "@app/shared/modules/util/relationship-type.service";

export class Contact {
  contactId: string;
  contactOrgNumber?: string;
  firstName: string;
  fullName: string;
  familyName: string;
  email: string;
  msisdn: string;
  phoneNumber: string;
  street: string;
  zip: string;
  city: string;
  msisdnCountry: string;
  phoneNumberCountry: string;
  created: string;
  updated: string;
  origin: string;
  externalId?: string;
  eaEmployeeId?: string;
  employees?: Employee[];
  sex?: string;
  maritalStatus?: string;
  dateOfBirth?: string;
  contactType?: contactType;
  preferredLanguage: string;
  profile?: ContactProfile;
  contactObjectConnections: ContactObjectConnection[];

  eaOfficeId?: string;
  active?: boolean;
  eaOid?: string;
  type?: string;
  externalIdSource?: string;
  activeConsentTypeId?: string;
  activeConsentTypeAlias?: string;
  deactivateConsentTypeId?: string;
  deactivateConsentTypeAlias?: string;
  consentValidFrom?: string;
  consentValidTo?: string;
  consentSourceId?: string;
  consentSourceType?: string;
  consentUserAgreementVersion?: string;
  consentNote?: string;
  communicationLevel?: number;
  surveyResponses?: SurveyResponse[];
  contactRelationships?: ContactRelationship[];
  object?: {
    eaOid?: string;
    mappingOrigin?: string;
    mappingInsertedDate?: string;
  };

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getFullName(): string {
    if (this.firstName && this.familyName) {
      return `${this.firstName} ${this.familyName}`;
    } else if (this.firstName) {
      return this.firstName;
    } else if (this.familyName) {
      return this.familyName;
    } else {
      return "";
    }
  }

  getAddress(): string {
    if (this.street && this.zip && this.city) {
      return `${this.street}, ${this.zip} ${this.city}`;
    } else if (this.street && this.zip) {
      return `${this.street}, ${this.zip}`;
    } else if (this.street && this.city) {
      return `${this.street}, ${this.city}`;
    } else if (this.zip && this.city) {
      return `${this.zip}, ${this.city}`;
    } else if (this.street) {
      return this.street;
    } else if (this.zip) {
      return this.zip;
    } else if (this.city) {
      return this.city;
    } else {
      return "";
    }
  }

  hasFirstName() {
    return !!this.firstName;
  }

  hasFamilyName() {
    return !!this.familyName;
  }

  hasEmail() {
    return !!this.email;
  }

  hasMobile() {
    return !!this.msisdn;
  }

  hasAddress() {
    return !!(this.street && this.zip && this.city);
  }

  getIcon(): string {
    if (this.contactType === "company") {
      return "building";
    } else if (this.contactType === "estate") {
      return "house-night";
    } else {
      return "user";
    }
  }

  getIconClass(): string {
    return `contact-icon--${this.contactType}`;
  }
}

export interface ContactRelationship {
  eaRelationshipId: string;
  familyName: string;
  firstName: string;
  insertedDate: string;
  relatedToContactId: string;
  relationshipSource: string;
  relationshipType: RelationShipTypes;
  updatedDate: string;
  relatedToContactType?: "company" | "person" | "estate";
}

export const getAddress = (street, zip, city) => {
  if (street && zip && city) {
    return `${street}, ${zip} ${city}`;
  } else if (street && zip) {
    return `${street}, ${zip}`;
  } else if (street && city) {
    return `${street}, ${city}`;
  } else if (zip && city) {
    return `${zip}, ${city}`;
  } else if (street) {
    return street;
  } else if (zip) {
    return zip;
  } else if (city) {
    return city;
  } else {
    return "";
  }
};

export const getFullName = (contact: Contact): string => {
  if (contact.firstName && contact.familyName) {
    return `${contact.firstName} ${contact.familyName}`;
  } else if (contact.firstName) {
    return contact.firstName;
  } else if (contact.familyName) {
    return contact.familyName;
  } else {
    return "";
  }
};

export type contactType = "company" | "person" | "estate";

export interface SurveyResponse {
  eaCrmSurveyResponseId: string;
  eaCrmSurveyId: string;
  responseId: string;
  surveyProvider: string;
  surveyName: string;
  npsType: string;
  npsQuestionId: string;
  eaEmployeeId: string;
  eaOfficeId: string;
  officeName: string;
  employeeFullName: string;
  responseUrl: string;
  responseDate: string;
  eaOid: string;
  objectZip: string;
  objectStreet: string;
  objectCity: string;
  npsValue: string;
  isActive: true;
}
