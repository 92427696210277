import { Component, Input, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { SendPotentialBuyersService } from "@app/integrations/bidding/services/send-potential-buyers.service";
import { Contact } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ContactCardAction } from "@app/shared/config/models/contact-card-actions";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import * as featureTypes from "@app/shared/config/utils/features";
import {
  CREATE_PRIVATE_SHOWING,
  EXTERNAL_PROVIDER,
  EXTERNAL_TIPS,
} from "@app/shared/config/utils/features";
import { CreateNoteModalComponent } from "@app/shared/modules/contact-components/create-note-modal/create-note-modal.component";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromUser from "@app/shared/user";
import { isManagerOrAdmin } from "@app/shared/user";
import {
  ROOT_SIDEBAR_ROUTE,
  SIDEBAR_CREATE_LEAD_CREATE_URL,
  SIDEBAR_CREATE_OBJECT_BASE_URL,
  SIDEBAR_FOLLOW_UPS_CREATE_URL,
  SIDEBAR_SALES_MEETING_CREATE_URL,
  SIDEBAR_SEND_EXTERNAL_TIPS_URL,
  SIDEBAR_SEND_MESSAGE_URL,
  SIDEBAR_TIPS_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_LEAD, CREATE_OBJECT } from "@app/shared/utils/tab-types";
import * as sendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import { MessageType } from "@app/sidebar/send-message/ngrx/send-message.reducer";
import { AddContactAsPotentialbuyerModalComponent } from "@app/sidebar/shared/add-contact-as-potentialbuyer-modal/add-contact-as-potentialbuyer-modal.component";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { select, Store } from "@ngrx/store";
import { combineLatest, map, Observable } from "rxjs";
import { hasIntegration } from "@app/integrations/ngrx/integrations.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";
import * as fromSharedReducer from "@app/shared/ngrx/shared.reducer";
import { FeatureConfigState } from "@app/shared/config/models";

@Component({
  selector: "app-contact-quick-actions",
  templateUrl: "./contact-quick-actions.component.html",
  styleUrls: ["./contact-quick-actions.component.scss"],
})
export class ContactQuickActionsComponent implements OnInit {
  @Input() contact: Contact;
  @Input() dialogRef?: MatDialogRef<any>;

  features$: Observable<FeatureConfigState>;
  buttons$: Observable<ContactCardAction[]>;
  createObjectFeatureEnabled$: Observable<boolean>;
  isManagerOrAdmin$: Observable<boolean>;
  eaOfficeId$: Observable<string>;
  showCreateShowing$: Observable<boolean>;
  externalProviderName$: Observable<string>;
  isMobile$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private contactService: ContactService,
    private modalService: QModalService,
    public sendPotentialBuyerService: SendPotentialBuyersService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.features$ = this.store.pipe(select(fromConfig.getFeatures));
    this.isMobile$ = this.store.pipe(select(fromSharedReducer.isMobile));
    this.isManagerOrAdmin$ = this.store.pipe(select(isManagerOrAdmin));
    this.createObjectFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(CREATE_OBJECT)),
      map((it) => !!it.enabled)
    );
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.buttons$ = combineLatest([
      this.store.pipe(select(fromConfig.getContactCardActions)),
      this.features$,
      this.isManagerOrAdmin$,
    ]).pipe(
      map(([actions, features, isManagerOrAdmin]) => {
        return this.filterActions(actions.buttons, features, isManagerOrAdmin);
      })
    );
    this.showCreateShowing$ = combineLatest([
      this.store.pipe(select(fromConfig.getFeature(CREATE_PRIVATE_SHOWING))),
      this.store.select(
        hasIntegration(IntegrationResource.CreatePublicShowing)
      ),
    ]).pipe(
      map(
        ([feature, canCreatePublicShowing]) =>
          feature.enabled || canCreatePublicShowing
      )
    );
    this.externalProviderName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
  }

  private filterActions(
    actions: ContactCardAction[],
    features: any,
    isManagerOrAdmin: boolean
  ): ContactCardAction[] {
    return actions
      .filter((action) => {
        if (action.type === EXTERNAL_TIPS) {
          return (
            action.enabled &&
            features[action.type] &&
            features[action.type].enabled &&
            features[action.type].templateId &&
            features[action.type].taskTypeId &&
            features[action.type].taskResultId
          );
        }

        if (action.type === CREATE_LEAD) {
          return (
            action.enabled &&
            features[action.type] &&
            features[action.type].enabled &&
            isManagerOrAdmin
          );
        }

        return (
          action.enabled &&
          features[action.type] &&
          features[action.type].enabled
        );
      })
      .sort((a, b) => a.sortOrder - b.sortOrder);
  }

  getAction(type: string): Pick<ContactCardAction, "label" | "icon"> {
    switch (type) {
      case "send_tips":
        return { label: "send_tips", icon: "lightbulb" };
      case "sales_meeting":
        return { label: "book_sales_meeting", icon: "briefcase" };
      case "send_message":
        return { label: "send_message", icon: "envelope-open" };
      case "external_tips":
        return { label: "send_external_tips", icon: "lightbulb-on" };
      case "create_follow_up":
        return { label: "create_follow_up", icon: "check" };
      case "create_lead":
        return { label: "create_lead", icon: "street-view" };
      case "create_object":
        return { label: "create_object", icon: "home-lg-alt" };
      case "send_tips_eika":
        return { label: "send_tips_eika", icon: "university" };
      default:
        return { label: "unknown", icon: "question" };
    }
  }

  navigate(type: string): void {
    switch (type) {
      case featureTypes.SALES_MEETING:
        this.openSalesMeeting();
        break;
      case featureTypes.SEND_MESSAGE:
        this.showSendMessage();
        break;
      case featureTypes.SEND_TIPS:
        this.showSendTips();
        break;
      case featureTypes.CREATE_FOLLOW_UP:
        this.createFollowUp();
        break;
      case featureTypes.EXTERNAL_TIPS:
        this.showSendExternalTips();
        break;
      case featureTypes.SEND_TIPS_EIKA:
        this.showSendTipsToEika();
        break;
      case CREATE_LEAD:
        this.showCreateLead();
        break;
      case CREATE_OBJECT:
        this.showCreateObject();
        break;
    }
    this.dialogRef?.close();
  }

  showSendTips(): void {
    const navigationAction = RouterActions.go({
      path: [
        "/crm/",
        {
          outlets: { sidebar: SIDEBAR_TIPS_CREATE_URL(this.contact.contactId) },
        },
      ],
    });
    this.store.dispatch(navigationAction);
  }

  showSendMessage(messageType?: MessageType): void {
    const navigationAction = RouterActions.go({
      path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }],
    });
    this.store.dispatch(
      sendMessageActions.addContacts({ recipients: [this.contact] })
    );
    if (!!messageType) {
      this.store.dispatch(sendMessageActions.setMessageType({ messageType }));
    }
    this.store.dispatch(navigationAction);
    this.dialogRef?.close();
  }

  createFollowUp(): void {
    const navigationAction = RouterActions.go({
      path: [
        "/crm",
        {
          outlets: {
            sidebar: SIDEBAR_FOLLOW_UPS_CREATE_URL(this.contact.contactId),
          },
        },
      ],
    });
    this.store.dispatch(navigationAction);
  }

  openSalesMeeting(): void {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_SALES_MEETING_CREATE_URL(this.contact.contactId),
            },
          },
        ],
      })
    );
  }

  showSendExternalTips(): void {
    const navigationAction = RouterActions.go({
      path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_EXTERNAL_TIPS_URL } }],
    });
    this.store.dispatch(navigationAction);
  }

  showSendTipsToEika(): void {
    const navigationAction = RouterActions.go({
      path: [
        "/crm/",
        {
          outlets: {
            sidebar: [ROOT_SIDEBAR_ROUTE, "eika", this.contact.contactId],
          },
        },
      ],
    });
    this.store.dispatch(navigationAction);
  }

  showCreateLead(): void {
    const navigationAction = RouterActions.go({
      path: [
        "/crm/",
        {
          outlets: {
            sidebar: SIDEBAR_CREATE_LEAD_CREATE_URL(this.contact.contactId),
          },
        },
      ],
    });
    this.store.dispatch(navigationAction);
  }

  showCreateObject(): void {
    const navigationAction = RouterActions.go({
      path: ["/crm/", { outlets: { sidebar: SIDEBAR_CREATE_OBJECT_BASE_URL } }],
    });
    this.store.dispatch(navigationAction);
  }

  registerSalesCall() {
    this.contactService
      .registerSalesCall(this.contact.contactId, {
        code: ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
      })
      .subscribe();
  }

  openNoteModal() {
    this.modalService.show(CreateNoteModalComponent, {
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: false,
      },
    });
  }

  createShowing(e: MouseEvent, contactId: string) {
    e.stopPropagation();
    const data: ShowingModalData = {
      contactId: contactId,
      isEditMode: false,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };
    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }

  sendToErpAsPotentialBuyer(e: MouseEvent, contact: Contact) {
    e.stopPropagation();
    this.modalService.show(AddContactAsPotentialbuyerModalComponent, {
      data: {
        contact: contact,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }
}
