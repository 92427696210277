import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { Observable, of } from "rxjs";

export class FormElementService {
  public form: FormGroup;
  public entity: any;
  public path: string[];
  public dataOutTransformer: any;
  public dataInTransformer: any;
  public onPatchFunction: Function;

  constructor(
    private fb: FormBuilder,
    private config: {
      entity?: any;
      path: string[];
      preselectedValue?: any;
      dataOutTransformer?: any;
      dataInTransformer?: any;
      onPatchFunction?: Function;
      validator?: any;
      disabled?: boolean;
    }
  ) {
    this.entity = this.config.entity;
    this.path = this.config.path;
    this.dataOutTransformer = this.config.dataOutTransformer;
    this.dataInTransformer = this.config.dataInTransformer;
    this.onPatchFunction = this.config.onPatchFunction;
    this.buildForm(
      this.config.preselectedValue,
      this.config.validator,
      this.config.disabled
    );
  }

  getFieldName(): string {
    return this.path[this.path.length - 1];
  }

  getFormControl(): AbstractControl {
    return this.form.get(this.getFieldName());
  }

  getFormValue(): any {
    return !!this.dataOutTransformer
      ? this.dataOutTransformer(this.form.get(this.getFieldName()).value)
      : this.form.get(this.getFieldName()).value;
  }

  handleBlur(): Observable<any> {
    return of("UNKNOWN");
  }

  private getEntityValue(index: number, entity: unknown): unknown {
    if (entity) {
      if (this.path.length - 1 === index) {
        return entity[this.path[index]];
      } else {
        const newIndex = index + 1;
        return this.getEntityValue(newIndex, entity[this.path[index]]);
      }
    } else {
      return null;
    }
  }

  private buildForm(
    preselectedValue?: any,
    validator?: any,
    disabled?: boolean
  ) {
    let value = null;
    if (
      !!preselectedValue &&
      ((Array.isArray(preselectedValue) && preselectedValue.length > 0) ||
        !Array.isArray(preselectedValue))
    ) {
      value = !!this.dataInTransformer
        ? this.dataInTransformer(preselectedValue)
        : preselectedValue;

      if (Array.isArray(value)) {
        value = [value];
      }
    }

    this.form = this.fb.group({
      [this.getFieldName()]: !!validator ? [value, validator] : value,
    });

    if (!!disabled) {
      this.form.get(this.getFieldName()).disable({ emitEvent: false });
    }

    if (!!this.entity) {
      this.fillForm();
    }
  }

  private fillForm() {
    // tslint:disable-next-line: max-line-length
    const value = !!this.dataInTransformer
      ? this.dataInTransformer(this.getEntityValue(0, this.entity))
      : this.getEntityValue(0, this.entity);
    this.form.get(this.getFieldName()).setValue(value);
  }
}
