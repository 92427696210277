<form [formGroup]="form">
  <section *ngIf="!(config$ | async)">
    <div class="alert alert-warning">
      {{ "lead_config_missing" | translate }}
    </div>
  </section>
  <section class="form__control">
    <label for="source" translate>source</label>
    <div>
      <input
        id="source"
        type="text"
        formControlName="source"
        [class]="
          !form.get('source').valid && form.get('source').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="source">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
  <section class="form__control">
    <label for="title" translate>title</label>
    <div>
      <input
        id="title"
        type="text"
        formControlName="title"
        [class]="
          !form.get('title').valid && form.get('title').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="title">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
  <section class="form__control">
    <label for="officeManagerExclusiveEndDate" translate
      >office_manager_exclusive_end_date</label
    >
    <select
      id="officeManagerExclusiveEndDate"
      formControlName="officeManagerExclusiveEndDate"
    >
      <option *ngFor="let hour of hours" [ngValue]="hour">
        {{ hour }} {{ "hours" | translate | lowercase }}
      </option>
    </select>
  </section>
  <section class="form__control">
    <label for="deliveryDate" translate>delivery_date</label>
    <select id="deliveryDate" formControlName="deliveryDate">
      <option *ngFor="let hour of hours" [ngValue]="hour">
        {{ hour }} {{ "hours" | translate | lowercase }}
      </option>
    </select>
  </section>
  <section class="form__control">
    <label for="expirationDate" translate>lead_expiration_date</label>
    <select id="expirationDate" formControlName="expirationDate">
      <option *ngFor="let hour of hours" [ngValue]="hour">
        {{ hour }} {{ "hours" | translate | lowercase }}
      </option>
    </select>
  </section>
  <section class="form__control">
    <mat-slide-toggle
      labelPosition="before"
      color="primary"
      formControlName="useCustomerOfficeHours"
    >
      {{ "use_customer_office_hours" | translate }}
    </mat-slide-toggle>
  </section>
  <section class="form__control">
    <label for="officeRule" translate>lead_office_rule</label>
    <select id="officeRule" formControlName="officeRule">
      <option *ngFor="let rule of officeRules" [ngValue]="rule.value">
        {{ rule.label | translate }}
      </option>
    </select>
  </section>
  <section class="form__control">
    <label for="employeeRule" translate>lead_employee_rule</label>
    <select id="employeeRule" formControlName="employeeRule">
      <option *ngFor="let rule of employeeRules" [ngValue]="rule.value">
        {{ rule.label | translate }}
      </option>
    </select>
  </section>
</form>
