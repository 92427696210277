<div class="widget-header">
  <h3>
    <app-icon size="large" name="building"></app-icon>&nbsp;<span>{{
      "company_details" | translate
    }}</span>
  </h3>
  <app-q-circle-btn
    icon="pen"
    tooltipText="edit_contact"
    (click)="editContact()"
  ></app-q-circle-btn>
</div>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div *ngIf="contact$ | async as contact" class="overview-data">
    <app-widget-field label="org_number">
      <span *ngIf="contact.contactOrgNumber">
        {{ contact.contactOrgNumber }}
      </span>
      <a
        *ngIf="!contact.contactOrgNumber"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          {
            outlets: {
              sidebar: ['root', 'contacts', contact.contactId, 'edit']
            }
          }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>

    <app-widget-field label="address">
      <a
        *ngIf="contact.getAddress()"
        target="_blank"
        [tooltip]="'open_in_google_maps' | translate"
        [href]="getGoogleMapsUrl(contact.getAddress())"
        rel="noopener noreferrer"
      >
        {{ contact.getAddress() }}
      </a>
      <a
        *ngIf="!contact.getAddress()"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          {
            outlets: {
              sidebar: ['root', 'contacts', contact.contactId, 'edit']
            }
          }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>

    <app-widget-field label="email">
      <a *ngIf="contact.email" href="mailto:{{ contact.email }}" target="_top">
        <span tooltip="{{ 'open_mail_client' | translate }}" placement="bottom">
          {{ contact.email }}
        </span>
      </a>
      <a
        *ngIf="!contact.email"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          {
            outlets: {
              sidebar: ['root', 'contacts', contact.contactId, 'edit']
            }
          }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
    <app-widget-field label="phone">
      <a
        *ngIf="contact.msisdn"
        href="tel:+{{ contact.msisdn }}"
        [tooltip]="getPhoneNumberTooltip(contact, false)"
      >
        <i
          *ngIf="contact.msisdnCountry"
          class="flag-icon flag-icon-{{ contact.msisdnCountry | lowercase }}"
        ></i>
        {{ contact.msisdn | phoneFormat : contact.msisdnCountry }} (<span
          translate
          >phone</span
        >)
      </a>
      <a
        *ngIf="!contact.msisdn"
        class="hidden-xs"
        [routerLink]="[
          '/crm',
          {
            outlets: {
              sidebar: ['root', 'contacts', contact.contactId, 'edit']
            }
          }
        ]"
      >
        <span class="muted" translate>add</span>
      </a>
    </app-widget-field>
  </div>
</div>
