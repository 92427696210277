import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { removeEntityId } from "@app/core/components/multi-tasker/ngrx/multi-tasker.actions";
import {
  getMultiTaskerEntities,
  MultiTaskerEntity,
} from "@app/core/components/multi-tasker/ngrx/multi-tasker.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import { getSubPages } from "@app/shared/config/config.reducer";
import { SubPageConfig } from "@app/shared/config/models";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { CONTACTS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { select, Store } from "@ngrx/store";
import { filter, first, map, Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-multi-tasker",
  templateUrl: "./multi-tasker.component.html",
  styleUrls: ["./multi-tasker.component.scss"],
})
export class MultiTaskerComponent implements OnInit, OnDestroy {
  entities$: Observable<MultiTaskerEntity[]>;
  isMobile$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();
  contactSubPages$: Observable<any[]>;
  showFullHistory = false;
  isEnable = true;
  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    private contactService: ContactService,
    public authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((event: NavigationEnd) => {
        this.isEnable = !event.url.includes("external-widget");
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  removeEntity(id: string) {
    this.store.dispatch(removeEntityId({ id }));
  }

  navigateToEntity(entity: MultiTaskerEntity) {
    const type = entity.entityType === "contact" ? "contacts" : "showings";
    this.router.navigate([
      "/crm",
      {
        outlets: {
          primary: [type, entity.id],
          sidebar: null,
          modal: [],
        },
      },
    ]);
    this.showFullHistory = false;
  }

  private mapStateToProps() {
    this.contactSubPages$ = this.store.pipe(
      select(getSubPages(CONTACTS_CRM_MODULE)),
      first(),
      map((subPages: SubPageConfig[]) => subPages.filter((p) => p.enabled))
    );
    this.isMobile$ = this.store.pipe(select(isMobile));
    this.entities$ = this.store.pipe(
      select(getMultiTaskerEntities),
      map((entities) =>
        entities
          .map((entity: MultiTaskerEntity) => {
            if (entity.entityType === "contact") {
              return {
                ...entity,
                data: this.contactService.entityMap$.pipe(
                  map((em) => em[entity.id]),
                  filter((item) => !!item)
                ),
              };
            } else if (entity.entityType === "object") {
              return {
                ...entity,
                data: this.objectService.entityMap$.pipe(
                  map((em) => em[entity.id]),
                  filter((item) => !!item)
                ),
              };
            }
          })
          .reverse()
      )
    );
  }
}
