import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { checkIfSystemOnlineRequest } from "@app/shared/config/config.actions";
import { getProxyVerified } from "@app/shared/config/config.reducer";
import { select, Store } from "@ngrx/store";
import { filter, first } from "rxjs";

@Component({
  selector: "app-unknown-origin",
  templateUrl: "./unknown-origin.component.html",
  styleUrls: ["./unknown-origin.component.scss"],
})
export class UnknownOriginComponent implements OnInit {
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    const systemCheckInterval = setInterval(
      () => this.checkIfSystemOnline(this.store),
      5000
    );
    this.store
      .pipe(
        select(getProxyVerified),
        filter((verified) => !!verified),
        first()
      )
      .subscribe(() => clearInterval(systemCheckInterval));
  }

  checkIfSystemOnline(store): void {
    store.dispatch(checkIfSystemOnlineRequest());
  }
}
