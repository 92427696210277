import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/sidebar/lead-channels/ngrx/lead-channels-form.actions";
import { TaskType } from "@app/models";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface LeadChannelsFormState {
  data: TaskType;
  isLoading: boolean;
}

const initialState: LeadChannelsFormState = {
  data: null,
  isLoading: false,
};

export const leadChannelsFormReducer = createReducer(
  initialState,
  on(actions.getLeadChannelRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadChannelSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: true,
  })),
  on(actions.getLeadChannelFail, (state) => ({
    ...state,
    isLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
export const selectLeadChannelsForm = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.leadChannelsForm
);

export const selectData = createSelector(
  selectLeadChannelsForm,
  (state: LeadChannelsFormState) => state.data
);
export const selectIsLoading = createSelector(
  selectLeadChannelsForm,
  (state: LeadChannelsFormState) => state.isLoading
);
