import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  exhaustMap,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as leadChannelsActions from "@app/settings/lead-channels/ngrx/lead-channels.actions";
import { LEAD_CHANNELS } from "@app/shared/utils/tab-types";
import * as actions from "@app/sidebar/lead-channels/ngrx/lead-channels-form.actions";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { TaskType } from "@app/models";

@Injectable()
export class LeadChannelsFormEffects {
  getLeadChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getLeadChannelRequest),
      switchMap(({ id }) =>
        this.apiService.get(`task-types/${id}`).pipe(
          map((response: TaskType) =>
            actions.getLeadChannelSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.getLeadChannelFail()))
        )
      )
    )
  );

  createLeadChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadChannelRequest),
      exhaustMap(({ body }) =>
        this.apiService.post("task-types", body).pipe(
          map((response: TaskType) =>
            actions.createLeadChannelSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.createLeadChannelFail()))
        )
      )
    )
  );

  createLeadChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadChannelSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          this.getLeadChannels(),
          toastActions.success({ message: "create_lead_channel_success" }),
        ])
      )
    )
  );

  createLeadChannelFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createLeadChannelFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          toastActions.danger({ message: "create_lead_channel_fail" }),
        ])
      )
    )
  );

  editLeadChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadChannelRequest),
      exhaustMap(({ id, body }) =>
        this.apiService.patch(`task-types/${id}`, body).pipe(
          map((response: TaskType) =>
            actions.editLeadChannelSuccess({ data: response })
          ),
          catchError(() => observableOf(actions.editLeadChannelFail()))
        )
      )
    )
  );

  editLeadChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadChannelSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          this.getLeadChannels(),
          toastActions.success({ message: "edit_lead_channel_success" }),
        ])
      )
    )
  );

  editLeadChannelFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.editLeadChannelFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          toastActions.danger({ message: "edit_lead_channel_fail" }),
        ])
      )
    )
  );

  deleteLeadChannelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadChannelRequest),
      exhaustMap(({ id }) =>
        this.apiService.delete(`task-types/${id}`).pipe(
          map(() => actions.deleteLeadChannelSuccess()),
          catchError(() => observableOf(actions.deleteLeadChannelFail()))
        )
      )
    )
  );

  deleteLeadChannelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadChannelSuccess),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          this.getLeadChannels(),
          toastActions.success({ message: "delete_lead_channel_success" }),
        ])
      )
    )
  );

  deleteLeadChannelFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteLeadChannelFail),
      switchMap(() =>
        observableFrom([
          sidebarActions.closeTab({ tabType: LEAD_CHANNELS }),
          toastActions.danger({ message: "delete_lead_channel_fail" }),
        ])
      )
    )
  );

  getLeadChannels() {
    const params = {
      taskTypeCategory: "lead",
      direction: "incoming,twoway",
    };
    return leadChannelsActions.getLeadChannelsRequest({ params });
  }

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
