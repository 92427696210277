import { PaginationListDTO, SalesMeeting, TaskType } from "@app/models";
import * as salesMeetingActions from "./lists-sales-meetings.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsSalesMeetingsState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  rows: SalesMeeting[];
  allContactIds: string[];
  taskTypes: TaskType[];
}

export const initialState: ListsSalesMeetingsState = {
  loaded: false,
  loading: false,
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  allContactIds: [],
  taskTypes: [],
};

export function listsSalesMeetingsReducer(
  state: ListsSalesMeetingsState,
  action: Action
): ListsSalesMeetingsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(salesMeetingActions.getSalesMeetingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    salesMeetingActions.getSalesMeetingsSuccess,
    (state, { salesMeetings }) => ({
      ...state,
      loading: false,
      loaded: true,
      ...salesMeetings,
    })
  ),
  on(salesMeetingActions.getSalesMeetingsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    rows: initialState.rows,
  })),
  on(salesMeetingActions.getMoreSalesMeetingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    salesMeetingActions.getMoreSalesMeetingsSuccess,
    (state, { salesMeetings }) => {
      const { limit, offset, total, rows } = salesMeetings;
      return {
        ...state,
        loading: false,
        limit: state.limit + limit,
        offset,
        total,
        rows: [...state.rows, ...rows],
      };
    }
  ),
  on(salesMeetingActions.getMoreSalesMeetingsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
  })),
  on(
    salesMeetingActions.getAllSalesMeetingsContactIdsSuccess,
    (state, { contactIds }) => ({
      ...state,
      allContactIds: contactIds
        .filter((contactId, index) => contactIds.indexOf(contactId) === index)
        .filter((value) => !!value),
    })
  ),
  on(salesMeetingActions.getTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    taskTypes,
  })),
  on(salesMeetingActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.salesMeetings
);

export const getTasksTypes = createSelector(
  getState,
  (state: ListsSalesMeetingsState) => state.taskTypes
);
