import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as standardModalActions from "@app/core/components/standard-modal/ngrx/standard-modal.actions";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Office } from "@app/models";
import { CustomFilter } from "@app/models/custom-filter";
import * as fromConfig from "@app/shared/config/config.reducer";
import { DrawSearchProfileAreaFeature } from "@app/shared/config/models/draw-search-profile-area";
import { DRAW_SEARCH_PROFILE_AREA } from "@app/shared/config/utils/features";
import * as fromUser from "@app/shared/user";
import { DrawMapModalService } from "@app/sidebar/search-profile/search-profile-form/draw-map-modal.service";
import * as areaTypes from "@app/sidebar/search-profile/utils/area-types";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BsModalService, ModalDirective } from "ngx-bootstrap/modal";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

declare var google: any;

@Component({
  selector: "draw-map-modal",
  templateUrl: "./draw-map-modal.component.html",
  styleUrls: ["./draw-map-modal.component.scss"],
})
export class DrawMapModalComponent implements OnInit, OnDestroy {
  @ViewChild("modal", { static: false })
  modal: ModalDirective;
  @Input() municipalityId: number | null;
  @Input() countyId: number | null;

  unsubscribe$: Subject<void> = new Subject<void>();
  mapRef: any;
  office$: Observable<Office>;
  map: any;
  mapReady$ = new BehaviorSubject(false);
  currentOfficeLat: string;
  currentOfficeLng: string;
  polygonsToBeSaved = [];
  drawingManager: any;
  address: string;
  title = "";
  areaToShow: any;
  polygon: any;
  storingPolygon = false;
  saveAsPermanent = false;
  feature: string;
  showMode: "Show" | "Create" | "Filter" = "Create";
  // Data to be saved
  countryIso: string;
  eaGeoCustomFilterPersonalPreferenceCategoryId: number;
  eaGeoCustomFilterAreaCategoryId: number;
  geoCustomFilterParent: number;
  mapReady = false;

  constructor(
    private store: Store<AppState>,
    private apiService: ApiService,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private mapModalService: DrawMapModalService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();

    this.dataStream();

    if (this.getFeature() === "settings") {
      this.saveAsPermanent = true;
      this.feature = "settings";
    } else {
      this.feature = "search-profile";
    }
  }

  private mapStateToProps(): void {
    this.office$ = this.store.pipe(select(fromUser.getOffice));
  }

  private dataStream() {
    this.office$.subscribe((o: Office) => {
      this.address = `${o.street} ${o.zip} ${o.city}`;
      this.currentOfficeLat = o.latitude;
      this.currentOfficeLng = o.longitude;
    });

    this.store
      .pipe(select(fromConfig.getFeature(DRAW_SEARCH_PROFILE_AREA)))
      .subscribe((response: DrawSearchProfileAreaFeature) => {
        this.eaGeoCustomFilterPersonalPreferenceCategoryId =
          response.eaGeoCustomFilterCategoryId;
        this.eaGeoCustomFilterAreaCategoryId =
          response.eaGeoCustomFilterAreaCategoryId;
        this.geoCustomFilterParent = response.custom_filter_root;
        this.countryIso = response.countryIso;
      });

    this.modalService.onHidden
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.reset());
    this.modalService.onShown
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.onModalShown());

    this.mapReady$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((ready: boolean) => {
        if (ready) {
          this.mapReady = true;
          this.showOfficeOnMap();
          this.setDrawManager();
        }
      });

    combineLatest([
      this.mapReady$.pipe(filter((ready) => !!ready)),
      this.mapModalService.showArea$.pipe(filter((area) => !!area)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([, area]) => {
        this.showArea(area);
      });

    this.mapModalService.showMode$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((showMode) => (this.showMode = showMode));
  }

  showArea(area) {
    this.areaToShow = area;

    this.apiService
      .get("custom-filters/" + area.id, {})
      .subscribe((response: CustomFilter) => {
        const coordinates = response.customFilterPolygon.map((c) => {
          return { lat: parseFloat(c.lat), lng: parseFloat(c.lng) };
        });

        const bounds = new google.maps.LatLngBounds();

        const polygonCoords = [];
        coordinates.forEach((c) => {
          polygonCoords.push(new google.maps.LatLng(c.lat, c.lng));
        });

        for (let i = 0; i < polygonCoords.length; i++) {
          bounds.extend(polygonCoords[i]);
        }

        const centerMap = new google.maps.LatLng(
          bounds.getCenter().lat(),
          bounds.getCenter().lng()
        );

        // Construct the polygon.
        const polygon = new google.maps.Polygon({
          paths: coordinates,
        });

        this.map = new google.maps.Map(
          document.getElementById("map-container"),
          {
            zoom: 14,
            center: centerMap,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
          }
        );

        polygon.setMap(this.map);
        this.map.fitBounds(bounds);
      });
  }

  showOfficeOnMap() {
    const centerMap = new google.maps.LatLng(
      Number(this.currentOfficeLat),
      Number(this.currentOfficeLng)
    );
    this.showMap(centerMap);
  }

  showMap(centerMap) {
    this.map = new google.maps.Map(document.getElementById("map-container"), {
      zoom: 14,
      center: centerMap,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    });
  }

  onModalShown() {
    this.polygonsToBeSaved = [];
  }

  setDrawManager() {
    this.drawingManager = new google.maps.drawing.DrawingManager(
      this.getDrawManagerOptions()
    );
    this.drawingManager.setMap(this.map);
    google.maps.event.addListener(
      this.drawingManager,
      "polygoncomplete",
      (polygon) => this.onPolygonComplete(polygon)
    );
  }

  getDrawManagerOptions() {
    return {
      drawingMode: google.maps.drawing.OverlayType.POLYGON,
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.POLYGON],
      },
      polygonOptions: {
        fillColor: "#000000",
        fillOpacity: 0.3,
        strokeWeight: 5,
        clickable: false,
        draggable: false,
        editable: false,
        zIndex: 1,
      },
    };
  }

  onPolygonComplete(polygon) {
    this.drawingManager.setDrawingMode(null);

    const customFilterPolygon = this.buildCustomFilterPolygon(polygon);
    if (customFilterPolygon.split(",").length > 2) {
      this.polygonsToBeSaved.push(customFilterPolygon);
    }
  }

  clearPolygons() {
    this.polygonsToBeSaved = [];
    this.polygon.setMap(null);
    this.mapModalService.drawnArea$.next(null);
  }

  buildCustomFilterPolygon(polygon) {
    this.polygon = polygon;
    const polygonBounds = polygon.getPath();
    const customFilterPolygon = [];
    let firstCoordinate;
    if (polygonBounds.length > 2) {
      for (let i = 0; i < polygonBounds.length; i++) {
        customFilterPolygon.push(
          polygonBounds.getAt(i).lat() + " " + polygonBounds.getAt(i).lng()
        );
        if (i === 0) {
          firstCoordinate =
            polygonBounds.getAt(i).lat() + " " + polygonBounds.getAt(i).lng();
        }
      }
      customFilterPolygon.push(firstCoordinate);
    }
    return customFilterPolygon.join(",");
  }

  onSubmit() {
    if (this.mapModalService.showMode$.value === "Create") {
      if (this.saveAsPermanent) {
        this.store.dispatch(
          confirmActions.show({
            header: "add_permanent_area_header",
            message: "add_permanent_area_message",
          })
        );
        this.store
          .pipe(
            select(confirmState),
            filter((value) => !!value),
            first()
          )
          .subscribe(() => {
            this.doSave();
          });
      } else {
        this.doSave();
      }
    } else {
      this.mapModalService.drawnArea$.next(this.polygonsToBeSaved[0]);
      this.hide();
    }
  }

  doSave() {
    this.storingPolygon = true;
    const body = {
      customFilterPolygon: this.polygonsToBeSaved[0],
      countryIso: this.countryIso,
      customFilterName: this.title
        ? this.title
        : this.translateService.instant("drawn_area"),
      eaGeoCustomFilterCategoryId: this.saveAsPermanent
        ? this.eaGeoCustomFilterAreaCategoryId
        : this.eaGeoCustomFilterPersonalPreferenceCategoryId,
      geoCustomFilterParent: this.geoCustomFilterParent,
      isApproved: !this.saveAsPermanent,
    };

    if (
      this.getFeature() === "settings" &&
      this.municipalityId &&
      this.countyId
    ) {
      body["municipalityId"] = this.municipalityId;
      body["countyId"] = this.countyId;
    }

    this.apiService.post("custom-filters", body).subscribe({
      next: (response: { id: string; name: string }) => {
        if (response && response.id) {
          this.mapModalService.areaSaved$.next({
            id: response.id,
            title: response.name,
            type: areaTypes.CUSTOM_FILTER,
          });
        }
        this.hide();
        this.polygonsToBeSaved = [];
        this.polygon = null;
        this.storingPolygon = false;
      },
      error: () => {
        this.store.dispatch(
          toastActions.danger({
            message: "map_area_create_failed",
          })
        );
        this.storingPolygon = false;
      },
    });
  }

  hide() {
    this.modalService.hide();
    this.store.dispatch(standardModalActions.close());
    this.close();
  }

  getFeature() {
    let feature = "search-profile";
    if (location.href.includes("settings")) {
      feature = "settings";
    }
    return feature;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onMapReady(map: any) {
    this.mapRef = map;
    this.mapReady$.next(true);
  }

  close(): void {
    this.store.dispatch(standardModalActions.close());
  }

  reset(): void {
    this.areaToShow = null;
  }
}
