import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { ApiService } from "@app/core/services/api/api.service";
import { catchError, map, mergeMap, Observable, of, switchMap } from "rxjs";

import * as actions from "./shortcuts-widget.actions";
import { SettingGroup } from "@app/shared/config/models/setting";
import { ShortcutsSetting } from "@app/shared/config/models/shortcuts-feature";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";

@Injectable()
export class ShortcutsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  getShortcutsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getShortcutsRequest),
      switchMap(() =>
        this.apiService
          .get(`settings/values`, {
            filterSettingName: "shortcuts",
          })
          .pipe(
            map((response: SettingGroupResponse) => {
              if (!response || response.settingGroups.length === 0) {
                return {};
              }

              const settingGroup = response.settingGroups[0];
              const settings = JSON.parse(
                settingGroup.settings[0].settingValue
              );

              return {
                settingGroupId: settingGroup.settingGroupId,
                settingId: settingGroup.settings[0].settingId,
                settingValueId: settingGroup.settings[0].settingValueId,
                predefinedShortcuts: settings.predefinedShortcuts,
                filters: settings.filters,
              } as ShortcutsSetting;
            }),
            map((data: ShortcutsSetting) =>
              actions.getShortcutsSuccess({ data })
            ),
            catchError(() => of(actions.getShortcutsFail()))
          )
      )
    )
  );

  updateShortcutsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateShortcutsRequest),
      switchMap(({ settingGroupId, settingId, settingValue }) =>
        this.apiService
          .patch(`settings/${settingGroupId}/${settingId}`, {
            settingValue,
          })
          .pipe(
            map(() => actions.updateShortcutsSuccess()),
            catchError(() => of(actions.updateShortcutsFail()))
          )
      )
    )
  );

  updateShortcutsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateShortcutsSuccess),
      mergeMap(() => {
        return [
          toastActions.success({ message: "setting_update_success" }),
          actions.getShortcutsRequest(),
        ];
      })
    )
  );

  updateShortcutsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.updateShortcutsFail),
      map(() => toastActions.danger({ message: "setting_update_failed" }))
    )
  );

  createShortcutsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createShortcutsSuccess),
      mergeMap(() => {
        return [
          toastActions.success({ message: "setting_create_success" }),
          actions.getShortcutsRequest(),
        ];
      })
    )
  );

  createShortcutsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createShortcutsFail),
      map(() => toastActions.danger({ message: "setting_create_failed" }))
    )
  );

  /*
   create a SettingGroup Record and get SettingGroupId
   create a Setting Record and get SettingId & SettingGroup-Setting Relation Record
   Create a Setting Value Record
   */
  createShortcutsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.createShortcutsRequest),
      switchMap(({ settingValue }) =>
        this.createSettingGroup$().pipe(
          switchMap((settingGroupId) => this.createSetting$(settingGroupId)),
          switchMap(({ settingGroupId, settingId, status }) => {
            this.verifyApiResponse({ status } as CreateSettingResponse);
            return this.apiService.post(
              `settings/${settingGroupId}/${settingId}`,
              { settingValue }
            );
          }),
          map(() => actions.createShortcutsSuccess()),
          catchError(() => of(actions.createShortcutsFail()))
        )
      )
    )
  );

  private verifyApiResponse(response: CreateSettingResponse) {
    if (response.status !== "OK") {
      throw new Error(response.status);
    }
    return response;
  }

  private createSettingGroup$(): Observable<string> {
    return this.apiService
      .post(`settings/setting-group`, {
        settingGroupName: "shortcuts",
        settingGroupDescription: "shortcuts group",
        status: "active",
      })
      .pipe(
        map((response: CreateSettingResponse) => {
          this.verifyApiResponse(response);
          return response.id;
        })
      );
  }

  private createSetting$(settingGroupId: string) {
    return this.apiService
      .post(`settings/setting`, {
        settingName: "shortcuts",
        settingDescription: "shortcuts setting",
        settingType: "varchar",
        settingSize: 5000,
        status: "active",
        settingGroupId: settingGroupId,
        settingToGroupStatus: "active",
      })
      .pipe(
        map((response: CreateSettingResponse) => {
          this.verifyApiResponse(response);
          return {
            settingGroupId,
            settingId: response.id,
            status: response.status,
          };
        })
      );
  }
}

type SettingGroupResponse = { settingGroups: SettingGroup[] };
type CreateSettingResponse = { id: string; status: string };
