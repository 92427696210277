import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "list-header",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./list-header.component.scss",
  ],
  template: `
    <li
      [ngClass]="{
        'disable-click': !isExpandable,
        'calling-list-padding': hasCallingListBtn
      }"
      class="list__header--action"
      (click)="toggleExpanded()"
    >
      <ng-content></ng-content>
      <div class="controls flex-container">
        <ng-content select="[controls]"></ng-content>
        <spinner
          *ngIf="badgeSpinner"
          class="list-header-spinner pull-right"
          [size]="'1.25rem'"
        ></spinner>
        <span
          *ngIf="badge && !badgeSpinner"
          class="badge"
          [style.background-color]="badgeColor"
          >{{ badgeText }}</span
        >
        <span *ngIf="isExpandable">
          <i class="fa fa-long-arrow-{{ isExpanded ? 'up' : 'down' }}"></i>
        </span>
      </div>
    </li>
  `,
})
export class ListHeaderComponent {
  @Input() badge = false;
  @Input() badgeText: number;
  @Input() badgeColor: string;
  @Input() badgeSpinner = false;
  @Input() isExpanded = true;
  @Input() isExpandable = false;
  @Input() hasCallingListBtn = false;
  @Input() itemType: string;

  @Output() expandedChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  toggleExpanded() {
    if (!this.isExpandable) {
      return;
    }
    this.isExpanded = !this.isExpanded;
    this.expandedChanged.emit(this.isExpanded);
  }
}
