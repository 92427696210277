import { Action, createReducer, on } from "@ngrx/store";
import { Contact } from "../models";
import { Nps } from "@app/models/nps";
import { Survey } from "@app/models/survey";
import { Tag } from "@app/models/tags";
import * as contactActions from "./contact.actions";

export interface ContactProfileResponse {
  contactId: string;
  ownsResidence: string;
  inserted: string;
  updated: string;
}

export interface ContactState {
  loaded: boolean;
  loading: boolean;
  contact: Contact;
  ownsResidence: string;
  callListMode: boolean;
  createConsumerCallLoading: boolean;
  createNoteLoading: boolean;
  backLink: string;
  quickLink: any;
  quickLinkLabel: string;
  latestContactedDate: string;
  nps: Nps | null;
  npsLoading: boolean;
  surveys: Survey[];
  surveysLoading: boolean;
  tagSuggestions: Tag[];
  contactTags: Tag[];
  contactTagsLoading: boolean;
  tagToContactStoring: boolean;
  tagFromContactDeleting: boolean;
}

export const initialState: ContactState = {
  loaded: false,
  loading: false,
  contact: null,
  ownsResidence: "",
  callListMode: false,
  createConsumerCallLoading: false,
  createNoteLoading: false,
  backLink: "",
  quickLink: "",
  quickLinkLabel: "",
  latestContactedDate: "",
  nps: null,
  npsLoading: false,
  surveys: [],
  surveysLoading: false,
  tagSuggestions: [],
  contactTags: [],
  contactTagsLoading: false,
  tagToContactStoring: false,
  tagFromContactDeleting: false,
};

export function contactReducer(
  state: ContactState,
  action: Action
): ContactState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(contactActions.getContactRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(contactActions.getContactSuccess, (state, { contact }) => ({
    ...state,
    loading: false,
    loaded: true,
    contact,
  })),
  on(contactActions.activateCallListMode, (state) => ({
    ...state,
    callListMode: true,
  })),
  on(contactActions.deactivateCallListMode, (state) => ({
    ...state,
    callListMode: false,
  })),
  on(
    contactActions.getContactFailed,
    contactActions.getContactProfileFailed,
    (state) => ({ ...state, loading: true })
  ),
  on(contactActions.getContactProfileSuccess, (state, { ownsResidence }) => ({
    ...state,
    loading: false,
    loaded: true,
    ownsResidence,
  })),
  on(contactActions.createConsumerCallActionRequest, (state) => ({
    ...state,
    createConsumerCallLoading: true,
  })),
  on(
    contactActions.createConsumerCallActionSuccess,
    contactActions.createConsumerCallActionFailed,
    (state) => ({ ...state, createConsumerCallLoading: false })
  ),
  on(contactActions.updateContactProfileRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    contactActions.updateContactProfileSuccess,
    contactActions.updateContactProfileFailed,
    (state) => ({
      ...state,
      loading: false,
      loaded: true,
    })
  ),
  on(contactActions.createNoteRequest, (state) => ({
    ...state,
    createNoteLoading: true,
  })),
  on(
    contactActions.createNoteSuccess,
    contactActions.createNoteFail,
    (state) => ({
      ...state,
      createNoteLoading: false,
    })
  ),
  on(contactActions.addBackLink, (state, { backLink }) => ({
    ...state,
    backLink,
  })),
  on(
    contactActions.addQuickLink,
    (state, { parameters: { route, label } }) => ({
      ...state,
      quickLink: route,
      quickLinkLabel: label,
    })
  ),
  on(contactActions.reset, () => ({ ...initialState })),
  on(contactActions.getContactNpsRequest, (state) => ({
    ...state,
    npsLoading: true,
  })),
  on(contactActions.getContactNpsSuccess, (state, { nps }) => ({
    ...state,
    npsLoading: false,
    nps,
  })),
  on(contactActions.getContactNpsFailed, (state) => ({
    ...state,
    npsLoading: false,
  })),
  on(contactActions.getContactSurveysRequest, (state) => ({
    ...state,
    surveysLoading: true,
  })),
  on(contactActions.getContactSurveysSuccess, (state, { surveys }) => ({
    ...state,
    surveysLoading: false,
    surveys,
  })),
  on(contactActions.getContactSurveysFailed, (state) => ({
    ...state,
    surveysLoading: false,
  })),
  on(
    contactActions.getContactTagSuggestionsSuccess,
    (state, { tagSuggestions }) => ({
      ...state,
      tagSuggestions,
    })
  ),
  on(contactActions.getTagsForContactRequest, (state) => ({
    ...state,
    contactTagsLoading: true,
  })),
  on(contactActions.getTagsForContactSuccess, (state, { contactTags }) => ({
    ...state,
    contactTags,
    contactTagsLoading: false,
  })),
  on(contactActions.getTagsForContactFailed, (state) => ({
    ...state,
    contactTagsLoading: false,
  })),
  on(contactActions.deleteTagFromContactRequest, (state) => ({
    ...state,
    tagFromContactDeleting: true,
  })),
  on(
    contactActions.deleteTagFromContactSuccess,
    contactActions.deleteTagFromContactFailed,
    (state) => ({
      ...state,
      tagFromContactDeleting: false,
    })
  ),
  on(contactActions.storeTagToContactRequest, (state) => ({
    ...state,
    tagToContactStoring: true,
  })),
  on(
    contactActions.storeTagToContactSuccess,
    contactActions.storeTagToContactFailed,
    (state) => ({
      ...state,
      tagToContactStoring: false,
    })
  ),
  on(
    contactActions.getLatestConsumerCallActionRequest,
    contactActions.getLatestConsumerCallActionFailed,
    (state) => ({ ...state, latestContactedDate: null })
  ),
  on(
    contactActions.getLatestConsumerCallActionSuccess,
    (state, { latestContactedDate }) => ({ ...state, latestContactedDate })
  )
);
