import {
  FeeTypeOrderResponse,
  OneTimeOrder,
  RecurringItem,
} from "@app/settings/invoicing/components/office-card/ngrx/office-card.reducer";
import { createAction, props } from "@ngrx/store";
import { TypesOfNonRecurringItem } from "@app/settings/invoicing/components/types-of-non-recurring-items/ngrx/types-of-non-recurring-items.reducer";

export const getCustomerNumberRequest = createAction(
  "[OfficeCard] GET_CUSTOMER_NUMBER_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getCustomerNumberSuccess = createAction(
  "[OfficeCard] GET_CUSTOMER_NUMBER_SUCCESS",
  props<{ customerNumber: string }>()
);

export const getCustomerNumberFailed = createAction(
  "[OfficeCard] GET_CUSTOMER_NUMBER_FAILED"
);

export const storeCustomerNumberRequest = createAction(
  "[OfficeCard] STORE_CUSTOMER_NUMBER_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeCustomerNumberSuccess = createAction(
  "[OfficeCard] STORE_CUSTOMER_NUMBER_SUCCESS"
);

export const storeCustomerNumberFailed = createAction(
  "[OfficeCard] STORE_CUSTOMER_NUMBER_FAILED"
);

export const updateCustomerNumberRequest = createAction(
  "[OfficeCard] UPDATE_CUSTOMER_NUMBER_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateCustomerNumberSuccess = createAction(
  "[OfficeCard] UPDATE_CUSTOMER_NUMBER_SUCCESS"
);

export const updateCustomerNumberFailed = createAction(
  "[OfficeCard] UPDATE_CUSTOMER_NUMBER_FAILED"
);

export const getRecurringItemsRequest = createAction(
  "[OfficeCard] GET_RECURRING_ITEMS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getRecurringItemsSuccess = createAction(
  "[OfficeCard] GET_RECURRING_ITEMS_SUCCESS",
  props<{ recurringItems: RecurringItem[] }>()
);

export const getRecurringItemsFailed = createAction(
  "[OfficeCard] GET_RECURRING_ITEMS_FAILED"
);

export const storeRecurringItemsRequest = createAction(
  "[OfficeCard] STORE_RECURRING_ITEMS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeRecurringItemsSuccess = createAction(
  "[OfficeCard] STORE_RECURRING_ITEMS_SUCCESS"
);

export const storeRecurringItemsFailed = createAction(
  "[OfficeCard] STORE_RECURRING_ITEMS_FAILED"
);

export const updateRecurringItemsRequest = createAction(
  "[OfficeCard] UPDATE_RECURRING_ITEMS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateRecurringItemsSuccess = createAction(
  "[OfficeCard] UPDATE_RECURRING_ITEMS_SUCCESS"
);

export const updateRecurringItemsFailed = createAction(
  "[OfficeCard] UPDATE_RECURRING_ITEMS_FAILED"
);

export const deleteRecurringItemsRequest = createAction(
  "[OfficeCard] DELETE_RECURRING_ITEMS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const deleteRecurringItemsSuccess = createAction(
  "[OfficeCard] DELETE_RECURRING_ITEMS_SUCCESS"
);

export const deleteRecurringItemsFailed = createAction(
  "[OfficeCard] DELETE_RECURRING_ITEMS_FAILED"
);

export const getOneTimeOrdersRequest = createAction(
  "[OfficeCard] GET_ONE_TIME_ORDERS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getOneTimeOrdersSuccess = createAction(
  "[OfficeCard] GET_ONE_TIME_ORDERS_SUCCESS",
  props<{ oneTimeOrders: OneTimeOrder[] }>()
);

export const getOneTimeOrdersFailed = createAction(
  "[OfficeCard] GET_ONE_TIME_ORDERS_FAILED"
);

export const getOneTimeTypesRequest = createAction(
  "[OfficeCard] GET_ONE_TIME_TYPES_REQUEST"
);

export const getOneTimeTypesSuccess = createAction(
  "[OfficeCard] GET_ONE_TIME_TYPES_SUCCESS",
  props<{ oneTimeTypes: TypesOfNonRecurringItem[] }>()
);

export const getOneTimeTypesFailed = createAction(
  "[OfficeCard] GET_ONE_TIME_TYPES_FAILED"
);

export const storeOneTimeOrdersRequest = createAction(
  "[OfficeCard] STORE_ONE_TIME_ORDERS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeOneTimeOrdersSuccess = createAction(
  "[OfficeCard] STORE_ONE_TIME_ORDERS_SUCCESS"
);

export const storeOneTimeOrdersFailed = createAction(
  "[OfficeCard] STORE_ONE_TIME_ORDERS_FAILED"
);

export const updateOneTimeOrdersRequest = createAction(
  "[OfficeCard] UPDATE_ONE_TIME_ORDERS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateOneTimeOrdersSuccess = createAction(
  "[OfficeCard] UPDATE_ONE_TIME_ORDERS_SUCCESS"
);

export const updateOneTimeOrdersFailed = createAction(
  "[OfficeCard] UPDATE_ONE_TIME_ORDERS_FAILED"
);

export const deleteOneTimeOrdersRequest = createAction(
  "[OfficeCard] DELETE_ONE_TIME_ORDERS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const deleteOneTimeOrdersSuccess = createAction(
  "[OfficeCard] DELETE_ONE_TIME_ORDERS_SUCCESS"
);

export const deleteOneTimeOrdersFailed = createAction(
  "[OfficeCard] DELETE_ONE_TIME_ORDERS_FAILED"
);

export const getFeeTypeOrdersRequest = createAction(
  "[OfficeCard] GET_FEE_TYPE_ORDERS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getFeeTypeOrdersSuccess = createAction(
  "[OfficeCard] GET_FEE_TYPES_SUCCESS",
  props<{ feeTypeOrders: FeeTypeOrderResponse[] }>()
);

export const getFeeTypeOrdersFailed = createAction(
  "[OfficeCard] GET_FEE_TYPES_FAILED"
);

export const storeFeeTypeOrderRequest = createAction(
  "[OfficeCard] STORE_FEE_TYPE_ORDER_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeFeeTypeOrderSuccess = createAction(
  "[OfficeCard] STORE_FEE_TYPE_ORDER_SUCCESS"
);

export const storeFeeTypeOrderFailed = createAction(
  "[OfficeCard] STORE_FEE_TYPE_ORDER_FAILED"
);

export const updateFeeTypeOrderRequest = createAction(
  "[OfficeCard] UPDATE_FEE_TYPE_ORDER_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateFeeTypeOrderSuccess = createAction(
  "[OfficeCard] UPDATE_FEE_TYPE_ORDER_SUCCESS"
);

export const updateFeeTypeOrderFailed = createAction(
  "[OfficeCard] UPDATE_FEE_TYPE_ORDER_FAILED"
);

export const deleteFeeTypeOrderRequest = createAction(
  "[OfficeCard] DELETE_FEE_TYPE_ORDER_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const deleteFeeTypeOrderSuccess = createAction(
  "[OfficeCard] DELETE_FEE_TYPE_ORDER_SUCCESS"
);

export const deleteFeeTypeOrderFailed = createAction(
  "[OfficeCard] DELETE_FEE_TYPE_ORDER_FAILED"
);
