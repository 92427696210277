export function focusAreaFormatParameters(
  officeIds: string[] | null,
  agentIds: string[] | null,
  entityType: "office" | "agent" | null
): Record<string, unknown> {
  const params = { nestedFilters: [] };

  // Handle the case where no selection is made.
  if (
    !entityType &&
    (!officeIds || officeIds.length === 0) &&
    (!agentIds || agentIds.length === 0)
  ) {
    return params;
  }

  // Handle the case where no entity type is specified.
  if (!entityType) {
    if (officeIds && officeIds.length > 0) {
      params.nestedFilters.push({
        key: "belongsToOffice",
        filters: [
          {
            field: "id",
            operator: "anyOf",
            value: officeIds,
          },
        ],
      });
    }
    if (agentIds && agentIds.length > 0) {
      params.nestedFilters.push({
        key: "belongsToAgent",
        filters: [
          {
            field: "id",
            operator: "anyOf",
            value: agentIds,
          },
        ],
      });
    }
  } else {
    // Handle the case where an entity type is specified.
    if (entityType === "office") {
      if (!officeIds || officeIds.length === 0) {
        params.nestedFilters.push({
          key: "belongsToOffice",
          filters: [
            {
              field: "id",
              operator: "notEmpty",
            },
          ],
        });
      } else {
        params.nestedFilters.push({
          key: "belongsToOffice",
          filters: [
            {
              field: "id",
              operator: "anyOf",
              value: officeIds,
            },
          ],
        });
      }

      params.nestedFilters.push({
        key: "belongsToAgent",
        filters: [
          {
            field: "id",
            operator: "empty",
          },
        ],
      });
    } else if (entityType === "agent") {
      if (!agentIds || agentIds.length === 0) {
        params.nestedFilters.push({
          key: "belongsToAgent",
          filters: [
            {
              field: "id",
              operator: "notEmpty",
            },
          ],
        });

        if (officeIds && officeIds.length > 0) {
          params.nestedFilters.push({
            key: "belongsToOffice",
            filters: [{ field: "id", operator: "anyOf", value: officeIds }],
          });
        }
      } else {
        params.nestedFilters.push({
          key: "belongsToAgent",
          filters: [
            {
              field: "id",
              operator: "anyOf",
              value: agentIds,
            },
          ],
        });
      }
    }
  }

  return params;
}
