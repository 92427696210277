import { Injectable } from "@angular/core";
import { Action } from "@app/models";

import { ActionFilter } from "../models";
import * as actionFilters from "../utils/action-filter";

@Injectable()
export class ActionService {
  getFilter(action: Action): ActionFilter {
    return actionFilters.FILTERS.find((filter: ActionFilter) =>
      filter.codes.some(
        (code: string) =>
          action.code === code &&
          !(code === "3205" && filter === actionFilters.OBJECT_CONNECTIONS)
      )
    );
  }

  getAllFilterCodes(): string[] {
    let list = [];
    actionFilters.FILTERS.forEach(
      (af: ActionFilter) => (list = list.concat(af.codes))
    );
    return list;
  }

  getAllFilters(): ActionFilter[] {
    return actionFilters.FILTERS;
  }

  getIcon(action: Action): string {
    const actionFilter: ActionFilter = this.getFilter(action);
    return actionFilter ? actionFilter.icon : "";
  }

  getColor(action: Action): string {
    const actionFilter: ActionFilter = this.getFilter(action);
    return actionFilter ? actionFilter.color : "";
  }
}
