import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Bid } from "@app/integrations/bidding/models/bid";
import { IntegrationResource } from "@app/integrations/models/enums";
import * as fromIntegration from "@app/integrations/ngrx/integrations.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { BIDDING } from "@app/shared/config/utils/features";
import * as fromManagePotentialBuyerBidding from "@app/sidebar/potential-buyer/ngrx/bidding.ngrx";
import { select, Store } from "@ngrx/store";
import {
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from "rxjs";
import {
  fetchBidsRequest,
  removeBidRequest,
  reset,
} from "../ngrx/bidding/bidding.actions";
import * as fromBidding from "../ngrx/bidding/bidding.reducer";

@Injectable({
  providedIn: "root",
})
export class BiddingService {
  private _biddingEnabled$: Observable<boolean>;
  private _hasIntegration$: Observable<boolean>;
  hasIntegration$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.mapStateToProps();
  }

  fetchBids(eaOid: string): void {
    this.store.dispatch(fetchBidsRequest({ eaOid }));
  }

  resetBidState(): void {
    this.store.dispatch(reset());
  }

  removeBid(id: string): void {
    this.store.dispatch(removeBidRequest({ id }));
  }

  getHighestBid(): Observable<string> {
    return this.store.pipe(select(fromBidding.getHighestBid));
  }

  isHighestBidLoading(): Observable<boolean> {
    return this.store.pipe(select(fromBidding.highestBidLoading));
  }

  getHighestBidForContact(): Observable<string> {
    return this.store.pipe(select(fromManagePotentialBuyerBidding.getBid));
  }

  getBids(): Observable<Bid[]> {
    return this.store.pipe(select(fromBidding.getBids));
  }

  bidsLoading(): Observable<boolean> {
    return this.store.pipe(select(fromBidding.bidsLoading));
  }

  getCurrency(): Observable<string> {
    return this.store.pipe(select(fromConfig.getCurrency));
  }

  private mapStateToProps() {
    this._biddingEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(BIDDING)),
      map((config: Feature) => config.enabled)
    );

    this._hasIntegration$ = this.store.pipe(
      select(fromIntegration.hasIntegration(IntegrationResource.GetBidsList))
    );

    this.hasIntegration$ = this._biddingEnabled$.pipe(
      switchMap((enabled) => (enabled ? this._hasIntegration$ : of(false))),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  getHighestBidFromBidList(bidList: Bid[], onlyActiveBids: boolean) {
    const result = bidList
      .slice()
      .sort((a, b) => (parseInt(a?.amount) > parseInt(b?.amount) ? -1 : 1));

    if (onlyActiveBids) {
      const highestBid = result.find((bid) => !bid.isCanceled);
      return highestBid ?? result[0];
    }
    return result[0];
  }
}
