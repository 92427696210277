import { createAction, props } from "@ngrx/store";
import { Template } from "@app/sidebar/send-message/models";

const ACTION_PREFIX = "[Message templates]";

export const changeFilter = createAction(
  `${ACTION_PREFIX} CHANGE_FILTER`,
  props<{
    groupType: string;
    groupName: string;
  }>()
);

export const fetchTemplatesRequest = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_REQUEST`
);

export const fetchTemplatesSuccess = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_SUCCESS`,
  props<{ templates: Template[] }>()
);

export const fetchTemplatesFail = createAction(
  `${ACTION_PREFIX} FETCH_TEMPLATES_FAIL`
);
