import { ContactProfile, Note } from "@app/models";
import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import * as fromBidding from "@app/sidebar/potential-buyer/ngrx/bidding.ngrx";
import * as fromContactProfile from "@app/sidebar/potential-buyer/ngrx/contact-profile.ngrx";
import * as fromContactedStatus from "@app/sidebar/potential-buyer/ngrx/contacted-status.ngrx";
import * as fromLoading from "@app/sidebar/potential-buyer/ngrx/loading.ngrx";
import * as fromNote from "@app/sidebar/potential-buyer/ngrx/note.ngrx";
import * as fromPotentialBuyer from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import * as fromPrivateShowing from "@app/sidebar/potential-buyer/ngrx/showing.ngrx";
import { ActionReducerMap, combineReducers } from "@ngrx/store";
import * as fromAttendance from "./attendance.ngrx";

export interface PotentialBuyerState {
  loading: fromLoading.LoadingState;
  attendances: ContactShowingAttendance[];
  note: Note | null;
  contacted: boolean | null;
  profile: ContactProfile | null;
  bidding: fromBidding.BiddingState;
  potential_buyer: fromPotentialBuyer.PotentialBuyerState;
  private_showing: fromPrivateShowing.PrivateShowingState;
}

export const potentialBuyerReducerMap: ActionReducerMap<PotentialBuyerState> = {
  loading: fromLoading.loadingReducer,
  attendances: fromAttendance.contactShowingAttendanceReducer,
  note: fromNote.noteReducer,
  contacted: fromContactedStatus.contactedStatusReducer,
  profile: fromContactProfile.contactProfileReducer,
  bidding: fromBidding.biddingReducer,
  potential_buyer: fromPotentialBuyer.potentialBuyerReducer,
  private_showing: fromPrivateShowing.privateShowingReducer,
};

export function potentialBuyerModuleReducer(state: any, action: any) {
  return combineReducers(potentialBuyerReducerMap)(state, action);
}
