import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { ProductNewsComponent } from "@app/shared/modules/product-news/product-news.component";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { ProductNewsModalComponent } from "@app/shared/modules/product-news/product-news-modal/product-news-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatBadgeModule } from "@angular/material/badge";

const components = [ProductNewsComponent, ProductNewsModalComponent];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    TooltipModule,
    SkeletonScreenModule,
    UiComponentsModule,
    MatDialogModule,
    MatBadgeModule,
  ],
  declarations: components,
  exports: components,
})
export class ProductNewsModule {}
