import { PageView } from "@app/models/page-view";
import { createAction, props } from "@ngrx/store";

export const fetchLatestContactsRequest = createAction(
  "[Widget Latest Objects] FETCH_LATEST_CONTACTS_REQUEST"
);

export const fetchLatestContactsSuccess = createAction(
  "[Widget Latest Objects] FETCH_LATEST_CONTACTS_SUCCESS",
  props<{ contacts: PageView[] }>()
);

export const fetchLatestContactsFail = createAction(
  "[Widget Latest Objects] FETCH_LATEST_CONTACTS_FAIL"
);
