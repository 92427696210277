<h3>
  <app-icon name="chart-line" size="large"></app-icon>&nbsp;<span translate
    >summary</span
  >
  <span class="info-tooltip" [tooltip]="'summary_widget_tooltip' | translate">
    <app-icon name="info-circle"></app-icon>
  </span>
</h3>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="widget-container__filters" #filterContainer>
    <app-segmented-controls
      *ngIf="showSegment"
      [controls]="filters"
      [active]="quickFilter"
      (change)="changeFilter($event)"
    ></app-segmented-controls>

    <app-dropdown-element
      *ngIf="!showSegment"
      [items]="dropDownFilters"
      [preselectValue]="quickFilter"
      [multiple]="false"
      [newStyle]="true"
      [required]="true"
      [path]="['dateFilter']"
      width="100%"
      (valueEntered)="changeFilter($event)"
    >
    </app-dropdown-element>
  </div>
  <div
    class="widget-container__data"
    *ngIf="statistics$ | async as stats; else noData"
  >
    <table>
      <thead>
        <tr>
          <th></th>
          <th translate>current_period</th>
          <th translate>last_year</th>
          <th>
            {{ "budget_for" | translate }}
            {{ getBudgetTitle(stats.budgetStartMonth, stats.budgetEndMonth) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr></tr>
        <tr *ngFor="let type of returnTypes">
          <td>{{ type.toLowerCase() | translate }}</td>
          <td>{{ stats.totals[type]?.current | qNumber }}</td>
          <td>
            {{ stats.totals[type]?.lastYear | qNumber }} ({{
              stats.totals[type]?.current
                | qPercentCalculator : stats.totals[type]?.lastYear
                | qPercent
            }})
          </td>
          <td>
            <ng-container *ngIf="!!stats.totals[type]?.budgetData?.budget">
              <span *ngIf="stats.totals[type]?.budgetData?.budget !== 0"
                >{{ stats.totals[type].budgetData.budget | qNumber }} ({{
                  stats.totals[type].budgetData.changeBudgetPercentage
                    | qNumber : "1.0-2"
                }}%)</span
              >
              <span
                *ngIf="stats.totals[type]?.budgetData?.budget === 0"
                translate
                >no_budget_added</span
              >
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="statistics$ | async" class="widget-container__footer">
    <span>
      {{
        "last_year_day_type"
          | translate : { day: getLastYearDay(), date: getLastYearDate() }
      }}
    </span>
    <span *ngIf="returnTypes.length > 5">
      <i class="icon-arrow-down-circle"></i> {{ "scroll_for_more" | translate }}
    </span>
  </div>
</div>

<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
