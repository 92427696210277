<table class="table table--striped">
  <thead>
    <tr>
      <th translate>office</th>
      <th translate>this_year</th>
      <th translate>last_year</th>
      <th translate>growth</th>
      <th translate>sales_target</th>
      <th translate>sales_target_accomplished</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let office of offices$ | async">
      <td dataLabel="office">
        <a
          queryParamsHandling="preserve"
          [routerLink]="[
            '/crm',
            { outlets: { modal: ['kpi', type$ | async, 'offices', office.id] } }
          ]"
        >
          {{ office.name | removeOfficePrefix | async }}
        </a>
      </td>
      <td dataLabel="this_year">
        {{ office.currentData ? (office.currentData | separateThousands) : 0 }}
      </td>
      <td dataLabel="last_year">
        {{
          office.lastYearData ? (office.lastYearData | separateThousands) : 0
        }}
      </td>
      <td
        dataLabel="growth"
        [ngClass]="{
          'text-success': office.getGrowth() > 0,
          'text-danger': office.getGrowth() < 0
        }"
      >
        <ng-container *ngIf="office.getGrowth() > 0">+</ng-container>
        {{ office.getGrowth() }}%
      </td>
      <td dataLabel="sales_target">
        {{ (office.salesTarget | separateThousands) || "-" }}
      </td>
      <td
        dataLabel="sales_target_accomplished"
        [className]="getClassNameByProgress(office.getSalesTargetPercent())"
      >
        {{ office.salesTarget ? office.getSalesTargetPercent() + "%" : "-" }}
      </td>
    </tr>
    <tr
      class="hidden-sm hidden-xs"
      kpi-summary-row
      [kpi]="summary$ | async"
    ></tr>
  </tbody>
</table>
