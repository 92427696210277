import {
  DataStudioConfiguration,
  HubDataStudio,
} from "@app/models/tailored-campaign";
import * as widgetActions from "@app/widgets/widgets/statistics-tailored-campaign-widget/ngrx/statistics-tailored-campaign-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface TailoredCampaignState {
  dataStudioConfigurationLoading: boolean;
  dataStudioConfigurationIsLoaded: boolean;
  dataStudiohubConfiguration: DataStudioConfiguration;
  hubDataStudioLoading: boolean;
  hubDataStudioIsLoaded: boolean;
  hubDataStudio: HubDataStudio;
}

export const initialState: TailoredCampaignState = {
  dataStudioConfigurationLoading: false,
  dataStudioConfigurationIsLoaded: false,
  dataStudiohubConfiguration: null,
  hubDataStudioLoading: false,
  hubDataStudioIsLoaded: false,
  hubDataStudio: null,
};

export function statisticsTailoredCampaignWidgetReducer(
  state: TailoredCampaignState,
  action: Action
): TailoredCampaignState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(widgetActions.fetchDataStudioConfigurationRequest, (state) => ({
    ...state,
    dataStudioConfigurationLoading: true,
    dataStudioConfigurationIsLoaded: false,
  })),
  on(widgetActions.fetchDataStudioConfigurationSuccess, (state, { data }) => ({
    ...state,
    dataStudioConfigurationLoading: false,
    dataStudioConfigurationIsLoaded: true,
    dataStudiohubConfiguration: data,
  })),
  on(widgetActions.fetchDataStudioConfigurationFail, (state) => ({
    ...state,
    dataStudioConfigurationLoading: false,
    dataStudioConfigurationIsLoaded: false,
  })),
  on(widgetActions.fetchHubDataStudioRequest, (state) => ({
    ...state,
    hubDataStudioLoading: true,
    hubDataStudioIsLoaded: false,
  })),
  on(widgetActions.fetchHubDataStudioSuccess, (state, { data }) => ({
    ...state,
    hubDataStudioLoading: false,
    hubDataStudioIsLoaded: true,
    hubDataStudio: data,
  })),
  on(widgetActions.fetchHubDataStudioFail, (state) => ({
    ...state,
    hubDataStudioLoading: false,
    hubDataStudioIsLoaded: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.tailoredCampaign
);

export const getDataStudioConfiguration = createSelector(
  getState,
  (state: TailoredCampaignState) => state.dataStudiohubConfiguration
);

export const getDataStudioConfigurationIsLoaded = createSelector(
  getState,
  (state: TailoredCampaignState) => state.dataStudioConfigurationIsLoaded
);

export const getHubDataStudio = createSelector(
  getState,
  (state: TailoredCampaignState) => state.hubDataStudio
);
export const getHubDataStudioLoading = createSelector(
  getState,
  (state: TailoredCampaignState) => state.hubDataStudioLoading
);
export const getHubDataStudioIsLoaded = createSelector(
  getState,
  (state: TailoredCampaignState) => state.hubDataStudioIsLoaded
);
