import { Office } from "@app/models";
import { Region } from "@app/models/region";
import { ObjectType } from "@app/shared/utils/q-object-types";
import {
  MIN_WIDTH_LG,
  MIN_WIDTH_MD,
  MIN_WIDTH_SM,
} from "@app/shared/utils/screen-sizes";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as sharedActions from "./shared.actions";

export interface SharedState {
  innerWidth: number;
  offices: Office[];
  regions: Region[];
  objectTypes: ObjectType[];
  objectStatus: ObjectStatus[];
  ssoifyLoading: boolean;
}

export const initialState: SharedState = {
  innerWidth: 0,
  offices: [],
  regions: [],
  objectTypes: [],
  objectStatus: [],
  ssoifyLoading: false,
};

export function sharedReducer(state: SharedState, action: Action): SharedState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(sharedActions.setInnerWidth, (state, { innerWidth }) => ({
    ...state,
    innerWidth,
  })),
  on(sharedActions.loadOfficesSuccess, (state, { offices }) => ({
    ...state,
    offices,
  })),
  on(sharedActions.loadOfficesFail, (state) => ({
    ...state,
    offices: initialState.offices,
  })),
  on(sharedActions.loadRegionsSuccess, (state, { regions }) => ({
    ...state,
    regions,
  })),
  on(sharedActions.loadRegionsFail, (state) => ({
    ...state,
    regions: initialState.regions,
  })),
  on(sharedActions.loadObjectTypesSuccess, (state, { objectTypes }) => ({
    ...state,
    objectTypes,
  })),
  on(sharedActions.loadObjectTypesFail, (state) => ({
    ...state,
    objectTypes: initialState.objectTypes,
  })),
  on(sharedActions.loadObjectStatusSuccess, (state, { objectStatus }) => ({
    ...state,
    objectStatus,
  })),
  on(sharedActions.loadObjectStatusFail, (state) => ({
    ...state,
    objectStatus: initialState.objectStatus,
  })),
  on(sharedActions.ssoifyLinkAndOpenItRequest, (state) => ({
    ...state,
    ssoifyLoading: true,
  })),
  on(
    sharedActions.ssoifyLinkAndOpenItSuccess,
    sharedActions.ssoifyLinkAndOpenItFail,
    (state) => ({
      ...state,
      ssoifyLoading: false,
    })
  )
);

const selectFeature = createFeatureSelector<SharedState>("shared");

export const getInnerWidth = createSelector(
  selectFeature,
  (state: SharedState) => state.innerWidth
);

export const isPad = createSelector(
  selectFeature,
  (state: SharedState) => state.innerWidth < MIN_WIDTH_LG
);

export const isMobile = createSelector(
  selectFeature,
  (state: SharedState) => state.innerWidth < MIN_WIDTH_MD
);

export const isXSMobile = createSelector(
  selectFeature,
  (state: SharedState) => state.innerWidth < MIN_WIDTH_SM
);

export const isLargeScreen = createSelector(
  selectFeature,
  (state: SharedState) => state.innerWidth >= MIN_WIDTH_LG
);

export const getOffices = createSelector(
  selectFeature,
  (state: SharedState) => state.offices
);

export const getRegions = createSelector(
  selectFeature,
  (state: SharedState) => state.regions
);

export const getOffice = (eaOfficeId: string) =>
  createSelector(selectFeature, (state: SharedState) =>
    state.offices.find((office: Office) => office.eaOfficeId === eaOfficeId)
  );

export const getObjectTypes = createSelector(
  selectFeature,
  (state: SharedState) => state.objectTypes
);

export const getObjectStatus = createSelector(
  selectFeature,
  (state: SharedState) => state.objectStatus
);

export const getSsoifyLoading = createSelector(
  selectFeature,
  (state: SharedState) => state.ssoifyLoading
);
