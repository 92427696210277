import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";
import { AddressValidationComponent } from "./address-validation/address-validation.component";
import { SearchAddressComponent } from "./search-address/search-address.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { SearchAddressAdvComponent } from "@app/shared/modules/search-address/search-address-advanced/search-address-advanced.component";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

const components = [
  SearchAddressAdvComponent,
  SearchAddressComponent,
  AddressValidationComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadModule,
    TranslateModule,
    UiComponentsModule,
    MatInputModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
  ],
  declarations: components,
  exports: components,
})
export class SearchAddressModule {}
