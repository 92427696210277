import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { View } from "@app/core/services/list-views/list-views.service";

@Component({
  selector: "app-tab-bar",
  templateUrl: "./tab-view-bar.component.html",
  styleUrls: ["./tab-view-bar.component.scss"],
})
export class TabViewBarComponent implements AfterViewInit {
  @Input() tabs: View[];
  @Input() activeTab: string;

  @Output() changeTab = new EventEmitter<any>();

  ngAfterViewInit() {
    setTimeout(() => {
      this.applyScroll();
    }, 1000);
  }

  onClick(val) {
    this.changeTab.emit(val);
  }

  private applyScroll() {
    if (this.activeTab) {
      document.querySelector("#" + this.activeTab)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "start",
      });
    }
  }
}
