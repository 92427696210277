import {
  TaskList,
  TaskListConfig,
} from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { createAction, props } from "@ngrx/store";
import moment from "moment";

export const getOverdue = (
  eaEmployeeId: string,
  offset?: number,
  limit?: number
) => {
  const filters: Partial<TaskList> = {
    limit: limit ? limit : 10,
    offset: offset ? offset : 0,
    eaEmployeeId: eaEmployeeId,
    taskTypeCategory: "lead,task",
    resolved: false,
    sortBy: "deliveryDate",
    sortOrder: "desc",
    deliveryTo: moment()
      .subtract(6, "days")
      .endOf("day")
      .format(API_DATE_FORMAT),
  };

  return getOverdueRequest({ filters });
};

export const getOverdueRequest = createAction(
  "[OverdueTaskList] GET_OVERDUE_REQUEST",
  props<{ filters: Partial<TaskList> }>()
);

export const getOverdueSuccess = createAction(
  "[OverdueTaskList] GET_OVERDUE_SUCCESS",
  props<{ tasks: Task[]; listStart: number; totalRows: number }>()
);

export const getOverdueFail = createAction(
  "[OverdueTaskList] GET_OVERDUE_FAIL"
);

export const getOverdueNoTasksFound = createAction(
  "[OverdueTaskList] GET_OVERDUE_NO_TASKS_FOUND"
);

export const setListConfig = createAction(
  "[OverdueTaskList] SET_LIST_CONFIG",
  props<{ payload: Partial<TaskListConfig> }>()
);

export const setListConfigLimit = createAction(
  "[OverdueTaskList] SET_LIST_CONFIG_LIMIT"
);

export const reset = createAction("[OverdueTaskList] RESET");
