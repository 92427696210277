export const CONTACT_SOURCE = "contact_source";
export const REGIONS = "regions";
export const ENIRO = "eniro";
export const ADDRESS_SUGGESTER = "address_suggester";
export const ADDRESS_VALIDATION = "address_validation";
export const SEND_TIPS = "send_tips";
export const CREATE_FOLLOW_UP = "create_follow_up";
export const SALES_MEETING = "sales_meeting";
export const KPI = "kpi";
export const OUTGOING_MAILS = "outgoing_mails";
export const EXCHANGE_INTEGARTION = "exchange_integration";
export const OFFICE_MANAGEMENT_EMAIL = "office_management_email";
export const SEND_MESSAGE = "send_message";
export const SHOWING_CONFIRMATION_EMAIL = "showing_confirmation_email";
export const EXTERNAL_TIPS = "external_tips";
export const COMPETITIONS = "competitions";
export const MATCHINGS = "matchings";
export const EXTERNAL_PROVIDER = "external_provider";
export const BIDDING = "bidding";
export const SEARCH_PROFILE = "search_profile";
export const ONLINE_BOOKING = "online_booking";
export const REGISTER_VIEW = "register_view";
export const NPS = "nps";
export const NPS_LIST = "nps_list";
export const SUPPORT = "support";
export const MILES = "miles";
export const DRAW_SEARCH_PROFILE_AREA = "draw_search_profile_area";
export const SALES_FOCUS_AREAS = "sales_focus_areas";
export const CREATE_PRIVATE_SHOWING = "create_private_showing";
export const PRIVATE_NOTES = "private_notes";
export const SALES_TARGETS = "sales_targets";
export const OBJECT_OVERVIEW = "object_overview";
export const ADVANCED_OBJECTS_LIST = "advanced_objects_list";
export const WIDGET_DASHBAORD = "widget_dashboard";
export const BANK_SETTINGS = "bank_settings";
export const SEND_TIPS_EIKA = "send_tips_eika";
export const TOP_LISTS = "top_lists";
export const GROWTH_REPORTS = "growth_reports";
export const PRODUCT_NEWS = "product_news";
export const POTENTIAL_BUYERS = "potential_buyers";
export const ERP = "erp";
export const TAGS = "tags";
export const WEATHER = "weather";
export const SUPPORT_WIDGET = "support_widget";
export const CONSENT = "consent";
export const BUDGET = "budget";
export const INVOICING = "invoicing";
export const CREATE_OBJECT = "create_object";
export const TRANSFORM_UNKNOWNS_TO_ZERO_BIDDERS =
  "transform_unknown_to_zero_bidders";
export const TAILORED_CAMPAIGNS = "tailored_campaigns";
export const THIRD_PARTY_WIDGET = "third_party_widget";
export const VALUE_MONITOR = "value_monitor";
export const LEAD_AUTOMATION = "lead_automation";
export const EMPLOYEE_DESCRIPTIONS = "employee_descriptions";
export const CREATE_OBJECT_VIDEO = "create_object_video";
export const ADFENIX_PROPERTY_MARKETING = "adfenix_property_marketing";
export const BUDGET_TURNOVER = "budget_turnover";
export const ADVANCED_LEADS_HANDLING = "advanced_leads_handing";
export const OPEN_IN_ERP = "open_in_erp";
export const OPEN_BUYER_OR_SELLER_IFRAME = "open_buyer_or_seller_iframe";
export const DOWNLOAD_OBJECT_IMAGES = "download_object_images";
export const STATISTICS_OFFICE_FILTER = "statistics_office_filter";
export const SHOWINGS_COMMENT = "showings_comment";
export const STATISTICS_MARKETING_REPORTS = "statistics_marketing_reports";
export const TEAMS = "teams";
export const CONSENT_ALARM_LEVEL = "consent_alarm_level";
export const SHORTCUTS = "shortcuts";
