import { Component, Input } from "@angular/core";
import { Survey } from "@app/models/survey";

@Component({
  selector: "survey-indicator",
  templateUrl: "./survey-indicator.component.html",
  styleUrls: ["./survey-indicator.component.scss"],
})
export class SurveyIndicatorComponent {
  @Input() surveys: Survey[];
}
