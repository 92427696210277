import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: "momentifyFromNow" })
export class MomentifyFromNowPipe implements PipeTransform {
  transform(value: string): string {
    if (!!value) {
      return moment(value).fromNow();
    } else {
      return null;
    }
  }
}
