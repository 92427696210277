import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CampaignsGuard } from "@app/campaigns/campaigns-guard.service";
import { ContactGuard } from "@app/contacts/guards/contact-guard.service";
import { LoginResolverService } from "@app/login/login-resolver.service";
import { AdminManagerGuard } from "@app/shared/guards/admin-manager.guard";
import { AdminGuard } from "@app/shared/guards/admin.guard";
import { MobileRedirectComponent } from "@app/shared/modules/pages/mobile-redirect/mobile-redirect.component";
import { SystemTemporarilyDownComponent } from "@app/shared/modules/pages/system-temporarily-down/system-temporarily-down.component";
import { UnknownOriginComponent } from "@app/shared/modules/pages/unknown-origin/unknown-origin.component";
import { ContactPotentialBuyerCreateGuard } from "@app/sidebar/guards/contact-potential-buyer-create-guard.service";
import { CreateObjectGuard } from "@app/sidebar/guards/create-object-guard.service";
import { EikaGuard } from "@app/sidebar/guards/eika-guard.service";
import { ErpEmployeesGuardService } from "@app/sidebar/guards/erp-employees-guard.service";
import { ErpOfficesGuardService } from "@app/sidebar/guards/erp-offices-guard.service";
import { ErpShowingsGuardService } from "@app/sidebar/guards/erp-showings-guard.service";
import { ExternalProviderConnectGuard } from "@app/sidebar/guards/external-provider-connect-guard.service";
import { ExternalProviderGuard } from "@app/sidebar/guards/external-provider-guard.service";
import { LeadChannelsGuard } from "@app/sidebar/guards/lead-channels-guard.service";
import { CreateLeadGuard } from "@app/sidebar/guards/lead-create-guard.service";
import { LeadResultOptionsGuard } from "@app/sidebar/guards/lead-result-options-guard.service";
import { LeadTemplatesGuard } from "@app/sidebar/guards/lead-templates-guard.service";
import { MessageTemplateGuard } from "@app/sidebar/guards/message-template-create-guard.service";
import { SearchProfileAlertCreateGuard } from "@app/sidebar/guards/search-profile-alert-create-guard.service";
import { SearchProfileCreateGuard } from "@app/sidebar/guards/search-profile-create-guard.service";
import { SearchProfileEditGuard } from "@app/sidebar/guards/search-profile-edit-guard.service";
import { SearchProfileGuard } from "@app/sidebar/guards/search-profile-guard.service";
import { SupportGuard } from "@app/sidebar/guards/support-guard.service";
import { ValueMonitorGuard } from "@app/sidebar/guards/value-monitor-guard.service";
import { AppCustomPreloader } from "./app-preloader";
import { AppGuard } from "./app.guard";
import { MainPageComponent } from "./core/components/layout/main-page/main-page.component";
import { routes as kpiRoutes } from "./kpi/kpi-routing";
import { KpiModalResolver } from "./kpi/resolvers/kpi-modal-resolver.service";
import { PageNotFoundComponent } from "./shared/modules/pages/page-not-found/page-not-found.component";
import { PagesModule } from "./shared/modules/pages/pages.module";
import * as crmModules from "./shared/utils/crm-modules";
import { EmployeesOfficeCreateResolverService } from "./sidebar/employees/employees-office-create/employees-office-create-resolver.service";
import { EditExternalTipGuard } from "./sidebar/external-tips/guards/edit-external-tip-guard.service";
import { CallingListCreateGuard } from "./sidebar/guards/calling-list-create-guard.service";
import { CompetitionCreateGuard } from "./sidebar/guards/competition-create-guard.service";
import { CompetitionEditGuard } from "./sidebar/guards/competition-edit-guard.service";
import { ContactCreateGuard } from "./sidebar/guards/contact-create-guard.service";
import { ContactEditGuard } from "./sidebar/guards/contact-edit-guard.service";
import { ContactSellerBuyerCreateGuard } from "./sidebar/guards/contact-seller-buyer-create-guard.service";
import { CreateFollowUpGuard } from "./sidebar/guards/create-follow-up-guard.service";
import { EmployeesGuard } from "./sidebar/guards/employees-guard.service";
import { ExistingHomeGuard } from "./sidebar/guards/existing-home-guard.service";
import { ExternalTipsCreateGuard } from "./sidebar/guards/external-tips-create-guard.service";
import { ExternalTipsEditGuard } from "./sidebar/guards/external-tips-edit-guard.service";
import { SalesMeetingGuard } from "./sidebar/guards/sales-meeting-guard.service";
import { SendExternalTipsGuard } from "./sidebar/guards/send-external-tips-guard.service.";
import { SendMessageGuard } from "./sidebar/guards/send-message-guard.service";
import { SendTipsGuard } from "./sidebar/guards/send-tips-guard.service";
import { SidebarGuard } from "./sidebar/guards/sidebar-guard.service";
import { TaskGuard } from "./sidebar/guards/task-guard.service";
import { SidebarContactResidencesResolver } from "./sidebar/residence/sidebar-contact-residences-resolver.service";
import { SidebarResidenceContactResolver } from "./sidebar/residence/sidebar-residence-contact-resolver.service";
import { SalesMeetingEditGuardService } from "./sidebar/sales-meeting/guards/sales-meeting-edit-guard.service";
import { SalesMeetingContactResolver } from "./sidebar/sales-meeting/resolvers/sales-meeting-contact-resolver.service";
import { SalesMeetingResolver } from "./sidebar/sales-meeting/resolvers/sales-meeting-resolver.service";
import { sidebarRoutes } from "./sidebar/sidebar-routing";
import { TasksDefaultGuard } from "./sidebar/tasks/guards/tasks-default.guard";
import { TasksSalesMeetingReportResolverService } from "./sidebar/tasks/tasks-sales-meeting-report/tasks-sales-meeting-report-resolver.service";
import { ExternalWidgetMainComponent } from "@app/shared/modules/pages/external-widget-main/external-widget-main.component";

const guards = [
  AppGuard,
  AdminGuard,
  AdminManagerGuard,
  ContactCreateGuard,
  ContactSellerBuyerCreateGuard,
  CompetitionCreateGuard,
  CompetitionEditGuard,
  ContactEditGuard,
  ContactPotentialBuyerCreateGuard,
  CreateFollowUpGuard,
  ExistingHomeGuard,
  SidebarGuard,
  SendTipsGuard,
  TasksDefaultGuard,
  TaskGuard,
  SalesMeetingGuard,
  SalesMeetingEditGuardService,
  EmployeesGuard,
  SendMessageGuard,
  CallingListCreateGuard,
  CreateObjectGuard,
  ExternalTipsCreateGuard,
  ExternalTipsEditGuard,
  EditExternalTipGuard,
  SendExternalTipsGuard,
  ContactGuard,
  ExternalProviderGuard,
  ExternalProviderConnectGuard,
  SearchProfileGuard,
  SearchProfileCreateGuard,
  SearchProfileEditGuard,
  SearchProfileAlertCreateGuard,
  SupportGuard,
  CreateLeadGuard,
  EikaGuard,
  ErpShowingsGuardService,
  ErpEmployeesGuardService,
  ErpOfficesGuardService,
  CampaignsGuard,
  MessageTemplateGuard,
  LeadChannelsGuard,
  LeadTemplatesGuard,
  LeadResultOptionsGuard,
  ValueMonitorGuard,
];

const resolvers = [
  SalesMeetingResolver,
  SalesMeetingContactResolver,
  TasksSalesMeetingReportResolverService,
  KpiModalResolver,
  SidebarContactResidencesResolver,
  SidebarResidenceContactResolver,
  EmployeesOfficeCreateResolverService,
  LoginResolverService,
];

export const routes: Routes = [
  { path: "", redirectTo: "crm", pathMatch: "full" },
  { path: "crm/mobile-redirect", component: MobileRedirectComponent },
  {
    path: "crm/external-widget",
    component: ExternalWidgetMainComponent,
    canActivate: [AppGuard],
  },
  {
    path: "crm",
    component: MainPageComponent,
    canActivate: [AppGuard],
    children: [
      { path: "", redirectTo: "dashboard/todo-list", pathMatch: "full" },
      {
        path: crmModules.DASHBOARD_CRM_MODULE,
        loadChildren: () =>
          import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
        data: { preload: true, state: "dashboard" },
      },
      {
        path: crmModules.CONTACTS_CRM_MODULE,
        loadChildren: () =>
          import("./contacts/contacts.module").then((m) => m.ContactsModule),
        data: { preload: true, state: "contacts" },
      },
      {
        path: crmModules.SHOWINGS_CRM_MODULE,
        loadChildren: () =>
          import("./showings/showings.module").then((m) => m.ShowingsModule),
        data: { preload: false, state: "showings" },
      },
      {
        path: crmModules.CALLING_LISTS_CRM_MODULE,
        loadChildren: () =>
          import("./calling-lists/calling-lists.module").then(
            (m) => m.CallingListsModule
          ),
        data: { preload: false, state: "callingLists" },
      },
      {
        path: crmModules.STATISTICS_CRM_MODULE,
        loadChildren: () =>
          import("./statistics/statistics.module").then(
            (m) => m.StatisticsModule
          ),
        data: { preload: false, state: "statistics" },
      },
      {
        path: crmModules.COMPETITIONS_CRM_MODULE,
        loadChildren: () =>
          import("./competitions/competitions.module").then(
            (m) => m.CompetitionsModule
          ),
        data: { preload: false, state: "competitions" },
      },
      {
        path: crmModules.LISTS_CRM_MODULE,
        loadChildren: () =>
          import("./lists/lists.module").then((m) => m.ListsModule),
        data: { preload: false, state: "lists" },
      },
      {
        path: crmModules.OBJECTS_CRM_MODULE,
        loadChildren: () =>
          import("./objects/objects.module").then((m) => m.ObjectsModule),
        data: { preload: false, state: "objects" },
      },
      {
        path: crmModules.MILES_CRM_MODULE,
        loadChildren: () =>
          import("./miles/miles.module").then((m) => m.MilesModule),
        data: { preload: false, state: "miles" },
      },
      {
        path: crmModules.SETTINGS_CRM_MODULE,
        loadChildren: () =>
          import("./settings/settings.module").then((m) => m.SettingsModule),
        data: { preload: false, state: "settings" },
      },
      {
        path: crmModules.EXTERNAL_LINKS_CRM_MODULE,
        loadChildren: () =>
          import("./external-links/external-links.module").then(
            (m) => m.ExternalLinksModule
          ),
        data: { preload: false, state: "externalLinks" },
      },
      {
        path: crmModules.CAMPAIGNS_CRM_MODULE,
        loadChildren: () =>
          import("./campaigns/campaigns.module").then((m) => m.CampaignsModule),
        data: { preload: false, state: "campaigns" },
      },
      ...kpiRoutes,
      sidebarRoutes,
      { path: "page-not-found", component: PageNotFoundComponent },
      { path: "**", component: PageNotFoundComponent },
    ],
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "system-temporarily-down",
    component: SystemTemporarilyDownComponent,
  },
  { path: "unknown-origin", component: UnknownOriginComponent },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: AppCustomPreloader,
    }),
    PagesModule,
  ],
  exports: [RouterModule],
  providers: [AppCustomPreloader, ...guards, ...resolvers],
})
export class AppRoutingModule {}
