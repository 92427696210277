import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
  saveListLocalStorage(name: listName, eaOfficeId: string, params: any): void {
    localStorage.setItem(
      this.getListLocalStorageId(name, eaOfficeId),
      window.btoa(JSON.stringify(params))
    );
  }

  fetchListLocalStorage(name: listName, eaOfficeId: string): any {
    if (localStorage.getItem(this.getListLocalStorageId(name, eaOfficeId))) {
      return {
        ...JSON.parse(
          window.atob(
            localStorage.getItem(this.getListLocalStorageId(name, eaOfficeId))
          )
        ),
      };
    } else {
      return null;
    }
  }

  saveLatestObject(eaOid): void {
    const eaOids: string[] = this.fetchLatestObjects();
    const existingIdIndex = eaOids.findIndex((id) => id === eaOid);
    if (existingIdIndex !== -1) {
      eaOids.splice(existingIdIndex, 1);
      eaOids.unshift(eaOid);
    } else {
      if (eaOids.length >= 10) {
        // Remove last eaOid
        eaOids.pop();
      }
      //  Push eaOid
      eaOids.unshift(eaOid);
    }
    localStorage.setItem(
      LATEST_OBJECTS,
      window.btoa(JSON.stringify({ eaOids }))
    );
  }

  fetchLatestObjects(): string[] {
    const storage = localStorage.getItem(LATEST_OBJECTS);
    if (!!storage) {
      return [...JSON.parse(window.atob(storage)).eaOids];
    } else {
      return [];
    }
  }

  saveUserPreference(type: userPreference, preference: string) {
    localStorage.setItem(type, preference);
  }

  fetchUserPreference(type: userPreference) {
    const item = localStorage.getItem(type);
    if (item) {
      return item;
    } else {
      return null;
    }
  }

  saveItemToLocalStorage(name: string, params: any): void {
    localStorage.setItem(name, window.btoa(JSON.stringify(params)));
  }

  removeItemFromLocalStorage(name: string): void {
    localStorage.removeItem(name);
  }

  fetchItemFromLocalStorage(name: string): any {
    if (localStorage.getItem(name)) {
      return {
        ...JSON.parse(window.atob(localStorage.getItem(name))),
      };
    } else {
      return null;
    }
  }

  fetchAndDecodeFromLocalStorage(name: string): any {
    if (localStorage.getItem(name)) {
      return JSON.parse(window.atob(localStorage.getItem(name)));
    } else {
      return null;
    }
  }

  private getListLocalStorageId = (name: listName, eaOfficeId: string) =>
    `${name}_${eaOfficeId}`;
}

// Add listnames here
export type listName = "object_list" | "advanced_object_table";
const LATEST_OBJECTS = "latest_objects";

type userPreference =
  | "show_untranslated_tooltips"
  | "language"
  | "redirect_after_login_url"
  | "php_preferred_version"
  | "latest_searches"
  | "product_news_latest_viewed"
  | "product_news_modal_is_shown"
  | "mobile-redirect";
