import { Component, Input } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "kpi-soon-for-sale-objects-new",
  templateUrl: "./kpi-soon-for-sale-objects-new.component.html",
  styleUrls: ["./kpi-soon-for-sale-objects-new.component.scss"],
})
export class KpiSoonForSaleObjectsNewComponent {
  @Input() resources$: Observable<any>;

  constructor(private store: Store<AppState>) {}

  navigate(eaOid: string): void {
    this.store.dispatch(
      go({
        path: [
          "/crm",
          {
            outlets: {
              primary: ["showings", eaOid, "potential-buyers"],
              modal: null,
            },
          },
        ],
      })
    );
  }
}
