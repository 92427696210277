import { Store } from "@ngrx/store";
import { isEqual } from "lodash";
import {
  combineLatest,
  debounceTime,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";

export interface ConnectableTab {
  tabType: string;

  connectTab(): void;
}

// Temporary name until ConnectableTab everywhere is refactored
export interface IConnectableTab {
  tabType: string;
  proxy$: Subject<any>;
}

export class ConnectTab {
  constructor(protected store: Store<AppState>) {}

  connectTab(
    proxy$: Observable<any>,
    unsubscribe$: Observable<void>,
    tabType: string
  ): void {
    combineLatest([proxy$.pipe(take(1)), proxy$.pipe(debounceTime(100))])
      .pipe(takeUntil(unsubscribe$))
      .subscribe(([first, current]) => {
        if (isEqual(first, current)) {
          this.store.dispatch(sidebarActions.resetDirty({ tabType }));
        } else {
          this.store.dispatch(sidebarActions.markAsDirty({ tabType }));
        }
      });
  }
}
