import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { danger } from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO, Office, PaginationListDTO } from "@app/models";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  catchError,
  filter,
  first,
  map,
  Observable,
  Subject,
  throwError as observableThrowError,
} from "rxjs";
import { getOffices } from "../../../ngrx/shared.reducer";
import { getEmployee, getEmployeeOffices } from "../../../user";

@Injectable()
export class OfficeEmployeeMultiSelectDropdownService {
  private _offices$ = new BehaviorSubject<Office[]>([]);

  private _employees$ = new BehaviorSubject<EmployeeDTO[]>([]);

  private _employeesLoading$ = new Subject<boolean>();

  get offices$() {
    return this._offices$.asObservable();
  }

  get employees$() {
    return this._employees$.asObservable();
  }

  get employeesLoading$(): Observable<boolean> {
    return this._employeesLoading$.asObservable();
  }

  constructor(private apiService: ApiService, private store: Store<AppState>) {}

  getOfficeByEaOfficeId(eaOfficeId): Office {
    return this._offices$
      .getValue()
      .find((office) => office.eaOfficeId === eaOfficeId);
  }

  fetchOffices(): void {
    this.store
      .pipe(
        select(getOffices),
        filter((offices: Office[]) => offices.length > 0),
        first()
      )
      .subscribe((offices) => this._offices$.next(offices));
  }

  // TODO once proxy employees/legacy is removed change so it uses name instead of officeName
  fetchOfficesConnectedToEmployee(onlyManager = true): void {
    this.store
      .pipe(
        select(getEmployeeOffices),
        first(),
        map((offices) => offices.map((o) => ({ ...o, name: o.officeName })))
      )
      .subscribe((offices) => {
        let chosenOffices = offices;
        if (onlyManager) {
          chosenOffices = offices.filter((office) => office.role === "manager");
        }
        this._offices$.next(chosenOffices);
      });
  }

  fetchEmployeesFromOffices(params: any): void {
    this._employeesLoading$.next(true);

    const apiParams = this.employeesForOfficeParams(
      params.eaOfficeId,
      params.includeInactiveEmployees
    );

    this.apiService
      .get("employees", apiParams)
      .pipe(
        map((response: PaginationListDTO) => response.rows),
        catchError((error: any) =>
          observableThrowError(() => new Error(error.json()))
        )
      )
      .subscribe({
        next: (employees) => this._employees$.next(employees),
        error: (message) => this.store.dispatch(danger({ message })),
        complete: () => this._employeesLoading$.next(false),
      });
  }

  fetchUserEmployee(): void {
    this._employeesLoading$.next(true);
    // @ts-ignore
    this.store
      .pipe(
        select(getEmployee),
        first(),
        map((employee) => ({
          ...employee,
          fullName: employee.employeeFullName,
        }))
      )
      .subscribe((employee: EmployeeDTO | any) =>
        this._employees$.next([employee])
      );
  }

  private employeesForOfficeParams(
    eaOfficeId: string[],
    includeInactiveEmployees: boolean
  ): any {
    if (includeInactiveEmployees) {
      return {
        limit: 2000,
        eaOfficeId,
        status: "deleted,active,inactive",
        officeMappingStatus: "deleted,active",
      };
    } else {
      return {
        limit: 2000,
        eaOfficeId,
      };
    }
  }
}
