import { Component, Input } from "@angular/core";

@Component({
  selector: "search-contact-list-header",
  template: `
    <div class="list-header">
      <span>{{ title }}</span>
    </div>
  `,
  styleUrls: ["./list-header.component.scss"],
})
export class SearchContactListHeaderComponent {
  @Input() title: String;
}
