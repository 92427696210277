import { SalesMeetingSummaryStatisticsSourceResponse } from "@app/statistics/boxes/sales-meetings/utils";
import {
  SalesMeetingSummaryStatisticsEmployeeResponse,
  SalesMeetingSummaryStatisticsOfficeResponse,
  SalesMeetingSummaryStatisticsTotalResponse,
} from "@app/statistics/statistics-sales-meetings/utils";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import {
  SalesMeetingStatisticsEmployeeResponse,
  SalesMeetingStatisticsOfficeResponse,
  SalesMeetingStatisticsTotalResponse,
} from "../utils";
import * as smActions from "./statistics-sales-meeting.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

// Results
export interface SalesMeetingResultsState {
  loading: boolean;
  total: SalesMeetingStatisticsTotalResponse;
  offices: SalesMeetingStatisticsOfficeResponse[];
  employees: SalesMeetingStatisticsEmployeeResponse[];
  employee: SalesMeetingStatisticsEmployeeResponse;
  filters: Record<string, unknown>;
}

export const initialStateResults: SalesMeetingResultsState = {
  loading: false,
  total: null,
  offices: null,
  employees: null,
  employee: null,
  filters: null,
};

// Booked
export interface SalesMeetingBookedState {
  loading: boolean;
  total: SalesMeetingSummaryStatisticsTotalResponse;
  offices: SalesMeetingSummaryStatisticsOfficeResponse[];
  employees: SalesMeetingSummaryStatisticsEmployeeResponse[];
  employee: SalesMeetingSummaryStatisticsEmployeeResponse;
}

export const initialStateBooked: SalesMeetingBookedState = {
  loading: false,
  total: null,
  offices: null,
  employees: null,
  employee: null,
};

// Sources
export interface SalesMeetingSourcesState {
  loading: boolean;
  total: SalesMeetingSummaryStatisticsTotalResponse;
  sources: SalesMeetingSummaryStatisticsSourceResponse[];
}

export const initialStateSources: SalesMeetingSourcesState = {
  loading: false,
  total: null,
  sources: null,
};

// Results
export function statisticsSalesMeetingReducerResults(
  state: SalesMeetingResultsState,
  action: Action
): SalesMeetingResultsState {
  return salesMeetingReducer(state, action);
}

const salesMeetingReducer = createReducer(
  initialStateResults,
  on(
    smActions.getResultsForChainRequest,
    smActions.getResultsForOfficeRequest,
    smActions.getResultsForEmployeeRequest,
    (state) => ({
      ...initialStateResults,
      loading: true,
      filters: state.filters,
    })
  ),
  on(smActions.getResultsForChainSuccess, (state, { total, offices }) => ({
    ...state,
    loading: false,
    total,
    offices,
  })),
  on(smActions.getResultsForOfficeSuccess, (state, { total, employees }) => ({
    ...state,
    loading: false,
    total,
    employees,
  })),
  on(smActions.getResultsForEmployeeSuccess, (state, { employee }) => ({
    ...state,
    loading: false,
    employee,
  })),
  on(
    smActions.getResultsForChainFail,
    smActions.getResultsForOfficeFail,
    smActions.getResultsForEmployeeFail,
    (state) => ({ ...state, loading: false })
  ),
  on(smActions.setFilters, (state, { filters }) => ({ ...state, filters }))
);

// Results
const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");

const getStateResults = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.salesMeetingResults
);

export const getLoadingForResult = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.loading
);

export const getTotalForResult = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.total
);

export const getOfficesForResult = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.offices
);

export const getEmployeesForResult = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.employees
);

export const getEmployeeForResult = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.employee
);

export const getFilters = createSelector(
  getStateResults,
  (state: SalesMeetingResultsState) => state.filters
);

// Booked
export function statisticsSalesMeetingReducerBooked(
  state: SalesMeetingBookedState,
  action: Action
): SalesMeetingBookedState {
  return bookedReducer(state, action);
}

const bookedReducer = createReducer(
  initialStateBooked,
  on(
    smActions.getBookedForChainRequest,
    smActions.getBookedForOfficeRequest,
    smActions.getBookedForEmployeeRequest,
    (state) => ({ ...state, loading: true })
  ),
  on(smActions.getBookedForChainSuccess, (state, { total, offices }) => ({
    ...state,
    loading: false,
    total,
    offices,
  })),
  on(smActions.getBookedForOfficeSuccess, (state, { total, employees }) => ({
    ...state,
    loading: false,
    total,
    employees,
  })),
  on(smActions.getBookedForEmployeeSuccess, (state, { employee }) => ({
    ...state,
    loading: false,
    employee,
  })),
  on(
    smActions.getBookedForChainFail,
    smActions.getBookedForOfficeFail,
    smActions.getBookedForEmployeeFail,
    (state) => ({ ...state, loading: false })
  )
);

export const getStateBooked = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.salesMeetingBooked
);

export const getLoadingBooked = createSelector(
  getStateBooked,
  (state: SalesMeetingBookedState) => state.loading
);

export const getTotalBooked = createSelector(
  getStateBooked,
  (state: SalesMeetingBookedState) => state.total
);

export const getOfficesBooked = createSelector(
  getStateBooked,
  (state: SalesMeetingBookedState) => state.offices
);

export const getEmployeesBooked = createSelector(
  getStateBooked,
  (state: SalesMeetingBookedState) => state.employees
);

export const getEmployeeBooked = createSelector(
  getStateBooked,
  (state: SalesMeetingBookedState) => state.employee
);

// Sources
export function salesMeetingSourcesReducer(
  state: SalesMeetingSourcesState,
  action: Action
): SalesMeetingSourcesState {
  return sourceReducer(state, action);
}

const sourceReducer = createReducer(
  initialStateSources,
  on(smActions.getSourcesRequest, () => ({
    ...initialStateSources,
    loading: true,
  })),
  on(smActions.getSourcesSuccess, (state, { total, sources }) => ({
    ...state,
    loading: false,
    total,
    sources,
  })),
  on(smActions.getSourcesFail, (state) => ({ ...state, loading: false }))
);

export const getStateSources = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.salesMeetingSources
);

export const getLoadingSources = createSelector(
  getStateSources,
  (state: SalesMeetingSourcesState) => state.loading
);

export const getTotalSources = createSelector(
  getStateSources,
  (state: SalesMeetingSourcesState) => state.total
);

export const getSources = createSelector(
  getStateSources,
  (state: SalesMeetingSourcesState) => state.sources
);
