<app-q-modal>
  <ng-container slot="body">
    <div class="modal-body">
      <iframe
        *ngIf="data.url && data.url.length > 0"
        [src]="data.url | safe">
      </iframe>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <button class="q-btn--cancel" mat-dialog-close (click)="closeModal();">{{'close' | translate | uppercase}}</button>
  </ng-container>
</app-q-modal>
