import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "separateThousands" })
export class ThousandSeparatorPipe implements PipeTransform {
  transform(value: any): string {
    if (!value) {
      return value;
    }

    let val;
    if (typeof value === "string" && value.charAt(0) === "-") {
      val = value.toString().replace("-", "");
    } else {
      val = value;
    }

    let str = val.toString().replace(/\s/g, "");
    str = (+str).toString();
    const remainder = str.length % 3;
    let result = (
      str.substr(0, remainder) +
      str.substr(remainder).replace(/(\d{3})/g, " $1")
    ).trim();

    if (typeof value === "string" && value.charAt(0) === "-") {
      result = "- " + result;
    }

    return result;
  }
}
