<section class="office-dropdown">
  <div [formGroup]="parentFormGroup" class="input-iconfield">
    <i class="icon icon-location-pin"></i>
    <select [formControlName]="parentFormControlName">
      <option translate [disabled]="disableSelectOffice" selected value="">
        select_office
      </option>
      <option *ngFor="let office of offices" [value]="office.eaOfficeId">
        {{ office.officeName | removeOfficePrefix | async }}
      </option>
    </select>
  </div>
</section>
