import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { BehaviorSubject, catchError, map, tap } from "rxjs";

export interface QSearchObjectParams {
  keyword?: string;
  eaEmployeeId?: string;
  eaOfficeId?: string;
  isAdmin?: boolean;
  superSearchTemplate?: string;
  publishOnNet?: string;
  filterStatus?: string;
}

@Injectable({ providedIn: "root" })
export class QSearchObjectService {
  loading$ = new BehaviorSubject<boolean>(false);

  constructor(private apiService: ApiService) {}

  doSearch(parameters: QSearchObjectParams) {
    this.loading$.next(true);
    const {
      keyword,
      eaEmployeeId,
      eaOfficeId,
      isAdmin,
      superSearchTemplate,
      filterStatus,
      publishOnNet,
    } = parameters;

    const nonAdminParams = {
      eaEmployeeId,
      eaOfficeId,
    };

    const template =
      superSearchTemplate && superSearchTemplate.length > 0
        ? superSearchTemplate
        : "crm3";

    let params = {
      keyword,
      groups: "locations",
      listNumber: "40",
      template: template,
      filterStatus:
        filterStatus ??
        "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,26,29,30,33,34,50,51,52",
      publishOnNet: publishOnNet ?? "true,false,limited",
    };

    if (!isAdmin) {
      params = { ...nonAdminParams, ...params };
    }

    return this.apiService.get("search", params).pipe(
      map((response: any) => response?.matches?.locations?.entities),
      tap(() => this.loading$.next(false)),
      catchError(() => {
        this.loading$.next(false);
        return [];
      })
    );
  }
}
