import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, ContactProfile, getFullName } from "@app/models";
import { CONTACT_EDIT } from "@app/shared/utils/tab-types";
import * as ccActions from "@app/sidebar/contacts/ngrx/create-contact.actions";
import * as contactActions from "@app/contacts/contact.actions";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { CompanyFormComponent } from "@app/sidebar/shared/company-form/company-form.component";
import { ContactFormComponent } from "@app/sidebar/shared/contact-form/contact-form.component";
import { EstateFormComponent } from "@app/sidebar/shared/estate-form/estate-form.component";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { getTab } from "@app/sidebar/ngrx/sidebar.reducer";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  debounceTime,
  delay,
  filter,
  first,
  map,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { ofType } from "@ngrx/effects";

@Component({
  selector: "edit-contact",
  templateUrl: "./edit-contact.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./edit-contact.component.scss",
  ],
})
export class EditContactComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;

  @ViewChild("companyForm", { static: false })
  companyForm: CompanyFormComponent;

  @ViewChild("estateForm", { static: false })
  estateForm: EstateFormComponent;

  tabType = CONTACT_EDIT;
  tab$: Observable<SidebarTab>;
  contact$: Observable<Contact>;
  headerLabel: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private dispatcher: ActionsSubject,
    private phoneNumberService: PhoneNumberService,
    private contactService: ContactService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.contactService.getById(params["id"], { getSSN: true }).subscribe();
      this.contact$ = this.contactService.entityMap$.pipe(
        map((entities) => entities[params["id"]]),
        filter((contact) => !!contact)
      );
    });
    this.mapStateToProps();

    this.contact$
      .pipe(
        map((contact) => getFullName(contact)),
        switchMap((name) => this.translate.get("edit_{name}", { name })),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((label) => {
        if (!this.headerLabel) {
          this.headerLabel = label;
        }
      });

    this.dispatcher
      .pipe(
        ofType(sidebarActions.resetTab),
        filter(({ tabType }) => tabType === CONTACT_EDIT),
        switchMap(() => this.tab$.pipe(first())),
        withLatestFrom(this.contact$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([tab, contact]) => {
        if (contact?.contactType === "company") {
          this.companyForm.form.setValue(tab.initialValue);
        } else if (contact?.contactType === "estate") {
          this.estateForm.form.setValue(tab.initialValue);
        } else {
          this.contactForm.form.setValue(tab.initialValue);
        }
      });
  }

  mapStateToProps() {
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));
  }

  ngAfterViewInit() {
    this.tab$
      .pipe(
        filter((value) => !!value),
        first(),
        withLatestFrom(this.contact$)
      )
      .subscribe(([tab, contact]) => {
        if (tab.dirty) {
          if (contact?.contactType === "company") {
            this.companyForm.form.setValue(tab.currentValue);
          } else if (contact?.contactType === "estate") {
            this.estateForm.form.setValue(tab.currentValue);
          } else {
            this.contactForm.form.setValue(tab.currentValue);
          }
        }
        this.startContactChangesStream(tab.dirty ? 1 : 0);
        this.startValueChangesStream();
      });
  }

  startContactChangesStream(skipNumber = 0) {
    this.contact$
      .pipe(
        skip(skipNumber),
        delay(1), // TODO: how to wait until contact has been set in the contact form child component
        takeUntil(this.unsubscribe$)
      )
      .subscribe((contact) => {
        let formValues;
        if (contact?.contactType === "company") {
          formValues = this.companyForm.form.value;
        } else if (contact?.contactType === "estate") {
          formValues = this.estateForm.form.value;
        } else {
          formValues = this.contactForm.form.value;
        }
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: formValues,
          })
        );
      });
  }

  startValueChangesStream() {
    this.contact$
      .pipe(
        first(),
        map((contact) => contact?.contactType)
      )
      .subscribe((type) => {
        let form;
        if (type === "company") {
          form = this.companyForm.form;
        } else if (type === "estate") {
          form = this.estateForm.form;
        } else {
          form = this.contactForm.form;
        }
        form.valueChanges
          .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
          .subscribe((formValues) =>
            this.store.dispatch(
              sidebarActions.setTabValue({
                tabType: this.tabType,
                value: formValues,
              })
            )
          );
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  handleSubmit(valid: boolean): void {
    if (valid) {
      this.contact$.pipe(first()).subscribe((contact) => {
        if (contact?.contactType === "company") {
          this.submitCompany(contact);
        } else if (contact?.contactType === "estate") {
          this.submitEstate(contact);
        } else {
          this.submitPerson();
        }
      });
    }
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              primary: ["contacts", contact.contactId],
              sidebar: null,
            },
          },
        ],
      })
    );
  }

  submit() {
    this.contact$
      .pipe(
        first(),
        map((contact) => contact?.contactType)
      )
      .subscribe((type) => {
        if (type === "company") {
          this.companyForm.submit();
        } else if (type === "estate") {
          this.estateForm.submit();
        } else {
          this.contactForm.submit();
        }
      });
  }

  private submitPerson() {
    this.contact$.pipe(first()).subscribe((contact) => {
      let contactProfileParams = null;
      if (
        contact?.profile?.ownsResidence !==
        this.contactForm.form.get("ownsResidence").value
      ) {
        contactProfileParams = {
          ownsResidence: this.contactForm.form.get("ownsResidence").value,
        };
      }
      this.patchContact(
        contact,
        this.contactForm.buildRequestObject(),
        contactProfileParams
      );
    });
  }

  private submitCompany(contact: Contact) {
    const msisdn = this.phoneNumberService.toLegacyFormat(
      this.companyForm.form.get("msisdn").value,
      this.companyForm.form.get("msisdnCountry").value
    );
    const params = {
      ...this.companyForm.form.value,
      contactType: "company",
      msisdn,
    };
    this.patchContact(contact, params);
  }

  private submitEstate(contact: Contact) {
    const fullName = this.estateForm.form.get("estate").get("firstName").value;
    const msisdn = this.phoneNumberService.toLegacyFormat(
      this.estateForm.form.get("estateContact").get("msisdn").value,
      this.estateForm.form.get("estateContact").get("msisdnCountry").value
    );
    const params = {
      ...this.estateForm.form.get("estate").value,
      contactType: "estate",
      msisdn,
      email: this.estateForm.form.get("estateContact").get("email").value,
    };

    const lastSpace = fullName.trim().lastIndexOf(" ");
    if (lastSpace > 0) {
      const firstName = fullName.slice(0, lastSpace).trim();
      const familyName = fullName.slice(lastSpace + 1).trim();
      params.firstName = firstName;
      params.familyName = familyName;
    }

    this.patchContact(contact, params);
  }

  private patchContact(
    contact,
    params,
    patchProfileParams?: Partial<ContactProfile>
  ) {
    this.contactService
      .patch(contact.contactId, params, true)
      .pipe(
        map((contact: Contact) => {
          params.type === "potentialbuyer"
            ? this.store.dispatch(
                ccActions.addContactToShowing(contact, params.eaOid, [])
              )
            : this.store.dispatch(ccActions.updateContactSuccess({ contact }));

          this.store.dispatch(contactActions.getContactSuccess({ contact }));

          if (!!patchProfileParams) {
            this.contactService
              .patchProfile(contact.contactId, patchProfileParams)
              .subscribe();
          }
        })
      )
      .subscribe();
  }
}
