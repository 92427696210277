export const getContactIcon = (
  contactType: "company" | "person" | "estate" = "person"
): string => {
  if (contactType === "company") {
    return "building";
  } else if (contactType === "estate") {
    return "house-night";
  } else {
    return "user";
  }
};

export const getContactIconClass = (
  contactType: "company" | "person" | "estate" = "person"
): string => {
  return `contact-icon--${contactType}`;
};
