import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as routerActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { API_DATE_FORMAT, API_LIMIT_MAX } from "@app/shared/utils/api-defaults";
import { UNAUTHORIZED } from "@app/shared/utils/http-status-codes";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { BehaviorSubject, delay, map, Observable } from "rxjs";
import { EventDTO } from "./models/event-dto";

@Injectable()
export class CalendarWidgetService {
  private _loading$ = new BehaviorSubject<boolean>(false);

  private _loaded$ = new BehaviorSubject<boolean>(false);

  private _list$ = new BehaviorSubject<EventDTO[]>([]);

  constructor(
    private apiService: ApiService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  get loaded$(): Observable<boolean> {
    return this._loaded$.asObservable();
  }

  get list$(): Observable<EventDTO[]> {
    return this._list$.asObservable();
  }

  getEventsRequest(dateFrom: Date, dateTo: Date): void {
    this._loading$.next(true);
    this.apiService
      .getWithoutErrorHandling(
        "events",
        this.getRequestParamsFromDates(dateFrom, dateTo)
      )
      .pipe(
        delay(0),
        map((response: PaginationListDTO) =>
          response.rows.map((event: EventDTO) => new EventDTO(event))
        )
      )
      .subscribe({
        next: (list: EventDTO[]) => {
          this._loading$.next(false);
          this._loaded$.next(true);
          this._list$.next(list);
        },
        error: (error) => {
          if (error.status === UNAUTHORIZED) {
            this.store.dispatch(
              toastActions.danger(
                { message: "invalid_exchange_credentials", duration: 15000 },
                {
                  label: this.translate.instant("account_settings"),
                  action: routerActions.go({
                    path: ["crm", "settings", "exchange"],
                  }),
                  icon: "icon icon-settings",
                }
              )
            );
          }
          this._loading$.next(false);
          this._loaded$.next(false);
          this._list$.next([]);
        },
      });
  }

  private getRequestParamsFromDates(dateFrom: Date, dateTo: Date): any {
    return {
      getWeather: true,
      type: "showing,salesmeeting,exchange",
      limit: API_LIMIT_MAX,
      startFrom: moment(dateFrom).startOf("day").format(API_DATE_FORMAT),
      startTo: moment(dateTo).endOf("day").format(API_DATE_FORMAT),
    };
  }
}
