<div
  class="search-card search-card-details search-card-details__object object-card"
>
  <section
    (click)="navigate(object.eaOid)"
    class="search-card-details__header search-card-details__header__object"
  >
    <div
      *ngIf="!object.objectImage"
      class="search-card-details__header__circle search-card-details__header__circle--object"
    >
      <app-icon name="home-lg-alt"></app-icon>
    </div>
    <img
      *ngIf="object.objectImage"
      (error)="updateImageOnError($event)"
      src="{{ object.objectImage }}"
      class="search-card-details__header__image search-card-details__header__image--object"
      alt=""
    />
  </section>

  <section
    (click)="navigate(object.eaOid)"
    class="search-card-details__person-contact-info ellipsify"
  >
    <h4 class="name">
      <app-property-name
        [property]="object"
        disableFullAddress="true"
      ></app-property-name>
    </h4>
    <p *ngIf="object.zip && object.city">
      <i class="icon-location-pin"></i> {{ object.zip }} {{ object.city }}
    </p>
    <p *ngIf="!(object.zip && object.city)">
      <i class="icon-location-pin"></i>
      <span class="muted" translate> - - -</span>
    </p>
    <p
      *ngIf="!!object.apartmentNumber"
      [matTooltip]="'appartment_number' | translate"
      matTooltipClass="q-tooltip"
    >
      <app-icon name="hashtag"></app-icon> {{ object.apartmentNumber }}
    </p>

    <p
      *ngIf="object.noOfRooms"
      [matTooltip]="'no_of_rooms' | translate"
      matTooltipClass="q-tooltip"
    >
      <app-icon name="door-closed"></app-icon> {{ object.noOfRooms }}
    </p>
    <p *ngIf="!object.noOfRooms">
      <app-icon name="door-closed"></app-icon>
      <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="object.statusName">
      <i class="icon-info"></i> {{ object.statusName }}
    </p>

    <p *ngIf="object.employeeFullName">
      <i class="icon-user"></i>{{ object.employeeFullName }}
    </p>
    <p *ngIf="!object.employeeFullName">
      <i class="icon-user"></i> <span class="muted" translate> - - -</span>
    </p>

    <p *ngIf="object.dateType1 && shouldShowDate(1)">
      <i class="icon-calendar"></i> {{ getDateTitle(1) | translate }}:
      {{ getDateFormatted(object.dateType1) }}
    </p>

    <p *ngIf="object.dateType2 && shouldShowDate(2)">
      <i class="icon-calendar"></i> {{ getDateTitle(2) | translate }}:
      {{ getDateFormatted(object.dateType2) }}
    </p>

    <p *ngIf="object.dateType3 && shouldShowDate(3)">
      <i class="icon-calendar"></i> {{ getDateTitle(3) | translate }}:
      {{ getDateFormatted(object.dateType3) }}
    </p>

    <p *ngIf="object?.sellers?.length > 0">
      {{ "seller" | translate }}:
      <ng-container *ngFor="let seller of object.sellers">
        <span>
          <app-contact-name
            [contactType]="seller?.contactType"
            [contactId]="seller?.contactId"
          >
            {{ seller.firstName }} {{ seller.familyName }}
          </app-contact-name>
        </span>
      </ng-container>
    </p>

    <p *ngIf="object?.buyers?.length > 0">
      {{ "buyer" | translate }}:
      <ng-container *ngFor="let buyer of object.buyers">
        <span>
          <app-contact-name
            [contactType]="buyer?.contactType"
            [contactId]="buyer?.contactId"
          >
            {{ buyer.firstName }} {{ buyer.familyName }}
          </app-contact-name>
        </span>
      </ng-container>
    </p>

    <p *ngIf="object?.owners?.length > 0">
      {{ "owner" | translate }}:
      <ng-container *ngFor="let owner of object.owners">
        <span>
          <app-contact-name
            [contactType]="owner?.contactType"
            [contactId]="owner?.contactId"
          >
            {{ owner.firstName }} {{ owner.familyName }}
          </app-contact-name>
        </span>
      </ng-container>
    </p>

    <p *ngIf="object?.tenants?.length > 0">
      {{ "tenant" | translate }}:
      <ng-container *ngFor="let tenant of object.tenants">
        <span>
          <app-contact-name
            [contactType]="tenant?.contactType"
            [contactId]="tenant?.contactId"
          >
            {{ tenant.firstName }} {{ tenant.familyName }}
          </app-contact-name>
        </span>
      </ng-container>
    </p>
  </section>
  <section
    *ngIf="showExternalProviderLink && !(objectOverViewIsEnabled$ | async)"
    class="external-provider-link"
  >
    <div dropdown>
      <button
        dropdownToggle
        (click)="getExternalProviderLinks.emit(object.eaOid)"
        type="button"
        class="btn btn-secondary--ghost btn-external-provider"
      >
        <i class="icon-layers"></i>
        {{ externalProviderName }}
        <i class="caret"></i>
      </button>
      <ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
        <i class="icon-layers dropdown-menu__main-icon"></i>
        <ng-container *ngIf="!externalProviderLinksLoading; else spinner">
          <li *ngFor="let link of externalProviderLinks" role="menuitem">
            <a class="link-element" (click)="openExternalLink($event, link)">
              <ng-container
                *ngIf="
                  !(ssoifyLoading$ | async) ||
                    clickedPresentableLink?.link !== link.link;
                  else linkLoading
                "
              >
                <span>
                  {{
                    link.translatedKey
                      ? link.translatedKey
                      : (link.key.toLowerCase() | translate)
                  }}
                </span>
                <i class="icon-share-alt"></i>
              </ng-container>
              <ng-template #linkLoading>
                <spinner [size]="'1rem'"></spinner>
              </ng-template>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
  </section>
  <div
    *ngIf="object.eaEmployeeId === eaEmployeeId"
    class="marker"
    tooltip="{{ 'my_object' | translate }}"
    placement="left"
  >
    <i class="icon-user"></i>
  </div>

  <div class="actions-menu-container">
    <app-actions-menu
      *ngIf="!(isMobile$ | async)"
      [dropdownAlignment]="'RIGHT'"
      style="display: flex; align-items: center; margin-right: 0.25rem"
    >
      <ng-container buttons>
        <li role="menuitem" *ngIf="showCreatePrivateShowing$ | async">
          <app-q-button
            [label]="'create_showing'"
            [icon]="'users'"
            [fullWidth]="true"
            (click)="createShowing($event, object.eaOid)"
          ></app-q-button>
        </li>
        <li role="menuitem">
          <app-q-button
            [label]="'open_in_new_tab'"
            [icon]="'share-square'"
            [fullWidth]="true"
            (click)="openInNewTab($event, object.eaOid)"
          ></app-q-button>
        </li>
        <li *ngIf="!!(hasObjectLinks$ | async) && !!(canOpenInErp$ | async)">
          <app-q-button
            label="open_in_kivi"
            icon="external-link"
            [fullWidth]="true"
            (click)="openMetaDataLink()"
          ></app-q-button>
        </li>
      </ng-container>
    </app-actions-menu>
  </div>
</div>

<ng-template #spinner>
  <spinner [size]="'2rem'"></spinner>
</ng-template>
