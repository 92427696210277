import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import moment from "moment";
import { Observable } from "rxjs";

@Component({
  selector: "app-kpi-rented-objects-commission",
  templateUrl: "./kpi-rented-objects-commission.component.html",
  styleUrls: ["./kpi-rented-objects-commission.component.scss"],
})
export class KpiRentedObjectsCommissionComponent {
  @Input() resources$: Observable<any>;

  constructor(private router: Router) {}

  navigateToObject(eaOid: string, event: Event): void {
    event.preventDefault();
    this.router.navigate([
      "/crm",
      {
        outlets: {
          primary: ["showings", eaOid],
          sidebar: null,
          modal: [],
        },
      },
    ]);
  }

  formatDate(startTime: string) {
    return moment(startTime, "YYYYMMDDHHmmss").toDate();
  }
}
