<h3 *ngIf="showHeader">
  <app-icon name="rectangle-ad" size="large"></app-icon>&nbsp;<span translate
    >marketing_reports</span
  >
  <span class="icon-tooltip"
    ><app-tooltip-icon
      [translationKey]="'marketing_reports_widget_tooltip'"
    ></app-tooltip-icon
  ></span>
</h3>

<div class="widget-container">
  <div class="widget-container__filters">
    <app-segmented-controls
      [controls]="dataTypeControls"
      [active]="form.get('dataTypes').value"
      (change)="form.get('dataTypes').setValue($event)"
    ></app-segmented-controls>
  </div>
  <app-statistics-adfenix-reporting-table
    [filters]="form.value"
    [enableCustomisation]="false"
    [triggerDataFetch$]="triggerDataFetch$"
  ></app-statistics-adfenix-reporting-table>
</div>
