import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { SALES_MEETING } from "@app/shared/utils/tab-types";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Action, select, Store } from "@ngrx/store";
import {
  debounceTime,
  delay,
  filter,
  first,
  Observable,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as fromSidebar from "../../ngrx/sidebar.reducer";
import { SalesMeetingFormComponent } from "../sales-meeting-form/sales-meeting-form.component";
import {
  getSalesMeetingHasError,
  getSalesMeetingProcessing,
} from "../ngrx/sales-meeting.reducer";

@Component({
  selector: "sales-meeting-create",
  templateUrl: "./sales-meeting-create.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./sales-meeting-create.component.scss",
  ],
})
export class SalesMeetingCreateComponent implements OnInit, OnDestroy {
  @ViewChild(SalesMeetingFormComponent, { static: false })
  smForm: SalesMeetingFormComponent;

  tabType = SALES_MEETING;
  tab$: Observable<SidebarTab>;
  loading = false;

  processing$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.route.data.pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.processing$ = this.store.pipe(select(getSalesMeetingProcessing));
  }

  connectTabToStore(): void {
    this.tab$.pipe(first()).subscribe((tab) => {
      if (tab.dirty) {
        this.smForm.form.setValue(tab.currentValue);
      } else {
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: this.smForm.form.getRawValue(),
          })
        );
      }
      this.smForm.form.valueChanges
        .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
        .subscribe(() =>
          this.store.dispatch(
            sidebarActions.setTabValue({
              tabType: this.tabType,
              value: this.smForm.form.getRawValue(),
            })
          )
        );
    });
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(action: Action): void {
    this.store.dispatch(action);
    this.store
      .pipe(
        delay(500),
        select(getSalesMeetingProcessing),
        filter((processing) => !processing),
        withLatestFrom(this.store.pipe(select(getSalesMeetingHasError))),
        first()
      )
      .subscribe(([_processing, hasError]) => {
        if (!hasError) {
          this.store.dispatch(
            sidebarActions.closeTab({ tabType: SALES_MEETING })
          );
        }

        this.loading = false;
      });
  }
}
