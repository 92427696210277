<h3>
  <app-icon name="smile" size="large"></app-icon>&nbsp;<span translate
    >nps</span
  >
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="widget-container__filters" #filterContainer>
    <app-segmented-controls
      *ngIf="showSegment"
      [controls]="filters"
      [active]="quickFilter"
      (change)="changeFilter($event)"
    ></app-segmented-controls>

    <app-dropdown-element
      *ngIf="!showSegment"
      [items]="dropDownFilters"
      [preselectValue]="quickFilter"
      [multiple]="false"
      [newStyle]="true"
      [required]="true"
      [path]="['dateFilter']"
      width="100%"
      (valueEntered)="changeFilter($event)"
    >
    </app-dropdown-element>
  </div>
  <div
    class="widget-container__data"
    *ngIf="statistics$ | async as stats; else noData"
  >
    <span *ngIf="useAverageValue$ | async; else standardValue">{{
      stats.averageNPSValue | qNumber
    }}</span>
    <ng-template #standardValue
      ><span>{{ stats.NPSValue | qNumber }}</span></ng-template
    >
    <p>
      {{
        "based_on_responses"
          | translate : { responses: stats.responsesWithNPSValues }
      }}
    </p>
  </div>
  <ng-template #noData>
    <div class="no-data">
      <span translate>no_data_found</span>
    </div>
  </ng-template>
</div>
