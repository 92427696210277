import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";
import { QObject } from "@app/models";
import { getCountry, getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import * as formUtils from "@app/shared/utils/form-utils";
import { ObjectType } from "@app/shared/utils/q-object-types";
import {
  MATCH_WHITE_SPACE_GLOBAL,
  ONLY_DIGITS,
  ONLY_DIGITS_SPECIALS_LIGHT,
  ONLY_DIGITS_WHITESPACE,
} from "@app/shared/utils/regex-patterns";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  debounceTime,
  filter,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { ResidenceFormData } from "./models/residence-form-data";

@Component({
  selector: "residence-form",
  templateUrl: "./residence-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./residence-form.component.scss",
  ],
})
export class ResidenceFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() residence: ResidenceFormData;
  @Input() processing: boolean;
  @Input() showSauna = false;
  @Input() objectTypes: ObjectType[];
  @Input() config: ResidenceSidebarConfig;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<QObject>();

  form: FormGroup;

  unSubscribe$ = new Subject<void>();

  // if address suggester and validation should be enabled
  addressSuggesterFeature$: Observable<Feature>;
  addressValidationFeature$: Observable<Feature>;
  addressValues$: Observable<Address>;
  selectedAddress: Address;
  defaultCountryCodeISO = "EN";

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initStoreObservers();
    this.buildForm();
    this.initAddressEvents();
  }

  ngOnChanges(changes: any) {
    if (changes.residence && this.form) {
      this.form.setValue(this.residence);
    }

    if (changes.processing && this.form) {
      if (this.processing) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initStoreObservers(): void {
    this.addressSuggesterFeature$ = this.store.pipe(
      select(getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressValidationFeature$ = this.store.pipe(
      select(getFeature(features.ADDRESS_VALIDATION))
    );

    this.store
      .pipe(
        select(getCountry),
        takeUntil(this.unSubscribe$),
        take(2), // initial state & setting value
        map((v) => v.toUpperCase())
      )
      .subscribe(
        (countryCodeISO) => (this.defaultCountryCodeISO = countryCodeISO)
      );
  }

  buildForm() {
    this.form = this.fb.group({
      objType: [""],
      address: this.fb.group({
        street: ["", Validators.required],
        zip: ["", Validators.required],
        city: ["", Validators.required],
      }),
      valuation: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
      fee: ["", Validators.pattern(ONLY_DIGITS_WHITESPACE)],
      area: ["", Validators.pattern(ONLY_DIGITS_SPECIALS_LIGHT)],
      rooms: ["", Validators.pattern(ONLY_DIGITS_SPECIALS_LIGHT)],
      year: ["", Validators.pattern(ONLY_DIGITS)],
      code: "",
      propertyDescription: "",
      floor: "",
      fireplace: "Unknown",
      balcony: "Unknown",
      elevator: "Unknown",
      sauna: "Unknown",
      eaOid: "",
    });

    if (this.residence) {
      this.form.setValue(this.residence);
      this.selectedAddress = {
        street: this.residence.address.street,
        zip: this.residence.address.zip,
        city: this.residence.address.city,
      };
    }
  }

  initAddressEvents() {
    this.addressValues$ = this.form.get("address").valueChanges.pipe(
      debounceTime(200),
      filter((values) => values.street)
    );
  }

  getAreaPlaceholder(): Observable<string> {
    return this.translateService.get("area", {
      unit: this.translateService.instant("area_unit"),
    }); // TODO fetch unit from config
  }

  submit(): void {
    if (this.form.valid) {
      const residence = this.getQObjectFromFormData();
      this.onSubmit.emit(residence);
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  getQObjectFromFormData(): QObject {
    let street = this.form?.get("address")?.get("street")?.value;

    if (
      this.selectedAddress?.street &&
      this.form?.get("address")?.get("street")?.value
    ) {
      street = this.selectedAddress.street;
    }

    return new QObject({
      eaOid: this.form.get("eaOid").value,
      objType: this.form.get("objType").value,
      street,
      zip: this.form.get("address.zip").value,
      city: this.form.get("address.city").value,
      built: this.form.get("year").value,
      balcony: this.form.get("balcony").value,
      elevator: this.form.get("elevator").value,
      sauna: this.form.get("sauna").value,
      fireplace: this.form.get("fireplace").value,
      area: this.form.get("area").value,
      rooms: this.form.get("rooms").value,
      level: this.form.get("floor").value,
      accessCode: this.form.get("code").value,
      propertyDescription: this.form.get("propertyDescription").value,
      price: this.form
        .get("valuation")
        .value.replace(MATCH_WHITE_SPACE_GLOBAL, ""),
      monthlyFee: this.form
        .get("fee")
        .value.replace(MATCH_WHITE_SPACE_GLOBAL, ""),
    });
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleZipSelected(address: Address): void {
    const addressGroup = this.form.get("address");
    addressGroup.get("zip").setValue(address.zip);
    addressGroup.get("city").setValue(address.city);
    addressGroup.updateValueAndValidity();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleStreetSelected(address: Address): void {
    this.selectedAddress = address;
    const addressGroup = this.form.get("address");
    addressGroup.get("zip").setValue(address?.zip ?? null);
    addressGroup.get("city").setValue(address?.city ?? null);
    addressGroup.get("street").setValue(address?.street ?? null);
    addressGroup.updateValueAndValidity();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleAddressSuggestionSelected(address: Address): void {
    this.selectedAddress = address;
    const addressGroup = this.form.get("address");
    addressGroup.get("zip").setValue(address?.zip ?? null);
    addressGroup.get("city").setValue(address?.city ?? null);
    addressGroup.get("street").setValue(address?.street ?? null);
    addressGroup.updateValueAndValidity();
  }
}
