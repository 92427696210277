import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { BiddingStatus } from "@app/sidebar/potential-buyer/models/bidding-status";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as biddingActions from "@app/integrations/bidding/ngrx/bidding/bidding.actions";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import * as biddingStatusActions from "@app/sidebar/potential-buyer/ngrx/bidding/bidding.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface BiddingState {
  status: string;
  bid: string | null;
  bidId: string | null;
  bidLoading: boolean;
  bidSettingsForBidderLink: ExternalPresentableLink | null;
  bidSettingsForBidderLinkLoading: boolean;
  setAsWinningBidLink: ExternalPresentableLink | null;
  setAsWinningBidLinkLoading: boolean;
}

const initialState: BiddingState = {
  status: BiddingStatus.Unknown,
  bid: null,
  bidId: null,
  bidLoading: false,
  bidSettingsForBidderLink: null,
  bidSettingsForBidderLinkLoading: false,
  setAsWinningBidLink: null,
  setAsWinningBidLinkLoading: false,
};

export function biddingReducer(
  state: BiddingState,
  action: Action
): BiddingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    biddingStatusActions.fetchBiddingStatusSuccess,
    biddingStatusActions.updateBiddingStatusOptimistic,
    (state, { bidStatus }) => {
      return state.status === bidStatus
        ? { ...state }
        : { ...state, status: bidStatus };
    }
  ),
  on(biddingActions.fetchHighestBidForContactRequest, (state) => ({
    ...state,
    bid: null,
    bidLoading: true,
  })),
  on(
    biddingActions.fetchHighestBidForContactSuccess,
    (state, { bidId, bidAmount }) => ({
      ...state,
      bid: bidAmount,
      bidId: bidId,
      bidLoading: false,
    })
  ),
  on(biddingActions.fetchHighestBidForContactFailure, (state) => ({
    ...state,
    bid: null,
    bidLoading: false,
  })),
  on(
    biddingStatusActions.fetchBiddingSettingsForBidderLinkSuccess,
    (state, { payload }) => ({
      ...state,
      bidSettingsForBidderLink: payload,
      bidSettingsForBidderLinkLoading: false,
    })
  ),
  on(
    biddingStatusActions.fetchBiddingSettingsForBidderLinkFailure,
    (state) => ({
      ...state,
      bidSettingsForBidderLink: null,
      bidSettingsForBidderLinkLoading: false,
    })
  ),
  on(
    biddingStatusActions.fetchBiddingSettingsForBidderLinkRequest,
    (state) => ({
      ...state,
      setAsWinningBidLink: null,
      bidSettingsForBidderLinkLoading: true,
    })
  ),
  on(biddingStatusActions.resetBiddingSettingsForBidderLink, (state) => ({
    ...state,
    bidSettingsForBidderLink: null,
    bidSettingsForBidderLinkLoading: false,
  })),
  on(
    biddingStatusActions.fetchSetAsWinningBidLinkSuccess,
    (state, { payload }) => ({
      ...state,
      setAsWinningBidLink: payload,
      setAsWinningBidLinkLoading: false,
    })
  ),
  on(biddingStatusActions.fetchSetAsWinningBidLinkFailure, (state) => ({
    ...state,
    setAsWinningBidLink: null,
    setAsWinningBidLinkLoading: false,
  })),
  on(biddingStatusActions.fetchSetAsWinningBidLinkRequest, (state) => ({
    ...state,
    bidSettingsForBidderLink: null,
    setAsWinningBidLinkLoading: true,
  })),
  on(potentialBuyerActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.potentialBuyer.bidding
);

export const getBid = createSelector(
  getState,
  (state: BiddingState) => state.bid
);
export const getBidId = createSelector(
  getState,
  (state: BiddingState) => state.bidId
);
export const bidLoading = createSelector(
  getState,
  (state: BiddingState) => state.bidLoading
);
export const bidSettingsForBidderLink = createSelector(
  getState,
  (state: BiddingState) => state.bidSettingsForBidderLink
);
export const bidSettingsForBidderLinkLoading = createSelector(
  getState,
  (state: BiddingState) => state.bidSettingsForBidderLinkLoading
);
export const setAsWinningBidLink = createSelector(
  getState,
  (state: BiddingState) => state.setAsWinningBidLink
);
export const setAsWinningBidLinkLoading = createSelector(
  getState,
  (state: BiddingState) => state.setAsWinningBidLinkLoading
);
