import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: "localizedDate",
  pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: Date, pattern: string = "mediumDate"): any {
    const datePipe: DatePipe = new DatePipe(this.translateService.currentLang);
    try {
      return datePipe.transform(value, pattern);
    } catch (err) {
      return "";
    }
  }
}
