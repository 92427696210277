import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type Select = {
  label: string;
  value: string | number;
  disabled?: boolean;
}[];

@Component({
  selector: "app-q-select",
  templateUrl: "./q-select.component.html",
  styleUrls: ["./q-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QSelectComponent),
    },
  ],
})
export class QSelectComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder = "select";
  @Input() options: Select;
  @Input() invalid: boolean;
  @Input() inline: boolean;
  @Input() parentRef: HTMLElement = null;

  @ViewChild("element", { static: false }) elementRef: ElementRef;

  show = false;
  private _value = null;
  touched = false;
  disabled = false;
  placementTop = false;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private window: Window
  ) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggle() {
    this.show = !this.show;

    if (this.show && !!this.parentRef) {
      this.changeDetectorRef.detectChanges();
      if (!this.verticallyBound()) {
        this.placementTop = true;
      }
    }
  }

  onClick(value: any) {
    this.show = false;
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  getSelectedName() {
    return this.options?.find((option) => option.value === this.value)?.label;
  }

  isSelected(value) {
    return value === this.value;
  }

  onClickOutside() {
    this.show = false;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private verticallyBound() {
    const parentRect = this.parentRef?.getBoundingClientRect();
    const childRect = this.elementRef?.nativeElement?.getBoundingClientRect();

    return (
      parentRect?.bottom >= childRect?.bottom &&
      parentRect?.top <= childRect?.top &&
      childRect?.top >= 0 &&
      childRect?.bottom <= this.window.innerHeight
    );
  }
}
