<toast></toast>
<app-standard-modal></app-standard-modal>
<preview-modal></preview-modal>
<external-contact-service-modal></external-contact-service-modal>
<app-multi-tasker></app-multi-tasker>
<router-outlet></router-outlet>

<div class="lock-the-page hide">
  <h3 (click)="reloadPage()">
    {{ "reload_the_page" | translate }}
  </h3>
</div>
