<div class="progress-item">
  <div class="progress-item__spinner">
    <spinner size="1rem"></spinner>
  </div>
  <span class="progress-item__text"
    >({{ value }}/{{ max }}) {{ label | translate }}</span
  >
  <span
    class="progress-item__text progress-item__text--failed"
    *ngIf="failed > 0"
  >
    ({{ failed }} {{ "failed_tasks" | translate }})</span
  >
  <div class="progress-item__bar">
    <progressbar
      [type]="getProgressBarType(value, max)"
      [max]="max"
      [value]="value"
    ></progressbar>
  </div>
</div>
