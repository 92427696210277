<div
  class="showing-container"
  *ngIf="!!config?.displayAsDropdown; else noDropdown"
>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <span class="showing-date"
        >{{
          getSelectedSlot(showing.slots)
            ? (formatDate(getSelectedSlot(showing.slots).from) | momentify)
            : showing.from
        }}
      </span>
      <ng-container *ngIf="showing.slots.length === 0; else showingSlotHeader">
        <span class="attendance-status" *ngIf="isSelected(Status.Registered)"
          >&nbsp;- {{ "registered" | translate }}
        </span>
        <span class="attendance-status" *ngIf="isSelected(Status.NotAttended)"
          >&nbsp;- {{ "not_attended" | translate }}
        </span>
        <span class="attendance-status" *ngIf="isSelected(Status.Attended)"
          >&nbsp;- {{ "attended" | translate }}
        </span>
      </ng-container>
      <ng-template #showingSlotHeader>
        <ng-container *ngIf="getSelectedSlot(showing.slots) as slot">
          <span
            class="attendance-status"
            *ngIf="isSlotSelected(Status.Registered, slot.eaShowingSlotId)"
            >&nbsp;- {{ "registered" | translate }}
          </span>
          <span
            class="attendance-status"
            *ngIf="isSlotSelected(Status.NotAttended, slot.eaShowingSlotId)"
            >&nbsp;- {{ "not_attended" | translate }}
          </span>
          <span
            class="attendance-status"
            *ngIf="isSlotSelected(Status.Attended, slot.eaShowingSlotId)"
            >&nbsp;- {{ "attended" | translate }}
          </span>
        </ng-container>
      </ng-template>
      <ng-container *ngIf="!showing.isPublic">
        &nbsp;<i
          [tooltip]="'private_showing' | translate"
          class="fa fa-eye"
        ></i>
      </ng-container>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div
        class="expansion-panel-content"
        *ngIf="showing.slots.length > 0; else showingWithoutSlots"
      >
        <ng-container *ngFor="let slot of sortShowingSlots(showing.slots)">
          <p>
            {{ formatDateToTime(slot.from) }} -
            {{ formatDateToTime(slot.to) }} ({{ slot.slotCapacity }}
            {{ "remaining_slots" | translate }})
          </p>
          <div class="btn-group">
            <button
              [class.active]="
                isSlotSelected(Status.Registered, slot.eaShowingSlotId)
              "
              [class.disabled]="isButtonDisabled(Status.Registered, slot)"
              [disabled]="internalLoading"
              [tooltip]="
                isButtonDisabled(Status.Registered, slot)
                  ? ('not_enough_slots_available' | translate)
                  : null
              "
              (click)="
                isButtonDisabled(Status.Registered, slot)
                  ? null
                  : handleSlotClick(Status.Registered, slot.eaShowingSlotId)
              "
              type="button"
              class="btn btn-default off-white"
            >
              {{ "registered" | translate }}
              <span
                [class.count-active]="
                  isSlotSelected(Status.Registered, slot.eaShowingSlotId)
                "
                class="attendance-count"
                *ngIf="
                  !!slotAttendancesCount &&
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.registered
                    .length > 0
                "
              >
                {{
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.registered
                    .length
                }}
              </span>
            </button>

            <button
              [class.active]="
                isSlotSelected(Status.Attended, slot.eaShowingSlotId)
              "
              [tooltip]="
                isButtonDisabled(Status.Attended, slot)
                  ? ('not_enough_slots_available' | translate)
                  : null
              "
              [disabled]="internalLoading"
              (click)="
                isButtonDisabled(Status.Attended, slot)
                  ? null
                  : handleSlotClick(Status.Attended, slot.eaShowingSlotId)
              "
              type="button"
              class="btn btn-default off-white"
              [class.disabled]="isButtonDisabled(Status.Attended, slot)"
            >
              {{ "attended" | translate }}
              <span
                [class.count-active]="
                  isSlotSelected(Status.Attended, slot.eaShowingSlotId)
                "
                class="attendance-count"
                *ngIf="
                  !!slotAttendancesCount &&
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.attended
                    .length > 0
                "
              >
                {{
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.attended
                    .length
                }}
              </span>
            </button>
            <button
              [class.active]="
                isSlotSelected(Status.NotAttended, slot.eaShowingSlotId)
              "
              [class.disabled]="isButtonDisabled(Status.NotAttended, slot)"
              [disabled]="internalLoading"
              [tooltip]="
                isButtonDisabled(Status.NotAttended, slot)
                  ? ('not_enough_slots_available' | translate)
                  : null
              "
              (click)="
                isButtonDisabled(Status.NotAttended, slot)
                  ? null
                  : handleSlotClick(Status.NotAttended, slot.eaShowingSlotId)
              "
              type="button"
              class="btn btn-default off-white"
            >
              {{ "not_attended" | translate }}
              <span
                [class.count-active]="
                  isSlotSelected(Status.NotAttended, slot.eaShowingSlotId)
                "
                class="attendance-count"
                *ngIf="
                  !!slotAttendancesCount &&
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.notAttended
                    .length > 0
                "
              >
                {{
                  getAttendanceCountForSlot(slot.eaShowingSlotId)?.notAttended
                    .length
                }}
              </span>
            </button>
          </div>
        </ng-container>
      </div>
      <ng-template #showingWithoutSlots>
        <div class="btn-group" *ngIf="!internalLoading; else loading">
          <button
            [class.active]="isSelected(Status.Registered)"
            (click)="handleClick(Status.Registered)"
            type="button"
            class="btn btn-default off-white"
          >
            {{ "registered" | translate }}
            <span
              [class.count-active]="isSelected(Status.Registered)"
              class="attendance-count"
              *ngIf="attendancesCount && attendancesCount.registered.length > 0"
            >
              {{ attendancesCount.registered.length }}
            </span>
          </button>

          <button
            [class.active]="isSelected(Status.Attended)"
            (click)="handleClick(Status.Attended)"
            type="button"
            class="btn btn-default off-white"
          >
            {{ "attended" | translate }}
            <span
              [class.count-active]="isSelected(Status.Attended)"
              class="attendance-count"
              *ngIf="attendancesCount && attendancesCount.attended.length > 0"
            >
              {{ attendancesCount.attended.length }}
            </span>
          </button>

          <button
            [class.active]="isSelected(Status.NotAttended)"
            (click)="handleClick(Status.NotAttended)"
            type="button"
            class="btn btn-default off-white"
          >
            {{ "not_attended" | translate }}
            <span
              [class.count-active]="isSelected(Status.NotAttended)"
              class="attendance-count"
              *ngIf="
                attendancesCount && attendancesCount.notAttended.length > 0
              "
            >
              {{ attendancesCount.notAttended.length }}
            </span>
          </button>
        </div>
      </ng-template>
      <ng-container
        *ngIf="
          showing.showingId &&
          showing.showingId.includes('quedro_p_') &&
          !showing.isPublic
        "
      >
        <div class="showing-actions">
          <button
            (click)="handleRemovePrivateShowing(showing.showingId)"
            *ngIf="mayDeleteShowing(employee$ | async) && showDeleteButton"
            [tooltip]="'remove' | translate"
            class="btn btn-danger"
            placement="left"
          >
            <app-icon name="trash"></app-icon>
            <span translate>delete</span>
          </button>
        </div>
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
</div>

<ng-template #noDropdown>
  <p>
    {{
      getSelectedSlot(showing.slots)
        ? (formatDate(getSelectedSlot(showing.slots).from) | momentify)
        : showing.from
    }}
    <ng-container *ngIf="!showing.isPublic">
      <i [tooltip]="'private_showing' | translate" class="fa fa-eye"></i>
      <ng-container
        *ngIf="showing.showingId && showing.showingId.includes('quedro_p_')"
      >
        <i
          (click)="handleRemovePrivateShowing(showing.showingId)"
          *ngIf="mayDeleteShowing(employee$ | async) && showDeleteButton"
          [tooltip]="'remove' | translate"
          class="remove icon-trash"
          placement="left"
        ></i>
      </ng-container>
    </ng-container>
  </p>
  <ng-container>
    <div class="btn-group" *ngIf="!internalLoading; else loading">
      <button
        [class.active]="isSelected(Status.Registered)"
        (click)="handleClick(Status.Registered)"
        type="button"
        class="btn btn-default"
      >
        {{ "registered" | translate }}
        <span
          [class.count-active]="isSelected(Status.Registered)"
          class="attendance-count"
          *ngIf="attendancesCount && attendancesCount.registered.length > 0"
        >
          {{ attendancesCount.registered.length }}
        </span>
      </button>

      <button
        [class.active]="isSelected(Status.Attended)"
        (click)="handleClick(Status.Attended)"
        type="button"
        class="btn btn-default"
      >
        {{ "attended" | translate }}
        <span
          [class.count-active]="isSelected(Status.Attended)"
          class="attendance-count"
          *ngIf="attendancesCount && attendancesCount.attended.length > 0"
        >
          {{ attendancesCount.attended.length }}
        </span>
      </button>

      <button
        [class.active]="isSelected(Status.NotAttended)"
        (click)="handleClick(Status.NotAttended)"
        type="button"
        class="btn btn-default"
      >
        {{ "not_attended" | translate }}
        <span
          [class.count-active]="isSelected(Status.NotAttended)"
          class="attendance-count"
          *ngIf="attendancesCount && attendancesCount.notAttended.length > 0"
        >
          {{ attendancesCount.notAttended.length }}
        </span>
      </button>
    </div>
  </ng-container>
  <ng-container
    *ngIf="
      showing.showingId &&
      showing.showingId.includes('quedro_p_') &&
      !showing.isPublic
    "
  >
    <div class="showing-actions">
      <button
        (click)="handleRemovePrivateShowing(showing.showingId)"
        *ngIf="mayDeleteShowing(employee$ | async) && showDeleteButton"
        [tooltip]="'remove' | translate"
        class="btn btn-danger"
        placement="left"
      >
        <app-icon name="trash"></app-icon>
        <span translate>delete</span>
      </button>
    </div>
  </ng-container>
</ng-template>

<ng-template #loading>
  <div class="loading-spinner">
    <spinner [size]="'2.5rem'"></spinner>
  </div>
</ng-template>
