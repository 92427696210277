import { PageView } from "@app/models/page-view";
import {
  fetchLatestContactsFail,
  fetchLatestContactsRequest,
  fetchLatestContactsSuccess,
} from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface LatestContactsWidgetState {
  contacts: PageView[];
  loading: boolean;
}

export const initialState: LatestContactsWidgetState = {
  loading: false,
  contacts: [],
};

export function latestContactsWidgetReducer(
  state: LatestContactsWidgetState,
  action: Action
): LatestContactsWidgetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(fetchLatestContactsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(fetchLatestContactsSuccess, (state, { contacts }) => ({
    ...state,
    loading: false,
    contacts,
  })),
  on(fetchLatestContactsFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.latestContacts
);

export const getLatestContacts = createSelector(
  getState,
  (state: LatestContactsWidgetState) => state.contacts
);
export const getLoading = createSelector(
  getState,
  (state: LatestContactsWidgetState) => state.loading
);
