<div bsModal class="modal fade" #modal="bs-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">
          {{ "nps" | translate }}
        </h4>
        <button type="button" class="close pull-right" (click)="modal.hide()">
          <span>&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="nps">
        <p>
          <i class="icon-calendar"></i>&nbsp; {{ "reply_date" | translate }}:
          {{ nps.responseDate }}
        </p>
        <p>
          <i class="icon-speedometer"></i>&nbsp; {{ "nps_value" | translate }}:
          {{ nps.nps }}
        </p>
        <p>
          <i class="icon-note"></i>&nbsp; {{ "survey_name" | translate }}:
          {{ nps.surveyName }}
        </p>
        <p>
          <i class="icon-user"></i>&nbsp; {{ "broker" | translate }}:
          {{ nps.employeeFullName }}
        </p>
        <p>
          <i class="icon-location-pin"></i>&nbsp; {{ "office" | translate }}:
          {{ nps.officeName }}
        </p>
      </div>
    </div>
  </div>
</div>
