import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Task } from "@app/models";
import * as deleteReasons from "../../utils/delete-task-reasons";

@Component({
  selector: "task-default-delete-modal",
  templateUrl: "./task-default-delete-modal.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./task-default-delete-modal.component.scss",
  ],
})
export class TaskDefaultDeleteModalComponent implements OnInit {
  @Input() task: Task;
  @Input() headerText: string;
  @Input() label: string = "remove";
  @Output()
  deleteClicked: EventEmitter<{
    id: string;
    reason: string;
    taskType: string;
  }> = new EventEmitter();
  reasons = deleteReasons;
  modalForm: FormGroup;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initModalForm();
  }

  initModalForm(): void {
    this.modalForm = this.fb.group({
      reason: this.reasons.INFORMATION_PROBLEM,
    });
  }

  onDeleteClicked(): void {
    this.deleteClicked.emit({
      id: this.task.eaTaskId,
      reason: this.modalForm.get("reason").value,
      taskType: this.task.eaTaskTypeCategory,
    });
  }
}
