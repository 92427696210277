import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  EditorChangeContent,
  EditorChangeSelection,
  QuillEditorComponent,
  Range,
} from "ngx-quill";

import { QuillConfiguration } from "./quill-configuration";

@Component({
  selector: "app-rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  styleUrls: ["./rich-text-editor.component.scss"],
})
export class RichTextEditorComponent implements OnInit {
  @ViewChild("quill") quill: QuillEditorComponent;

  @Input() hasColor = false;
  @Input() control: FormControl;
  @Input() toolbarEnabled = true;
  @Input() height = 180;
  @Input() placeholder = "";
  @Input() autoFocus = false;

  quillConfiguration;
  styles: any = {
    border: "1.5px solid #666",
    "border-bottom-left-radius": "5px",
    "border-bottom-right-radius": "5px",
  };
  caretPosition: Range;

  ngOnInit() {
    this.control = this.control ?? new FormControl();
    this.quillConfiguration = QuillConfiguration(
      this.toolbarEnabled,
      this.hasColor
    );
    this.styles = {
      ...this.styles,
      height: `${this.height}px`,
      ...(!this.toolbarEnabled && {
        "border-top-left-radius": "5px",
        "border-top-right-radius": "5px",
      }),
    };
  }

  insert(text: string, formats: Record<string, any>, multiple = false) {
    const editor = this.quill.quillEditor;
    const lastIndex = this.quill.quillEditor.getLength() - 1;
    const index =
      this.caretPosition?.index || this.caretPosition?.index === 0
        ? this.caretPosition.index
        : lastIndex;
    if (multiple) {
      editor.insertText(index, "\n" + text, formats);
    } else {
      editor.insertText(index, text + " ", formats);
      editor.removeFormat(index + text.length, 1);
    }
  }

  setCaretPosition($event: EditorChangeContent | EditorChangeSelection) {
    if ($event.event === "selection-change" && $event.range) {
      this.caretPosition = $event.range;
    }
  }

  editorCreated(editor) {
    if (this.autoFocus) {
      editor.focus();
    }
  }
}
