import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { Sort } from "@angular/material/sort";
import { Store } from "@ngrx/store";
import { maxBy } from "lodash";
import moment from "moment";
import {
  BehaviorSubject,
  combineLatest,
  filter,
  map,
  Observable,
  skip,
  Subject,
  takeUntil,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import { EmployeePerformanceWidgetConfig } from "@app/shared/config/models/widgets-page-config";
import { API_ONLY_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import {
  StatisticsEmployeesPerformanceData,
  StatisticsEmployeesPerformanceParams,
} from "@app/widgets/widgets/statistics-employees-performance-widget/models/statistics-employees-performance";
import * as statisticsEmployeesPerformanceActions from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.actions";
import {
  selectData,
  selectIsLoading,
} from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.reducer";

type PeriodFilters = "previousMonth" | "currentMonth" | "currentYear";

const getPeriod = (
  filter: PeriodFilters
): { periodStart: string; periodEnd: string } | null => {
  switch (filter) {
    case "previousMonth":
      return {
        periodStart: moment()
          .subtract(1, "months")
          .startOf("month")
          .format(API_ONLY_DATE_FORMAT),
        periodEnd: moment()
          .subtract(1, "months")
          .endOf("month")
          .format(API_ONLY_DATE_FORMAT),
      };
    case "currentMonth":
      return {
        periodStart: moment().startOf("month").format(API_ONLY_DATE_FORMAT),
        periodEnd: moment().format(API_ONLY_DATE_FORMAT),
      };
    case "currentYear":
      return {
        periodStart: moment().startOf("year").format(API_ONLY_DATE_FORMAT),
        periodEnd: moment().format(API_ONLY_DATE_FORMAT),
      };
    default:
      return null;
  }
};

const findMaxBudget = (data, compareToFilter, returnType): string => {
  const iteratee =
    compareToFilter === "budget"
      ? `${returnType}.budgetData.budget`
      : `${returnType}.lastYear`;
  const brokerWithHighestBudget = maxBy(data.results, iteratee);
  const brokerWithHighestProgressAgainstBudget = maxBy(
    data.results,
    `${returnType}.current`
  );

  const highestBudget =
    compareToFilter === "budget"
      ? brokerWithHighestBudget[returnType].budgetData.budget
      : brokerWithHighestProgressAgainstBudget[returnType].lastYear;

  return highestBudget >
    brokerWithHighestProgressAgainstBudget[returnType].current
    ? highestBudget
    : brokerWithHighestProgressAgainstBudget[returnType].current;
};

const colorBrackets = [
  { lower: 0, upper: 35, color: "#ff5757" },
  { lower: 36, upper: 70, color: "#ff802b" },
  { lower: 71, upper: 99, color: "#FFFF66" },
  { lower: 101, upper: 130, color: "#7FE854" },
  { lower: 131, upper: 160, color: "#67D23F" },
  { lower: 151, upper: Infinity, color: "#4CA732" },
];

@Component({
  selector: "app-statistics-employees-performance-widget",
  templateUrl: "./statistics-employees-performance-widget.component.html",
  styleUrls: ["./statistics-employees-performance-widget.component.scss"],
})
export class StatisticsEmployeesPerformanceWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() config: Pick<
    EmployeePerformanceWidgetConfig,
    "compareTo" | "returnTypes"
  >;
  @Input() triggerDataFetch$: Observable<void>;

  data$: Observable<StatisticsEmployeesPerformanceData>;
  isLoading$: Observable<boolean>;
  columns$: Observable<any>;
  employeeId$ = new BehaviorSubject<string>("");
  officeId$ = new BehaviorSubject<string>("");
  sortBy$ = new BehaviorSubject<Sort>({
    active: "entityName",
    direction: "asc",
  });
  periodFilter$: BehaviorSubject<PeriodFilters> = new BehaviorSubject(
    "currentMonth"
  );
  compareToFilter$: BehaviorSubject<
    EmployeePerformanceWidgetConfig["compareTo"]
  > = new BehaviorSubject("budget");
  params$: Observable<StatisticsEmployeesPerformanceParams>;
  unsubscribe$ = new Subject<void>();
  periodFilters: { label: string; value: PeriodFilters }[] = [
    { label: "previous_month", value: "previousMonth" },
    { label: "this_month", value: "currentMonth" },
    { label: "this_year", value: "currentYear" },
  ];
  compareToFilters: {
    label: string;
    value: EmployeePerformanceWidgetConfig["compareTo"];
  }[] = [
    { label: "compare_to_budget", value: "budget" },
    { label: "compare_to_last_year", value: "lastYear" },
  ];

  constructor(private readonly store: Store<AppState>) {}

  ngOnInit(): void {
    this.compareToFilter$ = new BehaviorSubject(this.config.compareTo);
    this.mapStateToProps();

    this.params$.subscribe((params) =>
      this.store.dispatch(
        statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsRequest(
          {
            params,
          }
        )
      )
    );

    this.triggerDataFetch$
      ?.pipe(
        skip(1),
        withLatestFrom(this.params$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([_, params]) => {
        this.store.dispatch(
          statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsRequest(
            {
              params,
            }
          )
        );
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId) {
      this.employeeId$.next(this.eaEmployeeId);
    }
    if (changes.eaOfficeId) {
      this.officeId$.next(this.eaOfficeId);
    }
  }

  mapStateToProps(): void {
    this.data$ = this.store.select(selectData);
    this.isLoading$ = this.store.select(selectIsLoading);

    this.columns$ = this.data$.pipe(
      withLatestFrom(this.compareToFilter$),
      takeUntil(this.unsubscribe$),
      filter(([data]) => !!data?.results && data?.results.length > 0),
      map(([data, compareToFilter]) => {
        const returnTypes = this.config.returnTypes;
        return returnTypes.map((returnType) => {
          const maxBudget = findMaxBudget(data, compareToFilter, returnType);

          return {
            label: returnType,
            maxBudget,
          };
        });
      })
    );

    this.params$ = combineLatest([
      this.employeeId$,
      this.officeId$,
      this.sortBy$,
      this.periodFilter$,
      this.compareToFilter$,
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(
        ([
          employeeId,
          officeId,
          sortBy,
          periodFilter,
          compareToFilter,
        ]: any): StatisticsEmployeesPerformanceParams => {
          return {
            ...(employeeId && { eaEmployeeId: employeeId }),
            ...(officeId && { eaOfficeId: officeId }),
            getBudgetData: compareToFilter === "budget",
            includeLastYearsData: compareToFilter === "lastYear",
            ...getPeriod(periodFilter),
            returnTypes: this.config.returnTypes,
            groupBy: "employee",
            ...(sortBy.direction && { sortBy: sortBy.active }),
            ...(sortBy.direction && { sortOrder: sortBy.direction }),
          };
        }
      )
    );
  }

  sortData(value: Sort) {
    this.sortBy$.next(value);
  }

  periodFilterChanged(value: PeriodFilters): void {
    this.periodFilter$.next(value);
  }

  compareToFilterChanged(
    value: EmployeePerformanceWidgetConfig["compareTo"]
  ): void {
    this.compareToFilter$.next(value);
  }

  getColumnWidth(columns: any[]): string {
    return `${(100 - 1) / columns.length}%`;
  }

  getPercentage(
    column: any,
    compareTo: EmployeePerformanceWidgetConfig["compareTo"]
  ): number {
    if (compareTo === "budget") {
      return column?.budgetData?.changeBudgetPercentage;
    } else if (compareTo === "lastYear") {
      const percentage = Math.round((column?.current / column?.lastYear) * 100);
      return percentage ? (percentage >= 1000 ? 1000 : percentage) : 0;
    }
  }

  getBudget(
    column: any,
    compareTo: EmployeePerformanceWidgetConfig["compareTo"]
  ): number {
    if (compareTo === "budget") {
      return column?.budgetData?.budget;
    } else if (compareTo === "lastYear") {
      return column?.lastYear;
    }
  }

  getProgressBarColor(percentage: number): string {
    const index = colorBrackets.findIndex(
      (bracket) => percentage >= bracket.lower && percentage <= bracket.upper
    );
    return colorBrackets[index]?.color;
  }

  getReferenceLine(budget: number, maxBudget: number): string {
    return `${(budget / maxBudget) * 100}%`;
  }

  percentageOrHyphen(percentage: number, budget: number): string | number {
    return percentage === 0 && budget > 0 ? "-" : percentage;
  }
}
