import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN, ROLE_BROKER, ROLE_MANAGER } from "@app/shared/utils/roles";
import { Template } from "@app/sidebar/message-templates/ngrx/message-templates-form.reducer";
import { DynamicContent } from "@app/sidebar/send-message/models";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, Observable } from "rxjs";

@Component({
  selector: "app-message-templates-form",
  templateUrl: "./message-templates-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./message-templates-form.component.scss",
  ],
})
export class MessageTemplatesFormComponent implements OnInit, OnChanges {
  @Input() action: "create" | "edit";
  @Input() template: Template;
  @Input() dynamicContents: DynamicContent[] | null;
  @Input() isEditable: boolean = true;

  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  isAgent$: Observable<boolean>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;

  form: FormGroup;
  messageTypeControls = ["email", "sms"];
  contextControls: Select = [
    {
      label: "general",
      value: "general",
    },
    {
      label: "showing",
      value: "showing",
    },
    {
      label: "sales_meeting",
      value: "salesMeeting",
    },
    {
      label: "others",
      value: "others",
    },
  ];

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    this.buildForm();
  }

  ngOnInit() {
    this.mapStateToProps();

    combineLatest([this.isAgent$, this.eaOfficeId$, this.eaEmployeeId$])
      .pipe(first())
      .subscribe(([isAgent, eaOfficeId, eaEmployeeId]) => {
        if (!!isAgent) {
          this.form.patchValue({
            eaOfficeId,
            eaEmployeeId,
            context: "salesMeeting",
          });

          this.form.get("context").disable({ emitEvent: false });

          if (this.action === "edit") {
            this.form.get("name").disable({ emitEvent: false });
            this.form.get("description").disable({ emitEvent: false });
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.template && !!this.template) {
      this.fillForm();
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      messageType: "email",
      context: "general",
      name: ["", [Validators.required]],
      description: "",
      dynamicContents: this.fb.array([]),
      eaOfficeId: null,
      eaEmployeeId: null,
    });
  }

  private fillForm() {
    const {
      messageType,
      templateGroupName,
      templateName,
      templateDescription,
    } = this.template;
    this.form.patchValue({
      messageType,
      context: templateGroupName,
      name: templateName,
      description: templateDescription,
    });
  }

  changeMessageType(control: string) {
    this.form.get("messageType").setValue(control);
  }

  getFormValues() {
    const { dynamicContents, ...rest } = this.form.getRawValue();
    const hasSubject = dynamicContents.length > 1;
    const subject = dynamicContents[0];
    const content = hasSubject ? dynamicContents[1] : dynamicContents[0];

    return { ...(hasSubject && { subject }), content, ...rest };
  }

  isEmail(): boolean {
    return this.form.get("messageType").value === "email";
  }

  private mapStateToProps() {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.isAgent$ = this.store.pipe(select(fromUser.hasRole(ROLE_BROKER)));
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
  }

  handleOfficeEmployeeFields(): void {
    combineLatest([
      this.isAdmin$,
      this.isManager$,
      this.isAgent$,
      this.eaEmployeeId$,
      this.eaOfficeId$,
    ])
      .pipe(first())
      .subscribe(
        ([
          isAdmin,
          isManager,
          isAgent,
          originEaEmployeeId,
          originEaOfficeId,
        ]) => {
          let newOriginEaOfficeId =
            isAgent || isManager ? originEaOfficeId : null;
          let newOriginEaEmployeeId = !isAgent ? null : originEaEmployeeId;

          if (!isAdmin) {
            this.form.get("eaOfficeId").disable();
            newOriginEaOfficeId = originEaOfficeId;
          }
          if (!isAdmin && !isManager) {
            this.form.get("eaEmployeeId").disable();
            newOriginEaEmployeeId = originEaEmployeeId;
          }

          this.form.patchValue({
            eaOfficeId: newOriginEaOfficeId,
            eaEmployeeId: newOriginEaEmployeeId,
          });
        }
      );
  }
}
