<ng-container *ngIf="types.length > 0">
  <ng-container
    *ngIf="
      !(kpiLoading$ | async) && (sortedKpis$ | async)?.length > 0;
      else loading
    "
  >
    <ng-container *ngIf="sortedKpis$ | async as sortedKpis">
      <achieved-targets
        *ngIf="showSummary$ | async"
        [style.order]="summaryPosition"
        [number]="total$ | async"
      ></achieved-targets>

      <kpi-box
        *ngFor="let kpi of sortedKpis; let i = index"
        [style.order]="i"
        [title]="getKpiTranslationKeyByType(kpi.kpiType) | translate"
        [max]="getKpiSalesTarget(kpi)"
        [value]="kpi.currentData"
        [type]="kpi.kpiType"
        (click)="onKpiClick(kpi)"
      >
      </kpi-box>

      <ng-container *ngIf="npsFeatureEnabled$ | async">
        <ng-container *ngIf="!(npsLoading$ | async); else npsLoading">
          <ng-container *ngIf="nps$ | async as nps">
            <app-nps-box
              [style.order]="sortedKpis.length"
              [nps]="nps"
              [useDefaultDate]="useDefaultDate"
              [npsConfig]="npsConfig$ | async"
              (npsBoxClicked)="onNPSClick(nps)"
            >
            </app-nps-box>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #loading>
  <div class="skeleton">
    <div class="skeleton__box" *ngFor="let key of [1, 2, 3, 4, 5]">
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <div class="skeleton__item"></div>
      <skeleton-rectangle [height]="5"></skeleton-rectangle>
    </div>
  </div>
</ng-template>

<ng-template #npsLoading>
  <div class="skeleton" [style.order]="(sortedKpis$ | async)?.length">
    <div class="skeleton__box">
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <div class="skeleton__item"></div>
      <skeleton-rectangle [height]="5"></skeleton-rectangle>
    </div>
  </div>
</ng-template>
