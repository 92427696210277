<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="tabType | translate"
  ></sidebar-header>
  <div class="scroll-view">
    <sales-meeting-form
      #smForm
      [editMode]="true"
      [residence]="residence$ | async"
      (ready)="onFormReady()"
      (submitClicked)="onSubmit($event)"
      (employeeChange)="onEmployeeChange()"
      (setLoading)="loading = $event"
    ></sales-meeting-form>
  </div>

  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [disabled]="(processing$ | async) || !!loading"
      [isLoading]="(processing$ | async) || !!loading"
      (click)="loading = true; smForm.submit()"
    >
    </app-q-button>
  </div>
</div>
