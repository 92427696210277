import { Component } from "@angular/core";
import { fadeInLeft } from "@app/shared/animations";

@Component({
  selector: "app-controls-box",
  templateUrl: "./controls-box.component.html",
  styleUrls: ["./controls-box.component.scss"],
  animations: [fadeInLeft()],
})
export class ControlsBoxComponent {}
