import { Component, Input } from "@angular/core";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { PropertySummaryDialogComponent } from "@app/shared/modules/property-components/property-summary/components/property-summary-dialog/property-summary-dialog.component";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";

@Component({
  selector: "app-property-summary",
  templateUrl: "./property-summary.component.html",
  styleUrls: ["./property-summary.component.scss"],
})
export class PropertySummaryComponent {
  @Input() eaOid: string;

  constructor(
    private modalService: QModalService,
    private objectService: ObjectService,
    private contactService: ContactService
  ) {}

  openModal(e) {
    e.stopPropagation();
    this.objectService
      .getById(this.eaOid, { getContacts: true, getLinks: true })
      .subscribe((response) => {
        this.contactService.upsertManyInCache(response.contacts);
      });
    this.modalService.show(PropertySummaryDialogComponent, {
      data: {
        eaOid: this.eaOid,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }
}
