<form [formGroup]="form">
  <div class="form-group">
    <label>{{ "first_name" | translate }}</label>
    <input type="text" formControlName="firstName" />
    <div ngxErrors="firstName">
      <div translate ngxError="required" showWhen="touched">
        validation_required
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>{{ "family_name" | translate }}</label>
    <input type="text" formControlName="familyName" />
    <div ngxErrors="familyName">
      <div translate ngxError="required" showWhen="touched">
        validation_required
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>{{ "email" | translate }}</label>
    <input type="text" formControlName="email" />
    <div ngxErrors="email">
      <div translate ngxError="required" showWhen="touched">
        validation_required
      </div>
      <div translate ngxError="validateEmail" showWhen="touched">
        validation_email
      </div>
      <div translate ngxError="emailTaken" showWhen="touched">
        validation_broker_exists
      </div>
    </div>
  </div>

  <div class="form-group">
    <mobile-input
      formControlName="mobile"
      [countryCode]="countryCode"
      [languageCode]="languageCode"
      (countryChange)="updateMobileNumberValidators($event)"
    ></mobile-input>
    <div ngxErrors="mobile">
      <div translate ngxError="required" showWhen="touched">
        validation_required
      </div>
      <div translate ngxError="phone" showWhen="touched">validation_mobile</div>
    </div>
  </div>

  <div class="form-group">
    <label>{{ "title" | translate }}</label>
    <input type="text" formControlName="title" />
  </div>
</form>
