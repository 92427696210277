import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink } from "@app/contacts/contact.actions";
import { UrlService } from "@app/core/services/url/url.service";
import { PageView } from "@app/models/page-view";
import { openLinkInNewTab as handleOpenInNewTab } from "@app/shared/utils/url-utils";
import { fetchLatestContactsRequest } from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.actions";
import {
  getLatestContacts,
  getLoading,
} from "@app/widgets/widgets/latest-contacts-widget/ngrx/latest-contacts-widget.reducer";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { go } from "@app/core/ngrx/router/router.actions";

@Component({
  selector: "app-latest-contacts-widget",
  templateUrl: "./latest-contacts-widget.component.html",
  styleUrls: ["./latest-contacts-widget.component.scss"],
})
export class LatestContactsWidgetComponent implements OnInit {
  @Input() openLinkInNewTab = false;

  contacts$: Observable<PageView[]>;
  loading$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchContacts();
  }

  private mapStateToProps() {
    this.contacts$ = this.store.pipe(select(getLatestContacts));
    this.loading$ = this.store.pipe(select(getLoading));
  }

  private fetchContacts() {
    this.store.dispatch(fetchLatestContactsRequest());
  }

  onContactClick(contact: PageView, event) {
    event.preventDefault();

    if (this.openLinkInNewTab) {
      this.openInNewTab(contact);
      return;
    }

    if (!event.target.attributes["disablenavigation"]) {
      this.addBackLink();
      this.store.dispatch(
        go({ path: ["/crm", "contacts", contact?.entity?.contactId] })
      );
    }
  }

  openInNewTab(contact: PageView) {
    const commands = ["/crm", "contacts", contact?.entity?.contactId];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    handleOpenInNewTab(url);
  }

  addBackLink(): void {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
  }
}
