import {
  PaginationListDTO,
  SalesMeeting,
  TaskType,
  TypedPaginationListDTO,
} from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getSalesMeetingsRequest = createAction(
  "[Lists Sales Meetings] GET_SALES_MEETINGS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getSalesMeetingsSuccess = createAction(
  "[Lists Sales Meetings] GET_SALES_MEETINGS_SUCCESS",
  props<{ salesMeetings: TypedPaginationListDTO<SalesMeeting> }>()
);

export const getSalesMeetingsFailed = createAction(
  "[Lists Sales Meetings] GET_SALES_MEETINGS_FAILED"
);

export const getMoreSalesMeetingsRequest = createAction(
  "[Lists Sales Meetings] GET_ALL_SALES_MEETINGS_CONTACT_IDS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getMoreSalesMeetingsSuccess = createAction(
  "[Lists Sales Meetings] GET_ALL_SALES_MEETINGS_CONTACT_IDS_SUCCESS",
  props<{ salesMeetings: PaginationListDTO }>()
);

export const getMoreSalesMeetingsFailed = createAction(
  "[Lists Sales Meetings] GET_ALL_SALES_MEETINGS_CONTACT_IDS_FAILED"
);

export const getAllSalesMeetingsContactIdsRequest = createAction(
  "[Lists Sales Meetings] GET_MORE_SALES_MEETINGS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getAllSalesMeetingsContactIdsSuccess = createAction(
  "[Lists Sales Meetings] GET_MORE_SALES_MEETINGS_SUCCESS",
  props<{ contactIds: string[] }>()
);

export const getAllSalesMeetingsContactIdsFailed = createAction(
  "[Lists Sales Meetings] GET_MORE_SALES_MEETINGS_FAILED"
);

export const getTaskTypesRequest = createAction(
  "[Lists Sales Meetings] GET_TASK_TYPES_REQUEST"
);

export const getTaskTypesSuccess = createAction(
  "[Lists Sales Meetings] GET_TASK_TYPES_SUCCESS",
  props<{ taskTypes: TaskType[] }>()
);

export const getTaskTypesFailed = createAction(
  "[Lists Sales Meetings] GET_TASK_TYPES_FAILED"
);

export const reset = createAction("[Lists Sales Meetings] RESET");
