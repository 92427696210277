import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import moment from "moment";
import { BsDropdownDirective } from "ngx-bootstrap/dropdown";

@Component({
  selector: "time-picker-input",
  templateUrl: "./time-picker-input.component.html",
  styleUrls: ["./time-picker-input.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimePickerInputComponent),
    },
  ],
})
export class TimePickerInputComponent implements OnInit, ControlValueAccessor {
  @Input() start = "09:00";
  @Input() end = "18:00";
  @Input() interval = 15;
  @Input() keyboardInterval = 5;
  @Input() label = "time";
  @Output() timeChanged = new EventEmitter<string>();

  @ViewChild("input", { static: true })
  input: ElementRef;
  @ViewChild(BsDropdownDirective, { static: false })
  dropdown: BsDropdownDirective;

  propagateChange: (value: any) => void;
  propagateTouch: () => void;

  times: string[] = [];

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    const start = moment(this.start, "HH:mm");
    const end = moment(this.end, "HH:mm");
    this.times = this.generateTimeStops(start, end);
  }

  writeValue(value: any): void {
    this.renderer.setProperty(this.input.nativeElement, "value", value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.renderer.setProperty(this.input.nativeElement, "disabled", disabled);
  }

  handleTimeSelected(time: string): void {
    this.writeValue(time);
    this.propagateChange(time);
    this.timeChanged.emit(time);
  }

  select(): void {
    this.input.nativeElement.select();
  }

  @HostListener("keyup", ["$event"])
  handleArrowUpDownEvent(event: KeyboardEvent) {
    const value = this.input.nativeElement.value;
    const valid = /^([01][0-9]|2[0-3]):[0-5][0-9]$/.test(
      this.input.nativeElement.value
    );

    if (value.length > 5) {
      const newValue = value.substring(0, value.length - 1);
      this.writeValue(newValue);
    } else {
      if (!["ArrowUp", "ArrowDown"].includes(event.code) || !valid) {
        if (value.length === 4 && value.indexOf(":") === -1) {
          const withColon = value.slice(0, 2) + ":" + value.slice(2);
          this.writeValue(withColon);
        }
      } else {
        event.preventDefault();

        let time;
        // if (event.keyCode === UP_ARROW) {
        if (event.key === "ArrowUp") {
          time = moment(value, "HH:mm").add(this.keyboardInterval, "m");
        } else {
          time = moment(value, "HH:mm").subtract(this.keyboardInterval, "m");
        }
        this.writeValue(time.format("HH:mm"));
      }
    }
  }

  private generateTimeStops(
    startTime: moment.Moment,
    endTime: moment.Moment
  ): string[] {
    const timeStops = [];
    while (startTime.isSameOrBefore(endTime)) {
      timeStops.push(moment(startTime));
      startTime.add(this.interval, "m");
    }
    return timeStops.map((time) => time.format("HH:mm"));
  }
}
