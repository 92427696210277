import { Bid } from "@app/integrations/bidding/models/bid";
import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { BiddingIntegrationState } from "../reducers";
import * as biddingActions from "./bidding.actions";

export interface BiddingState {
  externalObjectMetadata: ExternalObjectMetadata | null;
  highestBid: string | null;
  highestBidLoading: boolean;
  bids: Bid[] | null;
  bidsLoading: boolean;
}

export const initialState: BiddingState = {
  externalObjectMetadata: null,
  highestBid: null,
  highestBidLoading: false,
  bids: null,
  bidsLoading: false,
};

export function biddingReducer(
  state: BiddingState,
  action: Action
): BiddingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    biddingActions.fetchExternalObjectMetadataSuccess,
    (state, { externalObjectMetaData }) => ({
      ...state,
      externalObjectMetadata: externalObjectMetaData,
    })
  ),
  on(biddingActions.fetchExternalObjectMetadataFailure, (state) => ({
    ...state,
    externalObjectMetadata: null,
  })),
  on(biddingActions.fetchHighestBidRequest, (state) => ({
    ...state,
    highestBidLoading: true,
  })),
  on(biddingActions.fetchHighestBidSuccess, (state, { highestAmount }) => ({
    ...state,
    highestBidLoading: false,
    highestBid: highestAmount,
  })),
  on(biddingActions.fetchHighestBidFailure, (state) => ({
    ...state,
    highestBidLoading: false,
    highestBid: null,
  })),
  on(biddingActions.fetchBidsRequest, (state) => ({
    ...state,
    bidsLoading: true,
  })),
  on(biddingActions.fetchBidsSuccess, (state, { bids }) => ({
    ...state,
    bidsLoading: false,
    bids,
  })),
  on(biddingActions.fetchBidsFailure, (state) => ({
    ...state,
    bidsLoading: false,
    bids: null,
  })),
  on(biddingActions.reset, () => ({ ...initialState }))
);

const selectFeature =
  createFeatureSelector<BiddingIntegrationState>("biddingIntegration");
const getState = createSelector(
  selectFeature,
  (state: BiddingIntegrationState) => state.bidding
);

export const getHighestBid = createSelector(
  getState,
  (state: BiddingState) => state.highestBid
);
export const highestBidLoading = createSelector(
  getState,
  (state: BiddingState) => state.highestBidLoading
);
export const getBids = createSelector(
  getState,
  (state: BiddingState) => state.bids
);
export const bidsLoading = createSelector(
  getState,
  (state: BiddingState) => state.bidsLoading
);
export const getExternalObjectMetadata = createSelector(
  getState,
  (state: BiddingState) => state.externalObjectMetadata
);
