import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import * as AddContactToCallingListActions from "@app/shared/modules/add-contact-to-calling-list/ngrx/add-contact-to-calling-list.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface AddContactToCallingListState {
  loading: boolean;
  callingLists: CallingList[];
  userCallingLists: CallingList[];
  changing: boolean;
}

export const initialState: AddContactToCallingListState = {
  loading: true,
  callingLists: [],
  userCallingLists: [],
  changing: false,
};

export function addContactToCallingListReducer(
  state: AddContactToCallingListState,
  action: Action
): AddContactToCallingListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    AddContactToCallingListActions.addContactToCallingListRequest,
    (state) => ({ ...state, loading: true })
  ),
  on(
    AddContactToCallingListActions.storeContactToCallingListRequest,
    (state) => ({ ...state, changing: true })
  ),
  on(
    AddContactToCallingListActions.addContactToCallingListSuccess,
    (state, { callingLists }) => ({
      ...state,
      loading: false,
      callingLists,
    })
  ),
  on(
    AddContactToCallingListActions.storeContactToCallingListSuccess,
    (state) => ({
      ...state,
      changing: false,
    })
  ),
  on(AddContactToCallingListActions.addContactToCallingListFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(AddContactToCallingListActions.storeContactToCallingListFail, (state) => ({
    ...state,
    changing: false,
  })),
  on(
    AddContactToCallingListActions.getUserCallingListRequest,
    (state) => state
  ),
  on(
    AddContactToCallingListActions.getUserCallingListSuccess,
    (state, { callingLists }) => ({ ...state, userCallingLists: callingLists })
  ),
  on(AddContactToCallingListActions.getUserCallingListFail, (state) => ({
    ...state,
  })),
  on(
    AddContactToCallingListActions.removeContactFromCallingListRequest,
    (state) => ({ ...state, changing: true })
  ),
  on(
    AddContactToCallingListActions.removeContactFromCallingListSuccess,
    (state) => ({ ...state, changing: false })
  ),
  on(
    AddContactToCallingListActions.removeContactFromCallingListFail,
    (state) => ({ ...state, changing: false })
  ),
  on(AddContactToCallingListActions.reset, () => initialState)
);

const selectFeature = createFeatureSelector<AddContactToCallingListState>(
  "addToContactCallingList"
);

export const getChanging = createSelector(
  selectFeature,
  (state: AddContactToCallingListState) => state.changing
);

export const getCallingLists = createSelector(
  selectFeature,
  (state: AddContactToCallingListState) => state.callingLists
);

export const getUserCallingList = createSelector(
  selectFeature,
  (state: AddContactToCallingListState) => state.userCallingLists
);

export const getCallingListsLoading = createSelector(
  selectFeature,
  (state: AddContactToCallingListState) => state.loading
);
