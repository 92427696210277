import { Component, Input } from "@angular/core";

@Component({
  selector: "app-object-parties-widget",
  templateUrl: "./object-parties-widget.component.html",
  styleUrls: [
    "./object-parties-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectPartiesWidgetComponent {
  @Input() eaOid: string;
}
