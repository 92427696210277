import * as widgetActions from "@app/widgets/widgets/statistics-revenue-widget/ngrx/statistics-revenue-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface RevenueState {
  stats: number;
  previousStats: number;
  loading: boolean;
}

export const initialState: RevenueState = {
  loading: false,
  stats: null,
  previousStats: null,
};

export function revenueReducer(
  state: RevenueState,
  action: Action
): RevenueState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    widgetActions.fetchStatisticsRequest,
    widgetActions.fetchPreviousStatisticsRequest,
    (state) => ({
      ...state,
      loading: true,
    })
  ),
  on(widgetActions.fetchStatisticsSuccess, (state, { stats }) => ({
    ...state,
    loading: false,
    stats,
  })),
  on(
    widgetActions.fetchPreviousStatisticsSuccess,
    (state, { previousStats }) => ({
      ...state,
      loading: false,
      previousStats,
    })
  ),
  on(
    widgetActions.fetchStatisticsFail,
    widgetActions.fetchPreviousStatisticsFail,
    (state) => ({
      ...state,
      loading: false,
    })
  )
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.revenue
);

export const getStats = createSelector(
  getState,
  (state: RevenueState) => state.stats
);
export const getDifference = createSelector(
  getState,
  (state: RevenueState) => state.stats - state.previousStats
);
export const getLoading = createSelector(
  getState,
  (state: RevenueState) => state.loading
);
