import { Task } from "./task";

export class SalesMeeting {
  eaCrmSalesMeetingId: string;
  contactId: string;
  contactSource: string;
  eaEmployeeId: string;
  eaOfficeId: string;
  eaOid: string;
  veventId: string;
  startTime: string;
  endTime: string;
  status: string;
  statusUpdatedDate: string;
  eaObjectEvaluationId: string;
  commissionType: string;
  commissionVAT: boolean;
  commissionFixedValue: string;
  commissionPercentageBase: string;
  commissionComment: string;
  address: string;
  insertedDate: string;
  updatedDate: string;
  deletedDate: string;
  firstName: string;
  familyName: string;
  employeeFullName: string;
  officeName: string;
  objectStreet: string;
  objectZip: string;
  objectCity: string;
  objectStatus: string;
  evaluationValue: string;
  evaluationUncertainty: string;
  evaluationCurrency: string;
  evaluationComment: string;
  evaluationSource: string;
  evaluationDate: string;
  rating: string;
  languageStatus: string;
  isExistingHome: boolean;
  note: string;
  bookedBy: any;
  lastUpdatedBy: any;
  tasks: Task[];
  source: string;
  updateContactSource: boolean;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getStatusTranslationKey(): string {
    switch (this.status) {
      case "prepared":
        return "not_reported";
      case "won":
        return "won";
      case "lost":
        return "lost";
      case "valuation":
        return "valuation";
      case "waiting":
        return "waiting";
      case "canceledByConsumer":
        return "canceledByConsumer";
    }
    return "unknown";
  }

  getAddress(): string {
    return this.objectStreet ? this.objectStreet : this.address;
  }

  getFullName(): string {
    return `${this.firstName} ${this.familyName}`;
  }
}

export const getStatusTranslationKey = (status): string => {
  switch (status) {
    case "prepared":
      return "not_reported";
    case "won":
      return "won";
    case "lost":
      return "lost";
    case "valuation":
      return "valuation";
    case "waiting":
      return "waiting";
    case "canceledByConsumer":
      return "canceledByConsumer";
  }
  return "unknown";
};
