import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NpsEntity } from "@app/models/nps";
import { NpsConfig } from "@app/shared/config/models";
import { TranslateService } from "@ngx-translate/core";
import * as Highcharts from "highcharts";
import { ModalDirective } from "ngx-bootstrap/modal";
import { npsGauge } from "../charts/solid-gauge";
import { getProgressBarType } from "@app/shared/utils/colors";

@Component({
  selector: "app-nps-box",
  templateUrl: "./nps-box.component.html",
  styleUrls: ["./nps-box.component.scss"],
})
export class NpsBoxComponent implements OnInit {
  @ViewChild("modal", { static: true })
  modal: ModalDirective;
  Highcharts: typeof Highcharts = Highcharts;
  @Input() npsConfig: NpsConfig;
  @Input() nps: NpsEntity;
  @Input() useDefaultDate: boolean;
  @Output() npsBoxClicked = new EventEmitter<null>();

  options: any = {};
  getProgressBarType = getProgressBarType;

  get title() {
    const firstPart = this.translate.instant(this.npsConfig.title);

    if (this.useDefaultDate) {
      const lastPart = this.translate.instant("months_back").toLowerCase();
      return `${firstPart} ${this.npsConfig.months_back} ${lastPart}`;
    }

    return firstPart;
  }

  get value() {
    return this.npsConfig?.use_average_nps_value
      ? this.nps?.averageNPSValue
      : this.nps?.NPSValue || 0;
  }

  get max() {
    return this.npsConfig?.max;
  }

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.translate.get(`answer`).subscribe((title) => {
      this.options = npsGauge(
        this.npsConfig.min,
        this.npsConfig.max,
        this.npsConfig.use_average_nps_value
          ? this.nps.averageNPSValue
          : this.nps.NPSValue || 0,
        this.nps.responsesWithNPSValues,
        title.toLowerCase()
      );
    });
  }

  showInfo(): void {
    this.modal.show();
  }

  handleLinkClicked(url: string): void {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  hideModal(): void {
    this.modal.hide();
  }

  onClick() {
    this.npsBoxClicked.emit();
  }

  replaceNewLine(str: string) {
    return str.replace(/(\n)+/g, "<br>");
  }

  getTooltip(type: string): string {
    return type;
  }

  isTruncated(element) {
    return !(element.offsetWidth >= element.scrollWidth);
  }
}
