import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "detail-card",
  template: `
    <div class="wrapper flex-container">
      <div *ngIf="icon" class="icon-column">
        <i [class]="icon"></i>
      </div>
      <div class="flex-column">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: [
    "./detail-card.component.scss",
    "../../../../../styles/icon-colors.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailCardComponent {
  @Input() icon: String;
}
