import { Injectable } from "@angular/core";
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpResponse,
} from "@angular/common/http";
import { BehaviorSubject } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AuthService } from "@app/core/services/auth/auth.service";
import { ShowingObject } from "@app/showings/models";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";

@Injectable({
  providedIn: "root",
})
export class DownloadFileService {
  private httpClient: HttpClient;

  constructor(
    private handler: HttpBackend,
    private authService: AuthService,
    private store: Store<AppState>
  ) {
    // Bypass HTTP Interceptor
    this.httpClient = new HttpClient(this.handler);
  }

  isDownloading$ = new BehaviorSubject<boolean>(false);

  downloadFile(url: string, fileName: string) {
    this.isDownloading$.next(true);

    return this.getFile(url).pipe(
      map((response: HttpResponse<Blob>) => {
        return this.createDownloadTag(response, fileName);
      }),
      catchError(() => {
        this.isDownloading$.next(false);
        return null;
      })
    );
  }

  downloadFileInSecure(url: string, fileName: string) {
    this.isDownloading$.next(true);

    return this.getFile(url, true).pipe(
      map((response: HttpResponse<Blob>) => {
        return this.createDownloadTag(response, fileName);
      }),
      catchError(() => {
        this.isDownloading$.next(false);
        return null;
      })
    );
  }

  private getFile(url: string, useAuthToken: boolean = false) {
    let headers = new HttpHeaders({});

    if (useAuthToken) {
      headers = new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.authService.getToken()}`,
      });
    }

    return this.httpClient
      .get(url, {
        headers,
        observe: "response",
        responseType: "blob",
      })
      .pipe(catchError((error) => error));
  }

  private createDownloadTag(response: HttpResponse<Blob>, fileName: string) {
    const blob = response.body as Blob;
    const a = document.createElement("a");
    a.style.display = "none";
    document.body.appendChild(a);
    a.href = window.URL.createObjectURL(blob);
    a.setAttribute("download", fileName);
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
    this.isDownloading$.next(false);
    return blob;
  }

  // TODO: this part related to Aktiv customer for their migration from Webmegler to Vitec ERP ant it's a temporary code
  downloadImages(object: ShowingObject) {
    const baseUrl = `https://estate-file-assembler.qbaseops.quedro.com/assemble-{data-type}-for/${object.eaOid}`;
    const fileName = `${object.street}_${object.zip}_${object.city}_{data-type}.zip`;
    const requests = [];

    if (object?.images?.length > 0) {
      requests.push(
        this.downloadFileInSecure(
          baseUrl.replace("{data-type}", "images"),
          fileName.replace("{data-type}", "images")
        )
      );
    }
    requests.push(
      this.downloadFileInSecure(
        baseUrl.replace("{data-type}", "documents"),
        fileName.replace("{data-type}", "documents")
      )
    );

    const taskSet: TaskSet = {
      label: "download_files",
      tasks: requests,
    };
    this.store.dispatch(addTaskSetToQueue(taskSet));
  }
}
