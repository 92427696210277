import {
  catchError,
  concatMap,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
} from "rxjs";

import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import {
  SIDEBAR_CREATE_LEAD_BASE_URL,
  SIDEBAR_CREATE_LEAD_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_LEAD } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import * as ccActions from "../../contacts/ngrx/create-contact.actions";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { resetDirty } from "../../ngrx/sidebar.actions";
import * as createLeadActions from "./create-lead.actions";
import { Action } from "@ngrx/store";
import { TaskTemplate } from "@app/sidebar/create-lead/ngrx/create-lead.reducer";
import { QObject } from "@app/models";

@Injectable()
export class CreateLeadEffects {
  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.getContactRequest),
      switchMap(({ id }) =>
        this.apiService.get(`contacts/${id}`).pipe(
          map((response: any) =>
            createLeadActions.getContactSuccess({ contact: response })
          ),
          catchError(() =>
            observableFrom([
              createLeadActions.getContactFailed(),
              RouterActions.go({
                path: [
                  "/crm/",
                  { outlets: { sidebar: SIDEBAR_CREATE_LEAD_BASE_URL } },
                ],
              }),
            ])
          )
        )
      )
    )
  );

  createLead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.createLeadRequest),
      switchMap(({ params }) =>
        this.apiService.post(`tasks/template`, params).pipe(
          concatMap(() =>
            observableFrom([
              sidebarActions.closeTab({ tabType: CREATE_LEAD }),
              createLeadActions.createLeadSuccess(),
              toastActions.success({ message: "created_lead_success" }),
            ])
          ),
          catchError(() => observableOf(createLeadActions.createLeadFailed()))
        )
      )
    )
  );

  createLeadFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.createLeadFailed),
      switchMap(() => this.translateService.get("created_lead_failed")),
      map((message: string) => toastActions.danger({ message }))
    )
  );

  createContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.createContactRequest),
      switchMap(({ params, source }) =>
        this.apiService.post("contacts", params).pipe(
          mergeMap((data: { contactId }) => {
            const actions: Action[] = [
              createLeadActions.createContactSuccess({
                contactId: data.contactId,
              }),
            ];
            if (source) {
              actions.push(
                ccActions.addContactToSourceRequest({
                  params: {
                    ...params,
                    eaTaskTypeId: source,
                    contactId: data.contactId,
                  },
                })
              );
            }
            return observableFrom(actions);
          })
        )
      )
    )
  );

  createContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.createContactSuccess),
      concatMap(({ contactId }) =>
        observableFrom([
          resetDirty({ tabType: CREATE_LEAD }),
          RouterActions.go({
            path: [
              "/crm",
              {
                outlets: { sidebar: SIDEBAR_CREATE_LEAD_CREATE_URL(contactId) },
              },
            ],
          }),
        ])
      )
    )
  );

  getTaskTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.getTaskTemplatesRequest),
      switchMap(() =>
        this.apiService.get(`task-templates/search`, {}, "api").pipe(
          map((response: { rows: TaskTemplate[] }) =>
            createLeadActions.getTaskTemplatesSuccess({
              taskTemplates: response.rows,
            })
          ),
          catchError(() =>
            observableOf(createLeadActions.getTaskTemplatesFailed())
          )
        )
      )
    )
  );

  getOfficeObjectsForSale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createLeadActions.getOfficeObjectForSaleRequest),
      switchMap(({ params }) =>
        this.apiService
          .get(
            `objects/search`,
            {
              eaOfficeId: params.eaOfficeId,
              status: "10,51",
              getResponsibleBroker: true,
              seller: true,
            },
            "api"
          )
          .pipe(
            map((response: { rows: QObject[] }) =>
              createLeadActions.getOfficeObjectForSaleSuccess({
                objects: response.rows,
              })
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}
}
