import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Contact, EmployeeDTO } from "@app/models";
import * as consentTypes from "@app/shared/utils/consent-types";
import {
  SIDEBAR_FOLLOW_UPS_BASE_URL,
  SIDEBAR_FOLLOW_UPS_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_FOLLOW_UP } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  concatMap,
  forkJoin as observableForkJoin,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
} from "rxjs";
import * as ccActions from "../../contacts/ngrx/create-contact.actions";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as createFollowUpActions from "./create-follow-up.actions";
import { Action } from "@ngrx/store";

@Injectable()
export class CreateFollowUpEffects {
  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFollowUpActions.getContactRequest),
      switchMap(({ id }) =>
        this.apiService.get(`contacts/${id}`).pipe(
          map((response: any) =>
            createFollowUpActions.getContactSuccess({
              contact: new Contact(response),
            })
          ),
          catchError(() =>
            observableFrom([
              createFollowUpActions.getContactFailed(),
              RouterActions.go({
                path: [
                  "/crm",
                  { outlets: { sidebar: SIDEBAR_FOLLOW_UPS_BASE_URL } },
                ],
              }),
            ])
          )
        )
      )
    )
  );

  createContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFollowUpActions.createContactRequest),
      switchMap(({ params, source }) =>
        this.apiService.post("contacts", params).pipe(
          mergeMap((data: { contactId }) => {
            const actions: Action[] = [
              createFollowUpActions.createContactSuccess({
                contactId: data.contactId,
              }),
            ];
            if (source) {
              actions.push(
                ccActions.addContactToSourceRequest({
                  params: {
                    ...params,
                    eaTaskTypeId: source,
                    contactId: data.contactId,
                  },
                })
              );
            }
            return observableFrom(actions);
          }),
          catchError(() => observableOf(ccActions.createContactFail()))
        )
      )
    )
  );

  createContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFollowUpActions.createContactSuccess),
      concatMap(({ contactId }) =>
        observableFrom([
          sidebarActions.resetDirty({ tabType: CREATE_FOLLOW_UP }),
          RouterActions.go({
            path: [
              "/crm",
              {
                outlets: { sidebar: SIDEBAR_FOLLOW_UPS_CREATE_URL(contactId) },
              },
            ],
          }),
        ])
      )
    )
  );

  getOfficeEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFollowUpActions.getOfficeEmployeesRequest),
      switchMap(({ type, ...params }) =>
        this.apiService.get(`employees`, params).pipe(
          map((response: any) => response.rows.map((e) => new EmployeeDTO(e))),
          map((employees: EmployeeDTO[]) =>
            createFollowUpActions.getOfficeEmployeesSuccess({ employees })
          ),
          catchError(() =>
            observableOf(createFollowUpActions.getOfficeEmployeesFailed())
          )
        )
      )
    )
  );

  createFollowUpRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFollowUpActions.createFollowUpRequest),
      switchMap(({ params }) => {
        const requests = [
          this.apiService.patch(`contacts/${params.contactId}`, {
            origin: params.origin,
            maritalStatus: params.maritalStatus,
            preferredLanguage: params.preferredLanguage,
            dateOfBirth: params.dateOfBirth,
            sex: params.sex,
            consent: {
              activeConsentTypeAlias: consentTypes.ACCEPT_SALES_INFO,
              consentSourceType: "user",
              consentSourceId: params.eaEmployeeId,
              consentNote: params.description,
            },
          }),
          this.apiService.post("tasks", params),
        ];
        return observableForkJoin(requests).pipe(
          switchMap(() => [
            sidebarActions.closeTab({ tabType: CREATE_FOLLOW_UP }),
            createFollowUpActions.createFollowUpSuccess(),
            toastActions.success({ message: "create_follow_up_success" }),
          ]),
          catchError(() =>
            observableOf(createFollowUpActions.createFollowUpFailed())
          )
        );
      })
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
