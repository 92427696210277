import { CallingListContact } from "@app/lists/lists-calling-lists/models/calling-list";
import { createAction, props } from "@ngrx/store";

export const setSelectedCallingListId = createAction(
  "[Calling Lists] SET_SELECTED_CALLING_LIST_ID",
  props<{ callingListId: string }>()
);

export const setCallingListNotContactedContacts = createAction(
  "[Calling Lists] SET_CALLING_LIST_NOT_CONTACTED_CONTACTS",
  props<{ notContactedContacts: CallingListContact[] }>()
);

export const setSelectedContactId = createAction(
  "[Calling Lists] SET_SELECTED_CONTACT_ID",
  props<{ selectedContactId: string }>()
);

export const deleteContactFromCallingListNotContactedContacts = createAction(
  "[Calling Lists] DELETE_CONTACTS_FROM_CALLING_LIST_NOT_CONTACTED_CONTACTS",
  props<{ contactId: string }>()
);

export const reset = createAction("[Calling Lists] RESET");
