<div *ngIf="!!property">
  <span [class]="'icon ' + 'property-icon--' + (property | propertyTypeFromObject)">
    <app-icon size="none" [name]="(property | propertyTypeFromObject) | propertyTypeToIcon"></app-icon>
  </span>
  <span class="name-wrapper">
    {{ property.street }}
  </span>
  <span class="name-wrapper" *ngIf="!disableFullAddress">
    {{ property.zip }} {{ property.city }}
  </span>
  <span class="name-wrapper" *ngIf="!!property?.apartmentNumber">
    {{'no' | translate}} {{ property.apartmentNumber }}
  </span>
  <span *ngIf="!disableSummary" class="property-summary-container">
    <app-property-summary [eaOid]="property.eaOid"></app-property-summary>
  </span>
</div>
