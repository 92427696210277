<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="
      ((dealType$ | async) === 'seller' ? 'add_seller' : 'add_buyer')
        | translate
    "
  >
  </sidebar-header>
  <div class="scroll-view">
    <section>
      <ng-container *ngIf="contactTypeControls$ | async as controls">
        <app-segmented-controls
          *ngIf="controls.length > 1"
          [active]="selectedContactType"
          [controls]="controls"
          (change)="selectedContactType = $event"
        ></app-segmented-controls>
      </ng-container>
    </section>

    <ng-container [ngSwitch]="selectedContactType">
      <app-company-form
        *ngSwitchCase="'company'"
        (onSubmit)="handleSubmit($event)"
        #companyForm
      ></app-company-form>
      <app-estate-form
        *ngSwitchCase="'estate'"
        (onSubmit)="handleSubmit($event)"
        #estateForm
      ></app-estate-form>
      <contact-form
        *ngSwitchCase="'person'"
        #contactForm
        [contact]="prefillData$ | async"
        [editMode]="false"
        (onSubmit)="handleSubmit($event)"
        (onQuedroContactSelected)="onQuedroContactSelected($event)"
      ></contact-form>
    </ng-container>
  </div>

  <div *ngIf="isBusy$ | async as isBusy" class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      tabindex="-1"
      buttonType="save"
      label="save_and_create"
      [isLoading]="isBusy.status"
      [disabled]="isBusy.status"
      (click)="submit(false)"
      scrollToInvalidControl
      [formEl]="activeForm?.form"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="save"
      [isLoading]="isBusy.status"
      [disabled]="isBusy.status"
      (click)="submit(true)"
      scrollToInvalidControl
      [formEl]="activeForm?.form"
    >
    </app-q-button>
  </div>
</div>

<ng-template #contactCard>
  <section class="showings-container">
    <card
      class="receivers__card"
      [color]="contactsColor"
      [removable]="true"
      (remove)="existingContactSelected = null"
    >
      <div title>{{ existingContactSelected.getFullName() }}</div>
      <div body>
        <small>{{ existingContactSelected.email }}</small>
        <br />
        <small>{{ existingContactSelected.msisdn }}</small>
      </div>
    </card>
  </section>
</ng-template>
