import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs";
import { AppState } from "./app.reducer";
import { ConnectableTab } from "./sidebar/sidebar-connectable-tab";
import * as sidebarActions from "./sidebar/ngrx/sidebar.reducer";

@Injectable()
export class CustomReuseStrategy implements RouteReuseStrategy {
  routesToCache: string[] = [
    "crm/root/support",
    "crm/root/send-message",
    "crm/root/calling-list/new",
    "crm/root/competitions/new",
    "crm/root/competitions/:id/edit",
    "crm/root/external-tips/new",
    "crm/root/external-tips/send",
    "crm/root/external-tips/:id/edit",
    "crm/root/contacts/potential-buyer/new",
    "crm/root/external-provider/new",
    "crm/root/external-provider/connect",
    "crm/root/search-profile/new",
    "crm/root/search-profile/:id/edit",
    "crm/root/search-profile/:id/alert",
    "crm/root/eika/:contactId",
    "crm/root/eika/:contactId/:eaOid",
    "crm/root/erp-showings/new",
    "crm/root/erp-showings/:id",
    "crm/root/erp-employees/new",
    "crm/root/erp-offices/new",
    "crm/root/message-templates/new",
    "crm/root/message-templates/:id/edit",
  ];
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  constructor(@Inject(Store) private _store: Store<AppState>) {}

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this.routesToCache.indexOf(this.getPathFromRoot(route)) > -1;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRouteHandles.set(this.getPathFromRoot(route), handle);
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    let shouldAttach = false;
    const handle: DetachedRouteHandle = this.storedRouteHandles.get(
      this.getPathFromRoot(route)
    );

    if (handle && this.isConnectableTab(handle["componentRef"].instance)) {
      this._store
        .pipe(
          select(
            sidebarActions.getTab(handle["componentRef"].instance.tabType)
          ),
          take(1)
        )
        .subscribe((tab) => {
          shouldAttach = tab.dirty;
          if (!shouldAttach) {
            this.storedRouteHandles.delete(this.getPathFromRoot(route));
          }
        });
    }

    return handle && shouldAttach;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRouteHandles.get(this.getPathFromRoot(route));
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  isConnectableTab(
    componentInstance: any
  ): componentInstance is ConnectableTab {
    return (<ConnectableTab>componentInstance).connectTab !== undefined;
  }

  private getPathFromRoot(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((r) => r.routeConfig && r.routeConfig.path)
      .filter((path) => !!path)
      .join("/");
  }
}
