import { Contact, QObject } from "@app/models";
import { Municipality } from "@app/models/municipality";
import { createAction, props } from "@ngrx/store";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";

const defaultObjectSearchParams = {
  listNumber: "100",
  template: "crm2",
  groups: "locations",
};

export const updateResidenceRequest = createAction(
  "[External Provider] UPDATE_RESIDENCE_REQUEST",
  props<{
    residence: Partial<QObject>;
    extraData?: Record<string, unknown>;
    eaOid: string;
    contactId: string;
    hasObjectLinks: boolean;
  }>()
);

export const updateResidenceSuccess = createAction(
  "[External Provider] UPDATE_RESIDENCE_SUCCESS",
  props<{
    eaOid: string;
    contactId: string;
    extraData?: Record<string, unknown>;
    hasObjectLinks: boolean;
  }>()
);

export const updateResidenceFail = createAction(
  "[External Provider] UPDATE_RESIDENCE_FAIL"
);

export const updateContactRequest = createAction(
  "[External Provider] UPDATE_CONTACT_REQUEST",
  props<{
    contact: Partial<Contact>;
    residence: Partial<QObject>;
    extraData?: Record<string, unknown>;
    eaOid: string;
    contactId: string;
    hasObjectLinks: boolean;
  }>()
);

export const updateContactSuccess = createAction(
  "[External Provider] UPDATE_CONTACT_SUCCESS",
  props<{
    residence: Partial<QObject>;
    extraData?: Record<string, unknown>;
    eaOid: string;
    contactId: string;
    hasObjectLinks: boolean;
  }>()
);

export const updateContactFail = createAction(
  "[External Provider] UPDATE_CONTACT_FAIL"
);

export const patchProviderResidenceRequest = createAction(
  "[External Provider] PATCH_PROVIDER_RESIDENCE_REQUEST",
  props<{
    params: {
      eaOid: string;
      contactId: string;
      extraData?: Record<string, unknown>;
      hasObjectLinks: boolean;
    };
  }>()
);

export const patchProviderResidenceSuccess = createAction(
  "[External Provider] PATCH_PROVIDER_RESIDENCE_SUCCESS",
  props<{ eaOid: string; hasObjectLinks: boolean }>()
);

export const patchProviderResidenceFail = createAction(
  "[External Provider] PATCH_PROVIDER_RESIDENCE_FAIL",
  props<{ message: boolean }>()
);

export const getResidenceRequest = createAction(
  "[External Provider] GET_RESIDENCE_REQUEST",
  props<{ eaOid: string }>()
);

export const getResidenceSuccess = createAction(
  "[External Provider] GET_RESIDENCE_SUCCESS",
  props<{ qObject: QObject }>()
);

export const getResidenceFail = createAction(
  "[External Provider] GET_RESIDENCE_FAIL"
);

export const getContactResidences = (contactId: string) => {
  const params = {
    isSold: false,
    publishedOnNet: "both",
    contactId,
  };

  return getContactResidencesRequest({ params });
};

export const getContactResidencesRequest = createAction(
  "[External Provider] GET_CONTACT_RESIDENCES_REQUEST",
  props<{
    params: {
      isSold: boolean;
      publishedOnNet: string;
      contactId: string;
    };
  }>()
);

export const getContactResidencesSuccess = createAction(
  "[External Provider] GET_CONTACT_RESIDENCES_SUCCESS",
  props<{ qObjects: QObject[] }>()
);

export const getContactResidencesFail = createAction(
  "[External Provider] GET_CONTACT_RESIDENCES_FAIL"
);

export const getMunicipalitiesRequest = createAction(
  "[External Provider] GET_MUNICIPALITIES_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getMunicipalitiesSuccess = createAction(
  "[External Provider] GET_MUNICIPALITIES_SUCCESS",
  props<{ municipalities: Municipality[] }>()
);

export const getMunicipalitiesFail = createAction(
  "[External Provider] GET_MUNICIPALITIES_FAIL"
);

export const getPreselectedMunicipalityRequest = createAction(
  "[External Provider] GET_PRESELECTED_MUNICIPALITY_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getPreselectedMunicipalitySuccess = createAction(
  "[External Provider] GET_PRESELECTED_MUNICIPALITY_SUCCESS",
  props<{ municipality: Municipality }>()
);

export const getPreselectedMunicipalityFail = createAction(
  "[External Provider] GET_PRESELECTED_MUNICIPALITY_FAIL"
);

export const getObjectStatusesRequest = createAction(
  "[External Provider] GET_OBJECT_STATUSES_REQUEST",
  props<{ country: string }>()
);

export const getObjectStatusesSuccess = createAction(
  "[External Provider] GET_OBJECT_STATUSES_SUCCESS",
  props<{ objectStatuses: ObjectStatus[] }>()
);

export const getObjectStatusesFail = createAction(
  "[External Provider] GET_OBJECT_STATUSES_FAIL"
);

export const getObjectSearchResults = (filters: Record<string, unknown>) => {
  const params = {
    ...defaultObjectSearchParams,
    ...filters,
  };
  return getObjectSearchResultsRequest({ params });
};

export const getObjectSearchResultsRequest = createAction(
  "[External Provider] GET_OBJECT_SEARCH_RESULTS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getObjectSearchResultsSuccess = createAction(
  "[External Provider] GET_OBJECT_SEARCH_RESULTS_SUCCESS",
  props<{ locations: QObject[] }>()
);

export const getObjectSearchResultsFail = createAction(
  "[External Provider] GET_OBJECT_SEARCH_RESULTS_FAIL"
);

export const patchSalesMeetingResidenceRequest = createAction(
  "[External Provider] PATCH_SALES_MEETING_REQUEST",
  props<{ eaOid: string; eaSalesMeetingId: string }>()
);

export const patchSalesMeetingResidenceSuccess = createAction(
  "[External Provider] PATCH_SALES_MEETING_SUCCESS",
  props<{ contactId: string; eaCrmSalesMeetingId: string }>()
);

export const patchSalesMeetingResidenceFail = createAction(
  "[External Provider] PATCH_SALES_MEETING_FAIL"
);

export const getOpenInExternalProviderRequest = createAction(
  "[External Provider] GET_OPEN_IN_EXTERNAL_PROVIDER_REQUEST"
);

export const getOpenInExternalProviderSuccess = createAction(
  "[External Provider] GET_OPEN_IN_EXTERNAL_PROVIDER_SUCCESS",
  props<{ hasOpenIn: boolean }>()
);

export const getOpenInExternalProviderFail = createAction(
  "[External Provider] GET_OPEN_IN_EXTERNAL_PROVIDER_FAIL"
);

export const openCreationFormInExternalProviderRequest = createAction(
  "[External Provider] OPEN_CREATION_FORM_IN_EXTERNAL_PROVIDER_REQUEST",
  props<{ eaCrmSalesMeetingId: string }>()
);

export const openCreationFormInExternalProviderSuccess = createAction(
  "[External Provider] OPEN_CREATION_FORM_IN_EXTERNAL_PROVIDER_SUCCESS",
  props<{ url: string }>()
);

export const openCreationFormInExternalProviderFail = createAction(
  "[External Provider] OPEN_CREATION_FORM_IN_EXTERNAL_PROVIDER_FAIL"
);

export const getExternalProviderLinkRequest = createAction(
  "[External Provider] GET_EXTERNAL_PROVIDER_LINK_REQUEST",
  props<{ eaOid: string }>()
);

export const getExternalProviderLinkSuccess = createAction(
  "[External Provider] GET_EXTERNAL_PROVIDER_LINK_SUCCESS",
  props<{ eaOid: string | null }>()
);

export const getExternalProviderLinkFail = createAction(
  "[External Provider] GET_EXTERNAL_PROVIDER_LINK_FAIL"
);

export const reset = createAction("[External Provider] RESET");
