export class EmployeeDTO {
  eaEmployeeId: string;
  fullName: string;
  firstName: string;
  familyName: string;
  title: string;
  position: string;
  countryCode: string;
  mobile: string;
  phone: string;
  email: string;
  admin: boolean;
  signature: string;
  type: string;
  photo: string;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
