import { WeatherSymbol } from "@app/shared/modules/weather/weather";
import moment from "moment";
import { API_DATE_FORMAT } from "../../../utils/api-defaults";
import { EventType, EXCHANGE } from "../../../utils/event-types";

export class EventDTO {
  type: EventType;
  showingType: string;
  title: string;
  startTime: string;
  endTime: string;
  formatedStartTime: string;
  formatedEndTime: string;
  eaOid: string;
  externalId: string;
  street: string;
  zip: string;
  city: string;
  latitude: string;
  longitude: string;
  eaEmployeeId: string;
  employeeFullName: string;
  contactFullName: string;
  contactId: string;
  eaOfficeId: string;
  officeName: string;
  forecastData: {
    temperature: string;
    weatherSymbol: WeatherSymbol;
    forecastTimestamp: string;
  };
  weatherDataProviderName: string | null;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getAddress(): string {
    if (this.street && this.zip && this.city) {
      return `${this.street}, ${this.zip} ${this.city}`;
    } else if (this.zip && this.city) {
      return `${this.zip} ${this.city}`;
    } else if (this.street && this.city) {
      return `${this.street}, ${this.city}`;
    } else if (this.street) {
      return this.street;
    } else if (this.city) {
      return this.city;
    } else if (this.zip) {
      return this.zip;
    }
    return "";
  }

  getStartTime(): moment.Moment {
    return moment(this.startTime, API_DATE_FORMAT);
  }

  getEndTime(): moment.Moment {
    return moment(this.endTime, API_DATE_FORMAT);
  }

  isFullDay(): boolean {
    const start = moment(this.getStartTime());
    const end = moment(this.getEndTime());
    const diff = moment.duration(end.diff(start)).asHours();
    return diff >= 24;
  }

  isFullDayExchangeEvent(): boolean {
    return this.type === EXCHANGE && this.isFullDay();
  }
}
