import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@app/app.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { CONTACTS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { CONNECT_IN_EXTERNAL_PROVIDER } from "@app/shared/utils/tab-types";
import { CrmModule } from "@app/sidebar/models/crm-module";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map } from "rxjs";

@Injectable()
export class ExternalProviderConnectGuard implements CanActivate {
  crmModules = [CONTACTS_CRM_MODULE];

  constructor(
    private sidebarService: SidebarService,
    private store: Store<AppState>
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAvailable$ = this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      first(),
      map((response) => !!(response.enabled && response.name))
    );

    const crmModule = this.crmModules
      .map((module) => new CrmModule(module))
      .find((module) => module.isModuleActive(state.url));

    return combineLatest([
      this.sidebarService.canActivateTab(
        route,
        state.url,
        crmModule,
        CONNECT_IN_EXTERNAL_PROVIDER
      ),
      isAvailable$,
    ]).pipe(map(([canActivate, enabled]) => canActivate && enabled));
  }
}
