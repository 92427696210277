<div id="toast-container" class="center">
  <div id="toast" class="alert alert-{{state.type}}" *ngIf="display" [@slideUpDown]>
    <div id="toast__close">
      <i (click)="hideToast()" class="fa fa-close"></i>
    </div>
    <div class="layout">
      <p>{{state.message | translate}}</p>
      <div *ngIf="state.toastAction" (click)="dispatch()" class="btn action">
        {{state.toastAction.label}}
        <i *ngIf="state.toastAction.icon" class="fa {{state.toastAction.icon}}"></i>
      </div>
      <div *ngIf="state.toastLink" class="btn action">
        <a [href]="state.toastLink.link" target="_blank" rel="noopener noreferrer" class="toast-link">
          {{state.toastLink.label}}
          <i *ngIf="state.toastLink.icon" class="{{state.toastLink.icon}}"></i>
        </a>
      </div>
    </div>
  </div>
</div>
