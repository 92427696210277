<h3>
  <app-icon size="large" name="calendar"></app-icon>&nbsp;<span>{{
    "calendar" | translate
  }}</span>
</h3>
<div class="cal-container" [class.loading-pulse]="loading$ | async">
  <div
    *ngIf="showHideModalButton"
    (click)="hideModal()"
    class="cal-controller-close"
  >
    <i class="fa fa-close pull-right"></i>
  </div>
  <div *ngIf="!(isMobile$ | async)" class="cal-container--left">
    <div class="cal-controller">
      <div class="cal-controller-months">
        <div>
          <div
            class="cal-controller-header"
            (click)="setDpViewMode('month'); dp.show()"
          >
            <h5>
              {{ viewDate | localizedDate : "MMMM" | titlecase }}
            </h5>
            <i class="fa fa-angle-down"></i>
          </div>
        </div>
        <div>
          <div
            class="cal-controller-header"
            (click)="setDpViewMode('year'); dp.show()"
          >
            <h5>
              {{ viewDate | localizedDate : "y" }}
            </h5>
            <i class="fa fa-angle-down"></i>
          </div>
        </div>
      </div>
      <div class="cal-controller-arrows">
        <div (click)="previousDay()">
          <i
            class="fa fa-angle-left cal-arrow pull-right cal-controller-arrow"
          ></i>
        </div>
        <div
          mwlCalendarToday
          [(viewDate)]="viewDate"
          (viewDateChange)="viewDateChange($event)"
        >
          <div class="cal-controller-header-right">
            <h5 translate>today</h5>
          </div>
        </div>
        <div (click)="nextDay()">
          <i
            class="fa fa-angle-right cal-arrow pull-left cal-controller-arrow"
          ></i>
        </div>
      </div>
    </div>
    <div
      class="datepicker-dropdown"
      [bsValue]="viewDate"
      bsDatepicker
      #dp="bsDatepicker"
      (onShown)="onOpenCalendar($event)"
    ></div>
    <custom-calendar-month-view
      *ngIf="!(loading$ | async); else calSpinner"
      [viewDate]="viewDate"
      [events]="calMonthEvents$ | async"
      [activeDayIsOpen]="activeDayIsOpen"
      [weekStartsOn]="1"
      [cellTemplate]="customCellTemplate"
      [headerTemplate]="customHeaderTemplate"
      [locale]="locale"
      [openDayEventsTemplate]="customEventActionTemplate"
      (dayClicked)="dayClicked($event.day)"
      (beforeViewRender)="beforeViewRender()"
      class="cal-body"
    >
    </custom-calendar-month-view>
    <ng-template
      #customHeaderTemplate
      let-days="days"
      let-locale="locale"
      let-trackByWeekDayHeaderDate="trackByWeekDayHeaderDate"
    >
      <div class="cal-cell-row cal-header">
        <div class="cal-cell">{{ "week_abbreviation" | translate }}.</div>
        <div
          class="cal-cell"
          *ngFor="let day of days; trackBy: trackByWeekDayHeaderDate"
          [class.cal-past]="day.isPast"
          [class.cal-today]="day.isToday"
          [class.cal-future]="day.isFuture"
          [class.cal-weekend]="day.isWeekend"
          [ngClass]="day.cssClass"
        >
          {{ day.date | dayAbbreviation }}
        </div>
      </div>
    </ng-template>
    <ng-template #customEventActionTemplate> </ng-template>
    <ng-template
      #customCellTemplate
      let-day="day"
      let-locale="locale"
      let-highlightDay="highlightDay"
      let-openDay="openDay"
    >
      <div class="cal-cell-top-custom flex-center">
        <span
          class="cal-day-number-custom flex-center"
          [ngClass]="{
            'cal-out-month': !day.inMonth,
            'cal-today': day.isToday,
            'cal-open': day === openDay
          }"
        >
          <span
            class="cal-day-badge-custom"
            *ngIf="day.badgeTotal > 0 && day !== openDay"
          >
            {{ day.badgeTotal.length }}
          </span>
          {{ day.date | calendarDate : "monthViewDayNumber" : locale }}
        </span>
      </div>
    </ng-template>
    <ng-template #calSpinner>
      <div class="cal-loading flex-center">
        <spinner [size]="'1.5rem'"></spinner>
      </div>
    </ng-template>
  </div>
  <div class="cal-container--right">
    <div class="calendar-widget flex-space-between">
      <h5 class="header-column flex-1">
        {{ "planned_events_for" | translate }}
        {{ getDisplayDate(viewDate) | translate | lowercase }}
      </h5>
    </div>

    <div class="list-items" *ngIf="!(loading$ | async)">
      <calendar-widget-list-item
        *ngFor="let event of selectedEvents; let i = index"
        [event]="event"
        [eventIndex]="i"
        [weatherFeatureEnabled]="weatherFeatureEnabled$ | async"
        (click)="onClick(event)"
      >
      </calendar-widget-list-item>
    </div>

    <div
      class="no-events"
      *ngIf="selectedEvents?.length === 0 || (loading$ | async)"
    >
      <span *ngIf="!(loading$ | async); else spinner">{{
        "no_planned_events" | translate
      }}</span>
      <ng-template #spinner>
        <spinner [size]="'1.5rem'"></spinner>
      </ng-template>
    </div>
    <div class="weather-source" *ngIf="shouldShowWeatherSource(selectedEvents)">
      {{ "weather_source" | translate }}: {{ getWeatherSource(selectedEvents) }}
    </div>
    <div *ngIf="isMobile$ | async">
      <ng-container *ngTemplateOutlet="calendarModalButton"></ng-container>
    </div>
  </div>
</div>

<ng-template #calendarModalButton>
  <button class="btn btn-secondary" (click)="calendarModal.show()">
    <app-icon name="calendar-alt"></app-icon>
    {{ "calendar" | translate }}
  </button>
</ng-template>

<div
  bsModal
  class="modal fade"
  #calendarModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: true }"
>
  <div class="modal-dialog" *ngIf="isMobile$ | async">
    <calendar-widget
      (modalCloseClicked)="calendarModal.hide()"
      [showHideModalButton]="true"
      [date]="date$ | async"
    >
    </calendar-widget>
  </div>
</div>
