import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, Country, TaskType } from "@app/models";
import {
  ContactMaritalStatus,
  ContactOwnsResidence,
  ContactSex,
} from "@app/models/contact-dropdown-values";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import * as features from "@app/shared/config/utils/features";
import { validateEmail } from "@app/shared/modules/form-components/directives/email-validator.directive";
import { Address } from "@app/shared/modules/search-address/search-address/AddressProvider";
import { SearchProviderContact } from "@app/shared/modules/search-contact/models";
import { getEaIds, UserIds } from "@app/shared/user";
import * as formUtils from "@app/shared/utils/form-utils";
import { allFieldsRequiredOnAtLeastOneControlOrGroup } from "@app/shared/validators/form-group-validator";
import { validatePhoneNumber } from "@app/shared/validators/phone-validator";
import * as externalContactModalActions from "@app/sidebar/shared/external-contact-service/ngrx/external-contact-service.actions";
import { ActionsSubject, select, Store } from "@ngrx/store";
import * as libphonenumber from "google-libphonenumber";
import * as i18nISOCountries from "i18n-iso-countries";
import moment from "moment";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  PartialObserver,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  withLatestFrom,
} from "rxjs";
import * as createContactActions from "../../contacts/ngrx/create-contact.actions";
import { CreateContactParams } from "../../contacts/ngrx/create-contact.actions";
import * as fromCreateContact from "../../contacts/ngrx/create-contact.reducer";
import { ofType } from "@ngrx/effects";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";

interface InputChanges extends SimpleChanges {
  contact?: SimpleChange;
}

@Component({
  selector: "contact-form",
  templateUrl: "./contact-form.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./contact-form.component.scss",
  ],
})
export class ContactFormComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @ViewChild("addressToggle", { static: false }) addressToggle: ElementRef;

  @Input() type: "person" | "company" | "estate" = "person";

  @Input() contact: Contact;
  @Input() editMode = true;
  @Input() enableSource = true;
  @Input() preselectedSource: number;
  @Input() enableDuplicateValidation = true;
  @Input() observer: PartialObserver<any>;
  @Input() onlyContactDetails = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onQuedroContactSelected = new EventEmitter<Contact>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSubmit = new EventEmitter<boolean>();

  additionalInfoHidden = true;
  checkedInput = false;
  form: FormGroup;
  submitted = false;
  unsubscribe$: Subject<void> = new Subject<void>();

  // if address suggester and validation should be enabled
  addressSuggesterFeature$: Observable<Feature>;
  addressValidationFeature$: Observable<Feature>;
  addressValues$: Observable<Address>;
  addressIsRequired$: Observable<boolean>;

  // if contact source should be enabled
  contactSourceFeature$: Observable<Feature>;
  contactSources$: Observable<TaskType[]>;

  // async validation to check if contact already exists
  asyncResults = { msisdn: [], phoneNumber: [], email: [] };

  // input to external-contact-service-modal
  externalServiceContact = new Contact();
  externalServiceContactType: string;

  // country code for validation
  countryCode$: Observable<string>;
  countryCode: string;
  defaultPreferredLangauge: string;
  currentLanguage: string;
  msisdnCountryCode: string;
  phoneNumberCountryCode: string;
  prefilledFields: string[] = [];

  contactSex = ContactSex;
  contactMaritalStatus = ContactMaritalStatus;
  contactOwnsResidence = ContactOwnsResidence;

  defaultConsentValidDays$: Observable<number>;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private phoneNumberService: PhoneNumberService,
    private cdr: ChangeDetectorRef,
    private dispatcher: ActionsSubject,
    private route: ActivatedRoute
  ) {
    this.buildForm();
  }

  setDefaultPhoneValidators() {
    this.store
      .pipe(
        select(fromConfig.getCountry),
        map((value) => value.toUpperCase()),
        first()
      )
      .subscribe((countryCode) => {
        this.updateMsisdnValidators(countryCode);
        this.updatePhoneNumberValidators(countryCode);
        this.cdr.detectChanges();
      });
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.setValidators();
    this.initResetForm(); // TODO: fix this later

    this.addressValues$ = this.form.get("address").valueChanges.pipe(
      debounceTime(200),
      filter((values) => values.street)
    );

    this.setDefaultPhoneValidators();

    if (this.enableSource) {
      this.initContactSource();
    }

    if (this.contact) {
      this.handleContactInfoSelected(this.contact);
    }

    this.registerObserverToFormValueChanges();
  }

  ngOnChanges(changes: InputChanges): void {
    if (changes.preselectedSource && this.preselectedSource) {
      this.form.get("source").setValue(this.preselectedSource);
    }
    if (changes.contact && !changes.contact.isFirstChange()) {
      this.handleContactInfoSelected(this.contact);
    }
    if (changes.editMode && !!this.editMode) {
      this.additionalInfoHidden = false;
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.store.dispatch(createContactActions.clearPrefillData());
  }

  registerObserverToFormValueChanges(): void {
    this.form.valueChanges
      .pipe(
        map(() => this.form.getRawValue()),
        startWith(this.form.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }

  getDatePickerPlaceHolder() {
    return moment(new Date()).localeData().longDateFormat("L").toUpperCase();
  }

  buildForm(): void {
    this.form = this.fb.group({
      consent: [false, Validators.required],
      consentValidTo: [moment().endOf("day").add(30, "days").toDate()],
      contactInfo: this.fb.group({
        msisdn: "",
        phoneNumber: "",
        email: ["", this.getEmailValidators()],
      }),
      address: this.fb.group({ street: "", zip: "", city: "" }),
      firstName: ["", Validators.required],
      familyName: "",
      source: this.preselectedSource ? this.preselectedSource : "",
      dateOfBirth: [""],
      maritalStatus: [""],
      ownsResidence: ["UNKNOWN"],
      sex: [""],
      preferredLanguage: [""],
      preferredLanguageName: [""],
    });
  }

  setValidators(): void {
    this.addressIsRequired$.pipe(first()).subscribe((addressIsRequired) => {
      this.form.setValidators([
        allFieldsRequiredOnAtLeastOneControlOrGroup([
          this.form.get("contactInfo.msisdn"),
          this.form.get("contactInfo.phoneNumber"),
          this.form.get("contactInfo.email"),
          this.form.get("address"),
        ]),
      ]);

      if (addressIsRequired) {
        this.form.get("street").setValidators(Validators.required);
        this.form.get("zip").setValidators(Validators.required);
        this.form.get("city").setValidators(Validators.required);
      }
    });
  }

  mapStateToProps(): void {
    this.addressSuggesterFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_SUGGESTER))
    );
    this.addressValidationFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.ADDRESS_VALIDATION))
    );
    this.contactSourceFeature$ = this.store.pipe(
      select(fromConfig.getFeature(features.CONTACT_SOURCE))
    );
    this.contactSources$ = this.store.pipe(
      select(fromCreateContact.getSources)
    );
    this.countryCode$ = this.store.pipe(select(fromConfig.getCountry));
    this.defaultConsentValidDays$ = this.store.pipe(
      select(fromConfig.getConsentValidDate)
    );

    combineLatest([this.countryCode$, this.getLanguage()]).subscribe(
      ([code, language]) => {
        this.countryCode = code;
        this.defaultPreferredLangauge = code;
        this.currentLanguage = language;
        setTimeout(() => {
          this.form
            .get("preferredLanguageName")
            .setValue(
              i18nISOCountries.getName(this.defaultPreferredLangauge, language)
            );
        }, 1);
      }
    );

    this.addressIsRequired$ = this.store.pipe(
      select(fromConfig.getCreateContactTypesConfig),
      map((config) => {
        const contactType = config.find((type) => type.type === "person");
        return contactType.addressIsRequired;
      })
    );
  }

  initContactSource(): void {
    this.contactSourceFeature$
      .pipe(
        filter((f) => f.enabled),
        switchMap(() => this.contactSources$),
        first()
      )
      .subscribe((sources) => {
        const control = this.form.get("source");
        control.setValidators([Validators.required]);
        control.updateValueAndValidity();
        if (sources.length === 0) {
          this.store.dispatch(createContactActions.getContactSource());
        }
      });
  }

  initResetForm(): void {
    this.store
      .pipe(
        select(fromCreateContact.getLoading),
        filter((loading) => loading === false),
        withLatestFrom(this.defaultConsentValidDays$),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(([, consentValidDays]) => {
        this.submitted = false;
        this.form.reset(this.getDefaultFormModel(consentValidDays));
      });
  }

  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      if (this.editMode) {
        this.form.get("consentValidTo").setValue(null);
      }
      this.onSubmit.emit(true);
    } else {
      this.onSubmit.emit(false);
      formUtils.markAllAsTouched(this.form);
    }
  }

  getCreateContactRequestParams(): Observable<{
    params: CreateContactParams;
    source: string;
  }> {
    return this.store.pipe(
      getEaIds,
      map((eaIds: UserIds) => {
        const { source } = this.form.getRawValue();
        const params = {
          ...eaIds,
          ...this.buildRequestObject(),
        };
        return { params, source };
      })
    );
  }

  buildRequestObject(): any {
    const {
      contactInfo,
      address,
      firstName,
      familyName,
      dateOfBirth,
      maritalStatus,
      sex,
      preferredLanguage,
      consentValidTo,
    } = this.form.getRawValue();
    const msisdn = this.phoneNumberService.toLegacyFormat(
      contactInfo.msisdn,
      this.msisdnCountryCode
    );
    const phoneNumber = this.phoneNumberService.toLegacyFormat(
      contactInfo.phoneNumber,
      this.phoneNumberCountryCode
    );
    const selectedLanguage = !!preferredLanguage
      ? preferredLanguage
      : this.defaultPreferredLangauge;

    return {
      ...contactInfo,
      ...address,
      msisdn,
      phoneNumber,
      firstName,
      familyName,
      dateOfBirth: this.convertDateOfBirthToApiFormat(dateOfBirth),
      maritalStatus,
      sex,
      msisdnCountry: this.msisdnCountryCode,
      phoneNumberCountry: this.phoneNumberCountryCode,
      preferredLanguage: selectedLanguage,
      consentValidTo: moment(consentValidTo).format(API_DATE_FORMAT),
    };
  }

  convertDateOfBirthToApiFormat(dateOfBirth): string {
    if (!dateOfBirth) {
      return dateOfBirth;
    }

    return moment(dateOfBirth).format("YYYYMMDD");
  }

  convertApiDateToDateFormat(dateOfBirth): any {
    if (!dateOfBirth) {
      return "";
    }

    return moment(dateOfBirth, "YYYYMMDD").toDate();
  }

  shouldShowFormAlert(): boolean {
    return this.form.hasError("allRequired") && this.submitted;
  }

  handleAsyncValidation(contacts: Contact[], path: string): void {
    this.asyncResults[path.split(".")[1]] = contacts;
    if (this.contact) {
      this.removeCurrentContactFromMsisdn();
      this.removeCurrentContactFromEmail();
      this.removeCurrentContactFromPhoneNumber();
    }
    this.form.get(path).updateValueAndValidity();
  }

  removeCurrentContactFromEmail() {
    this.asyncResults.email.forEach((contact, index) => {
      if (this.contact.email === contact.email) {
        this.asyncResults.email.splice(index, 1);
      }
    });
  }

  removeCurrentContactFromMsisdn() {
    this.asyncResults.msisdn.forEach((contact, index) => {
      if (this.contact.msisdn === contact.msisdn) {
        this.asyncResults.msisdn.splice(index, 1);
      }
    });
  }

  removeCurrentContactFromPhoneNumber() {
    this.asyncResults.phoneNumber.forEach((contact, index) => {
      if (this.contact.phoneNumber === contact.phoneNumber) {
        this.asyncResults.phoneNumber.splice(index, 1);
      }
    });
  }

  ngAfterViewInit(): void {
    this.handlePreDefinedValues();
    this.dispatcher
      .pipe(
        ofType(externalContactModalActions.setExternalContact),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(({ contact }) => {
        Object.keys(contact).forEach((key) => this.prefilledFields.push(key));
        if (
          this.prefilledFields.includes("street") ||
          this.prefilledFields.includes("zip") ||
          this.prefilledFields.includes("city")
        ) {
          this.addressToggle.nativeElement.hidden = false;
        }
        this.handleContactInfoSelected(contact);
        this.cdr.detectChanges();
      });
  }

  handlePreDefinedValues() {
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams.number) {
        this.form.get("contactInfo.msisdn").setValue(queryParams.number);
      }
    });
  }

  handleContactInfoSelected(contact: Contact): void {
    const { msisdn, msisdnCountry, phoneNumber, phoneNumberCountry } = contact;

    let processedMsisdn = msisdnCountry
      ? this.phoneNumberService.format(msisdn, msisdnCountry)
      : msisdn;

    const formMsisdn = this.form.get("contactInfo.msisdn").value;
    if (formMsisdn.length > 0 && processedMsisdn !== formMsisdn) {
      processedMsisdn = formMsisdn;
    }

    const getFormValue = (key: string, formKey?: string) =>
      contact[key] || this.form.get(formKey ? formKey : key)?.value;

    if (contact.preferredLanguage) {
      this.defaultPreferredLangauge = contact.preferredLanguage;
    }

    const preferredLanguageName = i18nISOCountries.getName(
      this.defaultPreferredLangauge,
      this.currentLanguage
    );

    this.form.patchValue({
      contactInfo: {
        msisdn: processedMsisdn,
        phoneNumber: phoneNumberCountry
          ? this.phoneNumberService.format(phoneNumber, phoneNumberCountry)
          : phoneNumber,
        email: getFormValue("email", "contactInfo.email"),
      },
      address: {
        street: getFormValue("street", "address.street"),
        zip: getFormValue("zip", "address.zip"),
        city: getFormValue("city", "address.city"),
      },
      firstName: getFormValue("firstName"),
      familyName: getFormValue("familyName"),
      dateOfBirth:
        this.convertApiDateToDateFormat(contact.dateOfBirth) ||
        this.form.get("dateOfBirth")?.value,
      sex: getFormValue("sex"),
      ownsResidence: !!contact?.profile
        ? contact?.profile?.ownsResidence
        : "UNKNOWN",
      maritalStatus: getFormValue("maritalStatus"),
      preferredLanguage: this.defaultPreferredLangauge,
      preferredLanguageName:
        preferredLanguageName || this.form.get("preferredLanguageName")?.value,
      consentValidTo: getFormValue("consentValidTo"),
    });

    if (msisdnCountry) {
      this.updateMsisdnValidators(msisdnCountry);
    }
    if (phoneNumberCountry) {
      this.updatePhoneNumberValidators(phoneNumberCountry);
    }
  }

  handleQuedroContactClicked(contact: Contact): void {
    this.onQuedroContactSelected.emit(contact);
  }

  updateMsisdnValidators(countryCode: string): void {
    this.msisdnCountryCode = countryCode;
    const control = this.form.get("contactInfo.msisdn");
    control.setValidators(this.getMsisdnValidators(countryCode));
    control.updateValueAndValidity();
  }

  updatePhoneNumberValidators(countryCode: string): void {
    this.phoneNumberCountryCode = countryCode;
    const control = this.form.get("contactInfo.phoneNumber");
    control.setValidators(this.getPhoneNumberValidators(countryCode));
    control.updateValueAndValidity();
  }

  getAnimationClass(value: any): string | void {
    if (value && !this.editMode) {
      return "prefilled";
    }
  }

  private getMsisdnValidators(countryCode: string): ValidatorFn[] {
    return [
      validatePhoneNumber(countryCode, {
        type: libphonenumber.PhoneNumberType.MOBILE,
      }),
      this.enableDuplicateValidation
        ? unique(this.asyncResults, "msisdn")
        : () => {},
    ];
  }

  private getPhoneNumberValidators(countryCode: string): ValidatorFn[] {
    return [
      validatePhoneNumber(countryCode),
      this.enableDuplicateValidation
        ? unique(this.asyncResults, "phoneNumber")
        : () => {},
    ];
  }

  private getEmailValidators(): ValidatorFn[] {
    return [
      validateEmail(),
      this.enableDuplicateValidation
        ? unique(this.asyncResults, "email")
        : () => {},
    ];
  }

  private getDefaultFormModel(consentValidDays: number = 30): any {
    return {
      consent: false,
      consentValidTo: moment()
        .endOf("day")
        .add(consentValidDays, "days")
        .toDate(),
      contactInfo: {
        msisdn: "",
        phoneNumber: "",
        email: "",
      },
      address: {
        street: "",
        zip: "",
        city: "",
      },
      firstName: "",
      familyName: "",
      source: [this.preselectedSource ? this.preselectedSource : ""],
    };
  }

  handleCountryChange(country: Country) {
    this.form.get("preferredLanguage").setValue(country.regionCode);
    this.form.get("preferredLanguageName").setValue(country.countryName);
  }

  getLanguage(): Observable<string> {
    return this.store.pipe(select(fromConfig.getLanguage));
  }

  /**
   * 3rd party integration for creating contacts
   * @param eniroContact
   */
  handleEniroContactClicked(eniroContact: SearchProviderContact): void {
    this.externalServiceContact = eniroContact.toQuedroContact(
      this.countryCode
    );
    this.externalServiceContactType = eniroContact.subscriberType;
    this.store.dispatch(
      externalContactModalActions.showExternalContactModal({
        params: {
          contact: this.externalServiceContact,
          type: this.externalServiceContactType,
        },
      })
    );
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleZipSelected(address: Address): void {
    const addressGroup = this.form.get("address");
    addressGroup.get("zip").setValue(address?.zip || "");
    addressGroup.get("city").setValue(address?.city || "");
    addressGroup.updateValueAndValidity();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleAddressSelected(address: Address): void {
    const addressGroup = this.form.get("address");
    addressGroup.get("zip").setValue(address?.zip || "");
    addressGroup.get("city").setValue(address?.city || "");
    addressGroup.get("street").setValue(address?.street || "");
    addressGroup.updateValueAndValidity();
  }

  /**
   * 3rd party integration for receiving address info
   * @param address
   */
  handleAddressSuggestionSelected(address: Address): void {
    const addressGroup = this.form.get("address");
    addressGroup.get("street").setValue(address.street);
    addressGroup.get("zip").setValue(address.zip);
    addressGroup.get("city").setValue(address.city);
    addressGroup.updateValueAndValidity();
  }

  handleConsentChange(checked: boolean): void {
    this.checkedInput = checked;
  }
}

function unique(map: any, key: string): any {
  return () => (map[key].length === 0 ? null : { unique: { valid: false } });
}
