<form #form="ngForm">
  <div class="forgot-password">
    <div class="forgot-password__header">
      <div class="alert alert-info">
        {{ "forgot_password_msg1" | translate }}
        <br />
        {{ "forgot_password_msg2" | translate }}
      </div>
    </div>

    <div class="form-group">
      <div class="input-iconfield">
        <input
          [(ngModel)]="email"
          #emailModel="ngModel"
          id="email"
          name="email"
          type="email"
          validateEmail
          required
          placeholder="{{ 'email' | translate }}"
        />
        <i class="icon icon--lg fa-light fa-envelope"></i>
      </div>
    </div>
    <span
      class="validation-message"
      *ngIf="
        emailModel.control.hasError('validateEmail') &&
        emailModel.control.touched
      "
    >
      {{ "wrong_format" | translate }}
    </span>
  </div>

  <div class="forgot-password__footer">
    <app-q-button
      buttonType="cancel"
      label="cancel"
      classes="q-btn--cancel-border"
      routerLink="../"
    >
    </app-q-button>
    <button type="submit" class="q-btn q-btn--save" (click)="send()">
      <app-icon name="message-arrow-up-right"></app-icon>
      {{ "send" | translate }}
    </button>
  </div>
</form>
