<table class="table table--striped">
  <thead>
    <tr>
      <th translate>contact</th>
      <th translate>date</th>
      <th translate>address</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
      <tr *ngFor="let resource of resources$ | async">
        <td dataLabel="contact">
          <a
            [routerLink]="[
              '/crm',
              {
                outlets: {
                  primary: ['contacts', resource.contactId],
                  modal: null
                }
              }
            ]"
          >
            {{ resource.firstName + " " + resource.familyName }}
          </a>
        </td>
        <td dataLabel="date">
          {{ resource.timestamp | momentifyCustomFormat }}
        </td>
        <td dataLabel="address">{{ getAddress(resource) }}</td>
      </tr>
    </ng-container>

    <ng-template #empty>
      <tr>
        <td class="text-center" colspan="100%" translate>no_calls</td>
      </tr>
    </ng-template>
  </tbody>
</table>
