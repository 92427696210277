import { TaskListState } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as upcomingTasksListActions from "./upcoming-task-list.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export const initialState: TaskListState = {
  tasks: [],
  isLoading: false,
  loaded: false,
  totalNumberOfTasks: 0,
  numberOfTasksLeftToLoad: 0,
  allTasksLoaded: false,
  latestFetchAction: null,
  taskListConfig: {
    expanded: true,
    limit: 10,
  },
};

// TODO: Remove "| any" from action type
export function upcomingTaskListReducer(
  state: TaskListState,
  action: Action
): TaskListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(upcomingTasksListActions.getUpcomingRequest, (state, action) => ({
    ...state,
    isLoading: true,
    latestFetchAction: action,
  })),
  on(upcomingTasksListActions.getUpcomingNoTasksFound, (state) => ({
    ...state,
    isLoading: false,
    allTasksLoaded: true,
    loaded: true,
  })),
  on(
    upcomingTasksListActions.getUpcomingSuccess,
    (state, { tasks, listStart, totalRows, replace }) => {
      const numberOfTasksLeftToLoad = totalRows - listStart - tasks.length;
      return {
        ...state,
        isLoading: false,
        allTasksLoaded: totalRows <= 10 || numberOfTasksLeftToLoad === 0,
        totalNumberOfTasks: totalRows,
        numberOfTasksLeftToLoad,
        tasks: replace ? tasks : state.tasks.concat(tasks),
        loaded: true,
      };
    }
  ),
  on(upcomingTasksListActions.setListConfig, (state, { taskListConfig }) => ({
    ...state,
    taskListConfig: { ...state.taskListConfig, ...taskListConfig },
  })),
  on(upcomingTasksListActions.reset, (state) => ({
    ...initialState,
    taskListConfig: {
      ...state.taskListConfig,
      limit: Math.ceil(state.tasks.length / 10) * 10,
    },
  }))
);

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.upcomingTaskList
);

export const getLatestUpcomingTasksFetchAction = createSelector(
  getState,
  (state: TaskListState) => state.latestFetchAction
);

export const getUpcomingTasksLoaded = createSelector(
  getState,
  (state: TaskListState) => state.loaded
);

export const getUpcomingTasks = createSelector(
  getState,
  (state: TaskListState) => state.tasks
);

export const getTotalNumberOfUpcomingTasks = createSelector(
  getState,
  (state: TaskListState) => state.totalNumberOfTasks
);
