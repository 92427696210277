import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-mobile-card",
  templateUrl: "./mobile-card.component.html",
  styleUrls: ["./mobile-card.component.scss"],
})
export class MobileCardComponent {
  @Input() color: "WHITE" | "OFF-WHITE" | "TRANSPARENT" = "WHITE";
  @Input() size: "SLIM" | "DEFAULT" = "DEFAULT";
  @Input() shadow = true;
  @Input() clickable = true;
  @Input() selected = false;

  @Input() enableActionDropdown = false;
  @Input() enableCheckbox = false;
  @Input() enableMetadata = true;
  @Input() showCross = false;

  @Output() remove = new EventEmitter<any>();

  onRemove() {
    this.remove.emit();
  }
}
