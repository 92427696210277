import { CREATE_CALLING_LIST_LIMIT } from "@app/sidebar/calling-lists/create-calling-list/utils/create-calling-list-const";
import { createAction, props } from "@ngrx/store";
import { Segment } from "@app/campaigns/create-campaign/models/segment";
import { TypedPaginationListDTO } from "@app/models";

export const searchSegments = (
  segmentTypeFilter = "SALES_OPPORTUNITY_FILTER",
  limit: number = 2000
) => searchSegmentsRequest({ segmentTypeFilter, limit });

export const searchSegmentsRequest = createAction(
  "[SegmentationList] SEARCH_SEGMENTS_REQUEST",
  props<{
    segmentTypeFilter: string;
    limit: number;
  }>()
);

export const searchSegmentsSuccess = createAction(
  "[SegmentationList] SEARCH_SEGMENTS_SUCCESS",
  props<{ segments: Segment[] }>()
);

export const searchSegmentsFail = createAction(
  "[SegmentationList] SEARCH_SEGMENTS_FAIL"
);

export const noSegmentsFound = createAction(
  "[SegmentationList] NO_SEGMENTS_FOUND"
);

export const searchSegmentList = (
  params: {
    eaEmployeeId?: string;
    eaOfficeId?: string;
    offset?: number;
    limit?: number;
    sortBy?: string;
    sortOrder?: string;
  },
  segmentId: number
) => {
  const updateParams = {
    ...params,
    limit: params.limit ? params.limit : 10,
    offset: params.offset || 0,
    sortBy: params.sortBy ? params.sortBy : "insertDate",
    sortOrder: params.sortOrder ? params.sortOrder : "desc",
  };
  return searchSegmentListRequest({ params: updateParams, segmentId });
};

export const searchSegmentListRequest = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_REQUEST",
  props<{
    params: {
      eaEmployeeId?: string;
      eaOfficeId?: string;
      offset?: number;
      limit?: number;
      sortBy?: string;
      sortOrder?: string;
    };
    segmentId: number;
  }>()
);

export const searchSegmentListSuccess = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_SUCCESS",
  props<{ data: TypedPaginationListDTO<Segment>; segmentId: number }>()
);

export const searchSegmentListFail = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FAIL"
);

export const searchSegmentListForCallingList = (
  params: {
    eaEmployeeId: string;
    eaOfficeId: string;
  },
  segmentId: number
) => {
  const updatedParams = {
    ...params,
    limit: CREATE_CALLING_LIST_LIMIT,
  };

  return searchSegmentListForCallingListRequest({
    params: updatedParams,
    segmentId,
  });
};

export const searchSegmentListForCallingListRequest = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CALLING_LIST_REQUEST",
  props<{
    params: {
      eaEmployeeId: string;
      eaOfficeId: string;
      limit: number;
    };
    segmentId: number;
  }>()
);

export const searchSegmentListForCallingListSuccess = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CALLING_LIST_SUCCESS",
  props<{ payload: TypedPaginationListDTO<Segment> }>()
);

export const searchSegmentListForCallingListFail = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CALLING_LIST_FAIL"
);

export const searchSegmentListForCreateSegmentation = (
  params: {
    eaEmployeeId: string;
    eaOfficeId: string;
  },
  segmentId: number
) => {
  const updatedParams = {
    ...params,
    limit: CREATE_CALLING_LIST_LIMIT,
  };

  return searchSegmentListForCreateSegmentationRequest({
    params: updatedParams,
    segmentId,
  });
};

export const searchSegmentListForCreateSegmentationRequest = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CREATE_SEGMENTATION_REQUEST",
  props<{
    params: {
      eaEmployeeId: string;
      eaOfficeId: string;
    };
    segmentId: number;
  }>()
);

export const searchSegmentListForCreateSegmentationSuccess = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CREATE_SEGMENTATION_SUCCESS",
  props<{ payload: any }>()
);

export const searchSegmentListForCreateSegmentationFail = createAction(
  "[SegmentationList] SEARCH_SEGMENT_LIST_FOR_CREATE_SEGMENTATION_FAIL"
);

export const reset = createAction("[SegmentationList] RESET");

export const clearSegment = createAction(
  "[SegmentationList] CLEAR_SEGMENT",
  props<{ segmentId: number }>()
);
