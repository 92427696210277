import { ErrorOptions, UserContext } from "../models/index";
import { createAction, props } from "@ngrx/store";

export const captureException = createAction(
  "[Error] CAPTURE_EXCEPTION",
  props<{
    error: Error;
    options?: ErrorOptions;
  }>()
);

export const setUserContext = createAction(
  "[Error] SET_USER_CONTEXT",
  props<{
    user: UserContext;
  }>()
);

export const clearUserContext = createAction("[Error] CLEAR_USER_CONTEXT");

export const setExtraContext = createAction(
  "[Error] SET_EXTRA_CONTEXT",
  props<{ context: Record<string, unknown> }>()
);

export const setTagsContext = createAction(
  "[Error] SET_TAGS_CONTEXT",
  props<{ tags: Record<string, unknown> }>()
);
