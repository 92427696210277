import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import moment from "moment";

enum DateKeys {
  insertedFrom = "insertedFrom",
  insertedTo = "insertedTo",
  startTimeFrom = "startTimeFrom",
  startTimeTo = "startTimeTo",
  deliveryFrom = "deliveryFrom",
  deliveryTo = "deliveryTo",
  birthDateMin = "birthDateMin",
  birthDateMax = "birthDateMax",
  from = "from",
  to = "to",
  connectionDateMin = "connectionDateMin",
  connectionDateMax = "connectionDateMax",
  transferDateMin = "transferDateMin",
  transferDateMax = "transferDateMax",
  minDate = "minDate",
  maxDate = "maxDate",
  batchSendTimeMin = "batchSendTimeMin",
  batchSendTimeMax = "batchSendTimeMax",
}

export interface DateParams {
  from: string;
  to: string;
}

export const isDateKey = (key: string): boolean => {
  return key in DateKeys;
};

export const transformDateStringToDate = (dateString: string) => {
  if (!!dateString) {
    return moment(dateString, API_DATE_FORMAT).toDate();
  } else {
    return "";
  }
};

export const transformDateToString = (
  date: any,
  alterDate: "none" | "endOfDay" | "startOfDay" = "none"
) => {
  switch (alterDate) {
    case "none":
      return moment(date).format(API_DATE_FORMAT);
    case "endOfDay":
      return moment(date).endOf("day").format(API_DATE_FORMAT);
    case "startOfDay":
      return moment(date).startOf("day").format(API_DATE_FORMAT);
    default:
      return moment(date).format(API_DATE_FORMAT);
  }
};

export const compareDates = (from: any, to: any) => {
  if (!!from && !!to) {
    const fromDate = moment(from);
    const toDate = moment(to);
    return fromDate.diff(toDate) <= 0;
  }
  return true;
};
