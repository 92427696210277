import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ActionsSubject, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  bufferCount,
  first,
  map,
  mergeWith,
  Observable,
  switchMap,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as kpiActions from "../ngrx/kpi.actions";
import * as fromKpi from "../ngrx/kpi.reducer";
import * as KPI_TYPES from "../utils/kpi-types";
import { ofType } from "@ngrx/effects";

@Injectable()
export class KpiModalResolver implements Resolve<any> {
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    _routerStateSnapshot = {},
    queryParams = {}
  ): Observable<any> {
    const { type, eaEmployeeId } = route.params;
    const userIds = _.pick(route.params, ["eaOfficeId", "eaEmployeeId"]);
    const bufferCountNumber = eaEmployeeId ? 1 : type === KPI_TYPES.NPS ? 1 : 2;
    let queryParamsToUse =
      Object.keys(queryParams).length > 0
        ? queryParams
        : { ...route.queryParams };

    let useEmployeeNotDetail = false;
    if (!!queryParamsToUse["use"]) {
      if (!eaEmployeeId) {
        useEmployeeNotDetail = true;
      }

      queryParamsToUse = _.omit(queryParamsToUse, "use");
    }

    if (type === KPI_TYPES.NPS) {
      this.store.dispatch(
        kpiActions.fetchNpsSurveyResponsesRequest({
          survery: {
            type,
            userIds,
            searchParams: { ...queryParamsToUse, limit: 1000 },
          },
        })
      );
    } else {
      if (!eaEmployeeId) {
        this.store.dispatch(
          kpiActions.fetchKpiSummaryRequest({
            parameters: {
              type,
              userIds,
              searchParams: queryParamsToUse,
            },
          })
        );
      }

      this.store.dispatch(
        kpiActions.fetchKpiDetailsRequest({
          parameters: {
            type,
            userIds,
            searchParams: queryParamsToUse,
            useEmployeeNotDetail,
          },
        })
      );
    }

    const success$ = this.dispatcher.pipe(
      ofType(
        kpiActions.fetchKpiSummarySuccess,
        kpiActions.fetchKpiDetailsSuccess,
        kpiActions.fetchNpsSurveyResponsesSuccess
      ),
      bufferCount(bufferCountNumber),
      switchMap(() => this.store.pipe(fromKpi.getKpiDetailsByType(type)))
    );

    const fail$ = this.dispatcher.pipe(
      ofType(
        kpiActions.fetchKpiSummaryFail,
        kpiActions.fetchKpiDetailsFail,
        kpiActions.fetchEmployeeNpsFail
      ),
      map(() => [])
    );

    return success$.pipe(mergeWith(fail$), first());
  }
}
