import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppState } from "@app/app.reducer";
import { CallingListService } from "@app/calling-lists/calling-list.service";
import * as contactActions from "@app/contacts/contact.actions";
import { ContactEffects } from "@app/contacts/contact.effects";
import * as fromContact from "@app/contacts/contact.selectors";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { PRIVATE_NOTES } from "@app/shared/config/utils/features";
import * as fromUser from "@app/shared/user";
import * as NoteTypes from "@app/shared/utils/note-types";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "create-note-widget",
  templateUrl: "./create-note.component.html",
  styleUrls: ["./create-note.component.scss"],
})
export class CreateNoteComponent implements OnInit, OnDestroy {
  @Input() showSalesCallButton = true;

  @Output() submitPressed: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;
  unsubscribe$ = new Subject<void>();
  loading$: Observable<boolean>;
  privateEnabled$: Observable<boolean>;
  contactId$: Observable<string>;
  eaOfficeId$: Observable<string>;

  constructor(
    private fb: FormBuilder,
    private store: Store<AppState>,
    private contactEffects: ContactEffects,
    private callingListService: CallingListService,
    private contactService: ContactService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      note: ["", Validators.required],
      registerAsSalesCall: false,
      private: false,
    });
    this.contactEffects.createNoteSuccess$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.form.reset());
    this.mapStateToProps();
    this.callingListService.clearNote$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.form.get("note").setValue(""));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps(): void {
    this.loading$ = this.store.pipe(select(fromContact.getCreateNoteLoading));
    this.privateEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(PRIVATE_NOTES)),
      map((feature: Feature) => feature.enabled)
    );
    this.contactId$ = this.store.pipe(select(fromContact.getContactId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
  }

  storeNoteAndSalesCall() {
    if (this.form.valid) {
      this.storeNote();
      this.storeSalesCall();
    }
  }

  storeNote() {
    if (this.form.valid) {
      this.submitPressed.emit();
      this.store.dispatch(
        contactActions.createNoteRequest({
          parameters: {
            note: this.form.get("note").value,
            visibility: this.form.get("private").value ? "private" : null,
            type: NoteTypes.CUSTOMERCARD,
          },
        })
      );
    }
  }

  private storeSalesCall() {
    this.submitPressed.emit();
    if (this.form.valid) {
      combineLatest([this.contactId$, this.eaOfficeId$])
        .pipe(first())
        .subscribe(([contactId]) =>
          this.contactService
            .registerSalesCall(contactId, {
              code: ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
            })
            .subscribe()
        );
    }
  }

  onClick(): void {
    if (
      !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        window.navigator.userAgent
      )
    ) {
      this.store
        .pipe(
          select(fromSidebar.sidebarIsMinimized),
          first(),
          filter((minimized) => !minimized)
        )
        .subscribe(() => this.store.dispatch(sidebarActions.hide()));
    }
  }
}
