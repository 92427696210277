import { Component, Input } from "@angular/core";

@Component({
  selector: "calendar-widget-modal",
  templateUrl: "./calendar-widget-modal.component.html",
  styleUrls: ["./calendar-widget-modal.component.scss"],
})
export class CalendarWidgetModalComponent {
  @Input() date;
  @Input() textOnly = true;
  @Input() showAsRegularButton = false;
}
