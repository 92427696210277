import { BudgetStatisticsResponseMonth } from "@app/statistics/statistics-budget/models/budget-statistics-response";
import { createAction, props } from "@ngrx/store";
import { SalesTargetAtOffice } from "@app/models/sales-target-at-office";
import { BudgetCalculateTargetValues } from "@app/models/budget-calculate-target-values";

export const getSalesTargets = (
  eaOfficeId: string,
  year: number | string,
  month: string,
  loadedFromPreviousMonth: boolean
) => {
  const mergedMonth = year.toString() + month.toString();
  return getSalesTargetsRequest({
    eaOfficeId,
    month: mergedMonth,
    loadedFromPreviousMonth,
  });
};

export const getSalesTargetsRequest = createAction(
  "[SalesTarget] LOAD_REQUEST",
  props<{
    eaOfficeId: string;
    month: string;
    loadedFromPreviousMonth: boolean;
  }>()
);

export const getSalesTargetsSuccess = createAction(
  "[SalesTarget] LOAD_SUCCESS",
  props<{
    salesTargets: SalesTargetAtOffice;
    loadedFromPreviousMonth: boolean;
  }>()
);

export const getSalesTargetsFailed = createAction("[SalesTarget] LOAD_FAILED");

export const updateSalesTargetRequest = createAction(
  "[SalesTarget] UPDATE_SALES_TARGETS_REQUEST",
  props<{
    month: string;
    eaOfficeId: string;
    targetValues: string;
  }>()
);

export const updateSalesTargetSuccess = createAction(
  "[SalesTarget] UPDATE_SALES_TARGETS_SUCCESS"
);

export const updateSalesTargetFailed = createAction(
  "[SalesTarget] UPDATE_SALES_TARGETS_FAILED"
);

export const updateBudgetTargetRequest = createAction(
  "[SalesTarget] UPDATE_BUDGET_TARGETS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const updateBudgetTargetSuccess = createAction(
  "[SalesTarget] UPDATE_BUDGET_TARGETS_SUCCESS"
);

export const updateBudgetTargetFailed = createAction(
  "[SalesTarget] UPDATE_BUDGET_TARGETS_FAILED"
);

export const calculateBudgetTargetRequest = createAction(
  "[SalesTarget] CALCULATE_BUDGET_TARGETS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const calculateBudgetTargetSuccess = createAction(
  "[SalesTarget] CALCULATE_BUDGET_TARGETS_SUCCESS",
  props<{
    salesTargets: BudgetCalculateTargetValues;
    budgetTargets: Record<string, unknown>;
  }>()
);

export const calculateBudgetTargetFailed = createAction(
  "[SalesTarget] CALCULATE_BUDGET_TARGETS_FAILED"
);

export const storeNewRecruitRequest = createAction(
  "[SalesTarget] STORE_NEW_RECRUIT_REQUEST",
  props<{
    eaOfficeId: string;
    recruitName: string;
  }>()
);

export const storeNewRecruitSuccess = createAction(
  "[SalesTarget] STORE_NEW_RECRUIT_SUCCESS"
);

export const storeNewRecruitFailed = createAction(
  "[SalesTarget] STORE_NEW_RECRUIT_FAILED"
);

export const updateNewRecruitRequest = createAction(
  "[SalesTarget] UPDATE_NEW_RECRUIT_REQUEST",
  props<{
    eaOfficeId: string;
    recruitId: string;
    recruitName: string;
  }>()
);

export const updateNewRecruitSuccess = createAction(
  "[SalesTarget] UPDATE_NEW_RECRUIT_SUCCESS"
);

export const updateNewRecruitFailed = createAction(
  "[SalesTarget] UPDATE_NEW_RECRUIT_FAILED"
);

export const deleteNewRecruitRequest = createAction(
  "[SalesTarget] DELETE_NEW_RECRUIT_REQUEST",
  props<{
    eaOfficeId: string;
    recruitId: string;
  }>()
);

export const deleteNewRecruitSuccess = createAction(
  "[SalesTarget] DELETE_NEW_RECRUIT_SUCCESS"
);

export const deleteNewRecruitFailed = createAction(
  "[SalesTarget] DELETE_NEW_RECRUIT_FAILED"
);

export const hireNewRecruitRequest = createAction(
  "[SalesTarget] HIRE_NEW_RECRUIT_REQUEST",
  props<{
    eaOfficeId: string;
    eaEmployeeId: string;
    recruitId: string;
  }>()
);

export const hireNewRecruitSuccess = createAction(
  "[SalesTarget] HIRE_NEW_RECRUIT_SUCCESS"
);

export const hireNewRecruitFailed = createAction(
  "[SalesTarget] HIRE_NEW_RECRUIT_FAILED"
);

export const getEditPermissionsRequest = createAction(
  "[SalesTarget] GET_EDIT_PERMISSIONS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getEditPermissionsSuccess = createAction(
  "[SalesTarget] GET_EDIT_PERMISSIONS_SUCCESS",
  props<{
    officePermission: string;
  }>()
);

export const getEditPermissionsFailed = createAction(
  "[SalesTarget] GET_EDIT_PERMISSIONS_FAILED"
);

export const storeEditPermissionsRequest = createAction(
  "[SalesTarget] STORE_EDIT_PERMISSIONS_REQUEST",
  props<{ eaOfficeId: string; editType: string }>()
);

export const storeEditPermissionsSuccess = createAction(
  "[SalesTarget] STORE_EDIT_PERMISSIONS_SUCCESS"
);

export const storeEditPermissionsFailed = createAction(
  "[SalesTarget] STORE_EDIT_PERMISSIONS_FAILED"
);

export const getEditMonthPermissionsRequest = createAction(
  "[SalesTarget] GET_EDIT_MONTH_PERMISSIONS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getEditMonthPermissionsSuccess = createAction(
  "[SalesTarget] GET_EDIT_MONTH_PERMISSIONS_SUCCESS",
  props<{ monthBudget: BudgetStatisticsResponseMonth[] }>()
);

export const getEditMonthPermissionsFailed = createAction(
  "[SalesTarget] GET_EDIT_MONTH_PERMISSIONS_FAILED"
);

export const storeEditMonthPermissionsRequest = createAction(
  "[SalesTarget] STORE_EDIT_MONTH_PERMISSIONS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const storeEditMonthPermissionsSuccess = createAction(
  "[SalesTarget] STORE_EDIT_MONTH_PERMISSIONS_SUCCESS"
);

export const storeEditMonthPermissionsFailed = createAction(
  "[SalesTarget] STORE_EDIT_MONTH_PERMISSIONS_FAILED"
);

export const updateBudgetAndSalesTargetRequest = createAction(
  "[SalesTarget] UPDATE_BUDGET_AND_SALES_TARGET_REQUEST",
  props<{ payload: Record<string, unknown> }>()
);

export const updateBudgetAndSalesTargetSuccess = createAction(
  "[SalesTarget] UPDATE_BUDGET_AND_SALES_TARGET_SUCCESS"
);

export const updateBudgetAndSalesTargetFailed = createAction(
  "[SalesTarget] UPDATE_BUDGET_AND_SALES_TARGET_FAILED"
);
