<app-q-modal>
  <ng-container slot="header">
    {{
      "add_bid_for"
        | translate
          : {
              name:
                (potentialBuyer$ | async).firstName +
                " " +
                (potentialBuyer$ | async).familyName
            }
    }}
  </ng-container>
  <ng-container slot="body">
    <form [formGroup]="form">
      <app-q-input-thousand-separator
        [label]="'bid_amount'"
        [invalid]="form.get('amount').touched && form.get('amount').invalid"
        formControlName="amount"
      ></app-q-input-thousand-separator>
      <app-q-control-errors
        name="amount"
        [errors]="[
          { name: 'required', text: 'validation_required', when: 'touched' }
        ]"
      ></app-q-control-errors>
      <app-q-date-picker
        [label]="'date'"
        formControlName="date"
      ></app-q-date-picker>
      <app-q-input
        type="time"
        label="time"
        [step]="300"
        formControlName="time"
      ></app-q-input>
      <app-q-input
        type="text"
        label="comment"
        formControlName="condition"
      ></app-q-input>
      <div class="checkboxes">
        <mat-checkbox
          formControlName="isHidden"
          (change)="onHideBidChange($event)"
          >{{ "hide_bid" | translate }}</mat-checkbox
        >
        <div *ngIf="enableBroadcastSetting$ | async">
          <p>
            <strong>{{ "share_bids_with" | translate }}:</strong>
          </p>
          <div class="bid-checkboxes-container">
            <mat-checkbox formControlName="otherBidders">{{
              "other_bidders" | translate
            }}</mat-checkbox>
            <mat-checkbox formControlName="bidder">{{
              "the_bidder_making_bid" | translate
            }}</mat-checkbox>
            <mat-checkbox formControlName="sellers">{{
              "the_seller" | translate
            }}</mat-checkbox>
            <mat-checkbox formControlName="brokers">{{
              "the_agent" | translate
            }}</mat-checkbox>
          </div>
          <p>
            <strong>{{ "bid_note" | translate }}:</strong>&nbsp;{{
              "add_bid_broadcast_note" | translate
            }}
          </p>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button
      buttonType="cancel"
      label="close"
      classes="margin-right"
      mat-dialog-close
      (click)="closeModal()"
    ></app-q-button>
    <app-q-button
      buttonType="save"
      label="add"
      [disabled]="loading$ | async"
      [isLoading]="loading$ | async"
      (click)="submit()"
    >
    </app-q-button>
  </ng-container>
</app-q-modal>
