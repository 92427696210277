import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as contactActions from "@app/contacts/contact.actions";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { danger, success } from "@app/core/components/toast/ngrx/toast.actions";
import { ActionMessage } from "@app/shared/modules/contact-actions-widget/models/action-message";
import { ActionMessageTypes } from "@app/shared/modules/contact-actions-widget/models/message-types";
import { ActionsSubject, select, Store } from "@ngrx/store";
import { filter, first, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-contact-notes-widget",
  templateUrl: "./contact-notes-widget.component.html",
  styleUrls: ["./contact-notes-widget.component.scss"],
})
export class ContactNotesWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() contactId: string;
  @Input() eaEmployeeId: string;

  unsubscribe$ = new Subject<void>();
  delete$: Subject<string> = new Subject<string>();
  reload$: Subject<string> = new Subject<string>();

  writingNote = false;

  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  ngOnInit(): void {
    this.dispatcher
      .pipe(
        filter(
          (action) => action.type === contactActions.createNoteSuccess.type
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => this.reload$.next("RELOAD"));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes) {
    if (changes.contactId && !!this.contactId) {
      this.writingNote = false;
    }
  }

  onDelete(actionId: string): void {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_note_header",
        message: "remove_note_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        first()
      )
      .subscribe(() => this.delete$.next(actionId));
  }

  onMessage(event: ActionMessage): void {
    const action =
      event.type === ActionMessageTypes.SUCCESS
        ? success(event)
        : danger(event);
    this.store.dispatch(action);
  }
}
