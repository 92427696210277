import { DecimalPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "qNumber",
})
export class QNumberPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, digits?: string): any {
    if (!value) {
      return value;
    }
    return (<any>this.decimalPipe)
      .transform(value, digits, "en-US")
      .replace(/,/g, " "); // TODO Locale is first available in Angular ^4.4.0
  }
}
