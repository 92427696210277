import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import * as showingAttendanceActions from "@app/sidebar/potential-buyer/ngrx/attendance/attendance.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export function contactShowingAttendanceReducer(
  state: ContactShowingAttendance[],
  action: Action // ShowingAttendanceActions | PotentialBuyerActions
): ContactShowingAttendance[] {
  return reducer(state, action);
}

const reducer = createReducer(
  [],
  on(
    showingAttendanceActions.fetchShowingAttendanceSuccess,
    (_state, { contactsShowingAttendance }) => contactsShowingAttendance
  ),
  on(
    showingAttendanceActions.updateShowingAttendanceOptimistic,
    (state, { contactShowingAttendance }) => {
      let index;
      if (!!contactShowingAttendance.eaShowingSlotId) {
        index = state.findIndex(
          (attendance) =>
            attendance.eaShowingSlotId ===
            contactShowingAttendance.eaShowingSlotId
        );
      } else {
        index = state.findIndex(
          (attendance) =>
            attendance.showingId === contactShowingAttendance.showingId
        );
      }

      const attendances = [...state];
      if (index > 0) {
        attendances[index] = contactShowingAttendance;
      } else {
        attendances.push(contactShowingAttendance);
      }

      return attendances;
    }
  ),
  on(showingAttendanceActions.reset, potentialBuyerActions.reset, () => [])
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
export const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.potentialBuyer.attendances
);
