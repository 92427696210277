import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-lead-channels-form",
  templateUrl: "./lead-channels-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-channels-form.component.scss",
  ],
})
export class LeadChannelsFormComponent implements OnChanges {
  @Input() channel;

  form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.channel && !!this.channel) {
      this.fillForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      description: [""],
      taskExpirationDateInHours: ["", Validators.required],
    });
  }

  fillForm() {
    const { typeName, typeDescription, taskExpirationDateInHours } =
      this.channel;
    this.form.patchValue({
      name: typeName,
      description: typeDescription,
      taskExpirationDateInHours,
    });
  }

  submit(cb: (body: any) => void) {
    if (this.form.valid) {
      const { name, description, taskExpirationDateInHours } = this.form.value;
      cb({
        typeName: name,
        typeDescription: description,
        taskExpirationDateInHours,
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
