import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as fromSendPotentialBuyers from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.reducer";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
  throwError,
} from "rxjs";
import { ApiService, Categories } from "@app/core/services/api/api.service";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as pbActions from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.action";

@Injectable()
export class SendPotentialBuyersEffects {
  sendPotentialBuyerToERP$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pbActions.sendPotentialBuyersToERPRequest),
      map(({ eaOid, potentialBuyers, externalProviderName }) => {
        const requests = [];
        potentialBuyers.forEach((pb) => {
          requests.push(
            this.api
              .patch(
                `contact/${pb.contactId}/is-interested-in/${eaOid}`,
                { interestLevel: pb.bidStatus },
                Categories.Integrations
              )
              .pipe(
                map(() =>
                  this.store.dispatch(
                    pbActions.sendPotentialBuyersToERPSuccess()
                  )
                ),
                catchError((err) => {
                  const errorMessage =
                    `${pb.firstName} ${pb.familyName}`.trim();
                  this.store.dispatch(
                    pbActions.sendPotentialBuyersToERPFailure({
                      errorMessage,
                    })
                  );

                  err.customMessage = errorMessage;
                  return throwError(() => err);
                })
              )
          );
        });

        const taskSet: TaskSet = {
          label: this.translateService.instant("send_to_erp", {
            erp: externalProviderName.toUpperCase(),
          }),
          tasks: requests,
        };

        this.store.dispatch(addTaskSetToQueue(taskSet));
      }),
      switchMap(() => this.checkProgressIsDone$()),
      switchMap(() => of(pbActions.sendPotentialBuyersToERPDone()))
    )
  );

  sendPotentialBuyersToERPDone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(pbActions.sendPotentialBuyersToERPDone),
      switchMap(() =>
        this.store.pipe(select(fromSendPotentialBuyers.getErrors)).pipe(first())
      ),
      filter((errors) => errors.length === 0),
      map(() =>
        toastActions.success({ message: "selected_potential_buyers_sent" })
      )
    )
  );

  private checkProgressIsDone$(): Observable<boolean> {
    return this.store.pipe(select(fromSendPotentialBuyers.getIsDone)).pipe(
      filter((isDone) => isDone),
      first()
    );
  }

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private translateService: TranslateService,
    private store: Store<AppState>
  ) {}
}
