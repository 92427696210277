import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-widget-field",
  templateUrl: "./widget-detail-field.component.html",
  styleUrls: ["./widget-detail-field.component.scss"],
})
export class WidgetDetailFieldComponent {
  @Input() label: string;
  @Input() editable = false;
  @Input() hideEdit = true;

  @Output() editClicked: EventEmitter<void> = new EventEmitter<void>();
}
