<app-q-modal>
  <ng-container slot="header">
    {{ 'debugger_object' | translate }} - {{ requestType | uppercase }}
  </ng-container>
  <ng-container slot="body">
    <div class="body-container">
      <form [formGroup]="form" class="form-container">
        <div class="form-group">
          <app-q-search-object
            formControlName="eaOid"
            [invalid]="form.get('eaOid').touched && !form.get('eaOid').valid"
            [readonly]="false">
          </app-q-search-object>
          <app-q-control-errors
            name="eaOid"
            [errors]="[{ name: 'required', text: 'validation_required', when: 'touched'}]">
          </app-q-control-errors>
        </div>
        <div class="form-group">
          <label translate>adjacent_resources</label>
          <app-multi-select-dropdown
            [state]="'READY'"
            [parentFormGroup]="form"
            [parentFormControlName]="'adjacentResources'"
            [items]="adjacentResources"
            [displayProperty]="'label'"
            [valueProperty]="'value'"
            [isOffice]="false"
            [enableMobileSelect]="isMobile$ | async"
            [fullWidth]="isMobile$ | async"
            [enableSearch]="adjacentResources.length > 7"
          >
            <span placeholder translate>select_all</span>
            <span mobilePlaceholder translate>select_all</span>
          </app-multi-select-dropdown>
        </div>
      </form>
      <ng-container *ngIf="debuggerResponse$ | async as resp">
        <div>
          <pre>
            <p>{{resp | json }}</p>
            <div class="copy-button">
              <app-q-button
                buttonType="cancel"
                icon="copy"
                label=""
                tooltipText="copy_to_clipboard"
                (click)="copyResponse($event, resp)">
              </app-q-button>
            </div>
          </pre>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button mat-dialog-close (click)="closeModal();"
                    label="close" buttonType="cancel"
                    classes="margin-right">
      </app-q-button>
      <app-q-button (click)="submit();" label="save" buttonType="save"
                    [isLoading]="debuggerObjectService.isWorking$ | async"
                    [disabled]="debuggerObjectService.isWorking$ | async">
      </app-q-button>
    </div>
  </ng-container>
</app-q-modal>
