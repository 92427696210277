import { createAction, props } from "@ngrx/store";

export const addEntityId = createAction(
  "[MultiTasker] ADD_ENTITY_ID",
  props<{
    id: string;
    entityType: "contact" | "object";
  }>()
);

export const removeEntityId = createAction(
  "[MultiTasker] REMOVE_ENTITY_ID",
  props<{ id: string }>()
);

export const setEntityIds = createAction(
  "[MultiTasker] SET_ENTITY_IDS",
  props<{
    payload: {
      id: string;
      entityType: "contact" | "object";
    }[];
  }>()
);
