import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { go } from "@app/core/ngrx/router/router.actions";
import { CopyObjectModalComponent } from "@app/integrations/copy-object/component/copy-object-modal/copy-object-modal.component";
import { IntegrationResource } from "@app/integrations/models/enums";
import { ExternalObjectMetadata } from "@app/integrations/models/ExternalObjectMetadata";
import {
  fetchExternalObjectMetadataRequest,
  fetchExternalObjectMetadataReset,
} from "@app/integrations/ngrx/integrations.actions";
import {
  getExternalObjectMetadata,
  hasIntegration,
} from "@app/integrations/ngrx/integrations.reducer";
import { setContactsFilters } from "@app/lists/lists-contacts-advanced/ngrx/lists-contact-advanced.actions";
import { QObject } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import {
  CREATE_PRIVATE_SHOWING,
  EXTERNAL_PROVIDER,
  OPEN_BUYER_OR_SELLER_IFRAME,
} from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import * as fromUser from "@app/shared/user";
import { API_ONLY_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { openObjectMetaDataLink } from "@app/shared/utils/external-link-utils";
import * as externalProviders from "@app/shared/utils/external-providers";
import { constructObjectUrl } from "@app/shared/utils/object-utils";
import { ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { SIDEBAR_SELLER_OR_BUYER_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { DebuggerObjectModalComponent } from "@app/showings/components/debugger-object/debugger-object-modal.component";
import { ShowingObject } from "@app/showings/models";
import * as fromPotentialBuyers from "@app/showings/ngrx/potential-buyer/potential-buyer.reducer";
import { RequestType } from "@app/showings/services/debugger-object.service";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";
import { PropertyTriggerModalComponent } from "@app/shared/modules/property-components/property-trigger-modal/property-trigger-modal.component";
import { ObjectIntegrationService } from "@app/showings/services/object-integration.service";

@Component({
  selector: "app-property-quick-actions",
  templateUrl: "./property-quick-actions.component.html",
  styleUrls: ["./property-quick-actions.component.scss"],
})
export class PropertyQuickActionsComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() property: QObject & ShowingObject;

  showCreateShowing$: Observable<boolean>;
  showCopyObject$: Observable<boolean>;
  completeUrl$: Observable<string>;
  hasObjectLinks$: Observable<boolean>;
  externalObjectMetadata$: Observable<ExternalObjectMetadata>;
  isKiviCustomer$: Observable<boolean>;
  subListEnable$: Observable<boolean>;
  erpMenuLinks$: Observable<ExternalPresentableLink[]>;
  isSuperAdmin$: Observable<boolean>;
  hasDealDebugging$: Observable<boolean>;
  canCreateSeller$: Observable<boolean>;
  canCreateBuyer$: Observable<boolean>;
  canOpenBuyerSellerIframe$: Observable<boolean>;
  propertyTriggerEnabled$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    public pbService: PotentialBuyerService,
    private objectIntegrationService: ObjectIntegrationService,
    private modalService: QModalService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.store.dispatch(fetchExternalObjectMetadataReset());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.property && this.property) {
      this.completeUrl$ = combineLatest([
        this.store.pipe(select(fromConfig.getObjectLocationUrl)),
        this.store.pipe(select(fromConfig.getObjectPreviewLocationUrl)),
        this.store.pipe(select(fromConfig.getObjectLocationExternalIdProvider)),
        this.store.pipe(select(fromConfig.getObjectLocationExternalIdCategory)),
        this.store.pipe(
          select(fromConfig.getObjectLocationUseEaOidAsExternalId)
        ),
      ]).pipe(
        takeUntil(this.unsubscribe$),
        map(
          ([
            objectUrl,
            objectPreviewUrl,
            objectUrlExternalIdProvider,
            objectUrlExternalIdCategory,
            objectLocationUseEaOidAsExternalId,
          ]) => {
            let url = objectUrl;

            if (
              this.property?.publishOnNet.toLowerCase() === "limited" &&
              !!objectPreviewUrl
            ) {
              url = objectPreviewUrl;
            }

            return constructObjectUrl({
              object: this.property,
              url,
              objectLocationUseEaOidAsExternalId:
                objectLocationUseEaOidAsExternalId,
              objectUrlExternalIdProvider: objectUrlExternalIdProvider,
              objectUrlExternalIdCategory: objectUrlExternalIdCategory,
            });
          }
        )
      );

      this.hasObjectLinks$
        ?.pipe(
          filter(
            (hasExternalLinks) =>
              !!hasExternalLinks && !this.property.isExistingHome
          ),
          first()
        )
        .subscribe(() => {
          this.store.dispatch(
            fetchExternalObjectMetadataRequest({
              eaOid: this.property?.eaOid,
            })
          );
        });
    }
  }

  private mapStateToProps() {
    this.isSuperAdmin$ = this.store.pipe(
      select(fromUser.hasRole(ROLE_SUPER_ADMIN))
    );
    this.showCreateShowing$ = combineLatest([
      this.store.pipe(select(fromConfig.getFeature(CREATE_PRIVATE_SHOWING))),
      this.store.select(
        hasIntegration(IntegrationResource.CreatePublicShowing)
      ),
    ]).pipe(
      map(
        ([feature, canCreatePublicShowing]) =>
          feature.enabled || canCreatePublicShowing
      )
    );

    this.showCopyObject$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.ObjectCopy))
    );

    this.hasObjectLinks$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.ObjectLinks))
    );

    this.externalObjectMetadata$ = this.store.pipe(
      select(getExternalObjectMetadata())
    );

    this.isKiviCustomer$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map(
        (externalProviderFeature: ExternalProviderFeature) =>
          externalProviderFeature.name.toLowerCase() === externalProviders.KIVI
      )
    );

    this.erpMenuLinks$ = this.store.pipe(
      select(fromPotentialBuyers.getErpMenuLinks)
    );

    this.propertyTriggerEnabled$ =
      this.objectIntegrationService.propertyTriggerEnabled$;

    this.subListEnable$ = combineLatest([
      this.showCreateShowing$,
      this.hasObjectLinks$,
      this.isKiviCustomer$,
      this.isSuperAdmin$,
      this.propertyTriggerEnabled$,
    ]).pipe(
      map(
        ([
          showCreatePrivateShowing,
          hasObjectLinks,
          isKiviCustomer,
          isSuperAdmin,
          propertyTriggerEnabled,
        ]) =>
          showCreatePrivateShowing ||
          (hasObjectLinks && isKiviCustomer) ||
          isSuperAdmin ||
          propertyTriggerEnabled
      )
    );

    this.hasDealDebugging$ = this.store.select(
      hasIntegration(IntegrationResource.DealDebugging)
    );

    this.canCreateSeller$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.CreatePartySeller))
    );

    this.canCreateBuyer$ = this.store.pipe(
      select(hasIntegration(IntegrationResource.CreatePartyBuyer))
    );

    this.canOpenBuyerSellerIframe$ = this.store.pipe(
      select(fromConfig.getFeature(OPEN_BUYER_OR_SELLER_IFRAME)),
      map((feature) => feature.enabled)
    );
  }

  createShowing(e: MouseEvent, eaOid: string) {
    e.stopPropagation();
    const data: ShowingModalData = {
      isEditMode: false,
      eaOid: eaOid,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };
    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }

  handleCopyObject(e: MouseEvent) {
    e?.stopPropagation();
    this.modalService.show(CopyObjectModalComponent, {
      data: {
        eaOid: this.property.eaOid,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }

  prefillFilters() {
    const connectionDateMin = moment()
      .subtract(3, "months")
      .format(API_ONLY_DATE_FORMAT);

    // @ts-ignore
    this.store.dispatch(
      setContactsFilters({
        filters: {
          objectFilters: {
            connectionType: "potentialbuyer",
            roomsMax:
              +this.property.noOfRooms > 0 ? this.property.noOfRooms : null,
            roomsMin:
              +this.property.noOfRooms > 0 ? this.property.noOfRooms : null,
            priceMax: !!this.property.sold
              ? this.property.soldPrice.toString()
              : this.property.price.toString(),
            areaMin: +this.property.area > 0 ? this.property.area : null,
            objectType: this.property.objType,
            connectionDateMin: connectionDateMin,
          },
          basicFilters: {
            hasConsent: "True",
          },
        },
      })
    );
    this.store.dispatch(
      go({
        path: [
          "/crm",
          { outlets: { primary: ["lists", "contacts"], sidebar: null } },
        ],
      })
    );
  }

  openMetaDataLink(): void {
    this.externalObjectMetadata$
      .pipe(
        filter((metaData) => !!metaData),
        first()
      )
      .subscribe((metaData) => {
        openObjectMetaDataLink(metaData);
      });
  }

  openSellerOrBuyerSidebar(event: MouseEvent, key: string) {
    event.stopPropagation();
    combineLatest([this.erpMenuLinks$, this.canOpenBuyerSellerIframe$])
      .pipe(first())
      .subscribe(([erpMenuLinks, canOpenBuyerSellerIframe]) => {
        this.store.dispatch(
          RouterActions.go({
            path: [
              "/crm/",
              {
                outlets: {
                  sidebar: SIDEBAR_SELLER_OR_BUYER_CREATE_URL(
                    key.toLowerCase()
                  ),
                },
              },
            ],
          })
        );

        if (canOpenBuyerSellerIframe && erpMenuLinks.length > 0) {
          const fixedKey = `DESWINGLISHER_KEY_${key.toUpperCase()}`;
          const selectLink = erpMenuLinks.find((link) => link.key === fixedKey);
          this.pbService.objectCreatedActionLinks$.next(selectLink);
        }
      });
  }

  handleDebuggerObject(event: MouseEvent, requestType: RequestType) {
    event.stopPropagation();
    this.modalService.show(DebuggerObjectModalComponent, {
      data: {
        eaOid: this.property.eaOid,
        requestType: requestType,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }

  handleSellerChoiceTrigger(event: MouseEvent) {
    event.preventDefault();
    this.modalService.show(PropertyTriggerModalComponent, {
      data: {
        eaOid: this.property.eaOid,
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
    });
  }
}
