import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { getOverdue } from "@app/dashboard/todo-list/overdue-task-list/overdue-task-list.actions";
import {
  getOverdueTasksLoading,
  getTasks,
  getTotalNumberOfTasks,
} from "@app/dashboard/todo-list/overdue-task-list/overdue-task-list.reducer";
import { Task } from "@app/models";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-overdue-tasks-widget",
  templateUrl: "./overdue-tasks-widget.component.html",
  styleUrls: ["./overdue-tasks-widget.component.scss"],
})
export class OverdueTasksWidgetComponent implements OnInit, OnChanges {
  @Input() eaEmployeeId: string;
  @Input() openLinkInNewTab = false;
  @Input() showHeader: boolean = true;

  overdueTasks$: Observable<Task[]>;
  loading$: Observable<boolean>;
  total$: Observable<number>;

  constructor(
    private store: Store<AppState>,
    private taskTypeResolver: TaskTypeResolverService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    if (!this.eaEmployeeId) {
      this.fetchTasks();
    }
  }

  ngOnChanges(changes) {
    if (changes.eaEmployeeId) {
      this.fetchTasks();
    }
  }

  resolveIcon(task: Task): Observable<string> {
    return this.taskTypeResolver.resolveIcon(task);
  }

  resolveColor(task: Task) {
    return this.taskTypeResolver.resolveColor(task);
  }

  openInNewTab(task: Task) {
    this.taskTypeResolver.onTaskClick(task, true);
  }

  onTaskClick(task: Task, event) {
    event.preventDefault();

    if (!event.target.attributes["disablenavigation"]) {
      this.taskTypeResolver.onTaskClick(task, this.openLinkInNewTab);
    }
  }

  private mapStateToProps() {
    this.overdueTasks$ = this.store.pipe(select(getTasks));
    this.total$ = this.store.pipe(select(getTotalNumberOfTasks));
    this.loading$ = this.store.pipe(select(getOverdueTasksLoading));
  }

  private fetchTasks() {
    this.store.dispatch(getOverdue(this.eaEmployeeId, 0, 100));
  }
}
