import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { SegmentedControl } from "@app/shared/modules/ui-components/q-segmented-controls/q-segmented-controls.component";
import { getAdfenixReportingDataTypeControls } from "@app/statistics/statistics-adfenix-reporting/models/object-partitioned";
import moment from "moment/moment";
import { combineLatest, first, Observable } from "rxjs";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { getEaOfficesIds, hasRole } from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";

@Component({
  selector: "app-statistics-adfenix-reporting-widget",
  templateUrl: "./statistics-adfenix-reporting-widget.component.html",
  styleUrls: ["./statistics-adfenix-reporting-widget.component.scss"],
})
export class StatisticsAdfenixReportingWidgetComponent implements OnInit {
  @Input() eaOfficeId: string;
  @Input() triggerDataFetch$: Observable<void>;
  @Input() showHeader: boolean = true;

  form: FormGroup;
  dataTypeControls: SegmentedControl = [];

  constructor(private fb: FormBuilder, private store: Store<AppState>) {
    this.dataTypeControls = getAdfenixReportingDataTypeControls();
    this.buildForm();
  }

  ngOnInit() {
    combineLatest([
      this.store.pipe(select(hasRole(ROLE_ADMIN))),
      this.store.pipe(select(getEaOfficesIds)),
    ])
      .pipe(first())
      .subscribe(([isAdmin, eaOfficeIds]) => {
        if (!isAdmin) {
          this.eaOfficeId = eaOfficeIds.join(",");
          this.form.get("eaOfficeId").setValue(eaOfficeIds);
        }
      });
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOfficeId: [],
      objectTypes: [],
      startDate: [moment().subtract(12, "months").toDate()],
      endDate: [moment().endOf("month").toDate()],
      excludeNewProduction: [],
      groupBy: ["campaignGroup"],
      campaignNameAlias: [],
      campaignGroup: [],
      dataTypes: ["numberOfPotentialBuyers"],
    });
  }
}
