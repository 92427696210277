<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<!--    <ng-content select="[slot=buttons]"></ng-content>-->
<ng-container *ngIf="isMobile$ | async; else content">
  <app-q-circle-btn
    [icon]="'ellipsis-h-alt'"
    (click)="openSheet(sheet)"
  ></app-q-circle-btn>
</ng-container>

<ng-template #sheet>
  <mat-nav-list (click)="closeSheet()" class="action-menu-dropdown">
    <ul>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ul>
  </mat-nav-list>
</ng-template>
