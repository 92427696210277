import { Component, EventEmitter, Output, ViewChild } from "@angular/core";

@Component({
  selector: "step3",
  templateUrl: "./step3.component.html",
  styleUrls: ["../../forgot-password-form.component.scss"],
})
export class Step3Component {
  @ViewChild("form", { static: false })
  form;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSetPassword = new EventEmitter<string>();
  password: string;
  passwordRepeat: string;

  setPassword(): void {
    this.onSetPassword.emit(this.password);
  }
}
