import { Injectable } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class UrlService {
  constructor(private router: Router) {}

  buildUrlFromRouterCommands(commands: string[]): string {
    const newRelativeUrl = this.router.createUrlTree(commands);
    const baseUrl = window.location.href.replace(this.router.url, "");
    return baseUrl + newRelativeUrl;
  }

  buildUrlFromRouterWithQuery(
    commands: unknown[],
    query: Record<string, unknown> = {}
  ): string {
    const navigationExtras: NavigationExtras = {
      queryParams: query,
    };
    const newRelativeUrl = this.router.createUrlTree(
      commands,
      navigationExtras
    );
    const baseUrl = window.location.href.replace(this.router.url, "");

    return baseUrl + newRelativeUrl;
  }
}
