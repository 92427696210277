import { createAction, props } from "@ngrx/store";

export const fetchContactStatusRequest = createAction(
  "[Contact Status Widget] FETCH_CONTACT_STATUS_REQUEST",
  props<{ contactId: string; eaEmployeeId: string }>()
);

export const fetchContactStatusSuccess = createAction(
  "[Contact Status Widget] FETCH_CONTACT_STATUS_SUCCESS",
  props<{ results: unknown[] }>()
);

export const fetchContactStatusFail = createAction(
  "[Contact Status Widget] FETCH_CONTACT_STATUS_FAIL"
);
