import { Component, Input } from "@angular/core";
import { QObject } from "@app/models";
import { ShowingObject } from "@app/showings/models";

@Component({
  selector: "app-property-name",
  templateUrl: "./property-name.component.html",
  styleUrls: ["./property-name.component.scss"],
})
export class PropertyNameComponent {
  @Input() property: QObject | ShowingObject;
  @Input() disableSummary: boolean = false;
  @Input() disableFullAddress: boolean = false;
}
