import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { CopyObjectService } from "@app/integrations/copy-object/services/copy-object.service";
import { IntegrationCopyObject } from "@app/integrations/models/IntegrationCopyObject";
import { fadeInOutTrigger } from "@app/shared/animations";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  BehaviorSubject,
  debounceTime,
  filter,
  first,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-copy-object-modal",
  templateUrl: "./copy-object-modal.component.html",
  styleUrls: ["./copy-object-modal.component.scss"],
  animations: [fadeInOutTrigger()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyObjectModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  isWorking = false;
  eaOid = "";
  options$: Observable<IntegrationCopyObject>;
  currentOptions$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  optionsIsLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CopyObjectModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private copyObjectService: CopyObjectService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initOptions();
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    this.isWorking = true;
    const params = this.formatFormData();
    this.copyObjectService.storeCopyObject(params);
    this.copyObjectService.checkCopyObjectProgress();
    this.copyObjectService.isCompleted$
      .pipe(
        filter((completed) => !!completed),
        first()
      )
      .subscribe(() => {
        const copyEaOids = this.copyObjectService.getCopiedObjectEaOid();

        if (copyEaOids.length === 1) {
          this.store.dispatch(
            toastActions.success(
              { message: "copy_object_successfully" },
              {
                label: this.translate.instant("goto_object"),
                icon: "fa-arrow-right",
                action: RouterActions.go({
                  path: ["crm", "showings", copyEaOids[0]],
                }),
              }
            )
          );
        } else {
          this.store.dispatch(
            toastActions.success({ message: "copy_object_successfully" })
          );
        }

        this.closeModal();
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  getSortedOptions(copyOptions: any) {
    if (!!copyOptions) {
      const sortedOptions = _.sortBy(copyOptions, "copyParameterName");
      return sortedOptions;
    }

    return [];
  }

  private mapStateToProps() {
    this.options$ = this.copyObjectService.getCopyObjectOptions(
      this.data.eaOid
    );
  }

  private buildForm() {
    const controls = {};
    this.currentOptions$.value.copyOptions.forEach((option) => {
      controls[option.copyParameterName] = false;
    });

    this.form = this.fb.group({
      eaOid: this.data.eaOid,
      numberOfCopy: 1,
      ...controls,
    });

    this.currentOptions$.value.copyOptions.forEach((option) => {
      if (this.checkOptionDependency(option)) {
        this.form.get(option.copyParameterName).disable({ emitEvent: false });
      }
    });
  }

  private checkOptionDependency(option: any): boolean {
    let shouldDisable = false;
    if (option?.requiresOptions.length > 0) {
      const requiresValue = option.requiresOptions.reduce((result, item) => {
        return result && !this.form.get(item).value;
      }, true);

      shouldDisable ||= requiresValue;
    }

    if (option?.disabledByOptions.length > 0) {
      const disabledValue = option.disabledByOptions.reduce((result, item) => {
        return result && !!this.form.get(item).value;
      }, true);

      shouldDisable ||= disabledValue;
    }

    return shouldDisable;
  }

  private initFormEvent() {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$), debounceTime(200))
      .subscribe(() => {
        this.currentOptions$.value.copyOptions.forEach((option) => {
          const isDisable = this.checkOptionDependency(option);

          if (isDisable) {
            this.form
              .get(option.copyParameterName)
              .disable({ emitEvent: false });
            this.form
              .get(option.copyParameterName)
              .setValue(false, { emitEvent: false });
          } else {
            this.form
              .get(option.copyParameterName)
              .enable({ emitEvent: false });
          }
        });
      });
  }

  private initOptions() {
    this.options$
      .pipe(
        filter((options) => !!options),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((options) => {
        const sortedCopyOptions = this.getSortedOptions(options.copyOptions);
        const newOptions = {
          ...options,
          copyOptions: [...sortedCopyOptions],
        };

        this.currentOptions$.next(newOptions);
        this.buildForm();
        this.initFormEvent();
        this.optionsIsLoading$.next(false);
      });
  }

  private formatFormData() {
    const params = { ...this.form.value };
    const formatedParameters = {
      eaOid: params.eaOid,
      params: {
        options: {},
        copies: +params.numberOfCopy,
      },
    };

    delete params.eaOid;
    delete params.numberOfCopy;

    for (const key in params) {
      if (!!params[key]) {
        formatedParameters.params.options[key] = params[key];
      }
    }

    return formatedParameters;
  }
}
