import { createAction, props } from "@ngrx/store";
import { TaskResult } from "@app/models";

const ACTION_PREFIX = "[Lead result options form]";

export const getLeadResultOptionRequest = createAction(
  `${ACTION_PREFIX} GET_LEAD_RESULT_OPTION_REQUEST`,
  props<{ channelId: number; id: number }>()
);

export const getLeadResultOptionSuccess = createAction(
  `${ACTION_PREFIX} GET_LEAD_RESULT_OPTION_SUCCESS`,
  props<{ data: TaskResult }>()
);

export const getLeadResultOptionFail = createAction(
  `${ACTION_PREFIX} GET_LEAD_RESULT_OPTION_FAIL`
);

export const createLeadResultOptionRequest = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_RESULT_OPTION_REQUEST`,
  props<{ id: number; body: Partial<TaskResult> }>()
);

export const createLeadResultOptionSuccess = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_RESULT_OPTION_SUCCESS`,
  props<{ data: TaskResult }>()
);

export const createLeadResultOptionFail = createAction(
  `${ACTION_PREFIX} CREATE_LEAD_RESULT_OPTION_FAIL`
);

export const editLeadResultOptionRequest = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_RESULT_OPTION_REQUEST`,
  props<{ channelId: number; id: number; body: Partial<TaskResult> }>()
);

export const editLeadResultOptionSuccess = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_RESULT_OPTION_SUCCESS`,
  props<{ data: TaskResult }>()
);

export const editLeadResultOptionFail = createAction(
  `${ACTION_PREFIX} EDIT_LEAD_RESULT_OPTION_FAIL`
);

export const deleteLeadResultOptionRequest = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_RESULT_OPTION_REQUEST`,
  props<{ channelId: number; id: number }>()
);

export const deleteLeadResultOptionSuccess = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_RESULT_OPTION_SUCCESS`,
  props<{ channelId: number }>()
);

export const deleteLeadResultOptionFail = createAction(
  `${ACTION_PREFIX} DELETE_LEAD_RESULT_OPTION_FAIL`
);
