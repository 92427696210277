<div class="wrapper">
  <sidebar-header [type]="tabType" [label]="'create_object' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <form [formGroup]="form">
      <ng-container *ngIf="!(loading$ | async); else largeSpinner">
        <ng-container
          *ngIf="delegatedObjectRequirements$ | async as requirements"
        >
          <section>
            <div translate class="section-label">object_type</div>
            <select formControlName="baseObjectType">
              <option [value]="''" disabled translate>select</option>
              <option
                [value]="objectType"
                *ngFor="let objectType of requirements.objectTypes"
              >
                {{ getObjectTypeName(requirements.integration, objectType) }}
              </option>
            </select>
            <div ngxErrors="baseObjectType">
              <div translate ngxError="required" showWhen="touched">
                validation_required
              </div>
            </div>
          </section>
          <br />
          <ng-container *ngIf="requiredFormFields?.length > 0">
            <section *ngFor="let field of requiredFormFields">
              <div class="section-label">
                {{ getFieldNameTranslation(field.field) }}
                <span *ngIf="isAddressField(field.field)"
                  >({{ "optional" | translate }})</span
                >
              </div>
              <ng-container
                *ngIf="isAddressField(field.field); else normalRequiredField"
              >
                <ng-container
                  *ngIf="
                    field.field === 'streetAddress' &&
                    !!form?.get('streetAddress')
                  "
                >
                  <search-address-adv
                    type="street"
                    [containerFormControl]="form.get('streetAddress')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('streetAddress')"
                    [showFullAddress]="false"
                    (onSelected)="handleStreetSelected($event)"
                  ></search-address-adv>
                </ng-container>
                <ng-container
                  *ngIf="field.field === 'zip' && !!form?.get('zip')"
                >
                  <search-address-adv
                    type="zip"
                    [containerFormControl]="form.get('zip')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('zip')"
                    [showFullAddress]="false"
                    (onSelected)="handleZipSelected($event)"
                  ></search-address-adv>
                </ng-container>
                <ng-container
                  *ngIf="field.field === 'city' && !!form?.get('city')"
                >
                  <search-address-adv
                    type="city"
                    [containerFormControl]="form.get('city')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('city')"
                    [showFullAddress]="false"
                  ></search-address-adv>
                </ng-container>
              </ng-container>
              <ng-template #normalRequiredField>
                <input type="text" [formControlName]="field.field" />
                <div [ngxErrors]="field.field">
                  <div translate ngxError="required" showWhen="touched">
                    validation_required
                  </div>
                </div>
              </ng-template>
            </section>
          </ng-container>
          <ng-container *ngIf="optionalFormFields?.length > 0">
            <section *ngFor="let field of optionalFormFields">
              <div class="section-label">
                {{ getFieldNameTranslation(field.field) }} ({{
                  "optional" | translate
                }})
              </div>
              <ng-container
                *ngIf="isAddressField(field.field); else normalOptionalField"
              >
                <ng-container
                  *ngIf="
                    field.field === 'streetAddress' &&
                    !!form?.get('streetAddress')
                  "
                >
                  <search-address-adv
                    type="street"
                    [containerFormControl]="form.get('streetAddress')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('streetAddress')"
                    [showFullAddress]="false"
                    (onSelected)="handleStreetSelected($event)"
                  ></search-address-adv>
                </ng-container>
                <ng-container
                  *ngIf="field.field === 'zip' && !!form?.get('zip')"
                >
                  <search-address-adv
                    type="zip"
                    [containerFormControl]="form.get('zip')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('zip')"
                    [showFullAddress]="false"
                    (onSelected)="handleZipSelected($event)"
                  ></search-address-adv>
                </ng-container>
                <ng-container
                  *ngIf="field.field === 'city' && !!form?.get('city')"
                >
                  <search-address-adv
                    type="city"
                    [containerFormControl]="form.get('city')"
                    [enabled]="(addressSuggesterFeature$ | async)?.enabled"
                    [provider]="(addressSuggesterFeature$ | async)?.provider"
                    [countryCode]="defaultCountryCodeISO.toLowerCase()"
                    [invalid]="isFormValueValid('city')"
                    [showFullAddress]="false"
                  ></search-address-adv>
                </ng-container>
              </ng-container>
              <ng-template #normalOptionalField>
                <input type="text" [formControlName]="field.field" />
                <div [ngxErrors]="field.field">
                  <div translate ngxError="required" showWhen="touched">
                    validation_required
                  </div>
                </div>
              </ng-template>
            </section>
          </ng-container>
        </ng-container>
      </ng-container>
    </form>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      buttonType="cancel"
      label="cancel"
      [fullWidth]="true"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      *ngIf="
        form.get('baseObjectType').value.toUpperCase() ===
        'OBJECT_TYPE_TENANT_OWNERSHIP'
      "
      buttonType="save"
      label="create_from_brf_register"
      [fullWidth]="true"
      [isLoading]="storingBrf$ | async"
      [disabled]="
        !form.valid ||
        (storingBrf$ | async) ||
        (storingLantmateri$ | async) ||
        (storingManual$ | async)
      "
      (click)="submit('CREATE_OBJECT_MODE_BRF')"
    >
    </app-q-button>
    <app-q-button
      *ngIf="hasPermissionToCreateLantmateri() | async"
      buttonType="save"
      label="create_from_lantmateri"
      [fullWidth]="true"
      [isLoading]="storingLantmateri$ | async"
      [disabled]="
        !form.valid ||
        (storingBrf$ | async) ||
        (storingLantmateri$ | async) ||
        (storingManual$ | async)
      "
      (click)="submit('CREATE_OBJECT_MODE_LANTMATERI')"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="create_and_fill_out_manually"
      [fullWidth]="true"
      [isLoading]="storingManual$ | async"
      [disabled]="
        !form.valid ||
        (storingBrf$ | async) ||
        (storingLantmateri$ | async) ||
        (storingManual$ | async)
      "
      (click)="submit('CREATE_OBJECT_MODE_MANUAL')"
    >
    </app-q-button>
  </div>
</div>

<ng-template #largeSpinner>
  <spinner [size]="'2rem'"></spinner>
</ng-template>
