<div class="search-results container-fluid no-padding" [@fadeInUp]>
  <div class="row result-row">
    <div *ngIf="shouldShowHeaders()" class="latest-searches">
      <i class="icon-clock"></i>
      <h2>{{ "latest_searches" | translate }}</h2>
      <ul>
        <li (click)="removeLatestSearches()" class="remove">
          {{ "clear_list" | translate }}
        </li>
        <li
          (click)="runSearch(search)"
          *ngFor="let search of latestSearches"
          class="search-result"
        >
          {{ search }}
        </li>
      </ul>
    </div>

    <div *ngIf="!groupedResultsIsVisible">
      <ng-container *ngIf="!isMobileView; else mobileView">
        <search-result-column
          (showGroupedResults)="setGroupedResult($event)"
          [data]="contacts"
          [loaded]="loaded"
          [hits]="contactsHits"
          [type]="categoryTypes.CONTACTS_CATEGORY"
        >
          <div
            noResult
            (click)="handleCreateNewContact(); close()"
            class="create-new-contact"
          >
            <button class="btn btn-secondary btn-circle">+</button>
            <span>{{ "create_new_contact" | translate }}</span>
          </div>
        </search-result-column>

        <search-result-column
          (showGroupedResults)="setGroupedResult($event)"
          [data]="leads"
          [loaded]="loaded"
          [hits]="leadsHits"
          [type]="categoryTypes.LEADS_CATEGORY"
        >
        </search-result-column>

        <search-result-column
          (showGroupedResults)="setGroupedResult($event)"
          [data]="employees"
          [loaded]="loaded"
          [hits]="employeesHits"
          [type]="categoryTypes.EMPLOYEES_CATEGORY"
        >
        </search-result-column>

        <search-result-column
          (showGroupedResults)="setGroupedResult($event)"
          [data]="objects"
          [loaded]="loaded"
          [hits]="objectsHits"
          [type]="categoryTypes.OBJECTS_CATEGORY"
        >
        </search-result-column>
      </ng-container>

      <ng-template #mobileView>
        <div class="mobile-categories" *ngIf="!shouldShowHeaders()">
          <div class="btn-group">
            <button
              [class.active]="
                selectedMobileMode === categoryTypes.CONTACTS_CATEGORY
              "
              class="btn btn-default"
              (click)="selectedMobileMode = categoryTypes.CONTACTS_CATEGORY"
            >
              <i class="icon-user"></i> {{ "contacts" | translate }}({{
                contactsHits
              }})
            </button>
            <button
              [class.active]="
                selectedMobileMode === categoryTypes.OBJECTS_CATEGORY
              "
              class="btn btn-default"
              (click)="selectedMobileMode = categoryTypes.OBJECTS_CATEGORY"
            >
              <app-icon name="home-lg-alt"></app-icon>
              {{ "objects" | translate }}({{ objectsHits }})
            </button>
            <button
              [class.active]="
                selectedMobileMode === categoryTypes.LEADS_CATEGORY
              "
              class="btn btn-default"
              (click)="selectedMobileMode = categoryTypes.LEADS_CATEGORY"
            >
              <app-icon name="street-view"></app-icon>
              {{ "leads" | translate }}({{ leadsHits }})
            </button>
            <button
              [class.active]="
                selectedMobileMode === categoryTypes.EMPLOYEES_CATEGORY
              "
              class="btn btn-default"
              (click)="selectedMobileMode = categoryTypes.EMPLOYEES_CATEGORY"
            >
              <i class="icon-people"></i> {{ "employees" | translate }}({{
                employeesHits
              }})
            </button>
          </div>
        </div>
        <div class="mobile-container">
          <search-result-column
            *ngIf="selectedMobileMode === categoryTypes.CONTACTS_CATEGORY"
            (showGroupedResults)="setGroupedResult($event)"
            [data]="contacts"
            [loaded]="loaded"
            [hits]="contactsHits"
            [type]="categoryTypes.CONTACTS_CATEGORY"
          >
            <div
              noResult
              (click)="handleCreateNewContact(); close()"
              class="create-new-contact"
            >
              <button class="btn btn-secondary btn-circle">+</button>
              <span>{{ "create_new_contact" | translate }}</span>
            </div>
          </search-result-column>

          <search-result-column
            *ngIf="selectedMobileMode === categoryTypes.LEADS_CATEGORY"
            (showGroupedResults)="setGroupedResult($event)"
            [data]="leads"
            [loaded]="loaded"
            [hits]="leadsHits"
            [type]="categoryTypes.LEADS_CATEGORY"
          >
          </search-result-column>

          <search-result-column
            *ngIf="selectedMobileMode === categoryTypes.EMPLOYEES_CATEGORY"
            (showGroupedResults)="setGroupedResult($event)"
            [data]="employees"
            [loaded]="loaded"
            [hits]="employeesHits"
            [type]="categoryTypes.EMPLOYEES_CATEGORY"
          >
          </search-result-column>

          <search-result-column
            *ngIf="selectedMobileMode === categoryTypes.OBJECTS_CATEGORY"
            (showGroupedResults)="setGroupedResult($event)"
            [data]="objects"
            [loaded]="loaded"
            [hits]="objectsHits"
            [type]="categoryTypes.OBJECTS_CATEGORY"
          >
          </search-result-column>
        </div>
      </ng-template>
    </div>
  </div>

  <grouped-search-results
    *ngIf="groupedResultsIsVisible"
    [data]="getGroupedResults()"
    [hits]="getGroupedResultsHits()"
    [groupedResultsIsVisible]="groupedResultsIsVisible"
    [showExternalProviderLink]="
      (externalProvider$ | async).enabled &&
      (showExternalProviderObjectLinks$ | async)
    "
    [externalProviderName]="(externalProvider$ | async).name"
    [externalProviderUrl]="(externalProvider$ | async).url"
    [externalProviderLinks]="externalProviderLinks$ | async"
    [externalProviderLinksLoading]="externalProviderLinksLoading$ | async"
    (getExternalProviderLinks)="getExternalProviderLinks($event)"
    (onShowAllSearchTypes)="showAllSearchTypes()"
    [type]="groupedResultType"
  >
  </grouped-search-results>
</div>
