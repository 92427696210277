import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: "app-element-wrapper",
  templateUrl: "./element-wrapper.component.html",
  styleUrls: ["./element-wrapper.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ElementWrapperComponent {
  @Input() infoTranslationKey: string;
  @Input() width: string;
}
