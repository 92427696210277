import * as callingListsActions from "@app/calling-lists/ngrx/calling-lists.actions";
import { CallingListContact } from "@app/lists/lists-calling-lists/models/calling-list";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";

export interface CallingListsState {
  callingListId: string | null;
  notContactedContacts: CallingListContact[];
  selectedContactId: string;
}

export const initialState: CallingListsState = {
  callingListId: null,
  notContactedContacts: [],
  selectedContactId: "",
};

export function callingListsReducer(
  state: CallingListsState,
  action: Action
): CallingListsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    callingListsActions.setSelectedCallingListId,
    (state, { callingListId }) => ({
      ...state,
      callingListId,
    })
  ),
  on(
    callingListsActions.setCallingListNotContactedContacts,
    (state, { notContactedContacts }) => ({
      ...state,
      notContactedContacts,
    })
  ),
  on(
    callingListsActions.deleteContactFromCallingListNotContactedContacts,
    (state, { contactId }) => {
      const newList = state.notContactedContacts.filter(
        (callingListContact) => callingListContact.contactId !== contactId
      );
      return {
        ...state,
        notContactedContacts: newList,
      };
    }
  ),
  on(
    callingListsActions.setSelectedContactId,
    (state, { selectedContactId }) => ({
      ...state,
      selectedContactId,
    })
  ),
  on(callingListsActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<CallingListsState>("callingLists");

export const getCallingListId = createSelector(
  selectFeature,
  (state: CallingListsState) => state.callingListId
);

export const getNotContactedContacts = createSelector(
  selectFeature,
  (state: CallingListsState) => state.notContactedContacts
);

export const getContactIndex = createSelector(
  selectFeature,
  (state: CallingListsState) =>
    state.notContactedContacts.findIndex(
      (contact: CallingListContact) =>
        contact.contactId === state.selectedContactId
    )
);

export const getNextContactId = createSelector(
  selectFeature,
  (state: CallingListsState) => {
    const currentIndex = state.notContactedContacts.findIndex(
      (contact: CallingListContact) =>
        contact.contactId === state.selectedContactId
    );
    return state.notContactedContacts[currentIndex + 1]
      ? state.notContactedContacts[currentIndex + 1].contactId
      : null;
  }
);

export const getPreviousContactId = createSelector(
  selectFeature,
  (state: CallingListsState) => {
    const currentIndex = state.notContactedContacts.findIndex(
      (contact: CallingListContact) =>
        contact.contactId === state.selectedContactId
    );
    return state.notContactedContacts[currentIndex - 1]
      ? state.notContactedContacts[currentIndex - 1].contactId
      : null;
  }
);
