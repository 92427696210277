import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "nps-survey-responses",
  templateUrl: "./nps-survey-responses.component.html",
  styleUrls: ["./nps-survey-responses.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsSurveyResponsesComponent implements OnInit {
  @Input() resources$: Observable<any>;

  isAdmin$: Observable<boolean>;
  isManagerOrAdmin$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  mapStateToProps(): void {
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManagerOrAdmin$ = this.store.pipe(select(fromUser.isManagerOrAdmin));
  }

  handleSurveyClicked(url: string): void {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.rel = "noopener noreferrer";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
