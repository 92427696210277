export const MATCH_WHITE_SPACE_GLOBAL = /\s/g;
export const MATCH_NOT_DIGITS_WHITE_SPACE_GLOBAL = /[^\d\s]/g;
export const ONLY_DIGITS_SPECIALS_LIGHT = RegExp(/^[- +,.()0-9]*$/);
export const ONLY_DIGITS = RegExp(/^\d*$/);
export const ONLY_DIGITS_WHITESPACE = RegExp(/^[\d\s]*$/);
export const ONLY_DIGITS_ONE_DECIMAL = RegExp(/^\d*(\.\d{1})?$/);
export const ONLY_DIGITS_TWO_DECIMAL = RegExp(/^\d*(\.\d{0,2})?$/);
export const ONLY_LETTERS_DIGITS_WHITESPACE = RegExp(
  /^[A-Za-z\u0080-\uFFFF0-9 _]*[A-Za-z\u0080-\uFFFF0-9][A-Za-z\u0080-\uFFFF0-9 _]*$/
);
