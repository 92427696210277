import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Employee, Task, TaskResult } from "@app/models";
import { getEmployee } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { TASK } from "@app/shared/utils/tab-types";
import { SidebarService } from "@app/sidebar/sidebar.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import moment from "moment";
import {
  concatMap,
  from as observableFrom,
  map,
  Observable,
  of as observableOf,
  switchMap,
  tap,
} from "rxjs";
import { TabPersist } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as tasksActions from "../ngrx/tasks.actions";
import { TasksDefaultService } from "./tasks-default.service";

@Injectable()
export class TasksDefaultEffects {
  updateTaskDefaultManageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.createTaskAttemptSuccess),
      switchMap(() => observableOf(sidebarActions.closeTab({ tabType: TASK })))
    )
  );

  openSalesmeetingTabIfNotBooked$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tasksActions.openSalesmeetingTabIfNotBooked),
        switchMap(({ taskAttempt }) =>
          this.apiService
            .get(`contacts/${taskAttempt.task["contactId"]}/sales-meetings`, {
              insertedFrom: moment()
                .subtract(2, "days")
                .startOf("day")
                .format(API_DATE_FORMAT),
            })
            .pipe(
              tap((response: any) => {
                const taskResult: TaskResult =
                  taskAttempt.task.taskResults.find(
                    (result: TaskResult) =>
                      result.eaTaskResultId === taskAttempt.eaTaskResultId
                  );
                const shouldOpenSalesMeetingSidebar =
                  taskResult.eaWorkFlowId === "2";
                if (response.total === 0 && shouldOpenSalesMeetingSidebar) {
                  return this.sidebarService.openMultipleTabs(
                    ["sales_meeting"],
                    {
                      primaryOutlet: ["contacts", taskAttempt.task.contactId],
                      routeParams: { contactId: taskAttempt.task.contactId },
                    }
                  );
                }
              })
            )
        )
      ),
    { dispatch: false }
  );

  updateTaskDefaultAssignSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.updateTaskSuccess),
      switchMap(({ task }) =>
        this.store.pipe(
          select(getEmployee),
          concatMap((employee: Employee) =>
            this.getUpdateTaskDefaultAssignSuccessActions(task, employee)
          )
        )
      )
    )
  );

  showManage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(tasksActions.showManageTab),
        map(() => this.tasksDefaultService.onShowManage())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private tasksDefaultService: TasksDefaultService,
    private taskTypeResolverService: TaskTypeResolverService,
    private apiService: ApiService,
    private sidebarService: SidebarService
  ) {}

  getUpdateTaskDefaultAssignSuccessActions(
    task: Task,
    employee: Employee
  ): Observable<Action> {
    let actions: Action[];
    if (employee.eaEmployeeId === task.eaEmployeeId) {
      actions = [
        sidebarActions.setTabPersistence({
          tabType: TASK,
          persistence: TabPersist.ONCE,
        }),
        RouterActions.go({
          path: [
            "/crm",
            {
              outlets: {
                primary: ["contacts", task.contactId],
                sidebar:
                  this.taskTypeResolverService.resolveSidebarTaskOrLeadRoute(
                    task.eaTaskId.toString(),
                    task.eaTaskTypeId
                  ),
              },
            },
          ],
        }),
        tasksActions.showManageTab(),
      ];
    } else {
      actions = [sidebarActions.closeTab({ tabType: TASK })];
    }
    return observableFrom(actions);
  }
}
