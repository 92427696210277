import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { StickyNotificationService } from "@app/shared-features/sticky-notification/sticky-notification.service";
import { StickyNotificationComponent } from "./components/sticky-notification/sticky-notification.component";

@NgModule({
  imports: [CommonModule, OverlayModule, BrowserAnimationsModule],
  providers: [StickyNotificationService],
  declarations: [StickyNotificationComponent],
})
export class StickyNotificationModule {}
