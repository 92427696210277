import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as errorActions from "@app/core/error-handling/ngrx/error.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { AuthService } from "@app/core/services/auth/auth.service";
import { Employee, Office } from "@app/models";
import { TOO_MANY_REQUESTS } from "@app/shared/utils/http-status-codes";
import { ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  concatMap,
  delay,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  tap,
} from "rxjs";
import * as selectOfficeActions from "../../login/select-office/select-office.actions";
import * as userActions from "./user.actions";
import { Action } from "@ngrx/store";
import { AppConfigService } from "@app/app-config.service";

@Injectable()
export class UserEffects {
  removeToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.removeToken),
        map(() => localStorage.removeItem("token"))
      ),
    { dispatch: false }
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.refreshToken),
      switchMap(() => {
        const ids = this.authService.getIds();
        return this.apiService.post("user-data", ids, "auth").pipe(
          map((data: { token: string }) => {
            this.authService.setToken(data.token);
            return userActions.refreshTokenSuccess();
          }),
          catchError(() => observableOf(userActions.removeToken()))
        );
      })
    )
  );

  loadRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadRequest),
      switchMap(({ type, ...ids }) =>
        this.apiService.post("user-data", ids, "auth").pipe(
          mergeMap(
            (data: { employee: unknown; office: Office; token: string }) => {
              try {
                this.authService.setToken(data.token);
                return this.getLoadSuccessActions(
                  data.office,
                  new Employee(data.employee),
                  this.authService.getRoles()
                );
              } catch (e) {
                return [
                  userActions.clearState(),
                  errorActions.clearUserContext(),
                ];
              }
            }
          ),
          catchError(() => observableOf(userActions.removeToken()))
        )
      )
    )
  );

  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadSuccess, selectOfficeActions.loginOfficeSuccess),
      map(() =>
        userActions.setViewingAsSomeoneElse({
          isViewingAsSomeoneElse: this.authService.isViewingAsSomeoneElse(),
        })
      )
    )
  );

  loginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loginRequest),
      switchMap(({ credentials }) =>
        this.apiService.post("login", credentials, "auth").pipe(
          map((data: { token }) => {
            try {
              this.authService.setToken(data.token);
              return userActions.loginSuccess({
                username: credentials.username,
              });
            } catch (e) {
              return userActions.clearState();
            }
          }),
          catchError((err) => {
            if (err.status === TOO_MANY_REQUESTS) {
              return observableOf(
                toastActions.danger({ message: "to_many_requests_login" })
              );
            }
            return observableOf(userActions.loginFail());
          })
        )
      )
    )
  );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loginSuccess),
      map(({ username }) =>
        selectOfficeActions.searchEmployeesRequest({
          email: username,
        })
      )
    )
  );

  loginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loginFail),
      switchMap(() => this.translateService.get("credentials_wrong")),
      map((message) => toastActions.danger({ message }))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.logout, userActions.unauthorized),
      concatMap(() =>
        observableFrom([
          userActions.removeToken(),
          this.authService.hasRole(ROLE_SUPER_ADMIN)
            ? RouterActions.go({ path: "/admin/auth" })
            : RouterActions.go({ path: "/login" }),
        ])
      )
    )
  );

  loadAllEmployeesAndOfficesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadAllEmployeesAndOfficesRequest),
      switchMap(({ email }) =>
        this.apiService
          .get("employees/legacy", {
            email,
            includeOffice: true,
            status: "active",
          })
          .pipe(
            map((response: any) => {
              const employeeOffices = [];
              response.employees.forEach((employee) => {
                employeeOffices.push({
                  eaEmployeeId: employee.eaEmployeeId,
                  offices: employee.offices,
                });
              });
              return userActions.loadAllEmployeesAndOfficesSuccess({
                employeeOffices,
              });
            }),
            catchError(() =>
              observableOf(userActions.loadAllEmployeesAndOfficesFail())
            )
          )
      )
    )
  );

  superAdminLoginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.superAdminLoginRequest),
      switchMap(({ credentials }) =>
        this.apiService.post("super-admin", credentials, "auth").pipe(
          map((data: { token }) => {
            try {
              this.authService.setToken(data.token);
              return userActions.superAdminLoginSuccess();
            } catch (e) {
              return userActions.clearState();
            }
          }),
          catchError((err) => {
            if (err.status === TOO_MANY_REQUESTS) {
              return observableOf(
                toastActions.danger({ message: "to_many_requests_login" })
              );
            }
            return observableOf(userActions.superAdminLoginFail());
          })
        )
      )
    )
  );

  validateOauthTokenRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.validateOauthTokenRequest),
      switchMap(({ token }) =>
        this.apiService.post("sso/validate", token, "public").pipe(
          map((data: { token; email }) => {
            try {
              this.authService.setToken(data.token);
              return userActions.loginSuccess({ username: data.email });
            } catch (e) {
              return userActions.clearState();
            }
          }),
          catchError((err) => {
            const unmatchedSSOEmail: string = err?.error?.unmatchedSSOEmail;
            if (!!unmatchedSSOEmail) {
              return observableOf(
                userActions.validateOauthTokenFail({ unmatchedSSOEmail })
              );
            } else {
              return observableOf(userActions.validateOauthTokenFail({}));
            }
          })
        )
      )
    )
  );

  validateOauthTokenFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.validateOauthTokenFail),
      switchMap(() =>
        observableOf(RouterActions.go({ path: "login/sso-fail" }))
      )
    )
  );

  superAdminLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userActions.superAdminLoginSuccess,
        userActions.switchClientSuccess
      ),
      map(() => RouterActions.go({ path: "/admin/select-employee" }))
    )
  );

  superAdminLoginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        userActions.superAdminLoginFail,
        userActions.clientLessSuperAdminLoginFail
      ),
      switchMap(() => this.translateService.get("login_failed")),
      map((message) => toastActions.danger({ message }))
    )
  );

  clientLessSuperAdminLoginRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.clientLessSuperAdminLoginRequest),
      switchMap(({ credentials }) =>
        this.apiService
          .post("client-less/super-admin/login", credentials, "auth")
          .pipe(
            map((data: { token: string }) => {
              try {
                this.authService.setToken(data.token);
                return userActions.clientLessSuperAdminLoginSuccess();
              } catch (e) {
                return userActions.clearState();
              }
            }),
            catchError((err) => {
              if (err.status === TOO_MANY_REQUESTS) {
                return observableOf(
                  toastActions.danger({ message: "to_many_requests_login" })
                );
              }
              return observableOf(userActions.clientLessSuperAdminLoginFail());
            })
          )
      )
    )
  );

  clientLessSuperAdminLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.clientLessSuperAdminLoginSuccess),
      map(() => RouterActions.go({ path: "/admin/select-client" }))
    )
  );

  loadClientRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadClientRequest),
      switchMap(() =>
        this.apiService
          .get("client-less/super-admin/clients", {}, "auth", false)
          .pipe(
            map((response: { name: string }[]) => {
              try {
                const clients = response.reduce((result, item) => {
                  result.push(item.name);
                  return result;
                }, [] as string[]);
                return userActions.loadClientSuccess({ clients });
              } catch (e) {
                return userActions.clearState();
              }
            }),
            catchError(() => {
              return observableOf(userActions.loadClientFail());
            })
          )
      )
    )
  );

  switchClientRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.switchClientRequest),
      switchMap(({ clientName }) =>
        this.apiService
          .put(
            `client-less/super-admin/clients/switch-to/${clientName}`,
            {},
            "auth"
          )
          .pipe(
            map((data: { token: string }) => {
              try {
                this.authService.setToken(data.token);
                return userActions.switchClientSuccess({ clientName });
              } catch (e) {
                return userActions.clearState();
              }
            }),
            catchError(() => {
              return observableOf(userActions.switchClientFail());
            })
          )
      )
    )
  );

  switchSuperAdminLevelRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.switchSuperAdminLevelRequest),
      switchMap(({ level }) =>
        this.apiService
          .put(`client-less/super-admin/${level}`, {}, "auth")
          .pipe(
            map((data: { token: string }) => {
              try {
                this.authService.setToken(data.token);
                return userActions.switchSuperAdminLevelSuccess();
              } catch (e) {
                return userActions.clearState();
              }
            }),
            catchError(() => {
              return observableOf(userActions.switchSuperAdminLevelFail());
            })
          )
      )
    )
  );

  switchSuperAdminLevelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.switchSuperAdminLevelSuccess),
      switchMap(() =>
        observableOf(toastActions.success({ message: "token_changed" }))
      )
    )
  );

  switchSuperAdminLevelFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.switchSuperAdminLevelFail),
      switchMap(() =>
        observableOf(toastActions.danger({ message: "operation_failed" }))
      )
    )
  );

  detectClientRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.detectClientRequest),
      switchMap(() =>
        this.apiService.get("client-less/detect-client", {}, "public").pipe(
          map((data: { detectedClientIdentifier: string }) => {
            return userActions.detectClientSuccess({
              clientName: data.detectedClientIdentifier,
            });
          }),
          catchError(() => {
            return observableOf(userActions.detectClientFail());
          })
        )
      )
    )
  );

  switchClientSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.switchClientSuccess),
        delay(200),
        tap(() => {
          this.appConfig.load();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private authService: AuthService,
    private appConfig: AppConfigService,
    private translateService: TranslateService
  ) {}

  private getLoadSuccessActions(
    office: Office,
    employee: Employee,
    roles: string[]
  ): Action[] {
    return [
      userActions.loadSuccess({ employee, office, roles }),
      errorActions.setUserContext({
        user: {
          id: employee.eaEmployeeId,
          email: employee.employeeEmail,
          username: employee.employeeFullName,
        },
      }),
      errorActions.setExtraContext({
        context: {
          roles,
          eaOfficeId: office.eaOfficeId,
          officeName: office.name,
        },
      }),
    ];
  }
}
