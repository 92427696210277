import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { ContactRelationship } from "@app/models";
import {
  deleteContactRelationsFail,
  deleteContactRelationsRequest,
  deleteContactRelationsSuccess,
  fetchContactRelationsFail,
  fetchContactRelationsRequest,
  fetchContactRelationsSuccess,
  patchContactRelationsFail,
  patchContactRelationsRequest,
  patchContactRelationsSuccess,
  postContactRelationsFail,
  postContactRelationsRequest,
  postContactRelationsSuccess,
} from "@app/widgets/widgets/contact-widgets/contact-relations-widget/ngrx/contact-relations-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap, tap } from "rxjs";

@Injectable()
export class ContactRelationsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  latestFetchAction = null;

  fetchContactRelationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchContactRelationsRequest),
      tap((action) => (this.latestFetchAction = action)),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/contact-relationships`).pipe(
          map((response: ContactRelationship[]) =>
            fetchContactRelationsSuccess({ data: response })
          ),
          catchError(() => of(fetchContactRelationsFail()))
        )
      )
    )
  );

  postContactRelationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(postContactRelationsRequest),
      switchMap(({ contactId, params }) =>
        this.apiService
          .post(`contacts/${contactId}/contact-relationships`, {
            ...params,
            relationshipSource: "crm",
          })
          .pipe(
            map(() => postContactRelationsSuccess()),
            catchError(() => of(postContactRelationsFail()))
          )
      )
    )
  );

  patchContactRelationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patchContactRelationsRequest),
      switchMap(({ contactId, eaRelationshipId, params }) =>
        this.apiService
          .patch(
            `contacts/${contactId}/contact-relationships/${eaRelationshipId}`,
            { ...params }
          )
          .pipe(
            map(() => patchContactRelationsSuccess()),
            catchError(() => of(patchContactRelationsFail()))
          )
      )
    )
  );

  deleteContactRelationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteContactRelationsRequest),
      switchMap(({ contactId, eaRelationshipId }) =>
        this.apiService
          .delete(
            `contacts/${contactId}/contact-relationships/${eaRelationshipId}`
          )
          .pipe(
            map(() => deleteContactRelationsSuccess()),
            catchError(() => of(deleteContactRelationsFail()))
          )
      )
    )
  );

  refreshContactRelationsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        postContactRelationsSuccess,
        patchContactRelationsSuccess,
        deleteContactRelationsSuccess
      ),
      map(() => this.latestFetchAction)
    )
  );
}
