import {
  fetchStatisticsFail,
  fetchStatisticsRequest,
  fetchStatisticsSuccess,
  fetchTaskTypesFail,
  fetchTaskTypesRequest,
  fetchTaskTypesSuccess,
} from "@app/widgets/widgets/statistics-lead-result-widget-new/ngrx/statistics-lead-result-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface LeadResult {
  periodStart: string;
  periodEnd: string;
  numberOfLeads: number;
  numberOfResolvedLeads: number;
  numberOfSuccessfulLeads: number;
  numberOfSoldObjects: number;
}

export interface LeadResultState {
  stats: LeadResult;
  taskTypes: { typeName: string; eaTaskTypeId: number }[];
  loading: boolean;
}

export const initialState: LeadResultState = {
  stats: null,
  taskTypes: [],
  loading: false,
};

export function leadResultsReducer(
  state: LeadResultState,
  action: Action
): LeadResultState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(fetchStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(fetchStatisticsSuccess, (state, { leadResult }) => ({
    ...state,
    loading: false,
    stats: leadResult,
  })),
  on(fetchStatisticsFail, (state) => ({
    ...state,
    loading: false,
  })),
  on(fetchTaskTypesRequest, (state) => ({
    ...state,
    taskTypes: [],
  })),
  on(fetchTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    taskTypes,
  })),
  on(fetchTaskTypesFail, (state) => ({
    ...state,
    taskTypes: [],
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.leadResult
);

export const getStats = createSelector(
  getState,
  (state: LeadResultState) => state.stats
);
export const getLoading = createSelector(
  getState,
  (state: LeadResultState) => state.loading
);
export const getTaskTypes = createSelector(
  getState,
  (state: LeadResultState) => state.taskTypes
);
