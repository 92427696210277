import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "app-expandable-text",
  templateUrl: "./expandable-text.component.html",
  styleUrls: ["./expandable-text.component.scss"],
})
export class ExpandableTextComponent implements OnChanges {
  @Input() hideAfterNoOfChars = 30;
  @Input() text = "";
  displayText: string;

  ngOnChanges(changes): void {
    if (changes.text) {
      this.displayText = this.text;
      if (this.text.length > this.hideAfterNoOfChars) {
        this.showLessText();
      }
    }
  }

  showMoreText() {
    this.displayText = this.text;
  }

  showLessText() {
    this.displayText = this.text.slice(0, this.hideAfterNoOfChars) + "...";
  }
}
