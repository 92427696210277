<div class="wrapper">
  <div class="scroll-view">
    <sidebar-header [label]="'profile' | translate"></sidebar-header>

    <section>
      <profile-card></profile-card>
    </section>
    <ng-container *ngIf="numberOfOffices > 1">
      <section>
        <profile-change-office [employeeOffices]="employeeOffices$ | async">
        </profile-change-office>
      </section>
    </ng-container>
    <section>
      <language-switch></language-switch>
    </section>
    <section class="services">
      <h3 translate>deswinglisher_key_deal_services</h3>
      <ng-container *ngIf="baseSystemName.length > 0">
        <a
          class="btn btn-secondary"
          type="button"
          (click)="openExternalLink($event, baseSystemLink)"
        >
          <ng-container *ngIf="!(ssoifyLoading$ | async); else spinner">
            {{ "open" | translate }} {{ baseSystemName }}
            <i class="icon-share-alt"></i>
          </ng-container>
        </a>
      </ng-container>
      <ng-container *ngIf="milesFeatureEnabled$ | async">
        <a
          [routerLink]="[
            '/crm',
            { outlets: { sidebar: null, primary: ['miles'] } }
          ]"
          target="_blank"
          rel="noopener noreferrer"
          class="btn btn-secondary"
          type="button"
        >
          <span translate>open_driving_journal</span>&nbsp;
          <i class="icon-share-alt"></i>
        </a>
      </ng-container>
    </section>
    <section *ngIf="isSuperAdmin">
      <app-checkbox-element
        [width]="'fit-content'"
        [path]="['untranslated']"
        [label]="'View all untranslated tooltips'"
        [infoTranslationKey]="'crm_reload_necessary'"
        [preselectedValue]="showUntranslatedTooltips"
        (valueEntered)="updateLocalStorage($event)"
      ></app-checkbox-element>
      <client-switch></client-switch>
      <div class="promote-container">
        <button class="btn btn-secondary" (click)="switchUserLevel('demote')">
          {{ "demote" | translate }}
        </button>
        <button class="btn btn-secondary" (click)="switchUserLevel('promote')">
          {{ "promote" | translate }}
        </button>
      </div>
      <h3>Broker information</h3>
      <div class="broker-info__body">
        <span>Click to copy information</span>
        <h5>eaEmployeeId:</h5>
        <input
          #eaEmployeeId
          (click)="selectAndCopy(eaEmployeeId)"
          type="text"
          value="{{ (employee$ | async)?.eaEmployeeId }}"
          readonly
        />
        <h5>employeeId:</h5>
        <input
          #employeeId
          (click)="selectAndCopy(employeeId)"
          type="text"
          value="{{ (employee$ | async)?.employeeId }}"
          readonly
        />
        <h5>eaOfficeId:</h5>
        <input
          #eaOfficeId
          (click)="selectAndCopy(eaOfficeId)"
          type="text"
          value="{{ eaOfficeId$ | async }}"
          readonly
        />
        <h5>officeId:</h5>
        <input
          #officeId
          (click)="selectAndCopy(officeId)"
          type="text"
          value="{{ officeId$ | async }}"
          readonly
        />
        <h5>Email:</h5>
        <input
          #email
          (click)="selectAndCopy(email)"
          type="text"
          value="{{ (employee$ | async)?.employeeEmail }}"
          readonly
        />
        <h5>Bearer token:</h5>
        <input
          #token
          (click)="selectAndCopy(token)"
          type="text"
          [value]="authService.getToken()"
          readonly
        />
      </div>
    </section>
    <div class="sidebar-footer">
      <div style="width: 100%">
        <app-q-button
          buttonType="remove"
          label="logout"
          icon="door-closed"
          classes="q-btn--remove-border"
          [fullWidth]="true"
          (click)="logout()"
        >
        </app-q-button>
      </div>
    </div>
  </div>
</div>
<ng-template #spinner>
  <spinner [size]="'1rem'"></spinner>
</ng-template>
