<form #form="ngForm">
  <div class="forgot-password">
    <div class="forgot-password__header">
      <div class="alert alert-info">
        {{ "write_new_password" | translate }}
      </div>
    </div>
    <div class="form-group">
      <div class="input-iconfield">
        <input
          [(ngModel)]="password"
          #passwordModel="ngModel"
          validateEqual="passwordRepeat"
          required
          reverse="true"
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\w\W]+$"
          minlength="6"
          name="password"
          type="password"
          placeholder="{{ 'new_password' | translate }}"
        />
        <i class="icon icon--lg fa-light fa-key-skeleton"></i>
      </div>
    </div>
    <span
      class="validation-message"
      *ngIf="
        passwordModel.control.hasError('minlength') &&
        passwordModel.control.touched
      "
    >
      {{ "type_at_least_6_chars" | translate }}
    </span>
    <span
      class="validation-message"
      *ngIf="
        passwordModel.control.hasError('pattern') &&
        passwordModel.control.touched
      "
    >
      {{ "password_format" | translate }}
    </span>
    <span
      class="validation-message"
      *ngIf="
        passwordRepeatModel.value !== passwordModel.value &&
        passwordRepeatModel.control.touched
      "
    >
      {{ "password_mismatch" | translate }}
    </span>
    <div class="form-group">
      <div class="input-iconfield">
        <input
          [(ngModel)]="passwordRepeat"
          #passwordRepeatModel="ngModel"
          validateEqual="password"
          minlength="6"
          required
          name="passwordRepeat"
          type="password"
          placeholder="{{ 'password_repeat' | translate }}"
        />
        <i class="icon icon--lg fa-light fa-key-skeleton"></i>
      </div>
    </div>
  </div>

  <div class="forgot-password__footer">
    <app-q-button
      buttonType="cancel"
      label="cancel"
      classes="q-btn--cancel-border"
      routerLink="../"
    >
    </app-q-button>
    <button
      type="submit"
      class="q-btn q-btn--save"
      [disabled]="
        !form.valid || passwordRepeatModel.value !== passwordModel.value
      "
      (click)="setPassword()"
    >
      <app-icon name="floppy-disk"></app-icon>
      {{ "save" | translate }}
    </button>
  </div>
</form>
