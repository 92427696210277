import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-lead-result-options-form",
  templateUrl: "./lead-result-options-form.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-result-options-form.component.scss",
  ],
})
export class LeadResultOptionsFormComponent implements OnChanges {
  @Input() resultOption;

  form: FormGroup;
  followUpActivities: { label: string; value: any }[] = [
    { label: "no_follow_up_activity", value: "0" },
    { label: "book_sales_meeting", value: "2" },
    { label: "create_task", value: "4" },
  ];
  hours = [1, 2, 3, 4, 5, 6, 7, 8, 12, 16, 20, 24, 36, 40];

  constructor(private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.resultOption && !!this.resultOption) {
      this.fillForm();
    }
  }

  buildForm() {
    this.form = this.fb.group({
      name: ["", Validators.required],
      description: [""],
      resolved: [false],
      success: [false],
      followUpActivity: ["0"],
      postponeEndTimeHours: [0],
    });
  }

  fillForm() {
    const {
      resultName,
      resultDescription,
      resolved,
      success,
      eaWorkFlowId,
      postponeEndTimeHours,
    } = this.resultOption;
    this.form.patchValue({
      name: resultName,
      description: resultDescription,
      resolved,
      success,
      followUpActivity: eaWorkFlowId,
      postponeEndTimeHours,
    });
  }

  submit(cb: (body: any) => void) {
    if (this.form.valid) {
      const { name, description, followUpActivity, ...rest } = this.form.value;
      cb({
        resultName: name,
        resultDescription: description,
        eaWorkFlowId: followUpActivity,
        ...rest,
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
