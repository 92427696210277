import { Employee } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsActions from "../ngrx/statistics.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface EmployeesState {
  employees: Employee[];
}

export const initialState: EmployeesState = {
  employees: null,
};

export function employeesReducer(
  state: EmployeesState,
  action: Action
): EmployeesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsActions.fetchEmployeesSuccess, (state, { employees }) => ({
    ...state,
    employees,
  })),
  on(statisticsActions.clearEmployees, () => ({ ...initialState }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.employees
);

export const getEmployees = createSelector(
  getState,
  (state: EmployeesState) => state.employees
);
