<div class="timeline">
  <div
    class="timeline__item"
    *ngFor="let note of (data$ | async)?.notes; let last = last"
  >
    <div class="timeline__separator">
      <div class="timeline__dot">
        <app-icon name="file" size="medium"></app-icon>
      </div>
      <div class="timeline__connector" *ngIf="!last"></div>
    </div>
    <div class="timeline__content">
      <div>
        <h5 class="timeline__title">{{ note?.title }}</h5>
        <small class="text-muted">
          <span tooltip="{{ formatDate(note?.date) }}"
            >{{ formatDate(note?.date) }},
          </span>
          <span tooltip="{{ note?.author?.fullName }}">{{
            "by_X" | translate : { name: note?.author?.fullName }
          }}</span>
        </small>
        <div [innerHTML]="note?.content"></div>
      </div>
      <button
        type="button"
        class="trash-btn"
        (click)="deleteNote(note?.externalId)"
      >
        <app-icon name="trash" size="medium"></app-icon>
      </button>
    </div>
  </div>
</div>
