import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { createAction, props } from "@ngrx/store";

export const addContactToCallingListRequest = createAction(
  "[GET_ADD_CONTACT_CALLING_LIST] REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const reset = createAction("[ADD_CONTACT_TO_CALLING_LIST] RESET");

export const addContactToCallingListSuccess = createAction(
  "[GET_ADD_CONTACT_CALLING_LIST] SUCCESS",
  props<{ callingLists: CallingList[] }>()
);

export const addContactToCallingListFail = createAction(
  "[GET_ADD_CONTACT_CALLING_LIST] FAIL"
);

export const storeContactToCallingListRequest = createAction(
  "[STORE_CONTACT_TO_CALLING_LIST] REQUEST",
  props<{ callingListId: string; contactId: string }>()
);

export const storeContactToCallingListSuccess = createAction(
  "[STORE_CONTACT_TO_CALLING_LIST] SUCCESS"
);

export const storeContactToCallingListFail = createAction(
  "[STORE_CONTACT_TO_CALLING_LIST] FAIL"
);

export const getUserCallingListRequest = createAction(
  "[Add Contact To Calling List] GET_CONTACT_CALLING_LIST_REQUEST ",
  props<{ filters: Record<string, unknown> }>()
);

export const getUserCallingListSuccess = createAction(
  "[Add Contact To Calling List] GET_CONTACT_CALLING_LIST_SUCCESS",
  props<{ callingLists: CallingList[] }>()
);

export const getUserCallingListFail = createAction(
  "[Add Contact To Calling List] GET_CONTACT_CALLING_LIST_FAIL"
);

export const removeContactFromCallingListRequest = createAction(
  "[Add Contact To Calling List] REMOVE_CONTACT_FROM_CALLING_LIST_REQUEST ",
  props<{ callingListId: string; contactId: string }>()
);

export const removeContactFromCallingListSuccess = createAction(
  "[Add Contact To Calling List] REMOVE_CONTACT_FROM_CALLING_LIST_SUCCESS"
);

export const removeContactFromCallingListFail = createAction(
  "[Add Contact To Calling List] REMOVE_CONTACT_FROM_CALLING_LIST_FAIL"
);
