import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ContactConsent } from "@app/contacts/contact-consents/models";
import { Contact } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import moment from "moment";
import { Observable } from "rxjs";

@Component({
  selector: "contact-summary-consents",
  styleUrls: [
    "../../contact-summary.component.scss",
    "./contact-summary-consents.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h5 translate>consents</h5>
    <div *ngIf="!isLoading && (consents$ | async)?.length === 0">
      <span class="empty">{{ "no_consents" | translate }}</span>
    </div>
    <div *ngIf="!isLoading; else loading">
      <ul>
        <li *ngFor="let consent of consents$ | async as consents">
          <i class="icon-check"></i>
          <div>
            {{ consent.name }}
            <span *ngIf="consent.validTo" class="text-muted">
              ({{ "valid_to" | translate }}: {{ formatDate(consent.validTo) }})
            </span>
          </div>
        </li>
      </ul>
    </div>
    <ng-template #loading>
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
      <br />
      <skeleton-rectangle [height]="1"></skeleton-rectangle>
    </ng-template>
  `,
})
export class ContactSummaryConsentsComponent {
  @Input() isLoading: boolean;
  @Input() contact$: Observable<Contact>;
  @Input() consents$: Observable<ContactConsent[]>;

  formatDate(date) {
    return moment(date, API_DATE_FORMAT).format("L");
  }
}
