import { Pipe, PipeTransform } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getObjectTypes } from "@app/shared/ngrx/shared.reducer";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Pipe({
  name: "objectTypeName",
})
export class ObjectTypeNamePipe implements PipeTransform {
  objectTypes$: Observable<ObjectType[]>;

  constructor(private store: Store<AppState>) {
    this.objectTypes$ = this.store.pipe(select(getObjectTypes));
  }

  transform(typeId: string | number): Observable<string> {
    return this.objectTypes$.pipe(
      map(
        (types: ObjectType[]) =>
          types.find((t) => t.objectTypeId.toString() === typeId.toString())
            ?.objectTypeName
      )
    );
  }
}
