import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatisticsEmployeesPerformanceData } from "@app/widgets/widgets/statistics-employees-performance-widget/models/statistics-employees-performance";
import * as statisticsEmployeesPerformanceActions from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.actions";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface StatisticsEmployeesPerformanceState {
  isLoading: boolean;
  data: StatisticsEmployeesPerformanceData;
}

export const initialState: StatisticsEmployeesPerformanceState = {
  isLoading: false,
  data: null,
};

export const statisticsEmployeesPerformanceWidgetReducer = createReducer(
  initialState,
  on(
    statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsRequest,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsSuccess,
    (state, { data }) => ({
      ...state,
      isLoading: false,
      data,
    })
  ),
  on(
    statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsFail,
    (state) => ({
      ...state,
      isLoading: false,
    })
  )
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
export const selectEmployeesPerformance = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.employeesPerformance
);
export const selectData = createSelector(
  selectEmployeesPerformance,
  (state: StatisticsEmployeesPerformanceState) => state.data
);
export const selectIsLoading = createSelector(
  selectEmployeesPerformance,
  (state: StatisticsEmployeesPerformanceState) => state.isLoading
);
