import { BudgetTarget } from "@app/models/budget-target";
import { Tag } from "@app/models/tags";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsTopListsActions from "./statistics-growth.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsGrowthState {
  loading: boolean;
  csvLoading: boolean;
  growthStatistics: GrowthStatistics;
  budgetTargetTypes: BudgetTarget[];
  tagSuggestions: Tag[];
}

export const initialState: StatisticsGrowthState = {
  loading: false,
  csvLoading: false,
  growthStatistics: null,
  budgetTargetTypes: [],
  tagSuggestions: [],
};

export function statisticsGrowthReducer(
  state: StatisticsGrowthState,
  action: Action
): StatisticsGrowthState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsTopListsActions.fetchGrowthStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(statisticsTopListsActions.fetchGrowthStatisticsCsvRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(
    statisticsTopListsActions.fetchGrowthStatisticsSuccess,
    (state, { growthStatistics }) => ({
      ...state,
      growthStatistics,
      loading: false,
    })
  ),
  on(
    statisticsTopListsActions.fetchGrowthStatisticsCsvSuccess,
    statisticsTopListsActions.fetchGrowthStatisticsCsvFailed,
    (state) => ({
      ...state,
      csvLoading: false,
    })
  ),
  on(statisticsTopListsActions.fetchGrowthStatisticsFailed, (state) => ({
    ...state,
    growthStatistics: null,
    loading: false,
  })),
  on(
    statisticsTopListsActions.fetchBudgetTargetTypesSuccess,
    (state, { budgetTypes }) => ({
      ...state,
      budgetTargetTypes: budgetTypes,
    })
  ),
  on(statisticsTopListsActions.fetchBudgetTargetTypesFailed, (state) => ({
    ...state,
    budgetTargetTypes: [],
  })),
  on(
    statisticsTopListsActions.fetchTagSuggestionsSuccess,
    (state, { tags }) => ({
      ...state,
      tagSuggestions: tags,
    })
  ),
  on(statisticsTopListsActions.fetchTagSuggestionsFailed, (state) => ({
    ...state,
    tagSuggestions: [],
  }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");

const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.growth
);

export const getGrowthStatisticsLoading = createSelector(
  getState,
  (state: StatisticsGrowthState) => state.loading
);

export const getGrowthStatisticsCsvLoading = createSelector(
  getState,
  (state: StatisticsGrowthState) => state.csvLoading
);

export const getGrowthStatistics = createSelector(
  getState,
  (state: StatisticsGrowthState) => state.growthStatistics
);

export const getGrowthTagSuggestions = createSelector(
  getState,
  (state: StatisticsGrowthState) => state.tagSuggestions
);

export const getGrowthStatisticsBudgetTargetTypes = createSelector(
  getState,
  (state: StatisticsGrowthState) => state.budgetTargetTypes
);
