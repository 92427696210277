import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import {
  daLocale,
  defineLocale,
  fiLocale,
  nbLocale,
  nlLocale,
  svLocale,
} from "ngx-bootstrap/chronos";
import { BsLocaleService } from "ngx-bootstrap/datepicker";

export enum Locale {
  EN = "en",
  SV = "sv",
  DA = "da",
  NB = "nb",
  NL = "nl",
  FI = "fi",
  ET = "et",
}

@Injectable()
export class LocaleService {
  private activeLocale: string = "en";
  private readonly defaultLocale: string;
  private readonly supportedLocales: string[];
  // ngx-bootstrap currently doesn't support all locales
  private readonly supportedBootstrapLocales = [
    "en",
    "sv",
    "da",
    "nl",
    "fi",
    "nb",
  ];

  constructor(
    private translate: TranslateService,
    private localeService: BsLocaleService
  ) {
    this.defaultLocale = Locale.EN;
    this.supportedLocales = Object.keys(Locale).map((l) => Locale[l]);

    this.setFallbackLanguage(this.defaultLocale);
    this.defineLocalesForBootstrap();
  }

  getLocales(): string[] {
    return this.supportedLocales.slice(0);
  }

  getCurrentLocale(): string {
    return this.activeLocale;
  }

  isLocaleSupported(locale: string): boolean {
    return this.supportedLocales.includes(locale);
  }

  setLocale(locale: string): void {
    if (!this.isLocaleSupported(locale)) {
      locale = this.defaultLocale;
    }

    this.activeLocale = locale;
    this.translate.use(locale);
    this.setLocaleForMoment(locale);
    this.setLocaleForBootstrap(locale);
  }

  private setFallbackLanguage(locale: string): void {
    this.translate.setDefaultLang(locale);
  }

  private defineLocalesForBootstrap(): void {
    defineLocale(Locale.SV, svLocale);
    defineLocale(Locale.DA, daLocale);
    defineLocale(Locale.NL, nlLocale);
    defineLocale(Locale.FI, fiLocale);
    defineLocale(Locale.NB, nbLocale);
  }

  private setLocaleForMoment(locale: string): void {
    moment.locale(this.getLocale(locale));
  }

  private setLocaleForBootstrap(locale: string): void {
    this.localeService.use(this.getLocale(locale));
  }

  private getLocale(locale: string): string {
    if (this.supportedBootstrapLocales.includes(locale)) {
      return locale;
    } else {
      return this.defaultLocale;
    }
  }
}
