export const LightenColor = (baseColor: string, lum: number) => {
  let hex = String(baseColor).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substring(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substring(c.length);
  }

  return rgb;
};

// The most positive colors in the start, negative in the end
export const defaultColorScheme = {
  domain: [
    "#28E57C",
    "#009ADA",
    "#2ADCF5",
    "#FFC371",
    "#FFCB66",
    "#FF5F6D",
    "#DE1B41",
    "#F5515F",
    "#9F041B",
  ],
};

export const happyColorScheme = {
  domain: [
    "#28E57C",
    "#40bf9f",
    "#009ADA",
    "#5380ac",
    "#2ADCF5",
    "#FFC371",
    "#FFCB66",
  ],
};

export const highChartColorScheme = {
  domain: [
    "#7cb5ec",
    "#434348",
    "#90ed7d",
    "#f7a35c",
    "#8085e9",
    "#f15c80",
    "#e4d354",
    "#2b908f",
    "#f45b5b",
    "#91e8e1",
  ],
};
