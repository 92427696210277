import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Action } from "@app/models";
import * as fromUser from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Observable, Subject } from "rxjs";
import { ActionFilter } from "./models";
import { ActionService } from "./services/action.service";

@Component({
  selector: "action-list",
  templateUrl: "./action-list.component.html",
  styleUrls: [
    "../../../../styles/list-item.component.scss",
    "./action-list.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionListComponent implements OnInit, OnDestroy {
  @ViewChild("modal", { static: false })
  modal: ModalDirective;
  @Input() list: Action[];
  @Input() disableLoadMore: boolean;
  @Input() showLoadMore = false;
  @Input() showAllToggle: boolean;
  @Input() showToggleSlide = true;
  @Output() changedFilters = new EventEmitter<string[]>();
  @Output() loadMore = new EventEmitter<any>();
  @Output() toggleShowAll = new EventEmitter<any>();

  filters: ActionFilter[];
  selectedFilters: ActionFilter[] = [];

  eaEmployeeId$: Observable<string>;
  unSubscribe$ = new Subject<void>();

  constructor(private cas: ActionService, private store: Store<AppState>) {
    this.filters = this.cas.getAllFilters();
  }

  ngOnInit() {
    this.mapStateToProps();
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  toggleSelectedFilter(filter: ActionFilter): void {
    const i = this.selectedFilters.indexOf(filter);
    if (i === -1) {
      this.selectedFilters.push(filter);
    } else {
      this.selectedFilters.splice(i, 1);
    }

    const filterList: ActionFilter[] =
      this.selectedFilters.length === 0
        ? this.cas.getAllFilters()
        : this.selectedFilters;
    let list: string[] = [];

    filterList.forEach((af: ActionFilter) => (list = list.concat(af.codes)));

    this.changedFilters.emit(list);
  }

  resetFilters(): void {
    this.selectedFilters = [];
    this.changedFilters.emit(this.cas.getAllFilterCodes());
  }

  showReset(): boolean {
    return this.selectedFilters.length > 0;
  }

  filterSelected(filter: ActionFilter): boolean {
    return this.selectedFilters.indexOf(filter) !== -1;
  }

  filterColor(filter: ActionFilter): string {
    return this.filterSelected(filter) ? filter.color : "";
  }

  onLoadMore() {
    this.loadMore.emit(this.list.length);
  }

  private mapStateToProps() {
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
  }
}
