<app-q-table
  [columns]="columns"
  [length]="contactService.total$ | async"
  [data]="paginatedContacts$ | async"
  [enableClick]="true"
  [enableSelection]="true"
  [enableSearch]="false"
  [enableActions]="true"
  [enablePaginator]="true"
  [defaultPageSize]="paginator$.value.pageSize"
  [loading]="contactService.loading$ | async"
  [key]="'calling_list_contacts'"
  [sortableColumns]="sortableColumns"
  [sortActive]="sorting$.value.active"
  [sortDirection]="sorting$.value.direction"
  (sort)="onSort($event)"
  (rowClick)="
    !$event.contactedDate ? startCallingQueueFromContact($event) : null
  "
  (selected)="handleSelect($event)"
  (page)="onPage($event)"
>
  <ng-container slot="buttons">
    <app-q-sheet-wrapper>
      <div class="button-container">
        <app-q-button
          (click)="
            callingList?.progress?.percent !== 100
              ? startCallingQueueFromContact(null)
              : null
          "
          [disabled]="
            callingList?.progress?.contacts === 0 ||
            callingList?.progress?.percent === 100
          "
          tooltip="{{
            callingList?.progress?.percent !== 100
              ? ('go_to_call_queue' | translate)
              : ('all_contacts_already_contacted' | translate)
          }}"
          placement="left"
          label="go_to_call_queue"
          icon="phone"
        >
        </app-q-button>
        <app-q-button
          *ngIf="selection.length > 0"
          label="send_message"
          icon="envelope"
          (click)="openSendMessageSidebar()"
        >
        </app-q-button>
      </div>
    </app-q-sheet-wrapper>
  </ng-container>
  <ng-template let-cell="cell" let-column="column" let-row="row">
    <ng-container [ngSwitch]="column">
      <ng-container *ngSwitchCase="'name'">
        <app-contact-name
          [contactType]="row?.contactType"
          [contactId]="row?.contactId"
        >
          {{ row?.firstName }} {{ row?.familyName }}
        </app-contact-name>
      </ng-container>
      <ng-container *ngSwitchCase="'latest_object_connection'">
        <div
          class="object-connections"
          *ngIf="row?.latestObjectConnections.length > 0"
        >
          <app-mobile-card
            [enableMetadata]="true"
            [shadow]="false"
            [enableActionDropdown]="true"
            (click)="openObject($event, row?.latestObjectConnections[0].eaOid)"
            color="TRANSPARENT"
            [size]="'SLIM'"
          >
            <p title>
              {{ row?.latestObjectConnections[0].street }},
              {{ row?.latestObjectConnections[0].city }}
            </p>
            <p metadata>
              <span>{{
                row?.latestObjectConnections[0].type | translate
              }}</span>
            </p>
            <ng-container buttons>
              <li role="menuitem">
                <button
                  class="btn btn-secondary"
                  (click)="
                    openObject($event, row?.latestObjectConnections[0].eaOid)
                  "
                >
                  <app-icon name="house"></app-icon>
                  {{ "go_to_object" | translate }}
                </button>
              </li>
              <li disableNavigation role="menuitem">
                <button
                  disableNavigation
                  class="btn btn-default"
                  (click)="
                    openObjectInNewTab(
                      $event,
                      row?.latestObjectConnections[0].eaOid
                    )
                  "
                >
                  <app-icon name="share-square"></app-icon>
                  {{ "open_in_new_tab" | translate }}
                </button>
              </li>
            </ng-container>
          </app-mobile-card>
          <!--            Todo: Use this if we want to display multiple property connections-->
          <button
            class="btn btn-default"
            [popover]="popTemplate"
            *ngIf="row?.latestObjectConnections.length > 1"
          >
            +{{ row?.latestObjectConnections.length - 1 }}
          </button>
        </div>

        <ng-template #popTemplate>
          <ng-container
            *ngFor="let object of row.latestObjectConnections; let i = index"
          >
            <app-mobile-card
              [enableMetadata]="true"
              [shadow]="false"
              [enableActionDropdown]="true"
              [popover]="popTemplate"
              *ngIf="i !== 0"
              (click)="openObject($event, object.eaOid)"
              color="TRANSPARENT"
              [size]="'SLIM'"
            >
              <p title>{{ object.street }}, {{ object.city }}</p>
              <p metadata>
                <span>{{ object.type | translate }}</span>
              </p>
              <ng-container buttons>
                <li role="menuitem">
                  <button
                    class="btn btn-secondary"
                    (click)="openObject($event, object.eaOid)"
                  >
                    <app-icon name="house"></app-icon>
                    {{ "go_to_object" | translate }}
                  </button>
                </li>
                <li disableNavigation role="menuitem">
                  <button
                    disableNavigation
                    class="btn btn-default"
                    (click)="openObjectInNewTab($event, object.eaOid)"
                  >
                    <app-icon name="share-square"></app-icon>
                    {{ "open_in_new_tab" | translate }}
                  </button>
                </li>
              </ng-container>
            </app-mobile-card>
          </ng-container>
        </ng-template>
      </ng-container>
      <ng-container *ngSwitchCase="'status'">
        {{
          row?.contactedDate
            ? ("contacted" | translate)
            : ("not_contacted" | translate)
        }}
      </ng-container>
      <ng-container *ngSwitchCase="'contacted'">
        {{ row?.contactedDate ? row?.contactedDate : "" }}
      </ng-container>
      <ng-container *ngSwitchCase="'created'">
        {{ row.insertedDate | momentify }}
      </ng-container>
      <ng-container *ngSwitchCase="'mobile'">
        {{ row.msisdn | phoneFormat : row.msisdnCountry }}
      </ng-container>
      <ng-container *ngSwitchDefault>
        {{ cell }}
      </ng-container>
    </ng-container>
  </ng-template>
  <ng-template let-row="row">
    <app-q-action-dropdown-item
      [style.display]="!row.contactedDate ? 'auto' : 'none'"
      label="start_calling_queue_from_here"
      [icon]="'play'"
      (press)="startCallingQueueFromContact(row)"
    ></app-q-action-dropdown-item>
    <app-q-action-dropdown-item
      label="remove"
      icon="trash"
      [iconColor]="redColor"
      (press)="openModal(removeContactModal); selectedContactId = row.contactId"
    ></app-q-action-dropdown-item>
  </ng-template>
</app-q-table>

<ng-template #removeContactModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left" translate>
      remove_calling_list_contact_header
    </h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body" translate>remove_calling_list_contact_message</div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-default btn-lg"
      (click)="modalRef.hide()"
      translate
    >
      cancel
    </button>
    <button
      type="button"
      class="btn btn-danger btn-lg"
      (click)="modalRef.hide(); handleRemoveFromCallingList()"
      translate
    >
      remove
    </button>
  </div>
</ng-template>

<ng-template #skeletonView>
  <tbody>
    <tr *ngFor="let item of ['', '', '', '', '']">
      <td
        *ngFor="
          let label of [
            'name',
            'status',
            'created',
            'email',
            'mobile',
            'contacted',
            'remove'
          ]
        "
        [dataLabel]="label"
      >
        <skeleton-rectangle [height]="1" [width]="10"></skeleton-rectangle>
      </td>
    </tr>
  </tbody>
</ng-template>
