import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { Employment, TypedPaginationListDTO } from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of as observableOf, switchMap } from "rxjs";
import * as employeesActions from "./employees-search.actions";

@Injectable()
export class EmployeesSearchEffects {
  getIgnoreList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(employeesActions.getEmploymentsRequest),
      switchMap(({ eaOfficeId }) =>
        this.apiService.get(`offices/${eaOfficeId}/employments`).pipe(
          map((response: TypedPaginationListDTO<Employment>) =>
            employeesActions.getEmploymentsSuccess({
              employments: response.rows,
            })
          ),
          catchError(() =>
            observableOf(employeesActions.getEmploymentsFailed())
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
