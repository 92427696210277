import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { LEAD_CHANNELS } from "@app/shared/utils/tab-types";
import { LeadChannelsFormComponent } from "@app/sidebar/lead-channels/components/lead-channels-form/lead-channels-form.component";
import * as leadChannelsFormActions from "@app/sidebar/lead-channels/ngrx/lead-channels-form.actions";
import {
  selectData,
  selectIsLoading,
} from "@app/sidebar/lead-channels/ngrx/lead-channels-form.reducer";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";

@Component({
  selector: "app-lead-channels-edit",
  templateUrl: "./lead-channels-edit.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-channels-edit.component.scss",
  ],
})
export class LeadChannelsEditComponent
  extends ConnectTab
  implements OnInit, OnDestroy, IConnectableTab
{
  @ViewChild(LeadChannelsFormComponent, { static: true })
  leadChannelsForm: LeadChannelsFormComponent;

  tabType = LEAD_CHANNELS;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  data$: Observable<any>;
  isLoading$: Observable<any>;
  channelId$: Observable<number>;

  constructor(store: Store<AppState>, private route: ActivatedRoute) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.channelId$ = this.route.params.pipe(
      map((params) => params?.channelId)
    );
    this.channelId$.pipe(takeUntil(this.unsubscribe$)).subscribe((id) => {
      this.store.dispatch(
        leadChannelsFormActions.getLeadChannelRequest({ id })
      );
    });
    this.mapStateToProps();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps() {
    this.data$ = this.store.select(selectData);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onRemove() {
    this.store.dispatch(
      confirmActions.show({
        header: "delete_channel_header",
        message: "delete_channel_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1),
        withLatestFrom(this.channelId$)
      )
      .subscribe(([, id]) => {
        this.store.dispatch(
          leadChannelsFormActions.deleteLeadChannelRequest({
            id,
          })
        );
      });
  }

  onSubmit() {
    this.channelId$.pipe(first()).subscribe((id) => {
      this.leadChannelsForm.submit((body) =>
        this.store.dispatch(
          leadChannelsFormActions.editLeadChannelRequest({
            id,
            body,
          })
        )
      );
    });
  }
}
