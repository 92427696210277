import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { solidGauge } from "../charts/solid-gauge";
import { getProgressBarType } from "@app/shared/utils/colors";

@Component({
  selector: "kpi-box",
  templateUrl: "./kpi-box.component.html",
  styleUrls: ["./kpi-box.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiBoxComponent implements OnInit, AfterViewInit {
  _value: number;
  Highcharts: typeof Highcharts = Highcharts;
  getProgressBarType = getProgressBarType;

  @Input() title: string;
  @Input() min = 0;
  @Input() max: number;
  @Input() type: string;

  get value(): number {
    return this._value;
  }

  @Input()
  set value(value: number) {
    this._value = Math.round(value);
  }

  @HostBinding("class.active") active: boolean;

  options: Highcharts.Options = {};

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.active = this.max !== null;
    this.options = solidGauge(
      this.min,
      this.max,
      this.value || 0,
      this.type === "soonforsaleobjectsnew"
    );
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  getTooltip(type: string): string {
    switch (type) {
      case "won_salesmeeting":
        return "kpi_won_salesmeeting_tooltip";
      case "commissions_earnings":
        return "kpi_commissions_earnings_tooltip";
      case "sold_objects":
        return "kpi_sold_objects_tooltip";
      case "made_sales_calls":
        return "kpi_sales_calls_made_tooltip";
      case "booked_salesmeeting":
        return "kpi_booked_salesmeeting_tooltip";
      case "rentedobjects":
        return "kpi_rented_objects_tooltip";
      case "rentedobjectscommission":
        return "kpi_rented_objects_commission_tooltip";
      case "commissions_earnings_rentals":
        return "kpi_commissions_earnings_rentals_tooltip";
      case "estate_commission":
        return "kpi_estate_commission_tooltip";
      case "soldobjectswithcommission":
        return "kpi_sold_objects_commission_tooltip";
      default:
        return null;
    }
  }

  isTruncated(element) {
    return !(element.offsetWidth >= element.scrollWidth);
  }
}
