import { PercentPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "qPercent",
})
export class QPercentPipe implements PipeTransform {
  constructor(private percentPipe: PercentPipe) {}

  transform(value: any, digits?: any): any {
    if ((!value || value === "-") && value !== 0) {
      return value;
    }
    return (<any>this.percentPipe)
      .transform(value, digits, "en-US")
      .replace(/,/g, " "); // TODO Locale is first available in Angular ^4.4.0
  }
}
