import { FormControl, FormGroup } from "@angular/forms";

export function allFieldsRequiredOnAtLeastOneControlOrGroup(controls: any[]) {
  return function allFieldRequired() {
    return controls.some((control) => {
      if (control instanceof FormControl && control.value) {
        return control.value.length > 0;
      }
      if (control instanceof FormGroup) {
        return !Object.keys(control.controls).some((key) => {
          if (control.controls[key]) {
            return control.controls[key].value.length === 0;
          }
          return true;
        });
      }
    })
      ? null
      : { allRequired: { valid: false } };
  };
}
