import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as messageTemplatesFormActions from "@app/sidebar/message-templates/ngrx/message-templates-form.actions";
import { DynamicContent } from "@app/sidebar/send-message/models";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface Template {
  templateId: string;
  templateName: string;
  templateExternalId?: string;
  templateDescription: string;
  insertedBy?: string;
  insertedDate?: string;
  updatedBy?: string;
  updatedDate?: string;
  status?: string;
  adminViewPermission?: boolean;
  officeManagerViewPermission?: boolean;
  employeeViewPermission?: boolean;
  messageType: "email" | "sms";
  eaCrmTemplateGroupId?: string;
  displayName?: string;
  templateGroupName: string;
  templateGroupDescription?: string;
}

export interface MessageTemplatesFormState {
  template: Template | null;
  dynamicContents: DynamicContent[] | null;
}

export const initialState: MessageTemplatesFormState = {
  template: null,
  dynamicContents: null,
};

export const messageTemplatesFormReducer = createReducer(
  initialState,
  on(
    messageTemplatesFormActions.getMessageTemplateSuccess,
    (state, { template }) => ({ ...state, template })
  ),
  on(
    messageTemplatesFormActions.fetchDynamicContentSuccess,
    (state, { dynamicContents }) => ({ ...state, dynamicContents })
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.messageTemplatesForm
);

export const getTemplate = createSelector(
  getState,
  (state: MessageTemplatesFormState) => state.template
);

export const getDynamicContents = createSelector(
  getState,
  (state: MessageTemplatesFormState) => state.dynamicContents
);
