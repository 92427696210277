import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";

export const buildResidenceConfiguration = (
  objectOverviewEnabled: boolean
): ResidenceSidebarConfig => {
  return {
    objectTypeIsMandatory: objectOverviewEnabled,
    showSalesObjectSelection: objectOverviewEnabled,
  };
};
