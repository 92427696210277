import { Employee, Task } from "@app/models";
import { SendMessageParams } from "@app/sidebar/tasks/models/send-message-params";
import { TaskActionLog } from "@app/sidebar/tasks/ngrx/tasks.reducer";
import { createAction, props } from "@ngrx/store";
import { TaskUpdateParams } from "@app/sidebar/tasks/tasks-default/tasks-default.component";

export const openSalesmeetingTabIfNotBooked = createAction(
  "[Tasks] OPEN_SALESMEETING_TAB_IF_NOT_BOOKED",
  props<{
    taskAttempt: {
      task: Task;
      eaTaskId: string;
      eaTaskResultId: string;
      isSuccess: boolean;
    };
  }>()
);

export const getTaskRequest = createAction(
  "[Tasks] GET_TASK_REQUEST",
  props<{ eaTaskId: string }>()
);

export const getTaskSuccess = createAction(
  "[Tasks] GET_TASK_SUCCESS",
  props<{ task: Task }>()
);

export const getTaskFailed = createAction("[Tasks] GET_TASK_FAILED");

export const getTaskActionLogRequest = createAction(
  "[Tasks] GET_TASK_ACTION_LOG_REQUEST",
  props<{ taskId: string }>()
);
export const getTaskActionLogSuccess = createAction(
  "[Tasks] GET_TASK_ACTION_LOG_SUCCESS",
  props<{ taskActionLogs: TaskActionLog[] }>()
);

export const getTaskActionLogFailed = createAction(
  "[Tasks] GET_TASK_ACTION_LOG_FAILED"
);

export const updateTaskRequest = createAction(
  "[Tasks] UPDATE_TASK_REQUEST",
  props<{
    task: TaskUpdateParams;
    messageParams?: SendMessageParams;
  }>()
);

export const updateTaskSuccess = createAction(
  "[Tasks] UPDATE_TASK_SUCCESS",
  props<{
    task: Task;
    messageParams?: SendMessageParams;
  }>()
);

export const updateTaskFailed = createAction("[Tasks] UPDATE_TASK_FAILED");

export const deleteTaskRequest = createAction(
  "[Tasks] DELETE_TASK_REQUEST",
  props<{
    params: {
      id: string;
      reason: string;
      taskType: string;
    };
  }>()
);

export const deleteTaskSuccess = createAction("[Tasks] DELETE_TASK_SUCCESS");

export const deleteTaskFailed = createAction("[Tasks] DELETE_TASK_FAILED");

export const createTaskAttemptRequest = createAction(
  "[Tasks] CREATE_TASK_ATTEMPT_REQUEST",
  props<{
    taskAttempt: {
      task: Task;
      eaTaskId: string;
      eaTaskResultId: string;
      isSuccess: boolean;
    };
  }>()
);

export const createTaskAttemptSuccess = createAction(
  "[Tasks] CREATE_TASK_ATTEMPT_SUCCESS"
);

export const createTaskAttemptFailed = createAction(
  "[Tasks] CREATE_TASK_ATTEMPT_FAILED"
);

export const getOfficeEmployees = (eaOfficeId: string) => {
  return getOfficeEmployeesRequest({
    params: {
      limit: 200,
      eaOfficeId,
    },
  });
};

export const getOfficeEmployeesRequest = createAction(
  "[Tasks] GET_OFFICE_EMPLOYEES_REQUEST",
  props<{
    params: {
      eaOfficeId: string;
      limit: number;
    };
  }>()
);

export const getOfficeEmployeesSuccess = createAction(
  "[Tasks] GET_OFFICE_EMPLOYEES_SUCCESS",
  props<{ employees: Employee[] }>()
);

export const getOfficeEmployeesFailed = createAction(
  "[Tasks] GET_OFFICE_EMPLOYEES_FAILED"
);

export const createTaskRequest = createAction(
  "[Tasks] CREATE_TASK_REQUEST",
  props<{ params: Partial<Task> }>()
);

export const createTaskSuccess = createAction("[Tasks] CREATE_TASK_SUCCESS");

export const createTaskFailed = createAction("[Tasks] CREATE_TASK_FAILED");

export const sendMessageRequest = createAction(
  "[Tasks] SEND_MESSAGE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const sendMessageSuccess = createAction("[Tasks] SEND_MESSAGE_SUCCESS");

export const sendMessageFailed = createAction("[Tasks] SEND_MESSAGE_FAILED");

export const showManageTab = createAction("[Tasks] SHOW_MANAGE_TAB");

export const reset = createAction("[Tasks] RESET");
