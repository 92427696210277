import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { NpsEntity } from "@app/models/nps";
import { getFeature } from "@app/shared/config/config.reducer";
import { NpsConfig } from "@app/shared/config/models";
import { NPS } from "@app/shared/config/utils/features";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as widgetActions from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-nps-widget/ngrx/statistics-nps-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  debounceTime,
  fromEvent as observableFromEvent,
  map,
  Observable,
  skip,
  Subject,
  takeUntil,
} from "rxjs";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";

@Component({
  selector: "app-statistics-nps-widget",
  templateUrl: "./statistics-nps-widget.component.html",
  styleUrls: ["./statistics-nps-widget.component.scss"],
})
export class StatisticsNpsWidgetComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() quickFilter = "today";
  @Input() triggerDataFetch$: Observable<void>;

  @ViewChild("filterContainer", { static: false })
  filterContainer: ElementRef;

  showSegment: boolean = true;
  statistics$: Observable<NpsEntity>;
  loading$: Observable<boolean>;
  useAverageValue$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();
  filters = [
    { label: "yesterday", value: "yesterday" },
    { label: "today", value: "today" },
    { label: "this_month", value: "currentMonth" },
    { label: "this_year", value: "currentYear" },
  ];
  dropDownFilters: DropdownItem[] = [
    { display: "yesterday", value: "yesterday" },
    { display: "today", value: "today" },
    { display: "this_month", value: "currentMonth" },
    { display: "this_year", value: "currentYear" },
  ];
  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });

    observableFromEvent(window, "resize")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showSegment = !(
          this.filterContainer?.nativeElement?.offsetWidth < 450 &&
          this.dropDownFilters.length > 3
        );
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.filterContainer?.nativeElement?.offsetWidth < 450 &&
        this.dropDownFilters.length > 3
      ) {
        this.showSegment = false;
      }
    }, 200);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId) {
      this.getStatistics();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.statistics$ = this.store.pipe(select(fromWidget.getStats));
    this.useAverageValue$ = this.store.pipe(
      select(getFeature(NPS)),
      map((feature: NpsConfig) => feature.use_average_nps_value)
    );
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
  }

  getStatistics(): void {
    const params = {
      includeAnswerFrequency: false,
      minResponsesWithNpsValue: 0,
      ...this.getDates(),
      ...this.getIds(),
    };

    this.store.dispatch(widgetActions.fetchStatisticsRequest({ params }));
  }

  getIds(): any {
    const params = {};
    if (!!this.eaEmployeeId && this.eaEmployeeId.length > 0) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId && this.eaOfficeId.length > 0) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }

  private getDates() {
    switch (this.quickFilter) {
      case "yesterday":
        return {
          resDateMin: moment()
            .subtract(1, "day")
            .startOf("day")
            .format(API_DATE_FORMAT),
          resDateMax: moment()
            .subtract(1, "day")
            .endOf("day")
            .format(API_DATE_FORMAT),
        };
      case "today":
        return {
          resDateMin: moment().startOf("day").format(API_DATE_FORMAT),
          resDateMax: moment().endOf("day").format(API_DATE_FORMAT),
        };
      case "currentMonth":
        return {
          resDateMin: moment().startOf("month").format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
      case "currentYear":
        return {
          resDateMin: moment().startOf("year").format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
      default:
        return {
          resDateMin: moment().format(API_DATE_FORMAT),
          resDateMax: moment().format(API_DATE_FORMAT),
        };
    }
  }

  changeFilter(filter: string) {
    this.quickFilter = filter;
    this.getStatistics();
  }
}
