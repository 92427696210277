import { FormControl } from "@angular/forms";
import * as libphonenumber from "google-libphonenumber";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

interface PhoneValidatorOptions {
  type: number;
}

export function validatePhoneNumber(
  ISO: string,
  options: PhoneValidatorOptions = { type: null }
) {
  return (c: FormControl) => {
    if (!c.value) {
      return;
    }

    try {
      const phonenumber = phoneUtil.parse(c.value, ISO);
      switch (options.type) {
        case libphonenumber.PhoneNumberType.MOBILE:
          return phoneUtil.isValidNumberForRegion(phonenumber, ISO) &&
            isMobile(phonenumber)
            ? null
            : getError();

        case libphonenumber.PhoneNumberType.FIXED_LINE:
          return phoneUtil.isValidNumberForRegion(phonenumber, ISO) &&
            isFixedLine(phonenumber)
            ? null
            : getError();

        default:
          return phoneUtil.isValidNumberForRegion(phonenumber, ISO)
            ? null
            : getError();
      }
    } catch (e) {
      return getError();
    }
  };
}

export function getPhoneNumberType(number, countryCode): string {
  const phoneNumber = phoneUtil.parse(number, countryCode);
  if (
    phoneUtil.getNumberType(phoneNumber) ===
    libphonenumber.PhoneNumberType.MOBILE
  ) {
    return "MOBILE";
  }

  if (
    phoneUtil.getNumberType(phoneNumber) ===
    libphonenumber.PhoneNumberType.FIXED_LINE
  ) {
    return "FIXED_LINE";
  }

  return "UNKNOWN";
}

function getError() {
  return {
    phone: {
      valid: false,
    },
  };
}

function isMobile(phonenumber) {
  const validTypes = [
    libphonenumber.PhoneNumberType.MOBILE,
    libphonenumber.PhoneNumberType.FIXED_LINE_OR_MOBILE,
  ];
  return validTypes.indexOf(phoneUtil.getNumberType(phonenumber)) !== -1;
}

function isFixedLine(phonenumber) {
  const validTypes = [
    libphonenumber.PhoneNumberType.FIXED_LINE,
    libphonenumber.PhoneNumberType.FIXED_LINE_OR_MOBILE,
  ];
  return validTypes.indexOf(phoneUtil.getNumberType(phonenumber)) !== -1;
}
