import { AppState } from "@app/app.reducer";
import { ExternalPresentableLink } from "@app/integrations/models/ErpSystemLinks";
import { ExternalObjectMetadata } from "@app/integrations/models/ExternalObjectMetadata";
import * as sharedActions from "@app/shared/ngrx/shared.actions";
import { Store } from "@ngrx/store";

export const openExternalLink = (
  link: ExternalPresentableLink,
  store: Store<AppState>
): void => {
  if (link.singleSignOnRewritable) {
    store.dispatch(
      sharedActions.ssoifyLinkAndOpenItRequest({
        params: {
          integration: link.source,
          urlBearer: link.link,
        },
      })
    );
  } else {
    window.open(link.link);
  }
};

export const openObjectMetaDataLink = (
  metaData: ExternalObjectMetadata
): void => {
  window.open(metaData.baseUrlForResource);
};
