import { createAction, props } from "@ngrx/store";
import { AppConfigState } from "@app/shared/config/models";

export const loadRequest = createAction("[Config] LOAD_REQUEST");

export const loadSuccess = createAction(
  "[Config] LOAD_SUCCESS",
  props<{ conf: Record<string, unknown> }>()
);

export const loadFail = createAction(
  "[Config] LOAD_FAIL",
  props<{ error: Record<string, unknown> }>()
);

export const saveRequest = createAction(
  "[Config] SAVE_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const saveSuccess = createAction(
  "[Config] SAVE_SUCCESS",
  props<{ appConfig: AppConfigState }>()
);

export const saveFail = createAction("[Config] SAVE_FAIL");

export const loadConfigIdsRequest = createAction(
  "[Config] LOAD_CONFIG_IDS_REQUEST",
  props<{ filterSettingsGroupName: string }>()
);

export const loadConfigIdsSuccess = createAction(
  "[Config] LOAD_CONFIG_IDS_SUCCESS",
  props<{ payload: Record<string, unknown> }>()
);

export const loadConfigIdsFail = createAction("[Config] LOAD_CONFIG_IDS_FAIL");

export const checkIfSystemOnlineRequest = createAction(
  "[Config] CHECK_IF_SYSTEM_ONLINE_REQUEST"
);

export const checkIfSystemOnlineSuccess = createAction(
  "[Config] CHECK_IF_SYSTEM_ONLINE_SUCCESS"
);

export const checkIfSystemOnlineFail = createAction(
  "[Config] CHECK_IF_SYSTEM_ONLINE_FAIL",
  props<{ error: Record<string, unknown> }>()
);
