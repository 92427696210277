<form [formGroup]="form">
  <ng-container *ngIf="contact.contactType === 'company'; else person">
    <div class="form-group">
      <search-contact
        *ngIf="!selectedContact; else contactCard"
        #search
        [filter]="'freetext'"
        [icon]="'icon icon-magnifier'"
        [placeholder]="'search_contact_free_text_placeholder' | translate"
        (quedroClicked)="onContactClick($event)"
        [focus]="true"
      ></search-contact>
    </div>
    <h4>{{ "is" | translate }}:</h4>
    <div class="form-group">
      <select formControlName="relationshipType">
        <option *ngFor="let type of relationsShipTypeOptions" [value]="type">
          {{ "relation_" + type.toLowerCase() | translate }}
        </option>
      </select>
    </div>
    <p>
      {{ "of_to" | translate }} {{ contact.firstName }} {{ contact.familyName }}
    </p>
  </ng-container>
  <ng-template #person>
    <h4>
      {{ contact.firstName }} {{ contact.familyName }} {{ "is" | translate }}:
    </h4>
    <div class="form-group">
      <select formControlName="relationshipType">
        <option *ngFor="let type of relationsShipTypeOptions" [value]="type">
          {{ "relation_" + type.toLowerCase() | translate }}
        </option>
      </select>
    </div>
    <h4>{{ "of_to" | translate }}:</h4>
    <div class="form-group">
      <search-contact
        *ngIf="!selectedContact; else contactCard"
        #search
        [filter]="'freetext'"
        [icon]="'icon icon-magnifier'"
        [placeholder]="'search_contact_free_text_placeholder' | translate"
        (quedroClicked)="onContactClick($event)"
        [focus]="true"
      ></search-contact>
    </div>
  </ng-template>
  <ng-template #contactCard>
    <card [removable]="!contactRelation" (remove)="clearSelectedContact()">
      <div title>
        {{ selectedContact.firstName }} {{ selectedContact.familyName }}
      </div>
    </card>
  </ng-template>
  <span *ngIf="disabledMessage">{{ disabledMessage | translate }}</span>
  <button
    [disabled]="!form.valid || disabledMessage"
    class="btn btn-success"
    (click)="handleSubmit()"
    translate
  >
    <span *ngIf="!!contactRelation" translate>save</span>
    <span *ngIf="!contactRelation" translate>create</span>
  </button>
</form>
