import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ConfigService } from "@app/core/services/config/config.service";
import { fadeInUp } from "@app/shared/animations";
import { WeatherService } from "@app/shared/modules/weather/weather.service";
import { TranslateService } from "@ngx-translate/core";
import { PopoverDirective } from "ngx-bootstrap/popover";
import { EXCHANGE } from "../../../utils/event-types";
import { EventDTO } from "../models";

@Component({
  selector: "calendar-widget-list-item",
  styleUrls: [
    "../../../../../styles/list-item.component.scss",
    "./list-item.component.scss",
  ],
  templateUrl: "./list-item.component.html",
  animations: [fadeInUp()],
})
export class CalendarWidgetListItemComponent implements OnInit {
  @ViewChild("myPopOver") myPopOver: PopoverDirective;
  @Input() event: EventDTO;
  @Input() eventIndex: number;
  @Input() weatherFeatureEnabled: boolean;
  exchange = EXCHANGE;
  calendarProvider: string;
  weatherInfoText = "";

  constructor(
    private configService: ConfigService,
    private weatherService: WeatherService,
    private translateService: TranslateService
  ) {}

  getIconStyle(event: EventDTO): any {
    return this.weatherService.getIconStyle(event);
  }

  ngOnInit() {
    this.calendarProvider = this.configService.emailProvider;
    this.setWeatherInfoText(this.event);
    if (this.weatherFeatureEnabled) {
      this.initWeatherPopOver();
    }
  }

  initWeatherPopOver() {
    const timeToWait = (this.eventIndex + 1) * 500;
    if (this.event.forecastData && this.weatherInfoText.length > 0) {
      setTimeout(() => {
        this.myPopOver.show();
      }, timeToWait);
      setTimeout(() => {
        this.myPopOver.hide();
      }, timeToWait + 3000);
    }
  }

  getShowingTitle(event: EventDTO): string {
    const showingType = event.showingType;
    let translation = this.translateService.instant("showing");
    if (showingType) {
      translation = this.translateService.instant(showingType);
    }
    return translation;
  }

  setWeatherInfoText(event: EventDTO) {
    if (!event.forecastData) {
      return "";
    }

    const type = event.type.toLowerCase();
    const weatherGroup = this.weatherService
      .getWeatherGroupBySymbol(event)
      .toLowerCase();
    const temperatureSegment = this.weatherService.getTemperatureSegment(event);

    const translations = [];
    let hasMoreTranslations = true;
    let index = 1;
    while (hasMoreTranslations) {
      const translation = this.translateService.instant(
        `${type}_${weatherGroup}_${temperatureSegment}_${index}`
      );

      if (
        translation &&
        translation !== `${type}_${weatherGroup}_${temperatureSegment}_${index}`
      ) {
        translations.push(translation);
        index++;
      } else {
        hasMoreTranslations = false;
      }
    }

    const randomTranslationIndex = Math.floor(
      Math.random() * Math.floor(translations.length)
    );

    if (translations.length > 0) {
      this.weatherInfoText = this.translateService.instant(
        `${type}_${weatherGroup}_${temperatureSegment}_${
          randomTranslationIndex + 1
        }`
      );
    }
  }

  getTemperatureRounded(temperature: string): number {
    return Math.round(parseFloat(temperature));
  }
}
