import { Constructor } from "./mixin";

export function Contactable<T extends Constructor>(Base: T) {
  return class extends Base {
    email;
    msisdn;

    hasEmail() {
      return !!this.email;
    }

    hasMobile() {
      return !!this.msisdn;
    }
  };
}
