import {
  AfterContentInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import * as _ from "lodash";
import { filter, Subject } from "rxjs";

@Directive({
  selector: "[routerLinkParamsActive]",
})
export class RouterLinkParamsActiveDirective
  implements OnChanges, OnDestroy, AfterContentInit
{
  @Input() routerLink: any[] | string;

  private classes: string[] = [];

  private active = false;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private element: ElementRef,
    private renderer: Renderer2
  ) {
    router.events
      .pipe(filter((s) => s instanceof NavigationEnd))
      .subscribe(() => this.update());
  }

  get isActive(): boolean {
    return this.active;
  }

  ngAfterContentInit(): void {
    this.update();
  }

  @Input()
  set routerLinkParamsActive(data: string[] | string) {
    const classes = Array.isArray(data) ? data : data.split(" ");
    this.classes = classes.filter((c) => !!c);
  }

  ngOnChanges(): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private update(): void {
    if (!this.router.navigated) {
      return;
    }
    const hasActiveLinks = this.hasActiveLinks();

    // react only when status has changed to prevent unnecessary dom updates
    if (this.active !== hasActiveLinks) {
      this.classes.forEach((c) => {
        if (hasActiveLinks) {
          this.renderer.addClass(this.element.nativeElement, c);
        } else {
          this.renderer.removeClass(this.element.nativeElement, c);
        }
      });
      Promise.resolve(hasActiveLinks).then((active) => (this.active = active));
    }
  }

  private hasActiveLinks(): boolean {
    return _.isEqual(this.routerLink, this.route.snapshot.params);
  }
}
