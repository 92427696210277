import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BiddingModule } from "@app/integrations/bidding/bidding.module";
import { ContactActionListModule } from "@app/shared/modules/contact-action-list/contact-action-list.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CallingListsModule } from "@app/sidebar/calling-lists/calling-lists.module";
import { ShowingAttendanceEffects } from "@app/sidebar/potential-buyer/ngrx/attendance.ngrx";
import { BiddingEffects } from "@app/sidebar/potential-buyer/ngrx/bidding.ngrx";
import { ContactProfileEffects } from "@app/sidebar/potential-buyer/ngrx/contact-profile.ngrx";
import { ContactedStatusEffects } from "@app/sidebar/potential-buyer/ngrx/contacted-status.ngrx";
import { NoteEffects } from "@app/sidebar/potential-buyer/ngrx/note.ngrx";
import { PotentialBuyerEffects } from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import { PrivateShowingEffects } from "@app/sidebar/potential-buyer/ngrx/showing.ngrx";
import { ContactedStatusResolver } from "@app/sidebar/potential-buyer/services/contacted-status-resolver.service";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { EffectsModule } from "@ngrx/effects";
import { TranslateModule } from "@ngx-translate/core";
import { AlertModule } from "ngx-bootstrap/alert";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BiddingStatusComponent } from "./components/bidding-status/bidding-status.component";
import { ContactedStatusComponent } from "./components/contacted-status/contacted-status.component";
import { CreateNoteComponent } from "./components/create-note/create-note.component";
import { LoanCommitmentComponent } from "./components/loan-commitment/loan-commitment.component";
import { OwnsResidenceComponent } from "./components/owns-residence/owns-residence.component";
import { ShowingAttendanceComponent } from "./components/showing-attendance/showing-attendance.component";
import { PotentialBuyerManageMultipleComponent } from "./containers/potential-buyer-manage-multiple/potential-buyer-manage-multiple.component";
import { PotentialBuyerManageSingleComponent } from "./containers/potential-buyer-manage-single/potential-buyer-manage-single.component";
import { PotentialBuyerGuard } from "./potential-buyer.guard";
import { ContactedStatusFromLastContactsPipe } from "./util/contacted-status-from-last-contacts.pipe";

@NgModule({
  imports: [
    EffectsModule.forFeature([
      ShowingAttendanceEffects,
      NoteEffects,
      ContactedStatusEffects,
      ContactProfileEffects,
      BiddingEffects,
      PotentialBuyerEffects,
      PrivateShowingEffects,
    ]),
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    AlertModule,
    SidebarSharedModule,
    SkeletonScreenModule,
    UiComponentsModule,
    BiddingModule,
    TooltipModule,
    ContactActionListModule,
    FormComponentsModule,
    CallingListsModule,
    ModalModule,
    MatExpansionModule,
    MatTooltipModule,
    UtilModule,
  ],
  providers: [PotentialBuyerGuard, ContactedStatusResolver],
  declarations: [
    PotentialBuyerManageSingleComponent,
    PotentialBuyerManageMultipleComponent,
    ShowingAttendanceComponent,
    CreateNoteComponent,
    ContactedStatusComponent,
    LoanCommitmentComponent,
    OwnsResidenceComponent,
    BiddingStatusComponent,
    ContactedStatusFromLastContactsPipe,
  ],
  exports: [ShowingAttendanceComponent, CreateNoteComponent],
})
export class PotentialBuyerModule {}
