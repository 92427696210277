import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { LocaleService } from "@app/core/services/locale/locale.service";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "language-switch",
  template: `
    <div class="languageSwitch">
      <h3 translate>change_language</h3>
      <app-q-language-dropdown
        [defaultValue]="chosenLanguage"
        (selectedLanguageChanged)="setLanguage($event.key)"
      ></app-q-language-dropdown>
    </div>
  `,
  styleUrls: ["./language-switch.component.scss"],
})
export class LanguageSwitchComponent implements OnInit {
  chosenLanguage = "";
  crmLanguage = "";
  currentLanguage$: Observable<string>;

  constructor(
    private localStorageService: LocalStorageService,
    private localeService: LocaleService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.crmLanguage = this.localStorageService.fetchUserPreference("language");
    this.currentLanguage$ = this.store.select(
      (state: AppState) => state.config.app.general.language
    );
    this.currentLanguage$.subscribe((lang) => {
      if (this.crmLanguage && this.crmLanguage.length > 0) {
        this.setLanguage(this.crmLanguage);
        this.chosenLanguage = this.crmLanguage;
      } else {
        this.chosenLanguage = lang;
      }
    });
  }

  setLanguage(locale: string) {
    this.localeService.setLocale(locale);
    this.chosenLanguage = locale;
    this.localStorageService.saveUserPreference("language", locale);

    if (this.crmLanguage !== locale) {
      location.reload();
    }
  }
}
