import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CallingListOverviewComponent } from "./calling-list-overview.component";

const routes: Routes = [
  {
    path: ":callingListId",
    component: CallingListOverviewComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class CallingListsRoutingModule {}
