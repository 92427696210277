import { Contact, TaskType } from "@app/models";
import { Showing } from "@app/showings/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as createContactActions from "./create-contact.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface CreateContactState {
  loading: boolean;
  sources: TaskType[];
  prefillData: Partial<Contact>;
  showings: Showing[];
  showingsLoading: boolean;
  addToShowingLoading: boolean;
}

export const initialState: CreateContactState = {
  loading: false,
  sources: [],
  prefillData: {},
  showings: [],
  showingsLoading: false,
  addToShowingLoading: false,
};

export function createContactReducer(
  state: CreateContactState,
  action: Action
): CreateContactState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(createContactActions.getContactSourceSuccess, (state, { taskTypes }) => ({
    ...state,
    sources: taskTypes,
  })),
  on(
    createContactActions.createContactSuccess,
    createContactActions.createContactFail,
    (state) => ({
      ...state,
      loading: false,
    })
  ),
  on(createContactActions.createContactRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(createContactActions.setPrefillData, (state, { prefillData }) => ({
    ...state,
    prefillData,
  })),
  on(createContactActions.clearPrefillData, (state) => ({
    ...state,
    prefillData: {},
  })),
  on(
    createContactActions.updateShowingAttendanceRequest,
    createContactActions.updateShowingAttendanceSuccess,
    createContactActions.updateShowingAttendanceRequest,
    createContactActions.addContactToShowingRequest,
    (state) => ({ ...state, addToShowingLoading: true })
  ),
  on(
    createContactActions.addContactToShowingFail,
    createContactActions.addContactToShowingSuccess,
    (state) => ({ ...state, loading: false, addToShowingLoading: false })
  ),
  on(createContactActions.getShowingsRequest, (state) => ({
    ...state,
    showingsLoading: true,
  })),
  on(createContactActions.getShowingsSuccess, (state, { showings }) => ({
    ...state,
    showingsLoading: false,
    showings,
  })),
  on(createContactActions.getShowingsFail, (state) => ({
    ...state,
    showingsLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.createContact
);

export const getSources = createSelector(
  getState,
  (state: CreateContactState) => state.sources
);

export const getLoading = createSelector(
  getState,
  (state: CreateContactState) => state.loading
);

export const getPrefillData = createSelector(
  getState,
  (state: CreateContactState) => state.prefillData
);

export const getShowings = createSelector(
  getState,
  (state: CreateContactState) => state.showings
);

export const getAddToShowingLoading = createSelector(
  getState,
  (state: CreateContactState) => state.addToShowingLoading
);
