<div
  #overlay
  *ngIf="showOverlay$ | async"
  class="overlay"
  (scroll)="onScroll()"
>
  <div class="header">
    <search
      (keywordChange)="onKeywordChange($event)"
      [overlayActive]="true"
      [autofocus]="true"
      [value]="latestSearchKeyword"
      [placeholder]="'search_placeholder' | translate"
    >
    </search>
    <button type="button" (click)="close()" class="overlay-close btn-close">
      <app-icon name="times" size="large"></app-icon>
    </button>
  </div>
  <search-results (latestSearchClicked)="onLatestSearchClicked($event)">
  </search-results>
  <scroll-top *ngIf="hasScrolled" [parent]="overlay"> </scroll-top>
</div>
