<div class="wrapper wrapper--extra-footer-space" [formGroup]="form">
  <div class="scroll-view">
    <section>
      <h4>{{ "chosen_contact" | translate }}</h4>
      <contact-card
        *ngIf="selectedContact$ | async"
        [name]="(selectedContact$ | async)?.getFullName()"
        [address]="(selectedContact$ | async)?.street"
        [number]="
          formatNumber(
            (selectedContact$ | async)?.msisdn,
            (selectedContact$ | async)?.msisdnCountry
          )
        "
        [email]="(selectedContact$ | async)?.email"
        [deletable]="true"
        (delete)="goBack()"
      ></contact-card>
    </section>
    <hr />
    <section>
      <h4>{{ "tips_title" | translate }}</h4>
      <div class="task-title">
        <input
          [ngClass]="
            !form.get('title').valid && form.get('title').touched
              ? 'validation-input'
              : ''
          "
          type="text"
          formControlName="title"
        />
      </div>
      <div ngxErrors="title">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </section>
    <hr />
    <section>
      <h4>{{ "sender" | translate }}</h4>
      <office-employee-dropdown
        [parentFormGroup]="form.get('sender')"
        parentFormGroupName="sender"
        (onReady)="senderOnReady()"
      ></office-employee-dropdown>
      <br />

      <h4>{{ "recipient" | translate }}</h4>
      <all-office-employee-dropdown
        [parentFormGroup]="form.get('recipient')"
        parentFormGroupName="recipient"
        [disableSelectBrokerOption]="false"
        (employeesChange)="recipientEmployeesChanged()"
      ></all-office-employee-dropdown>
      <br />
      <h4>{{ "comment" | translate }}</h4>
      <textarea
        [ngClass]="
          !(processing$ | async) &&
          !form.get('comment').valid &&
          form.get('comment').touched
            ? 'validation-input'
            : ''
        "
        formControlName="comment"
      ></textarea>
      <span
        *ngIf="
          !(processing$ | async) &&
          !form.get('comment').valid &&
          form.get('comment').touched
        "
        class="validation-message"
        >{{ "validation_required" | translate }}</span
      >
      <small class="text-muted">{{
        "comment_will_connect_to_lead" | translate
      }}</small>
    </section>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="send"
      (click)="send(form.value)"
      [disabled]="processing$ | async"
      [isLoading]="processing$ | async"
      scrollToInvalidControl
      [formEl]="form"
    >
    </app-q-button>
  </div>
</div>
