import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as fromSendPotentialBuyers from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.reducer";
import { IntegrationResource } from "@app/integrations/models/enums";
import * as fromIntegration from "@app/integrations/ngrx/integrations.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { BIDDING, EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import { PotentialBuyer } from "@app/showings/models";
import { select, Store } from "@ngrx/store";
import { first, map, Observable, of, shareReplay, switchMap } from "rxjs";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import {
  reset,
  sendPotentialBuyersToERPRequest,
} from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.action";

@Injectable({
  providedIn: "root",
})
export class SendPotentialBuyersService {
  private _externalProviderName$: Observable<string>;

  private _biddingEnabled$: Observable<boolean>;
  private _hasIntegration$: Observable<boolean>;
  hasIntegration$: Observable<boolean>;

  constructor(private store: Store<AppState>) {
    this.mapStateToProps();
  }

  sendPotentialBuyersToERP(params: {
    eaOid: string;
    potentialBuyers: PotentialBuyer[];
  }) {
    this.store.dispatch(reset());

    this._externalProviderName$
      .pipe(first())
      .subscribe((externalProviderName) => {
        this.store.dispatch(
          sendPotentialBuyersToERPRequest({
            ...params,
            externalProviderName,
          })
        );
      });
  }

  getSendingProgress(): Observable<number> {
    return this.store.pipe(select(fromSendPotentialBuyers.getProgress));
  }

  isSending(): Observable<boolean> {
    return this.store.pipe(select(fromSendPotentialBuyers.getSending));
  }

  getErrors(): Observable<string[]> {
    return this.store.pipe(select(fromSendPotentialBuyers.getErrors));
  }

  getIsDone(): Observable<boolean> {
    return this.store.pipe(select(fromSendPotentialBuyers.getIsDone));
  }

  reset(): void {
    this.store.dispatch(reset());
  }

  private mapStateToProps() {
    this._biddingEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(BIDDING)),
      map((config: Feature) => config.enabled)
    );

    this._hasIntegration$ = this.store.pipe(
      select(
        fromIntegration.hasIntegration(IntegrationResource.SetSpeculatorStatus)
      )
    );

    this.hasIntegration$ = this._biddingEnabled$.pipe(
      switchMap((enabled) => (enabled ? this._hasIntegration$ : of(false))),
      shareReplay(1)
    );

    this._externalProviderName$ = this.store.pipe(
      select(fromConfig.getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name)
    );
  }
}
