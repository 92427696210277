import { EmployeeDTO, Employment } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getEmployeeRequest = createAction(
  "Sidebar Employees Office Create GET_EMPLOYEE_REQUEST",
  props<{ eaEmployeeId: string }>()
);

export const getEmployeeSuccess = createAction(
  "Sidebar Employees Office Create GET_EMPLOYEE_SUCCESS",
  props<{ employee: EmployeeDTO }>()
);

export const getEmployeeFailed = createAction(
  "Sidebar Employees Office Create GET_EMPLOYEE_FAILED"
);

export const getEmploymentsRequest = createAction(
  "Sidebar Employees Office Create GET_EMPLOYMENTS_REQUEST",
  props<{ eaEmployeeId: string }>()
);

export const getEmploymentsSuccess = createAction(
  "Sidebar Employees Office Create GET_EMPLOYMENTS_SUCCESS",
  props<{ employments: Employment[] }>()
);

export const getEmploymentsFailed = createAction(
  "Sidebar Employees Office Create GET_EMPLOYMENTS_FAILED"
);

export const createEmploymentRequest = createAction(
  "Sidebar Employees Office Create CREATE_EMPLOYMENT_REQUEST",
  props<{ employment: Employment }>()
);

export const createEmploymentSuccess = createAction(
  "Sidebar Employees Office Create CREATE_EMPLOYMENT_SUCCESS",
  props<{ employment: Employment }>()
);

export const createEmploymentFailed = createAction(
  "Sidebar Employees Office Create CREATE_EMPLOYMENT_FAILED"
);

export const reset = createAction("Sidebar Employees Office Create RESET");
