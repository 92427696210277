import { Directive, ElementRef } from "@angular/core";

const keys = ["Tab", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"];

@Directive({
  selector: "[appMenuPanel]",
})
export class MenuPanelDirective {
  constructor(el: ElementRef) {
    el.nativeElement.addEventListener(
      "keydown",
      this.stopPropagationOnDefinedKeys
    );
    el.nativeElement.addEventListener("click", this.stopPropagation);
  }

  private stopPropagationOnDefinedKeys = (event: KeyboardEvent) => {
    if (keys.includes(event.key)) {
      this.stopPropagation(event);
    }
  };

  private stopPropagation = (event: Event) => {
    event.stopImmediatePropagation();
  };
}
