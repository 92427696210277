<app-icon
  *ngIf="(translationKey | translate) !== translationKey; else untranslated"
  name="question-circle"
  size="none"
  [matTooltip]="translationKey | translate"
  matTooltipClass="q-tooltip"
>
</app-icon>

<ng-template #untranslated>
  <span
    (click)="selectAndCopy($event, translationKey)"
    *ngIf="displayUntranslatedTooltip$ | async"
    class="untranslated"
  >
    <app-icon
      name="question-circle"
      size="none"
      [matTooltip]="'Untranslated key: ' + translationKey + '. Click to copy.'"
      matTooltipClass="q-tooltip"
    >
    </app-icon>
  </span>
</ng-template>
