import { SalesMeetingGuard } from "../guards/sales-meeting-guard.service";
import { SalesMeetingEditGuardService } from "./guards/sales-meeting-edit-guard.service";
import { SalesMeetingContactResolver } from "./resolvers/sales-meeting-contact-resolver.service";
import { SalesMeetingResolver } from "./resolvers/sales-meeting-resolver.service";
import { SalesMeetingCreateContactComponent } from "./sales-meeting-create-contact/sales-meeting-create-contact.component";
import { SalesMeetingCreateComponent } from "./sales-meeting-create/sales-meeting-create.component";
import { SalesMeetingEditComponent } from "./sales-meeting-edit/sales-meeting-edit.component";
import { SalesMeetingSearchContactComponent } from "./sales-meeting-search-contact/sales-meeting-search-contact.component";

export const sidebarSalesMeetingsRoutes = {
  path: "sales-meetings",
  children: [
    {
      path: "",
      component: SalesMeetingSearchContactComponent,
      canActivate: [SalesMeetingGuard],
    },
    {
      path: "contacts/new",
      component: SalesMeetingCreateContactComponent,
      canActivate: [SalesMeetingGuard],
    },
  ],
};

export const sidebarContactsSalesMeetingsRoutes = {
  path: ":contactId/sales-meetings",
  children: [
    {
      path: "new",
      component: SalesMeetingCreateComponent,
      canActivate: [SalesMeetingGuard],
      resolve: {
        contact: SalesMeetingContactResolver,
      },
    },
    {
      path: ":smId/edit",
      component: SalesMeetingEditComponent,
      canActivate: [SalesMeetingEditGuardService, SalesMeetingGuard],
      resolve: {
        contact: SalesMeetingContactResolver,
        data: SalesMeetingResolver,
      },
    },
  ],
};
