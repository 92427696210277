import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import {
  getIsExternalTipsSupportedConfigured,
  hasContactSearchCapability,
} from "@app/integrations/ngrx/integrations.reducer";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, Observable, take } from "rxjs";

const CAPABILITIES = {
  RESULT_ID_LOOKUP: "RESULT_ID_LOOKUP",
  CONTACT_SEARCH: "CONTACT_SEARCH",
};

interface CustomerSetting {
  emailProvider: string;
  timeZone: string;
}

export interface ExternalTipsStatisticsIntegration {
  hasExternalTipsStatistics: boolean;
  integration: string;
}

@Injectable()
export class ConfigService {
  // Eniro osv
  searchIntegrationActive = false;
  hasCheckedSearchIntegration = false;

  // Exchange, Gsuite osv
  emailProvider = "";
  hasCheckedEmailProvider = false;
  emailProvider$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  hasCheckedEmailProvider$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  // Tips-integration
  externalTipsStatisticsIntegration$: BehaviorSubject<ExternalTipsStatisticsIntegration> =
    new BehaviorSubject<ExternalTipsStatisticsIntegration>({
      hasExternalTipsStatistics: false,
      integration: "",
    });
  hasCheckedExternalTipsStatisticsIntegration = false;
  hasTipsIntegration$: Observable<boolean>;

  constructor(private apiService: ApiService, private store: Store<AppState>) {
    this.hasTipsIntegration$ = this.store.pipe(
      select(getIsExternalTipsSupportedConfigured())
    );
  }

  verifySearchIntegration() {
    if (!this.hasCheckedSearchIntegration) {
      this.store
        .select(hasContactSearchCapability(CAPABILITIES.CONTACT_SEARCH))
        .subscribe((capability) => {
          this.searchIntegrationActive = capability;
          this.hasCheckedSearchIntegration = true;
        });
    }
  }

  verifyExternalTipsStatisticsIntegration() {
    this.hasTipsIntegration$.subscribe((hasIntegration) => {
      if (!this.hasCheckedExternalTipsStatisticsIntegration && hasIntegration) {
        this.apiService
          .get(
            "external-tips/integration-statistics-capabilities",
            {},
            "integrations"
          )
          .pipe(take(1))
          .subscribe((response: ExternalTipsStatisticsIntegration) => {
            this.hasCheckedExternalTipsStatisticsIntegration = true;
            this.externalTipsStatisticsIntegration$.next({
              hasExternalTipsStatistics: response.hasExternalTipsStatistics,
              integration: response.integration,
            });
          });
      }
    });
  }

  verifyEmailProviderIntegration() {
    if (!this.hasCheckedEmailProvider) {
      this.apiService
        .get("customer/settings", {})
        .pipe(take(1))
        .subscribe((setting: CustomerSetting) => {
          this.emailProvider = setting.emailProvider;
          this.emailProvider$.next(setting.emailProvider);
          this.hasCheckedEmailProvider$.next(true);
          this.hasCheckedEmailProvider = true;
        });
    }
  }

  isSearchIntegrationsActive() {
    return this.searchIntegrationActive;
  }
}
