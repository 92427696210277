export const CONTACT_CREATE = "contact_create";
export const CONTACT_CREATE_POTENTIAL_BUYER = "contact_create_potential_buyer";
export const CONTACT_CREATE_SELLER_BUYER = "contact_create_seller_buyer";
export const CONTACT_EDIT = "contact_edit";
export const CREATE_FOLLOW_UP = "create_follow_up";
export const SEND_TIPS = "send_tips";
export const CREATE_LEAD = "create_lead";
export const RESIDENCE = "residence";
export const TASK = "task";
export const SALES_MEETING = "sales_meeting";
export const EMPLOYEES = "employees";
export const SEND_MESSAGE = "send_message";
export const EXTERNAL_TIPS_CREATE = "external_tips_create";
export const EXTERNAL_TIPS_UPDATE = "external_tips_update";
export const SEND_EXTERNAL_TIPS = "send_external_tips";
export const CREATE_COMPETITION = "create_competition";
export const EDIT_COMPETITION = "edit_competition";
export const CALLING_LIST_CREATE = "calling_list_create";
export const MANAGE_POTENTIAL_BUYER_SINGLE = "manage_potential_buyer_single";
export const MANAGE_POTENTIAL_BUYER_MULTIPLE =
  "manage_potential_buyer_multiple";
export const CREATE_IN_EXTERNAL_PROVIDER = "create_in_external_provider";
export const CONNECT_IN_EXTERNAL_PROVIDER = "connect_in_external_provider";
export const SEARCH_PROFILE_CREATE = "search_profile_create";
export const SEARCH_PROFILE_EDIT = "search_profile_edit";
export const SEARCH_PROFILE_ALERT_CREATE = "search_profile_alert_create";
export const SEARCH_PROFILE_ALERT_EDIT = "search_profile_alert_edit";
export const SUPPORT = "support";
export const EIKA_SEND_TIPS = "eika_send_tips";
export const CREATE_ERP_SHOWING = "create_erp_showing";
export const CREATE_ERP_EMPLOYEE = "create_erp_employee";
export const CREATE_ERP_OFFICE = "create_erp_office";
export const CREATE_OBJECT = "create_object";
export const MESSAGE_TEMPLATE = "message_template";
export const LEAD_CHANNELS = "lead_channels";
export const LEAD_TEMPLATES = "lead_templates";
export const LEAD_RESULT_OPTIONS = "lead_result_options";
export const VALUE_MONITOR = "value_monitor";
export const ASSISTANT_PERMISSIONS = "assistant_permissions";
