<h3 *ngIf="showHeader">
  <app-icon name="rectangle-ad" size="large"></app-icon>&nbsp;<span translate
    >marketing_reports_summary</span
  >
  <span class="icon-tooltip"
    ><app-tooltip-icon
      [translationKey]="'marketing_reports_widget_tooltip'"
    ></app-tooltip-icon
  ></span>
</h3>

<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="widget-container__filters" #filterContainer>
    <app-segmented-controls
      *ngIf="showSegment"
      [truncate]="true"
      [controls]="dataTypeControls"
      [active]="form.get('dataTypes').value"
      (change)="form.get('dataTypes').setValue($event)"
    ></app-segmented-controls>

    <app-dropdown-element
      *ngIf="!showSegment"
      [items]="typesControl"
      [preselectValue]="form.get('dataTypes').value"
      [multiple]="false"
      [newStyle]="true"
      [required]="true"
      [path]="['typeId']"
      width="100%"
      (valueEntered)="form.get('dataTypes').setValue($event)"
    >
    </app-dropdown-element>
  </div>
  <div
    class="impact-box"
    [class.impact-box--single]="values$.value.length === 1"
    [ngClass]="'impact-box--' + i"
    *ngFor="let data of values$ | async | slice : 0 : 4; let i = index"
  >
    <h4>{{ data.columnName }}</h4>
    <div class="impact-box--impact">
      <span class="percentage" [ngClass]="data.impact > 0 ? 'green' : 'red'"
        >{{ data.impact > 0 ? "+" : ""
        }}{{ data.impact * 100 | qNumber }} %</span
      >
      <ng-container *ngIf="data.numberOfObjects < 100">
        <span translate>insufficient_data</span>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="values$.value.length === 0">
    <span class="no-data" translate>no_data_found</span>
  </ng-container>
</div>
