import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { Action } from "@app/models";
import { SHOWINGS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-object-email",
  template: `
    <div [innerHTML]="text | async" class=""></div>
    <div>
      <small *ngIf="item.param1?.length > 0" class="text-muted">
        <strong>{{ "message" | translate }}: </strong>{{ item.param1 }}
      </small>
    </div>
    <div>
      <small *ngIf="item.street?.length > 0 && item.eaOid?.length > 0">
        <strong>{{ "object" | translate }}: </strong
        ><a [routerLink]="['/crm', showingsCrmModule, item.eaOid]">{{
          item.street
        }}</a>
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionObjectEmailComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerName: Function;
  showingsCrmModule = SHOWINGS_CRM_MODULE;

  className = "action-object-email-name";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap((youString: string) =>
        this.translateService.get("action_object_email_text", {
          you: youString,
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
