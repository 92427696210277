<div class="wrapper">
  <sidebar-header [type]="'send_external_tips'" [label]="'send_external_tips' | translate"></sidebar-header>
  <section>
    <h4 class="name">{{ "search_contact" | translate }}</h4>
    <search-contact
      [filter]="'freetext'"
      [icon]="'icon icon-magnifier'"
      [placeholder]="'search_contact_free_text_placeholder' | translate"
      (quedroClicked)="onClick($event)"
      [focus]="true"
    >
    </search-contact>
  </section>
</div>
