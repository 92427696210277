import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Office } from "@app/models";
import { ExternalTip } from "@app/models/external-tips";
import { ALL_OFFICES } from "@app/settings/external-tips/utils/manage-external-tips-constants";
import { validateEmail } from "@app/shared/modules/form-components/directives/email-validator.directive";
import * as formUtils from "@app/shared/utils/form-utils";
import {
  CREATE_MODE,
  EDIT_MODE,
} from "@app/sidebar/external-tips/utils/external-tips-form-modes";
import { map, PartialObserver, startWith, Subject, takeUntil } from "rxjs";

@Component({
  selector: "external-tips-form",
  templateUrl: "./external-tips-form.component.html",
  styleUrls: ["./external-tips-form.component.scss"],
})
export class ExternalTipsFormComponent implements OnInit, OnDestroy {
  @Input() mode: string;
  @Input() externalTip: ExternalTip;
  @Input() canEditOffice: boolean;
  @Input() userOfficeId: string;
  @Input() offices: Office[];
  @Input() observer: PartialObserver<any>;

  @Output() closeTab: EventEmitter<void> = new EventEmitter<void>();
  @Output() submitForm: EventEmitter<void> = new EventEmitter<void>();

  externalTipsForm: FormGroup;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private fb: FormBuilder) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.fillForm();
    this.registerObserverToFormValueChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): void {
    this.externalTipsForm = this.fb.group({
      title: ["", Validators.required],
      email: ["", [Validators.required, validateEmail()]],
      office: ["", Validators.required],
    });
  }

  fillForm(): void {
    if (this.mode === EDIT_MODE) {
      this.externalTipsForm.get("title").setValue(this.externalTip.name);
      this.externalTipsForm.get("email").setValue(this.externalTip.email);
      this.externalTipsForm
        .get("office")
        .setValue(
          this.externalTip.eaOfficeId
            ? this.externalTip.eaOfficeId
            : ALL_OFFICES
        );
    } else if (this.mode === CREATE_MODE) {
      this.externalTipsForm.get("office").setValue(this.userOfficeId);
    }
    if (!this.canEditOffice) {
      this.externalTipsForm.get("office").disable();
    }
  }

  submit(): void {
    if (this.externalTipsForm.valid) {
      this.submitForm.emit();
    } else {
      formUtils.markAllAsTouched(this.externalTipsForm);
    }
  }

  registerObserverToFormValueChanges(): void {
    this.externalTipsForm.valueChanges
      .pipe(
        map(() => this.externalTipsForm.getRawValue()),
        startWith(this.externalTipsForm.getRawValue()),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(this.observer);
  }
}
