import { TaskListConfig } from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import { createAction, props } from "@ngrx/store";

export interface FreeLeadsRequestObject {
  eaOfficeId?: string;
  taskNotConnectedToEmployee?: string;
  taskTypeCategory?: string;
  resolved?: string;
  sortBy?: string;
  sortOrder?: string;
  limit?: string | number;
  offset?: string | number;
}

export const getFreeLeads = (params: Record<string, unknown>) => {
  const defaultFilters = {
    taskTypeCategory: "lead,task",
    resolved: "false",
    sortBy: "insertedDate",
    sortOrder: "desc",
    limit: 10,
  };
  return getFreeLeadsRequest({
    filters: {
      ...defaultFilters,
      ...params,
    },
  });
};

export const getFreeLeadsRequest = createAction(
  "[FreeLeadsList] GET_FREE_LEADS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getFreeLeadsSuccessWithDefault = (
  tasks: Task[],
  listStart: number,
  totalRows: number,
  replace?: boolean
) => {
  return getFreeLeadsSuccess({
    tasks,
    listStart,
    totalRows,
    replace: replace ?? false,
  });
};

export const getFreeLeadsSuccess = createAction(
  "[FreeLeadsList] GET_FREE_LEADS_SUCCESS",
  props<{
    tasks: Task[];
    listStart: number;
    totalRows: number;
    replace?: boolean;
  }>()
);

export const getFreeLeadsFail = createAction(
  "[FreeLeadsList] GET_FREE_LEADS_FAIL"
);

export const getFreeLeadsNoTasksFound = createAction(
  "[FreeLeadsList] GET_FREE_LEADS_NO_TASKS_FOUND"
);

export const setListConfig = createAction(
  "[FreeLeadsList] SET_LIST_CONFIG",
  props<{ payload: Partial<TaskListConfig> }>()
);

export const reset = createAction("[FreeLeadsList] RESET");
