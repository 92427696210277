import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

export type SegmentControls =
  | {
      label: string;
      value: any;
      total?: number;
      icon?: string;
      disabled?: boolean;
      tooltip?: string;
    }[]
  | string[];

@Component({
  selector: "app-segmented-controls",
  templateUrl: "./segmented-controls.component.html",
  styleUrls: ["./segmented-controls.component.scss"],
})
export class SegmentedControlsComponent implements AfterViewInit {
  @Input() controls: SegmentControls;
  @Input() active: any;
  @Input() truncate = true;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change = new EventEmitter<any>();

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  setActive(val: number) {
    this.change.emit(val);
  }

  getLabel(control: any): string {
    return control.label ? control.label : control;
  }

  getValue(control): any {
    return control.value == null ? control : control.value;
  }

  enableTooltip(index, elementRef) {
    const element = elementRef.children[index];
    return element.offsetWidth >= element.scrollWidth;
  }
}
