import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DateService } from "@app/core/services/date/date.service";
import { UrlService } from "@app/core/services/url/url.service";
import { Task } from "@app/models";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";

@Component({
  selector: "sidebar-tasks-detail-card",
  templateUrl: "./tasks-detail-card.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./tasks-detail-card.component.scss",
  ],
})
export class TasksDetailCardComponent {
  @Input() task: Task;

  constructor(
    public dus: DateService,
    private urlService: UrlService,
    private router: Router
  ) {}

  navigateToObject(e) {
    e.stopPropagation();
    this.router.navigate([
      "/crm",
      {
        outlets: {
          primary: ["showings", this.task.object.eaOid],
          sidebar: null,
        },
      },
    ]);
  }

  openObjectInNewTab(e, eaOid): void {
    e.stopPropagation();
    const commands = ["/crm", "showings", eaOid];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }
}
