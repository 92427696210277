import { Injectable } from "@angular/core";
import { SubPageConfig } from "@app/shared/config/models";
import { RouteAutoResolver } from "@app/shared/utils/route-auto-resolver";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, map, withLatestFrom } from "rxjs";
import { AppState } from "../app.reducer";
import { SubPage } from "../shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { getFeature, getSubPages } from "../shared/config/config.reducer";
import {
  CALLING_LISTS,
  CONTACTS,
  LEADS,
  OUTGOING_MESSAGES,
  SALES_MEETINGS,
  SEARCH_PROFILES,
  SENT_LEADS,
  SHOWINGS,
  SURVEYS,
  TASKS,
  VALUE_MONITOR,
} from "../shared/config/utils/sub-pages";
import { LISTS_CRM_MODULE } from "../shared/utils/crm-modules";
import {
  ICON_CALLING_LISTS,
  ICON_CONTACTS,
  ICON_LEADS,
  ICON_OUTOING_MESSAGES,
  ICON_SALES_MEETING,
  ICON_SEARCH_PROFILE,
  ICON_SHOWINGS,
  ICON_SURVEYS,
  ICON_TASKS,
  ICON_TIPS,
  ICON_VALUE_MONITOR,
} from "../shared/utils/icons";

@Injectable()
export class ListsSubPageService {
  private _subPages$ = new BehaviorSubject([]);

  get subPages$() {
    return this._subPages$.asObservable();
  }

  constructor(
    private store: Store<AppState>,
    private routeAutoResolver: RouteAutoResolver
  ) {}

  loadSubPages(): void {
    this.store
      .pipe(
        select(getSubPages(LISTS_CRM_MODULE)),
        first(),
        withLatestFrom(this.store.pipe(select(getFeature(VALUE_MONITOR)))),
        map(([subPages, vmFeature]) =>
          subPages.filter((p) => {
            if (p.type === VALUE_MONITOR) {
              return p.enabled && vmFeature.enabled;
            } else {
              return p.enabled;
            }
          })
        ),
        map((subPages: SubPageConfig[]) =>
          subPages.map((p) => this.getSubPage(p.type))
        )
      )
      .subscribe((pages) => {
        this._subPages$.next(pages);
        this.routeAutoResolver.autoResolve(pages);
      });
  }

  private getSubPage(type: string): SubPage {
    switch (type) {
      case SALES_MEETINGS: {
        return {
          label: "sales_meetings",
          routerLink: this.getRoute("sales-meetings"),
          icon: ICON_SALES_MEETING,
        };
      }
      case TASKS: {
        return {
          label: "tasks",
          routerLink: this.getRoute("tasks"),
          icon: ICON_TASKS,
        };
      }
      case LEADS: {
        return {
          label: "leads",
          routerLink: this.getRoute("leads"),
          icon: ICON_LEADS,
        };
      }
      case SENT_LEADS: {
        return {
          label: "sent_tips",
          routerLink: this.getRoute("sent-leads"),
          icon: ICON_TIPS,
        };
      }
      case CONTACTS: {
        return {
          label: "contacts",
          routerLink: this.getRoute("contacts"),
          icon: ICON_CONTACTS,
        };
      }
      case SHOWINGS: {
        return {
          label: "showings",
          routerLink: this.getRoute("showings"),
          icon: ICON_SHOWINGS,
        };
      }
      case CALLING_LISTS: {
        return {
          label: "calling_lists",
          routerLink: this.getRoute("calling-lists"),
          icon: ICON_CALLING_LISTS,
        };
      }
      case SURVEYS: {
        return {
          label: "surveys",
          routerLink: this.getRoute("surveys"),
          icon: ICON_SURVEYS,
        };
      }
      case OUTGOING_MESSAGES: {
        return {
          label: "mailing",
          routerLink: this.getRoute("outgoing-messages"),
          icon: ICON_OUTOING_MESSAGES,
        };
      }
      case SEARCH_PROFILES: {
        return {
          label: "search_profiles",
          routerLink: this.getRoute("search-profiles"),
          icon: ICON_SEARCH_PROFILE,
        };
      }
      case VALUE_MONITOR: {
        return {
          label: "value_monitor",
          routerLink: this.getRoute("value-monitor"),
          icon: ICON_VALUE_MONITOR,
        };
      }
    }
    return {
      label: "?",
      routerLink: ["/crm"],
      icon: "fa-question",
    };
  }

  private getRoute(subRoute: string): any[] {
    return ["/crm", LISTS_CRM_MODULE, subRoute];
  }
}
