import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { ContactEffects } from "@app/contacts/contact.effects";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { AddContactToCallingListModule } from "@app/shared/modules/add-contact-to-calling-list/add-contact-to-calling-list.module";
import { ContactNameComponent } from "@app/shared/modules/contact-components/contact-name/contact-name.component";
import { ContactQuickActionsComponent } from "@app/shared/modules/contact-components/contact-quick-actions/contact-quick-actions.component";
import { ContactSummaryConsentsComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-consents/contact-summary-consents.component";
import { ContactSummaryDialogComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-dialog/contact-summary-dialog.component";
import { ContactSummaryInfoComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-info/contact-summary-info.component";
import { ContactSummaryNotesComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-notes/contact-summary-notes.component";
import { ContactSummaryServicesComponent } from "@app/shared/modules/contact-components/contact-summary/components/contact-summary-services/contact-summary-services.component";
import { ContactSummaryComponent } from "@app/shared/modules/contact-components/contact-summary/contact-summary.component";
import { ContactSummaryEffects } from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.effects";
import { contactSummaryReducer } from "@app/shared/modules/contact-components/contact-summary/ngrx/contact-summary.reducer";
import { CreateNoteComponent } from "@app/shared/modules/contact-components/create-note/create-note.component";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { RichTextEditorModule } from "@app/shared/modules/form-components/rich-text-editor/rich-text-editor.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ContactCardComponent } from "./contact-card/contact-card.component";
import { CreateNoteModalComponent } from "./create-note-modal/create-note-modal.component";
import { ContactEditButtonComponent } from "@app/shared/modules/contact-components/contact-edit-button/contact-edit-button.component";

const components = [
  ContactCardComponent,
  ContactNameComponent,
  ContactQuickActionsComponent,
  CreateNoteComponent,
  CreateNoteModalComponent,
  ContactSummaryComponent,
  ContactEditButtonComponent,
  ContactSummaryDialogComponent,
  ContactSummaryInfoComponent,
  ContactSummaryConsentsComponent,
  ContactSummaryServicesComponent,
  ContactSummaryNotesComponent,
];

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    UiComponentsModule,
    TooltipModule,
    MatTooltipModule,
    AddContactToCallingListModule,
    RichTextEditorModule,
    FormComponentsModule,
    StoreModule.forFeature("contact-summary", contactSummaryReducer),
    EffectsModule.forFeature([ContactSummaryEffects, ContactEffects]),
    ModalModule,
    SkeletonScreenModule,
    RouterModule,
    MatCheckboxModule,
  ],
  declarations: [...components],
  exports: [...components],
  providers: [PhoneNumberService],
})
export class ContactComponentsModule {}
