import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { filter, first, map, Observable, Subject, takeUntil } from "rxjs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { PropertyTriggerService } from "@app/shared/modules/property-components/property-trigger-modal/property-trigger.service";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";
import { select, Store } from "@ngrx/store";
import * as fromUser from "@app/shared/user/user.selectors";
import { ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { AppState } from "@app/app.reducer";

@Component({
  selector: "app-property-trigger-modal",
  templateUrl: "./property-trigger-modal.component.html",
  styleUrls: ["./property-trigger-modal.component.scss"],
  providers: [PropertyTriggerService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyTriggerModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  propertyTriggers$: Observable<DropdownItem[]>;
  isSuperAdmin$: Observable<boolean>;
  isWorking$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PropertyTriggerModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    private propertyTriggerService: PropertyTriggerService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    this.propertyTriggerService
      .storePropertyTrigger(this.form.getRawValue())
      .pipe(first())
      .subscribe((isSuccess) => isSuccess && this.closeModal());
  }

  closeModal() {
    this.dialogRef.close();
  }

  handleTriggerChanged(triggerId: string) {
    this.form.get("triggerId").setValue(triggerId);
  }

  onDeduplicationChanged(event: Event) {
    this.form.get("disableDeduplication").setValue(event.target["checked"]);
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOid: [null, Validators.required],
      triggerId: [null, Validators.required],
      disableDeduplication: false,
    });
  }

  private mapStateToProps() {
    this.propertyTriggers$ = this.propertyTriggerService.propertyTriggers$.pipe(
      map((propertyTriggers) =>
        propertyTriggers?.map((propertyTrigger) => ({
          display: propertyTrigger.name,
          value: propertyTrigger.triggerId,
        }))
      )
    );
    this.isSuperAdmin$ = this.store.pipe(
      select(fromUser.hasRole(ROLE_SUPER_ADMIN))
    );
    this.isWorking$ = this.propertyTriggerService.isWorking$.pipe(
      takeUntil(this.unsubscribe$)
    );
  }

  private fillForm() {
    this.form.get("eaOid").setValue(this.data?.eaOid);
    this.propertyTriggers$
      .pipe(
        first(),
        filter((triggers) => !!triggers && triggers.length > 0)
      )
      .subscribe((propertyTriggers) => {
        this.form.get("triggerId").setValue(propertyTriggers[0].value);
      });
  }
}
