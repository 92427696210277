import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { TypedPaginationListDTO } from "@app/models";
import {
  fetchLatestObjectsFail,
  fetchLatestObjectsRequest,
  fetchLatestObjectsSuccess,
} from "@app/widgets/widgets/latest-objects-widget/ngrx/latest-objects-widget.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, of, switchMap } from "rxjs";
import { PageView } from "@app/models/page-view";

@Injectable()
export class LatestObjectsWidgetEffects {
  constructor(private actions$: Actions, private apiService: ApiService) {}

  fetchLatestObjectsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchLatestObjectsRequest),
      switchMap(() =>
        this.apiService.get(`page-views/unique/current-user/objects`, {}).pipe(
          map((response: TypedPaginationListDTO<PageView>) =>
            fetchLatestObjectsSuccess({ objects: response.rows })
          ),
          catchError(() => of(fetchLatestObjectsFail()))
        )
      )
    )
  );
}
