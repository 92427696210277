import { Component, Input } from "@angular/core";

@Component({
  selector: "app-q-circle-btn",
  templateUrl: "./q-circle-btn.component.html",
  styleUrls: ["./q-circle-btn.component.scss"],
})
export class QCircleBtnComponent {
  @Input() icon: string;
  @Input() tooltipText: string;
}
