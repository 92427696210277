import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { environment } from "@env/environment";
import { select, Store } from "@ngrx/store";
import {
  filter,
  first,
  lastValueFrom,
  map,
  Observable,
  switchMap,
  tap,
} from "rxjs";
import * as configActions from "./shared/config/config.actions";
import * as fromConfig from "./shared/config/config.reducer";

export interface AppConfig {
  apiUrl: string;
}

@Injectable()
export class AppConfigService {
  private _config: Partial<AppConfig> = {};

  constructor(private http: HttpClient, private store: Store<AppState>) {}

  load(): Promise<any> {
    return lastValueFrom(
      this.loadAppConfig().pipe(
        tap((config) => {
          this.config = config;
          this.store.dispatch(configActions.loadRequest());
        }),
        switchMap(() => this.loadCustomerSettings())
      )
    );
  }

  get config(): Partial<AppConfig> {
    return this._config;
  }

  set config(config: Partial<AppConfig>) {
    this._config = {
      ...config,
      apiUrl:
        config.apiUrl === "RESOLVE_BY_DOMAIN"
          ? this.resolveApiUrl()
          : config.apiUrl,
    };
  }

  private loadAppConfig(): Observable<AppConfig> {
    return this.http
      .get(
        `assets/conf/${environment.config}?timestamp=${new Date().getTime()}`
      )
      .pipe(map((response: AppConfig) => response));
  }

  private loadCustomerSettings(): Observable<boolean> {
    return this.store.pipe(
      select(fromConfig.getLoaded),
      filter((loaded) => loaded !== null),
      first()
    );
  }

  private resolveApiUrl() {
    const currentOrigin = location.origin;
    const apiPort = "/proxy/";
    const apiUrl = (currentOrigin ?? "") + apiPort;
    return apiUrl;
  }
}
