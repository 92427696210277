import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { first, map, Observable, Subject } from "rxjs";

@Component({
  selector: "sort-by-activated-route-params",
  templateUrl: "./sort-by-activated-route-params.component.html",
  styleUrls: ["./sort-by-activated-route-params.component.scss"],
})
export class SortByActivatedRouteParamsComponent implements OnInit {
  @Input() sortBy: string;

  @Input() startSortBy = "asc";

  isSelected$: Observable<boolean>;

  isAscending$: Observable<boolean>;

  isDescending$: Observable<boolean>;

  unsubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.isSelected$ = this.route.params.pipe(
      map((params) => params.sortBy === this.sortBy)
    );
    this.isAscending$ = this.route.params.pipe(
      map(
        (params) => params.sortBy === this.sortBy && params.sortOrder === "asc"
      )
    );
    this.isDescending$ = this.route.params.pipe(
      map(
        (params) => params.sortBy === this.sortBy && params.sortOrder === "desc"
      )
    );
  }

  onClick(): void {
    this.route.params
      .pipe(
        first(),
        map((params: Params) => this.getNewParams(params))
      )
      .subscribe((params) =>
        this.router.navigate([".", params], { relativeTo: this.route })
      );
  }

  getOppositeFromStartSortFrom(): string {
    return this.startSortBy === "asc" ? "desc" : "asc";
  }

  private getNewParams(params: any): any {
    const sortBy = this.sortBy;
    const sortOrder =
      params.sortBy === this.sortBy && params.sortOrder === this.startSortBy
        ? this.getOppositeFromStartSortFrom()
        : this.startSortBy;
    return { ...params, sortOrder, sortBy };
  }
}
