<h5
  *ngIf="(userCallingLists$ | async)?.length"
  class="checkbox-select__dropdown-header"
>
  {{ contactName ? contactName : "" }} {{ "found_in" | translate | lowercase }}:
</h5>
<ul class="checkbox-select__dropdown__list">
  <li
    class="checkbox-select__dropdown__list__item"
    *ngFor="let selected of userCallingLists$ | async"
    (click)="handleRemoveFromList(selected.callingListId)"
  >
    <input type="checkbox" class="checkbox" [checked]="true" />
    <div class="checkbox-select__dropdown__list__item__text-container">
      <span class="title">{{ selected.title }}</span
      ><br />
      <small
        >{{ "created_by" | translate }}: {{ selected.employeeFullName }}</small
      >
    </div>
  </li>

  <li
    *ngIf="!(userCallingLists$ | async)?.length"
    class="checkbox-select__dropdown__list__item disabled"
  >
    <div class="checkbox-select__dropdown__list__item__text-container">
      <span>
        {{ "contact_no_calling_list" | translate }}
      </span>
    </div>
  </li>
</ul>

<h5 class="checkbox-select__dropdown-header">
  {{ "remaining_lists" | translate }}:
</h5>
<ul class="checkbox-select__dropdown__list">
  <ng-container *ngIf="userCallingLists$ | async as userCallingLists">
    <ng-container *ngIf="callingLists$ | async as allCallingLists">
      <li
        class="checkbox-select__dropdown__list__item"
        *ngFor="
          let selected of getUnselectedList(allCallingLists, userCallingLists)
        "
        (click)="handleAddToListClick(selected.callingListId)"
      >
        <input type="checkbox" class="checkbox" />
        <div class="checkbox-select__dropdown__list__item__text-container">
          <span class="title"> {{ selected.title }} </span><br />
          <small
            >{{ "created_by" | translate }}:
            {{ selected.employeeFullName }}</small
          >
        </div>
      </li>

      <li
        *ngIf="!getUnselectedList(allCallingLists, userCallingLists).length"
        class="checkbox-select__dropdown__list__item disabled"
      >
        <div class="checkbox-select__dropdown__list__item__text-container">
          <span>
            {{ "no_calling_lists_found" | translate }}
          </span>
        </div>
      </li>
    </ng-container>
  </ng-container>
</ul>
