export const calculateGrowth = (x: number, y: number): number => {
  if (!x) {
    x = 0;
  }

  if (!y) {
    y = 0;
  }

  if (x === 0 && y === 0) {
    return 0;
  }

  const percent = Math.round((x / y) * 100);

  if (percent === 0 || percent === Infinity) {
    return 0;
  }

  return percent - 100;
};

export const calculatePercent = (x: number, y: number): number => {
  if (!x) {
    x = 0;
  }

  if (!y) {
    y = 0;
  }

  const percent = Math.round((x / y) * 100);
  return isNaN(percent) ? 0 : percent;
};
