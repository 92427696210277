import { Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Employee, Office } from "@app/models";
import * as fromUser from "@app/shared/user";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "profile-card",
  template: `
    <div *ngIf="employee$ | async as employee" class="profile">
      <div
        class="profile__image"
        *ngIf="employee.employeePhoto as src; else initials"
      >
        <figure
          [ngStyle]="{
            'background-image':
              'url(' +
              employee.employeePhoto +
              '),' +
              ' url(' +
              '/assets/img/img_error_placeholder.png' +
              ')'
          }"
        ></figure>
      </div>
      <ng-template #initials>
        <div class="profile__image profile__image--initials">
          {{ employee.getInitials() }}
        </div>
      </ng-template>
      <div class="profile__meta">
        <p class="profile__meta--name">{{ employee?.employeeFullName }}</p>
        <p class="profile__meta--info" *ngFor="let role of roles$ | async">
          {{ role | translate }}
        </p>
        <p class="profile__meta--info">{{ (office$ | async)?.officeName }}</p>
      </div>
    </div>
    <div
      *ngIf="isViewingAsSomeoneElse$ | async"
      style="margin: 1.5rem 0 1rem 0;"
    >
      <a
        routerLink="/admin/select-employee"
        class="btn btn-danger"
        style="display: block"
      >
        <app-icon
          name="triangle-exclamation"
          class="loading-pulse border-danger"
        ></app-icon>
        {{
          "warning_logged_in_as_someone_else"
            | translate : { name: (employee$ | async)?.employeeFullName }
        }}
      </a>
    </div>
  `,
  styleUrls: ["./profile-card.component.scss"],
})
export class ProfileCardComponent implements OnInit {
  employee$: Observable<Employee>;
  office$: Observable<Office>;
  roles$: Observable<string[]>;
  isViewingAsSomeoneElse$: Observable<boolean>;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
    this.office$ = this.store.pipe(select(fromUser.getOffice));
    this.roles$ = this.store.pipe(select(fromUser.getRoles));
    this.isViewingAsSomeoneElse$ = this.store.pipe(
      select(fromUser.isViewingAsSomeoneElse)
    );
  }
}
