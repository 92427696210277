<app-q-modal>
  <ng-container slot="header">
    {{ "seller_choice" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="body-container">
      <form [formGroup]="form" class="form-container">
        <div class="form-group">
          <app-dropdown-element
            [multiple]="false"
            [newStyle]="true"
            [items]="propertyTriggers$ | async"
            [path]="['triggerId']"
            [required]="true"
            [preselectValue]="form.get('triggerId').value"
            [class.invalid]="
              form.get('triggerId').invalid && form.get('triggerId').touched
            "
            label="trigger_endpoint"
            width="100%"
            (valueEntered)="handleTriggerChanged($event)"
          >
          </app-dropdown-element>
          <app-q-control-errors
            name="triggerId"
            [errors]="[
              { name: 'required', text: 'validation_required', when: 'touched' }
            ]"
          >
          </app-q-control-errors>
        </div>
        <div
          *ngIf="isSuperAdmin$ | async"
          class="form-group checkbox-tag-group"
        >
          <div class="input-checkbox-container">
            <input
              type="checkbox"
              id="disableDeduplication"
              class="checkbox"
              [checked]="form.get('disableDeduplication').value"
              (change)="onDeduplicationChanged($event)"
            />
            <label [attr.for]="'disableDeduplication'" translate>
              disable_deduplication
            </label>
          </div>
        </div>
      </form>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button
      mat-dialog-close
      (click)="closeModal()"
      label="close"
      buttonType="cancel"
    >
    </app-q-button>
    <app-q-button
      (click)="submit()"
      label="save"
      buttonType="save"
      [isLoading]="isWorking$ | async"
      [disabled]="isWorking$ | async"
    >
    </app-q-button>
  </ng-container>
</app-q-modal>
