import { EmployeeSettingsResponse } from "@app/settings/account/potential-buyers-to-zero-bidders/ngrx/potential-buyers-to-zero-bidders.effects";
import { createAction, props } from "@ngrx/store";

export const getEmployeeSettingsRequest = createAction(
  "[Tags] GET_EMPLOYEE_SETTINGS_REQUEST"
);

export const getEmployeeSettingsSuccess = createAction(
  "[Tags] GET_EMPLOYEE_SETTINGS_SUCCESS",
  props<{ settings: EmployeeSettingsResponse }>()
);

export const getEmployeeSettingsFail = createAction(
  "[Tags] GET_EMPLOYEE_SETTINGS_FAILED"
);

export const storeEmployeeSettingsRequest = createAction(
  "[Tags] STORE_EMPLOYEE_SETTINGS_REQUEST",
  props<{ sendPotentialBuyersToMspecs: boolean }>()
);

export const storeEmployeeSettingsSuccess = createAction(
  "[Tags] STORE_EMPLOYEE_SETTINGS_SUCCESS"
);

export const storeEmployeeSettingsFail = createAction(
  "[Tags] STORE_EMPLOYEE_SETTINGS_FAILED"
);
