<section>
  <div
    [ngClass]="
      countryCode && !hideFlags
        ? 'input-iconfield input-iconfield--flag'
        : '' || icon
        ? 'input-iconfield'
        : ''
    "
    [formGroup]="group"
    [class.pseudo-spinner-large]="
      showSpinner && showSearchResults && (loading$ | async)
    "
  >
    <label class="search-contact__label">{{
      label.length > 0 ? label : placeholder
    }}</label>
    <country-code-selector
      class="icon"
      *ngIf="filter?.toLowerCase() === 'number' && !hideFlags"
      [lang]="getLanguage() | async"
      [iso]="countryCode"
      (onChange)="handleCountryChange($event)"
    >
    </country-code-selector>
    <input
      [placeholder]="label.length > 0 ? placeholder : ''"
      [style.padding]="smallInputs ? '.5rem' : '1rem'"
      [type]="filter === 'number' ? 'tel' : 'text'"
      [formControlName]="controlName"
      [value]="predefinedValue"
      #searchInput
    />
    <i class="{{ icon }}"></i>
  </div>
  <div
    class="flex-column results-dropdown"
    *ngIf="showSearchResults && (showResult(searchInput.value.length) | async)"
  >
    <div class="results-content">
      <div class="flex-column result-set" *ngIf="showQuedroSearchResult">
        <search-contact-list-header
          [title]="'existing_contacts' | translate"
          *ngIf="extended"
        ></search-contact-list-header>
        <contact-card
          *ngFor="let contact of quedroContacts$ | async"
          [name]="contact.getFullName()"
          [address]="contact.street"
          [number]="formatNumber(contact.msisdn, contact.msisdnCountry)"
          [email]="contact.email"
          (click)="onClick(contact)"
        ></contact-card>
        <search-contact-list-empty
          *ngIf="(quedroContacts$ | async)?.length === 0"
        >
          <ng-content select="[empty]"></ng-content>
        </search-contact-list-empty>
      </div>

      <div
        class="flex-column result-set"
        *ngIf="searchAllowed('eniro') | async"
      >
        <search-contact-list-header
          [title]="'eniro_contact_hits' | translate"
        ></search-contact-list-header>
        <contact-card
          *ngFor="let contact of eniroContacts$ | async"
          [name]="contact?.getFullName()"
          [address]="contact?.getStreet()"
          [number]="
            contact?.phoneNumbers?.length > 0
              ? contact.phoneNumbers[0].phoneNumber
              : ''
          "
          [email]="contact?.email"
          [type]="contact?.subscriberType"
          (click)="onEniroClick(contact)"
        ></contact-card>
        <search-contact-list-empty
          *ngIf="(eniroContacts$ | async)?.length === 0"
        ></search-contact-list-empty>
      </div>
    </div>
  </div>
</section>
