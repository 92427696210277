import { Component, EventEmitter, Input, Output } from "@angular/core";
import { EmployeeDTO } from "@app/models";

@Component({
  selector: "search-employee-shared-card",
  templateUrl: "./search-employee-card.component.html",
  styleUrls: ["./search-employee-card.component.scss"],
})
export class SearchEmployeeCardComponent {
  @Input() employee: EmployeeDTO;
  @Input() deletable: boolean;

  @Output() delete = new EventEmitter<boolean>();
}
