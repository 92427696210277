import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  from as observableFrom,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import * as statisticsEmployeesPerformanceActions from "@app/widgets/widgets/statistics-employees-performance-widget/ngrx/statistics-employees-performance-widget.actions";

@Injectable()
export class StatisticsEmployeesPerformanceWidgetEffects {
  fetchEmployeesPerformanceStatisticsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsRequest
      ),
      switchMap(({ params }) =>
        this.apiService.get("stats/growth", params).pipe(
          map((response: any) =>
            statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsSuccess(
              { data: response }
            )
          ),
          catchError(() =>
            observableOf(
              statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsFail()
            )
          )
        )
      )
    )
  );

  fetchEmployeesPerformanceStatisticsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        statisticsEmployeesPerformanceActions.fetchEmployeesPerformanceStatisticsFail
      ),
      switchMap(() =>
        observableFrom([
          toastActions.danger({
            message: "fetch_employees_performance_statistics_fail",
          }),
        ])
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
