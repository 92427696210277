import { Injectable } from "@angular/core";
import { FormattedSearchArea } from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DrawMapModalService {
  showArea$ = new BehaviorSubject<FormattedSearchArea>(null);
  areaSaved$ = new Subject<FormattedSearchArea>();
  showMode$ = new BehaviorSubject<"Show" | "Create" | "Filter">("Create");
  drawnArea$ = new Subject<string>();
}
