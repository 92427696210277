import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./shortcuts-widget.actions";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";
import {
  PredefinedFilters,
  ShortcutLink,
} from "@app/shared/config/models/shortcuts-feature";

export interface ShortcutsState {
  settingId: string;
  settingGroupId: string;
  settingValueId: string;
  shortcuts: ShortcutLink[];
  filters: PredefinedFilters[];
  isLoading: boolean;
  isWorking: boolean;
}

const initialState: ShortcutsState = {
  settingId: null,
  settingGroupId: null,
  settingValueId: null,
  shortcuts: [],
  filters: [],
  isLoading: false,
  isWorking: false,
};

export const ShortcutsReducer = createReducer(
  initialState,
  on(actions.getShortcutsRequest, () => ({
    ...initialState,
    isLoading: true,
  })),
  on(actions.getShortcutsSuccess, (state, { data }) => ({
    ...state,
    settingId: data?.settingId,
    settingGroupId: data?.settingGroupId,
    settingValueId: data?.settingValueId,
    shortcuts: data?.predefinedShortcuts,
    filters: data?.filters,
    isLoading: false,
  })),
  on(actions.getShortcutsFail, () => ({
    ...initialState,
  })),
  on(
    actions.createShortcutsRequest,
    actions.updateShortcutsRequest,
    (state) => ({
      ...state,
      isWorking: true,
    })
  ),
  on(
    actions.createShortcutsSuccess,
    actions.createShortcutsFail,
    actions.updateShortcutsSuccess,
    actions.updateShortcutsFail,
    (state) => ({
      ...state,
      isWorking: false,
    })
  )
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const selectShortcuts = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.shortcuts
);
export const getPredefinedFilter = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.filters
);
export const getPredefinedShortcuts = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.shortcuts
);
export const getIsLoading = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.isLoading
);
export const getIsWorking = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.isWorking
);
export const getHasSettings = createSelector(
  selectShortcuts,
  (state: ShortcutsState) =>
    state.shortcuts?.length > 0 || state.filters?.length > 0
);
export const getSettingId = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.settingId
);
export const getSettingGroupId = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.settingGroupId
);
export const getSettingValueId = createSelector(
  selectShortcuts,
  (state: ShortcutsState) => state.settingValueId
);
