import { EntityMetadataMap } from "@ngrx/data";

export const entityMetadata: EntityMetadataMap = {
  Contact: {
    entityName: "Contact",
    selectId: (contact) => contact.contactId,
  },
  ContactRelationship: {
    entityName: "ContactRelationship",
    selectId: (contactRelation) => contactRelation.eaRelationshipId,
  },
  CallingList: {
    entityName: "CallingList",
    selectId: (callingList) => callingList.callingListId,
  },
  Note: {
    entityName: "Note",
    selectId: (note) => note.eaNoteId,
  },
  Object: {
    entityName: "Object",
    selectId: (object) => object.eaOid,
  },
  ObjectMarketing: {
    entityName: "ObjectMarketing",
    selectId: (object) => object.eaOid,
  },
  Task: {
    entityName: "Task",
    selectId: (task) => task.eaTaskId,
  },
  Employee: {
    entityName: "Employee",
    selectId: (employee) => employee.eaEmployeeId,
  },
  Office: {
    entityName: "Office",
    selectId: (office) => office.eaOfficeId,
  },
  Region: {
    entityName: "Region",
    selectId: (region) => region.eaRegionId,
  },
  Employment: {
    entityName: "Employment",
    selectId: (employment) => employment.eaEmployeeId,
  },
  TaskTemplate: {
    entityName: "TaskTemplate",
    selectId: (taskTemplate) => taskTemplate.eaTaskTemplateId,
  },
  ValueMonitor: {
    entityName: "ValueMonitor",
    selectId: (valueMonitor) => valueMonitor.contactPriceUpdateOrdersId,
  },
  EmployeeDescription: {
    entityName: "EmployeeDescription",
    selectId: (item) => item.eaDescriptionValueId,
  },
  EmployeeDescriptionType: {
    entityName: "EmployeeDescriptionType",
    selectId: (item) => item.eaDescriptionTypeId,
  },
  OutgoingMessage: {
    entityName: "OutgoingMessage",
    selectId: (message) => message.receiverId,
  },
  SalesMeeting: {
    entityName: "SalesMeeting",
    selectId: (meeting) => meeting.eaCrmSalesMeetingId,
  },
  Showing: {
    entityName: "Showing",
    selectId: (showing) => showing.eaShowingId,
  },
  Bid: {
    entityName: "Bid",
    selectId: (bid) => bid.externalBidId,
  },
  Action: {
    entityName: "Action",
    selectId: (action) => action.actionId,
  },
  Segment: {
    entityName: "Segment",
    selectId: (segment) => segment.segmentId,
  },
  County: {
    entityName: "County",
    selectId: (county) => county.countyId,
  },
  Municipality: {
    entityName: "Municipality",
    selectId: (municipality) => municipality.municipalityId,
  },
  CustomFilter: {
    entityName: "CustomFilter",
    selectId: (customFilter) => customFilter.customFilterId,
  },
  Team: {
    entityName: "Team",
    selectId: (team) => team.id,
  },
  ForeignGeoData: {
    entityName: "ForeignGeoData",
    selectId: (fgd) => fgd.entity,
  },
};

const pluralNames = {};

export const entityConfig = {
  entityMetadata,
  pluralNames,
};
