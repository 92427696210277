import { PaginationListDTO, Task } from "@app/models";
import { TaskType } from "@app/models/task-type";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as taskActions from "./lists-tasks.actions";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsTasksState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  selectingTasksLoading: boolean;
  rows: Task[];
  types: TaskType[];
  selectedEaTaskIds: string[];
  allContactIds: string[];
}

export const initialState: ListsTasksState = {
  loaded: false,
  loading: false,
  selectingTasksLoading: false,
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  types: [],
  selectedEaTaskIds: [],
  allContactIds: [],
};

export function listsTasksReducer(
  state: ListsTasksState,
  action: Action
): ListsTasksState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(taskActions.getTasksRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(taskActions.getTasksSuccess, (state, { tasks }) => ({
    ...state,
    loading: false,
    loaded: true,
    ...tasks,
  })),
  on(taskActions.getTasksFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    rows: initialState.rows,
  })),
  on(taskActions.getMoreTasksRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(taskActions.getMoreTasksSuccess, (state, { tasks }) => {
    const { limit, offset, total, rows } = tasks;
    return {
      ...state,
      loading: false,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    };
  }),
  on(taskActions.getMoreTasksFailed, (state) => ({
    ...state,
    loading: initialState.loading,
  })),
  on(taskActions.getTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    types: taskTypes,
  })),
  on(
    taskActions.getTaskTypesRequest,
    taskActions.getTaskTypesFailed,
    (state) => ({ ...state })
  ),
  on(taskActions.assignMultipleTasksRequest, (state) => ({
    ...state,
  })),
  on(taskActions.setSelectedTasks, (state, { eaTaskIds }) => ({
    ...state,
    selectedEaTaskIds: eaTaskIds,
  })),
  on(taskActions.selectAllTasksRequest, (state) => ({
    ...state,
    selectingTasksLoading: true,
  })),
  on(taskActions.selectAllTasksFail, (state) => ({
    ...state,
    selectingTasksLoading: false,
  })),
  on(taskActions.selectAllTasksSuccess, (state, { eaTaskIds }) => ({
    ...state,
    selectedEaTaskIds: eaTaskIds,
    selectingTasksLoading: false,
  })),
  on(taskActions.getAllTaskContactIdsSuccess, (state, { contactIds }) => ({
    ...state,
    allContactIds: contactIds
      .filter((contactId, index) => contactIds.indexOf(contactId) === index)
      .filter((value) => !!value),
  })),
  on(taskActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.tasks
);

export const getListsTasksTypes = createSelector(
  getState,
  (state: ListsTasksState) => state.types
);
