import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/settings/lead-channel-result-options/ngrx/lead-channel-result-options.actions";
import { TaskResult, TypedPaginationListDTO } from "@app/models";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface LeadChannelResultOptionsState {
  data: TypedPaginationListDTO<TaskResult>;
  isLoading: boolean;
}

const initialState: LeadChannelResultOptionsState = {
  data: null,
  isLoading: false,
};

export const leadChannelResultOptionsReducer = createReducer(
  initialState,
  on(actions.getLeadChannelResultOptionsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadChannelResultOptionsSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: false,
  })),
  on(actions.getLeadChannelResultOptionsFail, (state) => ({
    ...state,
    isLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.leadChannelResultOptions
);

export const selectData = createSelector(
  getState,
  (state: LeadChannelResultOptionsState) => state.data
);
export const selectIsLoading = createSelector(
  getState,
  (state: LeadChannelResultOptionsState) => state.isLoading
);
