import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { LEAD_TEMPLATES } from "@app/shared/utils/tab-types";
import { LeadTemplatesFormComponent } from "@app/sidebar/lead-templates/components/lead-templates-form/lead-templates-form.component";
import * as leadTemplatesFormActions from "@app/sidebar/lead-templates/ngrx/lead-templates-form.actions";
import {
  selectData,
  selectIsLoading,
  selectIsWorking,
} from "@app/sidebar/lead-templates/ngrx/lead-templates-form.reducer";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";

@Component({
  selector: "app-lead-templates-edit",
  templateUrl: "./lead-templates-edit.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-templates-edit.component.scss",
  ],
})
export class LeadTemplatesEditComponent
  extends ConnectTab
  implements OnInit, OnDestroy, IConnectableTab
{
  @ViewChild(LeadTemplatesFormComponent, { static: true })
  leadTemplatesForm: LeadTemplatesFormComponent;

  tabType = LEAD_TEMPLATES;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  data$: Observable<any>;
  isLoading$: Observable<any>;
  isWorking$: Observable<boolean>;
  templateId$: Observable<number>;

  constructor(store: Store<AppState>, private route: ActivatedRoute) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.templateId$ = this.route.params.pipe(map((params) => params?.id));
    this.templateId$.pipe(takeUntil(this.unsubscribe$)).subscribe((id) => {
      this.store.dispatch(
        leadTemplatesFormActions.getLeadTemplateRequest({ id })
      );
    });
    this.mapStateToProps();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps() {
    this.data$ = this.store.pipe(select(selectData));
    this.isLoading$ = this.store.pipe(select(selectIsLoading));
    this.isWorking$ = this.store.pipe(select(selectIsWorking));
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onRemove() {
    this.store.dispatch(
      confirmActions.show({
        header: "delete_template_header",
        message: "delete_template_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1),
        withLatestFrom(this.templateId$)
      )
      .subscribe(([, id]) => {
        this.store.dispatch(
          leadTemplatesFormActions.deleteLeadTemplateRequest({ id })
        );
      });
  }

  onSubmit() {
    this.templateId$.pipe(first()).subscribe((id) => {
      this.leadTemplatesForm.submit((body) =>
        this.store.dispatch(
          leadTemplatesFormActions.editLeadTemplateRequest({ id, body })
        )
      );
    });
  }
}
