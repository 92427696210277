import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as fromContact from "@app/contacts/contact.selectors";
import * as previewActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { Contact, Employee, Office } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/sub-pages";
import { getOffice } from "@app/shared/ngrx/shared.reducer";
import * as fromUser from "@app/shared/user";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { SEARCH_PROFILE_ALERT_CREATE } from "@app/shared/utils/tab-types";
import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import * as searchProfileActions from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.actions";
import * as fromSearchProfile from "@app/sidebar/search-profile/ngrx/search-profile/search-profile.reducer";
import { SearchProfileAlertFormComponent } from "@app/sidebar/search-profile/search-profile-alert-form/search-profile-alert-form.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import * as fromSidebar from "@app/sidebar/ngrx/sidebar.reducer";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-create-search-profile-alert",
  templateUrl: "./create-search-profile-alert.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-search-profile-alert.component.scss",
  ],
})
export class CreateSearchProfileAlertComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild(SearchProfileAlertFormComponent, { static: false })
  alertForm: SearchProfileAlertFormComponent;

  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  tabType = SEARCH_PROFILE_ALERT_CREATE;
  alertActivities$: Observable<{ activityId: string; name: string }[]>;
  alert$: Observable<Alert>;
  groupSettingId$: Observable<string>;
  templateId$: Observable<string>;
  employee$: Observable<Employee>;
  contact$: Observable<Contact>;
  searchProfileTitle$: Observable<string>;
  searchProfileId$: Observable<string>;
  sendModuleId$: Observable<string>;
  isAdmin$: Observable<boolean>;
  isManager$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();
  patching$: Observable<boolean>;
  deleting$: Observable<boolean>;
  loading$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.getAlertActivities();
    this.getSearchProfile();
    this.getAlert();
    this.connectTab();
  }

  ngOnDestroy(): void {
    this.store.dispatch(searchProfileActions.getAlertSuccess({ alert: null }));
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.alertActivities$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfileActivities)
    );
    this.groupSettingId$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE)),
      map((feature: SearchProfileFeature) => feature.settingGroupId)
    );
    this.templateId$ = this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE)),
      map((feature: SearchProfileFeature) => feature.templateId)
    );
    this.employee$ = this.store.pipe(
      select(fromSearchProfile.getAlertEmployee)
    );
    this.contact$ = this.store.pipe(select(fromContact.getContact));
    this.searchProfileTitle$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfileTitle)
    );
    this.searchProfileId$ = this.store.pipe(
      select(fromSearchProfile.getSearchProfileId)
    );
    this.sendModuleId$ = this.store.pipe(select(fromConfig.getSendModuleId));
    this.alert$ = this.store.pipe(select(fromSearchProfile.getSelectedAlert));
    this.isAdmin$ = this.store.pipe(select(fromUser.hasRole(ROLE_ADMIN)));
    this.isManager$ = this.store.pipe(select(fromUser.hasRole(ROLE_MANAGER)));
    this.patching$ = this.store.pipe(select(fromSearchProfile.getPatching));
    this.deleting$ = this.store.pipe(select(fromSearchProfile.getDeleting));
    this.loading$ = this.store.pipe(select(fromSearchProfile.getLoading));
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([firstEntity, current]) => {
        if (_.isEqual(firstEntity, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  private getAlertActivities(): void {
    this.groupSettingId$
      .pipe(first())
      .subscribe((filterSettingsGroupId: string) =>
        this.store.dispatch(
          searchProfileActions.getAlertActivitiesRequest({
            filterSettingsGroupId,
          })
        )
      );
  }

  private getSearchProfile(): void {
    this.route.params
      .pipe(map((params) => params?.id))
      .pipe(
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchProfileId: string) =>
        this.store.dispatch(
          searchProfileActions.getSearchProfileRequest({ searchProfileId })
        )
      );
  }

  private getAlert(): void {
    this.route.params
      .pipe(map((params) => params?.id))
      .pipe(
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((searchProfileId: string) =>
        this.store.dispatch(
          searchProfileActions.getAlertRequest({ searchProfileId })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(sidebarActions.closeTab({ tabType: this.tabType }));
  }

  submit(): void {
    this.alertForm.handleSubmit();
  }

  isSubmitDisabled() {
    if (this.alertForm) {
      return !this.alertForm.hasActivityIdSelected();
    } else {
      return true;
    }
  }

  deleteAlert(alertId: string): void {
    this.store.dispatch(searchProfileActions.deleteAlertRequest({ alertId }));
  }

  previewEmail(): void {
    this.getAlertEmployee(
      this.alertForm.alertForm.get("responsibleEmployee.employee").value
    );
    combineLatest([
      this.templateId$,
      this.employee$.pipe(
        filter((value) => !!value),
        filter(
          (employee) =>
            employee.eaEmployeeId ===
            this.alertForm.alertForm.get("responsibleEmployee.employee").value
        )
      ),
      this.store.pipe(
        select(
          getOffice(
            this.alertForm.alertForm.get("responsibleEmployee.office").value
          )
        ),
        filter((value) => !!value)
      ),
      this.contact$,
      this.alertActivities$.pipe(
        map(
          (activities) =>
            activities.find(
              (activity) =>
                activity.activityId ===
                this.alertForm.alertForm.get("activityId").value
            ).name
        )
      ),
      this.searchProfileTitle$,
    ])
      .pipe(first())
      .subscribe(
        ([
          templateId,
          employee,
          office,
          contact,
          activityName,
          searchProfileTitle,
        ]) => {
          const dataSet = this.getDataSet(
            employee,
            office,
            contact,
            activityName,
            searchProfileTitle
          );

          this.store.dispatch(
            previewActions.fetchPreviewRequest({
              parameters: {
                templateId,
                params: { dataSet },
              },
            })
          );
        }
      );
  }

  createAlert(alertId: string): void {
    this.getAlertEmployee(
      this.alertForm.alertForm.get("responsibleEmployee.employee").value
    );

    const office$ = combineLatest([
      this.store.pipe(
        select(
          getOffice(
            this.alertForm.alertForm.get("responsibleEmployee.office").value
          )
        )
      ),
      this.store.pipe(select(fromUser.getOffice)),
    ]).pipe(
      map(([selectedOffice, userOffice]) => {
        if (!!selectedOffice) {
          return selectedOffice;
        }

        if (
          userOffice.eaOfficeId ===
          this.alertForm.alertForm.get("responsibleEmployee.office").value
        ) {
          return userOffice;
        }

        return null;
      })
    );

    combineLatest([
      this.employee$.pipe(
        filter((value) => !!value),
        filter(
          (employee) =>
            employee.eaEmployeeId ===
            this.alertForm.alertForm.get("responsibleEmployee.employee").value
        )
      ),
      office$,
      this.alertActivities$.pipe(
        map(
          (activities) =>
            activities.find(
              (activity) =>
                activity.activityId ===
                this.alertForm.alertForm.get("activityId").value
            )?.name
        )
      ),
      this.templateId$,
      this.contact$,
      this.searchProfileTitle$,
      this.searchProfileId$,
      this.sendModuleId$,
    ])
      .pipe(first())
      .subscribe(
        ([
          employee,
          office,
          activityName,
          templateId,
          contact,
          searchProfileTitle,
          searchProfileId,
          sendModuleId,
        ]) => {
          let mail;

          const body: Partial<Alert> = {
            activityId: this.alertForm.alertForm.get("activityId").value,
            eaOfficeId: office.eaOfficeId,
            eaEmployeeId: employee.eaEmployeeId,
            title: this.translateService.instant("alert_title", {
              title: searchProfileTitle,
            }),
            searchProfileId: searchProfileId,
            contactId: contact.contactId,
            sendAlertToEmployee: this.alertForm.alertForm.get(
              "sendAlertToEmployee"
            ).value,
            sendAlertToResponsibleEmployee: this.alertForm.alertForm.get(
              "sendAlertToResponsibleEmployee"
            ).value,
          };

          if (!!this.alertForm.alertForm.get("sendAlertToEmployee").value) {
            body["sendAlertToEaEmployeeId"] = employee.eaEmployeeId;
          }

          if (this.alertForm.alertForm.get("sendConfirmationMail").value) {
            mail = {
              templateId: templateId,
              recipients: contact.email,
              sendToModule: sendModuleId,
              sendToActionContactIds: contact.contactId,
              origin: "crm",
              dataSet: this.getDataSet(
                employee,
                office,
                contact,
                activityName,
                searchProfileTitle
              ),
            };
          }

          if (alertId) {
            this.store.dispatch(
              searchProfileActions.updateAlertRequest({
                params: body,
                alertId,
                mail,
              })
            );
          } else {
            this.store.dispatch(
              searchProfileActions.createAlertRequest({ alert: body, mail })
            );
          }
        }
      );
  }

  private getDataSet(
    employee: any,
    office: Office,
    contact: Contact,
    activityName: string,
    searchProfileTitle: string
  ): string {
    return `consumerFirstName=${contact.firstName}|consumerFamilyName=${
      contact.familyName
    }|EmployeeFullName=${employee.fullName}|EmployeeTitle=${
      employee.title
    }|EmployeeMobile=${employee.mobile}|EmployeeEmail=${
      employee.email
    }|EmployeePhoto=${employee.photo}|OfficeName=${office.name}|OfficeStreet=${
      office.street
    }|OfficeZip=${office.zip}|OfficeCity=${office.city}|OfficePhone=${
      office.phone
    }|AlertName=${this.translateService.instant("alert_title", {
      title: searchProfileTitle,
    })}|ActivityName=${activityName}|fromName=${employee.fullName}|fromEmail=${
      employee.email
    }`;
  }

  private getAlertEmployee(id: string): void {
    this.store.dispatch(searchProfileActions.getAlertEmployeeRequest({ id }));
  }
}
