import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Sort } from "@angular/material/sort";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { BidService } from "@app/core/ngrx/entity-services/bid.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { ShowingService } from "@app/core/ngrx/entity-services/showing.service";
import { QObject } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import { CREATE_PRIVATE_SHOWING } from "@app/shared/config/utils/features";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as fromProgressWidget from "@app/shared/modules/progress-widget/ngrx/progress-widget.reducer";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { QTableComponent } from "@app/shared/modules/ui-components/q-table/q-table.component";
import { PotentialBuyer, Showing, ShowingSlot } from "@app/showings/models";
import * as fromPotentialBuyers from "@app/showings/ngrx/potential-buyer/potential-buyer.reducer";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";

@Component({
  selector: "app-showing-attendance-modal",
  templateUrl: "./showing-attendance-modal.component.html",
  styleUrls: ["./showing-attendance-modal.component.scss"],
})
export class ShowingAttendanceModalComponent implements OnInit, OnDestroy {
  contactIds$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  contacts$: Observable<PotentialBuyer[]>;
  provider$: Observable<string>;
  taskSetsInProgress$: Observable<TaskSet[]>;
  @ViewChild(QTableComponent, { static: false }) qTable: QTableComponent;
  columns = ["startTime", "isPublic", "attendance", "delete"];
  slotColumns = ["startTime", "slotCapacity", "attendance"];
  pbColumns = [
    "firstName",
    "address",
    "bidStatus",
    "notes",
    "contacted",
    "insertedDate",
  ];
  sortableColumns = [];
  headers = {
    bidStatus: "bid_status",
    startTime: "start_time",
    insertedDate: "created",
    isPublic: "public",
  };
  paginator$: BehaviorSubject<Partial<PageEvent>> = new BehaviorSubject<
    Partial<PageEvent>
  >({
    pageSize: 25,
    pageIndex: 0,
  });
  sorting$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>({
    active: "deliveryDate",
    direction: "desc",
  });
  pbSelection$: BehaviorSubject<PotentialBuyer[]> = new BehaviorSubject([]);

  showings$: Observable<Showing[]>;
  selectedShowing$: BehaviorSubject<Showing> = new BehaviorSubject<Showing>(
    null
  );
  filteredContacts$: Observable<PotentialBuyer[]>;

  showCreatePrivateShowing$: Observable<boolean>;
  object: QObject;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ShowingAttendanceModalComponent>,
    public contactService: ContactService,
    public showingService: ShowingService,
    private modalService: QModalService,
    private store: Store<AppState>,
    private bidEntityService: BidService,
    private objectService: ObjectService
  ) {}

  ngOnInit(): void {
    if (this.data?.contactIds?.length > 0) {
      this.contactIds$.next(this.data.contactIds);
    }
    if (this.data?.eaOid) {
      this.objectService.entityMap$
        .pipe(
          map((em) => em[this.data?.eaOid]),
          filter((it) => !!it),
          distinctUntilChanged(_.isEqual)
        )
        .subscribe((object) => (this.object = object));
    }
    this.mapStateToProps();
    this.showings$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(
          (showings) =>
            !!showings && showings.length === 1 && !!showings[0]?.showingSlots
        )
      )
      .subscribe((showings) => this.selectedShowing$.next(showings[0]));
  }

  unsubscribe$ = new Subject<void>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  private mapStateToProps() {
    this.provider$ = this.store.pipe(select(fromPotentialBuyers.getProvider));
    this.contacts$ = combineLatest([
      this.contactIds$,
      this.contactService.currentList$,
    ]).pipe(
      map(([ids, list]) =>
        list.filter((contact) => ids.includes(contact.contactId))
      ),
      filter((it) => !!it)
    );
    this.taskSetsInProgress$ = this.store.pipe(
      select(fromProgressWidget.getProgressWidgetTaskSetsInProgress)
    );
    this.showings$ = this.showingService.currentList$.pipe(
      map((showings) => {
        if (this.data?.eaShowingIds?.length > 0) {
          return showings.filter((s) =>
            this.data?.eaShowingIds.includes(s.eaShowingId)
          );
        } else {
          return showings;
        }
      })
    );
    this.filteredContacts$ = combineLatest([
      this.contactService.currentList$,
      this.bidEntityService.currentList$,
    ]).pipe(
      map(([potentialBuyers, bids]) => {
        const contactsWithBids = potentialBuyers.map((c) => {
          const contactBids = bids.filter((b) => b.contactId === c.contactId);
          return { ...c, bids: contactBids };
        });
        return contactsWithBids;
      })
    );
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );
  }

  createNewShowingModal() {
    const data: ShowingModalData = {
      isEditMode: false,
      contactId: this.contactIds$.value[0],
      eaOid: this.data.eaOid,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };

    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }

  handleAttendedChange(
    attendance: string,
    showing: Showing,
    showingSlot?: ShowingSlot
  ) {
    this.contacts$.pipe(first()).subscribe((contacts) => {
      const requests = contacts.map((contact) => {
        if (attendance === "NULL") {
          return this.contactService.removeContactShowingAttendance(
            contact.contactId,
            showing.showingId,
            showing.eaShowingId,
            !!showingSlot ? showingSlot.eaShowingSlotId : null
          );
        } else {
          const params = {
            eaShowingId: showing.eaShowingId,
            showingId: showing.showingId,
            registrationStatus: attendance,
            showingStartTime: showing.startTime,
          };
          if (!!showingSlot) {
            delete params.showingStartTime;
            params["eaShowingSlotId"] = showingSlot.eaShowingSlotId;
          }
          return this.contactService.postContactShowingAttendance(
            contact.contactId,
            params
          );
        }
      });
      const taskSet: TaskSet = {
        label: "updating_showing_attendance",
        tasks: requests,
      };
      this.store.dispatch(addTaskSetToQueue(taskSet));
    });
  }

  deleteShowing(showing: Showing) {
    this.store.dispatch(
      confirmActions.show({
        header: "remove_showing_header",
        message: "remove_showing_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((it) => !!it),
        take(1)
      )
      .subscribe(() => {
        if (showing.isPublic) {
          this.showingService
            .removePublicShowing(
              showing.eaOid,
              showing.showingId,
              showing.eaShowingId
            )
            .subscribe();
        } else {
          this.showingService
            .remove(showing.showingId, showing.eaShowingId)
            .subscribe();
        }
      });
  }

  openPreviewModal(html: string): void {
    this.store.dispatch(
      previewModalActions.showPreviewModal({
        parameters: { html },
      })
    );
  }

  setPbSelectionToContactIds() {
    this.contactIds$.next(this.pbSelection$.value.map((c) => c.contactId));
  }

  refreshShowings() {
    this.contactService.refreshPotentialBuyersNew().pipe(first()).subscribe();
    this.showingService.refreshFetchFromObject().pipe(first()).subscribe();
  }
}
