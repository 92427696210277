import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { AppState } from "@app/app.reducer";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";

@Component({
  selector: "app-filter-container",
  templateUrl: "./filter-container.component.html",
  styleUrls: ["./filter-container.component.scss"],
})
export class FilterContainerComponent implements OnInit {
  @Input() label: string = "filter";
  @Input() icon: string = "cog";

  isMobile$: Observable<boolean>;

  constructor(
    private store: Store<AppState>,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(isMobile));
  }

  openSheet(mobileFilters: TemplateRef<any>) {
    this._bottomSheet.open(mobileFilters, { panelClass: "sheet-container" });
  }

  close() {
    this._bottomSheet.dismiss();
  }
}
