import { SidebarTab, TabPersist } from "../models/sidebar-tab";
import { createAction, props } from "@ngrx/store";

export const addTab = createAction(
  "[Sidebar] ADD_TAB",
  props<{ tab: SidebarTab }>()
);

export const replaceTab = createAction(
  "[Sidebar] REPLACE_TAB",
  props<{ tabType: string; newTab: SidebarTab }>()
);

export const closeTab = createAction(
  "[Sidebar] CLOSE_TAB",
  props<{ tabType?: string }>()
);

export const closeTabs = createAction(
  "[Sidebar] CLOSE_TABS",
  props<{ tabTypes: string[] }>()
);

export const setInitialTabValue = createAction(
  "[Sidebar] SET_INITIAL_TAB_VALUE",
  props<{ tabType: string; value: any }>()
);

export const setTabPersistence = createAction(
  "[Sidebar] SET_TAB_PERSISTENCE",
  props<{
    tabType: string;
    persistence: TabPersist;
  }>()
);

export const resetDirty = createAction(
  "[Sidebar] RESET_DIRTY",
  props<{ tabType: string }>()
);

export const resetTab = createAction(
  "[Sidebar] RESET_TAB",
  props<{ tabType: string }>()
);

export const setTabValue = createAction(
  "[Sidebar] SET_TAB_VALUE",
  props<{ tabType: string; value: any }>()
);

export const markAsDirty = createAction(
  "[Sidebar] MARK_AS_DIRTY",
  props<{ tabType: string }>()
);

export const closeAllNonPersistentTabs = createAction(
  "[Sidebar] CLOSE_ALL_NON_PERSISTENT_TABS"
);

export const show = createAction("[Sidebar] SHOW_SIDEBAR");

export const hide = createAction("[Sidebar] HIDE_SIDEBAR");

export const toggle = createAction("[Sidebar] TOGGLE_SIDEBAR");
