<form [formGroup]="form.get('estateContact')" *ngIf="!this.editMode">
  <section>
    <h4>
      {{ "estate_contact_info" | translate }}
      <app-tooltip-icon
        [translationKey]="'estate_contact_info_tooltip'"
      ></app-tooltip-icon>
    </h4>

    <div class="form-group">
      <label>{{ "first_name" | translate }}</label>
      <input
        formControlName="firstName"
        [class.validation-input]="
          form.get('estateContact.firstName').invalid &&
          form.get('estateContact.firstName').touched
        "
      />
      <div
        translate
        ngxerror
        *ngIf="
          form.get('estateContact.firstName').errors?.required &&
          form.get('estateContact.firstName').touched
        "
      >
        validation_required
      </div>
    </div>

    <div class="form-group">
      <label>{{ "family_name" | translate }}</label>
      <input
        formControlName="familyName"
        [class.validation-input]="
          form.get('estateContact.familyName').invalid &&
          form.get('estateContact.familyName').touched
        "
      />
      <div ngxErrors="familyName">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-contact
        [placeholder]="'email' | translate"
        [group]="form.get('estateContact')"
        [controlName]="'email'"
        [waitForRequiredChar]="false"
        [requiredChars]="['@']"
        [hideEmptyResults]="true"
      ></search-contact>
    </div>

    <div class="form-group">
      <search-contact
        [countryCode]="selectedCountryCode$ | async"
        [filter]="'number'"
        [minLength]="3"
        [placeholder]="'phone' | translate"
        [group]="form.get('estateContact')"
        [controlName]="'msisdn'"
        [extended]="true"
        [hideEmptyResults]="true"
        (onCountryChange)="handleCountryCodeChange($event.regionCode)"
      ></search-contact>
      <div ngxErrors="phoneNumber">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>

    <div class="form-group">
      <search-address-adv
        type="street"
        [containerFormControl]="form.get('estateContact').get('street')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'street' | translate"
        [invalid]="form.get('estateContact').get('street').invalid"
        [showFullAddress]="false"
        (onSelected)="handleAddressSelected('estateContact', $event)"
      ></search-address-adv>
    </div>
    <div class="form-group">
      <search-address-adv
        type="zip"
        [containerFormControl]="form.get('estateContact').get('zip')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'zip' | translate"
        [invalid]="form.get('estateContact').get('zip').invalid"
        [showFullAddress]="false"
        (onSelected)="handleZipSelected('estateContact', $event)"
      ></search-address-adv>
    </div>

    <div class="form-group">
      <search-address-adv
        type="city"
        [containerFormControl]="form.get('estateContact').get('city')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'city' | translate"
        [invalid]="form.get('estateContact').get('city').invalid"
        [showFullAddress]="false"
      ></search-address-adv>
    </div>
  </section>
</form>
<hr *ngIf="!this.editMode" />
<form [formGroup]="form.get('estate')">
  <section>
    <h4>
      {{ "estate_info" | translate }}
      <app-tooltip-icon
        [translationKey]="'estate_info_tooltip'"
      ></app-tooltip-icon>
    </h4>

    <div class="form-group">
      <label>{{ "estate_name" | translate }}</label>
      <input
        formControlName="firstName"
        [class.validation-input]="
          form.get('estate.firstName').invalid &&
          form.get('estate.firstName').touched
        "
      />
      <div ngxErrors="firstName">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
    <div class="form-group">
      <search-address-adv
        type="street"
        [containerFormControl]="form.get('estate').get('street')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'street' | translate"
        [invalid]="form.get('estate').get('street').invalid"
        [showFullAddress]="false"
        (onSelected)="handleAddressSelected('estate', $event)"
      ></search-address-adv>
    </div>
    <div class="form-group">
      <search-address-adv
        type="zip"
        [containerFormControl]="form.get('estate').get('zip')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'zip' | translate"
        [invalid]="form.get('estate').get('zip').invalid"
        [showFullAddress]="false"
        (onSelected)="handleZipSelected('estate', $event)"
      ></search-address-adv>
    </div>

    <div class="form-group">
      <search-address-adv
        type="city"
        [containerFormControl]="form.get('estate').get('city')"
        [enabled]="(addressSuggesterFeature$ | async)?.enabled"
        [provider]="(addressSuggesterFeature$ | async)?.provider"
        [countryCode]="countryCode$ | async"
        [placeholder]="'city' | translate"
        [invalid]="form.get('estate').get('city').invalid"
        [showFullAddress]="false"
      ></search-address-adv>
    </div>

    <div class="form-group">
      <search-contact
        [placeholder]="'email' | translate"
        [group]="form.get('estateContact')"
        [controlName]="'email'"
        [waitForRequiredChar]="false"
        [requiredChars]="['@']"
        [hideEmptyResults]="true"
      ></search-contact>
    </div>

    <div class="form-group">
      <search-contact
        [countryCode]="selectedCountryCode$ | async"
        [filter]="'number'"
        [minLength]="3"
        [placeholder]="'phone' | translate"
        [group]="form.get('estateContact')"
        [controlName]="'msisdn'"
        [extended]="true"
        [hideEmptyResults]="true"
        (onCountryChange)="handleCountryCodeChange($event.regionCode)"
      ></search-contact>
      <div ngxErrors="phoneNumber">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
</form>
