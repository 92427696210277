import { Component, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { fadeInTrigger } from "@app/shared/animations";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import * as forgotPasswordActions from "./forgot-password/forgot-password.actions";

@Component({
  selector: "forgot-password-form",
  templateUrl: "./forgot-password-form.component.html",
  styleUrls: ["./forgot-password-form.component.scss"],
  animations: [fadeInTrigger()],
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
  step: number;
  eaEmployeeId: string;
  stateSubscription: Subscription;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.stateSubscription = this.store
      .select((state) => state.login.forgotPassword)
      .subscribe((state) => {
        this.eaEmployeeId = state.eaEmployeeId;
        this.step = state.step;
      });
  }

  ngOnDestroy() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }

  send(email: string) {
    this.store.dispatch(forgotPasswordActions.sendEmailRequest({ email }));
  }

  verify(verificationCode: string) {
    this.store.dispatch(
      forgotPasswordActions.verifyEmailCodeRequest({ verificationCode })
    );
  }

  setPassword(password: string) {
    this.store.dispatch(
      forgotPasswordActions.updateLoginInfoRequest({
        loginInfo: {
          eaEmployeeId: this.eaEmployeeId,
          password,
        },
      })
    );
  }
}
