import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { Employee, Office, Task } from "@app/models";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { TASK } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import { catchError, filter, map, Observable, of as observableOf } from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as tasksActions from "../ngrx/tasks.actions";

@Injectable()
export class TasksDefaultGuard implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private taskService: TaskService
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    if (next.params.eaTaskId.slice(0, 2) === "00") {
      // TODO: Remove after summer
      return observableOf(true);
    }

    return this.taskService.fetchById(next.params.eaTaskId).pipe(
      filter((item) => !!item),
      map(() => true),
      catchError((error) => this.catchApiError(error))
    );
  }

  canHandleTask(
    user: Employee,
    office: Office,
    task: Task,
    roles: string[]
  ): boolean {
    if (
      this.isUsersTask(user, task) ||
      this.containsRole(roles, ROLE_ADMIN) ||
      this.isManagerAndCanHandleTask(user, task, roles) ||
      this.isBrokerAndCanHandleTask(office, task)
    ) {
      return true;
    } else {
      this.store.dispatch(
        toastActions.danger({ message: "insufficient_permissions" })
      );
    }
    return false;
  }

  private isManagerAndCanHandleTask(
    user: Employee,
    task: Task,
    roles: string[]
  ): boolean {
    return (
      this.containsRole(roles, ROLE_MANAGER) &&
      this.userIsManagerAtOfficeConnectedToTask(user, task)
    );
  }

  private isBrokerAndCanHandleTask(currentOffice: Office, task: Task): boolean {
    return this.isFreeLead(task) && this.isOfficesTask(currentOffice, task);
  }

  private containsRole(roles: string[], role): boolean {
    return roles.indexOf(role) !== -1;
  }

  private isUsersTask(user: Employee, task: Task): boolean {
    return task.eaEmployeeId === user.eaEmployeeId;
  }

  private isOfficesTask(office: Office, task: Task): boolean {
    return office.eaOfficeId === task.eaOfficeId;
  }

  private isFreeLead(task: Task): boolean {
    return task.eaEmployeeId === "";
  }

  private userIsManagerAtOfficeConnectedToTask(
    user: Employee,
    task: Task
  ): boolean {
    return user.offices.some(
      (o: Office) => o.role === ROLE_MANAGER && this.isOfficesTask(o, task)
    );
  }

  private catchApiError(error: string): Observable<boolean> {
    this.store.dispatch(tasksActions.getTaskFailed());
    this.store.dispatch(sidebarActions.closeTab({ tabType: TASK }));
    this.store.dispatch(toastActions.danger({ message: error }));
    return observableOf(false);
  }
}
