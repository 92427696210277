<div class="container-fluid outlet-container calling-list-details">
  <div class="row">
    <div class="col-md-6" [@fadeInLeft]>
      <calling-list-card-details
        [callingList]="callingList$ | async"
      ></calling-list-card-details>
    </div>
    <div class="col-md-6"></div>
  </div>
  <div class="row" [@fadeInUp]>
    <div class="col-md-12">
      <div *ngIf="callingList$ | async as callingList">
        <calling-list-contact-list
          [callingList]="callingList"
        ></calling-list-contact-list>
      </div>
      <list-item-empty *ngIf="(callingList$ | async)?.progress.contacts === 0">
        {{ "no_hits" | translate }}
      </list-item-empty>
    </div>
  </div>
</div>
