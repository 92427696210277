import { Injectable } from "@angular/core";
import * as salesMeetingActions from "@app/contacts/contact-sales-meetings/contact-sales-meetings.actions";
import * as contactActions from "@app/contacts/contact.actions";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { IntegrationResource } from "@app/integrations/models/enums";
import { Integration } from "@app/integrations/models/Integration";
import { QObject, SalesMeeting, TypedPaginationListDTO } from "@app/models";
import { Municipality } from "@app/models/municipality";
import {
  CONNECT_IN_EXTERNAL_PROVIDER,
  CREATE_IN_EXTERNAL_PROVIDER,
} from "@app/shared/utils/tab-types";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import * as externalProviderActions from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  concatMap,
  from,
  map,
  of as observableOf,
  switchMap,
} from "rxjs";

@Injectable()
export class ExternalProviderEffects {
  updateResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.updateResidenceRequest),
      switchMap(({ residence, extraData, eaOid, contactId, hasObjectLinks }) =>
        this.apiService.patch(`residences/${eaOid}`, residence).pipe(
          map(() =>
            externalProviderActions.updateResidenceSuccess({
              eaOid,
              contactId,
              hasObjectLinks,
              extraData: extraData ?? null,
            })
          ),
          catchError(() =>
            observableOf(externalProviderActions.updateResidenceFail())
          )
        )
      )
    )
  );

  updateResidenceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.updateResidenceSuccess),
      map(({ type, ...params }) =>
        externalProviderActions.patchProviderResidenceRequest({ params })
      )
    )
  );

  updateContactRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.updateContactRequest),
      switchMap(
        ({ contact, residence, extraData, eaOid, contactId, hasObjectLinks }) =>
          this.apiService.patch(`contacts/${contactId}`, contact).pipe(
            map(() =>
              externalProviderActions.updateContactSuccess({
                residence,
                eaOid,
                contactId,
                hasObjectLinks,
                extraData: extraData ?? null,
              })
            ),
            catchError(() =>
              observableOf(externalProviderActions.updateContactFail())
            )
          )
      )
    )
  );

  updateContactSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.updateContactSuccess),
      concatMap(({ residence, extraData, eaOid, contactId, hasObjectLinks }) =>
        from([
          externalProviderActions.updateResidenceRequest({
            residence,
            eaOid,
            contactId,
            hasObjectLinks,
            extraData,
          }),
          contactActions.getContactRequest({ id: contactId }),
        ])
      )
    )
  );

  patchExternalProviderResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchProviderResidenceRequest),
      switchMap(({ params }) =>
        this.apiService
          .patchWithoutResponse(
            `contact/${params.contactId}/sells-object/${params.eaOid}`,
            { data: params.extraData },
            "integrations"
          )
          .pipe(
            map(() =>
              externalProviderActions.patchProviderResidenceSuccess({
                eaOid: params.eaOid,
                hasObjectLinks: params.hasObjectLinks,
              })
            ),
            catchError((error) => {
              return observableOf(
                externalProviderActions.patchProviderResidenceFail({
                  message: JSON.parse(error._body).message === "INVALID_TOWN",
                })
              );
            })
          )
      )
    )
  );

  patchExternalProviderResidenceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchProviderResidenceSuccess),
      switchMap(({ eaOid, hasObjectLinks }) => {
        const requests = [];
        if (hasObjectLinks) {
          requests.push(
            externalProviderActions.getExternalProviderLinkRequest({ eaOid })
          );
        } else {
          requests.push(
            toastActions.success({
              message: "create_residence_in_external_provider_success",
            })
          );
          requests.push(
            sidebarActions.closeTab({ tabType: CREATE_IN_EXTERNAL_PROVIDER })
          );
        }
        return from(requests);
      })
    )
  );

  patchExternalProviderResidenceFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchProviderResidenceFail),
      map(() => toastActions.danger({ message: "create_residence_failed" }))
    )
  );

  getResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getResidenceRequest),
      switchMap(({ eaOid }) =>
        this.apiService.get(`residences/${eaOid}`).pipe(
          map((response: QObject) =>
            externalProviderActions.getResidenceSuccess({ qObject: response })
          ),
          catchError(() =>
            observableOf(externalProviderActions.getResidenceFail())
          )
        )
      )
    )
  );

  getMunicipalitiesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getMunicipalitiesRequest),
      switchMap(({ params }) =>
        this.apiService.get(`municipalities/search`, params).pipe(
          map((response: TypedPaginationListDTO<Municipality>) =>
            externalProviderActions.getMunicipalitiesSuccess({
              municipalities: response.rows,
            })
          ),
          catchError(() =>
            observableOf(externalProviderActions.getMunicipalitiesFail())
          )
        )
      )
    )
  );

  getPreselectedMunicipalityRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getPreselectedMunicipalityRequest),
      switchMap(({ params }) =>
        this.apiService.get(`municipalities/search`, params).pipe(
          map((response: TypedPaginationListDTO<Municipality>) =>
            externalProviderActions.getPreselectedMunicipalitySuccess({
              municipality: response.rows[0],
            })
          ),
          catchError(() =>
            observableOf(
              externalProviderActions.getPreselectedMunicipalityFail()
            )
          )
        )
      )
    )
  );

  getContactResidencesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getContactResidencesRequest),
      switchMap(({ params }) =>
        this.apiService.get(`objects/search`, params, "api").pipe(
          map((response: TypedPaginationListDTO<QObject>) =>
            externalProviderActions.getContactResidencesSuccess({
              qObjects: response.rows,
            })
          ),
          catchError(() =>
            observableOf(externalProviderActions.getContactResidencesFail())
          )
        )
      )
    )
  );

  getObjectStatusesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getObjectStatusesRequest),
      switchMap(({ country }) =>
        this.apiService.get(`object-status/search`, { country }).pipe(
          map((response: TypedPaginationListDTO<ObjectStatus>) =>
            externalProviderActions.getObjectStatusesSuccess({
              objectStatuses: response.rows,
            })
          ),
          catchError(() =>
            observableOf(externalProviderActions.getObjectStatusesFail())
          )
        )
      )
    )
  );

  getObjectSearchResultRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getObjectSearchResultsRequest),
      switchMap(({ params }) =>
        this.apiService.get(`search`, params).pipe(
          map((response: { matches: { locations: QObject[] } }) =>
            externalProviderActions.getObjectSearchResultsSuccess({
              locations: response.matches.locations,
            })
          ),
          catchError(() =>
            observableOf(externalProviderActions.getObjectSearchResultsFail())
          )
        )
      )
    )
  );

  patchSalesMeetingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchSalesMeetingResidenceRequest),
      switchMap(({ eaOid, eaSalesMeetingId }) =>
        this.apiService
          .patch(`sales-meetings/${eaSalesMeetingId}`, {
            eaOid,
          })
          .pipe(
            map((response: SalesMeeting) =>
              externalProviderActions.patchSalesMeetingResidenceSuccess({
                contactId: response.contactId,
                eaCrmSalesMeetingId: eaSalesMeetingId,
              })
            ),
            catchError(() =>
              observableOf(
                externalProviderActions.patchSalesMeetingResidenceFail()
              )
            )
          )
      )
    )
  );

  patchSalesMeetingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchSalesMeetingResidenceSuccess),
      concatMap(({ contactId, eaCrmSalesMeetingId }) =>
        from([
          salesMeetingActions.getSalesMeetingsRequest({ contactId }),
          salesMeetingActions.getSalesMeetingDetailsRequest({
            eaCrmSalesMeetingId,
          }),
          toastActions.success({ message: "object_connected_success" }),
          sidebarActions.closeTab({ tabType: CONNECT_IN_EXTERNAL_PROVIDER }),
        ])
      )
    )
  );

  patchSalesMeetingFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.patchSalesMeetingResidenceFail),
      map(() => toastActions.danger({ message: "object_connected_failed" }))
    )
  );

  getOpenInExternalProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getOpenInExternalProviderRequest),
      switchMap(() =>
        this.apiService.get(``, {}, "integrations").pipe(
          map((response: Integration) =>
            externalProviderActions.getOpenInExternalProviderSuccess({
              hasOpenIn: response.integratedResources.includes(
                IntegrationResource.SalesMeetingExternalCreationUrlGeneration
              ),
            })
          ),
          catchError(() =>
            observableOf(
              externalProviderActions.getOpenInExternalProviderFail()
            )
          )
        )
      )
    )
  );

  openCreationFormInExternalProvider$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.openCreationFormInExternalProviderRequest),
      switchMap(({ eaCrmSalesMeetingId }) =>
        this.apiService
          .post(
            `create-url/for/form-creation/of/sales-meeting/${eaCrmSalesMeetingId}`,
            {},
            "integrations"
          )
          .pipe(
            map((response: { url: string }) =>
              externalProviderActions.openCreationFormInExternalProviderSuccess(
                { url: response.url }
              )
            ),
            catchError(() =>
              observableOf(
                externalProviderActions.openCreationFormInExternalProviderFail()
              )
            )
          )
      )
    )
  );

  openCreationFormInExternalProviderSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          externalProviderActions.openCreationFormInExternalProviderSuccess
        ),
        map(({ url }) => {
          window.open(url);
        })
      ),
    { dispatch: false }
  );

  openCreationFormInExternalProviderFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.openCreationFormInExternalProviderFail),
      map(() => toastActions.danger({ message: "open_creation_form_fail" }))
    )
  );

  getExternalProviderLinkRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getExternalProviderLinkRequest),
      switchMap(({ eaOid }) =>
        this.apiService
          .get(`external-meta-data/object/${eaOid}`, {}, "integrations")
          .pipe(
            map(
              (response: {
                presentableLinks: { OBJECT_INFORMATION: { link: string } };
              }) =>
                externalProviderActions.getExternalProviderLinkSuccess({
                  eaOid: response.presentableLinks.OBJECT_INFORMATION
                    ? eaOid
                    : null,
                })
            ),
            catchError(() =>
              observableOf(
                externalProviderActions.getExternalProviderLinkFail()
              )
            )
          )
      )
    )
  );

  getExternalProviderLinkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getExternalProviderLinkSuccess),
      concatMap(({ eaOid }) =>
        from([
          toastActions.success(
            {
              message: "create_residence_in_external_provider_success",
              duration: 8000,
            },
            {
              icon: "icon-arrow-right",
              label: this.translateService.instant("go_to_object"),
              action: RouterActions.go({ path: ["crm", "showings", eaOid] }),
            }
          ),
          sidebarActions.closeTab({ tabType: CREATE_IN_EXTERNAL_PROVIDER }),
        ])
      )
    )
  );

  getExternalProviderLinkFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(externalProviderActions.getExternalProviderLinkFail),
      concatMap(() =>
        from([
          toastActions.success({
            message: "create_residence_in_external_provider_success",
            duration: 5000,
          }),
          sidebarActions.closeTab({ tabType: CREATE_IN_EXTERNAL_PROVIDER }),
        ])
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private translateService: TranslateService
  ) {}
}
