import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ResourceFooterComponent } from "./resource-footer/resource-footer.component";
import { ResourceHeaderComponent } from "./resource-header/resource-header.component";
import { ResourcesEmptyComponent } from "./resources-empty/resources-empty.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";

const components = [
  ResourceHeaderComponent,
  ResourceFooterComponent,
  ResourcesEmptyComponent,
];

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, UiComponentsModule],
  declarations: components,
  exports: components,
})
export class ResourceModule {}
