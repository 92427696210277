<span class="text-muted">
  <small>{{ "presets" | translate }}: </small>
  <small (click)="dateClicked.emit(1)" class="text-link" translate>{{
    "tomorrow" | translate
  }}</small>
  <small> - </small>
  <small (click)="dateClicked.emit(7)" class="text-link">
    {{ "in_X_days" | translate : { amount: 7 } }}
  </small>
  <small> - </small>
  <small (click)="dateClicked.emit(14)" class="text-link">
    {{ "in_X_days" | translate : { amount: 14 } }}
  </small>
  <span> - </span>
  <small (click)="dateClicked.emit(30)" class="text-link">
    {{ "in_X_days" | translate : { amount: 30 } }}
  </small>
</span>
