import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CallingListService } from "@app/core/ngrx/entity-services/calling-list.service";
import { fadeInLeft, fadeInRight, fadeInUp } from "@app/shared/animations";
import { Store } from "@ngrx/store";
import * as RouterActions from "app/core/ngrx/router/router.actions";
import { BehaviorSubject, filter, first, map, Subject, takeUntil } from "rxjs";
import { AppState } from "../app.reducer";
import { CallingList } from "../lists/lists-calling-lists/models/calling-list";
import { LISTS_CRM_MODULE } from "../shared/utils/crm-modules";
import { SIDEBAR_SEND_MESSAGE_URL } from "../shared/utils/sidebar-tab-utils";
import * as callingListActions from "./ngrx/calling-lists.actions";

@Component({
  selector: "calling-list-overview",
  templateUrl: "./calling-list-overview.component.html",
  styleUrls: ["./calling-list-overview.component.scss"],
  animations: [fadeInUp(), fadeInRight(), fadeInLeft()],
})
export class CallingListOverviewComponent implements OnInit, OnDestroy {
  callingList$: BehaviorSubject<CallingList> = new BehaviorSubject<CallingList>(
    null
  );
  unsubscribe$ = new Subject<void>();
  listsCrmModule = LISTS_CRM_MODULE;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    public callingListService: CallingListService
  ) {}

  ngOnInit(): void {
    this.store.dispatch(callingListActions.reset());
    this.getCallingList();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openSendMessageSidebar(): void {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm/",
          {
            outlets: {
              sidebar: SIDEBAR_SEND_MESSAGE_URL,
            },
          },
        ],
      })
    );
  }

  private getCallingList(): void {
    this.route.params
      .pipe(
        map((params) => params?.callingListId),
        filter((value) => !!value),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((id: string) => {
        this.callingListService
          .getById(id)
          .pipe(
            first(),
            filter((callingList) => !!callingList)
          )
          .subscribe((callingList) => {
            this.store.dispatch(
              callingListActions.setSelectedCallingListId({ callingListId: id })
            );
            this.callingList$.next(callingList);
          });
      });
  }
}
