import { loginReducer, LoginState } from "@app/login/ngrx/login.reducer";
import { combineReducers } from "@ngrx/store";
import {
  forgotPasswordReducer,
  ForgotPasswordState,
} from "./forgot-password-form/forgot-password/forgot-password.reducer";
import {
  selectOfficeReducer,
  SelectOfficeState,
} from "./select-office/select-office.reducer";

export interface LoginModuleState {
  selectOffice: SelectOfficeState;
  forgotPassword: ForgotPasswordState;
  loginMethod: LoginState;
}

const reducers = {
  selectOffice: selectOfficeReducer,
  forgotPassword: forgotPasswordReducer,
  loginMethod: loginReducer,
};

export function loginModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
