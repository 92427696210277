import { MilesData } from "@app/miles/miles.component";
import { SalesMeeting } from "@app/models";
import { ShowingExtended } from "@app/showings/models";
import { createAction, props } from "@ngrx/store";

export const getSalesMeetingsRequest = createAction(
  "[Miles] GET_SALESMEETINGS_REQUEST",
  props<{
    eaEmployeeId: string;
    sortBy: string;
    sortOrder: string;
    startTimeFrom: string;
    startTimeTo: string;
    status: string;
  }>()
);

export const getSalesMeetingsSuccess = createAction(
  "[Miles] GET_SALESMEETINGS_SUCCESS",
  props<{ salesMeetings: SalesMeeting[] }>()
);

export const getSalesMeetingsFailed = createAction(
  "[Miles] GET_SALESMEETINGS_FAILED"
);

export const getShowingsRequest = createAction(
  "[Miles] GET_SHOWINGS_REQUEST",
  props<{
    eaEmployeeId: string;
    sortBy: string;
    sortOrder: string;
    from: string;
    to: string;
  }>()
);

export const getShowingsSuccess = createAction(
  "[Miles] GET_SHOWINGS_SUCCESS",
  props<{ showings: ShowingExtended[] }>()
);

export const getShowingsFailed = createAction("[Miles] GET_SHOWINGS_FAILED");

export const getMilesDataRequest = createAction(
  "[Miles] GET_MILES_DATA_REQUEST",
  props<{
    eaEmployeeId: string;
    year: string;
    month: string;
  }>()
);

export const getMilesDataSuccess = createAction(
  "[Miles] GET_MILES_DATA_SUCCESS",
  props<{ miles: MilesData[] }>()
);

export const getMilesDataFailed = createAction("[Miles] GET_MILES_DATA_FAILED");

export const storeMilesDataRequest = createAction(
  "[Miles] STORE_MILES_DATA_REQUEST",
  props<{
    eaEmployeeId: string;
    year: string;
    month: string;
    data: string;
  }>()
);

export const storeMilesDataSuccess = createAction(
  "[Miles] STORE_MILES_DATA_SUCCESS"
);

export const storeMilesDataFailed = createAction(
  "[Miles] STORE_MILES_DATA_FAILED"
);

export const deleteMilesDataRequest = createAction(
  "[Miles] DELETE_MILES_DATA_REQUEST",
  props<{
    eaEmployeeId: string;
    year: string;
    month: string;
  }>()
);

export const deleteMilesDataSuccess = createAction(
  "[Miles] DELETE_MILES_DATA_SUCCESS"
);

export const deleteMilesDataFailed = createAction(
  "[Miles] DELETE_MILES_DATA_FAILED"
);
