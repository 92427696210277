<div
  class="contact-card"
  (click)="onContactClick($event)"
>
  <div class="box-shadow"></div>
  <div class="card-content">
    <div>
      <div *ngIf="!!contact?.entity?.contactType"
           class="icon-container"
           [class]="'icon ' + contactIconFunctions.getContactIconClass(contact?.entity?.contactType)">
        <i [class]="'fa-light fa-' + contactIconFunctions.getContactIcon(contact?.entity?.contactType)"
           style="font-size: 40px"></i>
      </div>
    </div>
    <div>
      <h4 class="title">
        <span class="name-wrapper">
          {{ contact?.entity?.firstName }} {{ contact?.entity?.familyName }}
        </span>&nbsp;
      </h4>
      <div class="metadata">
        <span *ngIf="contact?.entity?.msisdn">
          {{ contact?.entity?.msisdn | phoneFormat:(contact?.entity?.msisdnCountry)}}
        </span>
        <span *ngIf="contact?.entity?.email">
            {{ contact?.entity?.email}}
        </span>
        <span *ngIf="contact?.entity?.street">
          {{ contact?.entity?.street }} {{ contact?.entity?.zip }}, {{ contact?.entity?.city }}
        </span>
      </div>
    </div>
  </div>
  <div class="view-more-icon">
    <app-icon name="arrow-right" size="large"></app-icon>
  </div>
</div>
