import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "propertyTypeToIcon",
})
export class PropertyTypeToIconPipe implements PipeTransform {
  transform(type: "project" | "condominium" | "rental"): unknown {
    switch (type) {
      case "condominium":
        return "house";
      case "project":
        return "city";
      case "rental":
        return "house";
      default:
        return "house";
    }
  }
}
