import * as loginActions from "@app/login/ngrx/login.actions";
import { Action, createReducer, on } from "@ngrx/store";

export interface LoginState {
  supportedLoginType: string;
  showLoginScreen: boolean;
  redirectUrl: string;
  isLoaded: boolean;
}

export const initialState: LoginState = {
  supportedLoginType: "",
  showLoginScreen: true,
  redirectUrl: "",
  isLoaded: false,
};

export function loginReducer(state: LoginState, action: Action): LoginState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(
    loginActions.checkLoginMethodSuccess,
    (state, { supportedLoginType, redirectUrl, showLoginScreen }) => ({
      ...state,
      supportedLoginType,
      redirectUrl,
      showLoginScreen,
      isLoaded: true,
    })
  ),
  on(loginActions.clearLoginMethod, (state) => ({
    ...state,
    ...initialState,
  }))
);
