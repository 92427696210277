import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from "@angular/forms";

export function markAllAsDirty(control: any): void {
  if (control instanceof FormControl) {
    control.markAsDirty();
  } else {
    Object.keys(control.controls).forEach((c: string) =>
      markAllAsDirty(control.get(c))
    );
  }
}

export function markAllAsTouched(control: any): void {
  if (control instanceof FormControl) {
    control.markAsTouched();
  } else {
    Object.keys(control.controls).forEach((c: string) =>
      markAllAsTouched(control.get(c))
    );
  }
}

export function setValueOnAllControls(
  control: AbstractControl,
  value: any,
  options: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  } = {}
) {
  if (control instanceof FormControl) {
    control.setValue(value, options);
  } else if (control instanceof FormGroup) {
    Object.keys(control.controls).forEach((key) =>
      setValueOnAllControls(control.get(key), value, options)
    );
  } else if (control instanceof FormArray) {
    control.controls.forEach((_control) =>
      setValueOnAllControls(_control, value, options)
    );
  }
}
