<ng-container *ngIf="setting$ | async as setting; else noData">
  <h3 *ngIf="showHeader">
    <app-icon [name]="setting.icon" size="large"></app-icon>&nbsp;
    <span translate>{{ setting.title }}</span>
  </h3>
  <div class="widget-container">
    <div class="widget-container__data">
      <iframe
        [src]="setting.url | safe"
        style="height: 100vh; width: 100%"
      ></iframe>
    </div>
    <div class="statistics-widget__show-more-btn">
      <a [href]="setting.url" target="_blank" class="btn btn-default">
        {{ "open_in_new_tab" | translate }}
        <app-icon name="chevron-right"></app-icon>
      </a>
    </div>
  </div>
</ng-container>
<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
