<app-q-modal>
  <ng-container slot="header">
    {{ "add_object_links" | translate }}
    <i class="icon-question" [tooltip]="'object_link_tooltips' | translate"></i>
  </ng-container>
  <ng-container slot="body">
    <form [formGroup]="objectLinksForm" class="form-container">
      <div class="select-container">
        <app-select-object (chosenObjectChanged)="handleObjectChosen($event)">
        </app-select-object>
      </div>
      <div class="form-group" formArrayName="links">
        <ng-container *ngIf="hasLinks && isObjectSelected">
          <ng-container
            *ngFor="let link of linksFormArray.controls; let i = index"
          >
            <label class="checkbox-group">
              <span class="name">
                {{ !!objectLinks[i]?.name ? objectLinks[i]?.name : "-" }}&nbsp;
              </span>
              <br />
              <div
                (click)="openUrl(objectLinks[i].url)"
                class="url"
                [tooltip]="'open_in_new_tab' | translate"
              >
                {{ objectLinks[i].url }}&nbsp;
              </div>
              <input type="checkbox" [formControl]="link" />
              <span class="checkbox-group__checkmark"></span>
            </label>
          </ng-container>
        </ng-container>
        <div *ngIf="!hasLinks && isObjectSelected" class="alert alert-info">
          {{ "object_has_no_links" | translate }}
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button
      buttonType="cancel"
      label="cancel"
      classes="margin-right"
      (click)="closeModal()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="add"
      [disabled]="!this.objectLinksForm.valid"
      (click)="handleAddLinks()"
    >
    </app-q-button>
  </ng-container>
</app-q-modal>
