import { ExternalBankTipsStatisticsResponse } from "@app/statistics/statistics-external-bank-tips/models/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsActions from "./statistics-external-bank-tips.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsExternalBankTipsState {
  loading: boolean;
  csvLoading: boolean;
  statistics: ExternalBankTipsStatisticsResponse | null;
}

export const initialState: StatisticsExternalBankTipsState = {
  loading: false,
  csvLoading: false,
  statistics: null,
};

export function statisticsExternalBankTipsReducer(
  state: StatisticsExternalBankTipsState,
  action: Action
): StatisticsExternalBankTipsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsActions.getExternalBankTipsStatisticsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(
    statisticsActions.getExternalBankTipsStatisticsSuccess,
    (state, { statistics }) => ({
      ...state,
      loading: false,
      statistics,
    })
  ),
  on(statisticsActions.getExternalBankTipsStatisticsFailure, (state) => ({
    ...state,
    loading: false,
  })),
  on(statisticsActions.getExternalBankTipsCSVRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(
    statisticsActions.getExternalBankTipsCSVSuccess,
    statisticsActions.getExternalBankTipsCSVFailure,
    (state) => ({
      ...state,
      csvLoading: false,
    })
  )
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.externalBankTips
);
export const getLoading = createSelector(
  getState,
  (state: StatisticsExternalBankTipsState) => state.loading
);

export const getCSVLoading = createSelector(
  getState,
  (state: StatisticsExternalBankTipsState) => state.csvLoading
);

export const getStatistics = createSelector(
  getState,
  (state: StatisticsExternalBankTipsState) => state.statistics
);
