import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { CalendarWidgetModule } from "@app/shared/modules/calendar-widget/calendar-widget.module";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { RichTextEditorModule } from "@app/shared/modules/form-components/rich-text-editor/rich-text-editor.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { MessageTemplatesDynamicContentsComponent } from "@app/sidebar/shared/message-templates-dynamic-contents/message-templates-dynamic-contents.component";
import { NgxErrorsModule } from "@ngspot/ngx-errors";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { QuillModule } from "ngx-quill";
import { AddContactAsPotentialbuyerModalComponent } from "./add-contact-as-potentialbuyer-modal/add-contact-as-potentialbuyer-modal.component";
import { AddShowingModalNewComponent } from "./add-showing-modal-new/add-showing-modal-new.component";
import { CompanyFormComponent } from "./company-form/company-form.component";
import { ContactFormComponent } from "./contact-form/contact-form.component";
import { CreateContactSimpleComponent } from "./create-contact-simple/create-contact-simple.component";
import { EmployeeFormComponent } from "./employee-form/employee-form.component";
import { EstateFormComponent } from "./estate-form/estate-form.component";
import { SidebarHeaderComponent } from "./sidebar-header/sidebar-header.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ErpFormElementsModule } from "@app/erp/components/form-elements/erp-form-elements.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    SearchContactModule,
    SearchAddressModule,
    TranslateModule,
    NgxErrorsModule,
    FormComponentsModule,
    RichTextEditorModule,
    QuillModule.forRoot(),
    UiComponentsModule,
    CalendarWidgetModule,
    ContactComponentsModule,
    MatCheckboxModule,
    UtilModule,
    MatTooltipModule,
    ErpFormElementsModule,
  ],
  exports: [
    ContactFormComponent,
    CreateContactSimpleComponent,
    SidebarHeaderComponent,
    EmployeeFormComponent,
    MessageTemplatesDynamicContentsComponent,
    CompanyFormComponent,
    EstateFormComponent,
    AddShowingModalNewComponent,
  ],
  declarations: [
    ContactFormComponent,
    CreateContactSimpleComponent,
    SidebarHeaderComponent,
    EmployeeFormComponent,
    MessageTemplatesDynamicContentsComponent,
    CompanyFormComponent,
    EstateFormComponent,
    AddShowingModalNewComponent,
    AddContactAsPotentialbuyerModalComponent,
  ],
})
export class SidebarSharedModule {}
