import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { EmployeeDTO } from "@app/models";
import { SIDEBAR_EMPLOYEE_OFFICE_EMPLOYMENT_URL } from "@app/shared/utils/sidebar-tab-utils";
import { EMPLOYEES } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, from as observableFrom, switchMap } from "rxjs";
import { resetDirty } from "../../../ngrx/sidebar.actions";
import * as employeeActions from "./employees-create.actions";

@Injectable()
export class EmployeesCreateEffects {
  createEmployeeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(employeeActions.createEmployeeRequest),
      switchMap(({ employee, eaOfficeId }) =>
        this.apiService.post("employees", employee).pipe(
          concatMap((res: EmployeeDTO) =>
            observableFrom([
              employeeActions.createEmployeeSuccess({ employee: res }),
              toastActions.success({ message: "broker_created" }),
              resetDirty({ tabType: EMPLOYEES }),
              RouterActions.go({
                path: [
                  "/crm",
                  {
                    outlets: {
                      sidebar: SIDEBAR_EMPLOYEE_OFFICE_EMPLOYMENT_URL(
                        res.eaEmployeeId,
                        eaOfficeId
                      ),
                    },
                  },
                ],
              }),
            ])
          ),
          catchError((error) =>
            observableFrom([
              employeeActions.createEmployeeFailed(),
              toastActions.danger(error),
            ])
          )
        )
      )
    )
  );

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
