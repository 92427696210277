import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ApiService } from "@app/core/services/api/api.service";
import { getFeature } from "@app/shared/config/config.reducer";
import { AdfenixMarketingReportFeature } from "@app/shared/config/models/adfenix-marketing-report-feature";
import { ADFENIX_PROPERTY_MARKETING } from "@app/shared/config/utils/features";
import { QTableComponent } from "@app/shared/modules/ui-components/q-table/q-table.component";
import { getEaOfficesIds, hasRole } from "@app/shared/user";
import { API_ONLY_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import {
  ObjectPartitioned,
  ObjectPartitionedFilters,
  ObjectPartitionedResponse,
} from "@app/statistics/statistics-adfenix-reporting/models/object-partitioned";
import { select, Store } from "@ngrx/store";
import moment from "moment/moment";
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  first,
  map,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-statistics-adfenix-reporting-table",
  templateUrl: "./statistics-adfenix-reporting-table.component.html",
  styleUrls: ["./statistics-adfenix-reporting-table.component.scss"],
})
export class StatisticsAdfenixReportingTableComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() filters: ObjectPartitionedFilters;
  @Input() enableCustomisation = true;
  @Input() triggerDataFetch$: Observable<void>;

  @ViewChild(QTableComponent, { static: false }) qTable: QTableComponent;
  columns = ["columnName", "value", "numberOfObjects", "average", "impact"];
  sortableColumns = [];
  headers = {
    columnName: "name",
    value: "adfenix_reporting_total",
    numberOfObjects: "number_of_properties",
  };
  adfenixMarketingFeature$: Observable<AdfenixMarketingReportFeature>;
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  values$: BehaviorSubject<ObjectPartitioned[]> = new BehaviorSubject<
    ObjectPartitioned[]
  >([]);
  unsubscribe$ = new Subject<void>();

  constructor(private apiService: ApiService, private store: Store<AppState>) {
    this.adfenixMarketingFeature$ = this.store.pipe(
      select(getFeature(ADFENIX_PROPERTY_MARKETING))
    );
  }

  ngOnInit() {
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.fetchStatistics({
          ...this.filters,
        });
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.filters && !!this.filters) {
      this.fetchStatistics({
        ...this.filters,
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private formatFilters(params): ObjectPartitionedFilters {
    return {
      ...params,
      startDate: !!params.startDate
        ? moment(params.startDate).format(API_ONLY_DATE_FORMAT)
        : null,
      endDate: !!params.endDate
        ? moment(params.endDate).format(API_ONLY_DATE_FORMAT)
        : null,
      dataTypes: [params.dataTypes],
      eaOfficeId:
        !!params.eaOfficeId && params.eaOfficeId.length > 0
          ? params.eaOfficeId
          : null,
      objectTypes:
        !!params.objectTypes && params.objectTypes.length > 0
          ? params.objectTypes.join(",")
          : null,
    };
  }

  private fetchStatistics(params?: Partial<ObjectPartitionedFilters>) {
    this.loading$.next(true);

    combineLatest([
      this.adfenixMarketingFeature$.pipe(
        first(),
        map((feature) => {
          return !!feature?.campaignGroup
            ? feature.campaignGroup.split(",")
            : null;
        })
      ),
      this.store.pipe(select(hasRole(ROLE_ADMIN))),
      this.store.pipe(select(hasRole(ROLE_MANAGER))),
      this.store.pipe(select(getEaOfficesIds)),
    ])
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        switchMap(([campaignGroup, isAdmin, isManager, officeIds]) => {
          if (isManager) {
            params.eaOfficeId = null;
          } else if (!isAdmin) {
            params.eaOfficeId = officeIds;
          }

          return this.apiService
            .post(
              "stats/object-partitioned",
              this.formatFilters({
                ...params,
                groupBy: "campaignGroup",
                campaignGroup,
              })
            )
            .pipe(
              map((res: ObjectPartitionedResponse) => ({ res, campaignGroup }))
            );
        })
      )
      .subscribe({
        next: ({ res, campaignGroup }) => {
          const result = [res.values[0].nonPartitioned];
          const values = res.values[0].values;

          if (!!campaignGroup) {
            campaignGroup.forEach((group) => {
              const found = values.find(
                (value) => value.categoryGroup === group
              );

              if (!!found) {
                result.push(found);
              }
            });
          } else {
            result.push(...values);
          }

          this.values$.next(result);
          this.loading$.next(false);
        },
        error: () => {
          this.loading$.next(false);
        },
      });
  }
}
