import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import { fadeInOutTrigger } from "@app/shared/animations";
import { Select } from "@app/shared/modules/ui-components/q-select/q-select.component";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { markAllAsTouched } from "@app/shared/utils/form-utils";
import { AdjacentResourcesOptions } from "@app/showings/enums/debugger-enums";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, first, Observable, Subject } from "rxjs";
import {
  DebuggerObjectService,
  RequestType,
} from "../../services/debugger-object.service";

@Component({
  selector: "app-debugger-object-modal",
  templateUrl: "./debugger-object-modal.component.html",
  styleUrls: ["./debugger-object-modal.component.scss"],
  providers: [DebuggerObjectService],
  animations: [fadeInOutTrigger()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebuggerObjectModalComponent implements OnInit, OnDestroy {
  form: FormGroup;
  requestType: RequestType = "remigrate";
  adjacentResources: Select = AdjacentResourcesOptions;
  debuggerResponse$ = new BehaviorSubject<any>(null);
  isMobile$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DebuggerObjectModalComponent>,
    private fb: FormBuilder,
    private store: Store<AppState>,
    public debuggerObjectService: DebuggerObjectService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.fillForm();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  submit() {
    if (this.form.invalid) {
      markAllAsTouched(this.form);
      return;
    }

    let adjacentResourcesToRemigrate = this.form.get("adjacentResources").value;
    if (this.form.get("adjacentResources").value.length === 0) {
      adjacentResourcesToRemigrate = this.adjacentResources.map(
        (adjacent) => adjacent.value
      );
    }

    const params = {
      eaOid: this.form.get("eaOid").value,
      adjacentResourcesToRemigrate,
    };

    this.debuggerObjectService
      .storeDebuggerObject(this.requestType, params)
      .pipe(first())
      .subscribe((res) => {
        this.debuggerResponse$.next(res);
      });
  }

  closeModal() {
    this.dialogRef.close();
  }

  private mapStateToProps() {
    this.isMobile$ = this.store.pipe(select(fromShared.isMobile));
  }

  private buildForm() {
    this.form = this.fb.group({
      eaOid: [null, Validators.required],
      adjacentResources: [[]],
    });
  }

  private fillForm() {
    this.requestType = this.data?.requestType;
    this.form.get("eaOid").setValue(this.data?.eaOid);
  }

  copyResponse(event: MouseEvent, response: any) {
    event.stopPropagation();
    navigator.clipboard.writeText(JSON.stringify(response));
  }
}
