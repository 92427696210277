import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import {
  CALLING_LISTS_SEARCH,
  CALLING_LIST_ID,
} from "@app/core/services/api/utils/api-endpoints";
import { CallingList } from "@app/lists/lists-calling-lists/models/calling-list";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import { catchError, map, Observable, takeUntil } from "rxjs";

@Injectable({ providedIn: "root" })
export class CallingListService extends CustomEntityCollectionServiceBase<CallingList> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("CallingList", serviceElementsFactory);
  }

  getById = (
    id: string,
    additionalParams?: { [key: string | number]: unknown }
  ) => {
    this.setLoading(true);
    return this.apiService
      .get(CALLING_LIST_ID(id), { ...additionalParams }, "api")
      .pipe(
        map((response: any) => {
          const callingList = response;
          this.upsertOneInCache(callingList);
          this.setLoading(false);
          return callingList;
        }),
        catchError((err) => {
          return err;
        })
      );
  };

  // @ts-ignore
  getWithQuery = (params, setListDefaults = true) => {
    this.setLoading(true);
    if (setListDefaults) {
      this.refreshCurrentList = () =>
        this.getWithQuery(params, setListDefaults);
      this.cancelExistingRequest$.next();
    }
    return this.apiService.get(CALLING_LISTS_SEARCH, { ...params }, "api").pipe(
      takeUntil(this.cancelExistingRequest$),
      map((response: any) => {
        const callingLists = response.callingLists;
        this.upsertManyInCache(callingLists);
        this.setLoading(false);
        if (setListDefaults) {
          this.setListDefaults(callingLists, response);
        }
        return callingLists;
      }),
      catchError((err) => {
        return err;
      })
    );
  };

  delete = (id) => {
    this.removeOneFromCache(id);
    this.handleDelete(id);
    return this.apiService.delete(CALLING_LIST_ID(id), {}).pipe(
      map(() => {
        this.appStore.dispatch(
          toastActions.success({ message: "delete_calling_list_success" })
        );
        return id;
      }),
      catchError((err) => {
        return err;
      })
    );
  };

  reassign = (id: string, additionalParams?: any) => {
    return this.apiService
      .patch(`calling-lists/reassign/${id}`, { ...additionalParams }, "api")
      .pipe(
        map((response: any) => {
          this.appStore.dispatch(
            toastActions.success({ message: "calling_list_reassigned" })
          );
          // Todo: When we get response from backend, please update cache with relevant data
          return response;
        }),
        catchError((err) => {
          return err;
        })
      );
  };

  // Task Attemps
  postUpload = (formData: FormData): Observable<any> => {
    this.setLoading(true);
    return this.apiService
      .postWithFormData(`calling-lists/upload`, formData, "api")
      .pipe(
        map((response: any) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.success({ message: "new_calling_list_uploaded" })
          );
          return response;
        }),
        catchError((err) => {
          return err;
        })
      );
  };
}
