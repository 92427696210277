import { ExternalTip } from "@app/models/external-tips";
import { createAction, props } from "@ngrx/store";

export const getExternalTipsRequest = createAction(
  "[Manage External Tips] GET_EXTERNAL_TIPS_REQUEST",
  props<{ officeId: string }>()
);

export const getExternalTipsSuccess = createAction(
  "[Manage External Tips] GET_EXTERNAL_TIPS_SUCCESS",
  props<{ externalTips: ExternalTip[] }>()
);

export const getExternalTipsFailed = createAction(
  "[Manage External Tips] GET_EXTERNAL_TIPS_FAILED"
);

export const getAllExternalTipsRequest = createAction(
  "[Manage External Tips] GET_ALL_EXTERNAL_TIPS_REQUEST"
);

export const getAllExternalTipsSuccess = createAction(
  "[Manage External Tips] GET_ALL_EXTERNAL_TIPS_SUCCESS",
  props<{ externalTips: ExternalTip[] }>()
);

export const getAllExternalTipsFailed = createAction(
  "[Manage External Tips] GET_ALL_EXTERNAL_TIPS_FAILED"
);

export const deleteExternalTipsRequest = createAction(
  "[Manage External Tips] DELETE_EXTERNAL_TIPS_REQUEST",
  props<{ id: string }>()
);

export const deleteExternalTipsSuccess = createAction(
  "[Manage External Tips] DELETE_EXTERNAL_TIPS_SUCCESS"
);

export const deleteExternalTipsFailed = createAction(
  "[Manage External Tips] DELETE_EXTERNAL_TIPS_FAILED"
);
