import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ContactConsent } from "@app/contacts/contact-consents/models";
import * as consentActions from "@app/contacts/contact-consents/ngrx/consents.actions";
import * as fromConsents from "@app/contacts/contact-consents/ngrx/consents.reducer";
import {
  getContactNpsRequest,
  getContactSurveysRequest,
} from "@app/contacts/contact.actions";
import * as fromContact from "@app/contacts/contact.selectors";
import * as objectTypes from "@app/contacts/utils/object-types";
import { Contact, QObject, SalesMeeting, Task } from "@app/models";
import { ContactNpsRequest, Nps, NPS_VALUES } from "@app/models/nps";
import { Survey } from "@app/models/survey";
import * as fromConfig from "@app/shared/config/config.reducer";
import { getFeature } from "@app/shared/config/config.reducer";
import { Feature, NpsConfig } from "@app/shared/config/models";
import { ConsentFeature } from "@app/shared/config/models/consent-feature";
import { CONSENT, NPS } from "@app/shared/config/utils/features";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import { fetchContactStatusRequest } from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.actions";
import * as fromContactStatusWidget from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { first, map, Observable } from "rxjs";

@Component({
  selector: "app-contact-status-widget",
  templateUrl: "./contact-status-widget.component.html",
  styleUrls: ["./contact-status-widget.component.scss"],
})
export class ContactStatusWidgetComponent implements OnInit, OnChanges {
  @Input() contactId: string;
  @Input() eaEmployeeId: string;

  loading$: Observable<boolean>;
  contact$: Observable<Contact>;
  consents$: Observable<ContactConsent[]>;
  numberOfTasks$: Observable<number>;
  tasks$: Observable<Task[]>;
  numberOfObjects$: Observable<number>;
  objects$: Observable<QObject[]>;
  hasPriceUpdateOrder$: Observable<boolean>;
  hasAlert$: Observable<boolean>;
  alerts$: Observable<Alert[]>;
  salesMeetings$: Observable<SalesMeeting[]>;
  contactActivity$: Observable<{
    hasActiveAlert: boolean;
    hasBeenToShowing: boolean;
    hasFutureTasks: boolean;
  }>;
  consentFeature$: Observable<ConsentFeature>;

  // NPS
  npsFeatureEnabled$: Observable<boolean>;
  npsTitle$: Observable<string>;
  contactNps$: Observable<Nps>;
  contactNpsLoading$: Observable<boolean>;
  contactSurveys$: Observable<Survey[]>;
  contactSurveysLoading$: Observable<boolean>;

  npsValues = NPS_VALUES;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.fetchContactSummary();
    this.fetchNPS();
  }

  ngOnChanges(changes) {
    if (changes.contactId && this.contactId && !!this.consentFeature$) {
      this.fetchContactSummary();
      this.fetchNPS();
    }
  }

  private mapStateToProps() {
    this.consents$ = this.store.pipe(
      select(fromContactStatusWidget.getConsents)
    );
    this.numberOfTasks$ = this.store.pipe(
      select(fromContactStatusWidget.getNumberOfTasks)
    );
    this.tasks$ = this.store.pipe(select(fromContactStatusWidget.getTasks));
    this.numberOfObjects$ = this.store.pipe(
      select(fromContactStatusWidget.getNumberOfObjects)
    );
    this.objects$ = this.store.pipe(select(fromContactStatusWidget.getObjects));
    this.hasPriceUpdateOrder$ = this.store.pipe(
      select(fromContactStatusWidget.hasPriceUpdateOrder)
    );
    this.hasAlert$ = this.store.pipe(select(fromContactStatusWidget.hasAlert));
    this.alerts$ = this.store.pipe(select(fromContactStatusWidget.getAlerts));
    this.salesMeetings$ = this.store.pipe(
      select(fromContactStatusWidget.getSalesMeetings)
    );
    this.consentFeature$ = this.store.pipe(select(getFeature(CONSENT)));
    this.contactActivity$ = this.store.pipe(
      select(fromConsents.getContactActivity)
    );
    this.contactSurveys$ = this.store.pipe(
      select(fromContact.getContactSurveys)
    );
    this.contactSurveysLoading$ = this.store.pipe(
      select(fromContact.getContactSurveysLoading)
    );
    this.contactNps$ = this.store.pipe(select(fromContact.getContactNps));
    this.contactNpsLoading$ = this.store.pipe(
      select(fromContact.getContactNpsLoading)
    );
    this.npsFeatureEnabled$ = this.store.pipe(
      select(fromConfig.getFeature(NPS)),
      map((feature: Feature) => feature.enabled)
    );

    this.npsTitle$ = this.store.pipe(
      select(fromConfig.getFeature(NPS)),
      map((feature: NpsConfig) => feature.title)
    );
  }

  private fetchContactSummary() {
    this.store.dispatch(
      fetchContactStatusRequest({
        contactId: this.contactId,
        eaEmployeeId: this.eaEmployeeId,
      })
    );

    this.consentFeature$?.pipe(first()).subscribe((consentFeature) => {
      const showingExpirationDate = moment()
        .subtract(consentFeature.showingActivityExpiration, "month")
        .toDate();
      this.store.dispatch(
        consentActions.fetchContactActivityRequest({
          contactId: this.contactId,
          showingExpirationDate,
        })
      );
    });
  }

  getObjectTypeTranslationKey(type: string, bidStatus = ""): string {
    if (type === objectTypes.POTENTIAL_BUYER && bidStatus === "participating") {
      return "bidder";
    } else {
      switch (type) {
        case objectTypes.UNKNOWN:
          return "unknown";
        case objectTypes.POTENTIAL_BUYER:
          return "potential_buyer";
        case objectTypes.OWNER:
          return "owner";
        case objectTypes.BUYER:
          return "buyer";
        case objectTypes.SELLER:
          return "seller";
        case objectTypes.TENANT:
          return "tenant";
        case objectTypes.FOLLOWER:
          return "follower";
        default:
          return "unknown";
      }
    }
  }

  private fetchNPS() {
    const parameters: ContactNpsRequest = {
      searchParams: {
        contactId: this.contactId,
        limit: 1,
        sort: "responseDate",
        sortOrder: "desc",
        NPSClass: `${NPS_VALUES.PROMOTERS},${NPS_VALUES.PASSIVES},${NPS_VALUES.DETRACTORS}`,
      },
    };

    this.store.dispatch(
      getContactNpsRequest({
        parameters,
      })
    );
    this.store.dispatch(
      getContactSurveysRequest({ contactId: this.contactId })
    );
  }

  getNpsIcon(type: "promoters" | "detractors" | "passives") {
    if (type === "promoters") {
      return "smile-beam";
    } else if (type === "detractors") {
      return "frown";
    } else {
      return "meh-blank";
    }
  }
}
