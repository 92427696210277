import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { GrowthStatistics } from "@app/statistics/statistics-growth/models/growth-statistics";
import * as widgetActions from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-summary-widget/ngrx/statistics-summary-widget.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import {
  debounceTime,
  fromEvent as observableFromEvent,
  Observable,
  skip,
  Subject,
  takeUntil,
} from "rxjs";
import { DropdownItem } from "@app/erp/components/form-elements/dropdown-element/dropdown-element.component";

@Component({
  selector: "app-statistics-summary-widget",
  templateUrl: "./statistics-summary-widget.component.html",
  styleUrls: ["./statistics-summary-widget.component.scss"],
})
export class StatisticsSummaryWidgetComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() returnTypes: string[] = [];
  @Input() quickFilter = "today";
  @Input() triggerDataFetch$: Observable<void>;
  @ViewChild("filterContainer", { static: false })
  filterContainer: ElementRef;

  showSegment: boolean = true;
  statistics$: Observable<GrowthStatistics>;
  loading$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();
  filters = [
    { label: "yesterday", value: "yesterday" },
    { label: "today", value: "today" },
    { label: "this_month", value: "currentMonth" },
    { label: "this_year", value: "currentYear" },
  ];
  dropDownFilters: DropdownItem[] = [
    { display: "yesterday", value: "yesterday" },
    { display: "today", value: "today" },
    { display: "this_month", value: "currentMonth" },
    { display: "this_year", value: "currentYear" },
  ];

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });

    observableFromEvent(window, "resize")
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.showSegment = !(
          this.filterContainer?.nativeElement?.offsetWidth < 450 &&
          this.dropDownFilters.length > 3
        );
      });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (
        this.filterContainer?.nativeElement?.offsetWidth < 450 &&
        this.dropDownFilters.length > 3
      ) {
        this.showSegment = false;
      }
    }, 200);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId || changes.returnTypes) {
      this.getStatistics();
    }
  }

  private mapStateToProps() {
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
    this.statistics$ = this.store.pipe(select(fromWidget.getStats));
  }

  getStatistics() {
    const params = {
      ...this.getDates(),
      ...this.getIds(),
      returnTypes: this.returnTypes.toString(),
      includeLastYearsData: true,
      getBudgetData: true,
    };
    this.store.dispatch(widgetActions.fetchStatisticsRequest({ params }));
  }

  private getDates() {
    switch (this.quickFilter) {
      case "yesterday":
        return {
          periodStart: moment().subtract(1, "day").format("YYYYMMDD"),
          periodEnd: moment().subtract(1, "day").format("YYYYMMDD"),
        };
      case "today":
        return {
          periodStart: moment().format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        };
      case "currentMonth":
        return {
          periodStart: moment().startOf("month").format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        };
      case "currentYear":
        return {
          periodStart: moment().startOf("year").format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        };
      default:
        return {
          periodStart: moment().format("YYYYMMDD"),
          periodEnd: moment().format("YYYYMMDD"),
        };
    }
  }

  private getIds() {
    const params = {};
    if (!!this.eaEmployeeId) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }

  getLastYearDay() {
    return moment().subtract(1, "year").format("dddd");
  }

  getBudgetTitle(startMonth: string, endMonth: string) {
    if (startMonth === endMonth) {
      return moment(startMonth, "YYYYMM").format("MMMM YYYY");
    } else {
      return `${moment(startMonth, "YYYYMM").format("MMMM YYYY")} - ${moment(
        endMonth,
        "YYYYMM"
      ).format("MMMM YYYY")}`;
    }
  }

  getLastYearDate() {
    return moment().subtract(1, "year").format("D MMMM");
  }

  changeFilter(filter: string) {
    this.quickFilter = filter;
    this.getStatistics();
  }
}
