<form [formGroup]="form">
  <section class="form__control">
    <label for="name" translate>name</label>
    <div>
      <input
        id="name"
        type="text"
        formControlName="name"
        [class]="
          !form.get('name').valid && form.get('name').touched
            ? 'validation-input'
            : ''
        "
      />
      <div ngxErrors="name">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </div>
  </section>
  <section class="form__control">
    <label for="description" translate>description</label>
    <div>
      <input id="description" type="text" formControlName="description" />
    </div>
  </section>
  <section class="form__control">
    <mat-slide-toggle
      labelPosition="before"
      color="primary"
      formControlName="resolved"
    >
      {{ "lead_resolved" | translate }}
    </mat-slide-toggle>
  </section>
  <section class="form__control">
    <mat-slide-toggle
      labelPosition="before"
      color="primary"
      formControlName="success"
    >
      {{ "lead_success" | translate }}
    </mat-slide-toggle>
  </section>
  <section class="form__control">
    <label for="followUpActivity" translate>follow_up_activity</label>
    <select id="followUpActivity" formControlName="followUpActivity">
      <option
        *ngFor="let activity of followUpActivities"
        [ngValue]="activity.value"
      >
        {{ activity.label | translate }}
      </option>
    </select>
  </section>
  <section class="form__control">
    <label for="postponeEndTimeHours" translate>extend_deadline</label>
    <select id="postponeEndTimeHours" formControlName="postponeEndTimeHours">
      <option [ngValue]="0">
        {{ "do_not_extend_the_deadline" | translate }}
      </option>
      <option *ngFor="let hour of hours" [ngValue]="hour">
        {{ hour }} {{ "hours" | translate | lowercase }}
      </option>
    </select>
  </section>
</form>
