import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ContactState } from "@app/contacts/contact.reducer";
import { ContactModuleState } from "@app/contacts/contact-module.reducer";

const selectFeature = createFeatureSelector<ContactModuleState>("contact");
const getState = createSelector(
  selectFeature,
  (state: ContactModuleState) => state.contact
);

export const getLoaded = createSelector(
  getState,
  (state: ContactState) => state.loaded
);

export const getLoading = createSelector(
  getState,
  (state: ContactState) => state.loading
);

export const getContact = createSelector(
  getState,
  (state: ContactState) => state.contact
);

export const getContactId = createSelector(
  getState,
  (state: ContactState) => state.contact.contactId
);

export const getContactProfileOwnsResidence = createSelector(
  getState,
  (state: ContactState) => state.ownsResidence
);

export const getCallListMode = createSelector(
  getState,
  (state: ContactState) => state.callListMode
);

export const getConsumerCallLoading = createSelector(
  getState,
  (state: ContactState) => state.createConsumerCallLoading
);

export const getCreateNoteLoading = createSelector(
  getState,
  (state: ContactState) => state.createNoteLoading
);

export const getBackLink = createSelector(
  getState,
  (state: ContactState) => state.backLink
);

export const getQuickLink = createSelector(
  getState,
  (state: ContactState) => state.quickLink
);

export const getQuickLinkLabel = createSelector(
  getState,
  (state: ContactState) => state.quickLinkLabel
);

export const getLatestContactedDate = createSelector(
  getState,
  (state: ContactState) => state.latestContactedDate
);

export const getContactNps = createSelector(
  getState,
  (state: ContactState) => state.nps
);

export const getContactNpsLoading = createSelector(
  getState,
  (state: ContactState) => state.npsLoading
);

export const getContactSurveys = createSelector(
  getState,
  (state: ContactState) => state.surveys
);

export const getContactSurveysLoading = createSelector(
  getState,
  (state: ContactState) => state.surveysLoading
);

export const getContactTagSuggestions = createSelector(
  getState,
  (state: ContactState) => state.tagSuggestions
);

export const storingTagToContact = createSelector(
  getState,
  (state: ContactState) => state.tagToContactStoring
);

export const getDeletingTagFromContact = createSelector(
  getState,
  (state: ContactState) => state.tagFromContactDeleting
);

export const getContactTags = createSelector(
  getState,
  (state: ContactState) => state.contactTags
);

export const getContactTagsLoading = createSelector(
  getState,
  (state: ContactState) => state.contactTagsLoading
);
