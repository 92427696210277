import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({ name: "momentify" })
export class MomentifyPipe implements PipeTransform {
  transform(value: string, onlyDate: boolean = false): string {
    if (!!value) {
      if (!!onlyDate) {
        return moment(value).format("L").toUpperCase();
      } else {
        return (
          moment(value).format("L").toUpperCase() +
          " " +
          moment(value).format("LT").toUpperCase()
        );
      }
    } else {
      return null;
    }
  }
}
