import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as previewModalActions from "@app/core/components/preview-modal/ngrx/preview.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Action } from "@app/models";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

export interface Receiver {
  contactId: string;
  subject: string;
  eaCrmReceiverId: number;
  content: string;
}

@Component({
  selector: "action-crm-sent",
  template: `
    <div [innerHTML]="text | async" class=""></div>
    <div>
      <small *ngIf="item.param1?.length > 0">
        <span>{{ item.param1 }}</span>
        <br />
        <div>
          <span class="preview-link" (click)="openPreviewModal()">
            <i class="icon-eye"></i>
            {{ "view" | translate }}
          </span>
        </div>
        <span>{{ item.timestamp | amDateFormat : "YYYY-MM-DD HH:mm" }}</span>
      </small>
    </div>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionCrmSentComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerName: Function;

  className = "action-crm-sent-name";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService,
    private apiService: ApiService,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.text = this.itemService.getYouTranslation(this.item).pipe(
      switchMap(() =>
        this.translateService.get("action_crm_sent_text", {
          name: `<span class="${this.className} text-link">${this.item.firstName} ${this.item.familyName}</span>`,
        })
      )
    );
  }

  openPreviewModal() {
    this.apiService
      .get(`ea-crm-receivers/${this.item.param2}`, {})
      .subscribe((receiver: Receiver) =>
        this.store.dispatch(
          previewModalActions.showPreviewModal({
            parameters: { html: receiver.content },
          })
        )
      );
  }

  ngAfterViewInit() {
    this.listenerName = this.itemService.createContactLinkListener(
      this.elementRef,
      this.renderer,
      this.className,
      this.item.contactId
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
