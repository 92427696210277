import { EmployeesGuard } from "../guards/employees-guard.service";
import { EmployeesCreateComponent } from "./employees-create/employees-create.component";
import { EmployeesOfficeCreateResolverService } from "./employees-office-create/employees-office-create-resolver.service";
import { EmployeesOfficeCreateComponent } from "./employees-office-create/employees-office-create.component";
import { EmployeesSearchComponent } from "./employees-search/employees-search.component";

export const sidebarEmployeesRoutesArray = [
  {
    path: "employees/:eaEmployeeId/offices",
    component: EmployeesOfficeCreateComponent,
    resolve: { task: EmployeesOfficeCreateResolverService },
    canActivate: [EmployeesGuard],
  },
  {
    path: "employees/offices/:eaOfficeId/search",
    component: EmployeesSearchComponent,
    canActivate: [EmployeesGuard],
  },
  {
    path: "employees/offices/:eaOfficeId/create",
    component: EmployeesCreateComponent,
    canActivate: [EmployeesGuard],
  },
  {
    path: "employees/:eaEmployeeId/offices/:eaOfficeId",
    component: EmployeesOfficeCreateComponent,
    resolve: { task: EmployeesOfficeCreateResolverService },
    canActivate: [EmployeesGuard],
  },
];
