<div class="dropdowns-container">
  <div class="offices">
    <span translate>offices</span>
    <app-multi-select-dropdown
      maxHeight="'18.55rem'"
      [parentFormGroup]="parentFormGroup"
      [parentFormControlName]="eaOfficeIdFormControlName"
      [items]="(offices$ | async) ? (offices$ | async) : []"
      [displayProperty]="'name'"
      [valueProperty]="'eaOfficeId'"
      [isOffice]="true"
      [enableSearch]="
        (offices$ | async) ? (offices$ | async).length > 7 : false
      "
      [officePrefix]="officePrefix$ | async"
      [enableMobileSelect]="isMobile$ | async"
      [enableRegions]="isAdmin$ | async"
      [state]="
        !(offices$ | async) || (offices$ | async).length === 0
          ? 'DISABLED'
          : 'READY'
      "
      [maxHeight]="'18.55rem'"
    >
      <span placeholder translate>all_offices</span>
    </app-multi-select-dropdown>
  </div>

  <div>
    <span translate>brokers</span>
    <app-multi-select-dropdown
      maxHeight="'18.55rem'"
      [parentFormGroup]="parentFormGroup"
      [parentFormControlName]="eaEmployeeIdFormControlName"
      [items]="(employees$ | async) ? (employees$ | async) : []"
      [displayProperty]="'fullName'"
      [valueProperty]="'eaEmployeeId'"
      [enableSearch]="(employees$ | async).length > 7"
      [enableMobileSelect]="isMobile$ | async"
      [state]="employeeDropdownState$ | async"
      [enableInactiveBrokers]="(isAdmin$ | async) || (isManager$ | async)"
      [inactiveBrokersSelected]="includeInactiveEmployees"
      (useInactiveBrokers)="includeInactiveEmployees = $event; fetchEmployees()"
      [maxHeight]="'18.55rem'"
    >
      <span placeholder translate>brokers</span>
    </app-multi-select-dropdown>
  </div>
</div>
