import { ManagePotentialBuyersSidebarConfig } from "@app/core/services/feature-config-manager/models/manage-potential-buyers-sidebar-config";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";

export const buildManagePotentialBuyerSidebarConfiguration = (
  externalProvideFeature: ExternalProviderFeature
): ManagePotentialBuyersSidebarConfig => {
  return {
    editBiddingStatusBatchEnabled: isEditBiddingStatusBatchEnabled(
      externalProvideFeature.name
    ),
  };
};

const isEditBiddingStatusBatchEnabled = (
  externalProviderName: string
): boolean => {
  return externalProviderName.toLowerCase() === "mspecs";
};
