import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { combineLatest, first, map, of, switchMap } from "rxjs";

import { AppState } from "@app/app.reducer";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { ApiService } from "@app/core/services/api/api.service";
import { TransferLeadParams } from "@app/lists/lists-leads/ngrx/lists-leads.reducer";
import { Employee, Office, Task } from "@app/models";
import * as fromConfig from "@app/shared/config/config.reducer";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import * as fromUser from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { transformObjectToMessageDataSet } from "@app/shared/utils/message-utils";
import moment from "moment/moment";

@Injectable({
  providedIn: "root",
})
export class AssignLeadService {
  constructor(
    private taskService: TaskService,
    private store: Store<AppState>,
    private contactService: ContactService,
    private apiService: ApiService
  ) {}

  handleAssign(
    tasks: Task[],
    isLead: boolean,
    params: TransferLeadParams,
    employee: Employee,
    office: Office
  ) {
    this.createRequests(tasks, isLead, params, employee, office);
  }

  private createRequests(
    tasks: Task[],
    isLead: boolean,
    params: TransferLeadParams,
    employee: Employee,
    office: Office
  ) {
    const requests = [];
    tasks.forEach((task: Task) => {
      const newParams = {
        eaOfficeId: params.eaOfficeId,
        eaEmployeeId: params.eaEmployeeId,
        transferComment: params.transferComment,
        transfer: true,
      };

      if (!!task.officeManagerExclusiveStartDate) {
        newParams["officeManagerExclusiveEndDate"] = moment(new Date()).format(
          API_DATE_FORMAT
        );
      }

      requests.push(
        this.taskService.patch(task.eaTaskId, { ...newParams }, false).pipe(
          map((task) => [params.sendMessage, task]),
          switchMap(([sendMessage, task]) => {
            if (!sendMessage) {
              return of(true);
            }

            let recipientName;
            let recipientEmail;
            if (!!employee) {
              recipientName = employee.fullName;
              recipientEmail = employee.email;
            } else {
              recipientName = office.officeName;
              recipientEmail = office.officeEmail;
            }

            return this.sendMessage(
              task,
              params.message,
              params.transferComment,
              recipientName,
              recipientEmail
            );
          })
        )
      );
    });

    const taskSet: TaskSet = {
      label: isLead ? "assigning_leads" : "assigning_tasks",
      tasks: requests,
    };
    this.store.dispatch(addTaskSetToQueue(taskSet));
  }

  private sendMessage(
    task: Task,
    message: string,
    transferComment: string,
    recipientName: string,
    recipientEmail: string
  ) {
    return combineLatest([
      this.store.pipe(select(fromUser.getOffice)),
      this.store.pipe(select(fromUser.getEmployee)),
      this.store.pipe(select(fromConfig.getMessageTemplateId)),
      this.store.pipe(select(fromConfig.getSendModuleId)),
      this.contactService.getById(task.contactId),
    ]).pipe(
      first(),
      switchMap(
        ([
          originOffice,
          originEmployee,
          templateId,
          sendToModuleId,
          contact,
        ]) => {
          const dataSet = {
            receiverName: recipientName,
            eaTaskId: task.eaTaskId,
            taskType: task.eaTaskTypeName,
            originEmployeeFullName: originEmployee.employeeFullName,
            originOfficeName: originOffice.officeName,
            taskTitle: task.title,
            comment: message,
            consumerId: task.contactId,
            consumerFirstName: contact?.firstName,
            consumerFamilyName: contact?.familyName,
            consumerEmail: contact?.email,
            consumerMsisdn: contact?.msisdn,
            consumerStreet: contact?.street,
            consumerZip: contact?.zip,
            consumerCity: contact?.city,
            brokerComment: message,
            description: transferComment,
            taskDescription: task.description,
          };
          const sendMessageParams = {
            templateId,
            sendToModule: sendToModuleId,
            recipients: recipientEmail,
            dataSet: transformObjectToMessageDataSet(dataSet),
          };

          return this.apiService
            .post("messages/email", sendMessageParams)
            .pipe(first());
        }
      )
    );
  }
}
