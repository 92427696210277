import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
import { API_DATE_TIME_FORMAT } from "@app/shared/utils/api-defaults";

@Pipe({ name: "momentifyCustomFormat" })
export class MomentifyCustomFormatPipe implements PipeTransform {
  transform(value: string, dateFormat: string = API_DATE_TIME_FORMAT): string {
    if (!!value) {
      return moment(value).format(dateFormat).toUpperCase();
    }

    return null;
  }
}
