import { EikaCreateContactComponent } from "@app/sidebar/eika/containers/eika-create-contact/eika-create-contact.component";
import { EikaComponent } from "@app/sidebar/eika/containers/eika/eika.component";
import { SelectContactComponent } from "@app/sidebar/eika/containers/select-contact/select-contact.component";
import { EikaGuard } from "@app/sidebar/guards/eika-guard.service";

export const sidebarEikaRoutes = {
  path: "eika",
  children: [
    {
      path: "",
      component: SelectContactComponent,
      canActivate: [EikaGuard],
    },
    {
      path: "new",
      component: EikaCreateContactComponent,
      canActivate: [EikaGuard],
    },
    {
      path: ":contactId",
      component: EikaComponent,
      canActivate: [EikaGuard],
    },
    {
      path: ":contactId/:eaOid",
      component: EikaComponent,
      canActivate: [EikaGuard],
    },
  ],
};
