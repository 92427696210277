import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { ContactActionService } from "@app/core/services/contact/contact-action.service";
import { Action } from "@app/models";

@Component({
  selector: "contact-action-list-item",
  templateUrl: "./contact-action-list-item.component.html",
  styleUrls: ["./contact-action-list-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactActionListItemComponent {
  @Input() action: Action;

  constructor(private cas: ContactActionService) {}

  getIcon() {
    return this.cas.getIcon(this.action.code);
  }
}
