import { ExternalTip } from "@app/models/external-tips";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as externalTipsActions from "./manage-external-tips.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface ExternalTipsState {
  loading: boolean;
  externalTips: ExternalTip[];
  latestFetchAction: Action;
}

export const initialState: ExternalTipsState = {
  loading: false,
  externalTips: [],
  latestFetchAction: null,
};

export function externalTipsReducer(
  state: ExternalTipsState,
  action: Action
): ExternalTipsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(externalTipsActions.getExternalTipsSuccess, (state, { externalTips }) => ({
    ...state,
    loading: false,
    externalTips,
  })),
  on(externalTipsActions.getExternalTipsFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(externalTipsActions.getExternalTipsRequest, (state, action) => ({
    ...state,
    loading: true,
    latestFetchAction: action,
  })),
  on(
    externalTipsActions.getAllExternalTipsSuccess,
    (state, { externalTips }) => ({
      ...state,
      loading: false,
      externalTips,
    })
  ),
  on(externalTipsActions.getAllExternalTipsFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(externalTipsActions.getAllExternalTipsRequest, (state, action) => ({
    ...state,
    loading: true,
    latestFetchAction: action,
  })),
  on(externalTipsActions.deleteExternalTipsSuccess, (state) => ({
    ...state,
    loading: false,
  })),
  on(externalTipsActions.deleteExternalTipsFailed, (state) => ({
    ...state,
    loading: false,
  })),
  on(externalTipsActions.deleteExternalTipsRequest, (state) => ({
    ...state,
    loading: true,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.externalTips
);

export const getExternalTips = createSelector(
  getState,
  (state: ExternalTipsState) => state.externalTips
);

export const getLatestNextStepFetchAction = createSelector(
  getState,
  (state: ExternalTipsState) => state.latestFetchAction
);
