import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ContactRelationService } from "@app/core/ngrx/entity-services/contact-relation.service";
import { Contact, ContactRelationship } from "@app/models";
import { RelationshipTypeService } from "@app/shared/modules/util/relationship-type.service";
import { SIDEBAR_CONTACTS_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { first } from "rxjs";

@Component({
  selector: "app-contact-relation-form",
  templateUrl: "./contact-relation-form.component.html",
  styleUrls: ["./contact-relation-form.component.scss"],
})
export class ContactRelationFormComponent implements OnChanges, OnInit {
  @Input() contactRelation: ContactRelationship;
  @Input() contact: Contact;

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit: EventEmitter<void> = new EventEmitter<void>();

  selectedContact: Partial<Contact>;
  form: FormGroup;
  disabledMessage = null;

  relationsShipTypeOptions = [];
  createContactUrl = SIDEBAR_CONTACTS_CREATE_URL;

  constructor(
    private fb: FormBuilder,
    private contactRelationService: ContactRelationService,
    private relationshipTypeService: RelationshipTypeService
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.form.valueChanges.subscribe((values) => {
      this.disabledMessage = null;
      if (
        values.relationshipType === "estatePrimaryContact" &&
        !!values.relatedToContactId &&
        (this.selectedContact.contactType === "estate" ||
          this.contact.contactType === "estate")
      ) {
        this.disabledMessage = " ";
        this.handleEstateValidation();
      }
    });

    this.relationshipTypeService
      .getRelationsShipTypeOptions()
      .pipe(first())
      .subscribe((relationsShipTypes) => {
        this.relationsShipTypeOptions = [...relationsShipTypes];
      });
  }

  ngOnChanges(changes) {
    if (changes.contactRelation && this.contactRelation) {
      this.fillForm();
    }

    if (changes.contact && this.contact) {
      this.relationshipTypeService
        .getRelationsShipTypeOptionsByContactType(this.contact.contactType)
        .pipe(first())
        .subscribe((relationsShipTypes) => {
          this.relationsShipTypeOptions = [...relationsShipTypes];
        });
    }
  }

  private buildForm() {
    this.form = this.fb.group({
      relatedToContactId: [null, Validators.required],
      relationshipType: [null, Validators.required],
    });
  }

  onContactClick(contact: Contact) {
    this.selectedContact = contact;
    this.form.get("relatedToContactId").setValue(contact.contactId);
  }

  checkIfPrimaryEstateContactExists(relations: ContactRelationship[]) {
    this.disabledMessage = null;
    if (
      relations.filter((rel) => rel.relationshipType === "estatePrimaryContact")
        .length > 0
    ) {
      this.disabledMessage = "estate_already_has_primary_estate_contact";
    }
  }

  clearSelectedContact() {
    this.selectedContact = null;
    this.form.get("relatedToContactId").setValue(null);
  }

  handleSubmit() {
    if (this.form.valid) {
      if (!!this.contactRelation) {
        this.contactRelationService
          .patch(
            { relationshipType: this.form.get("relationshipType").value },
            this.contact.contactId,
            this.contactRelation.eaRelationshipId
          )
          .subscribe();
      } else {
        this.contactRelationService
          .post(this.form.value, this.contact.contactId)
          .subscribe(() => {
            this.contactRelationService
              .getWithQuery(this.contact.contactId)
              .subscribe();
          });
      }

      this.submit.emit();
    }
  }

  private fillForm() {
    this.selectedContact = {
      firstName: this.contactRelation.firstName,
      familyName: this.contactRelation.familyName,
      contactId: this.contactRelation.relatedToContactId,
    };
    this.form.patchValue({
      relatedToContactId: this.contactRelation.relatedToContactId,
      relationshipType: this.contactRelation.relationshipType,
    });
  }

  private handleEstateValidation() {
    if (this.selectedContact.contactType === "estate") {
      this.contactRelationService
        .getWithQuery(this.selectedContact.contactId, {}, false)
        .pipe(first())
        .subscribe((selectedContactRelations) => {
          this.checkIfPrimaryEstateContactExists(selectedContactRelations);
        });
    } else if (this.contact.contactType === "estate") {
      // use existing relationships
      this.contactRelationService.currentList$
        .pipe(first())
        .subscribe((relations) =>
          this.checkIfPrimaryEstateContactExists(relations)
        );
    }
  }
}
