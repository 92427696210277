<div id="navbar"
     [@isVisibleChanged]="isNavbarVisible"
     (mouseleave)="isShowSubNav$.next(false);"
>
  <div>
    <ul class="menu">

      <ng-container *ngIf="isLargeScreen; else MobileNav">
        <li *ngFor="let page of pages$ | async">

          <a
            (click)="onClick()"
            (mouseenter)="showSubNav($event, page.label)"
            [routerLink]="['/crm', { outlets: { primary: page.label, sidebar: null } }]"
            routerLinkActive="active"
            class="large navbar-button" [ngClass]="checkHasSubLinks(page.label) ? 'no-cursor' : ''"
          >
            <app-icon [name]="getIcon(page.label)" size="large"></app-icon>
            <div class="navbar-button--text">{{ page.label | translate }}</div>
          </a>

          <div *ngIf="checkHasSubLinks(page.label)"
               [ngClass]="(isShowSubNav$|async)? 'open-subnav' : 'close-subnav'"
               class="navbar-sub-sidebar"
          >
            <div>
              <a *ngFor="let subPage of getSubPages(page.label)"
                 [routerLink]="subPage.routerLink"
                 (click)="onClick();"
                 class="nav-item" routerLinkActive="active"
              >
                <span class="page-nav-bar__icon hidden-xs hidden-sm">
                  <app-icon type="light" [name]="subPage.icon" size="medium"></app-icon>
                </span>
                <span>{{ subPage.label | translate }}</span>
              </a>
            </div>
          </div>

        </li>
      </ng-container>

      <ng-template #MobileNav>
        <li *ngFor="let page of pages$ | async">

          <ng-container *ngIf="checkHasSubLinks(page.label);else MobileNavWithoutSubNav">
            <a
              (click)="showSubNav($event, page.label)"
              routerLinkActive="active"
              class="with-sub navbar-button no-cursor"
            >
              <app-icon [name]="getIcon(page.label)" size="large"></app-icon>
              <div class="navbar-button--text">{{ page.label | translate }}</div>
            </a>

            <div class="navbar-sub-sidebar fast-transition"
                 [ngClass]="isNavbarVisible && !!isShowSubNav$ ? 'open-subnav' : 'close-subnav'"
            >
              <div>
                <a *ngFor="let subPage of getSubPages(page.label)"
                   [routerLink]="subPage.routerLink"
                   (click)="onClick();"
                   class="nav-item" routerLinkActive="active"
                >
                  <span class="page-nav-bar__icon hidden-xs hidden-sm">
                    <app-icon type="light" [name]="subPage.icon" size="medium"></app-icon>
                  </span>
                  <span>{{ subPage.label | translate }}</span>
                </a>
              </div>
            </div>

          </ng-container>

          <ng-template #MobileNavWithoutSubNav>
            <a
              (click)="onClick()"
              [routerLink]="['/crm', { outlets: { primary: page.label, sidebar: null } }]"
              routerLinkActive="active"
              class="no-sub navbar-button"
            >
              <app-icon [name]="getIcon(page.label)" size="large"></app-icon>
              <div class="navbar-button--text">{{ page.label | translate }}</div>
            </a>
          </ng-template>

        </li>
      </ng-template>

      <li *ngIf="isAuthorized() | async">
        <a [routerLink]="['/', 'admin']" class="navbar-button">
          <app-icon name="lock" size="large"></app-icon>
          <div class="navbar-button--text" translate>admin</div>
        </a>
      </li>

    </ul>
  </div>

  <div class="navbar__mobile">

    <a [routerLink]="['/crm', { outlets: { sidebar: ['root', 'support'] } }]"
       class="life-ring-icon"
    >
      <span [matTooltip]="'navbar_support_tooltip' | translate" matTooltipClass="q-tooltip">
        <app-icon name="life-ring" size="large"></app-icon>
      </span>
    </a>

    <div class="navbar__help" *ngIf="isSupportWidgetEnabled">
      <a
        id="navbar__hints"
        class="btn btn-primary btn-circle help-button hidden-xs hidden-sm"
        (click)="openSupportWidget()">
        <span>?</span>
      </a>
    </div>

  </div>

</div>
