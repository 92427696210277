import { MilesData } from "@app/miles/miles.component";
import * as milesActions from "@app/miles/ngrx/miles.actions";
import { SalesMeeting } from "@app/models";
import { ShowingExtended } from "@app/showings/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { MilesModuleState } from "@app/miles/miles-module.reducer";

export interface MilesState {
  showings: ShowingExtended[];
  salesMeetings: SalesMeeting[];
  milesData: MilesData[];
  loading: boolean;
  storing: boolean;
}

export const initialState: MilesState = {
  showings: [],
  salesMeetings: [],
  milesData: [],
  loading: false,
  storing: false,
};

export function milesReducer(state: MilesState, action: Action): MilesState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(milesActions.getSalesMeetingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(milesActions.getSalesMeetingsSuccess, (state, { salesMeetings }) => ({
    ...state,
    loading: false,
    salesMeetings,
  })),
  on(milesActions.getShowingsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(milesActions.getShowingsSuccess, (state, { showings }) => ({
    ...state,
    loading: false,
    showings,
  })),
  on(milesActions.getMilesDataRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(milesActions.getMilesDataSuccess, (state, { miles }) => ({
    ...state,
    loading: false,
    milesData: miles,
    storing: false,
  })),
  on(milesActions.storeMilesDataRequest, (state) => ({
    ...state,
    storing: true,
  })),
  on(milesActions.getMilesDataFailed, (state) => ({
    ...state,
    storing: false,
  }))
);

const selectFeature = createFeatureSelector<MilesModuleState>("miles");
const getState = createSelector(
  selectFeature,
  (state: MilesModuleState) => state.overview
);

export const getSalesMeetings = createSelector(
  getState,
  (state: MilesState) => state.salesMeetings
);

export const getShowings = createSelector(
  getState,
  (state: MilesState) => state.showings
);

export const getMilesData = createSelector(
  getState,
  (state: MilesState) => state.milesData
);
