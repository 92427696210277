import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import _ from "lodash";
import {
  buffer,
  catchError,
  forkJoin,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
  takeUntil,
} from "rxjs";

import { SurveyResponse } from "@app/models/survey";
import { ApiService, hardTrimBody } from "@app/core/services/api/api.service";
import { Employee, TypedPaginationListDTO } from "@app/models";
import * as kpiActions from "./kpi.actions";
import { Kpi } from "../models/kpi";
import { KpiDetails } from "../models/kpi-details";

@Injectable()
export class KpiEffects {
  fetchKpisRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchKpis),
      map(({ parameters: { type, userIds, searchParams } }) => ({
        searchParams,
        url: this.buildUrl({ type, ...userIds }),
      })),
      buffer(this.actions$.pipe(ofType(kpiActions.fetchKpisBufferClose))),
      mergeMap((requestObjects) =>
        forkJoin(
          requestObjects.map(({ url, searchParams }) =>
            this.apiService.get(url, searchParams)
          )
        ).pipe(
          takeUntil(this.actions$.pipe(ofType(kpiActions.fetchKpis))),
          map((responses: any) =>
            responses.map((response) => new Kpi(response))
          ),
          map((kpis) => kpiActions.fetchKpisSuccess({ kpis })),
          catchError(() => observableOf(kpiActions.fetchKpisFail()))
        )
      )
    )
  );

  fetchKpiSummaryRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchKpiSummaryRequest),
      map(({ parameters: { type, userIds, searchParams } }) => ({
        searchParams,
        url: this.buildUrl({ type, ...userIds }),
      })),
      switchMap(({ url, searchParams }) =>
        this.apiService.get(url, searchParams).pipe(
          map((response: any) => new Kpi(response)),
          map((kpi) => kpiActions.fetchKpiSummarySuccess({ kpi })),
          catchError(() => observableOf(kpiActions.fetchKpiSummaryFail()))
        )
      )
    )
  );

  fetchKpiDetailsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchKpiDetailsRequest),
      map(
        ({
          parameters: { type, userIds, searchParams, useEmployeeNotDetail },
        }) => ({
          type,
          searchParams,
          url: this.buildUrl({ type, ...userIds }, true, useEmployeeNotDetail),
          employeeDetails: _.has(userIds, "eaEmployeeId"),
        })
      ),
      switchMap(({ employeeDetails, type, url, searchParams }) =>
        this.apiService.get(url, searchParams).pipe(
          map((response: any) => {
            if (!employeeDetails) {
              return response.map((detail) => new KpiDetails(detail));
            }
            return response;
          }),
          map((response: any) =>
            kpiActions.fetchKpiDetailsSuccess({
              parameters: { type, details: response },
            })
          ),
          catchError(() => observableOf(kpiActions.fetchKpiDetailsFail()))
        )
      )
    )
  );

  fetchEmployeeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchEmployeeRequest),
      switchMap(({ id }) =>
        this.apiService.get(`employees/${id}`).pipe(
          map((response: any) => new Employee(response)),
          map((employee) => kpiActions.fetchEmployeeSuccess({ employee })),
          catchError(() => observableOf(kpiActions.fetchEmployeeFail()))
        )
      )
    )
  );

  fetchEmployeeNpsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchEmployeeNpsRequest),
      switchMap(({ nps: { eaOfficeId, eaEmployeeId, searchParams } }) => {
        return this.apiService
          .get(`nps-values/statistics/total`, {
            eaOfficeId,
            eaEmployeeId,
            ...searchParams,
          })
          .pipe(
            map((response: any) =>
              kpiActions.fetchEmployeeNpsSuccess({ nps: response })
            ),
            catchError(() => observableOf(kpiActions.fetchEmployeeFail()))
          );
      })
    )
  );

  fetchNpsSurveyResponsesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(kpiActions.fetchNpsSurveyResponsesRequest),
      switchMap(({ survery: { type, searchParams, userIds } }) =>
        this.apiService
          .get(
            "survey-responses/search",
            hardTrimBody({ ...searchParams, ...userIds })
          )
          .pipe(
            map((response: TypedPaginationListDTO<SurveyResponse>) =>
              kpiActions.fetchNpsSurveyResponsesSuccess({
                response: {
                  type,
                  details: response.rows,
                },
              })
            ),
            catchError(() =>
              observableOf(kpiActions.fetchNpsSurveyResponsesFail())
            )
          )
      )
    )
  );

  buildUrl(
    options: { eaOfficeId: string; eaEmployeeId: string; type: string },
    details = false,
    useEmployeeNotDetail = false
  ): string {
    const { type: resource, eaOfficeId, eaEmployeeId } = options;
    const urlSegments = [
      "simple-kpi",
      resource,
      "offices",
      eaOfficeId,
      "employees",
      eaEmployeeId,
      "details",
    ];

    if (useEmployeeNotDetail) {
      urlSegments[6] = "employees";
    }

    if (eaOfficeId && eaEmployeeId) {
      return urlSegments.slice(0, details ? 7 : 6).join("/");
    } else if (eaOfficeId) {
      urlSegments.splice(4, 2);
      return urlSegments.slice(0, details ? 5 : 4).join("/");
    } else if (eaEmployeeId) {
      urlSegments.splice(2, 2);
      return urlSegments.slice(0, details ? 5 : 4).join("/");
    } else {
      return urlSegments.slice(0, details ? 3 : 2).join("/");
    }
  }

  constructor(private actions$: Actions, private apiService: ApiService) {}
}
