<p>
  {{ displayText }}
  <span
    (click)="showMoreText()"
    *ngIf="
      text.length > hideAfterNoOfChars &&
      displayText.length <= hideAfterNoOfChars + 3
    "
    class="show-more"
    translate
    >show_more_text</span
  >
  <span
    (click)="showLessText()"
    *ngIf="
      text.length > hideAfterNoOfChars &&
      displayText.length > hideAfterNoOfChars + 3
    "
    class="show-more"
    translate
    >hide</span
  >
</p>
