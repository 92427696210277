import { createAction, props } from "@ngrx/store";
import { Segment } from "@app/campaigns/create-campaign/models/segment";
import { Template } from "@app/campaigns/create-campaign/models/template";

export interface StoreCampaignParams {
  templateId: string;
  segmentId: string;
}

export interface ResumeCampaignParams {
  newsLetterId: string;
}

export const getSegmentRequest = createAction(
  "[CreateCampaigns] GET_SEGMENTS_REQUEST",
  props<{ parameters: Record<string, unknown> }>()
);

export const getSegmentSuccess = createAction(
  "[CreateCampaigns] GET_SEGMENTS_SUCCESS",
  props<{ segments: Segment[] }>()
);

export const getSegmentFail = createAction(
  "[CreateCampaigns] GET_SEGMENTS_FAIL"
);

export const getDraftsRequest = createAction(
  "[CreateCampaigns] GET_DRAFTS_REQUEST"
);

export const getDraftsSuccess = createAction(
  "[CreateCampaigns] GET_DRAFTS_SUCCESS",
  props<{ templates: Template[] }>()
);

export const getDraftsFail = createAction(
  "[CreateCampaigns] GET_SEGMENTS_FAIL"
);

export const getTemplatesRequest = createAction(
  "[CreateCampaigns] GET_TEMPLATES_REQUEST"
);

export const getTemplatesSuccess = createAction(
  "[CreateCampaigns] GET_TEMPLATES_SUCCESS",
  props<{ templates: Template[] }>()
);

export const getTemplatesFail = createAction(
  "[CreateCampaigns] GET_TEMPLATES_FAIL"
);

export const storeCampaignRequest = createAction(
  "[CreateCampaigns] STORE_CAMPAIGN_REQUEST",
  props<{ parameters: StoreCampaignParams }>()
);

export const storeCampaignSuccess = createAction(
  "[CreateCampaigns] STORE_CAMPAIGN_SUCCESS",
  props<{ url: string }>()
);

export const storeCampaignFail = createAction(
  "[CreateCampaigns] STORE_CAMPAIGN_FAIL"
);

export const resumeCampaignRequest = createAction(
  "[CreateCampaigns] RESUME_CAMPAIGN_REQUEST",
  props<{ parameters: ResumeCampaignParams }>()
);

export const resumeCampaignSuccess = createAction(
  "[CreateCampaigns] RESUME_CAMPAIGN_SUCCESS",
  props<{ url: string }>()
);

export const resumeCampaignFail = createAction(
  "[CreateCampaigns] RESUME_CAMPAIGN_FAIL"
);

export const getNumberOfContactsForSegmentRequest = createAction(
  "[CreateCampaigns] GET_NUMBER_OF_CONTACTS_FOR_SEGMENT_REQUEST",
  props<{ segmentId: string }>()
);

export const getNumberOfContactsForSegmentSuccess = createAction(
  "[CreateCampaigns] GET_NUMBER_OF_CONTACTS_FOR_SEGMENT_SUCCESS",
  props<{ total: number }>()
);

export const getNumberOfContactsForSegmentFail = createAction(
  "[CreateCampaigns] GET_NUMBER_OF_CONTACTS_FOR_SEGMENT_FAIL"
);

export const reset = createAction("[CreateCampaigns] RESET");
