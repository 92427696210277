import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, combineLatest, filter, map, of, switchMap } from "rxjs";
import { SearchService } from "../services/search.service";
import * as searchActions from "./search.actions";

@Injectable()
export class SearchEffects {
  loadSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.loadRequest),
      filter(({ parameters: { keyword } }) => keyword.length >= 2),
      switchMap(
        ({
          parameters: {
            keyword,
            eaEmployeeId,
            eaOfficeId,
            isAdmin,
            superSearchTemplate,
          },
        }) =>
          combineLatest([
            this.searchService.formatKeywordIfPhoneNumber(keyword),
            of(eaEmployeeId),
            of(eaOfficeId),
            of(isAdmin),
            of(superSearchTemplate),
          ])
      ),
      switchMap(
        ([keyword, eaEmployeeId, eaOfficeId, isAdmin, superSearchTemplate]) => {
          const nonAdminParams = {
            eaEmployeeId,
            eaOfficeId,
          };

          const template =
            superSearchTemplate && superSearchTemplate.length > 0
              ? superSearchTemplate
              : "crm3";

          let params = {
            keyword,
            groups: "locations,consumers,employees,leads",
            listNumber: "40",
            template: template,
            filterStatus:
              "0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,26,29,30,33,34,50,51,52",
            publishOnNet: "true,false,limited",
          };

          if (!isAdmin) {
            params = Object.assign({}, nonAdminParams, params);
          }

          return this.apiService.get("search", params);
        }
      ),
      map((response: any) =>
        searchActions.loadSuccess({ result: response.matches })
      )
    )
  );

  setLatestSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.setLatestSearch),
      filter(({ keyword }) => keyword?.length >= 2),
      map(({ keyword }) => this.searchService.setLatestSearch(keyword)),
      map(() => searchActions.getLatestSearches())
    )
  );

  clearResults$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.loadRequest),
      filter(({ parameters: { keyword } }) => keyword.length < 2),
      map(() => searchActions.show())
    )
  );

  show$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.show),
      map(() => searchActions.getLatestSearches())
    )
  );

  getLatestSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.getLatestSearches),
      map(() => this.searchService.getLatestSearches()),
      map((latestSearches) =>
        searchActions.getLatestSearchesSuccess({ latestSearches })
      )
    )
  );

  removeLatestSearches$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.removeLatestSearches),
      map(() => {
        this.localStorageService.saveUserPreference("latest_searches", "");
        return searchActions.getLatestSearches();
      })
    )
  );

  getExternalLinks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(searchActions.getExternalLinks),
      switchMap(({ eaOid }) =>
        this.apiService
          .get(`external-meta-data/object/${eaOid}`, {}, "integrations")
          .pipe(
            map((response: { presentableLinks: any }) =>
              searchActions.getExternalLinksSuccess({
                externalLinks: response.presentableLinks,
              })
            ),
            catchError(() => of(searchActions.getExternalLinksFailed()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private searchService: SearchService,
    private localStorageService: LocalStorageService,
    private apiService: ApiService
  ) {}
}
