export class Note {
  eaNoteId: string;
  eaEmployeeId: string;
  eaOfficeId: string;
  employeeFullName: string;
  type: string;
  note: string;
  isPinned: boolean;
  updatedDate: string;
  visibility: string;
  eaOid?: string;
  contactId?: string;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
