import { createAction, props } from "@ngrx/store";

export const sendEmailRequest = createAction(
  "[Forgot Password] SEND_EMAIL_REQUEST",
  props<{ email: string }>()
);

export const sendEmailSuccess = createAction(
  "[Forgot Password] SEND_EMAIL_SUCCESS"
);

export const sendEmailFail = createAction("[Forgot Password] SEND_EMAIL_FAIL");

export const verifyEmailCodeRequest = createAction(
  "[Forgot Password] VERIFY_EMAIL_CODE_REQUEST",
  props<{ verificationCode: string }>()
);

export const verifyEmailCodeSuccess = createAction(
  "[Forgot Password] VERIFY_EMAIL_CODE_SUCCESS",
  props<{ userId: string }>()
);

export const verifyEmailCodeFail = createAction(
  "[Forgot Password] VERIFY_EMAIL_CODE_FAIL"
);

export const updateLoginInfoRequest = createAction(
  "[Forgot Password] UPDATE_LOGIN_INFO_REQUEST",
  props<{ loginInfo: Record<string, unknown> }>()
);

export const updateLoginInfoSuccess = createAction(
  "[Forgot Password] UPDATE_LOGIN_INFO_SUCCESS"
);

export const updateLoginInfoFail = createAction(
  "[Forgot Password] UPDATE_LOGIN_INFO_FAIL"
);
