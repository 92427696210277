import { Segment } from "@app/campaigns/create-campaign/models/segment";
import * as createCampaign from "@app/campaigns/create-campaign/ngrx/create-campaigns.actions";
import { Contact, Employee, PaginationListDTO, QObject } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as contactActions from "./lists-contacts.actions";
import { ListsModuleState } from "@app/lists/lists-module.reducer";

export interface ListsContactsState extends PaginationListDTO {
  loaded: boolean;
  loading: boolean;
  loadingMore: boolean;
  rows: Contact[];
  filters: {
    selectedAreas: any[];
    employees: Employee[];
    tagEmployees: Employee[];
  };
  segments: Segment[];
  segmentsLoading: boolean;
  segmentsLoaded: boolean;
  segmentDeleting: boolean;
  selectedSegment: {
    selectedSegmentId?: number;
    selectedSegmentName: string;
  };
  connectedObjects: QObject[];
}

export const initialState: ListsContactsState = {
  loaded: false,
  loading: false,
  loadingMore: false,
  limit: 0,
  offset: 0,
  total: 0,
  rows: [],
  filters: {
    selectedAreas: [],
    employees: [],
    tagEmployees: [],
  },
  segments: [],
  segmentsLoading: false,
  segmentsLoaded: false,
  segmentDeleting: false,
  selectedSegment: {
    selectedSegmentId: null,
    selectedSegmentName: "",
  },
  connectedObjects: [],
};

export function listsContactsReducer(
  state: ListsContactsState,
  action: Action
): ListsContactsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(contactActions.getContactsRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(contactActions.getContactsSuccess, (state, { payload }) => ({
    ...state,
    loading: false,
    loaded: true,
    limit: state.limit + payload.limit,
    offset: payload.offset,
    total: payload.total,
    rows: [...payload.segmentationListContacts],
  })),
  on(contactActions.getContactsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    rows: initialState.rows,
  })),
  on(contactActions.getMoreContactsRequest, (state) => ({
    ...state,
    loadingMore: true,
  })),
  on(contactActions.getMoreContactsSuccess, (state, { payload }) => {
    const { limit, offset, total, segmentationListContacts: rows } = payload;
    return {
      ...state,
      loadingMore: false,
      limit: state.limit + limit,
      offset,
      total,
      rows: [...state.rows, ...rows],
    };
  }),
  on(contactActions.getMoreContactsFailed, (state) => ({
    ...state,
    loading: initialState.loading,
  })),
  on(contactActions.getConnectedObjectRequest, (state) => ({ ...state })),
  on(contactActions.getConnectedObjectSuccess, (state, { objects }) => ({
    ...state,
    connectedObjects: objects,
  })),
  on(contactActions.getConnectedObjectFailed, (state) => ({
    ...state,
    connectedObjects: [],
  })),
  on(
    contactActions.getContactsForCallingListRequest,
    contactActions.getContactsForCallingListSuccess,
    contactActions.getContactsForCallingListFailed,
    (state) => ({
      ...state,
    })
  ),
  on(contactActions.reset, () => ({ ...initialState })),
  on(contactActions.getContactsFiltersRequest, (state) => ({
    ...state,
  })),
  on(contactActions.getContactsFiltersSuccess, (state, { payload }) => {
    const { municipalities, counties, customFilters } = payload;
    return {
      ...state,
      filters: {
        ...state.filters,
        selectedAreas: [
          ...municipalities.map((val) => ({
            id: val.municipalityId,
            title: val.name,
            type: "municipality",
          })),
          ...counties.map((val) => ({
            id: val.countyId,
            title: val.countyName,
            type: "county",
          })),
          ...customFilters.map((val) => ({
            id: val.customFilterId,
            title: val.customFilterName,
            type: "city_area",
          })),
        ],
      },
    };
  }),
  on(
    contactActions.getContactsFiltersFailed,
    contactActions.getEmployeesRequest,
    contactActions.getEmployeesFailed,
    (state) => ({
      ...state,
    })
  ),
  on(contactActions.getEmployeesSuccess, (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      employees: payload,
    },
  })),
  on(
    contactActions.getTagEmployeesRequest,
    contactActions.getTagEmployeesFailed,
    (state) => ({
      ...state,
    })
  ),
  on(contactActions.getTagEmployeesSuccess, (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      tagEmployees: payload,
    },
  })),
  on(createCampaign.getSegmentRequest, (state) => ({
    ...state,
    segmentsLoading: true,
    segmentsLoaded: false,
  })),
  on(createCampaign.getSegmentSuccess, (state, { segments }) => ({
    ...state,
    segments,
    segmentsLoading: false,
    segmentsLoaded: true,
  })),
  on(createCampaign.getSegmentFail, () => ({ ...initialState })),
  on(contactActions.deleteSegmentRequest, (state) => ({
    ...state,
    segmentDeleting: true,
  })),
  on(
    contactActions.deleteSegmentSuccess,
    contactActions.deleteSegmentFail,
    (state) => ({
      ...state,
      segmentDeleting: false,
    })
  ),
  on(
    contactActions.setSelectedSegment,
    (state, { selectedSegmentId, selectedSegmentName }) => ({
      ...state,
      selectedSegment: {
        selectedSegmentId,
        selectedSegmentName,
      },
    })
  )
);

const selectFeature = createFeatureSelector<ListsModuleState>("lists");
const getState = createSelector(
  selectFeature,
  (state: ListsModuleState) => state.contacts
);

export const getConnectedObjects = createSelector(
  getState,
  (state: ListsContactsState) => state.connectedObjects
);
