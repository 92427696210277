import { SalesTarget } from "./sales-target";

export interface SalesTargetTotal {
  [key: string]: number;
}

export class SalesTargets {
  [key: string]: SalesTarget;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
