<h3>
  <app-icon name="street-view" size="large"></app-icon>&nbsp;<span translate
    >results_vs_budget</span
  >
  <span
    class="info-tooltip"
    [tooltip]="'statistics_budget_widget_tooltip' | translate"
  >
    <app-icon name="info-circle"></app-icon>
  </span>
</h3>
<div class="budget-widget-container" [class.loading-pulse]="loading$ | async">
  <div
    class="budget-widget-container__data"
    *ngIf="statisticsChartValue$ | async as chartValues; else noData"
  >
    <ngx-charts-bar-vertical
      [scheme]="colorScheme"
      [results]="chartValues"
      [legend]="false"
      [animations]="true"
      [showXAxisLabel]="true"
      [xAxis]="true"
      [showYAxisLabel]="true"
      [yAxis]="true"
      [customColors]="customColors$ | async"
      [showGridLines]="true"
      [barPadding]="40"
      [yScaleMax]="300"
      [yAxisTicks]="[100]"
      [showDataLabel]="true"
      [yAxisLabel]="'achieved_budget_percentage' | translate"
      [yAxisTickFormatting]="yAxisTickFormatting"
    >
    </ngx-charts-bar-vertical>
  </div>
  <div *ngIf="statistics$ | async" class="budget-widget-container__footer">
    <span>
      {{ getDaysLeftPercentage() }}% /
      {{ "days_left_in_month" | translate : { days: getDaysLeft() } }}
    </span>
  </div>
</div>
<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
