export const MESSAGES_EMAIL = "messages/email";
export const MESSAGES_EMAIL_TEMPLATES = "messages/email/templates";
export const MESSAGES_SMS = "messages/sms";
export const MESSAGES_SMS_TEMPLATES = "messages/sms/templates";

export const MESSAGES_TEMPLATES = "messages/templates";
export const MESSAGE_TEMPLATE = (templateId: string) =>
  `templates/ea-crm/${templateId}`;
export const CREATE_MESSAGE_TEMPLATE = "templates/showing-general-template";
export const EDIT_MESSAGE_TEMPLATE = (templateId: string) =>
  `templates/showing-general-template/${templateId}`;
export const DELETE_MESSAGE_TEMPLATE = (templateId: string) =>
  `templates/ea-crm/${templateId}`;
export const MESSAGES_TEMPLATES_DYNAMIC_CONTENT = (templateId: string) =>
  `messages/templates/${templateId}/dynamic-content`;
export const MESSAGES_PREVIEW = (templateId: string) =>
  `messages/preview/templates/${templateId}`;

export const CONTACTS_ID = (contactId: string) => `contacts/${contactId}`;

export const COMPETITIONS = `competitions`;
export const COMPETITIONS_ID = (competitionId: string) =>
  `competitions/${competitionId}`;
export const COMPETITIONS_SEARCH = `competitions/search`;

export const EXTERNAL_TIPS = {
  TIP: (id: string) => `external-recipients/${id}`,
  TIPS: `external-recipients`,
  TIPS_BY_OFFICE_ID: (officeId: string) =>
    `offices/${officeId}/external-recipients`,
};

export const CALLING_LISTS = `calling-lists`;
export const CALLING_LISTS_SEARCH = `calling-lists/search`;
export const CALLING_LIST_ID = (callingListId: string) =>
  `calling-lists/${callingListId}`;
export const CALLING_LIST_CONTACTS = (callingListId: string) =>
  `calling-lists/${callingListId}/calling-list-contacts/search`;
export const CALLING_LIST_CONTACTS_ID = (callingListId: string, contactId) =>
  `calling-lists/${callingListId}/calling-list-contacts/${contactId}`;

export const SHOWINGS_ID = (showingId: string) =>
  `showing-objects/${showingId}`;
export const SHOWINGS_SEARCH = `showing-objects/search`;
