import { Component } from "@angular/core";

@Component({
  selector: "sidebar-tasks",
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class TasksComponent {}
