<h3 *ngIf="showHeader">
  <app-icon name="street-view" size="large"></app-icon>&nbsp;<span translate
    >leads_statistics</span
  >
  <span
    *ngIf="tooltip$ | async as tooltip"
    class="info-tooltip"
    [tooltip]="tooltip"
  >
    <app-icon name="info-circle"></app-icon>
  </span>
</h3>
<div class="widget-container" [class.loading-pulse]="loading$ | async">
  <div class="widget-container__filters" #filterContainer>
    <app-segmented-controls
      *ngIf="showSegment"
      [controls]="filters"
      [active]="quickFilter"
      (change)="changeFilter($event)"
    ></app-segmented-controls>

    <app-dropdown-element
      *ngIf="!showSegment"
      [items]="dropDownFilters"
      [preselectValue]="quickFilter"
      [multiple]="false"
      [newStyle]="true"
      [required]="true"
      [path]="['dateFilter']"
      width="100%"
      (valueEntered)="changeFilter($event)"
    >
    </app-dropdown-element>
  </div>
  <div
    class="widget-container__data"
    *ngIf="statistics$ | async as stats; else noData"
  >
    <span
      [leadPercentageColorCode]="
        formatPercentage(stats.numberOfResolvedLeads / stats.numberOfLeads)
      "
    >
      {{ formatPercentage(stats.numberOfResolvedLeads / stats.numberOfLeads) }}%
    </span>
    <p>
      {{ stats.numberOfLeads | qNumber }}
      {{ "lead_result_received_leads" | translate }}
    </p>
    <p>
      {{
        formatPercentage(stats.numberOfSuccessfulLeads / stats.numberOfLeads)
      }}% {{ "became_meeting" | translate }}
    </p>
  </div>
</div>
<ng-template #noData>
  <div class="no-data">
    <span translate>no_data_found</span>
  </div>
</ng-template>
