import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { ContactRelationService } from "@app/core/ngrx/entity-services/contact-relation.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { hardTrimBody } from "@app/core/services/api/api.service";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, ContactProfile } from "@app/models";
import { getCreateContactTypesConfig } from "@app/shared/config/config.reducer";
import { SegmentControls } from "@app/shared/modules/ui-components/segmented-controls/segmented-controls.component";
import { getEaIds, UserIds } from "@app/shared/user";
import { CONTACTS_COLOR } from "@app/shared/utils/colors";
import { CONTACT_CREATE_SELLER_BUYER } from "@app/shared/utils/tab-types";
import * as fromPotentialBuyers from "@app/showings/ngrx/potential-buyer/potential-buyer.reducer";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import * as ccActions from "@app/sidebar/contacts/ngrx/create-contact.actions";
import * as fromCreateContact from "@app/sidebar/contacts/ngrx/create-contact.reducer";
import { CompanyFormComponent } from "@app/sidebar/shared/company-form/company-form.component";
import { ContactFormComponent } from "@app/sidebar/shared/contact-form/contact-form.component";
import { EstateFormComponent } from "@app/sidebar/shared/estate-form/estate-form.component";
import { ConnectableTab } from "@app/sidebar/sidebar-connectable-tab";
import * as sidebarActions from "@app/sidebar/ngrx/sidebar.actions";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { SidebarTab } from "../../models/sidebar-tab";
import * as fromSidebar from "../../ngrx/sidebar.reducer";

@Component({
  selector: "create-seller-buyer-contact",
  templateUrl: "./create-seller-buyer-contact.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./create-seller-buyer-contact.component.scss",
  ],
})
export class CreateSellerBuyerContactComponent
  implements OnInit, OnDestroy, ConnectableTab
{
  @ViewChild("contactForm", { static: false })
  contactForm: ContactFormComponent;

  @ViewChild("companyForm", { static: false })
  companyForm: CompanyFormComponent;

  @ViewChild("estateForm", { static: false })
  estateForm: EstateFormComponent;

  tabType = CONTACT_CREATE_SELLER_BUYER;
  prefillData$: Observable<Partial<Contact>>;
  proxy$ = new Subject<any>();
  tab$: Observable<SidebarTab>;
  eaOid$: Observable<string>;
  unsubscribe$ = new Subject<void>();
  closeSidebar = true;
  existingContactSelected: Contact;
  contactsColor = CONTACTS_COLOR;
  dealType$ = new BehaviorSubject<string>("seller");
  erpMenuLinks$: Observable<ExternalPresentableLink[]>;
  contactTypeControls$: Observable<SegmentControls>;
  selectedContactType: "person" | "company" | "estate";
  eaIds$: Observable<UserIds>;
  isBusy$: Observable<{ status: boolean }>;

  get activeForm() {
    if (this.selectedContactType === "person") {
      return this.contactForm;
    } else if (this.selectedContactType === "company") {
      return this.companyForm;
    } else if (this.selectedContactType === "estate") {
      return this.estateForm;
    } else {
      return null;
    }
  }

  constructor(
    private store: Store<AppState>,
    public pbService: PotentialBuyerService,
    private objectService: ObjectService,
    private phoneNumberService: PhoneNumberService,
    private contactService: ContactService,
    private contactRelationService: ContactRelationService
  ) {}

  ngOnInit() {
    this.mapStateToProps();
    this.connectTab();
    this.tab$.pipe(first()).subscribe((tab) => {
      if (!!tab) {
        const type = tab?.url.find(
          (item) => item === "seller" || item === "buyer"
        );
        this.dealType$.next(type);
      }
    });
  }

  mapStateToProps(): void {
    this.tab$ = this.store.pipe(select(fromSidebar.getTab(this.tabType)));
    this.eaOid$ = this.store.pipe(select(fromShowings.getShowingObjectEaOid));
    this.erpMenuLinks$ = this.store.pipe(
      select(fromPotentialBuyers.getErpMenuLinks)
    );
    this.contactTypeControls$ = this.store.pipe(
      select(getCreateContactTypesConfig),
      tap((contactTypes) => (this.selectedContactType = contactTypes[0].type)),
      map((contactTypes) => {
        return contactTypes.map((type) => ({
          label: type.type,
          value: type.type,
          icon: this.getIcon(type.type),
        }));
      })
    );

    this.prefillData$ = this.store
      .pipe(select(fromCreateContact.getPrefillData))
      .pipe(filter((value) => !!value && !_.isEmpty(value)));

    this.eaIds$ = this.store.pipe(getEaIds);
    this.isBusy$ = combineLatest([
      this.contactService.loading$,
      this.objectService.loading$,
    ]).pipe(
      map(([contactLoading, objectLoading]) => ({
        status: contactLoading || objectLoading,
      }))
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  connectTab(): void {
    combineLatest([
      this.proxy$.pipe(take(1)),
      this.proxy$.pipe(debounceTime(100)),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([first, current]) => {
        if (_.isEqual(first, current)) {
          this.store.dispatch(
            sidebarActions.resetDirty({ tabType: this.tabType })
          );
        } else {
          this.store.dispatch(
            sidebarActions.markAsDirty({ tabType: this.tabType })
          );
        }
      });
  }

  submit(closeSidebar): void {
    this.isBusy$
      .pipe(
        first(),
        filter((isBusy) => !isBusy.status)
      )
      .subscribe(() => {
        this.closeSidebar = closeSidebar;
        if (this.existingContactSelected) {
          this.addContactAsSellerOrBuyer();
        } else {
          this.activeForm.submit();
        }
      });
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  handleSubmit(valid: boolean): void {
    if (valid) {
      if (this.selectedContactType === "person") {
        this.submitPerson();
      } else if (this.selectedContactType === "company") {
        this.submitCompany();
      } else if (this.selectedContactType === "estate") {
        this.submitEstate();
      }
    }
  }

  onQuedroContactSelected(contact: Contact) {
    this.contactForm.form.patchValue({
      contactInfo: {
        msisdn: contact.msisdn,
        phoneNumber: contact.phoneNumber,
        email: contact.email,
      },
      firstName: contact.firstName,
      familyName: contact.familyName,
    });
    this.existingContactSelected = contact;
  }

  private addContactAsSellerOrBuyer(): void {
    combineLatest([this.eaOid$, this.dealType$])
      .pipe(
        filter(([eaOid, dealType]) => !!eaOid && !!dealType),
        first(),
        switchMap(([eaOid, dealType]) => {
          return this.objectService
            .postDealParty(
              eaOid,
              this.existingContactSelected.contactId,
              dealType,
              {}
            )
            .pipe(first());
        })
      )
      .subscribe(() => this.closeSideBar());
  }

  private submitPerson() {
    this.contactForm
      .getCreateContactRequestParams()
      .pipe(
        withLatestFrom(this.eaOid$, this.dealType$),
        filter((value) => !!value),
        first()
      )
      .subscribe(([{ params, source }, eaOid, dealType]) => {
        const data = {
          ...params,
          originService: "CRM",
          type: dealType,
          eaOid,
        };

        let contactProfileParams;
        if (this.contactForm.form.get("ownsResidence").value) {
          contactProfileParams = {
            ownsResidence: this.contactForm.form.get("ownsResidence").value,
          };
        }
        this.createContact(hardTrimBody(data), source, contactProfileParams);
      });
  }

  private submitCompany() {
    this.eaIds$.pipe(first()).subscribe((ids) => {
      const msisdn = this.phoneNumberService.toLegacyFormat(
        this.companyForm.form.get("msisdn").value,
        this.companyForm.form.get("msisdnCountry").value
      );
      const params = {
        ...ids,
        ...this.companyForm.form.value,
        contactType: "company",
        originService: "CRM",
        msisdn,
      };
      this.createContact(hardTrimBody(params));
    });
  }

  private submitEstate() {
    this.eaIds$.pipe(first()).subscribe((ids) => {
      const msisdn = this.phoneNumberService.toLegacyFormat(
        this.estateForm.form.get("estateContact").get("msisdn").value,
        this.estateForm.form.get("estateContact").get("msisdnCountry").value
      );
      const estateParams = {
        ...ids,
        ...this.estateForm.form.get("estate").value,
        contactType: "estate",
      };
      const estateContactParams = {
        ...ids,
        ...this.estateForm.form.get("estateContact").value,
        contactType: "person",
        originService: "CRM",
        msisdn,
      };

      // Make estateContactParams optional, but check if ERP === "kivi"

      this.contactService
        .post(estateParams)
        .pipe(
          switchMap((estate: Contact) => {
            if (this.estateForm.form.get("estateContact").valid) {
              return this.contactService.post(estateContactParams, false).pipe(
                switchMap((estateContact) =>
                  this.contactRelationService.post(
                    {
                      relatedToContactId: estate.contactId,
                      relationshipType: "estatePrimaryContact",
                    },
                    estateContact.contactId
                  )
                )
              );
            } else {
              return of(estate);
            }
          })
        )
        .subscribe((estate: Contact) => {
          this.existingContactSelected = estate;
          this.addContactAsSellerOrBuyer();
        });
    });
  }

  private createContact(
    params,
    source?: any,
    patchProfileParams?: Partial<ContactProfile>
  ) {
    this.contactService
      .post(params)
      .pipe(
        map((contact: Contact) => {
          if (!!source) {
            this.store.dispatch(
              ccActions.addContactToSourceRequest({ params })
            );
          }

          if (!!patchProfileParams) {
            this.contactService
              .patchProfile(contact.contactId, patchProfileParams)
              .subscribe();
          }

          this.existingContactSelected = contact;
        })
      )
      .subscribe(() => this.addContactAsSellerOrBuyer());
  }

  private closeSideBar() {
    this.refreshMspecIframe();
    if (this.closeSidebar) {
      this.closeTab();
    } else {
      this.existingContactSelected = null;
      this.contactForm.initResetForm();
      this.estateForm?.form?.reset();
      this.companyForm?.form?.reset();
    }
  }

  private refreshMspecIframe() {
    combineLatest([this.erpMenuLinks$, this.dealType$])
      .pipe(first())
      .subscribe(([erpMenuLinks, dealType]) => {
        const selectLink = erpMenuLinks.find((link) =>
          link.key.toLowerCase().endsWith(dealType)
        );
        this.pbService.objectCreatedActionLinks$.next(selectLink);
      });
  }

  private getIcon(type) {
    if (type === "company") {
      return "building";
    } else if (type === "estate") {
      return "house-night";
    } else {
      return "user";
    }
  }
}
