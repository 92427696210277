import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { SubPage } from "@app/shared-features/mobile-navigation/ngrx/mobile-navigation.reducer";
import { Store } from "@ngrx/store";
import { BehaviorSubject, filter, map } from "rxjs";
import * as mobileNavigationActions from "../../../../shared-features/mobile-navigation/ngrx/mobile-navigation.actions";

@Component({
  selector: "page-nav-bar",
  templateUrl: "./page-nav-bar.component.html",
  styleUrls: ["./page-nav-bar.component.scss"],
})
export class PageNavBarComponent implements OnChanges, OnDestroy, OnInit {
  @ViewChild("filters", { static: false })
  filters;
  @Input() subPages: SubPage[];
  subSubPages$: BehaviorSubject<SubPage[]> = new BehaviorSubject<SubPage[]>([]);

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter((val) => val instanceof NavigationEnd),
        map(() =>
          this.subPages.find((page) => {
            return page.routerLink.includes(
              this.route.firstChild.snapshot.routeConfig.path
            );
          })
        ),
        filter((newVal) => !!newVal),
        map((subPage) => {
          return subPage?.subPages;
        })
      )
      .subscribe((pages) => {
        this.subSubPages$.next(pages);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { subPages } = changes;
    if (subPages) {
      this.subSubPages$.next(
        this.subPages.find((page) =>
          page.routerLink.includes(
            this.route.firstChild.snapshot.routeConfig.path
          )
        )?.subPages
      );
      this.store.dispatch(
        mobileNavigationActions.setRoutes({
          subPages: (<SimpleChange>subPages).currentValue,
        })
      );
    }
  }

  ngOnDestroy() {
    this.store.dispatch(mobileNavigationActions.reset());
  }
}
