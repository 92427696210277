<section>
  <div class="task-card">
    <i
      *ngIf="task.eaTaskTypeDescription"
      class="icon-question pull-right"
      [tooltip]="task.eaTaskTypeDescription"
      placement="left"
    ></i>

    <div>
      <span>{{ task.title }}</span>
      <contact-summary [contactId]="task.contactId"></contact-summary>
    </div>
    <div>
      <small>
        {{ "created_at" | translate : { fromNow: task.insertedDate } }}
      </small>
      <small> -</small>
      <small [class.expired]="dus.expired(task.deliveryDate)">
        {{ dus.getExpirationText(task.deliveryDate) | async }}
      </small>
      <p>
        <small *ngIf="task.originEaEmployeeFullname"
          >{{ "sent_by" | translate }}:
          <span>{{ task.originEaEmployeeFullname }}</span>
        </small>
      </p>
    </div>
    <ng-content select="[extraMetadata]"></ng-content>
    <ng-content></ng-content>
    <div *ngIf="task.description.length > 0">
      <hr />
      <small><span translate>note</span>:</small><br />
      <p [innerHTML]="task.description" class="description"></p>
    </div>
  </div>
</section>
<hr *ngIf="task.object" />
<section *ngIf="task.object">
  <h4 translate>connected_object</h4>
  <app-mobile-card
    [clickable]="true"
    [enableMetadata]="true"
    (click)="navigateToObject($event)"
    [enableActionDropdown]="true"
  >
    <span title>
      {{ task?.object?.street }}
    </span>
    <div metadata>
      <small>{{ task?.object?.zip }}</small>
      <br />
      <small>{{ task?.object?.city }}</small>
    </div>
    <div buttons>
      <li>
        <button class="btn btn-default" (click)="navigateToObject($event)">
          {{ "open" | translate }}
        </button>
      </li>
      <li role="menuitem">
        <button
          class="btn btn-default"
          (click)="openObjectInNewTab($event, task.eaOid)"
        >
          <app-icon name="share-square"></app-icon>
          {{ "open_in_new_tab" | translate }}
        </button>
      </li>
    </div>
  </app-mobile-card>
</section>
