<div [class.sidebyside]="sideBySide" [class.vertical]="!sideBySide">
  <div [class.new-style]="newStyle">
    <label *ngIf="newStyle" [class.dropdown-label]="newStyle"
      >{{ officeLabel | translate }}<span *ngIf="officeRequired">*</span></label
    >
    <span *ngIf="!newStyle"
      >{{ officeLabel | translate }}<span *ngIf="officeRequired">*</span></span
    >
    <app-dropdown-element
      *ngIf="officeItems$ | async as items"
      [multiple]="officeMultiple"
      [newStyle]="newStyle"
      [search]="items.length > 5"
      [items]="items"
      [oldStyle]="!newStyle"
      [label]="'offices'"
      [path]="['offices']"
      [width]="'100%'"
      [required]="officeRequired"
      [preselectValue]="
        (officeRegion$ | async) === 'offices'
          ? officeFormControl.value
          : regionControl.value
      "
      (valueEntered)="
        officeEmployeeDropdownService.handleOfficeValueEntered(
          $event,
          officeFormControl,
          availabilityMode,
          officeMultiple,
          regionControl,
          officeRegion$,
          setDefaults
        )
      "
    >
      <div [style.padding]="'0 16px'">
        <div
          *ngIf="
            officeEmployeeDropdownService.officeRequired(
              availabilityMode,
              officeMultiple,
              setDefaults
            ) | async
          "
          class="alert alert-info"
          translate
        >
          at_least_one_office_required
        </div>
        <app-segmented-controls
          *ngIf="
            (regionFeatureEnabled$ | async) &&
            (isAdmin$ | async) &&
            officeMultiple
          "
          [controls]="regionSegmentControls"
          [active]="officeRegion$ | async"
          (change)="officeRegion$.next($event)"
        ></app-segmented-controls>
      </div>
      <div *ngIf="officeMultiple" class="select-all-container">
        <span
          *ngIf="!isOfficeSelected"
          (click)="handleSelectAllOffices(!isOfficeSelected)"
          translate
        >
          select_all
        </span>
        <reset-filter
          *ngIf="isOfficeSelected"
          (reset)="handleSelectAllOffices(!isOfficeSelected)"
        >
        </reset-filter>
      </div>
    </app-dropdown-element>
  </div>
  <div [class.new-style]="newStyle" *ngIf="!employeeDisabled">
    <label *ngIf="newStyle" [class.dropdown-label]="newStyle"
      >{{ employeeLabel | translate
      }}<span *ngIf="employeeRequired">*</span></label
    >
    <span *ngIf="!newStyle"
      >{{ employeeLabel | translate
      }}<span *ngIf="employeeRequired">*</span></span
    >
    <app-dropdown-element
      [multiple]="employeeMultiple"
      [newStyle]="newStyle"
      [search]="(employeeItems$ | async).length > 5"
      [items]="employeeItems$ | async"
      [width]="'100%'"
      [oldStyle]="!newStyle"
      [required]="employeeRequired"
      [disabled]="disableEmployeeSegment"
      [label]="'employees'"
      [path]="['employees']"
      [preselectValue]="
        (teamEmployees$ | async) === 'employees'
          ? employeeFormControl.value
          : teamControl.value
      "
      (valueEntered)="
        officeEmployeeDropdownService.handleEmployeeValueEntered(
          $event,
          employeeFormControl,
          teamControl,
          teamEmployees$.value
        )
      "
    >
      <div *ngIf="isAdminOrManager$ | async" [style.padding]="'0 16px'">
        <div
          *ngIf="teamFeatureEnabled$ | async"
          [style.padding-bottom]="'0.5rem'"
        >
          <app-segmented-controls
            [controls]="teamSegmentControls"
            [active]="teamEmployees$ | async"
            (change)="teamEmployees$.next($event)"
          ></app-segmented-controls>
        </div>
        <app-segmented-controls
          *ngIf="(teamEmployees$ | async) === 'employees'"
          [controls]="activeSegmentControls"
          [active]="activeEmployees$ | async"
          (change)="activeEmployees$.next($event)"
        ></app-segmented-controls>
      </div>
      <div *ngIf="employeeMultiple" class="select-all-container">
        <span
          *ngIf="!isEmployeeSelected"
          (click)="handleSelectAllEmployees(!isEmployeeSelected)"
          translate
        >
          select_all
        </span>
        <reset-filter
          *ngIf="isEmployeeSelected"
          (reset)="handleSelectAllEmployees(!isEmployeeSelected)"
        >
        </reset-filter>
      </div>
    </app-dropdown-element>
  </div>
</div>
