import { Office } from "@app/models";
import { Region } from "@app/models/region";
import { API_LIMIT_MAX } from "@app/shared/utils/api-defaults";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { ObjectStatus } from "@app/sidebar/external-provider/models/object-status";
import { createAction, props } from "@ngrx/store";

export interface LoadObjectTypesRequestParams {
  countryIso: string;
  objectTypeIds: string;
}

export interface SsoifyRequestParams {
  integration: string;
  urlBearer: string;
}

export const setInnerWidth = createAction(
  "[Shared] SET_INNER_WIDTH",
  props<{ innerWidth: number }>()
);

export const loadOffices = (params: Record<string, unknown>) => {
  const filters = {
    limit: API_LIMIT_MAX,
    sortBy: "officeName",
    ...params,
  };
  return loadOfficesRequest({ filters });
};

export const loadOfficesRequest = createAction(
  "[Shared] LOAD_OFFICES_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const loadOfficesSuccess = createAction(
  "[Shared] LOAD_OFFICES_SUCCESS",
  props<{ offices: Office[] }>()
);

export const loadOfficesFail = createAction("[Shared] LOAD_OFFICES_FAIL");

export const loadRegionsRequest = createAction(
  "[Shared] LOAD_REGIONS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const loadRegionsSuccess = createAction(
  "[Shared] LOAD_REGIONS_SUCCESS",
  props<{ regions: Region[] }>()
);

export const loadRegionsFail = createAction("[Shared] LOAD_REGIONS_FAIL");

export const loadObjectTypesRequest = createAction(
  "[Shared] LOAD_OBJECT_TYPES_REQUEST",
  props<{ params: LoadObjectTypesRequestParams }>()
);

export const loadObjectTypesSuccess = createAction(
  "[Shared] LOAD_OBJECT_TYPES_SUCCESS",
  props<{ objectTypes: ObjectType[] }>()
);

export const loadObjectTypesFail = createAction(
  "[Shared] LOAD_OBJECT_TYPES_FAIL"
);

export const loadObjectStatusRequest = createAction(
  "[Shared] LOAD_OBJECT_STATUS_REQUEST",
  props<{ country: string }>()
);

export const loadObjectStatusSuccess = createAction(
  "[Shared] LOAD_OBJECT_STATUS_SUCCESS",
  props<{ objectStatus: ObjectStatus[] }>()
);

export const loadObjectStatusFail = createAction(
  "[Shared] LOAD_OBJECT_STATUS_FAIL"
);

export const ssoifyLinkAndOpenItRequest = createAction(
  "[Shared] SSOIFY_LINK_AND_OPEN_IT_REQUEST",
  props<{ params: SsoifyRequestParams }>()
);

export const ssoifyLinkAndOpenItSuccess = createAction(
  "[Shared] SSOIFY_LINK_AND_OPEN_IT_SUCCESS",
  props<{ url: string }>()
);

export const ssoifyLinkAndOpenItFail = createAction(
  "[Shared] SSOIFY_LINK_AND_OPEN_IT_FAIL"
);
