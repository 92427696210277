import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SearchProfileFeature } from "@app/shared/config/models/search-profile-feature";
import { SEARCH_PROFILE } from "@app/shared/config/utils/features";
import { select, Store } from "@ngrx/store";
import { map } from "rxjs";

@Injectable()
export class SearchProfileGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate() {
    return this.store.pipe(
      select(fromConfig.getFeature(SEARCH_PROFILE)),
      map((feature: SearchProfileFeature) => feature.enabled)
    );
  }
}
