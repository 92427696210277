import { ExchangeAccountDTO } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getExchangeDataRequest = createAction(
  "[Settings Account Exchange] GET_EXCHANGE_DATA_REQUEST",
  props<{ eaEmployeeId: string }>()
);

export const getExchangeDataSuccess = createAction(
  "[Settings Account Exchange] GET_EXCHANGE_DATA_SUCCESS",
  props<{ email: string }>()
);

export const getExchangeDataFailed = createAction(
  "[Settings Account Exchange] GET_EXCHANGE_DATA_FAILED"
);

export const createExchangeIntegrationRequest = createAction(
  "[Settings Account Exchange] CREATE_EXCHANGE_INTEGRATION_REQUEST",
  props<{ dto: ExchangeAccountDTO }>()
);

export const createExchangeIntegrationSuccess = createAction(
  "[Settings Account Exchange] CREATE_EXCHANGE_INTEGRATION_SUCCESS",
  props<{ exchangeAccount: ExchangeAccountDTO }>()
);

export const createExchangeIntegrationFailed = createAction(
  "[Settings Account Exchange] CREATE_EXCHANGE_INTEGRATION_FAILED"
);

export const deleteExchangeIntegrationRequest = createAction(
  "[Settings Account Exchange] DELETE_EXCHANGE_INTEGRATION_REQUEST",
  props<{ eaEmployeeId: string }>()
);

export const deleteExchangeIntegrationSuccess = createAction(
  "[Settings Account Exchange] DELETE_EXCHANGE_INTEGRATION_SUCCESS"
);

export const deleteExchangeIntegrationFailed = createAction(
  "[Settings Account Exchange] DELETE_EXCHANGE_INTEGRATION_FAILED"
);

export const reset = createAction("[Settings Account Exchange] RESET");
