import { Bid } from "@app/integrations/bidding/models/bid";
import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import { createAction, props } from "@ngrx/store";

export const fetchExternalObjectMetadataRequest = createAction(
  "[Bidding] FETCH_EXTERNAL_OBJECT_METADATA_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchExternalObjectMetadataSuccess = createAction(
  "[Bidding] FETCH_EXTERNAL_OBJECT_METADATA_SUCCESS",
  props<{ externalObjectMetaData: ExternalObjectMetadata }>()
);

export const fetchExternalObjectMetadataFailure = createAction(
  "[Bidding] FETCH_EXTERNAL_OBJECT_METADATA_FAILURE"
);

export const fetchHighestBidRequest = createAction(
  "[Bidding] FETCH_HIGHEST_BID_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchHighestBidSuccess = createAction(
  "[Bidding] FETCH_HIGHEST_BID_SUCCESS",
  props<{ highestAmount: string }>()
);

export const fetchHighestBidFailure = createAction(
  "[Bidding] FETCH_HIGHEST_BID_FAILURE",
  props<{ payload: Response }>()
);

export const fetchHighestBidForContactRequest = createAction(
  "[Bidding] FETCH_HIGHEST_BID_FOR_CONTACT_REQUEST",
  props<{ eaOid: string; contactId: string }>()
);

export const fetchHighestBidForContactSuccess = createAction(
  "[Bidding] FETCH_HIGHEST_BID_FOR_CONTACT_SUCCESS",
  props<{ bidAmount: string; bidId: string }>()
);

export const fetchHighestBidForContactFailure = createAction(
  "[Bidding] FETCH_HIGHEST_BID_FOR_CONTACT_FAILURE"
);

export const fetchBidsRequest = createAction(
  "[Bidding] FETCH_BIDS_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchBidsSuccess = createAction(
  "[Bidding] FETCH_BIDS_SUCCESS",
  props<{ bids: Bid[] }>()
);

export const fetchBidsFailure = createAction("[Bidding] FETCH_BIDS_FAILURE");

export const removeBidRequest = createAction(
  "[Bidding] REMOVE_BID_REQUEST",
  props<{ id: string }>()
);

export const removeBidSuccess = createAction("[Bidding] REMOVE_BID_SUCCESS");

export const removeBidFailure = createAction(
  "[Bidding] REMOVE_BID_FAILURE",
  props<{ payload: Response }>()
);

export const addBidRequest = createAction(
  "[Bidding] ADD_BID_REQUEST",
  props<{
    eaOid: string;
    contactId: string;
    body: {
      amount: string;
      createdAt: string;
      isHidden: boolean;
      condition?: string;
      communicationSettings: {
        communicateCreatedBidTo: {
          bidder: boolean;
          otherBidders: boolean;
          sellers: boolean;
          brokers: boolean;
        };
      };
    };
  }>()
);

export const addBidSuccess = createAction("[Bidding] ADD_BID_SUCCESS");

export const addBidFailure = createAction(
  "[Bidding] ADD_BID_FAILURE",
  props<{ payload: Response }>()
);

export const showAddBidModal = createAction("[Bidding] SHOW_ADD_BID_MODAL");

export const hideAddBidModal = createAction("[Bidding] HIDE_ADD_BID_MODAL");

export const reset = createAction("[Bidding] RESET");
