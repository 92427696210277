<a
  (click)="handleObjectClick()"
  [routerLink]="['/crm', 'showings', object.entity?.eaOid, navigationEndpoint]"
  [target]="openLinkInNewTab ? '_blank' : ''"
>
  <div
    class="object-card"
    [style.background-image]="
      object?.entity?.images[0]
        ? 'url(' + object?.entity?.images[0]?.path + '450' + ')'
        : ''
    "
  >
    <div class="box-shadow"></div>
    <div class="card-content">
      <h4 class="title">{{ object?.entity?.street }}</h4>
      <div class="metadata">
        <p *ngIf="sellersCount > 0">
          {{ "sellers" | translate }}:&nbsp;
          <span *ngIf="sellersCount === 1">
            <app-contact-name
              [contactId]="firstSeller.contactId"
              [contactType]="firstSeller?.contactType"
            >
              {{ firstSeller.firstName }} {{ firstSeller.familyName }}
            </app-contact-name>
          </span>
          <span
            *ngIf="sellersCount > 1"
            [popover]="sellersPopover"
            triggers="mouseenter:mouseleave"
            placement="top right"
            class="popover-container"
          >
            <app-contact-name
              [contactId]="firstSeller.contactId"
              [contactType]="firstSeller?.contactType"
            >
              <span *ngIf="sellersCount > 1">
                {{ firstSeller.firstName }} {{ firstSeller.familyName }} (+{{
                  sellersCount - 1
                }})
              </span>
            </app-contact-name>
          </span>
        </p>
        <ng-template #sellersPopover>
          <div *ngFor="let seller of otherSellers">
            <app-contact-name
              [contactId]="seller.contactId"
              [contactType]="seller?.contactType"
            >
              <span>{{ seller.firstName }} {{ seller.familyName }}</span>
            </app-contact-name>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="view-more-icon">
      <app-icon name="arrow-right" size="large"></app-icon>
    </div>
    <div class="status" [class]="getObjectStatusColor(object.entity?.status)">
      <mat-chip-listbox>
        <mat-chip>{{ object.entity?.statusName }}</mat-chip>
      </mat-chip-listbox>
    </div>
  </div>
</a>
