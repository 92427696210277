<div class="micro-page-container">
  <span
    (click)="
      breadcrumbs.length === 2
        ? breadcrumbClick(breadcrumbs.length - 2)
        : breadcrumbClick(breadcrumbs.length - 1)
    "
    class="small-back-button"
  >
    <i class="fa fa-long-arrow-left"></i>
    {{ "back" | translate }}
  </span>
  <ol class="breadcrumb">
    <li
      (click)="
        breadcrumbs.length === 2
          ? i !== breadcrumbs.length - 1 && breadcrumbClick(i)
          : breadcrumbClick(i + 1)
      "
      *ngFor="let breadcrumb of breadcrumbs; let i = index"
    >
      <span [class.breadcrumb-link]="i !== breadcrumbs.length - 1">{{
        breadcrumb
      }}</span>
    </li>
  </ol>
  <div class="micro-page-container--content-container">
    <ng-content></ng-content>
  </div>
</div>
