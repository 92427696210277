import { ProgressbarType } from "ngx-bootstrap/progressbar";

export const CONTACTS_COLOR = "#5cbddb";
export const LEADS_COLOR = "#71C57A";
export const LEAD_SCORING_COLOR = "#C39455";
export const CAMPAIGNS_COLOR = "#5362d6";
export const SALESMEETING_COLOR = "#F6A623";
export const SHOWINGS_COLOR = "#d3437f";

export const BRAND_SUCCESS_COLOR = "#28E57C";
export const BRAND_WARNING_COLOR = "#FF9F00";
export const BRAND_DANGER_COLOR = "#DE1B41";
export const BRAND_NEAUTRAL_COLOR = "#868686";

export const SKELETON_SCREEN_COLOR = "#a8cfd5";
export const DANGER_COLOR = "red";
export const WARNING_COLOR = "orange";
export const SUCCESS_COLOR = "#5cb85c";

export function getProgressColor(value: number, total: number): string {
  const progress = value / total;
  if (progress < 0.33) {
    return BRAND_DANGER_COLOR;
  } else if (progress < 0.66) {
    return BRAND_WARNING_COLOR;
  } else {
    return BRAND_SUCCESS_COLOR;
  }
}

export function getProgressBarType(
  value: number,
  total: number
): string | ProgressbarType {
  const progress = value / total;
  if (progress < 0.33) {
    return "danger";
  } else if (progress < 0.66) {
    return "warning";
  } else {
    return "success";
  }
}
