import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink } from "@app/contacts/contact.actions";
import { ContactService } from "@app/core/services/contact/contact.service";
import { UrlService } from "@app/core/services/url/url.service";
import { Contact } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import { CREATE_PRIVATE_SHOWING } from "@app/shared/config/utils/features";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { isMobile } from "@app/shared/ngrx/shared.reducer";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";
import { AddShowingModalNewComponent } from "@app/sidebar/shared/add-showing-modal-new/add-showing-modal-new.component";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";
import * as search from "../../ngrx/search.actions";
import { ShowingModalData } from "@app/sidebar/models/showing-modal-data";

@Component({
  selector: "search-contact-card",
  templateUrl: "./contact-card.component.html",
  styleUrls: ["./contact-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchContactCardComponent implements OnInit {
  _contact: Contact;
  @Input() eaEmployeeId: string;
  msisdn: string;
  phoneNumber: string;
  isMobile$: Observable<boolean>;
  showCreatePrivateShowing$: Observable<boolean>;

  get contact() {
    return this._contact;
  }

  @Input()
  set contact(contact: any) {
    this._contact = new Contact(contact);
  }

  ngOnInit() {
    this.msisdn = this.contact.msisdn;
    this.phoneNumber = this.contact.phoneNumber;
    this.contact = this.contactService.format(this.contact);
    this.isMobile$ = this.store.pipe(select(isMobile));
    this.showCreatePrivateShowing$ = this.store.pipe(
      select(getFeature(CREATE_PRIVATE_SHOWING)),
      map((feature) => feature.enabled)
    );
  }

  navigateToCustomerCard(contactId) {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
    this.router.navigate([
      "/crm",
      "contacts",
      contactId,
      { outlets: { sidebar: null } },
    ]);
    this.store.dispatch(search.hide());
  }

  doesContactBelongToEmployee(contact: Contact, eaEmployeeId) {
    return contact.eaEmployeeId === eaEmployeeId;
  }

  openInNewTab(e: Event, contactId): void {
    e.stopPropagation();
    const commands = ["/crm", "contacts", contactId, "overview"];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  constructor(
    private contactService: ContactService,
    private router: Router,
    private store: Store<AppState>,
    private urlService: UrlService,
    private modalService: QModalService
  ) {}

  createShowing(e: MouseEvent, contactId: string) {
    e.stopPropagation();
    const data: ShowingModalData = {
      isEditMode: false,
      contactId: contactId,
      hasHeader: true,
      hasHeaderCloseButton: true,
      hasActionBar: true,
    };

    this.modalService.show(AddShowingModalNewComponent, {
      data,
    });
  }
}
