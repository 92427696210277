<app-mobile-card
  *ngIf="contact"
  [enableMetadata]="false"
  [enableActionDropdown]="!disableActions"
  [enableCheckbox]="!disableActions"
  [size]="'SLIM'"
  [color]="'WHITE'"
  [shadow]="false"
  (click)="handleContactClick.emit({ contact: contact, event: $event })"
>
  <label disableNavigation class="checkbox-group" checkbox>
    <input
      disableNavigation
      type="checkbox"
      [checked]="selectedContactIds.includes(contact.contactId)"
      (change)="handleCheckboxChanged.emit(contact)"
    />
    <span disableNavigation class="checkbox-group__checkmark"></span>
  </label>
  <div class="title" title>
    <app-contact-name
      [contactId]="contact.contactId"
      [contactType]="contact.contactType"
    >
      {{ contact.firstName }} {{ contact.familyName }}
    </app-contact-name>
  </div>
  <ng-container buttons>
    <li disableNavigation role="menuitem">
      <button
        disableNavigation
        class="btn btn-secondary"
        (click)="sendMessageClick.emit(contact.contactId)"
      >
        <i disableNavigation class="icon-envelope-open"></i>
        {{ "send_message" | translate }}
      </button>
    </li>
    <li disableNavigation role="menuitem">
      <button
        disableNavigation
        class="btn btn-default"
        (click)="
          openInNewTab.emit({ event: $event, contactId: contact.contactId })
        "
      >
        <i disableNavigation class="icon-share-alt"></i>
        {{ "open_in_new_tab" | translate }}
      </button>
    </li>
  </ng-container>
</app-mobile-card>
