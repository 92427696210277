<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'edit_lead_channel' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <app-lead-channels-form [channel]="data$ | async"></app-lead-channels-form>
  </div>
  <div class="sidebar-footer">
    <button
      tabindex="-1"
      type="button"
      class="btn btn-cancel btn-lg"
      (click)="closeTab()"
    >
      {{ 'cancel' | translate }}
    </button>
    <button
      tabindex="-1"
      type="button"
      class="btn btn-danger btn-lg"
      (click)="onRemove()">
      {{ 'remove' | translate }}
    </button>
    <button type="button" class="btn btn-success btn-lg" (click)="onSubmit()">
      {{ 'save' | translate }}
    </button>
  </div>
</div>
