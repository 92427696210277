import { Component, Input } from "@angular/core";

@Component({
  selector: "spinner",
  template: `
    <div
      *ngIf="mode === 'SPINNER'"
      class="spinner"
      [style.margin]="margin"
      [style.display]="inlineBlock ? 'inline-block' : 'block'"
      [style.width]="size"
      [style.height]="size"
    ></div>
    <div *ngIf="mode === 'ELLIPSIS'" class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  `,
})
export class SpinnerComponent {
  @Input() size: string;
  @Input() margin: string;
  @Input() inlineBlock = false;

  @Input() mode: "SPINNER" | "ELLIPSIS" = "SPINNER";
}
