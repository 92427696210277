import { BudgetTarget } from "@app/models/budget-target";

export interface NewRecruit {
  recruitId: string;
  recruitName: string;
}

export interface NewRecruitTargetTotal {
  amount: number;
  averageAmount: number;
  quantity: number;
  totalTargetsValue: number;
}

export interface BudgetTargetTotal {
  amount: number;
  averageAmount: number;
  quantity: number;
  budgetMonthlyOverviewNumberOfSoldObjects: string;
  budgetMonthlyOverviewTurnover: string;
  totalTargetsValue: number;
  employees: {
    amount: number;
    averageAmount: number;
    quantity: number;
    totalTargetsValue: number;
  };
  newRecruits: NewRecruitTargetTotal;
}

export class BudgetTargets {
  targetTotal: BudgetTargetTotal;
  targets: BudgetTarget[];

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }
}
