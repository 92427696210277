import { Component, Input } from "@angular/core";
import { Contact, Note } from "@app/models";
import { Observable } from "rxjs";

@Component({
  selector: "contact-summary-notes",
  styleUrls: [
    "../../contact-summary.component.scss",
    "contact-summary-notes.component.scss",
  ],
  template: `
    <h5 translate>latest_notes</h5>
    <ul class="note-wrapper">
      <div *ngIf="!isLoading && (notes$ | async)?.length === 0">
        <span class="empty">{{ "no_notes" | translate }}</span>
      </div>
      <ng-container *ngIf="!isLoading; else loading">
        <li *ngFor="let note of notes$ | async" class="note">
          <section class="note__icon-container">
            <i class="icon-doc"></i>
            <div class="note__icon-container__line"></div>
          </section>
          <section class="note__text">
            <p
              class="note__text--private"
              *ngIf="
                note?.visibility === 'private' && !note?.note;
                else showNote
              "
            >
              <i class="fa fa-eye-slash"></i>
              {{ "private_note_label" | translate }}
            </p>
            <ng-template #showNote>
              <div class="d-flex">
                <span class="pinned-icon">
                  <app-icon
                    [tooltip]="'pinned_note_tooltip' | translate"
                    *ngIf="!!note?.isPinned"
                    name="thumbtack"
                  ></app-icon>
                </span>
                <p [innerHtml]="note?.note | nl2br | backslash"></p>
              </div>
            </ng-template>
            <p class="note__text--meta">
              <span translate>written_by</span>:
              <span>{{ note?.employeeFullName }}</span
              >,
              <span>{{ note?.updated | momentify }}</span>
            </p>
          </section>
        </li>
      </ng-container>
      <ng-template #loading>
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
        <br />
        <skeleton-rectangle [height]="1"></skeleton-rectangle>
      </ng-template>
    </ul>
  `,
})
export class ContactSummaryNotesComponent {
  @Input() isLoading: boolean;
  @Input() contact$: Observable<Contact>;
  @Input() notes$: Observable<Note[]>;
}
