import { ContactProfile } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer.ngrx";
import {
  fetchContactProfileSuccess,
  updateContactProfileOptimistic,
  updateContactProfileSuccess,
} from "@app/sidebar/potential-buyer/ngrx/contact-profile/contact-profile.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export function contactProfileReducer(
  state: ContactProfile | null,
  action: Action
): ContactProfile | null {
  return reducer(state, action);
}

const reducer = createReducer(
  null,
  on(
    fetchContactProfileSuccess,
    updateContactProfileSuccess,
    (_state, { profile }) => ({ ...profile })
  ),
  on(
    updateContactProfileOptimistic,
    (state, { ownsResidence, mortgageStatus }) => ({
      ...state,
      ownsResidence,
      mortgageStatus,
    })
  ),
  on(potentialBuyerActions.reset, () => null)
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
export const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.potentialBuyer.profile
);
