import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { QObject } from "@app/models";
import { DownloadFileService } from "@app/shared/modules/ui-components/download-file.service";
import { ShowingObject } from "@app/showings/models";
import { select, Store } from "@ngrx/store";
import * as _ from "lodash";
import { filter, first, switchMap, take } from "rxjs";

@Component({
  selector: "app-object-video-modal",
  templateUrl: "./object-video-modal.component.html",
  styleUrls: ["./object-video-modal.component.scss"],
})
export class ObjectVideoModalComponent implements OnInit {
  videoIndex: number = -1;
  eaOid: string = "";
  videoSrc: string = "";
  object: ShowingObject & QObject;
  fileName: string = "";

  constructor(
    private store: Store<AppState>,
    private objectService: ObjectService,
    public downloadFileService: DownloadFileService,
    public dialogRef: MatDialogRef<ObjectVideoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.object = this.data.object;
    this.eaOid = this.data.eaOid;
    this.videoIndex = -1;

    this.fileName = `${this.object.street}_${this.object.zip}_${this.object.city}.mp4`;

    if (!!this.object.links && this.object.links?.length > 0) {
      this.videoIndex = _.findLastIndex(this.object.links, (link) => {
        return this.isVideo(link);
      });
    }

    if (this.videoIndex !== null && this.videoIndex >= 0) {
      this.videoSrc = this.object.links[this.videoIndex].url;
    } else {
      this.videoSrc = "";
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  deleteVideo() {
    this.store.dispatch(
      confirmActions.show({
        header: "confirm",
        message: "delete_video_link_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter(Boolean),
        take(1),
        switchMap(() =>
          this.objectService.deleteVideo(this.eaOid, this.videoIndex)
        )
      )
      .subscribe(() => this.closeModal());
  }

  createOrUpdateVideo() {
    this.store.dispatch(
      confirmActions.show({
        header: "confirm",
        message: "create_update_video_link_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((it) => !!it),
        take(1),
        switchMap(() => this.objectService.postVideo(this.eaOid))
      )
      .subscribe(() => this.closeModal());
  }

  isVideo(video) {
    return video?.url.substring(video.url.length - 3) === "mp4";
  }

  downloadFile() {
    this.downloadFileService
      .downloadFile(this.videoSrc, this.fileName)
      .pipe(first())
      .subscribe();
  }
}
