import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Action } from "@app/models";
import {
  getLatestActions,
  getList,
  getLoading,
} from "@app/shared/modules/action-list";
import { ActionService } from "@app/shared/modules/action-list/services/action.service";
import {
  getLatestActionsRequestObject,
  hasRole,
  UserIds,
} from "@app/shared/user";
import { ROLE_ADMIN, ROLE_MANAGER } from "@app/shared/utils/roles";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  filter,
  map,
  Observable,
  Subject,
  takeUntil,
} from "rxjs";

@Component({
  selector: "app-broker-timeline-widget",
  templateUrl: "./broker-timeline-widget.component.html",
  styleUrls: ["./broker-timeline-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrokerTimelineWidgetComponent implements OnInit, AfterViewInit {
  @Input() eaEmployeeId: string;

  latestActionsList$: Observable<Action[]>;
  showToggleSlide$: Observable<boolean>;
  loading$: Observable<boolean>;
  actionFilters$: BehaviorSubject<string[]>;

  unSubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>, private cas: ActionService) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngAfterViewInit() {
    observableCombineLatest([
      this.actionFilters$,
      this.store.pipe(
        getLatestActionsRequestObject,
        filter((userIds: UserIds) => !!userIds)
      ),
    ])
      .pipe(
        map(([filters, data]) => ({
          ...data,
          codes: filters.toString(),
        })),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((data) => {
        this.store.dispatch(getLatestActions(data));
      });
  }

  private mapStateToProps() {
    this.latestActionsList$ = this.store.pipe(select(getList));
    this.loading$ = this.store.pipe(select(getLoading));
    this.actionFilters$ = new BehaviorSubject(this.cas.getAllFilterCodes());
    this.showToggleSlide$ = observableCombineLatest([
      this.store.pipe(select(hasRole(ROLE_MANAGER))),
      this.store.pipe(select(hasRole(ROLE_ADMIN))),
    ]).pipe(map(([isManager, isAdmin]) => isManager || isAdmin));
  }
}
