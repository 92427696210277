import { ExternalObjectMetadata } from "@app/integrations/bidding/models/external-object-metadata";
import { ExternalSystemLinks } from "@app/integrations/models/ErpSystemLinks";
import { Integration } from "@app/integrations/models/Integration";
import { IntegrationCopyObject } from "@app/integrations/models/IntegrationCopyObject";
import { IntegrationProviders } from "@app/integrations/models/IntegrationProviders";
import { StoreIntegrationCopyObject } from "@app/integrations/models/StoreIntegrationCopyObject";
import { SupportedLinkType } from "@app/integrations/models/SupportedLinkType";
import { createAction, props } from "@ngrx/store";
import { PropertyTrigger } from "@app/integrations/models/propertyTrigger";

export const fetchIntegrationsRequest = createAction(
  "[Integrations] FETCH_INTEGRATIONS_REQUEST"
);

export const fetchIntegrationsSuccess = createAction(
  "[Integrations] FETCH_INTEGRATIONS_SUCCESS",
  props<{ integration: Integration }>()
);

export const fetchIntegrationsFailure = createAction(
  "[Integrations] FETCH_INTEGRATIONS_FAILURE"
);

export const fetchIntegrationProviderRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_PROVIDER_REQUEST"
);

export const fetchIntegrationProviderSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_PROVIDER_SUCCESS",
  props<{ providers: IntegrationProviders }>()
);

export const fetchIntegrationProviderFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_PROVIDER_FAILURE"
);

export const fetchIntegrationSupportedLinkTypesRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_LINK_TYPES_REQUEST"
);

export const fetchIntegrationSupportedLinkTypesSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_LINK_TYPES_SUCCESS",
  props<{ supportedLinkTypes: SupportedLinkType[] }>()
);

export const fetchIntegrationSupportedLinkTypesFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_LINK_TYPES_FAILURE"
);

export const fetchExternalTipsIntegrationDescriptionRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_DESCRIPTION_REQUEST"
);

export const fetchExternalTipsIntegrationDescriptionSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_DESCRIPTION_SUCCESS",
  props<{ externalTipsSupported: boolean }>()
);

export const fetchExternalTipsIntegrationDescriptionFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_DESCRIPTION_FAILURE"
);

export const fetchIntegrationParentReceiverIsConfiguredForOfficeRequest =
  createAction(
    "[Integrations] FETCH_INTEGRATION_PARENT_RECEIVER_IS_CONFIGURED_FOR_OFFICE_REQUEST"
  );

export const fetchIntegrationParentReceiverIsConfiguredForOfficeSuccess =
  createAction(
    "[Integrations] FETCH_INTEGRATION_PARENT_RECEIVER_IS_CONFIGURED_FOR_OFFICE_SUCCESS",
    props<{ officeIsConfigured: boolean }>()
  );

export const fetchIntegrationParentReceiverIsConfiguredForOfficeFailure =
  createAction(
    "[Integrations] FETCH_INTEGRATION_PARENT_RECEIVER_IS_CONFIGURED_FOR_OFFICE_FAILURE"
  );

export const fetchIntegrationSupportedObjectDataRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_OBJECT_DATA_REQUEST"
);

export const fetchIntegrationSupportedObjectDataSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_OBJECT_DATA_SUCCESS",
  props<{ supportedObjectData: string[] }>()
);

export const fetchIntegrationSupportedObjectDataFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_SUPPORTED_OBJECT_DATA_FAILURE"
);

export const fetchNewsletterIsConfiguredRequest = createAction(
  "[Integrations] FETCH_NEWSLETTER_IS_CONFIGURED_REQUEST"
);

export const fetchNewsletterIsConfiguredSuccess = createAction(
  "[Integrations] FETCH_NEWSLETTER_IS_CONFIGURED_SUCCESS",
  props<{ newsletterIsConfigured: boolean }>()
);

export const fetchNewsletterIsConfiguredFailure = createAction(
  "[Integrations] FETCH_NEWSLETTER_IS_CONFIGURED_FAILURE"
);

export const fetchIntegrationContactSearchCapabilitiesRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_CONTACT_SEARCH_CAPABILITIES_REQUEST"
);

export const fetchIntegrationContactSearchCapabilitiesSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_CONTACT_SEARCH_CAPABILITIES_SUCCESS",
  props<{ contactSearchCapabilities: string[] }>()
);

export const fetchIntegrationContactSearchCapabilitiesFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_CONTACT_SEARCH_CAPABILITIES_FAILURE"
);

export const fetchIntegrationEngineAdminUrlRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_ENGINE_ADMIN_URL_REQUEST"
);

export const fetchIntegrationEngineAdminUrlSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_ENGINE_ADMIN_URL_SUCCESS",
  props<{ integrationEngineAdminUrl: string }>()
);

export const fetchIntegrationEngineAdminUrlFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_ENGINE_ADMIN_URL_FAILURE"
);

export const fetchIntegrationCopyObjectOptionsRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_COPY_OBJECT_OPTIONS_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchIntegrationCopyObjectOptionsSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_COPY_OBJECT_OPTIONS_SUCCESS",
  props<{ objectCopyOptions: IntegrationCopyObject }>()
);

export const fetchIntegrationCopyObjectOptionsFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_COPY_OBJECT_OPTIONS_FAILURE"
);

export const storeIntegrationCopyObjectRequest = createAction(
  "[Integrations] STORE_INTEGRATION_COPY_OBJECT_REQUEST",
  props<{
    parameters: {
      eaOid: string;
      params: {
        options?: {
          [key: string]: boolean;
        };
        copies: number;
      };
    };
  }>()
);

export const storeIntegrationCopyObjectSuccess = createAction(
  "[Integrations] STORE_INTEGRATION_COPY_OBJECT_SUCCESS",
  props<{ storedCopyObjects: StoreIntegrationCopyObject }>()
);

export const storeIntegrationCopyObjectFailure = createAction(
  "[Integrations] STORE_INTEGRATION_COPY_OBJECT_FAILURE"
);

export const resetCopyObject = createAction(
  "[Integrations] RESET_INTEGRATION_COPY_OBJECT"
);

export const fetchBrandkeeperIsConfiguredRequest = createAction(
  "[Integrations] FETCH_BRANDKEEPER_IS_CONFIGURED_REQUEST"
);

export const fetchBrandkeeperIsConfiguredSuccess = createAction(
  "[Integrations] FETCH_BRANDKEEPER_IS_CONFIGURED_SUCCESS",
  props<{ brandkeeperIsConfigured: boolean }>()
);

export const fetchBrandkeeperIsConfiguredFailure = createAction(
  "[Integrations] FETCH_BRANDKEEPER_IS_CONFIGURED_FAILURE"
);

export const fetchBrandkeeperUrlRequest = createAction(
  "[Integrations] FETCH_BRANDKEEPER_URL_REQUEST"
);

export const fetchBrandkeeperUrlSuccess = createAction(
  "[Integrations] FETCH_BRANDKEEPER_URL_SUCCESS",
  props<{ brandkeeperUrl: string }>()
);

export const fetchBrandkeeperUrlFailure = createAction(
  "[Integrations] FETCH_BRANDKEEPER_URL_FAILURE"
);

export const fetchIntegrationErpSystemLinksUrlRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_ERP_SYSTEM_LINKS_REQUEST"
);

export const fetchIntegrationErpSystemLinksUrlSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_ERP_SYSTEM_LINKS_SUCCESS",
  props<{ erpSystemLinks: ExternalSystemLinks }>()
);

export const fetchIntegrationErpSystemLinksUrlFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_ERP_SYSTEM_LINKS_FAILURE"
);

export const fetchExternalObjectMetadataRequest = createAction(
  "[Integrations] FETCH_EXTERNAL_OBJECT_METADATA_REQUEST",
  props<{ eaOid: string }>()
);

export const fetchExternalObjectMetadataSuccess = createAction(
  "[Integrations] FETCH_EXTERNAL_OBJECT_METADATA_SUCCESS",
  props<{ externalObjectMetadata: ExternalObjectMetadata }>()
);

export const fetchExternalObjectMetadataFailure = createAction(
  "[Integrations] FETCH_EXTERNAL_OBJECT_METADATA_FAILURE"
);

export const fetchExternalObjectMetadataReset = createAction(
  "[Integrations] FETCH_EXTERNAL_OBJECT_METADATA_RESET"
);

export const fetchIntegrationEraIntranetUrlRequest = createAction(
  "[Integrations] FETCH_INTEGRATION_ERA_INTRANET_URL_REQUEST"
);

export const fetchIntegrationEraIntranetUrlSuccess = createAction(
  "[Integrations] FETCH_INTEGRATION_ERA_INTRANET_URL_SUCCESS",
  props<{ eraIntranetUrl: string }>()
);

export const fetchIntegrationEraIntranetUrlFailure = createAction(
  "[Integrations] FETCH_INTEGRATION_ERA_INTRANET_URL_FAILURE"
);

export const fetchAdfenixPropertyTriggerConfigRequest = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGER_CONFIG_REQUEST"
);
export const fetchAdfenixPropertyTriggerConfigSuccess = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGER_CONFIG_SUCCESS",
  props<{ adfenixPropertyTriggerIsConfigured: boolean }>()
);

export const fetchAdfenixPropertyTriggerConfigFailure = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGER_CONFIG_FAILURE"
);

export const fetchAdfenixPropertyTriggersRequest = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGERS_REQUEST"
);

export const fetchAdfenixPropertyTriggersSuccess = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGERS_SUCCESS",
  props<{ triggers: PropertyTrigger[] }>()
);

export const fetchAdfenixPropertyTriggersFailure = createAction(
  "[Integrations] FETCH_ADFENIX_PROPERTY_TRIGGERS_FAILURE"
);
