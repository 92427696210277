import { Component, forwardRef, Input } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

export type SegmentedControl = { label: string; value: any }[];

@Component({
  selector: "app-q-segmented-controls",
  templateUrl: "./q-segmented-controls.component.html",
  styleUrls: ["./q-segmented-controls.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QSegmentedControlsComponent),
    },
  ],
})
export class QSegmentedControlsComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() controls: SegmentedControl;
  @Input() inline: boolean;

  private _value = null;
  touched = false;
  disabled = false;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
