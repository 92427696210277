import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { Office } from "@app/models";
import { select, Store } from "@ngrx/store";
import { Observable, of as observableOf, throwError } from "rxjs";
import { Md5 } from "ts-md5/dist/md5";
import {
  initialState,
  selectFeature,
  SessionState,
} from "./ngrx/session.reducer";

@Injectable()
export class SessionService {
  id: string;

  constructor(private store: Store<AppState>) {
    this.store
      .pipe(select(selectFeature))
      .subscribe((session: SessionState) => this.storeSession(session));
  }

  getSession(office: Office): Observable<SessionState> {
    if (office && office.eaEmployeeId && office.eaEmployeeId.length !== 0) {
      this.id = this.getId(office);
      const sessionStr = localStorage.getItem(this.id);
      return observableOf(this.parseSession(sessionStr));
    }
    return throwError(() => new Error("Invalid id provided"));
  }

  storeSession(session: SessionState): Observable<boolean> {
    if (session && this.id && this.id.length !== 0) {
      localStorage.setItem(this.id, JSON.stringify(session));
      return observableOf(true);
    }
    return throwError(() => new Error("Invalid id provided"));
  }

  parseSession(sessionStr = ""): SessionState {
    let session: SessionState;

    if (sessionStr && sessionStr.length !== 0) {
      session = JSON.parse(sessionStr);
    }

    return Object.assign({}, initialState, session);
  }

  getId(office: Office): string {
    return Md5.hashStr(office.eaEmployeeId + office.eaOfficeId).toString();
  }
}
