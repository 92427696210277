import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as standardModalActions from "@app/core/components/standard-modal/ngrx/standard-modal.actions";
import * as fromStandardModal from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import { BodyLink } from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import * as routerActions from "@app/core/ngrx/router/router.actions";
import { openCreationFormInExternalProviderRequest } from "@app/sidebar/external-provider/ngrx/external-provider.actions";
import { select, Store } from "@ngrx/store";
import { ModalDirective } from "ngx-bootstrap/modal";
import { Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-standard-modal",
  templateUrl: "./standard-modal.component.html",
  styleUrls: ["./standard-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardModalComponent implements OnInit, OnDestroy {
  @ViewChild("modal", { static: true })
  modal: ModalDirective;

  header$: Observable<string>;
  body$: Observable<string>;
  descriptionTooltip$: Observable<string>;
  links$: Observable<BodyLink[]>;
  externalLinks$: Observable<{ text: string; eaOid: string }[]>;
  confirmButton$: Observable<{ text: string; url: any[] }>;
  showModal$: Observable<boolean>;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.initShowModalStream();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.header$ = this.store.pipe(
      select(fromStandardModal.getStandardModalHeader)
    );
    this.body$ = this.store.pipe(
      select(fromStandardModal.getStandardModalBodyHtml)
    );
    this.descriptionTooltip$ = this.store.pipe(
      select(fromStandardModal.getStandardModalDescriptionTooltip)
    );
    this.links$ = this.store.pipe(
      select(fromStandardModal.getStandardModalBodyLinks)
    );
    this.externalLinks$ = this.store.pipe(
      select(fromStandardModal.getStandardModalBodyExternalLinks)
    );
    this.showModal$ = this.store.pipe(
      select(fromStandardModal.getStandardModalShowStatus)
    );
    this.confirmButton$ = this.store.pipe(
      select(fromStandardModal.getStandardModalConfirmButton)
    );
  }

  initShowModalStream(): void {
    this.showModal$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((showModal: boolean) =>
        showModal ? this.modal.show() : this.modal.hide()
      );
  }

  close(): void {
    this.store.dispatch(standardModalActions.close());
  }

  reset(): void {
    this.store.dispatch(standardModalActions.reset());
  }

  goToRoute(url: any[]): void {
    this.store.dispatch(standardModalActions.close());
    this.store.dispatch(routerActions.go({ path: url }));
  }

  openInExternalProvider(eaOid: string): void {
    this.store.dispatch(
      openCreationFormInExternalProviderRequest({ eaCrmSalesMeetingId: eaOid })
    );
  }
}
