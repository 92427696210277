import { Directive, forwardRef, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: `[validateEqual][formControlName],
  [validateEqual][formControl],[validateEqual][ngModel]`,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EqualValidatorDirective),
      multi: true,
    },
  ],
})
export class EqualValidatorDirective implements Validator {
  @Input() validateEqual: string;
  @Input() reverse: string;

  private get isReverse() {
    if (!this.reverse) {
      return false;
    }
    return this.reverse === "true";
  }
  validate(control: AbstractControl): { [key: string]: any } {
    const value = control.value;
    const targetFormControl = control.root.get(this.validateEqual);

    // value not equal
    if (
      targetFormControl &&
      value !== targetFormControl.value &&
      !this.isReverse
    ) {
      return {
        validateEqual: false,
      };
    }
    // value equal and reverse
    if (
      targetFormControl &&
      value === targetFormControl.value &&
      this.isReverse
    ) {
      delete targetFormControl.errors["validateEqual"];
      if (!Object.keys(targetFormControl.errors).length) {
        targetFormControl.setErrors(null);
      }
    }
    // value not equal and reverse
    if (
      targetFormControl &&
      value !== targetFormControl.value &&
      this.isReverse
    ) {
      targetFormControl.setErrors({ validateEqual: false });
    }
    return null;
  }
}
