import { createAction, props } from "@ngrx/store";

export const fetchContactSummaryRequest = createAction(
  "[CONTACT_SUMMARY] FETCH_CONTACT_SUMMARY_REQUEST",
  props<{
    contactId: string;
    eaEmployeeId: string;
  }>()
);

export const fetchContactSummarySuccess = createAction(
  "[CONTACT_SUMMARY] FETCH_CONTACT_SUMMARY_SUCCESS",
  props<{ payload: unknown[] }>()
);

export const fetchContactSummaryFail = createAction(
  "[CONTACT_SUMMARY] FETCH_CONTACT_SUMMARY_FAIL"
);

export const reset = createAction("[CONTACT_SUMMARY] RESET");
