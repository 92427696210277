<div class="widget-header">
  <h3>
    <app-icon size="large" name="wave-pulse"></app-icon>&nbsp;<span>{{
      "activity" | translate
    }}</span>
  </h3>
</div>
<div class="widget-container">
  <ng-container *ngIf="contactActivity$ | async as contactActivity">
    <app-contact-status-item
      *ngIf="contactActivity.hasFutureTasks"
      [clickable]="true"
      [routerLink]="['/crm', 'contacts', contactId, 'sales-meetings']"
    >
      <app-icon name="check" size="small"></app-icon>
      <span class="margin-left-05">{{ "has_future_tasks" | translate }}</span>
    </app-contact-status-item>
    <app-contact-status-item
      *ngIf="contactActivity.hasActiveAlert"
      [clickable]="true"
      [routerLink]="['/crm', 'contacts', contactId, 'search-profile']"
    >
      <app-icon name="bell" size="small"></app-icon>
      <span class="margin-left-05">{{ "has_active_alert" | translate }}</span>
    </app-contact-status-item>
    <app-contact-status-item
      *ngIf="contactActivity.hasBeenToShowing"
      [clickable]="true"
      [routerLink]="['/crm', 'contacts', contactId, 'residences']"
    >
      <app-icon name="briefcase" size="small"></app-icon>
      <span class="margin-left-05">
        {{
          "has_been_to_showing_within_x_months"
            | translate
              : { amount: (consentFeature$ | async).showingActivityExpiration }
        }}
      </span>
    </app-contact-status-item>
  </ng-container>

  <app-contact-status-item
    *ngIf="(numberOfTasks$ | async) > 0"
    [clickable]="true"
    [routerLink]="['/crm', 'contacts', contactId, 'sales-meetings']"
  >
    <app-icon name="street-view" size="small"></app-icon>
    <span class="margin-left-05">
      {{ "unhandled_leads_and_tasks" | translate }}
      {{ numberOfTasks$ | async }} {{ "pcs" | translate }}
    </span>
  </app-contact-status-item>

  <app-contact-status-item
    *ngIf="(salesMeetings$ | async)?.length > 0"
    [clickable]="true"
    [routerLink]="['/crm', 'contacts', contactId, 'sales-meetings']"
  >
    <app-icon name="briefcase" size="small"></app-icon>
    <span class="margin-left-05">
      {{ "sales_meetings" | translate }}:
      <span *ngIf="(salesMeetings$ | async)?.length > 0">
        <span translate>{{ (salesMeetings$ | async)?.length }}</span>
        <span> {{ "unfinished_meetings" | translate }}</span>
      </span>
    </span>
  </app-contact-status-item>

  <app-contact-status-item
    *ngIf="(numberOfObjects$ | async) > 0"
    [clickable]="true"
    [routerLink]="['/crm', 'contacts', contactId, 'residences']"
    [popover]="objectsTooltip"
    [outsideClick]="true"
    triggers="mouseenter:mouseleave"
    placement="bottom left"
    appClickStopPropagation
  >
    <app-icon name="home" size="small"></app-icon>
    <span class="margin-left-05">
      {{ "object_connections" | translate }}: {{ numberOfObjects$ | async }}
      {{ "pcs" | translate }}
    </span>
  </app-contact-status-item>

  <app-contact-status-item
    [clickable]="false"
    *ngIf="hasPriceUpdateOrder$ | async"
  >
    <app-icon name="comment-alt-dollar" size="small"></app-icon>
    <span class="margin-left-05">
      {{ "vardekollen" | translate }}:
      <span *ngIf="hasPriceUpdateOrder$ | async" translate>active</span>
      <span *ngIf="!(hasPriceUpdateOrder$ | async)" translate>inactive</span>
    </span>
  </app-contact-status-item>

  <ng-container
    *ngIf="
      (npsFeatureEnabled$ | async) &&
      ((contactNps$ | async) || (contactSurveys$ | async)?.length > 0)
    "
  >
    <div class="nps">
      <div class="nps--indicator">
        <nps-indicator
          *ngIf="contactNps$ | async"
          [npsClass]="(contactNps$ | async)?.NPSClass"
          [title]="npsTitle$ | async | uppercase"
          (click)="npsDetails.modal.show()"
        ></nps-indicator>
        <nps-details-modal #npsDetails [nps]="contactNps$ | async">
        </nps-details-modal>
      </div>
      <survey-indicator
        *ngIf="contactSurveys$ | async"
        [surveys]="contactSurveys$ | async"
        (click)="surveys.modal.show()"
      >
      </survey-indicator>
      <app-surveys-selector #surveys [surveys]="contactSurveys$ | async">
      </app-surveys-selector>
    </div>
  </ng-container>
</div>

<ng-template #objectsTooltip>
  <ul class="tooltip-list">
    <li *ngFor="let object of objects$ | async">
      <p>
        {{ object.street }}, {{ object.city }} -
        {{
          getObjectTypeTranslationKey(object.type, object.bidStatus)
            | translate
        }}<br />
        <span *ngIf="object.area"
          >{{ object.area }} {{ "area_unit" | translate }}</span
        ><br />
        <span *ngIf="object.rooms"
          >{{ object.rooms }} {{ "rooms" | translate }}</span
        >
      </p>
    </li>
  </ul>
</ng-template>
