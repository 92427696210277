import { RouterReducerState } from "@ngrx/router-store";
import { RouterStateUrl } from "./custom-serializer";
import { createFeatureSelector, createSelector } from "@ngrx/store";

const selectFeature =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>("router");

export const getUrl = createSelector(
  selectFeature,
  (state: RouterReducerState<RouterStateUrl>) => state.state.url
);
