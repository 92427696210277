import { sidebarCreateCallingListRoutes } from "@app/sidebar/calling-lists/create-calling-list/create-calling-list.routing";
import { createLeadRoutes } from "@app/sidebar/create-lead/create-lead-routing";
import { sidebarCreateObjectRoutes } from "@app/sidebar/create-object/create-object.routing";
import { sidebarEikaRoutes } from "@app/sidebar/eika/eika-routing";
import { sidebarExternalProviderRoutes } from "@app/sidebar/external-provider/external-provider-routing";
import { sidebarLeadChannelsRoutes } from "@app/sidebar/lead-channels/lead-channels-routing";
import { sidebarMessageTemplatesRoutes } from "@app/sidebar/message-templates/message-template-routing";
import { sidebarPotentialBuyerRoutes } from "@app/sidebar/potential-buyer/potential-buyer-routing";
import { sidebarSearchProfileRoutes } from "@app/sidebar/search-profile/search-profile-routing";
import { sidebarSupportRoutes } from "@app/sidebar/support/support-routing";
import { sidebarValueMonitorRoutes } from "@app/sidebar/value-monitor/value-monitor-routing";
import { ROOT_SIDEBAR_ROUTE } from "../shared/utils/sidebar-tab-utils";
import { sidebarCompetitionsRoutes } from "./competitions/competitions-routing";
import { SidebarCreateMenuComponent } from "./components/create-menu/create-menu.component";
import { sidebarContactsRoutes } from "./contacts/contacts-routing";
import { sidebarFollowUpsRoutes } from "./create-follow-up/follow-up-routing";
import { sidebarEmployeesRoutesArray } from "./employees/employees-routing";
import { sidebarExternalTipsRoutes } from "./external-tips/external-tips-routing";
import { SidebarGuard } from "./guards/sidebar-guard.service";
import { ProfileComponent } from "./profile/profile.component";
import { sidebarSalesMeetingsRoutes } from "./sales-meeting/sales-meeting-routing";
import { sidebarSendMessageRoutes } from "./send-message/send-message-routing";
import { SidebarComponent } from "./sidebar.component";
import { sidebarTasksRoutes } from "./tasks/tasks-routing";
import { sidebarTipsRoutes } from "./tips/tips-routing";

export const sidebarRoutes = {
  path: ROOT_SIDEBAR_ROUTE,
  outlet: "sidebar",
  component: SidebarComponent,
  canDeactivate: [SidebarGuard],
  children: [
    { path: "profile", component: ProfileComponent },
    { path: "create", component: SidebarCreateMenuComponent },
    sidebarContactsRoutes,
    sidebarTipsRoutes,
    sidebarCreateCallingListRoutes,
    sidebarCreateObjectRoutes,
    sidebarFollowUpsRoutes,
    sidebarTasksRoutes,
    sidebarSalesMeetingsRoutes,
    ...sidebarEmployeesRoutesArray,
    sidebarSendMessageRoutes,
    sidebarExternalTipsRoutes,
    sidebarCompetitionsRoutes,
    sidebarExternalProviderRoutes,
    ...sidebarPotentialBuyerRoutes,
    sidebarSearchProfileRoutes,
    ...sidebarSupportRoutes,
    createLeadRoutes,
    sidebarEikaRoutes,
    sidebarMessageTemplatesRoutes,
    sidebarLeadChannelsRoutes,
    sidebarValueMonitorRoutes,
  ],
};
