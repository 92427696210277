import { DOCUMENT } from "@angular/common";
import {
  Component,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Sort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as contactActions from "@app/contacts/contact.actions";
import { addBackLink } from "@app/contacts/contact.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { UrlService } from "@app/core/services/url/url.service";
import { Contact } from "@app/models";
import { QTableComponent } from "@app/shared/modules/ui-components/q-table/q-table.component";
import { CONTACTS_CRM_MODULE } from "@app/shared/utils/crm-modules";
import { SIDEBAR_SEND_MESSAGE_URL } from "@app/shared/utils/sidebar-tab-utils";
import * as fromSendMessage from "@app/sidebar/send-message/ngrx/send-message.actions";
import { Store } from "@ngrx/store";
import {
  CallingList,
  CallingListContact,
} from "app/lists/lists-calling-lists/models/calling-list";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  first,
  map,
  Observable,
  Subject,
  takeUntil,
  tap,
} from "rxjs";
import * as callingListsActions from "../../ngrx/calling-lists.actions";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";

@Component({
  selector: "calling-list-contact-list",
  templateUrl: "./calling-list-contact-list.component.html",
  styleUrls: ["./calling-list-contact-list.component.scss"],
})
export class CallingListContactListComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() callingList: CallingList;

  unsubscribe$ = new Subject<void>();
  selectAll = false;
  modalRef: BsModalRef;
  selectedContactId: string;
  selection: Contact[] = [];
  notContactedContact$: Observable<CallingListContact[]>;

  columns = [
    "name",
    "latest_object_connection",
    "status",
    "contacted",
    "created",
    "email",
    "mobile",
  ];
  redColor: string = "";
  paginatedContacts$: Observable<CallingListContact[]>;
  paginator$: BehaviorSubject<Partial<PageEvent>> = new BehaviorSubject<
    Partial<PageEvent>
  >({
    pageSize: 25,
    pageIndex: 0,
  });

  @ViewChild(QTableComponent, { static: false }) qTable: QTableComponent;
  sortableColumns = [
    "name",
    "latest_object_connection",
    "status",
    "contacted",
    "created",
    "email",
    "mobile",
  ];

  sorting$: BehaviorSubject<Sort> = new BehaviorSubject<Sort>({
    active: "name",
    direction: "asc",
  });

  constructor(
    private modalService: BsModalService,
    private urlService: UrlService,
    public contactService: ContactService,
    private router: Router,
    private store: Store<AppState>,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.redColor =
      "rgba(" +
      getComputedStyle(this.document.documentElement).getPropertyValue(
        "--red"
      ) +
      ")";
  }

  ngOnInit(): void {
    this.fetchContacts();
    this.notContactedContact$ = this.contactService.currentList$.pipe(
      map((contacts) => {
        return contacts.filter((contact) => !contact.contactedDate);
      })
    );

    this.paginatedContacts$ = combineLatest([
      this.paginator$.pipe(debounceTime(400)),
      this.contactService.currentList$.pipe(tap(() => this.goToFirstPage())),
    ]).pipe(
      map(([paginator, contacts]) => {
        const page = paginator.pageIndex;
        const size = paginator.pageSize;
        const newData = [...contacts];
        newData.splice((page + 1) * size, newData.length - size);
        return newData;
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.callingList.currentValue != this.callingList) {
      this.fetchContacts();
    }
  }

  startCallingQueueFromContact(contact: CallingListContact): void {
    this.notContactedContact$.pipe(first()).subscribe((contacts) => {
      this.store.dispatch(
        callingListsActions.setCallingListNotContactedContacts({
          notContactedContacts: contacts,
        })
      );
      this.store.dispatch(
        RouterActions.go({
          path: [
            "crm",
            CONTACTS_CRM_MODULE,
            contact?.contactId ? contact.contactId : contacts[0].contactId,
          ],
        })
      );
    });
    this.store.dispatch(contactActions.activateCallListMode());
  }

  contactCheckboxClicked(selectedRow: CallingListContact): void {
    const contact = this.formatContacts([selectedRow])[0];

    if (this.selection.length === 0) {
      this.selection.push(contact);
      this.openSendMessageSidebar();
      this.store.dispatch(
        fromSendMessage.addContacts({ recipients: [contact] })
      );
    } else if (
      this.selection.filter((c) => c.contactId === contact.contactId).length > 0
    ) {
      this.selection = this.selection.filter((c) => {
        return c.contactId !== contact.contactId;
      });
      this.store.dispatch(
        fromSendMessage.removeContacts({ contactIds: [contact.contactId] })
      );
    } else {
      this.selection.push(contact);
      this.store.dispatch(
        fromSendMessage.addContacts({ recipients: [contact] })
      );
    }
  }

  formatContacts(callingListContacts: CallingListContact[]): Contact[] {
    return callingListContacts.map(
      (contact: CallingListContact) => new Contact(contact)
    );
  }

  handleRemoveFromCallingList(): void {
    this.removeContactFromCallingList(this.selectedContactId);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { animated: false });
  }

  openObjectInNewTab(e: Event, eaOid): void {
    e.stopPropagation();
    const commands = ["/crm", "showings", eaOid];
    const url = this.urlService.buildUrlFromRouterCommands(commands);
    openLinkInNewTab(url);
  }

  openObject(event: any, eaOid: string) {
    if (!event.target.attributes["disablenavigation"]) {
      this.store.dispatch(addBackLink({ backLink: this.router.url }));
      this.store.dispatch(
        RouterActions.go({ path: ["crm", "showings", eaOid] })
      );
    }
  }

  handleSelect(selection: Contact[]) {
    this.contactService.currentList$.pipe(first()).subscribe((list) => {
      if (selection?.length === list.length) {
        this.selection = [...list];
      } else {
        this.selection = selection;
      }
    });
  }

  openSendMessageSidebar() {
    this.store.dispatch(
      fromSendMessage.addContacts({
        recipients: this.selection.map((contact) => new Contact(contact)),
      })
    );
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm/", { outlets: { sidebar: SIDEBAR_SEND_MESSAGE_URL } }],
      })
    );
  }

  onPage($event: PageEvent) {
    this.paginator$.next({
      pageSize: $event.pageSize,
      pageIndex: $event.pageIndex,
    });
  }

  onSort($event: Sort) {
    this.sorting$.next({
      active: $event.active,
      direction: $event.direction,
    });
  }

  removeContactFromCallingList(contactId: string): void {
    this.contactService
      .removeContactFromCallingList(this.callingList.callingListId, contactId)
      .subscribe();
  }

  private goToFirstPage() {
    this.paginator$.next({
      ...this.paginator$.value,
      pageIndex: 0,
    });
    this.qTable?.paginator?.firstPage();
  }

  private fetchContacts() {
    this.sorting$.pipe(takeUntil(this.unsubscribe$)).subscribe((sorting) => {
      this.contactService
        .getFromCallingListId(this.callingList.callingListId, {
          sortOrder: sorting.direction,
          sortBy: this.formatSort(sorting.active),
        })
        .pipe(first())
        .subscribe();
    });
  }

  private formatSort(columnName: string) {
    switch (columnName) {
      case "name":
        return "firstName";
      case "latest_object_connection":
        return "street";
      case "status":
        return "contactedDate";
      case "contacted":
        return "contactedDate";
      case "created":
        return "insertedDate";
      case "email":
        return "email";
      case "mobile":
        return "phone";
    }
  }
}
