<div class="q-tab-bar">
  <div class="q-tab-bar__container">
    <div
      class="q-tab-bar__item"
      matTooltipClass="q-tooltip"
      [matTooltip]="tab.tooltip"
      *ngFor="let tab of tabs"
      (click)="onClick(tab)"
      [class.active]="activeTab?.value === tab?.value"
    >
      {{ tab.label | translate }}
    </div>
  </div>
</div>
