import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { CustomFilter } from "@app/models/custom-filter";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class CustomFilterService extends CustomEntityCollectionServiceBase<CustomFilter> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("CustomFilter", serviceElementsFactory);
  }

  // @ts-ignore
  getWithQuery = (params: any) => {
    this.setLoading(true);
    return this.apiService.get("custom-filters/search", params).pipe(
      map((response: PaginationListDTO) => {
        this.setLoading(false);
        this.upsertManyInCache(response.rows);
        this.setListDefaults(response.rows, response);
        return response.rows;
      }),
      catchError((err) => err)
    );
  };
}
