import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreatePotentialBuyerContactComponent } from "@app/sidebar/contacts/create-potential-buyer-contact/create-potential-buyer-contact.component";
import { PotentialBuyerModule } from "@app/sidebar/potential-buyer/potential-buyer.module";
import { TranslateModule } from "@ngx-translate/core";
import { AlertModule } from "ngx-bootstrap/alert";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { CreateContactComponent } from "./create-contact/create-contact.component";
import { CreateSellerBuyerContactComponent } from "./create-seller-buyer-contact/create-seller-buyer-contact.component";
import { EditContactComponent } from "./edit-contact/edit-contact.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    SearchContactModule,
    AlertModule,
    PotentialBuyerModule,
    UiComponentsModule,
    MatExpansionModule,
    UtilModule,
  ],
  declarations: [
    CreateContactComponent,
    EditContactComponent,
    CreatePotentialBuyerContactComponent,
    CreateSellerBuyerContactComponent,
  ],
})
export class CreateContactModule {}
