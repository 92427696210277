<div class="input-iconfield" [class.pseudo-spinner]="loading">
  <input
    #suggester
    [(ngModel)]="selected"
    [typeahead]="dataSource || []"
    [typeaheadMinLength]="2"
    [typeaheadWaitMs]="200"
    [typeaheadItemTemplate]="customItemTemplate"
    (typeaheadLoading)="onLoadingChange($event)"
    (typeaheadOnSelect)="onSelect($event.item)"
    (focus)="markAsTouched()"
    [placeholder]="placeholder | translate"
  />
  <i class="icon icon-magnifier"></i>
  <ng-template #customItemTemplate let-model="item">
    <search-employee-shared-card
      [employee]="model"
    ></search-employee-shared-card>
  </ng-template>
</div>
