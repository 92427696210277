import { Injectable } from "@angular/core";
import { ApiService } from "@app/core/services/api/api.service";
import { map, Observable } from "rxjs";

@Injectable()
export class DevApiService {
  constructor(private apiService: ApiService) {}

  getCustomers(): Observable<any> {
    return this.apiService.get("customers", {}, "dev", false);
  }

  switchToCustomer(customer: string): Observable<any> {
    return this.apiService.postWithoutResponse(
      `customers/${customer}/switchTo`,
      {},
      "dev"
    );
  }

  getDevToken(): Observable<string> {
    return this.apiService
      .get("customers/currentlySelected/devToken", {}, "dev", false)
      .pipe(map((response: any) => response?.token));
  }

  currentlySelected(): Observable<string> {
    return this.apiService
      .get("customers/currentlySelected", {}, "dev", false)
      .pipe(
        map((response: { selectedOrigin: string }) => response?.selectedOrigin)
      );
  }
}
