import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as widgetActions from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.actions";
import * as fromWidget from "@app/widgets/widgets/statistics-object-status-widget/ngrx/statistics-object-status-widget.reducer";
import { select, Store } from "@ngrx/store";
import { Observable, skip, Subject, takeUntil } from "rxjs";

@Component({
  selector: "app-statistics-object-status-widget",
  templateUrl: "./statistics-object-status-widget.component.html",
  styleUrls: ["./statistics-object-status-widget.component.scss"],
})
export class StatisticsObjectStatusWidgetComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() eaEmployeeId: string;
  @Input() eaOfficeId: string;
  @Input() objectStatuses: { display: string; value: any }[] = [];
  @Input() triggerDataFetch$: Observable<void>;

  statistics$: Observable<fromWidget.ObjectStatusStatistics[]>;
  loading$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.mapStateToProps();

    if (!this.eaEmployeeId && !this.eaOfficeId && !this.objectStatuses) {
      this.getStatistics();
    }

    this.triggerDataFetch$
      ?.pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.getStatistics();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.eaEmployeeId || changes.eaOfficeId || changes.objectStatuses) {
      this.getStatistics();
    }
  }

  private mapStateToProps() {
    this.statistics$ = this.store.pipe(select(fromWidget.getStats));
    this.loading$ = this.store.pipe(select(fromWidget.getLoading));
  }

  private getStatistics() {
    const params = {
      ...this.getIds(),
      objectStatuses: this.objectStatuses
        .map((status) => status.value)
        .toString(),
    };
    this.store.dispatch(widgetActions.fetchStatisticsRequest({ params }));
  }

  private getIds() {
    const params = {};
    if (!!this.eaEmployeeId) {
      params["eaEmployeeId"] = this.eaEmployeeId;
    }
    if (!!this.eaOfficeId) {
      params["eaOfficeId"] = this.eaOfficeId;
    }
    return params;
  }
}
