import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { filter, Observable, Subject, takeUntil } from "rxjs";
import { ObjectFile, QObject } from "@app/models";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import * as showingActions from "@app/showings/ngrx/showings/showings.actions";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-add-object-files-modal",
  templateUrl: "./add-object-files-modal.component.html",
  styleUrls: ["./add-object-files-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddObjectFilesModalComponent implements OnInit, OnDestroy {
  objectFilesForm: FormGroup;
  objectFilesSingleFileTooLargeError = false;
  objectFilesAllFilesTooLargeError = false;
  objectFilesWrongFormatError = false;
  objectFilesTooManyFilesError = false;
  hasFiles: boolean;
  isObjectSelected: boolean;
  objectFiles: ObjectFile[] = [];
  objectFilesAllowedFormats = [
    "pdf",
    "doc",
    "gif",
    "png",
    "jpg",
    "jpeg",
    "bmp",
    "doc",
    "docx",
    "csv",
    "txt",
    "xls",
    "ppt",
    "pptx",
    "dotx",
    "xlam",
    "xlsb",
    "xltm",
    "xlsx",
  ];
  object$: Observable<QObject>;
  objectIsLoading$: Observable<boolean>;
  unsubscribe$ = new Subject<void>();

  get filesFormArray(): FormArray {
    return this.objectFilesForm.get("files") as FormArray;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AddObjectFilesModalComponent>,
    private store: Store<AppState>,
    private fb: FormBuilder,
    public sendMessageService: SendMessageService
  ) {
    this.buildForms();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.loadObjectIfInShowingsModule();
    this.handleObjectFiles();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeModal() {
    this.dialogRef.close();
  }

  roundFileSize(size: number): string {
    return size.toFixed(1);
  }

  openUrl(url: string): void {
    window.open(url);
  }

  handleObjectChosen(chosenObject: QObject) {
    this.filesFormArray.clear();
    this.isObjectSelected = !!chosenObject;

    if (!!chosenObject) {
      this.store.dispatch(
        showingActions.getObjectRequest({
          eaOid: chosenObject.eaOid,
          params: {
            getFiles: true,
          },
        })
      );
    } else {
      this.store.dispatch(showingActions.getObjectSuccess({ property: null }));
    }
  }

  handleObjectFiles(): void {
    this.object$
      .pipe(
        filter((val) => !!val),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((object) => {
        this.filesFormArray.clear();
        this.objectFiles = [];
        if (object.files.length > 0) {
          this.objectFiles.push(...object.files);
        }
        if (object.estateNonPublicFiles.length > 0) {
          this.objectFiles.push(...object.estateNonPublicFiles);
        }
        this.objectFiles.forEach(() =>
          this.filesFormArray.push(new FormControl())
        );
        this.hasFiles = this.objectFiles.length > 0;
      });
  }

  isFileSelectionValid(formValues: any): boolean {
    const selectedFiles = [];
    formValues.files.forEach((filesShouldBeAdded, i) => {
      if (filesShouldBeAdded) {
        selectedFiles.push(this.objectFiles[i]);
      }
    });

    const maxSingleFileSize = 9437184;
    const maxTotalFileSize = 47185920;

    const formIsValid = this.objectFilesForm.valid;

    const singleFileExistsLargerThan9MB =
      selectedFiles.length > 0
        ? selectedFiles.find(
            (file: ObjectFile) => file.fileSize > maxSingleFileSize
          )
        : false;

    const allFilesAreLargerThan45MB =
      selectedFiles.length > 0
        ? selectedFiles.reduce((acc, curr) => acc + curr) > maxTotalFileSize
        : false;

    const someFilesHaveWrongFormat =
      selectedFiles.length > 0
        ? selectedFiles.find(
            (file: ObjectFile) =>
              !this.objectFilesAllowedFormats.includes(
                file.fileType.toLowerCase()
              )
          )
        : false;

    this.objectFilesSingleFileTooLargeError = singleFileExistsLargerThan9MB;
    this.objectFilesAllFilesTooLargeError = allFilesAreLargerThan45MB;
    this.objectFilesWrongFormatError = someFilesHaveWrongFormat;
    this.objectFilesTooManyFilesError = selectedFiles.length > 5;

    return (
      formIsValid &&
      selectedFiles.length > 0 &&
      !singleFileExistsLargerThan9MB &&
      !allFilesAreLargerThan45MB &&
      !someFilesHaveWrongFormat
    );
  }

  handleAddFiles(): void {
    const filesToAdd = [];
    this.objectFilesForm
      .getRawValue()
      .files.forEach((filesShouldBeAdded, i) => {
        if (filesShouldBeAdded) {
          filesToAdd.push(this.objectFiles[i]);
        }
      });
    this.sendMessageService.objectFiles$.next(filesToAdd);
    this.closeModal();
  }

  loadObjectIfInShowingsModule(): void {
    // Todo: Dont use regex...
    const pattern = /showings\/([\s\S]*?)\/potential-buyers/;
    const result = location.href.match(pattern);
    if (result && result[1]) {
      const eaOid = result[1];
      this.store.dispatch(
        showingActions.getObjectRequest({
          eaOid,
          params: {
            getFiles: true,
          },
        })
      );
    }
  }

  private buildForms() {
    this.objectFilesForm = this.fb.group({
      files: this.fb.array([]),
    });
  }

  private mapStateToProps() {
    this.object$ = this.store.pipe(select(fromShowings.getObject));
    this.objectIsLoading$ = this.store.pipe(
      select(fromShowings.getObjectLoading)
    );
  }
}
