import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ComingSoonComponent } from "@app/shared/modules/pages/coming-soon/coming-soon.component";
import { MobileRedirectComponent } from "@app/shared/modules/pages/mobile-redirect/mobile-redirect.component";
import { SystemTemporarilyDownComponent } from "@app/shared/modules/pages/system-temporarily-down/system-temporarily-down.component";
import { UnknownOriginComponent } from "@app/shared/modules/pages/unknown-origin/unknown-origin.component";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ExternalWidgetMainComponent } from "@app/shared/modules/pages/external-widget-main/external-widget-main.component";
import { WidgetsModule } from "@app/widgets/widgets.module";

const components = [
  PageNotFoundComponent,
  ComingSoonComponent,
  SystemTemporarilyDownComponent,
  UnknownOriginComponent,
  MobileRedirectComponent,
  ExternalWidgetMainComponent,
];

@NgModule({
  imports: [CommonModule, TranslateModule, UiComponentsModule, WidgetsModule],
  declarations: components,
  exports: components,
})
export class PagesModule {}
