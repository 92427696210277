<div class="flex-space-between">
  <div class="widget-header">
    <h3>
      <app-icon size="large" name="comment-alt-dollar"></app-icon>&nbsp;<span>{{
        "value_monitor" | translate
      }}</span>
    </h3>
    <spinner
      [size]="'28px'"
      [style.margin-left]="'8px'"
      *ngIf="valueMonitorService.loading$ | async"
    ></spinner>
  </div>
  <app-q-circle-btn
    icon="plus"
    tooltipText="create_value_monitor"
    (click)="createValueMonitor()"
  ></app-q-circle-btn>
</div>

<div class="widget-container">
  <app-q-table
    [columns]="columns"
    [headers]="headers"
    [data]="valueMonitorService.currentList$ | async"
    [enableSearch]="false"
    [enableCustomization]="false"
    [enablePaginator]="false"
    (rowClick)="editValueMonitor($event.contactPriceUpdateOrdersId)"
  >
    <ng-template let-cell="cell" let-column="column" let-row="row">
      <ng-container [ngSwitch]="column">
        <ng-container *ngSwitchCase="'contact'">
          <app-contact-name
            [contactId]="row.contactId"
            [contactType]="row.contactType"
          >
            {{ row.firstName }} {{ row.familyName }}
          </app-contact-name>
        </ng-container>
        <ng-container *ngSwitchCase="'baseValuation'">
          {{ cell | mask : "separator" : " " }}
        </ng-container>
        <ng-container *ngSwitchDefault>{{ cell }}</ng-container>
      </ng-container>
    </ng-template>
    <ng-template let-row="row">
      <app-q-action-dropdown-item
        label="edit"
        icon="pencil"
        (press)="editValueMonitor(row.contactPriceUpdateOrdersId)"
      ></app-q-action-dropdown-item>
      <app-q-action-dropdown-item
        label="remove"
        icon="trash"
        iconColor="red"
        (press)="removeValueMonitor(row.contactPriceUpdateOrdersId)"
      ></app-q-action-dropdown-item>
    </ng-template>
  </app-q-table>
</div>
