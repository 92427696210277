import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as contactSmActions from "@app/contacts/contact-sales-meetings/contact-sales-meetings.actions";
import { ActionsSubject, select, Store } from "@ngrx/store";
import {
  first,
  map,
  mergeWith,
  Observable,
  of as observableOf,
  switchMap,
  tap,
} from "rxjs";
import * as smActions from "../ngrx/sales-meeting.actions";
import * as fromSalesMeeting from "../ngrx/sales-meeting.reducer";
import { ofType } from "@ngrx/effects";

@Injectable()
export class SalesMeetingEditGuardService implements CanActivate {
  constructor(
    private store: Store<AppState>,
    private dispatcher: ActionsSubject
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const { smId } = route.params;

    this.store.dispatch(smActions.getSalesMeetingRequest({ id: smId }));

    const salesMeeting$ = this.dispatcher.pipe(
      ofType(smActions.getSalesMeetingSuccess),
      switchMap(() => this.store.pipe(select(fromSalesMeeting.getSalesMeeting)))
    );

    const residenceSuccess$ = this.dispatcher.pipe(
      ofType(smActions.getResidenceSuccess),
      map(() => true)
    );

    const residenceFail$ = this.dispatcher.pipe(
      ofType(smActions.getResidenceFail),
      map(() => false)
    );

    return salesMeeting$.pipe(
      switchMap((salesMeeting) => {
        const { eaOid } = salesMeeting;

        if (!eaOid) {
          this.store.dispatch(
            contactSmActions.noEditModalShow({ salesMeeting })
          );
          return observableOf(false);
        }

        // need to check that eaOid really belongs to a residence and not a object
        // Error message: THE_OBJECT_IS_NOT_AN_EXISTING_HOME
        this.store.dispatch(smActions.getResidenceRequest({ id: eaOid }));
        return residenceSuccess$.pipe(
          mergeWith(residenceFail$),
          tap((isResidence) => {
            if (!isResidence) {
              this.store.dispatch(
                contactSmActions.noEditModalShow({ salesMeeting })
              );
            }
          })
        );
      }),
      first()
    );
  }
}
