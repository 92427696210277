import { Injectable, SecurityContext } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import { ApiService } from "@app/core/services/api/api.service";
import { QModalService } from "@app/shared/modules/ui-components/q-modal/q-modal.service";
import { BidSettingModalComponent } from "@app/showings/components/bid-setting-modal/bid-setting-modal.component";
import { SupportedDeepLinksEnum } from "@app/showings/enums/supported-deep-links.enum";
import { first, tap } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BidService {
  constructor(
    private apiService: ApiService,
    private sanitizer: DomSanitizer,
    private modalService: QModalService
  ) {}

  openBiddingSettingsModal = (
    estateAgentOfficeId: string,
    contactId: string
  ) => {
    return this.apiService
      .post(
        `generate/smart-link/for/${SupportedDeepLinksEnum.BIDDER_SETTINGS}`,
        { context: { eaOid: estateAgentOfficeId, contactId } },
        "integrations"
      )
      .pipe(
        first(),
        tap((link: ExternalPresentableLink) => this.manageLink(link))
      );
  };

  openSetAsWinningBidModal = (bidId: string) => {
    return this.apiService
      .post(
        `generate/smart-link/for/${SupportedDeepLinksEnum.SET_WINNING_BID}`,
        { context: { bidId } },
        "integrations"
      )
      .pipe(
        first(),
        tap((link: ExternalPresentableLink) => this.manageLink(link))
      );
  };

  private manageLink = (link: ExternalPresentableLink) => {
    if (link.iframePresentableLink?.singleSignOnRewritable) {
      this.apiService
        .post(
          `ssoify/link/for/${link.source}`,
          { url: link.iframePresentableLink.completeUrl },
          "integrations"
        )
        .pipe(first())
        .subscribe((response: { url: string }) => {
          const url = this.sanitizer.sanitize(
            SecurityContext.URL,
            response.url
          );
          this.openModal({ url });
        });
    } else {
      const url = this.sanitizer.sanitize(
        SecurityContext.URL,
        link.iframePresentableLink.completeUrl
      );
      this.openModal({ url });
    }
  };

  private openModal = (data: any) => {
    this.modalService.show(BidSettingModalComponent, {
      data: {
        hasActionBar: true,
        ...data,
      },
    });
  };
}
