import {
  InvoicingPeriodResponse,
  InvoicingStatisticsResponse,
} from "@app/statistics/statistics-invoicing/models/invoicing-models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as statisticsInvoicingActions from "./statistics-invoicing.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsInvoicingState {
  loadingStatistics: boolean;
  loadingPeriods: boolean;
  invoicingStatistics: InvoicingStatisticsResponse;
  invoicingPeriods: InvoicingPeriodResponse[];
  csv: string;
  csvLoading: boolean;
}

export const initialState: StatisticsInvoicingState = {
  loadingStatistics: false,
  loadingPeriods: false,
  invoicingStatistics: null,
  invoicingPeriods: [],
  csv: "",
  csvLoading: false,
};

export function statisticsInvoicingReducer(
  state: StatisticsInvoicingState,
  action: Action
): StatisticsInvoicingState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsInvoicingActions.getInvoicingStatisticsRequest, (state) => ({
    ...state,
    loadingStatistics: true,
  })),
  on(
    statisticsInvoicingActions.getInvoicingStatisticsSuccess,
    (state, { invoices }) => ({
      ...state,
      loadingStatistics: false,
      invoicingStatistics: invoices,
    })
  ),
  on(statisticsInvoicingActions.getInvoicingStatisticsFailure, (state) => ({
    ...state,
    loadingStatistics: false,
  })),
  on(statisticsInvoicingActions.getInvoicingPeriodsRequest, (state) => ({
    ...state,
    loadingPeriods: true,
  })),
  on(
    statisticsInvoicingActions.getInvoicingPeriodsSuccess,
    (state, { invoices }) => ({
      ...state,
      loadingPeriods: false,
      invoicingPeriods: invoices,
    })
  ),
  on(statisticsInvoicingActions.getInvoicingPeriodsFailure, (state) => ({
    ...state,
    loadingPeriods: false,
  })),
  on(statisticsInvoicingActions.getInvoicingCSVRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(statisticsInvoicingActions.getInvoicingCSVSuccess, (state, { csv }) => ({
    ...state,
    csvLoading: false,
    csv,
  })),
  on(statisticsInvoicingActions.getInvoicingCSVFailure, (state) => ({
    ...state,
    csvLoading: false,
  })),
  on(statisticsInvoicingActions.resetInvoiceState, () => ({ ...initialState }))
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.invoicing
);
export const getInvoicingStatistics = createSelector(
  getState,
  (state: StatisticsInvoicingState) => state.invoicingStatistics
);
export const getInvoicingPeriods = createSelector(
  getState,
  (state: StatisticsInvoicingState) => state.invoicingPeriods
);
