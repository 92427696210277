import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import { Region } from "@app/models/region";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class RegionService extends CustomEntityCollectionServiceBase<Region> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("Region", serviceElementsFactory);
  }

  // @ts-ignore
  getWithQuery = (params) => {
    this.setLoading(true);
    return this.apiService.get("regions/search", params, "api", false).pipe(
      map((response: PaginationListDTO) => {
        const regions = response.rows;
        this.upsertManyInCache(regions);
        this.setLoading(false);
        this.setListDefaults(regions, response);
        return regions;
      }),
      catchError((err) => {
        return err;
      })
    );
  };
}
