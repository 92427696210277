import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { ProductNewsService } from "@app/shared/modules/product-news/product-news.service";
import { combineLatest, Observable } from "rxjs";
import { filter, first } from "rxjs/operators";

@Component({
  selector: "product-news",
  templateUrl: "./product-news.component.html",
  styleUrls: ["./product-news.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductNewsComponent implements OnInit, OnDestroy {
  unseenProductNews$: Observable<{ count: number }>;

  constructor(private productNewsService: ProductNewsService) {}

  ngOnInit(): void {
    this.unseenProductNews$ = this.productNewsService.unseenProductNews$;
    this.productNewsService.initService();

    combineLatest([
      this.productNewsService.unseenProductNews$,
      this.productNewsService.isShown$,
    ])
      .pipe(
        first(),
        filter(([unseen, isShown]) => unseen?.count > 0 && !isShown)
      )
      .subscribe(() => {
        this.productNewsService.showModal();
        this.productNewsService.setIsShownDialog();
      });
  }

  ngOnDestroy() {
    this.productNewsService.setIsShownDialog(false);
  }

  showModal() {
    this.productNewsService.showModal();
  }
}
