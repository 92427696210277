import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { NgrxUtilService } from "@app/core/ngrx/services/ngrx-util.service";
import { ApiService } from "@app/core/services/api/api.service";
import { CallingListContact } from "@app/lists/lists-calling-lists/models/calling-list";
import {
  Contact,
  ContactObjectConnection,
  ContactProfile,
  PaginationListDTO,
} from "@app/models";
import { SegmentationFilters } from "@app/models/segmentation-filters";
import { getEaEmployeeId } from "@app/shared/user";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as consentTypes from "@app/shared/utils/consent-types";
import { prependUTF8BOM } from "@app/shared/utils/csv";
import { MatchingContact, PotentialBuyer } from "@app/showings/models";
import { ContactShowingAttendance } from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import moment from "moment";
import {
  catchError,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
  takeUntil,
} from "rxjs";

@Injectable({ providedIn: "root" })
export class ContactService extends CustomEntityCollectionServiceBase<
  Contact & PotentialBuyer & CallingListContact & MatchingContact
> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>,
    private translate: TranslateService,
    private ngrxUtilService: NgrxUtilService
  ) {
    super("Contact", serviceElementsFactory);
  }

  getById = (id: string, additionalParams?: any) => {
    if (!!id) {
      return this.apiService
        .get(`contacts/${id}`, { ...additionalParams }, "api")
        .pipe(
          map((response: any) => {
            const contact = new Contact(response);
            // @ts-ignore
            this.upsertOneInCache(contact);
            return contact;
          }),
          catchError(() => {
            this.appStore.dispatch(
              toastActions.danger({ message: "contact_load_failed" })
            );
            return of(null);
          })
        );
    } else {
      return of(null);
    }
  };

  newGetWithQuery = (
    params: {
      filters: Partial<SegmentationFilters>;
      sortBy: string;
      sortOrder: string;
      limit: number;
      offset: number;
    },
    setListDefaults = true
  ): Observable<(Contact & PotentialBuyer)[]> => {
    this.setLoading(true);
    if (setListDefaults) {
      this.cancelExistingRequest$.next();
    }

    const formattedParams = this.formatSearchParams({ ...params });

    // @ts-ignore
    return this.apiService
      .post(
        `contact-segmentation/custom/contacts`,
        { ...formattedParams },
        "api"
      )
      .pipe(
        takeUntil(this.cancelExistingRequest$),
        switchMap((response: PaginationListDTO) => {
          this.setLoading(false);
          const contacts = response?.rows;
          this.upsertManyInCache(contacts);
          if (setListDefaults) {
            this.setListDefaults(contacts, response);
          }
          return this.getListFromEntities(contacts);
        }),
        catchError((err) => {
          this.fetchErrorHandler(setListDefaults);
          return err;
        })
      );
  };

  getAsCsv = (params: {
    filters: Partial<SegmentationFilters>;
    sortBy: string;
    sortOrder: string;
    limit: number;
  }): Observable<(Contact & PotentialBuyer)[]> => {
    this.setLoading(true);
    const formattedParams = this.formatSearchParams({ ...params });

    // @ts-ignore
    return this.apiService
      .postWithoutResponse(
        `contact-segmentation/custom/contacts/csv`,
        { ...formattedParams },
        "api"
      )
      .pipe(
        map((response: string) => {
          this.setLoading(false);
          const blob = new Blob([prependUTF8BOM(response)], {
            type: "text/csv",
          });

          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `contacts-${moment().format("YYYY-MM-DD HH:mm")}.csv`;

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          return true;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "contacts_load_failed" })
          );
          return err;
        })
      );
  };

  patch(
    id: string,
    entity: Partial<Contact>,
    clearNonPresentNullableFields = false
  ) {
    this.setLoading(true);
    if ("consentValidTo" in entity) {
      delete entity.consentValidTo;
    }

    this.updateOneInCache({ contactId: id, ...entity });
    return this.apiService
      .patch(
        `contacts/${id}`,
        {
          ...entity,
          clearNonPresentNullableFields,
        },
        "api"
      )
      .pipe(
        map((response: any) => {
          this.setLoading(false);
          const contact = new Contact(response);
          this.upsertOneInCache(contact);
          this.appStore.dispatch(
            toastActions.success({ message: "contact_updated" })
          );
          return contact;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "contact_update_failed" })
          );
          return err;
        })
      );
  }

  post(entity: Partial<Contact>, showToast = true) {
    this.setLoading(true);
    return this.appStore.pipe(
      select(getEaEmployeeId),
      first(),
      switchMap((eaEmployeeId) => {
        const consentValidTo = entity?.consentValidTo
          ? entity.consentValidTo
          : moment().endOf("day").add(30, "days").format(API_DATE_FORMAT);
        delete entity?.consentValidTo;

        return this.apiService
          .post(
            `contacts`,
            {
              ...entity,
              consent: {
                activeConsentTypeAlias: consentTypes.EMPLOYEE_ENTRY,
                consentValidTo,
                consentNote: this.translate.instant("from_create_contact_form"),
                consentSourceType: "user",
                consentSourceId: eaEmployeeId,
              },
            },
            "api"
          )
          .pipe(
            map((response: any) => {
              this.setLoading(false);
              const contact = response;
              this.addOneToCache(contact);

              if (showToast) {
                const translations = this.translate.instant(
                  ["goto_contact_card", "contact_{name}_created"],
                  {
                    name: `${entity.firstName} ${entity.familyName}`,
                  }
                );

                this.appStore.dispatch(
                  toastActions.success(
                    {
                      message: translations["contact_{name}_created"],
                      duration: 8000,
                    },
                    {
                      label: translations["goto_contact_card"],
                      icon: "fa-arrow-right",
                      action: RouterActions.go({
                        path: ["crm", "contacts", contact.contactId],
                      }),
                    }
                  )
                );
              }
              return contact;
            }),
            catchError((err) => {
              this.setLoading(false);
              this.appStore.dispatch(
                toastActions.danger({ message: "contact_create_failed" })
              );
              return err;
            })
          );
      })
    );
  }

  // REGISTER SALES CALL
  registerSalesCall = (contactId: string, params: any) => {
    if (!!contactId) {
      return this.apiService.post(`contacts/${contactId}/actions`, params).pipe(
        map((response: { timestamp: string }) => {
          this.updateOneInCache({
            contactId: contactId,
            contactedDate: response?.timestamp,
          });
          this.appStore.dispatch(
            toastActions.success({ message: "sales_call_registered" })
          );
          return response;
        })
      );
    } else {
      return of(null);
    }
  };

  // CONTACT PROFILE APIS
  getProfile = (id: string) => {
    if (!!id) {
      return this.apiService.get(`contacts/${id}/profile`, {}, "api").pipe(
        map((response: Partial<Contact>) => {
          const contact = new Contact({
            contactId: response.contactId,
            profile: response,
          });
          this.upsertOneInCache(contact);
          return contact;
        }),
        catchError((err) => {
          return err;
        })
      );
    } else {
      return of(null);
    }
  };

  patchProfile(
    id: string,
    entity: Partial<ContactProfile>,
    additionalCommands = () =>
      this.ngrxUtilService.additionalCommands("contact_updated")
  ) {
    this.setLoading(true);
    return this.apiService
      .patch(
        `contacts/${id}/profile`,
        {
          ...entity,
        },
        "api"
      )
      .pipe(
        map((response: any) => {
          const contact = new Contact({
            contactId: response.contactId,
            profile: response,
          });
          this.upsertOneInCache(contact);
          additionalCommands();
          this.setLoading(false);
          return contact;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "contact_update_failed" })
          );
          return err;
        })
      );
  }

  // CONTACT OBJECT CONNECTION APIS
  fetchContactObjectConnection(id: string, params?: { eaOid: string }) {
    return this.apiService
      .get(`object/contact/${id}/contact-object-connections/search`, params)
      .pipe(
        map((res: { contactObjectConnections: ContactObjectConnection[] }) => {
          this.updateOneInCache({
            contactId: id,
            contactObjectConnections: res.contactObjectConnections,
          });
          return res.contactObjectConnections;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "contact_load_failed" })
          );
          return err;
        })
      );
  }

  postContactObjectConnection(
    id: string,
    params: {
      eaOid: string;
      type?: string;
      bidStatus: string;
    }
  ) {
    this.setLoading(true);
    return this.apiService
      .post(`contacts/${id}/contact-object-connections`, params)
      .pipe(
        map((res) => {
          if (!!params?.bidStatus) {
            // Todo: Update contact.contactObjectConnections as well
            this.updateOneInCache({
              contactId: id,
              bidStatus: params.bidStatus,
            });
          }
          this.setLoading(false);
          return res;
        }),
        catchError((err) => {
          this.setLoading(false);
          this.appStore.dispatch(
            toastActions.danger({ message: "contact_update_failed" })
          );
          return err;
        })
      );
  }

  removeContactObjectConnection(id: string, eaOid: string) {
    return this.apiService
      .delete(`object/contact/${eaOid}/relation/${id}/POTENTIAL_BUYER`)
      .pipe(
        map((res) => {
          this.handleDelete(id);
          return res;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({
              message:
                "remove_contact_form_object_potential_buyers_list_failed",
            })
          );
          return err;
        })
      );
  }

  // POTENTIAL BUYERS APIS
  refreshPotentialBuyers = (): Observable<any> | any => {};
  getPotentialBuyersWithQuery = (eaOid: string, params?: any) => {
    this.refreshPotentialBuyers = () =>
      this.getPotentialBuyersWithQuery(eaOid, params);
    this.setLoading(true);
    return this.apiService
      .get(
        `showing-objects/${eaOid}/potential-buyers/search`,
        {
          ...params,
        },
        "api"
      )
      .pipe(
        switchMap((response: any) => {
          this.setLoading(false);
          const contacts = response?.potentialBuyers;
          this.upsertManyInCache(contacts);
          this.setListDefaults(contacts);
          return contacts;
        }),
        catchError((err) => {
          this.fetchErrorHandler(true, "potential_buyers_load_failed");
          return err;
        })
      );
  };

  refreshPotentialBuyersNew = (): Observable<any> | any => {};
  getPotentialBuyersNewWithQuery = (eaOid: string, params?: any) => {
    this.refreshPotentialBuyersNew = () =>
      this.getPotentialBuyersNewWithQuery(eaOid, params);
    this.setLoading(true);
    return this.apiService
      .get(`potential-buyers/object/${eaOid}/view/crm`, { ...params }, "api")
      .pipe(
        switchMap((response: any) => {
          this.setLoading(false);
          const contacts = response?.potentialBuyers;
          // Todo: This currently overrides the eaOids completely.
          const newContacts = contacts.map((c) => ({
            ...c,
            eaOids: [eaOid],
            profile: c.contactProfile,
          }));
          this.upsertManyInCache(newContacts);
          this.setListDefaults(newContacts);
          return this.getListFromEntities(newContacts);
        }),
        catchError((err) => {
          this.fetchErrorHandler(true, "potential_buyers_load_failed");
          return err;
        })
      );
  };

  // Contact Showing Attendance
  postContactShowingAttendance = (
    contactId: string,
    params: ContactShowingAttendance
  ) => {
    this.entityMap$
      .pipe(
        map((em) => em[contactId]),
        first(),
        filter((it) => !!it)
      )
      .subscribe((contact) => {
        const idParam = !!params?.eaShowingSlotId
          ? "eaShowingSlotId"
          : "eaShowingId";
        const updatedContactShowings = _.cloneDeep(contact.showings);
        const updatedShowing = updatedContactShowings.find(
          (showing) =>
            showing[idParam] === params[idParam] &&
            showing.eaShowingId === params.eaShowingId
        );
        if (!!updatedShowing) {
          updatedShowing.registrationStatus = params.registrationStatus;
        } else {
          updatedContactShowings.push({
            eaShowingId: params?.eaShowingId,
            [idParam]: params[idParam],
            registrationStatus: params.registrationStatus,
          });
        }
        const updatedContact = {
          ...contact,
          showings: updatedContactShowings,
        };
        this.upsertOneInCache(updatedContact);
      });
    return this.apiService
      .post(`contact-showing-attendances`, {
        contactId,
        registrationStatus: params.registrationStatus,
        showingId: params.showingId,
        eaShowingSlotId: params.eaShowingSlotId ? params.eaShowingSlotId : null,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({
              message: "contact_showing_attendances_create_failed",
            })
          );
          throw err;
        })
      );
  };

  removeContactShowingAttendance = (
    contactId: string,
    showingId: string,
    eaShowingId: string,
    eaShowingSlotId?: string
  ) => {
    this.entityMap$
      .pipe(
        map((em) => em[contactId]),
        first(),
        filter((it) => !!it)
      )
      .subscribe((contact) => {
        const updatedContactShowings = _.cloneDeep(contact.showings);
        const removedShowingIndex = updatedContactShowings.findIndex(
          (showing) => {
            if (
              showing.eaShowingId === eaShowingId &&
              (!!eaShowingSlotId
                ? showing?.eaShowingSlotId === eaShowingSlotId
                : true)
            ) {
              return true;
            } else {
              return false;
            }
          }
        );
        updatedContactShowings.splice(removedShowingIndex, 1);
        const updatedContact = {
          ...contact,
          showings: updatedContactShowings,
        };
        this.upsertOneInCache(updatedContact);
      });
    const path = !!eaShowingSlotId
      ? `showings/${showingId}/consumer-attendance/${contactId}/slot/${eaShowingSlotId}`
      : `showings/${showingId}/consumer-attendance/${contactId}`;
    return this.apiService.delete(path, {}, "api").pipe(
      map((response) => response),
      catchError((err) => {
        this.appStore.dispatch(
          toastActions.danger({
            message: "contact_showing_attendances_remove_failed",
          })
        );
        return err;
      })
    );
  };

  getFromCallingListId = (callingListId: string, additionalParams?: any) => {
    this.setLoading(true);
    return this.apiService
      .get(
        `calling-lists/${callingListId}/calling-list-contacts/search`,
        { ...additionalParams },
        "api"
      )
      .pipe(
        map((response: any) => {
          const contacts = response.callingListContacts;
          this.upsertManyInCache(contacts);
          this.setListDefaults(contacts);
          this.setLoading(false);
          return contacts;
        }),
        catchError((err) => {
          this.fetchErrorHandler();
          return err;
        })
      );
  };

  removeContactFromCallingList(callingListId: string, contactId: string) {
    return this.apiService
      .delete(
        `calling-lists/${callingListId}/calling-list-contacts/${contactId}`,
        {}
      )
      .pipe(
        map(() => {
          this.removeOneFromCache(contactId);
          this.appStore.dispatch(
            toastActions.success({
              message: "remove_calling_list_contact_success",
            })
          );
          return true;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({
              message: "remove_contact_from_calling_list_failed",
            })
          );
          return err;
        })
      );
  }

  deleteSalesCall(contactId: string, actionId: string) {
    return this.apiService
      .delete(`contacts/${contactId}/action/${actionId}`, {})
      .pipe(
        map(() => {
          this.removeOneFromCache(contactId);
          this.appStore.dispatch(
            toastActions.success({ message: "sales_call_deleted" })
          );
          return true;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({
              message: "sales_call_delete_failed",
            })
          );
          return err;
        })
      );
  }

  // Matching
  fetchMatchingContactsFromEaOid = (
    eaOid: string,
    params?: any,
    setListDefaults = true
  ) => {
    this.setLoading(true);
    return this.apiService
      .get(`objects/${eaOid}/matching-contacts/search`, params, "api")
      .pipe(
        switchMap((response: any) => {
          this.setLoading(false);
          const contacts = response?.matchingContacts;
          this.upsertManyInCache(contacts);
          if (setListDefaults) {
            this.setListDefaults(contacts, response);
          }
          return this.getListFromEntities(contacts);
        }),
        catchError((err) => {
          this.fetchErrorHandler(setListDefaults);
          return err;
        })
      );
  };

  clearData(setListDefaults: boolean = true) {
    this.setLoading(false);
    this.upsertManyInCache([]);
    if (setListDefaults) {
      this.setListDefaults([], null);
    }
  }

  private formatSearchParams(params: {
    filters: Partial<SegmentationFilters>;
    sortBy: string;
    sortOrder: string;
    limit: number;
  }) {
    if (params.filters?.connectionFilters?.connectionType === "inFocusArea") {
      params.filters.saleFocusAreasFilters = {
        connectedToOffice: null,
        connectedToEmployee: null,
        connectedToEmployeesOffice: null,
      };

      const officeIds =
        params.filters?.connectionFilters?.connectedToOffice.slice();

      if (params.filters?.connectionFilters?.connectedToEmployee?.length > 0) {
        params.filters.saleFocusAreasFilters.connectedToEmployee = [
          ...params.filters.connectionFilters.connectedToEmployee,
        ];

        params.filters.saleFocusAreasFilters.connectedToEmployeesOffice =
          officeIds;
      } else {
        params.filters.saleFocusAreasFilters.connectedToOffice = officeIds;
      }

      delete params.filters.connectionFilters;
    }

    delete params.filters?.connectionFilters?.connectionType;
    return params;
  }

  private fetchErrorHandler(
    setListDefaults: boolean = true,
    message: string = "contacts_load_failed"
  ) {
    this.clearData(setListDefaults);
    this.appStore.dispatch(toastActions.danger({ message: message }));
  }
}
