import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";

@Component({
  selector: "kpi-bank-tips",
  templateUrl: "./kpi-bank-tips.component.html",
  styleUrls: ["./kpi-bank-tips.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiBankTipsComponent {
  @Input() resources$: Observable<any>;

  fixDate(insertedDate: string) {
    return (
      moment(insertedDate, "YYYYMMDDhhmmss").format("L").toUpperCase() +
      " " +
      moment(insertedDate, "YYYYMMDDhhmmss").format("LT").toUpperCase()
    );
  }
}
