<form #form="ngForm">
  <div class="forgot-password">
    <div class="forgot-password__header">
      <div class="alert alert-info">
        {{ "forgot_password_msg3" | translate }}
      </div>
    </div>

    <div class="form-group">
      <div class="input-iconfield">
        <input
          [(ngModel)]="emailCode"
          name="emailCode"
          type="text"
          placeholder="{{ 'security_code' | translate }}"
        />
        <i class="icon icon-lg fa-light fa-shield-keyhole"></i>
      </div>
    </div>
  </div>

  <div class="forgot-password__footer">
    <app-q-button
      buttonType="cancel"
      label="cancel"
      classes="q-btn--cancel-border"
      routerLink="../"
    >
    </app-q-button>
    <button type="submit" class="q-btn q-btn--save" (click)="verify()">
      <app-icon name="shield-check"></app-icon>
      {{ "verify" | translate }}
    </button>
  </div>
</form>
