<div class="parties-container" *ngIf="property$ | async as property">
  <ng-container *ngIf="!(rental$ | async); else rental">
    <ng-container *ngIf="!property.isExistingHome; else owner">
      <h5>{{ "sellers" | translate }}</h5>
      <ng-container *ngIf="(sellers$ | async)?.length > 0; else noItems">
        <div class="seller-container">
          <div class="card-container" *ngFor="let seller of sellers$ | async">
            <app-object-party-item
              [disableActions]="disableActions"
              [selectedContactIds]="selectedContactIds"
              [contact]="seller"
              (handleContactClick)="
                handleContactClick($event.contact, $event.event)
              "
              (handleCheckboxChanged)="handleCheckboxChanged($event)"
              (openInNewTab)="openInNewTab($event.event, $event.contactId)"
              (sendMessageClick)="sendMessageClick($event)"
            ></app-object-party-item>
          </div>
        </div>
      </ng-container>
      <h5>{{ "buyer" | translate }}</h5>
      <ng-container *ngIf="(buyers$ | async)?.length > 0; else noItems">
        <div class="seller-container">
          <div class="card-container" *ngFor="let buyer of buyers$ | async">
            <app-object-party-item
              [disableActions]="disableActions"
              [selectedContactIds]="selectedContactIds"
              [contact]="buyer"
              (handleContactClick)="
                handleContactClick($event.contact, $event.event)
              "
              (handleCheckboxChanged)="handleCheckboxChanged($event)"
              (openInNewTab)="openInNewTab($event.event, $event.contactId)"
              (sendMessageClick)="sendMessageClick($event)"
            ></app-object-party-item>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #rental>
    <ng-container *ngTemplateOutlet="owner"></ng-container>

    <h5>{{ "tenants" | translate }}</h5>
    <ng-container *ngIf="(tenants$ | async)?.length > 0; else noItems">
      <div class="seller-container">
        <div class="card-container" *ngFor="let tenant of tenants$ | async">
          <app-object-party-item
            [disableActions]="disableActions"
            [selectedContactIds]="selectedContactIds"
            [contact]="tenant"
            (handleContactClick)="
              handleContactClick($event.contact, $event.event)
            "
            (handleCheckboxChanged)="handleCheckboxChanged($event)"
            (openInNewTab)="openInNewTab($event.event, $event.contactId)"
            (sendMessageClick)="sendMessageClick($event)"
          ></app-object-party-item>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #owner>
    <h5>{{ "owners" | translate }}</h5>
    <ng-container *ngIf="(owners$ | async)?.length > 0; else noItems">
      <div class="seller-container">
        <div class="card-container" *ngFor="let owner of owners$ | async">
          <app-object-party-item
            [disableActions]="disableActions"
            [selectedContactIds]="selectedContactIds"
            [contact]="owner"
            (handleContactClick)="
              handleContactClick($event.contact, $event.event)
            "
            (handleCheckboxChanged)="handleCheckboxChanged($event)"
            (openInNewTab)="openInNewTab($event.event, $event.contactId)"
            (sendMessageClick)="sendMessageClick($event)"
          ></app-object-party-item>
        </div>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #noItems>
    <p class="muted">
      {{ "missing_information" | translate }}
    </p>
  </ng-template>
  <div class="actions" *ngIf="!disableActions">
    <div>
      <app-q-circle-btn
        *ngIf="selectedContactIds?.length > 0"
        (click)="openSendMessageSidebar()"
        [icon]="'envelope'"
        [tooltipText]="'send_message'"
      ></app-q-circle-btn>
    </div>
  </div>
</div>
