export class ExchangeAccountDTO {
  readonly eaEmployeeId: string;
  readonly email: string;
  readonly username: string;
  readonly password: string;

  constructor(
    eaEmployeeId: string,
    email: string,
    username: string,
    password: string
  ) {
    this.eaEmployeeId = eaEmployeeId;
    this.email = email;
    this.username = username;
    this.password = password;
  }
}
