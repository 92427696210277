import { Component, Input } from "@angular/core";

type ButtonType =
  | "save"
  | "remove"
  | "cancel"
  | "preview"
  | "success"
  | "ignore";

@Component({
  selector: "app-q-button",
  templateUrl: "./q-button.component.html",
  styleUrls: ["./q-button.component.scss"],
})
export class QButtonComponent {
  @Input() icon: string;
  @Input() afterIcon: string;
  @Input() label: string;
  @Input() classes: string;
  @Input() tooltipText: string;
  @Input() buttonType: ButtonType = "save";
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() fullWidth: boolean = false;

  @Input() enableHoverMenu = false;
  timedOutCloser;

  mouseEnter(trigger) {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  mouseLeave(trigger) {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }
}
