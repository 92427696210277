import {
  BudgetStatisticsParams,
  GetBudgetVersionsParams,
  StoreBudgetVersionsParams,
} from "@app/statistics/statistics-budget/models/budget-statistics-params";
import {
  BudgetStatisticsResponse,
  BudgetVersion,
} from "@app/statistics/statistics-budget/models/budget-statistics-response";
import { createAction, props } from "@ngrx/store";

export const getBudgetStatisticsRequest = createAction(
  "[Statistics] GET_BUDGET_STATISTICS_REQUEST",
  props<{ params: BudgetStatisticsParams }>()
);

export const getBudgetStatisticsSuccess = createAction(
  "[Statistics] GET_BUDGET_STATISTICS_SUCCESS",
  props<{ budgetStatistics: BudgetStatisticsResponse }>()
);

export const getBudgetStatisticsFailure = createAction(
  "[Statistics] GET_BUDGET_STATISTICS_FAILURE"
);

export const getBudgetVersionsRequest = createAction(
  "[Statistics] GET_BUDGET_VERSIONS_REQUEST",
  props<{ params: GetBudgetVersionsParams }>()
);

export const getBudgetVersionsSuccess = createAction(
  "[Statistics] GET_BUDGET_VERSIONS_SUCCESS",
  props<{ versions: BudgetVersion[] }>()
);

export const getBudgetVersionsFailure = createAction(
  "[Statistics] GET_BUDGET_VERSIONS_FAILURE"
);

export const storeBudgetVersionsRequest = createAction(
  "[Statistics] STORE_BUDGET_VERSIONS_REQUEST",
  props<{ params: StoreBudgetVersionsParams }>()
);

export const storeBudgetVersionsSuccess = createAction(
  "[statistics] store_budget_versions_success",
  props<{ versionId: string }>()
);

export const storeBudgetVersionsFailure = createAction(
  "[Statistics] STORE_BUDGET_VERSIONS_FAILURE"
);

export const updateBudgetVersionsRequest = createAction(
  "[statistics] update_budget_versions_request",
  props<{ params: StoreBudgetVersionsParams }>()
);

export const updateBudgetVersionsSuccess = createAction(
  "[Statistics] UPDATE_BUDGET_VERSIONS_SUCCESS",
  props<{ versionId: string }>()
);

export const updateBudgetVersionsFailure = createAction(
  "[Statistics] UPDATE_BUDGET_VERSIONS_FAILURE"
);

export const deleteBudgetVersionsRequest = createAction(
  "[Statistics] DELETE_BUDGET_VERSIONS_REQUEST",
  props<{ versionId: string }>()
);

export const deleteBudgetVersionsSuccess = createAction(
  "[Statistics] DELETE_BUDGET_VERSIONS_SUCCESS"
);

export const deleteBudgetVersionsFailure = createAction(
  "[Statistics] DELETE_BUDGET_VERSIONS_FAILURE"
);

export const getBudgetCSVRequest = createAction(
  "[Statistics] GET_BUDGET_CSV_REQUEST",
  props<{ params: BudgetStatisticsParams }>()
);

export const getBudgetCSVSuccess = createAction(
  "[Statistics] GET_BUDGET_CSV_SUCCESS",
  props<{ csv: string }>()
);

export const getBudgetCSVFailure = createAction(
  "[Statistics] GET_BUDGET_CSV_FAILURE"
);
