import { TaskListConfig } from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import moment from "moment";
import * as threeDayTaskListActions from "./three-day-task-list.actions";
import { DashboardModuleState } from "@app/dashboard/dashboard-module.reducer";

export interface ThreeDayTaskListState {
  taskLists: any[];
  latestFetchAction: Action;
  isLoading: boolean;
  loaded: boolean;
  taskListsConfig: TaskListConfig[];
}

export const initialState: ThreeDayTaskListState = {
  taskLists: [[], [], []],
  latestFetchAction: null,
  isLoading: false,
  loaded: false,
  taskListsConfig: [
    {
      expanded: true,
      limit: 10,
    },
    {
      expanded: true,
      limit: 10,
    },
    {
      expanded: true,
      limit: 10,
    },
  ],
};

export function threeDayTaskListReducer(
  state: ThreeDayTaskListState,
  action: Action
): ThreeDayTaskListState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(threeDayTaskListActions.getCurrentWeekRequest, (state, action) => ({
    ...state,
    latestFetchAction: action,
    isLoading: true,
  })),
  on(threeDayTaskListActions.getCurrentWeekSuccess, (state, { tasks }) => ({
    ...state,
    taskLists: groupTasksByDate(tasks),
    isLoading: false,
    loaded: true,
  })),
  on(
    threeDayTaskListActions.setListConfig,
    (state, { taskListConfig, index }) => ({
      ...state,
      taskListsConfig: groupTaskListsConfig(
        state.taskListsConfig,
        taskListConfig,
        index
      ),
    })
  )
);

export function groupTasksByDate(tasks: Task[]) {
  const currentWeek = [];

  currentWeek[0] = tasks.filter((task) => {
    return task.eaTaskTypeCategory === "lead"
      ? moment(task.deliveryDate).isAfter(
          moment().subtract(6, "days").endOf("day"),
          "day"
        )
      : moment(task.deliveryDate).isBetween(
          moment().subtract(6, "days"),
          moment().add(1, "day"),
          "day"
        );
  });

  for (let i = 1; i < 3; i++) {
    currentWeek[i] = tasks
      .filter((task: Task) => task.eaTaskTypeCategory === "task")
      .filter((task: Task) =>
        moment(task.deliveryDate).isSame(moment().add(i, "days"), "day")
      );
  }

  return currentWeek;
}

export function groupTaskListsConfig(
  currentConfig: TaskListConfig[],
  taskListConfig: TaskListConfig,
  index: number
) {
  const newConfig = [...currentConfig];
  newConfig[index] = taskListConfig;
  return newConfig;
}

const selectFeature = createFeatureSelector<DashboardModuleState>("dashboard");
const getState = createSelector(
  selectFeature,
  (state: DashboardModuleState) => state.threeDayTaskList
);

export const getLatestThreeDayTaskListFetchAction = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.latestFetchAction
);

export const getTaskListsConfig = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.taskListsConfig
);

export const getThreeDayTasksLoaded = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.loaded
);

export const getThreeDayTasksLoading = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.isLoading
);

export const getTodayTaskList = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.taskLists[0]
);

export const getTomorrowTaskList = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.taskLists[1]
);

export const getDayAfterTomorrowTaskList = createSelector(
  getState,
  (state: ThreeDayTaskListState) => state.taskLists[2]
);
