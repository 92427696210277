import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Contact } from "@app/models";
import { BehaviorSubject, debounceTime, map, Observable } from "rxjs";

@Component({
  selector: "app-preview-calling-list-dialog",
  templateUrl: "./preview-calling-list-dialog.component.html",
  styleUrls: ["./preview-calling-list-dialog.component.scss"],
})
export class PreviewCallingListDialogComponent implements OnInit {
  columns = [
    "firstName",
    "familyName",
    "msisdn",
    "email",
    "street",
    "zip",
    "city",
  ];
  headers = {
    firstName: "first_name",
    familyName: "family_name",
  };

  paginatedContacts$: Observable<Contact[]>;
  paginator$: BehaviorSubject<Partial<PageEvent>> = new BehaviorSubject<
    Partial<PageEvent>
  >({
    pageSize: 10,
    pageIndex: 0,
  });

  constructor(
    public dialogRef: MatDialogRef<PreviewCallingListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.paginatedContacts$ = this.paginator$.pipe(debounceTime(400)).pipe(
      map((paginator) => {
        const page = paginator.pageIndex;
        const size = paginator.pageSize;
        const newData = [...this.data.contacts];
        newData.splice((page + 1) * size, newData.length - size);
        return newData;
      })
    );
  }

  onPage($event: PageEvent) {
    this.paginator$.next({
      pageSize: $event.pageSize,
      pageIndex: $event.pageIndex,
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
