import { Office } from "./";

export class Employee {
  eaEmployeeId: string;
  employeeId: string;
  employeeFullName: string;
  employeeEmail: string;
  employeePhone: string;
  phone: string;
  phoneCountryCode: string;
  employeePhoto: string;
  photo: string;
  admin: boolean;
  countryCode: string;
  employeeMobile: string;
  mobile: string;
  officeNames: string;
  numberOfOffices: number;
  offices: Office[];
  mobileCountryCode: string;
  mobileNumber: string;
  employeeTitle: string;
  title?: string;
  status: string;
  email?: string;
  fullName?: string;
  firstName?: string;
  familyName?: string;
  exclusions?: {
    description: string;
    exclusionAliasName: string;
    exclusionId: string;
    exclusionType: string;
    insertedDate: string;
    name: string;
    updatedDate: string;
  }[];

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getFirstName(): string {
    return this.employeeFullName ? this.employeeFullName.split(" ")[0] : "";
  }

  getInitials(): string {
    if (this.employeeFullName) {
      const initials = this.employeeFullName.match(/\b\w/g) || [];
      return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
    }
    return "";
  }
}
