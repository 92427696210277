<app-q-modal>
  <ng-container slot="header">
    <div class="header-container">
      <div>
        <span style="margin-right: 2rem">
          {{ "html_generator" | translate }}&nbsp;
          <app-icon
            *ngIf="
              productNewsService.productNews[currentProductNewsItem].visited
            "
            name="check"
          >
          </app-icon> </span
        >&nbsp;
        <span *ngIf="productNewsService.productNews.length > 0">
          {{ currentProductNewsItem + 1 }} /
          {{ productNewsService.productNews.length }}
        </span>
      </div>
      <div>
        <app-q-button
          *ngIf="hasUnseenItem"
          buttonType="cancel"
          label="mark_all_as_read"
          classes="margin-right"
          (click)="markAllAsRead()"
        >
        </app-q-button>
      </div>
    </div>
  </ng-container>
  <ng-container slot="body">
    <div
      class="news-content"
      [innerHTML]="
        productNewsService.productNews[currentProductNewsItem].content
      "
    ></div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <button
        *ngIf="
          currentProductNewsItem > 0 &&
          productNewsService.productNews.length > 0 &&
          currentProductNewsItem <= productNewsService.productNews.length - 1
        "
        type="button"
        class="btn btn-secondary btn-lg pull-left"
        (click)="previousProductNewsItem()"
      >
        <app-icon name="arrow-left"></app-icon>
        {{ "previous" | translate }}
      </button>
      <button
        *ngIf="
          currentProductNewsItem >= 0 &&
          productNewsService.productNews.length > 0 &&
          currentProductNewsItem < productNewsService.productNews.length - 1
        "
        type="button"
        class="btn btn-secondary btn-lg pull-right"
        (click)="nextProductNewsItem()"
      >
        {{ "next" | translate }}
        <app-icon name="arrow-right"></app-icon>
      </button>
    </div>
  </ng-container>
</app-q-modal>
