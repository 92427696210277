import { calculateGrowth, calculatePercent } from "@app/shared/utils/math";

export class KpiDetails {
  id: string;
  currentData: number;
  lastYearData: number;
  salesTarget: number;
  name: string;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getGrowth(): number {
    return calculateGrowth(this.currentData, this.lastYearData);
  }

  getSalesTargetPercent(): number {
    return this.salesTarget
      ? calculatePercent(this.currentData, this.salesTarget)
      : null;
  }
}
