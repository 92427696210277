import { ToastAction, ToastLink } from "./toast.reducer";
import { Action, createAction, props } from "@ngrx/store";

export const DURATION_SHORT = 4000;
export const DURATION_LONG = 8000;

export const show = createAction(
  "[Toast] SHOW",
  props<{
    data: {
      message: string;
      duration: number;
      type: string;
    };
    toastAction?: ToastAction;
    toastLink?: ToastLink;
  }>()
);

export const success = (
  { message = "", duration = DURATION_SHORT } = {},
  toastAction: ToastAction = null,
  toastLink: ToastLink = null
): Action => {
  return show({
    data: { message, duration, type: "success" },
    toastAction,
    toastLink,
  });
};

export const danger = (
  { message = "", duration = DURATION_LONG } = {},
  toastAction: ToastAction = null,
  toastLink: ToastLink = null
): Action => {
  return show({
    data: { message, duration, type: "danger" },
    toastAction,
    toastLink,
  });
};

export const warning = (
  { message = "", duration = DURATION_SHORT } = {},
  toastAction: ToastAction = null,
  toastLink: ToastLink = null
): Action => {
  return show({
    data: { message, duration, type: "warning" },
    toastAction,
    toastLink,
  });
};

export const info = (
  { message = "", duration = DURATION_SHORT } = {},
  toastAction: ToastAction = null,
  toastLink: ToastLink = null
): Action => {
  return show({
    data: { message, duration, type: "info" },
    toastAction,
    toastLink,
  });
};

export const generalError = (): Action => {
  return show({
    data: {
      message: "general_error",
      duration: DURATION_LONG,
      type: "danger",
    },
  });
};
