import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { Contact, EmployeeDTO } from "@app/models";

import * as createFollowUpActions from "./create-follow-up.actions";
import { SidebarModuleState } from "@app/sidebar/sidebar-module.reducer";

export interface CreateFollowUpState {
  selectedContact: Contact;
  selectedContactLoading: boolean;
  employees: EmployeeDTO[];
  employeesLoading: boolean;
  processing: boolean;
  initialized: boolean;
}

export const initialState: CreateFollowUpState = {
  selectedContact: null,
  selectedContactLoading: false,
  employees: [],
  employeesLoading: false,
  processing: false,
  initialized: false,
};

export function createFollowUpReducer(
  state: CreateFollowUpState,
  action: Action
): CreateFollowUpState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(createFollowUpActions.createFollowUpRequest, (state) => ({
    ...state,
    processing: true,
  })),
  on(
    createFollowUpActions.createFollowUpFailed,
    createFollowUpActions.createFollowUpSuccess,
    (state) => ({ ...state, processing: false })
  ),
  on(createFollowUpActions.getContactRequest, (state) => ({
    ...state,
    selectedContactLoading: true,
  })),
  on(
    createFollowUpActions.getContactSuccess,
    createFollowUpActions.setContact,
    (state, { contact }) => ({
      ...state,
      selectedContact: contact,
      selectedContactLoading: false,
    })
  ),
  on(createFollowUpActions.getOfficeEmployeesRequest, (state) => ({
    ...state,
    employees: initialState.employees,
    employeesLoading: true,
    initialized: false,
  })),
  on(
    createFollowUpActions.getOfficeEmployeesSuccess,
    (state, { employees }) => ({
      ...state,
      employees,
      employeesLoading: false,
      initialized: true,
    })
  ),
  on(createFollowUpActions.getOfficeEmployeesFailed, (state) => ({
    ...state,
    employees: initialState.employees,
    employeesLoading: false,
    initialized: true,
  })),
  on(
    createFollowUpActions.reset,
    createFollowUpActions.getContactFailed,
    () => ({ ...initialState })
  )
);

const selectFeature = createFeatureSelector<SidebarModuleState>("sidebar");
const getState = createSelector(
  selectFeature,
  (state: SidebarModuleState) => state.createFollowUp
);

export const getSelectedContact = createSelector(
  getState,
  (state: CreateFollowUpState) => state.selectedContact
);

export const getSelectedContactLoading = createSelector(
  getState,
  (state: CreateFollowUpState) => state.selectedContactLoading
);

export const getEmployees = createSelector(
  getState,
  (state: CreateFollowUpState) => state.employees
);

export const getEmployeesLoading = createSelector(
  getState,
  (state: CreateFollowUpState) => state.employeesLoading
);

export const getProcessing = createSelector(
  getState,
  (state: CreateFollowUpState) => state.processing
);

export const isInitialized = createSelector(
  getState,
  (state: CreateFollowUpState) => state.initialized
);
