import { Employee, QObject } from "@app/models";
import { createAction, props } from "@ngrx/store";
import {
  ContactFilterResponse,
  SearchSegmentationContactResponse,
} from "../lists-contacts.model";
import { Segment } from "@app/campaigns/create-campaign/models/segment";

const defaultParams = {
  sortBy: "insertDate",
  sortOrder: "desc",
};

export const getContacts = (filters: Record<string, unknown>) => {
  const params = {
    ...defaultParams,
    ...filters,
  };
  return getContactsRequest({ params });
};

export const getContactsRequest = createAction(
  "[Lists Contacts] GET_CONTACTS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getContactsSuccess = createAction(
  "[Lists Contacts] GET_CONTACTS_SUCCESS",
  props<{ payload: SearchSegmentationContactResponse }>()
);

export const getContactsFailed = createAction(
  "[Lists Contacts] GET_CONTACTS_FAILED"
);

export const getMoreContacts = (filters: Record<string, unknown>) => {
  const params = {
    ...defaultParams,
    ...filters,
  };
  return getMoreContactsRequest({ params });
};

export const getMoreContactsRequest = createAction(
  "[Lists Contacts] GET_MORE_CONTACTS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getMoreContactsSuccess = createAction(
  "[Lists Contacts] GET_MORE_CONTACTS_SUCCESS",
  props<{ payload: SearchSegmentationContactResponse }>()
);

export const getMoreContactsFailed = createAction(
  "[Lists Contacts] GET_MORE_CONTACTS_FAILED"
);

export const getConnectedObjectRequest = createAction(
  "[Lists Contacts] GET_CONNECTED_OBJECTS_REQUEST",
  props<{ eaOids: string[] }>()
);

export const getConnectedObjectSuccess = createAction(
  "[Lists Contacts] GET_CONNECTED_OBJECTS_SUCCESS",
  props<{ objects: QObject[] }>()
);

export const getConnectedObjectFailed = createAction(
  "[Lists Contacts] GET_CONNECTED_OBJECTS_FAILED"
);

export const getContactsForCallingListRequest = createAction(
  "[Lists Contacts] GET_CONTACTS_FOR_CALLING_LIST_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getContactsForCallingListSuccess = createAction(
  "[Lists Contacts] GET_CONTACTS_FOR_CALLING_LIST_SUCCESS",
  props<{ payload: Record<string, unknown> }>()
);

export const getContactsForCallingListFailed = createAction(
  "[Lists Contacts] GET_CONTACTS_FOR_CALLING_LIST_FAILED"
);

export const reset = createAction("[Lists Contacts] RESET");

export const getContactsFilters = (params: Record<string, unknown>) => {
  const filters = {
    ...defaultParams,
    ...params,
  };
  return getContactsFiltersRequest({ filters });
};

export const getContactsFiltersRequest = createAction(
  "[Lists Contacts] GET_CONTACTS_FILTERS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getContactsFiltersSuccess = createAction(
  "[Lists Contacts] GET_CONTACTS_FILTERS_SUCCESS",
  props<{ payload: ContactFilterResponse }>()
);

export const getContactsFiltersFailed = createAction(
  "[Lists Contacts] GET_CONTACTS_FILTERS_FAILED"
);

export const getEmployeesRequest = createAction(
  "[Lists Contacts] GET_EMPLOYEES_REQUEST",
  props<{ payload?: string }>()
);

export const getEmployeesSuccess = createAction(
  "[Lists Contacts] GET_EMPLOYEES_SUCCESS",
  props<{ payload: Employee[] }>()
);

export const getEmployeesFailed = createAction(
  "[Lists Contacts] GET_EMPLOYEES_FAILED"
);

export const getTagEmployeesRequest = createAction(
  "[Lists Contacts] GET_TAG_EMPLOYEES_REQUEST",
  props<{ payload?: string }>()
);

export const getTagEmployeesSuccess = createAction(
  "[Lists Contacts] GET_TAG_EMPLOYEES_SUCCESS",
  props<{ payload: Employee[] }>()
);

export const getTagEmployeesFailed = createAction(
  "[Lists Contacts] GET_TAG_EMPLOYEES_FAILED"
);

export const getSegmentRequest = createAction(
  "[CreateCampaigns] GET_SEGMENTS_REQUEST",
  props<{ params: Record<string, unknown> }>()
);

export const getSegmentSuccess = createAction(
  "[CreateCampaigns] GET_SEGMENTS_SUCCESS",
  props<{ segments: Segment[] }>()
);

export const getSegmentFail = createAction(
  "[CreateCampaigns] GET_SEGMENTS_FAIL"
);

export const deleteSegmentRequest = createAction(
  "[CreateCampaigns] DELETE_SEGMENTS_REQUEST",
  props<{ segmentId: string }>()
);

export const deleteSegmentSuccess = createAction(
  "[CreateCampaigns] DELETE_SEGMENTS_SUCCESS"
);

export const deleteSegmentFail = createAction(
  "[CreateCampaigns] DELETE_SEGMENTS_FAIL"
);

export const setSelectedSegment = createAction(
  "[CreateCampaigns] SET_SELECTED_SEGMENT",
  props<{
    selectedSegmentId?: number;
    selectedSegmentName: string;
  }>()
);
