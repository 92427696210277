import { Route } from "@angular/router";
import { ConnectInExternalProviderComponent } from "@app/sidebar/external-provider/containers/connect-in-external-provider/connect-in-external-provider.component";
import { CreateInExternalProviderComponent } from "@app/sidebar/external-provider/containers/create-in-external-provider/create-in-external-provider.component";
import { ExternalProviderConnectGuard } from "@app/sidebar/guards/external-provider-connect-guard.service";
import { ExternalProviderGuard } from "@app/sidebar/guards/external-provider-guard.service";

export const sidebarExternalProviderRoutes: Route = {
  path: "external-provider",
  children: [
    {
      path: "",
      redirectTo: "new",
      pathMatch: "full",
    },
    {
      path: "new",
      component: CreateInExternalProviderComponent,
      canActivate: [ExternalProviderGuard],
    },
    {
      path: "connect",
      component: ConnectInExternalProviderComponent,
      canActivate: [ExternalProviderConnectGuard],
    },
  ],
};
