import { Component, Input } from "@angular/core";
import { Action } from "@app/models";

@Component({
  selector: "contact-action-call-registered-phone",
  template: `
    <ng-container>
      <h4>
        {{ action.timestamp }}
        <small>{{ "of" | translate }} {{ action.employeeFullName }}</small>
      </h4>
      <p>
        <b>{{ "status" | translate }}:</b> {{ action.param1 | translate }}
      </p>
    </ng-container>
  `,
  styleUrls: ["./common.scss"],
})
export class ContactActionCallRegisteredPhoneComponent {
  @Input() action: Action;
}
