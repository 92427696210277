import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ShowingAttendanceConfig } from "@app/core/services/feature-config-manager/models/showing-attendance-config";
import { Employee } from "@app/models";
import * as fromUser from "@app/shared/user";
import {
  PotentialBuyer,
  Showing,
  ShowingObject,
  ShowingSlot,
} from "@app/showings/models";
import {
  ContactShowingAttendance,
  ContactShowingAttendanceStatus,
  ShowingAttendances,
} from "@app/sidebar/potential-buyer/models/contact-showing-attendance";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { Observable } from "rxjs";

@Component({
  selector: "app-attendance-status",
  templateUrl: "./showing-attendance.component.html",
  styleUrls: ["./showing-attendance.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowingAttendanceComponent implements OnInit, OnChanges {
  @Input() showing: Showing;
  @Input() showDeleteButton = true;
  @Input() potentialBuyers: PotentialBuyer[];
  @Input() attendancesCount: ShowingAttendances;
  @Input() slotAttendancesCount: {
    eaShowingSlotId: string;
    attendancesCount: ShowingAttendances;
  }[];
  @Input() attendance: ContactShowingAttendance;
  @Input() slotAttendances: ContactShowingAttendance[];
  @Input() loading: boolean;
  @Input() config: ShowingAttendanceConfig;
  @Input() object: ShowingObject;

  @Output() statusChange = new EventEmitter<{
    status: string;
    eaShowingSlotId?: string;
  }>();
  @Output() deleteShowing = new EventEmitter<string>();
  employee$: Observable<Employee>;

  internalLoading = false;

  Status = ContactShowingAttendanceStatus;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.employee$ = this.store.pipe(select(fromUser.getEmployee));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.loading && !this.loading) {
      this.internalLoading = false;
    }
  }

  mayDeleteShowing(employee: Employee) {
    if (!this.object || !this.object.employees) {
      return false;
    }

    return (
      !!this.object.employees.find(
        (e: Employee) => e.eaEmployeeId === employee.eaEmployeeId
      ) || this.showing.showingAgentEaEmployeeId === employee.eaEmployeeId
    );
  }

  handleRemovePrivateShowing(showingId) {
    this.deleteShowing.emit(showingId);
  }

  handleClick(status: string): void {
    if (this.loading === false || this.loading === true) {
      this.internalLoading = true;
    }
    const isSelected = this.isSelected(status);
    const newStatus = isSelected
      ? ContactShowingAttendanceStatus.NotRegistered
      : status;
    this.statusChange.emit({ status: newStatus });
  }

  isSelected(status: string): boolean {
    if (this.attendancesCount) {
      if (
        status === this.Status.Registered &&
        this.attendancesCount.registered.length > 0
      ) {
        return (
          this.attendancesCount.attended.length === 0 &&
          this.attendancesCount.notAttended.length === 0
        );
      } else if (
        status === this.Status.Attended &&
        this.attendancesCount.attended.length > 0
      ) {
        return (
          this.attendancesCount.registered.length === 0 &&
          this.attendancesCount.notAttended.length === 0
        );
      } else if (
        status === this.Status.NotAttended &&
        this.attendancesCount.notAttended.length > 0
      ) {
        return (
          this.attendancesCount.registered.length === 0 &&
          this.attendancesCount.attended.length === 0
        );
      } else {
        return false;
      }
    }

    if (!this.attendance) {
      return;
    }
    return this.attendance.registrationStatus === status;
  }

  isExpandable() {
    return (
      !this.isSelected(this.Status.Registered) &&
      !this.isSelected(this.Status.Attended) &&
      !this.isSelected(this.Status.NotAttended)
    );
  }

  isSlotSelected(
    status: ContactShowingAttendanceStatus,
    eaShowingSlotId: string
  ) {
    if (this.slotAttendancesCount) {
      const currentSlotAttendanceCount = this.slotAttendancesCount.find(
        (item) => item.eaShowingSlotId === eaShowingSlotId
      );
      if (!!currentSlotAttendanceCount) {
        if (
          status === this.Status.Registered &&
          currentSlotAttendanceCount.attendancesCount.registered.length > 0
        ) {
          return (
            currentSlotAttendanceCount.attendancesCount.attended.length === 0 &&
            currentSlotAttendanceCount.attendancesCount.notAttended.length === 0
          );
        } else if (
          status === this.Status.Attended &&
          currentSlotAttendanceCount.attendancesCount.attended.length > 0
        ) {
          return (
            currentSlotAttendanceCount.attendancesCount.registered.length ===
              0 &&
            currentSlotAttendanceCount.attendancesCount.notAttended.length === 0
          );
        } else if (
          status === this.Status.NotAttended &&
          currentSlotAttendanceCount.attendancesCount.notAttended.length > 0
        ) {
          return (
            currentSlotAttendanceCount.attendancesCount.registered.length ===
              0 &&
            currentSlotAttendanceCount.attendancesCount.attended.length === 0
          );
        } else {
          return false;
        }
      }
    }

    const attendance = this.slotAttendances.find(
      (attendance) => attendance.eaShowingSlotId === eaShowingSlotId
    );
    if (!attendance) {
      return;
    }
    return attendance.registrationStatus === status;
  }

  handleSlotClick(
    status: ContactShowingAttendanceStatus,
    eaShowingSlotId: string
  ) {
    this.internalLoading = true;
    const isSelected = this.isSlotSelected(status, eaShowingSlotId);
    const newStatus = isSelected
      ? ContactShowingAttendanceStatus.NotRegistered
      : status;
    this.statusChange.emit({
      status: newStatus,
      eaShowingSlotId: eaShowingSlotId,
    });
  }

  formatDateToTime(from: string) {
    return moment(from, "YYYYMMDDHHmmss").format("HH:mm");
  }

  getSelectedSlot(slots: ShowingSlot[]) {
    return slots.find((slot) => {
      const attendance = this.slotAttendances.find(
        (attendance) => attendance.eaShowingSlotId === slot.eaShowingSlotId
      );
      if (
        !attendance ||
        (attendance.registrationStatus !== this.Status.Attended &&
          attendance.registrationStatus !== this.Status.NotAttended &&
          attendance.registrationStatus !== this.Status.Registered)
      ) {
        return false;
      } else {
        return true;
      }
    });
  }

  formatDate(from: string) {
    return moment(from, "YYYYMMDDHHmmss").toDate();
  }

  getSelectedSlotStatus(slots: ShowingSlot[]) {
    return this.slotAttendances.find((attendance) => {
      const selectedSlot = slots.find(
        (slot) => slot.eaShowingSlotId === attendance.eaShowingSlotId
      );
      if (!selectedSlot) {
        return false;
      } else {
        return true;
      }
    });
  }

  getAttendanceCountForSlot(eaShowingSlotId: string) {
    return this.slotAttendancesCount.find(
      (item) => item.eaShowingSlotId === eaShowingSlotId
    )?.attendancesCount;
  }

  sortShowingSlots(slots: ShowingSlot[]) {
    const slots2 = slots.slice().sort((a, b) => {
      if (
        moment(a.from, "YYYYMMDDHHmmss").isAfter(
          moment(b.from, "YYYYMMDDHHmmss")
        )
      ) {
        return 1;
      } else if (
        moment(a.from, "YYYYMMDDHHmmss").isBefore(
          moment(b.from, "YYYYMMDDHHmmss")
        )
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    return slots2;
  }

  isButtonDisabled(status, slot: ShowingSlot): boolean {
    return (
      (!this.isSlotSelected(this.Status.Registered, slot.eaShowingSlotId) &&
        !this.isSlotSelected(this.Status.Attended, slot.eaShowingSlotId) &&
        !this.isSlotSelected(this.Status.NotAttended, slot.eaShowingSlotId) &&
        slot.slotCapacity === 0) ||
      (!!this.slotAttendancesCount &&
        !this.isSlotSelected(status, slot.eaShowingSlotId) &&
        this.potentialBuyers.length > slot.slotCapacity)
    );
  }
}
