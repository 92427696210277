import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-message-templates-dynamic-contents",
  templateUrl: "./message-templates-dynamic-contents.component.html",
  styleUrls: ["./message-templates-dynamic-contents.component.scss"],
})
export class MessageTemplatesDynamicContentsComponent
  implements OnInit, OnChanges
{
  @ViewChild("richTextEditor") richTextEditor: any;

  @Input() parentForm: FormGroup;
  @Input() action: "create" | "edit" | "send-message" = "send-message";
  @Input() dynamicContents;
  @Input() isEmail = true;
  @Input() hasColor = false;

  get dynamicContentsFormArray(): FormArray {
    return this.parentForm.get("dynamicContents") as FormArray;
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    if (!this.dynamicContents) {
      this.dynamicContents = [
        { name: "subject", value: "" },
        { name: "message", value: "" },
      ];
      this.setDynamicContentControls();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dynamicContents && !!this.dynamicContents) {
      this.setDynamicContentControls();
    }
    if (changes.isEmail && this.action === "create") {
      if (this.isEmail) {
        this.dynamicContents = [
          { name: "subject", value: "" },
          { name: "message", value: "" },
        ];
      } else {
        this.dynamicContents = [{ name: "message", value: "" }];
      }
      this.setDynamicContentControls();
    }
  }

  isSubject(input: string) {
    return input.toLowerCase().includes("subject");
  }

  hasSubject(dynamicContents: any[]) {
    return dynamicContents.some((dc) => this.isSubject(dc.name));
  }

  setDynamicContentControls(): void {
    const controls = this.dynamicContents.map((dc) => {
      const ctrl = this.fb.control(dc.value, [
        Validators.required,
        !this.hasSubject(this.dynamicContents)
          ? Validators.maxLength(910)
          : Validators.nullValidator,
      ]);

      if (this.action === "edit" && !dc?.isEditableByCurrentUser) {
        ctrl.disable({ emitEvent: false });
      }
      return ctrl;
    });
    this.parentForm.setControl("dynamicContents", this.fb.array(controls));
  }

  isMessage(input: string): boolean {
    return (
      input.toLowerCase().includes("content") ||
      input.toLowerCase().includes("message")
    );
  }
}
