<article
  *ngIf="contact.getFullName()"
  class="search-card search-card-details"
  (click)="navigateToCustomerCard(contact.contactId)"
>
  <section
    class="search-card-details__header search-card-details__header__contact"
  >
    <figure
      class="search-card-details__header__circle search-card-details__header__circle--contacts"
    >
      <i class="icon-user"></i>
    </figure>
  </section>

  <section class="search-card-details__person-contact-info ellipsify">
    <h4 class="name">
      <app-contact-name
        [contactType]="contact.contactType"
        [contactId]="contact.contactId"
      >
        {{ contact.getFullName() }}
      </app-contact-name>
    </h4>
    <p *ngIf="contact.getAddress()">
      <i class="icon-location-pin"></i>{{ contact.getAddress() }}
    </p>
    <a *ngIf="!contact.getAddress()">
      <i class="icon-location-pin"></i
      ><span class="muted" translate> - - -</span>
    </a>

    <a *ngIf="contact.msisdn" href="tel:+{{ msisdn }}">
      <i class="icon-phone"></i>{{ contact.msisdn }}
    </a>
    <p *ngIf="!contact.msisdn">
      <i class="icon-phone"></i><span class="muted" translate> - - -</span>
    </p>

    <a *ngIf="contact.email" href="mailto:{{ contact.email }}" target="_top">
      <span tooltip="{{ 'open_mail_client' | translate }}" placement="bottom"
        ><i class="icon-envelope"></i>{{ contact.email }}</span
      >
    </a>
    <p *ngIf="!contact.email">
      <i class="icon-envelope"></i><span class="muted" translate> - - -</span>
    </p>

    <a *ngIf="contact.phoneNumber" href="tel:+{{ phoneNumber }}">
      <i class="icon-phone"></i>{{ contact.phoneNumber }} (<span translate
        >phone</span
      >)
    </a>
    <p *ngIf="!contact.phoneNumber">
      <i class="icon-phone"></i><span class="muted" translate> - - -</span>
    </p>
  </section>
  <app-actions-menu
    *ngIf="!(isMobile$ | async)"
    [dropdownAlignment]="'RIGHT'"
    style="display: flex; align-items: center; margin-right: 0.25rem"
  >
    <ng-container buttons>
      <li role="menuitem" *ngIf="showCreatePrivateShowing$ | async">
        <app-q-button
          [label]="'create_showing'"
          [icon]="'users'"
          [fullWidth]="true"
          (click)="createShowing($event, contact.contactId)"
        ></app-q-button>
      </li>
      <li role="menuitem">
        <app-q-button
          [label]="'open_in_new_tab'"
          [icon]="'share-square'"
          [fullWidth]="true"
          (click)="openInNewTab($event, contact.contactId)"
        ></app-q-button>
      </li>
    </ng-container>
  </app-actions-menu>
  <div
    *ngIf="doesContactBelongToEmployee(contact, eaEmployeeId)"
    class="marker"
    placement="left"
    tooltip="{{ 'my_contact' | translate }}"
  >
    <i class="icon-user"></i>
  </div>
</article>
