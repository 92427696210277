import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { Office, OfficeEmail, PaginationListDTO } from "@app/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { Store } from "@ngrx/store";
import moment from "moment";
import { catchError, first, map, switchMap } from "rxjs";

@Injectable({ providedIn: "root" })
export class OfficeService extends CustomEntityCollectionServiceBase<Office> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService,
    private appStore: Store<AppState>
  ) {
    super("Office", serviceElementsFactory);
  }

  // @ts-ignore
  getWithQuery = (params) => {
    this.setLoading(true);
    return this.apiService.get(`offices/search`, { ...params }, "api").pipe(
      map((response: PaginationListDTO) => {
        const offices = response.rows;
        this.upsertManyInCache(offices);
        this.setLoading(false);
        this.setListDefaults(offices, response);
        return offices;
      }),
      catchError((err) => {
        return err;
      })
    );
  };

  getById = (id: string, additionalParams?: any) => {
    this.setLoading(true);
    return this.apiService
      .get(`offices/${id}`, { ...additionalParams }, "api")
      .pipe(
        switchMap((response: any) => {
          const office = response;
          this.upsertOneInCache(office);
          this.setLoading(false);
          return this.getListFromEntities([office]).pipe(
            map((list) => list[0])
          );
        }),
        catchError((err) => {
          return err;
        })
      );
  };

  addOfficeEmail(eaOfficeId: string, officeEmail: Partial<OfficeEmail>) {
    return this.apiService
      .post(`offices/${eaOfficeId}/emails`, officeEmail)
      .pipe(
        map((response: { emailId: string }) => {
          this.appStore.dispatch(
            toastActions.success({ message: "add_office_email_success" })
          );

          const newOfficeEmail = {
            ...officeEmail,
            ...response,
            created: moment().format("YYYY-MM-DD HH:mm"),
          };

          this.entityMap$
            .pipe(
              map((em) => em[eaOfficeId]),
              first()
            )
            .subscribe((office) => {
              let updatedOfficeEmails = [];

              if (!!office.officeEmails) {
                updatedOfficeEmails = [...office.officeEmails];
              }

              const updatedOffice = {
                ...office,
                officeEmails: [...updatedOfficeEmails, newOfficeEmail],
              };

              this.updateOneInCache(updatedOffice);
            });

          return newOfficeEmail;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "add_office_email_failed" })
          );
          return err;
        })
      );
  }

  deleteOfficeEmail(eaOfficeId: string, emailId: string) {
    return this.apiService
      .delete(`offices/${eaOfficeId}/emails/${emailId}`, {})
      .pipe(
        map(() => {
          this.appStore.dispatch(
            toastActions.success({ message: "delete_office_email_success" })
          );

          this.entityMap$
            .pipe(
              map((em) => em[eaOfficeId]),
              first()
            )
            .subscribe((office) => {
              const updatedOfficeEmails = office.officeEmails.filter(
                (officeEmail) => officeEmail.emailId !== emailId
              );
              const updatedOffice = {
                ...office,
                officeEmails: [...updatedOfficeEmails],
              };
              this.updateOneInCache(updatedOffice);
            });

          return emailId;
        }),
        catchError((err) => {
          this.appStore.dispatch(
            toastActions.danger({ message: "delete_office_email_failed" })
          );
          return err;
        })
      );
  }

  // Adfenix object integration for office
  getHasAdfenixObjectIntegration = (eaOfficeId: string) => {
    return this.apiService
      .get(
        `adfenix/property/is-configured-for-office/${eaOfficeId}`,
        {},
        "integrations"
      )
      .pipe(
        map((res: { isConfigured: boolean }) => {
          this.upsertOneInCache({
            eaOfficeId,
            isAdfenixPropertyConfigured: res?.isConfigured,
          });
          return res?.isConfigured;
        }),
        catchError(() => null)
      );
  };
}
