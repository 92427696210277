import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Task } from "@app/models";
import { fadeInOutTrigger } from "@app/shared/animations";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import moment from "moment";

@Component({
  selector: "next-step-list-item",
  templateUrl: "./list-item.component.html",
  styleUrls: [
    "../../../../../../../styles/list-item.component.scss",
    "./list-item.component.scss",
  ],
  animations: [fadeInOutTrigger()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NextStepListItemComponent {
  @Input() task: Task;
  @Input() disableClick = false;
  @Input() icon: string;
  @Input() color: string;

  constructor(
    private taskTypeResolver: TaskTypeResolverService,
    private store: Store<AppState>
  ) {}

  isOverdue(): boolean {
    return moment(this.task.deliveryDate).isBefore(moment());
  }

  isEmpty(): boolean {
    return _.isEmpty(this.task);
  }

  getRouterLink(): any[] {
    const sidebarLink = this.taskTypeResolver.resolveSidebarTaskOrLeadRoute(
      this.task.eaTaskId.toString(),
      this.task.eaTaskTypeId
    );
    return ["/crm", { outlets: { sidebar: sidebarLink } }];
  }

  onTaskClick() {
    this.store.dispatch(RouterActions.go({ path: this.getRouterLink() }));
  }
}
