import { EstateCreationConfig } from "@app/core/services/feature-config-manager/models/estate-creation-config";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";

export const buildEstateCreationConfiguration = (
  externalProviderFeature: ExternalProviderFeature
): EstateCreationConfig => {
  let estatePrimaryContactRequired = false;

  if (externalProviderFeature.name.toLowerCase() === "kivi") {
    estatePrimaryContactRequired = true;
  }

  return {
    estatePrimaryContactRequired,
  };
};
