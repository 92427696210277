import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { PhoneNumberService } from "@app/core/services/phone-number/phone-number.service";
import { Contact, Office } from "@app/models";
import { getFeature } from "@app/shared/config/config.reducer";
import * as formUtils from "@app/shared/utils/form-utils";
import { SIDEBAR_TIPS_BASE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { SEND_TIPS } from "@app/shared/utils/tab-types";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import {
  BehaviorSubject,
  combineLatest as observableCombineLatest,
  debounceTime,
  filter,
  first,
  map,
  Observable,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { SidebarTab } from "../../models/sidebar-tab";
import {
  closeTab,
  setInitialTabValue,
  setTabValue,
} from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import * as sendTipsActions from "../ngrx/send-tips.actions";
import {
  closestOfficeRequestStatus,
  getClosestOffice,
  getClosestOfficeLoading,
  getClosestOfficeRequestStatus,
  getProcessing,
  getSelectedContact,
} from "../ngrx/send-tips.reducer";

@Component({
  selector: "send-tips-edit",
  templateUrl: "./send-tips-new.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./send-tips-new.component.scss",
  ],
})
export class SendTipsNewComponent implements OnInit, AfterViewInit, OnDestroy {
  selectedContact$: Observable<Contact>;
  closestOffice$: Observable<Office>;
  closestOfficeLoading$: Observable<boolean>;
  processing$: Observable<boolean>;

  recipientEmployeesChanged$ = new Subject<void>();
  senderOnReady$ = new Subject<void>();

  tab$: Observable<SidebarTab>;

  form: FormGroup;

  tabType = SEND_TIPS;

  unSubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private phoneNumberService: PhoneNumberService
  ) {}

  ngOnInit(): void {
    this.initStoreObservers();

    this.initFormAndEvents();
    this.initParamsEvents();
    this.initSelectedContactEvents();
    this.initClosestOfficeEvents();
  }

  ngAfterViewInit() {
    const initialized = this.store.pipe(
      select(getClosestOfficeRequestStatus),
      filter((value) => !!value),
      first(),
      switchMap((status) => {
        if (status === closestOfficeRequestStatus.succeeded) {
          return this.recipientEmployeesChanged$;
        }
        return new BehaviorSubject(undefined);
      })
    );

    observableCombineLatest([this.senderOnReady$, initialized])
      .pipe(
        switchMap(() => this.tab$.pipe(first())),
        first()
      )
      .subscribe((tab) => {
        if (tab.dirty) {
          this.form.setValue(tab.currentValue);
          this.startContactChangesStream(1);
        } else {
          this.startContactChangesStream();
        }
        this.startValueChangesStream();
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();

    this.store.dispatch(sendTipsActions.reset());
  }

  initStoreObservers(): void {
    this.selectedContact$ = this.store.pipe(select(getSelectedContact));
    this.closestOffice$ = this.store.pipe(select(getClosestOffice));
    this.closestOfficeLoading$ = this.store.pipe(
      select(getClosestOfficeLoading)
    );
    this.processing$ = this.store.pipe(select(getProcessing));

    this.tab$ = this.store.pipe(select(getTab(this.tabType)));
  }

  initFormAndEvents(): void {
    this.form = this.formBuilder.group({
      comment: ["", [Validators.required]],
      title: ["", [Validators.required]],
      sender: this.formBuilder.group({
        employee: ["", [Validators.required]],
        office: ["", [Validators.required]],
      }),
      recipient: this.formBuilder.group({
        employee: "",
        office: ["", [Validators.required]],
      }),
    });

    this.processing$
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((processing: boolean) => {
        if (processing) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      });
  }

  initParamsEvents(): void {
    this.route.params
      .pipe(
        filter((params: Params) => params.hasOwnProperty("id")),
        takeUntil(this.unSubscribe$),
        switchMap((params: Params) =>
          this.selectedContact$.pipe(
            first(),
            filter(
              (contact: Contact) =>
                !contact || contact.contactId !== params["id"]
            ),
            map(() => params["id"])
          )
        )
      )
      .subscribe((id: string) =>
        this.store.dispatch(sendTipsActions.getContactRequest({ id }))
      );
  }

  initSelectedContactEvents(): void {
    this.selectedContact$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((value) => !!value),
        switchMap((contact: Contact) =>
          this.translateService.get("tips_of", { name: contact.getFullName() })
        )
      )
      .subscribe((title: string) => this.form.get("title").setValue(title));
  }

  initClosestOfficeEvents(): void {
    this.closestOffice$
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((office) => office !== null)
      )
      .subscribe((office: Office) =>
        this.form.get("recipient.office").setValue(office.eaOfficeId)
      );

    this.closestOfficeLoading$
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((loading: boolean) =>
        loading
          ? this.form.get("recipient.office").disable()
          : this.form.get("recipient.office").enable()
      );
  }

  startContactChangesStream(skipNumber = 0) {
    this.selectedContact$
      .pipe(
        filter((value) => !!value),
        takeUntil(this.unSubscribe$),
        skip(skipNumber)
      )
      .subscribe(() =>
        this.store.dispatch(
          setInitialTabValue({ tabType: this.tabType, value: this.form.value })
        )
      );
  }

  startValueChangesStream() {
    this.form.valueChanges
      .pipe(takeUntil(this.unSubscribe$), debounceTime(500))
      .subscribe((formValues) =>
        this.store.dispatch(
          setTabValue({ tabType: this.tabType, value: formValues })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  send(formData: FormData): void {
    if (this.form.valid) {
      observableCombineLatest([
        this.selectedContact$,
        this.store.pipe(
          select(getFeature(this.tabType)),
          map((feature) => feature["template_id"])
        ),
      ])
        .pipe(
          map(([contact, eaTaskTemplateId]) => {
            const data: SendTipsParams = {
              eaTaskTemplateId: eaTaskTemplateId,
              eaOfficeId: formData.recipient.office,
              contactId: contact.contactId,
              title: formData.title,
              description: formData.comment,
              originEaEmployeeId: formData.sender.employee,
              originEaOfficeId: formData.sender.office,
            };

            if (formData.recipient.employee) {
              data.eaEmployeeId = formData.recipient.employee;
            }

            return data;
          }),
          first()
        )
        .subscribe((params) => {
          this.store.dispatch(sendTipsActions.sendTipsRequest({ params }));
        });
    } else {
      formUtils.markAllAsTouched(this.form);
    }
  }

  formatNumber(number: string, countryCode: string) {
    return this.phoneNumberService.format(number, countryCode);
  }

  goBack(): void {
    this.store.dispatch(sendTipsActions.reset());
    this.store.dispatch(
      RouterActions.go({
        path: ["/crm", { outlets: { sidebar: SIDEBAR_TIPS_BASE_URL } }],
      })
    );
  }

  senderOnReady(): void {
    this.senderOnReady$.next();
  }

  recipientEmployeesChanged(): void {
    this.recipientEmployeesChanged$.next();
  }
}

interface FormData {
  title: string;
  sender: EmployeeGroup;
  recipient: EmployeeGroup;
  comment: string;
}

interface EmployeeGroup {
  employee: string;
  office: string;
}

export interface SendTipsParams {
  eaTaskTemplateId: number;
  eaOfficeId: string;
  eaEmployeeId?: string;
  contactId: string;
  title: string;
  description: string;
  originEaEmployeeId: string;
  originEaOfficeId: string;
}
