import { SentTipsResponse } from "@app/statistics/statistics-sent-tips/models/sent-tips-response";
import * as statisticsSentTipsActions from "@app/statistics/statistics-sent-tips/ngrx/statistics-sent-tips.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface StatisticsSentTipsState {
  tips: SentTipsResponse;
  tipsLoading: boolean;
  types: { typeName: string; eaTaskTypeId: number }[];
  typesLoading: boolean;
  taskResults: {
    typeName: string;
    eaTaskTypeId: string;
    resultName: string;
    eaTaskResultId: string;
  }[];
  taskResultsLoading: boolean;
  csvLoading: boolean;
}

export const initialState: StatisticsSentTipsState = {
  tips: null,
  tipsLoading: false,
  types: [],
  typesLoading: false,
  taskResults: [],
  taskResultsLoading: false,
  csvLoading: false,
};

export function statisticsSentTipsReducer(
  state: StatisticsSentTipsState,
  action: Action
): StatisticsSentTipsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(statisticsSentTipsActions.fetchTaskTypesRequest, (state) => ({
    ...state,
    typesLoading: true,
  })),
  on(
    statisticsSentTipsActions.fetchTaskTypesSuccess,
    (state, { taskTypes }) => ({
      ...state,
      typesLoading: false,
      types: taskTypes,
    })
  ),
  on(statisticsSentTipsActions.fetchTaskTypesFail, (state) => ({
    ...state,
    typesLoading: false,
  })),
  on(statisticsSentTipsActions.fetchTaskResultsRequest, (state) => ({
    ...state,
    taskResultsLoading: true,
  })),
  on(
    statisticsSentTipsActions.fetchTaskResultsSuccess,
    (state, { taskResults }) => ({
      ...state,
      taskResultsLoading: false,
      taskResults,
    })
  ),
  on(statisticsSentTipsActions.fetchTaskResultsFail, (state) => ({
    ...state,
    taskResultsLoading: false,
  })),
  on(statisticsSentTipsActions.fetchStatisticsRequest, (state) => ({
    ...state,
    tipsLoading: true,
  })),
  on(statisticsSentTipsActions.fetchStatisticsSuccess, (state, { tips }) => ({
    ...state,
    tipsLoading: false,
    tips,
  })),
  on(statisticsSentTipsActions.fetchStatisticsFail, (state) => ({
    ...state,
    tipsLoading: false,
  })),
  on(statisticsSentTipsActions.fetchStatisticsCsvRequest, (state) => ({
    ...state,
    csvLoading: true,
  })),
  on(
    statisticsSentTipsActions.fetchStatisticsCsvSuccess,
    statisticsSentTipsActions.fetchStatisticsCsvFail,
    (state) => ({
      ...state,
      csvLoading: false,
    })
  )
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");

const getState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.sentTips
);

export const getTips = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.tips
);
export const getTipsLoading = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.tipsLoading
);
export const getTypes = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.types
);
export const getTypesLoading = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.typesLoading
);
export const getTaskResults = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.taskResults
);
export const getTaskResultsLoading = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.taskResultsLoading
);
export const getStatisticsCsvLoading = createSelector(
  getState,
  (state: StatisticsSentTipsState) => state.csvLoading
);
