import { TasksCallingListsComponent } from "@app/sidebar/tasks/tasks-calling-lists/tasks-calling-lists.component";
import { TasksStandardComponent } from "@app/sidebar/tasks/tasks-standard/tasks-standard.component";
import { TaskGuard } from "../guards/task-guard.service";
import { TasksDefaultGuard } from "./guards/tasks-default.guard";
import { TasksBlixtvarderingarComponent } from "./tasks-blixtvarderingar/tasks-blixtvarderingar.component";
import { TasksFinalPriceComponent } from "./tasks-final-price/tasks-final-price.component";
import { TasksMittBolanComponent } from "./tasks-mitt-bolan/tasks-mitt-bolan.component";
import { TasksPrivateShowingFollowUpComponent } from "./tasks-private-showing-follow-up/tasks-private-showing-follow-up.component";
import { TasksSalesMeetingReportResolverService } from "./tasks-sales-meeting-report/tasks-sales-meeting-report-resolver.service";
import { TasksSalesMeetingReportComponent } from "./tasks-sales-meeting-report/tasks-sales-meeting-report.component";
import { TasksVardebarometerComponent } from "./tasks-vardebarometer/tasks-vardebarometer.component";
import { TasksVardekollenComponent } from "./tasks-vardekollen/tasks-vardekollen.component";
import { TasksComponent } from "./tasks.component";

export const sidebarTasksRoutes = {
  path: "tasks",
  component: TasksComponent,
  children: [
    {
      path: ":eaTaskId",
      component: TasksStandardComponent,
      canActivate: [TaskGuard, TasksDefaultGuard],
    },
    {
      path: "blixtvarderingar/:eaTaskId",
      component: TasksBlixtvarderingarComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "final-price/:eaTaskId",
      component: TasksFinalPriceComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "mitt-bolan/:eaTaskId",
      component: TasksMittBolanComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "private-showing-follow-up/:eaTaskId",
      component: TasksPrivateShowingFollowUpComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "sales-meeting-follow-up/:eaTaskId",
      component: TasksSalesMeetingReportComponent,
      resolve: { task: TasksSalesMeetingReportResolverService },
      canActivate: [TaskGuard],
    },
    {
      path: "sales-meeting-report/:eaTaskId",
      component: TasksSalesMeetingReportComponent,
      resolve: { task: TasksSalesMeetingReportResolverService },
      canActivate: [TaskGuard],
    },
    {
      path: "vardebarometer/:eaTaskId",
      component: TasksVardebarometerComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "vardekollen/:eaTaskId",
      component: TasksVardekollenComponent,
      canActivate: [TaskGuard],
    },
    {
      path: "calling-list/:eaTaskId",
      component: TasksCallingListsComponent,
      canActivate: [TaskGuard, TasksDefaultGuard],
    },
  ],
};
