import { BodyLink } from "@app/core/components/standard-modal/ngrx/standard-modal.reducer";
import { createAction, props } from "@ngrx/store";

export const show = createAction(
  "[Standard Modal] SHOW_STANDARD_MODAL",
  props<{
    header: string;
    bodyHtml?: string;
    descriptionTooltip?: string;
    bodyLinks?: BodyLink[];
    bodyExternalLinks?: { text: string; eaOid: string }[];
    confirmButton?: BodyLink;
  }>()
);

export const close = createAction("[Standard Modal] CLOSE_STANDARD_MODAL");

export const reset = createAction("[Standard Modal] RESET_STANDARD_MODAL");
