import {
  listsContactsAdvancedReducer,
  ListsContactsAdvancedState,
} from "@app/lists/lists-contacts-advanced/ngrx/lists-contact-advanced.reducer";
import {
  listsOutgoingMessagesReducer,
  ListsOutgoingMessagesState,
} from "@app/lists/lists-outgoing-messages/ngrx/lists-outgoing-messages.reducer";
import {
  listsSearchProfilesReducer,
  ListsSearchProfilesState,
} from "@app/lists/lists-search-profiles/ngrx/lists-search-profiles.reducer";
import {
  listsSentLeadsReducer,
  ListsSentLeadsState,
} from "@app/lists/lists-sent-leads/ngrx/lists-sent-leads.reducer";
import {
  listsShowingsReducer,
  ListsShowingsState,
} from "@app/lists/lists-showings/ngrx/lists-showings.reducer";
import {
  listsSurveysReducer,
  ListsSurveysState,
} from "@app/lists/lists-surveys/ngrx/lists-surveys.reducer";
import { combineReducers } from "@ngrx/store";
import {
  listsContactsReducer,
  ListsContactsState,
} from "./lists-contacts/ngrx/lists-contacts.reducer";
import {
  listsLeadsReducer,
  ListsLeadsState,
} from "./lists-leads/ngrx/lists-leads.reducer";
import {
  listsSalesMeetingsReducer,
  ListsSalesMeetingsState,
} from "./lists-sales-meetings/ngrx/lists-sales-meetings.reducer";
import {
  listsTasksReducer,
  ListsTasksState,
} from "./lists-tasks/ngrx/lists-tasks.reducer";

export interface ListsModuleState {
  salesMeetings: ListsSalesMeetingsState;
  tasks: ListsTasksState;
  leads: ListsLeadsState;
  contacts: ListsContactsState;
  contactsAdvanced: ListsContactsAdvancedState;
  sentLeads: ListsSentLeadsState;
  showings: ListsShowingsState;
  surveys: ListsSurveysState;
  outgoingMessages: ListsOutgoingMessagesState;
  searchProfiles: ListsSearchProfilesState;
}

const reducers = {
  salesMeetings: listsSalesMeetingsReducer,
  tasks: listsTasksReducer,
  leads: listsLeadsReducer,
  contacts: listsContactsReducer,
  contactsAdvanced: listsContactsAdvancedReducer,
  sentLeads: listsSentLeadsReducer,
  showings: listsShowingsReducer,
  surveys: listsSurveysReducer,
  outgoingMessages: listsOutgoingMessagesReducer,
  searchProfiles: listsSearchProfilesReducer,
};

export function listsModuleReducer(state: any, action: any) {
  return combineReducers(reducers)(state, action);
}
