import { API_LIMIT_MAX } from "@app/shared/utils/api-defaults";
import { createAction, props } from "@ngrx/store";
import { Competition, Competitions } from "../models/competition.model";

export const getCompetitions = (params: Record<string, unknown>) => {
  const defaultFilters = {
    limit: API_LIMIT_MAX,
    sortBy: "startDate",
    sortOrder: "DESC",
  };

  const filters = {
    ...defaultFilters,
    ...params,
  };

  return getCompetitionsRequest({ filters });
};

export const getCompetitionsRequest = createAction(
  "[Competitions] GET_COMPETITIONS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getCompetitionsSuccess = createAction(
  "[Competitions] GET_COMPETITIONS_SUCCESS",
  props<{ competitions: Competitions }>()
);

export const getCompetitionsFailed = createAction(
  "[Competitions] GET_COMPETITIONS_FAILED"
);

export const getFinishedCompetitions = (params: Record<string, unknown>) => {
  const defaultFilters = {
    limit: API_LIMIT_MAX,
    sortBy: "startDate",
    sortOrder: "DESC",
    competitionStatus: "completed",
  };

  const filters = {
    ...defaultFilters,
    ...params,
  };

  return getFinishedCompetitionsRequest({ filters });
};

export const getFinishedCompetitionsRequest = createAction(
  "[Competitions] GET_FINISHED_COMPETITIONS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getFinishedCompetitionsSuccess = createAction(
  "[Competitions] GET_FINISHED_COMPETITIONS_SUCCESS",
  props<{ competitions: Competitions }>()
);

export const getFinishedCompetitionsFailed = createAction(
  "[Competitions] GET_FINISHED_COMPETITIONS_FAILED"
);

export const getUpcomingCompetitions = (params: Record<string, unknown>) => {
  const defaultFilters = {
    limit: API_LIMIT_MAX,
    sortBy: "startDate",
    sortOrder: "DESC",
    competitionStatus: "upcoming",
  };

  const filters = {
    ...defaultFilters,
    ...params,
  };

  return getUpcomingCompetitionsRequest({ filters });
};

export const getUpcomingCompetitionsRequest = createAction(
  "[Competitions] GET_UPCOMING_COMPETITIONS_REQUEST",
  props<{ filters: Record<string, unknown> }>()
);

export const getUpcomingCompetitionsSuccess = createAction(
  "[Competitions] GET_UPCOMING_COMPETITIONS_SUCCESS",
  props<{ competitions: Competitions }>()
);

export const getUpcomingCompetitionsFailed = createAction(
  "[Competitions] GET_UPCOMING_COMPETITIONS_FAILED"
);

export const getCompetitionRequest = createAction(
  "[Competitions] GET_COMPETITION_REQUEST",
  props<{ competitionId: string }>()
);

export const getCompetitionSuccess = createAction(
  "[Competitions] GET_COMPETITION_SUCCESS",
  props<{ competition: Competition }>()
);

export const getCompetitionFailed = createAction(
  "[Competitions] GET_COMPETITION_FAILED"
);

export const resetSelectedCompetition = createAction(
  "[Competitions] RESET_SELECTED_COMPETITION"
);

export const resetCompetitions = createAction(
  "[Competitions] RESET_COMPETITIONS"
);
