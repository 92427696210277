import { DynamicContent } from "@app/sidebar/send-message/models";

export const transformDynamicContentToMessageDataSet = (
  dynamicContents: DynamicContent[]
) =>
  dynamicContents.map((dc) => `DynamicContent${dc.name}=${dc.value}`).join("|");

export const transformObjectToMessageDataSet = (object: Object) =>
  Object.keys(object)
    .map((key) => `${key}=${object[key]}`)
    .join("|");

export const combineMessageDataSets = (...dataSet) => dataSet.join("|");
