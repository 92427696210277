import { AppState } from "@app/app.reducer";
import * as fromBidding from "@app/integrations/bidding/ngrx/bidding/bidding.reducer";
import * as fromSendPotentialBuyers from "@app/integrations/bidding/ngrx/send-potential-buyers/send-potential-buyers.reducer";
import { ActionReducerMap } from "@ngrx/store";

export interface BiddingIntegrationFeatureState extends AppState {
  biddingIntegration: BiddingIntegrationState;
}

export interface BiddingIntegrationState {
  bidding: fromBidding.BiddingState;
  sendPotentialBuyers: fromSendPotentialBuyers.SendPotentialBuyersState;
}

export const reducers: ActionReducerMap<BiddingIntegrationState> = {
  bidding: fromBidding.biddingReducer,
  sendPotentialBuyers: fromSendPotentialBuyers.sendPotentialBuyerReducer,
};
