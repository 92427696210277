import { Component } from "@angular/core";

@Component({
  selector: "page-nav-bar-item",
  template: `
    <div class="nav-item">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ["./page-nav-bar-item.component.scss"],
})
export class PageNavBarItemComponent {}
