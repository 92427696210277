import { ContactConsent } from "@app/contacts/contact-consents/models";
import { QObject, SalesMeeting, Task } from "@app/models";
import { Alert } from "@app/sidebar/search-profile/models/alert";
import {
  fetchContactStatusFail,
  fetchContactStatusRequest,
  fetchContactStatusSuccess,
} from "@app/widgets/widgets/contact-widgets/contact-status-widget/ngrx/contact-status-widget.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { WidgetsModuleState } from "@app/widgets/widgets-module.reducer";

export interface ContactStatusWidgetState {
  consents: ContactConsent[];
  numberOfTasks: number;
  tasks: Task[];
  numberOfObjects: number;
  objects: QObject[];
  hasPriceUpdateOrder: boolean;
  hasAlert: boolean;
  alerts: Alert[];
  salesMeetings: SalesMeeting[];
  loading: boolean;
}

export const initialState: ContactStatusWidgetState = {
  consents: [],
  numberOfTasks: null,
  tasks: [],
  numberOfObjects: null,
  objects: [],
  hasPriceUpdateOrder: null,
  hasAlert: null,
  alerts: [],
  salesMeetings: [],
  loading: false,
};

export const contactStatusWidgetReducer = (
  state: ContactStatusWidgetState,
  action: Action
): ContactStatusWidgetState => {
  return reducer(state, action);
};

const reducer = createReducer(
  initialState,
  on(fetchContactStatusRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(fetchContactStatusSuccess, (state, { results }) => ({
    ...state,
    loading: false,
    consents: results[0]["consents"],
    numberOfTasks: results[1]["total"],
    tasks: results[1]["rows"],
    numberOfObjects: results[2]["rows"].length,
    objects: results[2]["rows"],
    hasPriceUpdateOrder: results[3]["total"] > 0,
    hasAlert: results[4]["rows"].length > 0,
    alerts: results[4]["rows"],
    salesMeetings: results[5]["rows"],
  })),
  on(fetchContactStatusFail, (state) => ({
    ...state,
    loading: false,
  }))
);

const selectFeature = createFeatureSelector<WidgetsModuleState>("widgets");
const getState = createSelector(
  selectFeature,
  (state: WidgetsModuleState) => state.contactStatus
);

export const getConsents = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.consents
);

export const getNumberOfTasks = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.numberOfTasks
);

export const getTasks = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.tasks
);

export const getNumberOfObjects = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.numberOfObjects
);

export const getObjects = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.objects
);

export const hasPriceUpdateOrder = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.hasPriceUpdateOrder
);

export const hasAlert = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.hasAlert
);

export const getAlerts = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.alerts
);

export const getSalesMeetings = createSelector(
  getState,
  (state: ContactStatusWidgetState) => state.salesMeetings
);
