import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ApiService } from "@app/core/services/api/api.service";
import { ContactActionCodes } from "@app/core/services/contact/contact-action.service";
import { Contact, PaginationListDTO } from "@app/models";
import {
  MANAGE_POTENTIAL_BUYER_SINGLE,
  SEND_MESSAGE,
} from "@app/shared/utils/tab-types";
import { PotentialBuyerService } from "@app/showings/services/potential-buyer.service";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, first, map, mergeMap, of, switchMap, tap } from "rxjs";
import * as potentialBuyerActions from "@app/sidebar/potential-buyer/ngrx/potential-buyer/potential-buyer.actions";

@Injectable()
export class PotentialBuyerEffects {
  deleteContactObjectConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(potentialBuyerActions.deleteContactObjectConnectionRequest),
      switchMap(({ contactId, eaOid }) =>
        this.api
          .delete(
            `object/contact/${eaOid}/relation/${contactId}/POTENTIAL_BUYER`
          )
          .pipe(
            map(() =>
              potentialBuyerActions.deleteContactObjectConnectionSuccess()
            ),
            catchError(() =>
              of(potentialBuyerActions.deleteContactObjectConnectionFail())
            )
          )
      )
    )
  );

  deleteContactObjectConnectionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(potentialBuyerActions.deleteContactObjectConnectionSuccess),
      tap(() => this.pbService.refreshPotentialBuyersForBiddersList$.next()),
      tap(() =>
        this.contactService.refreshPotentialBuyers().pipe(first()).subscribe()
      ),
      mergeMap(() => {
        return [
          toastActions.success({
            message: "contact_removed_as_potential_buyer",
          }),
          closeTab({ tabType: MANAGE_POTENTIAL_BUYER_SINGLE }),
          closeTab({ tabType: SEND_MESSAGE }),
        ];
      })
    )
  );

  deleteContactObjectConnectionFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(potentialBuyerActions.deleteContactObjectConnectionFail),
      map(() =>
        toastActions.danger({
          message: "contact_removed_as_potential_buyer_failed",
        })
      )
    )
  );

  getPotentialBuyerExternalId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(potentialBuyerActions.getPotentialBuyerExternalIdRequest),
      switchMap(({ contactId }) =>
        this.api.get(`contacts/${contactId}`).pipe(
          map((contact: Contact) =>
            potentialBuyerActions.getPotentialBuyerExternalIdSuccess({
              externalId: contact.externalId,
            })
          ),
          catchError(() =>
            of(potentialBuyerActions.getPotentialBuyerExternalIdFail())
          )
        )
      )
    )
  );

  getContactActions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(potentialBuyerActions.getContactActionsRequest),
      switchMap(({ payload }) =>
        this.api
          .get("actions", {
            ...payload,
            ...{
              codes: [
                ContactActionCodes.CONSUMER_ONE2ONE_SMS,
                ContactActionCodes.CONSUMER_ONE2ONE_EMAIL,
                ContactActionCodes.CONSUMER_SALES_CALL_REGISTERED,
                ContactActionCodes.CONSUMER_CRM_SENT,
              ],
              sortBy: "timestamp",
              sortOrder: "desc",
              limit: 1000,
            },
          })
          .pipe(
            map((response: PaginationListDTO) =>
              potentialBuyerActions.getContactActionsSuccess({
                payload: response.rows,
              })
            ),
            catchError(() => of(potentialBuyerActions.getContactActionsFail()))
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private pbService: PotentialBuyerService,
    private contactService: ContactService
  ) {}
}
