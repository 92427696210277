import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { Employee, Employment, PaginationListDTO } from "@app/models";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class EmployeeService extends CustomEntityCollectionServiceBase<
  Employee & Employment
> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("Employee", serviceElementsFactory);
  }

  getById = (id: string) => {
    this.setLoading(true);
    return this.apiService.get(`employees/${id}`).pipe(
      map((res: Employee) => {
        this.upsertOneInCache(res);
        return res;
      }),
      catchError((err) => {
        return err;
      })
    );
  };

  // @ts-ignore
  getWithQuery = (params?) => {
    this.setLoading(true);
    return this.apiService.get(`employees`, { ...params }, "api").pipe(
      map((response: PaginationListDTO) => {
        let employees = response.rows;
        if (params.eaOfficeId) {
          const eaOfficeIds = params.eaOfficeId.split(",");
          if (eaOfficeIds.length === 1) {
            employees = employees.map((employee) => {
              employee[eaOfficeIds[0]] = true;
              return employee;
            });
          }
        }
        this.upsertManyInCache(employees);
        this.setLoading(false);
        this.setListDefaults(employees, response);
        return employees;
      }),
      catchError((err) => {
        return err;
      })
    );
  };

  getWithOfficeConnection = (params?) => {
    this.setLoading(true);
    return this.apiService
      .get(
        `employee-offices/search`,
        {
          status: "active,inactive,deleted",
          officeMappingStatus: "deleted,active",
          sortBy: "fullName",
          sortOrder: "asc",
          ...params,
        },
        "api"
      )
      .pipe(
        map((response: PaginationListDTO) => {
          const employees = response.rows;
          this.upsertManyInCache(employees);
          this.setLoading(false);
          this.setListDefaults(employees, response);
          return employees;
        }),
        catchError((err) => {
          return err;
        })
      );
  };
}
