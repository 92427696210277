import { Employment } from "@app/models";
import * as employmentsActions from "./manage-employments.actions";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface ManageEmploymentsState {
  loading: boolean;
  loaded: boolean;
  employments: Employment[];
}

export const initialState: ManageEmploymentsState = {
  loading: false,
  loaded: false,
  employments: [],
};

// TODO: Remove | any from action type
export function manageEmploymentsReducer(
  state: ManageEmploymentsState,
  action: Action
): ManageEmploymentsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(employmentsActions.getEmploymentsRequest, (state) => ({
    ...state,
    employments: initialState.employments,
    loading: true,
  })),
  on(employmentsActions.getEmploymentsSuccess, (state, { employments }) => ({
    ...state,
    employments,
    loading: false,
    loaded: true,
  })),
  on(employmentsActions.getEmploymentsFailed, (state) => ({
    ...state,
    employments: initialState.employments,
    loading: false,
    loaded: true,
  })),
  on(
    employmentsActions.updateEmploymentRequest,
    (state, { eaEmployeeId, params }) => {
      const previousState = state.employments.find(
        (e) => e.eaEmployeeId === eaEmployeeId
      );
      const employment = new Employment({
        ...previousState,
        ...params,
        previousState,
        processing: true,
      });
      const employments = state.employments.map((e) =>
        e.eaEmployeeId === eaEmployeeId ? employment : e
      );

      return { ...state, employments };
    }
  ),
  on(employmentsActions.updateEmploymentSuccess, (state, { employment }) => {
    const employments = state.employments.map((e) =>
      e.eaEmployeeId === employment.eaEmployeeId ? employment : e
    );
    return { ...state, employments };
  }),
  on(employmentsActions.updateEmploymentFailed, (state, { eaEmployeeId }) => {
    const employments = state.employments.map((e) =>
      e.eaEmployeeId === eaEmployeeId ? e.previousState : e
    );
    return { ...state, employments };
  }),
  on(employmentsActions.reset, () => ({ ...initialState })),
  on(
    employmentsActions.createHasOnlineBookingRequest,
    employmentsActions.createHasOnlineBookingSuccess,
    employmentsActions.createHasOnlineBookingFailed,
    employmentsActions.deleteHasOnlineBookingRequest,
    employmentsActions.deleteHasOnlineBookingSuccess,
    employmentsActions.deleteHasOnlineBookingFailed,
    (state) => ({ ...state })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.manageEmployments
);

export const getEmployments = createSelector(
  getState,
  (state: ManageEmploymentsState) => state.employments
);

export const getEmploymentsLoading = createSelector(
  getState,
  (state: ManageEmploymentsState) => state.loading
);

export const getEmploymentsLoaded = createSelector(
  getState,
  (state: ManageEmploymentsState) => state.loaded
);
