import { ContactProfile } from "@app/models";
import { Action, createAction, props } from "@ngrx/store";

export const fetchContactProfileRequest = createAction(
  "[PotentialBuyer] FETCH_CONTACT_PROFILE_REQUEST",
  props<{ contactId: string }>()
);

export const fetchContactProfileSuccess = createAction(
  "[PotentialBuyer] FETCH_CONTACT_PROFILE_SUCCESS",
  props<{ profile: ContactProfile }>()
);

export const fetchContactProfileFailure = createAction(
  "[PotentialBuyer] FETCH_CONTACT_PROFILE_FAILURE"
);

export const updateContactProfileRequest = createAction(
  "[PotentialBuyer] UPDATE_CONTACT_PROFILE_REQUEST",
  props<{
    contactId: string;
    body: { ownsResidence?: string; mortgageStatus?: string };
    undoAction: Action;
  }>()
);

export const updateContactProfileSuccess = createAction(
  "[PotentialBuyer] UPDATE_CONTACT_PROFILE_SUCCESS",
  props<{ profile: ContactProfile }>()
);

export const updateContactProfileFailure = createAction(
  "[PotentialBuyer] UPDATE_CONTACT_PROFILE_FAILURE"
);

export const updateContactProfileOptimistic = createAction(
  "[PotentialBuyer] UPDATE_CONTACT_PROFILE_OPTIMISTIC",
  props<{ ownsResidence?: string; mortgageStatus?: string }>()
);
