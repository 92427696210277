import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { MatDialogRef } from "@angular/material/dialog";
import { Observable } from "rxjs";
import * as confirmActions from "./ngrx/confirm-modal.actions";
import {
  getHeader,
  getMessage,
  getNewTabUrl,
} from "./ngrx/confirm-modal.reducer";
import { openLinkInNewTab } from "@app/shared/utils/url-utils";

@Component({
  selector: "confirm-modal",
  templateUrl: "./confirm-modal.component.html",
  styleUrls: ["./confirm-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent implements OnInit {
  header$: Observable<string>;
  message$: Observable<string>;
  newTabUrl$: Observable<string>;

  constructor(
    private dialogRef: MatDialogRef<ConfirmModalComponent>,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.header$ = this.store.pipe(select(getHeader));
    this.message$ = this.store.pipe(select(getMessage));
    this.newTabUrl$ = this.store.pipe(select(getNewTabUrl));
    this.dialogRef
      .afterClosed()
      .subscribe(() => this.store.dispatch(confirmActions.cancel()));
  }

  confirm(): void {
    this.store.dispatch(confirmActions.confirm());
    this.dialogRef.close();
  }

  cancel(): void {
    this.store.dispatch(confirmActions.cancel());
    this.dialogRef.close();
  }

  reset(): void {
    this.store.dispatch(confirmActions.reset());
  }

  openInNewTab(url: string): void {
    openLinkInNewTab(url);
    this.cancel();
  }
}
