import { SalesMeeting, Task } from "@app/models";
import { createAction, props } from "@ngrx/store";

export interface UpdateSalesMeetingReportPayload {
  salesMeeting: SalesMeeting;
  task: Task;
  followUpTask: Task;
  sendMessage: boolean;
}

export const updateSalesMeetingRequest = createAction(
  "[Sales Meeting Report] UPDATE_SALES_MEETING_REQUEST",
  props<{ params: UpdateSalesMeetingReportPayload }>()
);

export const updateSalesMeetingSuccess = createAction(
  "[Sales Meeting Report] UPDATE_SALES_MEETING_SUCCESS",
  props<{ salesMeeting: SalesMeeting }>()
);

export const updateSalesMeetingFailed = createAction(
  "[Sales Meeting Report] UPDATE_SALES_MEETING_FAILED",
  props<{ message: string }>()
);

export const updateTaskRequest = createAction(
  "[Sales Meeting Report] UPDATE_TASK_REQUEST",
  props<{ payload: UpdateSalesMeetingReportPayload }>()
);

export const updateTaskSuccess = createAction(
  "[Sales Meeting Report] UPDATE_TASK_SUCCESS"
);

export const updateTaskFailed = createAction(
  "[Sales Meeting Report] UPDATE_TASK_FAILED",
  props<{ message: string }>()
);

export const createFollowUpTaskRequest = createAction(
  "[Sales Meeting Report] CREATE_FOLLOW_UP_TASK_REQUEST",
  props<{ payload: UpdateSalesMeetingReportPayload }>()
);

export const createFollowUpTaskSuccess = createAction(
  "[Sales Meeting Report] CREATE_FOLLOW_UP_TASK_SUCCESS",
  props<{ task: Task }>()
);

export const createFollowUpTaskFailed = createAction(
  "[Sales Meeting Report] CREATE_FOLLOW_UP_TASK_FAILED",
  props<{ message: string }>()
);

export const updateTaskSalesMeetingReportData = createAction(
  "[Sales Meeting Report] UPDATE_APPLICATION"
);
