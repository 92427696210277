import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { buildCompetitionConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/competitions-config-builder";
import { buildEstateCreationConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/estate-creation-config-builder";
import { buildManagePotentialBuyerSidebarConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/manage-potential-buyers-sidebar-configuration-builder";
import { buildPotentialBuyersPageConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/potential-buyers-page-configuration-builder";
import { buildResidenceConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/residence-configuration-builder";
import { buildShowingAttendanceConfiguration } from "@app/core/services/feature-config-manager/configuration-builders/showing-attendance-builder";
import { CompetitionsConfig } from "@app/core/services/feature-config-manager/models/competitions-config";
import { EstateCreationConfig } from "@app/core/services/feature-config-manager/models/estate-creation-config";
import { ManagePotentialBuyersSidebarConfig } from "@app/core/services/feature-config-manager/models/manage-potential-buyers-sidebar-config";
import { PotentialBuyersPageConfig } from "@app/core/services/feature-config-manager/models/potential-buyers-page-config";
import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";
import { ShowingAttendanceConfig } from "@app/core/services/feature-config-manager/models/showing-attendance-config";
import { getCustomerName, getFeature } from "@app/shared/config/config.reducer";
import { Feature } from "@app/shared/config/models";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import {
  EXTERNAL_PROVIDER,
  OBJECT_OVERVIEW,
} from "@app/shared/config/utils/features";
import { select, Store } from "@ngrx/store";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeatureConfigManagerService {
  constructor(private store: Store<AppState>) {}

  getResidenceSidebarConfig(): Observable<ResidenceSidebarConfig> {
    return this.store.pipe(
      select(getFeature(OBJECT_OVERVIEW)),
      map((feature: Feature) => buildResidenceConfiguration(feature.enabled))
    );
  }

  getShowingAttendanceConfig(): Observable<ShowingAttendanceConfig> {
    return this.store.pipe(
      select(getCustomerName),
      map(() => buildShowingAttendanceConfiguration())
    );
  }

  getManagePotentialBuyerSidebarConfig(): Observable<ManagePotentialBuyersSidebarConfig> {
    return this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) =>
        buildManagePotentialBuyerSidebarConfiguration(feature)
      )
    );
  }

  getPotentialBuyerPageConfig(): Observable<PotentialBuyersPageConfig> {
    return this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) =>
        buildPotentialBuyersPageConfiguration(feature)
      )
    );
  }

  getCompetitionsConfig(): Observable<CompetitionsConfig> {
    return this.store.pipe(
      select(getCustomerName),
      map((customerName: string) => buildCompetitionConfiguration(customerName))
    );
  }

  getEstateCreationConfig(): Observable<EstateCreationConfig> {
    return this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((externalProviderFeature: ExternalProviderFeature) =>
        buildEstateCreationConfiguration(externalProviderFeature)
      )
    );
  }
}
