import { ComponentType } from "@angular/cdk/overlay";
import { Injectable, TemplateRef } from "@angular/core";
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from "@angular/material/dialog";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class QModalService<T = any> {
  private dialogRef: MatDialogRef<any, T | null>;

  constructor(private dialog: MatDialog) {}

  getDialogRef() {
    return this.dialogRef;
  }

  show(
    dialogTemplate: ComponentType<any> | TemplateRef<any>,
    config?: MatDialogConfig
  ): Observable<any> {
    this.dialogRef = this.dialog.open(dialogTemplate, {
      hasBackdrop: true,
      backdropClass: "q-modal-overlay",
      data: {
        hasHeader: true,
        hasHeaderCloseButton: true,
        hasActionBar: true,
      },
      ...config,
    });
    return this.dialogRef.afterClosed();
  }

  close(): void {
    this.dialogRef.close(null);
  }

  closeWithResult(result: T): void {
    this.dialogRef.close(result);
  }
}
