import { Competition } from "@app/competitions/models/competition.model";
import { createAction, props } from "@ngrx/store";

export const updateCompetitionRequest = createAction(
  "[Competitions] UPDATE_COMPETITION_REQUEST",
  props<{ competition: Competition }>()
);

export const updateCompetitionSuccess = createAction(
  "[Competitions] UPDATE_COMPETITION_SUCCESS"
);

export const updateCompetitionFailed = createAction(
  "[Competitions] UPDATE_COMPETITION_FAILED"
);

export const deleteCompetitionRequest = createAction(
  "[Competitions] DELETE_COMPETITION_REQUEST",
  props<{ competitionId: string }>()
);

export const deleteCompetitionSuccess = createAction(
  "[Competitions] DELETE_COMPETITION_SUCCESS"
);

export const deleteCompetitionFailed = createAction(
  "[Competitions] DELETE_COMPETITION_FAILED"
);

export const createCompetitionRequest = createAction(
  "[Competitions] CREATE_COMPETITION_REQUEST",
  props<{ competition: Competition }>()
);

export const createCompetitionSuccess = createAction(
  "[Competitions] CREATE_COMPETITION_SUCCESS"
);

export const createCompetitionFailed = createAction(
  "[Competitions] CREATE_COMPETITION_FAILED"
);
