import { Component, OnDestroy, OnInit } from "@angular/core";
import * as loginActions from "@app/login/ngrx/login.actions";
import { LOGIN_TYPES } from "@app/login/ngrx/login.effects";
import * as fromConfig from "@app/shared/config/config.reducer";
import { SupportSidebarFeature } from "@app/shared/config/models/support-sidebar-feature";
import * as features from "@app/shared/config/utils/features";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, Observable, Subscription, tap } from "rxjs";
import { AppState } from "../app.reducer";
import { getBranding, getCustomerName } from "../shared/config/config.reducer";
import { EVENTS } from "./compatibility-check/compatibility-check.component";
import { isChristmasTime } from "@app/shared/utils/date-utils";

@Component({
  selector: "login",
  styleUrls: ["./login.component.scss"],
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit, OnDestroy {
  customerName$: Observable<string>;
  brandingSubscription: Subscription;

  // TODO: find a solution with *ngIf instead of hidden
  isBrowserSupported$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  label$: BehaviorSubject<string>;

  branding = {
    header_text_color: "",
    header_background_image: "",
    header_background_color: "rgba(255, 255, 255, 1)",
    logo_width: "",
    logo_height: "",
    logo_url: "/assets/img/quedro_horizontal.jpg",
    bottom_quedro_logo: "/assets/img/quedro_horizontal.jpg",
    background_color: "",
  };

  supportTel: string;
  supportTelCountry: string;

  displayPhoneNumberOnLogin$: Observable<boolean>;

  showLoginScreen$: Observable<boolean>;
  isLoginMethodLoaded$: Observable<boolean>;
  isChristmasTime = false;

  constructor(
    private store: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.customerName$ = this.store.pipe(select(getCustomerName));

    this.brandingSubscription = this.store
      .pipe(select(getBranding))
      .subscribe((branding) => this.updateStyles(branding));

    this.translateService.get("compatibility_problem").subscribe((message) => {
      if (!this.label$) {
        this.label$ = new BehaviorSubject(message);
      }
    });

    this.displayPhoneNumberOnLogin$ = this.store.pipe(
      select(fromConfig.getDisplayPhoneNumberOnLogin)
    );

    this.store
      .pipe(select(fromConfig.getFeature(features.SUPPORT)))
      .subscribe((feature: SupportSidebarFeature) => {
        this.supportTel = feature.supportTel;
        this.supportTelCountry = feature.supportTelCountry;
      });

    this.store
      .select((state) => state.login.loginMethod)
      .subscribe((loginMethod) => {
        if (
          loginMethod.isLoaded &&
          loginMethod.supportedLoginType === LOGIN_TYPES.SSO_OAUTH &&
          !window.location.hash.includes("id_token")
        ) {
          window.location.href = loginMethod.redirectUrl;
        }
      });

    this.showLoginScreen$ = this.store.select(
      (state) => state.login.loginMethod.showLoginScreen
    );

    this.isLoginMethodLoaded$ = this.store
      .select((state) => state.login.loginMethod.isLoaded)
      .pipe(
        tap(
          (isLoaded) =>
            !isLoaded &&
            this.store.dispatch(loginActions.checkLoginMethodRequest())
        )
      );

    this.isChristmasTime = isChristmasTime();
  }

  ngOnDestroy() {
    if (this.brandingSubscription) {
      this.brandingSubscription.unsubscribe();
    }
  }

  updateStyles(branding) {
    Object.keys(branding)
      .filter((key) => !key.startsWith("login"))
      .forEach((key) => {
        if (
          branding.custom_style_enabled &&
          ((typeof branding[key] === "string" && branding[key].length > 0) ||
            (typeof branding[key] === "number" && branding[key] > 0))
        ) {
          if (key === "background_color") {
            this.branding.header_background_image = "";
          }
          this.branding[key] = branding[key];
        }
      });
  }

  onBrowserNotSupported(event) {
    if (event.type === EVENTS.browser) {
      const { browser } = event;
      if (browser && !this.label$) {
        this.label$ = new BehaviorSubject(browser.label);
      }
    }
    this.isBrowserSupported$.next(false);
  }
}
