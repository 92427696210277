<form [formGroup]="parentForm">
  <div formArrayName="dynamicContents">
    <div
      *ngFor="let control of dynamicContentsFormArray.controls; let i = index"
    >
      <ng-container
        *ngIf="isMessage(dynamicContents[i].name); then content; else subject"
      >
      </ng-container>
      <ng-template #content>
        <div class="template__control">
          <label translate>message</label>
          <div
            class="template__message"
            [matTooltip]="
              !!control.enabled ? '' : 'dont_have_permission_to_edit'
            "
            matTooltipClass="q-tooltip"
          >
            <app-rich-text-editor
              #richTextEditor
              [control]="control"
              [toolbarEnabled]="isEmail"
              [hasColor]="hasColor"
            ></app-rich-text-editor>
          </div>
          <div
            *ngIf="!isEmail && control.hasError('maxlength')"
            class="validation-message"
            translate
          >
            max_text_length_910
          </div>

          <ng-content></ng-content>
          <ng-template [ngTemplateOutlet]="validationMessage"></ng-template>
        </div>
      </ng-template>
      <ng-template #subject>
        <label *ngIf="isEmail" for="subject" translate>subject</label>
        <div
          *ngIf="isEmail"
          class="template__control margin-top--16"
          [matTooltip]="!!control.enabled ? '' : 'dont_have_permission_to_edit'"
          matTooltipClass="q-tooltip"
        >
          <app-q-input
            id="subject"
            type="text"
            placeholder="subject"
            [formControl]="control"
          >
          </app-q-input>
          <ng-template [ngTemplateOutlet]="validationMessage"></ng-template>
        </div>
      </ng-template>
      <ng-template #validationMessage>
        <ng-container *ngIf="control.hasError('required') && control.touched">
          <div class="validation-message" translate>validation_required</div>
        </ng-container>
      </ng-template>
    </div>
  </div>
</form>
