import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { TranslateModule } from "@ngx-translate/core";
import { ModalModule } from "ngx-bootstrap/modal";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { DevCustomerSelectionComponent } from "./components/dev-customer-selection-form/dev-customer-selection-form.component";
import { DevCustomerSelectionModalComponent } from "./components/dev-customer-selection-modal/dev-customer-selection-modal.component";
import { DevApiService } from "./services/dev-api.service";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModalModule,
    TooltipModule,
    UiComponentsModule,
    FormsModule,
    TranslateModule,
  ],
  providers: [DevApiService],
  declarations: [
    DevCustomerSelectionComponent,
    DevCustomerSelectionModalComponent,
  ],
  exports: [DevCustomerSelectionModalComponent],
})
export class DevModule {}
