import { Note } from "@app/models";
import * as noteTypes from "@app/shared/utils/note-types";
import { createAction, props } from "@ngrx/store";

export const fetchNote = (params: {
  contactId: string;
  eaOid: string;
  type?: string;
  sortBy?: string;
  sortOrder?: string;
  limit?: number;
}) => {
  const defaultParams = {
    type: noteTypes.OBJECT,
    sortBy: "insertedDate",
    sortOrder: "desc",
    limit: 1,
  };
  return fetchNoteRequest({ params: { ...defaultParams, ...params } });
};

export const fetchNoteRequest = createAction(
  "[PotentialBuyer] FETCH_NOTE_REQUEST",
  props<{
    params: {
      contactId: string;
      eaOid: string;
      type?: string;
      sortBy?: string;
      sortOrder?: string;
      limit?: number;
    };
  }>()
);

export const fetchNoteSuccess = createAction(
  "[PotentialBuyer] FETCH_NOTE_SUCCESS",
  props<{ payload: Note }>()
);

export const fetchNoteFailure = createAction(
  "[PotentialBuyer] FETCH_NOTE_FAILURE"
);

export const createNote = (params: {
  note: string;
  eaOid: string;
  contactId: string;
  type?: string;
}) => {
  const defaultParams = { type: noteTypes.OBJECT };
  return createNoteRequest({ params: { ...defaultParams, ...params } });
};

export const createNoteRequest = createAction(
  "[PotentialBuyer] CREATE_NOTE_REQUEST",
  props<{
    params: {
      note: string;
      eaOid: string;
      contactId: string;
      type?: string;
    };
  }>()
);

export const createNoteSuccess = createAction(
  "[PotentialBuyer] CREATE_NOTE_SUCCESS"
);

export const createNoteFailure = createAction(
  "[PotentialBuyer] CREATE_NOTE_FAILURE"
);
