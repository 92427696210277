import { createAction, props } from "@ngrx/store";
import { RequestObject } from "@app/shared/modules/action-list/models";
import { Action as ActionModel } from "@app/models";

export const deleteContactObjectConnectionRequest = createAction(
  "[PotentialBuyer] DELETE_CONTACT_OBJECT_CONNECTION_REQUEST",
  props<{ contactId: string; eaOid: string }>()
);

export const deleteContactObjectConnectionSuccess = createAction(
  "[PotentialBuyer] DELETE_CONTACT_OBJECT_CONNECTION_SUCCESS"
);

export const deleteContactObjectConnectionFail = createAction(
  "[PotentialBuyer] DELETE_CONTACT_OBJECT_CONNECTION_FAIL"
);

export const getPotentialBuyerExternalIdRequest = createAction(
  "[PotentialBuyer] GET_POTENTIAL_BUYER_EXTERNAL_ID_REQUEST",
  props<{ contactId: string }>()
);

export const getPotentialBuyerExternalIdSuccess = createAction(
  "[PotentialBuyer] GET_POTENTIAL_BUYER_EXTERNAL_ID_SUCCESS",
  props<{ externalId: string }>()
);

export const getPotentialBuyerExternalIdFail = createAction(
  "[PotentialBuyer] GET_POTENTIAL_BUYER_EXTERNAL_ID_FAIL"
);

export const getContactActionsRequest = createAction(
  "[PotentialBuyer] GET_CONTACT_ACTIONS_REQUEST",
  props<{ payload: RequestObject }>()
);

export const getContactActionsSuccess = createAction(
  "[PotentialBuyer] GET_CONTACT_ACTIONS_SUCCESS",
  props<{ payload: ActionModel[] }>()
);

export const getContactActionsFail = createAction(
  "[PotentialBuyer] GET_CONTACT_ACTIONS_FAIL"
);

export const reset = createAction("[PotentialBuyer] RESET");
