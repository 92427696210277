export const FOLLOW_UP = "task_type_followup";
export const FOLLOW_UP_BUYER_30_DAYS = "followup_buyer_30_days";
export const FOLLOW_UP_SELLER_30_DAYS = "followup_seller_30_days";
export const PRICE_UPDATER_SOLD_PROPERTIES = "priceupdater_sold_properties";
export const PRICE_UPDATER_EVALUATED_PROPERTIES =
  "priceupdater_evaluated_properties";
export const SALES_MEETING_REPORT = "salesmeeting_report";
export const SALES_MEETING_FOLLOW_UP = "salesmeeting_followup";
export const CONTACT_REQUEST = "contact_request";

export const MITT_BOLAN = "mitt_bolan";
export const BLIXTVARDERINGAR = "blixtvarderingar";
export const VARDEBAROMETER = "vardebarometer";
export const PRIVATE_SHOWING_FOLLOW_UP = "private_showings_follow_up";
export const FINAL_PRICE = "final_price";
export const VARDEKOLLEN = "vardekollen";
export const CALLING_LIST = "calling_list";
export const POTENTIAL_BUYER = "potential_buyer";
