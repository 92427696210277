<h4 translate>owns_residence</h4>
<div class="btn-group">
  <button
    [class.active]="isSelected(Status.OwnsResidence)"
    (click)="handleClick(Status.OwnsResidence)"
    type="button"
    class="btn btn-default"
    translate
  >
    yes
  </button>

  <button
    [class.active]="isSelected(Status.DoesNotOwnResidence)"
    (click)="handleClick(Status.DoesNotOwnResidence)"
    type="button"
    class="btn btn-default"
    translate
  >
    no
  </button>
</div>
