import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as exchangeActions from "./settings-account-exchange.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface SettingsAccountExchangeState {
  loaded: boolean;
  loading: boolean;
  processing: boolean;
  email: string;
}

export const initialState: SettingsAccountExchangeState = {
  loaded: false,
  loading: false,
  processing: false,
  email: null,
};

export function settingsAccountExchangeReducer(
  state: SettingsAccountExchangeState,
  action: Action
): SettingsAccountExchangeState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(exchangeActions.getExchangeDataRequest, (state) => ({
    ...state,
    loading: true,
  })),
  on(exchangeActions.getExchangeDataSuccess, (state, { email }) => ({
    ...state,
    loading: false,
    loaded: true,
    email,
  })),
  on(exchangeActions.getExchangeDataFailed, (state) => ({
    ...state,
    loading: initialState.loading,
    loaded: true,
    email: initialState.email,
  })),
  on(
    exchangeActions.createExchangeIntegrationRequest,
    exchangeActions.deleteExchangeIntegrationRequest,
    (state) => ({ ...state, processing: true })
  ),
  on(
    exchangeActions.createExchangeIntegrationSuccess,
    (state, { exchangeAccount }) => ({
      ...state,
      processing: false,
      email: exchangeAccount.email,
    })
  ),
  on(exchangeActions.deleteExchangeIntegrationSuccess, (state) => ({
    ...state,
    processing: false,
    email: initialState.email,
  })),
  on(
    exchangeActions.createExchangeIntegrationFailed,
    exchangeActions.deleteExchangeIntegrationFailed,
    (state) => ({ ...state, processing: false })
  ),
  on(exchangeActions.reset, () => ({ ...initialState }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.exchange
);

export const getSettingsAccountExchangeLoaded = createSelector(
  getState,
  (state: SettingsAccountExchangeState) => state.loaded
);

export const getSettingsAccountExchangeLoading = createSelector(
  getState,
  (state: SettingsAccountExchangeState) => state.loading
);

export const getSettingsAccountExchangeProcessing = createSelector(
  getState,
  (state: SettingsAccountExchangeState) => state.processing
);

export const getSettingsAccountExchangeEmail = createSelector(
  getState,
  (state: SettingsAccountExchangeState) => state.email
);
