import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";
import { DateService } from "@app/core/services/date/date.service";
import { Action } from "@app/models";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import { Observable, switchMap } from "rxjs";
import { ActionListItemService } from "../../services/action-list-item.service";

@Component({
  selector: "action-lead-connected",
  template: `
    <div class="display-flex-start">
      <span [innerHTML]="text | async" class=""></span>&nbsp;
      <contact-summary [contactId]="item.contactId"></contact-summary>
    </div>
    <p>
      <small *ngIf="item.param2?.length > 0" class="text-muted">
        <strong>{{ "type" | translate }}: </strong>{{ item.param2 }}
      </small>
      <small *ngIf="item.street?.length > 0" class="text-muted">
        <strong>{{ "address" | translate }}: </strong>
        <span
          class="text-link"
          (click)="itemService.navigateToObject(item.eaOid)"
          >{{ item.street }}
        </span>
      </small>
    </p>
  `,
  styleUrls: ["./common.scss"],
})
export class ActionLeadConnectedComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() item: Action;

  text: Observable<string>;
  listenerLead: Function;
  listenerName: Function;

  classLead = "action-lead-connected-lead";

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService,
    public itemService: ActionListItemService,
    private dateUtilService: DateService
  ) {}

  ngOnInit() {
    const inPastTense = this.dateUtilService.expired(this.item.param3);
    const translationKey =
      "action_lead_connected_text" + (inPastTense ? "_past" : "");

    this.text = this.itemService.getMeTranslation(this.item).pipe(
      switchMap((meString: string) =>
        this.translateService.get(translationKey, {
          lead: `<span class="${this.classLead} text-link">${this.item.param1}</span>`,
          name: meString,
          fromNow: moment(this.item.param3).fromNow(),
        })
      )
    );
  }

  ngAfterViewInit() {
    this.listenerLead = this.itemService.createLeadLinkListener(
      this.elementRef,
      this.renderer,
      this.classLead,
      this.item
    );
  }

  ngOnDestroy() {
    const listeners = [this.listenerLead, this.listenerName];
    listeners.forEach((listener: Function) => {
      if (listener) {
        listener();
      }
    });
  }
}
