import { Injectable } from "@angular/core";
import { CustomEntityCollectionServiceBase } from "@app/core/ngrx/entity-services/custom-entity-collection-service-base";
import { ApiService } from "@app/core/services/api/api.service";
import { Municipality } from "@app/models/municipality";
import { EntityCollectionServiceElementsFactory } from "@ngrx/data";
import { catchError, map } from "rxjs";

@Injectable({ providedIn: "root" })
export class MunicipalityService extends CustomEntityCollectionServiceBase<Municipality> {
  constructor(
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
    private apiService: ApiService
  ) {
    super("Municipality", serviceElementsFactory);
  }

  // @ts-ignore
  getWithQuery = (params: any) => {
    this.setLoading(true);
    return this.apiService.get("municipality-lists/search", params).pipe(
      map((response: any) => {
        this.setLoading(false);
        this.upsertManyInCache(response.municipalityLists);
        this.setListDefaults(response.municipalityLists);
        return response.municipalityLists;
      }),
      catchError((err) => err)
    );
  };
}
