import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import { ApiService } from "@app/core/services/api/api.service";
import {
  MANAGE_POTENTIAL_BUYER_SINGLE,
  SEND_MESSAGE,
} from "@app/shared/utils/tab-types";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, first, map, mergeMap, of, switchMap, tap } from "rxjs";
import * as showingActions from "@app/sidebar/potential-buyer/ngrx/showing/showing.actions";

@Injectable()
export class PrivateShowingEffects {
  storePrivateShowingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingActions.storeShowingRequest),
      switchMap(({ showings }) =>
        this.api.post(`showings`, showings).pipe(
          map(() =>
            showingActions.storeShowingSuccess({ eaOid: showings.eaOid })
          ),
          catchError(() => of(showingActions.storeShowingFail()))
        )
      )
    )
  );

  storePrivateShowingSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingActions.storeShowingSuccess),
      tap(() =>
        this.contactService.refreshPotentialBuyers().pipe(first()).subscribe()
      ),
      tap(() =>
        this.objectService.refreshShowingObject().pipe(first()).subscribe()
      ),
      mergeMap(() => {
        return [
          toastActions.success({ message: "showing_stored_successfully" }),
          closeTab({ tabType: MANAGE_POTENTIAL_BUYER_SINGLE }),
          closeTab({ tabType: SEND_MESSAGE }),
        ];
      })
    )
  );

  storePrivateShowingFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showingActions.storeShowingFail),
      map(() => toastActions.success({ message: "showing_stored_failed" }))
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private objectService: ObjectService,
    private contactService: ContactService
  ) {}
}
