import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[loadingMask]",
})
export class LoadingMaskDirective implements OnChanges {
  @Input() loadingMask: boolean;

  private _previousValue: boolean;
  private _overlay;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    this.createOverlay();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { loadingMask } = changes;

    if (loadingMask && loadingMask.currentValue !== this._previousValue) {
      this.update(loadingMask.currentValue);
    }
  }

  private createOverlay(): void {
    const spinner = this.renderer.createElement("div");
    this.renderer.addClass(spinner, "icon-spinner");

    this._overlay = this.renderer.createElement("div");
    this.renderer.addClass(this._overlay, "flex-center");
    this.renderer.addClass(this._overlay, "loading-overlay");
    this.renderer.appendChild(this._overlay, spinner);
  }

  private update(loading: boolean): void {
    if (loading) {
      this.addMask();
    } else {
      this.removeMask();
    }
    this._previousValue = loading;
  }

  private addMask(): void {
    this.renderer.addClass(this.elementRef.nativeElement, "disable-events");
    this.renderer.appendChild(this.elementRef.nativeElement, this._overlay);
  }

  private removeMask(): void {
    this.renderer.removeChild(this.elementRef.nativeElement, this._overlay);
    this.renderer.removeClass(this.elementRef.nativeElement, "disable-events");
  }
}
