import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "app-q-action-dropdown-item",
  templateUrl: "./q-action-dropdown-item.component.html",
  styleUrls: ["./q-action-dropdown-item.component.scss"],
})
export class QActionDropdownItemComponent {
  @Input() label: string;
  @Input() icon: string;
  @Input() iconColor: string;

  @Output() press = new EventEmitter<any>();
}
