<div class="widget-header">
  <h3>
    <app-icon size="large" name="house-return"></app-icon>&nbsp;<span>{{
      "showings" | translate
    }}</span>
  </h3>
  <app-q-circle-btn
    [style.margin-left]="'6px'"
    [icon]="'arrow-rotate-right'"
    [tooltipText]="'refresh_showings_tooltip'"
    (click)="refreshShowings()"
  ></app-q-circle-btn>
</div>

<div
  class="widget-container"
  [class.loading-pulse]="showingPdfLoading$ | async"
>
  <section class="meta">
    <ng-container *ngFor="let showing of showings$ | async">
      <app-mobile-card
        [enableActionDropdown]="true"
        [shadow]="false"
        [clickable]="false"
        [enableMetadata]="true"
        [size]="'SLIM'"
      >
        <div title>
          {{
            !!showing.startTime
              ? (showing.startTime | momentify : true)
              : ("no_start_date" | translate)
          }}
          <app-icon
            matTooltipClass="q-tooltip"
            [matTooltip]="'private_showing' | translate"
            *ngIf="!showing.isPublic"
            name="eye"
          ></app-icon>
        </div>
        <div metadata>
          <span *ngIf="showing.startTime">
            {{ moment(showing.startTime).format("LT") }}
            -
            {{
              showing.endTime
                ? moment(showing.endTime).format("LT")
                : ("no_end_time" | translate)
            }}.
          </span>
          <span *ngIf="!!showing?.showingAgent">
            {{ "showing_broker" | translate }}:
            {{ showing.showingAgent?.fullName }}.
          </span>
          <span *ngIf="!!showing?.potentialBuyers">
            {{ "potential_buyers" | translate }}:
            {{ showing?.potentialBuyers?.length }}.
          </span>
        </div>
        <div buttons>
          <li>
            <button
              class="pdf-link btn btn-default hidden-xs hidden-sm"
              (click)="getAsPDFForShowing(showing)"
            >
              <app-icon name="cloud-download"></app-icon>
              <span translate>potential_buyers</span>
            </button>
          </li>
        </div>
      </app-mobile-card>
    </ng-container>
  </section>
</div>

<ng-template #nodata>
  <span>-</span>
</ng-template>
