import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { select, Store } from "@ngrx/store";
import {
  filter,
  first,
  Observable,
  Subject,
  take,
  takeUntil,
  withLatestFrom,
} from "rxjs";

import { AppState } from "@app/app.reducer";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import { LEAD_RESULT_OPTIONS } from "@app/shared/utils/tab-types";
import { LeadResultOptionsFormComponent } from "@app/sidebar/lead-result-options/components/lead-result-options-form/lead-result-options-form.component";
import * as leadResultOptionsFormActions from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.actions";
import {
  selectData,
  selectIsLoading,
} from "@app/sidebar/lead-result-options/ngrx/lead-result-options-form.reducer";
import {
  ConnectTab,
  IConnectableTab,
} from "@app/sidebar/sidebar-connectable-tab";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";

@Component({
  selector: "app-lead-templates-edit",
  templateUrl: "./lead-result-options-edit.component.html",
  styleUrls: [
    "../../../sidebar.component.common.scss",
    "./lead-result-options-edit.component.scss",
  ],
})
export class LeadResultOptionsEditComponent
  extends ConnectTab
  implements OnInit, OnDestroy, IConnectableTab
{
  @ViewChild(LeadResultOptionsFormComponent, { static: true })
  leadTemplatesForm: LeadResultOptionsFormComponent;

  tabType = LEAD_RESULT_OPTIONS;

  proxy$ = new Subject<any>();
  unsubscribe$ = new Subject<void>();
  data$: Observable<any>;
  isLoading$: Observable<any>;
  ids$: Observable<Params>;

  constructor(store: Store<AppState>, private route: ActivatedRoute) {
    super(store);
    super.connectTab(this.proxy$, this.unsubscribe$, this.tabType);
  }

  ngOnInit(): void {
    this.ids$ = this.route.params;
    this.ids$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ channelId, id }) => {
        this.store.dispatch(
          leadResultOptionsFormActions.getLeadResultOptionRequest({
            channelId,
            id,
          })
        );
      });
    this.mapStateToProps();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps() {
    this.data$ = this.store.select(selectData);
    this.isLoading$ = this.store.select(selectIsLoading);
  }

  closeTab() {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onRemove() {
    this.store.dispatch(
      confirmActions.show({
        header: "delete_option_header",
        message: "delete_option_message",
      })
    );
    this.store
      .pipe(
        select(confirmState),
        filter((value) => !!value),
        take(1),
        withLatestFrom(this.ids$)
      )
      .subscribe(([, { channelId, id }]) => {
        this.store.dispatch(
          leadResultOptionsFormActions.deleteLeadResultOptionRequest({
            channelId,
            id,
          })
        );
      });
  }

  onSubmit() {
    this.ids$.pipe(first()).subscribe(({ channelId, id }) => {
      this.leadTemplatesForm.submit((body) =>
        this.store.dispatch(
          leadResultOptionsFormActions.editLeadResultOptionRequest({
            channelId,
            id,
            body,
          })
        )
      );
    });
  }
}
