import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { addBackLink } from "@app/contacts/contact.actions";
import { UrlService } from "@app/core/services/url/url.service";
import { openLinkInNewTab as handleOpenInNewTab } from "@app/shared/utils/url-utils";
import { select, Store } from "@ngrx/store";
import { first, map, Observable } from "rxjs";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import moment from "moment/moment";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { Kpi } from "@app/kpi/models/kpi";
import { NPS_VALUES, NpsEntity, NpsRequest } from "@app/models/nps";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import * as fromConfig from "@app/shared/config/config.reducer";
import * as features from "@app/shared/config/utils/features";
import { KpiFeature } from "@app/shared/config/models";

@Component({
  selector: "app-kpi-collection-widget",
  templateUrl: "./kpi-collection-widget.component.html",
  styleUrls: ["./kpi-collection-widget.component.scss"],
})
export class KpiCollectionWidgetComponent implements OnInit {
  @Input() showHeader = false;
  @Input() openLinkInNewTab = false;

  userIds$: Observable<UserIds>;
  showKpi$: Observable<boolean>;
  enabledKpiTypes$: Observable<string[]>;
  kpiSearchParams = {
    from: moment().format("YYYYMM"),
    to: moment().format("YYYYMM"),
  };
  npsMonthsBack: number;

  constructor(
    private store: Store<AppState>,
    private urlService: UrlService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.userIds$ = this.store.pipe(fromUser.getEaIds);
    this.showKpi$ = this.store.pipe(
      select(fromConfig.getFeature(features.KPI)),
      map((feature) => feature.enabled)
    );
    this.enabledKpiTypes$ = this.store.pipe(
      select(fromConfig.getFeature(features.KPI)),
      map((feature: KpiFeature) => {
        return feature.kpis.filter((kpi) => kpi.enabled).map((kpi) => kpi.type);
      })
    );
  }

  openInNewTab(segments: unknown[], queryParams: Record<string, unknown>) {
    const url = this.urlService.buildUrlFromRouterWithQuery(
      segments,
      queryParams
    );

    handleOpenInNewTab(url);
  }

  addBackLink(): void {
    this.store.dispatch(addBackLink({ backLink: this.router.url }));
  }

  onKpiClick(kpi: Kpi): void {
    this.userIds$.pipe(first()).subscribe((userIds) => {
      const urlSegments = [
        "kpi",
        kpi.kpiType,
        "offices",
        "",
        "employees",
        userIds.eaEmployeeId,
      ];

      if (this.openLinkInNewTab) {
        const params = {
          ...this.kpiSearchParams,
          type: kpi.kpiType,
          offices: "",
          employees: userIds.eaEmployeeId,
        };
        this.addBackLink();
        this.openInNewTab(["/crm", "xkpi"], params);
        return;
      }

      this.store.dispatch(
        RouterActions.go({
          path: ["/crm", { outlets: { modal: urlSegments } }],
          query: {
            ...this.kpiSearchParams,
          },
        })
      );
    });
  }

  onNpsClick(params: { nps: NpsEntity; filters: NpsRequest }): void {
    this.userIds$.pipe(first()).subscribe((userIds) => {
      const urlSegments = [
        "kpi",
        "nps",
        "offices",
        "",
        "employees",
        userIds.eaEmployeeId,
      ];

      if (
        params.filters?.eaOfficeId &&
        !params.filters.eaOfficeId.includes(",")
      ) {
        urlSegments[3] = "";
      }

      let minDate = "";
      let maxDate = "";

      if (params.filters.searchParams.resMonthMin) {
        minDate = moment()
          .add(params.filters.searchParams.resMonthMin, "months")
          .startOf("month")
          .format(API_DATE_FORMAT);
        maxDate = moment().endOf("month").format(API_DATE_FORMAT);
      } else {
        minDate = params.filters.searchParams.resDateMin;
        maxDate = params.filters.searchParams.resDateMax;
      }

      if (this.openLinkInNewTab) {
        const params = {
          type: "nps",
          offices: "",
          employees: userIds.eaEmployeeId,
          minDate: minDate,
          maxDate: maxDate,
          NPSClass: `${NPS_VALUES.PROMOTERS},${NPS_VALUES.PASSIVES},${NPS_VALUES.DETRACTORS}`,
        };
        this.addBackLink();
        this.openInNewTab(["/crm", "xkpi"], params);
        return;
      }

      this.store.dispatch(
        RouterActions.go({
          path: ["/crm", { outlets: { modal: urlSegments } }],
          query: {
            minDate: minDate,
            maxDate: maxDate,
            NPSClass: `${NPS_VALUES.PROMOTERS},${NPS_VALUES.PASSIVES},${NPS_VALUES.DETRACTORS}`,
          },
        })
      );
    });
  }
}
