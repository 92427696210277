import {
  AfterViewInit,
  Directive,
  OnDestroy,
  Optional,
  Self,
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { distinctUntilChanged, filter, Subject } from "rxjs";
import {
  MATCH_NOT_DIGITS_WHITE_SPACE_GLOBAL,
  MATCH_WHITE_SPACE_GLOBAL,
} from "../../../utils/regex-patterns";
import { ThousandSeparatorPipe } from "../../ui-components/pipes/thousand-separator.pipe";

@Directive({
  selector: "[thousandSeparator]",
})
export class ThousandSeparatorDirective implements AfterViewInit, OnDestroy {
  unSubscribe$ = new Subject<void>();

  constructor(
    private thousandSeparator: ThousandSeparatorPipe,
    @Optional()
    @Self()
    public _ngControl: NgControl
  ) {}

  ngAfterViewInit() {
    this._ngControl.valueChanges
      .pipe(
        filter((a, b) => !!a && !!b),
        distinctUntilChanged(
          (a, b) =>
            a.replace(MATCH_WHITE_SPACE_GLOBAL, "") ===
            b.replace(MATCH_WHITE_SPACE_GLOBAL, "")
        )
      )
      .subscribe((value) => {
        this._ngControl.control.setValue(
          this.thousandSeparator.transform(
            value.replace(MATCH_NOT_DIGITS_WHITE_SPACE_GLOBAL, "")
          )
        );
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }
}
