import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { catchError, map, Observable } from "rxjs";

import { ApiService } from "@app/core/services/api/api.service";
import { QObject, TypedPaginationListDTO } from "@app/models";

@Component({
  selector: "app-q-object-select",
  templateUrl: "./q-object-select.component.html",
  styleUrls: ["./q-object-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QObjectSelectComponent),
    },
  ],
})
export class QObjectSelectComponent implements OnInit {
  @Input() contactId: string;
  @Input() invalid: boolean;
  @Input() inline: boolean;

  private _value = null;
  touched = false;
  disabled = false;

  objects$: Observable<any>;

  get value() {
    return this._value;
  }

  @Input() set value(selected: any) {
    this._value = selected;
  }

  private onTouched!: Function;
  private onChange!: Function;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.objects$ = this.apiService
      .get(`object/contact/${this.contactId}/contact-objects/search`, {})
      .pipe(
        map((response: TypedPaginationListDTO<QObject>) => {
          const objects = response.rows as QObject[];
          return objects
            .filter(
              (object) => object.type === "buyer" || object.type === "seller"
            )
            .map((object) => {
              return {
                label: object.street,
                value: object.eaOid,
              };
            });
        }),
        catchError((err) => {
          return err;
        })
      );
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onClick(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
