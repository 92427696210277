import { Component, forwardRef, Input, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import moment from "moment";
import { Moment } from "moment-timezone";
import { BsDatepickerViewMode } from "ngx-bootstrap/datepicker";

@Component({
  selector: "app-q-date-picker",
  templateUrl: "./q-date-picker.component.html",
  styleUrls: ["./q-date-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => QDatePickerComponent),
    },
  ],
})
export class QDatePickerComponent implements OnInit, ControlValueAccessor {
  @Input() label: string;
  @Input() placeholder = moment(new Date())
    .localeData()
    .longDateFormat("L")
    .toUpperCase();
  @Input() invalid: boolean;
  @Input() defaultValue: Date | Moment;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() minMode: BsDatepickerViewMode = "day";
  @Input() dateFormat;
  @Input() placement: "top" | "bottom" = "bottom";
  @Input() inline: boolean;
  @Input() required = false;

  bsConfig: {};
  private _value;
  touched = false;
  disabled = false;

  get value() {
    return this._value;
  }

  @Input() set value(date: Date) {
    this._value = date;
  }

  private onTouched!: Function;
  private onChange!: Function;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.value = obj ? moment(obj).toDate() : obj;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.bsConfig = {
      adaptivePosition: true,
      containerClass: "theme-quedro",
      minMode: this.minMode,
      isAnimated: true,
      customTodayClass: "datepicker-custom-today-class",
    };

    if (this.dateFormat) {
      this.bsConfig["dateInputFormat"] = this.dateFormat;
    }
  }

  clear() {
    if (this.defaultValue) {
      this.value = moment(this.defaultValue).toDate();
      this.onChange(moment(this.defaultValue).format("YYYYMMDD"));
    } else {
      this.value = undefined;
      this.onChange("");
    }
  }

  onValueChange(value: Date) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.onChange(value ? moment(value).format("YYYYMMDD") : value);
    }
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
