import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: "resource-footer",
  styleUrls: ["./resource-footer.component.scss"],
  template: `
    <div class="col-lg-12 resource-footer">
      <ng-content select="[first]"></ng-content>
      <div class="second-btn-group">
        <ng-content select="[beforeEdit]"></ng-content>
        <ng-content select="[beforeEdit2]"></ng-content>
        <a
          class="q-btn btn-secondary"
          *ngIf="showEdit"
          type="button"
          (click)="!processing && !removing && onEdit.emit()"
          [class.disabled]="processing || removing"
          [routerLink]="processing ? undefined : editRouterLink"
        >
          <i class="icon-pencil"></i>
          <span>{{ "edit" | translate }}</span>
        </a>
        <ng-content select="[afterEdit]"></ng-content>
      </div>
      <div>
        <ng-content></ng-content>
      </div>
      <div>
        <ng-content select="[beforeRemove]"></ng-content>
        <app-q-button
          *ngIf="showRemove"
          buttonType="remove"
          label="remove"
          icon="trash"
          (click)="onRemove.emit()"
          [isLoading]="processing || removing"
          [disabled]="processing || removing"
        >
        </app-q-button>
        <ng-content select="[afterRemove]"></ng-content>
      </div>
      <ng-content select="[last]"></ng-content>
    </div>
  `,
})
export class ResourceFooterComponent {
  @Input() showEdit = false;

  @Input() showRemove = false;

  @Input() editRouterLink: any;

  @Input() processing = false;

  @Input() removing = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onEdit = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onRemove = new EventEmitter<any>();
}
