import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-progress-circle",
  templateUrl: "./progress-circle.component.html",
  styleUrls: ["./progress-circle.component.scss"],
})
export class ProgressCircleComponent implements OnInit {
  @Input() radius: number;
  @Input() stroke: number;
  @Input() strokeColor: string;
  @Input() currentValue: number;
  @Input() maxValue: number;

  normalizedRadius: number;
  circumference: number;

  ngOnInit(): void {
    this.normalizedRadius = this.radius - this.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  setProgress(): number {
    const percent = this.currentValue / this.maxValue;
    const offset = this.circumference - percent * this.circumference;
    return offset;
  }
}
