export enum WeatherSymbol {
  CLEAR_SKY = "CLEAR_SKY",
  NEARLY_CLEAR_SKY = "NEARLY_CLEAR_SKY",
  VARIABLE_CLOUDINESS = "VARIABLE_CLOUDINESS",
  HALFCLEAR_SKY = "HALFCLEAR_SKY",
  CLOUDY_SKY = "CLOUDY_SKY",
  OVERCAST = "OVERCAST",
  FOG = "FOG",
  LIGHT_RAIN_SHOWERS = "LIGHT_RAIN_SHOWERS",
  MODERATE_RAIN_SHOWERS = "MODERATE_RAIN_SHOWERS",
  HEAVY_RAIN_SHOWERS = "HEAVY_RAIN_SHOWERS",
  THUNDERSTORM = "THUNDERSTORM",
  LIGHT_SLEET_SHOWERS = "LIGHT_SLEET_SHOWERS",
  MODERATE_SLEET_SHOWERS = "MODERATE_SLEET_SHOWERS",
  HEAVY_SLEET_SHOWERS = "HEAVY_SLEET_SHOWERS",
  LIGHT_SNOW_SHOWERS = "LIGHT_SNOW_SHOWERS",
  MODERATE_SNOW_SHOWERS = "MODERATE_SNOW_SHOWERS",
  HEAVY_SNOW_SHOWERS = "HEAVY_SNOW_SHOWERS",
  LIGHT_RAIN = "LIGHT_RAIN",
  MODERATE_RAIN = "MODERATE_RAIN",
  HEAVY_RAIN = "HEAVY_RAIN",
  THUNDER = "THUNDER",
  LIGHT_SLEET = "LIGHT_SLEET",
  MODERATE_SLEET = "MODERATE_SLEET",
  HEAVY_SLEET = "HEAVY_SLEET",
  LIGHT_SNOWFALL = "LIGHT_SNOWFALL",
  MODERATE_SNOWFALL = "MODERATE_SNOWFALL",
  HEAVY_SNOWFALL = "HEAVY_SNOWFALL",
}

export enum WeatherSymbolGroup {
  CLEAR_SKY = "CLEAR_SKY",
  SEMI_CLOUDY = "SEMI_CLOUDY",
  CLOUDY = "CLOUDY",
  FOG = "FOG",
  RAIN = "RAIN",
  THUNDERSTORM = "THUNDERSTORM",
  SLEET = "SLEET",
  SNOW = "SNOW",
  THUNDER = "THUNDER",
  MOON = "MOON",
  NIGHT = "NIGHT",
}
