import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { Employee } from "@app/models";
import { fadeInTrigger } from "@app/shared/animations";
import { validateEmail } from "@app/shared/modules/form-components/directives/email-validator.directive";
import { Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";
import * as userActions from "../../shared/user/user.actions";

@Component({
  selector: "login-form",
  styleUrls: ["./login-form.component.scss"],
  templateUrl: "./login-form.component.html",
  animations: [fadeInTrigger()],
})
export class LoginFormComponent implements OnInit, OnDestroy {
  @Input() showForgotPassword = true;
  @Input() showRememberMe = true;

  loginForm: FormGroup;
  employees$: Observable<Employee[]>;
  unsubscribe$ = new Subject<void>();
  isMobileAppLogin = false;

  constructor(private store: Store<AppState>, private router: Router) {}

  ngOnInit(): void {
    this.mapStateToProps();
    this.isMobileAppLogin = localStorage.getItem("mobile-redirect") === "true";

    const isRememberMeChecked =
      this.showRememberMe &&
      !!JSON.parse(localStorage.getItem("login-remember-me"));

    this.loginForm = new FormGroup({
      username: new FormControl("", [Validators.required, validateEmail()]),
      password: new FormControl("", Validators.required),
      longLivedToken: new FormControl(isRememberMeChecked),
    });

    if (this.router.url === "/admin/auth") {
      this.loginForm.get("username").setValidators(Validators.required);
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.employees$ = this.store.select(
      (state) => state.login.selectOffice.employees
    );
  }

  onSubmit(): void {
    const credentials = this.loginForm.getRawValue();
    if (this.router.url === "/admin/auth") {
      this.store.dispatch(
        // userActions.superAdminLoginRequest({
        //   credentials,
        // })
        userActions.clientLessSuperAdminLoginRequest({
          credentials: {
            username: credentials.username,
            password: credentials.password,
          },
        })
      );
    } else {
      localStorage.setItem(
        "login-remember-me",
        credentials.longLivedToken.toString()
      );
      this.store.dispatch(userActions.loginRequest({ credentials }));
    }
  }
}
