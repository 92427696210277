import { Component, Input, OnChanges, OnDestroy, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as contactNextStepActions from "@app/contacts/contact-overview/contact-next-step/contact-next-step.actions";
import * as fromContactNextStep from "@app/contacts/contact-overview/contact-next-step/contact-next-step.reducer";
import { CONTACT_NEXT_STEP_LIMIT } from "@app/contacts/contact-overview/contact-next-step/utils/contact-next-step-constants";
import { TaskTypeResolverService } from "@app/core/services/tasktype/tasktype-resolver.service";
import { Task } from "@app/models";
import { select, Store } from "@ngrx/store";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-contact-next-step-widget",
  templateUrl: "./contact-next-step-widget.component.html",
  styleUrls: ["./contact-next-step-widget.component.scss"],
})
export class ContactNextStepWidgetComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() contactId: string;
  @Input() eaEmployeeId: string;

  tasks$: Observable<Task[]>;
  tasksTotal$: Observable<number>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  remainingTasks$: Observable<number>;
  unsubscribe$ = new Subject<void>();

  currentOffset = 0;

  constructor(
    public taskTypeResolver: TaskTypeResolverService,
    private store: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnChanges(changes) {
    if (changes.contactId && this.contactId) {
      this.fetchTasks();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private mapStateToProps() {
    this.tasks$ = this.store.pipe(select(fromContactNextStep.getTasks));
    this.tasksTotal$ = this.store.pipe(select(fromContactNextStep.getTotal));
    this.loaded$ = this.store.pipe(select(fromContactNextStep.getLoaded));
    this.loading$ = this.store.pipe(select(fromContactNextStep.getLoading));
    this.remainingTasks$ = this.store.pipe(
      select(fromContactNextStep.getRemainingTasks)
    );
  }

  private fetchTasks() {
    this.currentOffset = 0;
    this.store.dispatch(
      contactNextStepActions.getNextStep(
        this.contactId,
        CONTACT_NEXT_STEP_LIMIT
      )
    );
  }

  onLoadMore() {
    this.store.dispatch(
      contactNextStepActions.getMoreNextSteps(
        this.contactId,
        CONTACT_NEXT_STEP_LIMIT,
        (this.currentOffset += CONTACT_NEXT_STEP_LIMIT)
      )
    );
  }
}
