<app-q-modal>
  <ng-container slot="header">
    {{ "create_showing" | translate }}
  </ng-container>
  <ng-container slot="body">
    <calendar-widget-modal
      [showAsRegularButton]="true"
      [date]="dateToday"
      [textOnly]="false"
    >
    </calendar-widget-modal>

    <div class="segment-controls">
      <app-segmented-controls
        [controls]="segmentControls$ | async"
        [active]="activeView"
        (change)="changeShowingType($event)"
      >
      </app-segmented-controls>
    </div>

    <form [formGroup]="form" class="form-container">
      <div *ngIf="activeView === 'public'" class="group">
        <div>
          <office-employee-dropdown
            [sideBySide]="true"
            [fullWidth]="true"
            [boldTitles]="true"
            [parentFormGroup]="form"
            parentOfficeFormControlName="eaOfficeId"
            parentEmployeeFormControlName="eaEmployeeId"
            [setDefaults]="true"
            [disableSelectOffice]="!(isAdmin$ | async)"
            [disableSelectBroker]="!(isAdmin$ | async) && !(isManager$ | async)"
          >
          </office-employee-dropdown>
        </div>
      </div>
      <div class="group">
        <div *ngIf="activeView === 'private'">
          <app-q-search-contact
            formControlName="contactId"
            [invalid]="
              form.get('contactId').touched && !form.get('contactId').valid
            "
            [readonly]="false"
          ></app-q-search-contact>
          <app-q-control-errors
            name="contactId"
            [errors]="[
              { name: 'required', text: 'validation_required', when: 'touched' }
            ]"
          ></app-q-control-errors>
        </div>
        <div>
          <app-q-search-object
            formControlName="eaOid"
            [invalid]="form.get('eaOid').touched && !form.get('eaOid').valid"
            [readonly]="false"
          ></app-q-search-object>
          <app-q-control-errors
            name="eaOid"
            [errors]="[
              { name: 'required', text: 'validation_required', when: 'touched' }
            ]"
          ></app-q-control-errors>
        </div>
      </div>
      <div class="group">
        <div>
          <app-q-date-picker
            [invalid]="form.get('date').touched && !form.get('date').valid"
            formControlName="date"
            label="date"
          ></app-q-date-picker>
        </div>
        <div class="custom-input-container">
          <app-q-input
            [invalid]="form.get('startTime').touched && (form.get('startTime').invalid || form.errors?.['invalidRange'])"
            type="time"
            [step]="300"
            formControlName="startTime"
            label="start_time"
          ></app-q-input>

          <app-q-input
            [invalid]="form.get('endTime').touched && (form.get('endTime').invalid || form.errors?.['invalidRange'])"
            type="time"
            [step]="300"
            formControlName="endTime"
            label="end_time"
          ></app-q-input>
        </div>
      </div>
      <div class="group" style="flex-flow: column">
        <app-q-control-errors
          name="date"
          [errors]="[
            { name: 'required', text: 'validation_required', when: 'touched' }
          ]"
        >
        </app-q-control-errors>
        <app-q-control-errors
          name="startTime"
          [errors]="[
            { name: 'required', text: 'validation_required', when: 'touched' }
          ]"
        ></app-q-control-errors>
        <p
          *ngIf="form.errors?.['invalidRange'] && (form.get('startTime').touched || form.get('startTime').dirty) && (form.get('date').touched || form.get('date').dirty)"
          class="q-control-error"
        >
          {{ "range_mismatch" | translate }}
        </p>
      </div>
      <div *ngIf="activeView === 'private'" class="group">
        <div *ngIf="!!(showingConfirmationEmail$ | async)?.enabled">
          <mat-checkbox
            class="default-width"
            [formControl]="form.get('sendConfirmationMessage')"
          >
            {{ "send_confirmation_email" | translate }}
          </mat-checkbox>
        </div>
        <div style="width: 80%">
          <mat-checkbox
            *ngIf="activeView === 'private'"
            class="default-width"
            [(ngModel)]="assignToOtherAgent"
            [disabled]="isEditMode"
            (change)="assignToOtherAgent = !assignToOtherAgent"
          >
            {{ "assign_to_agent" | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div
        *ngIf="!isEditMode && activeView === 'private' && assignToOtherAgent"
        class="group"
      >
        <div>
          <app-advanced-office-employee-dropdown
            [availabilityMode]="'medium'"
            [employeeFormControl]="form.get('eaEmployeeId')"
            [employeeMultiple]="false"
            [officeFormControl]="form.get('eaOfficeId')"
            [officeMultiple]="false"
            [officeRequired]="true"
            [employeeRequired]="true"
            [setDefaults]="true"
            [sideBySide]="true"
          >
          </app-advanced-office-employee-dropdown>
        </div>
      </div>
      <div class="group">
        <div style="margin-top: -16px">
          <app-q-textarea
            formControlName="description"
            [label]="'description_showing'"
            [placeholder]="'description_showing_form_field'"
          >
          </app-q-textarea>
        </div>
      </div>
      <div *ngIf="canWriteComment$ | async" class="group">
        <div style="margin-top: -16px">
          <app-q-textarea
            formControlName="comment"
            [label]="'comment_showing'"
            [placeholder]="'comment_showing_form_field'"
          >
          </app-q-textarea>
        </div>
      </div>
    </form>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        (click)="closeModal()"
        label="close"
        buttonType="cancel"
        classes="margin-right"
      ></app-q-button>
      <app-q-button
        [@fadeInUp]
        *ngIf="
          activeView === 'private' &&
          !!this.form.get('sendConfirmationMessage').value
        "
        (click)="preview()"
        label="preview"
        buttonType="cancel"
        classes="margin-right"
      >
      </app-q-button>
      <app-q-button
        (click)="submit()"
        buttonType="save"
        [label]="isEditMode ? 'save' : 'create'"
        [isLoading]="isWorking"
        [disabled]="isWorking"
      ></app-q-button>
    </div>
  </ng-container>
</app-q-modal>
