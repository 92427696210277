<div class="create-note">
  <form class="form-horizontal" [formGroup]="form">
    <div class="create-note__textarea">
      <app-rich-text-editor [control]="form.get('note')" [height]="150"
                            [placeholder]="'write_a_note' | translate" [autoFocus]="true"
                            (click)="onClick()"></app-rich-text-editor>
    </div>

    <div class="create-note__footer">
      <div *ngIf="privateEnabled$ | async" class="checkbox-wrapper">
        <mat-checkbox formControlName="private">{{ 'private' | translate }}</mat-checkbox>
      </div>
      <div class="create-note__footer--buttons">
        <app-q-button
          buttonType="cancel"
          label="cancel"
          (click)="submitPressed.emit()">
        </app-q-button>
        <app-q-button
          *ngIf="showSalesCallButton"
          buttonType="save"
          label="sales_call"
          icon="check"
          [disabled]="form.invalid"
          tooltip="{{(form.valid ? '' : 'sales_call_tooltip') | translate}}"
          (click)="storeNoteAndSalesCall()">
        </app-q-button>
        <app-q-button
          buttonType="save"
          label="save"
          [isLoading]="loading$ | async"
          [disabled]="form.invalid"
          tooltip="{{(form.valid ? '' : 'create_note_tooltip') | translate}}"
          (click)="storeNote()">
        </app-q-button>
      </div>
    </div>
  </form>
</div>
