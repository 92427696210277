import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { map, Observable, Subject } from "rxjs";
import * as fromKpi from "../../../ngrx/kpi.reducer";
import { Kpi } from "../../../models/kpi";
import { KpiUtilsService } from "../../../services/kpi-utils.service";
import { KpiDetails } from "@app/kpi/models/kpi-details";

@Component({
  selector: "kpi-chain-advanced",
  templateUrl: "./kpi-chain-advanced.component.html",
  styleUrls: [
    "../../kpi-external.component.scss",
    "./kpi-chain-advanced.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KpiChainAdvancedComponent implements OnInit, OnDestroy {
  @Input() params: Record<string, string>;

  offices$: Observable<KpiDetails[]>;
  summary$: Observable<Kpi>;
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private kpiUtils: KpiUtilsService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  mapStateToProps(): void {
    this.offices$ = this.store.pipe(
      fromKpi.getKpiDetailsByType(this.params.type),
      map((data: any) => data?.map((item: any) => new KpiDetails(item)))
    );
    this.summary$ = this.store.pipe(select(fromKpi.getSummary));
  }

  getClassNameByProgress(progress: number): string {
    return this.kpiUtils.getClassNameByProgress(progress);
  }

  showOfficeDetail(eaOfficeId: string, event: MouseEvent) {
    event.preventDefault();
    const queryParams = {
      type: this.params.type,
      from: this.params.from,
      to: this.params.to,
      offices: eaOfficeId,
    };

    this.router.navigate([], {
      queryParams,
      replaceUrl: true,
    });
  }
}
