<table class="table table--striped">
  <thead>
  <tr>
    <th translate>address</th>
    <th translate>sold_date</th>
  </tr>
  </thead>
  <tbody>
  <ng-container *ngIf="(resources$ | async)?.length > 0; else empty">
    <tr *ngFor="let resource of resources$ | async">
      <td dataLabel="address">
        <a (click)="navigateToObject(resource.eaOid, $event)" href="#">{{resource.street}}</a>
      </td>
      <td dataLabel="sold_date">{{resource.date | amParse:'YYYYMMDD' | amDateFormat: 'YYYY-MM-DD'}}</td>
    </tr>
  </ng-container>

  <ng-template #empty>
    <tr>
      <td class="text-center" colspan="100%" translate>no_objects</td>
    </tr>
  </ng-template>
  </tbody>
</table>
