import { SidebarTab } from "@app/sidebar/models/sidebar-tab";
import {
  CONTACT_CREATE,
  CONTACT_CREATE_POTENTIAL_BUYER,
  CONTACT_CREATE_SELLER_BUYER,
  CONTACT_EDIT,
  CREATE_FOLLOW_UP,
  EMPLOYEES,
  MANAGE_POTENTIAL_BUYER_MULTIPLE,
  MANAGE_POTENTIAL_BUYER_SINGLE,
  RESIDENCE,
  SALES_MEETING,
  SEND_MESSAGE,
  SEND_TIPS,
  TASK,
} from "./tab-types";

export const ROOT_SIDEBAR_ROUTE = "root";

export const SIDEBAR_SEND_MESSAGE_URL = [ROOT_SIDEBAR_ROUTE, "send-message"];
export const SIDEBAR_SEND_EXTERNAL_TIPS_URL = [
  ROOT_SIDEBAR_ROUTE,
  "external-tips",
  "send",
];
export const SIDEBAR_EXTERNAL_TIPS_SEARCH_URL = [
  ROOT_SIDEBAR_ROUTE,
  "external-tips",
  "search",
];

export const SIDEBAR_CREATE_IN_EXTERNAL_PROVIDER_URL = [
  ROOT_SIDEBAR_ROUTE,
  "external-provider",
  "new",
];
export const SIDEBAR_CONNECT_IN_EXTERNAL_PROVIDER_URL = [
  ROOT_SIDEBAR_ROUTE,
  "external-provider",
  "connect",
];

export const SIDEBAR_CALLING_LISTS_BASE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "calling-lists",
];
export const SIDEBAR_CREATE_OBJECT_BASE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "create-object",
];

export const SIDEBAR_CONTACTS_BASE_URL = [ROOT_SIDEBAR_ROUTE, "contacts"];
export const SIDEBAR_CONTACTS_CREATE_URL = [
  ...SIDEBAR_CONTACTS_BASE_URL,
  "new",
];
export const SIDEBAR_POTENTIAL_BUYER_CREATE_URL = [
  ...SIDEBAR_CONTACTS_BASE_URL,
  "potential-buyer",
  "new",
];
export const SIDEBAR_SELLER_OR_BUYER_CREATE_URL = (contactType) => {
  return [...SIDEBAR_CONTACTS_BASE_URL, contactType, "new"];
};

export const SIDEBAR_CONTACTS_EDIT_URL = (contactId: string) => {
  return [...SIDEBAR_CONTACTS_BASE_URL, contactId, "edit"];
};

export const SIDEBAR_CONTACT_RESIDENCES_BASE_URL = (contactId: string) => [
  ROOT_SIDEBAR_ROUTE,
  "contacts",
  contactId,
  "residences",
];
export const SIDEBAR_CONTACT_RESIDENCES_CREATE_URL = (contactId: string) => [
  ...SIDEBAR_CONTACT_RESIDENCES_BASE_URL(contactId),
  "new",
];
export const SIDEBAR_CONTACT_RESIDENCES_EDIT_URL = (
  contactId: string,
  eaOid: string
) => {
  return [...SIDEBAR_CONTACT_RESIDENCES_BASE_URL(contactId), eaOid, "edit"];
};

export const SIDEBAR_TIPS_BASE_URL = [ROOT_SIDEBAR_ROUTE, "tips"];
export const SIDEBAR_TIPS_CONTACTS_CREATE_URL = [
  ...SIDEBAR_TIPS_BASE_URL,
  "contacts",
  "new",
];
export const SIDEBAR_TIPS_CREATE_URL = (contactId: string) => {
  return [...SIDEBAR_TIPS_BASE_URL, "new", "contacts", contactId];
};

export const SIDEBAR_CREATE_LEAD_BASE_URL = [ROOT_SIDEBAR_ROUTE, "create-lead"];
export const SIDEBAR_CREATE_LEAD_CONTACTS_CREATE_URL = [
  ...SIDEBAR_CREATE_LEAD_BASE_URL,
  "contacts",
  "new",
];
export const SIDEBAR_CREATE_LEAD_CREATE_URL = (contactId: string) => {
  return [...SIDEBAR_CREATE_LEAD_BASE_URL, "new", "contacts", contactId];
};

export const SIDEBAR_SEARCH_PROFILE_BASE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "search-profile",
];
export const SIDEBAR_SEARCH_PROFILE_EDIT_URL = (searchProfileId) => {
  return [...SIDEBAR_SEARCH_PROFILE_BASE_URL, searchProfileId, "edit"];
};
export const SIDEBAR_SEARCH_PROFILE_CREATE_URL = [
  ...SIDEBAR_SEARCH_PROFILE_BASE_URL,
  "new",
];

export const SIDEBAR_SEARCH_PROFILE_ALERT_CREATE_URL = (searchProfileId) => {
  return [...SIDEBAR_SEARCH_PROFILE_BASE_URL, searchProfileId, "alert"];
};

export const SIDEBAR_FOLLOW_UPS_BASE_URL = [ROOT_SIDEBAR_ROUTE, "follow-ups"];
export const SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL = [
  ...SIDEBAR_FOLLOW_UPS_BASE_URL,
  "contacts",
  "new",
];
export const SIDEBAR_FOLLOW_UPS_CREATE_URL = (contactId: string) => {
  return [...SIDEBAR_FOLLOW_UPS_BASE_URL, "new", "contacts", contactId];
};

export const SIDEBAR_TASKS_BASE_URL = [ROOT_SIDEBAR_ROUTE, "tasks"];
export const SIDEBAR_TASKS_DYNAMIC_SEGMENT_URL = (segment, eaTaskId) => {
  return [...SIDEBAR_TASKS_BASE_URL, segment, eaTaskId];
};
export const SIDEBAR_TASKS_DEFAULT_URL = (eaTaskId) => {
  return [...SIDEBAR_TASKS_BASE_URL, eaTaskId];
};

export const SIDEBAR_SALES_MEETING_BASE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "sales-meetings",
];
export const SIDEBAR_SALES_MEETING_CONTACTS_CREATE_URL = [
  ...SIDEBAR_SALES_MEETING_BASE_URL,
  "contacts",
  "new",
];
export const SIDEBAR_SALES_MEETING_CREATE_URL = (contactId: string) => {
  return [...SIDEBAR_CONTACTS_BASE_URL, contactId, "sales-meetings", "new"];
};
export const SIDEBAR_SALES_MEETING_EDIT_URL = (contactId, salesMeetingId) => {
  return [
    ...SIDEBAR_CONTACTS_BASE_URL,
    contactId,
    "sales-meetings",
    salesMeetingId,
    "edit",
  ];
};

export const SIDEBAR_COMPETITIONS_BASE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "competitions",
];
export const SIDEBAR_COMPETITIONS_CREATE_URL = [
  ...SIDEBAR_COMPETITIONS_BASE_URL,
  "new",
];

export const SIDEBAR_COMPETITIONS_EDIT_URL = (competitionId) => {
  return [...SIDEBAR_COMPETITIONS_BASE_URL, competitionId, "edit"];
};

// MANAGE POTENTIAL BUYERS
export const SIDEBAR_MANAGE_POTENTIAL_BUYER_SINGLE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "potential-buyer",
];
export const SIDEBAR_MANAGE_POTENTIAL_BUYER_MULTIPLE_URL = [
  ROOT_SIDEBAR_ROUTE,
  "potential-buyers",
];

// TODO not yet implemented
export const SIDEBAR_EMPLOYEES_BASE_URL = [ROOT_SIDEBAR_ROUTE, EMPLOYEES];
export const SIDEBAR_EMPLOYEES_SEARCH_URL = [
  ...SIDEBAR_EMPLOYEES_BASE_URL,
  "search",
];
export const SIDEBAR_EMPLOYEES_OFFICE_SEARCH_URL = (eaOfficeId: string) => [
  ...SIDEBAR_EMPLOYEES_BASE_URL,
  "offices",
  eaOfficeId,
  "search",
];
export const SIDEBAR_EMPLOYEES_OFFICE_CREATE_URL = (eaOfficeId: string) => [
  ...SIDEBAR_EMPLOYEES_BASE_URL,
  "offices",
  eaOfficeId,
  "create",
];
export const SIDEBAR_EMPLOYEE_OFFICE_EMPLOYMENT_URL = (
  eaEmployeeId: string,
  eaOfficeId: string
) => [...SIDEBAR_EMPLOYEES_BASE_URL, eaEmployeeId, "offices", eaOfficeId];

export const SIDEBAR_ERP_SHOWINGS_URL = [ROOT_SIDEBAR_ROUTE, "erp-showings"];
export function getSidebarTabUrlByTabType(
  tabType: string,
  routeParams: any = {}
) {
  const { contactId } = routeParams;

  switch (tabType) {
    case CREATE_FOLLOW_UP:
      return SIDEBAR_FOLLOW_UPS_BASE_URL;

    case CONTACT_CREATE:
      return SIDEBAR_CONTACTS_CREATE_URL;

    case CONTACT_CREATE_POTENTIAL_BUYER:
      return SIDEBAR_POTENTIAL_BUYER_CREATE_URL;

    case CONTACT_CREATE_SELLER_BUYER:
      return SIDEBAR_SELLER_OR_BUYER_CREATE_URL("seller");

    case CONTACT_EDIT:
      return SIDEBAR_CONTACTS_EDIT_URL(contactId);

    case RESIDENCE:
      return SIDEBAR_CONTACT_RESIDENCES_BASE_URL(contactId);

    case SEND_TIPS:
      return SIDEBAR_TIPS_BASE_URL;

    case TASK:
      return SIDEBAR_TIPS_BASE_URL;

    case SALES_MEETING:
      return SIDEBAR_SALES_MEETING_CREATE_URL(contactId);

    case EMPLOYEES:
      return SIDEBAR_EMPLOYEES_BASE_URL;

    case SEND_MESSAGE:
      return SIDEBAR_SEND_MESSAGE_URL;

    case MANAGE_POTENTIAL_BUYER_SINGLE:
      return SIDEBAR_MANAGE_POTENTIAL_BUYER_SINGLE_URL;

    case MANAGE_POTENTIAL_BUYER_MULTIPLE:
      return SIDEBAR_MANAGE_POTENTIAL_BUYER_MULTIPLE_URL;
  }
}

export function getSidebarTabByTabType(
  tabType: string,
  routeParams: any = {}
): SidebarTab {
  switch (tabType) {
    default:
      return new SidebarTab(
        tabType,
        getSidebarTabUrlByTabType(tabType, routeParams)
      );
  }
}
