<form [formGroup]="alertForm">
  <div class="form-group">
    <h4 translate>choose_time_for_search_profile_alert</h4>
    <div *ngFor="let activity of alertActivities">
      <label class="radio-button-group">
        {{ activity.name }}
        <input
          [value]="activity.activityId"
          class="radio-input"
          formControlName="activityId"
          type="radio"
        />
        <span class="radio-button-group__checkmark"></span>
      </label>
    </div>
    <div ngxErrors="activityId">
      <div translate ngxError="required" showWhen="touched">
        validation_required
      </div>
    </div>
  </div>
  <hr />

  <ng-container
    *ngIf="!!(searchProfileFeature$ | async)?.enableSendAlertToAgent"
  >
    <div class="form-group">
      <h4 translate>send_alert_to</h4>
      <app-segmented-controls
        [controls]="receiverControls"
        [active]="selectedSendToSegment"
        (change)="sendToSegmentChanged($event)"
      ></app-segmented-controls>
    </div>
    <hr />
  </ng-container>
  <ng-container *ngIf="!alertForm.get('sendAlertToResponsibleEmployee').value">
    <div class="form-group">
      <h4 translate>choose_employee_for_search_profile_alert</h4>
      <app-mobile-card
        *ngIf="
          alert &&
          alert.eaEmployeeId &&
          alert.employeeFullName &&
          !editResponsibleBroker
        "
        [shadow]="false"
        size="SLIM"
        [showCross]="true"
        (remove)="removeResponsibleEmployee()"
      >
        <div title>
          {{ alert.employeeFullName }}
        </div>
      </app-mobile-card>
      <app-advanced-office-employee-dropdown
        *ngIf="editResponsibleBroker || !alert?.eaEmployeeId"
        [setDefaults]="true"
        [sideBySide]="false"
        [officeRequired]="true"
        [employeeRequired]="true"
        [officeFormControl]="alertForm.get('responsibleEmployee').get('office')"
        [employeeFormControl]="
          alertForm.get('responsibleEmployee').get('employee')
        "
        [availabilityMode]="'restrictive'"
        [officeMultiple]="false"
        [employeeMultiple]="false"
      ></app-advanced-office-employee-dropdown>
    </div>
    <hr />
  </ng-container>
  <div *ngIf="hasActivityIdSelected()" class="form-group">
    <label class="checkbox-group">
      <span translate>send_confirmation_email</span>
      <input type="checkbox" formControlName="sendConfirmationMail" />
      <span class="checkbox-group__checkmark"></span>
    </label>
    <app-q-button
      *ngIf="showPreviewButton"
      buttonType="save"
      label="preview"
      (click)="handlePreview()"
    >
    </app-q-button>
  </div>
</form>
