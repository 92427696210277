import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { getLanguageModificationLayers } from "@app/shared/config/config.reducer";
import { select, Store } from "@ngrx/store";

@Injectable({ providedIn: "root" })
export class TranslationsModule {
  constructor(private store: Store<AppState>) {}

  getLanguageModificationLayers() {
    return this.store.pipe(select(getLanguageModificationLayers));
  }
}
