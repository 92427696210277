import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { SendMessageService } from "@app/sidebar/send-message/send-message.service";
import { TranslateModule } from "@ngx-translate/core";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { SidebarSharedModule } from "../shared/sidebar-shared.module";
import { SendMessageContainerComponent } from "./send-message-container/send-message-container.component";
import { SendMessageFormComponent } from "@app/sidebar/send-message/components/send-message-form/send-message-form.component";
import { AddObjectFilesModalComponent } from "@app/sidebar/send-message/components/add-object-files-modal/add-object-files-modal.component";
import { AddObjectLinksModalComponent } from "@app/sidebar/send-message/components/add-object-links-modal/add-object-links-modal.component";
import { SelectObjectComponent } from "@app/sidebar/send-message/components/select-object/select-object.component";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SidebarSharedModule,
    SearchContactModule,
    UiComponentsModule,
    FormComponentsModule,
    TooltipModule,
    UtilModule,
    MatTooltipModule,
  ],
  declarations: [
    SendMessageContainerComponent,
    SendMessageFormComponent,
    AddObjectFilesModalComponent,
    AddObjectLinksModalComponent,
    SelectObjectComponent,
  ],
  providers: [SendMessageService],
})
export class SendMessageModule {}
