import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { danger, success } from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { PaginationListDTO } from "@app/models";
import * as fromUser from "@app/shared/user";
import { UserIds } from "@app/shared/user";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { catchError, map, of, switchMap, tap, withLatestFrom } from "rxjs";
import * as noteActions from "@app/sidebar/potential-buyer/ngrx/note/note.actions";

@Injectable()
export class NoteEffects {
  fetchNoteRequest$: Action;

  fetchNotes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteActions.fetchNoteRequest),
      tap((action) => {
        this.fetchNoteRequest$ = action;
      }),
      switchMap(({ params }) =>
        this.api
          .get(`notes/for-contact/${params.contactId}/search`, {
            eaOid: params.eaOid,
          })
          .pipe(
            map((response: PaginationListDTO) =>
              noteActions.fetchNoteSuccess({ payload: response?.rows[0] })
            ),
            catchError(() => of(noteActions.fetchNoteFailure()))
          )
      )
    )
  );

  createNoteRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteActions.createNoteRequest),
      withLatestFrom(
        this.store.pipe(
          fromUser.getEaIds,
          map((userIds) => userIds as UserIds)
        )
      ),
      switchMap(([{ params }, userIds]) => {
        const body = { ...params, ...userIds };
        return this.api.post("notes", body).pipe(
          map(() => noteActions.createNoteSuccess()),
          catchError(() => of(noteActions.createNoteFailure()))
        );
      })
    )
  );

  createNoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteActions.createNoteSuccess),
      map(() => {
        const resource = this.translate.instant("note");
        return this.translate.instant("resource_created", { resource });
      }),
      map((message) => {
        this.store.dispatch(this.fetchNoteRequest$);
        return success({ message });
      })
    )
  );

  createNoteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(noteActions.createNoteFailure),
      map(() => {
        const note = this.translate.instant("note");
        return this.translate.instant("resource_creation_failed", {
          resource: note.toLowerCase(),
        });
      }),
      map((message) => danger({ message }))
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private store: Store<AppState>,
    private translate: TranslateService
  ) {}
}
