import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { AuthService } from "@app/core/services/auth/auth.service";
import { LocalStorageService } from "@app/core/services/local-storage/local-storage.service";
import * as loginActions from "@app/login/ngrx/login.actions";
import { LOGIN_TYPES } from "@app/login/ngrx/login.effects";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "@app/shared/utils/roles";
import { Store } from "@ngrx/store";
import { map } from "rxjs";

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {}

  canActivate() {
    return this.store
      .select((state) => state.login.loginMethod)
      .pipe(
        map((loginMethod) => {
          if (
            loginMethod.isLoaded &&
            loginMethod.supportedLoginType === LOGIN_TYPES.SSO_OAUTH
          ) {
            this.store.dispatch(
              loginActions.triggerLoginMethodRedirect({
                redirectUrl: loginMethod.redirectUrl,
              })
            );
            return false;
          }

          if (!!this.authService.isAuthenticated()) {
            const url = this.localStorageService.fetchUserPreference(
              "redirect_after_login_url"
            );

            if (url && url.length > 0) {
              this.localStorageService.saveUserPreference(
                "redirect_after_login_url",
                ""
              );
              this.store.dispatch(RouterActions.goByUrl({ url }));
            } else {
              this.store.dispatch(
                RouterActions.go({ path: ["/crm", "dashboard", "todo-list"] })
              );
            }
            return false;
          }

          return (
            !this.authService.isAuthenticated() ||
            this.authService.hasRole(ROLE_SUPER_ADMIN) ||
            this.authService.hasRole(ROLE_ADMIN)
          );
        })
      );
  }
}
