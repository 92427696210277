import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { ContactComponentsModule } from "@app/shared/modules/contact-components/contacts-components.module";
import { FormComponentsModule } from "@app/shared/modules/form-components/form-components.module";
import { SearchAddressModule } from "@app/shared/modules/search-address/search-address.module";
import { SearchContactModule } from "@app/shared/modules/search-contact/search-contact.module";
import { SkeletonScreenModule } from "@app/shared/modules/skeleton-screen/skeleton-screen.module";
import { UiComponentsModule } from "@app/shared/modules/ui-components/ui-components.module";
import { UtilModule } from "@app/shared/modules/util/util.module";
import { CreateContactModule } from "@app/sidebar/contacts/create-contact.module";
import { SendTipToEikaFormComponent } from "@app/sidebar/eika/components/send-tip-to-eika-form/send-tip-to-eika-form.component";
import { EikaCreateContactComponent } from "@app/sidebar/eika/containers/eika-create-contact/eika-create-contact.component";
import { EikaComponent } from "@app/sidebar/eika/containers/eika/eika.component";
import { SelectContactComponent } from "@app/sidebar/eika/containers/select-contact/select-contact.component";
import { SidebarSharedModule } from "@app/sidebar/shared/sidebar-shared.module";
import { TranslateModule } from "@ngx-translate/core";
import { TypeaheadModule } from "ngx-bootstrap/typeahead";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SidebarSharedModule,
    ReactiveFormsModule,
    FormsModule,
    FormComponentsModule,
    UtilModule,
    UiComponentsModule,
    SkeletonScreenModule,
    SearchAddressModule,
    SearchContactModule,
    TypeaheadModule,
    CreateContactModule,
    ContactComponentsModule,
  ],
  declarations: [
    EikaComponent,
    SendTipToEikaFormComponent,
    SelectContactComponent,
    EikaCreateContactComponent,
  ],
})
export class EikaModule {}
