<div
  [class.side-by-side]="sideBySide"
  [class.full-width]="fullWidth"
  class="form-group emp-office-container"
>
  <span translate [class.bold-titles]="boldTitles">office</span>
  <office-dropdown
    [offices]="offices$ | async"
    [parentFormGroup]="parentFormGroup"
    [parentFormControlName]="parentOfficeFormControlName"
    [disableSelectOffice]="disableSelectOffice"
    [extraOfficeProperty]="extraOfficeProperty"
    (extraOfficePropertyReady)="extraOfficePropertyReady.emit($event)"
  ></office-dropdown>
  <div [ngxErrors]="getFullControlName(parentOfficeFormControlName)">
    <div translate ngxError="required" showWhen="touched">
      validation_required
    </div>
  </div>
</div>
<div
  [class.side-by-side]="sideBySide"
  [class.full-width]="fullWidth"
  class="form-group emp-office-container"
>
  <span translate [class.bold-titles]="boldTitles">broker</span>
  <employee-dropdown
    [employees]="employees$ | async"
    [parentFormGroup]="parentFormGroup"
    [parentFormControlName]="parentEmployeeFormControlName"
    [disableSelectBroker]="disableSelectBroker"
    [extraEmployeeProperty]="extraEmployeeProperty"
    (extraEmployeePropertyReady)="extraEmployeePropertyReady.emit($event)"
  ></employee-dropdown>
  <div [ngxErrors]="getFullControlName(parentEmployeeFormControlName)">
    <div translate ngxError="required" showWhen="touched">
      validation_required
    </div>
  </div>
</div>
