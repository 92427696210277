import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Employee } from "@app/models";

@Component({
  selector: "search-employee-card",
  templateUrl: "./employee-card.component.html",
  styleUrls: ["./employee-card.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchEmployeeCardComponent {
  @Input() employee: Employee;

  getOfficeNames(offices: string): string[] {
    return offices ? offices.split(",") : [];
  }
}
