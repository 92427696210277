import { Contact, QObject } from "@app/models";
import { BankAdvisor } from "@app/settings/banks/manage-banks/models/bank-advisor";
import { ExternalBank } from "@app/settings/banks/manage-banks/models/external-bank";
import { createAction, props } from "@ngrx/store";

export const getBanksRequest = createAction("[EIKA SIDEBAR] GET_BANKS_REQUEST");

export const getBanksSuccess = createAction(
  "[EIKA SIDEBAR] GET_BANKS_SUCCESS",
  props<{ externalBanks: ExternalBank[] }>()
);

export const getBanksFail = createAction("[EIKA SIDEBAR] GET_BANKS_FAIL");

export const getAdvisorsRequest = createAction(
  "[EIKA SIDEBAR] GET_ADVISORS_REQUEST",
  props<{ bankId: string }>()
);

export const getAdvisorsSuccess = createAction(
  "[EIKA SIDEBAR] GET_ADVISORS_SUCCESS",
  props<{ bankAdvisors: BankAdvisor[] }>()
);

export const getAdvisorsFail = createAction("[EIKA SIDEBAR] GET_ADVISORS_FAIL");

export const getContactRequest = createAction(
  "[EIKA SIDEBAR] GET_CONTACT_REQUEST",
  props<{ contactId: string }>()
);

export const getContactSuccess = createAction(
  "[EIKA SIDEBAR] GET_CONTACT_SUCCESS",
  props<{ contact: Contact }>()
);

export const getContactFail = createAction("[EIKA SIDEBAR] GET_CONTACT_FAIL");

export const getObjectRequest = createAction(
  "[EIKA SIDEBAR] GET_OBJECT_REQUEST",
  props<{ eaOid: string }>()
);

export const getObjectSuccess = createAction(
  "[EIKA SIDEBAR] GET_OBJECT_SUCCESS",
  props<{ qObject: QObject }>()
);

export const getObjectFail = createAction("[EIKA SIDEBAR] GET_OBJECT_FAIL");

export const sendTipRequest = createAction(
  "[EIKA SIDEBAR] SEND_TIP_REQUEST",
  props<{
    params: {
      ids: {
        bank: string;
        advisor?: string;
        contactId: string;
        eaOid?: string;
      };
      body: {
        note?: string;
        origin?: string;
        urgent: boolean;
      };
    };
  }>()
);

export const sendTipSuccess = createAction("[EIKA SIDEBAR] SEND_TIP_SUCCESS");

export const sendTipFail = createAction("[EIKA SIDEBAR] SEND_TIP_FAIL");

export const createContactRequest = createAction(
  "[EIKA SIDEBAR] CREATE_CONTACT_REQUEST",
  props<{ params: Partial<Contact> }>()
);

export const createContactSuccess = createAction(
  "[EIKA SIDEBAR] CREATE_CONTACT_SUCCESS"
);

export const createContactFail = createAction(
  "[EIKA SIDEBAR] CREATE_CONTACT_FAIL"
);

export const updateContactRequest = createAction(
  "[EIKA SIDEBAR] UPDATE_CONTACT_REQUEST",
  props<{
    params: Partial<Contact>;
    contactId: string;
  }>()
);

export const updateContactSuccess = createAction(
  "[EIKA SIDEBAR] UPDATE_CONTACT_SUCCESS",
  props<{ contact: Contact }>()
);

export const updateContactFail = createAction(
  "[EIKA SIDEBAR] UPDATE_CONTACT_FAIL"
);

export const getOfficeObjectsForSaleRequest = createAction(
  "[EIKA SIDEBAR] GET_OFFICE_OBJECTS_FOR_SALE_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getOfficeObjectsForSaleSuccess = createAction(
  "[EIKA SIDEBAR] GET_OFFICE_OBJECTS_FOR_SALE_SUCCESS",
  props<{ payload: QObject[] }>()
);

export const getOfficeObjectsForSaleFail = createAction(
  "[EIKA SIDEBAR] GET_OFFICE_OBJECTS_FOR_SALE_FAIL"
);

export const clear = createAction("[EIKA SIDEBAR] CLEAR");
