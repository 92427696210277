import { EmployeeSalesTarget, SalesTargetTotal } from "@app/models";
import { BudgetTargetTotal } from "@app/models/budget-targets";
import { NewRecuitSalesTarget } from "@app/models/newrecuit-sales-target";
import { BudgetStatisticsResponseMonth } from "@app/statistics/statistics-budget/models/budget-statistics-response";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as salesTarget from "./manage-sales-target.actions";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface SalesTargetState {
  employees: EmployeeSalesTarget[];
  newRecruits: NewRecuitSalesTarget[];
  budgetTargetTotals: BudgetTargetTotal;
  salesTargetTotals: SalesTargetTotal;
  monthPermission: boolean;
  officePermission: string;
  loading: boolean;
  loaded: boolean;
  calculating: boolean;
  storingNewRecruit: boolean;
  updatingNewRecruit: boolean;
  deletingNewRecruit: boolean;
  hiringNewRecruit: boolean;
  editPermissionSetting: string;
  loadingEditPermissionSettings: boolean;
  storingEditPermissionSettings: boolean;
  loadingEditMonthPermissionSettings: boolean;
  months: BudgetStatisticsResponseMonth[];
  storingEditMonthPermissionSetting: boolean;
  storingBudgetAndSalesTarget: boolean;
}

export const initialState: SalesTargetState = {
  employees: [],
  newRecruits: [],
  budgetTargetTotals: {
    totalTargetsValue: 0,
    averageAmount: 0,
    quantity: 0,
    budgetMonthlyOverviewTurnover: "0",
    budgetMonthlyOverviewNumberOfSoldObjects: "0",
    amount: 0,
    employees: {
      totalTargetsValue: 0,
      averageAmount: 0,
      quantity: 0,
      amount: 0,
    },
    newRecruits: {
      totalTargetsValue: 0,
      averageAmount: 0,
      quantity: 0,
      amount: 0,
    },
  },
  salesTargetTotals: {
    bookedSalesMeeting: 0,
    wonSalesMeeting: 0,
    madeSalesCalls: 0,
    followedUpPotentialBuyers: 0,
    soldObjects: 0,
    commissionsEarnings: 0,
  },
  monthPermission: false,
  officePermission: "",
  loaded: false,
  loading: false,
  calculating: false,
  storingNewRecruit: false,
  updatingNewRecruit: false,
  deletingNewRecruit: false,
  hiringNewRecruit: false,
  editPermissionSetting: "",
  loadingEditPermissionSettings: false,
  storingEditPermissionSettings: false,
  loadingEditMonthPermissionSettings: false,
  months: [],
  storingEditMonthPermissionSetting: false,
  storingBudgetAndSalesTarget: false,
};

export function salesTargetReducer(
  state: SalesTargetState,
  action: Action
): SalesTargetState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(salesTarget.getSalesTargetsRequest, (state) => ({
    ...state,
    employees: [],
    loading: true,
    loaded: false,
  })),
  on(
    salesTarget.getSalesTargetsSuccess,
    (state, { salesTargets, loadedFromPreviousMonth }) => {
      const newState = {
        ...state,
        employees: salesTargets.employees.map(
          (e: EmployeeSalesTarget) =>
            new EmployeeSalesTarget(
              e.eaEmployeeId,
              e.employeeFullName,
              e.salesTargets.salesTargets,
              e.budgetTargets,
              e.comparisonOfCommissions
            )
        ),
        newRecruits: salesTargets.newRecruits.map(
          (nr: NewRecuitSalesTarget) =>
            new NewRecuitSalesTarget(
              nr.recruitId,
              nr.recruitName,
              nr.targetTotal,
              nr.targets
            )
        ),
        budgetTargetTotals: salesTargets.budgetTargetsTotal,
        salesTargetTotals: salesTargets.salesTargetTotals,
        loading: false,
        loaded: true,
      };

      if (!loadedFromPreviousMonth) {
        newState["monthPermission"] =
          salesTargets.budgetEditPermissions.monthPermission;
        newState["officePermission"] =
          salesTargets.budgetEditPermissions.officePermission;
      }

      return newState;
    }
  ),
  on(salesTarget.getSalesTargetsFailed, () => ({ ...initialState })),
  on(salesTarget.calculateBudgetTargetRequest, (state) => ({
    ...state,
    calculating: true,
  })),
  on(
    salesTarget.calculateBudgetTargetSuccess,
    (state, { salesTargets, budgetTargets }) => {
      const newEmployeesState = [];
      const newRecruitsState = [];
      const stateBudgetTargetTotals = JSON.parse(
        JSON.stringify(state.budgetTargetTotals)
      );
      stateBudgetTargetTotals.totalTargetsValue =
        salesTargets.totalTargetsTotal;

      state.employees.forEach((employee) => {
        const stateEmployee = JSON.parse(JSON.stringify(employee));
        const calculatedEmployee = salesTargets.employees.find(
          (e) => e.eaEmployeeId === stateEmployee.eaEmployeeId
        );
        if (calculatedEmployee) {
          stateEmployee.budgetTargets.targetTotal.totalTargetsValue =
            calculatedEmployee.totalTargetsTotal;

          const target = (
            budgetTargets.budgetTargets as Array<Record<string, unknown>>
          ).filter((t) => t.eaEmployeeId === employee.eaEmployeeId);
          if (target) {
            stateEmployee.budgetTargets.targets.forEach((t) => {
              const foundTarget = target.find(
                (t2) => t2.targetTypeId === t.targetTypeId
              );
              if (foundTarget) {
                if (
                  foundTarget.budgetTarget["budgetTargetType"] ===
                  "quantityAndAverageAmountPerQuantity"
                ) {
                  t.targetValue.averageAmountPerQuantity =
                    foundTarget.budgetTarget["averageAmountPerQuantity"];
                  t.targetValue.quantity = foundTarget.budgetTarget["quantity"];
                } else {
                  t.targetValue.amount = foundTarget.budgetTarget["amount"];
                }
              } else {
                t.targetValue.quantity = null;
                t.targetValue.averageAmountPerQuantity = null;
                t.targetValue.amount = null;
              }
            });
          }
        }
        newEmployeesState.push(stateEmployee);
      });

      state.newRecruits.forEach((recruit) => {
        const stateRecruit = JSON.parse(JSON.stringify(recruit));
        const calculatedNewRecruit = salesTargets.newRecruits.find(
          (nr) => nr.recruitId === stateRecruit.recruitId
        );
        if (calculatedNewRecruit) {
          stateRecruit.targetTotal.totalTargetsValue =
            calculatedNewRecruit.totalTargetsTotal;

          const target = (
            budgetTargets.budgetTargets as Array<Record<string, unknown>>
          ).filter((t) => t.recruitId === recruit.recruitId);
          if (target) {
            stateRecruit.targets.forEach((t) => {
              const foundTarget = target.find(
                (t2) => t2.targetTypeId === t.targetTypeId
              );
              if (foundTarget) {
                if (
                  foundTarget.budgetTarget["budgetTargetType"] ===
                  "quantityAndAverageAmountPerQuantity"
                ) {
                  t.targetValue.averageAmountPerQuantity =
                    foundTarget.budgetTarget["averageAmountPerQuantity"];
                  t.targetValue.quantity = foundTarget.budgetTarget["quantity"];
                } else {
                  t.targetValue.amount = foundTarget.budgetTarget["amount"];
                }
              } else {
                t.targetValue.quantity = null;
                t.targetValue.averageAmountPerQuantity = null;
                t.targetValue.amount = null;
              }
            });
          }
        }
        newRecruitsState.push(stateRecruit);
      });

      return {
        ...state,
        budgetTargetTotals: stateBudgetTargetTotals,
        employees: newEmployeesState,
        newRecruits: newRecruitsState,
        calculating: false,
      };
    }
  ),
  on(salesTarget.storeNewRecruitRequest, (state) => ({
    ...state,
    storingNewRecruit: true,
  })),
  on(
    salesTarget.storeNewRecruitSuccess,
    salesTarget.storeNewRecruitFailed,
    (state) => ({
      ...state,
      storingNewRecruit: false,
    })
  ),
  on(salesTarget.updateNewRecruitRequest, (state) => ({
    ...state,
    updatingNewRecruit: true,
  })),
  on(
    salesTarget.updateNewRecruitSuccess,
    salesTarget.updateNewRecruitFailed,
    (state) => ({
      ...state,
      updatingNewRecruit: false,
    })
  ),
  on(salesTarget.deleteNewRecruitRequest, (state) => ({
    ...state,
    deletingNewRecruit: true,
  })),
  on(
    salesTarget.deleteNewRecruitSuccess,
    salesTarget.deleteNewRecruitFailed,
    (state) => ({
      ...state,
      deletingNewRecruit: false,
    })
  ),
  on(salesTarget.hireNewRecruitRequest, (state) => ({
    ...state,
    hiringNewRecruit: true,
  })),
  on(
    salesTarget.hireNewRecruitSuccess,
    salesTarget.hireNewRecruitFailed,
    (state) => ({
      ...state,
      hiringNewRecruit: false,
    })
  ),
  on(salesTarget.getEditPermissionsRequest, (state) => ({
    ...state,
    loadingEditPermissionSettings: true,
  })),
  on(salesTarget.getEditPermissionsSuccess, (state, { officePermission }) => ({
    ...state,
    editPermissionSetting: officePermission,
    loadingEditPermissionSettings: false,
  })),
  on(salesTarget.getEditPermissionsFailed, (state) => ({
    ...state,
    loadingEditPermissionSettings: false,
  })),
  on(salesTarget.storeEditPermissionsRequest, (state) => ({
    ...state,
    storingEditPermissionSettings: true,
  })),
  on(
    salesTarget.storeEditPermissionsSuccess,
    salesTarget.storeEditPermissionsFailed,
    (state) => ({
      ...state,
      storingEditPermissionSettings: false,
    })
  ),
  on(salesTarget.getEditMonthPermissionsRequest, (state) => ({
    ...state,
    loadingEditMonthPermissionSettings: true,
  })),
  on(salesTarget.getEditMonthPermissionsSuccess, (state, { monthBudget }) => ({
    ...state,
    months: monthBudget,
    loadingEditMonthPermissionSettings: false,
  })),
  on(salesTarget.getEditMonthPermissionsFailed, (state) => ({
    ...state,
    loadingEditMonthPermissionSettings: false,
  })),
  on(salesTarget.storeEditMonthPermissionsRequest, (state) => ({
    ...state,
    storingEditMonthPermissionSetting: true,
  })),
  on(
    salesTarget.storeEditMonthPermissionsSuccess,
    salesTarget.storeEditMonthPermissionsFailed,
    (state) => ({
      ...state,
      storingEditMonthPermissionSetting: false,
    })
  ),
  on(salesTarget.updateBudgetAndSalesTargetRequest, (state) => ({
    ...state,
    storingBudgetAndSalesTarget: true,
  })),
  on(
    salesTarget.updateBudgetAndSalesTargetSuccess,
    salesTarget.updateBudgetAndSalesTargetFailed,
    (state) => ({
      ...state,
      storingBudgetAndSalesTarget: false,
    })
  )
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.salesTargets
);

export const getEmployeesSalesTargets = createSelector(
  getState,
  (state: SalesTargetState) => state.employees
);

export const getNewRecruitsSalesTargets = createSelector(
  getState,
  (state: SalesTargetState) => state.newRecruits
);

export const getSalesTargetTotal = createSelector(
  getState,
  (state: SalesTargetState) => state.salesTargetTotals
);

export const getBudgetTargetTotal = createSelector(
  getState,
  (state: SalesTargetState) => state.budgetTargetTotals
);

export const getMonthPermission = createSelector(
  getState,
  (state: SalesTargetState) => state.monthPermission
);

export const getOfficePermission = createSelector(
  getState,
  (state: SalesTargetState) => state.officePermission
);

export const getEmployeesSalesTargetsLoading = createSelector(
  getState,
  (state: SalesTargetState) => state.loading
);

export const getCalculating = createSelector(
  getState,
  (state: SalesTargetState) => state.calculating
);

export const getNewRecruitStoring = createSelector(
  getState,
  (state: SalesTargetState) => state.storingNewRecruit
);

export const getNewRecruitUpdating = createSelector(
  getState,
  (state: SalesTargetState) => state.updatingNewRecruit
);

export const getNewRecruitDeleting = createSelector(
  getState,
  (state: SalesTargetState) => state.deletingNewRecruit
);

export const getNewRecruitHiring = createSelector(
  getState,
  (state: SalesTargetState) => state.hiringNewRecruit
);

export const getEditPermissionSetting = createSelector(
  getState,
  (state: SalesTargetState) => state.editPermissionSetting
);

export const getEditPermissionSettingLoading = createSelector(
  getState,
  (state: SalesTargetState) => state.loadingEditPermissionSettings
);

export const getEditPermissionSettingStoring = createSelector(
  getState,
  (state: SalesTargetState) => state.storingEditPermissionSettings
);

export const getMonthPermissions = createSelector(
  getState,
  (state: SalesTargetState) => state.months
);

export const getLoadingMonthPermissions = createSelector(
  getState,
  (state: SalesTargetState) => state.loadingEditMonthPermissionSettings
);

export const getStoringMonthPermissions = createSelector(
  getState,
  (state: SalesTargetState) => state.storingEditMonthPermissionSetting
);

export const getStoringBudgetAndSalesTarget = createSelector(
  getState,
  (state: SalesTargetState) => state.storingBudgetAndSalesTarget
);
