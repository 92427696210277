<div class="wrapper">
  <sidebar-header
    [type]="tabType"
    [label]="'edit_value_monitor' | translate">
  </sidebar-header>
  <div class="scroll-view">
    <app-value-monitor-form action="edit" [data]="data">
    </app-value-monitor-form>
  </div>
  <div class="sidebar-footer">
    <button
      tabindex="-1"
      type="button"
      class="q-btn--cancel"
      (click)="closeTab()"
    >
      {{ 'cancel' | translate | uppercase }}
    </button>
    <button
      tabindex="-1"
      type="button"
      class="q-btn--remove"
      (click)="onRemove()">
      {{ 'remove' | translate | uppercase }}
    </button>
    <button type="submit" form="value-monitor-form" class="q-btn--save" (click)="onSubmit()">
      {{ 'save' | translate | uppercase }}
    </button>
  </div>
</div>
