import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Office } from "@app/models";
import { filter, Subject, takeUntil } from "rxjs";

@Component({
  selector: "office-dropdown",
  templateUrl: "./office-dropdown.component.html",
  styleUrls: ["./office-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfficeDropdownComponent implements OnInit, OnDestroy {
  @Input() parentFormGroup: FormGroup;
  @Input() parentFormControlName = "office";
  @Input() offices: Office[];
  @Input() disableSelectOffice = true;
  @Input() extraOfficeProperty: string;
  @Output() extraOfficePropertyReady = new EventEmitter<any>();

  destroy$ = new Subject<void>();

  ngOnInit(): void {
    if (this.extraOfficeProperty) {
      this.handleDropdownExtraProperty();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  handleDropdownExtraProperty(): void {
    this.parentFormGroup
      .get(this.parentFormControlName)
      .valueChanges.pipe(
        filter((value) => !!value),
        takeUntil(this.destroy$)
      )
      .subscribe((value) =>
        this.extraOfficePropertyReady.emit(
          this.offices.find((office: Office) => office.eaOfficeId === value)[
            this.extraOfficeProperty
          ]
        )
      );
  }
}
