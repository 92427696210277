import { Injectable } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as residencesActions from "@app/contacts/contact-residences/residences.actions";
import * as confirmActions from "@app/core/components/confirm-modal/ngrx/confirm-modal.actions";
import { confirmState } from "@app/core/components/confirm-modal/ngrx/confirm-modal.reducer";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { Contact, QObject, TypedPaginationListDTO } from "@app/models";
import { SIDEBAR_CONTACT_RESIDENCES_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { RESIDENCE } from "@app/shared/utils/tab-types";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { select, Store } from "@ngrx/store";
import {
  catchError,
  concatMap,
  filter,
  first,
  from as observableFrom,
  map,
  Observable,
  of as observableOf,
  switchMap,
  withLatestFrom,
} from "rxjs";
import { updateContactRequest } from "../../contacts/ngrx/create-contact.actions";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as residenceActions from "./residence.actions";
import * as smActions from "../../sales-meeting/ngrx/sales-meeting.actions";
import { getSidebarResidenceContact } from "./residence.reducer";
import { getSalesMeeting } from "../../sales-meeting/ngrx/sales-meeting.reducer";

@Injectable()
export class ResidenceEffects {
  getResidence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.getResidenceRequest),
      switchMap(({ eaOid }) =>
        this.apiService.get(`residences/${eaOid}`).pipe(
          map((residence: QObject) =>
            residenceActions.getResidenceSuccess({ residence })
          ),
          catchError(() =>
            this.store.pipe(
              select(getSidebarResidenceContact),
              filter((value) => !!value),
              first(),
              map((contact) => contact?.contactId),
              concatMap((contactId) =>
                observableFrom([
                  residenceActions.getResidenceFailed(),
                  RouterActions.go({
                    path: [
                      "/crm",
                      {
                        outlets: {
                          sidebar:
                            SIDEBAR_CONTACT_RESIDENCES_CREATE_URL(contactId),
                        },
                      },
                    ],
                  }),
                  toastActions.danger({ message: "get_residence_failed" }),
                ])
              )
            )
          )
        )
      )
    )
  );

  getContact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.getResidenceContactRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}`).pipe(
          map((contact: Contact) =>
            residenceActions.getResidenceContactSuccess({ contact })
          ),
          catchError(() =>
            observableFrom([
              sidebarActions.closeTab({ tabType: RESIDENCE }),
              residenceActions.getResidenceContactFailed(),
              toastActions.danger({ message: "contact_not_found" }),
            ])
          )
        )
      )
    )
  );

  getResidences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.getContactResidencesRequest),
      switchMap(({ contactId }) =>
        this.apiService.get(`contacts/${contactId}/residences`).pipe(
          map((response: TypedPaginationListDTO<QObject>) =>
            residenceActions.getContactResidencesSuccess({
              residences: response.rows,
            })
          ),
          catchError(() =>
            observableOf(residenceActions.getContactResidencesFailed())
          )
        )
      )
    )
  );

  createResidence$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.createResidenceRequest),
      switchMap(({ residence }) =>
        this.apiService.post(`residences`, residence).pipe(
          concatMap((response: QObject) =>
            observableFrom([
              sidebarActions.closeTab({ tabType: RESIDENCE }),
              residenceActions.createResidenceSuccess({ residence: response }),
              residencesActions.getContactResidenceListRequest({
                contactId: residence.contactId,
              }),
              toastActions.success({ message: "create_residence_success" }),
            ])
          ),
          catchError(() =>
            observableFrom([
              residenceActions.createResidenceFailed(),
              toastActions.danger({ message: "create_residence_failed" }),
            ])
          )
        )
      )
    )
  );

  createResidenceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        residenceActions.createResidenceSuccess,
        residenceActions.updateResidenceSuccess
      ),
      switchMap(({ residence: { street, zip, city } }) =>
        this.store.pipe(
          select(getSidebarResidenceContact),
          first(),
          switchMap((contact: Contact) => {
            return this.shouldUpdateContactAddress$(contact).pipe(
              filter((value) => !!value),
              map(() =>
                updateContactRequest({
                  contactId: contact.contactId,
                  params: {
                    street,
                    zip,
                    city,
                    maritalStatus: contact.maritalStatus,
                    preferredLanguage: contact.preferredLanguage,
                    dateOfBirth: contact.dateOfBirth,
                    sex: contact.sex,
                  },
                  origin: contact.origin,
                })
              )
            );
          })
        )
      )
    )
  );

  updateResidenceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.updateResidenceRequest),
      switchMap(({ residence }) =>
        this.apiService.patch(`residences/${residence.eaOid}`, residence).pipe(
          concatMap((response: QObject) =>
            observableFrom([
              sidebarActions.closeTab({ tabType: RESIDENCE }),
              residenceActions.updateResidenceSuccess({ residence: response }),
              residencesActions.getContactResidenceListRequest({
                contactId: residence.contactId,
              }),
              toastActions.success({ message: "update_residence_success" }),
            ])
          ),

          catchError(() =>
            observableFrom([
              residenceActions.updateResidenceFailed(),
              toastActions.danger({ message: "update_residence_failed" }),
            ])
          )
        )
      )
    )
  );

  updateResidenceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(residenceActions.updateResidenceSuccess),
      withLatestFrom(this.store.pipe(select(getSalesMeeting))),
      switchMap(([{ residence }, salesMeeting]) => {
        const updateSalesMeetingAction = smActions.updateSalesMeetingRequest({
          payload: {
            id: salesMeeting.eaCrmSalesMeetingId,
          },
        });

        return [
          residencesActions.setResidence({ residence }),
          updateSalesMeetingAction,
        ];
      })
    )
  );

  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private apiService: ApiService
  ) {}

  private shouldUpdateContactAddress$(contact: Contact): Observable<boolean> {
    if (contact.getAddress() === "") {
      this.store.dispatch(
        confirmActions.show({
          header: "current_address",
          message: "change_current_address",
        })
      );
      return this.store.pipe(select(confirmState), filter(Boolean), first());
    } else {
      return observableOf(false);
    }
  }
}
