<ng-container *ngIf="project$ | async as project">
  <div class="widget-header">
    <h3>
      <app-icon size="large" name="city"></app-icon>&nbsp;<span>{{
        "objects_in_project" | translate
      }}</span>
    </h3>

    <div class="scroll-for-more" *ngIf="project.objects?.length > 5">
      {{ "scroll_for_more" | translate }}
      <app-icon size="small" name="arrow-circle-down"></app-icon>
    </div>
  </div>

  <div class="widget-container" [class.loading-pulse]="loading$ | async">
    <p class="no-items" *ngIf="project?.objects?.length === 0">
      {{ "no_recent_contacts_found" | translate }}
    </p>
    <table>
      <thead>
        <tr>
          <th translate>nr</th>
          <th>
            {{ "area_without_unit" | translate }} ({{
              "area_unit" | translate
            }})
          </th>
          <th translate>nbr_of_rooms</th>
          <th>
            {{ "fee" | translate }} ({{ currency$ | async }} /
            {{ "month" | translate | lowercase }})
          </th>
          <th>{{ "price" | translate }} ({{ currency$ | async }})</th>
          <th translate>status</th>
          <th translate>actions</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let obj of project?.objects">
          <tr (click)="objectClick(obj?.eaOid, $event)">
            <td dataLabel="nr">{{ obj?.street }}</td>
            <td dataLabel="area_without_unit">{{ obj?.area | qNumber }}</td>
            <td dataLabel="nbr_of_rooms">{{ obj?.rooms }}</td>
            <td dataLabel="fee">{{ obj?.monthFee | qNumber : "1.0-0" }}</td>
            <td dataLabel="price">{{ obj?.price | qNumber : "1.0-0" }}</td>
            <td dataLabel="status">
              <span class="status" [class]="getObjectStatusColor(obj.status)">
                <mat-chip-list>
                  <mat-chip>{{ obj?.statusName }}</mat-chip>
                </mat-chip-list>
              </span>
            </td>
            <td dataLabel="actions">
              <app-actions-menu>
                <ng-container buttons>
                  <li role="menuitem">
                    <button class="btn btn-secondary">
                      <app-icon name="home"></app-icon>
                      {{ "go_to_object" | translate }}
                    </button>
                  </li>
                  <li disableNavigation role="menuitem">
                    <button
                      disableNavigation
                      class="btn btn-default"
                      (click)="openInNewTab(obj)"
                    >
                      <app-icon name="share-square"></app-icon>
                      {{ "open_in_new_tab" | translate }}
                    </button>
                  </li>
                </ng-container>
              </app-actions-menu>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</ng-container>
