import {
  combineLatest as observableCombineLatest,
  debounceTime,
  delay,
  filter,
  first,
  map,
  Observable,
  of as observableOf,
  skip,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { FeatureConfigManagerService } from "@app/core/services/feature-config-manager/feature-config-manager.service";
import { ResidenceSidebarConfig } from "@app/core/services/feature-config-manager/models/residence-config";
import { Office, QObject } from "@app/models";
import { getCurrency, getFeature } from "@app/shared/config/config.reducer";
import { ExternalProviderFeature } from "@app/shared/config/models/external-provider";
import { EXTERNAL_PROVIDER } from "@app/shared/config/utils/features";
import * as fromShared from "@app/shared/ngrx/shared.reducer";
import { getOffice } from "@app/shared/user";
import * as externalProviders from "@app/shared/utils/external-providers";
import { ObjectType } from "@app/shared/utils/q-object-types";
import { RESIDENCE } from "@app/shared/utils/tab-types";
import { closeTab } from "@app/sidebar/ngrx/sidebar.actions";
import { select, Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { SidebarTab } from "../../models/sidebar-tab";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import { getTab } from "../../ngrx/sidebar.reducer";
import { ResidenceFormData } from "../residence-form/models/residence-form-data";
import { ResidenceFormComponent } from "../residence-form/residence-form.component";
import * as residenceActions from "../ngrx/residence.actions";
import {
  getSidebarResidence,
  getSidebarResidenceContact,
  getSidebarResidenceProcessing,
} from "../ngrx/residence.reducer";

@Component({
  selector: "residence-edit",
  templateUrl: "./residence-edit.component.html",
  styleUrls: [
    "../../sidebar.component.common.scss",
    "./residence-edit.component.scss",
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResidenceEditComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild("residenceForm", { static: false })
  residenceForm: ResidenceFormComponent;

  private unSubscribe$: Subject<void> = new Subject<void>();

  tab$: Observable<SidebarTab>;
  selectedResidence$: Observable<ResidenceFormData>;
  showSauna$: Observable<boolean>;
  header$: Observable<string>;
  processing$: Observable<boolean>;
  objectTypes$: Observable<ObjectType[]>;
  residenceSidebarConfig$: Observable<ResidenceSidebarConfig>;

  tabType = RESIDENCE;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private featureConfigManager: FeatureConfigManagerService
  ) {}

  ngOnInit() {
    this.initStoreObservers();
    this.initParamsEvents();
  }

  ngAfterViewInit() {
    this.tab$
      .pipe(
        filter((value) => !!value),
        first()
      )
      .subscribe((tab) => {
        if (tab.dirty) {
          this.residenceForm.form.setValue(tab.currentValue);
          this.cdr.detectChanges();
          this.startResidencesChangesStream(1);
        } else {
          this.startResidencesChangesStream();
        }
        this.startValueChangesStream();
      });
  }

  ngOnDestroy() {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  initStoreObservers() {
    this.residenceSidebarConfig$ =
      this.featureConfigManager.getResidenceSidebarConfig();
    this.objectTypes$ = this.store.pipe(select(fromShared.getObjectTypes));
    this.tab$ = this.store.pipe(select(getTab(this.tabType)));
    this.processing$ = this.store.pipe(select(getSidebarResidenceProcessing));

    this.selectedResidence$ = this.store.pipe(
      select(getSidebarResidence),
      filter((value) => !!value),
      switchMap((residence: QObject) => this.getResidenceFormData(residence))
    );

    this.header$ = this.selectedResidence$.pipe(
      filter((value) => !!value),
      switchMap((residence: ResidenceFormData) =>
        this.translateService.get("edit_residence_header", {
          address: residence.address.street,
        })
      )
    );

    this.showSauna$ = this.store.pipe(
      select(getFeature(EXTERNAL_PROVIDER)),
      map((feature: ExternalProviderFeature) => feature.name),
      map((name) => name.toLowerCase() === externalProviders.KIVI)
    );
  }

  initParamsEvents(): void {
    this.route.params
      .pipe(
        filter((params: Params) => params.hasOwnProperty("id")),
        takeUntil(this.unSubscribe$),
        switchMap((params: Params) =>
          this.store.pipe(
            select(getSidebarResidence),
            first(),
            map(() => params.id)
          )
        )
      )
      .subscribe((eaOid: string) =>
        this.store.dispatch(residenceActions.getResidenceRequest({ eaOid }))
      );
  }

  startValueChangesStream() {
    this.residenceForm.form.valueChanges
      .pipe(takeUntil(this.unSubscribe$), debounceTime(500))
      .subscribe(() =>
        this.store.dispatch(
          sidebarActions.setTabValue({
            tabType: this.tabType,
            value: this.residenceForm.getQObjectFromFormData(),
          })
        )
      );
  }

  startResidencesChangesStream(skipNumber = 0) {
    this.selectedResidence$
      .pipe(
        takeUntil(this.unSubscribe$),
        skip(skipNumber),
        delay(1), // TODO: how to wait until residence has been set in the residence form child component
        map(() => this.residenceForm.getQObjectFromFormData())
      )
      .subscribe((formValues) =>
        this.store.dispatch(
          sidebarActions.setInitialTabValue({
            tabType: this.tabType,
            value: formValues,
          })
        )
      );
  }

  closeTab(): void {
    this.store.dispatch(closeTab({ tabType: this.tabType }));
  }

  onSubmit(residence: QObject): void {
    residence.rooms = residence.rooms.replace(",", ".");
    observableCombineLatest([
      this.store.pipe(
        select(getOffice),
        map((office: Office) => office.officeId)
      ),
      this.store.pipe(
        select(getSidebarResidenceContact),
        map((contact) => contact?.contactId)
      ),
      this.store.pipe(select(getCurrency)),
    ])
      .pipe(
        map(([officeId, contactId, priceCode]) =>
          residenceActions.updateResidenceRequest({
            residence: {
              ...residence,
              officeId,
              contactId,
              priceCode,
            },
          })
        ),
        first()
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  getResidenceFormData(qObject: QObject): Observable<ResidenceFormData> {
    const data: ResidenceFormData = {
      objType: qObject.objType,
      address: {
        street: qObject.street,
        zip: qObject.zip,
        city: qObject.city,
      },
      valuation: qObject.price.toString(),
      fee: qObject.monthlyFee.toString(),
      area: qObject.area,
      rooms: qObject.rooms,
      year: qObject.built,
      code: qObject.accessCode,
      propertyDescription: qObject.propertyDescription,
      floor: qObject.level,
      fireplace: qObject.fireplace,
      balcony: qObject.balcony,
      elevator: qObject.elevator,
      sauna: qObject.sauna,
      eaOid: qObject.eaOid,
    };

    return observableOf(data);
  }
}
