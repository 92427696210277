import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import { SIDEBAR_FOLLOW_UPS_CREATE_URL } from "@app/shared/utils/sidebar-tab-utils";
import { CREATE_FOLLOW_UP } from "@app/shared/utils/tab-types";
import { Store } from "@ngrx/store";
import * as createFollowUpActions from "../ngrx/create-follow-up.actions";

@Component({
  selector: "create-follow-up-create-contact",
  template: `
    <create-contact-simple
      [type]="tabType"
      (onSubmit)="onSubmit($event)"
      (onQuedroContactSelected)="onQuedroContactSelected($event)"
    ></create-contact-simple>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFollowUpCreateContactComponent {
  tabType = CREATE_FOLLOW_UP;

  constructor(private store: Store<AppState>) {}

  onSubmit({ params, source }): void {
    this.store.dispatch(createFollowUpActions.createContact(params, source));
  }

  onQuedroContactSelected(contact: Contact) {
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_FOLLOW_UPS_CREATE_URL(contact.contactId),
            },
          },
        ],
      })
    );
  }
}
