import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "[appOverlaySpinner]",
  templateUrl: "./overlay-spinner.component.html",
  styleUrls: ["./overlay-spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlaySpinnerComponent {
  @Input() appOverlaySpinner: boolean;
}
