import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "./navbar.actions";

export interface NavbarState {
  isVisible: boolean;
}

export const initialState: NavbarState = {
  isVisible: false,
};

export function navbarReducer(state: NavbarState, action: Action): NavbarState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialState,
  on(actions.show, (state) => ({ ...state, isVisible: true })),
  on(actions.hide, (state) => ({ ...state, isVisible: false })),
  on(actions.toggle, (state) => ({ ...state, isVisible: !state.isVisible }))
);

const selectFeature = createFeatureSelector<NavbarState>("navbar");

export const getNavbarStatus = createSelector(
  selectFeature,
  (state: NavbarState) => state.isVisible
);
