<app-q-modal>
  <ng-container slot="header">
    {{'video_management' | translate}}
  </ng-container>
  <ng-container slot="body">
    <div class="modal-body" [ngClass]="videoIndex>-1?'video-container':''">
      <video *ngIf="videoSrc?.length > 0;else noVideoContent" controls autoplay>
        <source src="{{videoSrc}}" type="video/mp4">
      </video>

      <ng-template #noVideoContent>
        <p>{{'no_video_uploaded'|translate}}</p>
      </ng-template>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <app-q-button *ngIf="videoIndex >= 0"
                  buttonType="save"
                  label="download"
                  icon="cloud-arrow-down"
                  classes="margin-right"
                  [isLoading]="downloadFileService.isDownloading$ | async"
                  [disabled]="downloadFileService.isDownloading$ | async"
                  (click)="downloadFile()">
    </app-q-button>
    <app-q-button buttonType="save"
                  label="create_or_update_video"
                  icon="video-plus"
                  classes="margin-right"
                  (click)="createOrUpdateVideo();">
    </app-q-button>
    <app-q-button *ngIf="videoIndex >= 0"
                  buttonType="remove"
                  label="delete_video"
                  icon="trash"
                  classes="margin-right"
                  (click)="deleteVideo();">
    </app-q-button>
    <app-q-button mat-dialog-close
                  buttonType="cancel"
                  label="close"
                  (click)="closeModal();">
    </app-q-button>
  </ng-container>
</app-q-modal>
