<div class="wrapper wrapper--extra-footer-space" [formGroup]="form">
  <div class="scroll-view">
    <section>
      <h4>{{ "chosen_contact" | translate }}</h4>
      <contact-card
        *ngIf="selectedContact$ | async"
        [name]="(selectedContact$ | async)?.getFullName()"
        [address]="(selectedContact$ | async)?.street"
        [number]="
          formatNumber(
            (selectedContact$ | async)?.msisdn,
            (selectedContact$ | async)?.msisdnCountry
          )
        "
        [email]="(selectedContact$ | async)?.email"
        [deletable]="true"
        (delete)="goBack()"
      ></contact-card>
    </section>
    <hr />
    <section *ngIf="objectsLoading$ | async" class="object-spinner">
      <spinner [size]="'1.5rem'"></spinner>
      <h4>{{ "loading_objects" | translate }}</h4>
    </section>
    <section *ngIf="!(objectsLoading$ | async)">
      <ng-container *ngIf="!selectedObject; else objectCard">
        <h4>
          {{ "select_residence" | translate }}
          <span class="text-lowercase">({{ "optional" | translate }})</span>
        </h4>
        <input
          #suggester
          formControlName="eaOid"
          [typeaheadItemTemplate]="customItemTemplate"
          [placeholder]="'street' | translate"
          typeaheadOptionField="street"
          [typeahead]="dataSource || []"
          [typeaheadMinLength]="0"
          [typeaheadWaitMs]="200"
          [typeaheadOptionsLimit]="10"
          (typeaheadOnSelect)="handleSelect($event)"
        />
        <small class="text-muted">{{
          "search_amongst_office_objects_for_sale" | translate
        }}</small>
      </ng-container>
      <ng-template #objectCard>
        <h4>{{ "chosen_object" | translate }}</h4>
        <card
          [color]="residenceColor"
          [removable]="true"
          (remove)="onObjectRemove()"
        >
          <div title>
            {{ selectedObject.street }} {{ selectedObject.zip }}
            {{ selectedObject.city }}
          </div>
          <div body>
            <small
              *ngIf="!selectedObject.rentMonth; else rent"
              class="typeahead-meta"
            >
              <span *ngIf="selectedObject.price > 0; else noResidencePrice2">
                {{ selectedObject.price | qNumber }}
                {{ selectedObject.priceCode }}
              </span>
              <ng-template #noResidencePrice2>{{
                "price_unknown" | translate
              }}</ng-template>
              |
            </small>
            <ng-template #rent>
              <small class="typeahead-meta">
                <span *ngIf="selectedObject.rentMonth > 0; else noRent">
                  {{ selectedObject.rentMonth | qNumber }}
                  {{ selectedObject.priceCode }}
                </span>
                <ng-template #noRent>{{
                  "rent_unknown" | translate
                }}</ng-template>
                |
              </small>
            </ng-template>
            <small *ngIf="!selectedObject.rentMonth" class="typeahead-meta">
              <span *ngIf="selectedObject.monthlyFee > 0; else noMonthFee2">
                {{ selectedObject.monthlyFee | qNumber }}
                {{ selectedObject.priceCode }} /{{
                  "month" | translate | lowercase
                }}
              </span>
              <ng-template #noMonthFee2>{{
                "fee_unknown" | translate
              }}</ng-template>
              |
            </small>
            <small>
              <span *ngIf="selectedObject.level; else noLevel2">
                {{ "level" | translate }}: {{ selectedObject.level }}
              </span>
              <ng-template #noLevel2>{{
                "level_unknown" | translate
              }}</ng-template>
            </small>

            <br />
            <small
              *ngIf="
                selectedObject.responsibleEmployee;
                else noResponsibleEmployee2
              "
            >
              {{ "responsible_broker" | translate }}:
              {{ selectedObject.responsibleEmployee.employeeFullName }}
            </small>
            <ng-template #noResponsibleEmployee2>
              <small>{{ "responsible_employee_missing" | translate }}</small>
            </ng-template>

            <ng-container>
              <br />
              <small
                class="typeahead-meta"
                *ngIf="
                  selectedObject.sellers && selectedObject.sellers.length > 0;
                  else noSeller
                "
              >
                {{
                  selectedObject.rentMonth
                    ? ("owner" | translate)
                    : ("seller" | translate)
                }}:
                <span
                  *ngFor="let seller of selectedObject.sellers; let i = index"
                >
                  {{ seller.firstName }} {{ seller.familyName }}
                  <span *ngIf="selectedObject.sellers.length > i + 1">, </span>
                </span>
              </small>
              <ng-template #noSeller>
                <small>{{ "sellers_missing" | translate }}</small>
              </ng-template>
            </ng-container>
          </div>
        </card>
      </ng-template>
      <ng-template #customItemTemplate let-model="item" let-index="index">
        <h5 class="typeahead-responsible-broker">
          {{ model.street }}, {{ model.zip }}, {{ model.city }}
        </h5>
        <small *ngIf="!model.rentMonth; else modelRent" class="typeahead-meta">
          <span *ngIf="model.price > 0; else noResidencePrice">
            {{ model.price | qNumber }} {{ model.priceCode }}
          </span>
          <ng-template #noResidencePrice>{{
            "price_unknown" | translate
          }}</ng-template>
          |
        </small>
        <ng-template #modelRent>
          <small class="typeahead-meta">
            <span *ngIf="model.rentMonth > 0; else noRent">
              {{ model.rentMonth | qNumber }} {{ model.priceCode }}
            </span>
            <ng-template #noRent>{{ "rent_unknown" | translate }}</ng-template>
            |
          </small>
        </ng-template>
        <small *ngIf="!model.rentMonth" class="typeahead-meta">
          <span *ngIf="model.monthlyFee > 0; else noMonthFee">
            {{ model.monthlyFee | qNumber }} {{ model.priceCode }}/{{
              "month" | translate | lowercase
            }}
          </span>
          <ng-template #noMonthFee>{{ "fee_unknown" | translate }}</ng-template>
          |
        </small>
        <small>
          <span *ngIf="model.level; else noLevel">
            {{ "level" | translate }}: {{ model.level }}
          </span>
          <ng-template #noLevel>{{ "level_unknown" | translate }}</ng-template>
        </small>

        <br />
        <small *ngIf="model.responsibleEmployee; else noResponsibleEmployee">
          {{ "responsible_broker" | translate }}:
          {{ model.responsibleEmployee.employeeFullName }}
        </small>
        <ng-template #noResponsibleEmployee>
          <small>{{ "responsible_employee_missing" | translate }}</small>
        </ng-template>

        <ng-container>
          <br />
          <small
            class="typeahead-meta"
            *ngIf="model.sellers && model.sellers.length > 0; else noSeller"
          >
            {{ "seller" | translate }}:
            <span *ngFor="let seller of model.sellers; let i = index">
              {{ seller.firstName }} {{ seller.familyName }}
              <span *ngIf="model.sellers.length > i + 1">, </span>
            </span>
          </small>
          <ng-template #noSeller>
            <small>{{ "sellers_missing" | translate }}</small>
          </ng-template>
        </ng-container>
      </ng-template>
    </section>
    <hr />
    <section>
      <h4>{{ "lead_title" | translate }}</h4>
      <input
        [placeholder]="'title' | translate"
        formControlName="title"
        type="text"
        [class.validation-input]="
          form.get('title').invalid && form.get('title').touched
        "
      />
      <div ngxErrors="title">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </section>
    <hr />
    <section>
      <h4>{{ "select_task_type" | translate }}</h4>
      <div class="input-iconfield">
        <i class="icon icon-mustache"></i>
        <select formControlName="taskTemplate" required>
          <option translate selected value="">select_task_type</option>
          <option
            *ngFor="let template of taskTemplates$ | async"
            value="{{ template.eaTaskTemplateId }}"
          >
            {{ template.typeName }}
            {{
              !!template?.originService ? "- " + template?.originService : null
            }}
          </option>
        </select>
      </div>
      <div ngxErrors="taskTemplate">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </section>
    <hr />
    <section>
      <h4>{{ "recipient" | translate }}</h4>
      <all-office-employee-dropdown
        [parentFormGroup]="form.get('recipient')"
        parentFormGroupName="recipient"
        [disableSelectBrokerOption]="false"
        (employeesChange)="recipientEmployeesChanged()"
      ></all-office-employee-dropdown>
      <br />
      <h4>{{ "comment" | translate }}</h4>
      <textarea
        [ngClass]="
          !(processing$ | async) &&
          !form.get('comment').valid &&
          form.get('comment').touched
            ? 'validation-input'
            : ''
        "
        formControlName="comment"
      ></textarea>
      <span
        *ngIf="
          !(processing$ | async) &&
          !form.get('comment').valid &&
          form.get('comment').touched
        "
        class="validation-message"
        >{{ "validation_required" | translate }}</span
      >
      <small class="text-muted">{{
        "comment_will_connect_to_lead" | translate
      }}</small>
    </section>
  </div>
  <div class="sidebar-footer">
    <app-q-button
      tabindex="-1"
      buttonType="cancel"
      label="cancel"
      (click)="closeTab()"
    >
    </app-q-button>
    <app-q-button
      buttonType="save"
      label="send"
      (click)="send(form.value)"
      [disabled]="processing$ | async"
      [isLoading]="processing$ | async"
      scrollToInvalidControl
      [formEl]="form"
    >
    </app-q-button>
  </div>
</div>
