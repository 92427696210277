<div class="form-group">
  <label translate>office</label>
  <office-dropdown
    [offices]="offices$ | async"
    [parentFormGroup]="parentFormGroup"
  ></office-dropdown>
  <div [ngxErrors]="getFullControlName(parentOfficeFormControlName)">
    <div translate ngxError="required" showWhen="touched">
      validation_required
    </div>
  </div>
</div>

<div class="form-group">
  <label translate>broker</label>
  <employee-dropdown
    [employees]="employees$ | async"
    [parentFormGroup]="parentFormGroup"
    [disableSelectBroker]="disableSelectBrokerOption"
  >
  </employee-dropdown>
  <div [ngxErrors]="getFullControlName(parentEmployeeFormControlName)">
    <div translate ngxError="required" showWhen="touched">
      validation_required
    </div>
  </div>
</div>
