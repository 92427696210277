import {
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as actions from "@app/settings/lead-channels/ngrx/lead-channels.actions";
import { TaskType, TypedPaginationListDTO } from "@app/models";
import { SettingsModuleState } from "@app/settings/settings-module.reducer";

export interface LeadChannelsState {
  data: TypedPaginationListDTO<TaskType>;
  isLoading: boolean;
}

const initialState: LeadChannelsState = {
  data: null,
  isLoading: false,
};

export const leadChannelsReducer = createReducer(
  initialState,
  on(actions.getLeadChannelsRequest, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(actions.getLeadChannelsSuccess, (state, { data }) => ({
    ...state,
    data,
    isLoading: true,
  })),
  on(actions.getLeadChannelsFail, (state) => ({
    ...state,
    isLoading: false,
  }))
);

const selectFeature = createFeatureSelector<SettingsModuleState>("settings");
const getState = createSelector(
  selectFeature,
  (state: SettingsModuleState) => state.leadChannels
);

export const selectData = createSelector(
  getState,
  (state: LeadChannelsState) => state.data
);
export const selectIsLoading = createSelector(
  getState,
  (state: LeadChannelsState) => state.isLoading
);
