export class ContactShowingAttendance {
  registrationStatus?: string | null = null;
  showingId?: string | null = null;
  showingStartTime?: string | null = null;
  showingSlotId?: string | null = null;
  eaShowingSlotId?: string | null = null;
  eaShowingId?: string | null = null;

  constructor(options?: Partial<ContactShowingAttendance>) {
    for (const prop in options) {
      if (options.hasOwnProperty(prop)) {
        this[prop] = options[prop];
      }
    }
  }
}

export interface ShowingAttendances {
  attended: string[];
  notAttended: string[];
  registered: string[];
}

export enum ContactShowingAttendanceStatus {
  Attended = "ATTENDED",
  NotAttended = "NOT_ATTENDED",
  Registered = "REGISTERED",
  NotRegistered = "NOT_REGISTERED",
}
