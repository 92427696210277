import { TaskType } from "@app/models";
import {
  LeadStatisticsEmployee,
  LeadStatisticsOffice,
  LeadStatisticsOrigin,
  LeadStatisticsTaskType,
  LeadStatisticsTotal,
} from "@app/statistics/statistics-leads-new/components/statistics-leads-new-content/utils/lead-statistics";
import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import * as resultsActions from "./statistics-leads-new.actions";
import { StatisticsModuleState } from "@app/statistics/statistics-module.reducer";

export interface LeadStatisticsState {
  loading: boolean;
  total: LeadStatisticsTotal;
  offices: LeadStatisticsOffice[];
  employees: LeadStatisticsEmployee[];
  taskTypes: LeadStatisticsTaskType[];
  origins: LeadStatisticsOrigin[];
  allTaskTypes: TaskType[];
  allTaskTypesLoading: boolean;
  csvDownloading: boolean;
  hasError: boolean;
}

export const initialStateLeadStatisics: LeadStatisticsState = {
  loading: false,
  total: null,
  offices: null,
  employees: null,
  taskTypes: null,
  origins: null,
  allTaskTypes: null,
  allTaskTypesLoading: false,
  csvDownloading: false,
  hasError: false,
};

export function leadStatisticsReducer(
  state: LeadStatisticsState,
  action: Action
): LeadStatisticsState {
  return reducer(state, action);
}

const reducer = createReducer(
  initialStateLeadStatisics,
  on(resultsActions.getLeadsStatisticsForChainRequest, (state) => ({
    ...state,
    loading: true,
    hasError: false,
  })),
  on(
    resultsActions.getLeadsStatisticsForChainSuccess,
    (state, { total, offices, employees, taskTypes, origins }) => ({
      ...state,
      loading: false,
      hasError: false,
      total,
      offices,
      employees,
      taskTypes,
      origins: origins ?? state.origins,
    })
  ),
  on(resultsActions.getLeadsStatisticsForChainFail, (state) => ({
    ...state,
    loading: false,
    hasError: true,
    total: null,
    offices: null,
    employees: null,
    taskTypes: null,
    origins: null,
  })),
  on(resultsActions.getTaskTypesRequest, (state) => ({
    ...state,
    allTaskTypesLoading: true,
  })),
  on(resultsActions.getTaskTypesSuccess, (state, { taskTypes }) => ({
    ...state,
    allTaskTypes: taskTypes,
    allTaskTypesLoading: false,
  })),
  on(resultsActions.getTaskTypesFail, (state) => ({
    ...state,
    allTaskTypesLoading: false,
  })),
  on(resultsActions.getLeadsCSVRequest, (state) => ({
    ...state,
    csvDownloading: true,
  })),
  on(
    resultsActions.getLeadsCSVSuccess,
    resultsActions.getLeadsCSVFail,
    (state) => ({ ...state, csvDownloading: false })
  )
);

const selectFeature =
  createFeatureSelector<StatisticsModuleState>("statistics");
const getLeadStatisticsState = createSelector(
  selectFeature,
  (state: StatisticsModuleState) => state.leadStatistics
);

export const getLeadStatisticsLoading = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.loading
);

export const getLeadStatisticsTotal = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.total
);

export const getOffices = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.offices
);

export const getEmployees = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.employees
);

export const getTaskTypes = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.taskTypes
);

export const getAllTaskTypes = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.allTaskTypes
);

export const getAllTaskTypesLoading = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.allTaskTypesLoading
);

export const getCSVDownloading = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.csvDownloading
);

export const getOrigins = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.origins
);

export const getLeadStatisticsHasError = createSelector(
  getLeadStatisticsState,
  (state: LeadStatisticsState) => state.hasError
);
