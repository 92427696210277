import { Contact, EmployeeDTO } from "@app/models";
import { CreateContactParams } from "../../contacts/ngrx/create-contact.actions";
import { createAction, props } from "@ngrx/store";

export interface CreateFollowUpRequest {
  title: string;
  contactId: string;
  deliveryDate: string;
  startTime: string;
  endTime: string;
  eaOfficeId: string;
  eaEmployeeId: string;
  description: string;
  origin: string;
  maritalStatus: string;
  preferredLanguage: string;
  dateOfBirth: string;
  sex: string;
}

const ACTION_PREFIX = "[Create Follow Up]";

export const createFollowUpRequest = createAction(
  `${ACTION_PREFIX} CREATE_FOLLOW_UP_REQUEST`,
  props<{ params: CreateFollowUpRequest }>()
);

export const createFollowUpSuccess = createAction(
  `${ACTION_PREFIX} CREATE_FOLLOW_UP_SUCCESS`
);

export const createFollowUpFailed = createAction(
  `${ACTION_PREFIX} CREATE_FOLLOW_UP_FAILED`
);

export const getOfficeEmployees = (eaOfficeId: string) => {
  return getOfficeEmployeesRequest({
    eaOfficeId,
    limit: 200,
  });
};

export const getOfficeEmployeesRequest = createAction(
  `${ACTION_PREFIX} GET_OFFICE_EMPLOYEES_REQUEST`,
  props<{
    eaOfficeId: string;
    limit: number;
  }>()
);

export const getOfficeEmployeesSuccess = createAction(
  `${ACTION_PREFIX} GET_OFFICE_EMPLOYEES_SUCCESS`,
  props<{
    employees: EmployeeDTO[];
  }>()
);

export const getOfficeEmployeesFailed = createAction(
  `${ACTION_PREFIX} GET_OFFICE_EMPLOYEES_FAILED`
);

export const getContactRequest = createAction(
  `${ACTION_PREFIX} GET_CONTACT_REQUEST`,
  props<{
    id: string;
  }>()
);

export const getContactSuccess = createAction(
  `${ACTION_PREFIX} GET_CONTACT_SUCCESS`,
  props<{
    contact: Contact;
  }>()
);

export const getContactFailed = createAction(
  `${ACTION_PREFIX} GET_CONTACT_FAILED`
);

export const setContact = createAction(
  `${ACTION_PREFIX} SET_CONTACT`,
  props<{
    contact: Contact;
  }>()
);

export const createContact = (params: CreateContactParams, source?: string) => {
  return createContactRequest({
    params: { ...params, originService: "CRM" },
    source,
  });
};

export const createContactRequest = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_REQUEST`,
  props<{
    params: CreateContactParams;
    source?: string;
  }>()
);

export const createContactSuccess = createAction(
  `${ACTION_PREFIX} CREATE_CONTACT_SUCCESS`,
  props<{
    contactId: string;
  }>()
);

export const reset = createAction(`${ACTION_PREFIX} RESET`);
