import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { MomentModule } from "angular2-moment";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { ListComponentsModule } from "../list-components/list.module";
import { UiComponentsModule } from "../ui-components/ui-components.module";
import { ContactActionsWidgetListItemComponent } from "./contact-actions-widget-list-item/contact-actions-widget-list-item.component";
import { ContactActionsWidgetComponent } from "./contact-actions-widget.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ListComponentsModule,
    MomentModule,
    UiComponentsModule,
    TooltipModule,
    RouterModule,
  ],
  declarations: [
    ContactActionsWidgetComponent,
    ContactActionsWidgetListItemComponent,
  ],
  exports: [ContactActionsWidgetComponent],
})
export class ContactActionsWidgetModule {}
