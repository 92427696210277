import { Component, Input, OnInit } from "@angular/core";
import { AppState } from "@app/app.reducer";
import { ShowingService } from "@app/core/ngrx/entity-services/showing.service";
import { Showing } from "@app/showings/models";
import * as showingActions from "@app/showings/ngrx/showings/showings.actions";
import * as fromShowings from "@app/showings/ngrx/showings/showings.reducer";
import { select, Store } from "@ngrx/store";
import moment from "moment";
import { first, Observable } from "rxjs";

@Component({
  selector: "app-object-upcoming-showings-widget",
  templateUrl: "./object-upcoming-showings-widget.component.html",
  styleUrls: [
    "./object-upcoming-showings-widget.component.scss",
    "../../widget/widget.common.scss",
  ],
})
export class ObjectUpcomingShowingsWidgetComponent implements OnInit {
  @Input() eaOid: string;
  showingPdfLoading$: Observable<boolean>;
  showings$: Observable<Showing[]>;

  moment = moment;

  constructor(
    private store: Store<AppState>,
    public showingService: ShowingService
  ) {}

  ngOnInit(): void {
    this.mapStateToProps();
  }

  private mapStateToProps() {
    this.showingPdfLoading$ = this.store.pipe(
      select(fromShowings.getPdfLoading)
    );
    this.showings$ = this.showingService.currentList$;
  }

  getAsPDFForShowing(showing: Showing) {
    this.store.dispatch(
      showingActions.getPDFRequest({
        eaOid: this.eaOid,
        filters: {
          sortBy: "insertedDate",
          sortOrder: "desc",
          showingId: showing.showingId,
        },
      })
    );
  }

  refreshShowings() {
    // Todo: Use showingService instead
    this.showingService.refreshFetchFromObject().pipe(first()).subscribe();
  }
}
