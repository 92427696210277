import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  distinctUntilChanged,
  filter,
  map,
  Observable,
  share,
  switchMap,
  tap,
} from "rxjs";
import { fadeInOutTrigger } from "../../../animations";
import {
  Address,
  AddressValidationResponse,
} from "../search-address/AddressProvider";
import { ValidService } from "../search-address/valid.service";
import { isEqual } from "@app/shared/utils/object-utils";

@Component({
  selector: "address-validation",
  templateUrl: "./address-validation.component.html",
  styleUrls: ["./address-validation.component.scss"],
  providers: [ValidService],
  animations: [fadeInOutTrigger()],
})
export class AddressValidationComponent implements OnChanges, OnInit {
  @Input() address: Observable<Address>;
  @Input() max: number;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect = new EventEmitter<Address>();

  hidden = false;
  validation$: Observable<AddressValidationResponse>;

  constructor(private valid: ValidService) {}

  private _countryCode: string;

  get countryCode(): string {
    return this._countryCode;
  }

  @Input()
  set countryCode(value: string) {
    this._countryCode = value.toLowerCase();
  }

  ngOnInit(): void {
    this.validation$ = this.address.pipe(
      tap(() => (this.hidden = true)),
      filter((address) => address.street && (!!address.zip || !!address.city)),
      distinctUntilChanged(isEqual),
      switchMap((address: Address) => this.validate(address)),
      tap(() => (this.hidden = false)),
      share()
    );
  }

  ngOnChanges(): void {
    this.valid.countryCode = this.countryCode;
  }

  validate(address: Address): Observable<AddressValidationResponse> {
    return this.valid.validate(address).pipe(
      map((r: AddressValidationResponse) => {
        return {
          valid: r.valid,
          suggestions: this.max
            ? r.suggestions.slice(0, this.max)
            : r.suggestions,
        };
      })
    );
  }

  handleClick(address: Address): void {
    this.onSelect.emit(address);
    this.hidden = true;
  }

  formatAddress(address: Address): string {
    return `${address.street}, ${address.zip} ${address.city}`;
  }
}
