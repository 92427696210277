<div class="widget-header">
  <h3>
    <app-icon size="large" name="home"></app-icon>&nbsp;<span>{{
      "details" | translate
    }}</span>
  </h3>
</div>

<div class="widget-container">
  <div *ngIf="property$ | async as property" class="overview-data">
    <app-widget-field [label]="'object_type'">
      <span>{{
        property?.objType
          ? (property.objType | objectTypeName | async)
          : ("object_type_missing" | translate)
      }}</span>
    </app-widget-field>
    <app-widget-field [label]="'status'">{{
      property?.status
        ? (property.status | objectStatusName | async)
        : ("status_missing" | translate)
    }}</app-widget-field>
    <app-widget-field [label]="'size'">
      <span>{{ property?.area }}&nbsp;</span>
      <span translate>area_unit</span>
    </app-widget-field>
    <app-widget-field [label]="'no_of_rooms'"
      ><span>{{ property?.noOfRooms }}</span></app-widget-field
    >
    <ng-container *ngIf="!!property['soldPrice']">
      <app-widget-field [label]="'sold_price'">{{
        (property["soldPrice"] | qNumber) + " " + property?.priceCode
      }}</app-widget-field>
    </ng-container>
    <app-widget-field [label]="'default_price'">{{
      property?.price
        ? (property?.price | qNumber) + " " + property?.priceCode
        : ("price_missing" | translate)
    }}</app-widget-field>
    <app-widget-field [label]="'monthly_fee'">
      {{
        property?.monthlyFee
          ? (property?.monthlyFee | qNumber) +
            " " +
            property?.priceCode +
            " / " +
            ("month" | translate)
          : ("monthly_fee_missing" | translate)
      }}
    </app-widget-field>

    <app-widget-field [label]="'responsible_broker'">
      {{
        property?.responsibleEmployee?.employeeFullName
          ? property?.responsibleEmployee?.employeeFullName
          : ("responsible_employee_missing" | translate)
      }}
    </app-widget-field>

    <ng-container
      *ngIf="property?.objectLinks && property?.objectLinks.length > 0"
    >
      <ng-container
        *ngIf="
          isVideo(property?.objectLinks[property?.objectLinks?.length - 1])
        "
      >
        <app-widget-field [label]="'video_link'">
          <span
            (click)="
              playVideo(
                property?.objectLinks[property?.objectLinks?.length - 1]
                  ?.objectLinkUrl
              )
            "
            class="video-button"
          >
            <i class="icon-control-play"></i>&nbsp;
            <span translate>play_video</span>
          </span>
        </app-widget-field>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="object$ | async as object">
      <ng-container *ngIf="object?.dates?.length > 0">
        <ng-container *ngFor="let date of object.dates">
          <!--      If there are any more dates that we should hide, then we should build a scalable solution    -->
          <app-widget-field
            *ngIf="
              !!date?.date &&
              date.typeName.toLowerCase() !== 'contractenddatesurvey' &&
              date.typeName.toLowerCase() !== 'settled date'
            "
            [label]="date.typeName | formatDateTypeName"
          >
            <span>{{ date.date | momentifyFromApiFormat }}</span>
          </app-widget-field>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<div
  bsModal
  class="modal fade"
  #videoModal="bs-modal"
  tabindex="-1"
  [config]="{ backdrop: true }"
>
  <div class="modal-dialog video-modal">
    <div class="modal-content">
      <div class="custom-modal-header">
        <button
          (click)="closeVideoModal()"
          class="btn-close pull-right"
          type="button"
        >
          <i class="fa fa-close"></i>
        </button>
      </div>
      <video *ngIf="videoSrc?.length > 0" controls autoplay>
        <source src="{{ videoSrc }}" type="video/mp4" />
      </video>
    </div>
  </div>
</div>
