import { entityMetadata } from "@app/core/ngrx/entity-metadata";
import { TypedPaginationListDTO } from "@app/models";
import {
  EntityCollectionServiceBase,
  EntityCollectionServiceElementsFactory,
} from "@ngrx/data";
import {
  BehaviorSubject,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";

export class CustomEntityCollectionServiceBase<
  T
> extends EntityCollectionServiceBase<T> {
  constructor(
    entityName: string,
    serviceElementsFactory: EntityCollectionServiceElementsFactory
  ) {
    super(entityName, serviceElementsFactory);
  }
  currentList$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  total$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  offset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  limit$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  entityList$: Observable<T[]> = this.currentList$.pipe(
    switchMap((pbs) => this.getListFromEntities(pbs))
  );

  refreshFetch$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
  refreshCurrentList = (): Observable<any> | any => {};
  getListFromEntitiesUnsubscribe$ = new Subject<void>();

  cancelExistingRequest$ = new Subject<void>();

  setListDefaults = (rows: T[], response?: TypedPaginationListDTO<T>) => {
    if (!!response) {
      this.total$.next(response.total);
      this.offset$.next(response.offset);
      this.limit$.next(response.limit);
    } else {
      this.total$.next(rows.length);
      this.offset$.next(0);
      this.limit$.next(rows.length);
    }

    // Use getListFromEntities takeUntil this function is called again
    this.getListFromEntitiesUnsubscribe$.next();
    this.getListFromEntitiesUnsubscribe$.complete();
    this.getListFromEntitiesUnsubscribe$ = new Subject<void>();

    if (response?.offset > 0) {
      this.getListFromEntities([...this.currentList$.value, ...rows])
        .pipe(takeUntil(this.getListFromEntitiesUnsubscribe$))
        .subscribe((items) => this.currentList$.next(items));
    } else {
      this.getListFromEntities([...rows])
        .pipe(takeUntil(this.getListFromEntitiesUnsubscribe$))
        .subscribe((items) => this.currentList$.next(items));
    }
  };

  handleDelete = (id) => {
    const newList = [...this.currentList$.value];
    const index = newList.findIndex(
      (val) => entityMetadata[this.entityName].selectId(val) === id
    );
    if (index !== -1) {
      newList.splice(index, 1);
      this.currentList$.next(newList);
      this.total$.next(this.total$.value - 1);
    }
  };

  getListFromEntities = (entities: Partial<T>[]) => {
    return this.entityMap$.pipe(
      map((entityMap) =>
        entities
          .map(
            (entity) =>
              entityMap[entityMetadata[this.entityName].selectId(entity)]
          )
          .filter((item) => !!item)
      )
    );
  };
}
