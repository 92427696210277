import { calculateGrowth, calculatePercent } from "@app/shared/utils/math";

export class Kpi {
  currentData: number;
  kpiType: string;
  lastYearData: number;
  lastYearPeriodEnd: string;
  lastYearPeriodStart: string;
  periodEnd: string;
  periodStart: string;
  salesTarget: number;

  constructor(data = {}) {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  }

  getGrowth(): number {
    return calculateGrowth(this.currentData, this.lastYearData);
  }

  getSalesTargetPercent(): number {
    return this.salesTarget
      ? calculatePercent(this.currentData, this.salesTarget)
      : null;
  }
}
