<div class="text-container {{ classes }}">
  <span>{{ text | translate }}</span>
  <span
    *ngIf="(isSuperAdmin$ | async) && isEnabled"
    class="btn-container"
    (click)="openEditDialog()"
  >
    <app-icon name="pen" size="x-small"></app-icon>
  </span>
</div>
