<li
  class="calendar__list-item"
  [ngClass]="{ 'no-link': event.type === exchange }"
>
  <div class="flex-center">
    <img
      class="fullday"
      *ngIf="event.isFullDayExchangeEvent(); else startTime"
      src="/assets/img/24h.svg"
    />
    <ng-template #startTime>
      <h5 class="text-bold">
        {{ event?.formatedStartTime | momentifyCustomFormat : "HH:mm" }} -
        {{ event?.formatedEndTime | momentifyCustomFormat : "HH:mm" }}
      </h5>
    </ng-template>
  </div>
  <div class="flex-column item-container">
    <span *ngIf="event.title === 'salesmeeting'" class="event-header">
      {{ "sales_meeting" | translate }}</span
    >
    <span *ngIf="event.type === 'showing'" class="event-header">
      <span>{{ getShowingTitle(event) }}</span>
      <a
        [routerLink]="[
          '/crm',
          {
            outlets: {
              primary: ['showings', event?.eaOid, 'register'],
              sidebar: null
            }
          }
        ]"
        target="_blank"
        [matTooltip]="'open_register_view' | translate"
        matTooltipClass="q-tooltip"
        class="showing-link"
      >
        <app-icon size="x-small" name="book-open"></app-icon>
      </a>
    </span>
    <span *ngIf="event.title !== 'salesmeeting'" class="event-header">
      {{ event.title | translate }}
      <img
        class="calendar__list-item--client-icon"
        *ngIf="event.type === exchange && calendarProvider === 'exchange'"
        src="/assets/img/exchange.svg"
      />
      <img
        class="calendar__list-item--client-icon"
        *ngIf="event.type === exchange && calendarProvider === 'gsuite'"
        src="/assets/img/gsuite.jpg"
      />
      <img
        class="calendar__list-item--client-icon"
        *ngIf="event.type === exchange && calendarProvider === 'msgraph'"
        src="/assets/img/exchange.svg"
      />
    </span>
    <small class="text-muted">{{ event.contactFullName }}</small>
    <small class="text-muted">{{ event.getAddress() }}</small>
    <span
      #myPopOver="bs-popover"
      *ngIf="weatherFeatureEnabled && event.forecastData"
      [popover]="weatherInfoText.length > 0 ? popTemplate : null"
      triggers="mouseenter:mouseleave"
      [ngStyle]="event.forecastData ? getIconStyle(event) : ''"
      class="weather-icon"
    >
      <span *ngIf="event.forecastData" class="temperature">
        {{ getTemperatureRounded(event.forecastData.temperature) }}°
      </span>
    </span>
  </div>
</li>

<ng-template #popTemplate>
  <span
    style="font-size: 1rem"
    [@fadeInUp]
    [innerHtml]="weatherInfoText"
  ></span>
</ng-template>
