import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AppState } from "@app/app.reducer";
import * as RouterActions from "@app/core/ngrx/router/router.actions";
import { Contact } from "@app/models";
import {
  SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL,
  SIDEBAR_FOLLOW_UPS_CREATE_URL,
} from "@app/shared/utils/sidebar-tab-utils";
import { Store } from "@ngrx/store";
import { setContact } from "../ngrx/create-follow-up.actions";

@Component({
  selector: "create-follow-up-home",
  template: `
    <div class="wrapper">
      <section class="create-follow-up">
        <h4 class="create-follow-up__label">
          {{ "search_a_contact" | translate }}
        </h4>
        <search-contact
          #search
          [filter]="'freetext'"
          [icon]="'icon icon-lg icon-magnifier'"
          [placeholder]="'search_contact_free_text_placeholder' | translate"
          (quedroClicked)="onClick($event)"
          [focus]="true"
        >
          <a
            class="text-link"
            [routerLink]="[
              '/crm',
              { outlets: { sidebar: SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL } }
            ]"
            [queryParams]="{ number: search.searchInput.nativeElement.value }"
            empty
          >
            - {{ "create_new_contact" | translate }}</a
          >
        </search-contact>
        <div class="create-contact flex-center">
          <a
            [routerLink]="[
              '/crm',
              { outlets: { sidebar: SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL } }
            ]"
            class="text-link"
            >{{ "create_new_contact" | translate }}</a
          >
        </div>
      </section>
    </div>
  `,
  styles: [".create-contact { padding: 0.5rem; }"],
  styleUrls: ["../../sidebar.component.common.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateFollowUpHomeComponent {
  SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL =
    SIDEBAR_FOLLOW_UPS_CONTACTS_CREATE_URL;

  constructor(private store: Store<AppState>) {}

  onClick(contact: Contact) {
    this.store.dispatch(setContact({ contact }));
    this.store.dispatch(
      RouterActions.go({
        path: [
          "/crm",
          {
            outlets: {
              sidebar: SIDEBAR_FOLLOW_UPS_CREATE_URL(contact.contactId),
            },
          },
        ],
      })
    );
  }
}
