import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "nps-details-modal",
  templateUrl: "./nps-details-modal.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NpsDetailsModalComponent {
  @ViewChild("modal", { static: false })
  modal: ModalDirective;
  @Input() nps: any;
}
