import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppState } from "@app/app.reducer";
import { createNoteModalClosed } from "@app/contacts/contact.actions";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-create-note-modal",
  templateUrl: "./create-note-modal.component.html",
  styleUrls: ["./create-note-modal.component.scss"],
})
export class CreateNoteModalComponent implements OnInit {
  showSalesCallButton = false;

  constructor(
    public dialogRef: MatDialogRef<CreateNoteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.showSalesCallButton = this.data?.showSalesCallButton;

    this.dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(createNoteModalClosed());
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
