import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  Renderer2,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Directive({
  selector: "[dataLabel]",
})
export class DataLabelDirective implements AfterViewInit {
  @Input() dataLabel: string;

  constructor(
    private element: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.translate
      .get(this.dataLabel)
      .subscribe((label) =>
        this.renderer.setAttribute(
          this.element.nativeElement,
          "data-label",
          label
        )
      );
  }
}
