import { Injectable } from "@angular/core";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { TaskService } from "@app/core/ngrx/entity-services/task.service";
import { ApiService } from "@app/core/services/api/api.service";
import { Employee, Task, TypedPaginationListDTO } from "@app/models";
import { transformObjectToMessageDataSet } from "@app/shared/utils/message-utils";
import { TASK } from "@app/shared/utils/tab-types";
import { SendMessageParams } from "@app/sidebar/tasks/models/send-message-params";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  from as observableFrom,
  map,
  mergeMap,
  of as observableOf,
  switchMap,
} from "rxjs";
import * as sidebarActions from "../../ngrx/sidebar.actions";
import * as tasksActions from "./tasks.actions";
import { TaskActionLog } from "@app/sidebar/tasks/ngrx/tasks.reducer";

@Injectable()
export class TasksEffects {
  getTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.getTaskRequest),
      switchMap(({ eaTaskId }) =>
        this.apiService.get(`tasks/${eaTaskId}`, { getObject: true }).pipe(
          map((task: Task) => tasksActions.getTaskSuccess({ task })),
          catchError(() =>
            observableFrom([
              tasksActions.getTaskFailed(),
              sidebarActions.closeTab({ tabType: TASK }),
              toastActions.danger({ message: "get_lead_failed" }),
            ])
          )
        )
      )
    )
  );

  getTaskActionLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.getTaskActionLogRequest),
      switchMap(({ taskId }) =>
        this.apiService
          .get(`task-actions/${taskId}`, { sortOrder: "desc" }, "api")
          .pipe(
            map((response: TypedPaginationListDTO<TaskActionLog>) =>
              tasksActions.getTaskActionLogSuccess({
                taskActionLogs: response.rows,
              })
            ),
            catchError(() =>
              observableOf(tasksActions.getTaskActionLogFailed())
            )
          )
      )
    )
  );

  updateTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.updateTaskRequest),
      switchMap(({ task, messageParams }) =>
        this.apiService.patch(`tasks/${task.eaTaskId}`, task).pipe(
          mergeMap((response: Task) =>
            observableFrom([
              tasksActions.updateTaskSuccess({ task: response, messageParams }),
            ])
          ),
          catchError(() =>
            observableFrom([
              tasksActions.updateTaskFailed(),
              toastActions.danger({ message: "update_lead_failed" }),
            ])
          )
        )
      )
    )
  );

  updateTaskSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.updateTaskSuccess),
      mergeMap(({ task, messageParams }) => {
        const msg =
          task.eaTaskTypeCategory === "task"
            ? "update_task_success"
            : "update_lead_success";
        const actions = [toastActions.success({ message: msg })];
        if (messageParams) {
          actions.push(
            tasksActions.sendMessageRequest({
              params: this.getMessageParams(task, messageParams),
            })
          );
        }
        this.taskService.refreshFetch$.next();
        return observableFrom(actions);
      })
    )
  );

  deleteTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.deleteTaskRequest),
      switchMap(({ params: { id, reason, taskType } }) =>
        this.apiService.delete(`tasks/${id}`, { reason: reason }).pipe(
          mergeMap(() => {
            let successMessage: string;
            if (taskType === "task") {
              successMessage = "delete_task_success";
            } else {
              successMessage = "delete_lead_success";
            }
            return observableFrom([
              tasksActions.deleteTaskSuccess(),
              toastActions.success({ message: successMessage }),
            ]);
          }),
          catchError(() => {
            let failedMessage: string;
            if (taskType === "task") {
              failedMessage = "delete_task_failed";
            } else {
              failedMessage = "delete_lead_failed";
            }
            return observableFrom([
              tasksActions.deleteTaskFailed(),
              toastActions.danger({ message: failedMessage }),
            ]);
          })
        )
      )
    )
  );

  createTaskAttempt$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.createTaskAttemptRequest),
      switchMap(({ taskAttempt }) =>
        this.apiService
          .post(`tasks/${taskAttempt.eaTaskId}/attempts`, taskAttempt)
          .pipe(
            mergeMap(() => {
              this.taskService.refreshFetch$.next();
              return observableFrom([
                tasksActions.createTaskAttemptSuccess(),
                toastActions.success({ message: "update_lead_success" }),
                tasksActions.openSalesmeetingTabIfNotBooked({ taskAttempt }),
              ]);
            }),
            catchError(() =>
              observableFrom([
                tasksActions.createTaskAttemptFailed(),
                toastActions.danger({ message: "update_lead_failed" }),
              ])
            )
          )
      )
    )
  );

  getOfficeEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.getOfficeEmployeesRequest),
      switchMap(({ params }) =>
        this.apiService.get(`employees`, params).pipe(
          map((response: TypedPaginationListDTO<Employee>) =>
            tasksActions.getOfficeEmployeesSuccess({ employees: response.rows })
          ),
          catchError(() =>
            observableOf(tasksActions.getOfficeEmployeesFailed())
          )
        )
      )
    )
  );

  createExternalTipTask$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.createTaskRequest),
      mergeMap(({ params }) =>
        this.apiService.post("tasks", params).pipe(
          map(() => tasksActions.createTaskSuccess()),
          catchError(() => {
            return observableOf(tasksActions.createTaskFailed());
          })
        )
      )
    )
  );

  sendEmailRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tasksActions.sendMessageRequest),
      mergeMap(({ params }) =>
        this.apiService.post("messages/email", params).pipe(
          map(() => tasksActions.sendMessageSuccess()),
          catchError(() => {
            return observableOf(tasksActions.sendMessageFailed());
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private taskService: TaskService
  ) {}

  private getMessageParams(
    task: Task,
    messageParams: SendMessageParams
  ): Record<string, unknown> {
    const dataSet = {
      receiverName: messageParams.receiverName,
      eaTaskId: task.eaTaskId,
      taskType: task.eaTaskTypeName,
      originEmployeeFullName: messageParams.originEmployeeFullName,
      originOfficeName: messageParams.originOfficeName,
      taskTitle: task.title,
      comment: messageParams.message,
      consumerId: task.contactId,
    };

    return {
      dataSet: transformObjectToMessageDataSet(dataSet),
      templateId: messageParams.templateId,
      recipients: messageParams.recipients,
      sendToModule: messageParams.sendToModule,
    };
  }
}
