import * as _ from "lodash";
import { BehaviorSubject, first, Observable } from "rxjs";

export class FilterService {
  private _values$: BehaviorSubject<any>;

  get values$(): Observable<any> {
    return this._values$.asObservable();
  }

  constructor(
    private source: Observable<any[]>,
    private searchableKeys: string[]
  ) {
    this.source
      .pipe(first())
      .subscribe((v) => (this._values$ = new BehaviorSubject(v)));
  }

  filter(query: string): void {
    this.source.pipe(first()).subscribe((items) => {
      if (!query) {
        this._values$.next(items);
      } else {
        const matches = this.searchableKeys.map((key) => {
          return items.filter(
            (item) => item[key].toString().toLowerCase().indexOf(query) !== -1
          );
        });
        const result = _.uniqWith(_.flatMap(matches), _.isEqual);
        this._values$.next(result);
      }
    });
  }
}
