import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class ListViewsService {
  availableViews: View[];
  selectedView: BehaviorSubject<View> = new BehaviorSubject<View>(null);

  setSelectedView(view: View) {
    this.selectedView.next(view);
  }

  setViews(views: View[]): any {
    this.availableViews = views;
  }

  shouldDisplayViewControls(): boolean {
    return this.availableViews?.length > 0;
  }
}

export interface View {
  name: string;
  params: any;
  extraParams?: any;
}
