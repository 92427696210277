<div class="wrapper">
  <div class="scroll-view">
    <div class="sidebar-header">
      <button type="button" class="btn-close pull-right" (click)="closeTab()">
        <app-icon name="times"></app-icon>
      </button>
      <div class="contact-summary-container">
        <h2
          [ngClass]="{
            'sidebar-header--smalltext': isLongText(
              ('create_residence_in' | translate) +
                ' ' +
                (providerName$ | async)
            )
          }"
        >
          {{
            ("create_residence_in" | translate) + " " + (providerName$ | async)
          }}
        </h2>
      </div>
    </div>

    <section>
      <card *ngIf="residence$ | async as residence">
        <div title>
          {{ residence.street }}, {{ residence.zip }} {{ residence.city }}
        </div>
        <div body>
          <small>
            <span *ngIf="checkProperty(residence.rooms); else roomsUnknown">
              {{ getResidenceNoOfRooms(residence.rooms) | async }}
            </span>
            -
            <span *ngIf="checkProperty(residence.area); else areaUnknown">
              {{ getResidenceArea(residence.area) | async }}
            </span>
            -
            <span *ngIf="residence.level; else levelUnknown">
              {{ "level" | translate }}: {{ residence.level }}
            </span>
          </small>
          <br />
          <small>
            <span *ngIf="residence.price > 0; else priceUnknown">
              {{ residence.price | qNumber }}
              {{ getCurrency(residence.currency) }}
            </span>
            -
            <span *ngIf="residence.monthlyFee; else feeUnknown">
              {{ residence.monthlyFee | qNumber }}
              {{ getCurrency(residence.currency) }}/{{ "month" | translate }}
            </span>
          </small>
          <br />
          <small>
            <span>{{
              getTranslationKey(residence.fireplace, "fireplace") | translate
            }}</span>
            -
            <span>{{
              getTranslationKey(residence.balcony, "balcony") | translate
            }}</span>
            -
            <span>{{
              getTranslationKey(residence.elevator, "elevator") | translate
            }}</span>
          </small>
        </div>
      </card>
    </section>
    <hr />
    <section>
      <div class="alert alert-info">
        {{
          "create_in_external_provider_info_message"
            | translate : { providerName: providerName$ | async }
        }}
      </div>
    </section>
    <section>
      <ng-container
        *ngIf="
          (providerName$ | async).toLowerCase() === externalProviders.VITEC
        "
      >
        <vitec-form
          *ngIf="(contact$ | async) && residence$ | async as residence"
          [municipalities$]="municipalities$"
          [preselectedMuncipitalityId]="preselectedMunicipalityId$ | async"
          [preselectedObjType]="residenceObjType$ | async"
          [patching]="patching$ | async"
          [observer]="proxy$"
          [residence]="residence"
          [objectTypes]="objectTypes$ | async"
          [contact]="contact$ | async"
          [addressSuggesterFeature]="addressSuggesterFeature$ | async"
          [addressValidationFeature]="addressValidationFeature$ | async"
          [countryCode]="countryCode$ | async"
          (submitClicked)="handleSubmission($event)"
          (closeClicked)="closeTab()"
        ></vitec-form>
      </ng-container>
      <ng-container
        *ngIf="
          (providerName$ | async).toLowerCase() === externalProviders.MSPECS
        "
      >
        <app-mspecs-form
          *ngIf="residence$ | async as residence"
          [municipalities$]="municipalities$"
          [disableMunicipalitySelect$]="disableMunicipalitySelectBox$"
          [preselectedMuncipitalityId]="preselectedMunicipalityId$ | async"
          [preselectedObjType]="residenceObjType$ | async"
          [patching]="patching$ | async"
          [observer]="proxy$"
          [residence]="residence"
          [objectTypes]="objectTypes$ | async"
          [addressSuggesterFeature]="addressSuggesterFeature$ | async"
          [addressValidationFeature]="addressValidationFeature$ | async"
          [countryCode]="countryCode$ | async"
          (submitClicked)="handleSubmission($event)"
          (closeClicked)="closeTab()"
        ></app-mspecs-form>
      </ng-container>
      <ng-container
        *ngIf="(providerName$ | async).toLowerCase() === externalProviders.KIVI"
      >
        <app-kivi-form
          *ngIf="(contact$ | async) && residence$ | async as residence"
          [municipalities$]="municipalities$"
          [preselectedMuncipitalityId]="preselectedMunicipalityId$ | async"
          [preselectedObjType]="residenceObjType$ | async"
          [patching]="patching$ | async"
          [observer]="proxy$"
          [residence]="residence"
          [objectTypes]="objectTypes$ | async"
          [contact]="contact$ | async"
          [addressSuggesterFeature]="addressSuggesterFeature$ | async"
          [addressValidationFeature]="addressValidationFeature$ | async"
          [countryCode]="countryCode$ | async"
          (submitClicked)="handleSubmission($event)"
          (closeClicked)="closeTab()"
        >
        </app-kivi-form>
      </ng-container>
    </section>
  </div>
</div>

<ng-template #roomsUnknown
  ><em>{{ "rooms_unknown" | translate }}</em></ng-template
>
<ng-template #areaUnknown
  ><em>{{ "size_unknown" | translate }}</em></ng-template
>
<ng-template #levelUnknown
  ><em>{{ "level_unknown" | translate }}</em></ng-template
>
<ng-template #priceUnknown
  ><em>{{ "price_unknown" | translate }}</em></ng-template
>
<ng-template #feeUnknown
  ><em>{{ "fee_unknown" | translate }}</em></ng-template
>
