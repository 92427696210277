import { createAction, props } from "@ngrx/store";
import { ShortcutsSetting } from "@app/shared/config/models/shortcuts-feature";

const ACTION_PREFIX = "[SHORTCUTS WIDGET]";

export const getShortcutsRequest = createAction(
  `${ACTION_PREFIX} GET_SHORTCUTS_REQUEST`
);

export const getShortcutsSuccess = createAction(
  `${ACTION_PREFIX} GET_SHORTCUTS_SUCCESS`,
  props<{ data: ShortcutsSetting }>()
);

export const getShortcutsFail = createAction(
  `${ACTION_PREFIX} GET_SHORTCUTS_FAIL`
);

export const createShortcutsRequest = createAction(
  `${ACTION_PREFIX} CREATE_SHORTCUTS_REQUEST`,
  props<{ settingValue: string }>()
);

export const createShortcutsSuccess = createAction(
  `${ACTION_PREFIX} CREATE_SHORTCUTS_SUCCESS`
);

export const createShortcutsFail = createAction(
  `${ACTION_PREFIX} CREATE_SHORTCUTS_FAIL`
);

export const updateShortcutsRequest = createAction(
  `${ACTION_PREFIX} UPDATE_SHORTCUTS_REQUEST`,
  props<{ settingId: string; settingGroupId: string; settingValue: string }>()
);

export const updateShortcutsSuccess = createAction(
  `${ACTION_PREFIX} UPDATE_SHORTCUTS_SUCCESS`
);

export const updateShortcutsFail = createAction(
  `${ACTION_PREFIX} UPDATE_SHORTCUTS_FAIL`
);
