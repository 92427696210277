<form novalidate [formGroup]="form">
  <section *ngIf="shouldShowFormAlert()">
    <div translate class="alert alert-info">validation_create_contact</div>
  </section>
  <section>
    <div formGroupName="contactInfo">
      <h4 translate>contact_info</h4>
      <div *ngIf="onlyContactDetails" class="form-group">
        <div class="alert alert-info" translate>
          validation_create_contact_external
        </div>
        <search-contact
          [filter]="'number'"
          [countryCode]="phoneNumberCountryCode"
          [minLength]="3"
          [placeholder]="'phone' | translate"
          [group]="form.get('contactInfo')"
          [controlName]="'phoneNumber'"
          [extended]="true"
          [hideEmptyResults]="true"
          (onCountryChange)="updatePhoneNumberValidators($event.regionCode)"
          (eniroClicked)="handleEniroContactClicked($event)"
          (quedroClicked)="handleQuedroContactClicked($event)"
          (quedroResult)="
            onlyContactDetails
              ? null
              : handleAsyncValidation($event, 'contactInfo.phoneNumber')
          "
        ></search-contact>
        <div [ngxErrors]="form.get('contactInfo.phoneNumber')">
          <div translate ngxError="phone" showWhen="touched">
            validation_fixed_line
          </div>
          <div translate ngxError="unique" showWhen="touched">
            validation_contact_exists
          </div>
        </div>
      </div>
      <div
        [class.prefilled]="prefilledFields.includes('msisdn')"
        [ngClass]="contact ? getAnimationClass(contact.msisdn) : ''"
      >
        <search-contact
          [filter]="'number'"
          [countryCode]="msisdnCountryCode"
          [minLength]="3"
          [focus]="!contact"
          [placeholder]="'mobile' | translate"
          [group]="form.get('contactInfo')"
          [controlName]="'msisdn'"
          [extended]="true"
          [hideEmptyResults]="true"
          (onCountryChange)="updateMsisdnValidators($event.regionCode)"
          (eniroClicked)="handleEniroContactClicked($event)"
          (quedroClicked)="handleQuedroContactClicked($event)"
          (quedroResult)="handleAsyncValidation($event, 'contactInfo.msisdn')"
          [class.validation-input]="
            (form.get('contactInfo.msisdn').invalid &&
              form.get('contactInfo.msisdn').touched) ||
            shouldShowFormAlert()
          "
        ></search-contact>
        <div ngxErrors="msisdn">
          <div translate ngxError="phone" showWhen="touched">
            validation_mobile
          </div>
          <div translate ngxError="unique" showWhen="touched">
            validation_contact_exists
          </div>
        </div>
        <div
          translate
          ngxerror
          *ngIf="
            this.form.hasError('allRequired') &&
            form.get('contactInfo.msisdn').touched
          "
        >
          validation_required
        </div>
      </div>
      <div
        [class.prefilled]="prefilledFields.includes('email')"
        [ngClass]="contact ? getAnimationClass(contact.email) : ''"
      >
        <search-contact
          [placeholder]="'email' | translate"
          [group]="form.get('contactInfo')"
          [controlName]="'email'"
          [waitForRequiredChar]="false"
          [requiredChars]="['@']"
          [hideEmptyResults]="true"
          (quedroClicked)="handleQuedroContactClicked($event)"
          (quedroResult)="handleAsyncValidation($event, 'contactInfo.email')"
          [class.validation-input]="
            form.get('contactInfo.email').invalid &&
            form.get('contactInfo.email').touched
          "
        ></search-contact>
        <div ngxErrors="email">
          <div translate ngxError="validateEmail" showWhen="touchedAndDirty">
            validation_email
          </div>
          <div translate ngxError="unique" showWhen="touchedAndDirty">
            validation_contact_exists
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="!onlyContactDetails">
      <div
        [class.prefilled]="prefilledFields.includes('firstName')"
        [ngClass]="contact ? getAnimationClass(contact.firstName) : ''"
      >
        <label>{{ "firstName" | translate }}</label>
        <input
          formControlName="firstName"
          [class.validation-input]="
            form.get('firstName').invalid && form.get('firstName').touched
          "
        />
      </div>
      <div ngxErrors="firstName">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
      <div
        [class.prefilled]="prefilledFields.includes('familyName')"
        [ngClass]="contact ? getAnimationClass(contact.familyName) : ''"
      >
        <search-contact
          (quedroClicked)="handleQuedroContactClicked($event)"
          (quedroResult)="handleAsyncValidation($event, 'familyName')"
          [additionalSearchText]="form.get('firstName').value + ' '"
          [controlName]="'familyName'"
          [group]="form"
          [hideEmptyResults]="true"
          [minLength]="form.get('firstName').value ? 3 : 5"
          [class.validation-input]="
            form.get('familyName').invalid && form.get('familyName').touched
          "
          [placeholder]="'familyName' | translate"
        ></search-contact>
        <div ngxErrors="familyName">
          <div translate ngxError="required" showWhen="touched">
            validation_required
          </div>
        </div>
      </div>
    </div>
  </section>

  <hr />
  <ng-container
    *ngIf="
      enableSource &&
      (contactSourceFeature$ | async)?.enabled &&
      !onlyContactDetails
    "
  >
    <section>
      <h4 translate class="required">source</h4>
      <div class="form-group">
        <select
          formControlName="source"
          [class.validation-input]="
            form.get('source').invalid && form.get('source').touched
          "
        >
          <option translate value="">select_source</option>
          <option
            *ngFor="let source of contactSources$ | async"
            [value]="source.eaTaskTypeId"
          >
            {{ source.typeName }}
          </option>
        </select>
      </div>
      <div ngxErrors="source">
        <div translate ngxError="required" showWhen="touched">
          validation_required
        </div>
      </div>
    </section>
    <hr />
  </ng-container>

  <section *ngIf="!onlyContactDetails" tabindex="-1">
    <h5
      (click)="additionalInfoHidden = !additionalInfoHidden"
      translate
      class="address"
      [ngClass]="
        addressToggle.hidden ? 'address__headline' : 'address__headline--black'
      "
    >
      <i [ngClass]="addressToggle.hidden ? 'fa fa-plus' : 'fa fa-minus'"></i>
      additional_information
    </h5>
    <div #addressToggle [hidden]="additionalInfoHidden">
      <div class="form-group">
        <label translate>owns_residence</label>
        <app-segmented-controls
          [active]="form.get('ownsResidence').value"
          [controls]="contactOwnsResidence"
          (change)="form.get('ownsResidence').setValue($event)"
        ></app-segmented-controls>
      </div>

      <div class="form-group">
        <label translate>marital_status</label>
        <app-segmented-controls
          [active]="form.get('maritalStatus')?.value"
          [controls]="contactMaritalStatus"
          (change)="form.get('maritalStatus').setValue($event)"
        ></app-segmented-controls>
      </div>

      <div class="form-group">
        <label translate>gender</label>
        <app-segmented-controls
          [active]="form.get('sex')?.value"
          [controls]="contactSex"
          (change)="form.get('sex').setValue($event)"
        ></app-segmented-controls>
      </div>
      <div class="form-group">
        <label translate>preferred_language</label>
        <div class="input-iconfield input-iconfield--flag">
          <country-code-selector
            class="icon"
            [lang]="getLanguage() | async"
            [iso]="defaultPreferredLangauge"
            [showCode]="false"
            (onChange)="handleCountryChange($event)"
          >
          </country-code-selector>
          <input formControlName="preferredLanguageName" type="text" disabled />
          <input
            formControlName="preferredLanguage"
            type="text"
            hidden
            disabled
          />
        </div>
      </div>

      <div formGroupName="address">
        <div
          [class.prefilled]="prefilledFields.includes('dateOfBirth')"
          class="form-group"
        >
          <label translate>birthday</label>
          <form-input-datepicker-dropdown
            controlName="dateOfBirth"
            [placeholder]="getDatePickerPlaceHolder()"
            [group]="form"
          >
          </form-input-datepicker-dropdown>
        </div>

        <div
          class="form-group"
          [class.prefilled]="prefilledFields.includes('phoneNumber')"
        >
          <search-contact
            [filter]="'number'"
            [countryCode]="phoneNumberCountryCode"
            [minLength]="3"
            [placeholder]="'phone' | translate"
            [group]="form.get('contactInfo')"
            [controlName]="'phoneNumber'"
            [extended]="true"
            [hideEmptyResults]="true"
            (onCountryChange)="updatePhoneNumberValidators($event.regionCode)"
            (eniroClicked)="handleEniroContactClicked($event)"
            (quedroClicked)="handleQuedroContactClicked($event)"
            (quedroResult)="
              handleAsyncValidation($event, 'contactInfo.phoneNumber')
            "
          ></search-contact>
          <div [ngxErrors]="form.get('contactInfo.phoneNumber')">
            <div translate ngxError="phone" showWhen="touched">
              validation_fixed_line
            </div>
            <div translate ngxError="unique" showWhen="touched">
              validation_contact_exists
            </div>
          </div>
        </div>

        <div
          class="form-group"
          [class.prefilled]="prefilledFields.includes('street')"
        >
          <search-address-adv
            type="street"
            [containerFormControl]="form.get('address').get('street')"
            [enabled]="(addressSuggesterFeature$ | async)?.enabled"
            [provider]="(addressSuggesterFeature$ | async)?.provider"
            [countryCode]="countryCode$ | async"
            [placeholder]="'street' | translate"
            [invalid]="form.get('address').get('street').invalid"
            [showFullAddress]="false"
            (onSelected)="handleAddressSelected($event)"
          ></search-address-adv>
        </div>

        <div
          class="form-group"
          [class.prefilled]="prefilledFields.includes('zip')"
        >
          <search-address-adv
            type="zip"
            [containerFormControl]="form.get('address').get('zip')"
            [enabled]="(addressSuggesterFeature$ | async)?.enabled"
            [provider]="(addressSuggesterFeature$ | async)?.provider"
            [countryCode]="countryCode$ | async"
            [placeholder]="'zip' | translate"
            [invalid]="form.get('address').get('zip').invalid"
            [showFullAddress]="false"
            (onSelected)="handleZipSelected($event)"
          ></search-address-adv>
        </div>

        <div
          class="form-group"
          [class.prefilled]="prefilledFields.includes('city')"
        >
          <search-address-adv
            type="city"
            [containerFormControl]="form.get('address').get('city')"
            [enabled]="(addressSuggesterFeature$ | async)?.enabled"
            [provider]="(addressSuggesterFeature$ | async)?.provider"
            [countryCode]="countryCode$ | async"
            [placeholder]="'city' | translate"
            [invalid]="form.get('address').get('city').invalid"
            [showFullAddress]="false"
          ></search-address-adv>
        </div>

        <address-validation
          *ngIf="(addressValidationFeature$ | async)?.enabled"
          [address]="addressValues$"
          [countryCode]="countryCode$ | async"
          [max]="5"
          (onSelect)="handleAddressSuggestionSelected($event)"
        ></address-validation>
      </div>
    </div>
  </section>
  <hr *ngIf="!onlyContactDetails" />
  <section *ngIf="!editMode">
    <h4 class="required" translate>consent</h4>
    <div class="consent-group">
      <div class="consent-group__icon-container">
        <i class="icon-gdpr"></i>
      </div>
      <div class="consent-group__box" [class.success-bg]="checkedInput">
        <label class="checkbox-group">
          {{ "contact_has_agreed_to_be_added" | translate }}
          <input
            type="checkbox"
            [checked]="checkedInput"
            (change)="handleConsentChange(!checkedInput)"
            formControlName="consent"
            required
          />
          <span class="checkbox-group__checkmark"></span>
        </label>
      </div>
      <div tabindex="-1" ngxErrors="consent">
        <div translate ngxError="required" showWhen="touched">
          consent_mandatory
        </div>
      </div>
    </div>
  </section>
</form>
