import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concat, first, map, of, switchMap } from "rxjs";
import { ContactObjectConnection } from "@app/models";
import { undo } from "ngrx-undo";
import { TaskSet } from "@app/shared/modules/progress-widget/models/TaskSet";
import { addTaskSetToQueue } from "@app/shared/modules/progress-widget/ngrx/progress-widget.actions";
import { SupportedDeepLinksEnum } from "@app/showings/enums/supported-deep-links.enum";
import { ExternalPresentableLink } from "@app/contacts/contact-sales-meetings/contact-sales-meetings.effects";
import * as toastActions from "@app/core/components/toast/ngrx/toast.actions";
import { ApiService } from "@app/core/services/api/api.service";
import { ContactService } from "@app/core/ngrx/entity-services/contact.service";
import * as biddingStatusActions from "@app/sidebar/potential-buyer/ngrx/bidding/bidding.actions";

@Injectable()
export class BiddingEffects {
  fetchBiddingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.fetchBiddingStatusRequest),
      switchMap(({ contactId, params }) =>
        this.api
          .get(
            `object/contact/${contactId}/contact-object-connections/search`,
            params
          )
          .pipe(
            map((response: any) =>
              response?.contactObjectConnections.map(
                (item) => item as ContactObjectConnection
              )
            ),
            map((connections) => connections[0]),
            map((connection) =>
              biddingStatusActions.fetchBiddingStatusSuccess({
                bidStatus: connection.bidStatus,
              })
            ),
            catchError(() =>
              of(biddingStatusActions.fetchBiddingStatusFailure())
            )
          )
      )
    )
  );

  updateBiddingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.updateBiddingStatusRequest),
      switchMap(({ contactId, body, undoAction }) =>
        this.api
          .post(`contacts/${contactId}/contact-object-connections`, body)
          .pipe(
            map((res) => {
              this.contactService
                .refreshPotentialBuyers()
                .pipe(first())
                .subscribe(() => {
                  this.contactService.entityMap$
                    .pipe(
                      first(),
                      map((entitymap) => entitymap[contactId])
                    )
                    .subscribe((contact) => {
                      // @ts-ignore
                      this.contactService.updateOneInCache({
                        contactId,
                        objectRelationships: [
                          {
                            ...contact.objectRelationships[0],
                            bidStatus: body.bidStatus,
                          },
                        ],
                      });
                    });
                });
              return biddingStatusActions.updateBiddingStatusSuccess({
                payload: res,
              });
            }),
            catchError(() =>
              of(
                biddingStatusActions.updateBiddingStatusFailure(),
                undo(undoAction)
              )
            )
          )
      )
    )
  );

  updateBiddingStatusMultiple$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.updateBiddingStatusMultipleRequest),
      switchMap(({ contactIds, body }) => {
        const requests = contactIds.map((contactId) => {
          return this.api.post(
            `contacts/${contactId}/contact-object-connections`,
            body
          );
        });
        const taskSet: TaskSet = {
          label: `updating_to_${body.bidStatus}`,
          tasks: requests,
        };
        return concat(
          [
            biddingStatusActions.updateBiddingStatusMultipleSuccess({
              payload: null,
            }),
          ],
          [addTaskSetToQueue(taskSet)]
        );
      })
    )
  );

  fetchBiddingSettingsForBidderLinkRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.fetchBiddingSettingsForBidderLinkRequest),
      switchMap(({ eaOid, contactId }) =>
        this.api
          .post(
            `generate/smart-link/for/${SupportedDeepLinksEnum.BIDDER_SETTINGS}`,
            { context: { eaOid, contactId } },
            "integrations"
          )
          .pipe(
            map((res: ExternalPresentableLink) => {
              return biddingStatusActions.fetchBiddingSettingsForBidderLinkSuccess(
                { payload: res }
              );
            }),
            catchError(() =>
              of(
                biddingStatusActions.fetchBiddingSettingsForBidderLinkFailure()
              )
            )
          )
      )
    )
  );

  createExternalTipSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.fetchBiddingSettingsForBidderLinkFailure),
      map(() =>
        toastActions.danger({
          message: "couldnt_open_bidding_settings",
          duration: 15000,
        })
      )
    )
  );

  fetchSetAsWinningBidLinkRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(biddingStatusActions.fetchSetAsWinningBidLinkRequest),
      switchMap(({ bidId }) =>
        this.api
          .post(
            `generate/smart-link/for/${SupportedDeepLinksEnum.SET_WINNING_BID}`,
            { context: { bidId } },
            "integrations"
          )
          .pipe(
            map((res: ExternalPresentableLink) =>
              biddingStatusActions.fetchSetAsWinningBidLinkSuccess({
                payload: res,
              })
            ),
            catchError(() =>
              of(biddingStatusActions.fetchSetAsWinningBidLinkFailure())
            )
          )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private contactService: ContactService
  ) {}
}
