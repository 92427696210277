<app-q-modal>
  <ng-container slot="header">
    {{ "copy_object" | translate }}
  </ng-container>
  <ng-container slot="body">
    <div class="body-container">
      <form [formGroup]="form" class="form-container">
        <ng-container *ngIf="!(optionsIsLoading$ | async); else loadingSmall">
          <div class="group" style="align-items: flex-end">
            <div style="width: 45%; margin: 0 0 12px 0">
              <app-q-input
                type="number"
                formControlName="numberOfCopy"
                min="1"
                [max]="currentOptions$.value?.maxNumberOfCopies"
                [invalid]="
                  form.get('numberOfCopy').invalid &&
                  form.get('numberOfCopy').dirty
                "
                [label]="'number_of_copy'"
              >
              </app-q-input>
            </div>
            <div style="margin: 0">
              <div
                *ngIf="form.get('numberOfCopy').value > 1"
                class="alert alert-info"
              >
                {{ "copy_object_tooltip" | translate }}
              </div>
            </div>
          </div>
        </ng-container>
        <h4 translate>options</h4>
        <ng-container *ngIf="!(optionsIsLoading$ | async); else loadingLarge">
          <div class="group checkbox-container">
            <ng-container
              *ngFor="let option of currentOptions$.value?.copyOptions"
            >
              <mat-checkbox
                class="default-width margin"
                [formControl]="form.get(option.copyParameterName)"
                >{{ option.translationKey.toLowerCase() | translate }}
              </mat-checkbox>
            </ng-container>
          </div>
        </ng-container>
      </form>
    </div>
  </ng-container>
  <ng-container slot="actions">
    <div class="actions-container">
      <app-q-button
        mat-dialog-close
        (click)="closeModal()"
        label="close"
        buttonType="cancel"
        classes="margin-right"
      ></app-q-button>
      <app-q-button
        (click)="submit()"
        label="create"
        buttonType="save"
        [isLoading]="isWorking"
        [disabled]="isWorking"
      ></app-q-button>
    </div>
  </ng-container>
</app-q-modal>

<ng-template #loadingSmall>
  <skeleton-rectangle [height]="4"></skeleton-rectangle>
</ng-template>
<ng-template #loadingLarge>
  <skeleton-rectangle [height]="10"></skeleton-rectangle>
</ng-template>
