import { Employment } from "@app/models";
import { createAction, props } from "@ngrx/store";

export const getEmploymentsRequest = createAction(
  "[Sidebar Employees Search] GET_EMPLOYMENTS_REQUEST",
  props<{ eaOfficeId: string }>()
);

export const getEmploymentsSuccess = createAction(
  "[Sidebar Employees Search] GET_EMPLOYMENTS_SUCCESS",
  props<{ employments: Employment[] }>()
);

export const getEmploymentsFailed = createAction(
  "[Sidebar Employees Search] GET_EMPLOYMENTS_FAILED"
);

export const reset = createAction("[Sidebar Employees Search] RESET");
