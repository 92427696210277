import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AppState } from "@app/app.reducer";
import { select, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { hasRole } from "../user";
import { ROLE_ADMIN } from "../utils/roles";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(private store: Store<AppState>) {}

  canActivate(): Observable<boolean> {
    return this.store.pipe(select(hasRole(ROLE_ADMIN)));
  }
}
