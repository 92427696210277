<article
  bsModal
  #modal="bs-modal"
  class="modal fade filter-modal"
  tabindex="-1"
>
  <header>
    <button type="button" class="close" (click)="modal.hide()">
      <app-icon name="times"></app-icon>
    </button>
    <h3 class="filter-modal__title">
      {{ "filter_latest_actions" | translate }}
    </h3>
  </header>
  <section>
    <ul class="list-group filter-modal__list-group">
      <li *ngIf="showReset()" class="reset-btn" (click)="resetFilters()">
        <i class="icon-reload"></i> {{ "reset" | translate }}
      </li>
      <li
        class="filter flex-container flex-space-between"
        *ngFor="let filter of filters"
        (click)="toggleSelectedFilter(filter)"
      >
        <div>
          <i
            [ngStyle]="{ color: filterColor(filter) }"
            [class]="filter.icon"
          ></i>
          {{ filter.translationKey | translate }}
        </div>
        <i
          *ngIf="filterSelected(filter)"
          class="fa fa-check filter-modal__list-group__check"
        ></i>
      </li>
      <li class="filter">
        <slide-toggle
          *ngIf="showToggleSlide"
          (onToggle)="toggleShowAll.emit('latestActions')"
          [checked]="showAllToggle"
          [activeLabel]="'all' | translate"
          [inactiveLabel]="'mine_plural' | translate"
          [modal]="true"
        ></slide-toggle>
      </li>
    </ul>
  </section>
</article>

<section class="action-list">
  <div class="action-list__filter hidden-sm hidden-xs">
    <div class="filter-slide-toggle">
      <slide-toggle
        *ngIf="showToggleSlide"
        (onToggle)="toggleShowAll.emit('latestActions')"
        [checked]="showAllToggle"
        [activeLabel]="'all' | translate"
        [inactiveLabel]="'mine_plural' | translate"
        [modal]="true"
      ></slide-toggle>
    </div>
    <div *ngIf="showReset()" class="filter reset-btn" (click)="resetFilters()">
      <span><i class="icon-reload"></i>{{ "reset_filters" | translate }}</span>
    </div>

    <div
      class="btn btn-default filter filter-btn"
      *ngFor="let filter of filters"
      [ngClass]="{ activated: filterSelected(filter) }"
      [ngStyle]="{ 'background-color': filterColor(filter) }"
      (click)="toggleSelectedFilter(filter)"
      [tooltip]="filter.translationKey | translate"
    >
      <app-icon [name]="filter.icon"></app-icon>
    </div>
  </div>

  <ul class="list-group">
    <section class="action-list__items-container">
      <li
        (click)="modal.show()"
        class="hidden-md hidden-lg hidden-xl no-link flex-center"
      >
        <div class="flex-center flex-column text-link">
          <i class="fa fa-filter"></i>
          <span>{{ "filter" | translate }}</span>
        </div>
      </li>

      <action-list-item
        [eaEmployeeId]="eaEmployeeId$ | async"
        *ngFor="let item of list"
        [item]="item"
      ></action-list-item>
      <list-item-empty *ngIf="!disableLoadMore && list?.length === 0">
        {{ "no_latest_consumer_actions_to_show" | translate }}
      </list-item-empty>
      <list-item-empty *ngIf="disableLoadMore">
        {{ "loading_latest_consumer_actions" | translate }}
      </list-item-empty>

      <list-load-more
        *ngIf="showLoadMore"
        [disabled]="disableLoadMore"
        (loadMore)="onLoadMore()"
      >
      </list-load-more>
    </section>
  </ul>
</section>
