import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
} from "rxjs";
import { ShowingObject } from "@app/showings/models";
import { EmployeeDTO, QObject } from "@app/models";
import { select, Store } from "@ngrx/store";
import { AppState } from "@app/app.reducer";
import { FormBuilder, FormGroup } from "@angular/forms";
import { OfficeEmployeeDropdownService } from "@app/shared/modules/form-components/office-employee-dropdown/office-employee-dropdown.service";
import { ObjectService } from "@app/core/ngrx/entity-services/object.service";
import * as showingActions from "@app/showings/ngrx/showings/showings.actions";
import * as _ from "lodash";
import * as sendMessageActions from "@app/sidebar/send-message/ngrx/send-message.actions";
import * as exchangeActions from "@app/settings/account/exchange/ngrx/settings-account-exchange.actions";
import * as fromConfig from "@app/shared/config/config.reducer";
import * as fromSendMessage from "@app/sidebar/send-message/ngrx/send-message.reducer";
import { getEaOid } from "@app/sidebar/send-message/ngrx/send-message.reducer";
import * as fromUser from "@app/shared/user";

@Component({
  selector: "app-select-object",
  templateUrl: "./select-object.component.html",
  styleUrls: ["./select-object.component.scss"],
  providers: [OfficeEmployeeDropdownService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectObjectComponent implements OnInit, OnDestroy {
  @Output() chosenObjectChanged = new EventEmitter<QObject>();

  form: FormGroup;
  showPublishedToggle = true;
  employees$: Observable<EmployeeDTO[]>;
  showingObject$: Observable<ShowingObject>;
  eaEmployeeId$: Observable<string>;
  eaOfficeId$: Observable<string>;
  unSoldObjectsForEmployee$: Observable<QObject[]>;
  soldObjectsForEmployee$: Observable<QObject[]>;
  unSoldObjectsForOffice$: Observable<QObject[]>;
  soldObjectsForOffice$: Observable<QObject[]>;
  unSoldObjectsForEmployee: QObject[];
  soldObjectsForEmployee: QObject[];
  unSoldObjectsForOffice: QObject[];
  soldObjectsForOffice: QObject[];
  loadingSoldObjects$: Observable<boolean>;
  loadingUnSoldObjects$: Observable<boolean>;
  sendMessageObjectLinkSoldObjectsEnabled$: Observable<boolean>;
  sendMessageObjectLinkChainObjectsEnabled$: Observable<boolean>;
  unsubscribeObjectChecker$ = new Subject<void>();
  unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private localStore: OfficeEmployeeDropdownService,
    private objectService: ObjectService
  ) {
    this.buildForms();
  }

  ngOnInit(): void {
    this.mapStateToProps();
    this.loadObjectIfInShowingsModule();
    this.preSelectObjectIfInShowingsModule();
    this.handleFormValueChange();
    this.handleStreams();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  togglePublished() {
    this.showPublishedToggle = !this.showPublishedToggle;
    this.loadUnSoldObject();
  }

  loadObjectIfInShowingsModule(): void {
    // Todo: Dont use regex...
    const pattern = /showings\/([\s\S]*?)\/potential-buyers/;
    const result = location.href.match(pattern);
    if (result && result[1]) {
      const eaOid = result[1];
      this.store.dispatch(
        showingActions.getObjectRequest({
          eaOid,
          params: {
            getFiles: true,
          },
        })
      );
    }
  }

  employeeIsChosen(): boolean {
    return this.form.get("eaEmployeeId").value !== "";
  }

  preSelectObjectIfInShowingsModule() {
    const pattern = /showings\/([\s\S]*?)\/potential-buyers/;
    const result = location.href.match(pattern);
    if (result && result[1]) {
      const currentEaOid = result[1];
      combineLatest([
        this.soldObjectsForEmployee$,
        this.unSoldObjectsForEmployee$,
        this.soldObjectsForOffice$,
        this.unSoldObjectsForOffice$,
      ])
        .pipe(
          takeUntil(this.unsubscribeObjectChecker$),
          filter((value) => !!value),
          distinctUntilChanged(_.isEqual)
        )
        .subscribe(
          ([
            soldObjectsForEmployee,
            unSoldObjectsForEmployee,
            soldObjectsForOffice,
            unSoldObjectsForOffice,
          ]) => {
            let selectedObject: QObject = null;

            if (this.employeeIsChosen()) {
              const soldObject = soldObjectsForEmployee.find(
                (obj: QObject) => obj.eaOid === currentEaOid
              );
              if (soldObject) {
                this.form.get("soldEaOid").setValue(soldObject.eaOid);
                selectedObject = soldObject;
                this.unsubscribeObjectChecker();
              } else {
                const unSoldObject = unSoldObjectsForEmployee.find(
                  (obj: QObject) => obj.eaOid === currentEaOid
                );
                if (unSoldObject) {
                  this.form.get("unSoldEaOid").setValue(unSoldObject.eaOid);
                  selectedObject = unSoldObject;
                  this.unsubscribeObjectChecker();
                }
              }
            } else {
              const soldObject = soldObjectsForOffice.find(
                (obj: QObject) => obj.eaOid === currentEaOid
              );
              if (soldObject) {
                this.form.get("soldEaOid").setValue(soldObject.eaOid);
                selectedObject = soldObject;
                this.unsubscribeObjectChecker();
              } else {
                const unSoldObject = unSoldObjectsForOffice.find(
                  (obj: QObject) => obj.eaOid === currentEaOid
                );
                if (unSoldObject) {
                  this.form.get("unSoldEaOid").setValue(unSoldObject.eaOid);
                  selectedObject = unSoldObject;
                  this.unsubscribeObjectChecker();
                }
              }
            }

            if (!!selectedObject) {
              this.chosenObjectChanged.emit(selectedObject);
            }
          }
        );
    }
  }

  unsubscribeObjectChecker() {
    this.unsubscribeObjectChecker$.next();
    this.unsubscribeObjectChecker$.complete();
  }

  loadUnSoldObject(): void {
    this.eaOfficeId$.pipe(first()).subscribe((officeId) => {
      const eaEmployeeId = this.form.get("eaEmployeeId").value;
      const eaOfficeId = this.form.get("eaOfficeId").value
        ? this.form.get("eaOfficeId").value
        : officeId;
      if (eaEmployeeId) {
        this.store.dispatch(
          sendMessageActions.getUnsoldObjectsForEmployeeRequest({
            eaEmployeeId,
            publishedOnNet: this.showPublishedToggle ? "True" : "both",
          })
        );
      } else {
        this.store.dispatch(
          sendMessageActions.getUnsoldObjectsForOfficeRequest({
            eaOfficeId,
            publishedOnNet: this.showPublishedToggle ? "True" : "both",
          })
        );
      }
    });
  }

  handleStreams() {
    this.unSoldObjectsForEmployee$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((objects: QObject[]) => {
        this.unSoldObjectsForEmployee = objects;
      });
    this.soldObjectsForEmployee$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((objects: QObject[]) => {
        this.soldObjectsForEmployee = objects;
      });
    this.unSoldObjectsForOffice$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((objects: QObject[]) => {
        this.unSoldObjectsForOffice = objects;
      });
    this.soldObjectsForOffice$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((objects: QObject[]) => {
        this.soldObjectsForOffice = objects;
      });
    this.eaOfficeId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOfficeId) => {
        this.localStore.fetchEmployeesForOffice(eaOfficeId);
        this.form.get("eaEmployeeId").setValue("");
      });
    this.eaEmployeeId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaEmployeeId) => {
        this.store.dispatch(
          exchangeActions.getExchangeDataRequest({ eaEmployeeId })
        );
        this.form.get("eaEmployeeId").setValue(eaEmployeeId);
      });
  }

  handleFormValueChange() {
    combineLatest([
      this.sendMessageObjectLinkSoldObjectsEnabled$,
      this.form.get("eaEmployeeId").valueChanges,
    ])
      .pipe(distinctUntilChanged(_.isEqual), takeUntil(this.unsubscribe$))
      .subscribe(([showSoldObjects, eaEmployeeId]) => {
        this.form.get("unSoldEaOid").setValue("");
        this.form.get("soldEaOid").setValue("");
        const eaOfficeId = this.form.get("eaOfficeId").value;

        if (eaEmployeeId) {
          this.store.dispatch(
            sendMessageActions.getUnsoldObjectsForEmployeeRequest({
              eaEmployeeId,
              publishedOnNet: this.showPublishedToggle ? "True" : "both",
            })
          );
          if (showSoldObjects) {
            this.store.dispatch(
              sendMessageActions.getSoldObjectsForEmployeeRequest({
                eaEmployeeId,
              })
            );
          }
        } else {
          this.store.dispatch(
            sendMessageActions.getUnsoldObjectsForOfficeRequest({
              eaOfficeId: eaOfficeId,
              publishedOnNet: this.showPublishedToggle ? "True" : "both",
            })
          );
          if (showSoldObjects) {
            this.store.dispatch(
              sendMessageActions.getSoldObjectsForOfficeRequest({ eaOfficeId })
            );
          }
        }
        this.preSelectObjectIfInShowingsModule();
      });

    this.form
      .get("unSoldEaOid")
      .valueChanges.pipe(filter((value) => !!value))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOid) => {
        this.form.get("soldEaOid").setValue("");
        this.handleObjectChosen(eaOid, "unsold");
      });

    this.form
      .get("soldEaOid")
      .valueChanges.pipe(filter((value) => !!value))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((eaOid) => {
        this.form.get("unSoldEaOid").setValue("");
        this.handleObjectChosen(eaOid, "sold");
      });
  }

  handleObjectChosen(eaOid, type) {
    let objects: QObject[];
    const forRole = this.form.get("eaEmployeeId").value ? "employee" : "office";

    if (type === "unsold" && forRole === "employee") {
      objects = this.unSoldObjectsForEmployee;
    } else if (type === "unsold" && forRole === "office") {
      objects = this.unSoldObjectsForOffice;
    } else if (type === "sold" && forRole === "employee") {
      objects = this.soldObjectsForEmployee;
    } else {
      objects = this.soldObjectsForOffice;
    }

    const chosenObject = objects.find((object) => object.eaOid === eaOid);
    if (chosenObject) {
      this.chosenObjectChanged.emit(chosenObject);
    }
  }

  private buildForms() {
    this.form = this.fb.group({
      unSoldEaOid: "",
      soldEaOid: "",
      eaEmployeeId: "",
      eaOfficeId: "",
    });
  }

  private mapStateToProps() {
    this.employees$ = this.localStore.employees$;
    this.eaEmployeeId$ = this.store.pipe(select(fromUser.getEaEmployeeId));
    this.eaOfficeId$ = this.store.pipe(select(fromUser.getEaOfficeId));
    this.showingObject$ = this.store.pipe(
      select(getEaOid),
      filter((eaOid) => !!eaOid),
      switchMap((eaOid) =>
        this.objectService.entityMap$.pipe(
          map((entities) => entities[eaOid]),
          filter((entity) => !!entity)
        )
      )
    );
    this.loadingUnSoldObjects$ = this.store.pipe(
      select(fromSendMessage.getUnSoldObjectsLoading)
    );
    this.loadingSoldObjects$ = this.store.pipe(
      select(fromSendMessage.getSoldObjectsLoading)
    );
    this.sendMessageObjectLinkSoldObjectsEnabled$ = this.store.pipe(
      select(fromConfig.getSendMessageObjectLinkSoldObjectsEnabled)
    );
    this.sendMessageObjectLinkChainObjectsEnabled$ = this.store.pipe(
      select(fromConfig.getSendMessageObjectLinkChainObjectsEnabled)
    );
    this.unSoldObjectsForEmployee$ = this.store.pipe(
      select(fromSendMessage.getUnSoldObjectsForEmployee)
    );
    this.soldObjectsForEmployee$ = this.store.pipe(
      select(fromSendMessage.getSoldObjectsForEmployee)
    );
    this.unSoldObjectsForOffice$ = this.store.pipe(
      select(fromSendMessage.getUnSoldObjectsForOffice)
    );
    this.soldObjectsForOffice$ = this.store.pipe(
      select(fromSendMessage.getSoldObjectsForOffice)
    );
  }
}
