import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { MortgageStatus } from "@app/sidebar/potential-buyer/models/mortgage-status";

@Component({
  selector: "app-loan-commitment",
  templateUrl: "./loan-commitment.component.html",
  styleUrls: ["./loan-commitment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoanCommitmentComponent {
  @Input() status: string;
  @Input() enableSendTipToBank: boolean;
  @Output() statusChange = new EventEmitter<string>();
  @Output() sendTipToBankClicked = new EventEmitter<void>();

  Status = MortgageStatus;

  handleClick(status: string): void {
    const isSelected = this.isSelected(status);
    const newStatus = isSelected ? MortgageStatus.Unknown : status;
    this.statusChange.emit(newStatus);
  }

  isSelected(status: string): boolean {
    if (!this.status) {
      return;
    }
    return this.status === status;
  }
}
