import {
  TaskList,
  TaskListConfig,
} from "@app/dashboard/todo-list/models/TaskList";
import { Task } from "@app/models";
import { API_DATE_FORMAT } from "@app/shared/utils/api-defaults";
import { createAction, props } from "@ngrx/store";
import moment from "moment";

export const getCurrentWeek = (eaEmployeeId: string) => {
  const filters: Partial<TaskList> = {
    limit: 2000,
    eaEmployeeId: eaEmployeeId,
    taskTypeCategory: "lead,task",
    resolved: false,
    sortBy: "insertedDate",
    sortOrder: "desc",
    deliveryFrom: moment()
      .subtract(5, "days")
      .startOf("day")
      .format(API_DATE_FORMAT),
  };

  return getCurrentWeekRequest({ filters });
};

export const getCurrentWeekRequest = createAction(
  "[ThreeDayTaskList] GET_CURRENT_WEEK_REQUEST",
  props<{ filters: Partial<TaskList> }>()
);

export const getCurrentWeekSuccess = createAction(
  "[ThreeDayTaskList] GET_CURRENT_WEEK_SUCCESS",
  props<{ tasks: Task[] }>()
);

export const getCurrentWeekFail = createAction(
  "[ThreeDayTaskList] GET_CURRENT_WEEK_FAIL"
);

export const setListConfig = createAction(
  "[ThreeDayTaskList] SET_LIST_CONFIG",
  props<{
    taskListConfig: TaskListConfig;
    index: number;
  }>()
);
