<div class="select-container">
  <div class="form-row">
    <office-employee-dropdown
      [parentFormGroup]="form"
      [sideBySide]="true"
      parentOfficeFormControlName="eaOfficeId"
      parentEmployeeFormControlName="eaEmployeeId"
      [setDefaults]="true"
      [disableSelectOffice]="false"
      [disableSelectBroker]="false"
      [showAllOffices]="sendMessageObjectLinkChainObjectsEnabled$ | async"
    ></office-employee-dropdown>
  </div>
  <div class="form-row">
    <div class="form-group">
      <div class="toggle-container">
        <label translate>non_sold_objects</label>
        <slide-toggle
          [checked]="showPublishedToggle"
          [activeLabel]="'is_published' | translate"
          [inactiveLabel]="'unpublished' | translate"
          (onToggle)="togglePublished()"
        ></slide-toggle>
      </div>
      <app-multi-select-dropdown
        [parentFormGroup]="form"
        parentFormControlName="unSoldEaOid"
        [items]="
          employeeIsChosen()
            ? (unSoldObjectsForEmployee$ | async)
            : (unSoldObjectsForOffice$ | async)
        "
        [displayProperty]="'street'"
        [secondaryDisplayProperty]="'zip'"
        [valueProperty]="'eaOid'"
        [enableSearch]="true"
        [singleSelect]="true"
        [maxHeight]="'40rem'"
        [minWidth]="'100%'"
        [placeholder]="'select_object'"
        [state]="(loadingUnSoldObjects$ | async) ? 'LOADING' : 'READY'"
      >
        <i class="icon-home" placeholderIcon></i>
        <span placeholder translate>select_object</span>
        <span mobilePlaceholder>{{ "select_object" | translate }}}</span>
      </app-multi-select-dropdown>
    </div>
    <div
      class="form-group"
      *ngIf="sendMessageObjectLinkSoldObjectsEnabled$ | async"
    >
      <label style="margin-bottom: 0; margin-top: 0rem; height: 2rem">
        <span translate>sold_objects</span>
        ({{ "latest_six_months" | translate }})
      </label>
      <app-multi-select-dropdown
        [parentFormGroup]="form"
        parentFormControlName="soldEaOid"
        [items]="
          employeeIsChosen()
            ? (soldObjectsForEmployee$ | async)
            : (soldObjectsForOffice$ | async)
        "
        [displayProperty]="'street'"
        [secondaryDisplayProperty]="'zip'"
        [valueProperty]="'eaOid'"
        [enableSearch]="true"
        [singleSelect]="true"
        [maxHeight]="'40rem'"
        [minWidth]="'100%'"
        [placeholder]="'select_object'"
        [state]="(loadingSoldObjects$ | async) ? 'LOADING' : 'READY'"
      >
        <i class="icon-home" placeholderIcon></i>
        <span placeholder translate>select_object</span>
        <span mobilePlaceholder>{{ "select_object" | translate }}}</span>
      </app-multi-select-dropdown>
    </div>
  </div>
</div>
